import { FetchItemPriceGroupItemPriceObject } from "./fetItemPriceGroup.model";
import { ItemAvaibility } from "./itemAvailability.model";

export interface ProductItem {
  brandId: string;
  brandName: string;
  subBrand: string;
  companyProductTitle: string;
  companySKU: string;
  existMandatoryAssociation: boolean;
  existFitmentRule: boolean;
  isBundle: boolean;
  isFittable: boolean;
  richContentUrlThumb: string;
  richContentUrl: string;
  numberOfComponentsPerProduct: string;
  quantityPerVehicle: string;
  associatedItems: any[];
}

export interface IProductPagedResult {
  orderedGroupingFields: string[] | undefined;
  existVehicleFitmentRule: boolean;
  totalCount: number;
  items: ProductItem[] | undefined;
}

export class Group {
  level = 0;
  parent: Group;
  totalCounts = 0;
}

export class ProductStocks {
  companySKU: string;
  stocks: ItemAvaibility;
}

export class ProductPrices {
  companySKU: string;
  prices: FetchItemPriceGroupItemPriceObject[];
}

export class OrderProductPrices {
  companySKU: string;
  prices: any = {};
}

export class ProductOrderOption {
  quantity: number;
  fitted: boolean = false;
  painted: boolean = false;
}
