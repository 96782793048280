import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { RegoStatus, StoreList, TrailerDetails, UpdateTrailerInfo } from 'libs/data/src/lib/models/camperTrailer';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-camper-trailer-details',
  templateUrl: './camper-trailer-details.component.html',
  styleUrls: ['./camper-trailer-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamperTrailerDetailsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() assetNumber: string;
  @Input() customerNumber: number;
  @Input() trailerDetails: TrailerDetails;
  @Input() hasMultiTrailers: boolean;
  @Input() storeList: StoreList[];
  @Input() regoStatusList: RegoStatus[];
  @Input() trailerAttachmentsLength: number;
  @Input() hasTrailerAttachmentChanged = false;
  @Input() hasServiceAttachmentChanged = false;
  @Input() hasServiceDetailsChanged = false;

  @Output() nextTrailerIndex = new EventEmitter<Number>();
  @Output() updateTrailerInfo = new EventEmitter<UpdateTrailerInfo>();
  @Output() cancelForm = new EventEmitter<any>();
  @Output() openTrailerAttachments = new EventEmitter<any>();
  @Output() hasTrailerDetailsChanged = new EventEmitter<boolean>();

  public trailerIndex: string;
  public camperTrailerDetailsForm = new FormGroup({
    vin: new FormControl({
      value: null,
      disabled: true,
    }),
    purchaseDate: new FormControl(),
    invoiceNumber: new FormControl(),
    regoNumber: new FormControl(),
    sellingStore: new FormControl(),
    salesPerson: new FormControl(),
    preferredARBStore: new FormControl(),
    serviceReminders: new FormControl(false),
    registrationStatusCode: new FormControl(),
    optionsFitted: new FormControl(),
  });
  public initialFormValue: any;

  private _hasFormValueChanged = false;

  set hasFormValueChanged(value) {
    this._hasFormValueChanged = value;
    this.hasTrailerDetailsChanged.emit(value);
  }

  get hasFormValueChanged(): boolean {
    return this._hasFormValueChanged;
  }

  private unSubscribe$ = new Subject<void>();

  constructor() {}

  ngOnInit(): void {
    this.camperTrailerDetailsForm.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.unSubscribe$)).subscribe((val) => {
      this.compareValues();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.trailerDetails?.currentValue && this.camperTrailerDetailsForm) {
      this.initializeForm();
    }

    if (changes.hasServiceDetailsChanged) {
      if (this.hasServiceDetailsChanged) {
        this.disableFormControls();
      } else {
        this.enableFormControls();
      }
    }

    if (changes.hasServiceAttachmentChanged) {
      if (this.hasServiceAttachmentChanged) {
        this.disableFormControls();
      } else {
        this.enableFormControls();
      }
    }
  }

  initializeForm() {
    this.initialFormValue = null;

    this.patchValueForCamperTrailerDetailsForm(this.trailerDetails);
    this.hasFormValueChanged = false;
    this.initialFormValue = this.camperTrailerDetailsForm.getRawValue();
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  patchValueForCamperTrailerDetailsForm(trailerDetails: TrailerDetails) {
    this.camperTrailerDetailsForm.patchValue({
      vin: trailerDetails.VIN,
      purchaseDate: trailerDetails.PurchaseDate,
      invoiceNumber: trailerDetails.InvoiceNumber,
      regoNumber: trailerDetails.RegoNumber,
      sellingStore: trailerDetails.SellingStore,
      salesPerson: trailerDetails.Salesperson,
      preferredARBStore: trailerDetails.PreferredARBStore,
      serviceReminders: trailerDetails.ServiceReminders === '0' ? false : true,
      registrationStatusCode: trailerDetails.RegistrationStatusCode,
      optionsFitted: trailerDetails.OptionsFitted,
    });
  }

  invokeUpdateTrailerInfo() {
    this.updateTrailerInfo.emit({
      assetNumber: Number(this.assetNumber),
      vin: this.camperTrailerDetailsForm.value.vin,
      purchaseDate: this.camperTrailerDetailsForm.value.purchaseDate,
      invoiceNumber: this.camperTrailerDetailsForm.value.invoiceNumber,
      regoNumber: this.camperTrailerDetailsForm.value.regoNumber,
      sellingStore: this.camperTrailerDetailsForm.value.sellingStore,
      salesperson: this.camperTrailerDetailsForm.value.salesPerson,
      preferredARBStore: this.camperTrailerDetailsForm.value.preferredARBStore,
      serviceReminders: !!this.camperTrailerDetailsForm.value.serviceReminders ? '1' : '0',
      registrationStatusCode: this.camperTrailerDetailsForm.value.registrationStatusCode,
      optionsFitted: this.camperTrailerDetailsForm.value.optionsFitted,
      customerNumber: this.customerNumber,
    });
  }

  invokeCancelForm() {
    this.cancelForm.emit();
    this.hasFormValueChanged = false;
  }

  removeTrailer() {
    if (this.hasServiceAttachmentChanged || this.hasServiceDetailsChanged) {
      return;
    }

    this.updateTrailerInfo.emit({
      assetNumber: Number(this.assetNumber),
      vin: this.camperTrailerDetailsForm.value.vin,
      purchaseDate: this.camperTrailerDetailsForm.value.purchaseDate,
      invoiceNumber: this.camperTrailerDetailsForm.value.invoiceNumber,
      regoNumber: this.camperTrailerDetailsForm.value.regoNumber,
      sellingStore: this.camperTrailerDetailsForm.value.sellingStore,
      salesperson: this.camperTrailerDetailsForm.value.salesPerson,
      preferredARBStore: this.camperTrailerDetailsForm.value.preferredARBStore,
      serviceReminders: !!this.camperTrailerDetailsForm.value.serviceReminders ? '1' : '0',
      registrationStatusCode: this.camperTrailerDetailsForm.value.registrationStatusCode,
      optionsFitted: this.camperTrailerDetailsForm.value.optionsFitted,
      customerNumber: 0,
    });
  }

  invokeSwitchTrailer() {
    if (this.hasServiceAttachmentChanged || this.hasServiceDetailsChanged) {
      return;
    }

    this.nextTrailerIndex.emit(1);
  }

  invokeOpenTrailerAttachments() {
    this.openTrailerAttachments.emit();
  }

  compareValues() {
    const currentFormValue = this.camperTrailerDetailsForm.getRawValue();

    if (JSON.stringify(currentFormValue) !== JSON.stringify(this.initialFormValue)) {
      this.hasFormValueChanged = true;
    } else {
      this.hasFormValueChanged = false;
    }
  }

  disableButton() {
    if (this.hasTrailerAttachmentChanged || this.hasFormValueChanged) {
      return false;
    } else {
      return true;
    }
  }

  disableFormControls() {
    this.camperTrailerDetailsForm.disable({emitEvent: false});
  }

  enableFormControls() {
    this.camperTrailerDetailsForm.enable({emitEvent: false});
    this.camperTrailerDetailsForm.controls['vin'].disable({emitEvent: false});
  }
}
