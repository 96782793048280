export interface Vehicle{
    VehicleID: string;
    Region: string;
    Year: string;
    Make: string;
    Model: string;
    Generation: string;
    SubModel: string;
    Chassis: string;
    Series: string;
    BodyType: string;
    NoOfDoors: string;
    DriveType: string;
    TransmissionType: string;
    BrakeType: string;
    BrakeSystem: string;
    EngineCode: string;
    KW: string;
    PS: string;
    CcmTech: string;
    Lit: string;
    Zyl: string;
    EngineType: string;
    FuelMixFormation: string;
    FuelType: string;
    FuelTankCapacity: string;
    Ventile: string;
    Ranking: string;
    partsVehicleID: string;
  }

  export class VehicleToBeSelect implements Vehicle{
    VehicleID: string;
    Region: string;
    Year: string;
    Make: string;
    Model: string;
    Generation: string;
    SubModel: string;
    Chassis: string;
    Series: string;
    BodyType: string;
    NoOfDoors: string;
    DriveType: string;
    TransmissionType: string;
    BrakeType: string;
    BrakeSystem: string;
    EngineCode: string;
    KW: string;
    PS: string;
    CcmTech: string;
    Lit: string;
    Zyl: string;
    EngineType: string;
    FuelMixFormation: string;
    FuelType: string;
    FuelTankCapacity: string;
    Ventile: string;
    Ranking: string;
    partsVehicleID: string;
  }
  
  export class VehicleSearchResult {
    Vehicles: Vehicle[];
    Year: string[];
    Make: string[];
    Model: string[];
    Generation: string[];
    SubModel: string[];
    Chassis: string[];
    Series: string[];
    BodyType: string[];
    DriveType: string[];
    TransmissionType: string[];
    EngineCode: string[];
    EngineType: string[];
    TooManyVehiclesYN: string;
    ErrorMessage: string;
    ErrorFlag: string;
  }

  export class VehicleSearchParamters {
    make: string;
    year: string;
    searchString: string;
    model: string;
    generation: string;
    subModel: string;
    chassis: string;
    series: string;
    bodyType: string;
    driveType: string;
    transmissionType: string;
    engineType: string;
    engineCode: string;
    partsVehicleID?: number;
  }

  export class VehicleDescriptionSearchResult {
    ErrorMessage: string;
    ErrorFlag: string;
    SearchResults: VehicleDescription[];    
  };

  export class VehicleDescription {
    jdeVehicleID: number;
    partsVehicleID: number;
    vehicleDescription: string;
  }

  export class VehicleDescriptionSearchParameter {
    jdeVehicleID: number;
    partsVehicleID: number;
  }
  