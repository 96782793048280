import { CdkDragDrop } from '@angular/cdk/drag-drop';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  ArrayOfLinesObject,
  ConsolidateOrder,
  DefaultOrder,
  OrderForBooking,
  RenameGroupName,
} from '@pos-app/data';
import {
  ALLOCATE_FITTER_UNIQUE_IDS,
  EMPTY_STRING,
} from 'libs/data/src/lib/constants/allocate-fitter';

@Component({
  selector: 'app-orders-for-booking',
  templateUrl: './orders-for-booking.component.html',
  styleUrls: ['./orders-for-booking.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrdersForBookingComponent implements OnInit {
  @Input() unAssignedOrdersList: Array<OrderForBooking>;
  @Input() dragDropUniqueIds: Map<string, string[]>;


  @Output() renameGroupName = new EventEmitter<RenameGroupName>();
  @Output() splitOrder = new EventEmitter<DefaultOrder>();
  @Output() consolidateOrder = new EventEmitter<ConsolidateOrder>();
  @Output() arrayOfLinesdropEvent = new EventEmitter<any>();
  @Output() arrayOfLineParentOrderData = new EventEmitter<any>();
  @Output() orderUnassignedEvent = new EventEmitter<any>();

  arrayOfLines: Array<ArrayOfLinesObject>;

  ordersForBooking: OrderForBooking[];

  orderForBookingId: string;
  arrayOfLineDropZoneId: string;

  constructor() {}

  ngOnInit(): void {
    this.orderForBookingId = ALLOCATE_FITTER_UNIQUE_IDS.OrderForBooking;
    this.arrayOfLineDropZoneId = ALLOCATE_FITTER_UNIQUE_IDS.ArrayOfLineDropZone;
  }

  orderDrop(event: CdkDragDrop<string[]>) {
    this.invokeChangeOrderBooking({
      previousContainer: event.previousContainer,
      currentContainer: event.container,
      previousIndex: event.previousIndex,
      currentIndex: event.currentIndex,
      orderNumber:
        event.previousContainer.data[event.previousIndex][
          'OrderNumber'
        ].toString(),
      orderType: event.previousContainer.data[event.previousIndex]['OrderType'],
      GroupName: event.previousContainer.data[event.previousIndex]['GroupName'],
      resourceID: EMPTY_STRING,
      resourceName: EMPTY_STRING,
      startDate: !!event.previousContainer.data[event.previousIndex][
        'InvoiceDate'
      ].trim()
        ? event.previousContainer.data[event.previousIndex][
            'InvoiceDate'
          ].trim()
        : event.previousContainer.data[event.previousIndex][
            'RequestDate'
          ].trim(),
      endDate: !!event.previousContainer.data[event.previousIndex][
        'InvoiceDate'
      ].trim()
        ? event.previousContainer.data[event.previousIndex][
            'InvoiceDate'
          ].trim()
        : event.previousContainer.data[event.previousIndex][
            'RequestDate'
          ].trim(),
      startTime: 0,
      endTime: 0,
      isOrderAssignedToFitter: false,
    });
  }

  arrayOfLineDropZone(event: CdkDragDrop<string[]>) {
    this.invokeReassignLine({
      previousContainer: event.previousContainer,
      currentContainer: !!event.container.data ? event.container : false,
      previousIndex: event.previousIndex,
      currentIndex: event.currentIndex,
      toGroupName: '',
      lineNumber:
        event.previousContainer.data[event.currentIndex][
          'LineNumber'
        ].toString(),
    });
  }

  emitArrayOfLineParentOrderData(parentOrderData: any) {
    this.arrayOfLineParentOrderData.emit(parentOrderData);
  }

  updateGroupName(newGroupName: RenameGroupName) {
    this.renameGroupName.emit(newGroupName);
  }

  invokeSplitOrder(parameters: DefaultOrder) {
    this.splitOrder.emit(parameters);
  }

  invokeConsolidateOrder(parameters: ConsolidateOrder) {
    this.consolidateOrder.emit(parameters);
  }

  invokeReassignLine(arrayOfLinesDragDrop: any) {
    this.arrayOfLinesdropEvent.emit(arrayOfLinesDragDrop);
  }

  invokeChangeOrderBooking(orderDragDrop: any) {
    this.orderUnassignedEvent.emit(orderDragDrop);
  }
}
