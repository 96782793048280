<div *ngIf="groupedItems && groupedItems.length" class="product-associated-items arb-clear-text-for-table">
  <div class="row">
    <div class="col-md-6"></div>
    <div class="col-md-6 text-right">
      <input type="checkbox" id="enforceAssociatedItemsRulesChk" [(ngModel)]="enforceAssociatedItemsRules" (change)="resetAssociatedItems()" />
      <label for="enforceAssociatedItemsRulesChk" class="arb-field-label checkbox-label"> Enforce Associated Items Rules </label>
    </div>
  </div>
  <div *ngFor="let item of groupedItems">
    <p-panel class="associated-group">
      <p-header class="p-header">
        <div class="ui-helper-clearfix" (click)="changePanelGroupState(item)">
          <span class="arb-medium-heading">
            <i class="pi pi-angle-double-down" style="font-size: 0.95em" *ngIf="item.isCollapsed"></i>
            <i class="pi pi-angle-double-up" style="font-size: 0.95em" *ngIf="!item.isCollapsed"></i>
            {{ item.name }}
          </span>

          <a class="arb-field-label" style="float: right; cursor: pointer">
            <span *ngIf="item.isCollapsed && checkGroupHasSelected(item)" style="color: gray; text-decoration: none; margin-right: 5px">
              showing selected item(s)
            </span>
            <span class="expand-btn"> CLICK TO {{ item.isCollapsed ? 'EXPAND' : 'COLLAPSE' }} </span>
          </a>
        </div>
      </p-header>
      <div *ngIf="item.filters && item.name != supplementGroupName && !item.isCollapsed" class="group-filter">
        <ng-container *ngFor="let filter of item.filters; let fIndex = index">
          <div *ngIf="fIndex < 4 || !item.isFilterCollapsed" class="filter">
            <div class="arb-field-label attribute-name">
              {{ filter.attribute }}
            </div>
            <div class="attribute-value">
              <ng-select
                [items]="filter.values"
                [(ngModel)]="item.currentFilter[fIndex]"
                bindLabel="value"
                bindValue="value"
                [placeholder]="''"
                (change)="groupFilterChange(item)"
              >
                <ng-template ng-option-tmp let-fvalue="item">
                  <span>{{ fvalue.value }}</span>
                  <span class="match-cnt">{{ fvalue.cnt }}</span>
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div
            class="more-filter icon"
            *ngIf="(item.isFilterCollapsed && fIndex == 4) || (!item.isFilterCollapsed && fIndex == item.filters.length - 1)"
            (click)="item.isFilterCollapsed = !item.isFilterCollapsed"
          >
            <i class="pi pi-angle-double-down" *ngIf="item.isFilterCollapsed && fIndex == 4"></i>
            <i class="pi pi-angle-double-up" *ngIf="!item.isFilterCollapsed && fIndex == item.filters.length - 1"></i>
          </div>
        </ng-container>
      </div>
      <table class="product-items-table associated-items arb-clear-text-for-table">
        <tr
          *ngIf="(item.name == supplementGroupName && !item.isCollapsed) || (item.isCollapsed && (item.values | filter : isSelected).length)"
          class="heading-row"
        >
          <td class="arb-field-label" style="width: 80px">QTY</td>
          <td></td>
          <td class="arb-field-label" style="width: 200px">ITEM #</td>
          <td class="arb-field-label" style="width: 200px">STOCK AVAIL</td>
          <td class="arb-field-label" style="width: 150px">MY PRICE</td>
        </tr>
        <ng-container *ngFor="let record of item.values">
          <tr [attr.data]="" [hidden]="(item.isCollapsed || isFilterOut(item, record)) && !record.isSelected" class="item-row">
            <td style="width: 80px; padding-top: 5px" [rowSpan]="record.isBundle || record.isKit ? '2' : '1'">
              <table>
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      [disabled]="(record.isMandatory && enforceAssociatedItemsRules) || (record.isConflicting && enforceAssociatedItemsRules)"
                      [(ngModel)]="record.isSelected"
                      (change)="selectProduct(record)"
                    />
                  </td>
                  <td>
                    <ng-container *ngIf="item.name == supplementGroupName">
                      <input type="text" step="1" min="0" [disabled]="true" [value]="mainQuantity" class="arb-clear-text-for-table order-quantity" />
                    </ng-container>

                    <ng-container *ngIf="item.name != supplementGroupName">
                      <p-inputNumber
                        class="quantity-input"
                        [disabled]="
                          !record.isSelected ||
                          (record.isMandatory && enforceAssociatedItemsRules) ||
                          (record.isConflicting && enforceAssociatedItemsRules)
                        "
                        [min]="0"
                        [(ngModel)]="record.quantity"
                        (ngModelChange)="updateItemPrices(record)"
                      ></p-inputNumber>
                    </ng-container>
                  </td>
                </tr>
              </table>
            </td>

            <td>
              <span *ngIf="item.name == supplementGroupName" class="arb-field-label">
                {{
                  record.addtionalProductData.supplementGroupItemName == 'FITTED'
                    ? getFitDescription(record.companySKU)
                    : record.addtionalProductData.companyProductTitle
                }}
              </span>
              <span *ngIf="item.name != supplementGroupName" class="arb-field-label has-link" (click)="viewProduct(record.companySKU)">
                {{ record.addtionalProductData.companyProductTitle }}
              </span>
              <br />
              <div *ngIf="!(item.name == supplementGroupName || item.isCollapsed)">
                <span>
                  Item: <span class="bold">{{ record.companySKU }}</span>
                </span>
                <br />
              </div>
              <div *ngIf="record.associatedItems && record.associatedItems.length" style="padding-top: 5px">
                <ng-container *ngFor="let addOnItem of record.associatedItems; let i = index" style="margin-right: 10px">
                  <div
                    *ngIf="
                      addOnItem.associatedType == 'Fit' || addOnItem.associatedType == 'Paint' || addOnItem.associatedType == 'SupplementaryCharge'
                    "
                  >
                    <input
                      [disabled]="
                        !record.isSelected ||
                        (enforceAssociatedItemsRules &&
                          record.isMandatory &&
                          record.isSelected &&
                          (addOnItem.associatedType == 'Fit' || addOnItem.associatedType == 'Paint'))
                      "
                      type="checkbox"
                      id="chk_{{ record.companySKU }}_{{ i }}"
                      [(ngModel)]="addOnItem.isSelected"
                      (change)="supplementItemChange(addOnItem, record)"
                    />
                    <label class="checkbox-label" for="chk_{{ record.companySKU }}_{{ i }}">
                      {{
                        addOnItem.associatedType == 'Fit'
                          ? 'FITTED'
                          : addOnItem.associatedType == 'Paint'
                          ? 'PAINTED'
                          : addOnItem.addtionalProductData.companySKU
                      }}
                    </label>
                  </div>
                </ng-container>
              </div>
              <span
                *ngIf="
                  record.associatedType &&
                  record.associatedType != 'Fit' &&
                  record.associatedType != 'Paint' &&
                  record.associatedType != 'SupplementaryCharge'
                "
                class="associated-item-message"
              >
                {{ record.infoMessage }}
              </span>
            </td>

            <td class="stock">
              <span *ngIf="item.name == supplementGroupName || item.isCollapsed">
                {{ record.companySKU }}
              </span>
              <ng-container *ngIf="!(item.name == supplementGroupName || item.isCollapsed)">
                <div
                  class="retrieving"
                  *ngIf="isUserloggedIn && (!record.stocks || (record.stocks.SearchResults && record.stocks.SearchResults.length == 0))"
                >
                  <i class="pi pi-spin pi-spinner"></i>Retrieving stocks..
                </div>
                <div class="cancelled" style="display: none"><i class="pi pi-ban"></i>Request cancelled</div>
                <table *ngIf="record.stocks && record.stocks.SearchResults" style="width: 100%">
                  <tr *ngFor="let stock of record.stocks.SearchResults">
                    <td>{{ stock.branchName ? stock.branchName : stock.branchCode }}:</td>
                    <td style="text-align: right; padding-right: 10px" class="{{ stock.quantity > 0 ? 'bold' : '' }}">
                      {{ getQuantity(record.stocks, stock) }}
                    </td>
                  </tr>
                </table>
              </ng-container>
            </td>

            <td style="width: 210px">
              <span *ngIf="item.name == supplementGroupName || item.isCollapsed">
                {{ getShortAvailabilityDescription(record.stocks) }}
              </span>
              <ng-container *ngIf="!(item.name == supplementGroupName || item.isCollapsed)">
                <div class="retrieving" *ngIf="isUserloggedIn && record.prices == 0"><i class="pi pi-spin pi-spinner"></i> Retrieving prices..</div>
                <div class="cancelled" style="display: none"><i class="pi pi-ban"></i> Request cancelled</div>
                <table *ngIf="record.prices && record.prices" style="width: 80%; float: right">
                  <tr *ngFor="let price of record.prices">
                    <td>{{ price.priceScheduleDescription }}:</td>
                    <td style="text-align: right; padding-right: 10px">
                      {{ price.taxedUnitPrice ? (price.taxedUnitPrice.toFixed(2) | currency : price.currency : 'symbol-narrow') : 'N/A' }}
                    </td>
                  </tr>
                </table>
              </ng-container>
            </td>

            <td style="width: 100px" class="product-image" [rowSpan]="record.isBundle || record.isKit ? '2' : '1'">
              <span *ngIf="item.name == supplementGroupName || item.isCollapsed">
                <ng-container *ngIf="record.addtionalProductData.supplementGroupItemName == 'FITTED'">
                  {{ getTotalFitPrice(record) | currency : currencyCode : 'symbol-narrow' }}
                </ng-container>
                <ng-container *ngIf="record.addtionalProductData.supplementGroupItemName == 'PAINTED'">
                  {{ getTotalPaintPrice(record) | currency : currencyCode : 'symbol-narrow' }}
                </ng-container>
                <ng-container
                  *ngIf="
                    !record.addtionalProductData ||
                    (record.addtionalProductData.supplementGroupItemName != 'FITTED' &&
                      record.addtionalProductData.supplementGroupItemName != 'PAINTED')
                  "
                >
                  {{ record.customerTaxedUnitPrice | currency : currencyCode : 'symbol-narrow' }}
                </ng-container>
              </span>
              <div *ngIf="!(item.name == supplementGroupName || item.isCollapsed)" class="product-image-container">
                <img appProductImage src="{{ record.addtionalProductData.richContentUrlThumb }}" alt="Product Image" />
              </div>
            </td>
          </tr>

          <tr [hidden]="(item.isCollapsed || isFilterOut(item, record)) && !record.isSelected" *ngIf="record.isBundle || record.isKit">
            <td colspan="3">
              <span *ngIf="record.isBundle || record.isKit" class="bold">
                {{ getBundleContents(record) }}
              </span>
              <span *ngIf="!(record.isBundle || record.isKit)">&nbsp;</span>
            </td>
          </tr>
          <tr [hidden]="(item.isCollapsed || isFilterOut(item, record)) && !record.isSelected">
            <td colspan="5" class="separator"></td>
          </tr>

          <ng-container *ngTemplateOutlet="recursiveListTmpl; context: { parent: record, group: item }"></ng-container>
        </ng-container>
      </table>
    </p-panel>
  </div>
</div>

<ng-template #recursiveListTmpl let-parent="parent" let-list="parent.associatedItems" let-item="group">
  <ng-container *ngFor="let record of list">
    <tr
      [hidden]="(item.isCollapsed || isFilterOut(item, record)) && !record.isSelected"
      *ngIf="
        record.associatedType != 'Fit' &&
        record.associatedType != 'Paint' &&
        record.associatedType != 'SupplementaryCharge' &&
        record.associatedType != 'Mandatory Bundle' &&
        parent.isSelected
      "
      class="item-row sub-row"
    >
      <td style="width: 80px; padding-top: 5px" [rowSpan]="record.isBundle || record.isKit ? '2' : '1'">
        <table>
          <tr>
            <td>
              <input
                type="checkbox"
                [disabled]="(record.isMandatory && enforceAssociatedItemsRules) || (record.isConflicting && enforceAssociatedItemsRules)"
                [(ngModel)]="record.isSelected"
                (change)="selectProduct(record)"
              />
            </td>
            <td>
              <input
                type="number"
                step="1"
                min="0"
                [disabled]="
                  item.name == supplementGroupName ||
                  !record.isSelected ||
                  (record.isMandatory && enforceAssociatedItemsRules) ||
                  (record.isConflicting && enforceAssociatedItemsRules)
                "
                [(ngModel)]="record.quantity"
                class="order-quantity"
                (change)="updateItemPrices(record)"
              />
            </td>
          </tr>
        </table>
      </td>

      <td>
        <span *ngIf="item.name == supplementGroupName" class="arb-field-label">
          {{ record.addtionalProductData.supplementGroupItemName }}
        </span>
        <span *ngIf="item.name != supplementGroupName" class="arb-field-label has-link" (click)="viewProduct(record.companySKU)">
          {{ record.addtionalProductData.companyProductTitle }}
        </span>
        <br />
        <div *ngIf="!(item.name == supplementGroupName || item.isCollapsed)">
          <span>
            Item:
            <span class="bold">{{ record.companySKU }}</span>
          </span>
          <br />
        </div>
        <div *ngIf="record.associatedItems && record.associatedItems.length" style="padding-top: 5px">
          <ng-container *ngFor="let addOnItem of record.associatedItems; let i = index" style="margin-right: 10px">
            <div
              *ngIf="addOnItem.associatedType == 'Fit' || addOnItem.associatedType == 'Paint' || addOnItem.associatedType == 'SupplementaryCharge'"
            >
              <input
                [disabled]="
                  !record.isSelected ||
                  (enforceAssociatedItemsRules &&
                    record.isMandatory &&
                    record.isSelected &&
                    (addOnItem.associatedType == 'Fit' || addOnItem.associatedType == 'Paint'))
                "
                type="checkbox"
                id="chk_{{ record.companySKU }}_{{ i }}"
                [(ngModel)]="addOnItem.isSelected"
                (change)="supplementItemChange(addOnItem, record)"
              />
              <label class="checkbox-label" for="chk_{{ record.companySKU }}_{{ i }}">
                {{
                  addOnItem.associatedType == 'Fit'
                    ? 'FITTED'
                    : addOnItem.associatedType == 'Paint'
                    ? 'PAINTED'
                    : addOnItem.addtionalProductData.companySKU
                }}
              </label>
            </div>
          </ng-container>
        </div>
        <span
          *ngIf="
            record.associatedType != 'Fit' &&
            record.associatedType != 'Paint' &&
            record.associatedType != 'SupplementaryCharge' &&
            record.associatedType != 'Mandatory Bundle'
          "
          class="associated-item-message"
        >
          {{ record.infoMessage }} To {{ parent.companySKU }}
        </span>
      </td>

      <td class="stock">
        <span *ngIf="item.name == supplementGroupName || item.isCollapsed">
          {{ record.companySKU }}
        </span>
        <ng-container *ngIf="!(item.name == supplementGroupName || item.isCollapsed)">
          <div
            class="retrieving"
            *ngIf="isUserloggedIn && (!record.stocks || (record.stocks.SearchResults && record.stocks.SearchResults.length == 0))"
          >
            <i class="pi pi-spin pi-spinner"></i>Retrieving stocks..
          </div>
          <div class="cancelled" style="display: none"><i class="pi pi-ban"></i>Request cancelled</div>
          <table *ngIf="record.stocks && record.stocks.SearchResults" style="width: 100%">
            <tr *ngFor="let stock of record.stocks.SearchResults">
              <td>{{ stock.branchName ? stock.branchName : stock.branchCode }}:</td>
              <td style="text-align: right; padding-right: 10px" class="{{ stock.quantity > 0 ? 'bold' : '' }}">
                {{ getQuantity(record.stocks, stock) }}
              </td>
            </tr>
          </table>
        </ng-container>
      </td>

      <td style="width: 210px">
        <span *ngIf="item.name == supplementGroupName || item.isCollapsed">
          {{ getShortAvailabilityDescription(record.stocks) }}
        </span>
        <ng-container *ngIf="!(item.name == supplementGroupName || item.isCollapsed)">
          <div class="retrieving" *ngIf="isUserloggedIn && record.prices == 0"><i class="pi pi-spin pi-spinner"></i> Retrieving prices..</div>
          <div class="cancelled" style="display: none"><i class="pi pi-ban"></i> Request cancelled</div>
          <table *ngIf="record.prices && record.prices" style="width: 90%; float: right">
            <tr *ngFor="let price of record.prices">
              <td>{{ price.priceScheduleDescription }}:</td>
              <td style="text-align: right; padding-right: 10px">{{ price.taxedUnitPrice | currency : currencyCode : 'symbol-narrow' }}</td>
            </tr>
          </table>
        </ng-container>
      </td>

      <td style="width: 100px" class="product-image" [rowSpan]="record.isBundle || record.isKit ? '2' : '1'">
        <span *ngIf="(item.name == supplementGroupName || item.isCollapsed) && record.customerTaxedUnitPrice">
          {{ record.customerTaxedUnitPrice | currency : currencyCode : 'symbol-narrow' }}
        </span>
        <img
          *ngIf="!(item.name == supplementGroupName || item.isCollapsed)"
          appProductImage
          src="{{ record.addtionalProductData.richContentUrlThumb }}"
          alt="Product Image"
        />
      </td>
    </tr>

    <tr
      [hidden]="(item.isCollapsed || isFilterOut(item, record)) && !record.isSelected"
      *ngIf="
        record.associatedType != 'Fit' &&
        record.associatedType != 'Paint' &&
        record.associatedType != 'SupplementaryCharge' &&
        parent.isSelected &&
        (record.isBundle || record.isKit)
      "
    >
      <td colspan="3">
        <span *ngIf="record.isBundle || record.isKit" class="bold">
          {{ getBundleContents(record) }}
        </span>
        <span *ngIf="!(record.isBundle || record.isKit)">&nbsp;</span>
      </td>
    </tr>

    <tr
      [hidden]="(item.isCollapsed || isFilterOut(item, record)) && !record.isSelected"
      *ngIf="
        record.associatedType != 'Fit' &&
        record.associatedType != 'Paint' &&
        record.associatedType != 'SupplementaryCharge' &&
        record.associatedType != 'Mandatory Bundle' &&
        parent.isSelected
      "
    >
      <td colspan="5" class="separator"></td>
    </tr>

    <ng-container *ngIf="parent.isSelected">
      <ng-container *ngTemplateOutlet="recursiveListTmpl; context: { parent: record, group: item }"></ng-container>
    </ng-container>
  </ng-container>
</ng-template>
