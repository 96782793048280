<ng-template #content let-c="close" let-d="dismiss">
  <pos-app-spinner [show]="loadingCompleteTask$ | async"></pos-app-spinner>
  <div class="modal-header">
    <div class="text-center">
      <h3 class="pt-3 arb-large-heading">
        COMPLETE TASK
      </h3>
    </div>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="d('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="completeTaskForm">
      <div class="row py-2">
        <label class="col-2">
          TASK OUTCOME
        </label>
        <div class="col">
          <ng-select formControlName="taskOutcome" bindLabel="description">
            <ng-option
              *ngFor="let taskOutCome of taskOutComesList"
              [value]="taskOutCome"
            >
              {{ taskOutCome.description }}
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div class="row py-2">
        <label class="col-2">
          NOTE
        </label>
        <div class="col-10">
          <input
            class="form-control"
            formControlName="note"
            size="100"
            type="text"
          />
        </div>
      </div>
      <div class="row py-2">
        <label class="col-2">
          ADDITIONAL DETAILS
        </label>
        <div class="col">
          <textarea
            formControlName="additionalDetails"
            type="text"
            class="form-control"
          ></textarea>
        </div>
      </div>
      <!-- Button Area -->
      <div class="d-flex justify-content-end my-4">
        <!-- TODO: Enable this button once Marlon has finished making new crm/CreateNoteBasedOnTemplate API -->
        <!-- <button
          *ngIf="isFollowUpTaskPossible"
          type="button"
          class="btn text-white"
          (click)="completeAndGoToNewTask()"
        >
          COMPLETE AND GO TO TASK SCREEN
        </button> -->
        <span class="px-2"></span>
        <button type="submit" class="btn text-white" (click)="completeTask()">
          COMPLETE
        </button>
        <span class="px-2"></span>
        <button type="button" class="btn btn-cancel" (click)="closeModal()">
          CANCEL
        </button>
      </div>
    </form>
  </div>
</ng-template>
