<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h3 class="arb-large-heading" id="modal-basic-title">
      CHECK USER FOR CREDITS
    </h3>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="d('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="userForm">
      <p>You don't have the authority to process a credit</p>
      <p>Please enter the credit credentials</p>
      <div class="row">
        <div class="col-4">
          <label>Username: </label>
        </div>
        <div class="col-8">
          <input class="form-control" formControlName="username" />
        </div>
      </div>
      <div class="row my-2">
        <div class="col-4">
          <label>Password: </label>
        </div>
        <div class="col-8">
          <input
            type="password"
            class="form-control"
            formControlName="password"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-6 text-danger">{{ error }}</div>
        <div class="col-6 d-flex justify-content-end align-items-baseline">
          <button
            class="btn text-white"
            (click)="checkCreditUser()"
            [disabled]="!userForm.valid"
          >
            SUBMIT
          </button>
          <span class="px-1"></span>
          <button class="btn btn-cancel" (click)="cancel()">CANCEL</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
