<div bsModal #postcodeInputModal="bs-modal"
     (onShown)="onShown()"
     class="modal fade"
     tabindex="-1"
     role="dialog"
     aria-labelledby="postcodeInputModal"
     aria-hidden="true"
     [config]="{backdrop: 'static'}">

    <div class="modal-dialog">
        <div class="modal-content">
            <form *ngIf="active" #inputPostcodeForm="ngForm" novalidate (ngSubmit)="save()" class="kt-form kt-form--label-right">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span>Your Postcode</span>
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="close()"></button>
                </div>

                <div class="modal-body">                    
                    <div class="form-group row mb-0">
                        <label class="col-4 col-form-label arb-field-label">
                            Postcode
                        </label>
                        <label class="col">
                            <input #postcodeInput="ngModel"
                                   type="text"
                                   id="postcodeInput"
                                   name="postcodeInput"
                                   class="form-control"
                                   [ngClass]="{'edited':postcode}"
                                   [(ngModel)]="postcode"
                                   pattern="^[0-9]{4}$"
                                   required
                                   maxlength="4">

                            <span *ngIf="!postcodeInput.pristine && (!postcode || postcodeInput.errors?.pattern || !validatePostCode(postcodeInput.value))"
                                  class="form-text text-danger text-left">
                                  Please input a valid postcode
                            </span>
                        </label>
                    </div>
                </div>

                <div class="modal-footer">
                    <button [disabled]="saving"
                            type="button"
                            class="close-button arb-cancel-button"
                            (click)="close()">
                            Cancel
                    </button>

                    <button type="submit"
                            class="save-button arb-ok-button"
                            [disabled]="!inputPostcodeForm.form.valid"
                            [buttonBusy]="saving"
                            [busyText]="'Processing...'">
                        <i class="fa fa-arrow-circle-right"></i>
                        <span>Save</span>
                    </button>
                </div>

            </form>

        </div>
    </div>
</div>
