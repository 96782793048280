<pos-app-spinner [show]="(isUpdating$ | async) || (isLoading$ | async)"></pos-app-spinner>
<div class="card">
  <div class="card-body">
    <app-vehicles-selection
      *ngIf="!selectedVehicle && !preselectedVehicle"
      (submitCar)="vehicleSelected($event)"
      (cancelSelect)="cancel()"
    ></app-vehicles-selection>
    <div *ngIf="selectedVehicle || preselectedVehicle">
      <p>
        {{ getVehicleDescription() }}
      </p>
      <app-customers-vehicles-details-form
        [filteredStateList]="stateList$ | async"
        [lookupLists]="lookupLists$ | async"
        [defaultState]="selectedCustomerState$ | async"
        [defaultCountry]="selectedCustomerCountry$ | async"
        (selectedCountry)="getFilteredStateList($event)"
        (formChanged)="formValue = $event"
        (formIsValid)="formValid = $event"
        (formIsDirty)="formDirtyHandler($event)"
        (submitByEnter)="formValid && addNewVehicle()"
      ></app-customers-vehicles-details-form>
      <div class="pt-2 text-right">
        <button class="btn text-white" [disabled]="!formValid" (click)="addNewVehicle()">SAVE</button>
        <span class="px-2"></span>
        <button class="btn btn-cancel" (click)="cancel()">CANCEL</button>
      </div>
    </div>
  </div>
</div>
