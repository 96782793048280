import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ManageusersPartialState } from '../../+state/manageusers.reducer';
import { Store, select } from '@ngrx/store';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ManagedUser } from '../../+state/user.model';
import { combineLatest, Observable, Subject } from 'rxjs';
import { manageusersQuery } from '../../+state/manageusers.selectors';
import { debounceTime, distinctUntilChanged, filter, tap, takeUntil } from 'rxjs/operators';
import {
  LoadInternalUsers,
  LoadSecurityFunctions,
  ResetList,
  EditUserSecurityFunctions,
  EditEmployeeProfile,
  ResetRegisteredList,
  LoadLookupLists,
} from '../../+state/manageusers.actions';
import { ApiService } from '@pos-app/core-ui';
import { Branch, ManagedUserFilterLists, Printer } from '@pos-app/data';
import { OrdersPartialState } from '../../../orders/+state/orders.reducer';
import { LoadParkedOrdersList } from '../../../orders/+state/orders.actions';

@Component({
  selector: 'app-manageusers-internal',
  templateUrl: './manageusers-internal.component.html',
  styleUrls: ['./manageusers-internal.component.scss'],
})
export class ManageusersInternalComponent implements OnInit, OnDestroy {
  @Input() isManageProfile = false;
  @Input() userId = '';
  unregisteredUserSearch = new FormControl('');
  loadingUnregistered$: Observable<boolean>;
  loadingRegistered$: Observable<boolean>;
  savingSecurityFunctions$: Observable<boolean>;
  unregisteredUsers$: Observable<ManagedUser[]>;
  registeredUsers$: Observable<ManagedUser[]>;
  securityFunctionList$: Observable<string[]>;
  selectedUnregisteredUser: ManagedUser;
  selectedRegisteredUser: ManagedUser;
  show = false;
  userForms: FormGroup[] = [];
  branchList: Branch[];
  printerList: Printer[];
  registeredUserSearch = new FormControl('');
  page = 1;
  pageSize = 10;
  newRegisteredUserId: string;
  lookupLists$: Observable<ManagedUserFilterLists>;
  private unSubscribe$ = new Subject<void>();

  constructor(
    private store: Store<ManageusersPartialState>,
    private orderStore: Store<OrdersPartialState>,
    private fb: FormBuilder,
    private apiService: ApiService
  ) {}

  ngOnInit() {
    this.store.dispatch(new LoadLookupLists());
    this.lookupLists$ = this.store.select(manageusersQuery.getLookupLists);

    combineLatest([this.apiService.getBranchList(), this.apiService.getPrinterList()])
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe(([branchSearch, printerSearch]) => {
        this.branchList = branchSearch.SearchResults;
        this.printerList = printerSearch.SearchResults;
      });

    this.unregisteredUsers$ = this.store.pipe(select(manageusersQuery.getUnregisteredUsers));
    this.loadingUnregistered$ = this.store.pipe(select(manageusersQuery.getIsLoadingUnregistered));
    this.loadingRegistered$ = this.store.pipe(select(manageusersQuery.getIsLoadingRegistered));
    this.registeredUsers$ = this.store.pipe(select(manageusersQuery.getRegisteredUsers));
    this.savingSecurityFunctions$ = this.store.pipe(select(manageusersQuery.getSavingUserSecurityFunctions));
    this.registeredUsers$.pipe(takeUntil(this.unSubscribe$)).subscribe((users) => {
      if (users) {
        let addedUser = null;
        this.userForms = [];
        users.map((user) => {
          const userForm = this.fb.group({
            userName: [user.userName],
            userID: [user.userID],
            email: [user.email],
            branch: [this.getBranchCode(user.branch)],
            validOrderTypes: [{ value: user.validOrderTypes, disabled: this.isManageProfile }],
            printerRole: [user.printer],
            registered: [
              {
                value: user.registered === 'Y' ? true : false,
                disabled: this.isManageProfile,
              },
            ],
            securityFunctions: [user.securityFunctions],
          });

          if (this.newRegisteredUserId && this.newRegisteredUserId === user.userID) {
            addedUser = userForm;
          } else {
            this.userForms.push(userForm);
          }
        });
        // Add new user to the top of array
        if (addedUser) {
          this.userForms.unshift(addedUser);
        }
      }
    });

    this.securityFunctionList$ = this.store.pipe(select(manageusersQuery.getSecurityFunctionList));

    this.store.dispatch(new LoadSecurityFunctions({ type: 'internal' }));
    if (this.userId && this.isManageProfile) {
      this.store.dispatch(
        new LoadInternalUsers({
          userFilter: this.userId,
          fetchRegistered: true,
          isManageProfile: this.isManageProfile,
        })
      );
    }
    this.onChanges();

    this.dispatchParkedOrdersList();
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
    this.store.dispatch(new ResetRegisteredList());
  }

  lostFocus() {
    setTimeout(() => {
      this.show = false;
    }, 300);
  }

  onChanges() {
    this.unregisteredUserSearch.valueChanges
      .pipe(
        debounceTime(250),
        distinctUntilChanged(),
        tap((v) => {
          if (v === '') {
            this.store.dispatch(new ResetList());
          }
        }),
        filter((v) => v.trim() !== ''),
        takeUntil(this.unSubscribe$)
      )
      .subscribe((value) => {
        this.store.dispatch(
          new LoadInternalUsers({
            userFilter: value,
            fetchRegistered: false,
            isManageProfile: this.isManageProfile,
          })
        );
      });
  }

  selectUnregisteredUser(user: ManagedUser) {
    this.selectedUnregisteredUser = user;
    this.unregisteredUserSearch.patchValue(this.selectedUnregisteredUser.userID);
  }

  toggleFunction(i, event) {
    const functionName = event.target.name;
    let userFunctions = this.userForms[i].controls.securityFunctions.value as string[];
    userFunctions = Object.assign([], userFunctions);
    if (event.target.checked) {
      userFunctions.push(functionName);
    } else {
      userFunctions = userFunctions.filter((x) => x !== functionName);
    }
    this.userForms[i].patchValue({ securityFunctions: userFunctions });
    this.userForms[i].markAsDirty();
  }

  save(i) {
    if (this.userForms[i].dirty) {
      const profile = {
        userName: this.userForms[i].controls.userID.value,
        branch: this.getBranchCode(this.userForms[i].controls.branch.value),
        printerRole: this.userForms[i].controls.printerRole.value ? this.userForms[i].controls.printerRole.value : '',
        validOrderTypes: this.userForms[i].controls.validOrderTypes.value,
        registered: this.userForms[i].controls.registered.value,
        securityFunctions: this.userForms[i].controls.securityFunctions.value,
      };

      this.isManageProfile ? this.store.dispatch(new EditEmployeeProfile(profile)) : this.store.dispatch(new EditUserSecurityFunctions(profile));
    }
  }

  registerUser() {
    this.store.dispatch(
      new EditUserSecurityFunctions({
        userName: this.selectedUnregisteredUser.userID,
        branch: this.getBranchCode(this.selectedUnregisteredUser.branch),
        printerRole: this.selectedUnregisteredUser.printer,
        registered: true,
        securityFunctions: this.selectedUnregisteredUser.securityFunctions,
      })
    );
    this.newRegisteredUserId = this.selectedUnregisteredUser.userID;
    this.selectedUnregisteredUser = null;
    this.unregisteredUserSearch.patchValue('');
    this.store.dispatch(new ResetList());
  }

  getBranchCode(branch: string) {
    if (branch === 'NO DEFAULT BRANCH' || !branch) {
      return '';
    }
    return branch.split(' - ')[0];
  }

  hasFunction(i, f) {
    return this.userForms[i].controls.securityFunctions.value.indexOf(f) !== -1;
  }

  hideRow(i) {
    // In pagination
    const isInPage = i >= (this.page - 1) * this.pageSize && i < (this.page - 1) * this.pageSize + this.pageSize;
    if (this.registeredUserSearch.value === '') {
      return !isInPage;
    }
    return (
      this.userForms[i].controls.userName.value.toLowerCase().indexOf(this.registeredUserSearch.value.toLowerCase()) === -1 &&
      this.userForms[i].controls.userID.value.toLowerCase().indexOf(this.registeredUserSearch.value.toLowerCase()) === -1
    );
  }

  searchRegisteredUser() {
    this.page = 1;
    this.store.dispatch(
      new LoadInternalUsers({
        userFilter: this.userId,
        fetchRegistered: true,
        isManageProfile: false,
      })
    );
  }

  fetchAllUsers() {
    this.page = 1;
    this.store.dispatch(
      new LoadInternalUsers({
        userFilter: '',
        fetchRegistered: true,
        isManageProfile: false,
      })
    );
  }

  dispatchParkedOrdersList() {
    this.orderStore.dispatch(new LoadParkedOrdersList());
  }
}
