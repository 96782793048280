import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomersBaseComponent } from './containers/customers-base/customers-base.component';
import { CustomersDetailsComponent } from './containers/customers-details/customers-details.component';
import { CustomersDetailsNewComponent } from './containers/customers-details-new/customers-details-new.component';
import { CustomersVehiclesMaintenanceComponent } from './containers/customers-vehicles-maintenance/customers-vehicles-maintenance.component';
import { CustomersVehiclesDetailsComponent } from './containers/customers-vehicles-details/customers-vehicles-details.component';
import { CustomersVehiclesDetailsNewComponent } from './containers/customers-vehicles-details-new/customers-vehicles-details-new.component';
import { CustomerOrdersComponent } from './containers/customer-orders/customer-orders.component';
import { UnsavedChangesCanDeactivateGuard } from '../../core/guards/candeactivate.guard';
import { CustomersShipToComponent } from './containers/customers-ship-to/customers-ship-to.component';
import { CustomersNotesComponent } from './containers/customers-notes/customers-notes.component';
import { CustomersTaskCalendarComponent } from './containers/customers-task-calendar/customers-task-calendar.component';

const routes: Routes = [
  { path: '', redirectTo: 'search', pathMatch: 'full' },
  {
    path: 'search',
    component: CustomersBaseComponent
  },
  {
    path: 'new',
    component: CustomersBaseComponent,
    children: [
      {
        path: '',
        component: CustomersDetailsNewComponent,
        canDeactivate: [UnsavedChangesCanDeactivateGuard]
      }
    ]
  },
  {
    path: ':id',
    component: CustomersBaseComponent,
    children: [
      { path: '', redirectTo: 'details', pathMatch: 'full' },
      {
        path: 'details',
        component: CustomersDetailsComponent,
        canDeactivate: [UnsavedChangesCanDeactivateGuard]
      },
      {
        path: 'shipTo',
        component: CustomersShipToComponent,
        canDeactivate: [UnsavedChangesCanDeactivateGuard]
      },
      {
        path: 'vehicles',
        component: CustomersVehiclesMaintenanceComponent
      },
      {
        path: 'orders',
        component: CustomerOrdersComponent
      },
      {
        path: 'vehicles/:id',
        component: CustomersVehiclesDetailsComponent,
        canDeactivate: [UnsavedChangesCanDeactivateGuard]
      },
      {
        path: 'add/vehicles',
        component: CustomersVehiclesDetailsNewComponent,
        canDeactivate: [UnsavedChangesCanDeactivateGuard]
      }
    ]
  },
  {
    path: ':id/notes',
    component: CustomersNotesComponent
  },
  {
    path: ':id/taskCalendar',
    component: CustomersTaskCalendarComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomersRoutingModule {}
