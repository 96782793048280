import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';

import { getStateSelectedVehicle } from '../../+store/vehicles';
import { Vehicle } from '../../models/vehicle-data';
import { IOOEOrder, IOOEUserState } from '../../models/userState.model';
import { takeUntil } from 'rxjs/operators';
import { getActiveOrderHeader } from '../../+store/customers/customers.selectors';
import { LoadActiveOrderHeader } from '../../+store/customers/customers.actions';
import { IMainState } from '../../+store';
import { OOESessionService } from '../../../../../../../../libs/core-ui/src/lib/services/ooe-session.service';
import { MessageService } from '@pos-app/core-ui';

@Component({
  selector: 'app-order-status-bar',
  templateUrl: './order-status-bar.component.html',
  styleUrls: ['./order-status-bar.component.scss'],
})
export class OrderStatusBarComponent implements OnInit, OnDestroy {
  public selectedVehicle$: Observable<Vehicle>;
  public activeOrderHeader$: Observable<any>;

  public get isUserloggedIn(): boolean {
    return this.ooeSessionService.loginedSession != null;
  }

  public get hasActiveOrder(): boolean {
    return this.ooeSessionService.hasActiveOrder;
  }

  public get ooeUserState(): IOOEUserState {
    return this.ooeSessionService.getOOEUserState();
  }

  public get openOrder(): IOOEOrder {
    return this.ooeSessionService.openOrder;
  }

  public get orderValue(): string {
    return this.openOrder.orderValue.toFixed(2);
  }


  private componentDestroyed$ = new Subject<void>();

  constructor(private messageService: MessageService, private store: Store<IMainState>, private ooeSessionService: OOESessionService) {}
  public ngOnInit(): void {
    this.activeOrderHeader$ = this.store.select(getActiveOrderHeader);
    this.selectedVehicle$ = this.store.select(getStateSelectedVehicle);

    this.messageService
      .listen('orderUpdated')
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        if (this.ooeSessionService.hasActiveOrder) {
          this.loadOrderHeader();
        }
      });
      
  }

  public ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  public loadOrderHeader(): void {
    if (this.ooeSessionService.hasActiveOrder) {
      this.store.dispatch(
        LoadActiveOrderHeader({
          payload: {
            orderNumber: this.openOrder.orderNumber,
          },
        })
      );
    }
  }

  public hasValidOrderTypes(): boolean {
    const session = JSON.parse(localStorage.getItem('jdeSession'));
    var returnV = true;
    if (!session || !session.ValidOrderTypes || session.ValidOrderTypes.length <= 0) returnV = false;
    return returnV;
  }
}
