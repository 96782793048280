import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import {
  ApiService,
  AttachmentEntityType,
  AttachmentType,
} from '@pos-app/core-ui';
import { ATTACHMENT_TYPE, KEYS } from '@pos-app/data';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pos-app-attachment-files-notes',
  templateUrl: './attachment-files-notes.component.html',
  styleUrls: ['./attachment-files-notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentFilesNotesComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() preloadAttachments: any;
  @Input() entityType: AttachmentEntityType;
  @Input() entityId: string;
  @Output() reloadedAttachments = new EventEmitter<void>();
  @Output() closeActiveModal = new EventEmitter<boolean>();

  public loading$ = new BehaviorSubject<boolean>(false);

  ATTACHMENT_TYPE = ATTACHMENT_TYPE;
  SIZE_LIMIT = 15 * 1024 * 1024; // 15MB

  unSubscribe$ = new Subject<void>();

  allAttachments: any;
  displayNoteGrid: boolean = true;
  noteInEdit = null;
  newAttachmentsNoteForm: FormGroup;
  editorConfig: AngularEditorConfig;
  attachedFile: File;
  userEmail = JSON.parse(localStorage.getItem(KEYS.jdeSession)).UserID;

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private api: ApiService
  ) {
    this.newAttachmentsNoteForm = this.fb.group({
      note: [''],
      link: [
        '',
        // Commented Url validation as requested. Leave it here in case it's needed in the future
        // Validators.pattern(
        //   '^(http[s]?:\\/\\/){0,1}[a-zA-Z0-9\\.\\-\\#\\?]+\\.[a-zA-Z]{2,5}$'
        // )
      ],
      noteType: [''],
    });

    this.editorConfig = {
      editable: true,
      spellcheck: false,
      height: '15rem',
      minHeight: '5rem',
      placeholder: 'Enter note here...',
      translate: 'no',
      defaultParagraphSeparator: 'p',
      toolbarHiddenButtons: [
        ['fontName', 'heading'],
        [
          'fontSize',
          'textColor',
          'backgroundColor',
          'customClasses',
          'link',
          'unlink',
          'insertImage',
          'insertVideo',
          'insertHorizontalRule',
          'toggleEditorMode',
        ],
      ],
    };
  }
  ngOnDestroy(): void {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.preloadAttachments && changes.preloadAttachments.currentValue) {
      this.allAttachments = this.preloadAttachments;
    }
  }

  ngOnInit(): void {
    this.newAttachmentsNoteForm.valueChanges
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((formValue) => {
        if (formValue.noteType === '') {
          this.displayNoteGrid = true;
        } else {
          this.displayNoteGrid = false;
        }
      });
  }

  selectNoteType() {
    this.newAttachmentsNoteForm.patchValue({
      note: '',
      link: '',
    });
  }

  loadAllAttachments() {
    return this.api.getAttachments(this.entityType, `${this.entityId}`).pipe(
      takeUntil(this.unSubscribe$),
      finalize(() => this.loading$.next(false))
    );
  }

  downloadFile(item) {
    this.api.downloadFile(item._id, item.name).subscribe((x) => {});
  }

  saveAttachment() {
    if (this.noteInEdit) {
      this.updateItem({
        id: this.noteInEdit._id,
        data:
          this.newAttachmentsNoteForm.value.noteType === ATTACHMENT_TYPE.note
            ? this.newAttachmentsNoteForm.value.note
            : this.newAttachmentsNoteForm.value.link,
        display: this.noteInEdit.display,
      });
      this.resetNoteForm();
      return;
    }
    if (this.attachedFile) {
      if (this.attachedFile.size > this.SIZE_LIMIT) {
        this.toastr.error('File is too big. Max allowed is 10MB');
      } else {
        this.api
          .uploadAttachment({
            entityType: this.entityType,
            entityId: `${this.entityId.toString()}`,
            type: ATTACHMENT_TYPE.file as AttachmentType,
            file: this.attachedFile,
          })
          .subscribe(
            () => {
              this.attachedFile = null;
              this.resetNoteForm();
              this.reloadAttachments();
              this.toastr.success('File uploaded');
            },
            (e) => {
              this.toastr.error('Upload failed');
            }
          );
      }
    } else {
      this.api
        .uploadAttachment({
          entityType: this.entityType,
          entityId: `${this.entityId.toString()}`,
          type: this.newAttachmentsNoteForm.value.noteType as AttachmentType,
          noteOrLink:
            this.newAttachmentsNoteForm.value.note ||
            this.newAttachmentsNoteForm.value.link,
        })
        .subscribe(
          (x) => {
            this.toastr.success(
              `${
                this.newAttachmentsNoteForm.value.noteType === 'note'
                  ? 'Note'
                  : 'Link'
              } added`
            );
            this.resetNoteForm();
            this.reloadAttachments();
          },
          (e) => {
            this.toastr.error('Action failed');
          }
        );
    }
  }

  private reloadAttachments() {
    this.loadAllAttachments().subscribe((res) => {
      this.allAttachments = res;
      this.reloadedAttachments.emit(this.allAttachments);
    });
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      this.attachedFile = event.target.files.item(0);
    }
  }

  deleteItem(item) {
    this.resetNoteForm();
    this.api.deleteAttachment(item._id, 'D', this.userEmail).subscribe((x) => {
      this.reloadAttachments();
      this.toastr.success('Order note deleted');
    });
  }

  updateItem(data) {
    this.api.updateAttachment(data).subscribe((x) => {
      this.reloadAttachments();
      this.toastr.success('Order note updated');
    });
  }

  editNote(item) {
    this.noteInEdit = item;
    this.newAttachmentsNoteForm.patchValue({
      ...{ [item.type]: item.data },
      noteType: item.type,
    });
  }

  resetNoteForm() {
    this.noteInEdit = null;
    this.newAttachmentsNoteForm.patchValue({
      note: '',
      noteType: '',
      link: '',
    });
  }

  closeModal() {
    this.closeActiveModal.emit(true);
  }

  formatTime(time) {
    // JDE time doesn't put a 0 at the start when less than 10am
    if (parseInt(time.slice(0, 2)) > 24) {
      return ['0' + time.slice(0, 1), ':', time.slice(1, 3)].join('');
    } else {
      return [time.slice(0, 2), ':', time.slice(2, 4)].join('');
    }
  }
}
