import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostListener,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { IProductOrderItem } from '../../models/product-order-item';
import { OOESessionService } from '../../../../../../../../libs/core-ui/src/lib/services/ooe-session.service';

@Component({
  selector: 'app-product-add-to-order',
  templateUrl: './product-add-to-order.component.html',
  styleUrls: ['./product-add-to-order.component.scss'],
})
export class ProductProductAddToOrderComponent implements AfterViewInit {
  @Input() public product: IProductOrderItem;
  @Input() public hasAssociatedItemWarning: boolean;
  @Input() public totalPrice: number;
  @Input() public currencyCode: string;
  @Input() public readOnlyEPCTF: boolean;

  @Output() public addedToOrder = new EventEmitter();
  @Output() public updatedQuantity = new EventEmitter();

  public addToOrderClicked = false;
  public top: any = 75;

  public get isUserloggedIn(): boolean {
    return this.ooeSessionService.loginedSession != null;
  }

  constructor(
    private ooeSessionService: OOESessionService,
    private changeDetectorRef: ChangeDetectorRef,

  ) {}

  public addToOrder(): void {
    if (this.addToOrderClicked) {
      return
    }
    this.addToOrderClicked = true;
    this.addedToOrder.emit();

    setTimeout(() => {
      this.addToOrderClicked = false;
    }, 1000);
  }

  public hasValidOrderTypes(): boolean {
    const session = JSON.parse(localStorage.getItem('jdeSession'));
    var returnV = true;
    if (
      !session ||
      !session.ValidOrderTypes ||
      session.ValidOrderTypes.length <= 0
    )
      returnV = false;
    return returnV;
  }

  public updateQuantity(): void {
    this.updatedQuantity.emit();
  }

  public ngAfterViewInit(): void {
    // adjust top position base on product-summary height
    this.onWindowScroll();
  }

  @HostListener('window:scroll', ['$event'])
  public onWindowScroll(event?: Event) {
    let element = document.getElementById('product-summary-section');
    let summaryHeight = element.clientHeight;
    
    this.top = summaryHeight + 59;
    this.changeDetectorRef.detectChanges();
  }
}
