import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { cloneDeep } from 'lodash';
import { catchError, map, mergeMap, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { CustomerApiService } from '../../services/customer-api.service';
import {
  LoadCustomerVehicles,
  LoadCustomerVehiclesSuccess,
  LoadLookupListsSuccess,
  LoadLookupLists,
  SelectCustomer,
  SelectCustomerSuccess,
  MaintainCustomerVehicleSuccess,
  MaintainCustomerVehicle,
  UpdateOrderHeader,
  UpdateOrderHeaderSuccess,
  LoadActiveOrderHeader,
  LoadActiveOrderHeaderSuccess,
} from './customers.actions';
import { LookupLists } from './customers.state';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { SetUserSelectedVehicle, UseInFilter, ShowUniversalProducts } from '../vehicles';
import { IMainState } from '..';
import { customersQuery, getActiveOrderHeader } from './customers.selectors';

@Injectable()
export class CustomerEffects {
  loadCustomerVehicles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadCustomerVehicles),
      mergeMap((action) => {
        return this.customerService.getCustomerVehicles(action.payload).pipe(
          map((res) => {
            return LoadCustomerVehiclesSuccess({ payload: res });
          }),
          catchError((error) => EMPTY)
        );
      })
    )
  );

  loadLookupLists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadLookupLists),
      switchMap(() => {
        return this.store$.select(customersQuery.getLookupLists).pipe(take(1));
      }),
      switchMap((lookupLists: LookupLists) => {
        if (lookupLists && lookupLists.colourList && lookupLists.countryList && lookupLists.stateList) {
          return of(LoadLookupListsSuccess({ payload: lookupLists }));
        }

        return this.customerService.getLookupLists().pipe(
          map((res: LookupLists) => {
            return LoadLookupListsSuccess({ payload: res });
          }),
          catchError((error) => EMPTY)
        );
      })
    )
  );

  loadAciveOrderHeader$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadActiveOrderHeader),
      mergeMap((action) => {
        return this.customerService.getOrderHeader(action.payload).pipe(
          map((res) => {
            return LoadActiveOrderHeaderSuccess({ payload: res });
          }),
          catchError((error) => EMPTY)
        );
      })
    )
  );

  selectCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SelectCustomer),
      withLatestFrom(this.store$.select(customersQuery.getSelectedCustomer)),
      mergeMap(([action, customerDetail]) => {
        if (action.payload && customerDetail && Number(action.payload) === Number(customerDetail.CustomerNumber)) {
          return of(SelectCustomerSuccess({ payload: customerDetail }));
        }

        return this.customerService.getCustomerDetails(action.payload).pipe(
          map((res) => {
            return SelectCustomerSuccess({ payload: res });
          }),
          catchError((error) => EMPTY)
        );
      })
    )
  );

  maintainCustomerVehicle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MaintainCustomerVehicle),
      mergeMap((action) => {
        return this.customerService.maintainCustomerVehicle(action.payload.newVehicle).pipe(
          mergeMap((result) => {
            switch (action.payload.actionCode) {
              case 'A':
                this.toastrService.success('Vehicle added');
                break;
            }

            return [
              MaintainCustomerVehicleSuccess({ payload: result }),
              UpdateOrderHeader({
                payload: {
                  customerVehicleId: result.CustomerVehicleID,
                  partsVehicleID: result.PartsVehicleID,
                  vehicleDescription: result.ShortDescription,
                },
              }),
              SetUserSelectedVehicle({
                payload: {
                  VehicleID: result.JDEVehicleID,
                  partsVehicleID: result.PartsVehicleID,
                  Make: result.ShortDescription,
                },
              }),
              UseInFilter({ payload: true }),
              ShowUniversalProducts({ payload: true }),
            ];
          }),
          catchError((error) => {
            this.toastrService.error(error);
            return EMPTY;
          })
        );
      })
    )
  );

  updateOrderHeader$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateOrderHeader),
      withLatestFrom(this.store$.select(getActiveOrderHeader)),
      mergeMap(([action, activeOrderHeader]) => {
        const currentOrderHeader = cloneDeep(activeOrderHeader);

        // Modify the currentOrderHeader
        currentOrderHeader.customerVehicleID = Number(action.payload.customerVehicleId);
        currentOrderHeader.partsVehicleID = action.payload.partsVehicleID;
        currentOrderHeader.vehicleDescription = action.payload.vehicleDescription;

        return this.customerService.updateOrderHeader(currentOrderHeader).pipe(
          mergeMap((result) => {
            return [
              UpdateOrderHeaderSuccess({ payload: result }),
            ];
          }),
          catchError((error) => EMPTY)
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private toastrService: ToastrService,
    private store$: Store<IMainState>,
    private customerService: CustomerApiService
  ) {}
}
