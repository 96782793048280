export const ATTACHMENT_TYPE = {
  note: 'note',
  link: 'link',
  file: 'file',
};

export const ATTACHMENT_ENTITY_TYPE = {
  order: 'order',
  feedback: 'feedback',
  warranty: 'warranty',
  crm: 'crm',
  orderLine: 'order_line',
  camperTrailer: 'CamperTrailer',
  serviceAttachment: 'serviceAttachment',
};

export const ATTACHMENT_DISPLAY_TEXT = {
  camperTrailerReportText: 'Generating report, this may take a few moments',
  generatingQuoteText: 'Generating quote please wait...',
  emptyText: '',
};
