import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '@pos-app/core-ui';
import { Vehicle } from '@pos-app/data';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { VehicleDetails } from '../../../../core/models/warranty-form.models';

@Component({
  selector: 'app-warrantyform-vehicle',
  templateUrl: './warrantyform-vehicle.component.html',
  styleUrls: ['./warrantyform-vehicle.component.scss']
})
export class WarrantyformVehicleComponent implements OnInit, OnDestroy {
  @Input() warrantySubmittedNumber;
  @Output() vehicleValue = new EventEmitter<{
    value: VehicleDetails;
    isValid: boolean;
  }>();
  selectedVehicle: Vehicle;
  useVehicleForm = false;
  vehicleForm: FormGroup;
  unSubscribe$ = new Subject<void>();
  accessoriesFittedCharCount: number = 0;
  vehicleConditionCharCount: number = 0;
  selectorVehicleShortDesc: string;

  constructor(private fb: FormBuilder, private api: ApiService) {
    this.vehicleForm = this.fb.group({
      vehicleId: [''],
      vehicleShortDesc: ['', [Validators.required]],
      vehicleSelector: [null, [Validators.required]],
      accessoriesFitted: ['', [Validators.required]],
      businessOrPersonalUseBP: ['B', [Validators.required]],
      currentOdometer: [1, [Validators.required, Validators.min(1)]],
      vehicleCondition: ['', [Validators.required]]
    });
  }

  ngOnInit() {
    this.vehicleValue.emit({
      value: this.vehicleForm.value,
      isValid: this.vehicleForm.valid
    });
    this.vehicleForm.valueChanges
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe(vehicleForm => {
        if (vehicleForm.vehicleShortDesc) {
          this.vehicleForm.controls.vehicleSelector.clearValidators();
          this.vehicleForm.controls.vehicleSelector.updateValueAndValidity({
            emitEvent: false
          });
        }

        if (vehicleForm.vehicleSelector) {
          this.vehicleForm.controls.vehicleShortDesc.clearValidators();
          this.vehicleForm.controls.vehicleShortDesc.updateValueAndValidity({
            emitEvent: false
          });
          if (!this.selectorVehicleShortDesc) {
            this.api
              .fetchVehicleDescription({
                jdeVehicleID: vehicleForm.vehicleId
              })
              .subscribe(res => {
                this.selectorVehicleShortDesc =
                  res.SearchResults[0].vehicleDescription;
              });
          }
        }

        const vehiclePayload = vehicleForm.vehicleShortDesc
          ? { ...vehicleForm, vehicleSelector: null, vehicleId: 0 }
          : { ...vehicleForm, vehicleShortDesc: this.selectorVehicleShortDesc };
        this.vehicleValue.emit({
          value: vehiclePayload,
          isValid: this.vehicleForm.valid
        });
        this.accessoriesFittedCharCount = vehicleForm.accessoriesFitted.length;
        this.vehicleConditionCharCount = vehicleForm.vehicleCondition.length;
      });
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  onSelectVehicle(selectedCar: Vehicle) {
    this.selectedVehicle = selectedCar;
    this.vehicleForm.patchValue({
      vehicleSelector: `${this.selectedVehicle.Make} ${this.selectedVehicle.Model} ${this.selectedVehicle.Year}  ${this.selectedVehicle.BodyType} ${this.selectedVehicle.EngineType}`,
      vehicleId: selectedCar.VehicleID
    });
    this.useVehicleForm = true;
  }

  onClearVehicle() {
    this.selectorVehicleShortDesc = null;
    this.selectedVehicle = null;
    this.vehicleForm.patchValue({ vehicleSelector: null });
  }

  onToggleVehicleSelector() {
    this.useVehicleForm = !this.useVehicleForm;
    this.vehicleForm.patchValue({ vehicleSelector: null });
  }
}
