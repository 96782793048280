import { ICellRenderer, ICellRendererParams } from 'ag-grid-community';
import { Component } from '@angular/core';
import { StagingOrderLine } from '../../+state/orders.models';
import { ORDER_ITEM_NUMBER } from '@pos-app/data';
import { OrderGridComponent } from '../order-grid/order-grid.component';

@Component({
  template: `
    <div class="font-weight-bold">
      <div *ngIf="isShowLabel else linkTemplate">
        {{ data?.itemNumber }}
      </div>
      <ng-template #linkTemplate>
        <a *ngIf="data.itemNumber === ORDER_ITEM_NUMBER.buyin" class="link" (click)="showBuyIn()">
          {{ data.itemNumber }}
        </a>
        <div *ngIf="data.itemNumber !== ORDER_ITEM_NUMBER.buyin" class="link">
          <span *ngIf="data.parentLineNumber"> ✅ </span>
          <span *ngIf="!showEPC">{{ data.itemNumber }}</span>
          <a *ngIf="showEPC" href="/epc/product/{{ data.itemNumber }}" target="_blank">{{ data.itemNumber }}</a>
        </div>
      </ng-template>
    </div>
  `,
})
export class OrderItemNumberCellRendererComponent implements ICellRenderer {
  public data: StagingOrderLine;
  public showEPC: boolean;
  public parentComponent: OrderGridComponent;

  public readonly ORDER_ITEM_NUMBER = ORDER_ITEM_NUMBER;

  get isShowLabel(): boolean {
    return this.data?.itemNumber === ORDER_ITEM_NUMBER.subTotal || !this.data?.orderNumber || this.data?.nonStockLineYN === 'Y';
  }

  public agInit(params: ICellRendererParams): void {
    this.data = params.node.data;
    this.parentComponent = params.context.componentParent;
    this.showEPC = params['showEPC'];
  }

  public refresh(params: any): boolean {
    this.data = params.node.data;
    return true;
  }

  public showBuyIn(): void {
    this.parentComponent.editingBuyin = this.data;
  }
}
