<div class="background">
  <div class="background-overlay">
    <div class="row py-4 mg-top ">
      <div class="col-xs-1 col-md-2"></div>
      <div class="col-xs-10 col-md-8">
        <div>
          <div  *ngIf="isRedirect"  class="card">
            <div class="card-header text-white text-center">
              <h1 class="title-heading">WE HAVE MOVED</h1>
            </div>
            <div class="card-body text-white">
              <p class="text-center">
                Please update your bookmark to the new site address: {{redirectURL}}
              </p>
          </div>
        </div>
        <div>
        <div *ngIf="!isRedirect" class="card">
          <div class="card-header text-white text-center">
            <h1 class="title-heading">ONLINE LOGIN</h1>
          </div>
          <div class="card-body text-white">
            <p class="text-center">
              Login here to allow raising of quotes and orders along with more
              accurate pricing and availability of stock. Employees should login
              with their network username and external parties should use their
              email address. Companies that raise orders should contact their
              sales rep to have an Online account setup.
            </p>
  
            <form
              [formGroup]="loginForm"
              class="login-container"
              (ngSubmit)="login()"
            >
              <div class="formGroup pb-2 row">
                <div class="col-4">
                  <label
                    >USERNAME / EMAIL<span class="text-danger">*</span></label
                  >
                </div>
                <div class="col-8">
                  <input
                    type="username"
                    class="form-control"
                    placeholder="Username"
                    formControlName="username"
                    autofocus
                  />
                </div>
              </div>
              <div
                *ngIf="
                  loginForm.controls.username.touched &&
                  loginForm.controls.username.invalid
                "
                class="text-white row"
              >
                <div class="offset-4 col-8">
                  Username is required.
                </div>
              </div>
              <div class="formGroup py-2 row">
                <div class="col-4">
                  <label>PASSWORD<span class="text-danger">*</span></label>
                </div>
                <div class="col-8">
                  <input
                    type="password"
                    class="form-control"
                    placeholder="Password"
                    formControlName="password"
                  />
                </div>
              </div>
              <div class="text-white row">
                <div *ngIf="isCaps" class="offset-4 col-8">
                  CapsLock on
                </div>
              </div>
              <div
                *ngIf="
                  loginForm.controls.password.touched &&
                  loginForm.controls.password.invalid
                "
                class="text-white row"
              >
                <div class="offset-4 col-8">
                  Password is required.
                </div>
              </div>
              <div class="formGroup">
                <div class="row pt-4">
                  <div class="offset-4 col-8 text-center">
                    <a
                      class="text-white font-weight-bold"
                      [routerLink]="['/pos/forgot-password']"
                      >I'VE FORGOTTEN MY PASSWORD</a
                    >
                  </div>
                </div>
                <div class="row pt-3">
                  <div
                    class="offset-lg-4 col-lg-8 offset-3 col-9 text-lg-center d-flex justify-content-end"
                  >
                    <button
                      type="submit"
                      class="btn text-white"
                      [disabled]="!loginForm.valid || loading"
                    >
                      <pos-app-spinner2 *ngIf="loading"></pos-app-spinner2>
                      LOGIN
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      </div>
      <div class="col-xs-1 col-md-2 col-xl"></div>
    </div>
  </div>

</div>

<app-logintoshow
  [userProfile]="userProfile"
  (loggedInShow)="redirectAfterLogin()"
  *ngIf="userProfile"
></app-logintoshow>
