<pos-app-spinner [show]="loading$ | async"></pos-app-spinner>
<div class="pt-2">
  <div class="row py-2">
    <h3 class="text-center arb-large-heading">ASSIGN ORDER TO FITTER</h3>
  </div>
  <div
    class="row border border-3 border-start-0 border-end-0 border-dark py-3 my-1"
  >
    <app-fitter-filters
      [defaultBranch]="(defaultBranch$ | async)?.BranchCode"
      [currentDate]="currentDate"
      [weekList]="weekList"
      [branchList]="branchList"
      (fitterFiltersFormData)="fetchResourceListAndOrderForBooking($event)"
      (fitterFiltersFormDataFromTransactionNo)="
        fetchOrderForBookingAndResourceList($event)
      "
    ></app-fitter-filters>
  </div>
  <div
    class="row px-2 flex-nowrap fitter-row-position position-relative"
    *ngIf="!!resourceList && resourceList.length > 0"
  >
    <div
      cdk-scrollable
      class="shadow-sm sidebar scroll-container h-500 mb-4 col-auto px-0 py-5 align-items-center border border-3 border-start-0 border-top-0 border-bottom-0 border-dark order-for-booking-sidebar bg-color-offwhite"
    >
      <app-orders-for-booking
        [unAssignedOrdersList]="unAssignedOrdersList"
        [dragDropUniqueIds]="dragDropUniqueIds"
        (renameGroupName)="updateGroupName($event)"
        (splitOrder)="getSplitOrder($event)"
        (consolidateOrder)="getConsolidateOrder($event)"
        (arrayOfLinesdropEvent)="reassignLine($event)"
        (arrayOfLineParentOrderData)="setArrayOfLineParentOrderData($event)"
        (orderUnassignedEvent)="changeOrderForBooking($event)"
      ></app-orders-for-booking>
    </div>

    <div
      *ngIf="!!assignedOrders && assignedOrders.size > 0"
      class="col-9 shadow-sm col-md-8 col-sm-6 py-5 px-0 w-80"
    >
      <app-fitters
        [resourceList]="resourceList"
        [dragDropOrdersIdsList]="dragDropUniqueIds.get('ordersForBooking')"
        [assignedOrders]="assignedOrders"
        (changeOrderForBookingEvent)="changeOrderForBooking($event)"
        (renameGroupName)="updateGroupName($event)"
        (invokeResourceFuzzySearch)="loadResourceAvailability($event)"
      ></app-fitters>
    </div>
  </div>
</div>
