<div>
  <div class="modal-header modal-header-text text-center">
    <h2 class="arb-medium-heading" id="alertModalLabel">
      {{ title }}
    </h2>
  </div>
  <div class="modal-body">
    <p class="text-center" *ngIf="faIconClasses">
      <i class="fa fa-10x" [ngClass]="faIconClasses"></i>
    </p>
    <p class="alert-message" *ngIf="!bodyIsHtml">{{ body }}</p>
    <p class="alert-message" *ngIf="bodyIsHtml" [innerHTML]="body"></p>
  </div>
  <div class="modal-footer text-center">
    <div>
      <button
        (click)="onOtherButton()"
        class="btn btn-secondary text-white"
        *ngIf="otherButton"
      >
        {{ otherButton.text }}
      </button>
      <button
        *ngIf="NoButton"
        autofocus="autofocus"
        (click)="activeModal.close(false)"
        class="btn text-white ml-2"
      >
        {{ NoButton }}
      </button>
      <button (click)="activeModal.close(true)" class="btn text-white ml-2">
        {{ YesButton || okButton }}
      </button>
    </div>
  </div>
</div>
