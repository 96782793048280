import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerTrailerSearch } from '@pos-app/data';

@Component({
  selector: 'app-customers-trailer-search',
  templateUrl: './customers-trailer-search.component.html',
  styleUrls: ['./customers-trailer-search.component.scss'],
})
export class CustomersTrailerSearchComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  selectCustomerTrailer(customerTrailer: CustomerTrailerSearch) {
    if (customerTrailer) {
      this.router.navigate([
        '/pos/camper-trailer/' + customerTrailer.AssetNumber,
      ]);
    }
  }
}
