<div class="py-4 background">
  <div class="row content">
    <div class="col-xs-1 col-md-2"></div>
    <div class="col-xs-10 col-md-8">
      <div class="card">
        <div class="card-header text-white text-center">
          <h1 class="title-heading">REGISTER AS GUEST</h1>
        </div>
        <div class="card-body text-white">
          <p class="text-center">
            Please ensure your email address, name and location into the fields
            below to create a guest account with ARB.
            <br />
            You will be sent an email with a temporary password that you can
            change when you login.
          </p>
          <p class="font-weight-bold text-center">
            If you are an existing ARB account customer please contact your ARB
            sales rep to have your login access setup
          </p>
          <form
            [formGroup]="form"
            class="login-container"
            (ngSubmit)="submit()"
          >
            <div class="row formGroup py-2">
              <div class="col-4">
                <label for="email"
                  >EMAIL<span class="text-danger">*</span></label
                >
              </div>
              <div class="col-8">
                <input
                  type="email"
                  class="form-control "
                  placeholder="Email"
                  formControlName="email"
                />
              </div>
            </div>
            <div
              *ngIf="
                form.controls['email'].touched && form.controls['email'].errors
              "
              class="row text-white"
            >
              <div class="offset-4 col-8">
                Valid email address is required.
              </div>
            </div>
            <div class="formGroup py-2 row">
              <div class="col-4">
                <label for="email"
                  >NAME<span class="text-danger">*</span></label
                >
              </div>
              <div class="col-8">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Name"
                  formControlName="name"
                />
              </div>
            </div>
            <div
              *ngIf="
                form.controls['name'].touched && form.controls['name'].errors
              "
              class="text-white row"
            >
              <div class="offset-4 col-8">
                Name is required.
              </div>
            </div>
            <div class="formGroup py-2 row">
              <div class="col-4">
                <label for="country"
                  >COUNTRY<span class="text-danger">*</span></label
                >
              </div>
              <div class="col-8">
                <ng-select
                  [items]="countryList$ | async"
                  formControlName="country"
                  bindLabel="CountryDescription"
                  bindValue="CountryCode"
                >
                </ng-select>
              </div>
            </div>
            <div
              *ngIf="
                form.controls['country'].touched &&
                form.controls['country'].errors
              "
              class="text-white row"
            >
              <div class="offset-4 col-8">
                Country is required.
              </div>
            </div>
            <div class="formGroup py-2 row">
              <div class="col-8" *ngIf="form.get('country').value === 'AU'">
                <label for="postcode"
                  >POSTCODE (TO PROVIDE ACCURATE PRICING)</label
                >
              </div>
              <div class="col-12" *ngIf="form.get('country').value !== 'AU'">
                <label for="postcode"
                  >PRICING WILL BE SHOWN IN AUSTRLIAN DOLLARS AND DOES NOT
                  INCLUDE SHIPPING OR HANDLING</label
                >
              </div>
              <div class="col-4" *ngIf="form.get('country').value === 'AU'">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Postcode"
                  formControlName="postcode"
                />
              </div>
            </div>
            <div
              *ngIf="
                form.controls['postcode'].touched &&
                form.controls['postcode'].errors?.pattern
              "
              class="text-white row"
            >
              <div class="offset-8 col-4">
                Invalid postcode
              </div>
            </div>
            <div class="formGroup text-right pt-3">
              <span>
                <button
                  type="submit"
                  class="btn"
                  [disabled]="!form.valid || loading"
                >
                  <pos-app-spinner2 *ngIf="loading"></pos-app-spinner2>
                  REGISTER AS GUEST
                </button>
              </span>
              <button
                type="button"
                class="btn btn-cancel"
                [routerLink]="['/pos/login']"
              >
                CANCEL
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-xs-1 col-md-2 col-xl"></div>
  </div>
</div>
