<pos-app-spinner [show]="isLoadingCustomerVehicles$ | async"></pos-app-spinner>
<div
  class="card"
  *ngIf="
    !isAddingVehicle &&
    !isAddVehicleForCustomer &&
    !isEdittingVehicle && {
      values: customerVehicles$ | async
    } as customerVehicles
  "
>
  <div class="card-body">
    <div class="text-warning" *ngIf="!(isLoadingCustomerVehicles$ | async) && customerVehicles?.values?.length === 0">
      The customer currently has no vehicle. Please add a vehicle to this customer
    </div>
    <div *ngIf="customerVehicles?.values?.length > 0">
      <div class="custom-control custom-checkbox my-1 mr-sm-2 text-right">
        <input type="checkbox" class="custom-control-input" id="customControlInline" [checked]="showRetired" (change)="showRetiredToggle()" />
        <label class="custom-control-label" for="customControlInline"> Show Retired Vehicles </label>
      </div>
      <div class="text-warning" *ngIf="!(hasActiveVehicle$ | async) && !showRetired">
        This customer has no active vehicle. Check the 'Show Retired Vehicles' to see all retired vehicles
      </div>
      <table class="table table-sm table-bordered" *ngIf="(hasActiveVehicle$ | async) || showRetired">
        <thead>
          <tr>
            <th scope="col" class="text-uppercase"><label>Default</label></th>
            <th scope="col" class="text-uppercase">
              <label>Short Description</label>
            </th>
            <th scope="col" class="text-uppercase"><label>Rego</label></th>
            <th scope="col" class="text-uppercase"><label>Colour</label></th>
            <th scope="col" class="text-uppercase">
              <label>Date Added</label>
            </th>
            <th scope="col" *ngIf="showRetired" class="text-uppercase">
              <label>Date Retired</label>
            </th>
            <th scope="col" class="text-uppercase">
              <label>Vehicle Long Description</label>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let vehicle of customerVehicles?.values; let i = index"
            (click)="selectVehicle(vehicle)"
            [ngClass]="(customerVehicles?.values)[i].customerVehicleID === currentCustomerVehicleId ? 'table-primary' : ''"
            [hidden]="!showRetired && (customerVehicles?.values)[i].DateRetired !== ''"
          >
            <td class="text-center">
              <mat-icon *ngIf="vehicle.DefaultFlag === '1'">check</mat-icon>
            </td>
            <td>
              {{ vehicle.ShortDescription
              }}<span class="px-2"
                ><mat-icon
                  (click)="copyToClipBoard(vehicle.customerVehicleID, true)"
                  [matTooltip]="!vehicleCustomerIDCopied || copiedValue !== vehicle.CustomerVehicleID ? 'Copy Customer Vehicle ID' : 'Copied'"
                  >move_to_inbox</mat-icon
                ></span
              >
            </td>
            <td>{{ vehicle.RegoNumber }}</td>
            <td>{{ vehicle.ColourCode }}</td>
            <td>{{ vehicle.DateCreated }}</td>
            <td *ngIf="showRetired">{{ vehicle.DateRetired }}</td>
            <td>
              {{ vehicle.VehicleFullDescription
              }}<span class="px-2"
                ><mat-icon
                  (click)="copyToClipBoard(vehicle.VehicleID)"
                  [matTooltip]="!vehicleIDCopied || copiedValue !== vehicle.VehicleID ? 'Copy Generic Vehicle ID' : 'Copied'"
                  >move_to_inbox</mat-icon
                ></span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="pt-2">
      <div class="row">
        <div class="col-10 text-left">
          <button class="btn btn-secondary text-white" (click)="addVehicle()">ADD VEHICLE</button>
          <span *ngIf="selectedVehicle">
            <span class="px-2"><button class="btn btn-secondary text-white" (click)="editVehicle()">EDIT VEHICLE</button></span>
            <span *ngIf="{ values: defaultVehicle$ | async } as defaultVehicle">
              <span
                class="pr-2"
                *ngIf="defaultVehicle?.values?.CustomerVehicleID !== selectedVehicle.customerVehicleID && selectedVehicle.DateRetired === ''"
              >
                <button class="btn btn-secondary text-white px-2" (click)="makeDefault(defaultVehicle.values)">MAKE VEHICLE DEFAULT</button>
              </span>
            </span>
            <button
              type="button"
              class="btn btn-secondary text-white mt-xl-0 mt-1"
              placement="bottom"
              [ngbPopover]="retireCode"
              popoverTitle="Please select the retire code"
              [autoClose]="'outside'"
              #popOver="ngbPopover"
              *ngIf="selectedVehicle.DateRetired === ''"
            >
              RETIRE VEHICLE
            </button>
            <button class="btn btn-secondary text-white mt-lg-0 mt-1" *ngIf="selectedVehicle.DateRetired !== ''" (click)="unRetire()">
              UN-RETIRE VEHICLE
            </button>
          </span>
        </div>
        <div class="col-2 actions">
          <button class="btn btn-cancel mr-1" *ngIf="!isEdittingVehicle && !isAddingVehicle" (click)="closeModal()">CANCEL</button>
          <button class="btn text-white" [disabled]="!selectedVehicle" (click)="submit()">SELECT</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isAddingVehicle">
  <app-customers-vehicles-details-new
    [currentCustomer]="selectedCustomerNumber"
    [preselectedVehicle]="preselectedVehicle"
    (vehicleAdded)="edittedVehicle($event)"
    (cancelAdd)="cancel()"
  ></app-customers-vehicles-details-new>
</div>

<div *ngIf="isEdittingVehicle">
  <app-customers-vehicles-details
    [currentCustomer]="selectedCustomerNumber"
    [currentCustomerVehicle]="selectedVehicle.customerVehicleID"
    (vehicleEditted)="edittedVehicle($event)"
    (cancelEdit)="cancel()"
  ></app-customers-vehicles-details>
</div>

<ng-template #retireCode>
  <select [formControl]="retireCodeForm">
    <option *ngFor="let reason of retiredReasons$ | async" [value]="reason.VehicleRetiredReasonCode">
      {{ reason.VehicleRetiredReasonCode + ' - ' + reason.VehicleRetiredReasonDescription }}
    </option>
  </select>
  <div class="pt-1">
    <button class="btn btn-success btn-sm" (click)="retire()">OK</button>
  </div>
</ng-template>
