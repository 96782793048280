import { Component } from '@angular/core';
import { CoreUiPartialState, getColourList } from '@pos-app/core-ui';
import { Observable } from 'rxjs';
import { Colour, ORDER_LINE_STATUS } from '@pos-app/data';
import { Store } from '@ngrx/store';
import { OrderGridComponent } from '../order-grid/order-grid.component';
import { ICellRenderer, ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'colour-cell',
  template: `
    <div *ngIf="!hideList()" class="colour-list">
      <ng-select
        [(ngModel)]="selectedColour"
        (change)="onChange($event)"
        appendTo="body"
        placeholder="UNPAINTED"
        class="order-grid-select"
        [disabled]="
          isCancelledOrInvoiced() || params.data.readOnlyLineYN === 'Y' || params['isReadOnly']
        "
        [clearable]="params.data.mandatoryPaintYN === 'N' || !enforceItemRule"
      >
        <ng-option
          *ngFor="let colour of colourList$ | async"
          [value]="colour.ColoursDescription"
        >
          <span class="font-size-14">{{ colour.ColoursDescription }}</span>
        </ng-option>
      </ng-select>
    </div>
  `,
  styles: [`
    :host::ng-deep {
      .ng-select.ng-select-single .ng-select-container {
        height: auto !important;
        font-size: 14px !important;
      }
    }

  `]
})
export class ColourCellComponent implements ICellRenderer {
  public params: ICellRendererParams;
  public parentComponent: OrderGridComponent;
  colourList$: Observable<Colour[]>;
  selectedColour: string;
  enforceItemRule: boolean;

  constructor(private store: Store<CoreUiPartialState>) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.parentComponent = this.params.context.componentParent;
    this.colourList$ = this.store.select(getColourList);
    this.selectedColour =
      params.data.paintYN === 'N'
        ? null
        : params.data.colourCode === ' '
        ? 'TBA TO BE ADVISED'
        : params.data.colourCode;
    this.enforceItemRule = this.parentComponent.viewSettings.enforceItemRule;
  }

  public onChange(colourCode) {
    if (!colourCode) {
      colourCode = ' ';
    }
    this.parentComponent.changeColourOption(this.params.node, colourCode);
  }

  public hideList() {
    return (
      !this.params.data.orderNumber ||
      this.params.data.parentLineNumber !== 0 ||
      this.params.data.paintableYN !== 'Y'
    );
  }

  public isCancelledOrInvoiced() {
    return (
      this.params.data.lineStatus === ORDER_LINE_STATUS.invoiced ||
      this.params.data.lineStatus === ORDER_LINE_STATUS.cancelled ||
      this.params.data.changeLineStatus === ORDER_LINE_STATUS.cancelled
    );
  }

  refresh(): boolean {
    return false;
  }
}
