import { Component, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CustomersPartialState } from '../../+state/customers.reducer';
import { CreateRetailCustomer, CheckDuplicateCustomer, SelectCountry, UpdateRetailCustomer, CheckDuplicateCustomerSuccess } from '../../+state/customers.actions';
import { hasUnsavedChanges, clearUnsavedChanges, ApiService, CoreUiPartialState, getBrandDefaults } from '@pos-app/core-ui';
import { customersQuery } from '../../+state/customers.selectors';
import { takeUntil } from 'rxjs/operators';
import { Customer } from '@pos-app/data';

@Component({
  selector: 'app-customers-details-new',
  templateUrl: './customers-details-new.component.html',
  styleUrls: ['./customers-details-new.component.scss'],
})
export class CustomersDetailsNewComponent implements OnDestroy {
  @Input() public isForNewOrder = false;
  @Input() public marketingOptInYNFromOrder: boolean;

  @Output() public newCustomer = new EventEmitter<string>();
  @Output() public updatedCustomer = new EventEmitter<string>();

  public customerDetailsForm: FormGroup;
  public formValid = false;
  public formDirty = false;
  public formValue = null;
  public checkingDuplicate = false;
  public newCustomerDetails: any;
  public isCreatingCustomerForOrder: boolean;
  public customerSelected: any;

  public unSubscribe$ = new Subject<void>();
  public isCreatingCustomer$ = this.store.select(customersQuery.getIsCreatingCustomer);
  public lookupLists$ = this.store.select(customersQuery.getLookupLists);
  public duplicateCustomers$ = this.store.select(customersQuery.getDuplicateCustomers);
  public filteredStateList$ = this.store.select(customersQuery.getFilteredStates);
  public brandDefaults$ = this.coreUIStore.select(getBrandDefaults);

  constructor(
    private router: Router,
    private store: Store<CustomersPartialState>,
    private coreUIStore: Store<CoreUiPartialState>,
    private apiService: ApiService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  public ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  public formDirtyHandler(dirty) {
    this.formDirty = dirty;
    if (dirty && !this.isForNewOrder) {
      this.store.dispatch(hasUnsavedChanges({ unsavedChanges: true }));
      this.store.dispatch(hasUnsavedChanges({ unsavedChanges: true }));
    }
  }

  public checkDuplicate() {
    if (!this.customerSelected) {
      this.checkingDuplicate = true;
      this.newCustomerDetails = {
        fullName: '',
        firstName: this.formValue.firstName,
        lastName: this.formValue.surname,
        phoneNumber: this.formValue.phone,
        address1: this.formValue.addressLine1,
        email: this.formValue.email,
      };
      this.store.dispatch(new CheckDuplicateCustomer(this.newCustomerDetails));
    } else {
      this.updateRetailCustomer();
      this.customerChoosen(this.formValue.customerNumber);
    }
  }
  public updateRetailCustomer() {
    this.formValue = {
      ...this.formValue,
      marketingOptInYN: this.formValue.marketingOptInYN === '' ? '' : this.formValue.marketingOptInYN ? 'Y' : 'N',
    };
    this.store.dispatch(new UpdateRetailCustomer(this.formValue));
    if (this.isForNewOrder) {
      setTimeout(() => {
        this.updatedCustomer.emit(this.formValue.customerNumber);
      }, 2000);
    }
  }
  public newCustomerEvent(value: boolean) {
    this.customerSelected = value;
  }

  public customerChoosen(customer: Customer) {
    if (customer) {
      this.store.dispatch(clearUnsavedChanges({ unsavedChanges: false }));
      if (this.isForNewOrder) {
        const customerNumber = customer.CustomerNumber ? customer.CustomerNumber : customer;
        this.newCustomer.emit(customerNumber.toString());
      } else {
        this.router.navigate(['/pos/customers/' + customer.CustomerNumber]);
      }
    } else {
      this.createRetail();
    }
  }

  public createRetail() {
    if (this.formValue) {
      if (this.isForNewOrder) {
        this.isCreatingCustomerForOrder = true;
        this.changeDetectorRef.detectChanges();
        this.apiService
          .createRetailCustomer(this.formValue)
          .pipe(takeUntil(this.unSubscribe$))
          .subscribe((res) => {
            if (res) {
              setTimeout(() => {
                this.newCustomer.emit(res.CustomerNumber);
                this.isCreatingCustomerForOrder = false;
                this.store.dispatch(new CheckDuplicateCustomerSuccess(null));
                this.changeDetectorRef.detectChanges();
              }, 1000);
            }
          });
      } else {
        this.store.dispatch(new CreateRetailCustomer(this.formValue));
      }
    }
  }

  public cancel() {
    if (this.isForNewOrder) {
      this.newCustomer.emit(null);
    } else {
      this.router.navigate(['pos/customers/search']);
    }
  }

  public getFilteredStateList(countryCode) {
    this.store.dispatch(new SelectCountry(countryCode));
  }
}
