import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { RequestVehicleSelection, SetPartsVehicleID } from '../../+store/vehicles';
import { Store } from '@ngrx/store';
import { IMainState } from '../../+store';
import { ProductApplicationItem } from '../../models/service-proxies';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Vehicle } from '../../models/vehicle-data';

@Component({
  selector: 'app-product-applications',
  templateUrl: './product-applications.component.html',
  styleUrls: ['./product-applications.component.scss'],
})
export class ProductProductApplicationsComponent {
  @ViewChild('vehicleLabelSearch') public vehicleLabelSearch: ElementRef;

  @Input() public applications: ProductApplicationItem[] = [];
  @Input() public selectedVehicleId: any;

  public otherVehicles: any[] = [];
  public otherVehiclesCollapsed: boolean = true;

  constructor(private modalService: NgbModal, private confirmationService: ConfirmationService, private store: Store<IMainState>) {}

  public getSelectedApplication(): any[] {
    let returnRows = [];
    let mainRow = this.applications?.find((app) => app.paRtsVehicleID == this.selectedVehicleId);
    if (mainRow) {
      returnRows.push(mainRow);
      returnRows.push({ ...mainRow, isPairRow: true });
    }

    return returnRows;
  }

  public getOtherApplications(): any[] {
    this.otherVehicles = [];
    this.applications.forEach((app) => {
      if (app.paRtsVehicleID != this.selectedVehicleId) {
        this.otherVehicles.push(app);
        this.otherVehicles.push({ ...app, isPairRow: true });
      }
    });

    return this.otherVehicles;
  }

  public updateSelectedVehicle(vehicle: ProductApplicationItem, selectVehicleContent): void {
    let confirmMsg =
      this.selectedVehicleId && this.selectedVehicleId != '' && Number(this.selectedVehicleId) > 0
        ? 'You are going to change your vehicle selection. Are you sure to continue?'
        : 'You are going to make this vehicle your selected vehicle. Are you sure to continue';

    this.confirmationService.confirm({
      message: confirmMsg,
      key: 'selectVehicleConfirmation',
      accept: () => {
        this.store.dispatch(SetPartsVehicleID({ payload: vehicle.paRtsVehicleID }));
        this.showVehicleSelectionModal(selectVehicleContent);
      },
    });
  }

  public showVehicleSelectionModal(content): void {
    this.modalService.open(content, {
      windowClass: 'vehicle-selection-modal',
      size: 'xl',
    });
  }

  public onSelectVehicle(vehicle: Vehicle): void {
    this.store.dispatch(
      RequestVehicleSelection({
        payload: vehicle,
      })
    );
  }
}
