import { Component, OnInit, OnDestroy } from '@angular/core';

import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { MessageService } from '@pos-app/core-ui';
import { LayoutService } from 'libs/core-ui/src/lib/services/layout.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit, OnDestroy {
  private unSubscribe$ = new Subject<void>();

  constructor(private route: ActivatedRoute, private layoutService: LayoutService, private router: Router, private messageService: MessageService) {}

  public routerUrl: string;
  public isLeftSideOpened = true;
  public hideLeftSideByClick = false;
  public isComponentHidden$ = this.layoutService.getIsComponentHidden();

  public ngOnInit(): void {
    this.layoutService.getIsComponentHidden().subscribe((data) => {
      console.log('Final-component', data);
    });
    if (this.router.url.toLowerCase().startsWith('/epc/product')) {
      this.isLeftSideOpened = false;
    } else {
      if (!this.hideLeftSideByClick) {
        this.isLeftSideOpened = true;
      }
    }

    this.router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        if (e.url.toLowerCase().startsWith('/epc/product')) {
          this.isLeftSideOpened = false;
        } else {
          if (!this.hideLeftSideByClick) {
            this.isLeftSideOpened = true;
          }
        }
      } else if (e instanceof NavigationEnd) {
        if (!this.router.url || this.router.url === '/epc') {
          this.messageService.trigger('landingPageAccessed');
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  public toggleLeftSide(): void {
    this.isLeftSideOpened = !this.isLeftSideOpened;
    if (!this.isLeftSideOpened) {
      this.hideLeftSideByClick = true;
    } else {
      this.hideLeftSideByClick = false;
    }
  }

  public onSelectCategory(selectedCedCategory): void {
    let id = selectedCedCategory.cedCategoryId;

    if (selectedCedCategory?.isRoot) {
      this.router.navigate(['.'], {
        relativeTo: this.route,
        queryParams: { id: id },
      });
    } else {
      this.router.navigate(['category', id], { relativeTo: this.route });
    }
  }
}
