import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { cloneDeep, sumBy, uniq } from 'lodash';
import {
  ApiService,
  CoreUiPartialState,
  getOrderTypes,
  loadColourList,
  hasActiveOrder,
  isExternalUser,
  getUserId,
  DialogService,
  getCashDrawer,
  setSelectedVehicle,
  DrawerService,
  getSecurityFunctions,
  getBranch,
  getNonCorpOrderTypes,
  getUserRole,
  getShowLocation,
  getDisplayOptions,
  getShowCodes,
  getBrandDefaults,
  hasIncompleteOrder,
  resetAllFlags,
  getHasPendingPayments,
  hasOrderChanged,
  ConfigService,
  setOpenOrder,
  ElectronService,
  allowFakeEFTPOSTF,
} from '@pos-app/core-ui';
import { Subject, Observable, combineLatest, of, BehaviorSubject } from 'rxjs';
import { OrdersPartialState } from '../../+state/orders.reducer';
import {
  LoadOrderHeader,
  LoadStagingOrder,
  UpdateStagingOrderLine,
  ValidateOrder,
  ResetOrderValidation,
  RevalidateOrder,
  ConvertLines,
  UpdateAndValidate,
  CreateCredit,
  CopyOrder,
  FinaliseOrder,
  AddItemsToOrder,
  FetchLoadedGiftCards,
  LoadParkedOrdersList,
  UpdateViewSettings,
  FetchPaymentInfo,
  UpdateStagingOrderAllLine,
  CancelOrder,
  CleanStagingOrder,
} from '../../+state/orders.actions';
import { ordersQuery } from '../../+state/orders.selectors';
import {
  OrderHeader,
  StagingOrderLine,
  StockAllocation,
  PaymentType,
  Payment,
  GiftCardLoadup,
  DistributeOutput,
  OverrideData,
  ViewSettings,
  ItemToAdd,
} from '../../+state/orders.models';
import {
  Branch,
  OrderType,
  ORDER_ITEM_NUMBER,
  Printer,
  CustomerDetails,
  ORDER_PAYMENT_TYPE,
  ORDER_LINE_STATUS,
  ORDER_TYPE,
  CreditReason,
  SECURITY_FUNCTION,
  ORDER_CONFIRMATION,
  NonCorpStore,
  USER_ROLE,
  DisplayOptions,
  InvoiceBranchDetails,
  BrandDefaults,
  CatalogueStatus,
  KEYS,
  LoadedGiftCard,
  ASSOCIATION_TYPE,
  RegisterHost,
  CREDIT_TYPE,
  AllocationArray,
  ATTACHMENT_DISPLAY_TEXT,
} from '@pos-app/data';
import { map, takeUntil, switchMap, take, finalize, catchError, filter, withLatestFrom, concatAll } from 'rxjs/operators';
import { CustomersPartialState } from '../../../customers/+state/customers.reducer';
import { GetAlertMessageAction, LoadLookupLists, SelectCustomerAction, SetSelectedCustomer } from '../../../customers/+state/customers.actions';
import { customersQuery } from '../../../customers/+state/customers.selectors';
import { ManagedUser, ShowUser } from '../../../manageusers/+state/user.model';
import { ToastrService } from 'ngx-toastr';
import { MatStepper } from '@angular/material/stepper';
import { SecureCatalogueService } from 'libs/core-ui/src/lib/services/secure-catelogue.service';
import * as utils from './orders-details.utils';
import { OrdersPaymentComponent } from '../../components/orders-payment/orders-payment.component';
import { OrdersResponseService } from '../../+state/orders.response';
import { MatDialog } from '@angular/material/dialog';
import { OrdersBuyinSupplierDialogComponent } from '../../components/orders-buyin-supplier-dialog/orders-buyin-supplier-dialog.component';
import { Supplier } from '../../models/supplier.model';
import { OrderGridComponent } from '../../components/order-grid/order-grid.component';
import { ProcessCellForExportParams, ProcessHeaderForExportParams, ShouldRowBeSkippedParams } from 'ag-grid-community';
import { OOESessionService } from 'libs/core-ui/src/lib/services/ooe-session.service';

@Component({
  selector: 'app-orders-details',
  templateUrl: './orders-details.component.html',
  styleUrls: ['./orders-details.component.scss'],
})
export class OrdersDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('content') modalBox: any;
  @ViewChild('ordersPaymentComponent') ordersPayment: OrdersPaymentComponent;
  @ViewChild('orderGridComponent') orderGrid: OrderGridComponent;

  hostName: string;
  orderHeader: OrderHeader;
  orderNumber: string;
  branchList: Branch[];
  nonCorpStoreList: NonCorpStore[];
  orderTypeList: OrderType[];
  nonCorpOrderTypeList: OrderType[];
  printerList: Printer[];
  orderDetails: StagingOrderLine[] = [];
  isLoadingPayments: boolean;
  isLoadingHeader: boolean;
  loadedAvailabilityList = [];
  selectedBranch: string;
  userDetails: ManagedUser;
  selectedCustomer: CustomerDetails;
  dispatchList: StagingOrderLine[] = [];
  creditList: StagingOrderLine[] = [];
  stockAllocationList: StockAllocation[] = [];
  isBusy = false;
  allocatedStockList: {
    [key: number]: number[];
  };
  cashDrawer: RegisterHost;
  viewSettings: ViewSettings = {
    enforceItemRule: true,
  };
  isValidAllocation: boolean;
  isMakingPayments = false;
  isSaving = false;
  totalValue: number;
  cancelAllLinesAllowed: boolean;
  toDisableAllocation = false;
  paymentTypes: PaymentType[];
  defaultColour: string;
  giftCardLoadUpList: GiftCardLoadup[] = [];
  orderConfirmation: string;
  hasCashPayment: boolean;
  isConvertToOrder: boolean;
  isCreditOrder: boolean;
  isCheckingCreditUser: boolean;
  userSecurityFunctions: string[];
  creditReasonList: CreditReason[];
  selectedCreditReason: string;
  isMininumPaymentMet: boolean;
  availabilityItem: StagingOrderLine;
  overrideData: OverrideData = {
    overrideBranch: null,
    overrideSalesPerson: null,
    overrideShipTo: null,
    nonCorpStoreSaleYN: null,
  };
  isDepositing: boolean;
  disableWhileEditing: boolean;
  serialNumberInput: string;
  selectedItemNumber: string;
  isSerialNumberOnHand: boolean;
  userRole: string;
  showLocation: string;
  isHeaderValid: boolean;
  hasInvalidItem: boolean;
  hasUpdatingRow: boolean;
  displayOptions: DisplayOptions;
  reloadCustomer: boolean;
  invoiceBranchDetails: InvoiceBranchDetails;
  allowToFinalise = true;
  distributeOutputData: DistributeOutput;
  pendingPayments: Payment[];
  showUsers: ShowUser[];
  currentShowCode: string;
  outstandingBalance: number;
  catalogueStatus: CatalogueStatus;
  loadedGiftCards: LoadedGiftCard[];
  showConvertQuoteMessage: boolean;
  showConvertBuyInPopup: boolean;
  isOrderOnHold: boolean;
  enableValidateOrder: boolean;
  hasPendingPayments: boolean;
  isOrderValidated: boolean;
  brandDefaults: BrandDefaults;
  isCashDrawerOpen: boolean;
  isUpdatingStagingCompleted: boolean;
  totalHistoricalPaymentAmount = 0;
  cashChange: number;
  showTextLoader: boolean = false;
  generateReportLoaderText: string = ATTACHMENT_DISPLAY_TEXT.emptyText;
  refreshDetailGridTF = true;
  allAttachments: any[];

  allowFakeEFTPOSTF$ = this.coreUIStore.select(allowFakeEFTPOSTF);
  orderValidated$ = this.store.select(ordersQuery.getOrderValidated);
  orderMessages$ = this.store.select(ordersQuery.getOrderMessages);
  headerMessages$ = this.store.select(ordersQuery.getHeaderMessages);
  validatingOrder$ = this.store.select(ordersQuery.getValidatingOrder);
  isExternalUser$ = this.coreUIStore.select(isExternalUser);
  loadingCustomerDetails$ = this.customerStore.select(customersQuery.getLoadingCustomerDetails);
  loadingLookupLists$ = this.customerStore.select(customersQuery.getLoadingLookupLists);
  loadingLines$ = this.store.select(ordersQuery.getCountOrderLineLoading).pipe(map((count) => count > 0));
  brandDefaults$ = this.coreUIStore.select(getBrandDefaults).pipe(
    map((res) => {
      this.brandDefaults = res;

      if (res) {
        this.updateViewSettings({ showATP: res?.showATPByDefaultTF });
      }

      return res;
    })
  );
  addItemProcessed$ = this.store.select(ordersQuery.getAddItemProcessed);
  paymentInfo$ = this.store.select(ordersQuery.getPaymentInfo).pipe(
    map((data) => {
      this.cashChange = data?.totals?.cashChange || 0;
      this.isLoadingPayments = false;
      return data;
    })
  );
  depositAmount$ = this.paymentInfo$.pipe(
    filter((data) => !!data),
    map((data) => {
      this.totalHistoricalPaymentAmount = (data.historicalPayments || []).reduce(
        (a, b) => Math.round((a + b.amount + Number.EPSILON) * 100) / 100,
        0
      );
      const totalInvoiced = data.totals.invoicedValue;
      const totalPendingPayment = (data.pendingPayments || []).reduce((a, b) => Math.round((a + b.amount + Number.EPSILON) * 100) / 100, 0);

      this.cancelAllLinesAllowed = this.totalHistoricalPaymentAmount <= totalInvoiced;

      return this.totalHistoricalPaymentAmount + totalPendingPayment - totalInvoiced;
    })
  );

  pendingLoadAmounts$ = this.store.select(ordersQuery.getPendingLoadAmounts);
  orderLoading$ = this.store.select(ordersQuery.getLoadingStagingOrder);

  USER_ROLE = USER_ROLE;
  ORDER_TYPE = ORDER_TYPE;
  CREDIT_TYPE = CREDIT_TYPE;
  SEE_BUY_PRICE = SECURITY_FUNCTION.seeBuyPrice;

  private unSubscribe$ = new Subject<void>();
  public generateQuoteloading$ = new BehaviorSubject<boolean>(false);

  constructor(
    public drawerService: DrawerService,
    public secureCatalogueService: SecureCatalogueService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<OrdersPartialState>,
    private customerStore: Store<CustomersPartialState>,
    private coreUIStore: Store<CoreUiPartialState>,
    private apiService: ApiService,
    private toastr: ToastrService,
    private dialogService: DialogService,
    private modalService: NgbModal,
    private changeDetectorRef: ChangeDetectorRef,
    private configService: ConfigService,
    private location: Location,
    private ordersResponseService: OrdersResponseService,
    private ooeSessionService: OOESessionService,
    private electronService: ElectronService
  ) {
    router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.orderNumber = route.snapshot.params.id;

    route.queryParams.pipe(takeUntil(this.unSubscribe$)).subscribe((params) => {
      this.showConvertQuoteMessage = params?.showConvertQuoteMessage === 'true';
      this.showConvertBuyInPopup = params?.showConvertBuyInPopup === 'true';
    });
  }

  ngOnInit() {
    this.loadLookupLists();
    this.loadOrderHeader();
    this.loadPaymentInfo();
    this.loadGiftCards();
    this.loadParkedOrdersList();

    this.catalogueStatus = JSON.parse(localStorage.getItem(KEYS.catalogueStatus));
    combineLatest([
      this.coreUIStore.select(getCashDrawer),
      this.coreUIStore.select(getOrderTypes),
      this.coreUIStore.select(getNonCorpOrderTypes),
      this.coreUIStore.select(getBranch),
      this.coreUIStore.select(getUserId),
    ])
      .pipe(take(1))
      .subscribe(([cashDrawer, orderTypes, nonCorpOrderTypes, branch, userId]) => {
        const register = JSON.parse(cashDrawer);
        this.cashDrawer = register;
        this.isCashDrawerOpen = this.cashDrawer?.status === 'O';

        if (this.isCashDrawerOpen) {
          this.hostName = this.configService.AppConfig.hostName || (this.electronService.isElectron ? '' : userId);

          this.checkEftPosStatus();
        }

        this.getPaymentTypes();

        this.orderTypeList = orderTypes.filter((type) => type.PaymentYN !== 'Y' || this.cashDrawer);
        this.nonCorpOrderTypeList = nonCorpOrderTypes.filter((type) => type.PaymentYN !== 'Y' || this.cashDrawer);
        this.overrideData.overrideBranch = branch.BranchCode;
      });

    this.customerStore
      .select(customersQuery.getSelectedCustomer)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((res) => {
        this.selectedCustomer = res;
      });

    combineLatest([
      this.coreUIStore.select(getUserId),
      this.coreUIStore.select(getUserRole),
      this.coreUIStore.select(getShowLocation),
      this.coreUIStore.select(getSecurityFunctions),
      this.coreUIStore.select(getDisplayOptions),
      this.coreUIStore.select(getShowCodes),
    ])
      .pipe(
        take(1),
        switchMap(([userId, userRole, showLocation, securityFunctions, displayOptions, showCodes]) => {
          this.userSecurityFunctions = securityFunctions;
          this.userRole = userRole;
          this.showLocation = showLocation;
          this.displayOptions = displayOptions;
          this.currentShowCode = showCodes.length !== 0 ? showCodes[0].showCode : '';

          if (userRole !== USER_ROLE.external && userRole !== USER_ROLE.showExternal) {
            return this.apiService.fetchSingleJDE({
              userFilter: userId,
              fetchRegistered: 'Y',
            });
          } else {
            // external user, userId as the email
            return of({ SearchResults: [{ email: userId, printer: null }] });
          }
        }),
        takeUntil(this.unSubscribe$)
      )
      .subscribe((res) => {
        this.userDetails = res.SearchResults[0];
      });

    combineLatest([
      this.apiService.getCreditReasons(),
      this.apiService.getBranchList(),
      this.apiService.getNonCorpStoresForShow(),
      this.isShowUser() ? this.apiService.getShowPrinterList() : this.apiService.getPrinterList(),
      this.apiService.getColourList(),
      this.isShowUser() ? this.apiService.getShowUsers({ showCode: this.currentShowCode }) : of({ SearchResults: [] }),
    ])
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe(([creditReasonsListResults, branchListResult, nonCorpStoreListResult, printerListResult, colourListResult, showUsers]) => {
        this.creditReasonList = creditReasonsListResults?.SearchResults;
        this.branchList = branchListResult?.SearchResults;
        this.nonCorpStoreList = nonCorpStoreListResult?.SearchResults || [];
        this.printerList = printerListResult?.SearchResults;
        this.coreUIStore.dispatch(loadColourList({ colourList: colourListResult?.SearchResults }));
        this.showUsers = showUsers?.SearchResults;
      });

    this.store
      .select(ordersQuery.getActiveOrderHeader)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((res) => {
        this.isLoadingHeader = false;
        // failed order header call will return an empty array
        if (res?.orderNumber) {
          this.orderHeader = res;

          // Disable NEXT button on allocation screen on load if order type is CO
          if (this.orderHeader.orderType === ORDER_TYPE.creditReturn) {
            this.isSerialNumberOnHand = false;
          }

          this.store.dispatch(
            hasActiveOrder({
              orderNumber: this.orderNumber,
              quickSaveAllowed: this.orderHeader.orderType !== ORDER_TYPE.creditPrice && this.orderHeader.orderType !== ORDER_TYPE.creditReturn,
            })
          );
          this.coreUIStore.dispatch(hasOrderChanged({ isOrderChanged: this.orderHeader.orderChangedYN === 'Y' }));

          this.store.dispatch(
            setOpenOrder({
              openOrder: {
                customerName: this.orderHeader.customerName,
                customerNumber: this.orderHeader.customerNumber,
                customerVehicleID: this.orderHeader.customerVehicleID,
                orderCompany: this.orderHeader.orderCompany,
                orderNumber: this.orderHeader.orderNumber,
                orderReference: this.orderHeader.reference,
                orderType: this.orderHeader.orderType,
                orderValue: null,
                partsVehicleID: this.orderHeader.partsVehicleID,
                pendingPaymentsTF: false,
                readOnlyEPCTF: this.orderHeader.readOnlyYN === 'Y' || this.orderHeader.readOnlyDetailYN === 'Y',
                requestDate: this.orderHeader.requestDate as any,
                userName: '',
                vehicleDescription: this.orderHeader.vehicleDescription,
              },
              quickSaveAllowed: this.orderHeader.orderType !== ORDER_TYPE.creditPrice && this.orderHeader.orderType !== ORDER_TYPE.creditReturn,
            })
          );

          this.isConvertToOrder =
            this.orderHeader.convertToOrderYN === 'Y' &&
            !this.orderHeader.orderNumber.startsWith('T') &&
            (this.isShowUser() ? !!this.cashDrawer : true);

          this.isCreditOrder = [ORDER_TYPE.creditReturn, ORDER_TYPE.creditPrice].includes(this.orderHeader.orderType);
          this.selectedBranch = this.orderHeader.branch;
          this.viewSettings.showInvoiced = this.orderHeader.readOnlyYN === 'Y';
          this.viewSettings.enforceItemRule = this.orderHeader.enforceItemAssociationsYN === 'Y';

          this.updateViewSettings({
            showInvoiced: this.orderHeader.readOnlyYN === 'Y',
            enforceItemRule: this.orderHeader.enforceItemAssociationsYN === 'Y',
          });

          if (this.refreshDetailGridTF) {
            this.customerStore.dispatch(new GetAlertMessageAction({ customerNumber: this.orderHeader.customerNumber }));
            this.customerStore.dispatch(new SelectCustomerAction(this.orderHeader.customerNumber.toString(), this.reloadCustomer));

            this.loadStagingOrder();
            this.loadAllAttachments();
          } else {
            this.refreshDetailGridTF = true;
          }
        }
      });

    /* Load staging order - Load past payments when it's an open order and not a quote order */
    combineLatest([
      this.store.select(ordersQuery.getLoadingStagingOrder).pipe(filter((loading) => !loading)),
      this.store.select(ordersQuery.getCountOrderLineLoading).pipe(filter((count) => count <= 0)),
    ])
      .pipe(takeUntil(this.unSubscribe$), withLatestFrom(this.store.select(ordersQuery.getStagingOrder)))
      .subscribe(([[isLoading, count], orderDetails]) => {
        if (orderDetails) {
          this.hasUpdatingRow = orderDetails.some((item) => item.id && item.updatingRow?.trim() === 'ADD');
          /* add invalid item */
          if (orderDetails.some((item) => item['message'] === 'Item not found')) {
            return;
          }
          this.orderDetails = cloneDeep(orderDetails);
          this.changeDetectorRef.detectChanges();

          // Handle Nonstock items
          this.handleNonstockItems(this.orderDetails);

          // if it's a credit order, get credit reason from the first item (all items have the same reason)
          this.selectedCreditReason =
            this.isCreditOrder && !this.selectedCreditReason
              ? (this.orderDetails[0] && this.orderDetails[0].reasonCode.trim()) || null
              : this.selectedCreditReason;

          this.dispatchList = this.getDispatchList(this.orderDetails);
          this.creditList = this.getCreditList(this.orderDetails);
        }
      });

    this.coreUIStore
      .select(getHasPendingPayments)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((hasPendingPayments) => (this.hasPendingPayments = hasPendingPayments));

    this.store
      .select(ordersQuery.getLoadedGiftCards)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((loadedCards) => {
        this.loadedGiftCards = loadedCards || [];
        this.getGiftCardLoadUpList();
      });

    this.apiService
      .fetchBranchInvoiceDetails()
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((res) => {
        const { ErrorFlag, ErrorMessage, ...details } = res;
        this.invoiceBranchDetails = details as InvoiceBranchDetails;
      });
  }

  ngOnDestroy() {
    this.cleanStagingOrder();

    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  loadLookupLists(): void {
    this.customerStore.dispatch(new LoadLookupLists());
  }

  loadParkedOrdersList(): void {
    this.store.dispatch(new LoadParkedOrdersList());
  }

  loadPaymentInfo(): void {
    this.isLoadingPayments = true;
    this.store.dispatch(new FetchPaymentInfo({ orderNumber: this.orderNumber }));
  }

  loadStagingOrder() {
    this.store.dispatch(new LoadStagingOrder({ orderNumber: this.orderNumber }));
  }

  cleanStagingOrder() {
    this.store.dispatch(new CleanStagingOrder());
  }

  loadOrderHeader() {
    this.isLoadingHeader = true;
    this.store.dispatch(new LoadOrderHeader({ orderNumber: this.orderNumber }));
  }

  loadGiftCards(): void {
    this.store.dispatch(new FetchLoadedGiftCards({ orderNumber: this.orderNumber }));
  }

  loadAllAttachments() {
    this.apiService
      .getAttachments('order', `${this.orderHeader.orderNumber.toString()}|${this.orderHeader.orderType}|${this.orderHeader.orderCompany}`)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((res) => (this.allAttachments = res));
  }

  lineUpdated() {
    this.loadStagingOrder();
    this.store.dispatch(new ResetOrderValidation());
  }

  getPaymentTypes(): void {
    const input = this.isCashDrawerOpen
      ? {
          host: this.hostName,
          register: this.cashDrawer?.registerID,
        }
      : null;

    this.apiService
      .getPaymentTypes(input)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((paymentTypeListResult) => {
        this.paymentTypes = paymentTypeListResult.SearchResults;
      });
  }

  getDispatchList(orderDetails: StagingOrderLine[]): StagingOrderLine[] {
    if (this.orderHeader) {
      if (this.orderHeader.orderType === ORDER_TYPE.saleOrder) {
        return orderDetails.filter((item) => item.changeLineStatus === ORDER_LINE_STATUS.selling);
      }

      if (this.orderHeader.orderType === ORDER_TYPE.quoteOrder) {
        return orderDetails.filter((item) => item.changeLineStatus === ORDER_LINE_STATUS.convertQuote);
      }
      return [];
    }
    return [];
  }

  getCreditList(orderDetails: StagingOrderLine[]): StagingOrderLine[] {
    if (this.orderHeader) {
      return orderDetails.filter((item) => item.changeLineStatus === ORDER_LINE_STATUS.credit);
    }
    return [];
  }

  handleNonstockItems(orderDetails: StagingOrderLine[]) {
    const nonStockOrders = orderDetails.filter((x) => x.itemNumber === 'NONSTOCK');
    if (nonStockOrders?.length > 0 && this.showConvertBuyInPopup && !this.isShowUser()) {
      nonStockOrders.forEach((item) => {
        const modalRef = this.modalService.open(NgbdModalContent, {
          size: 'lg',
          backdrop: 'static',
        });
        modalRef.componentInstance.buyinProduct = item;
        modalRef.componentInstance.defaultMarkup = this.brandDefaults.buyinMarkupPerc;
        modalRef.result.then((x) => {
          if (x) {
            this.loadStagingOrder();
          }
        });
      });
      /* remove query params */
      this.showConvertBuyInPopup = false;
      this.location.go(`/pos/orders/${this.orderNumber}?continueUrl=home&showConvertQuoteMessage=true`);
    }
  }

  updateCell(event: { index: number; data: StagingOrderLine; colKey: string }) {
    const updatedItem = utils.updatedData(event.data);
    const updatedData = [updatedItem];

    this.store.dispatch(new UpdateStagingOrderLine(updatedData));
    this.isUpdatingStagingCompleted = true;
  }

  updateHeaders(headers: { data: any; reloadCustomer: boolean; reloadUserState: boolean }) {
    this.apiService
      .updateOrderHeader(headers.data)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe(
        (res) => {
          if (res) {
            this.reloadCustomer = headers.reloadCustomer;
            this.refreshDetailGridTF = res.refreshDetailGridTF;

            this.store.dispatch(new ResetOrderValidation());

            this.loadOrderHeader();
            if (headers.reloadUserState) {
              this.ooeSessionService.fetchUserState().pipe(takeUntil(this.unSubscribe$)).subscribe();
            }
          }
        },
        (error) => {
          this.toastr.error(error?.ErrorMessage);
          this.loadOrderHeader();
        }
      );
  }

  cancelOrder(orderNumber) {
    if (this.checkPending()) {
      return;
    }

    this.dialogService
      .confirm(`Are you sure you want to cancel ${orderNumber.startsWith('T') ? 'the order' : 'your changes'}?`)
      .pipe(
        switchMap((val) => {
          if (val) {
            return combineLatest([this.apiService.unloadOrder({ orderNumber })]);
          } else {
            return of(null);
          }
        }),
        takeUntil(this.unSubscribe$)
      )
      .subscribe((res) => {
        if (res) {
          this.store.dispatch(resetAllFlags());
          this.store.dispatch(setSelectedVehicle(null));
          this.store.dispatch(new SetSelectedCustomer(null));
          this.store.dispatch(new CancelOrder());
          this.handleRoutingBack();
        }
      });
  }

  handleRoutingBack() {
    const continueUrl = this.route.snapshot.queryParams.continueUrl;
    if (continueUrl === 'home') {
      this.router.navigate([this.secureCatalogueService.getLandingPage()]);
    } else {
      window.history.back();
    }
  }

  validateOrder() {
    this.store.dispatch(new ValidateOrder({ orderNumber: this.orderNumber, finalModeYN: 'N' }));
    this.isOrderValidated = true;
  }

  toggleDisableConfirmButton(state) {
    this.disableWhileEditing = state;
  }

  completeOrder(hasPayment: boolean = false) {
    this.handleSendingOrderToJDE(hasPayment);
  }

  toOpenCashDrawer() {
    if (this.hasCashPayment) {
      this.openCashDrawer();
    }
  }

  handleSendingOrderToJDE(hasPayment: boolean) {
    /* Save order to JDE without payments */
    if (!hasPayment) {
      let warningText = '';
      let warningHeader = '';
      if (this.dispatchList.length > 0) {
        warningText = this.isConvertToOrder
          ? 'Lines selected for order conversion will be lost.'
          : 'Lines will not be sold and the order will remain opened.';
        warningHeader = `WARNING: ${this.isConvertToOrder ? 'LINES SELECTED FOR CONVERSION' : 'DISPATCHED ITEMS'}
          `;
      }
      if (this.creditList.length > 0) {
        warningText = 'Lines selected to convert to credits will be lost.';
        warningHeader = 'WARNING: LINES SELECTED FOR CREDIT CONVERSION';
      }

      if ((this.dispatchList.length > 0 && this.cashDrawer) || this.creditList.length > 0) {
        this.dialogService
          .confirm(warningText, warningHeader)
          .pipe(takeUntil(this.unSubscribe$))
          .subscribe((res) => {
            if (res) {
              this.store.dispatch(
                new FinaliseOrder({
                  distributeOutputData: this.distributeOutputData,
                })
              );
            }
          });
        return;
      }
    } else {
      // Selling items with stock allocations and splitting lines
      if (!this.toDisableAllocation) {
        const { itemsToUpdate } = this.applyStockAllocation();

        this.store.dispatch(
          new FinaliseOrder({
            receiptData: this.distributeOutputData.toPrintReceipt ? this.receiptData() : null,
            distributeOutputData: this.distributeOutputData,
            nextNumberType: 'I',
            // this is used to get the serial numbers for receipt printing
            itemsToUpdate: itemsToUpdate,
            isShowUser: this.isShowUser(),
            brandDefaults: this.brandDefaults,
            isQuickSale: this.orderHeader.quickSaleYN === 'Y',
            redirectRoute: this.showLocation === 'SHOW' ? 'QuickSale' : 'EPC',
          })
        );
        return;
      }
      // Selling items without stock allocations
      else {
        if (this.dispatchList?.length > 0 || this.creditList?.length > 0) {
          const { itemsToUpdate } = this.applyStockAllocation();
          this.store.dispatch(
            new UpdateAndValidate({
              data: itemsToUpdate,
              receiptData: this.distributeOutputData.toPrintReceipt ? this.receiptData() : null,
              distributeOutputData: this.distributeOutputData,
              nextNumberType: 'I',
            })
          );
          return;
        }
      }
    }

    // Save order without changing the status (including making deposits)
    this.store.dispatch(
      new FinaliseOrder({
        receiptData: this.distributeOutputData.toPrintReceipt ? this.receiptData() : null,
        nextNumberType: this.isDepositing && this.orderNumber.startsWith('T') ? 'O' : null,
        distributeOutputData: this.distributeOutputData,
      })
    );
  }

  applyStockAllocation() {
    let itemsToUpdate = (this.isCreditOrder ? this.creditList : this.dispatchList).map((dispatchedItem) => utils.updatedData(dispatchedItem));
    Object.keys(this.allocatedStockList || []).forEach((lineNumber) => {
      const updatedItemIndex = itemsToUpdate.findIndex((item) => item.orderLine.toString() === lineNumber);
      const allocationList: any[] = this.allocatedStockList[lineNumber];

      // Logic to check whether allocatedStockList contains for normal or serialized items:
      // If contains arrays then serialized items
      // If contains objects then normal items
      if (typeof allocationList[0] === 'string') {
        itemsToUpdate[updatedItemIndex].serialNumber = allocationList.toString();
      } else {
        itemsToUpdate[updatedItemIndex].stockOnHand = allocationList;
      }
    });
    return { itemsToUpdate };
  }

  updateCustomerEmail(data) {
    this.apiService
      .updateCustomerEmail(data)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((res) => {
        if (res) {
          this.toastr.success('Customer email updated');
        }
      });
  }

  onDeleteAllItems() {
    // Not allow cancel all lines if prepayment amount > invoiced amount
    if (!this.orderHeader.orderNumber.startsWith('T') && !this.cancelAllLinesAllowed) {
      this.toastr.error('Cannot cancel order while outstanding deposits are on the order');
      return;
    }

    this.dialogService
      .confirm(`Are you sure to delete all lines in the order?`, 'WARNING: DELETE ALL')
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((res) => {
        if (res) {
          this.store.dispatch(new UpdateStagingOrderAllLine({ changeLineStatus: ORDER_LINE_STATUS.cancelled }));
        }
      });
  }

  saveOrder() {
    this.isSaving = true;
    this.isDepositing = false;
    this.orderConfirmation = null;

    this.loadPaymentInfo();
  }

  produceQuote() {
    this.showTextLoader = true;
    this.generateReportLoaderText = ATTACHMENT_DISPLAY_TEXT.generatingQuoteText;
    this.generateQuoteloading$.next(true);

    this.apiService
      .generateQuoteForNonCorp({ orderNumber: this.orderNumber })
      .pipe(
        finalize(() => {
          this.generateQuoteloading$.next(false);
          this.showTextLoader = false;
          this.generateReportLoaderText = ATTACHMENT_DISPLAY_TEXT.emptyText;
        }),
        map((res) => {
          const contentDisposition = res.headers.get('Content-Disposition');
          let fileName = contentDisposition.split('filename=')[1];
          let fileUrl = window.URL.createObjectURL(res.body);

          return { fileName, fileUrl };
        })
      )
      .subscribe(({ fileName, fileUrl }) => {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = fileUrl;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(fileUrl);
        a.remove();
      });
  }

  exportQuote() {
    this.orderGrid?.gridApi?.exportDataAsCsv({
      fileName: `${this.orderNumber}.csv`,
      columnKeys: ['itemNumber', 'qtyOrdered', 'unitPrice', 'itemDescription', 'colour', 'atpDescription'],
      shouldRowBeSkipped: (params: ShouldRowBeSkippedParams) => {
        return params.node.data.itemNumber === ORDER_ITEM_NUMBER.newItem;
      },
      processCellCallback: (params: ProcessCellForExportParams) => {
        if (params.column.getColId() === 'unitPrice') {
          return params.value?.replace(/[,]+/g, '').trim();
        }

        return params.value;
      },
      processHeaderCallback: (params: ProcessHeaderForExportParams) => {
        if (params.column.getColId() === 'itemNumber') {
          return 'PART NO';
        }

        if (params.column.getColId() === 'unitPrice') {
          return 'UNIT PRICE';
        }

        if (params.column.getColId() === 'atpDescription') {
          return 'ATP';
        }

        return params.column.getColDef().headerName;
      },
    });
  }

  sellGoods() {
    const isShowUser = this.isShowUser();
    this.isDepositing = false;
    this.orderConfirmation = ORDER_CONFIRMATION.invoice;
    this.toDisableAllocation = false;

    // Do stock allocation for:
    // 1. any items that is stock (nonStockLineYN !== 'Y'), including associated items, except gift card
    // 2. children kit components, not the parent kit components

    const dispatchList = this.orderHeader.orderType === ORDER_TYPE.creditReturn && this.creditList.length ? this.creditList : this.dispatchList;

    const itemsToCheckList = dispatchList.filter((x) => x.nonStockLineYN !== 'Y' && x.kitPC !== 'P' && x.itemNumber !== ORDER_ITEM_NUMBER.subTotal);

    if (!this.isOrderOnHold) {
      if (itemsToCheckList.length > 0) {
        this.isBusy = true;
        this.apiService
          .checkStockAllocation({ orderNumber: this.orderNumber })
          .pipe(takeUntil(this.unSubscribe$))
          .subscribe((result) => {
            this.isBusy = false;
            this.stockAllocationList = (result.itemArray || []).map((item) => {
              const itemsToCheck = itemsToCheckList.filter((i) => i.itemNumber === item.itemNumber);
              let itemToCheck: StagingOrderLine;
              if (itemsToCheck?.length > 1 && item.colourCode && item.colourCode != 'UNPAINTED') {
                itemToCheck = itemsToCheck.find((i) => i.colourCode === item.colourCode);
              } else {
                itemToCheck = itemsToCheck?.length ? itemsToCheck[0] : {};
              }

              const qtyOnHandArray =
                !item.onHandQtyArray.length && item.serializedYN === 'N'
                  ? [
                      {
                        location: 'SHOWROOM',
                        serialNumber: '',
                        qtyOnHand: 0,
                        label: 'SHOWROOM (0)',
                        qtyOnHand_Number: 0,
                        qtyOnHandRemaining: 0,
                      },
                    ]
                  : (item.onHandQtyArray || []).map((itemQty) => ({
                      ...itemQty,
                      qtyOnHand: itemQty.qtyOnHand_Number,
                      label: `${itemQty.location} (${itemQty.qtyOnHand})`,
                    }));
              let lotSerialNumber = '';
              if (itemToCheck.paintableYN === 'Y' || item.colourCode) {
                lotSerialNumber = item.colourCode || 'UNPAINTED';
              } else if (itemToCheck.serialNumber?.trim() !== '') {
                lotSerialNumber = itemToCheck.serialNumber;
              }

              // if show user and not returning show location, add show location to the array
              if (isShowUser && !item.onHandQtyArray.find((i) => i.location === this.showLocation)) {
                qtyOnHandArray.push({
                  location: this.showLocation,
                  serialNumber: '',
                  qtyOnHand: 0,
                  label: `${this.showLocation} (0)`,
                  qtyOnHand_Number: 0,
                  qtyOnHandRemaining: 0,
                });
              }
              return {
                itemNumber: item.itemNumber,
                itemDescription: item.itemName,
                quantity: item.quantityRemaining + sumBy(item.stockAllocation, (i) => i.quantity),
                lineNumber: itemToCheck.ooeLineNumber?.toString(),
                quantityRemaining: item.quantityRemaining,
                serializedYN: item.serializedYN,
                onHandQtyArray: isShowUser ? qtyOnHandArray.filter((item) => item.location === this.showLocation) : qtyOnHandArray,
                lotSerialNumber: lotSerialNumber,
                allocatedSerialNumbers: item.serializedYN === 'Y' ? item.stockAllocation?.map((i) => i.serialNumber) : [],
                errorMessage: result.ErrorMessage?.trim(),
                stockAllocation: item.stockAllocation,
              } as StockAllocation;
            });

            /* proceed to Payment */
            this.isMakingPayments = true;

            // show stock allocation screen if these conditions hold
            const exceptionList = this.stockAllocationList.filter(
              (item) => item.serializedYN === 'Y' || item.errorMessage || (!isShowUser && !item.stockAllocation?.length && item.quantityRemaining > 0)
            );

            this.changeDetectorRef.detectChanges();
            this.stepper.selectedIndex = exceptionList.length > 0 ? 0 : 1;
          });
      } else {
        // if there's no items requiring stock allocation, proceed to payments screen
        this.proceedToPayment();
      }
      this.loadPaymentInfo();
      this.loadGiftCards();
    } else {
      this.dialogService.error('Release all hold codes before processing order');
    }
  }

  goToPayment(): void {
    const allocationArray: AllocationArray[] = [];

    Object.keys(this.allocatedStockList).forEach((lineNumber) => {
      const allocationList: any[] = this.allocatedStockList[lineNumber];
      allocationList.forEach((item) => {
        if (typeof item === 'string') {
          const serialNumber = item;
          const stockAllocation = this.stockAllocationList.find((i) => i.lineNumber === lineNumber);

          if (stockAllocation) {
            allocationArray.push({
              itemNumber: stockAllocation.itemNumber,
              location: stockAllocation.onHandQtyArray.find((i) => i.serialNumber === serialNumber)?.location || 'SHOWROOM',
              serial: serialNumber,
              quantity: this.isCreditOrder ? -1 : 1,
            });
          }
        } else {
          const stockAllocation = this.stockAllocationList.find((i) => i.lineNumber === lineNumber);

          if (stockAllocation) {
            allocationArray.push({
              itemNumber: stockAllocation.itemNumber,
              location: item.itemLocation,
              serial: '',
              quantity: item.qty,
            });
          }
        }
      });
    });

    const input = { orderNumber: this.orderNumber, allocationArray };
    this.apiService
      .adjustStockAllocation(input)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe(() => {
        this.toastr.success('Adjusted Stock Allocation Successfully');
        this.stepper.next();
      });
  }

  proceedToPayment(isDeposit = false) {
    this.isDepositing = isDeposit;
    this.orderConfirmation = this.isDepositing ? ORDER_CONFIRMATION.deposit : ORDER_CONFIRMATION.invoice;
    this.toDisableAllocation = true;
    this.isMakingPayments = true;
    this.changeDetectorRef.detectChanges();
  }

  placeDeposit() {
    this.proceedToPayment(true);
    this.loadPaymentInfo();
  }

  allocateStock(allocationForm: FormGroup) {
    const formValue = cloneDeep(allocationForm.getRawValue());
    let rowInvalid = false;

    /* tranform value */
    if (this.isCreditOrder) {
      Object.keys(formValue).forEach((lineNumber) => {
        if (formValue[lineNumber]?.length && typeof formValue[lineNumber][0].serialNumber === 'string') {
          formValue[lineNumber] = formValue[lineNumber].map((item) => item.serialNumber);
          /* serialNumbers should uniqe */
          if (uniq(formValue[lineNumber])?.length !== formValue[lineNumber]?.length) {
            rowInvalid = true;
          }
        } else {
          formValue[lineNumber] = formValue[lineNumber];
        }
      });
    }

    this.allocatedStockList = formValue;
    this.isValidAllocation = !allocationForm.invalid && !rowInvalid;
  }

  onDispatchAll(data: { changeLineStatus: string; isAllDispatched: boolean }): void {
    if (data.isAllDispatched) {
      this.store.dispatch(new UpdateStagingOrderAllLine({ changeLineStatus: ORDER_LINE_STATUS.new }));
      return;
    }

    this.store.dispatch(new UpdateStagingOrderAllLine({ changeLineStatus: data.changeLineStatus }));
  }

  onDispatchedItems(data: { listData: StagingOrderLine[]; changeLineStatus: string }) {
    this.updateLineStatus(data.listData, data.changeLineStatus);
  }

  getGiftCardLoadUpList() {
    const giftCardItems = this.dispatchList.filter((x) => x.itemNumber.toUpperCase().trim() === ORDER_ITEM_NUMBER.giftCard && !this.isCreditOrder);
    this.giftCardLoadUpList = [];
    let remainingLoadedGiftCards = [...this.loadedGiftCards];

    /* Build up list of gift cards to show in loadup screen, including loaded giftcard */
    giftCardItems.forEach((gift) => {
      this.giftCardLoadUpList = this.giftCardLoadUpList.concat(
        Array.from(Array(gift.qtyOrdered), () => {
          const indexCardLoaded = remainingLoadedGiftCards.findIndex((item) => item.amountLoaded === gift.unitPriceInc);
          let allocatedCard = null;
          if (indexCardLoaded >= 0) {
            allocatedCard = remainingLoadedGiftCards.splice(indexCardLoaded, 1);
          }
          const loadedCard = allocatedCard?.length ? allocatedCard[0] : null;
          return {
            loadedCard: loadedCard,
            orderLineNumber: gift.ooeLineNumber,
            amount: loadedCard ? loadedCard.amountLoaded : gift.unitPriceInc,
            receipt: loadedCard?.receipt || '',
          };
        })
      );
    });
  }

  onPendingPaymentChange(pendingPayments) {
    this.pendingPayments = pendingPayments;
  }

  openCashDrawer() {
    this.drawerService.openCashdrawer();
  }

  handleOverrideBranch() {
    if (this.isShowUser() || this.overrideData.overrideBranch !== this.selectedBranch) {
      this.modalService.open(this.modalBox, { backdrop: 'static' });
    } else {
      this.convertQuote();
    }
  }

  onSelectOverrideData(selectedData) {
    if (selectedData) {
      this.modalService.dismissAll();
      this.overrideData = { ...selectedData };
      this.convertQuote();
    } else {
      this.modalService.dismissAll();
    }
  }

  convertQuote() {
    const notSelected = this.orderDetails
      .filter(
        (item) =>
          item.ooeLineNumber.toString() !== ORDER_ITEM_NUMBER.subTotal &&
          item.lineStatus !== ORDER_LINE_STATUS.invoiced &&
          item.lineStatus !== ORDER_LINE_STATUS.cancelled
      )
      .filter((x) => this.dispatchList.findIndex((item: StagingOrderLine) => item.ooeLineNumber === x.ooeLineNumber) === -1);
    let updatedList = [];
    this.dispatchList.forEach((item) => {
      updatedList.push(
        utils.updatedData({
          ...item,
          changeLineStatus: ORDER_LINE_STATUS.convertQuote,
        })
      );
    });

    if (notSelected?.length > 0) {
      this.dialogService
        .confirm(
          `You are about to convert only some quote lines into a sales order,
           what do you want to do with the remaining lines on the quote?`,
          'CONVERT QUOTE TO ORDER',
          'CANCEL REMAINING QUOTE LINES',
          'LEAVE REMAINING QUOTE LINES OPEN',
          false,
          false,
          true
        )
        .pipe(takeUntil(this.unSubscribe$))
        .subscribe((x) => {
          if (x === false) {
            this.store.dispatch(
              new ConvertLines({
                data: updatedList,
                type: ORDER_LINE_STATUS.convertQuote,
                overrideData: this.overrideData,
              })
            );
            this.toastr.success('LEAVE REMAINING QUOTE LINES OPEN SUCCESS');
          } else {
            notSelected.forEach((item) => {
              updatedList.push(
                utils.updatedData({
                  ...item,
                  changeLineStatus: ORDER_LINE_STATUS.cancelled,
                })
              );
            });
            this.store.dispatch(
              new ConvertLines({
                data: updatedList,
                type: ORDER_LINE_STATUS.cancelled,
                overrideData: this.overrideData,
              })
            );
            this.toastr.success('CANCEL REMAINING QUOTE LINES SUCCESS');
          }
        });
      return;
    }
    this.store.dispatch(
      new ConvertLines({
        data: updatedList,
        type: ORDER_LINE_STATUS.convertQuote,
        overrideData: this.overrideData,
      })
    );
  }

  createCredit(creditType: string) {
    this.store.dispatch(new CreateCredit({ creditType }));
  }

  copyOrder() {
    if (this.checkPending()) {
      return;
    }
    this.store.dispatch(new CopyOrder());
  }

  processCredit() {
    if (!this.isOrderOnHold) {
      if (this.userSecurityFunctions.indexOf(SECURITY_FUNCTION.processCredits) !== -1) {
        // process credit
        this.handleCreditUser(true);
      } else {
        this.isCheckingCreditUser = true;
      }
    } else {
      this.dialogService.error('Release all hold codes before processing order');
    }
  }

  handleCreditUser(isCreditUser: boolean) {
    this.isCheckingCreditUser = false;

    if (isCreditUser) {
      // process credit
      if (this.orderHeader.orderType === ORDER_TYPE.creditReturn) {
        this.sellGoods();
        return;
      }

      this.proceedToPayment();
      this.loadPaymentInfo();
    }
  }

  updateLineStatus(list: StagingOrderLine[], status: string): void {
    let itemsToUpdate: any = list.map((item) => {
      return {
        id: item.id,
        changeLineStatus: status,
        orderNumber: item.orderNumber,
      };
    });

    if (itemsToUpdate?.length) {
      this.store.dispatch(new UpdateStagingOrderLine(itemsToUpdate));
    }
  }

  isReturnCreditAllow(orderTypeList: OrderType[]) {
    return orderTypeList.findIndex((item) => item.OrderTypeCode === ORDER_TYPE.creditReturn) !== -1;
  }
  isPriceCreditAllow(orderTypeList) {
    return orderTypeList.findIndex((item) => item.OrderTypeCode === ORDER_TYPE.creditPrice) !== -1;
  }

  cancelAvailabilityView() {
    this.availabilityItem = null;
  }

  onReturnToOrder() {
    this.isMakingPayments = false;
    this.isSaving = false;
    this.store.dispatch(hasIncompleteOrder({ isIncompleteOrder: false }));
  }

  onSelectCreditReason(reasonCode: string) {
    this.selectedCreditReason = reasonCode;
    this.store.dispatch(
      new RevalidateOrder({
        orderNumber: this.orderNumber,
        reasonCode: reasonCode || ' ',
      })
    );
  }

  isSerialNumberExist(e: boolean) {
    this.isSerialNumberOnHand = e;
  }

  isShowUser() {
    return this.userRole.indexOf('SHOW') !== -1;
  }

  onRefetchPaymentInfo() {
    this.loadPaymentInfo();
  }

  /* this data is consumed by Electron to print the thermal receipt */
  receiptData() {
    const data = utils.compileReceiptData(
      this.invoiceBranchDetails,
      this.selectedCustomer,
      this.pendingPayments.filter((payment) => +payment.amount),
      this.totalHistoricalPaymentAmount
    );
    return {
      ...data,
      isQuickSale: this.orderHeader.quickSaleYN === 'Y',
      isInvoice: !this.isDepositing,
      isCreditNote: this.isCreditOrder,
      registerId: this.cashDrawer.registerID,
      change: this.cashChange,
      outstandingBalance: this.outstandingBalance,
      orderNumber: this.orderHeader.orderNumber,
      orderType: this.orderHeader.orderType,
    };
  }

  allowToSave() {
    return (
      this.dispatchList?.length === 0 &&
      this.creditList?.length === 0 &&
      !this.hasPendingPayments &&
      this.orderHeader.quickSaleYN !== 'Y' &&
      !this.isCreditOrder
    );
  }

  hasErrorQuantity() {
    return (
      this.orderDetails.filter(
        (item) =>
          item.lineStatus !== ORDER_LINE_STATUS.invoiced &&
          item.lineStatus !== ORDER_LINE_STATUS.cancelled &&
          item.itemNumber !== ORDER_ITEM_NUMBER.subTotal &&
          item.associationType !== ASSOCIATION_TYPE.mandatory &&
          +item.qtyOrdered === 0
      ).length > 0
    );
  }

  checkPending() {
    if (this.hasPendingPayments || this.loadedGiftCards?.length) {
      this.toastr.error(
        this.loadedGiftCards?.length
          ? 'There are loaded gift cards on the current order so the order must be finalised'
          : 'There are pending payments on the current order so the order must either be saved into JDE or the pending payment must be netted out or deleted'
      );
    }
    return this.hasPendingPayments || this.loadedGiftCards?.length;
  }

  setIncompleteOrder() {
    this.store.dispatch(hasIncompleteOrder({ isIncompleteOrder: true }));
  }

  disableProcessCredit(loadingLines) {
    return (
      loadingLines ||
      this.isLoadingPayments ||
      (this.isCreditOrder && !this.selectedCreditReason) ||
      this.hasInvalidItem ||
      this.hasErrorQuantity() ||
      !this.isHeaderValid ||
      this.orderHeader?.dispatchableYN === 'N' ||
      this.hasUpdatingRow
    );
  }

  disableSaveGood(loadingLines) {
    return loadingLines || this.isLoadingPayments || this.hasInvalidItem || this.hasErrorQuantity() || !this.isHeaderValid || this.hasUpdatingRow;
  }

  disableDeposit(loadingLines) {
    return loadingLines || this.isLoadingPayments || this.hasInvalidItem || !this.isHeaderValid || this.hasErrorQuantity() || this.hasUpdatingRow;
  }

  disableConfirmOrder(): boolean {
    return !this.isHeaderValid || this.hasInvalidItem || !this.enableValidateOrder || this.hasUpdatingRow;
  }

  disableExportQuote(): boolean {
    return this.hasErrorQuantity() || this.orderHeader?.readOnlyYN === 'Y' || !this.isHeaderValid || this.hasUpdatingRow;
  }

  addItemsToOrder(data: { items: ItemToAdd; enforceItemRule: boolean }) {
    this.store.dispatch(
      new AddItemsToOrder({
        items: data.items,
        orderNumber: this.orderNumber,
        catalogueEnabledYN: this.catalogueStatus.catalogueEnabledYN,
        enforceItemRule: data.enforceItemRule,
        customerTaxRate: this.orderHeader.customerTaxRate,
        reasonCode: this.isCreditOrder ? this.selectedCreditReason : '',
      })
    );

    this.ordersResponseService.addItemsToOrderSuccess$.pipe(take(1)).subscribe((data) => {
      if (data?.length > 0) {
        this.dialog
          .open(OrdersBuyinSupplierDialogComponent, {
            data,
            position: { top: '1.75rem' },
            panelClass: 'p-0',
          })
          .afterClosed()
          .pipe(takeUntil(this.unSubscribe$))
          .subscribe((supplier: Supplier) => {
            if (supplier) {
              this.store.dispatch(
                new AddItemsToOrder({
                  items: [
                    {
                      itemNumber: supplier.itemNumber,
                      quantity: 1,
                    },
                  ],
                  orderNumber: this.orderNumber,
                  catalogueEnabledYN: this.catalogueStatus.catalogueEnabledYN,
                  enforceItemRule: this.viewSettings.enforceItemRule,
                  customerTaxRate: this.orderHeader.customerTaxRate,
                  reasonCode: this.isCreditOrder ? this.selectedCreditReason : '',
                })
              );
            }
          });
      }
    });
  }

  onCardLoaded(lineNumber) {
    const lineItem = this.orderDetails.find((item) => item.ooeLineNumber === lineNumber);
    if (lineItem.readOnlyLineYN !== 'Y') {
      lineItem.readOnlyLineYN = 'Y';
      /* to trigger ngOnChanges on order-grid*/
      this.orderDetails = cloneDeep(this.orderDetails);
    }
    this.loadGiftCards();
  }

  private updateViewSettings(viewSettings: ViewSettings) {
    this.viewSettings = {
      ...this.viewSettings,
      ...viewSettings,
    };
    this.store.dispatch(
      new UpdateViewSettings({
        ...viewSettings,
      })
    );
  }

  onViewSettingsChange(viewSettings: ViewSettings) {
    this.updateViewSettings(viewSettings);

    if (Object.prototype.hasOwnProperty.call(viewSettings, 'enforceItemRule')) {
      this.apiService
        .updateOrderHeader({
          orderNumber: this.orderNumber,
          enforceItemAssociationsYN: viewSettings.enforceItemRule ? 'Y' : 'N',
        })
        .pipe(takeUntil(this.unSubscribe$))
        .subscribe();
    }

    if (Object.prototype.hasOwnProperty.call(viewSettings, 'showInvoiced') || Object.prototype.hasOwnProperty.call(viewSettings, 'showCancelled')) {
      this.loadStagingOrder();
    }
  }

  onNext() {
    if (this.hasCashPayment) {
      this.openCashDrawer();
    }
  }

  confirmEftposPendingPayments() {
    this.isBusy = true;
    const eftpostPendingPayments$: Observable<any>[] = [];
    const eftpostPendingPayments = this.ordersPayment?.eftpostPendingPayments;

    eftpostPendingPayments.forEach((payment) => {
      const eftpostPendingPayment$: Observable<any> = this.apiService
        .updatePendingPayment({
          _id: payment.pendingId,
          amount: +payment.amount,
          registerID: this.cashDrawer.registerID,
          orderNumber: this.orderNumber,
          payInstrument: '?',
          paymentType: ORDER_PAYMENT_TYPE.eftpos,
          eftposDeviceName: this.cashDrawer.eftposDeviceName,
          reference: payment.reference,
        })
        .pipe(
          finalize(() => {
            this.isBusy = false;
          }),
          catchError((error) => of(error))
        );
      eftpostPendingPayments$.push(eftpostPendingPayment$);
    });
    eftpostPendingPayments$;
    let eftposPendingPayment$ = of(
      ...eftpostPendingPayments$.map((pendingPayments) => {
        return pendingPayments;
      })
    );
    eftposPendingPayment$.pipe(concatAll()).subscribe({
      next: (res) => {
        !!res.ErrorStatus ? this.toastr.error(res.ErrorMessage) : this.toastr.success(res.ErrorMessage);
      },
      complete: () => {
        this.loadPaymentInfo();
      },
    });
  }

  onUseVehicleColourForAll(): void {
    this.store.dispatch(new UpdateStagingOrderAllLine({ paintYN: 'Y' }));
  }

  checkEftPosStatus(isPolling = false): void {
    if (this.cashDrawer?.eftposDeviceName?.trim()) {
      this.apiService
        .checkEftposStatus({
          eftposDeviceName: this.cashDrawer.eftposDeviceName,
        })
        .pipe(takeUntil(this.unSubscribe$))
        .subscribe((res) => {
          if (res.isOnline) {
            if (!this.paymentTypes.some((item) => item.paymentTypeCode === ORDER_PAYMENT_TYPE.eftpos)) {
              this.paymentTypes.unshift({
                payInstrument: '#',
                paymentTypeCode: ORDER_PAYMENT_TYPE.eftpos,
                paymentTypeDescription: ORDER_PAYMENT_TYPE.eftpos,
              });
            }
            if (isPolling) {
              this.toastr.success('Online');
            }
            return;
          }
          if (isPolling) {
            this.toastr.error('Offline');
          }
          this.paymentTypes = this.paymentTypes.filter((item) => item.paymentTypeCode !== ORDER_PAYMENT_TYPE.eftpos);
        });
    }
  }
}

@Component({
  selector: 'ngbd-modal-content',
  templateUrl: './nonstock-modal-content.component.html',
})
export class NgbdModalContent {
  @Input() buyinProduct: StagingOrderLine;
  @Input() defaultMarkup: number;

  supplierForm: FormGroup;

  private unSubscribe$ = new Subject<void>();

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder, private apiService: ApiService) {}

  ngOnInit() {
    this.supplierForm = this.fb.group({
      supplier: [this.buyinProduct ? this.buyinProduct.supplierCode : '', [Validators.required, Validators.pattern('^[0-9]+')]],
      productCode: [this.buyinProduct ? this.buyinProduct.itemDescription : '', [Validators.required]],
      unitPrice: [
        this.buyinProduct ? (this.buyinProduct.unitPriceInc / (1 + this.defaultMarkup / 100)).toFixed(3) : '',
        [Validators.required, Validators.pattern('^[0-9]+(.[0-9]+)?$')],
      ],
      sellPrice: [this.buyinProduct ? this.buyinProduct.unitPriceInc : ''],
    });

    this.supplierForm.valueChanges.subscribe((x) => {
      if (x.unitPrice) {
        this.supplierForm.controls.sellPrice.patchValue((x.unitPrice * (1 + this.defaultMarkup / 100)).toFixed(3), {
          emitEvent: false,
        });
      }
    });
  }

  handleNonStockItem() {
    // Remove current nonstock item from order
    this.apiService
      .removeItemFromOrder({
        orderNumber: this.buyinProduct.orderNumber,
        orderLine: this.buyinProduct.ooeLineNumber,
        id: this.buyinProduct.id,
      })
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((res) => {
        // Add in the new BUYIN line
        this.insertBuyIn(this.supplierForm.value);
      });
  }

  insertBuyIn(formData) {
    const itemToInsert = {
      orderNumber: this.buyinProduct.orderNumber,
      itemCode: ORDER_ITEM_NUMBER.buyin,
      supplierCode: formData.supplier,
      itemDesc: formData.productCode,
      insertLineAfter: this.buyinProduct.ooeLineNumber,
      extPriceExTax: this.buyinProduct.extPriceEx,
      extPriceIncTax: this.buyinProduct.extPriceInc,
      itemQty: this.buyinProduct.qtyOrdered,
      unitPriceIncTax: formData.sellPrice,
      unitPriceExTax: this.buyinProduct.unitPriceEx,
    };
    this.apiService
      .insertLineAfter(itemToInsert)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((res) => {
        this.activeModal.close(res);
      });
  }

  selectSupplier(supplierCode) {
    this.supplierForm.patchValue({ supplier: supplierCode });
  }
}
