import {
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Branch, FitterFilters, WeekListObject } from '@pos-app/data';
import { formatDateForInternal } from 'libs/core-ui/src/lib/utils/utils-date';


@Component({
  selector: 'app-fitter-filters',
  templateUrl: './fitter-filters.component.html',
  styleUrls: ['./fitter-filters.component.scss'],
})
export class FitterFiltersComponent implements OnInit, OnChanges {
  @Input() defaultBranch;
  @Input() branchList: Branch[];
  @Input() currentDate: string;
  @Input() weekList: WeekListObject[] = [];
  @Output() fitterFiltersFormData = new EventEmitter<FitterFilters>();
  @Output() fitterFiltersFormDataFromTransactionNo =
    new EventEmitter<FitterFilters>();
  allWeeksList: WeekListObject[] = [];
  weekListObject: WeekListObject;
  maxRelativeWeekNumber: string;
  minRelativeWeekNumber: string;
  weekListObjLabel: string;
  isReviewByDateEnabled: boolean = false;
  fitterFiltersForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    @Inject(LOCALE_ID) public locale: string
  ) {}

  ngOnInit(): void {
    this.fitterFiltersForm = this.formBuilder.group({
      transactionNumber: [''],
      branch: [this.defaultBranch, Validators.required],
      reviewByDate: [
        { value: this.currentDate, disabled: this.isReviewByDateEnabled },
      ],
      reviewByWeek: [{ value: '', disabled: !this.isReviewByDateEnabled }],
      unallocatedOnlyYN: [''],
      invoicedOnlyYN: [''],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.weekList && !!this.weekList) {
      this.allWeeksList = this.weekList;
      this.maxRelativeWeekNumber = this.getMaxRelativeWeekNumber(
        this.allWeeksList
      );
      this.minRelativeWeekNumber = this.getMinRelativeWeekNumber(
        this.allWeeksList
      );
    }

    if (
      changes.branchList &&
      changes.branchList.currentValue &&
      this.fitterFiltersForm
    ) {
      this.fitterFiltersForm.patchValue({ branch: this.defaultBranch });
    }
  }

  enableReviewByDateToggle(isEnable: boolean) {
    this.isReviewByDateEnabled = isEnable;

    if (!!this.isReviewByDateEnabled) {
      this.enableReviewBy('reviewByDate');
      this.disableReviewBy('reviewByWeek');
    } else if (!this.isReviewByDateEnabled) {
      this.enableReviewBy('reviewByWeek');
      this.disableReviewBy('reviewByDate');
    } else {
      this.disableReviewBy('reviewByDate');
      this.disableReviewBy('reviewByWeek');
    }
  }

  getCurentReviewByWeekLabel(data: any) {
    if (!!this.allWeeksList) {
      let currentWeekObject = this.getCurrentWeekObject(data.index);
      if (!!currentWeekObject) {
        this.weekListObjLabel = currentWeekObject.Label;
      }
    }
  }

  getCurrentWeekObject(index: string) {
    return this.allWeeksList.find(
      (weekListObject) => weekListObject.RelativeWeekNumber === index
    );
  }

  disableReviewBy(reviewByName: string) {
    this.fitterFiltersForm.get(reviewByName).disable();
    this.fitterFiltersForm.controls[reviewByName].patchValue('');
  }

  enableReviewBy(reviewByName: string) {
    this.fitterFiltersForm.get(reviewByName).enable();
  }

  getMaxRelativeWeekNumber(weekList: WeekListObject[]) {
    return weekList.reduce((prev, current) =>
      prev.RelativeWeekNumber > current.RelativeWeekNumber ? prev : current
    ).RelativeWeekNumber;
  }

  getMinRelativeWeekNumber(weekList: WeekListObject[]) {
    return weekList.reduce((prev, current) =>
      prev.RelativeWeekNumber < current.RelativeWeekNumber ? prev : current
    ).RelativeWeekNumber;
  }

  getFormFieldValue(formField: string) {
    return this.fitterFiltersForm.get(formField).value as string;
  }

  hasNumberValue(value) {
    return (
      !(value.length === 0) &&
      value !== null &&
      value !== undefined &&
      !isNaN(Number(value))
    );
  }

  disableFetchOrder() {
    return (!!this.getFormFieldValue('reviewByDate') ||
      !!this.hasNumberValue(this.getFormFieldValue('reviewByWeek'))) &&
      !!this.getFormFieldValue('branch')
      ? false
      : true;
  }

  disableFetchOrderFromTransactionNumber() {
    return !!this.getFormFieldValue('transactionNumber') &&
      !!this.getFormFieldValue('branch')
      ? false
      : true;
  }

  fetchOrderForBookingAndResourceList() {
    let startDate: string = '';
    let endDate: string = '';
    let transactionNumber = this.getFormFieldValue('transactionNumber');
    let branch = this.getFormFieldValue('branch');
    let invoicedOnlyYN = 'N';
    let unallocatedOnlyYN = 'N';

    this.fitterFiltersFormDataFromTransactionNo.emit({
      startDate: startDate,
      endDate: endDate,
      transactionNumber: transactionNumber,
      branch: branch,
      invoicedOnlyYN: invoicedOnlyYN,
      unallocatedOnlyYN: unallocatedOnlyYN,
    });
  }

  fetchResourceListAndOrderForBooking() {
    let startDate: string = '';
    let endDate: string = '';
    let transactionNumber = '';
    let branch = this.getFormFieldValue('branch');
    let invoicedOnlyYN = !!this.getFormFieldValue('invoicedOnlyYN') ? 'Y' : 'N';
    let unallocatedOnlyYN = !!this.getFormFieldValue('unallocatedOnlyYN')
      ? 'Y'
      : 'N';
    let reviewByDate = !!this.getFormFieldValue('reviewByDate')
      ? this.getFormFieldValue('reviewByDate')
      : '';
    let reviewByWeek = !!this.hasNumberValue(
      this.getFormFieldValue('reviewByWeek')
    )
      ? this.getFormFieldValue('reviewByWeek')
      : '';

    if (!!reviewByDate && !this.hasNumberValue(reviewByWeek)) {
      startDate = formatDateForInternal(reviewByDate, this.locale);
      endDate = formatDateForInternal(reviewByDate, this.locale);
    } else if (!!this.hasNumberValue(reviewByWeek) && !reviewByDate) {
      let currentWeekObject = this.getCurrentWeekObject(reviewByWeek);
      startDate = currentWeekObject.StartDate;
      endDate = currentWeekObject.EndDate;
    }
    this.fitterFiltersFormData.emit({
      startDate: startDate,
      endDate: endDate,
      transactionNumber: transactionNumber,
      branch: branch,
      invoicedOnlyYN: invoicedOnlyYN,
      unallocatedOnlyYN: unallocatedOnlyYN,
    });
  }
}
