import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService, loginSuccess, MessageService } from '@pos-app/core-ui';
import { CatalogueStatus, KEYS, UserProfile, USER_ROLE } from '@pos-app/data';
import { of } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-logintoshow',
  templateUrl: './logintoshow.component.html',
  styleUrls: ['./logintoshow.component.scss']
})
export class LogintoshowComponent implements OnInit {
  @Input() userProfile: UserProfile;
  @ViewChild('content', { static: true }) modalBox: any;
  @Output() loggedInShow = new EventEmitter<void>();
  loading: boolean;
  USER_ROLE = USER_ROLE;

  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    if (
      this.userProfile.Role === USER_ROLE.guest &&
      this.userProfile.ShowCodes.length === 1
    ) {
      this.loginToShow(this.userProfile.ShowCodes[0].showCode);
    } else {
      // Internal and registered users to select the show or non-show
      this.modalService.open(this.modalBox, { backdrop: 'static' }).result.then(
        _ => {},
        _ => {
          // when escape => select non-show
          this.loggedInShow.emit();
        }
      );
    }
  }

  loginToShow(show: string) {
    this.loading = true;
    const showLogin = show
      ? this.apiService.loginToShow({ showCode: show })
      : of(null);
    showLogin.pipe(finalize(() => (this.loading = false))).subscribe(res => {
      if (res) {
        // Update user profile in localstorage
        const updatedProfile: UserProfile = {
          ...this.userProfile,
          CustomerNumber: res.customerNumber,
          DefaultBranch: res.defaultBranch,
          DefaultBranchName: res.defaultBranchName,
          Role: res.userRole,
          ShowLocation: res.showLocation,
          ValidOrderTypes: res.validOrderTypesShow,
          ValidOrderTypesNonCorp: res.validOrderTypesNonCorp,
          ShowCodes: [
            this.userProfile.ShowCodes.find(item => item.showCode === show)
          ]
        };
        localStorage.setItem(KEYS.jdeSession, JSON.stringify(updatedProfile));

        this.messageService.dispatchAction(
          loginSuccess({ payload: updatedProfile })
        );
        // Update customer category when login to show
        let catalogueStatus: CatalogueStatus = {
          catalogueVisibleYN: res.catalogueVisibleYN,
          catalogueEnabledYN: res.catalogueEnabledYN,
          customerCategory: 'retail'
        };
        localStorage.setItem(
          KEYS.catalogueStatus,
          JSON.stringify(catalogueStatus)
        );
      }
      this.loading = false;
      this.modalService.hasOpenModals()
        ? this.modalService.dismissAll()
        : this.loggedInShow.emit();
    });
  }
}
