<div class="form-inline">
  <div class="form-group hidden">
    <div class="input-group">
      <input
        name="datepicker"
        class="form-control calendar-box"
        ngbDatepicker
        #datepicker="ngbDatepicker"
        [autoClose]="'outside'"
        (dateSelect)="onDateSelection($event)"
        [displayMonths]="2"
        [dayTemplate]="t"
        outsideDays="hidden"
        [startDate]="fromDate"
        [(ngModel)]="model1"
      />
      <ng-template #t let-date let-focused="focused">
        <span
          class="custom-day"
          [class.focused]="focused"
          [class.range]="isRange(date)"
          [class.faded]="isHovered(date) || isInside(date)"
          (mouseenter)="hoveredDate = date"
          (mouseleave)="hoveredDate = null"
        >
          {{ date.day }}
        </span>
      </ng-template>
    </div>
  </div>
  <div class="form-group form-group-wrapper">
    <div class="input-group">
      <input
        #dpFromDate
        class="form-control calendar-box"
        [placeholder]="getCurrentLocaleDateFormat()"
        name="dpFromDate"
        (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
        [formControl]="fromDateString"
        (click)="datepicker.toggle()"
        (click)="isFromDateEnter = true"
      />
      <button
        type="button"
        class="close clear-button"
        aria-label="Close"
        (click)="clearDate()"
        *ngIf="fromDate"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="form-group ml-2 form-group-wrapper">
    <div class="input-group">
      <input
        #dpToDate
        class="form-control calendar-box"
        [placeholder]="getCurrentLocaleDateFormat()"
        name="dpToDate"
        (input)="toDate = validateInput(toDate, dpToDate.value)"
        [formControl]="toDateString"
        (click)="datepicker.toggle()"
        (click)="isFromDateEnter = false"
      />
      <button
        type="button"
        class="close clear-button"
        aria-label="Close"
        (click)="clearDate(false)"
        *ngIf="toDate"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
</div>
