import moment from 'moment';
import { getInMinutes, getformatedTime } from './scheduler-helper';

export const schedulerConstValue = {
  DROP_TO_BREAK: 'droppedToBreak',
  REMOVE_FROM_BREAK: 'removeFromBreak',
  NODROP_OR_REMOVE_FROM_BREAK: 'NoDropOrRemoveBreak',
};
export function checkInBreakRange(ev: any, original: any, technicanData: any) {
  /*
          condition
          //check of event is dropped
            1. when drag fall on break time (12-1) ( calculate draged from final - 12) 
              if more than 50% of break hour extend 1 hour
              if less than 50% of break hour decrease 1 hour to 12
            2. when drag dont fall dont worry
            3. when timeline already consist break dont do anything  
    
            return 1 for in range
         */
  let startTime = ev.start_date;
  let endTime = ev.end_date;
  let originalStartTime = original.start_date;
  let originalEndTime = original.end_date;
  let inRange = [];
  let break1StartTime = getformatedTime((technicanData?.break1StartTime > 0 ? technicanData?.break1StartTime + 100 : 0).toString());
  let break1EndTime = getformatedTime((technicanData?.break1EndTime > 0 ? technicanData?.break1EndTime - 100 : 0).toString());
  let break2StartTime = getformatedTime((technicanData?.break2StartTime > 0 ? technicanData?.break2StartTime + 100 : 0).toString());
  let break2EndTime = getformatedTime((technicanData?.break2EndTime > 0 ? technicanData?.break2EndTime - 100 : 0).toString());
  let break3StartTime = getformatedTime((technicanData?.break3StartTime > 0 ? technicanData?.break3StartTime + 100 : 0).toString());
  let break3EndTime = getformatedTime((technicanData?.break3EndTime > 0 ? technicanData?.break3EndTime - 100 : 0).toString());
  let newStartRangeTime = startTime.toLocaleTimeString('it-IT');
  let newEndRangeTime = endTime.toLocaleTimeString('it-IT');
  let originalEndRangeTime = originalEndTime.toLocaleTimeString('it-IT');
  let originalStartRangeTime = originalStartTime.toLocaleTimeString('it-IT');

  let break1Mins = getInMinutes(technicanData?.break1EndTime) - getInMinutes(technicanData?.break1StartTime);
  let break2Mins = getInMinutes(technicanData?.break2EndTime) - getInMinutes(technicanData?.break2StartTime);
  let break3Mins = getInMinutes(technicanData?.break3EndTime) - getInMinutes(technicanData?.break3StartTime);

  let timestamps = [];
  timestamps[0] = newStartRangeTime;
  timestamps[1] = newEndRangeTime;
  timestamps[4] = originalStartRangeTime;
  timestamps[5] = originalEndRangeTime;
  // build four item array if endDate is in next day
  if (newStartRangeTime > newEndRangeTime) {
    timestamps[1] = '24:00:00';
    timestamps[2] = '00:00:00';
    timestamps[3] = newEndRangeTime;
  }

  if (originalStartRangeTime === newStartRangeTime && ev.section_id === original.section_id) {
    return inRange;
  } else {
    if (break1StartTime && break1EndTime) {
      if (
        (originalEndRangeTime < break1StartTime || originalStartRangeTime > break1EndTime) &&
        ((newStartRangeTime < break1EndTime && newEndRangeTime > break1StartTime && newEndRangeTime < break1EndTime) ||
          (newStartRangeTime > break1StartTime && newStartRangeTime < break1EndTime && newEndRangeTime > break1EndTime) ||
          (newStartRangeTime < break1StartTime && newEndRangeTime > break1EndTime))
      ) {
        inRange.push({
          break: 1,
          value: schedulerConstValue.DROP_TO_BREAK,
          mins: break1Mins,
          breakStartTime: getformatedTime(technicanData?.break1StartTime.toString()),
          breakEndTime: getformatedTime(technicanData?.break1EndTime.toString()),
        });
      } else if (
        originalStartRangeTime < break1StartTime &&
        originalEndRangeTime > break1EndTime &&
        (newEndRangeTime < break1StartTime || newStartRangeTime > break1EndTime)
      ) {
        inRange.push({
          break: -1,
          value: schedulerConstValue.REMOVE_FROM_BREAK,
          mins: break1Mins,
          breakStartTime: getformatedTime(technicanData?.break1StartTime.toString()),
          breakEndTime: getformatedTime(technicanData?.break1EndTime.toString()),
        });
      } else if (
        (originalStartRangeTime < break1StartTime &&
          originalEndRangeTime > break1EndTime && // ref 1
          ((newEndRangeTime > break1StartTime && newEndRangeTime < break1EndTime) ||
            (newStartRangeTime > break1StartTime && newStartRangeTime < break1EndTime))) ||
        (newStartRangeTime < break1StartTime && newEndRangeTime > break1EndTime)
      ) {
        inRange.push({
          break: 1,
          value: schedulerConstValue.NODROP_OR_REMOVE_FROM_BREAK,
          mins: break1Mins,
          breakStartTime: getformatedTime(technicanData?.break1StartTime.toString()),
          breakEndTime: getformatedTime(technicanData?.break1EndTime.toString()),
        });
      }
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    if (break2StartTime && break2EndTime) {
      if (
        (originalEndRangeTime < break2StartTime || originalStartRangeTime > break2EndTime) &&
        ((newStartRangeTime < break2EndTime && newEndRangeTime > break2StartTime && newEndRangeTime < break2EndTime) ||
          (newStartRangeTime > break2StartTime && newStartRangeTime < break2EndTime && newEndRangeTime > break2EndTime) ||
          (newStartRangeTime < break2StartTime && newEndRangeTime > break2EndTime))
      ) {
        inRange.push({
          break: 1,
          value: schedulerConstValue.DROP_TO_BREAK,
          mins: break2Mins,
          breakStartTime: getformatedTime(technicanData?.break2StartTime.toString()),
          breakEndTime: getformatedTime(technicanData?.break2EndTime.toString()),
        });
      } else if (
        originalStartRangeTime < break2StartTime &&
        originalEndRangeTime > break2EndTime &&
        (newEndRangeTime < break2StartTime || newStartRangeTime > break2EndTime)
      ) {
        inRange.push({
          break: -1,
          value: schedulerConstValue.REMOVE_FROM_BREAK,
          mins: break2Mins,
          breakStartTime: getformatedTime(technicanData?.break2StartTime.toString()),
          breakEndTime: getformatedTime(technicanData?.break2EndTime.toString()),
        });
      } else if (
        (originalStartRangeTime < break2StartTime &&
          originalEndRangeTime > break2EndTime && // ref 1
          ((newEndRangeTime > break2StartTime && newEndRangeTime < break2EndTime) ||
            (newStartRangeTime > break2StartTime && newStartRangeTime < break2EndTime))) ||
        (newStartRangeTime < break2StartTime && newEndRangeTime > break2EndTime)
      ) {
        inRange.push({
          break: 1,
          value: schedulerConstValue.NODROP_OR_REMOVE_FROM_BREAK,
          mins: break2Mins,
          breakStartTime: getformatedTime(technicanData?.break2StartTime.toString()),
          breakEndTime: getformatedTime(technicanData?.break2EndTime.toString()),
        });
      }
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    if (break3StartTime && break3EndTime) {
      if (
        (originalEndRangeTime < break3StartTime || originalStartRangeTime > break3EndTime) &&
        ((newStartRangeTime < break3EndTime && newEndRangeTime > break3StartTime && newEndRangeTime < break3EndTime) ||
          (newStartRangeTime > break3StartTime && newStartRangeTime < break3EndTime && newEndRangeTime > break3EndTime) ||
          (newStartRangeTime < break3StartTime && newEndRangeTime > break3EndTime))
      ) {
        inRange.push({
          break: 1,
          value: schedulerConstValue.DROP_TO_BREAK,
          mins: break3Mins,
          breakStartTime: getformatedTime(technicanData?.break3StartTime.toString()),
          breakEndTime: getformatedTime(technicanData?.break3EndTime.toString()),
        });
      } else if (
        originalStartRangeTime < break3StartTime &&
        originalEndRangeTime > break3EndTime &&
        (newEndRangeTime < break3StartTime || newStartRangeTime > break3EndTime)
      ) {
        inRange.push({
          break: -1,
          value: schedulerConstValue.REMOVE_FROM_BREAK,
          mins: break3Mins,
          breakStartTime: getformatedTime(technicanData?.break3StartTime.toString()),
          breakEndTime: getformatedTime(technicanData?.break3EndTime.toString()),
        });
      } else if (
        (originalStartRangeTime < break3StartTime &&
          originalEndRangeTime > break3EndTime && // ref 1
          ((newEndRangeTime > break3StartTime && newEndRangeTime < break3EndTime) ||
            (newStartRangeTime > break3StartTime && newStartRangeTime < break3EndTime))) ||
        (newStartRangeTime < break3StartTime && newEndRangeTime > break3EndTime)
      ) {
        inRange.push({
          break: 1,
          value: schedulerConstValue.NODROP_OR_REMOVE_FROM_BREAK,
          mins: break3Mins,
          breakStartTime: getformatedTime(technicanData?.break3StartTime.toString()),
          breakEndTime: getformatedTime(technicanData?.break3EndTime.toString()),
        });
      }
    }
    return inRange;
  }
}
