<form [formGroup]="customerTrailerSearchForm">
  <div class="searchBox d-flex">
    <input
      #searchBox
      type="text"
      placeholder="Enter phone/name/vin/invoice/rego"
      class="form-control"
      (focus)="isFocus($event)"
      (blur)="lostFocus($event)"
      (keyup)="onKeyboard($event)"
      formControlName="customerTrailerSearch"
    />
    <span class="pl-2" *ngIf="loading$ | async">
      <pos-app-spinner2 [dark]="true"></pos-app-spinner2>
    </span>
  </div>

  <div *ngIf="!!listOfCustomersTrailers">
    <table
      class="border my-4 table table-striped shadow-lg searchResult"
      *ngIf="showTable && listOfCustomersTrailers.length > 0"
    >
      <thead>
        <tr>
          <th scope="col" class="text-uppercase">
            <label>Asset Number</label>
          </th>
          <th scope="col" class="text-uppercase">
            <label>Customer Name</label>
          </th>
          <th scope="col" class="text-uppercase">
            <label>Customer Number</label>
          </th>
          <th scope="col" class="text-uppercase">
            <label>Customer Phone</label>
          </th>
          <th scope="col" class="text-uppercase text-nowrap">
            <label>Invoice Number</label>
          </th>
          <th scope="col" class="text-uppercase text-nowrap">
            <label>VIN</label>
          </th>
          <th scope="col" class="text-uppercase text-nowrap">
            <label>Rego</label>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          (click)="selectCustomerTrailer(customerTrailer)"
          *ngFor="let customerTrailer of listOfCustomersTrailers; let i = index"
          [ngClass]="i === indexFocus ? 'table-primary' : ''"
        >
          <td>
            <mat-icon class="selectIcon" *ngIf="i === indexFocus"
              >keyboard_arrow_right</mat-icon
            >
            <ngb-highlight
              [result]="customerTrailer.AssetNumber"
              [term]="
                customerTrailerSearchForm.get('customerTrailerSearch').value
              "
            >
            </ngb-highlight>
          </td>
          <td>
            <ngb-highlight
              [result]="customerTrailer.CustomerName"
              [term]="
                customerTrailerSearchForm.get('customerTrailerSearch').value
              "
            ></ngb-highlight>
          </td>
          <td>
            <ngb-highlight
              [result]="customerTrailer.CustomerNumber"
              [term]="
                customerTrailerSearchForm.get('customerTrailerSearch').value
              "
            ></ngb-highlight>
          </td>
          <td>
            <ngb-highlight
              [result]="customerTrailer.CustomerPhone"
              [term]="
                customerTrailerSearchForm.get('customerTrailerSearch').value
              "
            ></ngb-highlight>
          </td>
          <td>
            <ngb-highlight
              [result]="customerTrailer.InvoiceNumber"
              [term]="
                customerTrailerSearchForm.get('customerTrailerSearch').value
              "
            ></ngb-highlight>
          </td>
          <td>
            <ngb-highlight
              [result]="customerTrailer.VIN"
              [term]="
                customerTrailerSearchForm.get('customerTrailerSearch').value
              "
            ></ngb-highlight>
          </td>
          <td>
            <ngb-highlight
            [result]="customerTrailer.Rego"
            [term]="
              customerTrailerSearchForm.get('customerTrailerSearch').value
            "
          ></ngb-highlight>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div
    class="p-2 mt-1 text-danger searchResult"
    *ngIf="
      !!listOfCustomersTrailers &&
      listOfCustomersTrailers.length === 0 &&
      !!loading$
    "
  >
    No result has found - Please try again
  </div>
</form>
