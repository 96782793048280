import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AuthService,
  CoreUiPartialState,
  loadBrandDefaults,
  MessageService,
  getBrandDefaults,
  isExternalUser,
  getLoginDetails,
  ConfigService,
} from '@pos-app/core-ui';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { GetUserStateAction } from './features/customers/+state/customers.actions';
import { CustomersPartialState } from './features/customers/+state/customers.reducer';
import { getCustomerName } from './features/customers/+state/customers.selectors';
import { LayoutService } from 'libs/core-ui/src/lib/services/layout.service';

@Component({
  selector: 'pos-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  public runningEnv = environment.production ? 'production' : 'local';
  public unSubscribe$ = new Subject<void>();
  public isRedirecting = true;
  public isFooterHidden$ = this.layoutService.getIsFooterHidden();
  public isNavBarHidden$ = this.layoutService.getIsNavBarHidden();

  constructor(
    private authService: AuthService,
    private config: ConfigService,
    private messageService: MessageService,
    private coreUiStore: Store<CoreUiPartialState>,
    private customerStore: Store<CustomersPartialState>,
    private layoutService: LayoutService,
    @Inject(DOCUMENT) private document: Document
  ) {
    if (window.location.pathname === '/') {
      window.location.href = '/pos/login';
    } else {
      this.isRedirecting = false;
      this.authService.restoreSession();
      this.loadClientTheme();
    }

    this.authService.restoreSession();
    this.coreUiStore.dispatch(loadBrandDefaults());
   
    this.coreUiStore
      .select(getBrandDefaults)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((data)=>{
        localStorage.setItem('PREDEFINED_LOCALE', JSON.stringify(data?.internalLocale));
    })
    this.coreUiStore
      .select(isExternalUser)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((data)=>{
        localStorage.setItem('IS_EXTERNAL_USER', JSON.stringify(data))
    })
    this.coreUiStore
      .select(getLoginDetails)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((data)=>{
        localStorage.setItem('IS_SHOW', JSON.stringify(data?.ShowCodes.length>0 && data?.ShowLocation==='SHOW'))
    })

  }
  public ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
    this.messageService.ngOnDestroy();
  }

  public loadClientTheme(): void {
    let themeCssUrl = this.config.AppConfig.enableTheme
      ? this.config.AppConfig.themeCssUrl
      : '/assets/styles/arb_styles.css';
    let favicon = this.config.AppConfig.enableTheme
      ? this.config.AppConfig.favicon
      : '/favicon.ico';
    const head = this.document.getElementsByTagName('head')[0];
    let themeLink = this.document.getElementById(
      'client-theme'
    ) as HTMLLinkElement;

    if (themeLink) {
      themeLink.href = themeCssUrl;
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = `${themeCssUrl}`;
      head.appendChild(style);
    }
    let faviconLink = this.document.getElementById(
      'favicon'
    ) as HTMLLinkElement;
    faviconLink.href = favicon;
  }
}
