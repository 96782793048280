import {
  Directive,
  ElementRef,
  Input,
  OnInit,
  AfterViewInit,
} from '@angular/core';

@Directive({
  selector: '[buttonBusy]',
})
export class ButtonBusyDirective implements OnInit, AfterViewInit {
  @Input() set buttonBusy(isBusy: boolean) {
    this.refreshState(isBusy);
  }

  @Input() busyText: string;

  private button: any;
  private originalButtonInnerHtml: any;

  constructor(private element: ElementRef) {}

  public ngOnInit(): void {
    this.button = this.element.nativeElement;
  }

  public ngAfterViewInit(): void {
    this.originalButtonInnerHtml = this.button.innerHTML;
  }

  public refreshState(isBusy: boolean): void {
    if (!this.button) {
      return;
    }

    if (isBusy) {
      // disable button
      this.button.setAttribute('disabled', 'disabled');

      this.button.innerHTML =
        '<i class="fa fa-spin fa-spinner"></i>' +
        '<span>' +
        (this.busyText ? this.busyText : 'Processing...') +
        '</span>';

      this.button.setAttribute('_disabledBefore', true);
    } else {
      if (!this.button.getAttribute('_disabledBefore')) {
        return;
      }

      // enable button
      this.button.removeAttribute('disabled');
      this.button.innerHTML = this.originalButtonInnerHtml;
    }
  }
}
