<div>
  <label>{{ isReplacement ? 'REPLACEMENT' : 'ORIGINAL' }} PARTS DETAILS</label>
  <form [formGroup]="partsForm">
    <div class="row" *ngIf="getAllParts().length > 0">
      <div class="col-2"><label>PART NO *</label></div>
      <div class="col-2"><label>DESCRIPTION</label></div>
      <div class="col-1"><label>QTY *</label></div>
      <div class="col-2"><label>COLOUR/SERIAL NO</label></div>
      <div class="col-2">
        <label>ORDER/INVOICE NO <span *ngIf="!isReplacement">*</span></label>
      </div>
      <div class="col-2"><label>SUPPLY/FITMENT DATE *</label></div>
    </div>
    <div
      formArrayName="parts"
      *ngFor="let item of getAllParts(); let i = index"
    >
      <div [formGroupName]="i" class="row pb-2">
        <div class="col-2">
          <pos-app-fuzzy-search
            [apiEndpointKey]="'searchItems'"
            [apiRequestKey]="'searchString'"
            [valueKey]="'ProductCode'"
            [description1Key]="'ProductDescription1'"
            [description2Key]="'ProductDescription2'"
            [placeholder]="'ENTER PART CODE / DESCRIPTION'"
            [useNotFoundItem]="true"
            [isRequired]="true"
            [searchStr]="item.get('itemNumber').value"
            [isAutoFocus]="true"
            (selectedItem)="onSelectItem(item, $event)"
          ></pos-app-fuzzy-search>
        </div>
        <div class="col-2">
          <input
            class="form-control"
            formControlName="description"
            placeholder="ENTER DESCRIPTION"
          />
        </div>
        <div class="col-1">
          <input
            type="number"
            class="form-control"
            formControlName="quantity"
            [class.is-invalid]="item.get('quantity').errors"
          />
        </div>
        <div class="col-2">
          <input
            class="form-control"
            formControlName="lotSerialNumber"
            placeholder="ENTER COLOUR/SERIAL NO"
          />
        </div>
        <div class="col-2">
          <input
            class="form-control"
            formControlName="invoiceNumber"
            placeholder="ENTER ORDER/INVOICE"
            [ngClass]="{
              'border border-danger rounded': item.get('invoiceNumber').errors
            }"
          />
        </div>
        <div class="col-2 d-flex">
          <div
            [ngClass]="{
              'border border-danger rounded':
                !item.get('dateSuppliedFitted').value && !isReplacement
            }"
          >
            <pos-app-date-picker-single
              (selectedDate)="onSelectDate(item, $event)"
              [showError]="isReplacement"
            ></pos-app-date-picker-single>
          </div>
        </div>
        <div class="col-1 pl-0 my-auto">
          <div class="row">
            <div class="col-1">
              <mat-icon
                class="text-danger my-auto"
                (click)="remove(i)"
                *ngIf="getAllParts().length > 1 || isReplacement"
                >close</mat-icon
              >
            </div>
            <div class="col-3" *ngIf="!isReplacement">
              <button
                class="btn text-white"
                [disabled]="
                  item.get('quantity').errors || item.get('itemNumber').errors
                "
                (click)="onReplaceItem(item)"
              >
                REPLACE
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pb-2 row">
      <div class="col-4">
        <button type="button" class="btn btn-secondary" (click)="addPart()">
          {{ isReplacement ? 'ADD A REPLACEMENT PART' : 'ADD A PART' }}
        </button>
      </div>
    </div>
  </form>
</div>
