import { createReducer, on } from '@ngrx/store';
import { LoadVehiclesSuccess,
   LoadVehicles, 
   AdvancedSearchStatus, 
   SearchString, 
   SelectVehicle, 
   RequestVehicleSelection, 
   ClearRequestSelectVehicle, 
   ResetVehicleSearch, 
   DeselectVehicle, 
   SetUserSelectedVehicle, 
   InitSelectedVehicle, 
   UpdateVehicleDescription, 
   UseInFilter,
   ShowUniversalProducts,
   SetPartsVehicleID
   } from './vehicles.actions';
import { IVehicleState, IVehicleValuesState } from './vehicle.state';
export const EPC_VEHICLE_FEATURE_KEY = 'epcVehicle';

const initVehicleValuesState: IVehicleValuesState = {
  ErrorMessage: "",
  Vehicles: [],
  ErrorFlag: "",
  Make: [],
  Year: [],
  Model: [],
  BodyType: [],
  Chassis: [],
  DriveType: [],
  EngineCode: [],
  EngineType: [],
  Generation: [],
  Series: [],
  SubModel: [],
  TransmissionType: [],
  TooManyVehiclesYN: ""
}

const initialState: IVehicleState = {
  Vehicles: initVehicleValuesState,
  IsLoaded: true,
  UsedAsFilter: true,
  AdvancedSearch: false,
  SearchString: "",
  paRtsVehicleID: null,
  VehicleSelected: null,
  VehicleToBeSelected: null,
  Initiated: false,
  ShowedUniversalProducts: true
};

const vehicleReducer = createReducer(initialState,
  on(SetUserSelectedVehicle, (state, action) => ({ ...state, VehicleSelected: action.payload })),
  on(LoadVehicles, (state, action) => ({ ...state, IsLoaded: false })),  
  on(LoadVehiclesSuccess, (state, action) => ({ ...state, Vehicles: action.payload, IsLoaded: true })),
  on(AdvancedSearchStatus, (state, action) => ({ ...state, AdvancedSearch: action.payload })),
  on(SearchString, (state, action) => ({ ...state, SearchString: action.payload })),
  on(SetPartsVehicleID, (state, action) => ({ ...state, paRtsVehicleID: action.payload })),
  on(UseInFilter, (state, action) => ({ ...state, UsedAsFilter: action.payload })),
  on(ShowUniversalProducts, (state, action) => ({ ...state, ShowedUniversalProducts: action.payload })),
  on(SelectVehicle, (state, action) => ({ ...state, VehicleSelected: action.payload})),
  on(DeselectVehicle, (state, action) => ({ ...state, VehicleSelected: null, Initiated: true })),
  on(RequestVehicleSelection, (state, action) => ({ ...state, VehicleToBeSelected: action.payload})),  
  on(ClearRequestSelectVehicle, (state, action) => ({ ...state, VehicleToBeSelected: null})),
  on(ResetVehicleSearch, (state, action) => ({ ...state, Vehicles: initVehicleValuesState })),  
  on(InitSelectedVehicle, (state, action) => ({ ...state, VehicleSelected: action.payload, Initiated: true })),
  on(UpdateVehicleDescription, (state, action) => ({ ...state, VehicleSelected: action.payload})),
)

export function reducer(state, action) {
  return vehicleReducer(state, action);
}


