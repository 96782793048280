import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerModule,
} from '@angular/platform-browser';
import {
  NgModule,
  APP_INITIALIZER,
  ErrorHandler,
  Injector,
} from '@angular/core';
import {
  DateAdapter,
  MatNativeDateModule,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  DateFnsAdapter,
  MatDateFnsModule,
} from '@angular/material-date-fns-adapter';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  NgbModule,
  NgbModalModule,
  NgbAccordionModule,
} from '@ng-bootstrap/ng-bootstrap';
import { enAU } from 'date-fns/locale';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginComponent } from './features/auth/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VehiclesModule } from './features/vehicles/vehicles.module';
import {
  CoreUiModule,
  ConfigService,
  IS_PROD,
  AuthTokenInterceptor,
} from '@pos-app/core-ui';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { GlobalErrorHandler } from './core/services/global.errorHandler';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataPersistence } from '@nrwl/angular';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ResourcesComponent } from './features/resources/resources.component';
import { ForgotPasswordComponent } from './features/auth/forgotpassword/forgotpassword.component';
import { SignUpComponent } from './features/auth/signup/signup.component';
import { OrdersModule } from './features/orders/orders.module';
import { ResetPasswordComponent } from './features/auth/resetpassword/resetpassword.component';
import { CustomersModule } from './features/customers/customers.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { ManageusersModule } from './features/manageusers/manageusers.module';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MatStepperModule } from '@angular/material/stepper';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { RegisterAdminComponent } from './features/register-admin/register-admin.component';
import { WarrantyformComponent } from './features/warrantyform/containers/warrantyform.component';
import { WarrantyformVehicleComponent } from './features/warrantyform/components/warrantyform-vehicle/warrantyform-vehicle.component';
import { WarrantyformOmeComponent } from './features/warrantyform/components/warrantyform-ome/warrantyform-ome.component';
import { WarrantyformHeaderComponent } from './features/warrantyform/components/warrantyform-header/warrantyform-header.component';
import { WarrantyformPartsComponent } from './features/warrantyform/components/warrantyform-parts/warrantyform-parts.component';
import { WarrantyformActionComponent } from './features/warrantyform/components/warrantyform-action/warrantyform-action.component';
import { LogintoshowComponent } from './features/auth/logintoshow/logintoshow.component';
import { GestureConfig } from '../gesture-config';
import { ContentComponent } from './features/content/content.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AllocateFitterComponent } from './features/filter/containers/allocate-fitter/allocate-fitter.component';
import { MatButtonModule } from '@angular/material/button';
import { OrdersForBookingComponent } from './features/filter/components/orders-for-booking/orders-for-booking.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OrderForBookingCardComponent } from './features/filter/components/order-for-booking-card/order-for-booking-card.component';
import { OrdersForFitterComponent } from './features/filter/components/orders-for-fitter/orders-for-fitter.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { CamperTrailerComponent } from './features/camper-trailer/containers/camper-trailer/camper-trailer.component';
import { CamperCustomerDetailsComponent } from './features/camper-trailer/components/camper-customer-details/camper-customer-details.component';
import { CamperTrailerDetailsComponent } from './features/camper-trailer/components/camper-trailer-details/camper-trailer-details.component';
import { CamperServiceDetailsComponent } from './features/camper-trailer/components/camper-service-details/camper-service-details.component';
import { CoreModule } from './core/core.module';
import { FitterFiltersComponent } from './features/filter/components/fitter-filters/fitter-filters.component';
import { FiltersReviewByWeekComponent } from './features/filter/components/filters-review-by-week/filters-review-by-week.component';
import { ArrayOfLineCardComponent } from './features/filter/components/array-of-line-card/array-of-line-card.component';
import { FittersComponent } from './features/filter/components/fitters/fitters.component';
import { AllocateOrderDialogComponent } from './features/filter/components/allocate-order-dialog/allocate-order-dialog.component';
import { EpcModule } from './features/epc/epc.module';
import { AppConsts } from './features/epc/constants/AppConsts';
import { NgxSpinnerModule } from 'ngx-spinner';

const appInitializerFn = (injector: Injector) => {
  return () => {
    const configService = injector.get(ConfigService);

    return configService.loadAppConfig().then(value => {
      const appConfig = configService.AppConfig
      AppConsts.ooeAPIBaseUrl = appConfig.backendUrl;
      AppConsts.brand = appConfig.brand;
    });
  };
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ResourcesComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    RegisterAdminComponent,
    WarrantyformComponent,
    WarrantyformVehicleComponent,
    WarrantyformOmeComponent,
    WarrantyformHeaderComponent,
    WarrantyformPartsComponent,
    WarrantyformActionComponent,
    LogintoshowComponent,
    ContentComponent,
    AllocateFitterComponent,
    FitterFiltersComponent,
    FiltersReviewByWeekComponent,
    OrdersForBookingComponent,
    OrderForBookingCardComponent,
    ArrayOfLineCardComponent,
    FittersComponent,
    OrdersForFitterComponent,
    AllocateOrderDialogComponent,
    CamperTrailerComponent,
    CamperCustomerDetailsComponent,
    CamperTrailerDetailsComponent,
    CamperServiceDetailsComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    NgbModule,
    NgbAccordionModule,
    FormsModule,
    ReactiveFormsModule,
    CoreUiModule,
    BrowserAnimationsModule,
    MatChipsModule,
    MatIconModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatAutocompleteModule,
    MatStepperModule,
    NgbModalModule,
    MatButtonModule,
    NgxSpinnerModule,
    AccordionModule.forRoot(),
    ToastrModule.forRoot({
      maxOpened: 1,
      preventDuplicates: true,
      autoDismiss: true,
    }),
    EffectsModule.forRoot([]),
    StoreModule.forRoot(
      {},
      {
        metaReducers: !environment.production ? [] : [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    !environment.production ? StoreDevtoolsModule.instrument() : [],

    // modules
    VehiclesModule,
    CustomersModule,
    OrdersModule,
    ManageusersModule,
    NgSelectModule,
    AngularEditorModule,
    HammerModule,
    DragDropModule,
    MatDialogModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDateFnsModule,
    EpcModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true,
    },
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [Injector],
    },
    {
      provide: IS_PROD,
      useValue: environment.production,
    },
    DataPersistence,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill' },
    },
    ToastrService,
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    {
      provide: MAT_DATE_LOCALE,
      useValue: enAU,
    },
    {
      provide: DateAdapter,
      useClass: DateFnsAdapter,
      //deps: [MAT_DATE_LOCALE],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
