import { Component, OnInit, Injector, ViewChild, AfterContentInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductProductPricesStocksComponent } from '../../components/product-prices-stocks/product-prices-stocks.component';
import { ProductProductAddToOrderComponent } from '../../components/product-add-to-order/product-add-to-order.component';
import { ProductProductAssociatedItemsComponent } from '../../components/product-associated-items/product-associated-items.component';
import { IProductOrderItem } from '../../models/product-order-item';
import { sumBy } from 'lodash';
import { OOEApiServiceProxy } from '../../services/ooe-api-service-proxy';
import { ItemAvaibility } from '../../models/itemAvailability.model';
import { AddItemToOrderInputDto, AddItemToOrderV2InputDto } from '../../models/addItemToOrder.model';
import { Store } from '@ngrx/store';
import { getStateSelectedVehicle, getStateShowedUniversalProducts, getStateUsedAsFilter } from '../../+store/vehicles';
import { Subject, from, Observable, combineLatest } from 'rxjs';
import {
  GetProductDetailInput,
  GetProductDetailOutput,
  ProductRichContentDto,
  ProductAttributeDto,
  DetailLevel,
  ProductAssociatedItem,
} from '../../models/service-proxies';
import { FetchItemPriceGroupItemPriceObject } from '../../models/fetItemPriceGroup.model';
import { takeUntil, finalize, map, concatMap, withLatestFrom, debounceTime, skip } from 'rxjs/operators';
import { AppConsts } from '../../constants/AppConsts';
import { IOOEOrder } from '../../models/userState.model';
import { ToastrService } from 'ngx-toastr';
import { GetPriceAndStockInput, PriceAndStock } from '../../models/price-and-stock.model';
import { EpcApiService } from '../../services/epc-api.service';
import { AssociatedItem, GetProductDetails } from '../../models/product-details';
import { MessageService } from '@pos-app/core-ui';
import { OOESessionService } from '../../../../../../../../libs/core-ui/src/lib/services/ooe-session.service';
import { IMainState } from '../../+store';
import { LayoutService } from 'libs/core-ui/src/lib/services/layout.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit, AfterContentInit {
  @ViewChild('pricesStocks', { static: false })
  public pricesStocksComponent: ProductProductPricesStocksComponent;
  @ViewChild('addToOrder', { static: false })
  public addToOrderComponent: ProductProductAddToOrderComponent;
  @ViewChild('associatedItems', { static: false })
  public associatedItemsComponent: ProductProductAssociatedItemsComponent;

  public currencyCode: string;
  public active: number = 1;
  public isProductLoading: boolean = false;
  public productNotFound: boolean = false;
  public branchId: number;
  public productQuery: GetProductDetailInput = new GetProductDetailInput();
  public productOutput: GetProductDetailOutput = new GetProductDetailOutput();
  public mainProduct: IProductOrderItem;
  public associatedItems: IProductOrderItem[] = [];
  public configuredPrices: number[] = [];
  public hasAssociatedItemWarning: boolean = true;
  public hasAssociatedFittmentWarning: boolean = true;
  public selectedVehicleId: any;
  public useVehicleInFilter: boolean;
  public showedUniversalProducts: boolean;
  public isFitted: boolean;
  public isEAIRules: boolean = true;
  public openOrder: IOOEOrder;
  public productInfoAttribute: ProductAttributeDto[];
  public productFitmentInfoAttribute: ProductAttributeDto[];
  public priceAndStockItems: PriceAndStock[] = [];
  public readOnlyEPCTF: boolean;

  public get isUserloggedIn(): boolean {
    return this.ooeSessionService.loginedSession != null;
  }

  public get userPostCode(): string {
    return this.ooeSessionService.getUserPostCode().trim();
  }

  private componentDestroyed$ = new Subject<void>();

  constructor(
    public injector: Injector,
    private epcApiService: EpcApiService,
    private store: Store<IMainState>,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private ooeAPIServiceProxy: OOEApiServiceProxy,
    private messageService: MessageService,
    private ooeSessionService: OOESessionService,
    private layoutService: LayoutService
  ) {}

  public ngOnInit(): void {
    this.layoutService.setIsSpotlightHidden(true);

    this.route.paramMap
      .pipe(
        takeUntil(this.componentDestroyed$),
        withLatestFrom(combineLatest([this.store.select(getStateUsedAsFilter), this.store.select(getStateSelectedVehicle)]))
      )
      .subscribe(([params, [usedAsFilter, vehicle]]) => {
        this.productQuery.productNumber = decodeURIComponent(params.get('productNumber'));
        this.useVehicleInFilter = usedAsFilter;
        this.selectedVehicleId = vehicle ? Number(vehicle.partsVehicleID) : undefined;

        if (this.productQuery && this.productQuery.productNumber) {
          this.getProductDetail();
        }
      });

    combineLatest([
      this.store.select(getStateUsedAsFilter),
      this.store.select(getStateSelectedVehicle),
      this.store.select(getStateShowedUniversalProducts),
    ])
      .pipe(
        takeUntil(this.componentDestroyed$),
        map((data) => {
          const [usedAsFilter, selectedVehicle, showedUniversalProducts] = data;

          this.useVehicleInFilter = usedAsFilter;
          this.selectedVehicleId = selectedVehicle ? Number(selectedVehicle.partsVehicleID) : undefined;
          this.showedUniversalProducts = showedUniversalProducts;

          return data;
        }),
        debounceTime(500),
        skip(1)
      )
      .subscribe((data) => {
        if (this.productOutput.productInfo) {
          this.getProductDetail();
        }
      });

    this.messageService
      .listen('postcodeUpdate')
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        this.refreshPricesWithPostCode();
      });
    this.messageService
      .listen('userStateUpdate')
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        this.refreshPricesWithPostCode();
      });
  }

  public ngAfterContentInit(): void {
    if (this.ooeSessionService.loginedSession) {
      this.getOpenOrder();
      this.ooeSessionService.ooeUserState$.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => {
        this.getOpenOrder();
      });
    }
  }

  public ngOnDestroy(): void {
    this.layoutService.setIsSpotlightHidden(false);

    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  public getOpenOrder(): void {
    this.openOrder = this.ooeSessionService.openOrder;
    this.readOnlyEPCTF = this.openOrder?.readOnlyEPCTF;
  }

  public updateMainProductPrices(): void {
    if (!this.ooeSessionService.loginedSession && !this.ooeSessionService.getUserPostCode().trim()) {
      return;
    }
    this.updateOrderConfiguredPrices();
  }

  public addItemToOrder(quantity: number, isCreatedOrderSilent = false): void {
    if (!this.ooeSessionService.hasActiveOrder && isCreatedOrderSilent == false) {
      this.createOrderSilent(quantity);
      return;
    }

    let selectedItems: AddItemToOrderInputDto[] = [];

    // Add main product and mandatory items
    this.getNewItem(selectedItems, this.mainProduct, this.associatedItems);

    let selectedItemsV2: AddItemToOrderV2InputDto[] = selectedItems.map((item) => {
      let productLine = new AddItemToOrderV2InputDto();
      productLine.orderNumber = item.orderNumber;
      productLine.itemNumber = item.parentItemCode;
      productLine.quantity = item.quantity;
      productLine.overridePriceYN = 'N';
      productLine.useEPCYN = 'Y';
      productLine.fetchInventoryYN = 'Y';
      productLine.enforceMandatoryAssociationYN = this.isEAIRules ? 'Y' : 'N';
      productLine.fitYN = item.fittedYN;
      productLine.paintYN = item.paintYN;
      productLine.unitPriceEx = 0;
      productLine.unitPriceInc = 0;
      productLine.sourceSystem = 'EPC';

      return productLine;
    });

    let productAddedCount = 0;
    from(selectedItemsV2)
      .pipe(
        takeUntil(this.componentDestroyed$),
        concatMap((item) => this.ooeAPIServiceProxy.addItemToOrder(item))
      )
      .subscribe(() => {
        productAddedCount++;

        if (selectedItemsV2.length > 1) {
          this.toastr.success('Item ' + productAddedCount + '/' + selectedItemsV2.length + ' added', null, {
            timeOut: productAddedCount < selectedItemsV2.length ? 300 : 3000,
          });
        } else {
          this.toastr.success('Item added');
        }

        if (productAddedCount == selectedItemsV2.length) {
          this.messageService.trigger('orderUpdated');
        }
      });
  }

  public recievechangeEAIRules(isEAIRules: boolean): void {
    this.isEAIRules = isEAIRules;
  }

  public updateOrderItem(item: IProductOrderItem): void {
    this.updateOrderConfiguredPrices();
  }

  private getProductDetail(): void {
    this.isProductLoading = true;
    this.productNotFound = false;
    this.productQuery.mode = DetailLevel.Full;
    this.productQuery.brand = AppConsts.brand;
    this.productQuery.priceList = this.ooeSessionService.priceLists;
    this.productQuery.showUniversalProducts = !!this.selectedVehicleId;
    this.productQuery.vehicleId = this.useVehicleInFilter ? this.selectedVehicleId : null;
    this.epcApiService
      .getProductDetail(this.productQuery)
      .pipe(
        takeUntil(this.componentDestroyed$),
        finalize(() => {
          this.isProductLoading = false;
          window.scroll(0, 0);
        })
      )
      .subscribe((result) => {
        if (result?.productInfo) {
          this.productOutput = this.transformProductData(result);

          this.buildSRPUrlForBreadcrumb(this.productOutput.productInfo.cedCategoryId, this.productOutput.productInfo.companySKU);

          this.buildTechSpecAttributes(this.productOutput);
          this.buildMainProduct();
          this.buildAssociatedItems();
          this.buildProductImages();
          this.buildPriceAndStock();

          this.isFitted =
            this.productOutput.productInfo.universal || this.productOutput.applications.some((app) => app.paRtsVehicleID == this.selectedVehicleId);

          this.hasAssociatedFittmentWarning = this.productOutput.applications?.length > 0;
          this.hasAssociatedItemWarning =
            this.productOutput.associatedItems?.filter(
              (item) => item.isMandatory && (!this.useVehicleInFilter || (this.useVehicleInFilter && this.selectedVehicleId && item.isFitted))
            )?.length > 0;
        } else {
          this.productNotFound = true;
        }
      });
  }

  private transformProductData(result: GetProductDetails): GetProductDetailOutput {
    const productOutput = new GetProductDetailOutput({
      productInfo: result.productInfo,
      addFitDescription: result.addFitDescription,
      applications: result.applications,
      attributes: result.attributes,
      images: result.images,
      found: result.found,
      fromProductPage: result.fromProductPage,
      fitDescription: result.fitDescription,
      fittingInstructions: result.fittingInstructions,
      associatedItems: result.associatedItems?.map((item) => {
        const bundleAssociatedItems = item.bundleComponents.map((bundleItem) => {
          return {
            ...bundleItem,
            associatedType: 'Mandatory Bundle',
            isMandatory: true,
          } as AssociatedItem;
        });

        if (item.fitableYN == 'Y') {
          item.associatedItems.push({
            companySKU: item.fitProductCode,
            quantity: 1,
            associatedType: 'Fit',
            unitPrice: item.fitUnitPriceIncTax,
          } as AssociatedItem);
        }

        if (item.paintableYN == 'Y') {
          item.associatedItems.push({
            companySKU: item.paintProductCode,
            quantity: 1,
            associatedType: 'Paint',
          } as AssociatedItem);
        }

        /* sub associatedItems */
        item.associatedItems.forEach((subItem) => {
          if (subItem.fitableYN == 'Y') {
            subItem.associatedItems.push({
              companySKU: subItem.fitProductCode,
              quantity: 1,
              associatedType: 'Fit',
              unitPrice: subItem.fitUnitPriceIncTax || subItem['fitUnitPrice'],
            } as AssociatedItem);
          }
          if (subItem.paintableYN == 'Y') {
            subItem.associatedItems.push({
              companySKU: subItem.paintProductCode,
              quantity: 1,
              associatedType: 'Paint',
            } as AssociatedItem);
          }
        });

        item.associatedItems = item.associatedItems.concat(bundleAssociatedItems);
        return new ProductAssociatedItem(item as any);
      }),
    });
    return productOutput;
  }

  private buildSRPUrlForBreadcrumb(categoryId, companySKU): void {
    this.messageService.trigger('productUpdated', {
      categoryId,
      companySKU,
    });
  }

  private buildPriceAndStock(): void {
    this.getPriceAndStock(this.mainProduct, this.associatedItems)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        this.getAllItemsPriceAndStocks();
      });
  }

  private buildProductImages(): void {
    if (this.productOutput.images.length == 0) {
      var defaultImage = new ProductRichContentDto();

      defaultImage.init({
        type: 1,
        fileName: '',
        contentUrl: '',
        contentUrlThumb: '',
        contentDescription: '',
      });

      this.productOutput.images.push(defaultImage);
    }
  }

  private buildTechSpecAttributes(result: GetProductDetailOutput): void {
    if (result.attributes && result.productInfo) {
      this.productInfoAttribute = result.attributes.filter((item) => item.attrGroup === 0 || item.attrGroup === 1);
      const productDimension: ProductAttributeDto[] = [];
      if (result.productInfo.weight) {
        productDimension.push(
          new ProductAttributeDto({
            attrGroup: 0,
            attribute: 'Net Weight(Kg)',
            value: result.productInfo.weight,
            sortNumber: 0,
            filterable: false,
          })
        );
      }
      if (result.productInfo.width) {
        productDimension.push(
          new ProductAttributeDto({
            attrGroup: 0,
            attribute: 'Width(cm)',
            value: result.productInfo.width,
            sortNumber: 0,
            filterable: false,
          })
        );
      }
      if (result.productInfo.depth) {
        productDimension.push(
          new ProductAttributeDto({
            attrGroup: 0,
            attribute: 'Height(cm)',
            value: result.productInfo.depth,
            sortNumber: 0,
            filterable: false,
          })
        );
      }
      if (result.productInfo.length) {
        productDimension.push(
          new ProductAttributeDto({
            attrGroup: 0,
            attribute: 'Length(cm)',
            value: result.productInfo.length,
            sortNumber: 0,
            filterable: false,
          })
        );
      }
      if (result.productInfo.barCode) {
        productDimension.push(
          new ProductAttributeDto({
            attrGroup: 0,
            attribute: 'Bar Code',
            value: result.productInfo.barCode,
            sortNumber: 0,
            filterable: false,
          })
        );
      }
      this.productInfoAttribute = [...productDimension, ...this.productInfoAttribute];
      this.productFitmentInfoAttribute = result.attributes.filter((item) => item.attrGroup === 2);
    }
  }

  private buildMainProduct(): void {
    this.mainProduct = this.createOrderItem(this.productOutput.productInfo);
    this.mainProduct.quantity = 1;
  }

  private buildAssociatedItems(): void {
    let items: IProductOrderItem[] = [];

    this.productOutput.associatedItems.forEach((item) => {
      let orderItem = this.buildAssociateItem(item);

      // TODO DUNGNP 1. change description: use CompanyProductTitle, 2. Show correct FUEL, WHEEL...
      // if the item is Paint (Color Code), then change group to SUPPLEMENTARY_CHARGE_GROUP_NAME
      if (item.standardDescription == '1871') {
        orderItem.associatedType = item.companySKU.startsWith('CC') ? 'Paint' : 'SupplementaryCharge';
        orderItem.addtionalProductData.supplementGroupItemName = 'PAINTED';
        orderItem.quantity = 1;
        orderItem.cedCategory = AppConsts.SUPPLEMENTARY_CHARGE_GROUP_NAME;
      }

      // if the item is FIT, add the price as calculated from API
      if (item.associatedType == 'Fit') {
        orderItem.uom = 'Each';
        orderItem.addtionalProductData.supplementGroupItemName = 'FITTED';
      }

      if (item.associatedType == 'Paint') {
        orderItem.quantity = 1;
        orderItem.addtionalProductData.supplementGroupItemName = 'PAINTED';
      }

      items.push(orderItem);
    });

    /* Additional Fit if associatedItems have no Fit*/
    if (this.productOutput.productInfo.fitableYN == 'Y') {
      items.push({
        productNumber: this.productOutput.productInfo.companySKU,
        cedCategory: AppConsts.SUPPLEMENTARY_CHARGE_GROUP_NAME,
        brandId: this.mainProduct.brandId,
        companySKU: 'ADDFIT',
        associatedType: 'Fit',
        quantity: 1,
        addtionalProductData: {
          companyProductTitle: 'Additional Fit',
          supplementGroupItemName: 'Charges in addition to standard product fitment',
        },
        prices: [],
        associatedItems: [],
      } as IProductOrderItem);
    }

    if (this.productOutput.productInfo.isBundle || this.productOutput.productInfo.isKit) {
      let bundleMembersThatHasFittingTime = items.filter((t) => t.associatedType == 'Mandatory Bundle' && t.fitableYN == 'Y');
      let bundleMembersThatHasPaintOption = items.filter((t) => t.associatedType == 'Mandatory Bundle' && t.paintableYN == 'Y');

      if (this.productOutput.productInfo.fitableYN != 'Y' && bundleMembersThatHasFittingTime.length) {
        // check if bundle component has fitting time values -> add a fitting line to the main bundle supplementary charge
        let fitProdCode = bundleMembersThatHasFittingTime[0].fitProductCode;
        let fitUnitPriceExTax = sumBy(
          bundleMembersThatHasFittingTime,
          (item) =>
            item.quantity *
            Number(item.addtionalProductData.fitBundleUnitPriceExTax ? item.addtionalProductData.fitBundleUnitPriceExTax : item.fitUnitPriceExTax)
        );
        let fitUnitPriceIncTax = sumBy(
          bundleMembersThatHasFittingTime,
          (item) =>
            item.quantity *
            Number(item.addtionalProductData.fitBundleUnitPriceIncTax ? item.addtionalProductData.fitBundleUnitPriceIncTax : item.fitUnitPriceIncTax)
        );

        items.push({
          productNumber: fitProdCode,
          companySKU: fitProdCode,
          cedCategory: AppConsts.SUPPLEMENTARY_CHARGE_GROUP_NAME,
          brandId: this.mainProduct.brandId,
          associatedType: 'Fit',
          quantity: 1,
          addtionalProductData: {
            companyProductTitle: 'Fit',
            supplementGroupItemName: 'FITTED',
            isBundleMetaInfo: true,
            bundleComponentFitExTax: fitUnitPriceExTax,
            bundleComponentFitIncTax: fitUnitPriceIncTax,
          },
          prices: [],
          customerUnitPrice: fitUnitPriceExTax,
          customerTaxedUnitPrice: fitUnitPriceIncTax,
          associatedItems: [],
          fitProductCode: fitProdCode,
          fitProductDesc: fitProdCode,
          uom: 'Each',
          fitableYN: 'Y',
          fittedYN: 'N',
        } as IProductOrderItem);
      }

      if (this.productOutput.productInfo.paintableYN != 'Y' && bundleMembersThatHasPaintOption.length) {
        let paintCodes = [];
        bundleMembersThatHasPaintOption.forEach((itm) => {
          if (paintCodes.find((cd) => cd == itm.companySKU) == undefined) {
            paintCodes.push(itm.paintProductCode);
          }
        });

        items.push({
          brandId: this.mainProduct.brandId,
          companySKU: '', // N/A
          paintDesc: '', // N/A
          paintProductCode: '', // N/A because there can be multiple paint codes from multiple bundle items
          associatedType: 'Paint',
          cedCategory: AppConsts.SUPPLEMENTARY_CHARGE_GROUP_NAME,
          originalQuantity: 1,
          quantity: 1,
          associatedItems: [],
          prices: [],
          paintableYN: 'Y',
          paintYN: 'N',
          uom: 'Each',
          addtionalProductData: {
            companyProductTitle: 'Bundle Items Paint',
            supplementGroupItemName: 'PAINTED',
            isBundleMetaInfo: true,
            bundleComponentPaintCodes: paintCodes.join(','),
          },
          infoMessage: 'Total of Bundle Items Paint',
        } as IProductOrderItem);
      }
    }

    this.associatedItems = items;
    console.log(items);

    this.updateOrderConfiguredPrices();
  }

  private buildAssociateItem(item: ProductAssociatedItem): IProductOrderItem {
    let orderItem = this.createOrderItem(item);

    (item.associatedItems || []).forEach((assocItm) => {
      if (assocItm.associatedType == 'Fit') {
        let subOrderItemFitted = this.createOrderItem(assocItm);

        subOrderItemFitted.uom = 'Each';
        subOrderItemFitted.addtionalProductData.supplementGroupItemName = 'FITTED';

        orderItem.associatedItems.push(subOrderItemFitted);
      } else if (assocItm.associatedType == 'Paint') {
        let subOrderItemPaitted = this.createOrderItem(assocItm);

        subOrderItemPaitted.addtionalProductData.supplementGroupItemName = 'PAINTED';

        orderItem.associatedItems.push(subOrderItemPaitted);
      } else {
        orderItem.associatedItems.push(this.buildAssociateItem(assocItm));
      }
    });

    return orderItem;
  }

  private updateOrderConfiguredPrices(): void {
    let mainQuantity = this.mainProduct.quantity || 0;
    this.configuredPrices = [];

    this.mainProduct.prices.forEach((price) => {
      let addedPrice = 0;

      if (mainQuantity > 0) {
        this.associatedItems.forEach((item) => {
          if (item.quantity && item.isSelected && !item.isMandatory && item.prices?.length) {
            let basePrice = this.getAssociatedItemPrice(price.priceScheduleDescription, item);

            if (item.associatedType == 'Fit' || item.associatedType == 'Paint' || item.associatedType == 'SupplementaryCharge') {
              addedPrice += mainQuantity * basePrice;
            } else {
              addedPrice += basePrice;
            }
          }
        });
      }

      this.configuredPrices.push(mainQuantity * price.taxedUnitPrice + addedPrice);
    });

    this.currencyCode = this.mainProduct.prices?.length ? this.mainProduct.prices[0].currency : 'AUD';
  }

  private getAssociatedItemPrice(priceScheduleDescription: string, item: IProductOrderItem): number {
    let addedPrice = 0;
    let basePriceItem =
      item.associatedType == 'Fit' ? item.prices[0] : item.prices.find((t) => t.priceScheduleDescription == priceScheduleDescription);

    if (basePriceItem) {
      let basePrice = basePriceItem.taxedUnitPrice;

      item.associatedItems.forEach((subItem) => {
        if (subItem.isSelected && subItem.prices?.length && subItem.quantity) {
          basePrice += this.getAssociatedItemPrice(priceScheduleDescription, subItem);
        }
      });

      addedPrice = item.quantity * basePrice;
    }

    return addedPrice;
  }

  private createOrderSilent(quantity: number) {
    this.ooeAPIServiceProxy
      .createOrderSilent()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((result) => {
        if (!result || result?.ErrorFlag == '1') {
          this.toastr.error(result.ErrorMessage);
          return;
        }

        this.toastr.success('Order Created');

        this.ooeSessionService
          .fetchUserState()
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(({ OpenOrder }) => {
            this.openOrder = OpenOrder;
            this.addItemToOrder(quantity, true);
            return;
          });
      });
  }

  private getNewItem(
    results: AddItemToOrderInputDto[],
    validatedItem: IProductOrderItem,
    associatedItems: IProductOrderItem[],
    parentQuantity?: number
  ) {
    const unitPriceExTax = validatedItem.customerUnitPrice;
    const unitPriceIncTax = validatedItem.customerTaxedUnitPrice;
    let quantity = validatedItem.quantity ? validatedItem.quantity : 0;

    if (
      validatedItem.associatedType == 'Mandatory Bundle' ||
      (validatedItem.associatedType == 'Fit' && validatedItem.companySKU != 'ADDFIT') ||
      validatedItem.associatedType == 'Paint' ||
      validatedItem.associatedType == 'Mandatory'
    ) {
      quantity *= parentQuantity ? parentQuantity : 1;
    }

    // if validated item is bundle but Enforce Association rule checkbox is not ticked, only add selected bundle items
    // else add only the bundle itemNumber ifself, the api will automatically add bundle items.
    if ((validatedItem.isKit || validatedItem.isBundle) && !this.isEAIRules) {
      associatedItems
        .filter((x) => x.isSelected)
        .map((item) => {
          this.getNewItem(results, item, item.associatedItems, quantity);
        });
    } else {
      if (quantity > 0) {
        let newItem = new AddItemToOrderInputDto({
          brand: AppConsts.brand,
          orderNumber: this.openOrder.orderNumber,
          parentItemCode: validatedItem.companySKU,
          parentItemDesc: validatedItem.addtionalProductData.companyProductTitle,
          parentItemQty: quantity.toString(),
          parentItemUOM: validatedItem.uom,
          paintableYN: validatedItem.addtionalProductData.paintableYN,
          paintYN: 'N',
          fitableYN: validatedItem.addtionalProductData.fitableYN,
          fittedYN: 'N',
          unitPriceExTax: unitPriceExTax,
          unitPriceIncTax: unitPriceIncTax,
          extPriceExTax: Number(Number(unitPriceExTax) * quantity).toFixed(2),
          extPriceIncTax: Number(Number(unitPriceIncTax) * quantity).toFixed(2),
          partsVehicleID: this.openOrder.partsVehicleID,
          fitProductCode: validatedItem.fitProductCode,
          fitProductDesc: validatedItem.fitProductCode,
          fitUnitPriceExTax: validatedItem.fitUnitPriceExTax,
          fitUnitPriceIncTax: validatedItem.fitUnitPriceIncTax,
          paintDesc: validatedItem.paintDesc,
          paintProductCode: validatedItem.paintProductCode,
          parentLineNumber: 0,
          productNumber: validatedItem.productNumber,
          quantity: validatedItem.quantity,
          nonStockLineYN: '',
          overridePriceAllowedYN: '',
          assocItems: [],
        });

        if (associatedItems.some((t) => t.associatedType == 'Fit' && t.isSelected && t.companySKU != 'ADDFIT')) {
          newItem.fittedYN = 'Y';
        }

        if (associatedItems.some((t) => t.associatedType == 'Paint' && t.isSelected)) {
          newItem.paintYN = 'Y';
        }

        results.push(newItem);

        if (!this.isEAIRules) {
          // if EAI rule is not enforced, add selected associated items as new separated line
          associatedItems.forEach((x) => {
            if (x.isSelected && x.associatedType != 'Paint' && (x.associatedType != 'Fit' || x.companySKU == 'ADDFIT')) {
              this.getNewItem(results, x, x.associatedItems, quantity);
            }
          });
        } else {
          // if EAI rule is enforced,
          // do nothing to mandatory associated items (because it will automatically added by api),
          // only collect selected optional ones to new lines
          associatedItems.forEach((x) => {
            if (x.isSelected && !x.isMandatory && x.associatedType != 'Paint' && (x.associatedType != 'Fit' || x.companySKU == 'ADDFIT')) {
              this.getNewItem(results, x, x.associatedItems, quantity);
            }
          });
        }
      } else {
        // handle the case main product quantity = 0 but customer select some associated items to build.
        // if EAIR is ticked, DONOT add mandatory associated item (which have selection checkboxes disabled)
        associatedItems.forEach((x) => {
          if (
            x.isSelected &&
            x.associatedType != 'Fit' &&
            x.associatedType != 'Paint' &&
            (!this.isEAIRules || (this.isEAIRules && x.associatedType != 'Mandatory'))
          ) {
            this.getNewItem(results, x, x.associatedItems, quantity);
          }
        });
      }
    }
  }

  private createOrderItem(item: any): IProductOrderItem {
    return {
      productNumber: item.productNumber,
      attributes: item.attributes,
      cedCategoryId: item.cedCategoryId,
      cedCategory: item.cedCategory,
      brandId: item.brandId,
      brandName: item.brandName,
      subBrand: item.subBrand,
      companySKU: item.companySKU || item.productNumber,
      isMandatory: item.isMandatory,
      associatedType: item.associatedType,
      isSelected: item.isSelected || item.isMandatory,
      originalQuantity: item.quantity,
      quantity: item.quantity,
      associatedItems: [],
      stocks: null,
      prices: [],
      fitProductCode: item.fitProductCode,
      fitProductDesc: item.fitProductDesc,
      fitUnitPriceExTax: item.fitUnitPriceExTax,
      fitUnitPriceIncTax: item.fitUnitPriceIncTax || item.fitUnitPrice,
      paintDesc: item.paintDesc,
      paintProductCode: item.paintProductCode,
      customerCurrency: '',
      customerUnitPrice: '',
      customerTaxedUnitPrice: '',
      paintableYN: item.paintableYN,
      paintYN: 'N',
      fitableYN: item.fitableYN,
      fittedYN: 'N',
      numberOfComponentsPerProduct: item.uom,
      uom: item.uom,
      addtionalProductData: item,
      infoMessage: item.associatedType == 'Mandatory Bundle' ? 'Mandatory Bundle Item' : item.associatedType + ' Associated Item',
      isConflicting: false,
      conflictingWith: '',
      isBundle: item.isBundle,
      isKit: item.isKit,
      productType: item.productType,
    };
  }

  private getPrices(itemCode: string): FetchItemPriceGroupItemPriceObject[] {
    const priceAndStockItem = this.priceAndStockItems?.find((item) => item.itemCode === itemCode);

    if (priceAndStockItem) {
      const prices = (priceAndStockItem.priceList || []).map((priceItem) => {
        return new FetchItemPriceGroupItemPriceObject({
          priceSchedule: priceItem.priceScheduleCode,
          priceScheduleDescription: priceItem.priceScheduleDescription,
          currency: priceItem.currency,
          unitPrice: Number(priceItem.exTaxUnitPrice),
          taxedUnitPrice: Number(priceItem.taxedUnitPrice),
          taxedYN: priceItem.taxedYN,
          quantity: Number(priceItem.quantity),
          priceLabel: priceItem.priceScheduleDescription,
        });
      });

      return prices;
    }

    return [];
  }

  private getStock(itemCode: string): ItemAvaibility {
    const priceAndStockItem = this.priceAndStockItems?.find((item) => item.itemCode === itemCode);

    /* TODO: Check with Beau about availabilityMode, qtyUncommitted and lowMediumHighValue */
    if (priceAndStockItem) {
      return {
        availabilityMode: '1',
        SearchResults: (priceAndStockItem.stock || []).map((item) => {
          return {
            branchCode: item.branchCode,
            branchName: item.branchName,
            qtyAvailable: item.qtyAvailable,
            qtyUncommitted: null,
            lowMediumHighValue: null,
          };
        }),
      } as ItemAvaibility;
    }
    return {
      availabilityMode: '1',
      SearchResults: [],
    } as ItemAvaibility;
  }

  private getAllItemsPriceAndStocks(): void {
    /* init main product prices */
    this.mainProduct.prices = this.getPrices(this.mainProduct.companySKU);
    this.mainProduct.stocks = this.getStock(this.mainProduct.companySKU);
    this.updateOrderConfiguredPrices();

    /* init associatedItems prices */
    const associatedItems: IProductOrderItem[] = [];
    this.associatedItems.forEach((item) => {
      this.traverse(this.mainProduct, item, associatedItems);
    });

    associatedItems.forEach((item) => {
      item.prices = this.getPrices(item.companySKU);
      item.stocks = this.getStock(item.companySKU);
    });
  }

  /* this function to generate list of items that need to get prices */
  private traverse(parentNode: IProductOrderItem, node: IProductOrderItem, allItems: IProductOrderItem[] = []): void {
    /* Fit get the prices from parent */
    if (node.associatedType == 'Fit' && node.companySKU === 'FIT') {
      node.customerCurrency = this.currencyCode;

      if (!node.customerUnitPrice) {
        node.customerUnitPrice = parentNode.fitUnitPriceExTax;
      }

      if (!node.customerTaxedUnitPrice) {
        node.customerTaxedUnitPrice = parentNode.fitUnitPriceIncTax || parentNode['fitUnitPrice'];
      }

      node.prices = [
        new FetchItemPriceGroupItemPriceObject({
          priceSchedule: null,
          priceScheduleDescription: 'My Price',
          currency: this.currencyCode,
          unitPrice: Number(node.customerUnitPrice),
          taxedUnitPrice: Number(node.customerTaxedUnitPrice),
          taxedYN: 'N',
          quantity: 1,
          priceLabel: 'My Price',
        }),
      ];
      node.stocks = this.getStock(node.companySKU);
    } else {
      allItems.push(node);
    }

    node.associatedItems?.forEach((childNode) => {
      this.traverse(node, childNode, allItems);
    });
  }

  private refreshPricesWithPostCode() {
    if (this.mainProduct) {
      this.buildPriceAndStock();
    }
  }

  private getPriceAndStock(
    mainProduct: IProductOrderItem,
    associatedItems: IProductOrderItem[]
  ): Observable<{
    items: PriceAndStock[];
  }> {
    const input: GetPriceAndStockInput = { itemCodes: [] };
    input.itemCodes.push({
      itemCode: mainProduct.companySKU,
      quantity: mainProduct.quantity,
    });

    this.getAllAssociatedItems(associatedItems).forEach((item) => {
      input.itemCodes.push({
        itemCode: item.companySKU,
        quantity: item.quantity,
      });
    });

    return this.epcApiService.getPriceAndStock(input).pipe(
      map((res) => {
        this.priceAndStockItems = res.items;

        return res;
      })
    );
  }

  private getAllAssociatedItems(associatedItems: IProductOrderItem[], currentItems: IProductOrderItem[] = [], isBundle = false): IProductOrderItem[] {
    associatedItems.forEach((item) => {
      const existed = currentItems.some((i) => i.companySKU == item.companySKU);

      if (!existed && item.companySKU) {
        if (!isBundle || (isBundle && item.associatedType.includes('CC'))) {
          currentItems.push(item);
        }
      }

      if (item.associatedItems?.length > 0) {
        this.getAllAssociatedItems(item.associatedItems, currentItems, item.isBundle);
      }
    });

    return currentItems;
  }
}
