import {
  VehicleFilterDropdownValues,
  FILTER_KEYS,
  getFormFilterKey
} from '@pos-app/data';

/**
 * Handle filters and text pills
 * @param allValues -  all fitler value arrays return by the API
 * @param vehicleSearchForm - the current vehicle search form values
 * @param searchPills - the current array of all search pills
 * @param searchStringMapToFilter - the current mapping the filter (whose value is unique) to the search pill
 */
export function manageFilters(
  allValues: VehicleFilterDropdownValues,
  vehicleSearchForm: any,
  searchPills: string[],
  searchStringMapToFilter: object
) {
  let suggestedFilters = '';
  const initialLoad =
    allValues.Make.length > 1 &&
    allValues.Year.length > 1 &&
    allValues.Model.length > 1;
  const patchValues = {};
  // Get the last input string from text box's text pills
  let inputString =
    searchPills.length > 0 ? searchPills[searchPills.length - 1] : '';
  const previousSearchString = inputString;
  const updatedSearchPills = searchPills;
  const updatedSearchStringMapToFilter = searchStringMapToFilter;
  FILTER_KEYS.forEach(key => {
    let isFilterSelection = false;
    const formKey = getFormFilterKey(key);
    if (allValues[key].length === 1) {
      const uniqueValue = allValues[key][0].toString();
      // when the fitler value is unique and equals to the form value ==> it's a filter selection input
      if (
        uniqueValue !== '' &&
        vehicleSearchForm[formKey] === uniqueValue
      ) {
        isFilterSelection = true;
      } else {
        // when the filter value is unique and not equal to the form value ==> to set the form value to the distinct value
        patchValues[formKey] = uniqueValue;
      }
      if (previousSearchString !== '') {
        // find if the unique filter value is in the search text
        const indexOfUniqueValue =
          inputString !== '' && uniqueValue !== ''
            ? inputString.toLowerCase().indexOf(uniqueValue.toLowerCase())
            : -1;
        // if unique value is in the search text, split the texts into 2 pills
        // and map the filter key with the unique value
        if (indexOfUniqueValue !== -1) {
          updatedSearchStringMapToFilter[key] = uniqueValue;
          inputString =
            inputString.substr(0, indexOfUniqueValue) +
            inputString.substr(indexOfUniqueValue + uniqueValue.length + 1);
          updatedSearchPills.pop();
          updatedSearchPills.push(uniqueValue);
          if (inputString !== '') {
            updatedSearchPills.push(inputString);
          }
        } else {
          // if unique value is not in the search text and it's not a filter selection input
          // map the filter key with the original text
          if (!isFilterSelection) {
            updatedSearchStringMapToFilter[key] = previousSearchString;
          }
        }
      }
    } else {
      patchValues[formKey] = '';
      if (!initialLoad) {
        suggestedFilters = suggestedFilters === '' ? key : suggestedFilters;
      }
    }
  });
  return {
    patchValues,
    suggestedFilters,
    initialLoad,
    updatedSearchPills,
    updatedSearchStringMapToFilter
  };
}

/**
 * Remove a pill
 * @param stringToRemove - the pill text to be removed
 * @param searchStringMapToFilter - the current mapping the filter (whose value is unique) to the search pill
 */
export function getPatchValuesWhenRemovingPill(
  stringToRemove: string,
  searchStringMapToFilter: object
) {
  const updatedSearchStringMapToFilter = searchStringMapToFilter;
  // loop through the filter map list to find the keys whose values contain the text pill being removed
  const foundFilterKeys = Object.keys(searchStringMapToFilter).filter(
    key =>
      searchStringMapToFilter[key]
        .toLowerCase()
        .indexOf(stringToRemove.toLowerCase()) !== -1
  );

  // reset form values of the filter keys having the text pill removed
  if (foundFilterKeys !== []) {
    const patchValues = {};
    foundFilterKeys.forEach(key => {
      const formKey = getFormFilterKey(key);
      patchValues[formKey] = '';
      delete updatedSearchStringMapToFilter[key];
    });

    return {
      patchValues,
      updatedSearchStringMapToFilter
    }
  }
}
