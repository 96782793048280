<pos-app-spinner
  [show]="(loadingRegistered$ | async) || (savingSecurityFunctions$ | async)"
></pos-app-spinner>
<div class="container-fluid">
  <div class="text-center">
    <div *ngIf="!isManageProfile">
      <h3 class="pt-3 arb-large-heading">MANAGE INTERNAL USERS</h3>
      <p>Use this screen to manage your ARB employee's access to ARB Online</p>
    </div>
    <div *ngIf="isManageProfile">
      <h3 class="pt-3 arb-large-heading">MANAGE MY PROFILE</h3>
      <p>
        Use this screen to manage your default branch and printer, you will need
        to log out and back in again for changes to take effect.
      </p>
    </div>
  </div>
  <div class="card mt-4" *ngIf="!isManageProfile">
    <div class="card-body">
      <form>
        <div class="mr-3 searchBox">
          <div class="row">
            <div class="col-5 d-flex">
              <label class="px-5 px-md-0 pr-md-2 my-auto">USERNAME</label>
              <input
                [ngClass]="{ expanded: show }"
                type="text"
                placeholder="Enter name or JDE username"
                class="flex-grow-1 form-control"
                (focus)="show = true; $event.target.select()"
                (blur)="lostFocus()"
                [formControl]="unregisteredUserSearch"
              />
              <span
                class="align-self-center pl-2"
                *ngIf="loadingUnregistered$ | async"
              >
                <pos-app-spinner2 [dark]="true"></pos-app-spinner2>
              </span>
            </div>
            <div class="col-4 pl-5 my-auto">
              {{ selectedUnregisteredUser?.userName }}
            </div>
            <div class="col-3 d-flex justify-content-end">
              <button
                class="btn mr-5 mr-md-0"
                (click)="registerUser()"
                [disabled]="!selectedUnregisteredUser"
              >
                REGISTER
              </button>
            </div>
          </div>
        </div>
        <div>
          <table
            class="table border my-4 shadow-lg searchResult"
            *ngIf="show && (unregisteredUsers$ | async)?.length > 0"
          >
            <thead>
              <tr>
                <th scope="col" class="px-3">Username</th>
                <th scope="col">UserID</th>
                <th scope="col">Branch</th>
              </tr>
            </thead>
            <tbody>
              <tr
                (click)="selectUnregisteredUser(user)"
                *ngFor="let user of unregisteredUsers$ | async; let i = index"
              >
                <td class="px-3">
                  <ngb-highlight
                    [result]="user.userName"
                    [term]="unregisteredUserSearch.value"
                  >
                  </ngb-highlight>
                </td>
                <td class="pr-3">
                  <ngb-highlight
                    [result]="user.userID"
                    [term]="unregisteredUserSearch.value"
                  ></ngb-highlight>
                </td>
                <td class="pr-3">
                  <ngb-highlight [result]="user.branch"></ngb-highlight>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </div>
  </div>

  <div class="card mt-4" *ngIf="!isManageProfile">
    <div class="card-body">
      <div class="d-flex align-items-center">
        <label class="my-auto">SEARCH REGISTERED USER</label>
        <span class="px-2"></span>
        <input
          type="text"
          class="form-control w-25"
          [(ngModel)]="userId"
          (keyup.enter)="searchRegisteredUser()"
        />
        <span class="px-2"></span>
        <button class="btn text-white" (click)="searchRegisteredUser()">
          SEARCH
        </button>
        <span class="px-2"></span>
        <button class="btn text-white" (click)="fetchAllUsers()">
          FETCH ALL USERS
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="{ data: registeredUsers$ | async } as registerUsers">
    <div class="card mt-4">
      <div class="card-body">
        <form>
          <div class="d-flex justify-content-end" *ngIf="!isManageProfile">
            <label class="pr-3">FILTER EXISTING USERS</label
            ><input [formControl]="registeredUserSearch" />
          </div>
          <div class="table-responsive">
            <table class="border my-4 table table-bordered">
              <thead>
                <tr>
                  <th scope="col" class="text-uppercase">
                    <label>Action</label>
                  </th>
                  <th scope="col" class="text-uppercase">
                    <label>Username</label>
                  </th>
                  <th scope="col" class="text-uppercase">
                    <label>UserID</label>
                  </th>
                  <th scope="col" class="text-uppercase">
                    <label>Email</label>
                  </th>
                  <th scope="col" class="text-uppercase">
                    <label>Branch</label>
                  </th>
                  <th scope="col" class="text-uppercase">
                    <label>Default printer</label>
                  </th>
                  <th scope="col" class="text-uppercase">
                    <label>VALID ORDER TYPES</label>
                  </th>
                  <th scope="col" class="text-uppercase">
                    <label>Access Enabled</label>
                  </th>
                  <th
                    *ngFor="let function of securityFunctionList$ | async"
                    scope="col"
                    class="text-uppercase"
                  >
                    <label>{{ function }}</label>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let userForm of userForms; let i = index"
                  [formGroup]="userForm"
                  [hidden]="hideRow(i)"
                >
                  <td>
                    <mat-icon
                      (click)="save(i)"
                      title="Save"
                      [ngClass]="!userForm.dirty ? 'action-disabled' : ''"
                      >save</mat-icon
                    >
                  </td>
                  <td>
                    <ngb-highlight
                      [result]="userForm.controls.userName.value"
                      [term]="registeredUserSearch.value.toLowerCase()"
                    >
                    </ngb-highlight>
                  </td>
                  <td>
                    {{ userForm.controls.userID.value }}
                  </td>
                  <td>
                    {{ userForm.controls.email.value }}
                  </td>
                  <td>
                    <ng-select formControlName="branch" appendTo="body">
                      <ng-option
                        *ngFor="let branch of branchList"
                        [value]="branch.BranchCode"
                      >
                        <span class="pr-1" *ngIf="branch.BranchCode"
                          >{{ branch.BranchCode }} -</span
                        >
                        {{ branch.BranchDescription }}
                      </ng-option>
                    </ng-select>
                  </td>
                  <td>
                    <ng-select formControlName="printerRole" appendTo="body">
                      <ng-option
                        *ngFor="let printer of printerList"
                        [value]="printer.printerRole"
                      >
                        <span class="pr-1"
                          >{{ printer.printerDescription }} -</span
                        >
                        {{ printer.printerRole }}
                      </ng-option>
                    </ng-select>
                  </td>
                  <td>
                    <ng-select
                      formControlName="validOrderTypes"
                      appendTo="body"
                      [items]="(lookupLists$ | async)?.orderTypeList"
                      [multiple]="true"
                      bindLabel="OrderTypeDescription"
                      bindValue="OrderTypeCode"
                      [searchable]="true"
                    >
                    </ng-select>
                  </td>
                  <td class="text-center">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      formControlName="registered"
                    />
                  </td>
                  <td
                    class="text-center"
                    *ngFor="let function of securityFunctionList$ | async"
                  >
                    <input
                      type="checkbox"
                      class="form-check-input"
                      [name]="function"
                      (change)="toggleFunction(i, $event)"
                      [checked]="hasFunction(i, function)"
                      [disabled]="isManageProfile"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </form>
      </div>
    </div>
    <div
      class="d-flex justify-content-between p-2"
      *ngIf="!isManageProfile && registerUsers.data.length > pageSize"
    >
      <ngb-pagination
        [collectionSize]="userForms?.length"
        [(page)]="page"
        [pageSize]="pageSize"
      >
      </ngb-pagination>
    </div>
  </div>
</div>
