import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Customer } from '@pos-app/data';
import { DialogService } from '@pos-app/core-ui';

@Component({
  selector: 'app-customers-duplicate',
  templateUrl: './customers-duplicate.component.html',
  styleUrls: ['./customers-duplicate.component.scss']
})
export class CustomersDuplicateComponent implements OnInit {
  public newCustomerDetails: any;
  @Input() set setNewCustomerDetails(value) {
    this.newCustomerDetails = value;
  }
  public duplicateCustomers: Customer[];
  @Input() set setDuplicateCustomers(value) {
    if (value && value.length === 0) {
      this.createNewCustomer();
    }
    this.duplicateCustomers = value;
  }
  @Output() customerChoosen = new EventEmitter<Customer>();
  @Output() cancelled = new EventEmitter<void>();
  public selectedCustomer: Customer;

  constructor(     
    private dialogService: DialogService,
    ) {}

  ngOnInit() {}

  selectCustomer(customer) {
    this.selectedCustomer = customer;
  }

  chooseExistingCustomer() {
    if(!this.selectedCustomer ){
      this.dialogService.alert(
        "You must select an existing customer before pressing this button",
        'ERRORS',
        'OK',
        true
      );
    }else{
      this.customerChoosen.emit(this.selectedCustomer);
    }
    
  }

  createNewCustomer() {
    this.customerChoosen.emit(null);
  }

  cancel() {
    this.cancelled.emit();
  }
}
