import { NgModule } from '@angular/core';
import { FooterComponent } from './components/footer/footer.component';
import { CommonModule } from '@angular/common';
import { EmptyRouteComponent } from './components/empty-route/empty-route.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { CoreUiModule } from '@pos-app/core-ui';

@NgModule({
  imports: [CommonModule, CoreUiModule],
  exports: [FooterComponent, EmptyRouteComponent, NavbarComponent],
  declarations: [FooterComponent, EmptyRouteComponent, NavbarComponent],
  providers: [],
})
export class CoreModule {}
