import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CUSTOMERS_FEATURE_KEY, CustomersState } from './customers.reducer';

// Lookup the 'Customers' feature state managed by NgRx
const getCustomersState = createFeatureSelector<CustomersState>(
  CUSTOMERS_FEATURE_KEY
);

const getError = createSelector(
  getCustomersState,
  (state: CustomersState) => state.error
);
const getLoadingCustomerDetails = createSelector(
  getCustomersState,
  (state: CustomersState) => state.loadingCustomerDetails
);

const getSelectedCustomer = createSelector(
  getCustomersState,
  (state: CustomersState) => state.selectedCustomer
);

const getLookupLists = createSelector(
  getCustomersState,
  (state: CustomersState) => state.lookupLists
);

const getLoadingLookupLists = createSelector(
  getCustomersState,
  (state: CustomersState) => state.loadingLookupLists
);

const getCreatedCustomerId = createSelector(
  getCustomersState,
  (state: CustomersState) => state.createdCustomerId
);

const getIsCreatingCustomer = createSelector(
  getCustomersState,
  (state: CustomersState) => state.isCreatingCustomer
);

const getCustomerVehicles = createSelector(
  getCustomersState,
  (state: CustomersState) => {
    if (
      state.customerVehicles.length === 0 ||
      state.customerVehicles.length === 1
    ) {
      return state.customerVehicles;
    }
    const array = state.customerVehicles.slice();
    const defaultVehicle = array
      .sort((a, b) => Number(a.DefaultFlag) - Number(b.DefaultFlag))
      .pop();

    array
      .sort((a, b) => Number(b.customerVehicleID) - Number(a.customerVehicleID))
      .unshift(defaultVehicle);

    return array;
  }
);

const getLoadingCustomerVehicles = createSelector(
  getCustomersState,
  (state: CustomersState) => state.loadingCustomerVehicles
);

const getUpdatingCustomerVehicle = createSelector(
  getCustomersState,
  (state: CustomersState) => state.updatingCustomerVehicle
);

const getCustomerVehicleID = createSelector(
  getCustomersState,
  (state: CustomersState) => state.selectedCustomerVehicleID
);

const getToAddDefaultVehicle = createSelector(
  getCustomersState,
  (state: CustomersState) => state.customerVehicles.filter((vehicle) => vehicle.DefaultFlag === '1').length === 0
);

const getSelectedCustomerVehicle = createSelector(
  getCustomersState,
  (state: CustomersState) => state.selectedCustomerVehicle
);

const getUpdatingCustomer = createSelector(
  getCustomersState,
  (state: CustomersState) => state.updatingCustomer
);

const getHasActiveVehicles = createSelector(
  getCustomersState,
  (state: CustomersState) => {
    if (state.customerVehicles.length > 0) {
      return (
        state.customerVehicles.filter((vehicle) => vehicle.DateRetired === '')
          .length > 0
      );
    }
  }
);

const getDefaultVehicle = createSelector(
  getCustomersState,
  (state: CustomersState) => {
    if (state.customerVehicles.length > 0) {
      return state.customerVehicles.find(
        (vehicle) => vehicle.DefaultFlag === '1'
      );
    }
  }
);

const getDuplicateCustomers = createSelector(
  getCustomersState,
  (state: CustomersState) => state.duplicateCustomers
);

const getHasMoreRecords = createSelector(
  getCustomersState,
  (state: CustomersState) => state.hasMoreRecords
);

const getFilteredStates = createSelector(
  getCustomersState,
  (state: CustomersState) => {
    if (state.lookupLists) {
      return state.lookupLists.stateList.filter(
        (x) => x.CountryCode === state.selectedCountryCode
      );
    }
    return [];
  }
);

const getSelectedCustomerState = createSelector(
  getCustomersState,
  (state: CustomersState) => state.selectedCustomer?.StateCode
);

const getSelectedCustomerCountry = createSelector(
  getCustomersState,
  (state: CustomersState) => state.selectedCustomer?.CountryCode
);

const getCustomerSales = createSelector(
  getCustomersState,
  (state: CustomersState) => state.customerSales
);

const getCustomerStatements = createSelector(
  getCustomersState,
  (state: CustomersState) => state.customerStatements
);

const getLoadingCustomerStatements = createSelector(
  getCustomersState,
  (state: CustomersState) => state.loadingCustomerStatements
);

const getCustomerInvoices = createSelector(
  getCustomersState,
  (state: CustomersState) => state.customerInvoices
);

const getLoadingCustomerInvoices = createSelector(
  getCustomersState,
  (state: CustomersState) => state.loadingCustomerInvoices
);

const getCustomerShowSales = createSelector(
  getCustomersState,
  (state: CustomersState) => state.customerShowSales
);
const getLoadingCustomerShowSales = createSelector(
  getCustomersState,
  (state: CustomersState) => state.loadingCustomerShowSales
);

const getTasksNotesList = createSelector(
  getCustomersState,
  (state: CustomersState) => state.tasksNotesList
);
const getLoadingTasksNotesList = createSelector(
  getCustomersState,
  (state: CustomersState) => state.loadingTasksNotesList
);

const getAlertMessage = createSelector(
  getCustomersState,
  (state: CustomersState) => state.customerAlertMessage
);
const getLoadingAlertMessage = createSelector(
  getCustomersState,
  (state: CustomersState) => state.loadingAlertMessage
);

const getLoadingCompleteTask = createSelector(
  getCustomersState,
  (state: CustomersState) => state.loadingCompleteTask
);

export const getCustomerName = createSelector(getSelectedCustomer, (state) => {
  return state?.CustomerName;
});

export const customersQuery = {
  getError,
  getSelectedCustomer,
  getLookupLists,
  getLoadingLookupLists,
  getIsCreatingCustomer,
  getCreatedCustomerId,
  getCustomerVehicles,
  getLoadingCustomerVehicles,
  getUpdatingCustomerVehicle,
  getLoadingCustomerDetails,
  getToAddDefaultVehicle,
  getSelectedCustomerVehicle,
  getUpdatingCustomer,
  getCustomerVehicleID,
  getHasActiveVehicles,
  getDefaultVehicle,
  getDuplicateCustomers,
  getHasMoreRecords,
  getFilteredStates,
  getSelectedCustomerState,
  getSelectedCustomerCountry,
  getCustomerSales,
  getCustomerStatements,
  getLoadingCustomerStatements,
  getCustomerInvoices,
  getLoadingCustomerInvoices,
  getCustomerShowSales,
  getLoadingCustomerShowSales,
  getTasksNotesList,
  getLoadingTasksNotesList,
  getLoadingAlertMessage,
  getAlertMessage,
  getLoadingCompleteTask,
};
