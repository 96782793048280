import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { CustomerVehicle, LookupLists, State } from '@pos-app/data';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-customers-vehicles-details-form',
  templateUrl: './customers-vehicles-details-form.component.html',
  styleUrls: ['./customers-vehicles-details-form.component.scss']
})
export class CustomersVehiclesDetailsFormComponent
  implements OnInit, OnChanges, OnDestroy {
  @Input() selectedCustomerVehicle: CustomerVehicle;
  @Input() lookupLists: LookupLists;
  @Input() defaultState: string;
  @Input() defaultCountry: string;
  @Output() formChanged = new EventEmitter<any>();
  @Output() formIsValid = new EventEmitter<boolean>();
  @Output() formIsDirty = new EventEmitter<boolean>();
  @Output() submitByEnter = new EventEmitter<void>();
  @Output() selectedCountry = new EventEmitter<void>();

  public stateList: State[];
  @Input() set filteredStateList(value) {
    this.stateList = value;
  }

  public vehicleDetailsForm: FormGroup;
  public lookupLists$: Observable<LookupLists>;
  private unSubscribe$ = new Subject<void>();

  constructor(private formBuilder: FormBuilder) {
    this.vehicleDetailsForm = this.formBuilder.group({
      actionCode: ['C'],
      customerVehicleID: [''],
      regoNumber: [
        '',
        [Validators.required, Validators.pattern('^[0-9a-zA-Z ]+')]
      ],
      regoState: [''],
      vinNumber: ['', [Validators.pattern('^[0-9a-zA-Z]+')]],
      colourCode: [''],
      vehicleID: [''],
      shortDescription: [''],
      fullDescription: [''],
      defaultFlag: [''],
      country: ['AU']
    });
  }

  ngOnInit() {
    this.vehicleDetailsForm.valueChanges
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe(x => {
        this.formChanged.emit({
          ...this.vehicleDetailsForm.getRawValue(),
          regoNumber: x.regoNumber.toUpperCase()
        });
        this.formIsValid.emit(this.vehicleDetailsForm.valid);
        this.formIsDirty.emit(this.vehicleDetailsForm.dirty);
      });

    this.vehicleDetailsForm.controls.country.valueChanges
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe(value => {
        this.selectedCountry.emit(value);
        this.vehicleDetailsForm.controls.regoState.patchValue('');
      });
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.selectedCustomerVehicle &&
      changes.selectedCustomerVehicle.currentValue &&
      this.vehicleDetailsForm
    ) {
      this.vehicleDetailsForm.patchValue({
        customerVehicleID: this.selectedCustomerVehicle.customerVehicleID,
        regoNumber: this.selectedCustomerVehicle.RegoNumber,
        regoState: this.selectedCustomerVehicle.RegoState,
        vinNumber: this.selectedCustomerVehicle.VinNumber,
        colourCode: this.selectedCustomerVehicle.ColourCode,
        shortDescription: this.selectedCustomerVehicle.ShortDescription,
        fullDescription: this.selectedCustomerVehicle.VehicleFullDescription,
        vehicleID: this.selectedCustomerVehicle.VehicleID,
        defaultFlag: this.selectedCustomerVehicle.DefaultFlag,
        country: this.selectedCustomerVehicle.countryCode
      });
    }
    if (
      changes.defaultState &&
      changes.defaultState.currentValue &&
      this.vehicleDetailsForm
    ) {
      this.vehicleDetailsForm.patchValue({
        regoState: this.defaultState
      });
    }
  }

  pressEnter() {
    this.submitByEnter.emit();
  }
}
