<pos-app-spinner [show]="isLoading"></pos-app-spinner>
<div class="row">
  <div class="col-12 col-xl-8">
    <div class="row">
      <div class="col-4">
        <button
          *ngIf="orderHeader.payableYN === 'Y' && cashDrawer?.status === 'O'"
          type="button"
          class="btn btn-secondary"
          (click)="addNewPayment()"
          [disabled]="notAllowNewPayment() || isSaving"
        >
          ADD NEW PAYMENT
        </button>
      </div>
      <div class="col-5 text-right my-auto">
        <label>MINIMUM PAYMENT:</label>
      </div>
      <div class="col-2 my-auto text-right">
        <label class="pl-1 pr-3">{{ minimumPayment | currency }}</label>
      </div>
    </div>
    <div class="row pb-2">
      <div class="offset-4 col-5 text-right my-auto">
        <label>RECOMMENDED PAYMENT:</label>
      </div>
      <div class="col-2 my-auto text-right">
        <label class="pl-1 pr-3">{{ recommendedPayment | currency }}</label>
      </div>
    </div>

    <div class="row">
      <div class="col-2"><label>DATE</label></div>
      <div class="col-4"><label>PAYMENT TYPE</label></div>
      <div class="col-3"><label>REFERENCE</label></div>
      <div class="col-2"><label>AMOUNT</label></div>
    </div>
    <div class="row pb-2">
      <div class="col-2"></div>
    </div>
    <form [formGroup]="paymentStackForm">
      <div formArrayName="pastPayments">
        <ng-container *ngFor="let item of pastPaymentsFormArray?.controls; let i = index">
          <div [formGroupName]="i" class="row pb-2">
            <div class="col-2">
              <input class="form-control" type="text" formControlName="date" />
            </div>
            <div class="col-4">
              <input class="form-control" type="text" formControlName="paymentType" />
            </div>
            <div class="col-3">
              <input class="form-control" type="text" formControlName="reference" />
            </div>
            <div class="col-2">
              <input
                class="form-control text-right"
                type="text"
                mask="separator.2"
                thousandSeparator=","
                [allowNegativeNumbers]="true"
                formControlName="amount"
              />
            </div>
          </div>
        </ng-container>
      </div>
      <div formArrayName="payments">
        <ng-container *ngFor="let item of paymentsFormArray.controls; let i = index; trackBy: trackByFn">
          <div
            [formGroupName]="i"
            class="row pb-2"
            *ngIf="{
              displayPaymentType: item.get('displayPaymentType').value,
              loading: item.get('loading').value,
              hideDeleteIcon: item.get('hideDeleteIcon').value,
              amount: item.get('amount').value
            } as row"
          >
            <div class="offset-2 col-4">
              <span *ngIf="!row.displayPaymentType">
                <ng-select
                  formControlName="paymentType"
                  [items]="paymentTypes"
                  bindLabel="paymentTypeDescription"
                  bindValue="paymentTypeCode"
                  [placeholder]="'Select payment type'"
                  [appendTo]="'body'"
                  autofocus
                >
                </ng-select>
              </span>
              <span *ngIf="row.displayPaymentType">
                <input class="form-control" type="text" formControlName="paymentType" [value]="row.displayPaymentType" />
              </span>
            </div>
            <div class="col-3">
              <input class="form-control" type="text" formControlName="reference" (focus)="onFocus(item)" (blur)="onBlur(item)" />
            </div>
            <div class="col-2">
              <input
                class="form-control text-right"
                type="text"
                mask="separator.2"
                thousandSeparator=","
                [allowNegativeNumbers]="true"
                formControlName="amount"
                (focus)="onFocus(item)"
                (blur)="onBlur(item)"
                [class.is-invalid]="item.errors?.invalid"
                (keyup.enter)="next.emit()"
              />
            </div>
            <div class="col-1 actions">
              <i *ngIf="row.loading" class="pi pi-spin pi-spinner text-danger"></i>
              <mat-icon *ngIf="orderHeader.payableYN === 'Y' && !row.hideDeleteIcon" class="my-auto text-danger" (click)="removePayment(i)">
                delete
              </mat-icon>
            </div>
          </div>
        </ng-container>
      </div>
    </form>
    <div class="row pt-2 border-top border-dark">
      <div class="offset-4 col-5 text-right">
        <label>TOTAL PAYMENTS:</label>
      </div>
      <div class="col-2 text-right">
        <label class="pl-1 pr-3">{{ totalPayments | currency }}</label>
      </div>
    </div>
    <div *ngIf="cashDrawer?.eftposDeviceName" class="row pt-5 justify-content-end">
      <div class="col-4 text-right">
        <button type="button" class="btn btn-secondary" (click)="pollEftPos.emit(true)">POLL EFTPOS</button>
      </div>
    </div>
    <div *ngIf="creditOriginalPayments?.length > 0" class="pt-4">
      <label>PAST INVOICED PAYMENTS</label>
      <div *ngFor="let item of creditOriginalPayments; let i = index">
        <div class="row pb-2">
          <div class="col-2">
            <input class="form-control" type="text" [value]="item.date" disabled />
          </div>
          <div class="col-4">
            <ng-select
              [items]="paymentTypes"
              bindLabel="paymentTypeDescription"
              bindValue="paymentTypeCode"
              [appendTo]="'body'"
              [(ngModel)]="item.type"
              disabled
            >
            </ng-select>
          </div>
          <div class="col-3">
            <input class="form-control" type="text" [value]="item.reference" disabled />
          </div>
          <div class="col-2">
            <input class="form-control text-right" type="text" [value]="item.amount | currency" disabled />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-xl-4">
    <table class="table table-sm table-borderless">
      <tbody>
        <tr>
          <td><label>PREVIOUSLY SOLD ITEM VALUE</label></td>
          <td class="text-right">
            <label>{{ totalInvoicedValue | currency }}</label>
          </td>
        </tr>
        <tr>
          <td><label>CURRENT SOLD ITEM VALUE</label></td>
          <td class="text-right">
            <label>{{ currentSoldItemValue | currency }}</label>
          </td>
        </tr>
        <tr>
          <td><label>DEFERRED ITEM VALUE</label></td>
          <td class="text-right">
            <label>{{ totalDeferredValue | currency }}</label>
          </td>
        </tr>
        <tr class="border-top border-dark">
          <td><label>TOTAL ORDER VALUE</label></td>
          <td class="text-right">
            <label>{{ totalValue | currency }}</label>
          </td>
        </tr>
        <tr>
          <td><label>MINUS TOTAL PAYMENTS</label></td>
          <td class="text-right">
            <label>{{ totalPayments | currency }}</label>
          </td>
        </tr>
        <tr class="border-top border-dark">
          <td><label>OUTSTANDING AMOUNT</label></td>
          <td class="text-right">
            <label>{{ outstandingValue | currency }}</label>
          </td>
        </tr>
        <tr *ngIf="paymentInfo?.totals?.cashChange && hasCashPayment">
          <td><label>CASH CHANGE</label></td>
          <td class="text-right">
            <label>{{ paymentInfo?.totals?.cashChange | currency }}</label>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h3 class="arb-large-heading" id="modal-basic-title">GIFT CARD PAYMENT</h3>
  </div>
  <div class="modal-body">
    <app-orders-gift-card
      [_id]="giftpendingId"
      [isPayment]="true"
      [orderNumber]="orderNumber"
      [loadAmount]="getMinPaymentAmount()"
      [outstanding]="outstandingValue"
      (paymentDetails)="handleGiftPayment($event)"
    ></app-orders-gift-card>
  </div>
  <div class="modal-footer pb-2">
    <span class="px-2"></span>
    <button class="btn btn-cancel" (click)="d()">CANCEL</button>
  </div>
</ng-template>

<ng-template #eftpos let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h3 class="arb-large-heading" id="modal-basic-title">EFTPOS PAYMENT</h3>
  </div>
  <div class="modal-body">
    <app-orders-eftpos
      [_id]="eftposPendingId"
      [loadAmount]="getMinPaymentAmount()"
      [outstanding]="outstandingValue"
      [orderNumber]="orderNumber"
      [fakeEFTPOSTF]="cashDrawer.fakeEFTPOSTF && allowFakeEFTPOSTF"
      [registerID]="cashDrawer?.registerID"
      [eftposDeviceName]="cashDrawer?.eftposDeviceName"
      (paymentDetails)="handleEftposPayment($event)"
      (cancel)="d()"
    ></app-orders-eftpos>
  </div>
</ng-template>
