import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { ApiService, CoreUiPartialState, getActiveOrder, getBranch, getDefaultCustomer } from '@pos-app/core-ui';
import {
  CRMUsers,
  Customer,
  ListTasksNotes,
  NoteTaskTemplate,
  NOTE_TASK_ACTION_CODE,
  NOTE_TASK_ACTIVITY_TYPE,
  OrderType,
  TasksNotes,
} from '@pos-app/data';
import { forkJoin, of, Subject } from 'rxjs';
import { defaultIfEmpty, take, takeUntil } from 'rxjs/operators';
import { UpdateNotesTasksAction } from '../../+state/customers.actions';
import { CustomersPartialState } from '../../+state/customers.reducer';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { OOESessionService } from 'libs/core-ui/src/lib/services/ooe-session.service';

@Component({
  selector: 'app-customers-notes-form',
  templateUrl: './customers-notes-form.component.html',
  styleUrls: ['./customers-notes-form.component.scss'],
})
export class CustomersNotesFormComponent implements OnInit {
  @ViewChild('content') modalBox: any;
  @ViewChild('customerModalContent') customerModalBox: any;
  @Input() selectedCustomer;
  @Input() listTaskNotesFilter?: ListTasksNotes;
  orderTypes: OrderType[];

  noteForm: FormGroup;
  noteTaskDetails: TasksNotes;
  customerNumber: number;
  templateList: NoteTaskTemplate[];
  attachedFiles: File[] = [];
  initialDate: string;
  defaultBranch: string;

  assignees: CRMUsers[];
  allAssignees: CRMUsers[];
  filteredAssignees: CRMUsers[];

  isTaskCalendarComp: boolean;
  activeOrder: { orderNumber: string; quickSaveAllowed: boolean };
  taskCalendarFilter: string = '';
  defaultCustomer: any = null;
  defaultCustomerNumber: string = '';

  isAddingCustomer: boolean;
  isEditingCustomer: boolean;
  isSelectingCustomer: boolean;
  customerModalReference: any;
  defaultCompanyCode: string = JSON.parse(localStorage.getItem('jdeSession')).CompanyCode;

  private unSubscribe$ = new Subject<void>();

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private store: Store<CustomersPartialState>,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private coreUIStore: Store<CoreUiPartialState>,
    private _location: Location,
    private toastr: ToastrService,
    private ooeSessionService: OOESessionService
  ) {}

  ngOnInit() {
    this.coreUIStore.pipe(select(getActiveOrder), takeUntil(this.unSubscribe$)).subscribe((activeOrder) => {
      this.activeOrder = activeOrder;
    });

    this.apiService
      .listTemplates()
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((templateList) => {
        this.templateList = templateList.SearchResults || [];
      });

    this.noteForm = this.formBuilder.group({
      template: [''],
      orderNumber: [''],
      orderType: [''],
      orderCompany: [''],
      dateDue: [''],
      assignTo: [{ value: '', disabled: true }],
      contact: [''],
      note: ['', [Validators.required, Validators.maxLength(51)]],
      additionalDetails: ['', [Validators.maxLength(1999)]],
      attachedFiles: [[]],
      isBranchAssignee: [{ value: '', disabled: true }],
    });

    this.coreUIStore.pipe(takeUntil(this.unSubscribe$), select(getBranch)).subscribe((x) => {
      this.defaultBranch = x.BranchCode;
    });

    this.coreUIStore
      .select(getDefaultCustomer)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((defaultCustomer) => {
        this.defaultCustomerNumber = defaultCustomer.customerNumber;
      });

    this.setDateDueValidation();
    this.setBranchAssignee();
    this.setDefaultCompanyCodeForOrderLink();
  }

  setDefaultCompanyCodeForOrderLink() {
    this.noteForm.valueChanges.pipe(takeUntil(this.unSubscribe$)).subscribe((formValue) => {
      if ((formValue.orderType || formValue.orderNumber) && !formValue.orderCompany) {
        this.noteForm.patchValue({
          orderCompany: this.defaultCompanyCode,
        });
      }
    });
  }

  setDateDueValidation() {
    this.noteForm.controls.dateDue.valueChanges.pipe(takeUntil(this.unSubscribe$)).subscribe((dateDueValue) => {
      if (dateDueValue) {
        this.noteForm.controls.assignTo.enable();
        this.noteForm.controls.assignTo.setValidators(Validators.required);
        this.noteForm.controls.assignTo.patchValue([this.defaultCustomerNumber]);
        this.noteForm.controls.assignTo.updateValueAndValidity();
        if (this.noteForm.controls.assignTo.value === []) {
          this.noteForm.controls.isBranchAssignee.setValue(true);
        }
        if (!this.allAssignees) {
          this.apiService
            .fetchCRMUsers({
              branch: '',
              userFilter: '',
            })
            .pipe(takeUntil(this.unSubscribe$))
            .subscribe((assignees) => {
              this.allAssignees = assignees.SearchResults;
              this.filteredAssignees = assignees.SearchResults.filter((assignee) => assignee.userBranch.includes(this.defaultBranch));
              this.noteForm.controls.isBranchAssignee.enable();
            });
        } else {
          this.noteForm.controls.isBranchAssignee.enable();
        }
      } else {
        this.noteForm.controls.isBranchAssignee.disable();
        this.noteForm.controls.assignTo.disable();
      }
    });
  }

  setBranchAssignee() {
    this.noteForm.controls.isBranchAssignee.valueChanges.pipe(takeUntil(this.unSubscribe$)).subscribe((isBranchAssigneeValue) => {
      isBranchAssigneeValue === true ? (this.assignees = this.filteredAssignees) : (this.assignees = this.allAssignees);
    });
  }

  openModal(noteTaskDetails, orderTypes, isTaskCalendarComp, taskCalendarFilter, defaultCustomer) {
    this.taskCalendarFilter = taskCalendarFilter;
    this.defaultCustomer = defaultCustomer;
    this.isTaskCalendarComp = isTaskCalendarComp;
    this.orderTypes = orderTypes;

    if (noteTaskDetails) {
      this.apiService
        .getCustomerDetails({
          customerNumberSearch: noteTaskDetails.customerNumber?.toString(),
        })
        .pipe(take(1))
        .subscribe((data) => {
          if (data?.SearchResults?.length > 0) {
            this.selectedCustomer = data.SearchResults[0] || null;
          }

          this.customerNumber = noteTaskDetails.customerNumber;
          this.attachedFiles = noteTaskDetails.attachmentIDArray.length > 0 ? [...noteTaskDetails.attachmentIDArray] : [];
          this.initialDate = noteTaskDetails.endDate;
          this.noteTaskDetails = noteTaskDetails;
          this.noteForm.patchValue({
            template: this.templateList.find((item) => item.note === noteTaskDetails.note),
            orderNumber: noteTaskDetails.orderNumber,
            orderType: noteTaskDetails.orderType,
            dateDue: noteTaskDetails.endDate !== ' ' ? noteTaskDetails.endDate : '',
            assignTo: noteTaskDetails.assignees.map((x) => x.assignee),
            contact: this.selectedCustomer ? this.selectedCustomer.Phone : '',
            note: noteTaskDetails.note,
            additionalDetails: noteTaskDetails.additionalDetails,
          });
        });
    } else {
      this.attachedFiles = [];
      this.initialDate = '';
      this.noteTaskDetails = null;
      this.customerNumber = this.route.snapshot.params.id;
      this.selectedCustomer = null;
      this.noteForm.reset();
    }

    this.modalService.open(this.modalBox, { size: 'xl', backdrop: 'static' }).result.then(
      (_) => {},
      (_) => {}
    );
  }

  onSelectTemplate() {
    let selectedTemplate = this.noteForm.getRawValue().template;
    this.initialDate = selectedTemplate.dueDate;
    this.noteForm.patchValue({
      contact: selectedTemplate.contact,
      dateDue: selectedTemplate.dueDate,
      assignTo: [selectedTemplate.assignTo],
      note: selectedTemplate.note,
      orderNumber: selectedTemplate.orderNumber,
      orderType: selectedTemplate.orderType,
    });
  }

  onSelectDate(date: string) {
    this.noteForm.patchValue({
      dateDue: date,
    });
  }

  openOrderInJDE() {
    this.ooeSessionService
      .fetchUserState()
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((userState) => {
        if (!userState.OpenOrder.orderNumber.startsWith('T')) {
          this.noteForm.patchValue({
            orderNumber: userState.OpenOrder.orderNumber,
            orderType: userState.OpenOrder.orderType,
            orderCompany: userState.OpenOrder.orderCompany,
          });
        } else {
          this.toastr.error('Temporary orders cannot be linked. Save order to JDE first');
        }
      });
  }

  updateNoteTask(noteFormValue, activityCode: string, activityType: string, activityID: string, attachmentIds?: string[]) {
    this.store.dispatch(
      new UpdateNotesTasksAction({
        defaultCustomer: this.defaultCustomer,
        taskCalendarFilter: this.taskCalendarFilter,
        isTask: this.isTaskCalendarComp,
        actionCode: activityCode ? activityCode : '',
        activityType: activityType ? activityType : '',
        activityID: activityID ? activityID : '',
        note: noteFormValue.note,
        dueDate: noteFormValue.dateDue,
        additionalDetails: noteFormValue.additionalDetails,
        contactWhosWhoLine: '',
        assignees: noteFormValue.assignTo,
        attachmentArray: attachmentIds ? attachmentIds : [],
        customerNumber: this.customerNumber,
        orderNumber: noteFormValue.orderNumber,
        orderType: noteFormValue.orderType,
        orderCompany: noteFormValue.orderCompany,
        listTaskNotesFilter: this.listTaskNotesFilter,
      })
    );
  }

  saveNoteTask() {
    const formValue = this.noteForm.value;
    // Upload all the attached file
    const uploadAttach =
      this.attachedFiles.length > 0
        ? forkJoin(
            formValue.attachedFiles.map((file) => {
              return this.apiService.uploadAttachment({
                entityType: 'crm',
                entityId: 'dummy',
                type: 'file',
                file,
              });
            })
          )
        : of([]);
    uploadAttach.pipe(defaultIfEmpty([]), takeUntil(this.unSubscribe$)).subscribe((res) => {
      const attachmentIds = res.length > 0 ? res.map((attachment) => attachment.id) : res;
      return this.updateNoteTask(
        formValue,
        this.noteTaskDetails ? NOTE_TASK_ACTION_CODE.Edit : NOTE_TASK_ACTION_CODE.Add,
        formValue.dateDue ? NOTE_TASK_ACTIVITY_TYPE.Task : NOTE_TASK_ACTIVITY_TYPE.Note,
        this.noteTaskDetails ? this.noteTaskDetails.activityID : null,
        attachmentIds
      );
    });
    if (this.route.snapshot.queryParams.openNewNote === 'true') {
      this._location.back();
    }
    this.modalService.dismissAll();
  }

  closeModal() {
    if (this.route.snapshot.queryParams.openNewNote === 'true') {
      this._location.back();
    }
    this.modalService.dismissAll();
    this.selectedCustomer = null;
  }

  onFileChange(event) {
    if (event.target.files?.length > 0) {
      Array.from(event.target.files).forEach((file) => this.attachedFiles.push(file as File));
      this.noteForm.patchValue({
        attachedFiles: this.attachedFiles,
      });
    }
  }

  onRemoveFile(index: number) {
    this.attachedFiles.splice(index, 1);
  }

  fetchNewSelectedCustomer(customer: Customer) {
    this.apiService
      .getCustomerDetails({
        customerNumberSearch: customer.CustomerNumber,
      })
      .pipe(take(1))
      .subscribe((data) => {
        if (data?.SearchResults?.length > 0) {
          this.selectedCustomer = data.SearchResults[0] || null;
        } else {
          this.selectedCustomer = customer;
        }
        this.customerNumber = parseInt(this.selectedCustomer.CustomerNumber);
      });
  }

  addNewCustomer() {
    this.isAddingCustomer = true;
    this.isSelectingCustomer = false;
    this.isEditingCustomer = false;
    this.openCustomerModalBox();
  }

  selectNewCustomer() {
    this.isSelectingCustomer = true;
    this.isEditingCustomer = false;
    this.isAddingCustomer = false;
    this.openCustomerModalBox();
  }

  editCustomer() {
    this.isEditingCustomer = true;
    this.isSelectingCustomer = false;
    this.isAddingCustomer = false;
    this.openCustomerModalBox();
  }

  handleAddNewCustomer(customer) {
    if (customer) {
      this.isAddingCustomer = false;
      this.isEditingCustomer = false;
      this.apiService
        .fetchCustomerDetails({
          customerNumber: customer,
        })
        .pipe(takeUntil(this.unSubscribe$))
        .subscribe((res) => {
          this.fetchNewSelectedCustomer(res.SearchResults[0]);
        });
    }
    this.customerModalReference.close();
  }

  handleSelectCustomer(customer: Customer) {
    if (customer) {
      this.fetchNewSelectedCustomer(customer);
      this.isSelectingCustomer = false;
      this.customerModalReference.close();
    }
  }

  openCustomerModalBox() {
    this.customerModalReference = this.modalService.open(this.customerModalBox, {
      windowClass: 'garage-modal',
      size: 'xl',
    });
    this.customerModalReference.result.then(
      (_) => {
        this.isAddingCustomer = false;
        this.isSelectingCustomer = false;
      },
      (_) => {
        this.isAddingCustomer = false;
        this.isSelectingCustomer = false;
      }
    );
  }

  clearCustomer() {
    this.selectedCustomer = null;
  }
}
