import { createFeatureSelector, createSelector } from '@ngrx/store';
import { KEYS, USER_ROLE, ValidOrderType } from '@pos-app/data';
import { COREUI_FEATURE_KEY, State, CoreUiPartialState, coreUiAdapter } from './core-ui.reducer';

// Lookup the 'CoreUi' feature state managed by NgRx
export const getCoreUiState = createFeatureSelector<CoreUiPartialState, State>(COREUI_FEATURE_KEY);

const { selectAll, selectEntities } = coreUiAdapter.getSelectors();

export const getCoreUiLoaded = createSelector(getCoreUiState, (state: State) => state.loaded);

export const getHasUnsavedChanges = createSelector(getCoreUiState, (state: State) => state.hasUnsavedChanges);

export const getHasActiveOrder = createSelector(getCoreUiState, (state: State) => {
  return state.hasActiveOrder;
});

export const getActiveOrder = createSelector(getCoreUiState, (state: State) => state.activeOrder);

export const getOpenOrder = createSelector(getCoreUiState, (state: State) => state.openOrder);

export const getActiveOrderVehicle = createSelector(getCoreUiState, (state: State) => state.selectedVehicle);

export const getCoreUiError = createSelector(getCoreUiState, (state: State) => state.error);

export const getUserId = createSelector(getCoreUiState, (state: State) => state.loginDetails && state.loginDetails.UserID);

export const getUserName = createSelector(getCoreUiState, (state: State) => state.loginDetails && state.loginDetails.Name);

export const getDefaultCustomer = createSelector(getCoreUiState, (state: State) => {
  if (state.loginDetails) {
    return {
      customerNumber: state.loginDetails.CustomerNumber,
      customerName: state.loginDetails.Name,
      defaultBranch: state.loginDetails.DefaultBranch,
      defaultBranchName: state.loginDetails.DefaultBranchName,
    };
  }
});

export const getIsLoggedIn = createSelector(getCoreUiState, (state: State) => state.loginDetails && !!state.loginDetails.Token);

export const getSecurityFunctions = createSelector(getCoreUiState, (state: State) => state.loginDetails && state.loginDetails.SecurityFunctions);

export const getOrderTypes = createSelector(getCoreUiState, (state: State) => {
  if (state.loginDetails) {
    return state.loginDetails.ValidOrderTypes.map((x, index) => {
      return {
        OrderTypeCode: x.orderType,
        OrderTypeDescription: x.orderTypeDescription,
        PaymentYN: x.paymentYN ? x.paymentYN : 'N',
        OrderTypeGroup: Number(x.orderTypeGroup),
      };
    });
  }
});

export const getNonCorpOrderTypes = createSelector(getCoreUiState, (state: State) => {
  if (state.loginDetails && state.loginDetails.ValidOrderTypesNonCorp) {
    return state.loginDetails.ValidOrderTypesNonCorp.map((x: ValidOrderType) => {
      return {
        OrderTypeCode: x.orderType,
        OrderTypeDescription: x.orderTypeDescription,
        PaymentYN: x.paymentYN,
        OrderTypeGroup: Number(x.orderTypeGroup),
      };
    });
  }
  return [];
});

export const getBranch = createSelector(getCoreUiState, (state: State) => {
  if (state.loginDetails) {
    return {
      BranchCode: state.loginDetails.DefaultBranch,
      BranchDescription: state.loginDetails.DefaultBranchName
    };
  }
  return null;
});

export const allowFakeEFTPOSTF = createSelector(getCoreUiState, (state: State) => {
  return state.brandDefaults?.AllowFakeEFTPOSTF;
});

export const isExternalUser = createSelector(getCoreUiState, (state: State) => {
  return state.loginDetails && state.loginDetails.Role === USER_ROLE.external;
});

export const isGuest = createSelector(getCoreUiState, (state: State) => {
  return state.loginDetails && state.loginDetails.Role === USER_ROLE.guest;
});

export const getUserRole = createSelector(getCoreUiState, (state: State) => state.loginDetails && state.loginDetails.Role);

export const getShowLocation = createSelector(getCoreUiState, (state: State) => state.loginDetails && state.loginDetails.ShowLocation);

export const getLoginDetails = createSelector(getCoreUiState, (state: State) => state.loginDetails);

export const getAllCoreUi = createSelector(getCoreUiState, (state: State) => selectAll(state));

export const getCoreUiEntities = createSelector(getCoreUiState, (state: State) => selectEntities(state));

export const getSelectedId = createSelector(getCoreUiState, (state: State) => state.selectedId);

export const getSelected = createSelector(getCoreUiEntities, getSelectedId, (entities, selectedId) => selectedId && entities[selectedId]);

export const getSearchingCustomer = createSelector(getCoreUiState, (state: State) => state.searchingCustomers);
export const getSearchingBillToCustomer = createSelector(getCoreUiState, (state: State) => state.searchingBillToCustomers);

export const getCustomers = createSelector(getCoreUiState, (state: State) => state.customers);
export const getBillToCustomers = createSelector(getCoreUiState, (state: State) => state.billToCustomers);

export const getHasMoreRecords = createSelector(getCoreUiState, (state: State) => state.hasMoreRecords);
export const getHasMoreBillToRecords = createSelector(getCoreUiState, (state: State) => state.hasMoreBillToRecords);
export const getColourList = createSelector(getCoreUiState, (state: State) => state.colourList);

export const getCashDrawer = createSelector(getCoreUiState, (state: State) => {
  return localStorage.getItem(KEYS.cashDrawer);
});

export const getHasPendingPayments = createSelector(getCoreUiState, (state: State) => state.hasPendingPayments);

export const getDisplayOptions = createSelector(
  getCoreUiState,
  (state: State) =>
    state.loginDetails && {
      displayCurrency: state.loginDetails.displayCurrencyYN === 'Y',
      displayIncTax: state.loginDetails.displayIncTaxYN === 'Y',
      displayExTax: state.loginDetails.displayExTaxYN === 'Y',
    }
);

export const getShowCodes = createSelector(getCoreUiState, (state: State) => state.loginDetails && state.loginDetails.ShowCodes);

export const getBrandDefaults = createSelector(getCoreUiState, (state: State) => state.brandDefaults);

export const getIsIncompleteOrder = createSelector(getCoreUiState, (state: State) => state.isIncompleteOrder);

export const getHasLoadedGiftCards = createSelector(getCoreUiState, (state: State) => state.hasLoadedGiftCards);

export const getOverdueAlerts = createSelector(getCoreUiState, (state: State) => state.alertCount);

export const getIsShowroomView = createSelector(getCoreUiState, (state: State) => state.isShowRoomView);

export const getHasOrderChanged = createSelector(getCoreUiState, (state: State) => state.isOrderChanged);

export const getParkedOrderListLength = createSelector(getCoreUiState, (state: State) => state.parkedOrderListLength);

export const getWarrantyShowExpensesTF = createSelector(getCoreUiState, (state: State) => state?.brandDefaults?.warrantyShowExpensesTF);
