<div class="container pb-5">
  <pos-app-spinner [show]="loadingTasksNotesList"></pos-app-spinner>
  <div class="text-center">
    <h3 class="pt-3 arb-large-heading">CALENDAR</h3>
  </div>
  <form [formGroup]="calendarForm">
    <div class="row py-2 mt-4">
      <label class="ml-n3 col-2 col-form-label font-weight-bold"
        >FILTER CALENDAR</label
      >
      <div class="col-8 col-form-value my-auto">
        <input
          type="radio"
          value="{{ BRANCH_CALENDAR_FILTER.MyCalendar }}"
          formControlName="calendarFilter"
          class="me-1 form-check-input"
        />My Calendar
        <span class="px-2"></span>
        <input
          type="radio"
          value="{{ BRANCH_CALENDAR_FILTER.BranchCalendar }}"
          formControlName="calendarFilter"
          class="me-1 form-check-input"
        />Branch Calendar
      </div>
    </div>
    <div class="row py-2">
      <label class="ml-n3 col-2 col-form-label font-weight-bold"
        >SELECT DATE RANGE</label
      >
      <div class="col-8 col-form-value my-auto">
        <pos-app-date-picker
          (selectedDateRange)="onSelectDate($event)"
          [fromDate]="fromDate"
          [toDate]="toDate"
        ></pos-app-date-picker>
      </div>
    </div>
    <div class="row">
      <div class="col-auto d-inline">
        <input
          class="px-2 form-check-input align-text-bottom"
          type="checkbox"
          formControlName="showCompletedTasks"
        />
        <label class="col-form-label px-2"> SHOW COMPLETED TASKS </label>
      </div>
    </div>
  </form>

  <!-- Timeline -->
  <app-customers-notes-task-timeline
    [selectedCustomer]="selectedCustomer"
    [orderTypes]="orderTypes"
    [rowData]="rowData"
    [isShowCompletedTasks]="isShowCompletedTasks"
    [isTaskCalendarComp]="true"
    [taskCalendarFilter]="true"
    [defaultCustomer]="defaultCustomer"
    [listTaskNotesFilter]="listTaskNotesFilter"
  ></app-customers-notes-task-timeline>
</div>
