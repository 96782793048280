import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LookupLists, CustomerVehicle, State } from '../../../+store/customers';

@Component({
  selector: 'epc-customers-vehicles-details-form',
  templateUrl: './customers-vehicles-details-form.component.html',
  styleUrls: ['./customers-vehicles-details-form.component.scss'],
})
export class EpcCustomersVehiclesDetailsFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() selectedCustomerVehicle: CustomerVehicle;
  @Input() lookupLists: LookupLists;
  @Input() defaultState: string;
  @Input() defaultCountry: string;
  @Input() set filteredStateList(value) {
    this.stateList = value;
  }

  @Output() formChanged = new EventEmitter<any>();
  @Output() formIsValid = new EventEmitter<boolean>();
  @Output() formIsDirty = new EventEmitter<boolean>();
  @Output() submitByEnter = new EventEmitter<void>();
  @Output() selectedCountry = new EventEmitter<void>();

  public stateList: State[];
  public vehicleDetailsForm: FormGroup;
  public lookupLists$: Observable<LookupLists>;
  private unSubscribe$ = new Subject<void>();

  constructor(private formBuilder: FormBuilder) {
    this.vehicleDetailsForm = this.formBuilder.group({
      actionCode: ['C'],
      customerVehicleID: [''],
      regoNumber: ['', [Validators.required, Validators.pattern('^[0-9a-zA-Z ]+')]],
      regoState: [null],
      vinNumber: ['', [Validators.pattern('^[0-9a-zA-Z]+')]],
      colourCode: [null],
      vehicleID: [''],
      shortDescription: [''],
      fullDescription: [''],
      defaultFlag: [''],
      country: ['AU'],
    });
  }

  public ngOnInit(): void {
    this.vehicleDetailsForm.valueChanges.pipe(takeUntil(this.unSubscribe$)).subscribe((x) => {
      this.formChanged.emit(this.vehicleDetailsForm.getRawValue());
      this.formIsValid.emit(this.vehicleDetailsForm.valid);
      this.formIsDirty.emit(this.vehicleDetailsForm.dirty);
    });

    this.vehicleDetailsForm.controls.country.valueChanges.pipe(takeUntil(this.unSubscribe$)).subscribe((value) => {
      this.selectedCountry.emit(value);
      this.vehicleDetailsForm.controls.regoState.patchValue('');
    });
  }

  public ngOnDestroy(): void {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedCustomerVehicle?.currentValue && this.vehicleDetailsForm) {
      this.vehicleDetailsForm.patchValue({
        customerVehicleID: this.selectedCustomerVehicle.customerVehicleID,
        regoNumber: this.selectedCustomerVehicle.RegoNumber,
        regoState: this.selectedCustomerVehicle.RegoState,
        vinNumber: this.selectedCustomerVehicle.VinNumber,
        colourCode: this.selectedCustomerVehicle.ColourCode,
        shortDescription: this.selectedCustomerVehicle.ShortDescription,
        fullDescription: this.selectedCustomerVehicle.VehicleFullDescription,
        vehicleID: this.selectedCustomerVehicle.VehicleID,
        defaultFlag: this.selectedCustomerVehicle.DefaultFlag,
      });
    }
    if (changes.defaultState?.currentValue && this.vehicleDetailsForm) {
      this.vehicleDetailsForm.patchValue({
        regoState: this.defaultState,
      });
    }

    if (changes.defaultCountry?.currentValue && this.vehicleDetailsForm) {
      this.vehicleDetailsForm.patchValue({
        country: this.defaultCountry,
      });
    }
  }

  public pressEnter(): void {
    this.submitByEnter.emit();
  }
}
