import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  Input,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  Inject,
  LOCALE_ID,
} from '@angular/core';
import {
  NgbDate,
  NgbDateParserFormatter,
  NgbCalendar,
  NgbInputDatepicker,
} from '@ng-bootstrap/ng-bootstrap';
import { formatDate, FormatWidth } from '@angular/common';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { format, parse } from 'date-fns';
import { getFullYearLocaleFormat } from '../../utils/utils-date';

@Component({
  selector: 'pos-app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('datepicker') datePicker: NgbInputDatepicker;
  @Input() fromDate: NgbDate;
  @Input() toDate: NgbDate;
  @Input() disabled: boolean;

  public hoveredDate: NgbDate;
  public isFromDateEnter: boolean;
  public fromDateString: FormControl;
  public toDateString: FormControl;
  public model1: string;
  private unSubscribe$ = new Subject<void>();

  @Input() preSelectedCustomer: boolean;
  @Output() selectedDateRange = new EventEmitter<any>();

  constructor(
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    @Inject(LOCALE_ID) public locale
  ) {
    this.toDate = this.calendar.getToday();
    this.fromDate = this.calendar.getNext(this.calendar.getToday(), 'm', -2);
  }

  ngOnInit() {
    if (!this.preSelectedCustomer) {
      this.fromDateString = new FormControl(
        this.formatter.format(this.fromDate)
      );
      this.toDateString = new FormControl(this.formatter.format(this.toDate));
      this.selectedDateRange.emit({
        fromDate: this.formatDate(this.fromDate),
        toDate: this.formatDate(this.toDate),
      });
    } else {
      this.fromDateString = new FormControl('');
      this.toDateString = new FormControl('');
    }

    this.fromDateString.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.unSubscribe$)
      )
      .subscribe((fromDate) => {
        this.fromDate = this.validateInput(this.fromDate, fromDate);
        this.selectedDateRange.emit({
          fromDate:
            this.fromDate === null ? '' : this.formatDate(this.fromDate),
          toDate: this.toDate === null ? '' : this.formatDate(this.toDate),
        });
      });
    this.toDateString.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.unSubscribe$)
      )
      .subscribe((toDate) => {
        this.toDate = this.validateInput(this.toDate, toDate);
        this.selectedDateRange.emit({
          fromDate:
            this.fromDate === null ? '' : this.formatDate(this.fromDate),
          toDate: this.toDate === null ? '' : this.formatDate(this.toDate),
        });
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disabled && this.fromDateString && this.toDateString) {
      if (this.disabled) {
        this.fromDateString.disable({ emitEvent: false });
        this.toDateString.disable({ emitEvent: false });
      } else {
        this.fromDateString.enable({ emitEvent: false });
        this.toDateString.enable({ emitEvent: false });
      }
    }
  }
  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  onDateSelection(date: NgbDate) {
    if (this.isFromDateEnter && (!this.toDate || date.before(this.toDate))) {
      this.fromDate = date;
      this.fromDateString.setValue(this.formatter.format(this.fromDate));
    } else if (
      !this.isFromDateEnter &&
      (!this.fromDate || date.after(this.fromDate))
    ) {
      this.toDate = date;
      this.toDateString.setValue(this.formatter.format(this.toDate));
    } else {
      return;
    }
    this.datePicker.toggle();
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      date.equals(this.toDate) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate, input: string): NgbDate {
    let formatedDateValue: string;
    if (input) {
      formatedDateValue = format(
        parse(
          input,
          getFullYearLocaleFormat(this.locale, FormatWidth.Short),
          new Date()
        ),
        'd/MM/yyyy',
        this.locale
      );
    }

    const parsed = this.formatter.parse(formatedDateValue);

    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : null;
  }

  // TODO: Redundant, need to delete in future if no erros come in production
  // Usage on template:
  // input :#dpFromDate | [value]="formatter.format(fromDate)"
  // input :#dpToDate | [value]="formatter.format(toDate)"

  formatDate(date: NgbDate) {
    return formatDate(
      date ? new Date(`${date.month}/${date.day}/${date.year}`) : null,
      'dd/MM/yyyy',
      'en-au'
    );
  }

  clearDate(isFromDate = true) {
    isFromDate
      ? this.fromDateString.setValue('')
      : this.toDateString.setValue('');
  }

  getCurrentLocaleDateFormat() {
    return getFullYearLocaleFormat(this.locale, FormatWidth.Short);
  }
}
