export const EPC_API_ENDPOINTS = {
  getCEDCategories: '/parts/GetCEDCategories',
  getPageContentStatic: '/parts/GetPageContentStatic',
  getArbCurrentLoginInformations: '/parts/GetArbCurrentLoginInformations',
  getFilterData: '/parts/GetFilterData',
  getStaticContent: '/parts/GetStaticContent',
  getBreadCrumbLink: '/parts/GetBreadCrumbLink',
  getCategoryFilterDataWithProductCount: '/parts/GetCategoryFilterDataWithProductCount',
  getProductList: '/parts/GetProductList',
  searchAll: '/parts/SearchAll',
  searchCategory: '/parts/SearchCategory',
  searchProduct: '/parts/SearchProduct',
  getProductDetail: '/parts/GetProductDetail',
  getPriceAndStock: '/parts/GetPriceAndStock',
  getConflictingProducts: '/parts/getConflictingProducts',
  getGroupAttributesFilterList: '/parts/GetGroupAttributesFilterList'
};
