<div class="product-details arb-clear-text-for-table">
  <div
    *ngIf="product.companyProductDescription"
    [innerHtml]="product.companyProductDescription"
  ></div>
  <div *ngIf="product.retail" [innerHtml]="product.retail"></div>
  <div
    *ngIf="product.paRetailsDescription"
    [innerHtml]="product.paRetailsDescription"
  ></div>
</div>
