<form [formGroup]="customerSearchForm">
  <div class="searchBox d-flex">
    <input
      #searchBox
      [ngClass]="{ expanded: show }"
      [autofocus]="!disabled"
      type="text"
      placeholder="Enter phone/rego/name/email"
      formControlName="customerSearch"
      class="form-control"
      (focus)="isFocus($event)"
      (blur)="lostFocus($event)"
      (keyup)="onKeyboard($event)"
    />
    <span class="pl-2" *ngIf="loading$ | async">
      <pos-app-spinner2 [dark]="true"></pos-app-spinner2>
    </span>
  </div>
  <div>
    <table
      *ngIf="show && listOfCustomers.length > 0"
      class="border my-4 table table-striped shadow-lg"
      [ngClass]="forOrderHeader ? 'forOrderHeaderResult' : 'searchResult'"
    >
      <thead>
        <tr>
          <th scope="col" class="text-uppercase">
            <label>Customer Name</label>
          </th>
          <th scope="col" class="text-uppercase">
            <label>Customer Type</label>
          </th>
          <th scope="col" class="text-uppercase text-nowrap">
            <label>Revenue Branch</label>
          </th>
          <th scope="col" class="text-uppercase"><label>Phone</label></th>
          <th scope="col" class="text-uppercase"><label>Email</label></th>
          <th scope="col" class="text-uppercase"><label>Address</label></th>
          <th scope="col" class="text-uppercase text-nowrap">
            <label>Vehicle Rego</label>
          </th>
          <th scope="col" class="text-uppercase text-nowrap">
            <label>Customer Number</label>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          (click)="selectCustomer(customer)"
          *ngFor="let customer of customers$ | async; let i = index"
          [ngClass]="i === indexFocus ? 'table-primary' : ''"
        >
          <td>
            <mat-icon class="selectIcon" *ngIf="i === indexFocus"> keyboard_arrow_right</mat-icon>
            <ngb-highlight [result]="customer.CustomerName" [term]="customerSearchForm.get('customerSearch').value"> </ngb-highlight>
          </td>
          <td>
            <ngb-highlight [result]="customer.CustomerType" [term]="customerSearchForm.get('customerSearch').value"></ngb-highlight>
          </td>
          <td>
            <ngb-highlight [result]="customer.revenueBranchName" [term]="customerSearchForm.get('customerSearch').value"></ngb-highlight>
          </td>
          <td>
            <ngb-highlight [result]="customer.Phone" [term]="customerSearchForm.get('customerSearch').value"></ngb-highlight>
          </td>
          <td>
            <ngb-highlight [result]="customer.Email" [term]="customerSearchForm.get('customerSearch').value"></ngb-highlight>
          </td>
          <td>
            <ngb-highlight [result]="getLocation(customer)" [term]="customerSearchForm.get('customerSearch').value"></ngb-highlight>
          </td>
          <td>
            <ngb-highlight [result]="customer.VehicleRego" [term]="customerSearchForm.get('customerSearch').value"></ngb-highlight>
          </td>
          <td>
            <ngb-highlight [result]="customer.CustomerNumber" [term]="customerSearchForm.get('customerSearch').value"></ngb-highlight>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="text-danger text-center" [ngClass]="forOrderHeader ? 'forOrderHeaderResult' : 'searchResult'" *ngIf="hasMoreRecords && show">
      More results exist - Please narrow search
      <div #customerType>
        <input type="radio" value="" formControlName="customerSearchType" /><span class="px-1"></span><label>ALL</label>
        <span class="px-2"></span>
        <input type="radio" value="RETAIL" formControlName="customerSearchType" /><span class="px-1"></span><label>RETAIL</label>
        <span class="px-2"></span>
        <input type="radio" value="BILLTO" formControlName="customerSearchType" /><span class="px-1"></span><label>BILL TO</label>
        <span class="px-2"></span>
        <input type="radio" value="SHIPTO" formControlName="customerSearchType" /><span class="px-1"></span><label>SHIPTO</label>
      </div>
    </div>
  </div>
</form>
