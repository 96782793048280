<div class="modal-header">
  <h3 class="arb-large-heading" id="modal-basic-title">
    BRANCH AND SALEPERSON
  </h3>
</div>
<div class="modal-body">
  <p>
    Please confirm the location to place the order on and the salesperson
    raising the order
  </p>
  <form [formGroup]="form">
    <div class="row">
      <div class="col-4 my-auto">
        <label class="my-auto mr-3">DISPATCH FROM</label>
      </div>
      <div class="col-8 d-flex">
        <div class="row w-100">
          <div class="col-10">
            <div
              *ngIf="!nonCorpStoreSale; else nonCorpOrder"
              class="w-100 my-auto"
            >
              <ng-select
                formControlName="overrideBranch"
                [clearable]="false"
                class="custom"
              >
                <ng-option
                  *ngFor="let branch of branchList"
                  [value]="branch.BranchCode"
                >
                  {{ branch.BranchDescription }} ({{ branch.BranchCode }})
                </ng-option>
              </ng-select>
            </div>
            <ng-template #nonCorpOrder>
              <div class="w-100 my-auto">
                <ng-select
                  formControlName="overrideShipTo"
                  [clearable]="false"
                  class="custom"
                  [placeholder]="'Please select a store'"
                >
                  <ng-option
                    *ngFor="let store of nonCorpStoreList"
                    [value]="store.customerNumber"
                  >
                    {{ store.customerName }}
                    ({{ store.address1 }}, {{ store.suburb }},
                    {{ store.postCode }}, {{ store.state }})
                  </ng-option>
                </ng-select>
              </div>
            </ng-template>
          </div>
          <span class="my-auto">
            <mat-icon
              [title]="
                nonCorpStoreSale
                  ? 'Switch to ARB branch'
                  : 'Switch to Non-Corp store'
              "
              (click)="switchShowOrder()"
              >compare_arrows</mat-icon
            >
          </span>
        </div>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-4 my-auto">
        <label class="my-auto mr-3">SALES PERSON</label>
      </div>
      <div class="col-8">
        <ng-select
          formControlName="overrideSalesPerson"
          class="custom"
          [clearable]="false"
          [placeholder]="'Please select sales person'"
        >
          <ng-option *ngFor="let person of showUsers" [value]="person.name">
            {{ person.name }} ({{ person.organisationsCode }})
          </ng-option>
        </ng-select>
      </div>
    </div>
  </form>
  <div class="d-flex justify-content-end mt-2">
    <button
      class="btn text-white"
      (click)="onSelect()"
      [disabled]="form.invalid"
    >
      SELECT
    </button>
    <span class="px-2"></span>
    <button class="btn btn-cancel" (click)="onCancel()">
      CANCEL
    </button>
  </div>
</div>
