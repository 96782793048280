<pos-app-spinner [show]="loading$ | async"></pos-app-spinner>
<div>
  <label>RECENT ORDERS:</label>
  <div class="pl-3 py-3 row">
    <div class="col-1">
      <label>USER</label>
    </div>
    <div class="col-4">
      <ng-select [(ngModel)]="selectedUser" (change)="loadRecentOrders($event)">
        <ng-option *ngFor="let user of userList" [value]="user.userID"
          >{{ user.userName }} ({{ user.userID }})</ng-option
        >
      </ng-select>
    </div>
  </div>
  <ag-grid-angular
    #agGrid
    class="ag-theme-balham datagrid"
    [rowData]="rowData"
    [context]="context"
    [frameworkComponents]="frameworkComponents"
    [gridOptions]="gridOptions"
    (gridReady)="onGridReady($event)"
    [domLayout]="'autoHeight'"
    [pagination]="true"
    [paginationPageSize]="10"
  >
  </ag-grid-angular>
</div>
