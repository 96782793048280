import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  Branch,
  OrderType,
  Customer,
  CustomerDetails,
  ORDER_TYPE
} from '@pos-app/data';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-static-filters',
  templateUrl: './static-filters.component.html',
  styleUrls: ['./static-filters.component.scss']
})
export class StaticFiltersComponent implements OnInit, OnDestroy, OnChanges {
  @Input() branchList: Branch[];
  @Input() orderTypeList: OrderType[];
  @Input() selectedCustomerNumber: string;
  @Input() preSelectedCustomer: boolean;
  @Input() orderType: string;
  @Output() filterValuesChange = new EventEmitter<any>();
  @Output() isFormValid = new EventEmitter<boolean>();
  public staticFilterForm: FormGroup;
  public isFromDateEnter: boolean;
  public selectedCustomerName = '';
  disableOrderDate = false;
  hoveredDate: NgbDate;
  orderFromDate: NgbDate;
  invoicedFromDate: NgbDate;
  toDate: NgbDate;
  ORDER_TYPE = ORDER_TYPE;

  private unSubscribe$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private cdref: ChangeDetectorRef,
    private calendar: NgbCalendar
  ) {
    this.staticFilterForm = this.formBuilder.group({
      branch: [''],
      orderType: [ORDER_TYPE.saleOrder],
      customerNumber: [''],
      ordDateFrom: [''],
      ordDateTo: [''],
      invoicedOrders10: [true],
      creditOrders10: [false],
      invDateFrom: [''],
      invDateTo: [''],
      maxCloseOrders: [0]
    });
    this.toDate = this.calendar.getToday();
    this.orderFromDate = new NgbDate(2016, 1, 1);
    this.invoicedFromDate = this.calendar.getNext(this.toDate, 'm', -2);
  }

  ngOnInit() {
    this.staticFilterForm.valueChanges
      .pipe(
        tap(value => {
          if (value.branch === null) {
            this.staticFilterForm.patchValue(
              { branch: '' },
              { emitEvent: false }
            );
          }
          if (value.orderType === null) {
            this.staticFilterForm.patchValue(
              { orderType: '' },
              { emitEvent: false }
            );
          }

          this.disableOrderDate = value.orderType === ORDER_TYPE.invoicedOrder;
        }),
        takeUntil(this.unSubscribe$)
      )
      .subscribe(() => {
        this.filterValuesChange.emit(this.staticFilterForm.getRawValue());
        this.isFormValid.emit(this.staticFilterForm.valid);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.orderType &&
      changes.orderType.currentValue &&
      this.staticFilterForm
    ) {
      this.staticFilterForm.patchValue({
        orderType:
          this.orderType === 'invoiced'
            ? ORDER_TYPE.invoicedOrder
            : this.orderType === 'quote'
            ? ORDER_TYPE.quoteOrder
            : ORDER_TYPE.saleOrder
      });
    }
    if (
      changes.selectedCustomerNumber &&
      changes.selectedCustomerNumber.currentValue &&
      this.staticFilterForm
    ) {
      this.staticFilterForm.patchValue({
        customerNumber: this.selectedCustomerNumber
      });
    }
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  selectCustomer(customer: Customer) {
    if (customer) {
      this.staticFilterForm.patchValue({
        customerNumber: customer ? customer.CustomerNumber : ''
      });
    }
  }

  selectOrderDate(date) {
    this.staticFilterForm.patchValue({
      ordDateFrom: date.fromDate,
      ordDateTo: date.toDate
    });
  }

  selectInvoicedDate(date) {
    this.staticFilterForm.patchValue({
      invDateFrom: date.fromDate,
      invDateTo: date.toDate
    });
  }

  getCustomersLink() {
    if (this.preSelectedCustomer) {
      return `/pos/customers/${this.selectedCustomerNumber}/details`;
    } else {
      return '/pos/customers/search';
    }
  }
}
