import * as fromVehicle from './vehicles';
import * as fromFilter from './filter';
import * as fromCustomer from './customers';

import { ActionReducerMap } from '@ngrx/store';

export interface IMainState {
  readonly [fromVehicle.EPC_VEHICLE_FEATURE_KEY]: fromVehicle.IVehicleState;
  readonly [fromFilter.FILTER_FEATURE_KEY]: fromFilter.IFilterState;
  readonly [fromCustomer.EPC_CUSTOMER_FEATURE_KEY]: fromCustomer.CustomersState
}

export const MainReducer: ActionReducerMap<IMainState> = {
  epcVehicle: fromVehicle.reducer,
  filter: fromFilter.filterReducer,
  epcCustomer: fromCustomer.customerReducer
};

export const MainEffects = [
  fromVehicle.VehicleEffects,
  fromFilter.FilterEffects,
  fromCustomer.CustomerEffects
];
