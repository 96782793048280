export class FetchItemPriceGroupInput {
    customerNumber: number | undefined;
    postCode: string | undefined;
    branch: string | undefined;
    brand: string | undefined;
    itemCodes: IFetchItemPriceGroupItemPriceItemCodeInput[] | undefined
}

export class FetchItemPriceGroupOutput {
    ErrorFlag: string;
    ErrorMessage: string;
    customerNumber: number;
    customerName: string;
    branch: string;
    branchDescription: string;
    p58m006Version: string;
    items: FetchItemPriceGroupOutputItem[]
}

export class FetchItemPriceGroupOutputItem {
    itemCode: string;
    itemDescription1: string;
    itemDescription2: string;
    quantity: number;
    uom: string;
    customerCurrency: string;
    customerUnitPrice: number;
    customerTaxedUnitPrice: number;
    customerPriceSchedule: string;
    customerTaxedYN: string;
    priceList: FetchItemPriceGroupItemPriceObject[];
};


export class FetchItemPriceGroupItemPriceObject implements IFetchItemPriceGroupItemPriceObject {
    priceSchedule: string;
    priceScheduleDescription: string;
    currency: string;
    unitPrice: number;
    taxedUnitPrice: number;
    taxedYN: string;
    quantity: number;
    priceLabel: string;

    constructor(data?: IFetchItemPriceGroupItemPriceObject) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }
}


export interface IFetchItemPriceGroupItemPriceObject {
    priceSchedule: string;
    priceScheduleDescription: string;
    currency: string;
    unitPrice: number;
    taxedUnitPrice: number;
    taxedYN: string;
    quantity: number,
    priceLabel: string;
}

export interface IFetchItemPriceGroupItemPriceItemCodeInput {
    code: string,
    quantity: number;
}