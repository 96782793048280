import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OOEApiServiceProxy } from './ooe-api-service-proxy';
import { CustomerDetails } from '@pos-app/data';

@Injectable({ providedIn: 'root' })
export class CustomerApiService {
  constructor(private ooeApiServiceProxy: OOEApiServiceProxy) {}

  getLookupLists(): Observable<any> {
    return this.ooeApiServiceProxy.getLookupLists().pipe(
      map(([countryListResult, stateListResult, colourListResult]) => {
        const result = {
          countryList: countryListResult.SearchResults,
          stateList: stateListResult.SearchResults,
          colourList: colourListResult.SearchResults,
        };

        return result;
      })
    );
  }

  getCustomerDetails(parameters: any): Observable<CustomerDetails> {
    return this.ooeApiServiceProxy
      .getCustomerDetails(parameters)
      .pipe(map((result) => result));
  }

  getCustomerVehicles(parameters: any): Observable<any> {
    return this.ooeApiServiceProxy
      .getCustomerVehicles(parameters)
      .pipe(map((result) => result));
  }

  maintainCustomerVehicle(parameter: any): Observable<any> {
    return this.ooeApiServiceProxy
      .maintainCustomerVehicle(parameter)
      .pipe(map((result) => result));
  }

  getOrderHeader(parameters: any): Observable<any> {
    return this.ooeApiServiceProxy
      .getOrderHeader(parameters)
      .pipe(map((result) => result.SearchResults));
  }

  updateOrderHeader(parameters: any): Observable<{refreshDetailGridTF: boolean}> {
    return this.ooeApiServiceProxy
      .updateOrderHeader(parameters)
      .pipe(map((result) => result));
  }
}
