import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, from, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DialogComponent } from '../components/dialog/dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(private ngbModal: NgbModal) {}

  confirm(
    prompt,
    title = 'Confirmation Required',
    yesButtonText = 'YES',
    noButtonText = 'NO',
    isHtml = false,
    showClose = true,
    isLarge = false,
    otherButton: { action: Observable<any>; text: string } = null
  ): Observable<boolean> {
    const modal = this.ngbModal.open(DialogComponent, {
      size: isLarge ? 'lg' : null,
      backdrop: 'static'
    });

    modal.componentInstance.body = prompt;
    modal.componentInstance.title = title;
    modal.componentInstance.YesButton = yesButtonText;
    modal.componentInstance.NoButton = noButtonText;
    modal.componentInstance.bodyIsHtml = isHtml;
    modal.componentInstance.ShowClose = showClose;
    modal.componentInstance.otherButton = otherButton;

    return from(modal.result).pipe(
      catchError(error => {
        console.warn(error);
        return of(undefined);
      })
    );
  }

  alert(
    message,
    title = 'Information',
    okButton = 'OK',
    isHtml = false
  ): Observable<boolean> {
    const modal = this.ngbModal.open(DialogComponent, { backdrop: 'static' });

    modal.componentInstance.body = message;
    modal.componentInstance.title = title;
    modal.componentInstance.okButton = okButton;
    modal.componentInstance.bodyIsHtml = isHtml;

    return from(modal.result).pipe(
      catchError(error => {
        console.warn(error);
        return of(undefined);
      })
    );
  }

  error(
    message,
    title = 'Error',
    okButton = 'OK',
    isHtml = false
  ): Observable<boolean> {
    const modal = this.ngbModal.open(DialogComponent, { backdrop: 'static' });

    modal.componentInstance.body = message;
    modal.componentInstance.title = title;
    modal.componentInstance.okButton = okButton;
    modal.componentInstance.bodyIsHtml = isHtml;

    return from(modal.result).pipe(
      catchError(error => {
        console.warn(error);
        return of(undefined);
      })
    );
  }
}
