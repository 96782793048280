import { Component, OnInit, OnDestroy } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Router, NavigationEnd, RouterEvent } from '@angular/router';

import { Subject } from 'rxjs';
import { parseInt, isNumber, cloneDeep } from 'lodash';
import { EpcApiService } from '../../services/epc-api.service';
import { AppConsts } from '../../constants/AppConsts';
import { BreadCrumbResponseDto } from '../../models/service-proxies';
import {
  distinctUntilChanged,
  filter,
  finalize,
  takeUntil,
} from 'rxjs/operators';
import { MessageService } from '@pos-app/core-ui';
import { OOESessionService } from 'libs/core-ui/src/lib/services/ooe-session.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  public menuItems: MenuItem[] = [];
  private loading = false;
  private destroyed$ = new Subject();

  constructor(
    private router: Router,
    private epcApiService: EpcApiService,
    private messageService: MessageService,
    private ooeSessionService: OOESessionService
  ) {}

  public ngOnInit(): void {
    this.initRouterWhenPageReload(this.router.url);

    this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationEnd),
        takeUntil(this.destroyed$),
        distinctUntilChanged()
      )
      .subscribe((router: any) => {
        if (router.url.includes('page=')) {
          return;
        }
        this.initRouterWhenPageReload(router.url);
      });

    this.messageService
      .listen('productUpdated')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(({ categoryId, companySKU }) => {
        if (this.menuItems.length <= 1 && !this.loading) {
          this.getBreadCrumbLink('Product', companySKU, 'product/', categoryId);
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private initRouterWhenPageReload(activeUrl: string): void {
    const lastPath = activeUrl.split('/').pop();
    this.menuItems = [];
    this.menuItems.push({
      label: '',
      routerLink: '.',
      icon: 'pi pi-home',
    });

    if (
      activeUrl.includes('epc') &&
      !activeUrl.includes('category') &&
      activeUrl.includes('?') &&
      activeUrl.includes('id=')
    ) {
      const id = lastPath.substring(lastPath.indexOf('=') + 1);
      if (id && isNumber(parseInt(id))) {
        this.getBreadCrumbLink('EPC', id, '?id=', '');
      }
    }

    if (activeUrl.includes('/category/')) {
      const id = lastPath.includes('?page')
        ? lastPath.substring(0, lastPath.indexOf('?'))
        : lastPath;
      if (id && isNumber(parseInt(id))) {
        this.getBreadCrumbLink('Category', id, 'category/', '');
      }
    }
  }

  private initMenuItem(category: BreadCrumbResponseDto): MenuItem {
    return {
      label: category.name,
      routerLink: category.isFirst ? '/epc' : category.redirectUrl,
    };
  }

  private getBreadCrumbLink(
    routerName: string,
    id: string,
    redirectUrl: string,
    originalCategoryId: string
  ): void {
    this.loading = true;
    this.epcApiService
      .getBreadCrumbLink(
        routerName,
        id,
        redirectUrl,
        originalCategoryId,
        AppConsts.brand,
        this.ooeSessionService.priceLists
      )
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => (this.loading = false))
      )
      .subscribe((response) => {
        if (response && !!response.length) {
          response.forEach((category) => {
            const menuItem = this.initMenuItem(category);
            this.menuItems.push(menuItem);
          });

          this.menuItems = cloneDeep(this.menuItems);
        }
      });
  }
}
