import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomersBaseComponent } from './features/customers/containers/customers-base/customers-base.component';
import { EmptyRouteComponent } from './core/components/empty-route/empty-route.component';
import { ForgotPasswordComponent } from './features/auth/forgotpassword/forgotpassword.component';
import { LoginComponent } from './features/auth/login/login.component';
import { OrdersBaseComponent } from './features/orders/containers/orders-base/orders-base.component';
import { ResetPasswordComponent } from './features/auth/resetpassword/resetpassword.component';
import { ResourcesComponent } from './features/resources/resources.component';
import { AuthGuard } from './core/guards/auth.guard';
import { SignUpComponent } from './features/auth/signup/signup.component';
import { ManageusersInternalComponent } from './features/manageusers/containers/manageusers-internal/manageusers-internal.component';
import { AdminGuard } from './core/guards/admin.guard';
import { ManageusersExternalComponent } from './features/manageusers/containers/manageusers-external/manageusers-external.component';
import { UnsavedChangesCanDeactivateGuard } from './core/guards/candeactivate.guard';
import { OrdersStartComponent } from './features/orders/containers/orders-start/orders-start.component';
import { ManageusersProfileComponent } from './features/manageusers/containers/manageusers-profile/manageusers-profile.component';
import { OrdersDetailsComponent } from './features/orders/containers/orders-details/orders-details.component';
import { CustomersDetailsComponent } from './features/customers/containers/customers-details/customers-details.component';
import { CustomersShipToComponent } from './features/customers/containers/customers-ship-to/customers-ship-to.component';
import { CustomersVehiclesMaintenanceComponent } from './features/customers/containers/customers-vehicles-maintenance/customers-vehicles-maintenance.component';
import { CustomerOrdersComponent } from './features/customers/containers/customer-orders/customer-orders.component';
import { CustomersVehiclesDetailsComponent } from './features/customers/containers/customers-vehicles-details/customers-vehicles-details.component';
import { CustomersVehiclesDetailsNewComponent } from './features/customers/containers/customers-vehicles-details-new/customers-vehicles-details-new.component';
import { CustomersDetailsNewComponent } from './features/customers/containers/customers-details-new/customers-details-new.component';
import { CustomersInvoiceComponent } from './features/customers/containers/customers-invoice/customers-invoice.component';
import { CustomersStatementComponent } from './features/customers/containers/customers-statement/customers-statement.component';
import { RegisterAdminComponent } from './features/register-admin/register-admin.component';
import { RegisterGuard } from './core/guards/register.guard';
import { WarrantyformComponent } from './features/warrantyform/containers/warrantyform.component';
import { ManageusersShowComponent } from './features/manageusers/containers/manageusers-show/manageusers-show.component';
import { CustomersShowSalesComponent } from './features/customers/containers/customers-show-sales/customers-show-sales.component';
import { CustomersNotesComponent } from './features/customers/containers/customers-notes/customers-notes.component';
import { CustomersTaskCalendarComponent } from './features/customers/containers/customers-task-calendar/customers-task-calendar.component';
import { ContentComponent } from './features/content/content.component';
import { CustomersMyOrdersComponent } from './features/customers/containers/customers-my-orders/customers-my-orders.component';
import { AllocateFitterComponent } from './features/filter/containers/allocate-fitter/allocate-fitter.component';
import { CamperTrailerComponent } from './features/camper-trailer/containers/camper-trailer/camper-trailer.component';
import { LandingComponent } from './features/epc/containers/landing/landing.component';
import { LandingMainComponent } from './features/epc/components/landing-main/landing-main.component';
import { ProductListComponent } from './features/epc/containers/product-list/product-list.component';
import { ProductComponent } from './features/epc/containers/product/product.component';
import { EpcGuard } from './core/guards/epc.guard';
import { ShowAdminGuard } from './core/guards/show-admin.guard';
import { SchedulerComponent } from './features/scheduler/scheduler.component';

const routes: Routes = [
  {
    path: 'pos/login',
    component: LoginComponent,
  },
  {
    path: 'pos/forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    /* TODO: remove if it is unused */
    path: 'pos/reset-password',
    component: ResetPasswordComponent,
  },
  /* TODO: remove if it is unused */
  {
    path: 'pos/signup',
    component: SignUpComponent,
  },
  {
    path: 'pos/orders',
    children: [
      {
        path: '',
        component: OrdersBaseComponent,
      },
      {
        path: 'customer/:customerNumber',
        component: OrdersBaseComponent,
      },
      {
        path: ':id',
        component: OrdersDetailsComponent,
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'pos/orders-start',
    component: OrdersStartComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'pos/resources',
    component: ResourcesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'pos/customers/search',
    component: CustomersBaseComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'pos/my-orders',
    component: CustomersMyOrdersComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'pos/customers/new',
    component: CustomersBaseComponent,
    children: [
      {
        path: '',
        component: CustomersDetailsNewComponent,
        canDeactivate: [UnsavedChangesCanDeactivateGuard],
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'pos/customers/:id',
    component: CustomersBaseComponent,
    children: [
      { path: '', redirectTo: 'details', pathMatch: 'full' },
      {
        path: 'details',
        component: CustomersDetailsComponent,
        canDeactivate: [UnsavedChangesCanDeactivateGuard],
      },
      {
        path: 'invoice',
        component: CustomersInvoiceComponent,
      },
      {
        path: 'statement',
        component: CustomersStatementComponent,
      },
      {
        path: 'showsales',
        component: CustomersShowSalesComponent,
      },
      {
        path: 'shipTo',
        component: CustomersShipToComponent,
        canDeactivate: [UnsavedChangesCanDeactivateGuard],
      },
      {
        path: 'vehicles',
        component: CustomersVehiclesMaintenanceComponent,
      },
      {
        path: 'orders',
        component: CustomerOrdersComponent,
      },
      {
        path: 'vehicles/:id',
        component: CustomersVehiclesDetailsComponent,
        canDeactivate: [UnsavedChangesCanDeactivateGuard],
      },
      {
        path: 'add/vehicles',
        component: CustomersVehiclesDetailsNewComponent,
        canDeactivate: [UnsavedChangesCanDeactivateGuard],
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'pos/customers/:id/notes',
    component: CustomersNotesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'pos/customers/:id/taskCalendar',
    component: CustomersTaskCalendarComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'pos/manageusers/internal',
    component: ManageusersInternalComponent,
    canActivate: [AuthGuard, AdminGuard],
  },
  {
    path: 'pos/manageusers/external',
    component: ManageusersExternalComponent,
    canActivate: [AuthGuard, AdminGuard],
    canDeactivate: [UnsavedChangesCanDeactivateGuard],
  },
  {
    path: 'pos/manageusers/show',
    component: ManageusersShowComponent,
    canActivate: [AuthGuard, ShowAdminGuard],
  },
  {
    path: 'pos/manageusers/profile',
    component: ManageusersProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'pos/register',
    component: RegisterAdminComponent,
    canActivate: [AuthGuard, RegisterGuard],
  },
  {
    path: 'pos/allocate-fitter',
    component: AllocateFitterComponent,
  },
  {
    path: 'pos/warranty',
    component: WarrantyformComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'pos/content',
    component: ContentComponent,
  },
  {
    path: 'pos/camper-trailer/:id',
    component: CamperTrailerComponent,
  },
  {
    path: 'pos/scheduler/:day/:month/:year',
    component: SchedulerComponent,
  },
  {
    path: 'epc',
    canActivate: [AuthGuard, EpcGuard],
    children: [
      {
        path: '',
        component: LandingComponent,
        children: [
          { path: '', component: LandingMainComponent, pathMatch: 'full' },
          {
            path: 'category/:id',
            data: {
              breadcrumb: 'Category',
            },
            component: ProductListComponent,
          },
          {
            path: 'product/:productNumber',
            data: {
              breadcrumb: 'Product',
            },
            component: ProductComponent,
          },
        ],
      },
    ],
  },
  {
    path: '**',
    component: EmptyRouteComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [{ provide: APP_BASE_HREF, useValue: '/' }],
})
export class AppRoutingModule {}
