import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '@pos-app/core-ui';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-orders-check-credit-users',
  templateUrl: './orders-check-credit-users.component.html',
  styleUrls: ['./orders-check-credit-users.component.scss']
})
export class OrdersCheckCreditUsersComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @Output() isCreditUser = new EventEmitter<boolean>();
  @ViewChild('content') modalBox: any;
  userForm: FormGroup;
  unSubscribe$ = new Subject<void>();
  error: string;

  constructor(
    private modalService: NgbModal,
    private apiService: ApiService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.userForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  ngAfterViewInit() {
    this.modalService.open(this.modalBox).result.then(
      _ => {
        this.isCreditUser.emit(false);
      },
      _ => {
        this.isCreditUser.emit(false);
      }
    );
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  checkCreditUser() {
    this.apiService
      .checkUserForCredits(this.userForm.value)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe(
        res => {
          if (res) {
            this.modalService.dismissAll();
            return this.isCreditUser.emit(true);
          }
        },
        error => {
          this.error = error.ErrorMessage;
        }
      );
  }

  cancel() {
    this.modalService.dismissAll();
    this.isCreditUser.emit(false);
  }
}
