import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { OnHandQty, StockAllocation } from '../../+state/orders.models';
import { FormBuilder, FormGroup, FormControl, FormArray, ValidatorFn, ValidationErrors, Validators } from '@angular/forms';
import { Subject, combineLatest, of } from 'rxjs';
import { takeUntil, distinctUntilChanged, debounceTime, switchMap } from 'rxjs/operators';
import { ApiService } from '@pos-app/core-ui';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-orders-stock-allocation',
  templateUrl: './orders-stock-allocation.component.html',
  styleUrls: ['./orders-stock-allocation.component.scss'],
})
export class OrdersStockAllocationComponent implements OnInit, OnDestroy {
  @Input() stockList: StockAllocation[];
  @Input() isCreditOrder: boolean;
  @Input() showLocation: string;

  @Output() allocateStock = new EventEmitter<any>();
  @Output() isSerialNumberExist = new EventEmitter<any>();

  stockAllocationForm: FormGroup;
  serializedList: StockAllocation[];
  noStockInBlankList: StockAllocation[];
  serialNumberFormControl = new FormControl('');
  onHandSerializedItems: {
    itemNumber: string;
    onHandArray: OnHandQty[];
  }[] = [];
  math = Math;
  errorList: StockAllocation[];
  isSerialNumberOnHand: boolean = false;
  selectedRowIndex: number;
  indexRowArray: {
    isSerialNumberOnHand: boolean;
    selectedRowIndex: number;
  }[] = [];
  serialNumberInputChange = new Subject<{
    itemNumber;
    serialNumber;
    rowIndex;
  }>();

  private unSubscribe$ = new Subject<void>();

  constructor(private fb: FormBuilder, private apiService: ApiService, private toastr: ToastrService) {}

  ngOnInit() {
    this.stockAllocationForm = new FormGroup({});

    this.errorList = this.stockList.filter((x) => x.errorMessage);
    this.serializedList = this.stockList.filter((x) => !x.errorMessage && x.serializedYN === 'Y');
    this.noStockInBlankList = this.stockList.filter((x) => x.serializedYN === 'N');
    this.serializedList.forEach((item) => {
      const allocatedSerialNumbers = item.allocatedSerialNumbers?.length
        ? item.allocatedSerialNumbers
        : this.isCreditOrder
        ? new Array(Math.abs(item.quantityRemaining)).fill('')
        : [];

      this.stockAllocationForm.addControl(
        item.lineNumber,
        this.isCreditOrder
          ? new FormArray(allocatedSerialNumbers.map((item) => new FormGroup({ serialNumber: new FormControl(item, [Validators.required]) })))
          : new FormControl(item.allocatedSerialNumbers || [])
      );

      if (this.isCreditOrder) {
        this.onHandSerializedItems = [];
      } else {
        if (!this.onHandSerializedItems.find((serializedList) => serializedList.itemNumber === item.itemNumber)) {
          this.onHandSerializedItems.push({
            itemNumber: item.itemNumber,
            onHandArray: item.onHandQtyArray.filter((onHandQty) => onHandQty.serialNumber !== ''),
          });
        }
      }
    });
    this.noStockInBlankList.forEach((item) => {
      this.stockAllocationForm.addControl(
        item.lineNumber,
        new FormArray(
          item.stockAllocation.map((stockAllocationItem) => {
            return new FormGroup({
              itemLocation: new FormControl(
                {
                  value: this.showLocation ? this.showLocation : stockAllocationItem.location || null,
                  disabled: !!this.showLocation,
                },
                this.itemLocationFormValidation()
              ),
              qty: new FormControl({
                value: stockAllocationItem.quantity || 0,
                disabled: !!this.showLocation,
              }),
            });
          })
        )
      );
    });

    this.allocateStock.emit(this.stockAllocationForm);

    this.serialNumberFormControl.valueChanges.pipe(debounceTime(250), distinctUntilChanged(), takeUntil(this.unSubscribe$)).subscribe((val) => {
      this.serializedList.forEach((item) => {
        const foundSerialNumber = this.getAvailableSerializedItems(item.itemNumber).find((onHand) => onHand.serialNumber === val);
        if (foundSerialNumber) {
          const serialNumber = foundSerialNumber.serialNumber;
          const selectedList: string[] = this.stockAllocationForm.controls[item.lineNumber].value;

          if (selectedList.length < item.quantity && selectedList.findIndex((x) => x === serialNumber) === -1) {
            selectedList.push(serialNumber);
            this.stockAllocationForm.controls[item.lineNumber].patchValue(selectedList);
            this.serialNumberFormControl.patchValue('', { emitEvent: false });
          }
        }
      });
    });
    this.stockAllocationForm.setValidators([this.allocationFormValidator(this.serializedList, this.noStockInBlankList)]);
    this.stockAllocationForm.updateValueAndValidity();
    this.stockAllocationForm.valueChanges.pipe(takeUntil(this.unSubscribe$)).subscribe((val) => {
      this.allocateStock.emit(this.stockAllocationForm);
    });
    this.allocateStock.emit(this.stockAllocationForm);

    this.listenInputChange();
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  getFormArray(lineNumber: string) {
    return (this.stockAllocationForm.controls[lineNumber] as FormArray).controls;
  }

  addLocation(allocation: StockAllocation) {
    const items = this.stockAllocationForm.controls[allocation.lineNumber] as FormArray;
    const allocatedQty = this.getAllocatedQty(allocation);
    items.push(
      this.fb.group({
        itemLocation: [null],
        qty: [allocation.quantity - allocatedQty],
      })
    );
  }

  removeLocation(lineNumber, index) {
    const items = this.stockAllocationForm.controls[lineNumber] as FormArray;
    items.removeAt(index);
  }

  getAvailableLocation(allocatedItem: StockAllocation, currentItem) {
    if (currentItem) {
      const items = this.getFormArray(allocatedItem.lineNumber);
      const pickedLocations = [];
      items.forEach((item) => {
        pickedLocations.push(item.get('itemLocation').value);
      });
      return allocatedItem.onHandQtyArray.filter(
        (x) => pickedLocations.indexOf(x.location) === -1 || currentItem.get('itemLocation').value === x.location
      );
    }
  }

  getAvailableSerializedItems(itemNumber: string) {
    let pickedItems: string[] = [];
    this.serializedList
      .filter((item) => item.itemNumber === itemNumber)
      .forEach((item) => {
        const pickedList = this.stockAllocationForm.controls[item.lineNumber].value;
        if (pickedList?.length > 0) {
          pickedItems = [...pickedItems, ...pickedList];
        }
      });

    return this.onHandSerializedItems
      .find((item) => item.itemNumber === itemNumber)
      .onHandArray.filter((serializedItem: OnHandQty) => !pickedItems.find((pickedItem) => pickedItem === serializedItem.serialNumber));
  }

  checkNegative(onHandQtyArray, item) {
    return item.get('itemLocation').value
      ? item.get('qty').value > onHandQtyArray.find((x) => x.location === item.get('itemLocation').value).qtyOnHand
      : false;
  }

  shouldPickAnotherLocation(allocatedItem: StockAllocation) {
    const items = this.stockAllocationForm.controls[allocatedItem.lineNumber] as FormArray;
    if (allocatedItem.quantity >= 0) {
      return this.getAllocatedQty(allocatedItem) < allocatedItem.quantity;
    }
    return this.getAllocatedQty(allocatedItem) > allocatedItem.quantity;
  }

  validStockAllocation(allocatedItem: StockAllocation) {
    return this.getAllocatedQty(allocatedItem) === allocatedItem.quantity;
  }

  getAllocatedQty(allocationItem: StockAllocation) {
    const items = this.getFormArray(allocationItem.lineNumber);
    let allocatedQty = 0;
    items.forEach((item) => {
      allocatedQty += item.get('itemLocation').value !== null ? item.get('qty').value : 0;
    });
    
    return allocatedQty;
  }

  allocationFormValidator(serializedList: StockAllocation[], noStockInBlankList: StockAllocation[]): ValidatorFn {
    return (form: FormGroup): ValidationErrors => {
      let errors = null;
      serializedList.forEach((item) => {
        if (form.controls[item.lineNumber].value.length !== this.math.abs(item.quantity)) {
          errors = { invalid: true };
        }
      });
      noStockInBlankList.forEach((item) => {
        const locations = this.getFormArray(item.lineNumber);
        let allocatedQty = 0;
        locations.forEach((location) => {
          if (location.get('itemLocation').value !== null) {
            allocatedQty += location.get('qty').value;
          }
        });
        if (allocatedQty !== item.quantity) {
          errors = { invalid: true };
        }
      });
      if (this.errorList.length > 0) {
        errors = { invalid: true };
      }
      return errors;
    };
  }

  onChangeMethod(event: Event, itemNumber, rowIndex) {
    const serialNumber = event.target['value']?.trim();
    this.selectedRowIndex = rowIndex;

    if (serialNumber) {
      this.serialNumberInputChange.next({
        itemNumber,
        serialNumber,
        rowIndex,
      });
    } else {
      this.toastr.error('Please enter a serial number');
      this.isSerialNumberExist.emit(true);
    }
  }

  listenInputChange() {
    this.serialNumberInputChange
      .pipe(
        debounceTime(300),
        takeUntil(this.unSubscribe$),
        switchMap((data) => {
          const input = {
            itemNumber: data.itemNumber,
            lotNumber: data.serialNumber,
          };

          return combineLatest([of(data.rowIndex), this.apiService.checkSerialNumberOnHand(input)]);
        })
      )
      .subscribe(([rowIndex, res]) => {
        if (res.onHandYN === 'Y') {
          this.toastr.error('Serial number already on hand');
          this.isSerialNumberOnHand = true;
        } else {
          this.isSerialNumberOnHand = false;
        }
        const foundIndex = this.indexRowArray.findIndex((item) => item.selectedRowIndex === this.selectedRowIndex);
        if (foundIndex === -1) {
          this.indexRowArray.push({
            isSerialNumberOnHand: this.isSerialNumberOnHand,
            selectedRowIndex: rowIndex,
          });
        } else {
          this.indexRowArray[foundIndex].isSerialNumberOnHand = this.isSerialNumberOnHand;
        }
        this.isSerialNumberExist.emit(!!this.indexRowArray.find((item) => item.isSerialNumberOnHand));
      });
  }

  checkRowIsSerialNumberOnHand(index) {
    const obj = this.indexRowArray.find((item) => item.selectedRowIndex === index);
    return obj ? obj.isSerialNumberOnHand : false;
  }

  itemLocationFormValidation(): ValidatorFn {
    return (itemLocation: FormControl): ValidationErrors => {
      const errors = itemLocation.value === null ? { invalidLocation: true } : null;
      return errors;
    };
  }
}
