<div class="card">
  <div class="card-body">
    <form class="row" [formGroup]="customerShipToForm">
      <div class="col-sm-6">
        <div class="form-group row">
          <label class="col-sm-4  my-2" for="name">NAME *</label>
          <div class="col-sm-8">
            <input
              type="text"
              class="form-control"
              [class.is-invalid]="
                customerShipToForm.controls.name.touched && customerShipToForm.controls.name.errors
              "
              formControlName="name"
              (keyup.enter)="pressEnter()"
              autofocus
            />
            <div
              class="invalid-feedback"
              *ngIf="
                customerShipToForm.controls.name.touched && customerShipToForm.controls.name.errors
              "
            >
              This field is required
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4  my-2" for="name">EMAIL</label>
          <div class="col-sm-8">
            <input
              type="text"
              class="form-control"
              [class.is-invalid]="
                customerShipToForm.controls.email.touched &&
                customerShipToForm.controls.email.errors
              "
              formControlName="email"
              (keyup.enter)="pressEnter()"
            />
            <div
              class="invalid-feedback"
              *ngIf="
                customerShipToForm.controls.email.touched &&
                customerShipToForm.controls.email.errors?.email
              "
            >
              Email is invalid
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4  my-2" for="name">PHONE</label>
          <div class="col-sm-8">
            <input
              type="text"
              class="form-control"
              [class.is-invalid]="
                customerShipToForm.controls.phone.touched &&
                customerShipToForm.controls.phone.errors
              "
              formControlName="phone"
              (keyup.enter)="pressEnter()"
            />
            <div
              class="invalid-feedback"
              *ngIf="
                customerShipToForm.controls.phone.touched &&
                customerShipToForm.controls.phone.errors?.pattern
              "
            >
              Phone number is invalid
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4  my-2" for="name">ADDRESS LINE 1 *</label>
          <div class="col-sm-8">
            <input
              type="text"
              class="form-control"
              [class.is-invalid]="
                customerShipToForm.controls.addressLine1.touched &&
                customerShipToForm.controls.addressLine1.errors
              "
              formControlName="addressLine1"
              (keyup.enter)="pressEnter()"
            />
            <div
              class="invalid-feedback"
              *ngIf="
                customerShipToForm.controls.addressLine1.touched &&
                customerShipToForm.controls.addressLine1.errors?.required
              "
            >
              This field is required
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4  my-2" for="name">ADDRESS LINE 2</label>
          <div class="col-sm-8">
            <input
              type="text"
              class="form-control"
              formControlName="addressLine2"
              (keyup.enter)="pressEnter()"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4  my-2" for="name">SUBURB</label>
          <div class="col-sm-8">
            <input
              type="text"
              class="form-control"
              formControlName="suburb"
              (keyup.enter)="pressEnter()"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4  my-2" for="name">STATE</label>
          <div class="col-sm-8">
            <ng-select
              [items]="stateList"
              formControlName="state"
              bindLabel="StateDescription"
              bindValue="StateCode"
            >
            </ng-select>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4  my-2" for="name">POSTCODE</label>
          <div class="col-sm-8">
            <input
              type="text"
              class="form-control"
              formControlName="postCode"
              (keyup.enter)="pressEnter()"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4  my-2" for="name">COUNTRY</label>
          <div class="col-sm-8">
            <ng-select
              [items]="lookupLists?.countryList"
              formControlName="country"
              bindLabel="CountryDescription"
              bindValue="CountryCode"
            >
            </ng-select>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group row">
          <label class="col-sm-4  my-2" for="delInstructions1"
            >DELIVERY INSTRUCTION 1</label
          >
          <input
            class="col-sm-7 form-control"
            type="text"
            formControlName="delInstructions1"
            (keyup.enter)="pressEnter()"
          />
        </div>
        <div class="form-group row">
          <label class="col-sm-4  my-2" for="delInstructions2"
            >DELIVERY INSTRUCTION 2</label
          >
          <input
            class="col-sm-7 form-control"
            type="text"
            formControlName="delInstructions2"
            (keyup.enter)="pressEnter()"
          />
        </div>
        <div class="form-group row">
          <label class="col-sm-4  my-2" for="zone"
            >PICK SCHEDULE (ZONE)</label
          >
          <ng-select
            [items]="lookupLists?.zoneList"
            formControlName="zone"
            bindLabel="ZoneDescription"
            bindValue="ZoneCode"
            class="col-sm-7"
          >
          </ng-select>
        </div>
        <div class="form-group row">
          <label class="col-sm-4  my-2" for="route"
            >PICK DAYS (ROUTE)</label
          >
          <ng-select
            [items]="lookupLists?.routeList"
            formControlName="route"
            bindLabel="RouteDescription"
            bindValue="RouteCode"
            class="col-sm-7"
          >
          </ng-select>
        </div>
        <div class="form-group row">
          <label class="col-sm-4  my-2" for="freightHandlingCode"
            >FREIGHT HANDLING</label
          >
          <ng-select
            [items]="lookupLists?.freightList"
            formControlName="freightHandlingCode"
            bindLabel="FreightHandlingDescription"
            bindValue="FreightHandlingCode"
            class="col-sm-7"
          >
          </ng-select>
        </div>
        <div class="form-group row">
          <label class="col-sm-4  my-2" for="carrier">CARRIER</label>
          <ng-select formControlName="carrier" class="col-sm-7">
            <ng-option
              *ngFor="let carrier of lookupLists?.carrierList"
              [value]="carrier.CarrierCode"
              [title]="carrier.CarrierCode"
            >
              {{ carrier.CarrierDescription }} ({{ carrier.CarrierCode }}) </ng-option
            >>
          </ng-select>
        </div>
        <div class="form-group row">
          <label class="col-sm-4  my-2" for="defaultBillTo"
            >DEFAULT SOLD TO</label
          >
          <select formControlName="defaultBillTo">
            <option [value]="soldToCustomerData.number">
              {{ soldToCustomerData.name }}
            </option>
            <option [value]="0">
              No Default
            </option>
          </select>
        </div>
      </div>
    </form>
  </div>
</div>
