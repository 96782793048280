import { ICellRenderer, ICellRendererParams } from 'ag-grid-community';
import { Component } from '@angular/core';

@Component({
  template: `
    <div *ngIf="data.orderNumber">
      <div *ngIf="showLoading" class="cell-loading"><pos-app-spinner2 [dark]="true"></pos-app-spinner2> Loading..</div>

      <div *ngIf="!showLoading">
        <span [innerHTML]="value"></span>
      </div>
    </div>
  `,
})
export class OrderLoadingCellRendererComponent implements ICellRenderer {
  public value: string;
  public data: any;
  public showLoading: boolean;

  public agInit(params: ICellRendererParams): void {
    this.setData(params);
  }

  public refresh(params: ICellRendererParams): boolean {
    this.setData(params);
    return true;
  }

  public setData(params: ICellRendererParams): void {
    this.data = params.node.data;
    this.value = params.value;
    
    if (params.colDef.colId === 'unitPrice') {
      this.showLoading = this.data.updatingRow?.trim() === 'ADD';
    } else {
      this.showLoading = !!this.data.updatingRow?.trim();
    }
  }
}
