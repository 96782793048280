import { Injectable } from '@angular/core';
import { KEYS } from '@pos-app/data';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SecureCatalogueService {
  private isCatalogueVisible = new BehaviorSubject<boolean>(true);
  constructor() {
    const status = localStorage.getItem(KEYS.catalogueStatus);
    this.isCatalogueVisible.next(
      status ? JSON.parse(status).catalogueVisibleYN === 'Y' : false
    );
  }

  setIsCatalogueVisible(value: boolean) {
    this.isCatalogueVisible.next(value);
  }

  getIsCatalogueVisible() {
    return this.isCatalogueVisible.value;
  }

  getLandingPage() {
    return this.isCatalogueVisible.value ? '/epc' : '/pos/customers/search';
  }
}
