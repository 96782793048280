import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { OrdersActionTypes } from './orders.actions';
import { Observable } from 'rxjs';
import { Supplier } from '../models/supplier.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OrdersResponseService {
  constructor(private actions$: Actions) {}

  public addItemsToOrderSuccess$: Observable<Supplier[]> = this.actions$.pipe(
    ofType(OrdersActionTypes.ADD_ITEMS_TO_ORDER_SUCCESS),
    map(({payload}) => payload)
  );

  public addItemsToOrderFailed$: Observable<Supplier[]> = this.actions$.pipe(
    ofType(OrdersActionTypes.ADD_ITEMS_TO_ORDER_FAILED),
    map(({payload}) => payload)
  );
}
