import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  ApiService,
  CoreUiPartialState,
  getUserRole,
  getSecurityFunctions,
  isExternalUser,
  getUserId,
  getOrderTypes,
  getNonCorpOrderTypes,
  getCashDrawer,
  getActiveOrder,
} from '@pos-app/core-ui';
import { CustomerDetails, CustomerSales, OpenOrder, OrderType, ORDER_TYPE, SECURITY_FUNCTION, USER_ROLE } from '@pos-app/data';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { ClearData, GetCustomerSalesAction, GetUserStateAction, LoadLookupLists, SelectCustomerAction } from '../../+state/customers.actions';
import { CustomersPartialState } from '../../+state/customers.reducer';
import { customersQuery } from '../../+state/customers.selectors';
import { FetchJdeOrder } from '../../../orders/+state/orders.actions';
import { ordersQuery } from '../../../orders/+state/orders.selectors';
import { CustomersRecentOrdersComponent } from '../../components/customers-recent-orders/customers-recent-orders.component';

@Component({
  templateUrl: './customers-my-orders.html',
  styleUrls: ['./customers-my-orders.scss'],
})
export class CustomersMyOrdersComponent implements OnInit, OnDestroy {
  @ViewChild('saveToJDE') fetchLatestOrders: CustomersRecentOrdersComponent;

  public selectedCustomer: CustomerDetails;
  public activeOrder$: Observable<any>;
  private unSubscribe$ = new Subject<void>();
  public isExternalUser$: Observable<boolean>;
  public userID$: Observable<string>;
  public securityFunctions: string[];
  public loadingCustomerDetails: boolean;
  public loadingFetchingOrderFromJDE: boolean;
  public customerSales: CustomerSales;
  public openOrder: OpenOrder;
  public orderTypes: OrderType[];
  public hasCashDrawer: boolean;

  public isShowUser: boolean;

  SECURITY_FUNCTION = SECURITY_FUNCTION;
  isFetching: boolean;
  giftCardNumber: string;
  giftCardBalance: string;
  giftCardErrorMessage: string;

  constructor(
    private customerStore: Store<CustomersPartialState>,
    private coreUiStore: Store<CoreUiPartialState>,
    private apiService: ApiService,
    private toastr: ToastrService,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.dispatchGetUserState();
    combineLatest([this.activeRoute.params, this.coreUiStore.select(getUserRole), this.coreUiStore.select(getSecurityFunctions)])
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe(([routeParams, userRole, securityFunctions]) => {
        this.securityFunctions = securityFunctions;
        this.isShowUser = userRole && userRole.includes('SHOW');

        if (routeParams && routeParams.id) {
          // for external user, it will call CustomerDetailsExternal API that takes no input
          this.customerStore.dispatch(new SelectCustomerAction(userRole !== USER_ROLE.external ? routeParams.id : null));
          this.customerStore.dispatch(new GetCustomerSalesAction(routeParams.id));
        } else {
          this.customerStore.dispatch(new ClearData());
        }
      });

    this.customerStore
      .select(customersQuery.getLoadingCustomerDetails)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((x) =>
        setTimeout(() => {
          this.loadingCustomerDetails = x;
        }, 0)
      );

    this.customerStore
      .select(ordersQuery.getIsFetchingJdeOrder)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((x) =>
        setTimeout(() => {
          this.loadingFetchingOrderFromJDE = x;
        }, 0)
      );

    this.customerStore
      .select(customersQuery.getSelectedCustomer)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((customer) => {
        setTimeout(() => {
          this.selectedCustomer = customer;
        }, 0);
      });

    this.customerStore
      .select(customersQuery.getCustomerSales)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((sales) => {
        setTimeout(() => {
          this.customerSales = sales;
        }, 0);
      });

    this.customerStore.dispatch(new LoadLookupLists());
    this.activeOrder$ = this.coreUiStore.select(getActiveOrder);
    this.isExternalUser$ = this.coreUiStore.select(isExternalUser);
    this.userID$ = this.coreUiStore.select(getUserId);

    combineLatest([this.coreUiStore.select(getOrderTypes), this.coreUiStore.select(getNonCorpOrderTypes), this.coreUiStore.select(getCashDrawer)])
      .pipe(take(1))
      .subscribe(([orderTypes, nonCorpOrderTypes, cashDrawer]) => {
        const register = JSON.parse(cashDrawer);
        this.hasCashDrawer = register && register.status === 'O';
        // concat 2 lists and remove duplicates
        this.orderTypes = orderTypes
          .concat(nonCorpOrderTypes)
          .filter((type, index, array) => index === array.findIndex((orderType) => orderType.OrderTypeCode === type.OrderTypeCode))
          .filter((type) => type.PaymentYN !== 'Y' || this.hasCashDrawer);
      });
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  fetchOrderFromJDE(data) {
    if (
      this.isShowUser &&
      ((data.orderType === ORDER_TYPE.reservedInvoice && !this.hasCashDrawer) ||
        (data.orderType !== ORDER_TYPE.reservedInvoice && this.orderTypes.findIndex((type) => type.OrderTypeCode === data.orderType) === -1))
    ) {
      this.toastr.error('Cannot fetch this order without a register connected');
      return;
    }
    this.customerStore.dispatch(
      new FetchJdeOrder({
        orderNumber: data.orderNumber,
        orderType: data.orderType,
      })
    );
  }

  saveToJDE(data) {
    this.fetchLatestOrders.getUsersForRecentOrders(data.userID);
  }

  dispatchGetUserState() {
    this.customerStore.dispatch(new GetUserStateAction());
  }
}
