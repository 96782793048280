import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomersPartialState } from '../../+state/customers.reducer';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { SelectCustomerVehicle, MaintainCustomerVehicle, SelectCountry } from '../../+state/customers.actions';
import { customersQuery } from '../../+state/customers.selectors';
import { CustomerVehicle, LookupLists, State } from '@pos-app/data';
import { hasUnsavedChanges, MessageService } from '@pos-app/core-ui';

@Component({
  selector: 'app-customers-vehicles-details',
  templateUrl: './customers-vehicles-details.component.html',
  styleUrls: ['./customers-vehicles-details.component.scss'],
})
export class CustomersVehiclesDetailsComponent implements OnInit, OnDestroy {
  @Input() public currentCustomer: string;
  @Input() public currentCustomerVehicle: string;
  @Output() public vehicleEditted = new EventEmitter<any>();
  @Output() public cancelEdit = new EventEmitter<void>();

  public selectedCustomerVehicleID: string;
  public selectedCustomerNumber: string;
  public vehicleDetailsForm: FormGroup;
  public formValid = false;
  public formDirty = false;
  public formValue = null;

  public selectedCustomerVehicle$: Observable<CustomerVehicle>;
  public isUpdatingCustomerVehicle$: Observable<boolean>;
  public stateList$: Observable<State[]>;
  public lookupLists$: Observable<LookupLists>;

  private unSubscribe$ = new Subject<void>();

  constructor(
    private store: Store<CustomersPartialState>,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService
  ) {}

  public ngOnInit(): void {
    if (this.currentCustomer && this.currentCustomerVehicle) {
      this.selectedCustomerNumber = this.currentCustomer;
      this.selectedCustomerVehicleID = this.currentCustomerVehicle;
    } else {
      this.selectedCustomerNumber = this.route.parent.snapshot.params.id;
      this.selectedCustomerVehicleID = this.route.snapshot.params.id;
    }
    this.store.dispatch(new SelectCustomerVehicle(this.selectedCustomerVehicleID));
    this.selectedCustomerVehicle$ = this.store.select(customersQuery.getSelectedCustomerVehicle);
    this.stateList$ = this.store.select(customersQuery.getFilteredStates);
    this.lookupLists$ = this.store.select(customersQuery.getLookupLists);
  }

  public ngOnDestroy(): void {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  public formDirtyHandler(dirty): void {
    this.formDirty = dirty;
    if (dirty) {
      this.store.dispatch(hasUnsavedChanges({ unsavedChanges: true }));
      this.messageService.dispatchAction(hasUnsavedChanges({ unsavedChanges: true }));
    }
  }

  public updateCustomerVehicle(): void {
    this.formValue['customerNumber'] = this.selectedCustomerNumber;
    this.store.dispatch(new MaintainCustomerVehicle(this.formValue));
    this.isUpdatingCustomerVehicle$ = this.store.select(customersQuery.getUpdatingCustomerVehicle);

    this.isUpdatingCustomerVehicle$.pipe(takeUntil(this.unSubscribe$)).subscribe((isUpdating) => {
      if (!isUpdating) {
        this.currentCustomer
          ? this.vehicleEditted.emit(this.formValue)
          : this.router.navigate(['pos/customers/' + this.selectedCustomerNumber + '/vehicles']);
      }
    });
  }

  public getFilteredStateList(countryCode): void {
    this.store.dispatch(new SelectCountry(countryCode));
  }

  public cancel(): void {
    this.currentCustomer ? this.cancelEdit.emit() : this.router.navigate(['pos/customers/' + this.selectedCustomerNumber + '/vehicles']);
  }
}
