<div class="pt-4 pb-2 px-0 container-fluid d-flex justify-content-between w-100">
  <div *ngIf="!isExternalUser">
    <label>CUSTOMER SEARCH: </label>
    <app-customers-search></app-customers-search>
  </div>
  <ng-container *ngIf="selectedCustomer">
    <button
      class="navlink btn"
      [routerLink]="['/pos/customers/' + selectedCustomer.CustomerNumber + '/details']"
      [ngClass]="{
        activeLink: isActive('/customers/' + selectedCustomer.CustomerNumber + '/details')
      }"
    >
      CUSTOMER DETAILS
    </button>
    <button
      class="navlink btn"
      [routerLink]="['/pos/customers/' + selectedCustomer.CustomerNumber + '/vehicles']"
      [ngClass]="{ activeLink: isActive('/vehicles') }"
      *ngIf="!isExternalUser"
    >
      CUSTOMER VEHICLES
    </button>
    <ng-template [ngIf]="isExternalUser">
      <button
        class="navlink btn"
        [routerLink]="['/pos/customers/' + selectedCustomer.CustomerNumber + '/invoice']"
        [ngClass]="{ activeLink: isActive('/invoice') }"
      >
        INVOICES
      </button>
      <button
        class="navlink btn"
        [routerLink]="['/pos/customers/' + selectedCustomer.CustomerNumber + '/statement']"
        [ngClass]="{ activeLink: isActive('/statement') }"
      >
        STATEMENTS
      </button>
      <button
        class="navlink btn"
        [routerLink]="['/pos/customers/' + selectedCustomer.CustomerNumber + '/showsales']"
        [ngClass]="{ activeLink: isActive('/showsales') }"
        *ngIf="hasShowSales$ | async"
      >
        SHOW SALES
      </button>
    </ng-template>
    <ng-template [ngIf]="!isExternalUser">
      <button
        class="navlink btn"
        [routerLink]="['/pos/customers/' + selectedCustomer.CustomerNumber + '/shipTo']"
        [ngClass]="{ activeLink: isActive('/shipTo') }"
        *ngIf="selectedCustomer.CustomerType === customerType.billTo"
      >
        ADD SHIP TO
      </button>
      <button class="navlink btn" [routerLink]="['/pos/orders/customer/' + selectedCustomer.CustomerNumber]">REVIEW ORDERS</button>
    </ng-template>
    <button *ngIf="canStartNewOrder()" class="navlink btn" (click)="toStartNewOrder(selectedCustomer.CustomerNumber)">NEW ORDER</button>
  </ng-container>
  <button
    class="navlink btn"
    [routerLink]="['/pos/customers/new']"
    [ngClass]="{ activeLink: isActive('/customers/new') }"
    *ngIf="!selectedCustomer && !isExternalUser"
  >
    CREATE RETAIL CUSTOMER
  </button>
</div>
