//Unallocated order
export const schedulerAppValue = {
  unAllocatedOrder_Master_SectionId: 1000000000000001,
  unAllocatedSalesOrder_SectionId: 1000000000000002, //0=14
  unAllocatedFloater_SectionId: 1000000000000003, //0=14
  invalid_SectionIds: [100000000000001, 100000000000002, 1000000000000001],
  technician_SectionId: 100000002,
  unAllocatedOrder_SectionId: 1000000000000001,
};
const durations = {
  day: 24 * 60 * 60 * 1000,
  hour: 60 * 60 * 1000,
  minute: 60 * 1000,
};
export function get_formatted_duration(start, end) {
  let diff = end - start;
  const days = Math.floor(diff / durations.day);
  diff -= days * durations.day;
  const hours = Math.floor(diff / durations.hour);
  diff -= hours * durations.hour;
  const minutes = Math.floor(diff / durations.minute);
  const results = [];
  if (days) results.push(days + ' days');
  if (hours) results.push(hours + ' hours');
  if (minutes) results.push(minutes + ' minutes');
  return results.join(', ');
}

export function getSplitLine(text: String) {
  const searchTerm = '|';
  let indexOfFirst = text.indexOf(searchTerm);
  let count = 0;
  for (var i = 0; i < text.length; i++) {
    if (indexOfFirst > 0 && i > indexOfFirst && count < 3) {
      if (text.indexOf(searchTerm, indexOfFirst + i) > 0) {
        count++;
        indexOfFirst = text.indexOf(searchTerm, indexOfFirst + i);
      }
    }
  }
  if (indexOfFirst > 0) {
    const line1 = text.substring(0, indexOfFirst);
    const line2 = text.substring(indexOfFirst, text.length - 1);
    return [line1, line2];
  } else {
    return [text];
  }
}

export function getEventColor(taskCode: string) {
  //TODO: add Code match with backend api
  switch (taskCode) {
    case 'AP':
      return 'appoitment';
    case 'BR':
      return 'break';
    case 'TR':
      return 'training';
    case 'AN':
      return 'annual';
    case 'CL':
      return 'cleaning';
    case 'SI':
      return 'sick';
    case 'CAR':
      return 'carers';
    case 'MA':
      return 'maternal';
    case 'AP':
      return 'appointment';
    case 'UN':
      return 'unscheduled';
    default:
      return '';
  }
}

export function getEventColorFromListWorkshopTask(taskCode: string, listWorkshopTask: any) {
  const color = listWorkshopTask.filter((el) => el?.key === taskCode || el?.code === taskCode || el?.keyCode === taskCode)[0].backgroundColourCode;
  return '#' + color;
}

export function getTimeSlots(start_time, time_gap) {
  const time_list = [start_time];
  const time_slots = [];
  for (let i = 0; i < 12; i++) {
    const next_time_slot = new Date(time_list[i]);
    next_time_slot.setHours(next_time_slot.getHours() + time_gap, (time_gap - Math.floor(time_gap)) * 10, 0);
    time_list.push(next_time_slot);
    time_slots.push({
      start_time: convertM(time_list[i]),
      end_time: convertM(next_time_slot),
    });
  }
  return time_slots;
}

export function getformatedDate(date: any) {
  let dateArr = date?.toString().split('/');
  return `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`;
}

export function getformatedTime(time: string) {
  if (!time) {
    return;
  }
  if (time.length === 1) {
    return '';
  }
  var timeValue = time;
  if (time.length === 5) {
    timeValue = '0' + timeValue;
  }
  let hr = timeValue?.slice(0, 2);
  let min = timeValue?.slice(2, 4);
  let sec = timeValue?.slice(4, 6);
  return `${hr}:${min}:${sec}`;
}

export function getInMinutes(time: number): any {
  console.log(time)
  if (time.toString().length === 1) {
    return '';
  }
  var timeValue = time.toString();
  if (time.toString().length === 5) {
    timeValue = '0' + timeValue;
  }
  let hr = Number(timeValue?.slice(0, 2));
  let min = Number(timeValue?.slice(2, 4));
  return hr * 60 + min;
}

export function getformatedTimeForZones(time: string) {
  var timeValue = time;
  if (time.length === 5) {
    timeValue = '0' + timeValue;
  }
  let hr = Number(timeValue?.slice(0, 2));
  let min = Number(timeValue?.slice(2, 4));
  return Number(hr) * 60 + Number(min);
}

export function dateRange(startDate, endDate, steps = 1) {
  const dateArray = [];
  let currentDate = new Date(startDate);

  while (currentDate <= new Date(endDate)) {
    dateArray.push(convertMM(currentDate));
    // Use UTC date to prevent problems with time zones and DST
    currentDate.setUTCDate(currentDate.getUTCDate() + steps);
  }

  return dateArray;
}

export function convert(str) {
  const date = new Date(str),
    mnth = ('0' + (date.getMonth() + 1)).slice(-2),
    day = ('0' + date.getDate()).slice(-2);
  return [day, mnth, date.getFullYear()].join('/');
}
export function convertM(str) {
  const date = new Date(str),
    mnth = ('0' + (date.getMonth() + 1)).slice(-2),
    day = ('0' + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join('-') + ' ' + [date.getHours(), date.getMinutes()].join(':');
}
export function convertMM(str) {
  const date = new Date(str),
    mnth = ('0' + (date.getMonth() + 1)).slice(-2),
    day = ('0' + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join('-');
}


export function getWorkDay(startDate: string, currentDate: string, endDate: string) {


  if (new Date(startDate).getTime() - new Date(currentDate).getTime() === 0 && new Date(currentDate).getTime() - new Date(endDate).getTime() === 0) {
    return '[' + 1 + '/' + 1 + ']';
  }
  const totalDays = ((new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 3600 * 24) + 1) | 0;
  const dateArray = dateRange(startDate, endDate);
  var day;
  for (let i = 0; i < dateArray.length; i++) {
    if (dateArray[i] === currentDate) {
      day = i;
    }
  }


  if (new Date(currentDate).getTime() - new Date(startDate).getTime() !== 0) {
    const day = ((new Date(currentDate).getTime() - new Date(startDate).getTime()) / (1000 * 3600 * 24) + 1) | 0;
    return '[' + day + '/' + totalDays + ']';
  } else if (new Date(endDate).getTime() - new Date(currentDate).getTime() !== 0) {
    const day = ((new Date(currentDate).getTime() - new Date(startDate).getTime()) / (1000 * 3600 * 24) + 1) | 0;
    return '[' + day + '/' + totalDays + ']';
  }
}

export function getListWorkshopTasksFormattted(SearchResults: any) {
  var result = SearchResults.map(function (el) {
    var o = Object.assign({}, el.code);
    return o;
  });
  let getListWorkshopTasks = [];
  for (let i = 0; i < result.length; i++) {
    let value = '';
    const alphaVal = (s) => s.toLowerCase().charCodeAt(0) - 97 + 1;
    for (let j = 0; j < Object.keys(result[i]).length; j++) {
      value += alphaVal(result[i][j]).toString();
    }
    getListWorkshopTasks[i] = {
      key: value,
      label: SearchResults[i].shortDescription,
      backgroundColourCode: SearchResults[i].backgroundColourCode,
      fontColourCode: SearchResults[i].fontColourCode,
      longDescription: SearchResults[i].longDescription,
      keyCode: SearchResults[i].code,
    };
  }
  return getListWorkshopTasks;
}

export function timeToMinutes(time: string) {
  let a = time.split(':'); // split it at the colons
  // Hours are worth 60 minutes.
  return +a[0] * 60 + +a[1];
}
