import { createReducer, on } from '@ngrx/store';
import {
  UpdateFilter,
  LoadFilterData,
  LoadFilterDataSuccess,
  ResetFilterLoaded,
  ResetFilter,
  ClearAllFilter,
  UpdateFilterData,
  GetCedCategoriesSuccess,
} from './filter.actions';
import { IFilterState, GlobalFilter } from './filter.state';
import { GetFilterDataOutput } from '../../models/service-proxies';
import { cloneDeep } from 'lodash';

export const FILTER_FEATURE_KEY = 'filter';

const initialState: IFilterState = {
  filterData: new GetFilterDataOutput(),
  filter: new GlobalFilter(),
  isLoaded: false,
  cedCategories: [],
};

const _filterReducer = createReducer(
  initialState,
  on(ResetFilter, (state, action) => {
    return {
      ...state,
      filter: {
        ...state.filter,
        cedCategoryId: action.payload.categoryId,
        brandIds: state.filter.cedCategoryId != action.payload.categoryId ? null : state.filter.brandIds,
        subBrands: state.filter.cedCategoryId != action.payload.categoryId ? null : state.filter.subBrands,
        categories: state.filter.cedCategoryId != action.payload.categoryId ? null : state.filter.categories,
        attributes: state.filter.cedCategoryId != action.payload.categoryId ? null : state.filter.attributes,
        attributesWithRelationship: state.filter.cedCategoryId != action.payload.categoryId ? null : state.filter.attributesWithRelationship,
        filterGroupUIStatus: state.filter.cedCategoryId != action.payload.categoryId ? null : state.filter.filterGroupUIStatus,
        page: action.payload.page,
        updated: true,
      },
    };
  }),
  on(UpdateFilter, (state, action) => {
    if (action.payload.key === 'ResetAll') {
      return {
        ...state,
        filter: {
          ...state.filter,
          brandIds: [],
          subBrands: [],
          categories: [],
          attributes: [],
          attributesWithRelationship: [],
          page: action.payload.page,
          updated: true,
          cedCategoryId: action.payload.value,
        },
      };
    } else {
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.payload.key]: action.payload.value,
          page: action.payload.page,
          updated: action.payload.key === 'filterGroupUIStatus' ? false : true,
        },
      };
    }
  }),
  on(ClearAllFilter, (state, action) => {
    return {
      ...state,
      filterData: new GetFilterDataOutput(),
      filter: new GlobalFilter(),
    };
  }),
  on(LoadFilterData, (state, action) => ({
    ...state,
    isLoaded: false,
    filter: {
      ...state.filter,
      cedCategoryId: action.payload.input.cedCategoryId,
      updated: false,
    },
  })),
  on(LoadFilterDataSuccess, (state, action) => {
    let fData = action.payload;
    let updatedFilter = state.filter;

    return {
      ...state,
      filter: updatedFilter,
      filterData: fData,
      isLoaded: true,
    };
  }),
  on(GetCedCategoriesSuccess, (state, action) => {
    return {
      ...state,
      cedCategories: action.payload || [],
      isLoaded: true,
    };
  }),
  on(UpdateFilterData, (state, action) => {
    if (action.payload.key == 'Attributes') {
      // 1. get new filter data option
      // 2. keep existing selected filter attributes if they still present in new filter data.
      let fData = { ...state.filterData, groupAttributesFilterList: action.payload.value };
      let f = { ...state.filter };

      if (fData.groupAttributesFilterList && fData.groupAttributesFilterList.length > 0) {
        if (f.attributes && f.attributes.length > 0) {
          let retainAttributes = [];
          for (let i = 0; i < f.attributes.length; i++) {
            if (fData.groupAttributesFilterList.find((att) => att.attribute == f.attributes[i].attribute && att.value == f.attributes[i].value)) {
              retainAttributes.push(f.attributes[i]);
            }
          }

          f.attributes = retainAttributes;
        }
      } else {
        f.attributes = [];
      }

      f.updated = true;

      return {
        ...state,
        filter: f,
        filterData: fData,
      };
    } else if (action.payload.key == 'FilterProductCount') {
      const currentFilterData = cloneDeep(state.filterData);

      return {
        ...state,
        filterData: Object.assign({}, state.filterData, {
          categoryFilterList: (currentFilterData.categoryFilterList || []).map((cat) => {
            let payloadCat = (action.payload.value.categoryFilterList || []).find((pc) => pc.cedCategoryId == cat.cedCategoryId);
            if (payloadCat) {
              cat.products = payloadCat.products;
            } else {
              cat.products = '0';
            }

            return cat;
          }),
          brandFilterList: (currentFilterData.brandFilterList || []).map((brand) => {
            let payloadBr = (action.payload.value.brandFilterList || []).find((pb) => pb.value == brand.value);
            if (payloadBr) {
              brand.products = payloadBr.products;
            } else {
              brand.products = '0';
            }

            return brand;
          }),
          subBrandFilterList: (currentFilterData.subBrandFilterList || []).map((sbrand) => {
            let payloadSBr = (action.payload.value.subBrandFilterList || []).find((psb) => psb.value == sbrand.value);
            if (payloadSBr) {
              sbrand.products = payloadSBr.products;
            } else {
              sbrand.products = '0';
            }

            return sbrand;
          }),
          groupAttributesFilterList: (currentFilterData.groupAttributesFilterList || []).map((att) => {
            let payloadAtt = (action.payload.value.groupAttributesFilterList || []).find(
              (patt) => patt.attribute == att.attribute && patt.value == att.value
            );
            if (payloadAtt) {
              att.products = payloadAtt.products;
            } else {
              att.products = '0';
            }

            return att;
          }),
        }),
        isLoaded: true,
      };
    }
  }),
  on(ResetFilterLoaded, (state) => ({
    ...state,
    filter: new GlobalFilter(),
  }))
);

export function filterReducer(state, action) {
  return _filterReducer(state, action);
}
