import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import { ApiService } from '@pos-app/core-ui';
import { CustomerDetails, CustomerSales } from '@pos-app/data';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-customer-details-view',
  templateUrl: './customer-details-view.component.html',
  styleUrls: ['./customer-details-view.component.scss'],
})
export class CustomerDetailsViewComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() customer: CustomerDetails;
  @Input() sales: CustomerSales;
  @Input() isExternal: boolean;
  @Input() selectedCustomer: CustomerDetails;
  
  public vehicleText: string;
  public assetNumber: string;

  private unSubscribe$ = new Subject<void>();

  constructor(private apiService: ApiService) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.customer?.currentValue) {
      this.vehicleText = this.customer.VehicleRego
        ? `${this.customer.VehicleCustomDesc} (${this.customer.VehicleRego})`
        : this.customer.VehicleCustomDesc
        ? this.customer.VehicleCustomDesc
        : '';

      if (
        !!changes.customer.currentValue.CamperTrailerVIN?.trim()
      ) {
        this.customerTrailerSearch(this.customer.CustomerNumber.toString());
      }
    }
  }

  customerTrailerSearch(customerNumber: string) {
    this.apiService
      .customerTrailerSearch({
        searchString: customerNumber,
      })
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((res) => {
        this.assetNumber = res.SearchResults[0].AssetNumber.toString();
      });
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }
}
