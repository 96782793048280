import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, delay } from 'rxjs/operators';
import {  VehicleSearchParamters } from '../+store/vehicles/vehicle';
import { OOEApiServiceProxy } from './ooe-api-service-proxy';
import { SelectedVehicle } from '../models/userState.model';

@Injectable({ providedIn: 'root' })
export class VehicleApiService {
   constructor(
      private _ooeApiServiceProxy: OOEApiServiceProxy) {
  }

   searchVehicles(parameters: VehicleSearchParamters): Observable<object> {
      return this._ooeApiServiceProxy.searchVehicle(parameters)
      .pipe(
         map(result => result),
         delay(1000));
   }

   updateSelectedVehicle(parameter: SelectedVehicle): Observable<object> {
      return this._ooeApiServiceProxy.updateSelectedVehicle(parameter)
      .pipe(map(result => result));
   }

}