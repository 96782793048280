<div *ngIf="duplicateCustomers && duplicateCustomers.length > 0">
  <div class="card">
    <div class="card-body">
      <div>
        <h4>New Customer Details</h4>
        <div>
          <span
            ><b>Name:</b>
            {{
              newCustomerDetails.name
                ? newCustomerDetails.fullName
                : newCustomerDetails.firstName + ' ' + newCustomerDetails.lastName
            }}</span
          >
          <span class="px-4">
            <b>Ph:</b>
            {{ newCustomerDetails.phoneNumber }}
          </span>
          <span> <b>Email:</b> {{ newCustomerDetails.email }} </span>
        </div>
        <div><b>Address:</b> {{ newCustomerDetails.address1 }}</div>
      </div>
      <div class="pt-2">
        <h4>Existing Similar Customers</h4>
        <table class="searchResult border my-3 table table-striped shadow-lg">
          <thead>
            <tr>
              <th scope="col">Customer Name</th>
              <th scope="col">Phone</th>
              <th scope="col">Email</th>
              <th scope="col">Customer Type</th>
              <th scope="col">Address</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let customer of duplicateCustomers; let i = index"
              [ngClass]="duplicateCustomers[i] === selectedCustomer ? 'table-primary' : ''"
              (click)="selectCustomer(customer)"
            >
              <td>
                {{ customer.CustomerName }}
              </td>
              <td>
                {{ customer.Phone }}
              </td>
              <td>
                {{ customer.Email }}
              </td>
              <td>
                {{ customer.CustomerType }}
              </td>
              <td>
                {{ customer.Address1 }}
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <button class="btn btn-success" (click)="chooseExistingCustomer()">
          Select Existing Customer Instead
        </button>
        <span class="px-2">
          <button class="btn btn-warning" (click)="createNewCustomer()">
            Continue Creating New Customer
          </button>
        </span>
        <button class="btn btn-danger" (click)="cancel()">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
