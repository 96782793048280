import { Action } from '@ngrx/store';
import { DefaultOrder } from '@pos-app/data';
import { UpdatedOrderLine, ViewSettings } from './orders.models';
import { Supplier } from '../models/supplier.model';

export enum OrdersActionTypes {
  LOAD_FILTER_LISTS = '[Orders] Load filter values',
  LOAD_FILTER_LISTS_SUCCESS = '[Orders] Load filter values success',
  LOAD_FILTER_LISTS_FAILED = '[Orders] Load filter values failed',

  SEARCH_ORDER_SUMMARY = '[Orders] Search order summary',
  SEARCH_ORDER_SUMMARY_SUCCESS = '[Orders] Search order summary success',
  SEARCH_ORDER_SUMMARY_FAILED = '[Orders] Search order summary failed',

  CREATE_ORDER = '[Orders] Create order',
  CREATE_ORDER_SUCCESS = '[Orders] Create order success',
  CREATE_ORDER_FAILED = '[Orders] Create order failed',

  LOAD_ORDER_HEADER = '[Orders] Load order header',
  LOAD_ORDER_HEADER_SUCCESS = '[Orders] Load order header success',
  LOAD_ORDER_HEADER_FAILED = '[Orders] Load order header failed',

  CLEAN_STAGING_ORDER_DETAILS = '[Orders] Clean staging order details',
  LOAD_STAGING_ORDER_DETAILS = '[Orders] Load staging order details',
  LOAD_STAGING_ORDER_DETAILS_SUCCESS = '[Orders] Load staging order details success',
  LOAD_STAGING_ORDER_DETAILS_FAILED = '[Orders] Load staging order details failed',

  FETCH_UPDATED_ORDER_LINE = '[Orders] Fetch Updated Order Line ',
  FETCH_UPDATED_ORDER_LINE_SUCCESS = '[Orders] Fetch Updated Order Line success',
  FETCH_UPDATED_ORDER_LINE_FAILED = '[Orders] Fetch Updated Order Line failed',

  UPDATE_STAGING_ORDER_LINE = '[Orders] Update staging order line',
  UPDATE_STAGING_ORDER_LINE_SUCCESS = '[Orders] Update staging order line success',
  UPDATE_STAGING_ORDER_LINE_FAILED = '[Orders] Update staging order line failed',

  UPDATE_STAGING_ORDER_ALL_LINE = '[Orders] Update staging order all line',
  UPDATE_STAGING_ORDER_ALL_LINE_SUCCESS = '[Orders] Update staging order all line success',
  UPDATE_STAGING_ORDER_ALL_LINE_FAILED = '[Orders] Update staging order all line failed',

  VALIDATE_ORDER = '[Orders] Validate order',
  VALIDATE_ORDER_SUCCESS = '[Orders] Validate order success',
  VALIDATE_ORDER_FAILED = '[Orders] Validate order failed',

  REVALIDATE_ORDER = '[Orders] Revalidate order',
  REVALIDATE_ORDER_SUCCESS = '[Orders] Revalidate order success',
  REVALIDATE_ORDER_FAILED = '[Orders] Revalidate order failed',

  CONVERT_LINES = '[Orders] Convert lines',
  CONVERT_LINES_SUCCESS = '[Orders] Convert lines success',
  CONVERT_LINES_FAILED = '[Orders] Convert lines failed',

  CREATE_CREDIT = '[Orders] Create Credit',
  CREATE_CREDIT_SUCCESS = '[Orders] Create Credit success',
  CREATE_CREDIT_FAILED = '[Orders] Create Credit failed',

  COPY_ORDER = '[Orders] Copy Order',
  COPY_ORDER_SUCCESS = '[Orders] Copy Order success',
  COPY_ORDER_FAILED = '[Orders] Copy Order failed',

  FETCH_JDE_ORDER = '[Orders] Fetch JDE Order',
  FETCH_JDE_ORDER_SUCCESS = '[Orders] Fetch JDE Order success',
  FETCH_JDE_ORDER_FAILED = '[Orders] Fetch JDE Order failed',

  UPDATE_AND_VALIDATE = '[Orders] Update and validate',
  UPDATE_AND_VALIDATE_SUCCESS = '[Orders] Update and validate success',
  UPDATE_AND_VALIDATE_FAILED = '[Orders] Update and validate failed',

  VALIDATE_SPLIT_LINES_ORDER = '[Orders] Validate split line order',
  VALIDATE_SPLIT_LINES_ORDER_SUCCESS = '[Orders] Validate split line order success',
  VALIDATE_SPLIT_LINES_ORDER_FAILED = '[Orders] Validate split line order failed',

  FETCH_PAYMENT_INFO = '[Orders] Fetch payment info',
  FETCH_PAYMENT_INFO_SUCCESS = '[Orders] Fetch payment info success',
  FETCH_PAYMENT_INFO_FAILED = '[Orders] Fetch payment info failed',

  FETCH_LOADED_GIFTCARDS = '[Orders] Fetch loaded giftcards',
  FETCH_LOADED_GIFTCARDS_SUCCESS = '[Orders] Fetch loaded giftcards success',
  FETCH_LOADED_GIFTCARDS_FAILED = '[Orders] Fetch loaded giftcards failed',

  FINALISE_ORDER = '[Orders] Finalise order',
  FINALISE_ORDER_SUCCESS = '[Orders] Finalise order success',
  FINALISE_ORDER_FAILED = '[Orders] Finalise order failed',

  ADD_ITEMS_TO_ORDER = '[Orders] Add items to order',
  ADD_ITEMS_TO_ORDER_SUCCESS = '[Orders] Add items to order success',
  ADD_ITEMS_TO_ORDER_FAILED = '[Orders] Add items to order failed',

  ADD_ITEMS_PROCESSED = '[Order] Add item processed',

  CANCEL_ORDER = '[Orders] Cancel Order',
  CLEAR_DATA = '[Orders] Clear data',
  UPDATE_GRID_DATA = '[Orders] Update grid data',
  RESET_ORDER_VALIDATION = '[Orders] Reset order validation',

  LOAD_PARKED_ORDERS_LIST = '[Orders] Load parked orders list',
  LOAD_PARKED_ORDERS_LIST_SUCCESS = '[Orders] Load parked orders list success',
  LOAD_PARKED_ORDERS_LIST_FAILED = '[Orders] Load parked orders list failed',

  LOAD_PARKED_ORDER = '[Orders] Load Parked Order',
  LOAD_PARKED_ORDER_SUCCESS = '[Orders] Load Parked Order success',
  LOAD_PARKED_ORDER_FAILED = '[Orders] Load Parked Order failed',

  UPDATE_VIEW_SETTINGS = '[Orders] Update view settings',
}

export class CancelOrder implements Action {
  readonly type = OrdersActionTypes.CANCEL_ORDER;
}

export class ClearData implements Action {
  readonly type = OrdersActionTypes.CLEAR_DATA;
  constructor() {}
}

export class LoadFitlerLists implements Action {
  readonly type = OrdersActionTypes.LOAD_FILTER_LISTS;
  constructor() {}
}

export class LoadFitlerListsSuccess implements Action {
  readonly type = OrdersActionTypes.LOAD_FILTER_LISTS_SUCCESS;
  constructor(public payload: any) {}
}

export class LoadFitlerListsFailed implements Action {
  readonly type = OrdersActionTypes.LOAD_FILTER_LISTS_FAILED;
  constructor(public payload: any) {}
}

export class SearchOrderSummary implements Action {
  readonly type = OrdersActionTypes.SEARCH_ORDER_SUMMARY;
  constructor(public payload: any) {}
}

export class SearchOrderSummarySuccess implements Action {
  readonly type = OrdersActionTypes.SEARCH_ORDER_SUMMARY_SUCCESS;
  constructor(public payload: any) {}
}

export class SearchOrderSummaryFailed implements Action {
  readonly type = OrdersActionTypes.SEARCH_ORDER_SUMMARY_FAILED;
  constructor(public payload: any) {}
}

export class CreateOrder implements Action {
  readonly type = OrdersActionTypes.CREATE_ORDER;
  constructor(public payload: any) {}
}

export class CreateOrderSuccess implements Action {
  readonly type = OrdersActionTypes.CREATE_ORDER_SUCCESS;
  constructor() {}
}

export class CreateOrderFailed implements Action {
  readonly type = OrdersActionTypes.CREATE_ORDER_FAILED;
  constructor(public payload: any) {}
}

export class LoadOrderHeader implements Action {
  readonly type = OrdersActionTypes.LOAD_ORDER_HEADER;
  constructor(public payload: any) {}
}

export class LoadOrderHeaderSuccess implements Action {
  readonly type = OrdersActionTypes.LOAD_ORDER_HEADER_SUCCESS;
  constructor(public payload: any) {}
}

export class LoadOrderHeaderFailed implements Action {
  readonly type = OrdersActionTypes.LOAD_ORDER_HEADER_FAILED;
  constructor(public payload: any) {}
}

export class CleanStagingOrder implements Action {
  readonly type = OrdersActionTypes.CLEAN_STAGING_ORDER_DETAILS;
}

export class LoadStagingOrder implements Action {
  readonly type = OrdersActionTypes.LOAD_STAGING_ORDER_DETAILS;
  constructor(public payload: { orderNumber: string }) {}
}

export class LoadStagingOrderSuccess implements Action {
  readonly type = OrdersActionTypes.LOAD_STAGING_ORDER_DETAILS_SUCCESS;
  constructor(public payload: any) {}
}

export class LoadStagingOrderFailed implements Action {
  readonly type = OrdersActionTypes.LOAD_STAGING_ORDER_DETAILS_FAILED;
  constructor(public payload: any) {}
}

export class fetchUpdatedOrderLine implements Action {
  readonly type = OrdersActionTypes.FETCH_UPDATED_ORDER_LINE;
  constructor(public payload: any) {}
}

export class fetchUpdatedOrderLineSuccess implements Action {
  readonly type = OrdersActionTypes.FETCH_UPDATED_ORDER_LINE_SUCCESS;
  constructor(public payload: UpdatedOrderLine[]) {}
}

export class fetchUpdatedOrderLineFailed implements Action {
  readonly type = OrdersActionTypes.FETCH_UPDATED_ORDER_LINE_FAILED;
  constructor(public payload: any) {}
}

export class UpdateStagingOrderLine implements Action {
  readonly type = OrdersActionTypes.UPDATE_STAGING_ORDER_LINE;
  constructor(public payload: any) {}
}

export class UpdateStagingOrderLineSuccess implements Action {
  readonly type = OrdersActionTypes.UPDATE_STAGING_ORDER_LINE_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateStagingOrderLineFailed implements Action {
  readonly type = OrdersActionTypes.UPDATE_STAGING_ORDER_LINE_FAILED;
  constructor(public payload: any) {}
}

export class UpdateStagingOrderAllLine implements Action {
  readonly type = OrdersActionTypes.UPDATE_STAGING_ORDER_ALL_LINE;
  constructor(public payload: { changeLineStatus?: string; paintYN?: 'Y' | 'N' }) {}
}

export class UpdateStagingOrderAllLineSuccess implements Action {
  readonly type = OrdersActionTypes.UPDATE_STAGING_ORDER_ALL_LINE_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateStagingOrderAllLineFailed implements Action {
  readonly type = OrdersActionTypes.UPDATE_STAGING_ORDER_ALL_LINE_FAILED;
  constructor(public payload: any) {}
}

export class UpdateGridData implements Action {
  readonly type = OrdersActionTypes.UPDATE_GRID_DATA;
  constructor(public payload: any) {}
}

export class ValidateOrder implements Action {
  readonly type = OrdersActionTypes.VALIDATE_ORDER;
  constructor(public payload: any) {}
}

export class ValidateOrderSuccess implements Action {
  readonly type = OrdersActionTypes.VALIDATE_ORDER_SUCCESS;
  constructor(public payload: any) {}
}

export class ValidateOrderFailed implements Action {
  readonly type = OrdersActionTypes.VALIDATE_ORDER_FAILED;
  constructor(public payload: any) {}
}

export class RevalidateOrder implements Action {
  readonly type = OrdersActionTypes.REVALIDATE_ORDER;
  constructor(public payload: any) {}
}

export class RevalidateOrderSuccess implements Action {
  readonly type = OrdersActionTypes.REVALIDATE_ORDER_SUCCESS;
  constructor(public payload: any) {}
}

export class RevalidateOrderFailed implements Action {
  readonly type = OrdersActionTypes.REVALIDATE_ORDER_FAILED;
  constructor(public payload: any) {}
}

export class ConvertLines implements Action {
  readonly type = OrdersActionTypes.CONVERT_LINES;
  constructor(public payload: any) {}
}

export class ConvertLinesSuccess implements Action {
  readonly type = OrdersActionTypes.CONVERT_LINES_SUCCESS;
  constructor() {}
}

export class ConvertLinesFailed implements Action {
  readonly type = OrdersActionTypes.CONVERT_LINES_FAILED;
  constructor(public payload: any) {}
}

export class CreateCredit implements Action {
  readonly type = OrdersActionTypes.CREATE_CREDIT;
  constructor(public payload: { creditType: string }) {}
}

export class CreateCreditSuccess implements Action {
  readonly type = OrdersActionTypes.CREATE_CREDIT_SUCCESS;
  constructor() {}
}

export class CreateCreditFailed implements Action {
  readonly type = OrdersActionTypes.CREATE_CREDIT_FAILED;
  constructor(public payload: any) {}
}

export class CopyOrder implements Action {
  readonly type = OrdersActionTypes.COPY_ORDER;
  constructor() {}
}

export class CopyOrderSuccess implements Action {
  readonly type = OrdersActionTypes.COPY_ORDER_SUCCESS;
  constructor() {}
}

export class CopyOrderFailed implements Action {
  readonly type = OrdersActionTypes.COPY_ORDER_FAILED;
  constructor(public payload: any) {}
}

export class FetchJdeOrder implements Action {
  readonly type = OrdersActionTypes.FETCH_JDE_ORDER;
  constructor(public payload: any) {}
}

export class FetchJdeOrderSuccess implements Action {
  readonly type = OrdersActionTypes.FETCH_JDE_ORDER_SUCCESS;
  constructor() {}
}

export class FetchJdeOrderFailed implements Action {
  readonly type = OrdersActionTypes.FETCH_JDE_ORDER_FAILED;
  constructor(public payload: any) {}
}

export class UpdateAndValidate implements Action {
  readonly type = OrdersActionTypes.UPDATE_AND_VALIDATE;
  constructor(public payload: any) {}
}

export class UpdateAndValidateSuccess implements Action {
  readonly type = OrdersActionTypes.UPDATE_AND_VALIDATE_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateAndValidateFailed implements Action {
  readonly type = OrdersActionTypes.UPDATE_AND_VALIDATE_FAILED;
  constructor(public payload: any) {}
}

export class ValidateSplitLineOrder implements Action {
  readonly type = OrdersActionTypes.VALIDATE_SPLIT_LINES_ORDER;
  constructor(public payload: any) {}
}

export class ValidateSplitLineSuccess implements Action {
  readonly type = OrdersActionTypes.VALIDATE_SPLIT_LINES_ORDER_SUCCESS;
  constructor(public payload: any) {}
}

export class ValidateSplitLineFailed implements Action {
  readonly type = OrdersActionTypes.VALIDATE_SPLIT_LINES_ORDER_FAILED;
  constructor(public payload: any) {}
}

export class FetchPaymentInfo implements Action {
  readonly type = OrdersActionTypes.FETCH_PAYMENT_INFO;
  constructor(public payload: { orderNumber: string }) {}
}

export class FetchPaymentInfoSuccess implements Action {
  readonly type = OrdersActionTypes.FETCH_PAYMENT_INFO_SUCCESS;
  constructor(public payload: any) {}
}

export class FetchPaymentInfoFailed implements Action {
  readonly type = OrdersActionTypes.FETCH_PAYMENT_INFO_FAILED;
  constructor(public payload: any) {}
}

export class FetchLoadedGiftCards implements Action {
  readonly type = OrdersActionTypes.FETCH_LOADED_GIFTCARDS;
  constructor(public payload: any) {}
}

export class FetchLoadedGiftCardsSuccess implements Action {
  readonly type = OrdersActionTypes.FETCH_LOADED_GIFTCARDS_SUCCESS;
  constructor(public payload: any) {}
}

export class FetchLoadedGiftCardsFailed implements Action {
  readonly type = OrdersActionTypes.FETCH_LOADED_GIFTCARDS_FAILED;
  constructor(public payload: any) {}
}
export class FinaliseOrder implements Action {
  readonly type = OrdersActionTypes.FINALISE_ORDER;
  constructor(public payload: any) {}
}

export class FinaliseOrderSuccess implements Action {
  readonly type = OrdersActionTypes.FINALISE_ORDER_SUCCESS;
  constructor(public payload: any) {}
}

export class FinaliseOrderFailed implements Action {
  readonly type = OrdersActionTypes.FINALISE_ORDER_FAILED;
  constructor(public payload: any) {}
}

export class AddItemsToOrder implements Action {
  readonly type = OrdersActionTypes.ADD_ITEMS_TO_ORDER;
  constructor(public payload: any) {}
}

export class AddItemsToOrderSuccess implements Action {
  readonly type = OrdersActionTypes.ADD_ITEMS_TO_ORDER_SUCCESS;
  constructor(public payload?: Supplier[]) {}
}

export class AddItemsToOrderFailed implements Action {
  readonly type = OrdersActionTypes.ADD_ITEMS_TO_ORDER_FAILED;
  constructor(public payload: any) {}
}

export class AddItemProcessed implements Action {
  readonly type = OrdersActionTypes.ADD_ITEMS_PROCESSED;
  constructor(public payload: any) {}
}

export class ResetOrderValidation implements Action {
  readonly type = OrdersActionTypes.RESET_ORDER_VALIDATION;
  constructor() {}
}

export class LoadParkedOrdersList implements Action {
  readonly type = OrdersActionTypes.LOAD_PARKED_ORDERS_LIST;
  constructor() {}
}

export class LoadParkedOrdersListSuccess implements Action {
  readonly type = OrdersActionTypes.LOAD_PARKED_ORDERS_LIST_SUCCESS;
  constructor(public payload: any) {}
}

export class LoadParkedOrdersListFailed implements Action {
  readonly type = OrdersActionTypes.LOAD_PARKED_ORDERS_LIST_FAILED;
  constructor(public payload: any) {}
}

export class LoadParkedOrder implements Action {
  readonly type = OrdersActionTypes.LOAD_PARKED_ORDER;
  constructor(public payLoad: DefaultOrder) {}
}

export class LoadParkedOrderSuccess implements Action {
  readonly type = OrdersActionTypes.LOAD_PARKED_ORDER_SUCCESS;
  constructor() {}
}

export class LoadParkedOrderFailed implements Action {
  readonly type = OrdersActionTypes.LOAD_PARKED_ORDER_FAILED;
  constructor(public payload: any) {}
}

export class UpdateViewSettings implements Action {
  readonly type = OrdersActionTypes.UPDATE_VIEW_SETTINGS;
  constructor(public payload: ViewSettings) {}
}

export type OrdersAction =
  | ClearData
  | CancelOrder
  | LoadFitlerLists
  | LoadFitlerListsSuccess
  | LoadFitlerListsFailed
  | SearchOrderSummary
  | SearchOrderSummarySuccess
  | SearchOrderSummaryFailed
  | CreateOrder
  | CreateOrderSuccess
  | CreateOrderFailed
  | LoadOrderHeader
  | LoadOrderHeaderSuccess
  | LoadOrderHeaderFailed
  | CleanStagingOrder
  | LoadStagingOrder
  | LoadStagingOrderSuccess
  | LoadStagingOrderFailed
  | fetchUpdatedOrderLine
  | fetchUpdatedOrderLineSuccess
  | fetchUpdatedOrderLineFailed
  | UpdateGridData
  | UpdateStagingOrderLine
  | UpdateStagingOrderLineSuccess
  | UpdateStagingOrderLineFailed
  | ValidateOrder
  | ValidateOrderSuccess
  | ValidateOrderFailed
  | RevalidateOrder
  | RevalidateOrderSuccess
  | RevalidateOrderFailed
  | ResetOrderValidation
  | ConvertLines
  | ConvertLinesSuccess
  | ConvertLinesFailed
  | CreateCredit
  | CreateCreditSuccess
  | CreateCreditFailed
  | CopyOrder
  | CopyOrderSuccess
  | CopyOrderFailed
  | FetchJdeOrder
  | FetchJdeOrderSuccess
  | FetchJdeOrderFailed
  | UpdateAndValidate
  | UpdateAndValidateSuccess
  | UpdateAndValidateFailed
  | ValidateSplitLineOrder
  | ValidateSplitLineSuccess
  | ValidateSplitLineFailed
  | FetchPaymentInfo
  | FetchPaymentInfoSuccess
  | FetchPaymentInfoFailed
  | FetchLoadedGiftCards
  | FetchLoadedGiftCardsSuccess
  | FetchLoadedGiftCardsFailed
  | FinaliseOrder
  | FinaliseOrderSuccess
  | FinaliseOrderFailed
  | AddItemsToOrder
  | AddItemsToOrderSuccess
  | AddItemsToOrderFailed
  | AddItemProcessed
  | LoadParkedOrdersList
  | LoadParkedOrdersListSuccess
  | LoadParkedOrdersListFailed
  | LoadParkedOrder
  | LoadParkedOrderSuccess
  | LoadParkedOrderFailed
  | UpdateViewSettings;

export const fromOrdersActions = {
  ClearData,
  CancelOrder,

  LoadFitlerLists,
  LoadFitlerListsSuccess,
  LoadFitlerListsFailed,

  SearchOrderSummary,
  SearchOrderSummarySuccess,
  SearchOrderSummaryFailed,

  CreateOrder,
  CreateOrderSuccess,
  CreateOrderFailed,

  LoadOrderHeader,
  LoadOrderHeaderSuccess,
  LoadOrderHeaderFailed,

  CleanStagingOrder,
  LoadStagingOrder,
  LoadStagingOrderSuccess,
  LoadStagingOrderFailed,

  UpdateGridData,

  UpdateStagingOrderLine,
  UpdateStagingOrderLineSuccess,
  UpdateStagingOrderLineFailed,

  ValidateOrder,
  ValidateOrderSuccess,
  ValidateOrderFailed,

  RevalidateOrder,
  RevalidateOrderSuccess,
  RevalidateOrderFailed,

  ConvertLines,
  ConvertLinesSuccess,
  ConvertLinesFailed,

  CreateCredit,
  CreateCreditSuccess,
  CreateCreditFailed,

  CopyOrder,
  CopyOrderSuccess,
  CopyOrderFailed,

  FetchJdeOrder,
  FetchJdeOrderSuccess,
  FetchJdeOrderFailed,

  UpdateAndValidate,
  UpdateAndValidateSuccess,
  UpdateAndValidateFailed,

  ValidateSplitLineOrder,
  ValidateSplitLineSuccess,
  ValidateSplitLineFailed,

  FetchPaymentInfo,
  FetchPaymentInfoSuccess,
  FetchPaymentInfoFailed,

  FetchLoadedGiftCards,
  FetchLoadedGiftCardsSuccess,
  FetchLoadedGiftCardsFailed,

  FinaliseOrder,
  FinaliseOrderSuccess,
  FinaliseOrderFailed,

  AddItemsToOrder,
  AddItemsToOrderSuccess,
  AddItemsToOrderFailed,

  AddItemProcessed,

  ResetOrderValidation,

  LoadParkedOrdersList,
  LoadParkedOrdersListSuccess,
  LoadParkedOrdersListFailed,

  LoadParkedOrder,
  LoadParkedOrderSuccess,
  LoadParkedOrderFailed,

  UpdateViewSettings,
};
