import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomersRoutingModule } from './customers-routing.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromCustomers from './+state/customers.reducer';
import { CustomersEffects } from './+state/customers.effects';
import { CustomersBaseComponent } from './containers/customers-base/customers-base.component';
import { CustomersNavComponent } from './components/customers-nav/customers-nav.component';
import { CustomersDetailsComponent } from './containers/customers-details/customers-details.component';
import { CustomersDetailsFormComponent } from './components/customers-details-form/customers-details-form.component';
import { CustomersDetailsNewComponent } from './containers/customers-details-new/customers-details-new.component';
// tslint:disable-next-line: max-line-length
import { CustomersVehiclesMaintenanceComponent } from './containers/customers-vehicles-maintenance/customers-vehicles-maintenance.component';
import { CustomersVehiclesDetailsComponent } from './containers/customers-vehicles-details/customers-vehicles-details.component';
import { CustomersVehiclesDetailsNewComponent } from './containers/customers-vehicles-details-new/customers-vehicles-details-new.component';
import { CustomersVehiclesSelectionComponent } from './components/customers-vehicles-selection/customers-vehicles-selection.component';
import { CustomerOrdersComponent } from './containers/customer-orders/customer-orders.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreUiModule } from '@pos-app/core-ui';
import { CustomerDetailsViewComponent } from './components/customer-details-view/customer-details-view.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
// tslint:disable-next-line: max-line-length
import { CustomersVehiclesDetailsFormComponent } from './components/customers-vehicles-details-form/customers-vehicles-details-form.component';
import { CustomersShipToComponent } from './containers/customers-ship-to/customers-ship-to.component';
import { CustomersShipToFormComponent } from './components/customers-ship-to-form/customers-ship-to-form.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { VehiclesModule } from '../vehicles/vehicles.module';
import { CustomersSearchComponent } from './containers/customers-search/customers-search.component';
import { OrdersSearchComponent } from './components/customers-orders-search/orders-search.component';
import { CustomersDuplicateComponent } from './components/customers-duplicate/customers-duplicate.component';
import { CustomersVehiclesComponent } from './containers/customers-vehicles/customers-vehicles.component';
import { CustomersInvoiceComponent } from './containers/customers-invoice/customers-invoice.component';
import { CustomersStatementComponent } from './containers/customers-statement/customers-statement.component';
import { AgGridModule } from 'ag-grid-angular';
import { CustomersGridActionRendererComponent } from './components/customersGridActionRenderer/customersGridActionRender.component';
import { CustomersRecentOrdersComponent } from './components/customers-recent-orders/customers-recent-orders.component';
import { CustomersShowSalesComponent } from './containers/customers-show-sales/customers-show-sales.component';
import { StockavailabilityComponent } from './containers/customers-stockavailability/stockavailability.component';
import { CustomersNotesComponent } from './containers/customers-notes/customers-notes.component';
import { CustomersNotesFormComponent } from './components/customers-notes-form/customers-notes-form.component';
import { CustomersNotesCompleteTaskComponent } from './components/customers-notes-complete-task/customers-notes-complete-task.component';
import { CustomersNotesFileAttachmentComponent } from './components/customers-notes-file-attachment/customers-notes-file-attachment.component';
import { CustomersTaskCalendarComponent } from './containers/customers-task-calendar/customers-task-calendar.component';
import { CustomersNotesTaskTimelineComponent } from './components/customers-notes-task-timeline/customers-notes-task-timeline.component';
import { TimelineGridActionRendererComponent } from './components/customers-notes-task-timeline/timelineGridActionRender/timelineGridActionRender';
import { CustomersMyOrdersComponent } from './containers/customers-my-orders/customers-my-orders.component';
import { CustomersParkedOrdersComponent } from './components/customers-parked-orders/customers-parked-orders.component';
import { CustomersTrailerSearchComponent } from './components/customers-trailer-search/customers-trailer-search.component';
@NgModule({
    declarations: [
        CustomersSearchComponent,
        OrdersSearchComponent,
        CustomersBaseComponent,
        CustomersNavComponent,
        CustomersDetailsComponent,
        CustomersDetailsFormComponent,
        CustomersDetailsNewComponent,
        CustomersVehiclesDetailsFormComponent,
        CustomersVehiclesMaintenanceComponent,
        CustomersVehiclesDetailsComponent,
        CustomersVehiclesDetailsNewComponent,
        CustomersVehiclesSelectionComponent,
        CustomerOrdersComponent,
        CustomerDetailsViewComponent,
        CustomersShipToComponent,
        CustomersShipToFormComponent,
        CustomersDuplicateComponent,
        CustomersVehiclesComponent,
        CustomersInvoiceComponent,
        CustomersStatementComponent,
        CustomersGridActionRendererComponent,
        CustomersRecentOrdersComponent,
        CustomersShowSalesComponent,
        StockavailabilityComponent,
        CustomersNotesComponent,
        TimelineGridActionRendererComponent,
        CustomersNotesFormComponent,
        CustomersNotesCompleteTaskComponent,
        CustomersNotesFileAttachmentComponent,
        CustomersTaskCalendarComponent,
        CustomersNotesTaskTimelineComponent,
        CustomersMyOrdersComponent,
        CustomersParkedOrdersComponent,
        CustomersTrailerSearchComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        MatIconModule,
        MatTooltipModule,
        CoreUiModule,
        NgSelectModule,
        CustomersRoutingModule,
        AgGridModule.withComponents([]),
        VehiclesModule,
        StoreModule.forFeature(fromCustomers.CUSTOMERS_FEATURE_KEY, fromCustomers.reducer),
        EffectsModule.forFeature([CustomersEffects])
    ],
    exports: [
        CustomersVehiclesComponent,
        CustomersVehiclesMaintenanceComponent,
        CustomersVehiclesDetailsNewComponent,
        CustomersVehiclesDetailsComponent,
        CustomersDetailsNewComponent,
        CustomersDetailsComponent
    ]
})
export class CustomersModule {}
