<div *ngIf="!!ordersAssignedToFitter" class="col-auto py-2 align-items-center">
  <div
    cdk-scrollable
    cdkDropList
    [id]="fitter.resourceID"
    class="col orders-for-booking-list rounded-1"
    [cdkDropListData]="ordersAssignedToFitter"
    [cdkDropListConnectedTo]="dragDropOrdersIdsList"
    (cdkDropListDropped)="orderDrop($event)"
  >
    <div class="empty-card" *ngIf="ordersAssignedToFitter.length > 0">
      <div
        *ngFor="let orderForBookingCard of ordersAssignedToFitter"
        [cdkDragData]="orderForBookingCard"
        cdkDrag
      >
        <app-order-for-booking-card
          [orderForBookingCard]="orderForBookingCard"
          (renameGroupName)="updateGroupName($event)"
          [isArrayOfLinesDisabled]="true"
        ></app-order-for-booking-card>
      </div>
    </div>
    <div *ngIf="ordersAssignedToFitter.length === 0">
      <h6 class="empty-card py-2 align-text-bottom">DRAG HERE TO ASSIGN</h6>
    </div>
  </div>
</div>
