import { Pipe, PipeTransform } from '@angular/core';
import { Branch } from '@pos-app/data';
@Pipe({
  name: 'branch',
  pure: false,
})
export class BranchPipe implements PipeTransform {
  transform(branchCode: string, branchList: Branch[]): any {
    const branch = branchList?.find((item) => item.BranchCode?.trim() === branchCode?.trim());
    if (branch) {
      return `${branch.BranchDescription} (${branch.BranchCode})`;
    }
    return '';
  }
}
