import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { distinctUntilChanged, map, takeWhile } from 'rxjs/operators';
import { DynamicFilterConfig } from '../../+state/orders.models';

@Component({
  selector: 'app-dynamic-filters',
  templateUrl: './dynamic-filters.component.html',
  styleUrls: ['./dynamic-filters.component.scss']
})
export class DynamicFiltersComponent implements OnInit, OnChanges, OnDestroy {
  @Input() filters: DynamicFilterConfig;
  @Output() filterValuesChange = new EventEmitter<any>();
  form: FormGroup;
  alive = true;

  get Params(): any[] {
    return (this.form.get('params') as FormArray).controls;
  }

  get AvailableFilters() {
    const activeFilters = this.Params.map(x => x.value.name);
    if (!this.filters) {
      return null;
    }
    return Object.keys(this.filters)
      .filter(x => activeFilters.indexOf(x) < 0)
      .map(k => ({
        value: k,
        name: this.filters[k].label
      }));
  }
  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      params: this.fb.array([])
    });
    this.form.valueChanges
      .pipe(
        takeWhile(() => this.alive),
        map(x =>
          x.params.reduce(
            (presV, currV) => ({
              ...presV,
              [currV.name]:
                currV.config.type !== 'boolean'
                  ? currV.value
                  : currV.value
                  ? '1'
                  : '0'
            }),
            {}
          )
        ),
        distinctUntilChanged((x, y) => JSON.stringify(x) === JSON.stringify(y))
      )
      .subscribe(x => {
        this.filterValuesChange.emit(x);
      });
  }

  ngOnInit() {}

  removeFilter(filter) {
    const indexToRemove = this.Params.findIndex(
      x => x.value.name === filter.value.name
    );
    const items = this.form.get('params') as FormArray;
    items.removeAt(indexToRemove);
  }

  onAddFilter(e) {
    if (this.form && this.filters) {
      const items = this.form.get('params') as FormArray;
      items.push(
        this.fb.group({
          name: e.target.value,
          label: this.filters[e.target.value].label,
          value: '',
          config: this.filters[e.target.value]
        })
      );
    }
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    this.alive = false;
  }

  isMaxFilter() {
    const items = this.form.get('params') as FormArray;
    return items.length >= 4;
  }
}
