import { OrderType } from "../models";

export const ORDER_ITEM_NUMBER = {
  newItem: 'ENTER ITEM CODE',
  subTotal: 'SUBTOTAL',
  buyin: 'BUYIN',
  giftCard: 'ARBGC',
};

export const ORDER_TYPE = {
  saleOrder: 'SO',
  quoteOrder: 'SQ',
  invoicedOrder: 'IO',
  creditReturn: 'CO',
  creditPrice: 'CV',
  creditReturnRetail: 'CR',
  showSaleOrder: 'S6',
  reservedInvoice: 'RI',
  reservedMer: 'RM',
  exportOrder: 'SX',
};

export const ORDER_TYPES: Partial<OrderType>[] = [
  {
    OrderTypeCode: ORDER_TYPE.saleOrder,
    OrderTypeDescription: 'Sales Order'
  },
  {
    OrderTypeCode: ORDER_TYPE.quoteOrder,
    OrderTypeDescription: 'Sales Quote'
  },
  {
    OrderTypeCode: ORDER_TYPE.exportOrder,
    OrderTypeDescription: 'Export Order'
  }
]

export const ORDER_LINE_STATUS = {
  invoiced: 'INVOICED',
  cancelled: 'CANCELLED',
  selling: 'SELLING',
  open: 'OPEN',
  backOrder: 'BACKORDER',
  convertQuote: 'CONVERTQUOTE',
  processing: 'PROCESSING',
  new: '',
  empty: ' ', // use this because can't update changeLineStatus to new.
  credit: 'CREDIT',
};

export const CREDIT_TYPE = {
  RETURN: 'RETURN',
  PRICE: 'PRICE'
}

export const ORDER_PAYMENT_TYPE = {
  gift: 'GIFT',
  cash: 'CASH',
  bankTransfer: 'BTFR',
  eftpos: 'EFTPOS',
  debit: 'DEBI',
  master: 'MSTC',
  visa: 'VISA',
  amex: 'AMEX',
  cheque: 'CHEQUE',
};

export const EFTPOS_CARD_TYPE = {
  debitCard: 'DEBIT CARD',
  visaCard: 'VISA CARD',
  masterCard: 'MASTERCARD',
  amex: 'AMEX CARD',
};

export const ASSOCIATION_TYPE = {
  mandatory: 'MANDATORY',
  paint: 'PAINT',
  fit: 'FIT',
};

export const ORDER_CONFIRMATION = {
  deposit: 'deposit',
  invoice: 'invoice',
};

export const ON_BEHALF = {
  branch: '0',
  nonCorp: '1',
};

export const ORDER_ATTACHMENT_TYPE = {
  note: 'note',
  link: 'link',
  file: 'file',
};

export const TRANSACTION_TYPE = {
  purchase: 'P',
  refund: 'R',
};

export const PREVIOUS_PAYMENT = 'Previous Payments';
