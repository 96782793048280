// Required in the right order for filter suggestions
export const FILTER_KEYS = [
  'Make',
  'Model',
  'Year',
  'Generation',
  'Series',
  'SubModel',
  'BodyType',
  'EngineType',
  'EngineCode',
  'DriveType',
  'TransmissionType',
  'Chassis'
];

export const FILTER_LABELS = {
  Make: 'Make',
  Model: 'Model',
  Year: 'Year',
  Generation: 'Generation',
  SubModel: 'Cab Type / Sub Model',
  Series: 'Trim',
  BodyType: 'Bopy',
  EngineType: 'Fuel Type',
  EngineCode: 'Engine',
  DriveType: 'Drive',
  TransmissionType: 'Transmission',
  Chassis: 'Chassis'
}
