import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation, Output, EventEmitter, HostListener, OnDestroy } from '@angular/core';
import { scheduler } from '../../../../../../../../../dhtmlx-scheduler';
import { EventService } from '@pos-app/core-ui';
import { first, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FetchJdeOrder } from '../orders/+state/orders.actions';
import { Store, select } from '@ngrx/store';
import { CustomersPartialState } from '../customers/+state/customers.reducer';
import { OrderArray, ResourceList, TaskArray, UserProfile } from '@pos-app/data';
import { getBranch, CoreUiPartialState } from '@pos-app/core-ui';
import { fetchAttachments } from './mockData';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import {
  get_formatted_duration,
  getSplitLine,
  convert,
  convertMM,
  getWorkDay,
  schedulerAppValue,
  getformatedDate,
  getformatedTime,
  getEventColorFromListWorkshopTask,
  getListWorkshopTasksFormattted,
  getformatedTimeForZones,
  getInMinutes,
  timeToMinutes,
} from './scheduler-helper';
import { checkInBreakRange } from './schedulerBreak-helper';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-scheduler',
  styleUrls: ['./scheduler.component.css'],
  templateUrl: './scheduler.component.html',
})
export class SchedulerComponent implements OnInit, OnDestroy {
  @ViewChild('scheduler_here', { static: true }) schedulerContainer!: ElementRef;
  @Output() onFetchOrderFromJDE = new EventEmitter();
  @Input() userProfile: UserProfile;
  unSubscribe$ = new Subject<void>();
  orderArray: OrderArray[] = [];
  taskArray: TaskArray[] = [];
  technician: ResourceList[] = [];
  unAllocatedOrders: OrderArray[] = [];
  unAllocatedFloater: OrderArray[] = [];
  clicked: Boolean = false;
  oldDate: any = '';
  currentEventData: any;
  year: number = this.route.snapshot.params.year;
  month: number = this.route.snapshot.params.month;
  day: number = this.route.snapshot.params.day;
  listWorkshopTasks;
  defaultBranch: string;

  constructor(
    private eventService: EventService,
    private customerStore: Store<CustomersPartialState>,
    private coreUIStore: Store<CoreUiPartialState>,
    private route: ActivatedRoute
  ) {}

  @HostListener('window:scroll', ['$event']) onScrollEvent($event) {
    scheduler.config.tooltip_offset_y = -window.scrollY;
  }

  ngOnInit() {
    //=========================================================================================
    //Initialize plugins
    //=========================================================================================
    this.coreUIStore.pipe(takeUntil(this.unSubscribe$), select(getBranch)).subscribe((x) => {
      this.defaultBranch = x.BranchCode;
    });
    var that = this;
    scheduler.clearAll();
    scheduler.plugins({
      timeline: true,
      treetimeline: true,
      editors: true,
      tooltip: true,
      key_nav: true,
      limit: true,
      multiselect: true,
      multisection: true,
      collision: true,
    });

    //=========================================================================================
    // config
    //=========================================================================================
    scheduler.config.details_on_create = true;
    scheduler.config.details_on_dblclick = true;
    scheduler.config.buttons_right = ['dhx_delete_btn'];
    scheduler.config.multisection = true;
    scheduler.config.show_loading = true;

    //=========================================================================================
    //scheduler.locale.labels
    //=========================================================================================
    //-----------------------------------
    //main
    //------------------------------------
    scheduler.locale.labels['timeline_tab'] = 'Timeline';
    scheduler.locale.labels['section_custom'] = 'Section';
    scheduler.locale.labels['unit_tab'] = 'Unit';
    //-----------------------------------
    //Common lighbox labels
    //-----------------------------------
    scheduler.locale.labels['section_customer'] = 'Customer :';
    scheduler.locale.labels['section_order'] = 'Order :';
    scheduler.locale.labels['section_vehicle'] = 'Vehicle :';
    scheduler.locale.labels['copy_button'] = 'Copy';
    scheduler.locale.labels['section_allocatedHours'] = 'Allocated Hours :';
    scheduler.locale.labels['section_extraHours'] = 'Extra Hrs:';
    scheduler.locale.labels['section_groupName'] = 'Group Name:';
    scheduler.locale.labels['section_attachment'] = 'Attachments';
    // scheduler.locale.labels['section_wholeDayWorkYN'] = 'Whole Day';
    //-----------------------------------
    //Unallocated order lighbox labels
    //-----------------------------------
    scheduler.locale.labels['split_button'] = 'Split';
    scheduler.locale.labels['consolidate_button'] = 'Consolidate';
    scheduler.locale.labels['dhx_save_btn'] = 'SAVE';
    scheduler.locale.labels['dhx_cancel_btn'] = 'CANCEL';
    //-----------------------------------
    //Allocated order lighbox labels
    //-----------------------------------
    scheduler.locale.labels['section_technician'] = 'Technician';
    scheduler.locale.labels['section_technicianSelect'] = 'Select More Technician';

    //=========================================================================================
    //Setting initial data for element
    //=========================================================================================
    const resize_date_format = scheduler.date.date_to_str(scheduler.config.hour_date);

    //=========================================================================================
    //Custom Formatting Event Bar
    //=========================================================================================
    scheduler.templates.event_bar_text = function (start, end, event) {
      const state = scheduler.getState();
      const lines = getSplitLine(event.text.toString());
      const extra =
        parseFloat([event?.end_date?.getHours(), event?.end_date.getMinutes()].join('.')) -
        parseFloat([event?.start_date?.getHours(), event?.start_date.getMinutes()].join('.')) -
        event?.data?.StandardFitHours;
      if (state.drag_id == event.id) {
        return resize_date_format(start) + ' - ' + resize_date_format(end) + ' (' + get_formatted_duration(start, end) + ') ';
        // | Extra Hrs:" +extra
      }
      var bar_partition = ''; //event.text;
      if ([1000000000000001, 1000000000000002, 1000000000000003].some((el) => el === event.section_id) === false) {
        let adjustedOrExtra: string = '';
        if (Math.abs(event?.end_date?.getTime() - event?.start_date?.getTime()) / 3600000 - event.data?.StandardFitHours > 0) {
          adjustedOrExtra =
            "<span style='padding-left:4px'>" +
            'Extra: ' +
            Math.round(
              (Math.abs(event?.end_date?.getTime() - event?.start_date?.getTime()) / 3600000 - event.data?.StandardFitHours + Number.EPSILON) * 100
            ) /
              100 +
            ' hrs' +
            '</span>';
        } else if (Math.abs(event?.end_date?.getTime() - event?.start_date?.getTime()) / 3600000 - event.data?.StandardFitHours < 0) {
          adjustedOrExtra =
            "<span style='margin-left:-" +
            (2 * scheduler._cols[0] * event.data?.StandardFitHours + 8) +
            "px; padding-left:8px'>" +
            Math.round(
              (Math.abs(event?.end_date?.getTime() - event?.start_date?.getTime()) / 3600000 - event.data?.StandardFitHours + Number.EPSILON) * 100
            ) /
              100 +
            ' hrs' +
            ' Adjusted | ' +
            '</span>';
        }
        bar_partition +=
          event.data?.StandardFitHours > 0
            ? "<div style='background:green; margin-left:-12px'><div style='border-left:" +
              (2 * scheduler._cols[0] * event.data?.StandardFitHours +
                (Math.abs(event?.end_date?.getTime() - event?.start_date?.getTime()) / 3600000 - event.data?.StandardFitHours > 0 ? 0 : 8)) +
              "px solid green; background:red; padding-top:20px; padding-bottom:20px;'>" +
              "<span style='margin-left:-" +
              2 * scheduler._cols[0] * event.data?.StandardFitHours +
              'px; margin-right:50px; padding-top:20px; padding-bottom:40px;margin-right:' +
              (2 * scheduler._cols[0] * event.data?.StandardFitHours + 8000) +
              "px; padding-left:4px '>" +
              lines[0] +
              '</span> ' +
              '<br>' +
              "<span style='margin-left:-" +
              (2 * scheduler._cols[0] * event.data?.StandardFitHours + 8) +
              "px; padding-bottom:40px;  padding-left:4px  '>" +
              lines[1] +
              '</span>' +
              '<br>' +
              adjustedOrExtra +
              '</div></div>'
            : '<div>' + event.text + '</div>';
        return bar_partition;
      } else if (event?.data?.StatusGroup === 'SALES ORDER') {
        return (
          '<div style="background:#03adfc; padding-top:20px; padding-bottom:20px; margin-left:-10px; padding-right:8000px" >' + event.text + '</div>'
        ); // default
      } else {
        return (
          '<div style="background:#d303fc; padding-top:20px; padding-bottom:20px; margin-left:-10px; padding-right:8000px" >' + event.text + '</div>'
        ); // default
      }
    };

    //=========================================================================================
    //TimeLineView
    //=========================================================================================
    scheduler.createTimelineView({
      section_autoheight: false,
      name: 'timeline',
      x_unit: 'minute',
      x_date: '%H:%i',
      x_step: 30,
      x_size: 24,
      x_start: 14,
      x_length: 48,
      y_unit: scheduler.serverList('sections', []),
      y_property: 'section_id',
      render: 'tree',
      folder_events_available: true,
      dy: 60,
      dx: 250,
      event_dy: 'full',
    });
    scheduler.config.hour_size_px = 84;
    scheduler.config.full_day = true;

    //=========================================================================================
    //Common Event Ligthbox setup
    //=========================================================================================
    var parent_select_options = scheduler.serverList('technician');
    var child_select_options = { '0000': scheduler.serverList('technician') };
    var update_select_options = function (select, options) {
      // helper function
      if (options) {
        for (var i = 0; i < options.length; i++) {
          select.section.options[i] = new Option(options[i].key, options[i].label);
        }
      }
    };
    const newEventLightbox = [
      { name: 'Type', type: 'select', options: scheduler.serverList('listworkshopTasks', this.listWorkshopTasks), map_to: 'taskCode' },
      { name: 'description', height: 50, map_to: 'text', type: 'textarea', focus: true },
      {
        name: 'technician',
        height: 100,
        width: 100,
        type: 'multiselect',
        options: scheduler.serverList('technician'),
        map_to: 'technician_id',
        vertical: true,
      },
      { name: 'time', height: 72, type: 'time', map_to: 'auto' },

      // { name: 'wholeDayWorkYN', map_to: 'single_checkbox', type: 'checkbox', checked_value: 'wholeDay', height: 40 },
    ];

    //=========================================================================================
    //Order Event Ligthbox setup
    //=========================================================================================
    const orderViewLightbox = [
      { name: 'order', height: 20, type: 'template', map_to: 'orderNumber', id: 'orderNumber' },
      { name: 'customer', height: 20, type: 'template', map_to: 'customer' },
      { name: 'vehicle', height: 20, type: 'template', map_to: 'vehicle' },
      { name: 'description', height: 60, map_to: 'text', type: 'textarea', focus: false },
      { name: 'technician', height: 40, type: 'select', options: scheduler.serverList('technician'), map_to: 'technician_id' },
      { name: 'groupName', height: 30, map_to: 'groupName', type: 'textarea', focus: false },
      { name: 'time', height: 72, type: 'time', map_to: 'auto' },
      { name: 'allocatedHours', height: 20, type: 'template', map_to: 'allocatedHours' },
      { name: 'extraHours', height: 20, type: 'template', map_to: 'extraHours' },
      { name: 'attachment', height: 150, type: 'template', map_to: 'my_templete' },
    ];

    //=========================================================================================
    //initialize scheduler
    //=========================================================================================
    const format = scheduler.date.date_to_str('%Y-%m-%d %H:%i');
    // scheduler.blockTime(new Date(this.year,this.month,this.day), [10*60,12*60]); // for blocking specific time allocation for event
    scheduler.init(this.schedulerContainer.nativeElement, new Date(this.year, this.month - 1, this.day), 'timeline'); // set default calander date of calander here
    scheduler.clearAll();

    //=========================================================================================
    //button function on lighbox
    //=========================================================================================
    const element = document.querySelector('.dhx_cal_data');
    element.addEventListener('scroll', (event) => {
      scheduler.removeContextMenu();
    });
    scheduler.attachEvent('onCellClick', function (x_ind, y_ind, x_val, y_val, e) {
      scheduler.removeContextMenu();
    });
    scheduler.attachEvent('onEmptyClick', function (date, e) {
      scheduler.removeContextMenu();
    });
    scheduler.attachEvent('onLightboxButton', function (button_id, node, e) {
      if (button_id === 'split_button') {
        scheduler.endLightbox(false);
        this.btn_splitOrder();
      }
      if (button_id === 'consolidate_button') {
        scheduler.endLightbox(false);
        this.btn_consolidateOrder();
      }
      if (button_id === 'copy_button') {
        scheduler.endLightbox(false);
        this.btn_copyEvent(button_id, node, e);
      }
    });

    // check if the event collison
    scheduler.attachEvent(
      'onEventCollision',
      (ev, evs) => {
        if ([1000000000000001, 1000000000000002, 1000000000000003].some((el) => el === ev.section_id) === true) {
          return false;
        } else {
          let showonce = true;
          // for (let i = 0; i < evs.length; i++) {
          //   if (ev.start_date.getTime()>=evs[i].end_date.getTime()) {
          // 		continue;
          // 	}
          if (showonce) {
            scheduler.alert({
              text: 'This techinican is already booked for selected date and time.',
              title: 'ERROR!',
              ok: 'OK',
              callback: function () {},
            });
            showonce = false;
          }
          // }
        }
        return true;
      },
      {}
    );

    //=========================================================================================
    //Initial load scheduler
    //=========================================================================================
    if (!(scheduler as any).$_initOnce) {
      (scheduler as any).$_initOnce = true;
      const startDate = this.day.toString() + '/' + this.month.toString() + '/' + this.year.toString();
      this.eventService
        .listWorkshopTask({})
        .pipe(takeUntil(this.unSubscribe$))
        .subscribe((data) => {
          if (data?.ErrorFlag === '0') {
            this.fetchOrder(startDate, data?.SearchResults);
            scheduler.updateCollection('listworkshopTasks', getListWorkshopTasksFormattted(data?.SearchResults));
            that.listWorkshopTasks = getListWorkshopTasksFormattted(data?.SearchResults);
          }
        });
      const dp = scheduler.createDataProcessor({
        event: {
          create: (data: any) => {
            console.log('create', data?.id);
          },
          update: (data: any) => {
            if (data?.groupName && data?.groupName !== data?.data?.GroupName) {
              this.eventService
                .renameGroupName({
                  orderNumber: (data?.data?.OrderNumber).toString(),
                  orderType: data?.data?.OrderType,
                  oldGroupName: data?.data?.GroupName,
                  newGroupName: data?.groupName,
                })
                .pipe(takeUntil(this.unSubscribe$))
                .subscribe((data) => {
                  if (data?.ErrorFlag === '0') {
                    scheduler.clearAll();
                    this.fetchOrder(convert(this.currentEventData?.start_date), this.listWorkshopTasks);
                  }
                });
            }
          },
          delete: (id: number) => {
            console.log('deleted', id);
          },
        },
      });

      dp.attachEvent('onBeforeDataSending', function (id, state, data) {
        if (
          data.taskCode &&
          (state === 'inserted' || state === 'updated') &&
          id &&
          data &&
          ![100000000000001, 100000000000002, 1000000000000001, 1000000000000002, 1000000000000003].some((el) => el === data.section_id)
        ) {
          let resourceIDList = data?.section_id.toString().indexOf(',') > 0 ? data?.section_id.split(',') : data?.section_id;
          const dataToCreateOrUpdate = {
            startDate: convert(data?.start_date),
            endDate: convert(data?.end_date),
            startTime: Number(moment(data?.start_date).format('HH:mm:ss').replace(/:|_/g, '')),
            endTime: Number(moment(data?.end_date).format('HH:mm:ss').replace(/:|_/g, '')),
            branch: that.defaultBranch,
            taskID: state === 'inserted' ? 0 : data?.data?.TaskID,
            taskCode: state === 'inserted' ? data?.keyCode : data?.data?.TaskCode,
            resourceID: resourceIDList.length > 1 ? resourceIDList.map((el) => '00' + el.toString()) : '00' + data?.section_id,
          };
          scheduler.customCreateOrUpdateEvent(id, dataToCreateOrUpdate);
          state === 'inserted' ? scheduler.reload(scheduler.getState().date, that.listWorkshopTasks) : null;
          return true;
        } else if (
          data.taskCode &&
          state === 'deleted' &&
          id &&
          data &&
          ![100000000000001, 100000000000002, 1000000000000001, 1000000000000002, 1000000000000003].some((el) => el === data.section_id)
        ) {
          const dataToDelete = {
            startDate: '',
            endDate: '',
            startTime: 0,
            endTime: 0,
            branch: that.defaultBranch,
            taskID: data?.data?.TaskID,
            taskCode: data?.data?.TaskCode ?? data?.keyCode,
            resourceID: '00' + data?.section_id.toString(),
          };
          scheduler.customDeleteEvent(id, dataToDelete);
          return true;
        } else if (
          !data.taskCode &&
          state === 'updated' &&
          ![100000000000001, 100000000000002, 1000000000000001, 1000000000000002, 1000000000000003, 100000000000003].some(
            (el) => el === data.section_id
          )
        ) {
          const orderDataToUpdate = {
            orderNumber: data?.data?.OrderNumber,
            orderType: data?.data?.OrderType,
            groupName: data?.data.GroupName,
            resourceID: '00' + data?.section_id.toString(),
            startTime: Number(moment(data?.start_date).format('HH:mm:ss').replace(/:|_/g, '')),
            endTime: Number(moment(data?.end_date).format('HH:mm:ss').replace(/:|_/g, '')),
            startDate: convert(data?.start_date),
            endDate: convert(data?.end_date),
          };
          scheduler.customUpdateOrder(orderDataToUpdate);
          return true;
        } else if (
          !data.taskCode &&
          state === 'updated' &&
          [100000000000001, 100000000000002, 1000000000000001, 1000000000000002, 1000000000000003, 100000000000003].some(
            (el) => el === data.section_id
          )
        ) {
          const orderDataToUpdate = {
            orderNumber: data?.data?.OrderNumber,
            orderType: data?.data?.OrderType,
            groupName: data?.data.GroupName,
            resourceID: '',
            startTime: Number(moment(data?.start_date).format('HH:mm:ss').replace(/:|_/g, '')),
            endTime: Number(moment(data?.end_date).format('HH:mm:ss').replace(/:|_/g, '')),
            startDate: convert(data?.start_date),
            endDate: convert(data?.end_date),
          };
          scheduler.customUpdateOrder(orderDataToUpdate);
          return true;
        } else {
          return false;
        }
      });
    }

    //=========================================================================================
    //Attaching action Event beofre lighbox appear
    //=========================================================================================

    scheduler.attachEvent('onBeforeLightbox', function (event_id) {
      scheduler.setNavStatus(true);
      scheduler.resetLightbox();
      const ev = scheduler.getEvent(event_id);
      scheduler.config.event_duration = ev.data?.StandardFitHours * 60;
      scheduler.config.auto_end_date = true;
      if ([1000000000000001, 1000000000000002, 1000000000000003].some((el) => el === ev.section_id) || ev?.data?.OrderNumber) {
        if ([1000000000000001, 1000000000000002, 1000000000000003].some((el) => el === ev.section_id) === false) {
          scheduler.config.buttons_left = ['dhx_save_btn', 'dhx_cancel_btn'];
        } else if (ev.data?.SpiltOrderYN === 'N') {
          scheduler.config.buttons_left = ['dhx_save_btn', 'dhx_cancel_btn', 'split_button'];
        } else if (ev.data?.SpiltOrderYN === 'Y') {
          scheduler.config.buttons_left = ['dhx_save_btn', 'dhx_cancel_btn', 'consolidate_button'];
        }
        scheduler.config.buttons_right = [];
        scheduler.config.lightbox.sections = orderViewLightbox;
      } else {
        scheduler.config.buttons_left = ['dhx_save_btn', 'dhx_cancel_btn'];
        scheduler.config.lightbox.sections = newEventLightbox;
      }
      ev.orderNumber = `<button style="border: none;" onclick="scheduler.btn_orderNumberClick(${ev.data?.OrderNumber})">${ev.data?.OrderNumber}</button>`;
      ev.customer = ev.data?.CustomerName;
      ev.vehicle = ev.data?.CarShortDesc;
      ev.technician_id = ev.section_id;
      ev.allocatedHours = ev.data?.StandardFitHours;

      ev.groupName = ev.data?.GroupName;
      const dataAttachemnt = fetchAttachments.map((el) => {
        return `
          <tr>
            <td>${el.type}</td>
            <td>Last changed By:${el.user} on ${el.date} at ${el.time}</br>
              ${el.type === 'Link' ? el.detail + "<a href='https://online.arb.com.au'>" + el.link + '</a>' : ''}   
            </td>
          </tr>
        `;
      });
      const removecommasString = dataAttachemnt.toString().replace(/,/g, '');
      ev.my_templete = `<div style='height:200px; width:auto; overflow:scroll;' >
                          <table border='0' align='center' cellspacing='0' cellpadding='10'>
                            <tr>
                              <th>Type</th>
                              <th>Description</th>
                            </tr>
                            ${removecommasString}
                            </table>
                        </div>`;
      const addedHours = Math.abs(ev.end_date?.getTime() - ev?.start_date?.getTime()) / 3600000;
      if (ev.data?.StandardFitHours < addedHours) {
        ev.extraHours = addedHours - ev.data?.StandardFitHours;
      } else {
        ev.extraHours = 0;
      }
      this.btn_currentEventData(ev);

      if (!ev.child_id) {
        var parent_id = ev.parent_id || parent_select_options[0].key;
        var new_child_options = child_select_options[parent_id];
        update_select_options(scheduler.formSection('child'), new_child_options);
      }

      return true;
    });

    //=========================================================================================
    //OnChange on lighbox event
    //=========================================================================================
    scheduler.attachEvent('onLightbox', function (ev) {
      const order = scheduler.formSection('order');
      const time = scheduler.formSection('time');
      const allocatedHours = scheduler.formSection('allocatedHours');
      const extraHours = scheduler.formSection('extraHours');
      const selects = time.node.getElementsByTagName('select');
      for (let i = 0; i < selects.length; i++) {
        selects[i].onchange = function () {
          const addedHours = Math.abs(time?.getValue()?.end_date?.getTime() - time?.getValue()?.start_date?.getTime()) / 3600000;
          if (allocatedHours?.getValue() < addedHours) {
            extraHours?.setValue(addedHours - allocatedHours?.getValue());
          } else {
            extraHours?.setValue(0);
          }
        };
      }
    });

    //=========================================================================================
    //adding custom button on calander with callback function
    //=========================================================================================
    scheduler.attachEvent('onBeforeViewChange', function (old_mode, old_date, mode, date) {
      scheduler.removeContextMenu();
      if (mode == 'Settings') {
        scheduler.alert({
          text: '',
          title: 'Custom setting needs more work',
          ok: 'OK',
          callback: function () {},
        });

        return false; // prevent view change on custom button click
      } else {
        this.btn_Clicked_onBeforeViewChange(old_date, date);
        return true;
      }
    });

    scheduler.attachEvent('onClick', function (id) {
      var event = scheduler.getEvent(id);
      if (event.important) scheduler.config.icons_select = ['icon_details'];
      else scheduler.config.icons_select = ['icon_details', 'icon_delete'];

      return true;
    });

    //=========================================================================================
    //adding save event
    //=========================================================================================
    scheduler.attachEvent('onEventSave', function (id, ev, is_new) {
      if (!is_new) {
      }
      if (ev.technician_id) {
        ev.section_id = ev.technician_id;
      } else {
        schedulerAppValue.invalid_SectionIds.some((el) => el === ev.section_id) ||
          schedulerAppValue.unAllocatedSalesOrder_SectionId === ev.section_id ||
          schedulerAppValue.unAllocatedFloater_SectionId === ev.section_id;
      }

      if (is_new) {
        const listworkshopTask = that.listWorkshopTasks.find((el) => el.key === ev.taskCode);
        ev.text = listworkshopTask.label + ' : ' + ev.text;
        ev.color = '#' + listworkshopTask.backgroundColourCode;
        ev.keyCode = listworkshopTask.keyCode;
      }
      if (!ev.text) {
        alert('Text must not be empty');
        return false;
      }
      return true;
    });

    scheduler.attachEvent('onBeforeEventDelete', function (id, ev) {
      if (ev?.data?.OrderNumber > 0) {
        scheduler.alert({
          text: '',
          title: 'Order not allowed to Delete',
          ok: 'OK',
          callback: function () {},
        });
        return false;
      }
      return true;
    });
    //=========================================================================================
    //Triggering method before event change
    //=========================================================================================
    scheduler.attachEvent('onBeforeEventChanged', function (ev, e, is_new, original) {
      let technicianData = that.technician?.filter((el) => el.resourceID === ev.section_id)[0];
      let condition = [];
      if (
        !is_new &&
        ![100000002, 1000000000000002, 1000000000000003, 1000000000000003, 100000000000002, 100000000000003].some((el) => el === ev.section_id)
      ) {
        condition = this.btn_eventDropped(ev, original, technicianData);
        console.log(condition);
        for (let i = 0; i < condition.length; i++) {
          if (original.section_id === 1000000000000001 || original.section_id === 1000000000000002 || original.section_id === 1000000000000003) {
            if (condition[i]?.mins) {
              ev.end_date = moment(ev.end_date).add(condition[i]?.mins, 'm').toDate();
            }
          } else if (condition[i]?.value === 'droppedToBreak') {
            if (original.section_id !== 1000000000000001 || original.section_id !== 1000000000000002 || original.section_id !== 1000000000000003) {
              if (
                ev.start_date.toLocaleTimeString('it-IT') > condition[i]?.breakStartTime &&
                ev.start_date.toLocaleTimeString('it-IT') < condition[i]?.breakEndTime
              ) {
                let exceedMins =
                  condition[i]?.mins + (timeToMinutes(condition[i]?.breakStartTime) - timeToMinutes(ev.start_date.toLocaleTimeString('it-IT')));
                ev.end_date = moment(ev.end_date).add(exceedMins, 'm').toDate();
              } else {
                ev.end_date = moment(ev.end_date).add(condition[i]?.mins, 'm').toDate();
              }
              if (ev.start_date.toLocaleTimeString('it-IT') >= condition[i]?.breakStartTime) {
                ev.start_date.setHours(Number(condition[i]?.breakEndTime.toString().split(':')[0]) * 1);
                ev.start_date.setMinutes(Number(condition[i]?.breakEndTime.toString().split(':')[1]) * 1);
              }
            }
            scheduler.updateEvent(ev.id);
          } else if (condition[i]?.value === 'removeFromBreak') {
            // when moving from on break
            if (original.section_id !== 1000000000000001 || original.section_id !== 1000000000000002 || original.section_id !== 1000000000000003) {
              ev.end_date = moment(ev.end_date)
                .add(condition[i]?.mins * condition[i]?.break, 'm')
                .toDate();
            } else {
              ev.end_date.setHours(ev.start_date.getHours() + Math.floor(ev.data?.StandardFitHours)); // for 1 hour lunch break
              ev.end_date.setMinutes(ev.start_date.getMinutes() + (ev.data?.StandardFitHours - Math.floor(ev.data?.StandardFitHours)) * 60);
            }
            scheduler.updateEvent(ev.id);
          } else if (
            condition[i]?.value === 'NoDropOrRemoveBreak' &&
            (original.section_id === 1000000000000001 || original.section_id === 1000000000000002 || original.section_id === 1000000000000003)
          ) {
            ev.end_date.setHours(ev.start_date.getHours() + Math.floor(ev.data?.StandardFitHours));
            ev.end_date.setMinutes(ev.start_date.getMinutes() + (ev.data?.StandardFitHours - Math.floor(ev.data?.StandardFitHours)) * 60);
            condition[0].value = this.btn_eventDropped(ev, original, technicianData);
            if (condition[0].value === 1) {
              ev.end_date.setHours(ev.end_date.getHours() + 1); // for 1 hour lunch break
              scheduler.updateEvent(ev.id);
            }
          } else if (condition[i]?.value === 'NoDropOrRemoveBreak') {
            if (ev.start_date.toLocaleTimeString('it-IT') >= condition[i]?.breakStartTime) {
              if (
                ev.start_date.toLocaleTimeString('it-IT') > condition[i]?.breakStartTime &&
                ev.start_date.toLocaleTimeString('it-IT') < condition[i]?.breakEndTime
              ) {
                ev.end_date = moment(ev.end_date)
                  .subtract(timeToMinutes(ev.start_date.toLocaleTimeString('it-IT')) - timeToMinutes(condition[i]?.breakStartTime), 'm')
                  .toDate();
              }
              ev.start_date.setHours(Number(condition[i]?.breakEndTime.toString().split(':')[0]) * 1);
              ev.start_date.setMinutes(Number(condition[i]?.breakEndTime.toString().split(':')[1]) * 1);
            }
            if (
              ev.end_date.toLocaleTimeString('it-IT') > condition[i]?.breakStartTime &&
              ev.end_date.toLocaleTimeString('it-IT') <= condition[i]?.breakEndTime
            ) {
              ev.end_date = moment(ev.end_date).subtract(condition[i]?.mins, 'm').toDate();
            }
          }
        }
      }
      if (
        (is_new && [1000000000000001, 1000000000000002, 1000000000000003, 100000000000002, 100000000000003].some((el) => el === ev.section_id)) ||
        (is_new && ev.data?.OrderNumber) ||
        [100000002, 100000000000001, 100000000000002, 1000000000000001].some((el) => el === ev.section_id)
      ) {
        scheduler.config.dblclick_create = false;
        return false;
      } else {
        return true;
      }
    });

    scheduler.attachEvent('onAfterLightbox', function () {
      scheduler.setNavStatus(false);
    });

    //-----------------------------------------------------------------------------
    //onContextMenu /Right Click
    //hide the calander
    //add menu div for right click
    //on scroll hide the menu
    //if right click without id return false
    //if right click on task return false
    //remove current menu div if on right click to different order
    //---------------------------------------------------------------------------
    scheduler.attachEvent('onContextMenu', function (id, e) {
      //TODO: add api call on user action
      scheduler.removeContextMenu();
      if (scheduler._locate_cell_timeline(e)) {
        var section = scheduler.matrix.timeline.y_unit[scheduler._locate_cell_timeline(e).y];
        if (scheduler._locate_cell_timeline(e).x === -1) {
          if (that.technician.some((el) => el.resourceID === section.key)) {
            scheduler.setNavStatus(true);
            document.querySelector('.dhx_cal_container')?.setAttribute('style', 'opacity:.7');
            const contextMenu = `
              <div 
                style="top: ${e.clientY}px;left: ${e.clientX}px; display: grid; background:white; padding-bottom:30px; width:200px"
                class='custom_menu'
                onclick="scheduler.removeContextMenu()"
              >
                <div style='display:flex; flex-direction:column; text-align:center'>
                  <input type=button value="Delete" onclick=""/ > 
                  <input type=button value="Edit" onclick=""/ > 
                </div>
                <div style='display:flex; flex-direction:column; background:red; text-align:center; color:white'>
                CANCEL
                </div>
              </div>
           `;
            //this.scheduler.tooltip.hide();
            const el = document.createElement('div');
            el.innerHTML = contextMenu;
            document.body.appendChild(el);
          }
        }
      }

      if (!id) {
        return;
      }
      var tooltip = scheduler.ext.tooltips.tooltip;
      if (tooltip) {
        tooltip.hide();
      }
      const ev = scheduler.getEvent(id);
      if (!ev?.data?.TaskCode) {
        scheduler.setNavStatus(true);
        document.querySelector('.dhx_cal_container')?.setAttribute('style', 'opacity:.7');
        const contextMenu = `
          <div 
            style="top: ${e.clientY}px;left: ${e.clientX}px; display: grid; background:white; padding-bottom:30px"
            class='custom_menu'
            onclick="scheduler.removeContextMenu()"
          >
            <div style='display:flex; flex-direction:column; text-align:center; background:grey;  padding-top:10px'>
              Update Status
              <input type=button value="Not Started"/ > 
              <input type=button value="Work in Progress"/>
              <input type=button value="Completed"/>
            </div>
            <div style='display:flex; flex-direction:column; text-align:center; background:grey;  padding-top:10px'>
              Update Job
              <input type=button value="Unallocat Task" onclick="scheduler.customUpdateOrder({},${id})"/>
              <input type=button value="Schedule For Tomorrow"/>
            </div>
            <div style='display:flex; flex-direction:column; background:red; text-align:center; color:white'>
            CANCEL
            </div>
          </div>
       `;
        //this.scheduler.tooltip.hide();
        const el = document.createElement('div');
        el.innerHTML = contextMenu;
        document.body.appendChild(el);
      }
    });

    //=========================================================================================
    //Adding Custom scheduler event function for button
    //=========================================================================================
    scheduler.btn_Clicked_onBeforeViewChange = (old_date, date) => {
      if (old_date !== date) {
        this.fetchOrder(convert(date), this.listWorkshopTasks);
      }
    };

    scheduler.btn_splitOrder = () => {
      this.splitOrder();
    };

    scheduler.btn_consolidateOrder = () => {
      this.consolidateOrder();
    };
    scheduler.btn_currentEventData = (data: any) => {
      this.currentEventData = data;
    };
    scheduler.btn_orderNumberClick = (data) => {
      this.fetchOrderFromJDE();
    };

    //dp. api action
    scheduler.customCreateOrUpdateEvent = (id, data) => {
      this.eventService.insert(data).then((val) => {
        return val;
      });
    };
    scheduler.customDeleteEvent = (id, data) => {
      this.eventService.remove(data);
    };

    scheduler.customUpdateOrder = (data = {}, id = 0) => {
      if (id > 0) {
        const ev = scheduler.getEvent(id);
        const orderDataToUpdate = {
          orderNumber: ev?.data?.OrderNumber,
          orderType: ev?.data?.OrderType,
          groupName: ev?.data?.GroupName,
          resourceID: '',
          startTime: ev?.data?.WorkshopStartTime,
          endTime: ev?.data?.WorkshopEndTime,
          startDate: ev?.data?.WorkshopStartDate,
          endDate: ev?.data?.WorkshopEndDate,
        };
        this.eventService.maintainOrderBooking(orderDataToUpdate);
        scheduler.reload(scheduler.getState().date, that.listWorkshopTasks);
      } else {
        this.eventService.maintainOrderBooking(data);
      }
    };

    scheduler.reload = (date, listWorkshopTask) => {
      setTimeout(() => {
        scheduler.clearAll();
        this.fetchOrder(convert(date), listWorkshopTask);
      }, 10);
    };

    scheduler.setNavStatus = (value) => {
      this.eventService.navDisable = value;
    };

    scheduler.removeContextMenu = () => {
      const el = document.querySelector('.custom_menu');
      if (el) {
        el.innerHTML = null;
        el.remove();
        scheduler.setNavStatus(false);
        document.querySelector('.dhx_cal_container')?.setAttribute('style', 'opacity:');
      }
    };

    scheduler.showSpinner = () => {
      if (scheduler.config.show_loading) {
        var spin;
        spin = document.createElement('div');
        spin.className = 'dhx_loading';
        spin.style.left = Math.round((scheduler._x - 128) / 2) + 'px';
        spin.style.top = Math.round((scheduler._y - 15) / 2) + 'px';
        scheduler._obj.appendChild(spin);
      }
    };

    scheduler.hideSpinner = () => {
      var spin = document.createElement('div');
      if (spin && typeof spin == 'object') {
        if (spin.parentNode) {
          spin.parentNode.removeChild(spin);
        }
        scheduler.config.show_loading = true;
      }
    };

    //=========================================================================================
    //ToolTips
    //=========================================================================================

    scheduler.templates.tooltip_text = function (start, end, event) {
      const eventText = event.text ? '<b>Task:</b> ' + event.text : '';
      const eventCustomer = event.data?.CustomerName ? '<br/><b>Customer:</b> ' + event.data?.CustomerName : '';
      const totalHours = event.data?.StandardFitHours ? '<br/><b>Total Fit Hours:</b> ' + event.data?.StandardFitHours : '';
      const groupName = event.data?.GroupName ? '<br/><b>Group Name:</b> ' + event.data?.GroupName : '';
      const date =
        [1000000000000001, 1000000000000002, 1000000000000003].some((el) => el === event.section_id) === false
          ? '<br/><b>Start date:</b> ' + format(start) + '<br/><b>End date:</b> ' + format(end)
          : '';

      return eventText + eventCustomer + totalHours + groupName + date;
    };

    scheduler.btn_eventDropped = (ev, original, technicianData) => {
      return checkInBreakRange(ev, original, technicianData);
    };
  }
  ngOnDestroy(): void {
    scheduler.clearAll();
   
  }

  //=========================================================================================
  //fetching technician and orders then adding to calender using key
  //=========================================================================================

  fetchOrder(date: any, listWorkshopTask: any) {
    scheduler.showSpinner();
    var that = this;
    this.eventService
      .fetchWorkshopHours({
        branch: that.defaultBranch,
        startDate: date,
        endDate: date,
      })
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((workshopHoursdata) => {
        that.technician = workshopHoursdata?.map(function (el) {
          var obj = Object.assign({}, el);
          obj.key = el.resourceID;
          obj.label = el.resourceName;
          return obj;
        });

        workshopHoursdata.map((el) => {
          const break1StartTime = getformatedTimeForZones(el.break1StartTime.toString());
          const break1EndTime = getformatedTimeForZones(el.break1EndTime.toString());
          const break2StartTime = getformatedTimeForZones(el.break2StartTime.toString());
          const break2EndTime = getformatedTimeForZones(el.break2EndTime.toString());
          const break3StartTime = getformatedTimeForZones(el.break3StartTime.toString());
          const break3EndTime = getformatedTimeForZones(el.break3EndTime.toString());
          scheduler.addMarkedTimespan({
            days: 'fullweek',
            zones: [break1StartTime, break1EndTime],
            css: 'green_section_short_break',
            sections: { timeline: el.resourceID },
            html: `TEA`,
          });
          scheduler.addMarkedTimespan({
            days: 'fullweek',
            zones: [break2StartTime, break2EndTime],
            css: 'green_section_lunch_break',
            sections: { timeline: el.resourceID },
            html: `${el.break2Name.toUpperCase()}`,
          });
          scheduler.addMarkedTimespan({
            days: 'fullweek',
            zones: [break3StartTime, break3EndTime],
            css: 'green_section_short_break',
            sections: { timeline: el.resourceID },
            html: `TEA`,
          });
        });

        this.eventService
          .fetchRecordsForBooking({
            startDate: date,
            endDate: date,
            branch: this.defaultBranch,
          })
          .pipe(takeUntil(this.unSubscribe$))
          .subscribe((data) => {
            that.orderArray = data?.orderArray;
            that.taskArray = data?.taskArray;
            this.unAllocatedOrders = data?.orderArray
              ?.filter((el) => el.ResourceAssigned === '')
              .map((data, index) => ({
                key: data?.ResourceAssigned ? parseInt(data?.ResourceAssigned, 10) : index + 1,
                label: data?.OrderNumber,
                data: data,
              }));

            scheduler.updateCollection('sections', this.getSection(that.technician));

            data?.taskArray.map((allocatedTasks, index) => {
              const colorEvent = getEventColorFromListWorkshopTask(allocatedTasks?.TaskCode, listWorkshopTask);
              const startDateTime =
                getformatedDate(allocatedTasks?.WorkshopStartDate) + ' ' + getformatedTime(allocatedTasks?.WorkshopStartTime.toString());
              const endDateTime =
                getformatedDate(allocatedTasks?.WorkshopEndDate) + ' ' + getformatedTime(allocatedTasks?.WorkshopEndTime.toString());
              index % 2 === 0
                ? scheduler.parse([
                    {
                      start_date: startDateTime,
                      end_date: endDateTime,
                      text: allocatedTasks?.TaskDescription,
                      data: allocatedTasks,
                      taskCode: allocatedTasks?.TaskCode,
                      section_id: parseInt(allocatedTasks?.ResourceAssigned, 10),
                      color: colorEvent,
                      listWorkshopTask: listWorkshopTask,
                    },
                  ])
                : scheduler.parse([
                    {
                      start_date: startDateTime,
                      end_date: endDateTime,
                      text: allocatedTasks?.TaskDescription,
                      data: allocatedTasks,
                      taskCode: allocatedTasks?.TaskCode,
                      section_id: parseInt(allocatedTasks?.ResourceAssigned, 10),
                      color: colorEvent,
                      listWorkshopTask: listWorkshopTask,
                    },
                  ]);
            });
            that.orderArray = data?.orderArray
              ?.filter((el) => el.ResourceAssigned !== '')
              .map((allocatedOrders, index) => {
                const startDateTime =
                  getformatedDate(allocatedOrders?.WorkshopStartDate) +
                  ' ' +
                  getformatedTime(allocatedOrders?.WorkshopStartTime < 1 ? '070000' : allocatedOrders?.WorkshopStartTime.toString());
                const endDateTime =
                  getformatedDate(allocatedOrders?.WorkshopEndDate) +
                  ' ' +
                  getformatedTime(allocatedOrders?.WorkshopEndTime < 1 ? '070000' : allocatedOrders?.WorkshopEndTime.toString());
                index % 2 === 0
                  ? scheduler.parse([
                      {
                        start_date: startDateTime,
                        end_date: endDateTime,
                        text:
                          getWorkDay(startDateTime, convertMM(scheduler.getState().date), endDateTime) +
                          allocatedOrders?.OrderNumber +
                          ' | ' +
                          allocatedOrders?.CarShortDesc +
                          ' | ' +
                          allocatedOrders?.StandardFitHours +
                          ' hrs' +
                          ' | ' +
                          allocatedOrders?.CustomerName,
                        data: allocatedOrders,
                        section_id: parseInt(allocatedOrders?.ResourceAssigned, 10),
                      },
                    ])
                  : scheduler.parse([
                      {
                        start_date: startDateTime,
                        end_date: endDateTime,
                        text:
                          getWorkDay(startDateTime, convertMM(scheduler.getState().date), endDateTime) +
                          ' | ' +
                          allocatedOrders?.OrderNumber +
                          ' | ' +
                          allocatedOrders?.CarShortDesc +
                          ' | ' +
                          allocatedOrders?.StandardFitHours +
                          ' hrs' +
                          ' | ' +
                          allocatedOrders?.CustomerName,
                        data: allocatedOrders,
                        section_id: parseInt(allocatedOrders?.ResourceAssigned, 10),
                      },
                    ]);
              });
            const unnallocatedOrders = data?.orderArray?.filter((el) => el.ResourceAssigned === '');
            const filteredSalesOrders = unnallocatedOrders?.filter((el) => el?.StatusGroup === 'SALES ORDER').sort();
            const filteredOrdersFloater = unnallocatedOrders?.filter((el) => el?.StatusGroup === 'FLOATER').sort();
            for (let i = 0; i < filteredSalesOrders.length; i++) {
              const minutes = filteredSalesOrders[i]?.StandardFitHours.toString()?.split('.');
              const totalInMinutes = Number(minutes[0]) * 60 + (minutes[1] ? Number(minutes[1]) * 0.1 * 60 : 0);
              const startTime = '07:00:00';
              const endTime = moment(startTime, 'HH:mm:ss').add(totalInMinutes, 'minutes').format('HH:mm');
              const startDateTime = getformatedDate(date) + ' 07:00'.toString();
              const endDateTime = (getformatedDate(date) + ' ' + endTime).toString();
              scheduler.parse([
                {
                  start_date: startDateTime,
                  end_date: endDateTime,
                  text:
                    filteredSalesOrders[i]?.OrderNumber +
                    ' | ' +
                    filteredSalesOrders[i]?.CarShortDesc +
                    ' | ' +
                    filteredSalesOrders[i]?.StandardFitHours +
                    ' | ' +
                    filteredSalesOrders[i]?.CustomerName,
                  data: filteredSalesOrders[i],
                  section_id: schedulerAppValue.unAllocatedSalesOrder_SectionId,
                },
              ]);
              // }
            }
            for (let i = 0; i < filteredOrdersFloater.length; i++) {
              const filteredOrderFitByOrder = filteredOrdersFloater?.filter((el) => el.OrderNumber === filteredOrdersFloater[i]?.OrderNumber);
              const minutes = filteredOrdersFloater[i]?.StandardFitHours.toString()?.split('.');
              const totalInMinutes = Number(minutes[0]) * 60 + (minutes[1] ? Number(minutes[1]) * 0.1 * 60 : 0);
              const startTime = '07:00:00';
              const endTime = moment(startTime, 'HH:mm:ss').add(totalInMinutes, 'minutes').format('HH:mm');
              const startDateTime = getformatedDate(date) + ' 07:00'.toString();
              const endDateTime = (getformatedDate(date) + ' ' + endTime).toString();
              scheduler.parse([
                {
                  start_date: startDateTime,
                  end_date: endDateTime,
                  text:
                    filteredOrdersFloater[i]?.OrderNumber +
                    ' | ' +
                    filteredOrdersFloater[i]?.CarShortDesc +
                    ' | ' +
                    filteredOrdersFloater[i]?.StandardFitHours +
                    ' | ' +
                    filteredOrdersFloater[i]?.CustomerName,
                  data: filteredOrdersFloater[i],
                  section_id: schedulerAppValue.unAllocatedFloater_SectionId,
                },
              ]);
              //}
            }
            scheduler.hideSpinner();
          });
      });

    //------------------------
    //Addding task to timeline
    //--------------
  }

  getSection(technician: any) {
    // technician.push({key: "0000", label: "All" })
    scheduler.updateCollection('technician', this.technician);
    const unallocatedOrderNormalLabel = this.unAllocatedOrders?.filter((el) => el?.data?.StatusGroup === 'SALES ORDER').length;
    const unallocatedOrderFitLabel = this.unAllocatedOrders?.filter((el) => el?.data?.StatusGroup === 'FLOATER').sort().length;
    let elements = [
      // original hierarhical array to display
      {
        key: schedulerAppValue.unAllocatedOrder_Master_SectionId,
        label: 'UNALLOCATED ORDERS ' + '(' + this.unAllocatedOrders.length + ')',
        open: true,
        children: [
          {
            key: 100000000000002,
            label: 'Sales Orders ' + '(' + unallocatedOrderNormalLabel + ')',
            children: [{ key: 1000000000000002, label: '', children: [] }],
          },
          {
            key: 100000000000003,
            label: 'Floaters ' + '(' + unallocatedOrderFitLabel + ')',
            children: [{ key: 1000000000000003, label: '', children: [] }],
          },
        ],
      },
      {
        key: schedulerAppValue.technician_SectionId,
        label: technician.length>0? '4X4 Technician': '(No Technician found)',
        open: true,
        children: technician,
      },
    ];

    return elements;
  }

  splitOrder() {
    this.eventService
      .splitOrder({
        orderNumber: this.currentEventData?.data?.OrderNumber,
        orderType: this.currentEventData?.data?.OrderType,
      })
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((data) => {
        if (data?.ErrorFlag === '0') {
          scheduler.clearAll();
          this.fetchOrder(convert(this.currentEventData?.start_date), this.listWorkshopTasks);
        }
      });
  }

  consolidateOrder() {
    this.eventService
      .consolidateOrder({
        orderNumber: this.currentEventData?.data?.OrderNumber,
        orderType: this.currentEventData?.data?.OrderType,
        groupName: this.currentEventData?.data?.groupName ?? '',
      })
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((data) => {
        if (data?.ErrorFlag === '0') {
          scheduler.clearAll();
          this.fetchOrder(convert(this.currentEventData?.start_date), this.listWorkshopTasks);
        }
      });
  }
  fetchOrderFromJDE() {
    this.customerStore.dispatch(
      new FetchJdeOrder({
        orderNumber: this.currentEventData?.data?.OrderNumber.toString(),
        orderType: this.currentEventData?.data?.OrderType,
      })
    );
  }
  removeContextMenu() {
    const el = document.querySelector('.context_menu');
    if (el) {
      el.innerHTML = null;
      el.remove();
    }
  }
}

/*********************** Table of content **************************************************/
/**
 * 1  Initialize plugins ------------(eg>. scheduler.plugins({timelinge:true, }))
 * 2  Config ------------------------(eg:  scheduler.config.details_on_create = true;)
 * 3  scheduler.locale.labels--------(eg:  scheduler.locale.labels['section_customer'] = 'Customer :'; )
 * 4  Custom Formatting Event Bar-----(eg: )
 * 5  TimeLineView
 * 6
 * 7
 * 8
 * 9
 * 10
 * 11
 * 12
 * 13
 * 14
 */
