import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { LandingMainComponent } from './components/landing-main/landing-main.component';
import { LandingComponent } from './containers/landing/landing.component';
import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromEpcStore from './+store';
import * as fromVehicle from './+store/vehicles';
import * as fromFilter from './+store/filter';
import * as fromCustomer from './+store/customers';
import {
  FILTER_CONFIG_TOKEN,
  FILTER_LOCAL_STORAGE_KEY,
  FILTER_STORAGE_KEYS,
  SELECTED_VEHICLE_CONFIG_TOKEN,
  SELECTED_VEHICLE_LOCAL_STORAGE_KEY,
  SELECTED_VEHICLE_STORAGE_KEYS,
  SHOW_UNIVERSAL_PRODUCTS_CONFIG_TOKEN,
  SHOW_UNIVERSAL_PRODUCTS_LOCAL_STORAGE_KEY,
  SHOW_UNIVERSAL_PRODUCTS_STORAGE_KEYS,
  USE_VEHICLE_CONFIG_TOKEN,
  USE_VEHICLE_LOCAL_STORAGE_KEY,
  USE_VEHICLE_STORAGE_KEYS,
} from './constants/app.tokens';
import { provideBootstrapEffects } from './shared/utils/utils';
import { SessionStorageService } from './shared/services/session-storage.service';
import { storageMetaReducer } from './shared/utils/storage-metareducer';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { OOEApiServiceProxy } from './services/ooe-api-service-proxy';
import { TopSearchComponent } from './components/top-search/top-search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HighlightTextPipe } from './shared/pipes/highlight-text.pipe';
import { PaginatorModule } from 'primeng/paginator';
import { TreeModule } from 'primeng/tree';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TableModule } from 'primeng/table';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PanelModule } from 'primeng/panel';
import { SpinnerModule } from 'primeng/spinner';
import { BusyIfDirective } from './shared/directives/busy-if.directive';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { VehicleSearchComponent } from './components/vehicle-search/vehicle-search.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { OrderStatusBarComponent } from './components/order-status-bar/order-status-bar.component';
import { VehicleUpdateModalComponent } from './components/order-status-bar/vehicle-update-modal/vehicle-update-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MatIconModule } from '@angular/material/icon';
import { EpcCustomersVehiclesDetailsNewComponent } from './components/order-status-bar/customers-vehicles-details-new/customers-vehicles-details-new.component';
import { EpcCustomersVehiclesDetailsFormComponent } from './components/order-status-bar/customers-vehicles-details-form/customers-vehicles-details-form.component';
import { NgxSummernoteModule } from 'ngx-summernote';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ProductFilterListComponent } from './components/product-filter-list/product-filter-list.component';
import { ArrayToTreeConverterService } from './shared/services/array-to-tree-converter.service';
import { TreeDataHelperService } from './shared/services/tree-data-helper.service';
import { RouterModule } from '@angular/router';
import { GalleryModule } from '@ngx-gallery/core';
import { LightboxModule } from '@ngx-gallery/lightbox';
import { ProductListComponent } from './containers/product-list/product-list.component';
import { PostcodeComponent } from './components/postcode/postcode.component';
import { ButtonBusyDirective } from './shared/directives/button-busy.directive';
import { ConfirmationService } from 'primeng/api';
import { ProductImageDirective } from './shared/directives/product-image.directive';
import { ProductComponent } from './containers/product/product.component';
import { ProductProductSummaryComponent } from './components/product-summary/product-summary.component';
import { ProductProductPricesStocksComponent } from './components/product-prices-stocks/product-prices-stocks.component';
import { ProductProductAddToOrderComponent } from './components/product-add-to-order/product-add-to-order.component';
import { ProductProductImagesComponent } from './components/product-images/product-images.component';
import { ProductProductAssociatedItemsComponent } from './components/product-associated-items/product-associated-items.component';
import { FilterPipe } from './shared/pipes/filter.pipe';
import { NgbCarouselModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { ProductProductDetailsComponent } from './components/product-details/product-details.component';
import { ProductProductApplicationsComponent } from './components/product-applications/product-applications.component';
import { ProductProductTechSpecsComponent } from './components/product-tech-specs/product-tech-specs.component';
import { ProductProductFittingInstructionsComponent } from './components/product-fitting-instructions/product-fitting-instructions.component';
import { ResizableDirective } from './shared/directives/resizable.directive';
import { VehiclesModule } from '../vehicles/vehicles.module';
import { CoreUiModule } from '@pos-app/core-ui';

export function getStorageConfig(
  saveKeys: string[],
  localStorageKey: string,
  storageService: SessionStorageService
) {
  return {
    metaReducers: [
      storageMetaReducer(saveKeys, localStorageKey, storageService),
    ],
  };
}

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    PaginatorModule,
    BreadcrumbModule,
    PanelModule,
    TableModule,
    TreeModule,
    ConfirmDialogModule,
    NgScrollbarModule,
    MatFormFieldModule,
    MatIconModule,
    MatChipsModule,
    NgSelectModule,
    ModalModule,
    SpinnerModule,
    NgbCarouselModule,
    NgbNavModule,
    NgxYoutubePlayerModule,
    GalleryModule.withConfig({}),
    LightboxModule.withConfig({}),
    NgxSummernoteModule,
    VehiclesModule,
    CoreUiModule,
    StoreModule.forFeature(
      fromVehicle.EPC_VEHICLE_FEATURE_KEY,
      fromVehicle.reducer,
      SELECTED_VEHICLE_CONFIG_TOKEN
    ),

    StoreModule.forFeature(
      fromFilter.FILTER_FEATURE_KEY,
      fromFilter.filterReducer,
      FILTER_CONFIG_TOKEN
    ),

    StoreModule.forFeature(
      fromCustomer.EPC_CUSTOMER_FEATURE_KEY,
      fromCustomer.customerReducer
    ),
  ],
  declarations: [
    LandingComponent,
    LandingMainComponent,
    TopSearchComponent,
    VehicleSearchComponent,
    OrderStatusBarComponent,
    VehicleUpdateModalComponent,
    EpcCustomersVehiclesDetailsNewComponent,
    EpcCustomersVehiclesDetailsFormComponent,
    ProductFilterListComponent,
    BreadcrumbComponent,
    ProductListComponent,
    PostcodeComponent,
    ProductComponent,
    ProductProductSummaryComponent,
    ProductProductPricesStocksComponent,
    ProductProductAddToOrderComponent,
    ProductProductImagesComponent,
    ProductProductAssociatedItemsComponent,
    ProductProductDetailsComponent,
    ProductProductApplicationsComponent,
    ProductProductTechSpecsComponent,
    ProductProductFittingInstructionsComponent,
    HighlightTextPipe,
    FilterPipe,
    BusyIfDirective,
    ButtonBusyDirective,
    ProductImageDirective,
    ResizableDirective
  ],
  bootstrap: [],
  providers: [
    {
      provide: SELECTED_VEHICLE_LOCAL_STORAGE_KEY,
      useValue: '__selected_vehicle_storage__',
    },
    { provide: SELECTED_VEHICLE_STORAGE_KEYS, useValue: ['VehicleSelected'] },
    {
      provide: SELECTED_VEHICLE_CONFIG_TOKEN,
      deps: [
        SELECTED_VEHICLE_STORAGE_KEYS,
        SELECTED_VEHICLE_LOCAL_STORAGE_KEY,
        SessionStorageService,
      ],
      useFactory: getStorageConfig,
    },
    {
      provide: USE_VEHICLE_LOCAL_STORAGE_KEY,
      useValue: '__use_selected_vehicle_storage__',
    },
    { provide: USE_VEHICLE_STORAGE_KEYS, useValue: ['UsedAsFilter'] },
    {
      provide: USE_VEHICLE_CONFIG_TOKEN,
      deps: [
        USE_VEHICLE_STORAGE_KEYS,
        USE_VEHICLE_LOCAL_STORAGE_KEY,
        SessionStorageService,
      ],
      useFactory: getStorageConfig,
    },

    {
      provide: SHOW_UNIVERSAL_PRODUCTS_LOCAL_STORAGE_KEY,
      useValue: '__show_universal_products_storage__',
    },
    {
      provide: SHOW_UNIVERSAL_PRODUCTS_STORAGE_KEYS,
      useValue: ['ShowedUniversalProducts'],
    },
    {
      provide: SHOW_UNIVERSAL_PRODUCTS_CONFIG_TOKEN,
      deps: [
        SHOW_UNIVERSAL_PRODUCTS_STORAGE_KEYS,
        SHOW_UNIVERSAL_PRODUCTS_LOCAL_STORAGE_KEY,
        SessionStorageService,
      ],
      useFactory: getStorageConfig,
    },

    { provide: FILTER_LOCAL_STORAGE_KEY, useValue: '__filter_storage__' },
    { provide: FILTER_STORAGE_KEYS, useValue: ['filter', 'isLoaded'] },
    {
      provide: FILTER_CONFIG_TOKEN,
      deps: [
        FILTER_STORAGE_KEYS,
        FILTER_LOCAL_STORAGE_KEY,
        SessionStorageService,
      ],
      useFactory: getStorageConfig,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill' },
    },
    { provide: APP_BASE_HREF, useValue: '/' },
    provideBootstrapEffects(fromEpcStore.MainEffects),
    OOEApiServiceProxy,
    ArrayToTreeConverterService,
    TreeDataHelperService,
    ConfirmationService
  ],
})
export class EpcModule {}
