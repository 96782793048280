import {
  ManageusersAction,
  ManageusersActionTypes,
} from './manageusers.actions';
import { ManagedUser, ManagedExternalUser } from './user.model';
import { ManagedUserFilterLists } from '@pos-app/data';

export const MANAGEUSERS_FEATURE_KEY = 'manageusers';

export interface ManageusersState {
  unregisteredUserList: ManagedUser[];
  registeredUserList: ManagedUser[];
  externalRegisteredUserList: ManagedExternalUser[];
  isCustomerRegistered: boolean;
  isLoadingUnregisteredUsers: boolean;
  isLoadingRegisteredUsers: boolean;
  isARBuAvailable: boolean;
  securityFunctionList: string[];
  isLoadingSecurityFunctionList: boolean;
  savingSecurityFunctions: boolean;
  lookupLists: ManagedUserFilterLists;
  isBusy: boolean;
  error?: any; // last none error (if any)
}

export interface ManageusersPartialState {
  readonly [MANAGEUSERS_FEATURE_KEY]: ManageusersState;
}

export const initialState: ManageusersState = {
  unregisteredUserList: [],
  registeredUserList: [],
  externalRegisteredUserList: [],
  isCustomerRegistered: false,
  securityFunctionList: [],
  isLoadingUnregisteredUsers: false,
  isARBuAvailable: false,
  isLoadingRegisteredUsers: false,
  isLoadingSecurityFunctionList: false,
  savingSecurityFunctions: false,
  lookupLists: null,
  isBusy: false,
};

export function reducer(
  state: ManageusersState = initialState,
  action: ManageusersAction
): ManageusersState {
  switch (action.type) {
    case ManageusersActionTypes.LOAD_INTERNAL_USERS: {
      const key = action.payload.fetchRegistered
        ? 'isLoadingRegisteredUsers'
        : 'isLoadingUnregisteredUsers';
      state = {
        ...state,
        [key]: true,
        registeredUserList: [],
      };
      break;
    }
    case ManageusersActionTypes.LOAD_INTERNAL_USERS_SUCCESS: {
      const listKey = state.isLoadingRegisteredUsers
        ? 'registeredUserList'
        : 'unregisteredUserList';

      const loadingKey = state.isLoadingRegisteredUsers
        ? 'isLoadingRegisteredUsers'
        : 'isLoadingUnregisteredUsers';
      state = {
        ...state,
        [listKey]: action.payload,
        [loadingKey]: false,
      };
      break;
    }
    case ManageusersActionTypes.LOAD_INTERNAL_USERS_FAILED: {
      const loadingKey = state.isLoadingRegisteredUsers
        ? 'isLoadingRegisteredUsers'
        : 'isLoadingUnregisteredUsers';
      state = {
        ...state,
        [loadingKey]: false,
      };
      break;
    }
    case ManageusersActionTypes.LOAD_REGISTERED_USERS: {
      state = {
        ...state,
        externalRegisteredUserList: [],
        isCustomerRegistered: false,
        isBusy: true,
      };
      break;
    }
    case ManageusersActionTypes.LOAD_REGISTERED_USERS_SUCCESS: {
      state = {
        ...state,
        externalRegisteredUserList: action.payload.SearchResults,
        isCustomerRegistered: action.payload.customerRegisteredYN === 'Y',
        isBusy: false,
      };
      break;
    }
    case ManageusersActionTypes.LOAD_REGISTERED_USERS_FAILED: {
      state = {
        ...state,
        isBusy: false,
      };
      break;
    }
    case ManageusersActionTypes.LOAD_SECURITY_FUNCTIONS: {
      state = {
        ...state,
        isLoadingSecurityFunctionList: true,
      };
      break;
    }
    case ManageusersActionTypes.LOAD_SECURITY_FUNCTIONS_SUCCESS: {
      state = {
        ...state,
        securityFunctionList: action.payload,
        isLoadingSecurityFunctionList: false,
      };
      break;
    }
    case ManageusersActionTypes.LOAD_SECURITY_FUNCTIONS_FAILED: {
      state = {
        ...state,
        isLoadingSecurityFunctionList: false,
      };
      break;
    }
    case ManageusersActionTypes.LOAD_LOOKUP_LIST: {
      state = {
        ...state,
        isBusy: true,
      };
      break;
    }
    case ManageusersActionTypes.LOAD_LOOKUP_LIST_SUCCESS: {
      state = {
        ...state,
        lookupLists: action.payload,
        isBusy: false,
      };
      break;
    }
    case ManageusersActionTypes.LOAD_LOOKUP_LIST_FAILED: {
      state = {
        ...state,
        isBusy: false,
      };
      break;
    }
    case ManageusersActionTypes.UPDATE_ACCOUNT_CUSTOMER_SETTINGS: {
      state = {
        ...state,
        isBusy: true,
      };
      break;
    }
    case ManageusersActionTypes.UPDATE_ACCOUNT_CUSTOMER_SETTINGS_SUCCESS: {
      state = {
        ...state,
        isARBuAvailable: action.ARBuAvailableYN === 'Y' ? true : false,
        // customser is registered once the account is updated
        isCustomerRegistered: true,
        isBusy: false,
      };
      break;
    }
    case ManageusersActionTypes.UPDATE_ACCOUNT_CUSTOMER_SETTINGS_FAILED: {
      state = {
        ...state,
        isBusy: false,
      };
      break;
    }
    case ManageusersActionTypes.EDIT_USER_SECURITY_FUNCTIONS: {
      state = {
        ...state,
        savingSecurityFunctions: true,
      };
      break;
    }
    case ManageusersActionTypes.EDIT_USER_SECURITY_FUNCTIONS_SUCCESS: {
      state = {
        ...state,
        savingSecurityFunctions: false,
      };
      break;
    }
    case ManageusersActionTypes.EDIT_USER_SECURITY_FUNCTIONS_FAILED: {
      state = {
        ...state,
        savingSecurityFunctions: false,
      };
      break;
    }
    case ManageusersActionTypes.RESET_LIST: {
      state = {
        ...state,
        unregisteredUserList: [],
      };
      break;
    }
    case ManageusersActionTypes.RESET_REGISTERED_LIST: {
      state = {
        ...state,
        externalRegisteredUserList: [],
        registeredUserList: [],
      };
      break;
    }
  }
  return state;
}
