import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import {
  ManageusersPartialState,
  MANAGEUSERS_FEATURE_KEY,
} from './manageusers.reducer';
import {
  ManageusersActionTypes,
  LoadInternalUsers,
  LoadInternalUsersSuccess,
  LoadInternalUsersFailed,
  LoadSecurityFunctions,
  LoadSecurityFunctionsSuccess,
  LoadSecurityFunctionsFailed,
  EditUserSecurityFunctions,
  EditUserSecurityFunctionsFailed,
  EditUserSecurityFunctionsSuccess,
  LoadRegisteredUsers,
  LoadRegisteredUsersSuccess,
  LoadRegisteredUsersFailed,
  EditExternalUserSecurityFunctions,
  EditExternalUserSecurityFunctionsSuccess,
  EditExternalUserSecurityFunctionsFailed,
  EditEmployeeProfile,
  EditEmployeeProfileFailed,
  EditEmployeeProfileSuccess,
  LoadLookupListsSuccess,
  LoadLookupListsFailed,
  UpdateAccountCustomerSettings,
  UpdateAccountCustomerSettingsSuccess,
  UpdateAccountCustomerSettingsFailed,
} from './manageusers.actions';
import {
  ApiService,
  hasUnsavedChanges,
  MessageService,
} from '@pos-app/core-ui';
import { map, mergeMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { LoadLookupLists } from '../../customers/+state/customers.actions';

@Injectable()
export class ManageusersEffects {
  @Effect() loadInternalUsers$ = this.dataPersistence.fetch(
    ManageusersActionTypes.LOAD_INTERNAL_USERS,
    {
      run: (action: LoadInternalUsers, state: ManageusersPartialState) => {
        const payload = {
          userFilter: action.payload.userFilter,
          fetchRegistered: action.payload.fetchRegistered,
        };
        const fetchUsers = action.payload.isManageProfile
          ? this.apiService.fetchSingleJDE(payload)
          : this.apiService.fetchJDEUsers(payload);
        return fetchUsers.pipe(
          map((result) => new LoadInternalUsersSuccess(result.SearchResults))
        );
      },

      onError: (action: LoadInternalUsers, error) => {
        return new LoadInternalUsersFailed(error);
      },
    }
  );

  @Effect() loadRegisteredUsers$ = this.dataPersistence.fetch(
    ManageusersActionTypes.LOAD_REGISTERED_USERS,
    {
      run: (action: LoadRegisteredUsers, state: ManageusersPartialState) => {
        return this.apiService
          .fetchRegisteredUsers(action.payload)
          .pipe(map((result) => new LoadRegisteredUsersSuccess(result)));
      },

      onError: (action: LoadRegisteredUsers, error) => {
        return new LoadRegisteredUsersFailed(error);
      },
    }
  );

  @Effect() loadSecurityFunctions$ = this.dataPersistence.fetch(
    ManageusersActionTypes.LOAD_SECURITY_FUNCTIONS,
    {
      run: (action: LoadSecurityFunctions, state: ManageusersPartialState) => {
        return this.apiService
          .fetchSecurityFunctions(action.payload)
          .pipe(
            map(
              (result) =>
                new LoadSecurityFunctionsSuccess(
                  result.SearchResults.map((x) => x.SecurityFunction)
                )
            )
          );
      },

      onError: (action: LoadSecurityFunctions, error) => {
        return new LoadSecurityFunctionsFailed(error);
      },
    }
  );

  @Effect() editUserSecurityFunctions$ = this.dataPersistence.fetch(
    ManageusersActionTypes.EDIT_USER_SECURITY_FUNCTIONS,
    {
      run: (
        action: EditUserSecurityFunctions,
        state: ManageusersPartialState
      ) => {
        return this.apiService.setJDEUserSecurity(action.payload).pipe(
          mergeMap((result) => {
            this.toastr.success('User security function saved successfully');
            return [
              new EditUserSecurityFunctionsSuccess(result),
              new LoadInternalUsers({
                userFilter: action.payload.userName,
                fetchRegistered: true,
              }),
            ];
          })
        );
      },

      onError: (action: EditUserSecurityFunctions, error) => {
        this.toastr.error(error.ErrorMessage);
        return new EditUserSecurityFunctionsFailed(error);
      },
    }
  );

  @Effect() editExternalUserSecurityFunctions$ = this.dataPersistence.fetch(
    ManageusersActionTypes.EDIT_EXTERNAL_USER_SECURITY_FUNCTIONS,
    {
      run: (
        action: EditExternalUserSecurityFunctions,
        state: ManageusersPartialState
      ) => {
        return this.apiService.setUserSecurity(action.payload).pipe(
          mergeMap((result) => {
            action.payload.actionCode === 'A'
              ? this.toastr.success('User registered successfully')
              : this.toastr.success(
                  'User security functions saved successfully'
                );
            this.messageService.dispatchAction(
              hasUnsavedChanges({ unsavedChanges: false })
            );
            return [
              hasUnsavedChanges({ unsavedChanges: false }),
              new EditExternalUserSecurityFunctionsSuccess(result),
              new LoadRegisteredUsers({
                CustomerABNumber: action.payload.parentCustomer,
              }),
            ];
          })
        );
      },

      onError: (action: EditExternalUserSecurityFunctions, error) => {
        this.toastr.error(error.ErrorMessage);
        return new EditExternalUserSecurityFunctionsFailed(error);
      },
    }
  );

  @Effect() editEmployeeProfile$ = this.dataPersistence.fetch(
    ManageusersActionTypes.EDIT_EMPLOYEE_PROFILE,
    {
      run: (action: EditEmployeeProfile, state: ManageusersPartialState) => {
        return this.apiService.setJDEUserSecurity(action.payload).pipe(
          mergeMap((result) => {
            this.toastr.success('Employee profile updated successfully');
            return [
              new EditEmployeeProfileSuccess(result),
              new LoadInternalUsers({
                userFilter: action.payload.userName,
                fetchRegistered: true,
                isManageProfile: true,
              }),
            ];
          })
        );
      },

      onError: (action: EditEmployeeProfile, error) => {
        this.toastr.error(error.ErrorMessage);
        return new EditEmployeeProfileFailed(error);
      },
    }
  );

  @Effect() loadLookupLists$ = this.dataPersistence.fetch(
    ManageusersActionTypes.LOAD_LOOKUP_LIST,
    {
      run: (action: LoadLookupLists, state: ManageusersPartialState) => {
        return this.apiService.getManagedUserFilterLists().pipe(
          map(
            ([
              branchListResult,
              orderTypeListResult,
              inventoryViewListResult,
            ]) => {
              const result = {
                branchList: branchListResult.SearchResults,
                orderTypeList: orderTypeListResult.SearchResults,
                inventoryViewList: inventoryViewListResult.SearchResults,
              };
              return new LoadLookupListsSuccess(result);
            }
          )
        );
      },
      onError: (action: LoadLookupLists, error) => {
        return new LoadLookupListsFailed(error);
      },
    }
  );

  @Effect() updateAccountCustomerSettings$ = this.dataPersistence.fetch(
    ManageusersActionTypes.UPDATE_ACCOUNT_CUSTOMER_SETTINGS,
    {
      run: (
        action: UpdateAccountCustomerSettings,
        state: ManageusersPartialState
      ) => {
        return this.apiService
          .updateAccountCustomerSettings(action.payload)
          .pipe(
            map((result) => {
              this.toastr.success('Customer settings updated');
              return new UpdateAccountCustomerSettingsSuccess(
                result,
                action.payload.ARBuAvailableYN
              );
            })
          );
      },

      onError: (action: UpdateAccountCustomerSettings, error) => {
        return new UpdateAccountCustomerSettingsFailed(error);
      },
    }
  );

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<ManageusersPartialState>,
    private apiService: ApiService,
    private toastr: ToastrService,
    private messageService: MessageService
  ) {}
}
