<pos-app-spinner [show]="(loadingCustomerDetails$ | async) || (loadingFetchingOrderFromJDE$ | async)"></pos-app-spinner>

<div
  class="container-fluid max1200"
  *ngIf="{
    isExternalUser: isExternalUser$ | async,
    brandDefaults: brandDefaults$ | async
  } as data"
>
  <ng-container *ngIf="!isShowStockAvailability">
    <hr />

    <div *ngIf="!data.isExternalUser || selectedCustomer">
      <app-customers-nav
        [activeOrder]="activeOrder$ | async"
        [selectedCustomer]="selectedCustomer"
        [isExternalUser]="data.isExternalUser"
        [securityFunctions]="securityFunctions"
      ></app-customers-nav>
      <hr />
    </div>

    <div *ngIf="!selectedCustomer && !newCustomer.isActive">
      <ng-container *ngIf="data.brandDefaults?.showARBGiftCardSearchTF">
        <label>ARB GIFT CARD BALANCE:</label>
        <div class="row py-2 pb-4">
          <div class="col-4">
            <input
              class="form-control"
              placeholder="Key or scan in gift card number"
              [(ngModel)]="giftCardNumber"
              (ngModelChange)="!giftCardNumber ? (giftCardBalance = undefined) : true"
            />
          </div>
          <div *ngIf="giftCardBalance != undefined && !giftCardErrorMessage" class="col-2">
            <input readonly class="form-control" value="${{ giftCardBalance ? (giftCardBalance | number : '1.2-2') : 0 }}" />
          </div>
          <div *ngIf="giftCardErrorMessage" class="col-2">
            <input readonly class="form-control" value="{{ giftCardErrorMessage }}" />
          </div>
          <div class="col d-flex justify-content-end">
            <button class="navlink btn" (click)="fetchCardBalance()" [disabled]="!giftCardNumber">
              FETCH BALANCE
              <pos-app-spinner2 *ngIf="isFetching"></pos-app-spinner2>
            </button>
          </div>
        </div>
        <hr />
      </ng-container>
      <ng-container *ngIf="data.brandDefaults?.showCamperTrailerTF">
        <app-customers-trailer-search></app-customers-trailer-search>
        <hr />
      </ng-container>
    </div>

    <div *ngIf="selectedCustomer" class="card shadow-sm">
      <div class="card-body p-4">
        <app-customer-details-view
          [customer]="selectedCustomer"
          [sales]="customerSales$ | async"
          [isExternal]="data.isExternalUser"
          [selectedCustomer]="selectedCustomer"
        ></app-customer-details-view>
      </div>
    </div>

    <div class="pt-4" *ngIf="selectedCustomer || newCustomer.isActive">
      <router-outlet></router-outlet>
    </div>
  </ng-container>
  <div *ngIf="!selectedCustomer && !newCustomer.isActive && !(loadingCustomerDetails$ | async)" class="pb-3">
    <app-stockavailability (showStockAvailability)="isShowStockAvailability = $event" [isExternalUser]="data.isExternalUser"></app-stockavailability>
  </div>
</div>

<a routerLink="/pos/customers/new" routerLinkActive #newCustomer="routerLinkActive"> </a>
