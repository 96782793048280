<pos-app-spinner [show]="loading$ | async"></pos-app-spinner>
<h4>SHOW SALES</h4>
<div class="row py-4">
  <div class="col-2">
    <label>SELECT SHOW</label>
  </div>
  <div class="col-6">
    <ng-select (change)="getShowSales($event)"
      ><ng-option
        *ngFor="let show of customerShowList$ | async"
        [value]="show.showCode"
        >{{ show.showDescription }} ({{ show.startDate }} - {{ show.endDate }})
      </ng-option></ng-select
    >
  </div>
</div>
<div class="pb-5" *ngIf="customerShowSales$ | async as customerShowSales">
  <ag-grid-angular
    #agGrid
    class="ag-theme-balham datagrid"
    [rowData]="customerShowSales"
    [gridOptions]="gridOptions"
    (gridReady)="onGridReady($event)"
    [domLayout]="'autoHeight'"
    [quickFilterText]="searchValue"
    [pagination]="true"
    [paginationPageSize]="10"
  >
  </ag-grid-angular>
</div>
