import { Action } from '@ngrx/store';
import { AlertMessageToUpdate } from '@pos-app/data';

export enum CustomersActionTypes {
  SELECT_CUSTOMER = '[Customers] SELECT_CUSTOMER',
  SELECT_CUSTOMER_SUCCESS = '[Customers] SELECT_CUSTOMER_SUCCESS',
  SELECT_CUSTOMER_FAILED = '[Customers] SELECT_CUSTOMER_FAILED',
  SET_SELECTED_CUSTOMER = '[Customers] Set Selected Customer',

  GET_CUSTOMER_SALES = '[Customers] Get customer sales',
  GET_CUSTOMER_SALES_SUCCESS = '[Customers] Get customer sales success',
  GET_CUSTOMER_SALES_FAILED = '[Customers] Get customer sales failed',

  LOAD_LOOKUP_LISTS = '[Customers] Load lookup lists',
  LOAD_LOOKUP_LISTS_SUCCESS = '[Customers] Load lookup lists success',
  LOAD_LOOKUP_LISTS_FAILED = '[Customers] Load lookup lists failed',

  CREATE_RETAIL_CUSTOMER = '[Customers] Create retail customer',
  CREATE_RETAIL_CUSTOMER_SUCCESS = '[Customers] Create retail customer success',
  CREATE_RETAIL_CUSTOMER_FAILED = '[Customers] Create retail customer failed',

  UPDATE_RETAIL_CUSTOMER = '[Customers] Update retail customer',
  UPDATE_RETAIL_CUSTOMER_SUCCESS = '[Customers] Update retail customer success',
  UPDATE_RETAIL_CUSTOMER_FAILED = '[Customers] Update retail customer failed',

  CREATE_SHIPTO_CUSTOMER = '[Customers] Create ship to customer',
  CREATE_SHIPTO_CUSTOMER_SUCCESS = '[Customers] Create ship to customer success',
  CREATE_SHIPTO_CUSTOMER_FAILED = '[Customers] Create ship to customer failed',

  UPDATE_SHIPTO_CUSTOMER = '[Customers] Update shipto customer',
  UPDATE_SHIPTO_CUSTOMER_SUCCESS = '[Customers] Update shipto customer success',
  UPDATE_SHIPTO_CUSTOMER_FAILED = '[Customers] Update shipto customer failed',

  LOAD_CUSTOMER_VEHICLES = '[Customers] Load customer vehicles',
  LOAD_CUSTOMER_VEHICLES_SUCCESS = '[Customers] Load customer vehicles success',
  LOAD_CUSTOMER_VEHICLES_FAILED = '[Customers] Load customer vehicles failed',

  MAINTAIN_CUSTOMER_VEHICLE = '[Customers] Maintain a customer vehicle',
  MAINTAIN_CUSTOMER_VEHICLE_SUCCESS = '[Customers] Maintain a customer vehicle success',
  MAINTAIN_CUSTOMER_VEHICLE_FAILED = '[Customers] Maintain a customer vehicle failed',

  ADD_CUSTOMER_VEHICLE = '[Customers] Add customer vehicle',
  ADD_CUSTOMER_VEHICLE_SUCCESS = '[Customers] Add customer vehicle success',
  ADD_CUSTOMER_VEHICLE_FAILED = '[Customers] Add customer vehicle',

  MAKE_VEHICLE_DEFAULT = '[Customers] Make vehicle default',
  MAKE_VEHICLE_DEFAULT_SUCCESS = '[Customers] Make vehicle default success',
  MAKE_VEHICLE_DEFAULT_FAILED = '[Customers] Make vehicle default failed',

  CHECK_DUPLICATE_CUSTOMER = '[Customers] Check duplicate customer',
  CHECK_DUPLICATE_CUSTOMER_SUCCESS = '[Customers] Check duplicate customer success',
  CHECK_DUPLICATE_CUSTOMER_FAILED = '[Customers] Check duplicate customer failed',

  GET_STATEMENT = '[Customers] Get statement list',
  GET_STATEMENT_SUCCESS = '[Customers] Get statement list successs',
  GET_STATEMENT_FAILED = '[Customers] Get statement list failed',

  GET_INVOICE = '[Customers] Get invoice list',
  GET_INVOICE_SUCCESS = '[Customers] Get invoice list successs',
  GET_INVOICE_FAILED = '[Customers] Get invoice list failed',

  GET_SHOW_SALES = '[Customers] Get show sales',
  GET_SHOW_SALES_SUCCESS = '[Customers] Get show sales successs',
  GET_SHOW_SALES_FAILED = '[Customers] Get show sales failed',

  SELECT_CUSTOMER_VEHICLE = '[Customers] Select customer vehicle',

  CLEAR_DATA = '[Customers] Clear data',

  SELECT_COUNTRY = '[Customers] Select country ',

  LIST_TASKS_NOTES = '[Customers] List tasks notes',
  LIST_TASKS_NOTES_SUCCESS = '[Customers] List tasks notes successs',
  LIST_TASKS_NOTES_FAILED = '[Customers] List tasks notes failed',

  UPDATE_ALERT_MESSAGE = '[Customers] UPDATE alert message',
  UPDATE_ALERT_MESSAGE_SUCCESS = '[Customers] UPDATE alert message successs',
  UPDATE_ALERT_MESSAGE_FAILED = '[Customers] UPDATE alert message failed',

  GET_ALERT_MESSAGE = '[Customers] GET alert message',
  GET_ALERT_MESSAGE_SUCCESS = '[Customers] GET alert message successs',
  GET_ALERT_MESSAGE_FAILED = '[Customers] GET alert message failed',

  UPDATE_NOTES_TASKS = '[Customers] UPDATE notes tasks',
  UPDATE_NOTES_TASKS_SUCCESS = '[Customers] UPDATE notes tasks successs',
  UPDATE_NOTES_TASKS_FAILED = '[Customers] UPDATE notes tasks failed',

  COMPLETE_TASKS = '[Customers] COMPLETE tasks',
  COMPLETE_TASKS_SUCCESS = '[Customers] COMPLETE tasks successs',
  COMPLETE_TASKS_FAILED = '[Customers] COMPLETE tasks failed',

  GET_OVERDUE_TASK_ALERTS = '[Customers] GET Overdue tasks alert',
  GET_OVERDUE_TASK_ALERTS_SUCCESS = '[Customers] GET Overdue tasks alert successs',
  GET_OVERDUE_TASK_ALERTS_FAILED = '[Customers] GET Overdue tasks alert failed',

  GET_USER_STATE = '[Customers] Get user state',
  GET_USER_STATE_SUCCESS = '[Customers] Get user state success',
  GET_USER_STATE_FAILED = '[Customers] Get user state failed',
}

export class SelectCountry implements Action {
  readonly type = CustomersActionTypes.SELECT_COUNTRY;
  constructor(public payload: string) {}
}

export class ClearData implements Action {
  readonly type = CustomersActionTypes.CLEAR_DATA;
  constructor() {}
}

export class CheckDuplicateCustomer implements Action {
  readonly type = CustomersActionTypes.CHECK_DUPLICATE_CUSTOMER;
  constructor(public payload: any) {}
}

export class CheckDuplicateCustomerSuccess implements Action {
  readonly type = CustomersActionTypes.CHECK_DUPLICATE_CUSTOMER_SUCCESS;
  constructor(public payload: any) {}
}

export class CheckDuplicateCustomerFailed implements Action {
  readonly type = CustomersActionTypes.CHECK_DUPLICATE_CUSTOMER_FAILED;
  constructor(public payload: any) {}
}

export class MakeVehicleDefault implements Action {
  readonly type = CustomersActionTypes.MAKE_VEHICLE_DEFAULT;
  constructor(public payload: any) {}
}

export class MakeVehicleDefaultSuccess implements Action {
  readonly type = CustomersActionTypes.MAKE_VEHICLE_DEFAULT_SUCCESS;
  constructor(public payload: any) {}
}

export class MakeVehicleDefaultFailed implements Action {
  readonly type = CustomersActionTypes.MAKE_VEHICLE_DEFAULT_FAILED;
  constructor(public payload: any) {}
}

export class SelectCustomerVehicle implements Action {
  readonly type = CustomersActionTypes.SELECT_CUSTOMER_VEHICLE;
  constructor(public payload: string) {}
}

export class MaintainCustomerVehicle implements Action {
  readonly type = CustomersActionTypes.MAINTAIN_CUSTOMER_VEHICLE;
  constructor(public payload: any) {}
}

export class MaintainCustomerVehicleSuccess implements Action {
  readonly type = CustomersActionTypes.MAINTAIN_CUSTOMER_VEHICLE_SUCCESS;
  constructor(public payload: any) {}
}

export class MaintainCustomerVehicleFailed implements Action {
  readonly type = CustomersActionTypes.MAINTAIN_CUSTOMER_VEHICLE_FAILED;
  constructor(public payload: any) {}
}
export class LoadCustomerVehicles implements Action {
  readonly type = CustomersActionTypes.LOAD_CUSTOMER_VEHICLES;
  constructor(public payload: any) {}
}

export class LoadCustomerVehiclesSuccess implements Action {
  readonly type = CustomersActionTypes.LOAD_CUSTOMER_VEHICLES_SUCCESS;
  constructor(public payload: any) {}
}

export class LoadCustomerVehiclesFailed implements Action {
  readonly type = CustomersActionTypes.LOAD_CUSTOMER_VEHICLES_FAILED;
  constructor(public payload: any) {}
}

export class UpdateShipToCustomer implements Action {
  readonly type = CustomersActionTypes.UPDATE_SHIPTO_CUSTOMER;
  constructor(public payload: any) {}
}

export class UpdateShipToCustomerSuccess implements Action {
  readonly type = CustomersActionTypes.UPDATE_SHIPTO_CUSTOMER_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateShipToCustomerFailed implements Action {
  readonly type = CustomersActionTypes.UPDATE_SHIPTO_CUSTOMER_FAILED;
  constructor(public payload: any) {}
}

export class CreateShipToCustomer implements Action {
  readonly type = CustomersActionTypes.CREATE_SHIPTO_CUSTOMER;
  constructor(public payload: any) {}
}

export class CreateShipToCustomerSuccess implements Action {
  readonly type = CustomersActionTypes.CREATE_SHIPTO_CUSTOMER_SUCCESS;
  constructor(public payload: any) {}
}

export class CreateShipToCustomerFailed implements Action {
  readonly type = CustomersActionTypes.CREATE_SHIPTO_CUSTOMER_FAILED;
  constructor(public payload: any) {}
}

export class UpdateRetailCustomer implements Action {
  readonly type = CustomersActionTypes.UPDATE_RETAIL_CUSTOMER;
  constructor(public payload: any) {}
}

export class UpdateRetailCustomerSuccess implements Action {
  readonly type = CustomersActionTypes.UPDATE_RETAIL_CUSTOMER_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateRetailCustomerFailed implements Action {
  readonly type = CustomersActionTypes.UPDATE_RETAIL_CUSTOMER_FAILED;
  constructor(public payload: any) {}
}

export class CreateRetailCustomer implements Action {
  readonly type = CustomersActionTypes.CREATE_RETAIL_CUSTOMER;
  constructor(public payload: any) {}
}

export class CreateRetailCustomerSuccess implements Action {
  readonly type = CustomersActionTypes.CREATE_RETAIL_CUSTOMER_SUCCESS;
  constructor(public payload: any) {}
}

export class CreateRetailCustomerFailed implements Action {
  readonly type = CustomersActionTypes.CREATE_RETAIL_CUSTOMER_FAILED;
  constructor(public payload: any) {}
}

export class LoadLookupLists implements Action {
  readonly type = CustomersActionTypes.LOAD_LOOKUP_LISTS;
  constructor() {}
}

export class LoadLookupListsSuccess implements Action {
  readonly type = CustomersActionTypes.LOAD_LOOKUP_LISTS_SUCCESS;
  constructor(public payload: any) {}
}

export class LoadLookupListsFailed implements Action {
  readonly type = CustomersActionTypes.LOAD_LOOKUP_LISTS_FAILED;
  constructor(public payload: any) {}
}

export class SetSelectedCustomer implements Action {
  readonly type = CustomersActionTypes.SET_SELECTED_CUSTOMER;
  constructor(public payload: any) {}
}

export class SelectCustomerAction implements Action {
  readonly type = CustomersActionTypes.SELECT_CUSTOMER;
  constructor(public payload: string, public reloadCustomer: boolean = false) {}
}

export class SelectCustomerSuccessAction implements Action {
  readonly type = CustomersActionTypes.SELECT_CUSTOMER_SUCCESS;
  constructor(public payload: any) {}
}

export class SelectCustomerFailureAction implements Action {
  readonly type = CustomersActionTypes.SELECT_CUSTOMER_FAILED;
  constructor(public payload: any) {}
}

export class GetCustomerSalesAction implements Action {
  readonly type = CustomersActionTypes.GET_CUSTOMER_SALES;
  constructor(public payload: any) {}
}

export class GetCustomerSalesSuccessAction implements Action {
  readonly type = CustomersActionTypes.GET_CUSTOMER_SALES_SUCCESS;
  constructor(public payload: any) {}
}

export class GetCustomerSalesFailureAction implements Action {
  readonly type = CustomersActionTypes.GET_CUSTOMER_SALES_FAILED;
  constructor(public payload: any) {}
}
export class GetStatementListAction implements Action {
  readonly type = CustomersActionTypes.GET_STATEMENT;
  constructor() {}
}

export class GetStatementListSuccessAction implements Action {
  readonly type = CustomersActionTypes.GET_STATEMENT_SUCCESS;
  constructor(public payload: any) {}
}

export class GetStatementListFailureAction implements Action {
  readonly type = CustomersActionTypes.GET_STATEMENT_FAILED;
  constructor(public payload: any) {}
}

export class GetInvoiceListAction implements Action {
  readonly type = CustomersActionTypes.GET_INVOICE;
  constructor() {}
}

export class GetInvoiceListSuccessAction implements Action {
  readonly type = CustomersActionTypes.GET_INVOICE_SUCCESS;
  constructor(public payload: any) {}
}

export class GetInvoiceListFailureAction implements Action {
  readonly type = CustomersActionTypes.GET_INVOICE_FAILED;
  constructor(public payload: any) {}
}

export class GetShowSalesAction implements Action {
  readonly type = CustomersActionTypes.GET_SHOW_SALES;
  constructor(public payload: any) {}
}

export class GetShowSalesSuccessAction implements Action {
  readonly type = CustomersActionTypes.GET_SHOW_SALES_SUCCESS;
  constructor(public payload: any) {}
}

export class GetShowSalesFailureAction implements Action {
  readonly type = CustomersActionTypes.GET_SHOW_SALES_FAILED;
  constructor(public payload: any) {}
}

export class ListTasksNotesAction implements Action {
  readonly type = CustomersActionTypes.LIST_TASKS_NOTES;
  constructor(public payload: any) {}
}

export class ListTasksNotesSuccessAction implements Action {
  readonly type = CustomersActionTypes.LIST_TASKS_NOTES_SUCCESS;
  constructor(public payload: any) {}
}

export class ListTasksNotesFailureAction implements Action {
  readonly type = CustomersActionTypes.LIST_TASKS_NOTES_FAILED;
  constructor(public payload: any) {}
}

export class UpdateAlertMessageAction implements Action {
  readonly type = CustomersActionTypes.UPDATE_ALERT_MESSAGE;
  constructor(public payload: AlertMessageToUpdate) {}
}

export class UpdateAlertMessageSuccessAction implements Action {
  readonly type = CustomersActionTypes.UPDATE_ALERT_MESSAGE_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateAlertMessageFailureAction implements Action {
  readonly type = CustomersActionTypes.UPDATE_ALERT_MESSAGE_FAILED;
  constructor(public payload: any) {}
}

export class GetAlertMessageAction implements Action {
  readonly type = CustomersActionTypes.GET_ALERT_MESSAGE;
  constructor(public payload: any) {}
}

export class GetAlertMessageSuccessAction implements Action {
  readonly type = CustomersActionTypes.GET_ALERT_MESSAGE_SUCCESS;
  constructor(public payload: any) {}
}

export class GetAlertMessageFailureAction implements Action {
  readonly type = CustomersActionTypes.GET_ALERT_MESSAGE_FAILED;
  constructor(public payload: any) {}
}

export class UpdateNotesTasksAction implements Action {
  readonly type = CustomersActionTypes.UPDATE_NOTES_TASKS;
  constructor(public payload: any) {}
}

export class UpdateNotesTasksSuccessAction implements Action {
  readonly type = CustomersActionTypes.UPDATE_NOTES_TASKS_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateNotesTasksFailureAction implements Action {
  readonly type = CustomersActionTypes.UPDATE_NOTES_TASKS_FAILED;
  constructor(public payload: any) {}
}

export class CompleteTasksAction implements Action {
  readonly type = CustomersActionTypes.COMPLETE_TASKS;
  constructor(public payload: any) {}
}

export class CompleteTasksSuccessAction implements Action {
  readonly type = CustomersActionTypes.COMPLETE_TASKS_SUCCESS;
  constructor(public payload: any) {}
}

export class CompleteTasksFailureAction implements Action {
  readonly type = CustomersActionTypes.COMPLETE_TASKS_FAILED;
  constructor(public payload: any) {}
}

export class GetUserStateAction implements Action {
  readonly type = CustomersActionTypes.GET_USER_STATE;
  constructor() {}
}

export class GetUserStateSuccessAction implements Action {
  readonly type = CustomersActionTypes.GET_USER_STATE_SUCCESS;
  constructor(public payload: any) {}
}

export class GetUserStateFailureAction implements Action {
  readonly type = CustomersActionTypes.GET_USER_STATE_FAILED;
  constructor(public payload: any) {}
}

export type CustomersAction =
  | SelectCountry
  | ClearData
  | SelectCustomerVehicle
  | CheckDuplicateCustomer
  | CheckDuplicateCustomerSuccess
  | CheckDuplicateCustomerFailed
  | MakeVehicleDefault
  | MakeVehicleDefaultSuccess
  | MakeVehicleDefaultFailed
  | MaintainCustomerVehicle
  | MaintainCustomerVehicleSuccess
  | MaintainCustomerVehicleFailed
  | LoadCustomerVehicles
  | LoadCustomerVehiclesSuccess
  | LoadCustomerVehiclesFailed
  | CreateShipToCustomer
  | CreateShipToCustomerSuccess
  | CreateShipToCustomerFailed
  | UpdateShipToCustomer
  | UpdateShipToCustomerSuccess
  | UpdateShipToCustomerFailed
  | CreateRetailCustomer
  | CreateRetailCustomerSuccess
  | CreateRetailCustomerFailed
  | UpdateRetailCustomer
  | UpdateRetailCustomerSuccess
  | UpdateRetailCustomerFailed
  | LoadLookupLists
  | LoadLookupListsSuccess
  | LoadLookupListsFailed
  | SelectCustomerAction
  | SelectCustomerSuccessAction
  | SelectCustomerFailureAction
  | SetSelectedCustomer
  | GetCustomerSalesAction
  | GetCustomerSalesSuccessAction
  | GetCustomerSalesFailureAction
  | GetStatementListAction
  | GetStatementListSuccessAction
  | GetStatementListFailureAction
  | GetInvoiceListAction
  | GetInvoiceListSuccessAction
  | GetInvoiceListFailureAction
  | GetShowSalesAction
  | GetShowSalesSuccessAction
  | GetShowSalesFailureAction
  | ListTasksNotesAction
  | ListTasksNotesSuccessAction
  | ListTasksNotesFailureAction
  | UpdateAlertMessageAction
  | UpdateAlertMessageSuccessAction
  | UpdateAlertMessageFailureAction
  | GetAlertMessageAction
  | GetAlertMessageSuccessAction
  | GetAlertMessageFailureAction
  | UpdateNotesTasksAction
  | UpdateNotesTasksSuccessAction
  | UpdateNotesTasksFailureAction
  | CompleteTasksAction
  | CompleteTasksSuccessAction
  | CompleteTasksFailureAction
  | GetUserStateAction
  | GetUserStateSuccessAction
  | GetUserStateFailureAction;

export const fromCustomersActions = {
  SelectCountry,
  ClearData,
  SelectCustomerVehicle,

  CheckDuplicateCustomer,
  CheckDuplicateCustomerSuccess,
  CheckDuplicateCustomerFailed,

  MakeVehicleDefault,
  MakeVehicleDefaultSuccess,
  MakeVehicleDefaultFailed,

  MaintainCustomerVehicle,
  MaintainCustomerVehicleSuccess,
  MaintainCustomerVehicleFailed,

  LoadCustomerVehicles,
  LoadCustomerVehiclesSuccess,
  LoadCustomerVehiclesFailed,

  UpdateShipToCustomer,
  UpdateShipToCustomerSuccess,
  UpdateShipToCustomerFailed,

  CreateShipToCustomer,
  CreateShipToCustomerSuccess,
  CreateShipToCustomerFailed,

  CreateRetailCustomer,
  CreateRetailCustomerSuccess,
  CreateRetailCustomerFailed,

  UpdateRetailCustomer,
  UpdateRetailCustomerSuccess,
  UpdateRetailCustomerFailed,

  LoadLookupLists,
  LoadLookupListsSuccess,
  LoadLookupListsFailed,

  SelectCustomerAction,
  SelectCustomerSuccessAction,
  SelectCustomerFailureAction,
  SetSelectedCustomer,

  GetCustomerSalesAction,
  GetCustomerSalesSuccessAction,
  GetCustomerSalesFailureAction,

  GetStatementListAction,
  GetStatementListSuccessAction,
  GetStatementListFailureAction,

  GetInvoiceListAction,
  GetInvoiceListSuccessAction,
  GetInvoiceListFailureAction,

  GetShowSalesAction,
  GetShowSalesSuccessAction,
  GetShowSalesFailureAction,

  ListTasksNotesAction,
  ListTasksNotesSuccessAction,
  ListTasksNotesFailureAction,

  UpdateAlertMessageAction,
  UpdateAlertMessageSuccessAction,
  UpdateAlertMessageFailureAction,

  GetAlertMessageAction,
  GetAlertMessageSuccessAction,
  GetAlertMessageFailureAction,

  UpdateNotesTasksAction,
  UpdateNotesTasksSuccessAction,
  UpdateNotesTasksFailureAction,

  CompleteTasksAction,
  CompleteTasksSuccessAction,
  CompleteTasksFailureAction,

  GetUserStateAction,
  GetUserStateSuccessAction,
  GetUserStateFailureAction,
};
