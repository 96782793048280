import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, API_ENDPOINTS } from '@pos-app/data';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
@Injectable()
export class EventService {
  navDisable: boolean = false;
  constructor(private config: ConfigService, private http: HttpClient) {}

  //   get(date): Promise<Event[]>{
  //     return
  //     //  firstValueFrom(this.http.get(this.eventUrl))
  //     //     .catch(HandleError);
  //   }

  fetchResourceList(parameters) {
    return this.http.post<ApiResponse>(`${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchResourceList}`, parameters);
  }

  fetchRecordsForBooking(parameters) {
    return this.http.post<ApiResponse>(`${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchRecordsForBooking}`, parameters);
  }

  splitOrder(parameters) {
    return this.http.post<ApiResponse>(`${this.config.AppConfig.backendUrl}${API_ENDPOINTS.splitOrder}`, parameters);
  }

  consolidateOrder(parameters) {
    return this.http.post<ApiResponse>(`${this.config.AppConfig.backendUrl}${API_ENDPOINTS.consolidateOrder}`, parameters);
  }

  changeOrderBooking(parameters) {
    return this.http.post<ApiResponse>(`${this.config.AppConfig.backendUrl}${API_ENDPOINTS.changeOrderBooking}`, parameters);
  }
  renameGroupName(parameters) {
    return this.http.post<ApiResponse>(`${this.config.AppConfig.backendUrl}${API_ENDPOINTS.renameGroupName}`, parameters);
  }

  fetchOrderFromJDE(parameters) {
    return this.http.post<ApiResponse>(`${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchOrderFromJDE}`, parameters);
  }

  listWorkshopTask(parameters) {
    return this.http.post<ApiResponse>(`${this.config.AppConfig.backendUrl}${API_ENDPOINTS.listWorkshopTask}`, parameters);
  }

  get(parameters) {
    return this.http.post<ApiResponse>(`${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchWeekList}`, parameters);
  }
  insert(parameters): Promise<any> {
    return this.http.post<ApiResponse>(`${this.config.AppConfig.backendUrl}${API_ENDPOINTS.maintainTaskBooking}`, parameters).toPromise();
  }
  update(parameters): Promise<any> {
    return this.http.post<ApiResponse>(`${this.config.AppConfig.backendUrl}${API_ENDPOINTS.maintainTaskBooking}`, parameters).toPromise();
  }
  remove(parameters): Promise<any> {
    return this.http.post<ApiResponse>(`${this.config.AppConfig.backendUrl}${API_ENDPOINTS.maintainTaskBooking}`, parameters).toPromise();
  }
  maintainOrderBooking(parameters): Promise<any> {
    return this.http.post<ApiResponse>(`${this.config.AppConfig.backendUrl}${API_ENDPOINTS.maintainOrderBooking}`, parameters).toPromise();
  }
  fetchWorkshopHours(parameters){
    return this.http.post<ApiResponse>(`${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchWorkshopHours}`, parameters);
  }
}
