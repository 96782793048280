import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnDestroy
} from '@angular/core';
import {
  LookupLists,
  INVOICE_TO,
  CustomerDetails,
  State
} from '@pos-app/data';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-customers-ship-to-form',
  templateUrl: './customers-ship-to-form.component.html',
  styleUrls: ['./customers-ship-to-form.component.scss']
})
export class CustomersShipToFormComponent
  implements OnInit, OnChanges, OnDestroy {
  @Input() soldToCustomer: CustomerDetails;
  @Input() selectedCustomer: CustomerDetails;
  @Input() lookupLists: LookupLists;
  @Output() formChanged = new EventEmitter<any>();
  @Output() formIsValid = new EventEmitter<boolean>();
  @Output() formIsDirty = new EventEmitter<boolean>();
  @Output() submitByEnter = new EventEmitter<void>();
  @Output() selectedCountry = new EventEmitter<string>();

  public stateList: State[];
  public soldToCustomerData = {
    name: '',
    number: ''
  };
  @Input() set filteredStateList(value) {
    this.stateList = value;
  }

  public customerShipToForm: FormGroup;
  public isAccountCustomer = false;
  public lookupLists$: Observable<LookupLists>;
  public invoiceToList = INVOICE_TO;
  private unSubscribe$ = new Subject<void>();

  constructor(private formBuilder: FormBuilder) {
    this.customerShipToForm = this.formBuilder.group({
      customerNumber: [''],
      branch: [''],
      name: ['', Validators.required],
      // TODO email and phone validation
      email: ['', [Validators.email]],
      phone: ['', [Validators.pattern('^[0-9 ]{10,}$')]],
      phoneType: [''],
      addressLine1: ['', Validators.required],
      addressLine2: [''],
      suburb: [''],
      state: [''],
      postCode: [''],
      country: ['AU'],
      soldTo: [''],
      invoiceTo: ['P'],
      delInstructions1: [''],
      delInstructions2: [''],
      zone: [''],
      carrier: [''],
      route: [''],
      freightHandlingCode: [''],
      defaultBillTo: ['']
    });
  }

  ngOnInit() {
    this.customerShipToForm.valueChanges
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe(x => {
        this.formChanged.emit(this.customerShipToForm.getRawValue());
        this.formIsValid.emit(this.customerShipToForm.valid);
        this.formIsDirty.emit(this.customerShipToForm.dirty);
      });
    this.customerShipToForm.controls.country.valueChanges
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe(value => {
        this.selectedCountry.emit(value);
        this.customerShipToForm.controls.state.patchValue('');
      });
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // new shipTo customer, soldTo is the retail customer number
    if (
      changes.soldToCustomer &&
      changes.soldToCustomer.currentValue &&
      this.customerShipToForm
    ) {
      this.customerShipToForm.patchValue({
        soldTo: this.soldToCustomer.CustomerNumber,
        defaultBillTo: this.soldToCustomer.CustomerNumber
      });
      this.soldToCustomerData.name = this.soldToCustomer.CustomerName;
      this.soldToCustomerData.number = this.soldToCustomer.CustomerNumber;
    }

    // existing shipTo customer
    if (
      changes.selectedCustomer &&
      changes.selectedCustomer.currentValue &&
      this.customerShipToForm
    ) {
      this.soldToCustomerData.name = this.selectedCustomer.BillToName;
      this.soldToCustomerData.number = this.selectedCustomer.BillToNumber;
      this.customerShipToForm.patchValue(
        {
          customerNumber: this.selectedCustomer.CustomerNumber,
          branch: this.selectedCustomer.BranchCode,
          name: this.selectedCustomer.CustomerName,
          email: this.selectedCustomer.Email,
          phone: this.selectedCustomer.Phone,
          soldTo: this.selectedCustomer.CustomerNumber,
          addressLine1: this.selectedCustomer.AddressLine1,
          addressLine2: this.selectedCustomer.AddressLine2,
          suburb: this.selectedCustomer.Suburb,
          state: this.selectedCustomer.StateCode,
          postCode: this.selectedCustomer.PostCode,
          country: this.selectedCustomer.CountryCode,
          invoiceTo: this.selectedCustomer.InvoiceTo,
          delInstructions1: this.selectedCustomer.DeliveryIns1,
          delInstructions2: this.selectedCustomer.DeliveryIns2,
          zone: this.selectedCustomer.ZoneNumber,
          carrier: this.selectedCustomer.CarrierNumber,
          route: this.selectedCustomer.RouteCode,
          freightHandlingCode: this.selectedCustomer.FreightCode,
          defaultBillTo:
            this.selectedCustomer.BillToNumber ===
            this.selectedCustomer.CustomerNumber
              ? '0'
              : this.selectedCustomer.BillToNumber
        },
        { emitEvent: false, onlySelf: true }
      );
      if (this.selectedCustomer.BillToNumber === this.selectedCustomer.CustomerNumber) {
        this.customerShipToForm.controls.defaultBillTo.disable();
      }
    }
  }

  pressEnter() {
    this.submitByEnter.emit();
  }
}
