<button
  mat-icon-button
  color="warn"
  aria-label="Get previous value"
  (click)="onLeft()"
  [disabled]="disabled || index === minNumber"
>
  <mat-icon>arrow_back</mat-icon>
</button>

<input
  class="form-control form-unset-display w-px-200"
  [disabled]="true"
  [ngModel]="inputLabel"
/>
<button
  mat-icon-button
  color="warn"
  aria-label="Get next value"
  (click)="onRight()"
  [disabled]="disabled || index === maxNumber"
>
  <mat-icon>arrow_forward</mat-icon>
</button>
