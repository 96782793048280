<div class="card">
  <div class="card-body">
    <div *ngIf="selectedVehicle">
      <p>
        {{ getVehicleDescription() }}
      </p>
      <epc-customers-vehicles-details-form
        [filteredStateList]="stateList$ | async"
        [lookupLists]="lookupLists$ | async"
        [defaultState]="selectedCustomerState$ | async"
        [defaultCountry]="selectedCustomerCountry$ | async"
        (formChanged)="formValue = $event"
        (formIsValid)="formValid = $event"
        (formIsDirty)="formDirtyHandler($event)"
        (submitByEnter)="formValid && addNewVehicle()"
        (selectedCountry)="getFilteredStateList($event)"
      ></epc-customers-vehicles-details-form>
      <div class="pt-2 text-right">
        <button class="btn text-white" [disabled]="!formValid" (click)="addNewVehicle()" [busyIf]="isUpdating$ | async">SAVE</button>
        <span class="px-2"></span>
        <button class="btn btn-cancel" (click)="cancel()">CANCEL</button>
      </div>
    </div>
  </div>
</div>
