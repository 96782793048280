import {
  Directive,
  Input,
  HostBinding,
  EventEmitter,
  Output,
} from '@angular/core';

@Directive({
  selector: '[appProductImage]',
  host: {
    '(error)': 'updateUrl()',
    '(load)': 'load()',
    '[src]': 'src',
  },
})
export class ProductImageDirective {
  @Input() src: string;
  @Output() imageErrorCallBack: EventEmitter<string> =
    new EventEmitter<string>();
  @HostBinding('class') className;

  defaultProductImage: string = '/assets/img/ARB_logo.png'; //todo: get from db

  productDefaultImageSettings = {
    externalProductImageUrl:
      'https://i.ibb.co/1q8HLWd/IMAGE-NOT-ON-FILE-ARB.jpg',
    productDefaultImageFileType: null,
    productDefaultImageId: null,
    useExternalProductImageUrl: true,
  };

  constructor() {}

  updateUrl() {
    this.imageErrorCallBack.emit(this.src);
    this.className = 'not-available';
    this.src = this.fallbackProductImageUrl();
  }

  load() {
    if (!this.src) {
      this.src = this.fallbackProductImageUrl();
    }
  }

  fallbackProductImageUrl(): string {
    if (
      this.productDefaultImageSettings &&
      this.productDefaultImageSettings.useExternalProductImageUrl
    ) {
      return this.productDefaultImageSettings.externalProductImageUrl;
    }

    return this.defaultProductImage;
  }
}
