<div class="container vehicles-selection">
  <div>
    <h3 class="arb-large-heading">ADVANCED VEHICLE SEARCH</h3>
    <form [formGroup]="vehicleSearchForm" id="vehicleSearchForm" novalidate>
      <div class="form-group">
        <div class="row">
          <div
            class="col-12"
            *ngIf="{
              values: vehicleFilterDropdownValue$ | async
            } as vehicleValues"
          >
            <div class="">
              <mat-form-field class="vehicle-search-box">
                <mat-chip-list #chipList aria-label="Vehicle search" focus="false">
                  <mat-chip *ngFor="let searchPill of searchPills" [removable]="true" (removed)="removePill(searchPill)" selected color="primary">
                    {{ searchPill }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                  </mat-chip>
                  <input
                    #searchTextBox
                    autofocus
                    placeholder="{{ vehicleSearchTextLabel }}"
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="addString($event)"
                    [formControl]="searchFormControl"
                  />
                  <span class="spinner-border text-primary" role="status" *ngIf="(isLoaded$ | async) === false">
                    <span class="sr-only"></span>
                  </span>
                </mat-chip-list>
              </mat-form-field>
            </div>
            <div id="collapseExample">
              <div class="row filter py-1">
                <div class="col">
                  <label for="make">Make</label>
                  <ng-select [items]="vehicleValues?.values?.Make" bindLabel="Make" formControlName="make"> </ng-select>
                </div>
                <div class="col">
                  <label for="model">Model</label>
                  <ng-select [items]="vehicleValues?.values?.Model" bindLabel="Model" formControlName="model"> </ng-select>
                </div>
                <div class="col">
                  <label for="year">Year</label>
                  <ng-select [items]="vehicleValues?.values?.Year" bindLabel="Year" formControlName="year"> </ng-select>
                </div>
                <div class="col">
                  <label for="generation">Generation</label>
                  <ng-select [items]="vehicleValues?.values?.Generation" bindLabel="Generation" formControlName="generation"> </ng-select>
                </div>
                <div class="col">
                  <label for="series">Trim</label>
                  <ng-select [items]="vehicleValues?.values?.Series" bindLabel="Trim" formControlName="series"> </ng-select>
                </div>
                <div class="col">
                  <label for="subModel">Cab Type / Sub Model</label>
                  <ng-select [items]="vehicleValues?.values?.SubModel" bindLabel="Sub Model" formControlName="subModel"> </ng-select>
                </div>
                <div class="col">
                  <label for="bodyType">Body</label>
                  <ng-select [items]="vehicleValues?.values?.BodyType" bindLabel="Body" formControlName="bodyType"> </ng-select>
                </div>
                <div class="col">
                  <label for="engineType">Fuel Type</label>
                  <ng-select [items]="vehicleValues?.values?.EngineType" bindLabel="Fuel Type" formControlName="engineType" placeholder="Engine Type">
                  </ng-select>
                </div>
                <div class="col">
                  <label for="engineCode">Engine</label>
                  <ng-select [items]="vehicleValues?.values?.EngineCode" bindLabel="Engine" formControlName="engineCode"> </ng-select>
                </div>
                <div class="col">
                  <label for="driveType">Drive</label>
                  <ng-select [items]="vehicleValues?.values?.DriveType" bindLabel="Drive" formControlName="driveType"> </ng-select>
                </div>
                <div class="col">
                  <label for="transmissionType">Transmission</label>
                  <ng-select
                    [items]="vehicleValues?.values?.TransmissionType"
                    bindLabel="Transmission"
                    formControlName="transmissionType"
                    placeholder="Transmission"
                  >
                  </ng-select>
                </div>
                <div class="col">
                  <label for="chassis">Chassis</label>
                  <ng-select [items]="vehicleValues?.values?.Chassis" bindLabel="Chassis" formControlName="chassis"> </ng-select>
                </div>
              </div>
            </div>
            <div *ngIf="(vehicles$ | async)?.length > 0" class="mt-4">
              <div class="text-center">
                <h3 class="text-uppercase arb-large-heading">Vehicles matching search criteria</h3>
              </div>
              <div class="list-group">
                <button
                  type="button"
                  class="list-group-item list-group-item-action py-1"
                  *ngFor="let vehicle of vehicles$ | async as vehicles; let i = index"
                  (click)="selectVehicle(vehicle)"
                  (dblclick)="submit()"
                  [ngClass]="selectedVehicle && vehicles[i].VehicleID === selectedVehicle.VehicleID ? 'active' : ''"
                  (keyup.enter)="submit()"
                >
                  {{ vehicle.Make }} {{ vehicle.Model }} {{ vehicle.Year }} {{ vehicle.Generation }} {{ vehicle.Series }}
                  {{ vehicle.SubModel }}
                  {{ vehicle.BodyType }}
                  {{ vehicle.EngineType }}
                  {{ vehicle.EngineCode }}
                  {{ vehicle.DriveType }}
                  {{ vehicle.TransmissionType }}
                  {{ vehicle.Chassis }}
                </button>
              </div>
            </div>
            <div class="search-results text-center" *ngIf="!(vehicles$ | async)?.length && !vehicleValues?.values?.Make?.length">
              Your search yields no results - Please search again
            </div>
            <div class="text-center text-warning text-uppercase" *ngIf="(tooManyVehicles$ | async) === 'Y'">
              Too many vehicles match the search criteria - please refine your search
            </div>
            <div class="text-right mt-4">
              <span>
                <button class="btn" [disabled]="!selectedVehicle" (click)="submit()">SELECT</button>
              </span>
              <span class="mx-4">
                <button class="btn" (click)="reset()" [disabled]="!(isLoaded$ | async)">RESET</button>
              </span>
              <span *ngIf="!hideCancel">
                <button class="btn btn-cancel" (click)="cancel()">CANCEL</button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
