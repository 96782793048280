<div class="scroll-content">
  <div class="row px-1 py-2 orders-for-booking-list">
    <div class="col-auto px-3">
      <h6 class="align-text-bottom"><strong>UNALLOCATED ORDERS</strong></h6>
    </div>
    <div class="row scroll-content align-items-center">
      <div
        cdk-scrollable
        cdkDropList
        [id]="orderForBookingId"
        class="col"
        [cdkDropListData]="unAssignedOrdersList"
        [cdkDropListConnectedTo]="dragDropUniqueIds.get('ordersForBooking')"
        (cdkDropListDropped)="orderDrop($event)"
      >
        <div
          *ngFor="let orderForBookingCard of unAssignedOrdersList"
          [cdkDragData]="orderForBookingCard"
          cdkDrag
        >
          <app-order-for-booking-card
            [orderForBookingCard]="orderForBookingCard"
            [dragDropArrayOfLineIdsList]="
              dragDropUniqueIds.get(orderForBookingCard.OrderNumber.toString())
            "
            [isArrayOfLinesDisabled]="false"
            (renameGroupName)="updateGroupName($event)"
            (splitOrder)="invokeSplitOrder($event)"
            (consolidateOrder)="invokeConsolidateOrder($event)"
            (arrayOfLinesdropEvent)="invokeReassignLine($event)"
            (arrayOfLineParentOrderData)="
              emitArrayOfLineParentOrderData($event)
            "
          ></app-order-for-booking-card>
        </div>
        <div
          class="empty-card m-2 border border-2 border-dark px-2 py-5 rounded-1"
          *ngIf="!!unAssignedOrdersList && unAssignedOrdersList.length === 0"
        >
          <h6 class="text-center">DRAG HERE TO UNALLOCATE ORDER</h6>
        </div>
      </div>
    </div>
    <div
      *ngIf="!!unAssignedOrdersList && unAssignedOrdersList.length > 0"
      cdkDropList
      [cdkDropListData]="arrayOfLines"
      [cdkDropListConnectedTo]="dragDropUniqueIds.get(arrayOfLineDropZoneId)"
      (cdkDropListDropped)="arrayOfLineDropZone($event)"
      [id]="arrayOfLineDropZoneId"
      class="row p-3 align-items-center"
    >
      <div
        class="col empty-card m-2 border border-2 border-dark px-2 py-5 rounded-1"
      >
        <h6 class="text-center">DRAG ITEM HERE TO CREATE NEW GROUP</h6>
      </div>
    </div>
  </div>
</div>
