import { Component, EventEmitter, OnDestroy, OnInit, Output, Input, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Customer } from '@pos-app/data';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators';
import { CoreUiPartialState } from '../../+state/core-ui/core-ui.reducer';
import {
  getCustomers,
  getSearchingCustomer,
  getHasMoreRecords,
  getSearchingBillToCustomer,
  getBillToCustomers,
  getHasMoreBillToRecords,
} from '../../+state/core-ui/core-ui.selectors';
import { searchCustomers, clearSearchData } from '../../+state/core-ui/core-ui.actions';

@Component({
  selector: 'pos-app-lib-customer-search',
  templateUrl: './customer-search.component.html',
  styleUrls: ['./customer-search.component.scss'],
})
export class CustomersSearchComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('searchBox') searchBox: ElementRef;

  @Input() forOrderSearch: boolean;
  @Input() forOrderHeader: boolean;
  @Input() selectedCustomerName: string;
  @Input() customerType = '';
  @Input() disabled: boolean;

  @Output() selectNewCustomer = new EventEmitter<Customer>();

  unSubscribe$ = new Subject<void>();
  loading$: Observable<boolean>;
  customers$: Observable<Customer[]>;
  hasMoreRecords$: Observable<boolean>;
  hasMoreRecords: boolean;
  customerSelected: Customer = null;
  listOfCustomers: Customer[];

  indexFocus = 0;
  show = false;
  customerSearchForm: FormGroup;
  selectType: boolean;

  constructor(private coreUIStore: Store<CoreUiPartialState>, private fb: FormBuilder) {}

  ngOnInit() {
    this.coreUIStore.dispatch(clearSearchData());
    this.customerSearchForm = this.fb.group({
      customerSearch: [{ value: '', disabled: this.disabled }],
      customerSearchType: [''],
    });
    this.customers$ =
      this.customerType !== 'billto' ? this.coreUIStore.pipe(select(getCustomers)) : this.coreUIStore.pipe(select(getBillToCustomers));
    this.customers$.pipe(takeUntil(this.unSubscribe$)).subscribe((x) => {
      this.listOfCustomers = x;
      const matchCustomerNumber = this.listOfCustomers.find(
        (customer) => customer.CustomerNumber === this.customerSearchForm.get('customerSearch').value
      );
      if (matchCustomerNumber) {
        this.selectCustomer(matchCustomerNumber);
      }
    });

    this.loading$ =
      this.customerType !== 'billto' ? this.coreUIStore.select(getSearchingCustomer) : this.coreUIStore.select(getSearchingBillToCustomer);

    this.hasMoreRecords$ =
      this.customerType !== 'billto' ? this.coreUIStore.select(getHasMoreRecords) : this.coreUIStore.select(getHasMoreBillToRecords);

    this.hasMoreRecords$.pipe(takeUntil(this.unSubscribe$)).subscribe((hasMoreRecord) => {
      this.hasMoreRecords = hasMoreRecord;
    });
    this.onChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedCustomerName && this.customerSearchForm) {
      this.customerSearchForm.patchValue({ customerSearch: this.selectedCustomerName }, { emitEvent: false });
    }

    if (changes.disabled && this.customerSearchForm) {
      if (this.disabled) {
        this.customerSearchForm.get('customerSearch').disable({emitEvent: false});
      } else {
        this.customerSearchForm.get('customerSearch').enable({emitEvent: false});
        this.searchBox.nativeElement.focus();
      }
    }
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  onKeyboard(e) {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      let newIdx = this.indexFocus + (e.key === 'ArrowDown' ? 1 : -1);
      newIdx = newIdx < 0 ? 0 : newIdx;
      this.indexFocus = newIdx % this.listOfCustomers.length;
    } else if (e.key === 'Enter') {
      this.selectCustomer(this.listOfCustomers[this.indexFocus]);
    } else {
      this.show = true;
    }
  }
  lostFocus(e) {
    setTimeout(() => {
      this.show = false;
      if (this.selectType) {
        this.searchBox.nativeElement.focus();
        this.selectType = false;
      }
    }, 300);
  }
  selectCustomer(customer: Customer) {
    this.show = false;
    this.customerSelected = customer;

    if (this.forOrderSearch) {
      this.customerSearchForm.patchValue(
        {
          customerSearch: `${customer.CustomerName} (${customer.CustomerNumber})`,
        },
        { emitEvent: false }
      );
    } else {
      this.customerSearchForm.patchValue({ customerSearch: '' }, { emitEvent: false });
    }
    this.selectNewCustomer.emit(customer);
    this.coreUIStore.dispatch(clearSearchData());
  }

  onChanges() {
    this.customerSearchForm
      .get('customerSearch')
      .valueChanges.pipe(
        tap((value) => {
          this.customerSelected = null;
          this.listOfCustomers = [];
          this.hasMoreRecords = false;
          
          this.selectNewCustomer.emit(null);
          this.customerSearchForm.patchValue({ customerSearchType: '' }, { emitEvent: false });
        }),
        debounceTime(500),
        distinctUntilChanged(),
        filter((v) => (v || '').trim().length >= 3),
        takeUntil(this.unSubscribe$)
      )
      .subscribe((value: string) => {
        this.coreUIStore.dispatch(clearSearchData());
        this.coreUIStore.dispatch(
          searchCustomers({
            customerfuzzySearch: value.replace('-', ''),
            customerType: this.customerType,
          })
        );
      });

    this.customerSearchForm
      .get('customerSearchType')
      .valueChanges.pipe(takeUntil(this.unSubscribe$))
      .subscribe((value) => {
        this.coreUIStore.dispatch(
          searchCustomers({
            customerfuzzySearch: this.customerSearchForm.get('customerSearch').value.replace('-', ''),
            customerType: value,
          })
        );
        this.selectType = true;
      });
  }

  getLocation(customer: Customer) {
    return (customer.Suburb ? `${customer.Suburb}, ` : '') + (customer.State ? `${customer.State}, ` : '') + customer.CountryName;
  }
  isDisabled() {
    return this.disabled;
  }
  isFocus(e) {
    this.show = true;
    e.target.select();
  }
}
