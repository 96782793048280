import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Store } from '@ngrx/store';
import { CoreUiPartialState, getIsLoggedIn } from '@pos-app/core-ui';
import { ROUTE_URL } from '@pos-app/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private store: Store<CoreUiPartialState>,
    private router: Router,
    private toastr: ToastrService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.select(getIsLoggedIn).pipe(
      map(loggedIn => {
        if (!loggedIn) {
          if (window.location.search.indexOf('returnUrl') < 0) {
            if (state.url.indexOf('orders-start') !== -1) {
              this.toastr.error('Please log in before starting a new order');
            }
            this.router.navigate([ROUTE_URL.login], {
              queryParams: {
                returnUrl: encodeURIComponent(state.url)
              }
            });
          }
          return false;
        }
        return true;
      })
    );
  }
}
