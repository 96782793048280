import moment from 'moment';

export class IsTenantAvailableInput implements IIsTenantAvailableInput {
  tenancyName!: string | undefined;

  constructor(data?: IIsTenantAvailableInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.tenancyName = data['tenancyName'];
    }
  }

  static fromJS(data: any): IsTenantAvailableInput {
    data = typeof data === 'object' ? data : {};
    let result = new IsTenantAvailableInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['tenancyName'] = this.tenancyName;
    return data;
  }
}

export interface IIsTenantAvailableInput {
  tenancyName: string | undefined;
}

export enum TenantAvailabilityState {
  Available = 1,
  InActive = 2,
  NotFound = 3,
}

export class IsTenantAvailableOutput implements IIsTenantAvailableOutput {
  state!: TenantAvailabilityState;
  tenantId!: number | undefined;
  serverRootAddress!: string | undefined;

  constructor(data?: IIsTenantAvailableOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.state = data['state'];
      this.tenantId = data['tenantId'];
      this.serverRootAddress = data['serverRootAddress'];
    }
  }

  static fromJS(data: any): IsTenantAvailableOutput {
    data = typeof data === 'object' ? data : {};
    let result = new IsTenantAvailableOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['state'] = this.state;
    data['tenantId'] = this.tenantId;
    data['serverRootAddress'] = this.serverRootAddress;
    return data;
  }
}

export interface IIsTenantAvailableOutput {
  state: TenantAvailabilityState;
  tenantId: number | undefined;
  serverRootAddress: string | undefined;
}

export class ResolveTenantIdInput implements IResolveTenantIdInput {
  c!: string | undefined;

  constructor(data?: IResolveTenantIdInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.c = data['c'];
    }
  }

  static fromJS(data: any): ResolveTenantIdInput {
    data = typeof data === 'object' ? data : {};
    let result = new ResolveTenantIdInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['c'] = this.c;
    return data;
  }
}

export interface IResolveTenantIdInput {
  c: string | undefined;
}

export class RegisterInput implements IRegisterInput {
  name!: string | undefined;
  surname!: string | undefined;
  userName!: string | undefined;
  emailAddress!: string | undefined;
  password!: string | undefined;
  captchaResponse!: string | undefined;

  constructor(data?: IRegisterInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.name = data['name'];
      this.surname = data['surname'];
      this.userName = data['userName'];
      this.emailAddress = data['emailAddress'];
      this.password = data['password'];
      this.captchaResponse = data['captchaResponse'];
    }
  }

  static fromJS(data: any): RegisterInput {
    data = typeof data === 'object' ? data : {};
    let result = new RegisterInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['name'] = this.name;
    data['surname'] = this.surname;
    data['userName'] = this.userName;
    data['emailAddress'] = this.emailAddress;
    data['password'] = this.password;
    data['captchaResponse'] = this.captchaResponse;
    return data;
  }
}

export interface IRegisterInput {
  name: string | undefined;
  surname: string | undefined;
  userName: string | undefined;
  emailAddress: string | undefined;
  password: string | undefined;
  captchaResponse: string | undefined;
}

export class RegisterOutput implements IRegisterOutput {
  canLogin!: boolean;

  constructor(data?: IRegisterOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.canLogin = data['canLogin'];
    }
  }

  static fromJS(data: any): RegisterOutput {
    data = typeof data === 'object' ? data : {};
    let result = new RegisterOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['canLogin'] = this.canLogin;
    return data;
  }
}

export interface IRegisterOutput {
  canLogin: boolean;
}

export class SendPasswordResetCodeInput implements ISendPasswordResetCodeInput {
  emailAddress!: string | undefined;

  constructor(data?: ISendPasswordResetCodeInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.emailAddress = data['emailAddress'];
    }
  }

  static fromJS(data: any): SendPasswordResetCodeInput {
    data = typeof data === 'object' ? data : {};
    let result = new SendPasswordResetCodeInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['emailAddress'] = this.emailAddress;
    return data;
  }
}

export interface ISendPasswordResetCodeInput {
  emailAddress: string | undefined;
}

export class ResetPasswordInput implements IResetPasswordInput {
  userId!: number;
  resetCode!: string | undefined;
  password!: string | undefined;
  returnUrl!: string | undefined;
  singleSignIn!: string | undefined;
  c!: string | undefined;

  constructor(data?: IResetPasswordInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.userId = data['userId'];
      this.resetCode = data['resetCode'];
      this.password = data['password'];
      this.returnUrl = data['returnUrl'];
      this.singleSignIn = data['singleSignIn'];
      this.c = data['c'];
    }
  }

  static fromJS(data: any): ResetPasswordInput {
    data = typeof data === 'object' ? data : {};
    let result = new ResetPasswordInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['userId'] = this.userId;
    data['resetCode'] = this.resetCode;
    data['password'] = this.password;
    data['returnUrl'] = this.returnUrl;
    data['singleSignIn'] = this.singleSignIn;
    data['c'] = this.c;
    return data;
  }
}

export interface IResetPasswordInput {
  userId: number;
  resetCode: string | undefined;
  password: string | undefined;
  returnUrl: string | undefined;
  singleSignIn: string | undefined;
  c: string | undefined;
}

export class ResetPasswordOutput implements IResetPasswordOutput {
  canLogin!: boolean;
  userName!: string | undefined;

  constructor(data?: IResetPasswordOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.canLogin = data['canLogin'];
      this.userName = data['userName'];
    }
  }

  static fromJS(data: any): ResetPasswordOutput {
    data = typeof data === 'object' ? data : {};
    let result = new ResetPasswordOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['canLogin'] = this.canLogin;
    data['userName'] = this.userName;
    return data;
  }
}

export interface IResetPasswordOutput {
  canLogin: boolean;
  userName: string | undefined;
}

export class SendEmailActivationLinkInput
  implements ISendEmailActivationLinkInput
{
  emailAddress!: string | undefined;

  constructor(data?: ISendEmailActivationLinkInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.emailAddress = data['emailAddress'];
    }
  }

  static fromJS(data: any): SendEmailActivationLinkInput {
    data = typeof data === 'object' ? data : {};
    let result = new SendEmailActivationLinkInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['emailAddress'] = this.emailAddress;
    return data;
  }
}

export interface ISendEmailActivationLinkInput {
  emailAddress: string | undefined;
}

export class ActivateEmailInput implements IActivateEmailInput {
  userId!: number;
  confirmationCode!: string | undefined;
  c!: string | undefined;

  constructor(data?: IActivateEmailInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.userId = data['userId'];
      this.confirmationCode = data['confirmationCode'];
      this.c = data['c'];
    }
  }

  static fromJS(data: any): ActivateEmailInput {
    data = typeof data === 'object' ? data : {};
    let result = new ActivateEmailInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['userId'] = this.userId;
    data['confirmationCode'] = this.confirmationCode;
    data['c'] = this.c;
    return data;
  }
}

export interface IActivateEmailInput {
  userId: number;
  confirmationCode: string | undefined;
  c: string | undefined;
}

export class ImpersonateInput implements IImpersonateInput {
  tenantId!: number | undefined;
  userId!: number;

  constructor(data?: IImpersonateInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.tenantId = data['tenantId'];
      this.userId = data['userId'];
    }
  }

  static fromJS(data: any): ImpersonateInput {
    data = typeof data === 'object' ? data : {};
    let result = new ImpersonateInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['tenantId'] = this.tenantId;
    data['userId'] = this.userId;
    return data;
  }
}

export interface IImpersonateInput {
  tenantId: number | undefined;
  userId: number;
}

export class ImpersonateOutput implements IImpersonateOutput {
  impersonationToken!: string | undefined;
  tenancyName!: string | undefined;

  constructor(data?: IImpersonateOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.impersonationToken = data['impersonationToken'];
      this.tenancyName = data['tenancyName'];
    }
  }

  static fromJS(data: any): ImpersonateOutput {
    data = typeof data === 'object' ? data : {};
    let result = new ImpersonateOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['impersonationToken'] = this.impersonationToken;
    data['tenancyName'] = this.tenancyName;
    return data;
  }
}

export interface IImpersonateOutput {
  impersonationToken: string | undefined;
  tenancyName: string | undefined;
}

export class SwitchToLinkedAccountInput implements ISwitchToLinkedAccountInput {
  targetTenantId!: number | undefined;
  targetUserId!: number;

  constructor(data?: ISwitchToLinkedAccountInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.targetTenantId = data['targetTenantId'];
      this.targetUserId = data['targetUserId'];
    }
  }

  static fromJS(data: any): SwitchToLinkedAccountInput {
    data = typeof data === 'object' ? data : {};
    let result = new SwitchToLinkedAccountInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['targetTenantId'] = this.targetTenantId;
    data['targetUserId'] = this.targetUserId;
    return data;
  }
}

export interface ISwitchToLinkedAccountInput {
  targetTenantId: number | undefined;
  targetUserId: number;
}

export class SwitchToLinkedAccountOutput
  implements ISwitchToLinkedAccountOutput
{
  switchAccountToken!: string | undefined;
  tenancyName!: string | undefined;

  constructor(data?: ISwitchToLinkedAccountOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.switchAccountToken = data['switchAccountToken'];
      this.tenancyName = data['tenancyName'];
    }
  }

  static fromJS(data: any): SwitchToLinkedAccountOutput {
    data = typeof data === 'object' ? data : {};
    let result = new SwitchToLinkedAccountOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['switchAccountToken'] = this.switchAccountToken;
    data['tenancyName'] = this.tenancyName;
    return data;
  }
}

export interface ISwitchToLinkedAccountOutput {
  switchAccountToken: string | undefined;
  tenancyName: string | undefined;
}

export class AttributeListDto implements IAttributeListDto {
  attributeId!: number;
  name!: string | undefined;
  filter!: boolean;
  group!: boolean;
  visible!: boolean;
  attributeType!: string | undefined;
  sortNr!: number;

  constructor(data?: IAttributeListDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.attributeId = data['attributeId'];
      this.name = data['name'];
      this.filter = data['filter'];
      this.group = data['group'];
      this.visible = data['visible'];
      this.attributeType = data['attributeType'];
      this.sortNr = data['sortNr'];
    }
  }

  static fromJS(data: any): AttributeListDto {
    data = typeof data === 'object' ? data : {};
    let result = new AttributeListDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['attributeId'] = this.attributeId;
    data['name'] = this.name;
    data['filter'] = this.filter;
    data['group'] = this.group;
    data['visible'] = this.visible;
    data['attributeType'] = this.attributeType;
    data['sortNr'] = this.sortNr;
    return data;
  }
}

export interface IAttributeListDto {
  attributeId: number;
  name: string | undefined;
  filter: boolean;
  group: boolean;
  visible: boolean;
  attributeType: string | undefined;
  sortNr: number;
}

export class ListResultDtoOfAttributeListDto
  implements IListResultDtoOfAttributeListDto
{
  items!: AttributeListDto[] | undefined;

  constructor(data?: IListResultDtoOfAttributeListDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['items'])) {
        this.items = [] as any;
        for (let item of data['items'])
          this.items!.push(AttributeListDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): ListResultDtoOfAttributeListDto {
    data = typeof data === 'object' ? data : {};
    let result = new ListResultDtoOfAttributeListDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.items)) {
      data['items'] = [];
      for (let item of this.items) data['items'].push(item.toJSON());
    }
    return data;
  }
}

export interface IListResultDtoOfAttributeListDto {
  items: AttributeListDto[] | undefined;
}

export class AttributeInputDto implements IAttributeInputDto {
  attributes!: AttributeListDto[] | undefined;

  constructor(data?: IAttributeInputDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['attributes'])) {
        this.attributes = [] as any;
        for (let item of data['attributes'])
          this.attributes!.push(AttributeListDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): AttributeInputDto {
    data = typeof data === 'object' ? data : {};
    let result = new AttributeInputDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.attributes)) {
      data['attributes'] = [];
      for (let item of this.attributes) data['attributes'].push(item.toJSON());
    }
    return data;
  }
}

export interface IAttributeInputDto {
  attributes: AttributeListDto[] | undefined;
}

export class AuditLogListDto implements IAuditLogListDto {
  userId!: number | undefined;
  userName!: string | undefined;
  impersonatorTenantId!: number | undefined;
  impersonatorUserId!: number | undefined;
  serviceName!: string | undefined;
  methodName!: string | undefined;
  parameters!: string | undefined;
  executionTime!: moment.Moment;
  executionDuration!: number;
  clientIpAddress!: string | undefined;
  clientName!: string | undefined;
  browserInfo!: string | undefined;
  exception!: string | undefined;
  customData!: string | undefined;
  id!: number;

  constructor(data?: IAuditLogListDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.userId = data['userId'];
      this.userName = data['userName'];
      this.impersonatorTenantId = data['impersonatorTenantId'];
      this.impersonatorUserId = data['impersonatorUserId'];
      this.serviceName = data['serviceName'];
      this.methodName = data['methodName'];
      this.parameters = data['parameters'];
      this.executionTime = data['executionTime']
        ? moment(data['executionTime'].toString())
        : <any>undefined;
      this.executionDuration = data['executionDuration'];
      this.clientIpAddress = data['clientIpAddress'];
      this.clientName = data['clientName'];
      this.browserInfo = data['browserInfo'];
      this.exception = data['exception'];
      this.customData = data['customData'];
      this.id = data['id'];
    }
  }

  static fromJS(data: any): AuditLogListDto {
    data = typeof data === 'object' ? data : {};
    let result = new AuditLogListDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['userId'] = this.userId;
    data['userName'] = this.userName;
    data['impersonatorTenantId'] = this.impersonatorTenantId;
    data['impersonatorUserId'] = this.impersonatorUserId;
    data['serviceName'] = this.serviceName;
    data['methodName'] = this.methodName;
    data['parameters'] = this.parameters;
    data['executionTime'] = this.executionTime
      ? this.executionTime.toISOString()
      : <any>undefined;
    data['executionDuration'] = this.executionDuration;
    data['clientIpAddress'] = this.clientIpAddress;
    data['clientName'] = this.clientName;
    data['browserInfo'] = this.browserInfo;
    data['exception'] = this.exception;
    data['customData'] = this.customData;
    data['id'] = this.id;
    return data;
  }
}

export interface IAuditLogListDto {
  userId: number | undefined;
  userName: string | undefined;
  impersonatorTenantId: number | undefined;
  impersonatorUserId: number | undefined;
  serviceName: string | undefined;
  methodName: string | undefined;
  parameters: string | undefined;
  executionTime: moment.Moment;
  executionDuration: number;
  clientIpAddress: string | undefined;
  clientName: string | undefined;
  browserInfo: string | undefined;
  exception: string | undefined;
  customData: string | undefined;
  id: number;
}

export class PagedResultDtoOfAuditLogListDto
  implements IPagedResultDtoOfAuditLogListDto
{
  totalCount!: number;
  items!: AuditLogListDto[] | undefined;

  constructor(data?: IPagedResultDtoOfAuditLogListDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.totalCount = data['totalCount'];
      if (Array.isArray(data['items'])) {
        this.items = [] as any;
        for (let item of data['items'])
          this.items!.push(AuditLogListDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): PagedResultDtoOfAuditLogListDto {
    data = typeof data === 'object' ? data : {};
    let result = new PagedResultDtoOfAuditLogListDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['totalCount'] = this.totalCount;
    if (Array.isArray(this.items)) {
      data['items'] = [];
      for (let item of this.items) data['items'].push(item.toJSON());
    }
    return data;
  }
}

export interface IPagedResultDtoOfAuditLogListDto {
  totalCount: number;
  items: AuditLogListDto[] | undefined;
}

export class FileDto implements IFileDto {
  fileName!: string | undefined;
  fileType!: string | undefined;
  fileToken!: string | undefined;

  constructor(data?: IFileDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.fileName = data['fileName'];
      this.fileType = data['fileType'];
      this.fileToken = data['fileToken'];
    }
  }

  static fromJS(data: any): FileDto {
    data = typeof data === 'object' ? data : {};
    let result = new FileDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['fileName'] = this.fileName;
    data['fileType'] = this.fileType;
    data['fileToken'] = this.fileToken;
    return data;
  }
}

export interface IFileDto {
  fileName: string | undefined;
  fileType: string | undefined;
  fileToken: string | undefined;
}

export class NameValueDto implements INameValueDto {
  name!: string | undefined;
  value!: string | undefined;

  constructor(data?: INameValueDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.name = data['name'];
      this.value = data['value'];
    }
  }

  static fromJS(data: any): NameValueDto {
    data = typeof data === 'object' ? data : {};
    let result = new NameValueDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['name'] = this.name;
    data['value'] = this.value;
    return data;
  }
}

export interface INameValueDto {
  name: string | undefined;
  value: string | undefined;
}

export enum EntityChangeType {
  Created = 0,
  Updated = 1,
  Deleted = 2,
}

export class EntityChangeListDto implements IEntityChangeListDto {
  userId!: number | undefined;
  userName!: string | undefined;
  changeTime!: moment.Moment;
  entityTypeFullName!: string | undefined;
  changeType!: EntityChangeType;
  readonly changeTypeName!: string | undefined;
  entityChangeSetId!: number;
  id!: number;

  constructor(data?: IEntityChangeListDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.userId = data['userId'];
      this.userName = data['userName'];
      this.changeTime = data['changeTime']
        ? moment(data['changeTime'].toString())
        : <any>undefined;
      this.entityTypeFullName = data['entityTypeFullName'];
      this.changeType = data['changeType'];
      (<any>this).changeTypeName = data['changeTypeName'];
      this.entityChangeSetId = data['entityChangeSetId'];
      this.id = data['id'];
    }
  }

  static fromJS(data: any): EntityChangeListDto {
    data = typeof data === 'object' ? data : {};
    let result = new EntityChangeListDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['userId'] = this.userId;
    data['userName'] = this.userName;
    data['changeTime'] = this.changeTime
      ? this.changeTime.toISOString()
      : <any>undefined;
    data['entityTypeFullName'] = this.entityTypeFullName;
    data['changeType'] = this.changeType;
    data['changeTypeName'] = this.changeTypeName;
    data['entityChangeSetId'] = this.entityChangeSetId;
    data['id'] = this.id;
    return data;
  }
}

export interface IEntityChangeListDto {
  userId: number | undefined;
  userName: string | undefined;
  changeTime: moment.Moment;
  entityTypeFullName: string | undefined;
  changeType: EntityChangeType;
  changeTypeName: string | undefined;
  entityChangeSetId: number;
  id: number;
}

export class PagedResultDtoOfEntityChangeListDto
  implements IPagedResultDtoOfEntityChangeListDto
{
  totalCount!: number;
  items!: EntityChangeListDto[] | undefined;

  constructor(data?: IPagedResultDtoOfEntityChangeListDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.totalCount = data['totalCount'];
      if (Array.isArray(data['items'])) {
        this.items = [] as any;
        for (let item of data['items'])
          this.items!.push(EntityChangeListDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): PagedResultDtoOfEntityChangeListDto {
    data = typeof data === 'object' ? data : {};
    let result = new PagedResultDtoOfEntityChangeListDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['totalCount'] = this.totalCount;
    if (Array.isArray(this.items)) {
      data['items'] = [];
      for (let item of this.items) data['items'].push(item.toJSON());
    }
    return data;
  }
}

export interface IPagedResultDtoOfEntityChangeListDto {
  totalCount: number;
  items: EntityChangeListDto[] | undefined;
}

export class EntityPropertyChangeDto implements IEntityPropertyChangeDto {
  entityChangeId!: number;
  newValue!: string | undefined;
  originalValue!: string | undefined;
  propertyName!: string | undefined;
  propertyTypeFullName!: string | undefined;
  tenantId!: number | undefined;
  id!: number;

  constructor(data?: IEntityPropertyChangeDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.entityChangeId = data['entityChangeId'];
      this.newValue = data['newValue'];
      this.originalValue = data['originalValue'];
      this.propertyName = data['propertyName'];
      this.propertyTypeFullName = data['propertyTypeFullName'];
      this.tenantId = data['tenantId'];
      this.id = data['id'];
    }
  }

  static fromJS(data: any): EntityPropertyChangeDto {
    data = typeof data === 'object' ? data : {};
    let result = new EntityPropertyChangeDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['entityChangeId'] = this.entityChangeId;
    data['newValue'] = this.newValue;
    data['originalValue'] = this.originalValue;
    data['propertyName'] = this.propertyName;
    data['propertyTypeFullName'] = this.propertyTypeFullName;
    data['tenantId'] = this.tenantId;
    data['id'] = this.id;
    return data;
  }
}

export interface IEntityPropertyChangeDto {
  entityChangeId: number;
  newValue: string | undefined;
  originalValue: string | undefined;
  propertyName: string | undefined;
  propertyTypeFullName: string | undefined;
  tenantId: number | undefined;
  id: number;
}

export class BreadCrumbResponseDto implements IBreadCrumbResponseDto {
  name!: string | undefined;
  redirectUrl!: string | undefined;
  id!: string | undefined;
  routerName!: string | undefined;
  icon!: string | undefined;
  isFirst!: boolean;

  constructor(data?: IBreadCrumbResponseDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.name = data['name'];
      this.redirectUrl = data['redirectUrl'];
      this.id = data['id'];
      this.routerName = data['routerName'];
      this.icon = data['icon'];
      this.isFirst = data['isFirst'];
    }
  }

  static fromJS(data: any): BreadCrumbResponseDto {
    data = typeof data === 'object' ? data : {};
    let result = new BreadCrumbResponseDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['name'] = this.name;
    data['redirectUrl'] = this.redirectUrl;
    data['id'] = this.id;
    data['routerName'] = this.routerName;
    data['icon'] = this.icon;
    data['isFirst'] = this.isFirst;
    return data;
  }
}

export interface IBreadCrumbResponseDto {
  name: string | undefined;
  redirectUrl: string | undefined;
  id: string | undefined;
  routerName: string | undefined;
  icon: string | undefined;
  isFirst: boolean;
}

export class CacheDto implements ICacheDto {
  name!: string | undefined;

  constructor(data?: ICacheDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.name = data['name'];
    }
  }

  static fromJS(data: any): CacheDto {
    data = typeof data === 'object' ? data : {};
    let result = new CacheDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['name'] = this.name;
    return data;
  }
}

export interface ICacheDto {
  name: string | undefined;
}

export class ListResultDtoOfCacheDto implements IListResultDtoOfCacheDto {
  items!: CacheDto[] | undefined;

  constructor(data?: IListResultDtoOfCacheDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['items'])) {
        this.items = [] as any;
        for (let item of data['items']) this.items!.push(CacheDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): ListResultDtoOfCacheDto {
    data = typeof data === 'object' ? data : {};
    let result = new ListResultDtoOfCacheDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.items)) {
      data['items'] = [];
      for (let item of this.items) data['items'].push(item.toJSON());
    }
    return data;
  }
}

export interface IListResultDtoOfCacheDto {
  items: CacheDto[] | undefined;
}

export class EntityDtoOfString implements IEntityDtoOfString {
  id!: string | undefined;

  constructor(data?: IEntityDtoOfString) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id'];
    }
  }

  static fromJS(data: any): EntityDtoOfString {
    data = typeof data === 'object' ? data : {};
    let result = new EntityDtoOfString();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['id'] = this.id;
    return data;
  }
}

export interface IEntityDtoOfString {
  id: string | undefined;
}

export class CEDCategoryDto implements ICEDCategoryDto {
  cedCategoryId!: number;
  name!: string | undefined;
  categoryNumber!: string | undefined;
  cedCategoryImageUrl!: string | undefined;
  parentCEDCategoryId!: number | undefined;
  cedCategoryDescription!: string | undefined;
  vehicleRequiredYPN!: 'Y' | 'N' | 'P';

  constructor(data?: ICEDCategoryDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.cedCategoryId = data['cedCategoryId'];
      this.name = data['name'];
      this.categoryNumber = data['categoryNumber'];
      this.cedCategoryImageUrl = data['cedCategoryImageUrl'];
      this.parentCEDCategoryId = data['parentCEDCategoryId'];
      this.cedCategoryDescription = data['cedCategoryDescription'];
    }
  }

  static fromJS(data: any): CEDCategoryDto {
    data = typeof data === 'object' ? data : {};
    let result = new CEDCategoryDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['cedCategoryId'] = this.cedCategoryId;
    data['name'] = this.name;
    data['categoryNumber'] = this.categoryNumber;
    data['cedCategoryImageUrl'] = this.cedCategoryImageUrl;
    data['parentCEDCategoryId'] = this.parentCEDCategoryId;
    data['cedCategoryDescription'] = this.cedCategoryDescription;
    return data;
  }
}

export interface ICEDCategoryDto {
  cedCategoryId: number;
  name: string | undefined;
  categoryNumber: string | undefined;
  cedCategoryImageUrl: string | undefined;
  parentCEDCategoryId: number | undefined;
  cedCategoryDescription: string | undefined;
  vehicleRequiredYPN: 'Y' | 'N' | 'P';
}

export class ListResultDtoOfCEDCategoryDto
  implements IListResultDtoOfCEDCategoryDto
{
  items!: CEDCategoryDto[] | undefined;

  constructor(data?: IListResultDtoOfCEDCategoryDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['items'])) {
        this.items = [] as any;
        for (let item of data['items'])
          this.items!.push(CEDCategoryDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): ListResultDtoOfCEDCategoryDto {
    data = typeof data === 'object' ? data : {};
    let result = new ListResultDtoOfCEDCategoryDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.items)) {
      data['items'] = [];
      for (let item of this.items) data['items'].push(item.toJSON());
    }
    return data;
  }
}

export interface IListResultDtoOfCEDCategoryDto {
  items: CEDCategoryDto[] | undefined;
}

export class StaticContentInputDto implements IStaticContentInputDto {
  id!: number;
  content!: string | undefined;

  constructor(data?: IStaticContentInputDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id'];
      this.content = data['content'];
    }
  }

  static fromJS(data: any): StaticContentInputDto {
    data = typeof data === 'object' ? data : {};
    let result = new StaticContentInputDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['id'] = this.id;
    data['content'] = this.content;
    return data;
  }
}

export interface IStaticContentInputDto {
  id: number;
  content: string | undefined;
}

export class CEDCategoryTypeDto implements ICEDCategoryTypeDto {
  id!: number;
  cedCategoryId!: number;
  categoryNumber!: number;
  name!: string | undefined;
  parentCEDCategoryId!: number | undefined;
  type!: string | undefined;

  constructor(data?: ICEDCategoryTypeDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id'];
      this.cedCategoryId = data['cedCategoryId'];
      this.categoryNumber = data['categoryNumber'];
      this.name = data['name'];
      this.parentCEDCategoryId = data['parentCEDCategoryId'];
      this.type = data['type'];
    }
  }

  static fromJS(data: any): CEDCategoryTypeDto {
    data = typeof data === 'object' ? data : {};
    let result = new CEDCategoryTypeDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['id'] = this.id;
    data['cedCategoryId'] = this.cedCategoryId;
    data['categoryNumber'] = this.categoryNumber;
    data['name'] = this.name;
    data['parentCEDCategoryId'] = this.parentCEDCategoryId;
    data['type'] = this.type;
    return data;
  }
}

export interface ICEDCategoryTypeDto {
  id: number;
  cedCategoryId: number;
  categoryNumber: number;
  name: string | undefined;
  parentCEDCategoryId: number | undefined;
  type: string | undefined;
}

export class CEDCategoryTypeInputDto implements ICEDCategoryTypeInputDto {
  sparePartCEDCategories!: CEDCategoryTypeDto[] | undefined;

  constructor(data?: ICEDCategoryTypeInputDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['sparePartCEDCategories'])) {
        this.sparePartCEDCategories = [] as any;
        for (let item of data['sparePartCEDCategories'])
          this.sparePartCEDCategories!.push(CEDCategoryTypeDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): CEDCategoryTypeInputDto {
    data = typeof data === 'object' ? data : {};
    let result = new CEDCategoryTypeInputDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.sparePartCEDCategories)) {
      data['sparePartCEDCategories'] = [];
      for (let item of this.sparePartCEDCategories)
        data['sparePartCEDCategories'].push(item.toJSON());
    }
    return data;
  }
}

export interface ICEDCategoryTypeInputDto {
  sparePartCEDCategories: CEDCategoryTypeDto[] | undefined;
}

export enum FriendshipState {
  Accepted = 1,
  Blocked = 2,
}

export class FriendDto implements IFriendDto {
  friendUserId!: number;
  friendTenantId!: number | undefined;
  friendUserName!: string | undefined;
  friendTenancyName!: string | undefined;
  friendProfilePictureId!: string | undefined;
  unreadMessageCount!: number;
  isOnline!: boolean;
  state!: FriendshipState;

  constructor(data?: IFriendDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.friendUserId = data['friendUserId'];
      this.friendTenantId = data['friendTenantId'];
      this.friendUserName = data['friendUserName'];
      this.friendTenancyName = data['friendTenancyName'];
      this.friendProfilePictureId = data['friendProfilePictureId'];
      this.unreadMessageCount = data['unreadMessageCount'];
      this.isOnline = data['isOnline'];
      this.state = data['state'];
    }
  }

  static fromJS(data: any): FriendDto {
    data = typeof data === 'object' ? data : {};
    let result = new FriendDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['friendUserId'] = this.friendUserId;
    data['friendTenantId'] = this.friendTenantId;
    data['friendUserName'] = this.friendUserName;
    data['friendTenancyName'] = this.friendTenancyName;
    data['friendProfilePictureId'] = this.friendProfilePictureId;
    data['unreadMessageCount'] = this.unreadMessageCount;
    data['isOnline'] = this.isOnline;
    data['state'] = this.state;
    return data;
  }
}

export interface IFriendDto {
  friendUserId: number;
  friendTenantId: number | undefined;
  friendUserName: string | undefined;
  friendTenancyName: string | undefined;
  friendProfilePictureId: string | undefined;
  unreadMessageCount: number;
  isOnline: boolean;
  state: FriendshipState;
}

export class GetUserChatFriendsWithSettingsOutput
  implements IGetUserChatFriendsWithSettingsOutput
{
  serverTime!: moment.Moment;
  friends!: FriendDto[] | undefined;

  constructor(data?: IGetUserChatFriendsWithSettingsOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.serverTime = data['serverTime']
        ? moment(data['serverTime'].toString())
        : <any>undefined;
      if (Array.isArray(data['friends'])) {
        this.friends = [] as any;
        for (let item of data['friends'])
          this.friends!.push(FriendDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): GetUserChatFriendsWithSettingsOutput {
    data = typeof data === 'object' ? data : {};
    let result = new GetUserChatFriendsWithSettingsOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['serverTime'] = this.serverTime
      ? this.serverTime.toISOString()
      : <any>undefined;
    if (Array.isArray(this.friends)) {
      data['friends'] = [];
      for (let item of this.friends) data['friends'].push(item.toJSON());
    }
    return data;
  }
}

export interface IGetUserChatFriendsWithSettingsOutput {
  serverTime: moment.Moment;
  friends: FriendDto[] | undefined;
}

export enum ChatSide {
  Sender = 1,
  Receiver = 2,
}

export enum ChatMessageReadState {
  Unread = 1,
  Read = 2,
}

export class ChatMessageDto implements IChatMessageDto {
  userId!: number;
  tenantId!: number | undefined;
  targetUserId!: number;
  targetTenantId!: number | undefined;
  side!: ChatSide;
  readState!: ChatMessageReadState;
  receiverReadState!: ChatMessageReadState;
  message!: string | undefined;
  creationTime!: moment.Moment;
  sharedMessageId!: string | undefined;
  id!: number;

  constructor(data?: IChatMessageDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.userId = data['userId'];
      this.tenantId = data['tenantId'];
      this.targetUserId = data['targetUserId'];
      this.targetTenantId = data['targetTenantId'];
      this.side = data['side'];
      this.readState = data['readState'];
      this.receiverReadState = data['receiverReadState'];
      this.message = data['message'];
      this.creationTime = data['creationTime']
        ? moment(data['creationTime'].toString())
        : <any>undefined;
      this.sharedMessageId = data['sharedMessageId'];
      this.id = data['id'];
    }
  }

  static fromJS(data: any): ChatMessageDto {
    data = typeof data === 'object' ? data : {};
    let result = new ChatMessageDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['userId'] = this.userId;
    data['tenantId'] = this.tenantId;
    data['targetUserId'] = this.targetUserId;
    data['targetTenantId'] = this.targetTenantId;
    data['side'] = this.side;
    data['readState'] = this.readState;
    data['receiverReadState'] = this.receiverReadState;
    data['message'] = this.message;
    data['creationTime'] = this.creationTime
      ? this.creationTime.toISOString()
      : <any>undefined;
    data['sharedMessageId'] = this.sharedMessageId;
    data['id'] = this.id;
    return data;
  }
}

export interface IChatMessageDto {
  userId: number;
  tenantId: number | undefined;
  targetUserId: number;
  targetTenantId: number | undefined;
  side: ChatSide;
  readState: ChatMessageReadState;
  receiverReadState: ChatMessageReadState;
  message: string | undefined;
  creationTime: moment.Moment;
  sharedMessageId: string | undefined;
  id: number;
}

export class ListResultDtoOfChatMessageDto
  implements IListResultDtoOfChatMessageDto
{
  items!: ChatMessageDto[] | undefined;

  constructor(data?: IListResultDtoOfChatMessageDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['items'])) {
        this.items = [] as any;
        for (let item of data['items'])
          this.items!.push(ChatMessageDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): ListResultDtoOfChatMessageDto {
    data = typeof data === 'object' ? data : {};
    let result = new ListResultDtoOfChatMessageDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.items)) {
      data['items'] = [];
      for (let item of this.items) data['items'].push(item.toJSON());
    }
    return data;
  }
}

export interface IListResultDtoOfChatMessageDto {
  items: ChatMessageDto[] | undefined;
}

export class MarkAllUnreadMessagesOfUserAsReadInput
  implements IMarkAllUnreadMessagesOfUserAsReadInput
{
  tenantId!: number | undefined;
  userId!: number;

  constructor(data?: IMarkAllUnreadMessagesOfUserAsReadInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.tenantId = data['tenantId'];
      this.userId = data['userId'];
    }
  }

  static fromJS(data: any): MarkAllUnreadMessagesOfUserAsReadInput {
    data = typeof data === 'object' ? data : {};
    let result = new MarkAllUnreadMessagesOfUserAsReadInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['tenantId'] = this.tenantId;
    data['userId'] = this.userId;
    return data;
  }
}

export interface IMarkAllUnreadMessagesOfUserAsReadInput {
  tenantId: number | undefined;
  userId: number;
}

export class SubscribableEditionComboboxItemDto
  implements ISubscribableEditionComboboxItemDto
{
  isFree!: boolean | undefined;
  value!: string | undefined;
  displayText!: string | undefined;
  isSelected!: boolean;

  constructor(data?: ISubscribableEditionComboboxItemDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.isFree = data['isFree'];
      this.value = data['value'];
      this.displayText = data['displayText'];
      this.isSelected = data['isSelected'];
    }
  }

  static fromJS(data: any): SubscribableEditionComboboxItemDto {
    data = typeof data === 'object' ? data : {};
    let result = new SubscribableEditionComboboxItemDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['isFree'] = this.isFree;
    data['value'] = this.value;
    data['displayText'] = this.displayText;
    data['isSelected'] = this.isSelected;
    return data;
  }
}

export interface ISubscribableEditionComboboxItemDto {
  isFree: boolean | undefined;
  value: string | undefined;
  displayText: string | undefined;
  isSelected: boolean;
}

export class ListResultDtoOfSubscribableEditionComboboxItemDto
  implements IListResultDtoOfSubscribableEditionComboboxItemDto
{
  items!: SubscribableEditionComboboxItemDto[] | undefined;

  constructor(data?: IListResultDtoOfSubscribableEditionComboboxItemDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['items'])) {
        this.items = [] as any;
        for (let item of data['items'])
          this.items!.push(SubscribableEditionComboboxItemDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): ListResultDtoOfSubscribableEditionComboboxItemDto {
    data = typeof data === 'object' ? data : {};
    let result = new ListResultDtoOfSubscribableEditionComboboxItemDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.items)) {
      data['items'] = [];
      for (let item of this.items) data['items'].push(item.toJSON());
    }
    return data;
  }
}

export interface IListResultDtoOfSubscribableEditionComboboxItemDto {
  items: SubscribableEditionComboboxItemDto[] | undefined;
}

export class FindUsersInput implements IFindUsersInput {
  tenantId!: number | undefined;
  maxResultCount!: number;
  skipCount!: number;
  filter!: string | undefined;

  constructor(data?: IFindUsersInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.tenantId = data['tenantId'];
      this.maxResultCount = data['maxResultCount'];
      this.skipCount = data['skipCount'];
      this.filter = data['filter'];
    }
  }

  static fromJS(data: any): FindUsersInput {
    data = typeof data === 'object' ? data : {};
    let result = new FindUsersInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['tenantId'] = this.tenantId;
    data['maxResultCount'] = this.maxResultCount;
    data['skipCount'] = this.skipCount;
    data['filter'] = this.filter;
    return data;
  }
}

export interface IFindUsersInput {
  tenantId: number | undefined;
  maxResultCount: number;
  skipCount: number;
  filter: string | undefined;
}

export class PagedResultDtoOfNameValueDto
  implements IPagedResultDtoOfNameValueDto
{
  totalCount!: number;
  items!: NameValueDto[] | undefined;

  constructor(data?: IPagedResultDtoOfNameValueDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.totalCount = data['totalCount'];
      if (Array.isArray(data['items'])) {
        this.items = [] as any;
        for (let item of data['items'])
          this.items!.push(NameValueDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): PagedResultDtoOfNameValueDto {
    data = typeof data === 'object' ? data : {};
    let result = new PagedResultDtoOfNameValueDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['totalCount'] = this.totalCount;
    if (Array.isArray(this.items)) {
      data['items'] = [];
      for (let item of this.items) data['items'].push(item.toJSON());
    }
    return data;
  }
}

export interface IPagedResultDtoOfNameValueDto {
  totalCount: number;
  items: NameValueDto[] | undefined;
}

export class GetDefaultEditionNameOutput
  implements IGetDefaultEditionNameOutput
{
  name!: string | undefined;

  constructor(data?: IGetDefaultEditionNameOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.name = data['name'];
    }
  }

  static fromJS(data: any): GetDefaultEditionNameOutput {
    data = typeof data === 'object' ? data : {};
    let result = new GetDefaultEditionNameOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['name'] = this.name;
    return data;
  }
}

export interface IGetDefaultEditionNameOutput {
  name: string | undefined;
}

export class Widget implements IWidget {
  widgetId!: string | undefined;
  height!: number;
  width!: number;
  positionX!: number;
  positionY!: number;

  constructor(data?: IWidget) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.widgetId = data['widgetId'];
      this.height = data['height'];
      this.width = data['width'];
      this.positionX = data['positionX'];
      this.positionY = data['positionY'];
    }
  }

  static fromJS(data: any): Widget {
    data = typeof data === 'object' ? data : {};
    let result = new Widget();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['widgetId'] = this.widgetId;
    data['height'] = this.height;
    data['width'] = this.width;
    data['positionX'] = this.positionX;
    data['positionY'] = this.positionY;
    return data;
  }
}

export interface IWidget {
  widgetId: string | undefined;
  height: number;
  width: number;
  positionX: number;
  positionY: number;
}

export class Page implements IPage {
  id!: string | undefined;
  name!: string | undefined;
  widgets!: Widget[] | undefined;

  constructor(data?: IPage) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id'];
      this.name = data['name'];
      if (Array.isArray(data['widgets'])) {
        this.widgets = [] as any;
        for (let item of data['widgets'])
          this.widgets!.push(Widget.fromJS(item));
      }
    }
  }

  static fromJS(data: any): Page {
    data = typeof data === 'object' ? data : {};
    let result = new Page();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['id'] = this.id;
    data['name'] = this.name;
    if (Array.isArray(this.widgets)) {
      data['widgets'] = [];
      for (let item of this.widgets) data['widgets'].push(item.toJSON());
    }
    return data;
  }
}

export interface IPage {
  id: string | undefined;
  name: string | undefined;
  widgets: Widget[] | undefined;
}

export class Dashboard implements IDashboard {
  dashboardName!: string | undefined;
  pages!: Page[] | undefined;

  constructor(data?: IDashboard) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.dashboardName = data['dashboardName'];
      if (Array.isArray(data['pages'])) {
        this.pages = [] as any;
        for (let item of data['pages']) this.pages!.push(Page.fromJS(item));
      }
    }
  }

  static fromJS(data: any): Dashboard {
    data = typeof data === 'object' ? data : {};
    let result = new Dashboard();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['dashboardName'] = this.dashboardName;
    if (Array.isArray(this.pages)) {
      data['pages'] = [];
      for (let item of this.pages) data['pages'].push(item.toJSON());
    }
    return data;
  }
}

export interface IDashboard {
  dashboardName: string | undefined;
  pages: Page[] | undefined;
}

export class SavePageInput implements ISavePageInput {
  dashboardName!: string | undefined;
  application!: string | undefined;
  pages!: Page[] | undefined;

  constructor(data?: ISavePageInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.dashboardName = data['dashboardName'];
      this.application = data['application'];
      if (Array.isArray(data['pages'])) {
        this.pages = [] as any;
        for (let item of data['pages']) this.pages!.push(Page.fromJS(item));
      }
    }
  }

  static fromJS(data: any): SavePageInput {
    data = typeof data === 'object' ? data : {};
    let result = new SavePageInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['dashboardName'] = this.dashboardName;
    data['application'] = this.application;
    if (Array.isArray(this.pages)) {
      data['pages'] = [];
      for (let item of this.pages) data['pages'].push(item.toJSON());
    }
    return data;
  }
}

export interface ISavePageInput {
  dashboardName: string | undefined;
  application: string | undefined;
  pages: Page[] | undefined;
}

export class RenamePageInput implements IRenamePageInput {
  dashboardName!: string | undefined;
  id!: string | undefined;
  name!: string | undefined;
  application!: string | undefined;

  constructor(data?: IRenamePageInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.dashboardName = data['dashboardName'];
      this.id = data['id'];
      this.name = data['name'];
      this.application = data['application'];
    }
  }

  static fromJS(data: any): RenamePageInput {
    data = typeof data === 'object' ? data : {};
    let result = new RenamePageInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['dashboardName'] = this.dashboardName;
    data['id'] = this.id;
    data['name'] = this.name;
    data['application'] = this.application;
    return data;
  }
}

export interface IRenamePageInput {
  dashboardName: string | undefined;
  id: string | undefined;
  name: string | undefined;
  application: string | undefined;
}

export class AddNewPageInput implements IAddNewPageInput {
  dashboardName!: string | undefined;
  name!: string | undefined;
  application!: string | undefined;

  constructor(data?: IAddNewPageInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.dashboardName = data['dashboardName'];
      this.name = data['name'];
      this.application = data['application'];
    }
  }

  static fromJS(data: any): AddNewPageInput {
    data = typeof data === 'object' ? data : {};
    let result = new AddNewPageInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['dashboardName'] = this.dashboardName;
    data['name'] = this.name;
    data['application'] = this.application;
    return data;
  }
}

export interface IAddNewPageInput {
  dashboardName: string | undefined;
  name: string | undefined;
  application: string | undefined;
}

export class AddNewPageOutput implements IAddNewPageOutput {
  pageId!: string | undefined;

  constructor(data?: IAddNewPageOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.pageId = data['pageId'];
    }
  }

  static fromJS(data: any): AddNewPageOutput {
    data = typeof data === 'object' ? data : {};
    let result = new AddNewPageOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['pageId'] = this.pageId;
    return data;
  }
}

export interface IAddNewPageOutput {
  pageId: string | undefined;
}

export class AddWidgetInput implements IAddWidgetInput {
  widgetId!: string | undefined;
  pageId!: string | undefined;
  dashboardName!: string | undefined;
  width!: number;
  height!: number;
  application!: string | undefined;

  constructor(data?: IAddWidgetInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.widgetId = data['widgetId'];
      this.pageId = data['pageId'];
      this.dashboardName = data['dashboardName'];
      this.width = data['width'];
      this.height = data['height'];
      this.application = data['application'];
    }
  }

  static fromJS(data: any): AddWidgetInput {
    data = typeof data === 'object' ? data : {};
    let result = new AddWidgetInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['widgetId'] = this.widgetId;
    data['pageId'] = this.pageId;
    data['dashboardName'] = this.dashboardName;
    data['width'] = this.width;
    data['height'] = this.height;
    data['application'] = this.application;
    return data;
  }
}

export interface IAddWidgetInput {
  widgetId: string | undefined;
  pageId: string | undefined;
  dashboardName: string | undefined;
  width: number;
  height: number;
  application: string | undefined;
}

export class WidgetFilterOutput implements IWidgetFilterOutput {
  readonly id!: string | undefined;
  readonly name!: string | undefined;

  constructor(data?: IWidgetFilterOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      (<any>this).id = data['id'];
      (<any>this).name = data['name'];
    }
  }

  static fromJS(data: any): WidgetFilterOutput {
    data = typeof data === 'object' ? data : {};
    let result = new WidgetFilterOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['id'] = this.id;
    data['name'] = this.name;
    return data;
  }
}

export interface IWidgetFilterOutput {
  id: string | undefined;
  name: string | undefined;
}

export class WidgetOutput implements IWidgetOutput {
  readonly id!: string | undefined;
  readonly name!: string | undefined;
  readonly description!: string | undefined;
  filters!: WidgetFilterOutput[] | undefined;

  constructor(data?: IWidgetOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      (<any>this).id = data['id'];
      (<any>this).name = data['name'];
      (<any>this).description = data['description'];
      if (Array.isArray(data['filters'])) {
        this.filters = [] as any;
        for (let item of data['filters'])
          this.filters!.push(WidgetFilterOutput.fromJS(item));
      }
    }
  }

  static fromJS(data: any): WidgetOutput {
    data = typeof data === 'object' ? data : {};
    let result = new WidgetOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['id'] = this.id;
    data['name'] = this.name;
    data['description'] = this.description;
    if (Array.isArray(this.filters)) {
      data['filters'] = [];
      for (let item of this.filters) data['filters'].push(item.toJSON());
    }
    return data;
  }
}

export interface IWidgetOutput {
  id: string | undefined;
  name: string | undefined;
  description: string | undefined;
  filters: WidgetFilterOutput[] | undefined;
}

export class DashboardOutput implements IDashboardOutput {
  name!: string | undefined;
  widgets!: WidgetOutput[] | undefined;

  constructor(data?: IDashboardOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.name = data['name'];
      if (Array.isArray(data['widgets'])) {
        this.widgets = [] as any;
        for (let item of data['widgets'])
          this.widgets!.push(WidgetOutput.fromJS(item));
      }
    }
  }

  static fromJS(data: any): DashboardOutput {
    data = typeof data === 'object' ? data : {};
    let result = new DashboardOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['name'] = this.name;
    if (Array.isArray(this.widgets)) {
      data['widgets'] = [];
      for (let item of this.widgets) data['widgets'].push(item.toJSON());
    }
    return data;
  }
}

export interface IDashboardOutput {
  name: string | undefined;
  widgets: WidgetOutput[] | undefined;
}

export class DateToStringOutput implements IDateToStringOutput {
  dateString!: string | undefined;

  constructor(data?: IDateToStringOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.dateString = data['dateString'];
    }
  }

  static fromJS(data: any): DateToStringOutput {
    data = typeof data === 'object' ? data : {};
    let result = new DateToStringOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['dateString'] = this.dateString;
    return data;
  }
}

export interface IDateToStringOutput {
  dateString: string | undefined;
}

export class NameValueOfString implements INameValueOfString {
  name!: string | undefined;
  value!: string | undefined;

  constructor(data?: INameValueOfString) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.name = data['name'];
      this.value = data['value'];
    }
  }

  static fromJS(data: any): NameValueOfString {
    data = typeof data === 'object' ? data : {};
    let result = new NameValueOfString();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['name'] = this.name;
    data['value'] = this.value;
    return data;
  }
}

export interface INameValueOfString {
  name: string | undefined;
  value: string | undefined;
}

export class StringOutput implements IStringOutput {
  output!: string | undefined;

  constructor(data?: IStringOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.output = data['output'];
    }
  }

  static fromJS(data: any): StringOutput {
    data = typeof data === 'object' ? data : {};
    let result = new StringOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['output'] = this.output;
    return data;
  }
}

export interface IStringOutput {
  output: string | undefined;
}

export class EditionListDto implements IEditionListDto {
  name!: string | undefined;
  displayName!: string | undefined;
  dailyPrice!: number | undefined;
  weeklyPrice!: number | undefined;
  monthlyPrice!: number | undefined;
  annualPrice!: number | undefined;
  waitingDayAfterExpire!: number | undefined;
  trialDayCount!: number | undefined;
  expiringEditionDisplayName!: string | undefined;
  id!: number;

  constructor(data?: IEditionListDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.name = data['name'];
      this.displayName = data['displayName'];
      this.dailyPrice = data['dailyPrice'];
      this.weeklyPrice = data['weeklyPrice'];
      this.monthlyPrice = data['monthlyPrice'];
      this.annualPrice = data['annualPrice'];
      this.waitingDayAfterExpire = data['waitingDayAfterExpire'];
      this.trialDayCount = data['trialDayCount'];
      this.expiringEditionDisplayName = data['expiringEditionDisplayName'];
      this.id = data['id'];
    }
  }

  static fromJS(data: any): EditionListDto {
    data = typeof data === 'object' ? data : {};
    let result = new EditionListDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['name'] = this.name;
    data['displayName'] = this.displayName;
    data['dailyPrice'] = this.dailyPrice;
    data['weeklyPrice'] = this.weeklyPrice;
    data['monthlyPrice'] = this.monthlyPrice;
    data['annualPrice'] = this.annualPrice;
    data['waitingDayAfterExpire'] = this.waitingDayAfterExpire;
    data['trialDayCount'] = this.trialDayCount;
    data['expiringEditionDisplayName'] = this.expiringEditionDisplayName;
    data['id'] = this.id;
    return data;
  }
}

export interface IEditionListDto {
  name: string | undefined;
  displayName: string | undefined;
  dailyPrice: number | undefined;
  weeklyPrice: number | undefined;
  monthlyPrice: number | undefined;
  annualPrice: number | undefined;
  waitingDayAfterExpire: number | undefined;
  trialDayCount: number | undefined;
  expiringEditionDisplayName: string | undefined;
  id: number;
}

export class ListResultDtoOfEditionListDto
  implements IListResultDtoOfEditionListDto
{
  items!: EditionListDto[] | undefined;

  constructor(data?: IListResultDtoOfEditionListDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['items'])) {
        this.items = [] as any;
        for (let item of data['items'])
          this.items!.push(EditionListDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): ListResultDtoOfEditionListDto {
    data = typeof data === 'object' ? data : {};
    let result = new ListResultDtoOfEditionListDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.items)) {
      data['items'] = [];
      for (let item of this.items) data['items'].push(item.toJSON());
    }
    return data;
  }
}

export interface IListResultDtoOfEditionListDto {
  items: EditionListDto[] | undefined;
}

export class EditionEditDto implements IEditionEditDto {
  id!: number | undefined;
  displayName!: string | undefined;
  expiringEditionId!: number | undefined;

  constructor(data?: IEditionEditDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id'];
      this.displayName = data['displayName'];
      this.expiringEditionId = data['expiringEditionId'];
    }
  }

  static fromJS(data: any): EditionEditDto {
    data = typeof data === 'object' ? data : {};
    let result = new EditionEditDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['id'] = this.id;
    data['displayName'] = this.displayName;
    data['expiringEditionId'] = this.expiringEditionId;
    return data;
  }
}

export interface IEditionEditDto {
  id: number | undefined;
  displayName: string | undefined;
  expiringEditionId: number | undefined;
}

export class IValueValidator implements IIValueValidator {
  readonly name!: string | undefined;
  readonly attributes!: { [key: string]: any } | undefined;

  constructor(data?: IIValueValidator) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      (<any>this).name = data['name'];
      if (data['attributes']) {
        (<any>this).attributes = {} as any;
        for (let key in data['attributes']) {
          if (data['attributes'].hasOwnProperty(key))
            (<any>this).attributes![key] = data['attributes'][key];
        }
      }
    }
  }

  static fromJS(data: any): IValueValidator {
    data = typeof data === 'object' ? data : {};
    let result = new IValueValidator();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['name'] = this.name;
    if (this.attributes) {
      data['attributes'] = {};
      for (let key in this.attributes) {
        if (this.attributes.hasOwnProperty(key))
          data['attributes'][key] = this.attributes[key];
      }
    }
    return data;
  }
}

export interface IIValueValidator {
  name: string | undefined;
  attributes: { [key: string]: any } | undefined;
}

export class LocalizableComboboxItemDto implements ILocalizableComboboxItemDto {
  value!: string | undefined;
  displayText!: string | undefined;

  constructor(data?: ILocalizableComboboxItemDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.value = data['value'];
      this.displayText = data['displayText'];
    }
  }

  static fromJS(data: any): LocalizableComboboxItemDto {
    data = typeof data === 'object' ? data : {};
    let result = new LocalizableComboboxItemDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['value'] = this.value;
    data['displayText'] = this.displayText;
    return data;
  }
}

export interface ILocalizableComboboxItemDto {
  value: string | undefined;
  displayText: string | undefined;
}

export class LocalizableComboboxItemSourceDto
  implements ILocalizableComboboxItemSourceDto
{
  items!: LocalizableComboboxItemDto[] | undefined;

  constructor(data?: ILocalizableComboboxItemSourceDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['items'])) {
        this.items = [] as any;
        for (let item of data['items'])
          this.items!.push(LocalizableComboboxItemDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): LocalizableComboboxItemSourceDto {
    data = typeof data === 'object' ? data : {};
    let result = new LocalizableComboboxItemSourceDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.items)) {
      data['items'] = [];
      for (let item of this.items) data['items'].push(item.toJSON());
    }
    return data;
  }
}

export interface ILocalizableComboboxItemSourceDto {
  items: LocalizableComboboxItemDto[] | undefined;
}

export class FeatureInputTypeDto implements IFeatureInputTypeDto {
  name!: string | undefined;
  attributes!: { [key: string]: any } | undefined;
  validator!: IValueValidator;
  itemSource!: LocalizableComboboxItemSourceDto;

  constructor(data?: IFeatureInputTypeDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.name = data['name'];
      if (data['attributes']) {
        this.attributes = {} as any;
        for (let key in data['attributes']) {
          if (data['attributes'].hasOwnProperty(key))
            this.attributes![key] = data['attributes'][key];
        }
      }
      this.validator = data['validator']
        ? IValueValidator.fromJS(data['validator'])
        : <any>undefined;
      this.itemSource = data['itemSource']
        ? LocalizableComboboxItemSourceDto.fromJS(data['itemSource'])
        : <any>undefined;
    }
  }

  static fromJS(data: any): FeatureInputTypeDto {
    data = typeof data === 'object' ? data : {};
    let result = new FeatureInputTypeDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['name'] = this.name;
    if (this.attributes) {
      data['attributes'] = {};
      for (let key in this.attributes) {
        if (this.attributes.hasOwnProperty(key))
          data['attributes'][key] = this.attributes[key];
      }
    }
    data['validator'] = this.validator
      ? this.validator.toJSON()
      : <any>undefined;
    data['itemSource'] = this.itemSource
      ? this.itemSource.toJSON()
      : <any>undefined;
    return data;
  }
}

export interface IFeatureInputTypeDto {
  name: string | undefined;
  attributes: { [key: string]: any } | undefined;
  validator: IValueValidator;
  itemSource: LocalizableComboboxItemSourceDto;
}

export class FlatFeatureDto implements IFlatFeatureDto {
  parentName!: string | undefined;
  name!: string | undefined;
  displayName!: string | undefined;
  description!: string | undefined;
  defaultValue!: string | undefined;
  inputType!: FeatureInputTypeDto;

  constructor(data?: IFlatFeatureDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.parentName = data['parentName'];
      this.name = data['name'];
      this.displayName = data['displayName'];
      this.description = data['description'];
      this.defaultValue = data['defaultValue'];
      this.inputType = data['inputType']
        ? FeatureInputTypeDto.fromJS(data['inputType'])
        : <any>undefined;
    }
  }

  static fromJS(data: any): FlatFeatureDto {
    data = typeof data === 'object' ? data : {};
    let result = new FlatFeatureDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['parentName'] = this.parentName;
    data['name'] = this.name;
    data['displayName'] = this.displayName;
    data['description'] = this.description;
    data['defaultValue'] = this.defaultValue;
    data['inputType'] = this.inputType
      ? this.inputType.toJSON()
      : <any>undefined;
    return data;
  }
}

export interface IFlatFeatureDto {
  parentName: string | undefined;
  name: string | undefined;
  displayName: string | undefined;
  description: string | undefined;
  defaultValue: string | undefined;
  inputType: FeatureInputTypeDto;
}

export class GetEditionEditOutput implements IGetEditionEditOutput {
  edition!: EditionEditDto;
  featureValues!: NameValueDto[] | undefined;
  features!: FlatFeatureDto[] | undefined;

  constructor(data?: IGetEditionEditOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.edition = data['edition']
        ? EditionEditDto.fromJS(data['edition'])
        : <any>undefined;
      if (Array.isArray(data['featureValues'])) {
        this.featureValues = [] as any;
        for (let item of data['featureValues'])
          this.featureValues!.push(NameValueDto.fromJS(item));
      }
      if (Array.isArray(data['features'])) {
        this.features = [] as any;
        for (let item of data['features'])
          this.features!.push(FlatFeatureDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): GetEditionEditOutput {
    data = typeof data === 'object' ? data : {};
    let result = new GetEditionEditOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['edition'] = this.edition ? this.edition.toJSON() : <any>undefined;
    if (Array.isArray(this.featureValues)) {
      data['featureValues'] = [];
      for (let item of this.featureValues)
        data['featureValues'].push(item.toJSON());
    }
    if (Array.isArray(this.features)) {
      data['features'] = [];
      for (let item of this.features) data['features'].push(item.toJSON());
    }
    return data;
  }
}

export interface IGetEditionEditOutput {
  edition: EditionEditDto;
  featureValues: NameValueDto[] | undefined;
  features: FlatFeatureDto[] | undefined;
}

export class EditionCreateDto implements IEditionCreateDto {
  id!: number | undefined;
  displayName!: string | undefined;
  dailyPrice!: number | undefined;
  weeklyPrice!: number | undefined;
  monthlyPrice!: number | undefined;
  annualPrice!: number | undefined;
  trialDayCount!: number | undefined;
  waitingDayAfterExpire!: number | undefined;
  expiringEditionId!: number | undefined;

  constructor(data?: IEditionCreateDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id'];
      this.displayName = data['displayName'];
      this.dailyPrice = data['dailyPrice'];
      this.weeklyPrice = data['weeklyPrice'];
      this.monthlyPrice = data['monthlyPrice'];
      this.annualPrice = data['annualPrice'];
      this.trialDayCount = data['trialDayCount'];
      this.waitingDayAfterExpire = data['waitingDayAfterExpire'];
      this.expiringEditionId = data['expiringEditionId'];
    }
  }

  static fromJS(data: any): EditionCreateDto {
    data = typeof data === 'object' ? data : {};
    let result = new EditionCreateDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['id'] = this.id;
    data['displayName'] = this.displayName;
    data['dailyPrice'] = this.dailyPrice;
    data['weeklyPrice'] = this.weeklyPrice;
    data['monthlyPrice'] = this.monthlyPrice;
    data['annualPrice'] = this.annualPrice;
    data['trialDayCount'] = this.trialDayCount;
    data['waitingDayAfterExpire'] = this.waitingDayAfterExpire;
    data['expiringEditionId'] = this.expiringEditionId;
    return data;
  }
}

export interface IEditionCreateDto {
  id: number | undefined;
  displayName: string | undefined;
  dailyPrice: number | undefined;
  weeklyPrice: number | undefined;
  monthlyPrice: number | undefined;
  annualPrice: number | undefined;
  trialDayCount: number | undefined;
  waitingDayAfterExpire: number | undefined;
  expiringEditionId: number | undefined;
}

export class CreateEditionDto implements ICreateEditionDto {
  edition!: EditionCreateDto;
  featureValues!: NameValueDto[] | undefined;

  constructor(data?: ICreateEditionDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
    if (!data) {
      this.edition = new EditionCreateDto();
    }
  }

  init(data?: any) {
    if (data) {
      this.edition = data['edition']
        ? EditionCreateDto.fromJS(data['edition'])
        : new EditionCreateDto();
      if (Array.isArray(data['featureValues'])) {
        this.featureValues = [] as any;
        for (let item of data['featureValues'])
          this.featureValues!.push(NameValueDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): CreateEditionDto {
    data = typeof data === 'object' ? data : {};
    let result = new CreateEditionDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['edition'] = this.edition ? this.edition.toJSON() : <any>undefined;
    if (Array.isArray(this.featureValues)) {
      data['featureValues'] = [];
      for (let item of this.featureValues)
        data['featureValues'].push(item.toJSON());
    }
    return data;
  }
}

export interface ICreateEditionDto {
  edition: EditionCreateDto;
  featureValues: NameValueDto[] | undefined;
}

export class UpdateEditionDto implements IUpdateEditionDto {
  edition!: EditionEditDto;
  featureValues!: NameValueDto[] | undefined;

  constructor(data?: IUpdateEditionDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
    if (!data) {
      this.edition = new EditionEditDto();
    }
  }

  init(data?: any) {
    if (data) {
      this.edition = data['edition']
        ? EditionEditDto.fromJS(data['edition'])
        : new EditionEditDto();
      if (Array.isArray(data['featureValues'])) {
        this.featureValues = [] as any;
        for (let item of data['featureValues'])
          this.featureValues!.push(NameValueDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): UpdateEditionDto {
    data = typeof data === 'object' ? data : {};
    let result = new UpdateEditionDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['edition'] = this.edition ? this.edition.toJSON() : <any>undefined;
    if (Array.isArray(this.featureValues)) {
      data['featureValues'] = [];
      for (let item of this.featureValues)
        data['featureValues'].push(item.toJSON());
    }
    return data;
  }
}

export interface IUpdateEditionDto {
  edition: EditionEditDto;
  featureValues: NameValueDto[] | undefined;
}

export class MoveTenantsToAnotherEditionDto
  implements IMoveTenantsToAnotherEditionDto
{
  sourceEditionId!: number;
  targetEditionId!: number;

  constructor(data?: IMoveTenantsToAnotherEditionDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.sourceEditionId = data['sourceEditionId'];
      this.targetEditionId = data['targetEditionId'];
    }
  }

  static fromJS(data: any): MoveTenantsToAnotherEditionDto {
    data = typeof data === 'object' ? data : {};
    let result = new MoveTenantsToAnotherEditionDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['sourceEditionId'] = this.sourceEditionId;
    data['targetEditionId'] = this.targetEditionId;
    return data;
  }
}

export interface IMoveTenantsToAnotherEditionDto {
  sourceEditionId: number;
  targetEditionId: number;
}

export class ProductDefaultImageSettingsEditDto
  implements IProductDefaultImageSettingsEditDto
{
  useExternalProductImageUrl!: boolean;
  externalProductImageUrl!: string | undefined;
  productDefaultImageId!: string | undefined;
  productDefaultImageFileType!: string | undefined;

  constructor(data?: IProductDefaultImageSettingsEditDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.useExternalProductImageUrl = data['useExternalProductImageUrl'];
      this.externalProductImageUrl = data['externalProductImageUrl'];
      this.productDefaultImageId = data['productDefaultImageId'];
      this.productDefaultImageFileType = data['productDefaultImageFileType'];
    }
  }

  static fromJS(data: any): ProductDefaultImageSettingsEditDto {
    data = typeof data === 'object' ? data : {};
    let result = new ProductDefaultImageSettingsEditDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['useExternalProductImageUrl'] = this.useExternalProductImageUrl;
    data['externalProductImageUrl'] = this.externalProductImageUrl;
    data['productDefaultImageId'] = this.productDefaultImageId;
    data['productDefaultImageFileType'] = this.productDefaultImageFileType;
    return data;
  }
}

export interface IProductDefaultImageSettingsEditDto {
  useExternalProductImageUrl: boolean;
  externalProductImageUrl: string | undefined;
  productDefaultImageId: string | undefined;
  productDefaultImageFileType: string | undefined;
}

export class FittingChargeRateDto implements IFittingChargeRateDto {
  fittingChargeRateId!: number | undefined;
  attributeName!: string | undefined;
  rate!: string | undefined;
  productNumber!: string | undefined;
  fitDescription!: string | undefined;
  addFitDescription!: string | undefined;
  brand!: string | undefined;

  constructor(data?: IFittingChargeRateDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.fittingChargeRateId = data['fittingChargeRateId'];
      this.attributeName = data['attributeName'];
      this.rate = data['rate'];
      this.productNumber = data['productNumber'];
      this.fitDescription = data['fitDescription'];
      this.addFitDescription = data['addFitDescription'];
      this.brand = data['brand'];
    }
  }

  static fromJS(data: any): FittingChargeRateDto {
    data = typeof data === 'object' ? data : {};
    let result = new FittingChargeRateDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['fittingChargeRateId'] = this.fittingChargeRateId;
    data['attributeName'] = this.attributeName;
    data['rate'] = this.rate;
    data['productNumber'] = this.productNumber;
    data['fitDescription'] = this.fitDescription;
    data['addFitDescription'] = this.addFitDescription;
    data['brand'] = this.brand;
    return data;
  }
}

export interface IFittingChargeRateDto {
  fittingChargeRateId: number | undefined;
  attributeName: string | undefined;
  rate: string | undefined;
  productNumber: string | undefined;
  fitDescription: string | undefined;
  addFitDescription: string | undefined;
  brand: string | undefined;
}

export class EPCSettingsEditDto implements IEPCSettingsEditDto {
  productDefaultImage!: ProductDefaultImageSettingsEditDto;
  productGroupBy!: string[] | undefined;
  currencyLabel!: string | undefined;
  reportToEmail!: string | undefined;
  fittingTimeBundleMinsAttribute!: string | undefined;
  fittingChargeRateList!: FittingChargeRateDto[] | undefined;

  constructor(data?: IEPCSettingsEditDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.productDefaultImage = data['productDefaultImage']
        ? ProductDefaultImageSettingsEditDto.fromJS(data['productDefaultImage'])
        : <any>undefined;
      if (Array.isArray(data['productGroupBy'])) {
        this.productGroupBy = [] as any;
        for (let item of data['productGroupBy'])
          this.productGroupBy!.push(item);
      }
      this.currencyLabel = data['currencyLabel'];
      this.reportToEmail = data['reportToEmail'];
      this.fittingTimeBundleMinsAttribute =
        data['fittingTimeBundleMinsAttribute'];
      if (Array.isArray(data['fittingChargeRateList'])) {
        this.fittingChargeRateList = [] as any;
        for (let item of data['fittingChargeRateList'])
          this.fittingChargeRateList!.push(FittingChargeRateDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): EPCSettingsEditDto {
    data = typeof data === 'object' ? data : {};
    let result = new EPCSettingsEditDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['productDefaultImage'] = this.productDefaultImage
      ? this.productDefaultImage.toJSON()
      : <any>undefined;
    if (Array.isArray(this.productGroupBy)) {
      data['productGroupBy'] = [];
      for (let item of this.productGroupBy) data['productGroupBy'].push(item);
    }
    data['currencyLabel'] = this.currencyLabel;
    data['reportToEmail'] = this.reportToEmail;
    data['fittingTimeBundleMinsAttribute'] =
      this.fittingTimeBundleMinsAttribute;
    if (Array.isArray(this.fittingChargeRateList)) {
      data['fittingChargeRateList'] = [];
      for (let item of this.fittingChargeRateList)
        data['fittingChargeRateList'].push(item.toJSON());
    }
    return data;
  }
}

export interface IEPCSettingsEditDto {
  productDefaultImage: ProductDefaultImageSettingsEditDto;
  productGroupBy: string[] | undefined;
  currencyLabel: string | undefined;
  reportToEmail: string | undefined;
  fittingTimeBundleMinsAttribute: string | undefined;
  fittingChargeRateList: FittingChargeRateDto[] | undefined;
}

export class FeedbackDto implements IFeedbackDto {
  email!: string | undefined;
  content!: string | undefined;
  feedbackType!: string | undefined;

  constructor(data?: IFeedbackDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.email = data['email'];
      this.content = data['content'];
      this.feedbackType = data['feedbackType'];
    }
  }

  static fromJS(data: any): FeedbackDto {
    data = typeof data === 'object' ? data : {};
    let result = new FeedbackDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['email'] = this.email;
    data['content'] = this.content;
    data['feedbackType'] = this.feedbackType;
    return data;
  }
}

export interface IFeedbackDto {
  email: string | undefined;
  content: string | undefined;
  feedbackType: string | undefined;
}

export class CriteriaFilterDto implements ICriteriaFilterDto {
  attribute!: string | undefined;
  value!: string | undefined;
  group!: string | undefined;
  isLinkedAttribute!: boolean;
  products!: string | undefined;

  constructor(data?: ICriteriaFilterDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.attribute = data['attribute'];
      this.value = data['value'];
      this.group = data['group'];
      this.isLinkedAttribute = data['isLinkedAttribute'];
      this.products = data['products'];
    }
  }

  static fromJS(data: any): CriteriaFilterDto {
    data = typeof data === 'object' ? data : {};
    let result = new CriteriaFilterDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['attribute'] = this.attribute;
    data['value'] = this.value;
    data['group'] = this.group;
    data['isLinkedAttribute'] = this.isLinkedAttribute;
    data['products'] = this.products;
    return data;
  }
}

export interface ICriteriaFilterDto {
  attribute: string | undefined;
  value: string | undefined;
  group: string | undefined;
  isLinkedAttribute: boolean;
  products: string | undefined;
}

export class CEDCategoryLinkedAttributeValueDto
  implements ICEDCategoryLinkedAttributeValueDto
{
  parentAttributeName!: string | undefined;
  parentAttributeValue!: string | undefined;
  childAttributeName!: string | undefined;
  childAttributeValue!: string | undefined;

  constructor(data?: ICEDCategoryLinkedAttributeValueDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.parentAttributeName = data['parentAttributeName'];
      this.parentAttributeValue = data['parentAttributeValue'];
      this.childAttributeName = data['childAttributeName'];
      this.childAttributeValue = data['childAttributeValue'];
    }
  }

  static fromJS(data: any): CEDCategoryLinkedAttributeValueDto {
    data = typeof data === 'object' ? data : {};
    let result = new CEDCategoryLinkedAttributeValueDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['parentAttributeName'] = this.parentAttributeName;
    data['parentAttributeValue'] = this.parentAttributeValue;
    data['childAttributeName'] = this.childAttributeName;
    data['childAttributeValue'] = this.childAttributeValue;
    return data;
  }
}

export interface ICEDCategoryLinkedAttributeValueDto {
  parentAttributeName: string | undefined;
  parentAttributeValue: string | undefined;
  childAttributeName: string | undefined;
  childAttributeValue: string | undefined;
}

export class BrandFilterDto implements IBrandFilterDto {
  displayText!: string | undefined;
  value!: string | undefined;
  products!: string | undefined;

  constructor(data?: IBrandFilterDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.displayText = data['displayText'];
      this.value = data['value'];
      this.products = data['products'];
    }
  }

  static fromJS(data: any): BrandFilterDto {
    data = typeof data === 'object' ? data : {};
    let result = new BrandFilterDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['displayText'] = this.displayText;
    data['value'] = this.value;
    data['products'] = this.products;
    return data;
  }
}

export interface IBrandFilterDto {
  displayText: string | undefined;
  value: string | undefined;
  products: string | undefined;
}

export class SubBrandFilterDto implements ISubBrandFilterDto {
  displayText!: string | undefined;
  value!: string | undefined;
  products!: string | undefined;

  constructor(data?: ISubBrandFilterDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.displayText = data['displayText'];
      this.value = data['value'];
      this.products = data['products'];
    }
  }

  static fromJS(data: any): SubBrandFilterDto {
    data = typeof data === 'object' ? data : {};
    let result = new SubBrandFilterDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['displayText'] = this.displayText;
    data['value'] = this.value;
    data['products'] = this.products;
    return data;
  }
}

export interface ISubBrandFilterDto {
  displayText: string | undefined;
  value: string | undefined;
  products: string | undefined;
}

export class CategoryFilterDto implements ICategoryFilterDto {
  cedCategoryId!: number;
  name!: string | undefined;
  parentCEDCategoryId!: number | undefined;
  products!: string | undefined;

  constructor(data?: ICategoryFilterDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.cedCategoryId = data['cedCategoryId'];
      this.name = data['name'];
      this.parentCEDCategoryId = data['parentCEDCategoryId'];
      this.products = data['products'];
    }
  }

  static fromJS(data: any): CategoryFilterDto {
    data = typeof data === 'object' ? data : {};
    let result = new CategoryFilterDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['cedCategoryId'] = this.cedCategoryId;
    data['name'] = this.name;
    data['parentCEDCategoryId'] = this.parentCEDCategoryId;
    data['products'] = this.products;
    return data;
  }
}

export interface ICategoryFilterDto {
  cedCategoryId: number;
  name: string | undefined;
  parentCEDCategoryId: number | undefined;
  products: string | undefined;
}

export class GetFilterDataOutput implements IGetFilterDataOutput {
  categoryId!: number;
  vehicleId!: number | undefined;
  brandFilterList!: BrandFilterDto[] | undefined;
  subBrandFilterList!: SubBrandFilterDto[] | undefined;
  categoryFilterList!: CategoryFilterDto[] | undefined;
  groupAttributesFilterList!: CriteriaFilterDto[] | undefined;
  groupAttributesFilterWithRelationshipList!:
    | CEDCategoryLinkedAttributeValueDto[]
    | undefined;

  constructor(data?: IGetFilterDataOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.categoryId = data['categoryId'];
      this.vehicleId = data['vehicleId'];
      if (Array.isArray(data['brandFilterList'])) {
        this.brandFilterList = [] as any;
        for (let item of data['brandFilterList'])
          this.brandFilterList!.push(BrandFilterDto.fromJS(item));
      }
      if (Array.isArray(data['subBrandFilterList'])) {
        this.subBrandFilterList = [] as any;
        for (let item of data['subBrandFilterList'])
          this.subBrandFilterList!.push(SubBrandFilterDto.fromJS(item));
      }
      if (Array.isArray(data['categoryFilterList'])) {
        this.categoryFilterList = [] as any;
        for (let item of data['categoryFilterList'])
          this.categoryFilterList!.push(CategoryFilterDto.fromJS(item));
      }
      if (Array.isArray(data['groupAttributesFilterList'])) {
        this.groupAttributesFilterList = [] as any;
        for (let item of data['groupAttributesFilterList'])
          this.groupAttributesFilterList!.push(CriteriaFilterDto.fromJS(item));
      }
      if (Array.isArray(data['groupAttributesFilterWithRelationshipList'])) {
        this.groupAttributesFilterWithRelationshipList = [] as any;
        for (let item of data['groupAttributesFilterWithRelationshipList'])
          this.groupAttributesFilterWithRelationshipList!.push(
            CEDCategoryLinkedAttributeValueDto.fromJS(item)
          );
      }
    }
  }

  static fromJS(data: any): GetFilterDataOutput {
    data = typeof data === 'object' ? data : {};
    let result = new GetFilterDataOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['categoryId'] = this.categoryId;
    data['vehicleId'] = this.vehicleId;
    if (Array.isArray(this.brandFilterList)) {
      data['brandFilterList'] = [];
      for (let item of this.brandFilterList)
        data['brandFilterList'].push(item.toJSON());
    }
    if (Array.isArray(this.subBrandFilterList)) {
      data['subBrandFilterList'] = [];
      for (let item of this.subBrandFilterList)
        data['subBrandFilterList'].push(item.toJSON());
    }
    if (Array.isArray(this.categoryFilterList)) {
      data['categoryFilterList'] = [];
      for (let item of this.categoryFilterList)
        data['categoryFilterList'].push(item.toJSON());
    }
    if (Array.isArray(this.groupAttributesFilterList)) {
      data['groupAttributesFilterList'] = [];
      for (let item of this.groupAttributesFilterList)
        data['groupAttributesFilterList'].push(item.toJSON());
    }
    if (Array.isArray(this.groupAttributesFilterWithRelationshipList)) {
      data['groupAttributesFilterWithRelationshipList'] = [];
      for (let item of this.groupAttributesFilterWithRelationshipList)
        data['groupAttributesFilterWithRelationshipList'].push(item.toJSON());
    }
    return data;
  }
}

export interface IGetFilterDataOutput {
  categoryId: number;
  vehicleId: number | undefined;
  brandFilterList: BrandFilterDto[] | undefined;
  subBrandFilterList: SubBrandFilterDto[] | undefined;
  categoryFilterList: CategoryFilterDto[] | undefined;
  groupAttributesFilterList: CriteriaFilterDto[] | undefined;
  groupAttributesFilterWithRelationshipList:
    | CEDCategoryLinkedAttributeValueDto[]
    | undefined;
}

export class CreateFriendshipRequestInput
  implements ICreateFriendshipRequestInput
{
  userId!: number;
  tenantId!: number | undefined;

  constructor(data?: ICreateFriendshipRequestInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.userId = data['userId'];
      this.tenantId = data['tenantId'];
    }
  }

  static fromJS(data: any): CreateFriendshipRequestInput {
    data = typeof data === 'object' ? data : {};
    let result = new CreateFriendshipRequestInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['userId'] = this.userId;
    data['tenantId'] = this.tenantId;
    return data;
  }
}

export interface ICreateFriendshipRequestInput {
  userId: number;
  tenantId: number | undefined;
}

export class CreateFriendshipRequestByUserNameInput
  implements ICreateFriendshipRequestByUserNameInput
{
  tenancyName!: string | undefined;
  userName!: string | undefined;

  constructor(data?: ICreateFriendshipRequestByUserNameInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.tenancyName = data['tenancyName'];
      this.userName = data['userName'];
    }
  }

  static fromJS(data: any): CreateFriendshipRequestByUserNameInput {
    data = typeof data === 'object' ? data : {};
    let result = new CreateFriendshipRequestByUserNameInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['tenancyName'] = this.tenancyName;
    data['userName'] = this.userName;
    return data;
  }
}

export interface ICreateFriendshipRequestByUserNameInput {
  tenancyName: string | undefined;
  userName: string | undefined;
}

export class BlockUserInput implements IBlockUserInput {
  userId!: number;
  tenantId!: number | undefined;

  constructor(data?: IBlockUserInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.userId = data['userId'];
      this.tenantId = data['tenantId'];
    }
  }

  static fromJS(data: any): BlockUserInput {
    data = typeof data === 'object' ? data : {};
    let result = new BlockUserInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['userId'] = this.userId;
    data['tenantId'] = this.tenantId;
    return data;
  }
}

export interface IBlockUserInput {
  userId: number;
  tenantId: number | undefined;
}

export class UnblockUserInput implements IUnblockUserInput {
  userId!: number;
  tenantId!: number | undefined;

  constructor(data?: IUnblockUserInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.userId = data['userId'];
      this.tenantId = data['tenantId'];
    }
  }

  static fromJS(data: any): UnblockUserInput {
    data = typeof data === 'object' ? data : {};
    let result = new UnblockUserInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['userId'] = this.userId;
    data['tenantId'] = this.tenantId;
    return data;
  }
}

export interface IUnblockUserInput {
  userId: number;
  tenantId: number | undefined;
}

export class AcceptFriendshipRequestInput
  implements IAcceptFriendshipRequestInput
{
  userId!: number;
  tenantId!: number | undefined;

  constructor(data?: IAcceptFriendshipRequestInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.userId = data['userId'];
      this.tenantId = data['tenantId'];
    }
  }

  static fromJS(data: any): AcceptFriendshipRequestInput {
    data = typeof data === 'object' ? data : {};
    let result = new AcceptFriendshipRequestInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['userId'] = this.userId;
    data['tenantId'] = this.tenantId;
    return data;
  }
}

export interface IAcceptFriendshipRequestInput {
  userId: number;
  tenantId: number | undefined;
}

export class TopStatsData implements ITopStatsData {
  newTenantsCount!: number;
  newSubscriptionAmount!: number;
  dashboardPlaceholder1!: number;
  dashboardPlaceholder2!: number;

  constructor(data?: ITopStatsData) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.newTenantsCount = data['newTenantsCount'];
      this.newSubscriptionAmount = data['newSubscriptionAmount'];
      this.dashboardPlaceholder1 = data['dashboardPlaceholder1'];
      this.dashboardPlaceholder2 = data['dashboardPlaceholder2'];
    }
  }

  static fromJS(data: any): TopStatsData {
    data = typeof data === 'object' ? data : {};
    let result = new TopStatsData();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['newTenantsCount'] = this.newTenantsCount;
    data['newSubscriptionAmount'] = this.newSubscriptionAmount;
    data['dashboardPlaceholder1'] = this.dashboardPlaceholder1;
    data['dashboardPlaceholder2'] = this.dashboardPlaceholder2;
    return data;
  }
}

export interface ITopStatsData {
  newTenantsCount: number;
  newSubscriptionAmount: number;
  dashboardPlaceholder1: number;
  dashboardPlaceholder2: number;
}

export class RecentTenant implements IRecentTenant {
  id!: number;
  name!: string | undefined;
  creationTime!: moment.Moment;

  constructor(data?: IRecentTenant) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id'];
      this.name = data['name'];
      this.creationTime = data['creationTime']
        ? moment(data['creationTime'].toString())
        : <any>undefined;
    }
  }

  static fromJS(data: any): RecentTenant {
    data = typeof data === 'object' ? data : {};
    let result = new RecentTenant();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['id'] = this.id;
    data['name'] = this.name;
    data['creationTime'] = this.creationTime
      ? this.creationTime.toISOString()
      : <any>undefined;
    return data;
  }
}

export interface IRecentTenant {
  id: number;
  name: string | undefined;
  creationTime: moment.Moment;
}

export class GetRecentTenantsOutput implements IGetRecentTenantsOutput {
  recentTenants!: RecentTenant[] | undefined;
  recentTenantsDayCount!: number;
  maxRecentTenantsShownCount!: number;
  tenantCreationStartDate!: moment.Moment;

  constructor(data?: IGetRecentTenantsOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['recentTenants'])) {
        this.recentTenants = [] as any;
        for (let item of data['recentTenants'])
          this.recentTenants!.push(RecentTenant.fromJS(item));
      }
      this.recentTenantsDayCount = data['recentTenantsDayCount'];
      this.maxRecentTenantsShownCount = data['maxRecentTenantsShownCount'];
      this.tenantCreationStartDate = data['tenantCreationStartDate']
        ? moment(data['tenantCreationStartDate'].toString())
        : <any>undefined;
    }
  }

  static fromJS(data: any): GetRecentTenantsOutput {
    data = typeof data === 'object' ? data : {};
    let result = new GetRecentTenantsOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.recentTenants)) {
      data['recentTenants'] = [];
      for (let item of this.recentTenants)
        data['recentTenants'].push(item.toJSON());
    }
    data['recentTenantsDayCount'] = this.recentTenantsDayCount;
    data['maxRecentTenantsShownCount'] = this.maxRecentTenantsShownCount;
    data['tenantCreationStartDate'] = this.tenantCreationStartDate
      ? this.tenantCreationStartDate.toISOString()
      : <any>undefined;
    return data;
  }
}

export interface IGetRecentTenantsOutput {
  recentTenants: RecentTenant[] | undefined;
  recentTenantsDayCount: number;
  maxRecentTenantsShownCount: number;
  tenantCreationStartDate: moment.Moment;
}

export class ExpiringTenant implements IExpiringTenant {
  tenantName!: string | undefined;
  remainingDayCount!: number;

  constructor(data?: IExpiringTenant) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.tenantName = data['tenantName'];
      this.remainingDayCount = data['remainingDayCount'];
    }
  }

  static fromJS(data: any): ExpiringTenant {
    data = typeof data === 'object' ? data : {};
    let result = new ExpiringTenant();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['tenantName'] = this.tenantName;
    data['remainingDayCount'] = this.remainingDayCount;
    return data;
  }
}

export interface IExpiringTenant {
  tenantName: string | undefined;
  remainingDayCount: number;
}

export class GetExpiringTenantsOutput implements IGetExpiringTenantsOutput {
  expiringTenants!: ExpiringTenant[] | undefined;
  subscriptionEndAlertDayCount!: number;
  maxExpiringTenantsShownCount!: number;
  subscriptionEndDateStart!: moment.Moment;
  subscriptionEndDateEnd!: moment.Moment;

  constructor(data?: IGetExpiringTenantsOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['expiringTenants'])) {
        this.expiringTenants = [] as any;
        for (let item of data['expiringTenants'])
          this.expiringTenants!.push(ExpiringTenant.fromJS(item));
      }
      this.subscriptionEndAlertDayCount = data['subscriptionEndAlertDayCount'];
      this.maxExpiringTenantsShownCount = data['maxExpiringTenantsShownCount'];
      this.subscriptionEndDateStart = data['subscriptionEndDateStart']
        ? moment(data['subscriptionEndDateStart'].toString())
        : <any>undefined;
      this.subscriptionEndDateEnd = data['subscriptionEndDateEnd']
        ? moment(data['subscriptionEndDateEnd'].toString())
        : <any>undefined;
    }
  }

  static fromJS(data: any): GetExpiringTenantsOutput {
    data = typeof data === 'object' ? data : {};
    let result = new GetExpiringTenantsOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.expiringTenants)) {
      data['expiringTenants'] = [];
      for (let item of this.expiringTenants)
        data['expiringTenants'].push(item.toJSON());
    }
    data['subscriptionEndAlertDayCount'] = this.subscriptionEndAlertDayCount;
    data['maxExpiringTenantsShownCount'] = this.maxExpiringTenantsShownCount;
    data['subscriptionEndDateStart'] = this.subscriptionEndDateStart
      ? this.subscriptionEndDateStart.toISOString()
      : <any>undefined;
    data['subscriptionEndDateEnd'] = this.subscriptionEndDateEnd
      ? this.subscriptionEndDateEnd.toISOString()
      : <any>undefined;
    return data;
  }
}

export interface IGetExpiringTenantsOutput {
  expiringTenants: ExpiringTenant[] | undefined;
  subscriptionEndAlertDayCount: number;
  maxExpiringTenantsShownCount: number;
  subscriptionEndDateStart: moment.Moment;
  subscriptionEndDateEnd: moment.Moment;
}

export enum ChartDateInterval {
  Daily = 1,
  Weekly = 2,
  Monthly = 3,
}

export class IncomeStastistic implements IIncomeStastistic {
  label!: string | undefined;
  date!: moment.Moment;
  amount!: number;

  constructor(data?: IIncomeStastistic) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.label = data['label'];
      this.date = data['date']
        ? moment(data['date'].toString())
        : <any>undefined;
      this.amount = data['amount'];
    }
  }

  static fromJS(data: any): IncomeStastistic {
    data = typeof data === 'object' ? data : {};
    let result = new IncomeStastistic();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['label'] = this.label;
    data['date'] = this.date ? this.date.toISOString() : <any>undefined;
    data['amount'] = this.amount;
    return data;
  }
}

export interface IIncomeStastistic {
  label: string | undefined;
  date: moment.Moment;
  amount: number;
}

export class GetIncomeStatisticsDataOutput
  implements IGetIncomeStatisticsDataOutput
{
  incomeStatistics!: IncomeStastistic[] | undefined;

  constructor(data?: IGetIncomeStatisticsDataOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['incomeStatistics'])) {
        this.incomeStatistics = [] as any;
        for (let item of data['incomeStatistics'])
          this.incomeStatistics!.push(IncomeStastistic.fromJS(item));
      }
    }
  }

  static fromJS(data: any): GetIncomeStatisticsDataOutput {
    data = typeof data === 'object' ? data : {};
    let result = new GetIncomeStatisticsDataOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.incomeStatistics)) {
      data['incomeStatistics'] = [];
      for (let item of this.incomeStatistics)
        data['incomeStatistics'].push(item.toJSON());
    }
    return data;
  }
}

export interface IGetIncomeStatisticsDataOutput {
  incomeStatistics: IncomeStastistic[] | undefined;
}

export class TenantEdition implements ITenantEdition {
  label!: string | undefined;
  value!: number;

  constructor(data?: ITenantEdition) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.label = data['label'];
      this.value = data['value'];
    }
  }

  static fromJS(data: any): TenantEdition {
    data = typeof data === 'object' ? data : {};
    let result = new TenantEdition();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['label'] = this.label;
    data['value'] = this.value;
    return data;
  }
}

export interface ITenantEdition {
  label: string | undefined;
  value: number;
}

export class GetEditionTenantStatisticsOutput
  implements IGetEditionTenantStatisticsOutput
{
  editionStatistics!: TenantEdition[] | undefined;

  constructor(data?: IGetEditionTenantStatisticsOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['editionStatistics'])) {
        this.editionStatistics = [] as any;
        for (let item of data['editionStatistics'])
          this.editionStatistics!.push(TenantEdition.fromJS(item));
      }
    }
  }

  static fromJS(data: any): GetEditionTenantStatisticsOutput {
    data = typeof data === 'object' ? data : {};
    let result = new GetEditionTenantStatisticsOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.editionStatistics)) {
      data['editionStatistics'] = [];
      for (let item of this.editionStatistics)
        data['editionStatistics'].push(item.toJSON());
    }
    return data;
  }
}

export interface IGetEditionTenantStatisticsOutput {
  editionStatistics: TenantEdition[] | undefined;
}

export class GeneralSettingsEditDto implements IGeneralSettingsEditDto {
  timezone!: string | undefined;
  timezoneForComparison!: string | undefined;

  constructor(data?: IGeneralSettingsEditDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.timezone = data['timezone'];
      this.timezoneForComparison = data['timezoneForComparison'];
    }
  }

  static fromJS(data: any): GeneralSettingsEditDto {
    data = typeof data === 'object' ? data : {};
    let result = new GeneralSettingsEditDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['timezone'] = this.timezone;
    data['timezoneForComparison'] = this.timezoneForComparison;
    return data;
  }
}

export interface IGeneralSettingsEditDto {
  timezone: string | undefined;
  timezoneForComparison: string | undefined;
}

export class SessionTimeOutSettingsEditDto
  implements ISessionTimeOutSettingsEditDto
{
  isEnabled!: boolean;
  timeOutSecond!: number;
  showTimeOutNotificationSecond!: number;

  constructor(data?: ISessionTimeOutSettingsEditDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.isEnabled = data['isEnabled'];
      this.timeOutSecond = data['timeOutSecond'];
      this.showTimeOutNotificationSecond =
        data['showTimeOutNotificationSecond'];
    }
  }

  static fromJS(data: any): SessionTimeOutSettingsEditDto {
    data = typeof data === 'object' ? data : {};
    let result = new SessionTimeOutSettingsEditDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['isEnabled'] = this.isEnabled;
    data['timeOutSecond'] = this.timeOutSecond;
    data['showTimeOutNotificationSecond'] = this.showTimeOutNotificationSecond;
    return data;
  }
}

export interface ISessionTimeOutSettingsEditDto {
  isEnabled: boolean;
  timeOutSecond: number;
  showTimeOutNotificationSecond: number;
}

export class HostUserManagementSettingsEditDto
  implements IHostUserManagementSettingsEditDto
{
  isEmailConfirmationRequiredForLogin!: boolean;
  smsVerificationEnabled!: boolean;
  isCookieConsentEnabled!: boolean;
  isQuickThemeSelectEnabled!: boolean;
  useCaptchaOnLogin!: boolean;
  sessionTimeOutSettings!: SessionTimeOutSettingsEditDto;

  constructor(data?: IHostUserManagementSettingsEditDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.isEmailConfirmationRequiredForLogin =
        data['isEmailConfirmationRequiredForLogin'];
      this.smsVerificationEnabled = data['smsVerificationEnabled'];
      this.isCookieConsentEnabled = data['isCookieConsentEnabled'];
      this.isQuickThemeSelectEnabled = data['isQuickThemeSelectEnabled'];
      this.useCaptchaOnLogin = data['useCaptchaOnLogin'];
      this.sessionTimeOutSettings = data['sessionTimeOutSettings']
        ? SessionTimeOutSettingsEditDto.fromJS(data['sessionTimeOutSettings'])
        : <any>undefined;
    }
  }

  static fromJS(data: any): HostUserManagementSettingsEditDto {
    data = typeof data === 'object' ? data : {};
    let result = new HostUserManagementSettingsEditDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['isEmailConfirmationRequiredForLogin'] =
      this.isEmailConfirmationRequiredForLogin;
    data['smsVerificationEnabled'] = this.smsVerificationEnabled;
    data['isCookieConsentEnabled'] = this.isCookieConsentEnabled;
    data['isQuickThemeSelectEnabled'] = this.isQuickThemeSelectEnabled;
    data['useCaptchaOnLogin'] = this.useCaptchaOnLogin;
    data['sessionTimeOutSettings'] = this.sessionTimeOutSettings
      ? this.sessionTimeOutSettings.toJSON()
      : <any>undefined;
    return data;
  }
}

export interface IHostUserManagementSettingsEditDto {
  isEmailConfirmationRequiredForLogin: boolean;
  smsVerificationEnabled: boolean;
  isCookieConsentEnabled: boolean;
  isQuickThemeSelectEnabled: boolean;
  useCaptchaOnLogin: boolean;
  sessionTimeOutSettings: SessionTimeOutSettingsEditDto;
}

export class EmailSettingsEditDto implements IEmailSettingsEditDto {
  defaultFromAddress!: string | undefined;
  defaultFromDisplayName!: string | undefined;
  smtpHost!: string | undefined;
  smtpPort!: number;
  smtpUserName!: string | undefined;
  smtpPassword!: string | undefined;
  smtpDomain!: string | undefined;
  smtpEnableSsl!: boolean;
  smtpUseDefaultCredentials!: boolean;

  constructor(data?: IEmailSettingsEditDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.defaultFromAddress = data['defaultFromAddress'];
      this.defaultFromDisplayName = data['defaultFromDisplayName'];
      this.smtpHost = data['smtpHost'];
      this.smtpPort = data['smtpPort'];
      this.smtpUserName = data['smtpUserName'];
      this.smtpPassword = data['smtpPassword'];
      this.smtpDomain = data['smtpDomain'];
      this.smtpEnableSsl = data['smtpEnableSsl'];
      this.smtpUseDefaultCredentials = data['smtpUseDefaultCredentials'];
    }
  }

  static fromJS(data: any): EmailSettingsEditDto {
    data = typeof data === 'object' ? data : {};
    let result = new EmailSettingsEditDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['defaultFromAddress'] = this.defaultFromAddress;
    data['defaultFromDisplayName'] = this.defaultFromDisplayName;
    data['smtpHost'] = this.smtpHost;
    data['smtpPort'] = this.smtpPort;
    data['smtpUserName'] = this.smtpUserName;
    data['smtpPassword'] = this.smtpPassword;
    data['smtpDomain'] = this.smtpDomain;
    data['smtpEnableSsl'] = this.smtpEnableSsl;
    data['smtpUseDefaultCredentials'] = this.smtpUseDefaultCredentials;
    return data;
  }
}

export interface IEmailSettingsEditDto {
  defaultFromAddress: string | undefined;
  defaultFromDisplayName: string | undefined;
  smtpHost: string | undefined;
  smtpPort: number;
  smtpUserName: string | undefined;
  smtpPassword: string | undefined;
  smtpDomain: string | undefined;
  smtpEnableSsl: boolean;
  smtpUseDefaultCredentials: boolean;
}

export class TenantManagementSettingsEditDto
  implements ITenantManagementSettingsEditDto
{
  allowSelfRegistration!: boolean;
  isNewRegisteredTenantActiveByDefault!: boolean;
  useCaptchaOnRegistration!: boolean;
  defaultEditionId!: number | undefined;

  constructor(data?: ITenantManagementSettingsEditDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.allowSelfRegistration = data['allowSelfRegistration'];
      this.isNewRegisteredTenantActiveByDefault =
        data['isNewRegisteredTenantActiveByDefault'];
      this.useCaptchaOnRegistration = data['useCaptchaOnRegistration'];
      this.defaultEditionId = data['defaultEditionId'];
    }
  }

  static fromJS(data: any): TenantManagementSettingsEditDto {
    data = typeof data === 'object' ? data : {};
    let result = new TenantManagementSettingsEditDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['allowSelfRegistration'] = this.allowSelfRegistration;
    data['isNewRegisteredTenantActiveByDefault'] =
      this.isNewRegisteredTenantActiveByDefault;
    data['useCaptchaOnRegistration'] = this.useCaptchaOnRegistration;
    data['defaultEditionId'] = this.defaultEditionId;
    return data;
  }
}

export interface ITenantManagementSettingsEditDto {
  allowSelfRegistration: boolean;
  isNewRegisteredTenantActiveByDefault: boolean;
  useCaptchaOnRegistration: boolean;
  defaultEditionId: number | undefined;
}

export class PasswordComplexitySetting implements IPasswordComplexitySetting {
  requireDigit!: boolean;
  requireLowercase!: boolean;
  requireNonAlphanumeric!: boolean;
  requireUppercase!: boolean;
  requiredLength!: number;

  constructor(data?: IPasswordComplexitySetting) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.requireDigit = data['requireDigit'];
      this.requireLowercase = data['requireLowercase'];
      this.requireNonAlphanumeric = data['requireNonAlphanumeric'];
      this.requireUppercase = data['requireUppercase'];
      this.requiredLength = data['requiredLength'];
    }
  }

  static fromJS(data: any): PasswordComplexitySetting {
    data = typeof data === 'object' ? data : {};
    let result = new PasswordComplexitySetting();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['requireDigit'] = this.requireDigit;
    data['requireLowercase'] = this.requireLowercase;
    data['requireNonAlphanumeric'] = this.requireNonAlphanumeric;
    data['requireUppercase'] = this.requireUppercase;
    data['requiredLength'] = this.requiredLength;
    return data;
  }
}

export interface IPasswordComplexitySetting {
  requireDigit: boolean;
  requireLowercase: boolean;
  requireNonAlphanumeric: boolean;
  requireUppercase: boolean;
  requiredLength: number;
}

export class UserLockOutSettingsEditDto implements IUserLockOutSettingsEditDto {
  isEnabled!: boolean;
  maxFailedAccessAttemptsBeforeLockout!: number;
  defaultAccountLockoutSeconds!: number;

  constructor(data?: IUserLockOutSettingsEditDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.isEnabled = data['isEnabled'];
      this.maxFailedAccessAttemptsBeforeLockout =
        data['maxFailedAccessAttemptsBeforeLockout'];
      this.defaultAccountLockoutSeconds = data['defaultAccountLockoutSeconds'];
    }
  }

  static fromJS(data: any): UserLockOutSettingsEditDto {
    data = typeof data === 'object' ? data : {};
    let result = new UserLockOutSettingsEditDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['isEnabled'] = this.isEnabled;
    data['maxFailedAccessAttemptsBeforeLockout'] =
      this.maxFailedAccessAttemptsBeforeLockout;
    data['defaultAccountLockoutSeconds'] = this.defaultAccountLockoutSeconds;
    return data;
  }
}

export interface IUserLockOutSettingsEditDto {
  isEnabled: boolean;
  maxFailedAccessAttemptsBeforeLockout: number;
  defaultAccountLockoutSeconds: number;
}

export class TwoFactorLoginSettingsEditDto
  implements ITwoFactorLoginSettingsEditDto
{
  isEnabledForApplication!: boolean;
  isEnabled!: boolean;
  isEmailProviderEnabled!: boolean;
  isSmsProviderEnabled!: boolean;
  isRememberBrowserEnabled!: boolean;
  isGoogleAuthenticatorEnabled!: boolean;

  constructor(data?: ITwoFactorLoginSettingsEditDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.isEnabledForApplication = data['isEnabledForApplication'];
      this.isEnabled = data['isEnabled'];
      this.isEmailProviderEnabled = data['isEmailProviderEnabled'];
      this.isSmsProviderEnabled = data['isSmsProviderEnabled'];
      this.isRememberBrowserEnabled = data['isRememberBrowserEnabled'];
      this.isGoogleAuthenticatorEnabled = data['isGoogleAuthenticatorEnabled'];
    }
  }

  static fromJS(data: any): TwoFactorLoginSettingsEditDto {
    data = typeof data === 'object' ? data : {};
    let result = new TwoFactorLoginSettingsEditDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['isEnabledForApplication'] = this.isEnabledForApplication;
    data['isEnabled'] = this.isEnabled;
    data['isEmailProviderEnabled'] = this.isEmailProviderEnabled;
    data['isSmsProviderEnabled'] = this.isSmsProviderEnabled;
    data['isRememberBrowserEnabled'] = this.isRememberBrowserEnabled;
    data['isGoogleAuthenticatorEnabled'] = this.isGoogleAuthenticatorEnabled;
    return data;
  }
}

export interface ITwoFactorLoginSettingsEditDto {
  isEnabledForApplication: boolean;
  isEnabled: boolean;
  isEmailProviderEnabled: boolean;
  isSmsProviderEnabled: boolean;
  isRememberBrowserEnabled: boolean;
  isGoogleAuthenticatorEnabled: boolean;
}

export class SecuritySettingsEditDto implements ISecuritySettingsEditDto {
  allowOneConcurrentLoginPerUser!: boolean;
  useDefaultPasswordComplexitySettings!: boolean;
  passwordComplexity!: PasswordComplexitySetting;
  defaultPasswordComplexity!: PasswordComplexitySetting;
  userLockOut!: UserLockOutSettingsEditDto;
  twoFactorLogin!: TwoFactorLoginSettingsEditDto;

  constructor(data?: ISecuritySettingsEditDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.allowOneConcurrentLoginPerUser =
        data['allowOneConcurrentLoginPerUser'];
      this.useDefaultPasswordComplexitySettings =
        data['useDefaultPasswordComplexitySettings'];
      this.passwordComplexity = data['passwordComplexity']
        ? PasswordComplexitySetting.fromJS(data['passwordComplexity'])
        : <any>undefined;
      this.defaultPasswordComplexity = data['defaultPasswordComplexity']
        ? PasswordComplexitySetting.fromJS(data['defaultPasswordComplexity'])
        : <any>undefined;
      this.userLockOut = data['userLockOut']
        ? UserLockOutSettingsEditDto.fromJS(data['userLockOut'])
        : <any>undefined;
      this.twoFactorLogin = data['twoFactorLogin']
        ? TwoFactorLoginSettingsEditDto.fromJS(data['twoFactorLogin'])
        : <any>undefined;
    }
  }

  static fromJS(data: any): SecuritySettingsEditDto {
    data = typeof data === 'object' ? data : {};
    let result = new SecuritySettingsEditDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['allowOneConcurrentLoginPerUser'] =
      this.allowOneConcurrentLoginPerUser;
    data['useDefaultPasswordComplexitySettings'] =
      this.useDefaultPasswordComplexitySettings;
    data['passwordComplexity'] = this.passwordComplexity
      ? this.passwordComplexity.toJSON()
      : <any>undefined;
    data['defaultPasswordComplexity'] = this.defaultPasswordComplexity
      ? this.defaultPasswordComplexity.toJSON()
      : <any>undefined;
    data['userLockOut'] = this.userLockOut
      ? this.userLockOut.toJSON()
      : <any>undefined;
    data['twoFactorLogin'] = this.twoFactorLogin
      ? this.twoFactorLogin.toJSON()
      : <any>undefined;
    return data;
  }
}

export interface ISecuritySettingsEditDto {
  allowOneConcurrentLoginPerUser: boolean;
  useDefaultPasswordComplexitySettings: boolean;
  passwordComplexity: PasswordComplexitySetting;
  defaultPasswordComplexity: PasswordComplexitySetting;
  userLockOut: UserLockOutSettingsEditDto;
  twoFactorLogin: TwoFactorLoginSettingsEditDto;
}

export class HostBillingSettingsEditDto implements IHostBillingSettingsEditDto {
  legalName!: string | undefined;
  address!: string | undefined;

  constructor(data?: IHostBillingSettingsEditDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.legalName = data['legalName'];
      this.address = data['address'];
    }
  }

  static fromJS(data: any): HostBillingSettingsEditDto {
    data = typeof data === 'object' ? data : {};
    let result = new HostBillingSettingsEditDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['legalName'] = this.legalName;
    data['address'] = this.address;
    return data;
  }
}

export interface IHostBillingSettingsEditDto {
  legalName: string | undefined;
  address: string | undefined;
}

export class OtherSettingsEditDto implements IOtherSettingsEditDto {
  isQuickThemeSelectEnabled!: boolean;

  constructor(data?: IOtherSettingsEditDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.isQuickThemeSelectEnabled = data['isQuickThemeSelectEnabled'];
    }
  }

  static fromJS(data: any): OtherSettingsEditDto {
    data = typeof data === 'object' ? data : {};
    let result = new OtherSettingsEditDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['isQuickThemeSelectEnabled'] = this.isQuickThemeSelectEnabled;
    return data;
  }
}

export interface IOtherSettingsEditDto {
  isQuickThemeSelectEnabled: boolean;
}

export class HostSettingsEditDto implements IHostSettingsEditDto {
  general!: GeneralSettingsEditDto;
  userManagement!: HostUserManagementSettingsEditDto;
  email!: EmailSettingsEditDto;
  tenantManagement!: TenantManagementSettingsEditDto;
  security!: SecuritySettingsEditDto;
  billing!: HostBillingSettingsEditDto;
  otherSettings!: OtherSettingsEditDto;

  constructor(data?: IHostSettingsEditDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
    if (!data) {
      this.general = new GeneralSettingsEditDto();
      this.userManagement = new HostUserManagementSettingsEditDto();
      this.email = new EmailSettingsEditDto();
      this.tenantManagement = new TenantManagementSettingsEditDto();
      this.security = new SecuritySettingsEditDto();
    }
  }

  init(data?: any) {
    if (data) {
      this.general = data['general']
        ? GeneralSettingsEditDto.fromJS(data['general'])
        : new GeneralSettingsEditDto();
      this.userManagement = data['userManagement']
        ? HostUserManagementSettingsEditDto.fromJS(data['userManagement'])
        : new HostUserManagementSettingsEditDto();
      this.email = data['email']
        ? EmailSettingsEditDto.fromJS(data['email'])
        : new EmailSettingsEditDto();
      this.tenantManagement = data['tenantManagement']
        ? TenantManagementSettingsEditDto.fromJS(data['tenantManagement'])
        : new TenantManagementSettingsEditDto();
      this.security = data['security']
        ? SecuritySettingsEditDto.fromJS(data['security'])
        : new SecuritySettingsEditDto();
      this.billing = data['billing']
        ? HostBillingSettingsEditDto.fromJS(data['billing'])
        : <any>undefined;
      this.otherSettings = data['otherSettings']
        ? OtherSettingsEditDto.fromJS(data['otherSettings'])
        : <any>undefined;
    }
  }

  static fromJS(data: any): HostSettingsEditDto {
    data = typeof data === 'object' ? data : {};
    let result = new HostSettingsEditDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['general'] = this.general ? this.general.toJSON() : <any>undefined;
    data['userManagement'] = this.userManagement
      ? this.userManagement.toJSON()
      : <any>undefined;
    data['email'] = this.email ? this.email.toJSON() : <any>undefined;
    data['tenantManagement'] = this.tenantManagement
      ? this.tenantManagement.toJSON()
      : <any>undefined;
    data['security'] = this.security ? this.security.toJSON() : <any>undefined;
    data['billing'] = this.billing ? this.billing.toJSON() : <any>undefined;
    data['otherSettings'] = this.otherSettings
      ? this.otherSettings.toJSON()
      : <any>undefined;
    return data;
  }
}

export interface IHostSettingsEditDto {
  general: GeneralSettingsEditDto;
  userManagement: HostUserManagementSettingsEditDto;
  email: EmailSettingsEditDto;
  tenantManagement: TenantManagementSettingsEditDto;
  security: SecuritySettingsEditDto;
  billing: HostBillingSettingsEditDto;
  otherSettings: OtherSettingsEditDto;
}

export class SendTestEmailInput implements ISendTestEmailInput {
  emailAddress!: string | undefined;

  constructor(data?: ISendTestEmailInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.emailAddress = data['emailAddress'];
    }
  }

  static fromJS(data: any): SendTestEmailInput {
    data = typeof data === 'object' ? data : {};
    let result = new SendTestEmailInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['emailAddress'] = this.emailAddress;
    return data;
  }
}

export interface ISendTestEmailInput {
  emailAddress: string | undefined;
}

export class InstallDto implements IInstallDto {
  connectionString!: string | undefined;
  adminPassword!: string | undefined;
  webSiteUrl!: string | undefined;
  serverUrl!: string | undefined;
  defaultLanguage!: string | undefined;
  smtpSettings!: EmailSettingsEditDto;
  billInfo!: HostBillingSettingsEditDto;

  constructor(data?: IInstallDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.connectionString = data['connectionString'];
      this.adminPassword = data['adminPassword'];
      this.webSiteUrl = data['webSiteUrl'];
      this.serverUrl = data['serverUrl'];
      this.defaultLanguage = data['defaultLanguage'];
      this.smtpSettings = data['smtpSettings']
        ? EmailSettingsEditDto.fromJS(data['smtpSettings'])
        : <any>undefined;
      this.billInfo = data['billInfo']
        ? HostBillingSettingsEditDto.fromJS(data['billInfo'])
        : <any>undefined;
    }
  }

  static fromJS(data: any): InstallDto {
    data = typeof data === 'object' ? data : {};
    let result = new InstallDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['connectionString'] = this.connectionString;
    data['adminPassword'] = this.adminPassword;
    data['webSiteUrl'] = this.webSiteUrl;
    data['serverUrl'] = this.serverUrl;
    data['defaultLanguage'] = this.defaultLanguage;
    data['smtpSettings'] = this.smtpSettings
      ? this.smtpSettings.toJSON()
      : <any>undefined;
    data['billInfo'] = this.billInfo ? this.billInfo.toJSON() : <any>undefined;
    return data;
  }
}

export interface IInstallDto {
  connectionString: string | undefined;
  adminPassword: string | undefined;
  webSiteUrl: string | undefined;
  serverUrl: string | undefined;
  defaultLanguage: string | undefined;
  smtpSettings: EmailSettingsEditDto;
  billInfo: HostBillingSettingsEditDto;
}

export class NameValue implements INameValue {
  name!: string | undefined;
  value!: string | undefined;

  constructor(data?: INameValue) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.name = data['name'];
      this.value = data['value'];
    }
  }

  static fromJS(data: any): NameValue {
    data = typeof data === 'object' ? data : {};
    let result = new NameValue();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['name'] = this.name;
    data['value'] = this.value;
    return data;
  }
}

export interface INameValue {
  name: string | undefined;
  value: string | undefined;
}

export class AppSettingsJsonDto implements IAppSettingsJsonDto {
  webSiteUrl!: string | undefined;
  serverSiteUrl!: string | undefined;
  languages!: NameValue[] | undefined;

  constructor(data?: IAppSettingsJsonDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.webSiteUrl = data['webSiteUrl'];
      this.serverSiteUrl = data['serverSiteUrl'];
      if (Array.isArray(data['languages'])) {
        this.languages = [] as any;
        for (let item of data['languages'])
          this.languages!.push(NameValue.fromJS(item));
      }
    }
  }

  static fromJS(data: any): AppSettingsJsonDto {
    data = typeof data === 'object' ? data : {};
    let result = new AppSettingsJsonDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['webSiteUrl'] = this.webSiteUrl;
    data['serverSiteUrl'] = this.serverSiteUrl;
    if (Array.isArray(this.languages)) {
      data['languages'] = [];
      for (let item of this.languages) data['languages'].push(item.toJSON());
    }
    return data;
  }
}

export interface IAppSettingsJsonDto {
  webSiteUrl: string | undefined;
  serverSiteUrl: string | undefined;
  languages: NameValue[] | undefined;
}

export class CheckDatabaseOutput implements ICheckDatabaseOutput {
  isDatabaseExist!: boolean;

  constructor(data?: ICheckDatabaseOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.isDatabaseExist = data['isDatabaseExist'];
    }
  }

  static fromJS(data: any): CheckDatabaseOutput {
    data = typeof data === 'object' ? data : {};
    let result = new CheckDatabaseOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['isDatabaseExist'] = this.isDatabaseExist;
    return data;
  }
}

export interface ICheckDatabaseOutput {
  isDatabaseExist: boolean;
}

export class InvoiceDto implements IInvoiceDto {
  amount!: number;
  editionDisplayName!: string | undefined;
  invoiceNo!: string | undefined;
  invoiceDate!: moment.Moment;
  tenantLegalName!: string | undefined;
  tenantAddress!: string[] | undefined;
  tenantTaxNo!: string | undefined;
  hostLegalName!: string | undefined;
  hostAddress!: string[] | undefined;

  constructor(data?: IInvoiceDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.amount = data['amount'];
      this.editionDisplayName = data['editionDisplayName'];
      this.invoiceNo = data['invoiceNo'];
      this.invoiceDate = data['invoiceDate']
        ? moment(data['invoiceDate'].toString())
        : <any>undefined;
      this.tenantLegalName = data['tenantLegalName'];
      if (Array.isArray(data['tenantAddress'])) {
        this.tenantAddress = [] as any;
        for (let item of data['tenantAddress']) this.tenantAddress!.push(item);
      }
      this.tenantTaxNo = data['tenantTaxNo'];
      this.hostLegalName = data['hostLegalName'];
      if (Array.isArray(data['hostAddress'])) {
        this.hostAddress = [] as any;
        for (let item of data['hostAddress']) this.hostAddress!.push(item);
      }
    }
  }

  static fromJS(data: any): InvoiceDto {
    data = typeof data === 'object' ? data : {};
    let result = new InvoiceDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['amount'] = this.amount;
    data['editionDisplayName'] = this.editionDisplayName;
    data['invoiceNo'] = this.invoiceNo;
    data['invoiceDate'] = this.invoiceDate
      ? this.invoiceDate.toISOString()
      : <any>undefined;
    data['tenantLegalName'] = this.tenantLegalName;
    if (Array.isArray(this.tenantAddress)) {
      data['tenantAddress'] = [];
      for (let item of this.tenantAddress) data['tenantAddress'].push(item);
    }
    data['tenantTaxNo'] = this.tenantTaxNo;
    data['hostLegalName'] = this.hostLegalName;
    if (Array.isArray(this.hostAddress)) {
      data['hostAddress'] = [];
      for (let item of this.hostAddress) data['hostAddress'].push(item);
    }
    return data;
  }
}

export interface IInvoiceDto {
  amount: number;
  editionDisplayName: string | undefined;
  invoiceNo: string | undefined;
  invoiceDate: moment.Moment;
  tenantLegalName: string | undefined;
  tenantAddress: string[] | undefined;
  tenantTaxNo: string | undefined;
  hostLegalName: string | undefined;
  hostAddress: string[] | undefined;
}

export class CreateInvoiceDto implements ICreateInvoiceDto {
  subscriptionPaymentId!: number;

  constructor(data?: ICreateInvoiceDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.subscriptionPaymentId = data['subscriptionPaymentId'];
    }
  }

  static fromJS(data: any): CreateInvoiceDto {
    data = typeof data === 'object' ? data : {};
    let result = new CreateInvoiceDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['subscriptionPaymentId'] = this.subscriptionPaymentId;
    return data;
  }
}

export interface ICreateInvoiceDto {
  subscriptionPaymentId: number;
}

export class ApplicationLanguageListDto implements IApplicationLanguageListDto {
  tenantId!: number | undefined;
  name!: string | undefined;
  displayName!: string | undefined;
  icon!: string | undefined;
  isDisabled!: boolean;
  isDeleted!: boolean;
  deleterUserId!: number | undefined;
  deletionTime!: moment.Moment | undefined;
  lastModificationTime!: moment.Moment | undefined;
  lastModifierUserId!: number | undefined;
  creationTime!: moment.Moment;
  creatorUserId!: number | undefined;
  id!: number;

  constructor(data?: IApplicationLanguageListDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.tenantId = data['tenantId'];
      this.name = data['name'];
      this.displayName = data['displayName'];
      this.icon = data['icon'];
      this.isDisabled = data['isDisabled'];
      this.isDeleted = data['isDeleted'];
      this.deleterUserId = data['deleterUserId'];
      this.deletionTime = data['deletionTime']
        ? moment(data['deletionTime'].toString())
        : <any>undefined;
      this.lastModificationTime = data['lastModificationTime']
        ? moment(data['lastModificationTime'].toString())
        : <any>undefined;
      this.lastModifierUserId = data['lastModifierUserId'];
      this.creationTime = data['creationTime']
        ? moment(data['creationTime'].toString())
        : <any>undefined;
      this.creatorUserId = data['creatorUserId'];
      this.id = data['id'];
    }
  }

  static fromJS(data: any): ApplicationLanguageListDto {
    data = typeof data === 'object' ? data : {};
    let result = new ApplicationLanguageListDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['tenantId'] = this.tenantId;
    data['name'] = this.name;
    data['displayName'] = this.displayName;
    data['icon'] = this.icon;
    data['isDisabled'] = this.isDisabled;
    data['isDeleted'] = this.isDeleted;
    data['deleterUserId'] = this.deleterUserId;
    data['deletionTime'] = this.deletionTime
      ? this.deletionTime.toISOString()
      : <any>undefined;
    data['lastModificationTime'] = this.lastModificationTime
      ? this.lastModificationTime.toISOString()
      : <any>undefined;
    data['lastModifierUserId'] = this.lastModifierUserId;
    data['creationTime'] = this.creationTime
      ? this.creationTime.toISOString()
      : <any>undefined;
    data['creatorUserId'] = this.creatorUserId;
    data['id'] = this.id;
    return data;
  }
}

export interface IApplicationLanguageListDto {
  tenantId: number | undefined;
  name: string | undefined;
  displayName: string | undefined;
  icon: string | undefined;
  isDisabled: boolean;
  isDeleted: boolean;
  deleterUserId: number | undefined;
  deletionTime: moment.Moment | undefined;
  lastModificationTime: moment.Moment | undefined;
  lastModifierUserId: number | undefined;
  creationTime: moment.Moment;
  creatorUserId: number | undefined;
  id: number;
}

export class GetLanguagesOutput implements IGetLanguagesOutput {
  defaultLanguageName!: string | undefined;
  items!: ApplicationLanguageListDto[] | undefined;

  constructor(data?: IGetLanguagesOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.defaultLanguageName = data['defaultLanguageName'];
      if (Array.isArray(data['items'])) {
        this.items = [] as any;
        for (let item of data['items'])
          this.items!.push(ApplicationLanguageListDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): GetLanguagesOutput {
    data = typeof data === 'object' ? data : {};
    let result = new GetLanguagesOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['defaultLanguageName'] = this.defaultLanguageName;
    if (Array.isArray(this.items)) {
      data['items'] = [];
      for (let item of this.items) data['items'].push(item.toJSON());
    }
    return data;
  }
}

export interface IGetLanguagesOutput {
  defaultLanguageName: string | undefined;
  items: ApplicationLanguageListDto[] | undefined;
}

export class ApplicationLanguageEditDto implements IApplicationLanguageEditDto {
  id!: number | undefined;
  name!: string | undefined;
  icon!: string | undefined;
  isEnabled!: boolean;

  constructor(data?: IApplicationLanguageEditDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id'];
      this.name = data['name'];
      this.icon = data['icon'];
      this.isEnabled = data['isEnabled'];
    }
  }

  static fromJS(data: any): ApplicationLanguageEditDto {
    data = typeof data === 'object' ? data : {};
    let result = new ApplicationLanguageEditDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['id'] = this.id;
    data['name'] = this.name;
    data['icon'] = this.icon;
    data['isEnabled'] = this.isEnabled;
    return data;
  }
}

export interface IApplicationLanguageEditDto {
  id: number | undefined;
  name: string | undefined;
  icon: string | undefined;
  isEnabled: boolean;
}

export class ComboboxItemDto implements IComboboxItemDto {
  value!: string | undefined;
  displayText!: string | undefined;
  isSelected!: boolean;

  constructor(data?: IComboboxItemDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.value = data['value'];
      this.displayText = data['displayText'];
      this.isSelected = data['isSelected'];
    }
  }

  static fromJS(data: any): ComboboxItemDto {
    data = typeof data === 'object' ? data : {};
    let result = new ComboboxItemDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['value'] = this.value;
    data['displayText'] = this.displayText;
    data['isSelected'] = this.isSelected;
    return data;
  }
}

export interface IComboboxItemDto {
  value: string | undefined;
  displayText: string | undefined;
  isSelected: boolean;
}

export class GetLanguageForEditOutput implements IGetLanguageForEditOutput {
  language!: ApplicationLanguageEditDto;
  languageNames!: ComboboxItemDto[] | undefined;
  flags!: ComboboxItemDto[] | undefined;

  constructor(data?: IGetLanguageForEditOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.language = data['language']
        ? ApplicationLanguageEditDto.fromJS(data['language'])
        : <any>undefined;
      if (Array.isArray(data['languageNames'])) {
        this.languageNames = [] as any;
        for (let item of data['languageNames'])
          this.languageNames!.push(ComboboxItemDto.fromJS(item));
      }
      if (Array.isArray(data['flags'])) {
        this.flags = [] as any;
        for (let item of data['flags'])
          this.flags!.push(ComboboxItemDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): GetLanguageForEditOutput {
    data = typeof data === 'object' ? data : {};
    let result = new GetLanguageForEditOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['language'] = this.language ? this.language.toJSON() : <any>undefined;
    if (Array.isArray(this.languageNames)) {
      data['languageNames'] = [];
      for (let item of this.languageNames)
        data['languageNames'].push(item.toJSON());
    }
    if (Array.isArray(this.flags)) {
      data['flags'] = [];
      for (let item of this.flags) data['flags'].push(item.toJSON());
    }
    return data;
  }
}

export interface IGetLanguageForEditOutput {
  language: ApplicationLanguageEditDto;
  languageNames: ComboboxItemDto[] | undefined;
  flags: ComboboxItemDto[] | undefined;
}

export class CreateOrUpdateLanguageInput
  implements ICreateOrUpdateLanguageInput
{
  language!: ApplicationLanguageEditDto;

  constructor(data?: ICreateOrUpdateLanguageInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
    if (!data) {
      this.language = new ApplicationLanguageEditDto();
    }
  }

  init(data?: any) {
    if (data) {
      this.language = data['language']
        ? ApplicationLanguageEditDto.fromJS(data['language'])
        : new ApplicationLanguageEditDto();
    }
  }

  static fromJS(data: any): CreateOrUpdateLanguageInput {
    data = typeof data === 'object' ? data : {};
    let result = new CreateOrUpdateLanguageInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['language'] = this.language ? this.language.toJSON() : <any>undefined;
    return data;
  }
}

export interface ICreateOrUpdateLanguageInput {
  language: ApplicationLanguageEditDto;
}

export class SetDefaultLanguageInput implements ISetDefaultLanguageInput {
  name!: string | undefined;

  constructor(data?: ISetDefaultLanguageInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.name = data['name'];
    }
  }

  static fromJS(data: any): SetDefaultLanguageInput {
    data = typeof data === 'object' ? data : {};
    let result = new SetDefaultLanguageInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['name'] = this.name;
    return data;
  }
}

export interface ISetDefaultLanguageInput {
  name: string | undefined;
}

export class LanguageTextListDto implements ILanguageTextListDto {
  key!: string | undefined;
  baseValue!: string | undefined;
  targetValue!: string | undefined;

  constructor(data?: ILanguageTextListDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.key = data['key'];
      this.baseValue = data['baseValue'];
      this.targetValue = data['targetValue'];
    }
  }

  static fromJS(data: any): LanguageTextListDto {
    data = typeof data === 'object' ? data : {};
    let result = new LanguageTextListDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['key'] = this.key;
    data['baseValue'] = this.baseValue;
    data['targetValue'] = this.targetValue;
    return data;
  }
}

export interface ILanguageTextListDto {
  key: string | undefined;
  baseValue: string | undefined;
  targetValue: string | undefined;
}

export class PagedResultDtoOfLanguageTextListDto
  implements IPagedResultDtoOfLanguageTextListDto
{
  totalCount!: number;
  items!: LanguageTextListDto[] | undefined;

  constructor(data?: IPagedResultDtoOfLanguageTextListDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.totalCount = data['totalCount'];
      if (Array.isArray(data['items'])) {
        this.items = [] as any;
        for (let item of data['items'])
          this.items!.push(LanguageTextListDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): PagedResultDtoOfLanguageTextListDto {
    data = typeof data === 'object' ? data : {};
    let result = new PagedResultDtoOfLanguageTextListDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['totalCount'] = this.totalCount;
    if (Array.isArray(this.items)) {
      data['items'] = [];
      for (let item of this.items) data['items'].push(item.toJSON());
    }
    return data;
  }
}

export interface IPagedResultDtoOfLanguageTextListDto {
  totalCount: number;
  items: LanguageTextListDto[] | undefined;
}

export class UpdateLanguageTextInput implements IUpdateLanguageTextInput {
  languageName!: string | undefined;
  sourceName!: string | undefined;
  key!: string | undefined;
  value!: string | undefined;

  constructor(data?: IUpdateLanguageTextInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.languageName = data['languageName'];
      this.sourceName = data['sourceName'];
      this.key = data['key'];
      this.value = data['value'];
    }
  }

  static fromJS(data: any): UpdateLanguageTextInput {
    data = typeof data === 'object' ? data : {};
    let result = new UpdateLanguageTextInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['languageName'] = this.languageName;
    data['sourceName'] = this.sourceName;
    data['key'] = this.key;
    data['value'] = this.value;
    return data;
  }
}

export interface IUpdateLanguageTextInput {
  languageName: string | undefined;
  sourceName: string | undefined;
  key: string | undefined;
  value: string | undefined;
}

export enum UserNotificationState {
  Unread = 0,
  Read = 1,
}

export class NotificationData implements INotificationData {
  readonly type!: string | undefined;
  properties!: { [key: string]: any } | undefined;

  constructor(data?: INotificationData) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      (<any>this).type = data['type'];
      if (data['properties']) {
        this.properties = {} as any;
        for (let key in data['properties']) {
          if (data['properties'].hasOwnProperty(key))
            this.properties![key] = data['properties'][key];
        }
      }
    }
  }

  static fromJS(data: any): NotificationData {
    data = typeof data === 'object' ? data : {};
    let result = new NotificationData();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['type'] = this.type;
    if (this.properties) {
      data['properties'] = {};
      for (let key in this.properties) {
        if (this.properties.hasOwnProperty(key))
          data['properties'][key] = this.properties[key];
      }
    }
    return data;
  }
}

export interface INotificationData {
  type: string | undefined;
  properties: { [key: string]: any } | undefined;
}

export enum NotificationSeverity {
  Info = 0,
  Success = 1,
  Warn = 2,
  Error = 3,
  Fatal = 4,
}

export class TenantNotification implements ITenantNotification {
  tenantId!: number | undefined;
  notificationName!: string | undefined;
  data!: NotificationData;
  entityType!: string | undefined;
  entityTypeName!: string | undefined;
  entityId!: any | undefined;
  severity!: NotificationSeverity;
  creationTime!: moment.Moment;
  id!: string;

  constructor(data?: ITenantNotification) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.tenantId = data['tenantId'];
      this.notificationName = data['notificationName'];
      this.data = data['data']
        ? NotificationData.fromJS(data['data'])
        : <any>undefined;
      this.entityType = data['entityType'];
      this.entityTypeName = data['entityTypeName'];
      this.entityId = data['entityId'];
      this.severity = data['severity'];
      this.creationTime = data['creationTime']
        ? moment(data['creationTime'].toString())
        : <any>undefined;
      this.id = data['id'];
    }
  }

  static fromJS(data: any): TenantNotification {
    data = typeof data === 'object' ? data : {};
    let result = new TenantNotification();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['tenantId'] = this.tenantId;
    data['notificationName'] = this.notificationName;
    data['data'] = this.data ? this.data.toJSON() : <any>undefined;
    data['entityType'] = this.entityType;
    data['entityTypeName'] = this.entityTypeName;
    data['entityId'] = this.entityId;
    data['severity'] = this.severity;
    data['creationTime'] = this.creationTime
      ? this.creationTime.toISOString()
      : <any>undefined;
    data['id'] = this.id;
    return data;
  }
}

export interface ITenantNotification {
  tenantId: number | undefined;
  notificationName: string | undefined;
  data: NotificationData;
  entityType: string | undefined;
  entityTypeName: string | undefined;
  entityId: any | undefined;
  severity: NotificationSeverity;
  creationTime: moment.Moment;
  id: string;
}

export class UserNotification implements IUserNotification {
  tenantId!: number | undefined;
  userId!: number;
  state!: UserNotificationState;
  notification!: TenantNotification;
  id!: string;

  constructor(data?: IUserNotification) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.tenantId = data['tenantId'];
      this.userId = data['userId'];
      this.state = data['state'];
      this.notification = data['notification']
        ? TenantNotification.fromJS(data['notification'])
        : <any>undefined;
      this.id = data['id'];
    }
  }

  static fromJS(data: any): UserNotification {
    data = typeof data === 'object' ? data : {};
    let result = new UserNotification();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['tenantId'] = this.tenantId;
    data['userId'] = this.userId;
    data['state'] = this.state;
    data['notification'] = this.notification
      ? this.notification.toJSON()
      : <any>undefined;
    data['id'] = this.id;
    return data;
  }
}

export interface IUserNotification {
  tenantId: number | undefined;
  userId: number;
  state: UserNotificationState;
  notification: TenantNotification;
  id: string;
}

export class GetNotificationsOutput implements IGetNotificationsOutput {
  unreadCount!: number;
  totalCount!: number;
  items!: UserNotification[] | undefined;

  constructor(data?: IGetNotificationsOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.unreadCount = data['unreadCount'];
      this.totalCount = data['totalCount'];
      if (Array.isArray(data['items'])) {
        this.items = [] as any;
        for (let item of data['items'])
          this.items!.push(UserNotification.fromJS(item));
      }
    }
  }

  static fromJS(data: any): GetNotificationsOutput {
    data = typeof data === 'object' ? data : {};
    let result = new GetNotificationsOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['unreadCount'] = this.unreadCount;
    data['totalCount'] = this.totalCount;
    if (Array.isArray(this.items)) {
      data['items'] = [];
      for (let item of this.items) data['items'].push(item.toJSON());
    }
    return data;
  }
}

export interface IGetNotificationsOutput {
  unreadCount: number;
  totalCount: number;
  items: UserNotification[] | undefined;
}

export class EntityDtoOfGuid implements IEntityDtoOfGuid {
  id!: string;

  constructor(data?: IEntityDtoOfGuid) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id'];
    }
  }

  static fromJS(data: any): EntityDtoOfGuid {
    data = typeof data === 'object' ? data : {};
    let result = new EntityDtoOfGuid();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['id'] = this.id;
    return data;
  }
}

export interface IEntityDtoOfGuid {
  id: string;
}

export class NotificationSubscriptionWithDisplayNameDto
  implements INotificationSubscriptionWithDisplayNameDto
{
  displayName!: string | undefined;
  description!: string | undefined;
  name!: string | undefined;
  isSubscribed!: boolean;

  constructor(data?: INotificationSubscriptionWithDisplayNameDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.displayName = data['displayName'];
      this.description = data['description'];
      this.name = data['name'];
      this.isSubscribed = data['isSubscribed'];
    }
  }

  static fromJS(data: any): NotificationSubscriptionWithDisplayNameDto {
    data = typeof data === 'object' ? data : {};
    let result = new NotificationSubscriptionWithDisplayNameDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['displayName'] = this.displayName;
    data['description'] = this.description;
    data['name'] = this.name;
    data['isSubscribed'] = this.isSubscribed;
    return data;
  }
}

export interface INotificationSubscriptionWithDisplayNameDto {
  displayName: string | undefined;
  description: string | undefined;
  name: string | undefined;
  isSubscribed: boolean;
}

export class GetNotificationSettingsOutput
  implements IGetNotificationSettingsOutput
{
  receiveNotifications!: boolean;
  notifications!: NotificationSubscriptionWithDisplayNameDto[] | undefined;

  constructor(data?: IGetNotificationSettingsOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.receiveNotifications = data['receiveNotifications'];
      if (Array.isArray(data['notifications'])) {
        this.notifications = [] as any;
        for (let item of data['notifications'])
          this.notifications!.push(
            NotificationSubscriptionWithDisplayNameDto.fromJS(item)
          );
      }
    }
  }

  static fromJS(data: any): GetNotificationSettingsOutput {
    data = typeof data === 'object' ? data : {};
    let result = new GetNotificationSettingsOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['receiveNotifications'] = this.receiveNotifications;
    if (Array.isArray(this.notifications)) {
      data['notifications'] = [];
      for (let item of this.notifications)
        data['notifications'].push(item.toJSON());
    }
    return data;
  }
}

export interface IGetNotificationSettingsOutput {
  receiveNotifications: boolean;
  notifications: NotificationSubscriptionWithDisplayNameDto[] | undefined;
}

export class NotificationSubscriptionDto
  implements INotificationSubscriptionDto
{
  name!: string | undefined;
  isSubscribed!: boolean;

  constructor(data?: INotificationSubscriptionDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.name = data['name'];
      this.isSubscribed = data['isSubscribed'];
    }
  }

  static fromJS(data: any): NotificationSubscriptionDto {
    data = typeof data === 'object' ? data : {};
    let result = new NotificationSubscriptionDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['name'] = this.name;
    data['isSubscribed'] = this.isSubscribed;
    return data;
  }
}

export interface INotificationSubscriptionDto {
  name: string | undefined;
  isSubscribed: boolean;
}

export class UpdateNotificationSettingsInput
  implements IUpdateNotificationSettingsInput
{
  receiveNotifications!: boolean;
  notifications!: NotificationSubscriptionDto[] | undefined;

  constructor(data?: IUpdateNotificationSettingsInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.receiveNotifications = data['receiveNotifications'];
      if (Array.isArray(data['notifications'])) {
        this.notifications = [] as any;
        for (let item of data['notifications'])
          this.notifications!.push(NotificationSubscriptionDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): UpdateNotificationSettingsInput {
    data = typeof data === 'object' ? data : {};
    let result = new UpdateNotificationSettingsInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['receiveNotifications'] = this.receiveNotifications;
    if (Array.isArray(this.notifications)) {
      data['notifications'] = [];
      for (let item of this.notifications)
        data['notifications'].push(item.toJSON());
    }
    return data;
  }
}

export interface IUpdateNotificationSettingsInput {
  receiveNotifications: boolean;
  notifications: NotificationSubscriptionDto[] | undefined;
}

export class OrganizationUnitDto implements IOrganizationUnitDto {
  parentId!: number | undefined;
  code!: string | undefined;
  displayName!: string | undefined;
  memberCount!: number;
  roleCount!: number;
  lastModificationTime!: moment.Moment | undefined;
  lastModifierUserId!: number | undefined;
  creationTime!: moment.Moment;
  creatorUserId!: number | undefined;
  id!: number;

  constructor(data?: IOrganizationUnitDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.parentId = data['parentId'];
      this.code = data['code'];
      this.displayName = data['displayName'];
      this.memberCount = data['memberCount'];
      this.roleCount = data['roleCount'];
      this.lastModificationTime = data['lastModificationTime']
        ? moment(data['lastModificationTime'].toString())
        : <any>undefined;
      this.lastModifierUserId = data['lastModifierUserId'];
      this.creationTime = data['creationTime']
        ? moment(data['creationTime'].toString())
        : <any>undefined;
      this.creatorUserId = data['creatorUserId'];
      this.id = data['id'];
    }
  }

  static fromJS(data: any): OrganizationUnitDto {
    data = typeof data === 'object' ? data : {};
    let result = new OrganizationUnitDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['parentId'] = this.parentId;
    data['code'] = this.code;
    data['displayName'] = this.displayName;
    data['memberCount'] = this.memberCount;
    data['roleCount'] = this.roleCount;
    data['lastModificationTime'] = this.lastModificationTime
      ? this.lastModificationTime.toISOString()
      : <any>undefined;
    data['lastModifierUserId'] = this.lastModifierUserId;
    data['creationTime'] = this.creationTime
      ? this.creationTime.toISOString()
      : <any>undefined;
    data['creatorUserId'] = this.creatorUserId;
    data['id'] = this.id;
    return data;
  }
}

export interface IOrganizationUnitDto {
  parentId: number | undefined;
  code: string | undefined;
  displayName: string | undefined;
  memberCount: number;
  roleCount: number;
  lastModificationTime: moment.Moment | undefined;
  lastModifierUserId: number | undefined;
  creationTime: moment.Moment;
  creatorUserId: number | undefined;
  id: number;
}

export class ListResultDtoOfOrganizationUnitDto
  implements IListResultDtoOfOrganizationUnitDto
{
  items!: OrganizationUnitDto[] | undefined;

  constructor(data?: IListResultDtoOfOrganizationUnitDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['items'])) {
        this.items = [] as any;
        for (let item of data['items'])
          this.items!.push(OrganizationUnitDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): ListResultDtoOfOrganizationUnitDto {
    data = typeof data === 'object' ? data : {};
    let result = new ListResultDtoOfOrganizationUnitDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.items)) {
      data['items'] = [];
      for (let item of this.items) data['items'].push(item.toJSON());
    }
    return data;
  }
}

export interface IListResultDtoOfOrganizationUnitDto {
  items: OrganizationUnitDto[] | undefined;
}

export class OrganizationUnitUserListDto
  implements IOrganizationUnitUserListDto
{
  name!: string | undefined;
  surname!: string | undefined;
  userName!: string | undefined;
  emailAddress!: string | undefined;
  profilePictureId!: string | undefined;
  addedTime!: moment.Moment;
  id!: number;

  constructor(data?: IOrganizationUnitUserListDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.name = data['name'];
      this.surname = data['surname'];
      this.userName = data['userName'];
      this.emailAddress = data['emailAddress'];
      this.profilePictureId = data['profilePictureId'];
      this.addedTime = data['addedTime']
        ? moment(data['addedTime'].toString())
        : <any>undefined;
      this.id = data['id'];
    }
  }

  static fromJS(data: any): OrganizationUnitUserListDto {
    data = typeof data === 'object' ? data : {};
    let result = new OrganizationUnitUserListDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['name'] = this.name;
    data['surname'] = this.surname;
    data['userName'] = this.userName;
    data['emailAddress'] = this.emailAddress;
    data['profilePictureId'] = this.profilePictureId;
    data['addedTime'] = this.addedTime
      ? this.addedTime.toISOString()
      : <any>undefined;
    data['id'] = this.id;
    return data;
  }
}

export interface IOrganizationUnitUserListDto {
  name: string | undefined;
  surname: string | undefined;
  userName: string | undefined;
  emailAddress: string | undefined;
  profilePictureId: string | undefined;
  addedTime: moment.Moment;
  id: number;
}

export class PagedResultDtoOfOrganizationUnitUserListDto
  implements IPagedResultDtoOfOrganizationUnitUserListDto
{
  totalCount!: number;
  items!: OrganizationUnitUserListDto[] | undefined;

  constructor(data?: IPagedResultDtoOfOrganizationUnitUserListDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.totalCount = data['totalCount'];
      if (Array.isArray(data['items'])) {
        this.items = [] as any;
        for (let item of data['items'])
          this.items!.push(OrganizationUnitUserListDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): PagedResultDtoOfOrganizationUnitUserListDto {
    data = typeof data === 'object' ? data : {};
    let result = new PagedResultDtoOfOrganizationUnitUserListDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['totalCount'] = this.totalCount;
    if (Array.isArray(this.items)) {
      data['items'] = [];
      for (let item of this.items) data['items'].push(item.toJSON());
    }
    return data;
  }
}

export interface IPagedResultDtoOfOrganizationUnitUserListDto {
  totalCount: number;
  items: OrganizationUnitUserListDto[] | undefined;
}

export class OrganizationUnitRoleListDto
  implements IOrganizationUnitRoleListDto
{
  displayName!: string | undefined;
  name!: string | undefined;
  addedTime!: moment.Moment;
  id!: number;

  constructor(data?: IOrganizationUnitRoleListDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.displayName = data['displayName'];
      this.name = data['name'];
      this.addedTime = data['addedTime']
        ? moment(data['addedTime'].toString())
        : <any>undefined;
      this.id = data['id'];
    }
  }

  static fromJS(data: any): OrganizationUnitRoleListDto {
    data = typeof data === 'object' ? data : {};
    let result = new OrganizationUnitRoleListDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['displayName'] = this.displayName;
    data['name'] = this.name;
    data['addedTime'] = this.addedTime
      ? this.addedTime.toISOString()
      : <any>undefined;
    data['id'] = this.id;
    return data;
  }
}

export interface IOrganizationUnitRoleListDto {
  displayName: string | undefined;
  name: string | undefined;
  addedTime: moment.Moment;
  id: number;
}

export class PagedResultDtoOfOrganizationUnitRoleListDto
  implements IPagedResultDtoOfOrganizationUnitRoleListDto
{
  totalCount!: number;
  items!: OrganizationUnitRoleListDto[] | undefined;

  constructor(data?: IPagedResultDtoOfOrganizationUnitRoleListDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.totalCount = data['totalCount'];
      if (Array.isArray(data['items'])) {
        this.items = [] as any;
        for (let item of data['items'])
          this.items!.push(OrganizationUnitRoleListDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): PagedResultDtoOfOrganizationUnitRoleListDto {
    data = typeof data === 'object' ? data : {};
    let result = new PagedResultDtoOfOrganizationUnitRoleListDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['totalCount'] = this.totalCount;
    if (Array.isArray(this.items)) {
      data['items'] = [];
      for (let item of this.items) data['items'].push(item.toJSON());
    }
    return data;
  }
}

export interface IPagedResultDtoOfOrganizationUnitRoleListDto {
  totalCount: number;
  items: OrganizationUnitRoleListDto[] | undefined;
}

export class CreateOrganizationUnitInput
  implements ICreateOrganizationUnitInput
{
  parentId!: number | undefined;
  displayName!: string | undefined;

  constructor(data?: ICreateOrganizationUnitInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.parentId = data['parentId'];
      this.displayName = data['displayName'];
    }
  }

  static fromJS(data: any): CreateOrganizationUnitInput {
    data = typeof data === 'object' ? data : {};
    let result = new CreateOrganizationUnitInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['parentId'] = this.parentId;
    data['displayName'] = this.displayName;
    return data;
  }
}

export interface ICreateOrganizationUnitInput {
  parentId: number | undefined;
  displayName: string | undefined;
}

export class UpdateOrganizationUnitInput
  implements IUpdateOrganizationUnitInput
{
  id!: number;
  displayName!: string | undefined;

  constructor(data?: IUpdateOrganizationUnitInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id'];
      this.displayName = data['displayName'];
    }
  }

  static fromJS(data: any): UpdateOrganizationUnitInput {
    data = typeof data === 'object' ? data : {};
    let result = new UpdateOrganizationUnitInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['id'] = this.id;
    data['displayName'] = this.displayName;
    return data;
  }
}

export interface IUpdateOrganizationUnitInput {
  id: number;
  displayName: string | undefined;
}

export class MoveOrganizationUnitInput implements IMoveOrganizationUnitInput {
  id!: number;
  newParentId!: number | undefined;

  constructor(data?: IMoveOrganizationUnitInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id'];
      this.newParentId = data['newParentId'];
    }
  }

  static fromJS(data: any): MoveOrganizationUnitInput {
    data = typeof data === 'object' ? data : {};
    let result = new MoveOrganizationUnitInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['id'] = this.id;
    data['newParentId'] = this.newParentId;
    return data;
  }
}

export interface IMoveOrganizationUnitInput {
  id: number;
  newParentId: number | undefined;
}

export class UsersToOrganizationUnitInput
  implements IUsersToOrganizationUnitInput
{
  userIds!: number[] | undefined;
  organizationUnitId!: number;

  constructor(data?: IUsersToOrganizationUnitInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['userIds'])) {
        this.userIds = [] as any;
        for (let item of data['userIds']) this.userIds!.push(item);
      }
      this.organizationUnitId = data['organizationUnitId'];
    }
  }

  static fromJS(data: any): UsersToOrganizationUnitInput {
    data = typeof data === 'object' ? data : {};
    let result = new UsersToOrganizationUnitInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.userIds)) {
      data['userIds'] = [];
      for (let item of this.userIds) data['userIds'].push(item);
    }
    data['organizationUnitId'] = this.organizationUnitId;
    return data;
  }
}

export interface IUsersToOrganizationUnitInput {
  userIds: number[] | undefined;
  organizationUnitId: number;
}

export class RolesToOrganizationUnitInput
  implements IRolesToOrganizationUnitInput
{
  roleIds!: number[] | undefined;
  organizationUnitId!: number;

  constructor(data?: IRolesToOrganizationUnitInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['roleIds'])) {
        this.roleIds = [] as any;
        for (let item of data['roleIds']) this.roleIds!.push(item);
      }
      this.organizationUnitId = data['organizationUnitId'];
    }
  }

  static fromJS(data: any): RolesToOrganizationUnitInput {
    data = typeof data === 'object' ? data : {};
    let result = new RolesToOrganizationUnitInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.roleIds)) {
      data['roleIds'] = [];
      for (let item of this.roleIds) data['roleIds'].push(item);
    }
    data['organizationUnitId'] = this.organizationUnitId;
    return data;
  }
}

export interface IRolesToOrganizationUnitInput {
  roleIds: number[] | undefined;
  organizationUnitId: number;
}

export class FindOrganizationUnitUsersInput
  implements IFindOrganizationUnitUsersInput
{
  organizationUnitId!: number;
  maxResultCount!: number;
  skipCount!: number;
  filter!: string | undefined;

  constructor(data?: IFindOrganizationUnitUsersInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.organizationUnitId = data['organizationUnitId'];
      this.maxResultCount = data['maxResultCount'];
      this.skipCount = data['skipCount'];
      this.filter = data['filter'];
    }
  }

  static fromJS(data: any): FindOrganizationUnitUsersInput {
    data = typeof data === 'object' ? data : {};
    let result = new FindOrganizationUnitUsersInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['organizationUnitId'] = this.organizationUnitId;
    data['maxResultCount'] = this.maxResultCount;
    data['skipCount'] = this.skipCount;
    data['filter'] = this.filter;
    return data;
  }
}

export interface IFindOrganizationUnitUsersInput {
  organizationUnitId: number;
  maxResultCount: number;
  skipCount: number;
  filter: string | undefined;
}

export class FindOrganizationUnitRolesInput
  implements IFindOrganizationUnitRolesInput
{
  organizationUnitId!: number;
  maxResultCount!: number;
  skipCount!: number;
  filter!: string | undefined;

  constructor(data?: IFindOrganizationUnitRolesInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.organizationUnitId = data['organizationUnitId'];
      this.maxResultCount = data['maxResultCount'];
      this.skipCount = data['skipCount'];
      this.filter = data['filter'];
    }
  }

  static fromJS(data: any): FindOrganizationUnitRolesInput {
    data = typeof data === 'object' ? data : {};
    let result = new FindOrganizationUnitRolesInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['organizationUnitId'] = this.organizationUnitId;
    data['maxResultCount'] = this.maxResultCount;
    data['skipCount'] = this.skipCount;
    data['filter'] = this.filter;
    return data;
  }
}

export interface IFindOrganizationUnitRolesInput {
  organizationUnitId: number;
  maxResultCount: number;
  skipCount: number;
  filter: string | undefined;
}

export class PageContentStaticDto implements IPageContentStaticDto {
  content!: string | undefined;
  page!: string | undefined;

  constructor(data?: IPageContentStaticDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.content = data['content'];
      this.page = data['page'];
    }
  }

  static fromJS(data: any): PageContentStaticDto {
    data = typeof data === 'object' ? data : {};
    let result = new PageContentStaticDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['content'] = this.content;
    data['page'] = this.page;
    return data;
  }
}

export interface IPageContentStaticDto {
  content: string | undefined;
  page: string | undefined;
}

export class EditionSelectDto implements IEditionSelectDto {
  id!: number;
  name!: string | undefined;
  displayName!: string | undefined;
  expiringEditionId!: number | undefined;
  dailyPrice!: number | undefined;
  weeklyPrice!: number | undefined;
  monthlyPrice!: number | undefined;
  annualPrice!: number | undefined;
  trialDayCount!: number | undefined;
  waitingDayAfterExpire!: number | undefined;
  isFree!: boolean;
  additionalData!: AdditionalData | undefined;

  constructor(data?: IEditionSelectDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id'];
      this.name = data['name'];
      this.displayName = data['displayName'];
      this.expiringEditionId = data['expiringEditionId'];
      this.dailyPrice = data['dailyPrice'];
      this.weeklyPrice = data['weeklyPrice'];
      this.monthlyPrice = data['monthlyPrice'];
      this.annualPrice = data['annualPrice'];
      this.trialDayCount = data['trialDayCount'];
      this.waitingDayAfterExpire = data['waitingDayAfterExpire'];
      this.isFree = data['isFree'];
      this.additionalData = data['additionalData']
        ? AdditionalData.fromJS(data['additionalData'])
        : <any>undefined;
    }
  }

  static fromJS(data: any): EditionSelectDto {
    data = typeof data === 'object' ? data : {};
    let result = new EditionSelectDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['id'] = this.id;
    data['name'] = this.name;
    data['displayName'] = this.displayName;
    data['expiringEditionId'] = this.expiringEditionId;
    data['dailyPrice'] = this.dailyPrice;
    data['weeklyPrice'] = this.weeklyPrice;
    data['monthlyPrice'] = this.monthlyPrice;
    data['annualPrice'] = this.annualPrice;
    data['trialDayCount'] = this.trialDayCount;
    data['waitingDayAfterExpire'] = this.waitingDayAfterExpire;
    data['isFree'] = this.isFree;
    data['additionalData'] = this.additionalData
      ? this.additionalData.toJSON()
      : <any>undefined;
    return data;
  }
}

export interface IEditionSelectDto {
  id: number;
  name: string | undefined;
  displayName: string | undefined;
  expiringEditionId: number | undefined;
  dailyPrice: number | undefined;
  weeklyPrice: number | undefined;
  monthlyPrice: number | undefined;
  annualPrice: number | undefined;
  trialDayCount: number | undefined;
  waitingDayAfterExpire: number | undefined;
  isFree: boolean;
  additionalData: AdditionalData | undefined;
}

export class PaymentInfoDto implements IPaymentInfoDto {
  edition!: EditionSelectDto;
  additionalPrice!: number;

  constructor(data?: IPaymentInfoDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.edition = data['edition']
        ? EditionSelectDto.fromJS(data['edition'])
        : <any>undefined;
      this.additionalPrice = data['additionalPrice'];
    }
  }

  static fromJS(data: any): PaymentInfoDto {
    data = typeof data === 'object' ? data : {};
    let result = new PaymentInfoDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['edition'] = this.edition ? this.edition.toJSON() : <any>undefined;
    data['additionalPrice'] = this.additionalPrice;
    return data;
  }
}

export interface IPaymentInfoDto {
  edition: EditionSelectDto;
  additionalPrice: number;
}

export enum EditionPaymentType {
  NewRegistration = 0,
  BuyNow = 1,
  Upgrade = 2,
  Extend = 3,
}

export enum PaymentPeriodType {
  Daily = 1,
  Weekly = 7,
  Monthly = 30,
  Annual = 365,
}

export enum SubscriptionPaymentGatewayType {
  Paypal = 1,
  Stripe = 2,
}

export class CreatePaymentDto implements ICreatePaymentDto {
  editionId!: number;
  editionPaymentType!: EditionPaymentType;
  paymentPeriodType!: PaymentPeriodType;
  subscriptionPaymentGatewayType!: SubscriptionPaymentGatewayType;
  recurringPaymentEnabled!: boolean;
  successUrl!: string | undefined;
  errorUrl!: string | undefined;

  constructor(data?: ICreatePaymentDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.editionId = data['editionId'];
      this.editionPaymentType = data['editionPaymentType'];
      this.paymentPeriodType = data['paymentPeriodType'];
      this.subscriptionPaymentGatewayType =
        data['subscriptionPaymentGatewayType'];
      this.recurringPaymentEnabled = data['recurringPaymentEnabled'];
      this.successUrl = data['successUrl'];
      this.errorUrl = data['errorUrl'];
    }
  }

  static fromJS(data: any): CreatePaymentDto {
    data = typeof data === 'object' ? data : {};
    let result = new CreatePaymentDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['editionId'] = this.editionId;
    data['editionPaymentType'] = this.editionPaymentType;
    data['paymentPeriodType'] = this.paymentPeriodType;
    data['subscriptionPaymentGatewayType'] =
      this.subscriptionPaymentGatewayType;
    data['recurringPaymentEnabled'] = this.recurringPaymentEnabled;
    data['successUrl'] = this.successUrl;
    data['errorUrl'] = this.errorUrl;
    return data;
  }
}

export interface ICreatePaymentDto {
  editionId: number;
  editionPaymentType: EditionPaymentType;
  paymentPeriodType: PaymentPeriodType;
  subscriptionPaymentGatewayType: SubscriptionPaymentGatewayType;
  recurringPaymentEnabled: boolean;
  successUrl: string | undefined;
  errorUrl: string | undefined;
}

export class CancelPaymentDto implements ICancelPaymentDto {
  paymentId!: string | undefined;
  gateway!: SubscriptionPaymentGatewayType;

  constructor(data?: ICancelPaymentDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.paymentId = data['paymentId'];
      this.gateway = data['gateway'];
    }
  }

  static fromJS(data: any): CancelPaymentDto {
    data = typeof data === 'object' ? data : {};
    let result = new CancelPaymentDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['paymentId'] = this.paymentId;
    data['gateway'] = this.gateway;
    return data;
  }
}

export interface ICancelPaymentDto {
  paymentId: string | undefined;
  gateway: SubscriptionPaymentGatewayType;
}

export class SubscriptionPaymentListDto implements ISubscriptionPaymentListDto {
  gateway!: string | undefined;
  amount!: number;
  editionId!: number;
  dayCount!: number;
  paymentPeriodType!: string | undefined;
  externalPaymentId!: string | undefined;
  payerId!: string | undefined;
  status!: string | undefined;
  editionDisplayName!: string | undefined;
  tenantId!: number;
  invoiceNo!: string | undefined;
  lastModificationTime!: moment.Moment | undefined;
  lastModifierUserId!: number | undefined;
  creationTime!: moment.Moment;
  creatorUserId!: number | undefined;
  id!: number;

  constructor(data?: ISubscriptionPaymentListDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.gateway = data['gateway'];
      this.amount = data['amount'];
      this.editionId = data['editionId'];
      this.dayCount = data['dayCount'];
      this.paymentPeriodType = data['paymentPeriodType'];
      this.externalPaymentId = data['externalPaymentId'];
      this.payerId = data['payerId'];
      this.status = data['status'];
      this.editionDisplayName = data['editionDisplayName'];
      this.tenantId = data['tenantId'];
      this.invoiceNo = data['invoiceNo'];
      this.lastModificationTime = data['lastModificationTime']
        ? moment(data['lastModificationTime'].toString())
        : <any>undefined;
      this.lastModifierUserId = data['lastModifierUserId'];
      this.creationTime = data['creationTime']
        ? moment(data['creationTime'].toString())
        : <any>undefined;
      this.creatorUserId = data['creatorUserId'];
      this.id = data['id'];
    }
  }

  static fromJS(data: any): SubscriptionPaymentListDto {
    data = typeof data === 'object' ? data : {};
    let result = new SubscriptionPaymentListDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['gateway'] = this.gateway;
    data['amount'] = this.amount;
    data['editionId'] = this.editionId;
    data['dayCount'] = this.dayCount;
    data['paymentPeriodType'] = this.paymentPeriodType;
    data['externalPaymentId'] = this.externalPaymentId;
    data['payerId'] = this.payerId;
    data['status'] = this.status;
    data['editionDisplayName'] = this.editionDisplayName;
    data['tenantId'] = this.tenantId;
    data['invoiceNo'] = this.invoiceNo;
    data['lastModificationTime'] = this.lastModificationTime
      ? this.lastModificationTime.toISOString()
      : <any>undefined;
    data['lastModifierUserId'] = this.lastModifierUserId;
    data['creationTime'] = this.creationTime
      ? this.creationTime.toISOString()
      : <any>undefined;
    data['creatorUserId'] = this.creatorUserId;
    data['id'] = this.id;
    return data;
  }
}

export interface ISubscriptionPaymentListDto {
  gateway: string | undefined;
  amount: number;
  editionId: number;
  dayCount: number;
  paymentPeriodType: string | undefined;
  externalPaymentId: string | undefined;
  payerId: string | undefined;
  status: string | undefined;
  editionDisplayName: string | undefined;
  tenantId: number;
  invoiceNo: string | undefined;
  lastModificationTime: moment.Moment | undefined;
  lastModifierUserId: number | undefined;
  creationTime: moment.Moment;
  creatorUserId: number | undefined;
  id: number;
}

export class PagedResultDtoOfSubscriptionPaymentListDto
  implements IPagedResultDtoOfSubscriptionPaymentListDto
{
  totalCount!: number;
  items!: SubscriptionPaymentListDto[] | undefined;

  constructor(data?: IPagedResultDtoOfSubscriptionPaymentListDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.totalCount = data['totalCount'];
      if (Array.isArray(data['items'])) {
        this.items = [] as any;
        for (let item of data['items'])
          this.items!.push(SubscriptionPaymentListDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): PagedResultDtoOfSubscriptionPaymentListDto {
    data = typeof data === 'object' ? data : {};
    let result = new PagedResultDtoOfSubscriptionPaymentListDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['totalCount'] = this.totalCount;
    if (Array.isArray(this.items)) {
      data['items'] = [];
      for (let item of this.items) data['items'].push(item.toJSON());
    }
    return data;
  }
}

export interface IPagedResultDtoOfSubscriptionPaymentListDto {
  totalCount: number;
  items: SubscriptionPaymentListDto[] | undefined;
}

export class PaymentGatewayModel implements IPaymentGatewayModel {
  gatewayType!: SubscriptionPaymentGatewayType;
  supportsRecurringPayments!: boolean;

  constructor(data?: IPaymentGatewayModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.gatewayType = data['gatewayType'];
      this.supportsRecurringPayments = data['supportsRecurringPayments'];
    }
  }

  static fromJS(data: any): PaymentGatewayModel {
    data = typeof data === 'object' ? data : {};
    let result = new PaymentGatewayModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['gatewayType'] = this.gatewayType;
    data['supportsRecurringPayments'] = this.supportsRecurringPayments;
    return data;
  }
}

export interface IPaymentGatewayModel {
  gatewayType: SubscriptionPaymentGatewayType;
  supportsRecurringPayments: boolean;
}

export enum SubscriptionPaymentStatus {
  NotPaid = 1,
  Paid = 2,
  Failed = 3,
  Cancelled = 4,
  Completed = 5,
}

export class SubscriptionPaymentDto implements ISubscriptionPaymentDto {
  description!: string | undefined;
  gateway!: SubscriptionPaymentGatewayType;
  amount!: number;
  editionId!: number;
  tenantId!: number;
  dayCount!: number;
  paymentPeriodType!: PaymentPeriodType;
  paymentId!: string | undefined;
  payerId!: string | undefined;
  editionDisplayName!: string | undefined;
  invoiceNo!: number;
  status!: SubscriptionPaymentStatus;
  isRecurring!: boolean;
  externalPaymentId!: string | undefined;
  successUrl!: string | undefined;
  errorUrl!: string | undefined;
  editionPaymentType!: EditionPaymentType;
  id!: number;

  constructor(data?: ISubscriptionPaymentDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.description = data['description'];
      this.gateway = data['gateway'];
      this.amount = data['amount'];
      this.editionId = data['editionId'];
      this.tenantId = data['tenantId'];
      this.dayCount = data['dayCount'];
      this.paymentPeriodType = data['paymentPeriodType'];
      this.paymentId = data['paymentId'];
      this.payerId = data['payerId'];
      this.editionDisplayName = data['editionDisplayName'];
      this.invoiceNo = data['invoiceNo'];
      this.status = data['status'];
      this.isRecurring = data['isRecurring'];
      this.externalPaymentId = data['externalPaymentId'];
      this.successUrl = data['successUrl'];
      this.errorUrl = data['errorUrl'];
      this.editionPaymentType = data['editionPaymentType'];
      this.id = data['id'];
    }
  }

  static fromJS(data: any): SubscriptionPaymentDto {
    data = typeof data === 'object' ? data : {};
    let result = new SubscriptionPaymentDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['description'] = this.description;
    data['gateway'] = this.gateway;
    data['amount'] = this.amount;
    data['editionId'] = this.editionId;
    data['tenantId'] = this.tenantId;
    data['dayCount'] = this.dayCount;
    data['paymentPeriodType'] = this.paymentPeriodType;
    data['paymentId'] = this.paymentId;
    data['payerId'] = this.payerId;
    data['editionDisplayName'] = this.editionDisplayName;
    data['invoiceNo'] = this.invoiceNo;
    data['status'] = this.status;
    data['isRecurring'] = this.isRecurring;
    data['externalPaymentId'] = this.externalPaymentId;
    data['successUrl'] = this.successUrl;
    data['errorUrl'] = this.errorUrl;
    data['editionPaymentType'] = this.editionPaymentType;
    data['id'] = this.id;
    return data;
  }
}

export interface ISubscriptionPaymentDto {
  description: string | undefined;
  gateway: SubscriptionPaymentGatewayType;
  amount: number;
  editionId: number;
  tenantId: number;
  dayCount: number;
  paymentPeriodType: PaymentPeriodType;
  paymentId: string | undefined;
  payerId: string | undefined;
  editionDisplayName: string | undefined;
  invoiceNo: number;
  status: SubscriptionPaymentStatus;
  isRecurring: boolean;
  externalPaymentId: string | undefined;
  successUrl: string | undefined;
  errorUrl: string | undefined;
  editionPaymentType: EditionPaymentType;
  id: number;
}

export class PayPalConfigurationDto implements IPayPalConfigurationDto {
  clientId!: string | undefined;
  demoUsername!: string | undefined;
  demoPassword!: string | undefined;

  constructor(data?: IPayPalConfigurationDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.clientId = data['clientId'];
      this.demoUsername = data['demoUsername'];
      this.demoPassword = data['demoPassword'];
    }
  }

  static fromJS(data: any): PayPalConfigurationDto {
    data = typeof data === 'object' ? data : {};
    let result = new PayPalConfigurationDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['clientId'] = this.clientId;
    data['demoUsername'] = this.demoUsername;
    data['demoPassword'] = this.demoPassword;
    return data;
  }
}

export interface IPayPalConfigurationDto {
  clientId: string | undefined;
  demoUsername: string | undefined;
  demoPassword: string | undefined;
}

export class FlatPermissionWithLevelDto implements IFlatPermissionWithLevelDto {
  level!: number;
  parentName!: string | undefined;
  name!: string | undefined;
  displayName!: string | undefined;
  description!: string | undefined;
  isGrantedByDefault!: boolean;

  constructor(data?: IFlatPermissionWithLevelDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.level = data['level'];
      this.parentName = data['parentName'];
      this.name = data['name'];
      this.displayName = data['displayName'];
      this.description = data['description'];
      this.isGrantedByDefault = data['isGrantedByDefault'];
    }
  }

  static fromJS(data: any): FlatPermissionWithLevelDto {
    data = typeof data === 'object' ? data : {};
    let result = new FlatPermissionWithLevelDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['level'] = this.level;
    data['parentName'] = this.parentName;
    data['name'] = this.name;
    data['displayName'] = this.displayName;
    data['description'] = this.description;
    data['isGrantedByDefault'] = this.isGrantedByDefault;
    return data;
  }
}

export interface IFlatPermissionWithLevelDto {
  level: number;
  parentName: string | undefined;
  name: string | undefined;
  displayName: string | undefined;
  description: string | undefined;
  isGrantedByDefault: boolean;
}

export class ListResultDtoOfFlatPermissionWithLevelDto
  implements IListResultDtoOfFlatPermissionWithLevelDto
{
  items!: FlatPermissionWithLevelDto[] | undefined;

  constructor(data?: IListResultDtoOfFlatPermissionWithLevelDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['items'])) {
        this.items = [] as any;
        for (let item of data['items'])
          this.items!.push(FlatPermissionWithLevelDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): ListResultDtoOfFlatPermissionWithLevelDto {
    data = typeof data === 'object' ? data : {};
    let result = new ListResultDtoOfFlatPermissionWithLevelDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.items)) {
      data['items'] = [];
      for (let item of this.items) data['items'].push(item.toJSON());
    }
    return data;
  }
}

export interface IListResultDtoOfFlatPermissionWithLevelDto {
  items: FlatPermissionWithLevelDto[] | undefined;
}

export class AttributeInput implements IAttributeInput {
  attribute!: string | undefined;
  value!: string | undefined;
  isLinkedAttribute!: boolean;

  constructor(data?: IAttributeInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.attribute = data['attribute'];
      this.value = data['value'];
      this.isLinkedAttribute = data['isLinkedAttribute'];
    }
  }

  static fromJS(data: any): AttributeInput {
    data = typeof data === 'object' ? data : {};
    let result = new AttributeInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['attribute'] = this.attribute;
    data['value'] = this.value;
    data['isLinkedAttribute'] = this.isLinkedAttribute;
    return data;
  }
}

export interface IAttributeInput {
  attribute: string | undefined;
  value: string | undefined;
  isLinkedAttribute: boolean;
}

export class AttributeInputWithRelationship
  implements IAttributeInputWithRelationship
{
  attribute!: string | undefined;
  value!: string | undefined;
  isLinkedAttribute!: boolean;
  childItems!: AttributeInput[] | undefined;

  constructor(data?: IAttributeInputWithRelationship) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.attribute = data['attribute'];
      this.value = data['value'];
      this.isLinkedAttribute = data['isLinkedAttribute'];
      if (Array.isArray(data['childItems'])) {
        this.childItems = [] as any;
        for (let item of data['childItems'])
          this.childItems!.push(AttributeInput.fromJS(item));
      }
    }
  }

  static fromJS(data: any): AttributeInputWithRelationship {
    data = typeof data === 'object' ? data : {};
    let result = new AttributeInputWithRelationship();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['attribute'] = this.attribute;
    data['value'] = this.value;
    data['isLinkedAttribute'] = this.isLinkedAttribute;
    if (Array.isArray(this.childItems)) {
      data['childItems'] = [];
      for (let item of this.childItems) data['childItems'].push(item.toJSON());
    }
    return data;
  }
}

export interface IAttributeInputWithRelationship {
  attribute: string | undefined;
  value: string | undefined;
  isLinkedAttribute: boolean;
  childItems: AttributeInput[] | undefined;
}

export class ProductSearchInput implements IProductSearchInput {
  cedCategoryId!: number;
  vehicleId!: number | undefined;
  brandIds!: number[] | undefined;
  subBrands!: string[] | undefined;
  categories!: number[] | undefined;
  attributes!: AttributeInput[] | undefined;
  attributesWithRelationship!: AttributeInputWithRelationship[] | undefined;
  brand!: string | undefined;
  priceList!: string | undefined;
  showUniversalProducts!: boolean;
  sorting!: string | undefined;
  maxResultCount!: number;
  skipCount!: number;

  constructor(data?: IProductSearchInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.cedCategoryId = data['cedCategoryId'];
      this.vehicleId = data['vehicleId'];
      if (Array.isArray(data['brandIds'])) {
        this.brandIds = [] as any;
        for (let item of data['brandIds']) this.brandIds!.push(item);
      }
      if (Array.isArray(data['subBrands'])) {
        this.subBrands = [] as any;
        for (let item of data['subBrands']) this.subBrands!.push(item);
      }
      if (Array.isArray(data['categories'])) {
        this.categories = [] as any;
        for (let item of data['categories']) this.categories!.push(item);
      }
      if (Array.isArray(data['attributes'])) {
        this.attributes = [] as any;
        for (let item of data['attributes'])
          this.attributes!.push(AttributeInput.fromJS(item));
      }
      if (Array.isArray(data['attributesWithRelationship'])) {
        this.attributesWithRelationship = [] as any;
        for (let item of data['attributesWithRelationship'])
          this.attributesWithRelationship!.push(
            AttributeInputWithRelationship.fromJS(item)
          );
      }
      this.brand = data['brand'];
      this.priceList = data['priceList'];
      this.showUniversalProducts = data['showUniversalProducts'];
      this.sorting = data['sorting'];
      this.maxResultCount = data['maxResultCount'];
      this.skipCount = data['skipCount'];
    }
  }

  static fromJS(data: any): ProductSearchInput {
    data = typeof data === 'object' ? data : {};
    let result = new ProductSearchInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['cedCategoryId'] = this.cedCategoryId;
    data['vehicleId'] = this.vehicleId;
    if (Array.isArray(this.brandIds)) {
      data['brandIds'] = [];
      for (let item of this.brandIds) data['brandIds'].push(item);
    }
    if (Array.isArray(this.subBrands)) {
      data['subBrands'] = [];
      for (let item of this.subBrands) data['subBrands'].push(item);
    }
    if (Array.isArray(this.categories)) {
      data['categories'] = [];
      for (let item of this.categories) data['categories'].push(item);
    }
    if (Array.isArray(this.attributes)) {
      data['attributes'] = [];
      for (let item of this.attributes) data['attributes'].push(item.toJSON());
    }
    if (Array.isArray(this.attributesWithRelationship)) {
      data['attributesWithRelationship'] = [];
      for (let item of this.attributesWithRelationship)
        data['attributesWithRelationship'].push(item.toJSON());
    }
    data['brand'] = this.brand;
    data['priceList'] = this.priceList;
    data['showUniversalProducts'] = this.showUniversalProducts;
    data['sorting'] = this.sorting;
    data['maxResultCount'] = this.maxResultCount;
    data['skipCount'] = this.skipCount;
    return data;
  }
}

export interface IProductSearchInput {
  cedCategoryId: number;
  vehicleId: number | undefined;
  brandIds: number[] | undefined;
  subBrands: string[] | undefined;
  categories: number[] | undefined;
  attributes: AttributeInput[] | undefined;
  attributesWithRelationship: AttributeInputWithRelationship[] | undefined;
  brand: string | undefined;
  priceList: string | undefined;
  showUniversalProducts: boolean;
  sorting: string | undefined;
  maxResultCount: number;
  skipCount: number;
}

export class ProductGroupAttribute implements IProductGroupAttribute {
  name!: string | undefined;
  value!: string | undefined;
  sortNr!: number;

  constructor(data?: IProductGroupAttribute) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.name = data['name'];
      this.value = data['value'];
      this.sortNr = data['sortNr'];
    }
  }

  static fromJS(data: any): ProductGroupAttribute {
    data = typeof data === 'object' ? data : {};
    let result = new ProductGroupAttribute();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['name'] = this.name;
    data['value'] = this.value;
    data['sortNr'] = this.sortNr;
    return data;
  }
}

export interface IProductGroupAttribute {
  name: string | undefined;
  value: string | undefined;
  sortNr: number;
}

export class ProductAttributeDto implements IProductAttributeDto {
  attrGroup!: number;
  attribute!: string | undefined;
  value!: string | undefined;
  sortNumber!: number;
  filterable!: boolean;

  constructor(data?: IProductAttributeDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.attrGroup = data['attrGroup'];
      this.attribute = data['attribute'];
      this.value = data['value'];
      this.sortNumber = data['sortNumber'];
      this.filterable = data['filterable'];
    }
  }

  static fromJS(data: any): ProductAttributeDto {
    data = typeof data === 'object' ? data : {};
    let result = new ProductAttributeDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['attrGroup'] = this.attrGroup;
    data['attribute'] = this.attribute;
    data['value'] = this.value;
    data['sortNumber'] = this.sortNumber;
    data['filterable'] = this.filterable;
    return data;
  }
}

export interface IProductAttributeDto {
  attrGroup: number;
  attribute: string | undefined;
  value: string | undefined;
  sortNumber: number;
  filterable: boolean;
}

export class AssociatedItem implements IAssociatedItem {
  productNumber!: string | undefined;
  brandId!: number;
  companySKU!: string | undefined;
  companyProductTitle!: string | undefined;
  isBundle!: boolean;
  associationType!: string | undefined;
  fitableYN!: string | undefined;
  paintableYN!: string | undefined;
  fitProductCode!: string | undefined;
  fitProductDesc!: string | undefined;
  fitUnitPriceExTax!: string | undefined;
  fitUnitPriceIncTax!: string | undefined;
  fitBundleUnitPriceExTax!: string | undefined;
  fitBundleUnitPriceIncTax!: string | undefined;
  paintProductCode!: string | undefined;
  paintDesc!: string | undefined;
  quantity!: number;
  upLevelProductNumber!: string | undefined;
  upLevelBrandId!: string | undefined;
  level!: number;

  constructor(data?: IAssociatedItem) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.productNumber = data['productNumber'];
      this.brandId = data['brandId'];
      this.companySKU = data['companySKU'];
      this.companyProductTitle = data['companyProductTitle'];
      this.isBundle = data['isBundle'];
      this.associationType = data['associationType'];
      this.fitableYN = data['fitableYN'];
      this.paintableYN = data['paintableYN'];
      this.fitProductCode = data['fitProductCode'];
      this.fitProductDesc = data['fitProductDesc'];
      this.fitUnitPriceExTax = data['fitUnitPriceExTax'];
      this.fitUnitPriceIncTax = data['fitUnitPriceIncTax'];
      this.fitBundleUnitPriceExTax = data['fitBundleUnitPriceExTax'];
      this.fitBundleUnitPriceIncTax = data['fitBundleUnitPriceIncTax'];
      this.paintProductCode = data['paintProductCode'];
      this.paintDesc = data['paintDesc'];
      this.quantity = data['quantity'];
      this.upLevelProductNumber = data['upLevelProductNumber'];
      this.upLevelBrandId = data['upLevelBrandId'];
      this.level = data['level'];
    }
  }

  static fromJS(data: any): AssociatedItem {
    data = typeof data === 'object' ? data : {};
    let result = new AssociatedItem();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['productNumber'] = this.productNumber;
    data['brandId'] = this.brandId;
    data['companySKU'] = this.companySKU;
    data['companyProductTitle'] = this.companyProductTitle;
    data['isBundle'] = this.isBundle;
    data['associationType'] = this.associationType;
    data['fitableYN'] = this.fitableYN;
    data['paintableYN'] = this.paintableYN;
    data['fitProductCode'] = this.fitProductCode;
    data['fitProductDesc'] = this.fitProductDesc;
    data['fitUnitPriceExTax'] = this.fitUnitPriceExTax;
    data['fitUnitPriceIncTax'] = this.fitUnitPriceIncTax;
    data['fitBundleUnitPriceExTax'] = this.fitBundleUnitPriceExTax;
    data['fitBundleUnitPriceIncTax'] = this.fitBundleUnitPriceIncTax;
    data['paintProductCode'] = this.paintProductCode;
    data['paintDesc'] = this.paintDesc;
    data['quantity'] = this.quantity;
    data['upLevelProductNumber'] = this.upLevelProductNumber;
    data['upLevelBrandId'] = this.upLevelBrandId;
    data['level'] = this.level;
    return data;
  }
}

export interface IAssociatedItem {
  productNumber: string | undefined;
  brandId: number;
  companySKU: string | undefined;
  companyProductTitle: string | undefined;
  isBundle: boolean;
  associationType: string | undefined;
  fitableYN: string | undefined;
  paintableYN: string | undefined;
  fitProductCode: string | undefined;
  fitProductDesc: string | undefined;
  fitUnitPriceExTax: string | undefined;
  fitUnitPriceIncTax: string | undefined;
  fitBundleUnitPriceExTax: string | undefined;
  fitBundleUnitPriceIncTax: string | undefined;
  paintProductCode: string | undefined;
  paintDesc: string | undefined;
  quantity: number;
  upLevelProductNumber: string | undefined;
  upLevelBrandId: string | undefined;
  level: number;
}

export class ProductDto implements IProductDto {
  productNumber!: string | undefined;
  brandId!: number;
  brandName!: string | undefined;
  subBrand!: string | undefined;
  description!: string | undefined;
  retail!: string | undefined;
  note!: string | undefined;
  companySKU!: string | undefined;
  numberOfComponentsPerProduct!: string | undefined;
  companyProductTitle!: string | undefined;
  companyProductDescription!: string | undefined;
  richContentUrl!: string | undefined;
  richContentUrlThumb!: string | undefined;
  cedCategoryName!: string | undefined;
  cedCategoryId!: number;
  isBundle!: boolean;
  fitableYN!: string | undefined;
  paintableYN!: string | undefined;
  fitProductCode!: string | undefined;
  fitProductDesc!: string | undefined;
  fitUnitPriceExTax!: string | undefined;
  fitUnitPriceIncTax!: string | undefined;
  fitBundleUnitPriceExTax!: string | undefined;
  fitBundleUnitPriceIncTax!: string | undefined;
  paintProductCode!: string | undefined;
  paintDesc!: string | undefined;
  existFitmentRule!: boolean;
  quantityPerVehicle!: number;
  existMandatoryAssociation!: boolean;
  groupAttributes!: ProductGroupAttribute[] | undefined;
  filterAttributes!: ProductAttributeDto[] | undefined;
  associatedItems!: AssociatedItem[] | undefined;

  constructor(data?: IProductDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.productNumber = data['productNumber'];
      this.brandId = data['brandId'];
      this.brandName = data['brandName'];
      this.subBrand = data['subBrand'];
      this.description = data['description'];
      this.retail = data['retail'];
      this.note = data['note'];
      this.companySKU = data['companySKU'];
      this.numberOfComponentsPerProduct = data['numberOfComponentsPerProduct'];
      this.companyProductTitle = data['companyProductTitle'];
      this.companyProductDescription = data['companyProductDescription'];
      this.richContentUrl = data['richContentUrl'];
      this.richContentUrlThumb = data['richContentUrlThumb'];
      this.cedCategoryName = data['cedCategoryName'];
      this.cedCategoryId = data['cedCategoryId'];
      this.isBundle = data['isBundle'];
      this.fitableYN = data['fitableYN'];
      this.paintableYN = data['paintableYN'];
      this.fitProductCode = data['fitProductCode'];
      this.fitProductDesc = data['fitProductDesc'];
      this.fitUnitPriceExTax = data['fitUnitPriceExTax'];
      this.fitUnitPriceIncTax = data['fitUnitPriceIncTax'];
      this.fitBundleUnitPriceExTax = data['fitBundleUnitPriceExTax'];
      this.fitBundleUnitPriceIncTax = data['fitBundleUnitPriceIncTax'];
      this.paintProductCode = data['paintProductCode'];
      this.paintDesc = data['paintDesc'];
      this.existFitmentRule = data['existFitmentRule'];
      this.quantityPerVehicle = data['quantityPerVehicle'];
      this.existMandatoryAssociation = data['existMandatoryAssociation'];
      if (Array.isArray(data['groupAttributes'])) {
        this.groupAttributes = [] as any;
        for (let item of data['groupAttributes'])
          this.groupAttributes!.push(ProductGroupAttribute.fromJS(item));
      }
      if (Array.isArray(data['filterAttributes'])) {
        this.filterAttributes = [] as any;
        for (let item of data['filterAttributes'])
          this.filterAttributes!.push(ProductAttributeDto.fromJS(item));
      }
      if (Array.isArray(data['associatedItems'])) {
        this.associatedItems = [] as any;
        for (let item of data['associatedItems'])
          this.associatedItems!.push(AssociatedItem.fromJS(item));
      }
    }
  }

  static fromJS(data: any): ProductDto {
    data = typeof data === 'object' ? data : {};
    let result = new ProductDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['productNumber'] = this.productNumber;
    data['brandId'] = this.brandId;
    data['brandName'] = this.brandName;
    data['subBrand'] = this.subBrand;
    data['description'] = this.description;
    data['retail'] = this.retail;
    data['note'] = this.note;
    data['companySKU'] = this.companySKU;
    data['numberOfComponentsPerProduct'] = this.numberOfComponentsPerProduct;
    data['companyProductTitle'] = this.companyProductTitle;
    data['companyProductDescription'] = this.companyProductDescription;
    data['richContentUrl'] = this.richContentUrl;
    data['richContentUrlThumb'] = this.richContentUrlThumb;
    data['cedCategoryName'] = this.cedCategoryName;
    data['cedCategoryId'] = this.cedCategoryId;
    data['isBundle'] = this.isBundle;
    data['fitableYN'] = this.fitableYN;
    data['paintableYN'] = this.paintableYN;
    data['fitProductCode'] = this.fitProductCode;
    data['fitProductDesc'] = this.fitProductDesc;
    data['fitUnitPriceExTax'] = this.fitUnitPriceExTax;
    data['fitUnitPriceIncTax'] = this.fitUnitPriceIncTax;
    data['fitBundleUnitPriceExTax'] = this.fitBundleUnitPriceExTax;
    data['fitBundleUnitPriceIncTax'] = this.fitBundleUnitPriceIncTax;
    data['paintProductCode'] = this.paintProductCode;
    data['paintDesc'] = this.paintDesc;
    data['existFitmentRule'] = this.existFitmentRule;
    data['quantityPerVehicle'] = this.quantityPerVehicle;
    data['existMandatoryAssociation'] = this.existMandatoryAssociation;
    if (Array.isArray(this.groupAttributes)) {
      data['groupAttributes'] = [];
      for (let item of this.groupAttributes)
        data['groupAttributes'].push(item.toJSON());
    }
    if (Array.isArray(this.filterAttributes)) {
      data['filterAttributes'] = [];
      for (let item of this.filterAttributes)
        data['filterAttributes'].push(item.toJSON());
    }
    if (Array.isArray(this.associatedItems)) {
      data['associatedItems'] = [];
      for (let item of this.associatedItems)
        data['associatedItems'].push(item.toJSON());
    }
    return data;
  }
}

export interface IProductDto {
  productNumber: string | undefined;
  brandId: number;
  brandName: string | undefined;
  subBrand: string | undefined;
  description: string | undefined;
  retail: string | undefined;
  note: string | undefined;
  companySKU: string | undefined;
  numberOfComponentsPerProduct: string | undefined;
  companyProductTitle: string | undefined;
  companyProductDescription: string | undefined;
  richContentUrl: string | undefined;
  richContentUrlThumb: string | undefined;
  cedCategoryName: string | undefined;
  cedCategoryId: number;
  isBundle: boolean;
  fitableYN: string | undefined;
  paintableYN: string | undefined;
  fitProductCode: string | undefined;
  fitProductDesc: string | undefined;
  fitUnitPriceExTax: string | undefined;
  fitUnitPriceIncTax: string | undefined;
  fitBundleUnitPriceExTax: string | undefined;
  fitBundleUnitPriceIncTax: string | undefined;
  paintProductCode: string | undefined;
  paintDesc: string | undefined;
  existFitmentRule: boolean;
  quantityPerVehicle: number;
  existMandatoryAssociation: boolean;
  groupAttributes: ProductGroupAttribute[] | undefined;
  filterAttributes: ProductAttributeDto[] | undefined;
  associatedItems: AssociatedItem[] | undefined;
}

export class ProductPagedResultDto implements IProductPagedResultDto {
  orderedGroupingFields!: string[] | undefined;
  existVehicleFitmentRule!: boolean;
  totalCount!: number;
  items!: ProductDto[] | undefined;

  constructor(data?: IProductPagedResultDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['orderedGroupingFields'])) {
        this.orderedGroupingFields = [] as any;
        for (let item of data['orderedGroupingFields'])
          this.orderedGroupingFields!.push(item);
      }
      this.existVehicleFitmentRule = data['existVehicleFitmentRule'];
      this.totalCount = data['totalCount'];
      if (Array.isArray(data['items'])) {
        this.items = [] as any;
        for (let item of data['items'])
          this.items!.push(ProductDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): ProductPagedResultDto {
    data = typeof data === 'object' ? data : {};
    let result = new ProductPagedResultDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.orderedGroupingFields)) {
      data['orderedGroupingFields'] = [];
      for (let item of this.orderedGroupingFields)
        data['orderedGroupingFields'].push(item);
    }
    data['existVehicleFitmentRule'] = this.existVehicleFitmentRule;
    data['totalCount'] = this.totalCount;
    if (Array.isArray(this.items)) {
      data['items'] = [];
      for (let item of this.items) data['items'].push(item.toJSON());
    }
    return data;
  }
}

export interface IProductPagedResultDto {
  orderedGroupingFields: string[] | undefined;
  existVehicleFitmentRule: boolean;
  totalCount: number;
  items: ProductDto[] | undefined;
}

export enum DetailLevel {
  Minimal = 1,
  Full = 2,
}

export class GetProductDetailInput implements IGetProductDetailInput {
  productNumber!: string | undefined;
  brand!: string | undefined;
  priceList!: string | undefined;
  mode!: DetailLevel;
  vehicleId!: number | undefined;
  showUniversalProducts!: boolean;

  constructor(data?: IGetProductDetailInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.productNumber = data['productNumber'];
      this.brand = data['brand'];
      this.priceList = data['priceList'];
      this.mode = data['mode'];
      this.vehicleId = data['vehicleId'];
      this.showUniversalProducts = data['showUniversalProducts'];
    }
  }

  static fromJS(data: any): GetProductDetailInput {
    data = typeof data === 'object' ? data : {};
    let result = new GetProductDetailInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['productNumber'] = this.productNumber;
    data['brand'] = this.brand;
    data['priceList'] = this.priceList;
    data['mode'] = this.mode;
    data['vehicleId'] = this.vehicleId;
    data['showUniversalProducts'] = this.showUniversalProducts;
    return data;
  }
}

export interface IGetProductDetailInput {
  productNumber: string | undefined;
  brand: string | undefined;
  priceList: string | undefined;
  mode: DetailLevel;
  vehicleId: number | undefined;
  showUniversalProducts: boolean;
}

export class ProductDetail implements IProductDetail {
  productNumber!: string | undefined;
  brandId!: number;
  brandName!: string | undefined;
  companySKU!: string | undefined;
  universal!: boolean;
  supersededProductNumber!: string | undefined;
  supplierUrl!: string | undefined;
  weight!: string | undefined;
  width!: string | undefined;
  depth!: string | undefined;
  length!: string | undefined;
  barCode!: string | undefined;
  companyProductTitle!: string | undefined;
  companyProductDescription!: string | undefined;
  subBrand!: string | undefined;
  description!: string | undefined;
  note!: string | undefined;
  retail!: string | undefined;
  richContentUrl!: string | undefined;
  richContentUrlThumb!: string | undefined;
  uom!: string | undefined;
  paintableYN!: string | undefined;
  fitableYN!: string | undefined;
  fitProductCode!: string | undefined;
  fitProductDesc!: string | undefined;
  fitUnitPriceExTax!: string | undefined;
  fitUnitPriceIncTax!: string | undefined;
  fitBundleUnitPriceExTax!: string | undefined;
  fitBundleUnitPriceIncTax!: string | undefined;
  paintProductCode!: string | undefined;
  paintDesc!: string | undefined;
  isBundle!: boolean;
  isKit!: boolean;
  paRetailsDescription!: string | undefined;
  cedCategoryId: string | undefined;

  constructor(data?: IProductDetail) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.productNumber = data['productNumber'];
      this.brandId = data['brandId'];
      this.brandName = data['brandName'];
      this.companySKU = data['companySKU'];
      this.universal = data['universal'];
      this.supersededProductNumber = data['supersededProductNumber'];
      this.supplierUrl = data['supplierUrl'];
      this.weight = data['weight'];
      this.width = data['width'];
      this.depth = data['depth'];
      this.length = data['length'];
      this.barCode = data['barCode'];
      this.companyProductTitle = data['companyProductTitle'];
      this.companyProductDescription = data['companyProductDescription'];
      this.subBrand = data['subBrand'];
      this.description = data['description'];
      this.note = data['note'];
      this.retail = data['retail'];
      this.richContentUrl = data['richContentUrl'];
      this.richContentUrlThumb = data['richContentUrlThumb'];
      this.uom = data['uom'];
      this.paintableYN = data['paintableYN'];
      this.fitableYN = data['fitableYN'];
      this.fitProductCode = data['fitProductCode'];
      this.fitProductDesc = data['fitProductDesc'];
      this.fitUnitPriceExTax = data['fitUnitPriceExTax'];
      this.fitUnitPriceIncTax = data['fitUnitPriceIncTax'];
      this.fitBundleUnitPriceExTax = data['fitBundleUnitPriceExTax'];
      this.fitBundleUnitPriceIncTax = data['fitBundleUnitPriceIncTax'];
      this.paintProductCode = data['paintProductCode'];
      this.paintDesc = data['paintDesc'];
      this.isBundle = data['isBundle'];
      this.isKit = data['isKit'];
      this.paRetailsDescription = data['paRetailsDescription'];
      this.cedCategoryId = data['cedCategoryId'];
    }
  }

  static fromJS(data: any): ProductDetail {
    data = typeof data === 'object' ? data : {};
    let result = new ProductDetail();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['productNumber'] = this.productNumber;
    data['brandId'] = this.brandId;
    data['brandName'] = this.brandName;
    data['companySKU'] = this.companySKU;
    data['universal'] = this.universal;
    data['supersededProductNumber'] = this.supersededProductNumber;
    data['supplierUrl'] = this.supplierUrl;
    data['weight'] = this.weight;
    data['width'] = this.width;
    data['depth'] = this.depth;
    data['length'] = this.length;
    data['barCode'] = this.barCode;
    data['companyProductTitle'] = this.companyProductTitle;
    data['companyProductDescription'] = this.companyProductDescription;
    data['subBrand'] = this.subBrand;
    data['description'] = this.description;
    data['note'] = this.note;
    data['retail'] = this.retail;
    data['richContentUrl'] = this.richContentUrl;
    data['richContentUrlThumb'] = this.richContentUrlThumb;
    data['uom'] = this.uom;
    data['paintableYN'] = this.paintableYN;
    data['fitableYN'] = this.fitableYN;
    data['fitProductCode'] = this.fitProductCode;
    data['fitProductDesc'] = this.fitProductDesc;
    data['fitUnitPriceExTax'] = this.fitUnitPriceExTax;
    data['fitUnitPriceIncTax'] = this.fitUnitPriceIncTax;
    data['fitBundleUnitPriceExTax'] = this.fitBundleUnitPriceExTax;
    data['fitBundleUnitPriceIncTax'] = this.fitBundleUnitPriceIncTax;
    data['paintProductCode'] = this.paintProductCode;
    data['paintDesc'] = this.paintDesc;
    data['isBundle'] = this.isBundle;
    data['isKit'] = this.isKit;
    data['paRetailsDescription'] = this.paRetailsDescription;
    data['cedCategoryId'] = this.cedCategoryId;
    return data;
  }
}

export interface IProductDetail {
  productNumber: string | undefined;
  brandId: number;
  brandName: string | undefined;
  companySKU: string | undefined;
  universal: boolean;
  supersededProductNumber: string | undefined;
  supplierUrl: string | undefined;
  weight: string | undefined;
  width: string | undefined;
  depth: string | undefined;
  length: string | undefined;
  barCode: string | undefined;
  companyProductTitle: string | undefined;
  companyProductDescription: string | undefined;
  subBrand: string | undefined;
  description: string | undefined;
  note: string | undefined;
  retail: string | undefined;
  richContentUrl: string | undefined;
  richContentUrlThumb: string | undefined;
  uom: string | undefined;
  paintableYN: string | undefined;
  fitableYN: string | undefined;
  fitProductCode: string | undefined;
  fitProductDesc: string | undefined;
  fitUnitPriceExTax: string | undefined;
  fitUnitPriceIncTax: string | undefined;
  fitBundleUnitPriceExTax: string | undefined;
  fitBundleUnitPriceIncTax: string | undefined;
  paintProductCode: string | undefined;
  paintDesc: string | undefined;
  isBundle: boolean;
  isKit: boolean;
  paRetailsDescription: string | undefined;
}

export enum ProductRichContentType {
  Unknown = 0,
  Image = 1,
  Video = 2,
  Pdf = 3,
  Zip = 4,
}

export class ProductRichContentDto implements IProductRichContentDto {
  sortNumber!: number;
  fileName!: string | undefined;
  contentUrl!: string | undefined;
  contentUrlThumb!: string | undefined;
  contentDescription!: string | undefined;
  extension!: string | undefined;
  readonly isVideo!: boolean;
  type!: ProductRichContentType;

  constructor(data?: IProductRichContentDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.sortNumber = data['sortNumber'];
      this.fileName = data['fileName'];
      this.contentUrl = data['contentUrl'];
      this.contentUrlThumb = data['contentUrlThumb'];
      this.contentDescription = data['contentDescription'];
      this.extension = data['extension'];
      (<any>this).isVideo = data['isVideo'];
      this.type = data['type'];
    }
  }

  static fromJS(data: any): ProductRichContentDto {
    data = typeof data === 'object' ? data : {};
    let result = new ProductRichContentDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['sortNumber'] = this.sortNumber;
    data['fileName'] = this.fileName;
    data['contentUrl'] = this.contentUrl;
    data['contentUrlThumb'] = this.contentUrlThumb;
    data['contentDescription'] = this.contentDescription;
    data['extension'] = this.extension;
    data['isVideo'] = this.isVideo;
    data['type'] = this.type;
    return data;
  }
}

export interface IProductRichContentDto {
  sortNumber: number;
  fileName: string | undefined;
  contentUrl: string | undefined;
  contentUrlThumb: string | undefined;
  contentDescription: string | undefined;
  extension: string | undefined;
  isVideo: boolean;
  type: ProductRichContentType;
}

export class ProductAssociatedItem implements IProductAssociatedItem {
  associatedItems!: ProductAssociatedItem[] | undefined;
  associatedType!: string | undefined;
  quantity!: string | undefined;
  unitPrice!: string | undefined;
  cedCategoryId!: number;
  cedCategory!: string | undefined;
  standardDescription!: string | undefined;
  isFitted!: boolean;
  linkSource!: string | undefined;
  attributes!: ProductAttributeDto[] | undefined;
  richContent!: ProductRichContentDto[] | undefined;
  isMandatory!: boolean;
  isConflict!: boolean;
  productNumber!: string | undefined;
  brandId!: number;
  brandName!: string | undefined;
  companySKU!: string | undefined;
  universal!: boolean;
  supersededProductNumber!: string | undefined;
  supplierUrl!: string | undefined;
  weight!: string | undefined;
  width!: string | undefined;
  depth!: string | undefined;
  length!: string | undefined;
  barCode!: string | undefined;
  companyProductTitle!: string | undefined;
  companyProductDescription!: string | undefined;
  subBrand!: string | undefined;
  description!: string | undefined;
  note!: string | undefined;
  retail!: string | undefined;
  richContentUrl!: string | undefined;
  richContentUrlThumb!: string | undefined;
  uom!: string | undefined;
  paintableYN!: string | undefined;
  fitableYN!: string | undefined;
  fitProductCode!: string | undefined;
  fitProductDesc!: string | undefined;
  fitUnitPriceExTax!: string | undefined;
  fitUnitPriceIncTax!: string | undefined;
  fitBundleUnitPriceExTax!: string | undefined;
  fitBundleUnitPriceIncTax!: string | undefined;
  paintProductCode!: string | undefined;
  paintDesc!: string | undefined;
  isBundle!: boolean;
  isKit!: boolean;
  paRetailsDescription!: string | undefined;

  constructor(data?: IProductAssociatedItem) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['associatedItems'])) {
        this.associatedItems = [] as any;
        for (let item of data['associatedItems'])
          this.associatedItems!.push(ProductAssociatedItem.fromJS(item));
      }
      this.associatedType = data['associatedType'];
      this.quantity = data['quantity'];
      this.unitPrice = data['unitPrice'];
      this.cedCategoryId = data['cedCategoryId'];
      this.cedCategory = data['cedCategory'];
      this.standardDescription = data['standardDescription'];
      this.isFitted = data['isFitted'];
      this.linkSource = data['linkSource'];
      if (Array.isArray(data['attributes'])) {
        this.attributes = [] as any;
        for (let item of data['attributes'])
          this.attributes!.push(ProductAttributeDto.fromJS(item));
      }
      if (Array.isArray(data['richContent'])) {
        this.richContent = [] as any;
        for (let item of data['richContent'])
          this.richContent!.push(ProductRichContentDto.fromJS(item));
      }
      this.isMandatory = data['isMandatory'];
      this.isConflict = data['isConflict'];
      this.productNumber = data['productNumber'];
      this.brandId = data['brandId'];
      this.brandName = data['brandName'];
      this.companySKU = data['companySKU'];
      this.universal = data['universal'];
      this.supersededProductNumber = data['supersededProductNumber'];
      this.supplierUrl = data['supplierUrl'];
      this.weight = data['weight'];
      this.width = data['width'];
      this.depth = data['depth'];
      this.length = data['length'];
      this.barCode = data['barCode'];
      this.companyProductTitle = data['companyProductTitle'];
      this.companyProductDescription = data['companyProductDescription'];
      this.subBrand = data['subBrand'];
      this.description = data['description'];
      this.note = data['note'];
      this.retail = data['retail'];
      this.richContentUrl = data['richContentUrl'];
      this.richContentUrlThumb = data['richContentUrlThumb'];
      this.uom = data['uom'];
      this.paintableYN = data['paintableYN'];
      this.fitableYN = data['fitableYN'];
      this.fitProductCode = data['fitProductCode'];
      this.fitProductDesc = data['fitProductDesc'];
      this.fitUnitPriceExTax = data['fitUnitPriceExTax'];
      this.fitUnitPriceIncTax = data['fitUnitPriceIncTax'];
      this.fitBundleUnitPriceExTax = data['fitBundleUnitPriceExTax'];
      this.fitBundleUnitPriceIncTax = data['fitBundleUnitPriceIncTax'];
      this.paintProductCode = data['paintProductCode'];
      this.paintDesc = data['paintDesc'];
      this.isBundle = data['isBundle'];
      this.isKit = data['isKit'];
      this.paRetailsDescription = data['paRetailsDescription'];
    }
  }

  static fromJS(data: any): ProductAssociatedItem {
    data = typeof data === 'object' ? data : {};
    let result = new ProductAssociatedItem();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.associatedItems)) {
      data['associatedItems'] = [];
      for (let item of this.associatedItems)
        data['associatedItems'].push(item.toJSON());
    }
    data['associatedType'] = this.associatedType;
    data['quantity'] = this.quantity;
    data['unitPrice'] = this.unitPrice;
    data['cedCategoryId'] = this.cedCategoryId;
    data['cedCategory'] = this.cedCategory;
    data['standardDescription'] = this.standardDescription;
    data['isFitted'] = this.isFitted;
    data['linkSource'] = this.linkSource;
    if (Array.isArray(this.attributes)) {
      data['attributes'] = [];
      for (let item of this.attributes) data['attributes'].push(item.toJSON());
    }
    if (Array.isArray(this.richContent)) {
      data['richContent'] = [];
      for (let item of this.richContent)
        data['richContent'].push(item.toJSON());
    }
    data['isMandatory'] = this.isMandatory;
    data['isConflict'] = this.isConflict;
    data['productNumber'] = this.productNumber;
    data['brandId'] = this.brandId;
    data['brandName'] = this.brandName;
    data['companySKU'] = this.companySKU;
    data['universal'] = this.universal;
    data['supersededProductNumber'] = this.supersededProductNumber;
    data['supplierUrl'] = this.supplierUrl;
    data['weight'] = this.weight;
    data['width'] = this.width;
    data['depth'] = this.depth;
    data['length'] = this.length;
    data['barCode'] = this.barCode;
    data['companyProductTitle'] = this.companyProductTitle;
    data['companyProductDescription'] = this.companyProductDescription;
    data['subBrand'] = this.subBrand;
    data['description'] = this.description;
    data['note'] = this.note;
    data['retail'] = this.retail;
    data['richContentUrl'] = this.richContentUrl;
    data['richContentUrlThumb'] = this.richContentUrlThumb;
    data['uom'] = this.uom;
    data['paintableYN'] = this.paintableYN;
    data['fitableYN'] = this.fitableYN;
    data['fitProductCode'] = this.fitProductCode;
    data['fitProductDesc'] = this.fitProductDesc;
    data['fitUnitPriceExTax'] = this.fitUnitPriceExTax;
    data['fitUnitPriceIncTax'] = this.fitUnitPriceIncTax;
    data['fitBundleUnitPriceExTax'] = this.fitBundleUnitPriceExTax;
    data['fitBundleUnitPriceIncTax'] = this.fitBundleUnitPriceIncTax;
    data['paintProductCode'] = this.paintProductCode;
    data['paintDesc'] = this.paintDesc;
    data['isBundle'] = this.isBundle;
    data['isKit'] = this.isKit;
    data['paRetailsDescription'] = this.paRetailsDescription;
    return data;
  }
}

export interface IProductAssociatedItem {
  associatedItems: ProductAssociatedItem[] | undefined;
  associatedType: string | undefined;
  quantity: string | undefined;
  unitPrice: string | undefined;
  cedCategoryId: number;
  cedCategory: string | undefined;
  standardDescription: string | undefined;
  isFitted: boolean;
  linkSource: string | undefined;
  attributes: ProductAttributeDto[] | undefined;
  richContent: ProductRichContentDto[] | undefined;
  isMandatory: boolean;
  isConflict: boolean;
  productNumber: string | undefined;
  brandId: number;
  brandName: string | undefined;
  companySKU: string | undefined;
  universal: boolean;
  supersededProductNumber: string | undefined;
  supplierUrl: string | undefined;
  weight: string | undefined;
  width: string | undefined;
  depth: string | undefined;
  length: string | undefined;
  barCode: string | undefined;
  companyProductTitle: string | undefined;
  companyProductDescription: string | undefined;
  subBrand: string | undefined;
  description: string | undefined;
  note: string | undefined;
  retail: string | undefined;
  richContentUrl: string | undefined;
  richContentUrlThumb: string | undefined;
  uom: string | undefined;
  paintableYN: string | undefined;
  fitableYN: string | undefined;
  fitProductCode: string | undefined;
  fitProductDesc: string | undefined;
  fitUnitPriceExTax: string | undefined;
  fitUnitPriceIncTax: string | undefined;
  fitBundleUnitPriceExTax: string | undefined;
  fitBundleUnitPriceIncTax: string | undefined;
  paintProductCode: string | undefined;
  paintDesc: string | undefined;
  isBundle: boolean;
  isKit: boolean;
  paRetailsDescription: string | undefined;
}

export class ProductApplicationItem implements IProductApplicationItem {
  make!: string | undefined;
  model!: string | undefined;
  year!: string | undefined;
  variant!: string | undefined;
  generation!: string | undefined;
  series!: string | undefined;
  subModel!: string | undefined;
  bodyType!: string | undefined;
  engineType!: string | undefined;
  engineCapacity!: string | undefined;
  engineCode!: string | undefined;
  fuelType!: string | undefined;
  driveType!: string | undefined;
  transmissionType!: string | undefined;
  chassis!: string | undefined;
  paRtsVehicleID!: number;
  paNote!: string | undefined;

  constructor(data?: IProductApplicationItem) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.make = data['make'];
      this.model = data['model'];
      this.year = data['year'];
      this.variant = data['variant'];
      this.generation = data['generation'];
      this.series = data['series'];
      this.subModel = data['subModel'];
      this.bodyType = data['bodyType'];
      this.engineType = data['engineType'];
      this.engineCapacity = data['engineCapacity'];
      this.engineCode = data['engineCode'];
      this.fuelType = data['fuelType'];
      this.driveType = data['driveType'];
      this.transmissionType = data['transmissionType'];
      this.chassis = data['chassis'];
      this.paRtsVehicleID = data['paRtsVehicleID'];
      this.paNote = data['paNote'];
    }
  }

  static fromJS(data: any): ProductApplicationItem {
    data = typeof data === 'object' ? data : {};
    let result = new ProductApplicationItem();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['make'] = this.make;
    data['model'] = this.model;
    data['year'] = this.year;
    data['variant'] = this.variant;
    data['generation'] = this.generation;
    data['series'] = this.series;
    data['subModel'] = this.subModel;
    data['bodyType'] = this.bodyType;
    data['engineType'] = this.engineType;
    data['engineCapacity'] = this.engineCapacity;
    data['engineCode'] = this.engineCode;
    data['fuelType'] = this.fuelType;
    data['driveType'] = this.driveType;
    data['transmissionType'] = this.transmissionType;
    data['chassis'] = this.chassis;
    data['paRtsVehicleID'] = this.paRtsVehicleID;
    data['paNote'] = this.paNote;
    return data;
  }
}

export interface IProductApplicationItem {
  make: string | undefined;
  model: string | undefined;
  year: string | undefined;
  variant: string | undefined;
  generation: string | undefined;
  series: string | undefined;
  subModel: string | undefined;
  bodyType: string | undefined;
  engineType: string | undefined;
  engineCapacity: string | undefined;
  engineCode: string | undefined;
  fuelType: string | undefined;
  driveType: string | undefined;
  transmissionType: string | undefined;
  chassis: string | undefined;
  paRtsVehicleID: number;
  paNote: string | undefined;
}

export class GetProductDetailOutput implements IGetProductDetailOutput {
  found!: boolean;
  fromProductPage!: number | undefined;
  fitDescription!: string | undefined;
  addFitDescription!: string | undefined;
  productInfo!: ProductDetail;
  associatedItems!: ProductAssociatedItem[] | undefined;
  fittingInstructions!: ProductRichContentDto[] | undefined;
  applications!: ProductApplicationItem[] | undefined;
  images!: ProductRichContentDto[] | undefined;
  attributes!: ProductAttributeDto[] | undefined;

  constructor(data?: IGetProductDetailOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.found = data['found'];
      this.fromProductPage = data['fromProductPage'];
      this.fitDescription = data['fitDescription'];
      this.addFitDescription = data['addFitDescription'];
      this.productInfo = data['productInfo']
        ? ProductDetail.fromJS(data['productInfo'])
        : <any>undefined;
      if (Array.isArray(data['associatedItems'])) {
        this.associatedItems = [] as any;
        for (let item of data['associatedItems'])
          this.associatedItems!.push(ProductAssociatedItem.fromJS(item));
      }
      if (Array.isArray(data['fittingInstructions'])) {
        this.fittingInstructions = [] as any;
        for (let item of data['fittingInstructions'])
          this.fittingInstructions!.push(ProductRichContentDto.fromJS(item));
      }
      if (Array.isArray(data['applications'])) {
        this.applications = [] as any;
        for (let item of data['applications'])
          this.applications!.push(ProductApplicationItem.fromJS(item));
      }
      if (Array.isArray(data['images'])) {
        this.images = [] as any;
        for (let item of data['images'])
          this.images!.push(ProductRichContentDto.fromJS(item));
      }
      if (Array.isArray(data['attributes'])) {
        this.attributes = [] as any;
        for (let item of data['attributes'])
          this.attributes!.push(ProductAttributeDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): GetProductDetailOutput {
    data = typeof data === 'object' ? data : {};
    let result = new GetProductDetailOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['found'] = this.found;
    data['fromProductPage'] = this.fromProductPage;
    data['fitDescription'] = this.fitDescription;
    data['addFitDescription'] = this.addFitDescription;
    data['productInfo'] = this.productInfo
      ? this.productInfo.toJSON()
      : <any>undefined;
    if (Array.isArray(this.associatedItems)) {
      data['associatedItems'] = [];
      for (let item of this.associatedItems)
        data['associatedItems'].push(item.toJSON());
    }
    if (Array.isArray(this.fittingInstructions)) {
      data['fittingInstructions'] = [];
      for (let item of this.fittingInstructions)
        data['fittingInstructions'].push(item.toJSON());
    }
    if (Array.isArray(this.applications)) {
      data['applications'] = [];
      for (let item of this.applications)
        data['applications'].push(item.toJSON());
    }
    if (Array.isArray(this.images)) {
      data['images'] = [];
      for (let item of this.images) data['images'].push(item.toJSON());
    }
    if (Array.isArray(this.attributes)) {
      data['attributes'] = [];
      for (let item of this.attributes) data['attributes'].push(item.toJSON());
    }
    return data;
  }
}

export interface IGetProductDetailOutput {
  found: boolean;
  fromProductPage: number | undefined;
  fitDescription: string | undefined;
  addFitDescription: string | undefined;
  productInfo: ProductDetail;
  associatedItems: ProductAssociatedItem[] | undefined;
  fittingInstructions: ProductRichContentDto[] | undefined;
  applications: ProductApplicationItem[] | undefined;
  images: ProductRichContentDto[] | undefined;
  attributes: ProductAttributeDto[] | undefined;
}

export class GetProductDetailsForOrderInput
  implements IGetProductDetailsForOrderInput
{
  productNumber!: string | undefined;
  brand!: string | undefined;
  priceList!: string | undefined;
  vehicleId!: number | undefined;

  constructor(data?: IGetProductDetailsForOrderInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.productNumber = data['productNumber'];
      this.brand = data['brand'];
      this.priceList = data['priceList'];
      this.vehicleId = data['vehicleId'];
    }
  }

  static fromJS(data: any): GetProductDetailsForOrderInput {
    data = typeof data === 'object' ? data : {};
    let result = new GetProductDetailsForOrderInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['productNumber'] = this.productNumber;
    data['brand'] = this.brand;
    data['priceList'] = this.priceList;
    data['vehicleId'] = this.vehicleId;
    return data;
  }
}

export interface IGetProductDetailsForOrderInput {
  productNumber: string | undefined;
  brand: string | undefined;
  priceList: string | undefined;
  vehicleId: number | undefined;
}

export class ProductInfoForOrderOutput implements IProductInfoForOrderOutput {
  productNumber!: string | undefined;
  universal!: boolean;
  companyProductTitle!: string | undefined;
  vehicleFitmentYN!: string | undefined;
  fittingTimeMins!: string | undefined;
  fittingTimeBundleMins!: string | undefined;
  isBundle!: boolean;
  isKit!: boolean;
  fitableYN!: string | undefined;
  fitProductCode!: string | undefined;
  fitDescription!: string | undefined;
  addFitDescription!: string | undefined;
  fitUnitPriceExTax!: string | undefined;
  fitUnitPriceIncTax!: string | undefined;
  fitBundleUnitPriceExTax!: string | undefined;
  fitBundleUnitPriceIncTax!: string | undefined;
  paintableYN!: string | undefined;
  mandatoryPaintYN!: string | undefined;
  paintProductCode!: string | undefined;
  paintDesc!: string | undefined;

  constructor(data?: IProductInfoForOrderOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.productNumber = data['productNumber'];
      this.universal = data['universal'];
      this.companyProductTitle = data['companyProductTitle'];
      this.vehicleFitmentYN = data['vehicleFitmentYN'];
      this.fittingTimeMins = data['fittingTimeMins'];
      this.fittingTimeBundleMins = data['fittingTimeBundleMins'];
      this.isBundle = data['isBundle'];
      this.isKit = data['isKit'];
      this.fitableYN = data['fitableYN'];
      this.fitProductCode = data['fitProductCode'];
      this.fitDescription = data['fitDescription'];
      this.addFitDescription = data['addFitDescription'];
      this.fitUnitPriceExTax = data['fitUnitPriceExTax'];
      this.fitUnitPriceIncTax = data['fitUnitPriceIncTax'];
      this.fitBundleUnitPriceExTax = data['fitBundleUnitPriceExTax'];
      this.fitBundleUnitPriceIncTax = data['fitBundleUnitPriceIncTax'];
      this.paintableYN = data['paintableYN'];
      this.mandatoryPaintYN = data['mandatoryPaintYN'];
      this.paintProductCode = data['paintProductCode'];
      this.paintDesc = data['paintDesc'];
    }
  }

  static fromJS(data: any): ProductInfoForOrderOutput {
    data = typeof data === 'object' ? data : {};
    let result = new ProductInfoForOrderOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['productNumber'] = this.productNumber;
    data['universal'] = this.universal;
    data['companyProductTitle'] = this.companyProductTitle;
    data['vehicleFitmentYN'] = this.vehicleFitmentYN;
    data['fittingTimeMins'] = this.fittingTimeMins;
    data['fittingTimeBundleMins'] = this.fittingTimeBundleMins;
    data['isBundle'] = this.isBundle;
    data['isKit'] = this.isKit;
    data['fitableYN'] = this.fitableYN;
    data['fitProductCode'] = this.fitProductCode;
    data['fitDescription'] = this.fitDescription;
    data['addFitDescription'] = this.addFitDescription;
    data['fitUnitPriceExTax'] = this.fitUnitPriceExTax;
    data['fitUnitPriceIncTax'] = this.fitUnitPriceIncTax;
    data['fitBundleUnitPriceExTax'] = this.fitBundleUnitPriceExTax;
    data['fitBundleUnitPriceIncTax'] = this.fitBundleUnitPriceIncTax;
    data['paintableYN'] = this.paintableYN;
    data['mandatoryPaintYN'] = this.mandatoryPaintYN;
    data['paintProductCode'] = this.paintProductCode;
    data['paintDesc'] = this.paintDesc;
    return data;
  }
}

export interface IProductInfoForOrderOutput {
  productNumber: string | undefined;
  universal: boolean;
  companyProductTitle: string | undefined;
  vehicleFitmentYN: string | undefined;
  fittingTimeMins: string | undefined;
  fittingTimeBundleMins: string | undefined;
  isBundle: boolean;
  isKit: boolean;
  fitableYN: string | undefined;
  fitProductCode: string | undefined;
  fitDescription: string | undefined;
  addFitDescription: string | undefined;
  fitUnitPriceExTax: string | undefined;
  fitUnitPriceIncTax: string | undefined;
  fitBundleUnitPriceExTax: string | undefined;
  fitBundleUnitPriceIncTax: string | undefined;
  paintableYN: string | undefined;
  mandatoryPaintYN: string | undefined;
  paintProductCode: string | undefined;
  paintDesc: string | undefined;
}

export class AssociatedItemForOrder implements IAssociatedItemForOrder {
  associatedType!: string | undefined;
  quantity!: string | undefined;
  isMandatory!: boolean;
  productNumber!: string | undefined;
  universal!: boolean;
  companyProductTitle!: string | undefined;
  vehicleFitmentYN!: string | undefined;
  fittingTimeMins!: string | undefined;
  fittingTimeBundleMins!: string | undefined;
  isBundle!: boolean;
  isKit!: boolean;
  note!: string | undefined;
  retail!: string | undefined;
  fitableYN!: string | undefined;
  fitProductCode!: string | undefined;
  fitDescription!: string | undefined;
  addFitDescription!: string | undefined;
  fitUnitPriceExTax!: string | undefined;
  fitUnitPriceIncTax!: string | undefined;
  fitBundleUnitPriceExTax!: string | undefined;
  fitBundleUnitPriceIncTax!: string | undefined;
  paintableYN!: string | undefined;
  mandatoryPaintYN!: string | undefined;
  paintProductCode!: string | undefined;
  paintDesc!: string | undefined;

  constructor(data?: IAssociatedItemForOrder) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.associatedType = data['associatedType'];
      this.quantity = data['quantity'];
      this.isMandatory = data['isMandatory'];
      this.productNumber = data['productNumber'];
      this.universal = data['universal'];
      this.companyProductTitle = data['companyProductTitle'];
      this.vehicleFitmentYN = data['vehicleFitmentYN'];
      this.fittingTimeMins = data['fittingTimeMins'];
      this.fittingTimeBundleMins = data['fittingTimeBundleMins'];
      this.isBundle = data['isBundle'];
      this.isKit = data['isKit'];
      this.note = data['note'];
      this.retail = data['retail'];
      this.fitableYN = data['fitableYN'];
      this.fitProductCode = data['fitProductCode'];
      this.fitDescription = data['fitDescription'];
      this.addFitDescription = data['addFitDescription'];
      this.fitUnitPriceExTax = data['fitUnitPriceExTax'];
      this.fitUnitPriceIncTax = data['fitUnitPriceIncTax'];
      this.fitBundleUnitPriceExTax = data['fitBundleUnitPriceExTax'];
      this.fitBundleUnitPriceIncTax = data['fitBundleUnitPriceIncTax'];
      this.paintableYN = data['paintableYN'];
      this.mandatoryPaintYN = data['mandatoryPaintYN'];
      this.paintProductCode = data['paintProductCode'];
      this.paintDesc = data['paintDesc'];
    }
  }

  static fromJS(data: any): AssociatedItemForOrder {
    data = typeof data === 'object' ? data : {};
    let result = new AssociatedItemForOrder();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['associatedType'] = this.associatedType;
    data['quantity'] = this.quantity;
    data['isMandatory'] = this.isMandatory;
    data['productNumber'] = this.productNumber;
    data['universal'] = this.universal;
    data['companyProductTitle'] = this.companyProductTitle;
    data['vehicleFitmentYN'] = this.vehicleFitmentYN;
    data['fittingTimeMins'] = this.fittingTimeMins;
    data['fittingTimeBundleMins'] = this.fittingTimeBundleMins;
    data['isBundle'] = this.isBundle;
    data['isKit'] = this.isKit;
    data['note'] = this.note;
    data['retail'] = this.retail;
    data['fitableYN'] = this.fitableYN;
    data['fitProductCode'] = this.fitProductCode;
    data['fitDescription'] = this.fitDescription;
    data['addFitDescription'] = this.addFitDescription;
    data['fitUnitPriceExTax'] = this.fitUnitPriceExTax;
    data['fitUnitPriceIncTax'] = this.fitUnitPriceIncTax;
    data['fitBundleUnitPriceExTax'] = this.fitBundleUnitPriceExTax;
    data['fitBundleUnitPriceIncTax'] = this.fitBundleUnitPriceIncTax;
    data['paintableYN'] = this.paintableYN;
    data['mandatoryPaintYN'] = this.mandatoryPaintYN;
    data['paintProductCode'] = this.paintProductCode;
    data['paintDesc'] = this.paintDesc;
    return data;
  }
}

export interface IAssociatedItemForOrder {
  associatedType: string | undefined;
  quantity: string | undefined;
  isMandatory: boolean;
  productNumber: string | undefined;
  universal: boolean;
  companyProductTitle: string | undefined;
  vehicleFitmentYN: string | undefined;
  fittingTimeMins: string | undefined;
  fittingTimeBundleMins: string | undefined;
  isBundle: boolean;
  isKit: boolean;
  note: string | undefined;
  retail: string | undefined;
  fitableYN: string | undefined;
  fitProductCode: string | undefined;
  fitDescription: string | undefined;
  addFitDescription: string | undefined;
  fitUnitPriceExTax: string | undefined;
  fitUnitPriceIncTax: string | undefined;
  fitBundleUnitPriceExTax: string | undefined;
  fitBundleUnitPriceIncTax: string | undefined;
  paintableYN: string | undefined;
  mandatoryPaintYN: string | undefined;
  paintProductCode: string | undefined;
  paintDesc: string | undefined;
}

export class GetProductDetailsForOrderOutput
  implements IGetProductDetailsForOrderOutput
{
  found!: boolean;
  productInfo!: ProductInfoForOrderOutput;
  associatedItems!: AssociatedItemForOrder[] | undefined;
  success!: boolean;
  error!: string | undefined;

  constructor(data?: IGetProductDetailsForOrderOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.found = data['found'];
      this.productInfo = data['productInfo']
        ? ProductInfoForOrderOutput.fromJS(data['productInfo'])
        : <any>undefined;
      if (Array.isArray(data['associatedItems'])) {
        this.associatedItems = [] as any;
        for (let item of data['associatedItems'])
          this.associatedItems!.push(AssociatedItemForOrder.fromJS(item));
      }
      this.success = data['success'];
      this.error = data['error'];
    }
  }

  static fromJS(data: any): GetProductDetailsForOrderOutput {
    data = typeof data === 'object' ? data : {};
    let result = new GetProductDetailsForOrderOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['found'] = this.found;
    data['productInfo'] = this.productInfo
      ? this.productInfo.toJSON()
      : <any>undefined;
    if (Array.isArray(this.associatedItems)) {
      data['associatedItems'] = [];
      for (let item of this.associatedItems)
        data['associatedItems'].push(item.toJSON());
    }
    data['success'] = this.success;
    data['error'] = this.error;
    return data;
  }
}

export interface IGetProductDetailsForOrderOutput {
  found: boolean;
  productInfo: ProductInfoForOrderOutput;
  associatedItems: AssociatedItemForOrder[] | undefined;
  success: boolean;
  error: string | undefined;
}

export class AssocationAttributesFilter implements IAssocationAttributesFilter {
  attributeName!: string | undefined;
  value!: string[] | undefined;

  constructor(data?: IAssocationAttributesFilter) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.attributeName = data['attributeName'];
      if (Array.isArray(data['value'])) {
        this.value = [] as any;
        for (let item of data['value']) this.value!.push(item);
      }
    }
  }

  static fromJS(data: any): AssocationAttributesFilter {
    data = typeof data === 'object' ? data : {};
    let result = new AssocationAttributesFilter();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['attributeName'] = this.attributeName;
    if (Array.isArray(this.value)) {
      data['value'] = [];
      for (let item of this.value) data['value'].push(item);
    }
    return data;
  }
}

export interface IAssocationAttributesFilter {
  attributeName: string | undefined;
  value: string[] | undefined;
}

export class ProductAssocationsFilteredInput
  implements IProductAssocationsFilteredInput
{
  parentProductNumber!: string | undefined;
  brand!: string | undefined;
  vehicleId!: number | undefined;
  priceList!: string | undefined;
  attributes!: AssocationAttributesFilter[] | undefined;
  associationTypes!: string[] | undefined;

  constructor(data?: IProductAssocationsFilteredInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.parentProductNumber = data['parentProductNumber'];
      this.brand = data['brand'];
      this.vehicleId = data['vehicleId'];
      this.priceList = data['priceList'];
      if (Array.isArray(data['attributes'])) {
        this.attributes = [] as any;
        for (let item of data['attributes'])
          this.attributes!.push(AssocationAttributesFilter.fromJS(item));
      }
      if (Array.isArray(data['associationTypes'])) {
        this.associationTypes = [] as any;
        for (let item of data['associationTypes'])
          this.associationTypes!.push(item);
      }
    }
  }

  static fromJS(data: any): ProductAssocationsFilteredInput {
    data = typeof data === 'object' ? data : {};
    let result = new ProductAssocationsFilteredInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['parentProductNumber'] = this.parentProductNumber;
    data['brand'] = this.brand;
    data['vehicleId'] = this.vehicleId;
    data['priceList'] = this.priceList;
    if (Array.isArray(this.attributes)) {
      data['attributes'] = [];
      for (let item of this.attributes) data['attributes'].push(item.toJSON());
    }
    if (Array.isArray(this.associationTypes)) {
      data['associationTypes'] = [];
      for (let item of this.associationTypes)
        data['associationTypes'].push(item);
    }
    return data;
  }
}

export interface IProductAssocationsFilteredInput {
  parentProductNumber: string | undefined;
  brand: string | undefined;
  vehicleId: number | undefined;
  priceList: string | undefined;
  attributes: AssocationAttributesFilter[] | undefined;
  associationTypes: string[] | undefined;
}

export class SimpleRichContent implements ISimpleRichContent {
  richContentSequence!: number;
  richContentUrl!: string | undefined;
  richContentUrlThumb!: string | undefined;
  richContentType!: string | undefined;

  constructor(data?: ISimpleRichContent) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.richContentSequence = data['richContentSequence'];
      this.richContentUrl = data['richContentUrl'];
      this.richContentUrlThumb = data['richContentUrlThumb'];
      this.richContentType = data['richContentType'];
    }
  }

  static fromJS(data: any): SimpleRichContent {
    data = typeof data === 'object' ? data : {};
    let result = new SimpleRichContent();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['richContentSequence'] = this.richContentSequence;
    data['richContentUrl'] = this.richContentUrl;
    data['richContentUrlThumb'] = this.richContentUrlThumb;
    data['richContentType'] = this.richContentType;
    return data;
  }
}

export interface ISimpleRichContent {
  richContentSequence: number;
  richContentUrl: string | undefined;
  richContentUrlThumb: string | undefined;
  richContentType: string | undefined;
}

export class SimpleAttribute implements ISimpleAttribute {
  fitmentSpecificYN!: string | undefined;
  name!: string | undefined;
  value!: string | undefined;

  constructor(data?: ISimpleAttribute) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.fitmentSpecificYN = data['fitmentSpecificYN'];
      this.name = data['name'];
      this.value = data['value'];
    }
  }

  static fromJS(data: any): SimpleAttribute {
    data = typeof data === 'object' ? data : {};
    let result = new SimpleAttribute();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['fitmentSpecificYN'] = this.fitmentSpecificYN;
    data['name'] = this.name;
    data['value'] = this.value;
    return data;
  }
}

export interface ISimpleAttribute {
  fitmentSpecificYN: string | undefined;
  name: string | undefined;
  value: string | undefined;
}

export class GetAsssociationsFilteredParentProductInfo
  implements IGetAsssociationsFilteredParentProductInfo
{
  richContent!: SimpleRichContent[] | undefined;
  attributes!: SimpleAttribute[] | undefined;
  productNumber!: string | undefined;
  universal!: boolean;
  companyProductTitle!: string | undefined;
  vehicleFitmentYN!: string | undefined;
  fittingTimeMins!: string | undefined;
  fittingTimeBundleMins!: string | undefined;
  isBundle!: boolean;
  isKit!: boolean;
  note!: string | undefined;
  retail!: string | undefined;
  fitableYN!: string | undefined;
  fitProductCode!: string | undefined;
  fitDescription!: string | undefined;
  addFitDescription!: string | undefined;
  fitUnitPriceExTax!: string | undefined;
  fitUnitPriceIncTax!: string | undefined;
  fitBundleUnitPriceExTax!: string | undefined;
  fitBundleUnitPriceIncTax!: string | undefined;
  paintableYN!: string | undefined;
  mandatoryPaintYN!: string | undefined;
  paintProductCode!: string | undefined;
  paintDesc!: string | undefined;

  constructor(data?: IGetAsssociationsFilteredParentProductInfo) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['richContent'])) {
        this.richContent = [] as any;
        for (let item of data['richContent'])
          this.richContent!.push(SimpleRichContent.fromJS(item));
      }
      if (Array.isArray(data['attributes'])) {
        this.attributes = [] as any;
        for (let item of data['attributes'])
          this.attributes!.push(SimpleAttribute.fromJS(item));
      }
      this.productNumber = data['productNumber'];
      this.universal = data['universal'];
      this.companyProductTitle = data['companyProductTitle'];
      this.vehicleFitmentYN = data['vehicleFitmentYN'];
      this.fittingTimeMins = data['fittingTimeMins'];
      this.fittingTimeBundleMins = data['fittingTimeBundleMins'];
      this.isBundle = data['isBundle'];
      this.isKit = data['isKit'];
      this.note = data['note'];
      this.retail = data['retail'];
      this.fitableYN = data['fitableYN'];
      this.fitProductCode = data['fitProductCode'];
      this.fitDescription = data['fitDescription'];
      this.addFitDescription = data['addFitDescription'];
      this.fitUnitPriceExTax = data['fitUnitPriceExTax'];
      this.fitUnitPriceIncTax = data['fitUnitPriceIncTax'];
      this.fitBundleUnitPriceExTax = data['fitBundleUnitPriceExTax'];
      this.fitBundleUnitPriceIncTax = data['fitBundleUnitPriceIncTax'];
      this.paintableYN = data['paintableYN'];
      this.mandatoryPaintYN = data['mandatoryPaintYN'];
      this.paintProductCode = data['paintProductCode'];
      this.paintDesc = data['paintDesc'];
    }
  }

  static fromJS(data: any): GetAsssociationsFilteredParentProductInfo {
    data = typeof data === 'object' ? data : {};
    let result = new GetAsssociationsFilteredParentProductInfo();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.richContent)) {
      data['richContent'] = [];
      for (let item of this.richContent)
        data['richContent'].push(item.toJSON());
    }
    if (Array.isArray(this.attributes)) {
      data['attributes'] = [];
      for (let item of this.attributes) data['attributes'].push(item.toJSON());
    }
    data['productNumber'] = this.productNumber;
    data['universal'] = this.universal;
    data['companyProductTitle'] = this.companyProductTitle;
    data['vehicleFitmentYN'] = this.vehicleFitmentYN;
    data['fittingTimeMins'] = this.fittingTimeMins;
    data['fittingTimeBundleMins'] = this.fittingTimeBundleMins;
    data['isBundle'] = this.isBundle;
    data['isKit'] = this.isKit;
    data['note'] = this.note;
    data['retail'] = this.retail;
    data['fitableYN'] = this.fitableYN;
    data['fitProductCode'] = this.fitProductCode;
    data['fitDescription'] = this.fitDescription;
    data['addFitDescription'] = this.addFitDescription;
    data['fitUnitPriceExTax'] = this.fitUnitPriceExTax;
    data['fitUnitPriceIncTax'] = this.fitUnitPriceIncTax;
    data['fitBundleUnitPriceExTax'] = this.fitBundleUnitPriceExTax;
    data['fitBundleUnitPriceIncTax'] = this.fitBundleUnitPriceIncTax;
    data['paintableYN'] = this.paintableYN;
    data['mandatoryPaintYN'] = this.mandatoryPaintYN;
    data['paintProductCode'] = this.paintProductCode;
    data['paintDesc'] = this.paintDesc;
    return data;
  }
}

export interface IGetAsssociationsFilteredParentProductInfo {
  richContent: SimpleRichContent[] | undefined;
  attributes: SimpleAttribute[] | undefined;
  productNumber: string | undefined;
  universal: boolean;
  companyProductTitle: string | undefined;
  vehicleFitmentYN: string | undefined;
  fittingTimeMins: string | undefined;
  fittingTimeBundleMins: string | undefined;
  isBundle: boolean;
  isKit: boolean;
  note: string | undefined;
  retail: string | undefined;
  fitableYN: string | undefined;
  fitProductCode: string | undefined;
  fitDescription: string | undefined;
  addFitDescription: string | undefined;
  fitUnitPriceExTax: string | undefined;
  fitUnitPriceIncTax: string | undefined;
  fitBundleUnitPriceExTax: string | undefined;
  fitBundleUnitPriceIncTax: string | undefined;
  paintableYN: string | undefined;
  mandatoryPaintYN: string | undefined;
  paintProductCode: string | undefined;
  paintDesc: string | undefined;
}

export class GetAsssociationsFilteredAssociatedItem
  implements IGetAsssociationsFilteredAssociatedItem
{
  associatedType!: string | undefined;
  quantity!: string | undefined;
  isMandatory!: boolean;
  richContent!: SimpleRichContent[] | undefined;
  attributes!: SimpleAttribute[] | undefined;
  productNumber!: string | undefined;
  universal!: boolean;
  companyProductTitle!: string | undefined;
  vehicleFitmentYN!: string | undefined;
  fittingTimeMins!: string | undefined;
  fittingTimeBundleMins!: string | undefined;
  isBundle!: boolean;
  isKit!: boolean;
  note!: string | undefined;
  retail!: string | undefined;
  fitableYN!: string | undefined;
  fitProductCode!: string | undefined;
  fitDescription!: string | undefined;
  addFitDescription!: string | undefined;
  fitUnitPriceExTax!: string | undefined;
  fitUnitPriceIncTax!: string | undefined;
  fitBundleUnitPriceExTax!: string | undefined;
  fitBundleUnitPriceIncTax!: string | undefined;
  paintableYN!: string | undefined;
  mandatoryPaintYN!: string | undefined;
  paintProductCode!: string | undefined;
  paintDesc!: string | undefined;

  constructor(data?: IGetAsssociationsFilteredAssociatedItem) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.associatedType = data['associatedType'];
      this.quantity = data['quantity'];
      this.isMandatory = data['isMandatory'];
      if (Array.isArray(data['richContent'])) {
        this.richContent = [] as any;
        for (let item of data['richContent'])
          this.richContent!.push(SimpleRichContent.fromJS(item));
      }
      if (Array.isArray(data['attributes'])) {
        this.attributes = [] as any;
        for (let item of data['attributes'])
          this.attributes!.push(SimpleAttribute.fromJS(item));
      }
      this.productNumber = data['productNumber'];
      this.universal = data['universal'];
      this.companyProductTitle = data['companyProductTitle'];
      this.vehicleFitmentYN = data['vehicleFitmentYN'];
      this.fittingTimeMins = data['fittingTimeMins'];
      this.fittingTimeBundleMins = data['fittingTimeBundleMins'];
      this.isBundle = data['isBundle'];
      this.isKit = data['isKit'];
      this.note = data['note'];
      this.retail = data['retail'];
      this.fitableYN = data['fitableYN'];
      this.fitProductCode = data['fitProductCode'];
      this.fitDescription = data['fitDescription'];
      this.addFitDescription = data['addFitDescription'];
      this.fitUnitPriceExTax = data['fitUnitPriceExTax'];
      this.fitUnitPriceIncTax = data['fitUnitPriceIncTax'];
      this.fitBundleUnitPriceExTax = data['fitBundleUnitPriceExTax'];
      this.fitBundleUnitPriceIncTax = data['fitBundleUnitPriceIncTax'];
      this.paintableYN = data['paintableYN'];
      this.mandatoryPaintYN = data['mandatoryPaintYN'];
      this.paintProductCode = data['paintProductCode'];
      this.paintDesc = data['paintDesc'];
    }
  }

  static fromJS(data: any): GetAsssociationsFilteredAssociatedItem {
    data = typeof data === 'object' ? data : {};
    let result = new GetAsssociationsFilteredAssociatedItem();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['associatedType'] = this.associatedType;
    data['quantity'] = this.quantity;
    data['isMandatory'] = this.isMandatory;
    if (Array.isArray(this.richContent)) {
      data['richContent'] = [];
      for (let item of this.richContent)
        data['richContent'].push(item.toJSON());
    }
    if (Array.isArray(this.attributes)) {
      data['attributes'] = [];
      for (let item of this.attributes) data['attributes'].push(item.toJSON());
    }
    data['productNumber'] = this.productNumber;
    data['universal'] = this.universal;
    data['companyProductTitle'] = this.companyProductTitle;
    data['vehicleFitmentYN'] = this.vehicleFitmentYN;
    data['fittingTimeMins'] = this.fittingTimeMins;
    data['fittingTimeBundleMins'] = this.fittingTimeBundleMins;
    data['isBundle'] = this.isBundle;
    data['isKit'] = this.isKit;
    data['note'] = this.note;
    data['retail'] = this.retail;
    data['fitableYN'] = this.fitableYN;
    data['fitProductCode'] = this.fitProductCode;
    data['fitDescription'] = this.fitDescription;
    data['addFitDescription'] = this.addFitDescription;
    data['fitUnitPriceExTax'] = this.fitUnitPriceExTax;
    data['fitUnitPriceIncTax'] = this.fitUnitPriceIncTax;
    data['fitBundleUnitPriceExTax'] = this.fitBundleUnitPriceExTax;
    data['fitBundleUnitPriceIncTax'] = this.fitBundleUnitPriceIncTax;
    data['paintableYN'] = this.paintableYN;
    data['mandatoryPaintYN'] = this.mandatoryPaintYN;
    data['paintProductCode'] = this.paintProductCode;
    data['paintDesc'] = this.paintDesc;
    return data;
  }
}

export interface IGetAsssociationsFilteredAssociatedItem {
  associatedType: string | undefined;
  quantity: string | undefined;
  isMandatory: boolean;
  richContent: SimpleRichContent[] | undefined;
  attributes: SimpleAttribute[] | undefined;
  productNumber: string | undefined;
  universal: boolean;
  companyProductTitle: string | undefined;
  vehicleFitmentYN: string | undefined;
  fittingTimeMins: string | undefined;
  fittingTimeBundleMins: string | undefined;
  isBundle: boolean;
  isKit: boolean;
  note: string | undefined;
  retail: string | undefined;
  fitableYN: string | undefined;
  fitProductCode: string | undefined;
  fitDescription: string | undefined;
  addFitDescription: string | undefined;
  fitUnitPriceExTax: string | undefined;
  fitUnitPriceIncTax: string | undefined;
  fitBundleUnitPriceExTax: string | undefined;
  fitBundleUnitPriceIncTax: string | undefined;
  paintableYN: string | undefined;
  mandatoryPaintYN: string | undefined;
  paintProductCode: string | undefined;
  paintDesc: string | undefined;
}

export class ProductAssocationsFilteredOutput
  implements IProductAssocationsFilteredOutput
{
  found!: boolean;
  productInfo!: GetAsssociationsFilteredParentProductInfo;
  associatedItems!: GetAsssociationsFilteredAssociatedItem[] | undefined;
  success!: boolean;
  error!: string | undefined;

  constructor(data?: IProductAssocationsFilteredOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.found = data['found'];
      this.productInfo = data['productInfo']
        ? GetAsssociationsFilteredParentProductInfo.fromJS(data['productInfo'])
        : <any>undefined;
      if (Array.isArray(data['associatedItems'])) {
        this.associatedItems = [] as any;
        for (let item of data['associatedItems'])
          this.associatedItems!.push(
            GetAsssociationsFilteredAssociatedItem.fromJS(item)
          );
      }
      this.success = data['success'];
      this.error = data['error'];
    }
  }

  static fromJS(data: any): ProductAssocationsFilteredOutput {
    data = typeof data === 'object' ? data : {};
    let result = new ProductAssocationsFilteredOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['found'] = this.found;
    data['productInfo'] = this.productInfo
      ? this.productInfo.toJSON()
      : <any>undefined;
    if (Array.isArray(this.associatedItems)) {
      data['associatedItems'] = [];
      for (let item of this.associatedItems)
        data['associatedItems'].push(item.toJSON());
    }
    data['success'] = this.success;
    data['error'] = this.error;
    return data;
  }
}

export interface IProductAssocationsFilteredOutput {
  found: boolean;
  productInfo: GetAsssociationsFilteredParentProductInfo;
  associatedItems: GetAsssociationsFilteredAssociatedItem[] | undefined;
  success: boolean;
  error: string | undefined;
}

export class CurrentUserProfileEditDto implements ICurrentUserProfileEditDto {
  name!: string | undefined;
  surname!: string | undefined;
  userName!: string | undefined;
  emailAddress!: string | undefined;
  phoneNumber!: string | undefined;
  isPhoneNumberConfirmed!: boolean;
  timezone!: string | undefined;
  qrCodeSetupImageUrl!: string | undefined;
  isGoogleAuthenticatorEnabled!: boolean;

  constructor(data?: ICurrentUserProfileEditDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.name = data['name'];
      this.surname = data['surname'];
      this.userName = data['userName'];
      this.emailAddress = data['emailAddress'];
      this.phoneNumber = data['phoneNumber'];
      this.isPhoneNumberConfirmed = data['isPhoneNumberConfirmed'];
      this.timezone = data['timezone'];
      this.qrCodeSetupImageUrl = data['qrCodeSetupImageUrl'];
      this.isGoogleAuthenticatorEnabled = data['isGoogleAuthenticatorEnabled'];
    }
  }

  static fromJS(data: any): CurrentUserProfileEditDto {
    data = typeof data === 'object' ? data : {};
    let result = new CurrentUserProfileEditDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['name'] = this.name;
    data['surname'] = this.surname;
    data['userName'] = this.userName;
    data['emailAddress'] = this.emailAddress;
    data['phoneNumber'] = this.phoneNumber;
    data['isPhoneNumberConfirmed'] = this.isPhoneNumberConfirmed;
    data['timezone'] = this.timezone;
    data['qrCodeSetupImageUrl'] = this.qrCodeSetupImageUrl;
    data['isGoogleAuthenticatorEnabled'] = this.isGoogleAuthenticatorEnabled;
    return data;
  }
}

export interface ICurrentUserProfileEditDto {
  name: string | undefined;
  surname: string | undefined;
  userName: string | undefined;
  emailAddress: string | undefined;
  phoneNumber: string | undefined;
  isPhoneNumberConfirmed: boolean;
  timezone: string | undefined;
  qrCodeSetupImageUrl: string | undefined;
  isGoogleAuthenticatorEnabled: boolean;
}

export class UpdateGoogleAuthenticatorKeyOutput
  implements IUpdateGoogleAuthenticatorKeyOutput
{
  qrCodeSetupImageUrl!: string | undefined;

  constructor(data?: IUpdateGoogleAuthenticatorKeyOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.qrCodeSetupImageUrl = data['qrCodeSetupImageUrl'];
    }
  }

  static fromJS(data: any): UpdateGoogleAuthenticatorKeyOutput {
    data = typeof data === 'object' ? data : {};
    let result = new UpdateGoogleAuthenticatorKeyOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['qrCodeSetupImageUrl'] = this.qrCodeSetupImageUrl;
    return data;
  }
}

export interface IUpdateGoogleAuthenticatorKeyOutput {
  qrCodeSetupImageUrl: string | undefined;
}

export class SendVerificationSmsInputDto
  implements ISendVerificationSmsInputDto
{
  phoneNumber!: string | undefined;

  constructor(data?: ISendVerificationSmsInputDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.phoneNumber = data['phoneNumber'];
    }
  }

  static fromJS(data: any): SendVerificationSmsInputDto {
    data = typeof data === 'object' ? data : {};
    let result = new SendVerificationSmsInputDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['phoneNumber'] = this.phoneNumber;
    return data;
  }
}

export interface ISendVerificationSmsInputDto {
  phoneNumber: string | undefined;
}

export class VerifySmsCodeInputDto implements IVerifySmsCodeInputDto {
  code!: string | undefined;
  phoneNumber!: string | undefined;

  constructor(data?: IVerifySmsCodeInputDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.code = data['code'];
      this.phoneNumber = data['phoneNumber'];
    }
  }

  static fromJS(data: any): VerifySmsCodeInputDto {
    data = typeof data === 'object' ? data : {};
    let result = new VerifySmsCodeInputDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['code'] = this.code;
    data['phoneNumber'] = this.phoneNumber;
    return data;
  }
}

export interface IVerifySmsCodeInputDto {
  code: string | undefined;
  phoneNumber: string | undefined;
}

export class ChangePasswordInput implements IChangePasswordInput {
  currentPassword!: string | undefined;
  newPassword!: string | undefined;

  constructor(data?: IChangePasswordInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.currentPassword = data['currentPassword'];
      this.newPassword = data['newPassword'];
    }
  }

  static fromJS(data: any): ChangePasswordInput {
    data = typeof data === 'object' ? data : {};
    let result = new ChangePasswordInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['currentPassword'] = this.currentPassword;
    data['newPassword'] = this.newPassword;
    return data;
  }
}

export interface IChangePasswordInput {
  currentPassword: string | undefined;
  newPassword: string | undefined;
}

export class UpdateProfilePictureInput implements IUpdateProfilePictureInput {
  fileToken!: string | undefined;
  x!: number;
  y!: number;
  width!: number;
  height!: number;

  constructor(data?: IUpdateProfilePictureInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.fileToken = data['fileToken'];
      this.x = data['x'];
      this.y = data['y'];
      this.width = data['width'];
      this.height = data['height'];
    }
  }

  static fromJS(data: any): UpdateProfilePictureInput {
    data = typeof data === 'object' ? data : {};
    let result = new UpdateProfilePictureInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['fileToken'] = this.fileToken;
    data['x'] = this.x;
    data['y'] = this.y;
    data['width'] = this.width;
    data['height'] = this.height;
    return data;
  }
}

export interface IUpdateProfilePictureInput {
  fileToken: string | undefined;
  x: number;
  y: number;
  width: number;
  height: number;
}

export class GetPasswordComplexitySettingOutput
  implements IGetPasswordComplexitySettingOutput
{
  setting!: PasswordComplexitySetting;

  constructor(data?: IGetPasswordComplexitySettingOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.setting = data['setting']
        ? PasswordComplexitySetting.fromJS(data['setting'])
        : <any>undefined;
    }
  }

  static fromJS(data: any): GetPasswordComplexitySettingOutput {
    data = typeof data === 'object' ? data : {};
    let result = new GetPasswordComplexitySettingOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['setting'] = this.setting ? this.setting.toJSON() : <any>undefined;
    return data;
  }
}

export interface IGetPasswordComplexitySettingOutput {
  setting: PasswordComplexitySetting;
}

export class GetProfilePictureOutput implements IGetProfilePictureOutput {
  profilePicture!: string | undefined;

  constructor(data?: IGetProfilePictureOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.profilePicture = data['profilePicture'];
    }
  }

  static fromJS(data: any): GetProfilePictureOutput {
    data = typeof data === 'object' ? data : {};
    let result = new GetProfilePictureOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['profilePicture'] = this.profilePicture;
    return data;
  }
}

export interface IGetProfilePictureOutput {
  profilePicture: string | undefined;
}

export class ChangeUserLanguageDto implements IChangeUserLanguageDto {
  languageName!: string | undefined;

  constructor(data?: IChangeUserLanguageDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.languageName = data['languageName'];
    }
  }

  static fromJS(data: any): ChangeUserLanguageDto {
    data = typeof data === 'object' ? data : {};
    let result = new ChangeUserLanguageDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['languageName'] = this.languageName;
    return data;
  }
}

export interface IChangeUserLanguageDto {
  languageName: string | undefined;
}

export class ResourcePageDto implements IResourcePageDto {
  title!: string | undefined;
  body!: string | undefined;
  urlSlug!: string | undefined;
  published!: boolean;
  userGroups!: string | undefined;
  seoFriendlyName!: string | undefined;
  seoMetaTitle!: string | undefined;
  seoMetaDescription!: string | undefined;
  seoMetaKeywords!: string | undefined;
  creationTime!: moment.Moment;
  creatorUserId!: number | undefined;
  id!: number | undefined;

  constructor(data?: IResourcePageDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.title = data['title'];
      this.body = data['body'];
      this.urlSlug = data['urlSlug'];
      this.published = data['published'];
      this.userGroups = data['userGroups'];
      this.seoFriendlyName = data['seoFriendlyName'];
      this.seoMetaTitle = data['seoMetaTitle'];
      this.seoMetaDescription = data['seoMetaDescription'];
      this.seoMetaKeywords = data['seoMetaKeywords'];
      this.creationTime = data['creationTime']
        ? moment(data['creationTime'].toString())
        : <any>undefined;
      this.creatorUserId = data['creatorUserId'];
      this.id = data['id'];
    }
  }

  static fromJS(data: any): ResourcePageDto {
    data = typeof data === 'object' ? data : {};
    let result = new ResourcePageDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['title'] = this.title;
    data['body'] = this.body;
    data['urlSlug'] = this.urlSlug;
    data['published'] = this.published;
    data['userGroups'] = this.userGroups;
    data['seoFriendlyName'] = this.seoFriendlyName;
    data['seoMetaTitle'] = this.seoMetaTitle;
    data['seoMetaDescription'] = this.seoMetaDescription;
    data['seoMetaKeywords'] = this.seoMetaKeywords;
    data['creationTime'] = this.creationTime
      ? this.creationTime.toISOString()
      : <any>undefined;
    data['creatorUserId'] = this.creatorUserId;
    data['id'] = this.id;
    return data;
  }
}

export interface IResourcePageDto {
  title: string | undefined;
  body: string | undefined;
  urlSlug: string | undefined;
  published: boolean;
  userGroups: string | undefined;
  seoFriendlyName: string | undefined;
  seoMetaTitle: string | undefined;
  seoMetaDescription: string | undefined;
  seoMetaKeywords: string | undefined;
  creationTime: moment.Moment;
  creatorUserId: number | undefined;
  id: number | undefined;
}

export class PagedResultDtoOfResourcePageDto
  implements IPagedResultDtoOfResourcePageDto
{
  totalCount!: number;
  items!: ResourcePageDto[] | undefined;

  constructor(data?: IPagedResultDtoOfResourcePageDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.totalCount = data['totalCount'];
      if (Array.isArray(data['items'])) {
        this.items = [] as any;
        for (let item of data['items'])
          this.items!.push(ResourcePageDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): PagedResultDtoOfResourcePageDto {
    data = typeof data === 'object' ? data : {};
    let result = new PagedResultDtoOfResourcePageDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['totalCount'] = this.totalCount;
    if (Array.isArray(this.items)) {
      data['items'] = [];
      for (let item of this.items) data['items'].push(item.toJSON());
    }
    return data;
  }
}

export interface IPagedResultDtoOfResourcePageDto {
  totalCount: number;
  items: ResourcePageDto[] | undefined;
}

export class ResourcePageEditDto implements IResourcePageEditDto {
  title!: string | undefined;
  body!: string | undefined;
  urlSlug!: string | undefined;
  published!: boolean;
  userGroups!: string[] | undefined;
  seoFriendlyName!: string | undefined;
  seoMetaTitle!: string | undefined;
  seoMetaDescription!: string | undefined;
  seoMetaKeywords!: string | undefined;
  creationTime!: moment.Moment;
  creatorUserId!: number | undefined;
  id!: number | undefined;

  constructor(data?: IResourcePageEditDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.title = data['title'];
      this.body = data['body'];
      this.urlSlug = data['urlSlug'];
      this.published = data['published'];
      if (Array.isArray(data['userGroups'])) {
        this.userGroups = [] as any;
        for (let item of data['userGroups']) this.userGroups!.push(item);
      }
      this.seoFriendlyName = data['seoFriendlyName'];
      this.seoMetaTitle = data['seoMetaTitle'];
      this.seoMetaDescription = data['seoMetaDescription'];
      this.seoMetaKeywords = data['seoMetaKeywords'];
      this.creationTime = data['creationTime']
        ? moment(data['creationTime'].toString())
        : <any>undefined;
      this.creatorUserId = data['creatorUserId'];
      this.id = data['id'];
    }
  }

  static fromJS(data: any): ResourcePageEditDto {
    data = typeof data === 'object' ? data : {};
    let result = new ResourcePageEditDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['title'] = this.title;
    data['body'] = this.body;
    data['urlSlug'] = this.urlSlug;
    data['published'] = this.published;
    if (Array.isArray(this.userGroups)) {
      data['userGroups'] = [];
      for (let item of this.userGroups) data['userGroups'].push(item);
    }
    data['seoFriendlyName'] = this.seoFriendlyName;
    data['seoMetaTitle'] = this.seoMetaTitle;
    data['seoMetaDescription'] = this.seoMetaDescription;
    data['seoMetaKeywords'] = this.seoMetaKeywords;
    data['creationTime'] = this.creationTime
      ? this.creationTime.toISOString()
      : <any>undefined;
    data['creatorUserId'] = this.creatorUserId;
    data['id'] = this.id;
    return data;
  }
}

export interface IResourcePageEditDto {
  title: string | undefined;
  body: string | undefined;
  urlSlug: string | undefined;
  published: boolean;
  userGroups: string[] | undefined;
  seoFriendlyName: string | undefined;
  seoMetaTitle: string | undefined;
  seoMetaDescription: string | undefined;
  seoMetaKeywords: string | undefined;
  creationTime: moment.Moment;
  creatorUserId: number | undefined;
  id: number | undefined;
}

export class CreateOrUpdateResourcePageInput
  implements ICreateOrUpdateResourcePageInput
{
  id!: number | undefined;
  title!: string | undefined;
  body!: string | undefined;
  urlSlug!: string | undefined;
  published!: boolean;
  userGroups!: string[] | undefined;
  seoFriendlyName!: string | undefined;
  seoMetaTitle!: string | undefined;
  seoMetaDescription!: string | undefined;
  seoMetaKeywords!: string | undefined;

  constructor(data?: ICreateOrUpdateResourcePageInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id'];
      this.title = data['title'];
      this.body = data['body'];
      this.urlSlug = data['urlSlug'];
      this.published = data['published'];
      if (Array.isArray(data['userGroups'])) {
        this.userGroups = [] as any;
        for (let item of data['userGroups']) this.userGroups!.push(item);
      }
      this.seoFriendlyName = data['seoFriendlyName'];
      this.seoMetaTitle = data['seoMetaTitle'];
      this.seoMetaDescription = data['seoMetaDescription'];
      this.seoMetaKeywords = data['seoMetaKeywords'];
    }
  }

  static fromJS(data: any): CreateOrUpdateResourcePageInput {
    data = typeof data === 'object' ? data : {};
    let result = new CreateOrUpdateResourcePageInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['id'] = this.id;
    data['title'] = this.title;
    data['body'] = this.body;
    data['urlSlug'] = this.urlSlug;
    data['published'] = this.published;
    if (Array.isArray(this.userGroups)) {
      data['userGroups'] = [];
      for (let item of this.userGroups) data['userGroups'].push(item);
    }
    data['seoFriendlyName'] = this.seoFriendlyName;
    data['seoMetaTitle'] = this.seoMetaTitle;
    data['seoMetaDescription'] = this.seoMetaDescription;
    data['seoMetaKeywords'] = this.seoMetaKeywords;
    return data;
  }
}

export interface ICreateOrUpdateResourcePageInput {
  id: number | undefined;
  title: string | undefined;
  body: string | undefined;
  urlSlug: string | undefined;
  published: boolean;
  userGroups: string[] | undefined;
  seoFriendlyName: string | undefined;
  seoMetaTitle: string | undefined;
  seoMetaDescription: string | undefined;
  seoMetaKeywords: string | undefined;
}

export class ResourcePageContentDto implements IResourcePageContentDto {
  title!: string | undefined;
  body!: string | undefined;
  seoFriendlyName!: string | undefined;
  seoMetaTitle!: string | undefined;
  seoMetaDescription!: string | undefined;
  seoMetaKeywords!: string | undefined;

  constructor(data?: IResourcePageContentDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.title = data['title'];
      this.body = data['body'];
      this.seoFriendlyName = data['seoFriendlyName'];
      this.seoMetaTitle = data['seoMetaTitle'];
      this.seoMetaDescription = data['seoMetaDescription'];
      this.seoMetaKeywords = data['seoMetaKeywords'];
    }
  }

  static fromJS(data: any): ResourcePageContentDto {
    data = typeof data === 'object' ? data : {};
    let result = new ResourcePageContentDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['title'] = this.title;
    data['body'] = this.body;
    data['seoFriendlyName'] = this.seoFriendlyName;
    data['seoMetaTitle'] = this.seoMetaTitle;
    data['seoMetaDescription'] = this.seoMetaDescription;
    data['seoMetaKeywords'] = this.seoMetaKeywords;
    return data;
  }
}

export interface IResourcePageContentDto {
  title: string | undefined;
  body: string | undefined;
  seoFriendlyName: string | undefined;
  seoMetaTitle: string | undefined;
  seoMetaDescription: string | undefined;
  seoMetaKeywords: string | undefined;
}

export class GetResourcePageContentOutputDto
  implements IGetResourcePageContentOutputDto
{
  status!: string | undefined;
  resourcePageContent!: ResourcePageContentDto;

  constructor(data?: IGetResourcePageContentOutputDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.status = data['status'];
      this.resourcePageContent = data['resourcePageContent']
        ? ResourcePageContentDto.fromJS(data['resourcePageContent'])
        : <any>undefined;
    }
  }

  static fromJS(data: any): GetResourcePageContentOutputDto {
    data = typeof data === 'object' ? data : {};
    let result = new GetResourcePageContentOutputDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['status'] = this.status;
    data['resourcePageContent'] = this.resourcePageContent
      ? this.resourcePageContent.toJSON()
      : <any>undefined;
    return data;
  }
}

export interface IGetResourcePageContentOutputDto {
  status: string | undefined;
  resourcePageContent: ResourcePageContentDto;
}

export class RoleListDto implements IRoleListDto {
  name!: string | undefined;
  displayName!: string | undefined;
  isStatic!: boolean;
  isDefault!: boolean;
  creationTime!: moment.Moment;
  id!: number;

  constructor(data?: IRoleListDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.name = data['name'];
      this.displayName = data['displayName'];
      this.isStatic = data['isStatic'];
      this.isDefault = data['isDefault'];
      this.creationTime = data['creationTime']
        ? moment(data['creationTime'].toString())
        : <any>undefined;
      this.id = data['id'];
    }
  }

  static fromJS(data: any): RoleListDto {
    data = typeof data === 'object' ? data : {};
    let result = new RoleListDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['name'] = this.name;
    data['displayName'] = this.displayName;
    data['isStatic'] = this.isStatic;
    data['isDefault'] = this.isDefault;
    data['creationTime'] = this.creationTime
      ? this.creationTime.toISOString()
      : <any>undefined;
    data['id'] = this.id;
    return data;
  }
}

export interface IRoleListDto {
  name: string | undefined;
  displayName: string | undefined;
  isStatic: boolean;
  isDefault: boolean;
  creationTime: moment.Moment;
  id: number;
}

export class ListResultDtoOfRoleListDto implements IListResultDtoOfRoleListDto {
  items!: RoleListDto[] | undefined;

  constructor(data?: IListResultDtoOfRoleListDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['items'])) {
        this.items = [] as any;
        for (let item of data['items'])
          this.items!.push(RoleListDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): ListResultDtoOfRoleListDto {
    data = typeof data === 'object' ? data : {};
    let result = new ListResultDtoOfRoleListDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.items)) {
      data['items'] = [];
      for (let item of this.items) data['items'].push(item.toJSON());
    }
    return data;
  }
}

export interface IListResultDtoOfRoleListDto {
  items: RoleListDto[] | undefined;
}

export class RoleEditDto implements IRoleEditDto {
  id!: number | undefined;
  displayName!: string | undefined;
  isDefault!: boolean;

  constructor(data?: IRoleEditDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id'];
      this.displayName = data['displayName'];
      this.isDefault = data['isDefault'];
    }
  }

  static fromJS(data: any): RoleEditDto {
    data = typeof data === 'object' ? data : {};
    let result = new RoleEditDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['id'] = this.id;
    data['displayName'] = this.displayName;
    data['isDefault'] = this.isDefault;
    return data;
  }
}

export interface IRoleEditDto {
  id: number | undefined;
  displayName: string | undefined;
  isDefault: boolean;
}

export class FlatPermissionDto implements IFlatPermissionDto {
  parentName!: string | undefined;
  name!: string | undefined;
  displayName!: string | undefined;
  description!: string | undefined;
  isGrantedByDefault!: boolean;

  constructor(data?: IFlatPermissionDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.parentName = data['parentName'];
      this.name = data['name'];
      this.displayName = data['displayName'];
      this.description = data['description'];
      this.isGrantedByDefault = data['isGrantedByDefault'];
    }
  }

  static fromJS(data: any): FlatPermissionDto {
    data = typeof data === 'object' ? data : {};
    let result = new FlatPermissionDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['parentName'] = this.parentName;
    data['name'] = this.name;
    data['displayName'] = this.displayName;
    data['description'] = this.description;
    data['isGrantedByDefault'] = this.isGrantedByDefault;
    return data;
  }
}

export interface IFlatPermissionDto {
  parentName: string | undefined;
  name: string | undefined;
  displayName: string | undefined;
  description: string | undefined;
  isGrantedByDefault: boolean;
}

export class GetRoleForEditOutput implements IGetRoleForEditOutput {
  role!: RoleEditDto;
  permissions!: FlatPermissionDto[] | undefined;
  grantedPermissionNames!: string[] | undefined;

  constructor(data?: IGetRoleForEditOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.role = data['role']
        ? RoleEditDto.fromJS(data['role'])
        : <any>undefined;
      if (Array.isArray(data['permissions'])) {
        this.permissions = [] as any;
        for (let item of data['permissions'])
          this.permissions!.push(FlatPermissionDto.fromJS(item));
      }
      if (Array.isArray(data['grantedPermissionNames'])) {
        this.grantedPermissionNames = [] as any;
        for (let item of data['grantedPermissionNames'])
          this.grantedPermissionNames!.push(item);
      }
    }
  }

  static fromJS(data: any): GetRoleForEditOutput {
    data = typeof data === 'object' ? data : {};
    let result = new GetRoleForEditOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['role'] = this.role ? this.role.toJSON() : <any>undefined;
    if (Array.isArray(this.permissions)) {
      data['permissions'] = [];
      for (let item of this.permissions)
        data['permissions'].push(item.toJSON());
    }
    if (Array.isArray(this.grantedPermissionNames)) {
      data['grantedPermissionNames'] = [];
      for (let item of this.grantedPermissionNames)
        data['grantedPermissionNames'].push(item);
    }
    return data;
  }
}

export interface IGetRoleForEditOutput {
  role: RoleEditDto;
  permissions: FlatPermissionDto[] | undefined;
  grantedPermissionNames: string[] | undefined;
}

export class CreateOrUpdateRoleInput implements ICreateOrUpdateRoleInput {
  role!: RoleEditDto;
  grantedPermissionNames!: string[] | undefined;

  constructor(data?: ICreateOrUpdateRoleInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
    if (!data) {
      this.role = new RoleEditDto();
    }
  }

  init(data?: any) {
    if (data) {
      this.role = data['role']
        ? RoleEditDto.fromJS(data['role'])
        : new RoleEditDto();
      if (Array.isArray(data['grantedPermissionNames'])) {
        this.grantedPermissionNames = [] as any;
        for (let item of data['grantedPermissionNames'])
          this.grantedPermissionNames!.push(item);
      }
    }
  }

  static fromJS(data: any): CreateOrUpdateRoleInput {
    data = typeof data === 'object' ? data : {};
    let result = new CreateOrUpdateRoleInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['role'] = this.role ? this.role.toJSON() : <any>undefined;
    if (Array.isArray(this.grantedPermissionNames)) {
      data['grantedPermissionNames'] = [];
      for (let item of this.grantedPermissionNames)
        data['grantedPermissionNames'].push(item);
    }
    return data;
  }
}

export interface ICreateOrUpdateRoleInput {
  role: RoleEditDto;
  grantedPermissionNames: string[] | undefined;
}

export class UserLoginInfoDto implements IUserLoginInfoDto {
  name!: string | undefined;
  surname!: string | undefined;
  userName!: string | undefined;
  emailAddress!: string | undefined;
  profilePictureId!: string | undefined;
  id!: number;

  constructor(data?: IUserLoginInfoDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.name = data['name'];
      this.surname = data['surname'];
      this.userName = data['userName'];
      this.emailAddress = data['emailAddress'];
      this.profilePictureId = data['profilePictureId'];
      this.id = data['id'];
    }
  }

  static fromJS(data: any): UserLoginInfoDto {
    data = typeof data === 'object' ? data : {};
    let result = new UserLoginInfoDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['name'] = this.name;
    data['surname'] = this.surname;
    data['userName'] = this.userName;
    data['emailAddress'] = this.emailAddress;
    data['profilePictureId'] = this.profilePictureId;
    data['id'] = this.id;
    return data;
  }
}

export interface IUserLoginInfoDto {
  name: string | undefined;
  surname: string | undefined;
  userName: string | undefined;
  emailAddress: string | undefined;
  profilePictureId: string | undefined;
  id: number;
}

export enum SubscriptionPaymentType {
  Manual = 0,
  RecurringAutomatic = 1,
  RecurringManual = 2,
}

export class EditionInfoDto implements IEditionInfoDto {
  displayName!: string | undefined;
  trialDayCount!: number | undefined;
  monthlyPrice!: number | undefined;
  annualPrice!: number | undefined;
  isHighestEdition!: boolean;
  isFree!: boolean;
  id!: number;

  constructor(data?: IEditionInfoDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.displayName = data['displayName'];
      this.trialDayCount = data['trialDayCount'];
      this.monthlyPrice = data['monthlyPrice'];
      this.annualPrice = data['annualPrice'];
      this.isHighestEdition = data['isHighestEdition'];
      this.isFree = data['isFree'];
      this.id = data['id'];
    }
  }

  static fromJS(data: any): EditionInfoDto {
    data = typeof data === 'object' ? data : {};
    let result = new EditionInfoDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['displayName'] = this.displayName;
    data['trialDayCount'] = this.trialDayCount;
    data['monthlyPrice'] = this.monthlyPrice;
    data['annualPrice'] = this.annualPrice;
    data['isHighestEdition'] = this.isHighestEdition;
    data['isFree'] = this.isFree;
    data['id'] = this.id;
    return data;
  }
}

export interface IEditionInfoDto {
  displayName: string | undefined;
  trialDayCount: number | undefined;
  monthlyPrice: number | undefined;
  annualPrice: number | undefined;
  isHighestEdition: boolean;
  isFree: boolean;
  id: number;
}

export class TenantLoginInfoDto implements ITenantLoginInfoDto {
  tenancyName!: string | undefined;
  name!: string | undefined;
  logoId!: string | undefined;
  logoFileType!: string | undefined;
  customCssId!: string | undefined;
  subscriptionEndDateUtc!: moment.Moment | undefined;
  isInTrialPeriod!: boolean;
  subscriptionPaymentType!: SubscriptionPaymentType;
  edition!: EditionInfoDto;
  creationTime!: moment.Moment;
  paymentPeriodType!: PaymentPeriodType;
  subscriptionDateString!: string | undefined;
  creationTimeString!: string | undefined;
  id!: number;

  constructor(data?: ITenantLoginInfoDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.tenancyName = data['tenancyName'];
      this.name = data['name'];
      this.logoId = data['logoId'];
      this.logoFileType = data['logoFileType'];
      this.customCssId = data['customCssId'];
      this.subscriptionEndDateUtc = data['subscriptionEndDateUtc']
        ? moment(data['subscriptionEndDateUtc'].toString())
        : <any>undefined;
      this.isInTrialPeriod = data['isInTrialPeriod'];
      this.subscriptionPaymentType = data['subscriptionPaymentType'];
      this.edition = data['edition']
        ? EditionInfoDto.fromJS(data['edition'])
        : <any>undefined;
      this.creationTime = data['creationTime']
        ? moment(data['creationTime'].toString())
        : <any>undefined;
      this.paymentPeriodType = data['paymentPeriodType'];
      this.subscriptionDateString = data['subscriptionDateString'];
      this.creationTimeString = data['creationTimeString'];
      this.id = data['id'];
    }
  }

  static fromJS(data: any): TenantLoginInfoDto {
    data = typeof data === 'object' ? data : {};
    let result = new TenantLoginInfoDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['tenancyName'] = this.tenancyName;
    data['name'] = this.name;
    data['logoId'] = this.logoId;
    data['logoFileType'] = this.logoFileType;
    data['customCssId'] = this.customCssId;
    data['subscriptionEndDateUtc'] = this.subscriptionEndDateUtc
      ? this.subscriptionEndDateUtc.toISOString()
      : <any>undefined;
    data['isInTrialPeriod'] = this.isInTrialPeriod;
    data['subscriptionPaymentType'] = this.subscriptionPaymentType;
    data['edition'] = this.edition ? this.edition.toJSON() : <any>undefined;
    data['creationTime'] = this.creationTime
      ? this.creationTime.toISOString()
      : <any>undefined;
    data['paymentPeriodType'] = this.paymentPeriodType;
    data['subscriptionDateString'] = this.subscriptionDateString;
    data['creationTimeString'] = this.creationTimeString;
    data['id'] = this.id;
    return data;
  }
}

export interface ITenantLoginInfoDto {
  tenancyName: string | undefined;
  name: string | undefined;
  logoId: string | undefined;
  logoFileType: string | undefined;
  customCssId: string | undefined;
  subscriptionEndDateUtc: moment.Moment | undefined;
  isInTrialPeriod: boolean;
  subscriptionPaymentType: SubscriptionPaymentType;
  edition: EditionInfoDto;
  creationTime: moment.Moment;
  paymentPeriodType: PaymentPeriodType;
  subscriptionDateString: string | undefined;
  creationTimeString: string | undefined;
  id: number;
}

export class ApplicationInfoDto implements IApplicationInfoDto {
  version!: string | undefined;
  releaseDate!: moment.Moment;
  currency!: string | undefined;
  currencySign!: string | undefined;
  allowTenantsToChangeEmailSettings!: boolean;
  features!: { [key: string]: boolean } | undefined;

  constructor(data?: IApplicationInfoDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.version = data['version'];
      this.releaseDate = data['releaseDate']
        ? moment(data['releaseDate'].toString())
        : <any>undefined;
      this.currency = data['currency'];
      this.currencySign = data['currencySign'];
      this.allowTenantsToChangeEmailSettings =
        data['allowTenantsToChangeEmailSettings'];
      if (data['features']) {
        this.features = {} as any;
        for (let key in data['features']) {
          if (data['features'].hasOwnProperty(key))
            this.features![key] = data['features'][key];
        }
      }
    }
  }

  static fromJS(data: any): ApplicationInfoDto {
    data = typeof data === 'object' ? data : {};
    let result = new ApplicationInfoDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['version'] = this.version;
    data['releaseDate'] = this.releaseDate
      ? this.releaseDate.toISOString()
      : <any>undefined;
    data['currency'] = this.currency;
    data['currencySign'] = this.currencySign;
    data['allowTenantsToChangeEmailSettings'] =
      this.allowTenantsToChangeEmailSettings;
    if (this.features) {
      data['features'] = {};
      for (let key in this.features) {
        if (this.features.hasOwnProperty(key))
          data['features'][key] = this.features[key];
      }
    }
    return data;
  }
}

export interface IApplicationInfoDto {
  version: string | undefined;
  releaseDate: moment.Moment;
  currency: string | undefined;
  currencySign: string | undefined;
  allowTenantsToChangeEmailSettings: boolean;
  features: { [key: string]: boolean } | undefined;
}

export class ThemeLayoutSettingsDto implements IThemeLayoutSettingsDto {
  layoutType!: string | undefined;

  constructor(data?: IThemeLayoutSettingsDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.layoutType = data['layoutType'];
    }
  }

  static fromJS(data: any): ThemeLayoutSettingsDto {
    data = typeof data === 'object' ? data : {};
    let result = new ThemeLayoutSettingsDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['layoutType'] = this.layoutType;
    return data;
  }
}

export interface IThemeLayoutSettingsDto {
  layoutType: string | undefined;
}

export class ThemeHeaderSettingsDto implements IThemeHeaderSettingsDto {
  desktopFixedHeader!: boolean;
  mobileFixedHeader!: boolean;
  headerSkin!: string | undefined;
  minimizeDesktopHeaderType!: string | undefined;
  headerMenuArrows!: boolean;

  constructor(data?: IThemeHeaderSettingsDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.desktopFixedHeader = data['desktopFixedHeader'];
      this.mobileFixedHeader = data['mobileFixedHeader'];
      this.headerSkin = data['headerSkin'];
      this.minimizeDesktopHeaderType = data['minimizeDesktopHeaderType'];
      this.headerMenuArrows = data['headerMenuArrows'];
    }
  }

  static fromJS(data: any): ThemeHeaderSettingsDto {
    data = typeof data === 'object' ? data : {};
    let result = new ThemeHeaderSettingsDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['desktopFixedHeader'] = this.desktopFixedHeader;
    data['mobileFixedHeader'] = this.mobileFixedHeader;
    data['headerSkin'] = this.headerSkin;
    data['minimizeDesktopHeaderType'] = this.minimizeDesktopHeaderType;
    data['headerMenuArrows'] = this.headerMenuArrows;
    return data;
  }
}

export interface IThemeHeaderSettingsDto {
  desktopFixedHeader: boolean;
  mobileFixedHeader: boolean;
  headerSkin: string | undefined;
  minimizeDesktopHeaderType: string | undefined;
  headerMenuArrows: boolean;
}

export class ThemeSubHeaderSettingsDto implements IThemeSubHeaderSettingsDto {
  fixedSubHeader!: boolean;
  subheaderStyle!: string | undefined;

  constructor(data?: IThemeSubHeaderSettingsDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.fixedSubHeader = data['fixedSubHeader'];
      this.subheaderStyle = data['subheaderStyle'];
    }
  }

  static fromJS(data: any): ThemeSubHeaderSettingsDto {
    data = typeof data === 'object' ? data : {};
    let result = new ThemeSubHeaderSettingsDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['fixedSubHeader'] = this.fixedSubHeader;
    data['subheaderStyle'] = this.subheaderStyle;
    return data;
  }
}

export interface IThemeSubHeaderSettingsDto {
  fixedSubHeader: boolean;
  subheaderStyle: string | undefined;
}

export class ThemeMenuSettingsDto implements IThemeMenuSettingsDto {
  position!: string | undefined;
  asideSkin!: string | undefined;
  fixedAside!: boolean;
  allowAsideMinimizing!: boolean;
  defaultMinimizedAside!: boolean;
  submenuToggle!: string | undefined;
  searchActive!: boolean;

  constructor(data?: IThemeMenuSettingsDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.position = data['position'];
      this.asideSkin = data['asideSkin'];
      this.fixedAside = data['fixedAside'];
      this.allowAsideMinimizing = data['allowAsideMinimizing'];
      this.defaultMinimizedAside = data['defaultMinimizedAside'];
      this.submenuToggle = data['submenuToggle'];
      this.searchActive = data['searchActive'];
    }
  }

  static fromJS(data: any): ThemeMenuSettingsDto {
    data = typeof data === 'object' ? data : {};
    let result = new ThemeMenuSettingsDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['position'] = this.position;
    data['asideSkin'] = this.asideSkin;
    data['fixedAside'] = this.fixedAside;
    data['allowAsideMinimizing'] = this.allowAsideMinimizing;
    data['defaultMinimizedAside'] = this.defaultMinimizedAside;
    data['submenuToggle'] = this.submenuToggle;
    data['searchActive'] = this.searchActive;
    return data;
  }
}

export interface IThemeMenuSettingsDto {
  position: string | undefined;
  asideSkin: string | undefined;
  fixedAside: boolean;
  allowAsideMinimizing: boolean;
  defaultMinimizedAside: boolean;
  submenuToggle: string | undefined;
  searchActive: boolean;
}

export class ThemeFooterSettingsDto implements IThemeFooterSettingsDto {
  fixedFooter!: boolean;

  constructor(data?: IThemeFooterSettingsDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.fixedFooter = data['fixedFooter'];
    }
  }

  static fromJS(data: any): ThemeFooterSettingsDto {
    data = typeof data === 'object' ? data : {};
    let result = new ThemeFooterSettingsDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['fixedFooter'] = this.fixedFooter;
    return data;
  }
}

export interface IThemeFooterSettingsDto {
  fixedFooter: boolean;
}

export class ThemeSettingsDto implements IThemeSettingsDto {
  theme!: string | undefined;
  layout!: ThemeLayoutSettingsDto;
  header!: ThemeHeaderSettingsDto;
  subHeader!: ThemeSubHeaderSettingsDto;
  menu!: ThemeMenuSettingsDto;
  footer!: ThemeFooterSettingsDto;

  constructor(data?: IThemeSettingsDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.theme = data['theme'];
      this.layout = data['layout']
        ? ThemeLayoutSettingsDto.fromJS(data['layout'])
        : <any>undefined;
      this.header = data['header']
        ? ThemeHeaderSettingsDto.fromJS(data['header'])
        : <any>undefined;
      this.subHeader = data['subHeader']
        ? ThemeSubHeaderSettingsDto.fromJS(data['subHeader'])
        : <any>undefined;
      this.menu = data['menu']
        ? ThemeMenuSettingsDto.fromJS(data['menu'])
        : <any>undefined;
      this.footer = data['footer']
        ? ThemeFooterSettingsDto.fromJS(data['footer'])
        : <any>undefined;
    }
  }

  static fromJS(data: any): ThemeSettingsDto {
    data = typeof data === 'object' ? data : {};
    let result = new ThemeSettingsDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['theme'] = this.theme;
    data['layout'] = this.layout ? this.layout.toJSON() : <any>undefined;
    data['header'] = this.header ? this.header.toJSON() : <any>undefined;
    data['subHeader'] = this.subHeader
      ? this.subHeader.toJSON()
      : <any>undefined;
    data['menu'] = this.menu ? this.menu.toJSON() : <any>undefined;
    data['footer'] = this.footer ? this.footer.toJSON() : <any>undefined;
    return data;
  }
}

export interface IThemeSettingsDto {
  theme: string | undefined;
  layout: ThemeLayoutSettingsDto;
  header: ThemeHeaderSettingsDto;
  subHeader: ThemeSubHeaderSettingsDto;
  menu: ThemeMenuSettingsDto;
  footer: ThemeFooterSettingsDto;
}

export class UiCustomizationSettingsDto implements IUiCustomizationSettingsDto {
  baseSettings!: ThemeSettingsDto;
  isLeftMenuUsed!: boolean;
  isTopMenuUsed!: boolean;
  isTabMenuUsed!: boolean;
  allowMenuScroll!: boolean;

  constructor(data?: IUiCustomizationSettingsDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.baseSettings = data['baseSettings']
        ? ThemeSettingsDto.fromJS(data['baseSettings'])
        : <any>undefined;
      this.isLeftMenuUsed = data['isLeftMenuUsed'];
      this.isTopMenuUsed = data['isTopMenuUsed'];
      this.isTabMenuUsed = data['isTabMenuUsed'];
      this.allowMenuScroll = data['allowMenuScroll'];
    }
  }

  static fromJS(data: any): UiCustomizationSettingsDto {
    data = typeof data === 'object' ? data : {};
    let result = new UiCustomizationSettingsDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['baseSettings'] = this.baseSettings
      ? this.baseSettings.toJSON()
      : <any>undefined;
    data['isLeftMenuUsed'] = this.isLeftMenuUsed;
    data['isTopMenuUsed'] = this.isTopMenuUsed;
    data['isTabMenuUsed'] = this.isTabMenuUsed;
    data['allowMenuScroll'] = this.allowMenuScroll;
    return data;
  }
}

export interface IUiCustomizationSettingsDto {
  baseSettings: ThemeSettingsDto;
  isLeftMenuUsed: boolean;
  isTopMenuUsed: boolean;
  isTabMenuUsed: boolean;
  allowMenuScroll: boolean;
}

export class GetCurrentLoginInformationsOutput
  implements IGetCurrentLoginInformationsOutput
{
  user!: UserLoginInfoDto;
  tenant!: TenantLoginInfoDto;
  application!: ApplicationInfoDto;
  theme!: UiCustomizationSettingsDto;
  productDefaultImage!: ProductDefaultImageSettingsEditDto;

  constructor(data?: IGetCurrentLoginInformationsOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.user = data['user']
        ? UserLoginInfoDto.fromJS(data['user'])
        : <any>undefined;
      this.tenant = data['tenant']
        ? TenantLoginInfoDto.fromJS(data['tenant'])
        : <any>undefined;
      this.application = data['application']
        ? ApplicationInfoDto.fromJS(data['application'])
        : <any>undefined;
      this.theme = data['theme']
        ? UiCustomizationSettingsDto.fromJS(data['theme'])
        : <any>undefined;
      this.productDefaultImage = data['productDefaultImage']
        ? ProductDefaultImageSettingsEditDto.fromJS(data['productDefaultImage'])
        : <any>undefined;
    }
  }

  static fromJS(data: any): GetCurrentLoginInformationsOutput {
    data = typeof data === 'object' ? data : {};
    let result = new GetCurrentLoginInformationsOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['user'] = this.user ? this.user.toJSON() : <any>undefined;
    data['tenant'] = this.tenant ? this.tenant.toJSON() : <any>undefined;
    data['application'] = this.application
      ? this.application.toJSON()
      : <any>undefined;
    data['theme'] = this.theme ? this.theme.toJSON() : <any>undefined;
    data['productDefaultImage'] = this.productDefaultImage
      ? this.productDefaultImage.toJSON()
      : <any>undefined;
    return data;
  }
}

export interface IGetCurrentLoginInformationsOutput {
  user: UserLoginInfoDto;
  tenant: TenantLoginInfoDto;
  application: ApplicationInfoDto;
  theme: UiCustomizationSettingsDto;
  productDefaultImage: ProductDefaultImageSettingsEditDto;
}

export class UpdateUserSignInTokenOutput
  implements IUpdateUserSignInTokenOutput
{
  signInToken!: string | undefined;
  encodedUserId!: string | undefined;
  encodedTenantId!: string | undefined;

  constructor(data?: IUpdateUserSignInTokenOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.signInToken = data['signInToken'];
      this.encodedUserId = data['encodedUserId'];
      this.encodedTenantId = data['encodedTenantId'];
    }
  }

  static fromJS(data: any): UpdateUserSignInTokenOutput {
    data = typeof data === 'object' ? data : {};
    let result = new UpdateUserSignInTokenOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['signInToken'] = this.signInToken;
    data['encodedUserId'] = this.encodedUserId;
    data['encodedTenantId'] = this.encodedTenantId;
    return data;
  }
}

export interface IUpdateUserSignInTokenOutput {
  signInToken: string | undefined;
  encodedUserId: string | undefined;
  encodedTenantId: string | undefined;
}

export class Paging implements IPaging {
  currentPage!: number;
  pageSize!: number;
  totalRecords!: number;
  readonly pageCount!: number;
  readonly start!: number;

  constructor(data?: IPaging) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.currentPage = data['currentPage'];
      this.pageSize = data['pageSize'];
      this.totalRecords = data['totalRecords'];
      (<any>this).pageCount = data['pageCount'];
      (<any>this).start = data['start'];
    }
  }

  static fromJS(data: any): Paging {
    data = typeof data === 'object' ? data : {};
    let result = new Paging();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['currentPage'] = this.currentPage;
    data['pageSize'] = this.pageSize;
    data['totalRecords'] = this.totalRecords;
    data['pageCount'] = this.pageCount;
    data['start'] = this.start;
    return data;
  }
}

export interface IPaging {
  currentPage: number;
  pageSize: number;
  totalRecords: number;
  pageCount: number;
  start: number;
}

export enum SortOrder {
  Asc = 1,
  Desc = 2,
}

export class PagingOrder implements IPagingOrder {
  fieldName!: string | undefined;
  direction!: string | undefined;
  sortOrder!: SortOrder;

  constructor(data?: IPagingOrder) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.fieldName = data['fieldName'];
      this.direction = data['direction'];
      this.sortOrder = data['sortOrder'];
    }
  }

  static fromJS(data: any): PagingOrder {
    data = typeof data === 'object' ? data : {};
    let result = new PagingOrder();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['fieldName'] = this.fieldName;
    data['direction'] = this.direction;
    data['sortOrder'] = this.sortOrder;
    return data;
  }
}

export interface IPagingOrder {
  fieldName: string | undefined;
  direction: string | undefined;
  sortOrder: SortOrder;
}

export class ProductSearchInputDto implements IProductSearchInputDto {
  searchText!: string | undefined;
  searchSparePart!: boolean;
  vehicleId!: number | undefined;
  brand!: string | undefined;
  priceList!: string | undefined;
  paging!: Paging;
  pagingOrders!: PagingOrder[] | undefined;

  constructor(data?: IProductSearchInputDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.searchText = data['searchText'];
      this.searchSparePart = data['searchSparePart'];
      this.vehicleId = data['vehicleId'];
      this.brand = data['brand'];
      this.priceList = data['priceList'];
      this.paging = data['paging']
        ? Paging.fromJS(data['paging'])
        : <any>undefined;
      if (Array.isArray(data['pagingOrders'])) {
        this.pagingOrders = [] as any;
        for (let item of data['pagingOrders'])
          this.pagingOrders!.push(PagingOrder.fromJS(item));
      }
    }
  }

  static fromJS(data: any): ProductSearchInputDto {
    data = typeof data === 'object' ? data : {};
    let result = new ProductSearchInputDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['searchText'] = this.searchText;
    data['searchSparePart'] = this.searchSparePart;
    data['vehicleId'] = this.vehicleId;
    data['brand'] = this.brand;
    data['priceList'] = this.priceList;
    data['paging'] = this.paging ? this.paging.toJSON() : <any>undefined;
    if (Array.isArray(this.pagingOrders)) {
      data['pagingOrders'] = [];
      for (let item of this.pagingOrders)
        data['pagingOrders'].push(item.toJSON());
    }
    return data;
  }
}

export interface IProductSearchInputDto {
  searchText: string | undefined;
  searchSparePart: boolean;
  vehicleId: number | undefined;
  brand: string | undefined;
  priceList: string | undefined;
  paging: Paging;
  pagingOrders: PagingOrder[] | undefined;
}

export class ProductSearchOutputDto implements IProductSearchOutputDto {
  brandId!: number;
  brandName!: string | undefined;
  subBrand!: string | undefined;
  productNumber!: string | undefined;
  companySKU!: string | undefined;
  companyProductTitle!: string | undefined;
  fittedVehicleIds!: string | undefined;
  isFitted!: boolean;
  metaKeyWords!: string | undefined;
  productDescription!: string | undefined;
  referenceNumber!: string | undefined;
  standardDescription!: string | undefined;
  tradeNumber!: string | undefined;
  universal!: boolean;

  constructor(data?: IProductSearchOutputDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.brandId = data['brandId'];
      this.brandName = data['brandName'];
      this.subBrand = data['subBrand'];
      this.productNumber = data['productNumber'];
      this.companySKU = data['companySKU'];
      this.companyProductTitle = data['companyProductTitle'];
      this.fittedVehicleIds = data['fittedVehicleIds'];
      this.isFitted = data['isFitted'];
      this.metaKeyWords = data['metaKeyWords'];
      this.productDescription = data['productDescription'];
      this.referenceNumber = data['referenceNumber'];
      this.standardDescription = data['standardDescription'];
      this.tradeNumber = data['tradeNumber'];
      this.universal = data['universal'];
    }
  }

  static fromJS(data: any): ProductSearchOutputDto {
    data = typeof data === 'object' ? data : {};
    let result = new ProductSearchOutputDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['brandId'] = this.brandId;
    data['brandName'] = this.brandName;
    data['subBrand'] = this.subBrand;
    data['productNumber'] = this.productNumber;
    data['companySKU'] = this.companySKU;
    data['companyProductTitle'] = this.companyProductTitle;
    data['fittedVehicleIds'] = this.fittedVehicleIds;
    data['isFitted'] = this.isFitted;
    data['metaKeyWords'] = this.metaKeyWords;
    data['productDescription'] = this.productDescription;
    data['referenceNumber'] = this.referenceNumber;
    data['standardDescription'] = this.standardDescription;
    data['tradeNumber'] = this.tradeNumber;
    data['universal'] = this.universal;
    return data;
  }
}

export interface IProductSearchOutputDto {
  brandId: number;
  brandName: string | undefined;
  subBrand: string | undefined;
  productNumber: string | undefined;
  companySKU: string | undefined;
  companyProductTitle: string | undefined;
  fittedVehicleIds: string | undefined;
  isFitted: boolean;
  metaKeyWords: string | undefined;
  productDescription: string | undefined;
  referenceNumber: string | undefined;
  standardDescription: string | undefined;
  tradeNumber: string | undefined;
  universal: boolean;
}

export class BasePagingOutputDtoOfProductSearchOutputDto
  implements IBasePagingOutputDtoOfProductSearchOutputDto
{
  records!: ProductSearchOutputDto[] | undefined;
  totalRecord!: number;

  constructor(data?: IBasePagingOutputDtoOfProductSearchOutputDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['records'])) {
        this.records = [] as any;
        for (let item of data['records'])
          this.records!.push(ProductSearchOutputDto.fromJS(item));
      }
      this.totalRecord = data['totalRecord'];
    }
  }

  static fromJS(data: any): BasePagingOutputDtoOfProductSearchOutputDto {
    data = typeof data === 'object' ? data : {};
    let result = new BasePagingOutputDtoOfProductSearchOutputDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.records)) {
      data['records'] = [];
      for (let item of this.records) data['records'].push(item.toJSON());
    }
    data['totalRecord'] = this.totalRecord;
    return data;
  }
}

export interface IBasePagingOutputDtoOfProductSearchOutputDto {
  records: ProductSearchOutputDto[] | undefined;
  totalRecord: number;
}

export class BrandSearchOutputDto implements IBrandSearchOutputDto {
  brandId!: number;
  brandName!: string | undefined;
  brandType!: string | undefined;
  brandImageLocation!: string | undefined;
  brandLogoFileName!: string | undefined;

  constructor(data?: IBrandSearchOutputDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.brandId = data['brandId'];
      this.brandName = data['brandName'];
      this.brandType = data['brandType'];
      this.brandImageLocation = data['brandImageLocation'];
      this.brandLogoFileName = data['brandLogoFileName'];
    }
  }

  static fromJS(data: any): BrandSearchOutputDto {
    data = typeof data === 'object' ? data : {};
    let result = new BrandSearchOutputDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['brandId'] = this.brandId;
    data['brandName'] = this.brandName;
    data['brandType'] = this.brandType;
    data['brandImageLocation'] = this.brandImageLocation;
    data['brandLogoFileName'] = this.brandLogoFileName;
    return data;
  }
}

export interface IBrandSearchOutputDto {
  brandId: number;
  brandName: string | undefined;
  brandType: string | undefined;
  brandImageLocation: string | undefined;
  brandLogoFileName: string | undefined;
}

export class BasePagingOutputDtoOfBrandSearchOutputDto
  implements IBasePagingOutputDtoOfBrandSearchOutputDto
{
  records!: BrandSearchOutputDto[] | undefined;
  totalRecord!: number;

  constructor(data?: IBasePagingOutputDtoOfBrandSearchOutputDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['records'])) {
        this.records = [] as any;
        for (let item of data['records'])
          this.records!.push(BrandSearchOutputDto.fromJS(item));
      }
      this.totalRecord = data['totalRecord'];
    }
  }

  static fromJS(data: any): BasePagingOutputDtoOfBrandSearchOutputDto {
    data = typeof data === 'object' ? data : {};
    let result = new BasePagingOutputDtoOfBrandSearchOutputDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.records)) {
      data['records'] = [];
      for (let item of this.records) data['records'].push(item.toJSON());
    }
    data['totalRecord'] = this.totalRecord;
    return data;
  }
}

export interface IBasePagingOutputDtoOfBrandSearchOutputDto {
  records: BrandSearchOutputDto[] | undefined;
  totalRecord: number;
}

export class CategorySearchOutputDto implements ICategorySearchOutputDto {
  cedCategoryId!: number;
  cedCategoryName!: string | undefined;
  cedCategoryImageUrl!: string | undefined;
  parentCEDCategoryId!: number | undefined;
  cedCategoryDescription!: string | undefined;
  toTreeView!: string | undefined;

  constructor(data?: ICategorySearchOutputDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.cedCategoryId = data['cedCategoryId'];
      this.cedCategoryName = data['cedCategoryName'];
      this.cedCategoryImageUrl = data['cedCategoryImageUrl'];
      this.parentCEDCategoryId = data['parentCEDCategoryId'];
      this.cedCategoryDescription = data['cedCategoryDescription'];
      this.toTreeView = data['toTreeView'];
    }
  }

  static fromJS(data: any): CategorySearchOutputDto {
    data = typeof data === 'object' ? data : {};
    let result = new CategorySearchOutputDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['cedCategoryId'] = this.cedCategoryId;
    data['cedCategoryName'] = this.cedCategoryName;
    data['cedCategoryImageUrl'] = this.cedCategoryImageUrl;
    data['parentCEDCategoryId'] = this.parentCEDCategoryId;
    data['cedCategoryDescription'] = this.cedCategoryDescription;
    data['toTreeView'] = this.toTreeView;
    return data;
  }
}

export interface ICategorySearchOutputDto {
  cedCategoryId: number;
  cedCategoryName: string | undefined;
  cedCategoryImageUrl: string | undefined;
  parentCEDCategoryId: number | undefined;
  cedCategoryDescription: string | undefined;
  toTreeView: string | undefined;
}

export class BasePagingOutputDtoOfCategorySearchOutputDto
  implements IBasePagingOutputDtoOfCategorySearchOutputDto
{
  records!: CategorySearchOutputDto[] | undefined;
  totalRecord!: number;

  constructor(data?: IBasePagingOutputDtoOfCategorySearchOutputDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['records'])) {
        this.records = [] as any;
        for (let item of data['records'])
          this.records!.push(CategorySearchOutputDto.fromJS(item));
      }
      this.totalRecord = data['totalRecord'];
    }
  }

  static fromJS(data: any): BasePagingOutputDtoOfCategorySearchOutputDto {
    data = typeof data === 'object' ? data : {};
    let result = new BasePagingOutputDtoOfCategorySearchOutputDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.records)) {
      data['records'] = [];
      for (let item of this.records) data['records'].push(item.toJSON());
    }
    data['totalRecord'] = this.totalRecord;
    return data;
  }
}

export interface IBasePagingOutputDtoOfCategorySearchOutputDto {
  records: CategorySearchOutputDto[] | undefined;
  totalRecord: number;
}

export class SearchAllOutputDto implements ISearchAllOutputDto {
  products!: BasePagingOutputDtoOfProductSearchOutputDto;
  brands!: BasePagingOutputDtoOfBrandSearchOutputDto;
  categories!: BasePagingOutputDtoOfCategorySearchOutputDto;
  readonly found!: boolean;

  constructor(data?: ISearchAllOutputDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.products = data['products']
        ? BasePagingOutputDtoOfProductSearchOutputDto.fromJS(data['products'])
        : <any>undefined;
      this.brands = data['brands']
        ? BasePagingOutputDtoOfBrandSearchOutputDto.fromJS(data['brands'])
        : <any>undefined;
      this.categories = data['categories']
        ? BasePagingOutputDtoOfCategorySearchOutputDto.fromJS(
            data['categories']
          )
        : <any>undefined;
      (<any>this).found = data['found'];
    }
  }

  static fromJS(data: any): SearchAllOutputDto {
    data = typeof data === 'object' ? data : {};
    let result = new SearchAllOutputDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['products'] = this.products ? this.products.toJSON() : <any>undefined;
    data['brands'] = this.brands ? this.brands.toJSON() : <any>undefined;
    data['categories'] = this.categories
      ? this.categories.toJSON()
      : <any>undefined;
    data['found'] = this.found;
    return data;
  }
}

export interface ISearchAllOutputDto {
  products: BasePagingOutputDtoOfProductSearchOutputDto;
  brands: BasePagingOutputDtoOfBrandSearchOutputDto;
  categories: BasePagingOutputDtoOfCategorySearchOutputDto;
  found: boolean;
}

export class CEDSearchOutputDto implements ICEDSearchOutputDto {
  companySKU!: string | undefined;
  cedId!: number;
  companyProductTitle!: string | undefined;

  constructor(data?: ICEDSearchOutputDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.companySKU = data['companySKU'];
      this.cedId = data['cedId'];
      this.companyProductTitle = data['companyProductTitle'];
    }
  }

  static fromJS(data: any): CEDSearchOutputDto {
    data = typeof data === 'object' ? data : {};
    let result = new CEDSearchOutputDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['companySKU'] = this.companySKU;
    data['cedId'] = this.cedId;
    data['companyProductTitle'] = this.companyProductTitle;
    return data;
  }
}

export interface ICEDSearchOutputDto {
  companySKU: string | undefined;
  cedId: number;
  companyProductTitle: string | undefined;
}

export class BasePagingOutputDtoOfCEDSearchOutputDto
  implements IBasePagingOutputDtoOfCEDSearchOutputDto
{
  records!: CEDSearchOutputDto[] | undefined;
  totalRecord!: number;

  constructor(data?: IBasePagingOutputDtoOfCEDSearchOutputDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['records'])) {
        this.records = [] as any;
        for (let item of data['records'])
          this.records!.push(CEDSearchOutputDto.fromJS(item));
      }
      this.totalRecord = data['totalRecord'];
    }
  }

  static fromJS(data: any): BasePagingOutputDtoOfCEDSearchOutputDto {
    data = typeof data === 'object' ? data : {};
    let result = new BasePagingOutputDtoOfCEDSearchOutputDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.records)) {
      data['records'] = [];
      for (let item of this.records) data['records'].push(item.toJSON());
    }
    data['totalRecord'] = this.totalRecord;
    return data;
  }
}

export interface IBasePagingOutputDtoOfCEDSearchOutputDto {
  records: CEDSearchOutputDto[] | undefined;
  totalRecord: number;
}

export class StripeConfigurationDto implements IStripeConfigurationDto {
  publishableKey!: string | undefined;

  constructor(data?: IStripeConfigurationDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.publishableKey = data['publishableKey'];
    }
  }

  static fromJS(data: any): StripeConfigurationDto {
    data = typeof data === 'object' ? data : {};
    let result = new StripeConfigurationDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['publishableKey'] = this.publishableKey;
    return data;
  }
}

export interface IStripeConfigurationDto {
  publishableKey: string | undefined;
}

export class StripeCreatePaymentSessionInput
  implements IStripeCreatePaymentSessionInput
{
  paymentId!: number;
  successUrl!: string | undefined;
  cancelUrl!: string | undefined;

  constructor(data?: IStripeCreatePaymentSessionInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.paymentId = data['paymentId'];
      this.successUrl = data['successUrl'];
      this.cancelUrl = data['cancelUrl'];
    }
  }

  static fromJS(data: any): StripeCreatePaymentSessionInput {
    data = typeof data === 'object' ? data : {};
    let result = new StripeCreatePaymentSessionInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['paymentId'] = this.paymentId;
    data['successUrl'] = this.successUrl;
    data['cancelUrl'] = this.cancelUrl;
    return data;
  }
}

export interface IStripeCreatePaymentSessionInput {
  paymentId: number;
  successUrl: string | undefined;
  cancelUrl: string | undefined;
}

export class StripePaymentResultOutput implements IStripePaymentResultOutput {
  paymentDone!: boolean;

  constructor(data?: IStripePaymentResultOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.paymentDone = data['paymentDone'];
    }
  }

  static fromJS(data: any): StripePaymentResultOutput {
    data = typeof data === 'object' ? data : {};
    let result = new StripePaymentResultOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['paymentDone'] = this.paymentDone;
    return data;
  }
}

export interface IStripePaymentResultOutput {
  paymentDone: boolean;
}

export class TenantListDto implements ITenantListDto {
  tenancyName!: string | undefined;
  name!: string | undefined;
  editionDisplayName!: string | undefined;
  connectionString!: string | undefined;
  isActive!: boolean;
  creationTime!: moment.Moment;
  subscriptionEndDateUtc!: moment.Moment | undefined;
  editionId!: number | undefined;
  isInTrialPeriod!: boolean;
  id!: number;

  constructor(data?: ITenantListDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.tenancyName = data['tenancyName'];
      this.name = data['name'];
      this.editionDisplayName = data['editionDisplayName'];
      this.connectionString = data['connectionString'];
      this.isActive = data['isActive'];
      this.creationTime = data['creationTime']
        ? moment(data['creationTime'].toString())
        : <any>undefined;
      this.subscriptionEndDateUtc = data['subscriptionEndDateUtc']
        ? moment(data['subscriptionEndDateUtc'].toString())
        : <any>undefined;
      this.editionId = data['editionId'];
      this.isInTrialPeriod = data['isInTrialPeriod'];
      this.id = data['id'];
    }
  }

  static fromJS(data: any): TenantListDto {
    data = typeof data === 'object' ? data : {};
    let result = new TenantListDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['tenancyName'] = this.tenancyName;
    data['name'] = this.name;
    data['editionDisplayName'] = this.editionDisplayName;
    data['connectionString'] = this.connectionString;
    data['isActive'] = this.isActive;
    data['creationTime'] = this.creationTime
      ? this.creationTime.toISOString()
      : <any>undefined;
    data['subscriptionEndDateUtc'] = this.subscriptionEndDateUtc
      ? this.subscriptionEndDateUtc.toISOString()
      : <any>undefined;
    data['editionId'] = this.editionId;
    data['isInTrialPeriod'] = this.isInTrialPeriod;
    data['id'] = this.id;
    return data;
  }
}

export interface ITenantListDto {
  tenancyName: string | undefined;
  name: string | undefined;
  editionDisplayName: string | undefined;
  connectionString: string | undefined;
  isActive: boolean;
  creationTime: moment.Moment;
  subscriptionEndDateUtc: moment.Moment | undefined;
  editionId: number | undefined;
  isInTrialPeriod: boolean;
  id: number;
}

export class PagedResultDtoOfTenantListDto
  implements IPagedResultDtoOfTenantListDto
{
  totalCount!: number;
  items!: TenantListDto[] | undefined;

  constructor(data?: IPagedResultDtoOfTenantListDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.totalCount = data['totalCount'];
      if (Array.isArray(data['items'])) {
        this.items = [] as any;
        for (let item of data['items'])
          this.items!.push(TenantListDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): PagedResultDtoOfTenantListDto {
    data = typeof data === 'object' ? data : {};
    let result = new PagedResultDtoOfTenantListDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['totalCount'] = this.totalCount;
    if (Array.isArray(this.items)) {
      data['items'] = [];
      for (let item of this.items) data['items'].push(item.toJSON());
    }
    return data;
  }
}

export interface IPagedResultDtoOfTenantListDto {
  totalCount: number;
  items: TenantListDto[] | undefined;
}

export class CreateTenantInput implements ICreateTenantInput {
  tenancyName!: string | undefined;
  name!: string | undefined;
  adminEmailAddress!: string | undefined;
  adminPassword!: string | undefined;
  connectionString!: string | undefined;
  shouldChangePasswordOnNextLogin!: boolean;
  sendActivationEmail!: boolean;
  editionId!: number | undefined;
  isActive!: boolean;
  subscriptionEndDateUtc!: moment.Moment | undefined;
  isInTrialPeriod!: boolean;

  constructor(data?: ICreateTenantInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.tenancyName = data['tenancyName'];
      this.name = data['name'];
      this.adminEmailAddress = data['adminEmailAddress'];
      this.adminPassword = data['adminPassword'];
      this.connectionString = data['connectionString'];
      this.shouldChangePasswordOnNextLogin =
        data['shouldChangePasswordOnNextLogin'];
      this.sendActivationEmail = data['sendActivationEmail'];
      this.editionId = data['editionId'];
      this.isActive = data['isActive'];
      this.subscriptionEndDateUtc = data['subscriptionEndDateUtc']
        ? moment(data['subscriptionEndDateUtc'].toString())
        : <any>undefined;
      this.isInTrialPeriod = data['isInTrialPeriod'];
    }
  }

  static fromJS(data: any): CreateTenantInput {
    data = typeof data === 'object' ? data : {};
    let result = new CreateTenantInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['tenancyName'] = this.tenancyName;
    data['name'] = this.name;
    data['adminEmailAddress'] = this.adminEmailAddress;
    data['adminPassword'] = this.adminPassword;
    data['connectionString'] = this.connectionString;
    data['shouldChangePasswordOnNextLogin'] =
      this.shouldChangePasswordOnNextLogin;
    data['sendActivationEmail'] = this.sendActivationEmail;
    data['editionId'] = this.editionId;
    data['isActive'] = this.isActive;
    data['subscriptionEndDateUtc'] = this.subscriptionEndDateUtc
      ? this.subscriptionEndDateUtc.toISOString()
      : <any>undefined;
    data['isInTrialPeriod'] = this.isInTrialPeriod;
    return data;
  }
}

export interface ICreateTenantInput {
  tenancyName: string | undefined;
  name: string | undefined;
  adminEmailAddress: string | undefined;
  adminPassword: string | undefined;
  connectionString: string | undefined;
  shouldChangePasswordOnNextLogin: boolean;
  sendActivationEmail: boolean;
  editionId: number | undefined;
  isActive: boolean;
  subscriptionEndDateUtc: moment.Moment | undefined;
  isInTrialPeriod: boolean;
}

export class TenantEditDto implements ITenantEditDto {
  tenancyName!: string | undefined;
  name!: string | undefined;
  connectionString!: string | undefined;
  editionId!: number | undefined;
  isActive!: boolean;
  subscriptionEndDateUtc!: moment.Moment | undefined;
  isInTrialPeriod!: boolean;
  id!: number;

  constructor(data?: ITenantEditDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.tenancyName = data['tenancyName'];
      this.name = data['name'];
      this.connectionString = data['connectionString'];
      this.editionId = data['editionId'];
      this.isActive = data['isActive'];
      this.subscriptionEndDateUtc = data['subscriptionEndDateUtc']
        ? moment(data['subscriptionEndDateUtc'].toString())
        : <any>undefined;
      this.isInTrialPeriod = data['isInTrialPeriod'];
      this.id = data['id'];
    }
  }

  static fromJS(data: any): TenantEditDto {
    data = typeof data === 'object' ? data : {};
    let result = new TenantEditDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['tenancyName'] = this.tenancyName;
    data['name'] = this.name;
    data['connectionString'] = this.connectionString;
    data['editionId'] = this.editionId;
    data['isActive'] = this.isActive;
    data['subscriptionEndDateUtc'] = this.subscriptionEndDateUtc
      ? this.subscriptionEndDateUtc.toISOString()
      : <any>undefined;
    data['isInTrialPeriod'] = this.isInTrialPeriod;
    data['id'] = this.id;
    return data;
  }
}

export interface ITenantEditDto {
  tenancyName: string | undefined;
  name: string | undefined;
  connectionString: string | undefined;
  editionId: number | undefined;
  isActive: boolean;
  subscriptionEndDateUtc: moment.Moment | undefined;
  isInTrialPeriod: boolean;
  id: number;
}

export class GetTenantFeaturesEditOutput
  implements IGetTenantFeaturesEditOutput
{
  featureValues!: NameValueDto[] | undefined;
  features!: FlatFeatureDto[] | undefined;

  constructor(data?: IGetTenantFeaturesEditOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['featureValues'])) {
        this.featureValues = [] as any;
        for (let item of data['featureValues'])
          this.featureValues!.push(NameValueDto.fromJS(item));
      }
      if (Array.isArray(data['features'])) {
        this.features = [] as any;
        for (let item of data['features'])
          this.features!.push(FlatFeatureDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): GetTenantFeaturesEditOutput {
    data = typeof data === 'object' ? data : {};
    let result = new GetTenantFeaturesEditOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.featureValues)) {
      data['featureValues'] = [];
      for (let item of this.featureValues)
        data['featureValues'].push(item.toJSON());
    }
    if (Array.isArray(this.features)) {
      data['features'] = [];
      for (let item of this.features) data['features'].push(item.toJSON());
    }
    return data;
  }
}

export interface IGetTenantFeaturesEditOutput {
  featureValues: NameValueDto[] | undefined;
  features: FlatFeatureDto[] | undefined;
}

export class UpdateTenantFeaturesInput implements IUpdateTenantFeaturesInput {
  id!: number;
  featureValues!: NameValueDto[] | undefined;

  constructor(data?: IUpdateTenantFeaturesInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id'];
      if (Array.isArray(data['featureValues'])) {
        this.featureValues = [] as any;
        for (let item of data['featureValues'])
          this.featureValues!.push(NameValueDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): UpdateTenantFeaturesInput {
    data = typeof data === 'object' ? data : {};
    let result = new UpdateTenantFeaturesInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['id'] = this.id;
    if (Array.isArray(this.featureValues)) {
      data['featureValues'] = [];
      for (let item of this.featureValues)
        data['featureValues'].push(item.toJSON());
    }
    return data;
  }
}

export interface IUpdateTenantFeaturesInput {
  id: number;
  featureValues: NameValueDto[] | undefined;
}

export class EntityDto implements IEntityDto {
  id!: number;

  constructor(data?: IEntityDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id'];
    }
  }

  static fromJS(data: any): EntityDto {
    data = typeof data === 'object' ? data : {};
    let result = new EntityDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['id'] = this.id;
    return data;
  }
}

export interface IEntityDto {
  id: number;
}

export class MemberActivity implements IMemberActivity {
  name!: string | undefined;
  earnings!: string | undefined;
  cases!: number;
  closed!: number;
  rate!: string | undefined;

  constructor(data?: IMemberActivity) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.name = data['name'];
      this.earnings = data['earnings'];
      this.cases = data['cases'];
      this.closed = data['closed'];
      this.rate = data['rate'];
    }
  }

  static fromJS(data: any): MemberActivity {
    data = typeof data === 'object' ? data : {};
    let result = new MemberActivity();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['name'] = this.name;
    data['earnings'] = this.earnings;
    data['cases'] = this.cases;
    data['closed'] = this.closed;
    data['rate'] = this.rate;
    return data;
  }
}

export interface IMemberActivity {
  name: string | undefined;
  earnings: string | undefined;
  cases: number;
  closed: number;
  rate: string | undefined;
}

export class GetMemberActivityOutput implements IGetMemberActivityOutput {
  memberActivities!: MemberActivity[] | undefined;

  constructor(data?: IGetMemberActivityOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['memberActivities'])) {
        this.memberActivities = [] as any;
        for (let item of data['memberActivities'])
          this.memberActivities!.push(MemberActivity.fromJS(item));
      }
    }
  }

  static fromJS(data: any): GetMemberActivityOutput {
    data = typeof data === 'object' ? data : {};
    let result = new GetMemberActivityOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.memberActivities)) {
      data['memberActivities'] = [];
      for (let item of this.memberActivities)
        data['memberActivities'].push(item.toJSON());
    }
    return data;
  }
}

export interface IGetMemberActivityOutput {
  memberActivities: MemberActivity[] | undefined;
}

export enum SalesSummaryDatePeriod {
  Daily = 1,
  Weekly = 2,
  Monthly = 3,
}

export class SalesSummaryData implements ISalesSummaryData {
  period!: string | undefined;
  sales!: number;
  profit!: number;

  constructor(data?: ISalesSummaryData) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.period = data['period'];
      this.sales = data['sales'];
      this.profit = data['profit'];
    }
  }

  static fromJS(data: any): SalesSummaryData {
    data = typeof data === 'object' ? data : {};
    let result = new SalesSummaryData();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['period'] = this.period;
    data['sales'] = this.sales;
    data['profit'] = this.profit;
    return data;
  }
}

export interface ISalesSummaryData {
  period: string | undefined;
  sales: number;
  profit: number;
}

export class GetDashboardDataOutput implements IGetDashboardDataOutput {
  totalProfit!: number;
  newFeedbacks!: number;
  newOrders!: number;
  newUsers!: number;
  salesSummary!: SalesSummaryData[] | undefined;
  totalSales!: number;
  revenue!: number;
  expenses!: number;
  growth!: number;
  transactionPercent!: number;
  newVisitPercent!: number;
  bouncePercent!: number;
  dailySales!: number[] | undefined;
  profitShares!: number[] | undefined;

  constructor(data?: IGetDashboardDataOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.totalProfit = data['totalProfit'];
      this.newFeedbacks = data['newFeedbacks'];
      this.newOrders = data['newOrders'];
      this.newUsers = data['newUsers'];
      if (Array.isArray(data['salesSummary'])) {
        this.salesSummary = [] as any;
        for (let item of data['salesSummary'])
          this.salesSummary!.push(SalesSummaryData.fromJS(item));
      }
      this.totalSales = data['totalSales'];
      this.revenue = data['revenue'];
      this.expenses = data['expenses'];
      this.growth = data['growth'];
      this.transactionPercent = data['transactionPercent'];
      this.newVisitPercent = data['newVisitPercent'];
      this.bouncePercent = data['bouncePercent'];
      if (Array.isArray(data['dailySales'])) {
        this.dailySales = [] as any;
        for (let item of data['dailySales']) this.dailySales!.push(item);
      }
      if (Array.isArray(data['profitShares'])) {
        this.profitShares = [] as any;
        for (let item of data['profitShares']) this.profitShares!.push(item);
      }
    }
  }

  static fromJS(data: any): GetDashboardDataOutput {
    data = typeof data === 'object' ? data : {};
    let result = new GetDashboardDataOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['totalProfit'] = this.totalProfit;
    data['newFeedbacks'] = this.newFeedbacks;
    data['newOrders'] = this.newOrders;
    data['newUsers'] = this.newUsers;
    if (Array.isArray(this.salesSummary)) {
      data['salesSummary'] = [];
      for (let item of this.salesSummary)
        data['salesSummary'].push(item.toJSON());
    }
    data['totalSales'] = this.totalSales;
    data['revenue'] = this.revenue;
    data['expenses'] = this.expenses;
    data['growth'] = this.growth;
    data['transactionPercent'] = this.transactionPercent;
    data['newVisitPercent'] = this.newVisitPercent;
    data['bouncePercent'] = this.bouncePercent;
    if (Array.isArray(this.dailySales)) {
      data['dailySales'] = [];
      for (let item of this.dailySales) data['dailySales'].push(item);
    }
    if (Array.isArray(this.profitShares)) {
      data['profitShares'] = [];
      for (let item of this.profitShares) data['profitShares'].push(item);
    }
    return data;
  }
}

export interface IGetDashboardDataOutput {
  totalProfit: number;
  newFeedbacks: number;
  newOrders: number;
  newUsers: number;
  salesSummary: SalesSummaryData[] | undefined;
  totalSales: number;
  revenue: number;
  expenses: number;
  growth: number;
  transactionPercent: number;
  newVisitPercent: number;
  bouncePercent: number;
  dailySales: number[] | undefined;
  profitShares: number[] | undefined;
}

export class GetTopStatsOutput implements IGetTopStatsOutput {
  totalProfit!: number;
  newFeedbacks!: number;
  newOrders!: number;
  newUsers!: number;

  constructor(data?: IGetTopStatsOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.totalProfit = data['totalProfit'];
      this.newFeedbacks = data['newFeedbacks'];
      this.newOrders = data['newOrders'];
      this.newUsers = data['newUsers'];
    }
  }

  static fromJS(data: any): GetTopStatsOutput {
    data = typeof data === 'object' ? data : {};
    let result = new GetTopStatsOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['totalProfit'] = this.totalProfit;
    data['newFeedbacks'] = this.newFeedbacks;
    data['newOrders'] = this.newOrders;
    data['newUsers'] = this.newUsers;
    return data;
  }
}

export interface IGetTopStatsOutput {
  totalProfit: number;
  newFeedbacks: number;
  newOrders: number;
  newUsers: number;
}

export class GetProfitShareOutput implements IGetProfitShareOutput {
  profitShares!: number[] | undefined;

  constructor(data?: IGetProfitShareOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['profitShares'])) {
        this.profitShares = [] as any;
        for (let item of data['profitShares']) this.profitShares!.push(item);
      }
    }
  }

  static fromJS(data: any): GetProfitShareOutput {
    data = typeof data === 'object' ? data : {};
    let result = new GetProfitShareOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.profitShares)) {
      data['profitShares'] = [];
      for (let item of this.profitShares) data['profitShares'].push(item);
    }
    return data;
  }
}

export interface IGetProfitShareOutput {
  profitShares: number[] | undefined;
}

export class GetDailySalesOutput implements IGetDailySalesOutput {
  dailySales!: number[] | undefined;

  constructor(data?: IGetDailySalesOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['dailySales'])) {
        this.dailySales = [] as any;
        for (let item of data['dailySales']) this.dailySales!.push(item);
      }
    }
  }

  static fromJS(data: any): GetDailySalesOutput {
    data = typeof data === 'object' ? data : {};
    let result = new GetDailySalesOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.dailySales)) {
      data['dailySales'] = [];
      for (let item of this.dailySales) data['dailySales'].push(item);
    }
    return data;
  }
}

export interface IGetDailySalesOutput {
  dailySales: number[] | undefined;
}

export class GetSalesSummaryOutput implements IGetSalesSummaryOutput {
  totalSales!: number;
  revenue!: number;
  expenses!: number;
  growth!: number;
  salesSummary!: SalesSummaryData[] | undefined;

  constructor(data?: IGetSalesSummaryOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.totalSales = data['totalSales'];
      this.revenue = data['revenue'];
      this.expenses = data['expenses'];
      this.growth = data['growth'];
      if (Array.isArray(data['salesSummary'])) {
        this.salesSummary = [] as any;
        for (let item of data['salesSummary'])
          this.salesSummary!.push(SalesSummaryData.fromJS(item));
      }
    }
  }

  static fromJS(data: any): GetSalesSummaryOutput {
    data = typeof data === 'object' ? data : {};
    let result = new GetSalesSummaryOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['totalSales'] = this.totalSales;
    data['revenue'] = this.revenue;
    data['expenses'] = this.expenses;
    data['growth'] = this.growth;
    if (Array.isArray(this.salesSummary)) {
      data['salesSummary'] = [];
      for (let item of this.salesSummary)
        data['salesSummary'].push(item.toJSON());
    }
    return data;
  }
}

export interface IGetSalesSummaryOutput {
  totalSales: number;
  revenue: number;
  expenses: number;
  growth: number;
  salesSummary: SalesSummaryData[] | undefined;
}

export class RegionalStatCountry implements IRegionalStatCountry {
  countryName!: string | undefined;
  sales!: number;
  change!: number[] | undefined;
  averagePrice!: number;
  totalPrice!: number;

  constructor(data?: IRegionalStatCountry) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.countryName = data['countryName'];
      this.sales = data['sales'];
      if (Array.isArray(data['change'])) {
        this.change = [] as any;
        for (let item of data['change']) this.change!.push(item);
      }
      this.averagePrice = data['averagePrice'];
      this.totalPrice = data['totalPrice'];
    }
  }

  static fromJS(data: any): RegionalStatCountry {
    data = typeof data === 'object' ? data : {};
    let result = new RegionalStatCountry();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['countryName'] = this.countryName;
    data['sales'] = this.sales;
    if (Array.isArray(this.change)) {
      data['change'] = [];
      for (let item of this.change) data['change'].push(item);
    }
    data['averagePrice'] = this.averagePrice;
    data['totalPrice'] = this.totalPrice;
    return data;
  }
}

export interface IRegionalStatCountry {
  countryName: string | undefined;
  sales: number;
  change: number[] | undefined;
  averagePrice: number;
  totalPrice: number;
}

export class GetRegionalStatsOutput implements IGetRegionalStatsOutput {
  stats!: RegionalStatCountry[] | undefined;

  constructor(data?: IGetRegionalStatsOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['stats'])) {
        this.stats = [] as any;
        for (let item of data['stats'])
          this.stats!.push(RegionalStatCountry.fromJS(item));
      }
    }
  }

  static fromJS(data: any): GetRegionalStatsOutput {
    data = typeof data === 'object' ? data : {};
    let result = new GetRegionalStatsOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.stats)) {
      data['stats'] = [];
      for (let item of this.stats) data['stats'].push(item.toJSON());
    }
    return data;
  }
}

export interface IGetRegionalStatsOutput {
  stats: RegionalStatCountry[] | undefined;
}

export class GetGeneralStatsOutput implements IGetGeneralStatsOutput {
  transactionPercent!: number;
  newVisitPercent!: number;
  bouncePercent!: number;

  constructor(data?: IGetGeneralStatsOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.transactionPercent = data['transactionPercent'];
      this.newVisitPercent = data['newVisitPercent'];
      this.bouncePercent = data['bouncePercent'];
    }
  }

  static fromJS(data: any): GetGeneralStatsOutput {
    data = typeof data === 'object' ? data : {};
    let result = new GetGeneralStatsOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['transactionPercent'] = this.transactionPercent;
    data['newVisitPercent'] = this.newVisitPercent;
    data['bouncePercent'] = this.bouncePercent;
    return data;
  }
}

export interface IGetGeneralStatsOutput {
  transactionPercent: number;
  newVisitPercent: number;
  bouncePercent: number;
}

export enum SubscriptionStartType {
  Free = 1,
  Trial = 2,
  Paid = 3,
}

export class RegisterTenantInput implements IRegisterTenantInput {
  tenancyName!: string | undefined;
  name!: string | undefined;
  adminEmailAddress!: string | undefined;
  adminPassword!: string | undefined;
  captchaResponse!: string | undefined;
  subscriptionStartType!: SubscriptionStartType;
  editionId!: number | undefined;

  constructor(data?: IRegisterTenantInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.tenancyName = data['tenancyName'];
      this.name = data['name'];
      this.adminEmailAddress = data['adminEmailAddress'];
      this.adminPassword = data['adminPassword'];
      this.captchaResponse = data['captchaResponse'];
      this.subscriptionStartType = data['subscriptionStartType'];
      this.editionId = data['editionId'];
    }
  }

  static fromJS(data: any): RegisterTenantInput {
    data = typeof data === 'object' ? data : {};
    let result = new RegisterTenantInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['tenancyName'] = this.tenancyName;
    data['name'] = this.name;
    data['adminEmailAddress'] = this.adminEmailAddress;
    data['adminPassword'] = this.adminPassword;
    data['captchaResponse'] = this.captchaResponse;
    data['subscriptionStartType'] = this.subscriptionStartType;
    data['editionId'] = this.editionId;
    return data;
  }
}

export interface IRegisterTenantInput {
  tenancyName: string | undefined;
  name: string | undefined;
  adminEmailAddress: string | undefined;
  adminPassword: string | undefined;
  captchaResponse: string | undefined;
  subscriptionStartType: SubscriptionStartType;
  editionId: number | undefined;
}

export class RegisterTenantOutput implements IRegisterTenantOutput {
  tenantId!: number;
  tenancyName!: string | undefined;
  name!: string | undefined;
  userName!: string | undefined;
  emailAddress!: string | undefined;
  isTenantActive!: boolean;
  isActive!: boolean;
  isEmailConfirmationRequired!: boolean;

  constructor(data?: IRegisterTenantOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.tenantId = data['tenantId'];
      this.tenancyName = data['tenancyName'];
      this.name = data['name'];
      this.userName = data['userName'];
      this.emailAddress = data['emailAddress'];
      this.isTenantActive = data['isTenantActive'];
      this.isActive = data['isActive'];
      this.isEmailConfirmationRequired = data['isEmailConfirmationRequired'];
    }
  }

  static fromJS(data: any): RegisterTenantOutput {
    data = typeof data === 'object' ? data : {};
    let result = new RegisterTenantOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['tenantId'] = this.tenantId;
    data['tenancyName'] = this.tenancyName;
    data['name'] = this.name;
    data['userName'] = this.userName;
    data['emailAddress'] = this.emailAddress;
    data['isTenantActive'] = this.isTenantActive;
    data['isActive'] = this.isActive;
    data['isEmailConfirmationRequired'] = this.isEmailConfirmationRequired;
    return data;
  }
}

export interface IRegisterTenantOutput {
  tenantId: number;
  tenancyName: string | undefined;
  name: string | undefined;
  userName: string | undefined;
  emailAddress: string | undefined;
  isTenantActive: boolean;
  isActive: boolean;
  isEmailConfirmationRequired: boolean;
}

export class IInputType implements IIInputType {
  readonly name!: string | undefined;
  readonly attributes!: { [key: string]: any } | undefined;
  validator!: IValueValidator;

  constructor(data?: IIInputType) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      (<any>this).name = data['name'];
      if (data['attributes']) {
        (<any>this).attributes = {} as any;
        for (let key in data['attributes']) {
          if (data['attributes'].hasOwnProperty(key))
            (<any>this).attributes![key] = data['attributes'][key];
        }
      }
      this.validator = data['validator']
        ? IValueValidator.fromJS(data['validator'])
        : <any>undefined;
    }
  }

  static fromJS(data: any): IInputType {
    data = typeof data === 'object' ? data : {};
    let result = new IInputType();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['name'] = this.name;
    if (this.attributes) {
      data['attributes'] = {};
      for (let key in this.attributes) {
        if (this.attributes.hasOwnProperty(key))
          data['attributes'][key] = this.attributes[key];
      }
    }
    data['validator'] = this.validator
      ? this.validator.toJSON()
      : <any>undefined;
    return data;
  }
}

export interface IIInputType {
  name: string | undefined;
  attributes: { [key: string]: any } | undefined;
  validator: IValueValidator;
}

export class FlatFeatureSelectDto implements IFlatFeatureSelectDto {
  parentName!: string | undefined;
  name!: string | undefined;
  displayName!: string | undefined;
  description!: string | undefined;
  defaultValue!: string | undefined;
  inputType!: IInputType;
  textHtmlColor!: string | undefined;

  constructor(data?: IFlatFeatureSelectDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.parentName = data['parentName'];
      this.name = data['name'];
      this.displayName = data['displayName'];
      this.description = data['description'];
      this.defaultValue = data['defaultValue'];
      this.inputType = data['inputType']
        ? IInputType.fromJS(data['inputType'])
        : <any>undefined;
      this.textHtmlColor = data['textHtmlColor'];
    }
  }

  static fromJS(data: any): FlatFeatureSelectDto {
    data = typeof data === 'object' ? data : {};
    let result = new FlatFeatureSelectDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['parentName'] = this.parentName;
    data['name'] = this.name;
    data['displayName'] = this.displayName;
    data['description'] = this.description;
    data['defaultValue'] = this.defaultValue;
    data['inputType'] = this.inputType
      ? this.inputType.toJSON()
      : <any>undefined;
    data['textHtmlColor'] = this.textHtmlColor;
    return data;
  }
}

export interface IFlatFeatureSelectDto {
  parentName: string | undefined;
  name: string | undefined;
  displayName: string | undefined;
  description: string | undefined;
  defaultValue: string | undefined;
  inputType: IInputType;
  textHtmlColor: string | undefined;
}

export class EditionWithFeaturesDto implements IEditionWithFeaturesDto {
  edition!: EditionSelectDto;
  featureValues!: NameValueDto[] | undefined;

  constructor(data?: IEditionWithFeaturesDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.edition = data['edition']
        ? EditionSelectDto.fromJS(data['edition'])
        : <any>undefined;
      if (Array.isArray(data['featureValues'])) {
        this.featureValues = [] as any;
        for (let item of data['featureValues'])
          this.featureValues!.push(NameValueDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): EditionWithFeaturesDto {
    data = typeof data === 'object' ? data : {};
    let result = new EditionWithFeaturesDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['edition'] = this.edition ? this.edition.toJSON() : <any>undefined;
    if (Array.isArray(this.featureValues)) {
      data['featureValues'] = [];
      for (let item of this.featureValues)
        data['featureValues'].push(item.toJSON());
    }
    return data;
  }
}

export interface IEditionWithFeaturesDto {
  edition: EditionSelectDto;
  featureValues: NameValueDto[] | undefined;
}

export class EditionsSelectOutput implements IEditionsSelectOutput {
  allFeatures!: FlatFeatureSelectDto[] | undefined;
  editionsWithFeatures!: EditionWithFeaturesDto[] | undefined;

  constructor(data?: IEditionsSelectOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['allFeatures'])) {
        this.allFeatures = [] as any;
        for (let item of data['allFeatures'])
          this.allFeatures!.push(FlatFeatureSelectDto.fromJS(item));
      }
      if (Array.isArray(data['editionsWithFeatures'])) {
        this.editionsWithFeatures = [] as any;
        for (let item of data['editionsWithFeatures'])
          this.editionsWithFeatures!.push(EditionWithFeaturesDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): EditionsSelectOutput {
    data = typeof data === 'object' ? data : {};
    let result = new EditionsSelectOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.allFeatures)) {
      data['allFeatures'] = [];
      for (let item of this.allFeatures)
        data['allFeatures'].push(item.toJSON());
    }
    if (Array.isArray(this.editionsWithFeatures)) {
      data['editionsWithFeatures'] = [];
      for (let item of this.editionsWithFeatures)
        data['editionsWithFeatures'].push(item.toJSON());
    }
    return data;
  }
}

export interface IEditionsSelectOutput {
  allFeatures: FlatFeatureSelectDto[] | undefined;
  editionsWithFeatures: EditionWithFeaturesDto[] | undefined;
}

export class TenantUserManagementSettingsEditDto
  implements ITenantUserManagementSettingsEditDto
{
  allowSelfRegistration!: boolean;
  isNewRegisteredUserActiveByDefault!: boolean;
  isEmailConfirmationRequiredForLogin!: boolean;
  useCaptchaOnRegistration!: boolean;
  useCaptchaOnLogin!: boolean;
  isCookieConsentEnabled!: boolean;
  isQuickThemeSelectEnabled!: boolean;
  sessionTimeOutSettings!: SessionTimeOutSettingsEditDto;

  constructor(data?: ITenantUserManagementSettingsEditDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.allowSelfRegistration = data['allowSelfRegistration'];
      this.isNewRegisteredUserActiveByDefault =
        data['isNewRegisteredUserActiveByDefault'];
      this.isEmailConfirmationRequiredForLogin =
        data['isEmailConfirmationRequiredForLogin'];
      this.useCaptchaOnRegistration = data['useCaptchaOnRegistration'];
      this.useCaptchaOnLogin = data['useCaptchaOnLogin'];
      this.isCookieConsentEnabled = data['isCookieConsentEnabled'];
      this.isQuickThemeSelectEnabled = data['isQuickThemeSelectEnabled'];
      this.sessionTimeOutSettings = data['sessionTimeOutSettings']
        ? SessionTimeOutSettingsEditDto.fromJS(data['sessionTimeOutSettings'])
        : <any>undefined;
    }
  }

  static fromJS(data: any): TenantUserManagementSettingsEditDto {
    data = typeof data === 'object' ? data : {};
    let result = new TenantUserManagementSettingsEditDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['allowSelfRegistration'] = this.allowSelfRegistration;
    data['isNewRegisteredUserActiveByDefault'] =
      this.isNewRegisteredUserActiveByDefault;
    data['isEmailConfirmationRequiredForLogin'] =
      this.isEmailConfirmationRequiredForLogin;
    data['useCaptchaOnRegistration'] = this.useCaptchaOnRegistration;
    data['useCaptchaOnLogin'] = this.useCaptchaOnLogin;
    data['isCookieConsentEnabled'] = this.isCookieConsentEnabled;
    data['isQuickThemeSelectEnabled'] = this.isQuickThemeSelectEnabled;
    data['sessionTimeOutSettings'] = this.sessionTimeOutSettings
      ? this.sessionTimeOutSettings.toJSON()
      : <any>undefined;
    return data;
  }
}

export interface ITenantUserManagementSettingsEditDto {
  allowSelfRegistration: boolean;
  isNewRegisteredUserActiveByDefault: boolean;
  isEmailConfirmationRequiredForLogin: boolean;
  useCaptchaOnRegistration: boolean;
  useCaptchaOnLogin: boolean;
  isCookieConsentEnabled: boolean;
  isQuickThemeSelectEnabled: boolean;
  sessionTimeOutSettings: SessionTimeOutSettingsEditDto;
}

export class TenantEmailSettingsEditDto implements ITenantEmailSettingsEditDto {
  useHostDefaultEmailSettings!: boolean;
  defaultFromAddress!: string | undefined;
  defaultFromDisplayName!: string | undefined;
  smtpHost!: string | undefined;
  smtpPort!: number;
  smtpUserName!: string | undefined;
  smtpPassword!: string | undefined;
  smtpDomain!: string | undefined;
  smtpEnableSsl!: boolean;
  smtpUseDefaultCredentials!: boolean;

  constructor(data?: ITenantEmailSettingsEditDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.useHostDefaultEmailSettings = data['useHostDefaultEmailSettings'];
      this.defaultFromAddress = data['defaultFromAddress'];
      this.defaultFromDisplayName = data['defaultFromDisplayName'];
      this.smtpHost = data['smtpHost'];
      this.smtpPort = data['smtpPort'];
      this.smtpUserName = data['smtpUserName'];
      this.smtpPassword = data['smtpPassword'];
      this.smtpDomain = data['smtpDomain'];
      this.smtpEnableSsl = data['smtpEnableSsl'];
      this.smtpUseDefaultCredentials = data['smtpUseDefaultCredentials'];
    }
  }

  static fromJS(data: any): TenantEmailSettingsEditDto {
    data = typeof data === 'object' ? data : {};
    let result = new TenantEmailSettingsEditDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['useHostDefaultEmailSettings'] = this.useHostDefaultEmailSettings;
    data['defaultFromAddress'] = this.defaultFromAddress;
    data['defaultFromDisplayName'] = this.defaultFromDisplayName;
    data['smtpHost'] = this.smtpHost;
    data['smtpPort'] = this.smtpPort;
    data['smtpUserName'] = this.smtpUserName;
    data['smtpPassword'] = this.smtpPassword;
    data['smtpDomain'] = this.smtpDomain;
    data['smtpEnableSsl'] = this.smtpEnableSsl;
    data['smtpUseDefaultCredentials'] = this.smtpUseDefaultCredentials;
    return data;
  }
}

export interface ITenantEmailSettingsEditDto {
  useHostDefaultEmailSettings: boolean;
  defaultFromAddress: string | undefined;
  defaultFromDisplayName: string | undefined;
  smtpHost: string | undefined;
  smtpPort: number;
  smtpUserName: string | undefined;
  smtpPassword: string | undefined;
  smtpDomain: string | undefined;
  smtpEnableSsl: boolean;
  smtpUseDefaultCredentials: boolean;
}

export class LdapSettingsEditDto implements ILdapSettingsEditDto {
  isModuleEnabled!: boolean;
  isEnabled!: boolean;
  domain!: string | undefined;
  userName!: string | undefined;
  password!: string | undefined;

  constructor(data?: ILdapSettingsEditDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.isModuleEnabled = data['isModuleEnabled'];
      this.isEnabled = data['isEnabled'];
      this.domain = data['domain'];
      this.userName = data['userName'];
      this.password = data['password'];
    }
  }

  static fromJS(data: any): LdapSettingsEditDto {
    data = typeof data === 'object' ? data : {};
    let result = new LdapSettingsEditDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['isModuleEnabled'] = this.isModuleEnabled;
    data['isEnabled'] = this.isEnabled;
    data['domain'] = this.domain;
    data['userName'] = this.userName;
    data['password'] = this.password;
    return data;
  }
}

export interface ILdapSettingsEditDto {
  isModuleEnabled: boolean;
  isEnabled: boolean;
  domain: string | undefined;
  userName: string | undefined;
  password: string | undefined;
}

export class TenantBillingSettingsEditDto
  implements ITenantBillingSettingsEditDto
{
  legalName!: string | undefined;
  address!: string | undefined;
  taxVatNo!: string | undefined;

  constructor(data?: ITenantBillingSettingsEditDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.legalName = data['legalName'];
      this.address = data['address'];
      this.taxVatNo = data['taxVatNo'];
    }
  }

  static fromJS(data: any): TenantBillingSettingsEditDto {
    data = typeof data === 'object' ? data : {};
    let result = new TenantBillingSettingsEditDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['legalName'] = this.legalName;
    data['address'] = this.address;
    data['taxVatNo'] = this.taxVatNo;
    return data;
  }
}

export interface ITenantBillingSettingsEditDto {
  legalName: string | undefined;
  address: string | undefined;
  taxVatNo: string | undefined;
}

export class TenantOtherSettingsEditDto implements ITenantOtherSettingsEditDto {
  isQuickThemeSelectEnabled!: boolean;

  constructor(data?: ITenantOtherSettingsEditDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.isQuickThemeSelectEnabled = data['isQuickThemeSelectEnabled'];
    }
  }

  static fromJS(data: any): TenantOtherSettingsEditDto {
    data = typeof data === 'object' ? data : {};
    let result = new TenantOtherSettingsEditDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['isQuickThemeSelectEnabled'] = this.isQuickThemeSelectEnabled;
    return data;
  }
}

export interface ITenantOtherSettingsEditDto {
  isQuickThemeSelectEnabled: boolean;
}

export class TenantSettingsEditDto implements ITenantSettingsEditDto {
  general!: GeneralSettingsEditDto;
  userManagement!: TenantUserManagementSettingsEditDto;
  email!: TenantEmailSettingsEditDto;
  ldap!: LdapSettingsEditDto;
  security!: SecuritySettingsEditDto;
  billing!: TenantBillingSettingsEditDto;
  otherSettings!: TenantOtherSettingsEditDto;

  constructor(data?: ITenantSettingsEditDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
    if (!data) {
      this.userManagement = new TenantUserManagementSettingsEditDto();
      this.security = new SecuritySettingsEditDto();
    }
  }

  init(data?: any) {
    if (data) {
      this.general = data['general']
        ? GeneralSettingsEditDto.fromJS(data['general'])
        : <any>undefined;
      this.userManagement = data['userManagement']
        ? TenantUserManagementSettingsEditDto.fromJS(data['userManagement'])
        : new TenantUserManagementSettingsEditDto();
      this.email = data['email']
        ? TenantEmailSettingsEditDto.fromJS(data['email'])
        : <any>undefined;
      this.ldap = data['ldap']
        ? LdapSettingsEditDto.fromJS(data['ldap'])
        : <any>undefined;
      this.security = data['security']
        ? SecuritySettingsEditDto.fromJS(data['security'])
        : new SecuritySettingsEditDto();
      this.billing = data['billing']
        ? TenantBillingSettingsEditDto.fromJS(data['billing'])
        : <any>undefined;
      this.otherSettings = data['otherSettings']
        ? TenantOtherSettingsEditDto.fromJS(data['otherSettings'])
        : <any>undefined;
    }
  }

  static fromJS(data: any): TenantSettingsEditDto {
    data = typeof data === 'object' ? data : {};
    let result = new TenantSettingsEditDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['general'] = this.general ? this.general.toJSON() : <any>undefined;
    data['userManagement'] = this.userManagement
      ? this.userManagement.toJSON()
      : <any>undefined;
    data['email'] = this.email ? this.email.toJSON() : <any>undefined;
    data['ldap'] = this.ldap ? this.ldap.toJSON() : <any>undefined;
    data['security'] = this.security ? this.security.toJSON() : <any>undefined;
    data['billing'] = this.billing ? this.billing.toJSON() : <any>undefined;
    data['otherSettings'] = this.otherSettings
      ? this.otherSettings.toJSON()
      : <any>undefined;
    return data;
  }
}

export interface ITenantSettingsEditDto {
  general: GeneralSettingsEditDto;
  userManagement: TenantUserManagementSettingsEditDto;
  email: TenantEmailSettingsEditDto;
  ldap: LdapSettingsEditDto;
  security: SecuritySettingsEditDto;
  billing: TenantBillingSettingsEditDto;
  otherSettings: TenantOtherSettingsEditDto;
}

export enum SettingScopes {
  Application = 1,
  Tenant = 2,
  User = 4,
  All = 7,
}

export class ListResultDtoOfNameValueDto
  implements IListResultDtoOfNameValueDto
{
  items!: NameValueDto[] | undefined;

  constructor(data?: IListResultDtoOfNameValueDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['items'])) {
        this.items = [] as any;
        for (let item of data['items'])
          this.items!.push(NameValueDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): ListResultDtoOfNameValueDto {
    data = typeof data === 'object' ? data : {};
    let result = new ListResultDtoOfNameValueDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.items)) {
      data['items'] = [];
      for (let item of this.items) data['items'].push(item.toJSON());
    }
    return data;
  }
}

export interface IListResultDtoOfNameValueDto {
  items: NameValueDto[] | undefined;
}

export class AuthenticateModel implements IAuthenticateModel {
  userNameOrEmailAddress!: string | undefined;
  password!: string | undefined;
  twoFactorVerificationCode!: string | undefined;
  rememberClient!: boolean;
  twoFactorRememberClientToken!: string | undefined;
  singleSignIn!: boolean | undefined;
  returnUrl!: string | undefined;
  captchaResponse!: string | undefined;

  constructor(data?: IAuthenticateModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.userNameOrEmailAddress = data['userNameOrEmailAddress'];
      this.password = data['password'];
      this.twoFactorVerificationCode = data['twoFactorVerificationCode'];
      this.rememberClient = data['rememberClient'];
      this.twoFactorRememberClientToken = data['twoFactorRememberClientToken'];
      this.singleSignIn = data['singleSignIn'];
      this.returnUrl = data['returnUrl'];
      this.captchaResponse = data['captchaResponse'];
    }
  }

  static fromJS(data: any): AuthenticateModel {
    data = typeof data === 'object' ? data : {};
    let result = new AuthenticateModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['userNameOrEmailAddress'] = this.userNameOrEmailAddress;
    data['password'] = this.password;
    data['twoFactorVerificationCode'] = this.twoFactorVerificationCode;
    data['rememberClient'] = this.rememberClient;
    data['twoFactorRememberClientToken'] = this.twoFactorRememberClientToken;
    data['singleSignIn'] = this.singleSignIn;
    data['returnUrl'] = this.returnUrl;
    data['captchaResponse'] = this.captchaResponse;
    return data;
  }
}

export interface IAuthenticateModel {
  userNameOrEmailAddress: string | undefined;
  password: string | undefined;
  twoFactorVerificationCode: string | undefined;
  rememberClient: boolean;
  twoFactorRememberClientToken: string | undefined;
  singleSignIn: boolean | undefined;
  returnUrl: string | undefined;
  captchaResponse: string | undefined;
}

export class AuthenticateResultModel implements IAuthenticateResultModel {
  accessToken!: string | undefined;
  encryptedAccessToken!: string | undefined;
  expireInSeconds!: number;
  shouldResetPassword!: boolean;
  passwordResetCode!: string | undefined;
  userId!: number;
  requiresTwoFactorVerification!: boolean;
  twoFactorAuthProviders!: string[] | undefined;
  twoFactorRememberClientToken!: string | undefined;
  returnUrl!: string | undefined;
  refreshToken!: string | undefined;
  refreshTokenExpireInSeconds!: number;

  constructor(data?: IAuthenticateResultModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.accessToken = data['accessToken'];
      this.encryptedAccessToken = data['encryptedAccessToken'];
      this.expireInSeconds = data['expireInSeconds'];
      this.shouldResetPassword = data['shouldResetPassword'];
      this.passwordResetCode = data['passwordResetCode'];
      this.userId = data['userId'];
      this.requiresTwoFactorVerification =
        data['requiresTwoFactorVerification'];
      if (Array.isArray(data['twoFactorAuthProviders'])) {
        this.twoFactorAuthProviders = [] as any;
        for (let item of data['twoFactorAuthProviders'])
          this.twoFactorAuthProviders!.push(item);
      }
      this.twoFactorRememberClientToken = data['twoFactorRememberClientToken'];
      this.returnUrl = data['returnUrl'];
      this.refreshToken = data['refreshToken'];
      this.refreshTokenExpireInSeconds = data['refreshTokenExpireInSeconds'];
    }
  }

  static fromJS(data: any): AuthenticateResultModel {
    data = typeof data === 'object' ? data : {};
    let result = new AuthenticateResultModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['accessToken'] = this.accessToken;
    data['encryptedAccessToken'] = this.encryptedAccessToken;
    data['expireInSeconds'] = this.expireInSeconds;
    data['shouldResetPassword'] = this.shouldResetPassword;
    data['passwordResetCode'] = this.passwordResetCode;
    data['userId'] = this.userId;
    data['requiresTwoFactorVerification'] = this.requiresTwoFactorVerification;
    if (Array.isArray(this.twoFactorAuthProviders)) {
      data['twoFactorAuthProviders'] = [];
      for (let item of this.twoFactorAuthProviders)
        data['twoFactorAuthProviders'].push(item);
    }
    data['twoFactorRememberClientToken'] = this.twoFactorRememberClientToken;
    data['returnUrl'] = this.returnUrl;
    data['refreshToken'] = this.refreshToken;
    data['refreshTokenExpireInSeconds'] = this.refreshTokenExpireInSeconds;
    return data;
  }
}

export interface IAuthenticateResultModel {
  accessToken: string | undefined;
  encryptedAccessToken: string | undefined;
  expireInSeconds: number;
  shouldResetPassword: boolean;
  passwordResetCode: string | undefined;
  userId: number;
  requiresTwoFactorVerification: boolean;
  twoFactorAuthProviders: string[] | undefined;
  twoFactorRememberClientToken: string | undefined;
  returnUrl: string | undefined;
  refreshToken: string | undefined;
  refreshTokenExpireInSeconds: number;
}

export class RefreshTokenResult implements IRefreshTokenResult {
  accessToken!: string | undefined;
  encryptedAccessToken!: string | undefined;
  expireInSeconds!: number;

  constructor(data?: IRefreshTokenResult) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.accessToken = data['accessToken'];
      this.encryptedAccessToken = data['encryptedAccessToken'];
      this.expireInSeconds = data['expireInSeconds'];
    }
  }

  static fromJS(data: any): RefreshTokenResult {
    data = typeof data === 'object' ? data : {};
    let result = new RefreshTokenResult();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['accessToken'] = this.accessToken;
    data['encryptedAccessToken'] = this.encryptedAccessToken;
    data['expireInSeconds'] = this.expireInSeconds;
    return data;
  }
}

export interface IRefreshTokenResult {
  accessToken: string | undefined;
  encryptedAccessToken: string | undefined;
  expireInSeconds: number;
}

export class SendTwoFactorAuthCodeModel implements ISendTwoFactorAuthCodeModel {
  userId!: number;
  provider!: string | undefined;

  constructor(data?: ISendTwoFactorAuthCodeModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.userId = data['userId'];
      this.provider = data['provider'];
    }
  }

  static fromJS(data: any): SendTwoFactorAuthCodeModel {
    data = typeof data === 'object' ? data : {};
    let result = new SendTwoFactorAuthCodeModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['userId'] = this.userId;
    data['provider'] = this.provider;
    return data;
  }
}

export interface ISendTwoFactorAuthCodeModel {
  userId: number;
  provider: string | undefined;
}

export class ImpersonatedAuthenticateResultModel
  implements IImpersonatedAuthenticateResultModel
{
  accessToken!: string | undefined;
  encryptedAccessToken!: string | undefined;
  expireInSeconds!: number;

  constructor(data?: IImpersonatedAuthenticateResultModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.accessToken = data['accessToken'];
      this.encryptedAccessToken = data['encryptedAccessToken'];
      this.expireInSeconds = data['expireInSeconds'];
    }
  }

  static fromJS(data: any): ImpersonatedAuthenticateResultModel {
    data = typeof data === 'object' ? data : {};
    let result = new ImpersonatedAuthenticateResultModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['accessToken'] = this.accessToken;
    data['encryptedAccessToken'] = this.encryptedAccessToken;
    data['expireInSeconds'] = this.expireInSeconds;
    return data;
  }
}

export interface IImpersonatedAuthenticateResultModel {
  accessToken: string | undefined;
  encryptedAccessToken: string | undefined;
  expireInSeconds: number;
}

export class SwitchedAccountAuthenticateResultModel
  implements ISwitchedAccountAuthenticateResultModel
{
  accessToken!: string | undefined;
  encryptedAccessToken!: string | undefined;
  expireInSeconds!: number;

  constructor(data?: ISwitchedAccountAuthenticateResultModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.accessToken = data['accessToken'];
      this.encryptedAccessToken = data['encryptedAccessToken'];
      this.expireInSeconds = data['expireInSeconds'];
    }
  }

  static fromJS(data: any): SwitchedAccountAuthenticateResultModel {
    data = typeof data === 'object' ? data : {};
    let result = new SwitchedAccountAuthenticateResultModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['accessToken'] = this.accessToken;
    data['encryptedAccessToken'] = this.encryptedAccessToken;
    data['expireInSeconds'] = this.expireInSeconds;
    return data;
  }
}

export interface ISwitchedAccountAuthenticateResultModel {
  accessToken: string | undefined;
  encryptedAccessToken: string | undefined;
  expireInSeconds: number;
}

export class ExternalLoginProviderInfoModel
  implements IExternalLoginProviderInfoModel
{
  name!: string | undefined;
  clientId!: string | undefined;
  additionalParams!: { [key: string]: string } | undefined;

  constructor(data?: IExternalLoginProviderInfoModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.name = data['name'];
      this.clientId = data['clientId'];
      if (data['additionalParams']) {
        this.additionalParams = {} as any;
        for (let key in data['additionalParams']) {
          if (data['additionalParams'].hasOwnProperty(key))
            this.additionalParams![key] = data['additionalParams'][key];
        }
      }
    }
  }

  static fromJS(data: any): ExternalLoginProviderInfoModel {
    data = typeof data === 'object' ? data : {};
    let result = new ExternalLoginProviderInfoModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['name'] = this.name;
    data['clientId'] = this.clientId;
    if (this.additionalParams) {
      data['additionalParams'] = {};
      for (let key in this.additionalParams) {
        if (this.additionalParams.hasOwnProperty(key))
          data['additionalParams'][key] = this.additionalParams[key];
      }
    }
    return data;
  }
}

export interface IExternalLoginProviderInfoModel {
  name: string | undefined;
  clientId: string | undefined;
  additionalParams: { [key: string]: string } | undefined;
}

export class ExternalAuthenticateModel implements IExternalAuthenticateModel {
  authProvider!: string | undefined;
  providerKey!: string | undefined;
  providerAccessCode!: string | undefined;
  returnUrl!: string | undefined;
  singleSignIn!: boolean | undefined;

  constructor(data?: IExternalAuthenticateModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.authProvider = data['authProvider'];
      this.providerKey = data['providerKey'];
      this.providerAccessCode = data['providerAccessCode'];
      this.returnUrl = data['returnUrl'];
      this.singleSignIn = data['singleSignIn'];
    }
  }

  static fromJS(data: any): ExternalAuthenticateModel {
    data = typeof data === 'object' ? data : {};
    let result = new ExternalAuthenticateModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['authProvider'] = this.authProvider;
    data['providerKey'] = this.providerKey;
    data['providerAccessCode'] = this.providerAccessCode;
    data['returnUrl'] = this.returnUrl;
    data['singleSignIn'] = this.singleSignIn;
    return data;
  }
}

export interface IExternalAuthenticateModel {
  authProvider: string | undefined;
  providerKey: string | undefined;
  providerAccessCode: string | undefined;
  returnUrl: string | undefined;
  singleSignIn: boolean | undefined;
}

export class ExternalAuthenticateResultModel
  implements IExternalAuthenticateResultModel
{
  accessToken!: string | undefined;
  encryptedAccessToken!: string | undefined;
  expireInSeconds!: number;
  waitingForActivation!: boolean;
  returnUrl!: string | undefined;
  refreshToken!: string | undefined;
  refreshTokenExpireInSeconds!: number;

  constructor(data?: IExternalAuthenticateResultModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.accessToken = data['accessToken'];
      this.encryptedAccessToken = data['encryptedAccessToken'];
      this.expireInSeconds = data['expireInSeconds'];
      this.waitingForActivation = data['waitingForActivation'];
      this.returnUrl = data['returnUrl'];
      this.refreshToken = data['refreshToken'];
      this.refreshTokenExpireInSeconds = data['refreshTokenExpireInSeconds'];
    }
  }

  static fromJS(data: any): ExternalAuthenticateResultModel {
    data = typeof data === 'object' ? data : {};
    let result = new ExternalAuthenticateResultModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['accessToken'] = this.accessToken;
    data['encryptedAccessToken'] = this.encryptedAccessToken;
    data['expireInSeconds'] = this.expireInSeconds;
    data['waitingForActivation'] = this.waitingForActivation;
    data['returnUrl'] = this.returnUrl;
    data['refreshToken'] = this.refreshToken;
    data['refreshTokenExpireInSeconds'] = this.refreshTokenExpireInSeconds;
    return data;
  }
}

export interface IExternalAuthenticateResultModel {
  accessToken: string | undefined;
  encryptedAccessToken: string | undefined;
  expireInSeconds: number;
  waitingForActivation: boolean;
  returnUrl: string | undefined;
  refreshToken: string | undefined;
  refreshTokenExpireInSeconds: number;
}

export class UserListRoleDto implements IUserListRoleDto {
  roleId!: number;
  roleName!: string | undefined;

  constructor(data?: IUserListRoleDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.roleId = data['roleId'];
      this.roleName = data['roleName'];
    }
  }

  static fromJS(data: any): UserListRoleDto {
    data = typeof data === 'object' ? data : {};
    let result = new UserListRoleDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['roleId'] = this.roleId;
    data['roleName'] = this.roleName;
    return data;
  }
}

export interface IUserListRoleDto {
  roleId: number;
  roleName: string | undefined;
}

export class UserListDto implements IUserListDto {
  name!: string | undefined;
  surname!: string | undefined;
  userName!: string | undefined;
  emailAddress!: string | undefined;
  phoneNumber!: string | undefined;
  profilePictureId!: string | undefined;
  isEmailConfirmed!: boolean;
  roles!: UserListRoleDto[] | undefined;
  isActive!: boolean;
  creationTime!: moment.Moment;
  id!: number;

  constructor(data?: IUserListDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.name = data['name'];
      this.surname = data['surname'];
      this.userName = data['userName'];
      this.emailAddress = data['emailAddress'];
      this.phoneNumber = data['phoneNumber'];
      this.profilePictureId = data['profilePictureId'];
      this.isEmailConfirmed = data['isEmailConfirmed'];
      if (Array.isArray(data['roles'])) {
        this.roles = [] as any;
        for (let item of data['roles'])
          this.roles!.push(UserListRoleDto.fromJS(item));
      }
      this.isActive = data['isActive'];
      this.creationTime = data['creationTime']
        ? moment(data['creationTime'].toString())
        : <any>undefined;
      this.id = data['id'];
    }
  }

  static fromJS(data: any): UserListDto {
    data = typeof data === 'object' ? data : {};
    let result = new UserListDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['name'] = this.name;
    data['surname'] = this.surname;
    data['userName'] = this.userName;
    data['emailAddress'] = this.emailAddress;
    data['phoneNumber'] = this.phoneNumber;
    data['profilePictureId'] = this.profilePictureId;
    data['isEmailConfirmed'] = this.isEmailConfirmed;
    if (Array.isArray(this.roles)) {
      data['roles'] = [];
      for (let item of this.roles) data['roles'].push(item.toJSON());
    }
    data['isActive'] = this.isActive;
    data['creationTime'] = this.creationTime
      ? this.creationTime.toISOString()
      : <any>undefined;
    data['id'] = this.id;
    return data;
  }
}

export interface IUserListDto {
  name: string | undefined;
  surname: string | undefined;
  userName: string | undefined;
  emailAddress: string | undefined;
  phoneNumber: string | undefined;
  profilePictureId: string | undefined;
  isEmailConfirmed: boolean;
  roles: UserListRoleDto[] | undefined;
  isActive: boolean;
  creationTime: moment.Moment;
  id: number;
}

export class PagedResultDtoOfUserListDto
  implements IPagedResultDtoOfUserListDto
{
  totalCount!: number;
  items!: UserListDto[] | undefined;

  constructor(data?: IPagedResultDtoOfUserListDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.totalCount = data['totalCount'];
      if (Array.isArray(data['items'])) {
        this.items = [] as any;
        for (let item of data['items'])
          this.items!.push(UserListDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): PagedResultDtoOfUserListDto {
    data = typeof data === 'object' ? data : {};
    let result = new PagedResultDtoOfUserListDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['totalCount'] = this.totalCount;
    if (Array.isArray(this.items)) {
      data['items'] = [];
      for (let item of this.items) data['items'].push(item.toJSON());
    }
    return data;
  }
}

export interface IPagedResultDtoOfUserListDto {
  totalCount: number;
  items: UserListDto[] | undefined;
}

export class UserEditDto implements IUserEditDto {
  id!: number | undefined;
  name!: string | undefined;
  surname!: string | undefined;
  userName!: string | undefined;
  emailAddress!: string | undefined;
  phoneNumber!: string | undefined;
  password!: string | undefined;
  isActive!: boolean;
  shouldChangePasswordOnNextLogin!: boolean;
  isTwoFactorEnabled!: boolean;
  isLockoutEnabled!: boolean;

  constructor(data?: IUserEditDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id'];
      this.name = data['name'];
      this.surname = data['surname'];
      this.userName = data['userName'];
      this.emailAddress = data['emailAddress'];
      this.phoneNumber = data['phoneNumber'];
      this.password = data['password'];
      this.isActive = data['isActive'];
      this.shouldChangePasswordOnNextLogin =
        data['shouldChangePasswordOnNextLogin'];
      this.isTwoFactorEnabled = data['isTwoFactorEnabled'];
      this.isLockoutEnabled = data['isLockoutEnabled'];
    }
  }

  static fromJS(data: any): UserEditDto {
    data = typeof data === 'object' ? data : {};
    let result = new UserEditDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['id'] = this.id;
    data['name'] = this.name;
    data['surname'] = this.surname;
    data['userName'] = this.userName;
    data['emailAddress'] = this.emailAddress;
    data['phoneNumber'] = this.phoneNumber;
    data['password'] = this.password;
    data['isActive'] = this.isActive;
    data['shouldChangePasswordOnNextLogin'] =
      this.shouldChangePasswordOnNextLogin;
    data['isTwoFactorEnabled'] = this.isTwoFactorEnabled;
    data['isLockoutEnabled'] = this.isLockoutEnabled;
    return data;
  }
}

export interface IUserEditDto {
  id: number | undefined;
  name: string | undefined;
  surname: string | undefined;
  userName: string | undefined;
  emailAddress: string | undefined;
  phoneNumber: string | undefined;
  password: string | undefined;
  isActive: boolean;
  shouldChangePasswordOnNextLogin: boolean;
  isTwoFactorEnabled: boolean;
  isLockoutEnabled: boolean;
}

export class UserRoleDto implements IUserRoleDto {
  roleId!: number;
  roleName!: string | undefined;
  roleDisplayName!: string | undefined;
  isAssigned!: boolean;
  inheritedFromOrganizationUnit!: boolean;

  constructor(data?: IUserRoleDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.roleId = data['roleId'];
      this.roleName = data['roleName'];
      this.roleDisplayName = data['roleDisplayName'];
      this.isAssigned = data['isAssigned'];
      this.inheritedFromOrganizationUnit =
        data['inheritedFromOrganizationUnit'];
    }
  }

  static fromJS(data: any): UserRoleDto {
    data = typeof data === 'object' ? data : {};
    let result = new UserRoleDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['roleId'] = this.roleId;
    data['roleName'] = this.roleName;
    data['roleDisplayName'] = this.roleDisplayName;
    data['isAssigned'] = this.isAssigned;
    data['inheritedFromOrganizationUnit'] = this.inheritedFromOrganizationUnit;
    return data;
  }
}

export interface IUserRoleDto {
  roleId: number;
  roleName: string | undefined;
  roleDisplayName: string | undefined;
  isAssigned: boolean;
  inheritedFromOrganizationUnit: boolean;
}

export class GetUserForEditOutput implements IGetUserForEditOutput {
  profilePictureId!: string | undefined;
  user!: UserEditDto;
  roles!: UserRoleDto[] | undefined;
  allOrganizationUnits!: OrganizationUnitDto[] | undefined;
  memberedOrganizationUnits!: string[] | undefined;

  constructor(data?: IGetUserForEditOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.profilePictureId = data['profilePictureId'];
      this.user = data['user']
        ? UserEditDto.fromJS(data['user'])
        : <any>undefined;
      if (Array.isArray(data['roles'])) {
        this.roles = [] as any;
        for (let item of data['roles'])
          this.roles!.push(UserRoleDto.fromJS(item));
      }
      if (Array.isArray(data['allOrganizationUnits'])) {
        this.allOrganizationUnits = [] as any;
        for (let item of data['allOrganizationUnits'])
          this.allOrganizationUnits!.push(OrganizationUnitDto.fromJS(item));
      }
      if (Array.isArray(data['memberedOrganizationUnits'])) {
        this.memberedOrganizationUnits = [] as any;
        for (let item of data['memberedOrganizationUnits'])
          this.memberedOrganizationUnits!.push(item);
      }
    }
  }

  static fromJS(data: any): GetUserForEditOutput {
    data = typeof data === 'object' ? data : {};
    let result = new GetUserForEditOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['profilePictureId'] = this.profilePictureId;
    data['user'] = this.user ? this.user.toJSON() : <any>undefined;
    if (Array.isArray(this.roles)) {
      data['roles'] = [];
      for (let item of this.roles) data['roles'].push(item.toJSON());
    }
    if (Array.isArray(this.allOrganizationUnits)) {
      data['allOrganizationUnits'] = [];
      for (let item of this.allOrganizationUnits)
        data['allOrganizationUnits'].push(item.toJSON());
    }
    if (Array.isArray(this.memberedOrganizationUnits)) {
      data['memberedOrganizationUnits'] = [];
      for (let item of this.memberedOrganizationUnits)
        data['memberedOrganizationUnits'].push(item);
    }
    return data;
  }
}

export interface IGetUserForEditOutput {
  profilePictureId: string | undefined;
  user: UserEditDto;
  roles: UserRoleDto[] | undefined;
  allOrganizationUnits: OrganizationUnitDto[] | undefined;
  memberedOrganizationUnits: string[] | undefined;
}

export class GetUserPermissionsForEditOutput
  implements IGetUserPermissionsForEditOutput
{
  permissions!: FlatPermissionDto[] | undefined;
  grantedPermissionNames!: string[] | undefined;

  constructor(data?: IGetUserPermissionsForEditOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['permissions'])) {
        this.permissions = [] as any;
        for (let item of data['permissions'])
          this.permissions!.push(FlatPermissionDto.fromJS(item));
      }
      if (Array.isArray(data['grantedPermissionNames'])) {
        this.grantedPermissionNames = [] as any;
        for (let item of data['grantedPermissionNames'])
          this.grantedPermissionNames!.push(item);
      }
    }
  }

  static fromJS(data: any): GetUserPermissionsForEditOutput {
    data = typeof data === 'object' ? data : {};
    let result = new GetUserPermissionsForEditOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.permissions)) {
      data['permissions'] = [];
      for (let item of this.permissions)
        data['permissions'].push(item.toJSON());
    }
    if (Array.isArray(this.grantedPermissionNames)) {
      data['grantedPermissionNames'] = [];
      for (let item of this.grantedPermissionNames)
        data['grantedPermissionNames'].push(item);
    }
    return data;
  }
}

export interface IGetUserPermissionsForEditOutput {
  permissions: FlatPermissionDto[] | undefined;
  grantedPermissionNames: string[] | undefined;
}

export class EntityDtoOfInt64 implements IEntityDtoOfInt64 {
  id!: number;

  constructor(data?: IEntityDtoOfInt64) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id'];
    }
  }

  static fromJS(data: any): EntityDtoOfInt64 {
    data = typeof data === 'object' ? data : {};
    let result = new EntityDtoOfInt64();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['id'] = this.id;
    return data;
  }
}

export interface IEntityDtoOfInt64 {
  id: number;
}

export class UpdateUserPermissionsInput implements IUpdateUserPermissionsInput {
  id!: number;
  grantedPermissionNames!: string[] | undefined;

  constructor(data?: IUpdateUserPermissionsInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id'];
      if (Array.isArray(data['grantedPermissionNames'])) {
        this.grantedPermissionNames = [] as any;
        for (let item of data['grantedPermissionNames'])
          this.grantedPermissionNames!.push(item);
      }
    }
  }

  static fromJS(data: any): UpdateUserPermissionsInput {
    data = typeof data === 'object' ? data : {};
    let result = new UpdateUserPermissionsInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['id'] = this.id;
    if (Array.isArray(this.grantedPermissionNames)) {
      data['grantedPermissionNames'] = [];
      for (let item of this.grantedPermissionNames)
        data['grantedPermissionNames'].push(item);
    }
    return data;
  }
}

export interface IUpdateUserPermissionsInput {
  id: number;
  grantedPermissionNames: string[] | undefined;
}

export class CreateOrUpdateUserInput implements ICreateOrUpdateUserInput {
  user!: UserEditDto;
  assignedRoleNames!: string[] | undefined;
  sendActivationEmail!: boolean;
  setRandomPassword!: boolean;
  organizationUnits!: number[] | undefined;

  constructor(data?: ICreateOrUpdateUserInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
    if (!data) {
      this.user = new UserEditDto();
    }
  }

  init(data?: any) {
    if (data) {
      this.user = data['user']
        ? UserEditDto.fromJS(data['user'])
        : new UserEditDto();
      if (Array.isArray(data['assignedRoleNames'])) {
        this.assignedRoleNames = [] as any;
        for (let item of data['assignedRoleNames'])
          this.assignedRoleNames!.push(item);
      }
      this.sendActivationEmail = data['sendActivationEmail'];
      this.setRandomPassword = data['setRandomPassword'];
      if (Array.isArray(data['organizationUnits'])) {
        this.organizationUnits = [] as any;
        for (let item of data['organizationUnits'])
          this.organizationUnits!.push(item);
      }
    }
  }

  static fromJS(data: any): CreateOrUpdateUserInput {
    data = typeof data === 'object' ? data : {};
    let result = new CreateOrUpdateUserInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['user'] = this.user ? this.user.toJSON() : <any>undefined;
    if (Array.isArray(this.assignedRoleNames)) {
      data['assignedRoleNames'] = [];
      for (let item of this.assignedRoleNames)
        data['assignedRoleNames'].push(item);
    }
    data['sendActivationEmail'] = this.sendActivationEmail;
    data['setRandomPassword'] = this.setRandomPassword;
    if (Array.isArray(this.organizationUnits)) {
      data['organizationUnits'] = [];
      for (let item of this.organizationUnits)
        data['organizationUnits'].push(item);
    }
    return data;
  }
}

export interface ICreateOrUpdateUserInput {
  user: UserEditDto;
  assignedRoleNames: string[] | undefined;
  sendActivationEmail: boolean;
  setRandomPassword: boolean;
  organizationUnits: number[] | undefined;
}

export class LinkToUserInput implements ILinkToUserInput {
  tenancyName!: string | undefined;
  usernameOrEmailAddress!: string | undefined;
  password!: string | undefined;

  constructor(data?: ILinkToUserInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.tenancyName = data['tenancyName'];
      this.usernameOrEmailAddress = data['usernameOrEmailAddress'];
      this.password = data['password'];
    }
  }

  static fromJS(data: any): LinkToUserInput {
    data = typeof data === 'object' ? data : {};
    let result = new LinkToUserInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['tenancyName'] = this.tenancyName;
    data['usernameOrEmailAddress'] = this.usernameOrEmailAddress;
    data['password'] = this.password;
    return data;
  }
}

export interface ILinkToUserInput {
  tenancyName: string | undefined;
  usernameOrEmailAddress: string | undefined;
  password: string | undefined;
}

export class LinkedUserDto implements ILinkedUserDto {
  tenantId!: number | undefined;
  tenancyName!: string | undefined;
  username!: string | undefined;
  id!: number;

  constructor(data?: ILinkedUserDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.tenantId = data['tenantId'];
      this.tenancyName = data['tenancyName'];
      this.username = data['username'];
      this.id = data['id'];
    }
  }

  static fromJS(data: any): LinkedUserDto {
    data = typeof data === 'object' ? data : {};
    let result = new LinkedUserDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['tenantId'] = this.tenantId;
    data['tenancyName'] = this.tenancyName;
    data['username'] = this.username;
    data['id'] = this.id;
    return data;
  }
}

export interface ILinkedUserDto {
  tenantId: number | undefined;
  tenancyName: string | undefined;
  username: string | undefined;
  id: number;
}

export class PagedResultDtoOfLinkedUserDto
  implements IPagedResultDtoOfLinkedUserDto
{
  totalCount!: number;
  items!: LinkedUserDto[] | undefined;

  constructor(data?: IPagedResultDtoOfLinkedUserDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.totalCount = data['totalCount'];
      if (Array.isArray(data['items'])) {
        this.items = [] as any;
        for (let item of data['items'])
          this.items!.push(LinkedUserDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): PagedResultDtoOfLinkedUserDto {
    data = typeof data === 'object' ? data : {};
    let result = new PagedResultDtoOfLinkedUserDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['totalCount'] = this.totalCount;
    if (Array.isArray(this.items)) {
      data['items'] = [];
      for (let item of this.items) data['items'].push(item.toJSON());
    }
    return data;
  }
}

export interface IPagedResultDtoOfLinkedUserDto {
  totalCount: number;
  items: LinkedUserDto[] | undefined;
}

export class ListResultDtoOfLinkedUserDto
  implements IListResultDtoOfLinkedUserDto
{
  items!: LinkedUserDto[] | undefined;

  constructor(data?: IListResultDtoOfLinkedUserDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['items'])) {
        this.items = [] as any;
        for (let item of data['items'])
          this.items!.push(LinkedUserDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): ListResultDtoOfLinkedUserDto {
    data = typeof data === 'object' ? data : {};
    let result = new ListResultDtoOfLinkedUserDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.items)) {
      data['items'] = [];
      for (let item of this.items) data['items'].push(item.toJSON());
    }
    return data;
  }
}

export interface IListResultDtoOfLinkedUserDto {
  items: LinkedUserDto[] | undefined;
}

export class UnlinkUserInput implements IUnlinkUserInput {
  tenantId!: number | undefined;
  userId!: number;

  constructor(data?: IUnlinkUserInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.tenantId = data['tenantId'];
      this.userId = data['userId'];
    }
  }

  static fromJS(data: any): UnlinkUserInput {
    data = typeof data === 'object' ? data : {};
    let result = new UnlinkUserInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['tenantId'] = this.tenantId;
    data['userId'] = this.userId;
    return data;
  }
}

export interface IUnlinkUserInput {
  tenantId: number | undefined;
  userId: number;
}

export class UserLoginAttemptDto implements IUserLoginAttemptDto {
  tenancyName!: string | undefined;
  userNameOrEmail!: string | undefined;
  clientIpAddress!: string | undefined;
  clientName!: string | undefined;
  browserInfo!: string | undefined;
  result!: string | undefined;
  creationTime!: moment.Moment;

  constructor(data?: IUserLoginAttemptDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.tenancyName = data['tenancyName'];
      this.userNameOrEmail = data['userNameOrEmail'];
      this.clientIpAddress = data['clientIpAddress'];
      this.clientName = data['clientName'];
      this.browserInfo = data['browserInfo'];
      this.result = data['result'];
      this.creationTime = data['creationTime']
        ? moment(data['creationTime'].toString())
        : <any>undefined;
    }
  }

  static fromJS(data: any): UserLoginAttemptDto {
    data = typeof data === 'object' ? data : {};
    let result = new UserLoginAttemptDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['tenancyName'] = this.tenancyName;
    data['userNameOrEmail'] = this.userNameOrEmail;
    data['clientIpAddress'] = this.clientIpAddress;
    data['clientName'] = this.clientName;
    data['browserInfo'] = this.browserInfo;
    data['result'] = this.result;
    data['creationTime'] = this.creationTime
      ? this.creationTime.toISOString()
      : <any>undefined;
    return data;
  }
}

export interface IUserLoginAttemptDto {
  tenancyName: string | undefined;
  userNameOrEmail: string | undefined;
  clientIpAddress: string | undefined;
  clientName: string | undefined;
  browserInfo: string | undefined;
  result: string | undefined;
  creationTime: moment.Moment;
}

export class ListResultDtoOfUserLoginAttemptDto
  implements IListResultDtoOfUserLoginAttemptDto
{
  items!: UserLoginAttemptDto[] | undefined;

  constructor(data?: IListResultDtoOfUserLoginAttemptDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['items'])) {
        this.items = [] as any;
        for (let item of data['items'])
          this.items!.push(UserLoginAttemptDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): ListResultDtoOfUserLoginAttemptDto {
    data = typeof data === 'object' ? data : {};
    let result = new ListResultDtoOfUserLoginAttemptDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.items)) {
      data['items'] = [];
      for (let item of this.items) data['items'].push(item.toJSON());
    }
    return data;
  }
}

export interface IListResultDtoOfUserLoginAttemptDto {
  items: UserLoginAttemptDto[] | undefined;
}

export class GetLatestWebLogsOutput implements IGetLatestWebLogsOutput {
  latestWebLogLines!: string[] | undefined;

  constructor(data?: IGetLatestWebLogsOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data['latestWebLogLines'])) {
        this.latestWebLogLines = [] as any;
        for (let item of data['latestWebLogLines'])
          this.latestWebLogLines!.push(item);
      }
    }
  }

  static fromJS(data: any): GetLatestWebLogsOutput {
    data = typeof data === 'object' ? data : {};
    let result = new GetLatestWebLogsOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.latestWebLogLines)) {
      data['latestWebLogLines'] = [];
      for (let item of this.latestWebLogLines)
        data['latestWebLogLines'].push(item);
    }
    return data;
  }
}

export interface IGetLatestWebLogsOutput {
  latestWebLogLines: string[] | undefined;
}

export class AdditionalData implements IAdditionalData {
  paypal!: { [key: string]: string };
  stripe!: { [key: string]: string };

  constructor(data?: IAdditionalData) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (data['Paypal']) {
        this.paypal = {} as any;
        for (let key in data['Paypal']) {
          if (data['Paypal'].hasOwnProperty(key))
            this.paypal![key] = data['Paypal'][key];
        }
      }
      if (data['Stripe']) {
        this.stripe = {} as any;
        for (let key in data['Stripe']) {
          if (data['Stripe'].hasOwnProperty(key))
            this.stripe![key] = data['Stripe'][key];
        }
      }
    }
  }

  static fromJS(data: any): AdditionalData {
    data = typeof data === 'object' ? data : {};
    let result = new AdditionalData();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (this.paypal) {
      data['Paypal'] = {};
      for (let key in this.paypal) {
        if (this.paypal.hasOwnProperty(key))
          data['Paypal'][key] = this.paypal[key];
      }
    }
    if (this.stripe) {
      data['Stripe'] = {};
      for (let key in this.stripe) {
        if (this.stripe.hasOwnProperty(key))
          data['Stripe'][key] = this.stripe[key];
      }
    }
    return data;
  }
}

export interface IAdditionalData {
  paypal: { [key: string]: string };
  stripe: { [key: string]: string };
}

export class ApiException extends Error {
  message: string;
  status: number;
  response: string;
  headers: { [key: string]: any };
  result: any;

  constructor(
    message: string,
    status: number,
    response: string,
    headers: { [key: string]: any },
    result: any
  ) {
    super();

    this.message = message;
    this.status = status;
    this.response = response;
    this.headers = headers;
    this.result = result;
  }

  protected isApiException = true;

  static isApiException(obj: any): obj is ApiException {
    return obj.isApiException === true;
  }
}
