<div class="modal-header">
  <h3 class="arb-large-heading" id="modal-basic-title">SUPPLIER PARTS</h3>
</div>
<div class="modal-body">
  <div class="text-danger mb-3">THE PART NUMBER ENTERED MATCHED THE FOLLOWING SUPPLIER PART NUMBERS - DO YOU WANT TO USE ONE OF THESE?</div>

  <table class="table table-sm table-bordered">
    <thead>
      <tr>
        <th scope="col" class="text-uppercase"><label>Code</label></th>
        <th scope="col" class="text-uppercase"><label>Supplier</label></th>
        <th scope="col" class="text-uppercase"><label>Description</label></th>
        <th scope="col" class="text-uppercase"><label>Operation</label></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of data">
        <td>{{ item.itemNumber }}</td>
        <td>{{ item.supplierName }}</td>
        <td>{{ item.itemDescription }}</td>
        <td>
          <button class="btn text-white" (click)="select(item)">Select</button>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="d-flex justify-content-end">
    <button class="btn btn-cancel mr-2" (click)="cancel()">
      CANCEL
    </button>
  </div>
</div>
