<div class="container pt-2">
  <h2 class="text-center pt-2">DISTRIBUTE ORDER</h2>
  <div *ngIf="cashPaymentData.cashTendered || cashPaymentData.cashChange" class="font-weight-bold pb-3" >
    CASH TENDERED: {{ cashPaymentData.cashTendered | currency }}
    <br />
    CASH CHANGE: {{ cashPaymentData.cashChange | currency }}
  </div>
  <form [formGroup]="distributeForm">
    <div class="pb-3">
      <div class="font-weight-bold pb-2">SELECT OUTPUTS TO PRODUCE:</div>
      <input class="form-check-input" type="checkbox" formControlName="toOutput" /><span class="px-1"></span>{{ getOrderConfirmationHeading() }}
    </div>
    <div *ngIf="distributeForm.controls.toOutput.value">
      <div class="font-weight-bold pb-2">SELECT WHERE TO SEND OUTPUTS:</div>
      <div class="row pb-3" *ngIf="isReceiptPrinterConnected && !!orderConfirmation">
        <div class="col-2">
          <input class="form-check-input" type="checkbox" formControlName="toPrintReceipt" />
          <span class="px-1"></span>PRINT RECEIPT
        </div>
      </div>
      <div class="row pb-3">
        <div class="col-2">
          <input class="form-check-input" type="checkbox" formControlName="toEmail" />
          <span class="px-1"></span>EMAIL
        </div>
        <div class="col-4">
          <input type="text" class="form-control" formControlName="email" />
        </div>
        <div class="col-4">
          <button
            class="btn btn-secondary"
            type="button"
            (click)="updateCustomerEmail()"
            [disabled]="
              (distributeForm.controls.toEmail.value && !distributeForm.controls.email.valid) || selectedCustomer.ReadOnlyCustomerYN === 'Y'
            "
            *ngIf="!isQuickSale && userRole !== USER_ROLE.external && userRole !== USER_ROLE.showExternal"
          >
            UPDATE EMAIL ON CUSTOMER
          </button>
        </div>
      </div>
      <div class="row pb-3" *ngIf="userRole !== USER_ROLE.external">
        <div class="col-2">
          <input class="form-check-input" type="checkbox" formControlName="toPrint" />
          <span class="px-1"></span>PRINT
        </div>
        <div class="col-4">
          <ng-select formControlName="printerID" *ngIf="distributeForm.controls.toPrint.value" appendTo="body">
            <ng-option *ngFor="let printer of printerList" [value]="printer.printerRole">
              <span class="pr-1">{{ printer.printerDescription }} -</span>
              {{ printer.printerRole }}
            </ng-option>
          </ng-select>
        </div>
      </div>
    </div>
  </form>
</div>
