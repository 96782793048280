import { formatNumber } from '@angular/common';
import { ApiService } from '@pos-app/core-ui';
import { ORDER_TYPE } from '@pos-app/data';
import { getFullYearLocaleDate } from 'libs/core-ui/src/lib/utils/utils-date';
import { loadPartDetails } from '../../services/orders-helper.service';
import { GridOptions } from 'ag-grid-community';

export const getMasterGrid = (orderType: string, locale: string) => {
  return {
    defaultColDef: {
      resizable: true,
    },
    columnDefs: [
      {
        headerName: 'Action',
        cellRenderer: 'actionRenderer',
        colId: 'action',
        width: 40,
        cellClass: 'cell-class',
        headerClass: 'header-class',
      },
      {
        headerName: 'Invoice',
        field: 'InvoiceNumber',
        sortable: true,
        cellRenderer: 'agGroupCellRenderer',
        headerTooltip: 'Invoice Number',
        cellClass: 'cell-class',
        headerClass: 'header-class',
      },
      {
        headerName: 'Order',
        field: 'OrderNumber',
        sortable: true,
        headerTooltip: 'Order Number',
        cellClass: 'cell-class',
        headerClass: 'header-class',
        cellRendererSelector: (params) => {
          const expandDetails = {
            component: 'agGroupCellRenderer',
          };
          return orderType !== ORDER_TYPE.invoicedOrder ? expandDetails : null;
        },
      },
      {
        headerName: 'Reference',
        field: 'CustomerReference',
        sortable: true,
        headerTooltip: 'Customer Reference',
        cellClass: 'cell-class',
        headerClass: 'header-class',
      },
      {
        headerName: 'SO Type',
        field: 'OrderType',
        sortable: true,
        headerTooltip: 'Order Type',
        cellClass: 'cell-class',
        headerClass: 'header-class',
      },
      {
        headerName: 'Stock Branch',
        field: 'StockingBranchName',
        sortable: true,
        headerTooltip: 'Stocking Branch',
        cellClass: 'cell-class',
        headerClass: 'header-class',
      },
      {
        headerName: 'Rev Branch',
        field: 'RevenueBranchName',
        sortable: true,
        headerTooltip: 'Revenue Branch',
        cellClass: 'cell-class',
        headerClass: 'header-class',
      },
      {
        headerName: 'Ship To',
        field: 'ShipToName',
        sortable: true,
        headerTooltip: 'Ship To Name',
        cellClass: 'cell-class',
        headerClass: 'header-class',
      },
      {
        headerName: 'Channel',
        field: 'CustomerChannelName',
        sortable: true,
        headerTooltip: 'Customer Channel Name',
        cellClass: 'cell-class',
        headerClass: 'header-class',
      },
      {
        headerName: 'Order Date',
        field: 'OrderDate',
        sortable: true,
        headerTooltip: 'Order Date',
        cellClass: 'cell-class',
        headerClass: 'header-class',
        valueGetter: (params) => {
          return dateFormatter(params.data.OrderDate);
        },
      },
      {
        headerName: 'Inv Date',
        field: 'InvoiceDate',
        sortable: true,
        headerTooltip: 'Invoice Date',
        cellClass: 'cell-class',
        headerClass: 'header-class',
        valueGetter: (params) => {
          return dateFormatter(params.data.InvoiceDate);
        },
      },
      {
        headerName: 'Req Date',
        field: 'RequestDate',
        sortable: true,
        headerTooltip: 'Request Date',
        cellClass: 'cell-class',
        headerClass: 'header-class',
        valueGetter: (params) => {
          return dateFormatter(params.data.RequestDate);
        },
      },
      {
        headerName: 'Part',
        field: 'PartNumbers',
        sortable: true,
        headerTooltip: 'Part Number',
        cellClass: 'cell-class',
        headerClass: 'header-class',
      },
      {
        headerName: 'Status',
        field: 'OrderStatus',
        sortable: true,
        headerTooltip: 'Order Status',
        cellClass: 'cell-class',
        headerClass: 'header-class',
      },
      {
        headerName: 'Hold',
        field: 'holdCodeDescription',
        sortable: true,
        headerTooltip: 'Hold Code Description',
        cellClass: 'cell-class',
        headerClass: 'header-class',
      },
      {
        headerName: 'Total Value',
        field: 'TotalOrderValue_Number',
        sortable: true,
        headerTooltip: 'Total Order Value',
        cellClass: 'cell-class',
        headerClass: 'header-class',
        valueGetter: (params) => {
          return formatNumber(params.data.TotalOrderValue_Number, locale);
        },
      },
      {
        headerName: 'Open Amt',
        field: 'OutstandingAmount_Number',
        sortable: true,
        headerTooltip: 'Outstanding Amount',
        cellClass: 'cell-class',
        headerClass: 'header-class',
        valueGetter: (params) => {
          return formatNumber(params.data.OutstandingAmount_Number, locale);
        },
      },
      {
        headerName: 'Payments',
        field: 'PaymentsReceived_Number',
        sortable: true,
        headerTooltip: 'Payments Received',
        cellClass: 'cell-class',
        headerClass: 'header-class',
        valueGetter: (params) => {
          return formatNumber(params.data.OutstandingAmount_Number, locale);
        },
      },
      {
        headerName: 'Phone',
        field: 'CustomerPhone',
        sortable: true,
        headerTooltip: 'Customer Phone',
        cellClass: 'cell-class',
        headerClass: 'header-class',
      },
      {
        headerName: 'Fit Y/N',
        field: 'FitOrderYN',
        sortable: true,
        headerTooltip: 'Fit Order',
        cellClass: 'cell-class',
        headerClass: 'header-class',
      },
      {
        headerName: 'Vehicle',
        field: 'VehicleShortDescription',
        sortable: true,
        headerTooltip: 'Vehicle Description',
        cellClass: 'cell-class',
        headerClass: 'header-class',
      },
      {
        headerName: 'Rego',
        field: 'VehicleRego',
        sortable: true,
        headerTooltip: 'Vehicle Rego',
        cellClass: 'cell-class',
        headerClass: 'header-class',
      },
    ],
  } as GridOptions;
  function dateFormatter(params) {
    if (!!params && params.length > 0) {
      //Response from server will always be in dd/MM/yyy i.e 30/10/2022
      let date = params.split('/');
      let x = getFullYearLocaleDate(locale, {
        year: date[2],
        monthIndex: date[1] - 1, // 0 - 11 is for Jan - Dec Url: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date
        day: date[0],
      });

      return x;
      //moment().day(date[0]).month(date[1]).year(date[2]).format('L');
    }
  }
};

export const getDetailedGrid = (orderType: string, apiService: ApiService, locale: string) => {
  return {
    detailGridOptions: {
      enableRangeSelection: true,
      domLayout: 'normal',
      pagination: true,
      paginationPageSize: 10,
      defaultColDef: {
        resizable: true,
      },
      columnDefs: [
        {
          headerName: 'Order',
          field: 'OrderNumber',
          headerTooltip: 'Order Number',
          cellClass: 'cell-class',
          headerClass: 'header-class',
        },
        {
          headerName: 'Line',
          field: 'LineNumber',
          headerTooltip: 'Line Number',
          cellClass: 'cell-class',
          headerClass: 'header-class',
        },
        {
          headerName: 'Invoice',
          field: 'InvoiceNumber',
          headerTooltip: 'Invoice Number',
          cellClass: 'cell-class',
          headerClass: 'header-class',
        },
        {
          headerName: 'Reference',
          field: 'CustomerReference',
          headerTooltip: 'Customer Reference',
          cellClass: 'cell-class',
          headerClass: 'header-class',
        },
        {
          headerName: 'Part',
          field: 'PartNumber',
          headerTooltip: 'Part Number',
          cellClass: 'cell-class',
          headerClass: 'header-class',
        },
        {
          headerName: 'Part Desc',
          field: 'PartDescription',
          headerTooltip: 'Part Description',
          suppressSizeToFit: true,
          cellClass: 'cell-class',
          headerClass: 'header-class',
        },
        {
          headerName: 'Rls Qty',
          field: 'QuantityReleased',
          headerTooltip: 'Quantity Released',
          cellClass: 'cell-class',
          headerClass: 'header-class',
        },
        {
          headerName: 'Shp Qty',
          field: 'QuantityShipped',
          headerTooltip: 'Quantity Shipped',
          cellClass: 'cell-class',
          headerClass: 'header-class',
        },
        {
          headerName: 'BO Qty',
          field: 'QuantityBackordered',
          headerTooltip: 'Quantity Backordered',
          cellClass: 'cell-class',
          headerClass: 'header-class',
        },
        {
          headerName: 'Lot',
          field: 'LotNumber',
          headerTooltip: 'Lot',
          cellClass: 'cell-class',
          headerClass: 'header-class',
        },
        {
          headerName: 'UPrice',
          field: 'UnitPriceIncTax',
          headerTooltip: 'Unit Price (Inc Tax)',
          cellClass: 'cell-class',
          headerClass: 'header-class',
          valueFormatter: (params) => {
            return formatNumber(params.data.UnitPriceIncTax_Number, locale);
          },
        },
        {
          headerName: 'Price',
          field: 'ExtendedPriceIncTax',
          headerTooltip: 'Extended Price (IncTax)',
          cellClass: 'cell-class',
          headerClass: 'header-class',
          valueFormatter: (params) => {
            return formatNumber(params.data.ExtendedPriceIncTax_Number, locale);
          },
        },
        {
          headerName: 'Lot',
          field: 'LotNumber',
          headerTooltip: 'Lot',
          cellClass: 'cell-class',
          headerClass: 'header-class',
        },
        {
          headerName: 'ATP',
          field: 'ATPDescription',
          cellClass: 'cell-class availability-cell',
          headerClass: 'header-class',
        },
      ],
      onFirstDataRendered(params) {
        if (
          orderType === ORDER_TYPE.saleOrder ||
          orderType === ORDER_TYPE.quoteOrder
        ) {
          params.columnApi.setColumnVisible('InvoiceNumber', false);
          if (orderType === ORDER_TYPE.quoteOrder) {
            params.columnApi.setColumnVisible('OrderNumber', false);
          }
        }
        params.api.sizeColumnsToFit();
      },
    },
    getDetailRowData(params) {
      const trimUnitPrice = (unitPrice: string) => {
        if (unitPrice.substr(unitPrice.indexOf('.') + 1).length > 2) {
          if (unitPrice.charAt(unitPrice.length - 1) === '0') {
            unitPrice = unitPrice.slice(0, -1);
            return trimUnitPrice(unitPrice);
          }
        }
        return unitPrice;
      };
      loadPartDetails(apiService, {
        invoiceNumberSearch: params.data.InvoiceNumber,
        orderNumberSearch: params.data.OrderNumber,
      })
        .toPromise()
        .then((x) => {
          const detailsData = x.SearchResults.map((partDetails) => {
            return {
              ...partDetails,
              QuantityReleased: +partDetails.QuantityReleased,
              QuantityShipped: +partDetails.QuantityShipped,
              QuantityBackordered: +partDetails.QuantityBackordered,
              UnitPriceIncTax: trimUnitPrice(partDetails.UnitPriceIncTax),
              ExtendedPriceIncTax: partDetails.ExtendedPriceIncTax,
            };
          });
          params.successCallback(detailsData);
        });
    },
  };
};
