import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import { AbstractControl, Validators, FormControl } from '@angular/forms';
import {
  switchMap,
  tap,
  debounceTime,
  distinctUntilChanged,
  filter,
  takeWhile
} from 'rxjs/operators';
import { formatDate } from '@angular/common';
import { fuzzySearch } from '../../services/orders-helper.service';
import {
  NgbDate,
  NgbCalendar,
  NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'libs/core-ui/src/lib/services';
import { FilterModel } from '../../+state/orders.models';

@Component({
  selector: 'app-dynamic-form-control',
  templateUrl: './dynamic-form-control.component.html',
  styleUrls: ['./dynamic-form-control.component.scss']
})
export class DynamicFormControlComponent implements OnInit, OnDestroy {
  @Input() config: FilterModel;
  @Input() control: AbstractControl | null = null;

  alive = true;
  fuzzySearchForm = new FormControl('');
  showFuzzySearchList = false;
  fuzzySearchList: any;

  hoveredDate: NgbDate;

  fromDate: NgbDate;
  toDate: NgbDate;
  public isFromDateEnter: boolean;

  constructor(
    private cf: ChangeDetectorRef,
    private apiService: ApiService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter
  ) {}

  ngOnDestroy(): void {
    this.alive = false;
  }
  ngOnInit() {
    if (!this.control) {
      return;
    }
    let validators = [];
    switch (this.config.type) {
      case 'list':
        break;
      case 'api-list':
        break;
      case 'boolean':
        break;
      case 'dateRange':
        break;
      case 'text':
        break;
      case 'fuzzySearch':
        this.fuzzySearchForm.valueChanges
          .pipe(
            tap(() => {
              this.control.patchValue('');
              this.showFuzzySearchList = false;
            }),
            debounceTime(250),
            distinctUntilChanged(),
            filter(v => (v || '').trim().length >= 3),
            switchMap(value => {
              return fuzzySearch(
                this.apiService,
                { searchString: value },
                this.config.endpoint
              );
            }),
            takeWhile(() => this.alive)
          )
          .subscribe(x => {
            this.fuzzySearchList = x.SearchResults;
            if (this.fuzzySearchList.length === 1) {
              const productCode = this.fuzzySearchList[0].ProductCode;
              this.fuzzySearchForm.patchValue(productCode, {
                emitEvent: false
              });
              this.control.patchValue(productCode);
            } else {
              this.showFuzzySearchList = true;
            }
          });
        break;
      case 'number':
        validators = [Validators.pattern('^[0-9]$')];
        break;
      default:
        validators = [];
        break;
    }
    if (this.config.defaultValue) {
      this.control.patchValue(this.config.defaultValue);
    }
    this.control.updateValueAndValidity();
    this.cf.detectChanges();
  }

  selectItem() {
    this.showFuzzySearchList = false;
    this.fuzzySearchForm.patchValue(this.control.value, { emitEvent: false });
  }

  getObjectKeys(item) {
    return Object.keys(item);
  }

  selectDate(date) {
    this.control.setValue({
      [this.config.fromKey]: date.fromDate,
      [this.config.toKey]: date.toDate
    });
  }
}
