<div class="top-search">
  <div class="product-search">
    <div class="product-search-box">
      <span *ngIf="searchText && isSearching" style="position: absolute; right: 5px; top: 4px">
        <i class="pi pi-spin pi-spinner" style="font-size: 2em; color: #ed1c1a"></i>
      </span>
      <input
        id="productSearchBox"
        (click)="resetSearch()"
        (keydown)="onProducSearchKeydown($event)"
        [(ngModel)]="searchText"
        placeholder="Product Search - Suggestion: Enter part code or description"
      />
      <div class="result" style="position: relative" *ngIf="searchText">
        <div *ngIf="searchText && !found" style="position: absolute; width: 100%; z-index: 2; background: #fff; padding: 5px">
          <span>No results found</span>
        </div>
        <div
          id="product-search-container"
          *ngIf="
            searchText &&
            (!userPickedResultItem || productResult.totalRecord > 1) &&
            (productResult.totalRecord > 0 || categoryResult.totalRecord > 0)
          "
        >
          <div id="category-search-result" *ngIf="categoryResult.totalRecord > 0" style="margin: 0 0 5px 0">
            <div class="product-search-header arb-small-heading">
              <span>Categories Result</span>
              <span class="result-header"> {{ categoryResult.totalRecord }} items</span>
            </div>
            <div class="product-search-body" [ngStyle]="{ 'max-height': '265px', position: 'relative' }" [busyIf]="isCategorySearching">
              <ng-scrollbar class="scroll-event" thumbClass="white-scrollbars">
                <ul>
                  <li *ngFor="let category of categoryResult.records; index as i" (click)="searchCategory(category)">
                    <div class="result-item">
                      <div class="row">
                        <div
                          class="col-sm result-item--product-name arb-detail-text-bold"
                          [innerHtml]="category.toTreeView | highlightText : searchText"
                        ></div>
                      </div>
                    </div>
                  </li>
                </ul>
              </ng-scrollbar>
            </div>
            <div class="product-search-footer">
              <p-paginator
                [rows]="10"
                [totalRecords]="categoryResult.totalRecord"
                styleClass="paginator-search"
                (onPageChange)="paginateCategory($event)"
              ></p-paginator>
            </div>
          </div>
          <div id="product-search-result" *ngIf="productResult.totalRecord > 0" style="margin: 0 0 5px">
            <div class="product-search-header arb-small-heading">
              <span>{{ getProductSearchHeader() }}</span>
              <span class="result-header"> {{ productResult.totalRecord }} items</span>
            </div>
            <div class="product-search-body" [ngStyle]="{ 'max-height': '265px', position: 'relative' }" [busyIf]="isProductSearching">
              <ng-scrollbar class="scroll-event" thumbClass="white-scrollbars">
                <ul>
                  <li *ngFor="let product of productResult.records; index as i" (click)="searchProduct(product.companySKU)">
                    <div class="result-item" *ngIf="!(foundByOE(product) || foundByTrade(product) || foundByMetaTag(product))">
                      <div class="row">
                        <div style="padding-left: 15px" class="d-inline-flex">
                          <div class="result-item--brand arb-detail-text" [innerHtml]="product.brandName | highlightText : searchText"></div>
                          <div
                            class="result-item--brand arb-detail-text"
                            *ngIf="product.subBrand"
                            [innerHtml]="', ' + product.subBrand | highlightText : searchText"
                          ></div>
                        </div>
                      </div>
                      <div class="row">
                        <div
                          class="col-sm result-item--product-name arb-detail-text-bold"
                          [innerHtml]="getProductResultText(product) | highlightText : searchText"
                        ></div>
                        <div class="col-sm result-item-vehiclefit" *ngIf="product.fittedVehicleIds">
                          <span *ngIf="product.isFitted">Fit your vehicle</span>
                          <span *ngIf="vehicleId == null || vehicleId == 0" class="arb-red"> Select vehicle to see if this part fits </span>
                        </div>
                      </div>
                    </div>
                    <div class="result-item" *ngIf="foundByOE(product)">
                      <div class="row">
                        <div style="padding-left: 15px" class="d-inline-flex">
                          <div class="result-item--brand arb-detail-text" [innerHtml]="product.brandName | highlightText : searchText"></div>
                          <div
                            class="result-item--brand arb-detail-text"
                            *ngIf="product.subBrand"
                            [innerHtml]="', ' + product.subBrand | highlightText : searchText"
                          ></div>
                        </div>
                        <div class="col-sm result-item--found-from text-right">Found from AOE : {{ product.referenceNumber }}</div>
                      </div>
                      <div class="row">
                        <div
                          class="col-sm result-item--product-name arb-detail-text-bold"
                          [innerHtml]="getProductResultText(product) | highlightText : searchText"
                        ></div>
                        <div class="col-sm result-item-vehiclefit" *ngIf="product.fittedVehicleIds">
                          <span *ngIf="product.isFitted">Fit your vehicle</span>
                          <span *ngIf="vehicleId == null || vehicleId == 0" class="arb-red"> Select vehicle to see if this part fits </span>
                        </div>
                      </div>
                    </div>
                    <div class="result-item" *ngIf="foundByTrade(product)">
                      <div class="row">
                        <div style="padding-left: 15px" class="d-inline-flex">
                          <div class="result-item--brand arb-detail-text" [innerHtml]="product.brandName | highlightText : searchText"></div>
                          <div
                            class="result-item--brand arb-detail-text"
                            *ngIf="product.subBrand"
                            [innerHtml]="', ' + product.subBrand | highlightText : searchText"
                          ></div>
                        </div>
                        <div class="col-sm result-item--found-from text-right">Found from Trade Number : {{ product.tradeNumber }}</div>
                      </div>
                      <div class="row">
                        <div
                          class="col-sm result-item--product-name arb-detail-text-bold"
                          [innerHtml]="getProductResultText(product) | highlightText : searchText"
                        ></div>
                        <div class="col-sm result-item-vehiclefit" *ngIf="product.fittedVehicleIds">
                          <span *ngIf="product.isFitted">Fit your vehicle</span>
                          <span *ngIf="vehicleId == null || vehicleId == 0" class="arb-red"> Select vehicle to see if this part fits </span>
                        </div>
                      </div>
                    </div>
                    <div class="result-item" *ngIf="foundByMetaTag(product)">
                      <div class="row">
                        <div style="padding-left: 15px" class="d-inline-flex">
                          <div class="result-item--brand arb-detail-text" [innerHtml]="product.brandName | highlightText : searchText"></div>
                          <div
                            class="result-item--brand arb-detail-text"
                            *ngIf="product.subBrand"
                            [innerHtml]="', ' + product.subBrand | highlightText : searchText"
                          ></div>
                        </div>
                        <div class="col-sm result-item--found-from text-right">Found by Tags: {{ product.metaKeyWords }}</div>
                      </div>
                      <div class="row">
                        <div
                          class="col-sm result-item--product-name arb-detail-text-bold"
                          [innerHtml]="getProductResultText(product) | highlightText : searchText"
                        ></div>
                        <div class="col-sm result-item-vehiclefit" *ngIf="product.fittedVehicleIds">
                          <span *ngIf="product.isFitted">Fit your vehicle</span>
                          <span *ngIf="vehicleId == null || vehicleId == 0" class="arb-red"> Select vehicle to see if this part fits </span>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </ng-scrollbar>
            </div>
            <div class="product-search-footer">
              <p-paginator
                [rows]="10"
                [totalRecords]="productResult.totalRecord"
                (onPageChange)="paginateProduct($event)"
                styleClass="paginator-search"
              ></p-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="radio-group">
      <div class="radio-control">
        <label for="productSearch" class="arb-field-label"> Product </label>
        <input type="radio" name="productSearch" class="toggle" [value]="false" [(ngModel)]="searchSparePart" (change)="doProductSearch()" />
      </div>
      <div class="radio-control">
        <label for="productSearch" class="arb-field-label"> Spare Parts </label>
        <input type="radio" name="productSearch" [value]="true" class="toggle" [(ngModel)]="searchSparePart" (change)="doProductSearch()" />
      </div>
    </div>
  </div>

  <div class="vehicle-search">
    <app-vehicle-search></app-vehicle-search>
  </div>
</div>
  