<div class="modal-header">
  <h3 class="arb-large-heading" id="modal-basic-title">OVERRIDE BRANCH</h3>
</div>
<div class="modal-body">
  <p>
    The branch on the {{ this.isConvertToOrder ? 'quote' : 'invoice' }} and your
    branch are different. Please confirm the branch to raise the
    {{ this.isConvertToOrder ? 'order' : 'credit' }}
    on.
  </p>
  <div>
    <ng-select [(ngModel)]="overridenBranch">
      <ng-option *ngFor="let branch of branchList" [value]="branch.BranchCode">
        <span class="pr-1" *ngIf="branch.BranchCode"
          >{{ branch.BranchCode }} -</span
        >
        {{ branch.BranchDescription }}
      </ng-option>
    </ng-select>
  </div>
  <div class="d-flex justify-content-end mt-2">
    <button class="btn text-white" (click)="onSelectBranch()">
      SELECT
    </button>
    <span class="px-2"></span>
    <button class="btn btn-cancel" (click)="onCancel()">
      CANCEL
    </button>
  </div>
</div>
