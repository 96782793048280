import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ProductRichContentDto } from '../../models/service-proxies';
import { ApiService } from '@pos-app/core-ui';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-product-fitting-instructions',
  templateUrl: './product-fitting-instructions.component.html',
  styleUrls: ['./product-fitting-instructions.component.scss'],
})
export class ProductProductFittingInstructionsComponent implements OnInit, OnDestroy {
  @Input() public fittingInstructions: ProductRichContentDto[] = [];
  @Input() public supplierUrl;
  @Input() public supersededProductNumber;

  public rowGroupMetadata: any;
  private sub = new Subscription()
  
  constructor(private apiService: ApiService) {}
  public ngOnInit(): void {
    this.updateRowGroupMetaData();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  public updateRowGroupMetaData(): void {
    this.rowGroupMetadata = {};

    if (this.fittingInstructions) {
      for (let i = 0; i < this.fittingInstructions.length; i++) {
        let rowData = this.fittingInstructions[i];
        let contentDescription = rowData.contentDescription;

        if (i == 0) {
          this.rowGroupMetadata[contentDescription] = { index: 0, size: 1 };
        } else {
          let previousRowData = this.fittingInstructions[i - 1];
          let previousRowGroup = previousRowData.contentDescription;
          if (contentDescription === previousRowGroup) this.rowGroupMetadata[contentDescription].size++;
          else this.rowGroupMetadata[contentDescription] = { index: i, size: 1 };
        }
      }
    }
  }

  public download(file: any): void {
    this.sub = this.apiService.saveFileAs(file.contentUrl, file.fileName).subscribe();
  }
}
