<pos-app-spinner [show]="isLoading"></pos-app-spinner>
<div>
  <h3 class="text-center py-3 arb-large-heading">STOCK AVAILABILITY</h3>
  <div class="row pb-3">
    <div class="col-3">
      <label>SEARCH PRODUCT</label>
    </div>
    <div class="col-9">
      <pos-app-fuzzy-search
        #fuzzySearch
        [apiEndpointKey]="'searchItems'"
        [apiRequestKey]="'searchString'"
        [valueKey]="'ProductCode'"
        [description1Key]="'ProductDescription1'"
        [description2Key]="'ProductDescription2'"
        [placeholder]="'ENTER PART CODE / DESCRIPTION'"
        [isAutoFocus]="isExternalUser ? true : false"
        (selectedItem)="loadItemAvailability($event)"
        (clearSearchValue)="clearSearchValue()"
      ></pos-app-fuzzy-search>
    </div>
  </div>
  <ng-container *ngIf="isShowTable">
    <div *ngIf="itemAvailability" class="pb-3">
      <label class="label">
        <span>AVAILABLE INVENTORY</span>
        <span *ngIf="atpBranch" class="label__atp">Estimated date for stock to arrive at {{atpBranch}} - {{atpDateDescription}}</span>
      </label>
      <table class="table table-striped table-bordered table-sm" *ngIf="allowToViewStock || isInternalUser; else nonViewStock">
        <thead>
          <tr>
            <th>STORE / DISTRIBUTION CENTRE</th>
            <th>AVAILABLE</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let availability of itemAvailability">
            <td>
              {{ availability.branchCode }}
              <b *ngIf="availability.branchName">-</b>
              {{ availability.branchName }}
            </td>
            <td>
              {{ displayAvailability(availability.qtyAvailable_Number, availability.qtyAvailable) }}
            </td>
          </tr>
        </tbody>
      </table>
      <ng-template #nonViewStock>
        <div>Not authorised to view stock levels</div>
      </ng-template>
    </div>
    <div *ngIf="itemPriceGroup">
      <label>PRICE SCALES</label>
      <pos-app-item-price-scales
        [isShowroomView]="isShowroomView"
        [isDisplayMyPrice]="isDisplayMyPrice"
        [item]="itemPriceGroup"
        [showDescription]="false"
        [displayOptions]="displayOptions"
        [isExternal]="true"
        [isInternal]="isInternalUser"
        [showCloseBtn]="false"
        *ngIf="displayOptions.displayExTax || displayOptions.displayIncTax || isInternalUser; else nonPriceScale"
      ></pos-app-item-price-scales>
      <ng-template #nonPriceScale>
        <div>Not authorised to view price scales</div>
      </ng-template>
    </div>
    <div class="col-sm">
      <button class="btn btn-cancel mb-3 mr-3 float-right" (click)="cancel()">CANCEL</button>
    </div>
  </ng-container>
</div>
