import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { DialogService } from './dialog.service';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  constructor(
    private apiService: ApiService,
    private dialogService: DialogService
  ) {}

  cancelActiveOrderConfirmation(
    message: string,
    orderNumber: string,
    quickSaveAllowed: boolean
  ) {
    return this.dialogService.confirm(
      message,
      'WARNING: UNSAVED ORDER',
      'YES',
      'NO',
      false,
      true,
      false,
      quickSaveAllowed
        ? {
            action: this.apiService.finaliseOrderToJDE({
              orderNumber: orderNumber,
              email: '',
              printerID: '',
              outputType: ''
            }),
            text: 'SAVE ORDER'
          }
        : null
    );
  }
}
