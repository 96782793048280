import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';


import {
  BasePagingOutputDtoOfCategorySearchOutputDto,
  BasePagingOutputDtoOfProductSearchOutputDto,
  BreadCrumbResponseDto,
  CriteriaFilterDto,
  DetailLevel,
  GetCurrentLoginInformationsOutput,
  GetFilterDataOutput,
  GetProductDetailInput,
  ListResultDtoOfCEDCategoryDto,
  PageContentStaticDto,
  ProductSearchInput,
  ProductSearchInputDto,
  SearchAllOutputDto,
} from '../models/service-proxies';
import { PriceAndStock, GetPriceAndStockInput } from '../models/price-and-stock.model';
import { GetProductDetails } from '../models/product-details';
import { EPC_API_ENDPOINTS } from '../constants/routes';
import { IProductPagedResult } from '../models/product-item.model';
import { ConfigService } from '@pos-app/core-ui';

@Injectable({
  providedIn: 'root',
})
export class EpcApiService {
  constructor(private config: ConfigService, private http: HttpClient) {}

  public getCEDCategories(): Observable<ListResultDtoOfCEDCategoryDto> {
    let headers = new HttpHeaders();
    const url = `${this.config.AppConfig.backendUrl}${EPC_API_ENDPOINTS.getCEDCategories}`;

    return this.http.get<ListResultDtoOfCEDCategoryDto>(url, { headers });
  }

  public getPageContentStatic(pageName: string | undefined): Observable<PageContentStaticDto> {
    let headers = new HttpHeaders();
    let url = `${this.config.AppConfig.backendUrl}${EPC_API_ENDPOINTS.getPageContentStatic}?`;

    if (pageName) url += 'pageName=' + encodeURIComponent('' + pageName) + '&';
    url = url.replace(/[?&]$/, '');

    return this.http.get<PageContentStaticDto>(url, { headers });
  }

  public getArbCurrentLoginInformations(): Observable<GetCurrentLoginInformationsOutput> {
    let headers = new HttpHeaders();
    const url = `${this.config.AppConfig.backendUrl}${EPC_API_ENDPOINTS.getArbCurrentLoginInformations}`;

    return this.http.get<GetCurrentLoginInformationsOutput>(url, { headers });
  }

  public getStaticContent(id: number | undefined): Observable<string> {
    let headers = new HttpHeaders();
    let url = `${this.config.AppConfig.backendUrl}${EPC_API_ENDPOINTS.getStaticContent}?`;

    if (id) url += 'id=' + encodeURIComponent('' + id) + '&';

    url = url.replace(/[?&]$/, '');

    return this.http.get<string>(url, { headers });
  }

  public getBreadCrumbLink(
    routerName: string | undefined,
    id: string | undefined,
    redirectUrl: string | undefined,
    originalCategoryId: string | undefined,
    brand: string | undefined,
    priceList: string | undefined
  ): Observable<BreadCrumbResponseDto[]> {
    let headers = new HttpHeaders();
    let url = `${this.config.AppConfig.backendUrl}${EPC_API_ENDPOINTS.getBreadCrumbLink}?`;

    if (routerName) url += 'RouterName=' + encodeURIComponent('' + routerName) + '&';
    if (id) url += 'Id=' + encodeURIComponent('' + id) + '&';
    if (redirectUrl) url += 'RedirectUrl=' + encodeURIComponent('' + redirectUrl) + '&';
    if (originalCategoryId) url += 'OriginalCategoryId=' + encodeURIComponent('' + originalCategoryId) + '&';
    if (brand) url += 'Brand=' + encodeURIComponent('' + brand) + '&';
    if (priceList) url += 'PriceList=' + encodeURIComponent('' + priceList) + '&';
    url = url.replace(/[?&]$/, '');

    return this.http.get<BreadCrumbResponseDto[]>(url, { headers });
  }

  public getCategoryFilterDataWithProductCount(body: ProductSearchInput | undefined): Observable<GetFilterDataOutput> {
    let headers = new HttpHeaders();
    let url = `${this.config.AppConfig.backendUrl}${EPC_API_ENDPOINTS.getCategoryFilterDataWithProductCount}`;

    return this.http.post<GetFilterDataOutput>(url, body, { headers });
  }

  public getProductList(body: ProductSearchInput | undefined): Observable<IProductPagedResult> {
    let headers = new HttpHeaders();
    let url = `${this.config.AppConfig.backendUrl}${EPC_API_ENDPOINTS.getProductList}`;

    return this.http.post<IProductPagedResult>(url, body, { headers });
  }

  public searchAll(body: ProductSearchInputDto | undefined): Observable<SearchAllOutputDto> {
    let headers = new HttpHeaders();
    let url = `${this.config.AppConfig.backendUrl}${EPC_API_ENDPOINTS.searchAll}`;

    return this.http.post<SearchAllOutputDto>(url, body, { headers });
  }

  public searchProduct(body: ProductSearchInputDto | undefined): Observable<BasePagingOutputDtoOfProductSearchOutputDto> {
    let headers = new HttpHeaders();
    let url = `${this.config.AppConfig.backendUrl}${EPC_API_ENDPOINTS.searchProduct}`;

    return this.http.post<BasePagingOutputDtoOfProductSearchOutputDto>(url, body, { headers });
  }

  public searchCategory(body: ProductSearchInputDto | undefined): Observable<BasePagingOutputDtoOfCategorySearchOutputDto> {
    let headers = new HttpHeaders();
    let url = `${this.config.AppConfig.backendUrl}${EPC_API_ENDPOINTS.searchCategory}`;

    return this.http.post<BasePagingOutputDtoOfCategorySearchOutputDto>(url, body, { headers });
  }

  public getProductDetail(body: GetProductDetailInput | undefined): Observable<GetProductDetails> {
    let headers = new HttpHeaders();
    let url = `${this.config.AppConfig.backendUrl}${EPC_API_ENDPOINTS.getProductDetail}`;
    return this.http.post<GetProductDetails>(url, body, { headers });
  }

  public getPriceAndStock(body: GetPriceAndStockInput | undefined): Observable<{ items: PriceAndStock[] }> {
    let headers = new HttpHeaders();
    let url = `${this.config.AppConfig.backendUrl}${EPC_API_ENDPOINTS.getPriceAndStock}`;

    return this.http.post<{ items: PriceAndStock[] }>(url, body, { headers });
  }

  public getConflictingProducts(
    productNumber: string,
    brand: string,
    priceList: string | undefined,
    mode: DetailLevel | undefined,
    vehicleId: number | undefined,
    showUniversalProducts: boolean | undefined
  ): Observable<string[]> {
    let headers = new HttpHeaders();
    let url = `${this.config.AppConfig.backendUrl}${EPC_API_ENDPOINTS.getConflictingProducts}?`;

    if (productNumber) url += 'ProductNumber=' + encodeURIComponent('' + productNumber) + '&';
    if (brand) url += 'Brand=' + encodeURIComponent('' + brand) + '&';
    if (priceList) url += 'PriceList=' + encodeURIComponent('' + priceList) + '&';
    if (mode) url += 'Mode=' + encodeURIComponent('' + mode) + '&';
    if (vehicleId) url += 'VehicleId=' + encodeURIComponent('' + vehicleId) + '&';
    if (showUniversalProducts) url += 'ShowUniversalProducts=' + encodeURIComponent('' + showUniversalProducts) + '&';
    url = url.replace(/[?&]$/, '');

    return this.http.get<string[]>(url, { headers });
  }

  public getGroupAttributesFilterList(
    categoryId: number | undefined,
    brand: string | undefined,
    priceList: string | undefined,
    vehicleId: number | undefined,
    showUniversalProducts: boolean | undefined
  ): Observable<CriteriaFilterDto[]> {
    let headers = new HttpHeaders();
    let url = `${this.config.AppConfig.backendUrl}${EPC_API_ENDPOINTS.getGroupAttributesFilterList}?`;

    if (categoryId) url += 'CategoryId=' + encodeURIComponent('' + categoryId) + '&';
    if (brand) url += 'Brand=' + encodeURIComponent('' + brand) + '&';
    if (priceList) url += 'PriceList=' + encodeURIComponent('' + priceList) + '&';
    if (vehicleId) url += 'VehicleId=' + encodeURIComponent('' + vehicleId) + '&';
    if (showUniversalProducts) url += 'ShowUniversalProducts=' + encodeURIComponent('' + showUniversalProducts) + '&';
    url = url.replace(/[?&]$/, '');

    return this.http.get<CriteriaFilterDto[]>(url, { headers });
  }
}
