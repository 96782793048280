import { Injectable, OnDestroy } from '@angular/core';
import { Store, Action } from '@ngrx/store';
import { CoreUiPartialState } from '../+state/core-ui/core-ui.reducer';
import { LayoutService } from './layout.service';
import { SaleService } from './sale.service';
import { Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

type ArbEvent = 'userStateUpdate' | 'orderUpdated' | 'landingPageAccessed' | 'postcodeUpdate' | 'productUpdated' | 'selectCedCategory';

@Injectable({
  providedIn: 'root',
})
export class MessageService implements OnDestroy {
  // tslint:disable-next-line: no-string-literal
  private sessionId = window['session'];
  private ch: BroadcastChannel;
  private arbEvent$ = new Subject<{ name: ArbEvent; value }>();

  constructor(private store: Store<CoreUiPartialState>, private layoutService: LayoutService, private saleService: SaleService) {
    console.log(`POS - START session: ${this.sessionId}`);
    this.ch = new BroadcastChannel(this.sessionId);
    this.ch.onmessage = (e) => {
      console.log(`POS - NEW message`, e);
      const message = JSON.parse(e.data);

      if (message.type) {
        console.log(`POS - dispatching action to local Redux Store`);
        this.store.dispatch(message);
      }
    };
  }

  ngOnDestroy(): void {
    console.log(`POS - CLOSE SESSION: ${this.sessionId}`);
    this.ch.close();
  }

  dispatchAction(action: Action) {
    console.log(`POS - SEND ACTION`);
    this.store.dispatch(action);
  }

  setIsNavBarHidden(isVisible: boolean) {
    this.layoutService.setIsNavBarHidden(isVisible);
  }

  setIsFooterHidden(isVisible: boolean) {
    this.layoutService.setIsFooterHidden(isVisible);
  }

  createQuickSale() {
    this.saleService.openQuickSale();
  }
  trigger(triggerEventName: ArbEvent, value?: any): void {
    this.arbEvent$.next({
      name: triggerEventName,
      value,
    });
  }

  listen(listenEventName: ArbEvent) {
    return this.arbEvent$.pipe(
      filter((event) => event.name === listenEventName),
      map((event) => event.value)
    );
  }
}
