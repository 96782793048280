<pos-app-spinner [show]="(isCreatingCustomer$ | async) || isCreatingCustomerForOrder"></pos-app-spinner>

<div *ngIf="!(duplicateCustomers$ | async) || (duplicateCustomers$ | async).length === 0">
  <app-customers-details-form
    [lookupLists]="lookupLists$ | async"
    [filteredStateList]="filteredStateList$ | async"
    [brandDefaults]="brandDefaults$ | async"
    [marketingOptInYNFromOrderValue]="marketingOptInYNFromOrder"
    (selectedCountry)="getFilteredStateList($event)"
    (customerChoosen)="customerChoosen($event)"
    (submitByEnter)="formValid && checkDuplicate()"
    (newCustomerEvent)="newCustomerEvent($event)"
    (formChanged)="formValue = $event"
    (formIsValid)="formValid = $event"
    (formIsDirty)="formDirtyHandler($event)"
  >
  </app-customers-details-form>
  <div class="row pt-3 pb-5 mx-1 float-right">
    <button class="btn btn-cancel mb-3" (click)="cancel()">CANCEL</button>
    <button class="btn text-white ml-0" [disabled]="!formValid" (click)="checkDuplicate()">SAVE</button>
  </div>
</div>

<app-customers-duplicate
  [setNewCustomerDetails]="newCustomerDetails"
  [setDuplicateCustomers]="duplicateCustomers$ | async"
  (customerChoosen)="customerChoosen($event)"
  (cancelled)="cancel()"
>
</app-customers-duplicate>
