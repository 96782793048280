import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { MatIconModule } from '@angular/material/icon';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { EffectsModule } from '@ngrx/effects';
import * as fromCoreUi from './+state/core-ui/core-ui.reducer';
import { CoreUiEffects } from './+state/core-ui/core-ui.effects';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import {
  NgbModalModule,
  NgbModule,
  NgbDateAdapter,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { Spinner2Component } from './components/spinner2/spinner2.component';
import { RouterModule } from '@angular/router';
import { AutofocusDirective } from './directives/autofocus.directive';
import { DialogComponent } from './components/dialog/dialog.component';
import { CustomersSearchComponent } from './components/customer-search/customer-search.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { BannerComponent } from './components/banner/banner.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import {
  CustomDateParserFormatter,
  CustomAdapter,
} from './utils/date-formater/date-formater';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { ClipboardModule } from 'ngx-clipboard';
import { ItemPriceScalesComponent } from './components/item-price-scales/item-price-scales.component';
import { FuzzySearchComponent } from './components/fuzzy-search/fuzzy-search.component';
import { DatePickerSingleComponent } from './components/date-picker-single/date-picker-single.component';
import { MatBadgeModule } from '@angular/material/badge';
import { ProductSpotlightComponent } from './components/product-spotlight/product-spotlight.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LocalizationModule } from './localization/localization.module';
import { CustomerTrailerSearchComponent } from './components/customer-trailer-search/customer-trailer-search.component';
import { AttachmentFilesNotesComponent } from './components/attachment-files-notes/attachment-files-notes.component';
const COMPS = [
  NavBarComponent,
  SpinnerComponent,
  CustomersSearchComponent,
  Spinner2Component,
  DialogComponent,
  AutofocusDirective,
  DatePickerComponent,
  DatePickerSingleComponent,
  BannerComponent,
  FeedbackComponent,
  ItemPriceScalesComponent,
  FuzzySearchComponent,
  ProductSpotlightComponent,
  CustomerTrailerSearchComponent,
  AttachmentFilesNotesComponent,
];
@NgModule({
  imports: [
    MatBadgeModule,
    ClipboardModule,
    CommonModule,
    NgbModule,
    FormsModule,
    MatIconModule,
    NgSelectModule,
    MatTooltipModule,
    RouterModule,
    ReactiveFormsModule,
    NgbModalModule,
    MatToolbarModule,
    MatTabsModule,
    MatButtonModule,
    StoreModule.forFeature(fromCoreUi.COREUI_FEATURE_KEY, fromCoreUi.reducer),
    EffectsModule.forFeature([CoreUiEffects]),
    LocalizationModule,
    AngularEditorModule,
  ],
  declarations: COMPS,
  exports: COMPS,
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class CoreUiModule {}
