<div class="card">
  <div class="card-body">
    <h5>ADDITIONAL FILTERS</h5>
    <div class="filterForm">
      <form [formGroup]="form">
        <div class="row" formArrayName="params">
          <div class="col-12">
            <div class="row" >
              <div *ngFor="let item of Params; let i = index" [formGroupName]="i" [ngClass] = "item.controls.config.value.type==='boolean' ? 'col-3' : 'col-6'">
                <div class="row pb-2">
                  <div class="font-weight-bold filter-label" [ngClass]="item.controls.config.value.type==='boolean' ? 'col-4' : 'col-3'">
                    <label>{{ item.controls.label.value }}</label>
                  </div>
                  <div class="filter-content" [ngClass]="item.controls.config.value.type==='boolean' ? 'col-1' : 'col-8'">
                    <app-dynamic-form-control
                      [config]="item.controls.config.value"
                      [control]="item.controls.value"
                    >
                    </app-dynamic-form-control>
                  </div>
                  <div class="filter-delete" [ngClass]="item.controls.config.value.type==='boolean' ? 'col-7' : 'col-1'">
                    <mat-icon class="text-danger" (click)="removeFilter(item)">delete</mat-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-5">
            <select class="form-control" (change)="onAddFilter($event)" [disabled]="isMaxFilter()">
              <option value="">Add new filter...</option>
              <option *ngFor="let filterType of AvailableFilters" [value]="filterType.value">{{
                filterType.name
              }}</option>
            </select>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
