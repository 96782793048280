import {
  CellClickedEvent,
  CellEditingStoppedEvent,
  ColumnApi,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowDragEvent,
  RowDragLeaveEvent,
} from 'ag-grid-community';
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  LOCALE_ID,
  Inject,
  TemplateRef,
  AfterViewInit,
} from '@angular/core';
import { ApiService, DialogService, ElectronService } from '@pos-app/core-ui';
import { OrderGridActionRendererComponent } from '../orderGridActionRenderer/orderGridActionRender.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { takeUntil, switchMap, map, finalize, take } from 'rxjs/operators';
import { Subject, of } from 'rxjs';
import { cloneDeep, isEqual } from 'lodash';
import { StagingOrderLine, OrderHeader, OrderMessage, Buyin, ItemToAdd, ViewSettings } from '../../+state/orders.models';
import { CheckboxCellComponent } from '../orderGridCheckboxCellRenderer/orderGridCheckboxCellRenderer.component';
import { ColourCellComponent } from '../orderGridColourCellRenderer/orderGridColourCellRenderer.component';
import {
  ASSOCIATION_TYPE,
  BrandDefaults,
  DisplayOptions,
  ItemPrice,
  ItemPriceGroup,
  KEYS,
  ORDER_ITEM_NUMBER,
  ORDER_LINE_STATUS,
  ORDER_TYPE,
  roundingNumber,
  SECURITY_FUNCTION,
} from '@pos-app/data';
import { FormControl, Validators } from '@angular/forms';
import { getGridDef } from './order-grid-helper';
import { OrderGridActionHeaderComponent } from '../orderGridActionHeader/orderGridActionHeader.component';
import { SecureCatalogueService } from 'libs/core-ui/src/lib/services/secure-catelogue.service';
import { ActivatedRoute } from '@angular/router';
import { formatNumber, getCurrencySymbol } from '@angular/common';
import { OrderLoadingCellRendererComponent } from '../order-loading-cell-renderer/order-loading-cell-renderer.component';
import { TextCellEditorComponent } from '../orderGridCellEditorRenderer/textCellEditor.component';
import { AgGridAngular } from 'ag-grid-angular';
import { OrdersResponseService } from '../../+state/orders.response';
import { OrderItemNumberCellRendererComponent } from '../order-item-number-cell-renderer/order-item-number-cell-renderer.component';

@Component({
  selector: 'app-order-grid',
  templateUrl: './order-grid.component.html',
  styleUrls: ['./order-grid.component.scss'],
})
export class OrderGridComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @ViewChild('content') modalBox: TemplateRef<any>;
  @ViewChild('lineAttachmentContent') lineAttachmentModalBox: TemplateRef<any>;
  @ViewChild('orderGrid') grid!: AgGridAngular;

  @Input() gridResult: StagingOrderLine[];
  @Input() orderHeader: OrderHeader;
  @Input() orderMessages: OrderMessage[];
  @Input() isExternalUser: boolean;
  @Input() isShowUser: boolean;
  @Input() cashDrawer: boolean;
  @Input() isCreditOrder: boolean;
  @Input() isConvertToOrder: boolean;
  @Input() selectedCreditReason: string;
  @Input() cancelAllLinesAllowed: boolean;
  @Input() displayOptions: DisplayOptions;
  @Input() brandDefaults: BrandDefaults;
  @Input() userSecurityFunctions: string[];
  @Input() isOrderValidated: boolean;
  @Input() depositAmount: number;
  @Input() isUpdatingStagingCompleted: boolean;
  @Input() viewSettings: ViewSettings = { enforceItemRule: true };
  @Input() dispatchList: StagingOrderLine[] = [];
  @Input() creditList: StagingOrderLine[] = [];
  @Input() orderLoading: boolean;

  @Output() lineUpdated = new EventEmitter<void>();
  @Output() cellEdited = new EventEmitter<any>();
  @Output() deletingAllItems = new EventEmitter();
  @Output() copyOrder = new EventEmitter();
  @Output() dispatchAll = new EventEmitter<{ changeLineStatus: string; isAllDispatched: boolean }>();
  @Output() dispatchedItems = new EventEmitter<{ listData: StagingOrderLine[]; changeLineStatus: string }>();
  @Output() viewSettingsChange = new EventEmitter<ViewSettings>();
  @Output() showDetailedAvailability = new EventEmitter<any>();
  @Output() isCellStartEditing = new EventEmitter<boolean>();
  @Output() hasInvalidItem = new EventEmitter<boolean>();
  @Output() addItemsToOrder = new EventEmitter<{ items: ItemToAdd[]; enforceItemRule: boolean }>();
  @Output() enableValidateOrder = new EventEmitter<boolean>();
  @Output() loadStagingOrder = new EventEmitter<void>();

  unSubscribe$ = new Subject<void>();
  gridOptions: GridOptions;
  columnApi: ColumnApi;
  frameworkComponents = {
    actionRenderer: OrderGridActionRendererComponent,
    checkboxRenderer: CheckboxCellComponent,
    colourRenderer: ColourCellComponent,
    actionHeader: OrderGridActionHeaderComponent,
    orderLoadingCellRenderer: OrderLoadingCellRendererComponent,
    textCellEditorComponent: TextCellEditorComponent,
    itemNumberCellRenderer: OrderItemNumberCellRendererComponent,
  };
  rowData: StagingOrderLine[] = [];
  gridApi: GridApi;
  rowClassRules;
  isThinking = false;
  discount = new FormControl('', [Validators.required]);
  showPriceScales = false;
  itemPricesToShow: ItemPriceGroup;
  itemSearchStr: string;
  addingBuyin: boolean;
  editingBuyin: StagingOrderLine;
  isAddingSubtotal: boolean;
  SEE_BUY_PRICE = SECURITY_FUNCTION.seeBuyPrice;
  ORDER_TYPE = ORDER_TYPE;
  orderDetails;
  orderNumber: string;
  isReadOnlyLineAttachment: boolean;
  enableResetPrice: boolean = false;
  defaultBranch = JSON.parse(localStorage.getItem(KEYS.jdeSession)).DefaultBranch;
  previousRowCount: number;

  getRowNodeId = (data) => {
    return data.ooeLineNumber;
  };

  get isReadOnly() {
    return this.orderHeader && (this.orderHeader?.readOnlyYN === 'Y' || this.orderHeader.readOnlyDetailYN === 'Y');
  }

  get isGridReadOnly() {
    return this.orderHeader && this.orderHeader.readOnlyDetailYN === 'Y';
  }

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private apiService: ApiService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private dialogService: DialogService,
    private secureCatalogueService: SecureCatalogueService,
    private route: ActivatedRoute,
    private ordersResponseService: OrdersResponseService,
    private electronService: ElectronService
  ) {}

  ngOnInit() {
    this.orderNumber = this.route.snapshot.params.id;
    this.refreshGridOptions();

    this.rowClassRules = {
      'subtotal-row': (params) => {
        return params.data.itemNumber === ORDER_ITEM_NUMBER.subTotal;
      },
      'invoiced-row': (params) => {
        return params.data.lineStatus === ORDER_LINE_STATUS.invoiced;
      },
      'cancelled-row': (params) => {
        return params.data.lineStatus === ORDER_LINE_STATUS.cancelled || params.data.changeLineStatus === ORDER_LINE_STATUS.cancelled;
      },
    };
  }

  ngAfterViewInit(): void {
    if (this.grid) {
      this.gridApi = this.grid.api;
      this.columnApi = this.grid.columnApi;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    /* to make sure gridApi ready */
    const interval = setInterval(() => {
      if (this.gridApi && this.columnApi) {
        clearInterval(interval);
        if (changes.gridResult?.currentValue) {
          this.gridResult.forEach((item) => {
            if (item.overridePriceYN === 'Y' && item.readOnlyLineYN === 'N') {
              this.enableResetPrice = true;
            }
            if (item.validateRequiredYN === 'Y' && item.lineValidatedYN === 'N') {
              this.enableValidateOrder.emit(true);
            }
          });

          this.rowData = cloneDeep(this.gridResult);

          if (!this.isReadOnly) {
            this.rowData.push({ itemNumber: ORDER_ITEM_NUMBER.newItem });
          }

          /* set data to grid */
          const renderedNodes = this.gridApi.getRenderedNodes() || [];
          const updatedOrderLines = this.rowData.filter((row) => row.isFetchUpdatedOrderLine);

          if (updatedOrderLines?.length > 0) {
            renderedNodes.forEach((node) => {
              const nodeData = node.data;
              const lineReadyForUpdate = updatedOrderLines.find((line) => line.id === nodeData.id);
              if (nodeData.id && nodeData.updatingRow?.trim() && lineReadyForUpdate) {
                node.setData(lineReadyForUpdate);
              }
            });
          } else {
            this.gridApi.setRowData(this.rowData);
            this.gridApi.redrawRows();
          }
        }

        if (changes.cashDrawer) {
          this.gridApi.refreshCells({ columns: ['action'] });
          this.gridApi.refreshHeader();
        }

        if (changes.orderMessages) {
          this.gridApi.forEachNode((rowNode) => {
            const messages = this.orderMessages.filter((mess) => mess.lineNumber === rowNode.data.ooeLineNumber);

            if (rowNode.data && messages?.length) {
              rowNode.setRowHeight(26 * (messages?.length || 1));
              this.gridApi.onRowHeightChanged();
              this.columnApi.autoSizeColumn('messages');
            }
          });
        }

        if (changes.orderHeader?.currentValue) {
          if (this.columnApi && this.displayOptions.displayCurrency && this.isExternalUser) {
            const unitPricecolDef = this.columnApi.getColumn('unitPrice').getColDef();
            const extPricecolDef = this.columnApi.getColumn('extPrice').getColDef();
            unitPricecolDef.headerName += ` (${getCurrencySymbol(this.orderHeader.currencyCode, 'narrow')})`;
            extPricecolDef.headerName += ` (${getCurrencySymbol(this.orderHeader.currencyCode, 'narrow')})`;
            this.gridApi.refreshHeader();
          }
        }

        if (changes.viewSettings && !isEqual(changes.viewSettings.previousValue, changes.viewSettings.currentValue)) {
          this.columnApi.setColumnVisible('atpDescription', this.viewSettings.showATP);
          this.columnApi.setColumnVisible('availability', !this.viewSettings.showATP);
        }
      } else {
        console.error('gridApi undefined');
      }
    }, 15);
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  onGridReady(params: GridReadyEvent) {
    if (!this.gridApi) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    }

    this.columnApi.setColumnVisible('tax', false);
  }

  refreshGridOptions(): void {
    this.gridOptions = getGridDef(
      this.secureCatalogueService.getIsCatalogueVisible(),
      this,
      this.isExternalUser && !this.userSecurityFunctions.includes(this.SEE_BUY_PRICE) && this.orderHeader?.orderType !== ORDER_TYPE.quoteOrder
    );
  }

  refreshOrderGrid() {
    this.loadStagingOrder.emit();
  }

  onDataRendered(): void {
    this.gridApi.onRowHeightChanged();

    if (this.gridApi && this.columnApi) {
      this.gridApi.refreshCells({
        columns: ['action', 'unitPrice', 'extPrice', 'colour', 'bo', 'fit', 'availability', 'messages', 'atpDescription'],
        force: true,
      });

      this.startEditingCodeInNewRow();
    }

    this.isAddingSubtotal = false;
  }

  startEditingCodeInNewRow(): void {
    const currentRenderedNodes = this.gridApi.getRenderedNodes();
    if (this.previousRowCount < currentRenderedNodes.length || this.electronService.isElectron) {
      /* Add new item */
      setTimeout(() => {
        const lastNode = currentRenderedNodes[currentRenderedNodes.length - 1];
        if (lastNode.data['itemNumber'] === ORDER_ITEM_NUMBER.newItem && !this.gridApi.getEditingCells()?.length) {
          this.gridApi.clearRangeSelection();
          this.gridApi.clearFocusedCell();

          this.gridApi.startEditingCell({
            rowIndex: lastNode.rowIndex,
            colKey: 'itemNumber',
            keyPress: 46,
          });

          this.previousRowCount = currentRenderedNodes.length;
        }
      }, 200);
    }
  }

  onCellEditingStarted() {
    this.isCellStartEditing.emit(true);
  }

  onCellEditingStopped(row: CellEditingStoppedEvent) {
    this.isCellStartEditing.emit(false);

    const itemNumber = row.data.itemNumber?.trim();

    /* Add new item */
    if (!row.data.orderNumber && itemNumber) {
      /* add a subTotal line */
      if (itemNumber.toUpperCase() === ORDER_ITEM_NUMBER.subTotal) {
        if (row.column.getColId() === 'itemNumber') {
          row.node.setData({
            itemNumber: ORDER_ITEM_NUMBER.subTotal,
            itemDescription: 'ENTER SUBTOTAL DESCRIPTION',
          });
          this.gridApi.setFocusedCell(row.rowIndex, 'itemDescription');

          row.api.startEditingCell({
            rowIndex: row.rowIndex,
            colKey: 'itemDescription',
          });
        } else if (row.column.getColId() === 'itemDescription') {
          if (row.data?.parentLineNumber) {
            this.insertSubTotal(row.data, this.rowData[row.rowIndex - 1]?.ooeLineNumber);
          } else {
            this.addItemsToOrder.emit({
              items: [
                {
                  itemNumber: itemNumber,
                  quantity: 1,
                  price: null,
                  itemDescription: row.data.itemDescription,
                },
              ],
              enforceItemRule: this.viewSettings.enforceItemRule,
            });
          }
        }
      } else if (itemNumber.toUpperCase() === ORDER_ITEM_NUMBER.buyin) {
        /* 
          Add a buyin line
          buyin does not be used on sales quotes
        */
        if (this.orderHeader?.orderType === ORDER_TYPE.quoteOrder) {
          this.toastr.error('ERROR - BUYIN cannot be used on quotes');
        } else {
          this.addingBuyin = true;
          this.gridApi.startEditingCell({
            rowIndex: row.rowIndex,
            colKey: 'itemNumber',
            keyPress: 46,
          });
        }
      } else if (itemNumber !== ORDER_ITEM_NUMBER.newItem) {
        /* add a new line */
        if (row.column.getColId() === 'itemNumber' && !row.api.getEditingCells()?.length) {
          /* adding from code column */
          this.addItemToOrder(row);
        } else if (row.column.getColId() === 'qtyOrdered') {
          if (!!Number(row.data.qtyOrdered)) {
            /* adding from QTY column */
            this.addItemToOrder(row);
          }
        }
      }
    } else if (!itemNumber) {
      // if there's no item number, reset fields
      row.data.itemNumber = ORDER_ITEM_NUMBER.newItem;
      this.gridApi.refreshCells({ columns: ['itemNumber'] });
      this.hasInvalidItem.emit(false);
    }
  }

  addItemToOrder(row: CellEditingStoppedEvent): void {
    this.addItemsToOrder.emit({
      items: [
        {
          itemNumber: row.data.itemNumber?.trim(),
          quantity: row.data.qtyOrdered || (this.isCreditOrder ? -1 : 1),
          price: null,
        },
      ],
      enforceItemRule: this.viewSettings.enforceItemRule,
    });
    row.node.setData({ ...row.node.data, rowLoading: true });
    this.ordersResponseService.addItemsToOrderFailed$.pipe(take(1)).subscribe(() => {
      row.node.setData({ ...row.node.data, rowLoading: false });
      row.api.clearFocusedCell();
      row.api.clearRangeSelection();

      row.api.setFocusedCell(row.rowIndex, 'itemNumber');
    });

    /* handle focusing on ENTER ITEM CODE cell in new row. */
    this.previousRowCount = this.gridApi.getRenderedNodes().length;
  }

  onCellClicked(row: CellClickedEvent) {
    if (row.colDef.field === 'itemNumber' && !this.isReadOnly) {
      if (row.data.itemNumber === ORDER_ITEM_NUMBER.newItem || !row.data.orderNumber) {
        this.gridApi.startEditingCell({
          rowIndex: row.rowIndex,
          colKey: 'itemNumber',
          // press delete (46) to clear new item text, or enter the existing invalid item code to bring the cursor to the end
          keyPress: row.data.itemNumber === ORDER_ITEM_NUMBER.newItem ? 46 : null,
          charPress: row.data.itemNumber !== ORDER_ITEM_NUMBER.newItem ? row.data.itemNumber : null,
        });
      }
      return;
    }

    if (
      row.data.orderNumber &&
      (row.colDef.colId === 'availability' || row.colDef.colId === 'atpDescription') &&
      row.data.nonStockLineYN !== 'Y' &&
      row.data.availability.length > 0 &&
      !this.isExternalUser &&
      !this.isShowUser
    ) {
      this.showDetailedAvailability.emit(row.data);
    }
  }

  onCellDoubleClicked(row) {
    if (row.data.readOnlyLineYN === 'Y' || this.isReadOnly) {
      return;
    }
    this.gridApi.startEditingCell({
      rowIndex: row.rowIndex,
      colKey: row.colDef.field,
    });
  }

  attachItem(cell, isReadOnly) {
    this.modalService.open(this.lineAttachmentModalBox, {
      size: 'xl',
    });
    this.orderDetails = cell.data;
    this.isReadOnlyLineAttachment = isReadOnly;
  }

  deleteItem(cell) {
    // if it's the last remaining line, check prepayment amount > invoiced amount
    const isNewLine = cell.data.lineStatus.trim() === ORDER_LINE_STATUS.new;
    const numberLineRemain = this.gridResult.filter(
      (item) => item.lineStatus !== ORDER_LINE_STATUS.invoiced && item.lineStatus !== ORDER_LINE_STATUS.cancelled
    ).length;

    if (!this.orderHeader.orderNumber.startsWith('T') && numberLineRemain === 1 && !this.cancelAllLinesAllowed) {
      this.toastr.error('Cannot cancel order while outstanding deposits are on the order');
      return;
    }

    this.dialogService
      .confirm(
        `Select YES to ${isNewLine ? 'delete' : 'cancel'} this line from the order or select NO to keep this line.`,
        `WARNING: ${isNewLine ? 'DELETE' : 'CANCEL'} LINE`
      )
      .pipe(
        switchMap((val) => {
          if (val) {
            if (isNewLine) {
              return this.apiService.removeItemFromOrder({
                orderNumber: this.orderHeader.orderNumber,
                orderLine: cell.data.ooeLineNumber,
                id: cell.data.id,
              });
            } else {
              // If it's an open JDE line, update line status to CANCELLED instead of deleting it
              this.cancelLine(cell.data, cell.rowIndex);
              return of(null);
            }
          }
          return of(null);
        })
      )
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((res) => {
        if (res) {
          this.lineUpdated.emit();
        }
      });
  }

  cancelLine(data: StagingOrderLine, index: number) {
    const updatedItem = {
      ...data,
      lineStatus: ORDER_LINE_STATUS.cancelled,
      changeLineStatus: ORDER_LINE_STATUS.cancelled,
    };
    this.cellEdited.emit({
      index,
      data: updatedItem,
    });
    if (this.isUpdatingStagingCompleted) {
      this.removeAssociatedItem(data.ooeLineNumber);
    }
  }

  deleteAllItems() {
    if (this.gridResult.length > 0) {
      this.deletingAllItems.emit();
    }
  }

  searchingItem(searchStr: string) {
    this.itemSearchStr = searchStr === ORDER_ITEM_NUMBER.newItem ? '' : searchStr;
    this.modalService.open(this.modalBox);
  }

  addSearchedItem(item) {
    this.modalService.dismissAll();
    if (item.itemCode) {
      this.addItemsToOrder.emit({
        items: [{ itemNumber: item.itemCode, quantity: item.quantity, price: null }],
        enforceItemRule: this.viewSettings.enforceItemRule,
      });
    }
  }

  processDataFromClipboard = (params) => {
    const itemCodes = [];
    params.data.forEach((line) => {
      if (line[0] !== '') {
        // multiple line format supported
        switch (line.length) {
          // line contains only item code
          case 1:
            itemCodes.push({ itemNumber: line[0]?.trim(), quantity: 1, price: null });
            break;
          // line contains item code and quantity
          case 2:
            itemCodes.push({
              itemNumber: line[0]?.trim(),
              quantity: line[1],
              price: null,
            });
            break;
          // line contains item code, quantity and override price
          case 3:
            itemCodes.push({
              itemNumber: line[0]?.trim(),
              quantity: line[1],
              price: line[2],
            });
            break;
          default:
            itemCodes.push({ itemNumber: line[0]?.trim(), quantity: 1, price: null });
        }
      }
    });
    this.addItemsToOrder.emit({
      items: itemCodes,
      enforceItemRule: this.viewSettings.enforceItemRule,
    });
  };

  viewSettingsToggle(setting: string) {
    switch (setting) {
      case 'exPrice':
        this.viewSettings.showExPrice = !this.viewSettings.showExPrice;
        this.gridApi.refreshCells({
          force: true,
          columns: ['unitPrice', 'extPrice'],
        });
        this.columnApi.getColumn('unitPrice').getColDef().headerName = 'UPRICE' + (this.viewSettings.showExPrice ? '-EX' : '');
        this.columnApi.getColumn('extPrice').getColDef().headerName = 'PRICE' + (this.viewSettings.showExPrice ? '-EX' : '');
        this.gridApi.refreshHeader();

        break;
      case 'cancelled':
        this.viewSettings.showCancelled = !this.viewSettings.showCancelled;
        break;
      case 'invoiced':
        this.viewSettings.showInvoiced = !this.viewSettings.showInvoiced;
        break;
      case 'itemRule':
        this.viewSettings.enforceItemRule = !this.viewSettings.enforceItemRule;
        this.gridApi.refreshCells({
          force: true,
          columns: ['colour'],
        });
        this.viewSettingsChange.emit({
          enforceItemRule: this.viewSettings.enforceItemRule,
        });
        break;
      case 'tax':
        this.viewSettings.showTaxFlag = !this.viewSettings.showTaxFlag;
        this.columnApi.setColumnVisible('tax', this.viewSettings.showTaxFlag);
        break;
      case 'atp':
        this.viewSettings.showATP = !this.viewSettings.showATP;
        this.columnApi.setColumnVisible('atpDescription', this.viewSettings.showATP);
        this.columnApi.setColumnVisible('availability', !this.viewSettings.showATP);
        break;
      default:
        break;
    }

    if (setting === 'invoiced' || setting === 'cancelled') {
      this.viewSettingsChange.emit({
        showCancelled: this.viewSettings.showCancelled,
        showInvoiced: this.viewSettings.showInvoiced,
      });
    }
  }

  handleFitPaintToggle(updateItemOnOrder$, isAdding, ooeLineNumber) {
    if (isAdding) {
      this.isThinking = true;
      updateItemOnOrder$
        .pipe(
          finalize(() => (this.isThinking = false)),
          takeUntil(this.unSubscribe$)
        )
        .subscribe((res) => {
          if (res) {
            this.lineUpdated.emit();
          }
        });
    } else {
      this.removeAssociatedItem(ooeLineNumber, ASSOCIATION_TYPE.fit, updateItemOnOrder$);
    }
  }
  changeFitOption(cell, ticked) {
    this.handleFitPaintToggle(
      this.apiService.updateItemOnOrder({
        orderNumber: this.orderHeader.orderNumber,
        id: cell.data.id,
        fittedYN: ticked ? 'Y' : 'N',
        v2ModeYN: 'Y',
      }),
      ticked,
      cell.data.ooeLineNumber
    );
  }

  changeColourOption(cell, colourCode) {
    this.handleFitPaintToggle(
      this.apiService.updateItemOnOrder({
        orderNumber: this.orderHeader.orderNumber,
        id: cell.data.id,
        paintYN: colourCode !== ' ' ? 'Y' : 'N',
        colourCode: colourCode !== ' ' ? colourCode : ' ',
        v2ModeYN: 'Y',
      }),
      colourCode !== ' ',
      cell.data.ooeLineNumber
    );
  }

  // Remove fit or paint item
  removeAssociatedItem(ooeLineNumber, lineType = null, updateItemOnOrder$ = of({})) {
    const associatedItems = this.rowData.filter(
      (x) => x.parentLineNumber === ooeLineNumber && (lineType ? x.associationType.toUpperCase() === lineType : true)
    );

    if (associatedItems.length > 0) {
      this.isThinking = true;
    }
    updateItemOnOrder$
      .pipe(
        finalize(() => (this.isThinking = false)),
        takeUntil(this.unSubscribe$)
      )
      .subscribe(() => {
        this.lineUpdated.emit();
      });
  }

  displayPrice(price: number) {
    if (!price) {
      return '';
    }
    const priceStr = '' + price;
    const decimalPoint = priceStr.indexOf('.') === -1 || priceStr.split('.')[1].length <= 2 ? 2 : 4;
    return '' + formatNumber(Number(price.toFixed(decimalPoint)), this.locale);
  }

  onRowDragEnd(event: RowDragEvent) {
    const newLineNumber =
      event.overIndex === this.rowData.length - 1
        ? this.rowData[event.overIndex - 1].ooeLineNumber // if drag over the ENTER ITEM CODE
        : this.rowData[event.overIndex].ooeLineNumber;
    const oldLineNumber = event.node.data.ooeLineNumber;

    if (Math.round(newLineNumber) !== Math.round(oldLineNumber)) {
      this.apiService
        .reSequenceLines({
          orderNumber: this.orderHeader.orderNumber,
          oldLineNumber,
          newLineNumber,
        })
        .pipe(takeUntil(this.unSubscribe$))
        .subscribe((res) => {
          if (res) {
            this.lineUpdated.emit();
          }
        });

      this.gridApi.refreshCells({ columns: ['action'] });
    } else {
      this.gridApi.setRowData(this.rowData);
      this.gridApi.redrawRows();
    }
  }

  onRowDragLeave(event: RowDragLeaveEvent): void {
    this.gridApi.setRowData(this.rowData);
    this.gridApi.redrawRows();
  }

  getDataPath(data) {
    if (data.parentLineNumber !== undefined) {
      return data.parentLineNumber === 0 ? [data.ooeLineNumber] : ('' + data.ooeLineNumber).split('.');
    } else {
      return ['0'];
    }
  }

  addSubTotal(cell) {
    let subTotalInc;
    let subTotalExc;
    let subStartIndex = 0;
    // Check if item has associated line
    const associatedItems = this.rowData.filter((x) => x.parentLineNumber === cell.data.ooeLineNumber);

    const subEndIndex = cell.rowIndex + associatedItems.length;
    for (let i = subEndIndex; i--; i === 0) {
      if (this.rowData[i].itemNumber === ORDER_ITEM_NUMBER.subTotal) {
        subStartIndex = i + 1;
        break;
      }
    }
    const subArray = this.rowData
      .slice(subStartIndex, subEndIndex + 1)
      .filter((x) => x.lineStatus !== ORDER_LINE_STATUS.cancelled && x.lineStatus !== ORDER_LINE_STATUS.invoiced);

    subTotalInc = subArray.map((x) => x.extPriceInc).reduce((a, b) => a + b);
    subTotalExc = subArray.map((x) => x.extPriceEx).reduce((a, b) => a + b);
    this.rowData.splice(subEndIndex + 1, 0, {
      // put subtotal as a child of current item
      ooeLineNumber: cell.data.ooeLineNumber + 0.00999,
      itemNumber: ORDER_ITEM_NUMBER.subTotal,
      itemDescription: 'ENTER SUBTOTAL DESCRIPTION',
      extPriceInc: +roundingNumber(subTotalInc, 2),
      extPriceEx: +roundingNumber(subTotalExc, 2),
      parentLineNumber: cell.data.ooeLineNumber,
    });
    this.isAddingSubtotal = true;
    this.gridApi.setRowData(this.rowData);

    if (!this.isReadOnly) {
      setTimeout(() => {
        this.gridApi.startEditingCell({
          rowIndex: subEndIndex + 1,
          colKey: 'itemDescription',
        });
      }, 200);
    }
  }

  insertSubTotal(subTotalItem, lineNumber) {
    const itemToInsert = {
      orderNumber: this.orderHeader.orderNumber,
      itemCode: subTotalItem.itemNumber,
      itemDesc: subTotalItem.itemDescription ? subTotalItem.itemDescription : 'ENTER SUBTOTAL DESCRIPTION',
      extPriceExTax: subTotalItem.extPriceEx,
      extPriceIncTax: subTotalItem.extPriceInc,
      insertLineAfter: lineNumber,
    };
    this.isThinking = true;
    this.apiService
      .insertLineAfter(itemToInsert)
      .pipe(
        finalize(() => (this.isThinking = false)),
        takeUntil(this.unSubscribe$)
      )
      .subscribe((res) => {
        this.isThinking = false;
        if (res) {
          this.lineUpdated.emit();
        }
      });
  }

  availabilityGrid(data: StagingOrderLine) {
    if (data.availability?.length > 0 && data.id && data.itemNumber !== ORDER_ITEM_NUMBER.subTotal) {
      const quantityOrdered = data.qtyOrdered;
      let availabilityGrid = '';
      data.availability.forEach((item, index) => {
        if (index !== 0) {
          availabilityGrid += '/';
        }
        const qtyAvailable = this.displayNumber(item.qtyAvailable);
        availabilityGrid += this.getAvailabilityElement(qtyAvailable, quantityOrdered);
      });
      return availabilityGrid;
    }

    return '';
  }

  getAvailabilityColourClass(availableNumber, quantityOrder) {
    if (availableNumber <= 0) {
      return 'order-text-red';
    }
    return availableNumber >= quantityOrder ? 'order-text-green' : 'order-text-orange';
  }

  getAvailabilityElement(availableNumber, quantityOrder) {
    return `
      <span class="${this.getAvailabilityColourClass(availableNumber, quantityOrder)}">
      ${availableNumber}
      </span>`;
  }

  displayNumber(numberStr: any) {
    numberStr = ('' + numberStr).replace(',', '');
    return isNaN(+numberStr) ? numberStr : +numberStr;
  }

  priceOverride(params) {
    params.data = { ...params.data };
    let newPrice = 0;
    let discount = 0;
    const oldPrice = this.viewSettings.showExPrice ? params.data['unitPriceEx'] : params.data['unitPriceInc'];

    let newUnitPriceEx = 0;
    let newUnitPriceInc = 0;

    if (params.newValue !== '') {
      const enteredPrice = params.newValue;
      if (enteredPrice.endsWith('%') || !isNaN(enteredPrice)) {
        // Normal price
        if (!enteredPrice.endsWith('%')) {
          newPrice = enteredPrice;
          discount = oldPrice !== 0 ? 1 - +enteredPrice / oldPrice : null;
        } else {
          // Percentage discount
          discount = +enteredPrice.slice(0, -1) / 100;
          newPrice = oldPrice * (1 - discount);
        }

        newUnitPriceEx = this.viewSettings.showExPrice
          ? newPrice
          : params.data['unitPriceEx'] !== 0
          ? params.data['unitPriceEx'] * (1 - discount)
          : newPrice / (1 + this.orderHeader.customerTaxRate / 100);
        newUnitPriceInc = !this.viewSettings.showExPrice
          ? newPrice
          : params.data['unitPriceInc'] !== 0
          ? params.data['unitPriceInc'] * (1 - discount)
          : newPrice * (1 + this.orderHeader.customerTaxRate / 100);

        if (params.data.taxYN !== 'Y') {
          if (this.viewSettings.showExPrice) {
            newUnitPriceInc = newUnitPriceEx;
          } else {
            newUnitPriceEx = newUnitPriceInc;
          }
        }

        if (params.data['itemNumber'].toUpperCase() === ORDER_ITEM_NUMBER.giftCard) {
          newUnitPriceInc = newPrice;
          newUnitPriceEx = newPrice;
        }
        return of({
          newUnitPriceEx,
          newUnitPriceInc,
          overridePriceYN: 'Y',
        });
      } else {
        // get price from price schedule codes
        this.isThinking = true;
        return this.apiService
          .getItemPriceGroup({
            customerNumber: this.orderHeader.customerNumber,
            branch: this.orderHeader.branch,
            itemCodes: [{ code: params.data.itemNumber, quantity: 1 }],
            postCode: '',
            overrideShowroomViewYN: 'Y',
          })
          .pipe(
            map((res) => {
              if (res) {
                this.isThinking = false;
                const priceList: ItemPrice[] = res.items[0].priceList;
                const foundPrice = priceList.find((price) => price.priceScheduleCode.toLowerCase() === enteredPrice.toLowerCase());
                if (foundPrice) {
                  return {
                    newUnitPriceInc: params.data.taxYN !== 'Y' ? foundPrice.unitPrice : foundPrice.taxedUnitPrice,
                    newUnitPriceEx: foundPrice.unitPrice,
                    overridePriceYN: 'Y',
                  };
                } else {
                  return null;
                }
              }
            })
          );
      }
    } else {
      // Get standard price
      return params.data.miscCharge === 'Y'
        ? of({
            newUnitPriceInc: 0,
            newUnitPriceEx: 0,
            overridePriceYN: 'N',
          })
        : this.getStandardPrice(params.data.itemNumber, params.data.taxYN === 'Y');
    }
  }

  getStandardPrice(itemNumber, isTaxed) {
    return this.apiService
      .getItemPriceGroup({
        customerNumber: this.orderHeader.customerNumber,
        branch: this.orderHeader.branch,
        itemCodes: [{ code: itemNumber, quantity: 1 }],
        postCode: '',
      })
      .pipe(
        map((res) => {
          return {
            newUnitPriceInc: isTaxed ? res.items[0].customerTaxedUnitPrice : res.items[0].customerUnitPrice,
            newUnitPriceEx: res.items[0].customerUnitPrice,
            overridePriceYN: 'N',
          };
        })
      );
  }

  resetPrices() {
    this.dialogService
      .confirm(
        `Select NO to keep existing pricing. Select YES to re-adjust pricing to customers default price.`,
        `WARNING: RESET PRICE`,
        'YES',
        'NO',
        false,
        false,
        true
      )
      .pipe(
        takeUntil(this.unSubscribe$),
        finalize(() => {
          this.isThinking = false;
        }),
        switchMap((val) => {
          if (!!val) {
            this.isThinking = true;
            this.discount.patchValue('');
            return this.apiService.resetOrderPrice({
              orderNumber: this.orderHeader.orderNumber,
            });
          } else {
            return of(null);
          }
        })
      )
      .subscribe((res) => {
        if (res) {
          this.lineUpdated.emit();
        }
      });
  }

  rePrice() {
    this.isThinking = true;
    let discountAmount = 0;
    let discountLetter = '';
    let discountType = '';
    if (!this.discount.value.endsWith('%')) {
      if (isNaN(this.discount.value)) {
        // Pass price code for all overriden allowed and not misc charge items
        discountType = 'letter';
        discountLetter = this.discount.value;
      } else {
        discountAmount = +this.discount.value;
        discountType = 'number';
      }
    } else {
      discountAmount = +this.discount.value.slice(0, -1);
      discountType = 'percentage';
    }
    const incOrExTax_IE = !this.viewSettings.showExPrice ? 'I' : 'E';
    this.apiService
      .orderLevelReprice({
        orderNumber: this.orderHeader.orderNumber,
        orderDiscountType: discountType,
        orderDiscountValue: discountAmount,
        discountLetter: discountLetter,
        incOrExTax_IE,
      })
      .pipe(finalize(() => (this.isThinking = false)))
      .subscribe(() => {
        this.lineUpdated.emit();
      });
  }

  priceScales(cell) {
    this.showPriceScales = true;
    this.isThinking = true;
    this.apiService
      .getItemPriceGroup({
        customerNumber: this.orderHeader.customerNumber,
        branch: this.orderHeader.branch,
        itemCodes: [{ code: cell.data.itemNumber, quantity: 1 }],
        postCode: '',
        overrideShowroomViewYN: 'Y',
      })
      .pipe(
        finalize(() => (this.isThinking = false)),
        takeUntil(this.unSubscribe$)
      )
      .subscribe((res) => {
        if (res) {
          this.isThinking = false;
          this.itemPricesToShow = res.items[0];
          this.itemPricesToShow.customerCurrency = this.orderHeader.currencyCode;
          setTimeout(() => {
            this.modalService.open(this.modalBox).result.then(
              (_) => {
                this.showPriceScales = false;
              },
              (_) => {
                this.showPriceScales = false;
              }
            );
          }, 0);
        }
      });
  }

  dispatchItem(data: StagingOrderLine, changeLineStatus: string) {
    if (changeLineStatus === ORDER_LINE_STATUS.credit) {
      if (data.changeLineStatus === ORDER_LINE_STATUS.credit) {
        this.dispatchedItems.emit({ listData: [data], changeLineStatus: ORDER_LINE_STATUS.empty });
      } else {
        this.dispatchedItems.emit({ listData: [data], changeLineStatus });
      }
      return;
    }

    if (data.changeLineStatus === ORDER_LINE_STATUS.selling || data.changeLineStatus === ORDER_LINE_STATUS.convertQuote) {
      this.dispatchedItems.emit({ listData: [data], changeLineStatus: ORDER_LINE_STATUS.empty });
    } else {
      this.dispatchedItems.emit({ listData: [data], changeLineStatus });
    }

    this.gridApi.refreshCells({ columns: ['action'] });
  }

  dispatchAllItems(changeLineStatus: string) {
    this.dispatchAll.emit({ changeLineStatus, isAllDispatched: this.isAllDispatched(changeLineStatus === ORDER_LINE_STATUS.credit) });

    if (this.gridApi) {
      this.gridApi.refreshCells({ columns: ['action'] });
    }
  }

  isAllDispatched(isCredit = false) {
    if (isCredit) {
      return (
        this.creditList.length > 0 &&
        this.creditList.length ===
          this.gridResult.filter((x) => x.lineStatus === ORDER_LINE_STATUS.invoiced && x.itemNumber !== ORDER_ITEM_NUMBER.subTotal).length
      );
    }

    return (
      this.dispatchList.length > 0 &&
      this.dispatchList.length ===
        this.gridResult.filter((x) => x.lineStatus !== ORDER_LINE_STATUS.invoiced && x.lineStatus !== ORDER_LINE_STATUS.cancelled).length
    );
  }

  // filter the grid data based on the view settings

  isInvoicedOrder() {
    return (
      this.orderHeader &&
      !this.orderHeader.orderNumber.startsWith('T') &&
      this.gridResult?.length > 0 &&
      this.gridResult.filter((line) => line.lineStatus !== ORDER_LINE_STATUS.invoiced && line.lineStatus !== ORDER_LINE_STATUS.cancelled).length === 0
    );
  }

  handleBuyin(formData) {
    if (!formData) {
      this.addingBuyin = false;
      this.editingBuyin = null;
      return;
    }
    const unitPriceTaxed = formData.unitPrice * (1 + formData.markup / 100);
    const buyin: Buyin = {
      supplierCode: formData.supplier,
      itemDescription: `${formData.productCode}-${formData.itemDescription}`,
      unitCost: formData.unitPrice,
    };
    const newBuyinItem = {
      itemNumber: ORDER_ITEM_NUMBER.buyin,
      quantity: 1,
      price: unitPriceTaxed,
      buyin,
    };

    if (this.addingBuyin) {
      this.addItemsToOrder.emit({
        items: [newBuyinItem],
        enforceItemRule: this.viewSettings.enforceItemRule,
      });
      this.addingBuyin = false;
    } else if (this.editingBuyin) {
      const updatedItem = {
        ...this.editingBuyin,
        itemDescription: buyin.itemDescription,
        supplierCode: buyin.supplierCode,
        unitCost: buyin.unitCost,
        overridePriceYN: 'Y',
        unitPriceEx: roundingNumber(unitPriceTaxed / (1 + this.orderHeader.customerTaxRate / 100), 4),
        unitPriceInc: roundingNumber(unitPriceTaxed, 4),
        extPriceEx: roundingNumber((this.editingBuyin.qtyOrdered * unitPriceTaxed) / (1 + this.orderHeader.customerTaxRate / 100), 2),
        extPriceInc: roundingNumber(this.editingBuyin.qtyOrdered * unitPriceTaxed, 2),
      };
      this.cellEdited.emit({
        data: updatedItem,
      });
      this.editingBuyin = null;
    }
  }

  changeTaxOption(cell, ticked) {
    const updatedData = {
      ...cell.data,
      taxYN: ticked ? 'Y' : 'N',
    };
    this.cellEdited.emit({
      index: cell.rowIndex,
      data: updatedData,
    });
  }

  isJDEOrder() {
    return this.orderHeader && this.orderHeader.orderNumber.startsWith('T') ? false : true;
  }

  onCopyOrder() {
    this.copyOrder.emit();
  }

  handleCloseModal(event) {
    if (event) {
      this.modalService.dismissAll();
    }
  }
}
