<pos-app-spinner [displayText]="generateReportLoaderText" [showTextLoader]="showTextLoader" [show]="generateQuoteloading$ | async"></pos-app-spinner>
<pos-app-spinner
  [show]="(orderLoading$ | async) || (validatingOrder$ | async) || (addItemProcessed$ | async) || isLoadingHeader || isLoadingPayments"
></pos-app-spinner>
<div *ngIf="addItemProcessed$ | async as processed" class="progress">Processed {{ processed }} items</div>

<div
  *ngIf="orderHeader && {
    isExternalUser: isExternalUser$ | async,
    loadingLines: loadingLines$ | async,
    orderValidated: orderValidated$ | async,
    paymentInfo: paymentInfo$ | async,
    depositAmount: depositAmount$ | async
  } as data"
>
  <div [hidden]="isMakingPayments || isSaving">
    <div class="mx-5">
      <div class="text-center">
        <h3 class="pt-3 arb-large-heading">
          {{ orderHeader?.readOnlyYN === 'Y' ? 'ORDER DETAILS - ENQUIRY ONLY' : 'ORDER DETAILS' }}
        </h3>
        <label *ngIf="showConvertQuoteMessage" [ngStyle]="{ color: 'red' }">
          SALES ORDER CREATED BUT NOT YET SAVED TO JDE - PLEASE SAVE ORDER WHEN READY
        </label>
        <label *ngIf="orderHeader?.readOnlyDetailYN === 'Y'" class="text-danger"> Order contains restricted items, only header can be updated </label>
      </div>

      <div>
        <app-order-header
          [orderHeader]="orderHeader"
          [headerMessages]="headerMessages$ | async"
          [branchList]="branchList"
          [nonCorpStoreList]="nonCorpStoreList"
          [orderTypeList]="orderTypeList"
          [nonCorpOrderTypeList]="nonCorpOrderTypeList"
          [isExternalUser]="data.isExternalUser"
          [isVehicleDataReady]="!(loadingCustomerDetails$ | async) && !(loadingLookupLists$ | async)"
          [isCreditOrder]="isCreditOrder"
          [creditReasonList]="creditReasonList"
          [selectedReasonCode]="selectedCreditReason"
          [isShowUser]="isShowUser()"
          [customerType]="selectedCustomer?.CustomerType"
          [hasCashDrawer]="!!isCashDrawerOpen"
          [selectedCustomer]="selectedCustomer"
          [allAttachments]="allAttachments"
          (useVehicleColourForAll)="onUseVehicleColourForAll()"
          (isHeaderValid)="isHeaderValid = $event"
          (isOrderOnHold)="isOrderOnHold = $event"
          (selectReason)="onSelectCreditReason($event)"
          (headersToUpdate)="updateHeaders($event)"
          (loadAllAttachments)="loadAllAttachments()"
        ></app-order-header>
      </div>

      <div class="order-grid">
        <app-order-grid
          #orderGridComponent
          [gridResult]="orderDetails"
          [orderHeader]="orderHeader"
          [orderMessages]="orderMessages$ | async"
          [isUpdatingStagingCompleted]="isUpdatingStagingCompleted"
          [isExternalUser]="data.isExternalUser"
          [cashDrawer]="isCashDrawerOpen"
          [viewSettings]="viewSettings"
          [isCreditOrder]="isCreditOrder"
          [isConvertToOrder]="isConvertToOrder"
          [selectedCreditReason]="selectedCreditReason"
          [cancelAllLinesAllowed]="cancelAllLinesAllowed"
          [displayOptions]="displayOptions"
          [brandDefaults]="brandDefaults$ | async"
          [userSecurityFunctions]="userSecurityFunctions"
          [isOrderValidated]="isOrderValidated"
          [isShowUser]="isShowUser()"
          [depositAmount]="data.depositAmount"
          [dispatchList]="dispatchList"
          [creditList]="creditList"
          [orderLoading]="orderLoading$ | async"
          (showDetailedAvailability)="availabilityItem = $event"
          (hasInvalidItem)="hasInvalidItem = $event"
          (enableValidateOrder)="enableValidateOrder = $event"
          (copyOrder)="copyOrder()"
          (isCellStartEditing)="toggleDisableConfirmButton($event)"
          (deletingAllItems)="onDeleteAllItems()"
          (dispatchedItems)="onDispatchedItems($event)"
          (dispatchAll)="onDispatchAll($event)"
          (addItemsToOrder)="addItemsToOrder($event)"
          (viewSettingsChange)="onViewSettingsChange($event)"
          (lineUpdated)="lineUpdated()"
          (cellEdited)="updateCell($event)"
          (loadStagingOrder)="loadStagingOrder()"
        ></app-order-grid>
      </div>

      <div class="pb-5 pt-3 row order-total">
        <div class="col-8 col-lg-6 col-xl-5">
          <app-order-total
            *ngIf="!data.isExternalUser || userSecurityFunctions.includes(SEE_BUY_PRICE)"
            [depositAmount]="data.depositAmount"
            [dispatchList]="dispatchList"
            [orderDetails]="orderDetails"
            [isQuoteOrder]="isConvertToOrder"
            [viewSettings]="viewSettings"
            [currencyCode]="orderHeader.currencyCode"
          ></app-order-total>
        </div>

        <div class="col-12 col-xl-7">
          <table class="table table-borderless table-responsive d-flex justify-content-start justify-content-xl-end actions">
            <tbody>
              <tr>
                <td *ngIf="!data.orderValidated && orderDetails?.length > 0">
                  <button type="button" class="btn btn-secondary" (click)="validateOrder()" [disabled]="disableConfirmOrder()">CONFIRM ORDER</button>
                </td>
                <td *ngIf="secureCatalogueService.getIsCatalogueVisible()">
                  <button type="button" class="btn text-white order-buttons" (click)="handleRoutingBack()">PRODUCT CATALOGUE</button>
                </td>
                <td>
                  <button type="button" class="btn btn-cancel order-buttons" (click)="cancelOrder(orderHeader.orderNumber)">
                    {{ orderHeader.orderNumber.startsWith('T') ? 'CANCEL ORDER' : 'CANCEL CHANGES' }}
                  </button>
                </td>
              </tr>
              <tr>
                <ng-container
                  *ngIf="
                    allowToSave() &&
                    (!data.isExternalUser ||
                      (data.isExternalUser && [ORDER_TYPE.saleOrder, ORDER_TYPE.exportOrder].includes(orderHeader.orderType))) &&
                    data.orderValidated &&
                    (orderDetails?.length > 0 || !orderHeader.orderNumber.startsWith('T')) &&
                    orderHeader?.readOnlyYN === 'N'
                  "
                >
                  <td>
                    <button
                      type="button"
                      class="btn text-white order-buttons"
                      (click)="saveOrder()"
                      [disabled]="hasErrorQuantity() || !isHeaderValid || hasUpdatingRow"
                    >
                      SAVE ORDER
                    </button>
                  </td>
                </ng-container>
                <ng-container *ngIf="data.orderValidated && orderDetails?.length > 0">
                  <ng-container *ngIf="data.isExternalUser && orderHeader?.orderType === ORDER_TYPE.quoteOrder">
                    <td *ngIf="allowToSave()">
                      <button type="button" class="btn text-white order-buttons" (click)="produceQuote()" [disabled]="disableExportQuote()">
                        PRODUCE QUOTE
                      </button>
                    </td>
                    <td>
                      <button type="button" class="btn text-white order-buttons" (click)="exportQuote()" [disabled]="disableExportQuote()">
                        EXPORT QUOTE
                      </button>
                    </td>
                  </ng-container>
                  <ng-container
                    *ngIf="
                      orderHeader.payableYN === 'Y' &&
                      orderHeader?.customerPaymentTerms === 'POS' &&
                      orderHeader?.orderType !== ORDER_TYPE.quoteOrder &&
                      !isConvertToOrder
                    "
                  >
                    <td *ngIf="!isCreditOrder && dispatchList.length">
                      <button
                        type="button"
                        class="btn text-white order-buttons"
                        (click)="sellGoods()"
                        [disabled]="disableSaveGood(data.loadingLines)"
                      >
                        SELL GOODS
                      </button>
                    </td>
                    <td *ngIf="isCreditOrder && creditList?.length">
                      <button
                        type="button"
                        class="btn text-white order-buttons"
                        (click)="processCredit()"
                        [disabled]="disableProcessCredit(data.loadingLines)"
                      >
                        PROCESS CREDIT
                      </button>
                    </td>
                  </ng-container>
                  <td
                    *ngIf="
                      orderHeader.payableYN === 'Y' &&
                      orderHeader.quickSaleYN === 'N' &&
                      orderHeader?.customerPaymentTerms === 'POS' &&
                      orderHeader?.orderType !== ORDER_TYPE.quoteOrder &&
                      !isConvertToOrder &&
                      !isCreditOrder &&
                      isCashDrawerOpen &&
                      !creditList?.length &&
                      !dispatchList?.length
                    "
                  >
                    <button
                      type="button"
                      class="btn text-white order-buttons"
                      [disabled]="disableDeposit(data.loadingLines)"
                      (click)="placeDeposit()"
                    >
                      PLACE DEPOSIT
                    </button>
                  </td>
                  <td *ngIf="isConvertToOrder">
                    <button
                      type="button"
                      class="btn text-white order-buttons"
                      (click)="handleOverrideBranch()"
                      [disabled]="!isHeaderValid || dispatchList?.length === 0"
                    >
                      CONVERT QUOTE TO ORDER
                    </button>
                  </td>
                </ng-container>
                <ng-container *ngIf="creditList?.length > 0 && viewSettings.showInvoiced">
                  <td *ngIf="isReturnCreditAllow(orderTypeList)">
                    <button type="button" class="btn text-white order-buttons" (click)="createCredit(CREDIT_TYPE.RETURN)" [disabled]="!isHeaderValid">
                      RETURN CREDIT
                    </button>
                  </td>
                  <td *ngIf="isPriceCreditAllow(orderTypeList)">
                    <button type="button" class="btn text-white order-buttons" (click)="createCredit(CREDIT_TYPE.PRICE)" [disabled]="!isHeaderValid">
                      PRICE CREDIT
                    </button>
                  </td>
                </ng-container>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isMakingPayments || isSaving" class="container-fluid pb-2">
    <mat-horizontal-stepper linear #stepper>
      <mat-step *ngIf="!isSaving && !toDisableAllocation">
        <ng-template matStepLabel>STOCK ALLOCATION</ng-template>
        <app-orders-stock-allocation
          [showLocation]="isShowUser() ? showLocation : null"
          [isCreditOrder]="isCreditOrder"
          [stockList]="stockAllocationList"
          (allocateStock)="allocateStock($event)"
          (isSerialNumberExist)="isSerialNumberExist($event)"
        ></app-orders-stock-allocation>
        <div class="mt-4 d-flex justify-content-end">
          <button mat-button class="btn text-white" [disabled]="!isValidAllocation || isSerialNumberOnHand" (click)="goToPayment()">NEXT</button>
          <span class="px-2"></span>
          <button class="btn btn-cancel" (click)="onReturnToOrder()">RETURN TO ORDER</button>
        </div>
      </mat-step>
      <mat-step *ngIf="!isSaving" [label]="'payments'">
        <ng-template matStepLabel>TAKE PAYMENTS</ng-template>
        <ng-container>
          <app-orders-payment
            #ordersPaymentComponent
            [orderHeader]="orderHeader"
            [paymentInfo]="data.paymentInfo"
            [paymentTypes]="paymentTypes"
            [hasGiftCard]="giftCardLoadUpList.length > 0"
            [orderNumber]="orderHeader.orderNumber"
            [cashDrawer]="cashDrawer"
            [allowFakeEFTPOSTF]="allowFakeEFTPOSTF$ | async"
            (cashPayment)="hasCashPayment = $event"
            (refetchPaymentInfo)="onRefetchPaymentInfo()"
            (setOutstandingBalance)="outstandingBalance = $event"
            (pendingPaymentChange)="onPendingPaymentChange($event)"
            (next)="onNext()"
            (pollEftPos)="checkEftPosStatus($event)"
          ></app-orders-payment>
          <div *ngIf="ordersPayment" class="mt-4 d-flex justify-content-end">
            <button *ngIf="!toDisableAllocation" [disabled]="ordersPayment?.formDisabled" mat-button matStepperPrevious class="btn text-white">
              BACK
            </button>
            <span class="px-2"></span>
            <button
              mat-button
              matStepperNext
              class="btn text-white"
              [disabled]="data.paymentInfo?.enableNextTF === false || ordersPayment?.formDisabled"
              (click)="onNext()"
            >
              NEXT
            </button>
            <span class="px-2"></span>
            <span *ngIf="ordersPayment?.eftpostPendingPayments?.length > 0">
              <button
                [disabled]="ordersPayment?.formDisabled"
                mat-button
                class="btn btn-secondary text-white"
                (click)="confirmEftposPendingPayments()"
              >
                CONFIRM PAYMENT
              </button>
            </span>
            <span class="px-2"></span>
            <button class="btn btn-cancel" [disabled]="ordersPayment?.formDisabled" (click)="onReturnToOrder()">RETURN TO ORDER</button>
          </div>
        </ng-container>
      </mat-step>
      <mat-step *ngIf="!isSaving && giftCardLoadUpList?.length > 0">
        <ng-template matStepLabel>LOAD UP GIFT CARD</ng-template>
        <div>
          <app-orders-gift-card-loadup
            [orderNumber]="orderNumber"
            [giftCardLoadUpList]="giftCardLoadUpList"
            (cardLoadedEmit)="onCardLoaded($event)"
          ></app-orders-gift-card-loadup>
        </div>
        <div class="mt-4 d-flex justify-content-end">
          <button mat-button matStepperPrevious class="btn text-white">BACK</button>
          <span class="px-2"></span>
          <button mat-button matStepperNext class="btn text-white" [disabled]="(pendingLoadAmounts$ | async)?.length > 0">NEXT</button>
          <span class="px-2"></span>
          <button class="btn btn-cancel" (click)="onReturnToOrder()">RETURN TO ORDER</button>
        </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>DISTRIBUTE ORDER</ng-template>
        <div>
          <app-order-distribute
            [orderType]="orderHeader.orderType"
            [printerList]="printerList"
            [userDetails]="userDetails"
            [selectedCustomer]="selectedCustomer"
            [userRole]="userRole"
            [orderConfirmation]="orderConfirmation"
            [isQuickSale]="orderHeader.quickSaleYN === 'Y'"
            [isCreditOrder]="isCreditOrder"
            [cashPaymentData]="{
              cashChange: data.paymentInfo?.totals?.cashChange,
              cashTendered: data.paymentInfo?.totals?.cashTendered
            }"
            [isReceiptPrinterConnected]="drawerService.getIsPrinterConnected()"
            (distributeOrderData)="distributeOutputData = $event"
            (updateEmail)="updateCustomerEmail($event)"
            (allowToFinalise)="allowToFinalise = $event"
            (setIncompleteOrder)="setIncompleteOrder()"
          ></app-order-distribute>
        </div>
        <div class="mt-4 d-flex justify-content-end">
          <button mat-button matStepperPrevious class="btn text-white" *ngIf="!isSaving">BACK</button>
          <span class="px-2"></span>
          <button
            *ngIf="!data.isExternalUser"
            mat-button
            class="btn text-white"
            (click)="completeOrder(isMakingPayments)"
            [disabled]="!allowToFinalise"
          >
            FINALISE SALE
          </button>
          <button
            *ngIf="data.isExternalUser"
            mat-button
            class="btn text-white"
            (click)="completeOrder(isMakingPayments)"
            [disabled]="!allowToFinalise"
          >
            {{ orderHeader.orderType === ORDER_TYPE.quoteOrder ? 'PRODUCE QUOTE' : 'SEND ORDER TO ARB' }}
          </button>
          <span class="px-2"></span>
          <button class="btn btn-cancel" (click)="onReturnToOrder()">RETURN TO ORDER</button>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</div>

<div *ngIf="isCheckingCreditUser">
  <app-orders-check-credit-users (isCreditUser)="handleCreditUser($event)"></app-orders-check-credit-users>
</div>

<div *ngIf="availabilityItem">
  <app-orders-detailed-availability
    [availabilityItem]="availabilityItem"
    [branchList]="branchList"
    [orderHeader]="orderHeader"
    (cancelAvailabilityView)="cancelAvailabilityView()"
  >
  </app-orders-detailed-availability>
</div>

<ng-template #content>
  <app-orders-override-branch
    [overridenBranch]="overrideData.overrideBranch"
    [branchList]="branchList"
    [isConvertToOrder]="isConvertToOrder"
    (selectOverridenBranch)="onSelectOverrideData($event)"
    *ngIf="!isShowUser(); else overrideForShow"
  ></app-orders-override-branch>
  <ng-template #overrideForShow>
    <app-orders-override-show
      [salesPerson]="orderHeader.salesperson"
      [showBranch]="overrideData.overrideBranch"
      [orderBranch]="orderHeader.branch"
      [shipTo]="orderHeader.shipToNumber"
      [branchList]="branchList"
      [nonCorpStoreList]="nonCorpStoreList"
      [showUsers]="showUsers"
      [nonCorpStoreSale]="orderHeader.nonCorpStoreSaleYN === 'Y'"
      (selectForShow)="onSelectOverrideData($event)"
    ></app-orders-override-show>
  </ng-template>
</ng-template>
