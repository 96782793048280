import { CustomerDetails, InvoiceBranchDetails, ORDER_LINE_STATUS, PREVIOUS_PAYMENT } from '@pos-app/data';
import { Payment, StagingOrderLine, StockAllocation } from '../../+state/orders.models';

// Build payload for update item API
export const updatedData = (orderLine: StagingOrderLine) => {
  return {
    orderNumber: orderLine.orderNumber,
    brand: orderLine.brand,
    orderLine: orderLine.ooeLineNumber,
    itemQty: orderLine.qtyOrdered,
    paintYN: orderLine.paintYN,
    paintableYN: orderLine.paintableYN,
    fittedYN: orderLine.fittedYN,
    fitableYN: orderLine.fitableYN,
    colourCode: orderLine.colourCode,
    itemDesc: orderLine.itemDescription,
    unitPriceExTax: orderLine.unitPriceEx,
    unitPriceIncTax: orderLine.unitPriceInc,
    extPriceExTax: orderLine.extPriceEx,
    extPriceIncTax: orderLine.extPriceInc,
    partsVehicleID: orderLine.partsVehicleID,
    overridePriceYN: orderLine.overridePriceYN,
    miscChargeYN: orderLine.miscChargeYN,
    changeDescriptionAllowedYN: orderLine.changeDescriptionAllowedYN,
    serialNumber: orderLine.serialNumber,
    location: orderLine.location,
    lineStatus: orderLine.lineStatus,
    changeLineStatus: orderLine.changeLineStatus,
    reasonCode: orderLine.reasonCode,
    taxYN: orderLine.taxYN,
    supplierCode: orderLine.supplierCode,
    unitCost: orderLine.unitCost,
    readOnlyLineYN: orderLine.readOnlyLineYN,
    id: orderLine.id,
    stockOnHand: undefined
  };
};

// Build the payload to update the item with allocatio details
export const locationOrSerialAllocation = (
  item,
  allocation,
  stockOnHand: StockAllocation,
  isShowUser: boolean,
  showLocation: string,
  isAssociatedLine = false
) => {
  // Add new serialized item
  const pickedItemFromOnHandArray = stockOnHand.onHandQtyArray.find((item) => item.serialNumber === allocation);
  const newExtPriceExTax = item[isAssociatedLine ? 'assocUnitPriceExTax' : 'unitPriceExTax'] * item[isAssociatedLine ? 'assocQty' : 'parentItemQty'];
  const newExtPriceIncTax = item[isAssociatedLine ? 'assocUnitPriceIncTax' : 'unitPriceIncTax'] * item[isAssociatedLine ? 'assocQty' : 'parentItemQty'];
  item = {
    ...item,
    [isAssociatedLine ? 'assocExtPriceExTax' : 'extPriceExTax']: newExtPriceExTax,
    [isAssociatedLine ? 'assocExtPriceIncTax' : 'extPriceIncTax']: newExtPriceIncTax,
  };
  return appendLocationOrSerialNumber(item, allocation, pickedItemFromOnHandArray, isShowUser, showLocation);
};

export const appendLocationOrSerialNumber = (item, allocation, pickedItemFromOnHandArray, isShowUser: boolean, showLocation: string) => {
  // if it's a serialised item, update serial number and location
  if (typeof allocation === 'string') {
    return {
      ...item,
      location: isShowUser ? showLocation : (pickedItemFromOnHandArray && pickedItemFromOnHandArray.location) || '',
      serialNumber: allocation,
    };
  } else {
    // if it's a blank location item, update the location
    return {
      ...item,
      location: allocation.itemLocation,
    };
  }
};

// Build the payload to add new item after the stock allocation
export const getItemsToAdd = (
  dispatchedItem: StagingOrderLine,
  allocationList,
  isCreditOrder: boolean,
  isShowUser: boolean,
  showLocation: string,
  stockOnHand: StockAllocation
) => {
  let itemsToAdd = [];
  // Add standalone item
  if (dispatchedItem.parentLineNumber === 0) {
    itemsToAdd = itemsToAdd.concat(
      allocationList.map((allocation) => {
        let qty = getQty(allocation, isCreditOrder, dispatchedItem);
        const newItem = {
          orderNumber: dispatchedItem.orderNumber,
          parentItemCode: dispatchedItem.itemNumber,
          parentItemDesc: dispatchedItem.itemDescription,
          parentItemQty: qty,
          parentItemUOM: dispatchedItem.uom,
          paintableYN: dispatchedItem.paintableYN,
          paintYN: dispatchedItem.paintYN,
          fitableYN: dispatchedItem.fitableYN,
          fittedYN: dispatchedItem.fittedYN,
          unitPriceExTax: dispatchedItem.unitPriceEx,
          unitPriceIncTax: dispatchedItem.unitPriceInc,
          extPriceExTax: dispatchedItem.extPriceEx,
          extPriceIncTax: dispatchedItem.extPriceInc,
          partsVehicleID: dispatchedItem.partsVehicleID,
          parentLineNumber: 0,
          nonStockLineYN: dispatchedItem.nonStockLineYN,
          overridePriceAllowedYN: dispatchedItem.overridePriceAllowedYN,
          overridePriceYN: dispatchedItem.overridePriceYN,
          miscChargeYN: dispatchedItem.miscChargeYN,
          changeDescriptionAllowedYN: dispatchedItem.changeDescriptionAllowedYN,
          mandatoryPaintYN: dispatchedItem.mandatoryPaintYN,
          brand: dispatchedItem.brand,
          fitProductCode: dispatchedItem.fitProductCode,
          fitProductDesc: dispatchedItem.fitProductDesc,
          fitUnitPriceExTax: dispatchedItem.fitUnitPriceExTax,
          fitUnitPriceIncTax: dispatchedItem.fitUnitPriceIncTax,
          paintProductCode: dispatchedItem.paintProductCode,
          reasonCode: dispatchedItem.reasonCode,
          changeLineStatus: ORDER_LINE_STATUS.selling,
        };

        return locationOrSerialAllocation(newItem, allocation, stockOnHand, isShowUser, showLocation);
      })
    );
    // Add associated line
  } else {
    const newItem = {
      orderNumber: dispatchedItem.orderNumber,
      parentItemCode: '',
      parentItemDesc: '',
      parentItemQty: '',
      parentItemUOM: '',
      paintableYN: '',
      paintYN: '',
      fitableYN: '',
      fittedYN: '',
      unitPriceExTax: '',
      unitPriceIncTax: '',
      extPriceExTax: '',
      extPriceIncTax: '',
      partsVehicleID: '',
      parentLineNumber: dispatchedItem.parentLineNumber,
      nonStockLineYN: '',
      fitProductCode: '',
      fitProductDesc: '',
      fitUnitPriceExTax: '',
      fitUnitPriceIncTax: '',
      paintProductCode: '',
      paintDesc: '',
      overridePriceAllowedYN: '',
      assocItems: allocationList.map((allocation) => {
        let qty = getQty(allocation, isCreditOrder, dispatchedItem);
        const newAssocItem = {
          assocItemCode: dispatchedItem.itemNumber,
          assocItemDesc: dispatchedItem.itemDescription,
          assocItemUOM: dispatchedItem.uom,
          assocType: dispatchedItem.associationType,
          assocUnitPriceExTax: dispatchedItem.unitPriceEx,
          assocUnitPriceIncTax: dispatchedItem.unitPriceInc,
          assocExtPriceExTax: dispatchedItem.extPriceEx,
          assocExtPriceIncTax: dispatchedItem.extPriceInc,
          assocQty: qty,
          nonStockLineYN: dispatchedItem.nonStockLineYN,
          overridePriceAllowedYN: dispatchedItem.overridePriceAllowedYN,
          miscChargeYN: dispatchedItem.miscChargeYN,
          changeDescriptionAllowedYN: dispatchedItem.changeDescriptionAllowedYN,
          taxYN: dispatchedItem.taxYN,
          fitableYN: dispatchedItem.fitableYN,
          fittedYN: dispatchedItem.fittedYN,
          fitProductCode: dispatchedItem.fitProductCode,
          fitProductDesc: dispatchedItem.fitProductDesc,
          fitUnitPriceExTax: dispatchedItem.fitUnitPriceExTax,
          fitUnitPriceIncTax: dispatchedItem.fitUnitPriceIncTax,
          paintProductCode: dispatchedItem.paintProductCode,
          paintDesc: dispatchedItem.paintDesc,
          paintableYN: dispatchedItem.paintableYN,
          paintYN: dispatchedItem.paintYN,
          reasonCode: dispatchedItem.reasonCode,
          changeLineStatus: ORDER_LINE_STATUS.selling,
        };
        return locationOrSerialAllocation(newAssocItem, allocation, stockOnHand, isShowUser, showLocation, true);
      }),
    };
    itemsToAdd.push(newItem);
  }
  return itemsToAdd;
};

const getQty = (allocation, isCreditOrder, dispatchedItem) => {
  let qty = typeof allocation === 'string' ? 1 : allocation.qty;
  qty = isCreditOrder && dispatchedItem.lineStatus.trim() !== '' ? -qty : qty;
  return qty;
};

// data for thermal receipt
export const compileReceiptData = (branchDetails: InvoiceBranchDetails, customer: CustomerDetails, pendingPayments: Payment[], pastPaymentsAmount: number) => {
  const companyDetails = branchDetails;
  const customerDetails = {
    name: customer.CustomerName,
    address1: customer.AddressLine1,
    address2: customer.AddressLine2,
    phone: customer.Phone,
    email: customer.Email,
  };
  let payments = pendingPayments.map((payment) => {
    return {
      paymentType: payment.paymentType,
      amount: payment.amount,
    };
  });
  if (pastPaymentsAmount > 0) {
    payments.push({
      paymentType: PREVIOUS_PAYMENT,
      amount: pastPaymentsAmount,
    });
  }
  return { companyDetails, customerDetails, payments };
};
