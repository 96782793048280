<pos-app-spinner [show]="isLoading"></pos-app-spinner>
<div class="container">
  <div class="text-center">
    <h3 class="pt-3 arb-large-heading">START NEW ORDER</h3>
  </div>
  <div class="card mt-4">
    <div class="card-body">
      <form [formGroup]="startOrderForm">
        <!-- ShipTo for external users -->
        <div class="row py-1" *ngIf="preSelectedBillToCustomer">
          <div class="col-5 col-lg-4">
            <label class="my-auto">SELECT CUSTOMER (SHIPTO) </label>
          </div>
          <div class="col-7 col-lg-8">
            <ng-select formControlName="shipToNumber">
              <ng-option [value]="preSelectedBillToCustomer.CustomerNumber">
                {{ preSelectedBillToCustomer.CustomerName }} ({{ preSelectedBillToCustomer.CustomerNumber }})
              </ng-option>
              <ng-option *ngFor="let customerShipTo of customerShipToList" [value]="customerShipTo.customerNumber">
                {{ customerShipTo.customerName }} ({{ customerShipTo.customerNumber }})
              </ng-option>
            </ng-select>
          </div>
        </div>
        <!-- Shipto for internal users -->
        <div class="row py-2" *ngIf="!preSelectedBillToCustomer">
          <div class="col-5 col-lg-4">
            <label class="my-auto d-flex">
              SELECT CUSTOMER (SHIPTO) <span class="text-danger">*</span>
              <mat-icon (click)="addNewCustomer() && open(content)"> add_circle_outline</mat-icon>
            </label>
          </div>
          <div class="col-7 col-lg-8">
            <pos-app-lib-customer-search
              #customerSearch
              [disabled]="isLoading"
              [forOrderSearch]="true"
              [selectedCustomerName]="getCustomerName()"
              (selectNewCustomer)="selectShipToCustomer($event)"
            ></pos-app-lib-customer-search>
          </div>
        </div>
        <div class="row py-2" *ngIf="preSelectedBillToCustomer">
          <div class="offset-4 col-8 font-weight-bold text-right">CONTACT ARB TO CREATE MORE DELIVERY ADDRESSES</div>
        </div>
        <!-- BillTo for internal users -->
        <div class="row py-2" *ngIf="preSelectedBillToCustomer || selectedShipToCustomer">
          <div class="col-5 col-lg-4">
            <label class="my-auto">SELECT CUSTOMER (BILLTO)</label>
          </div>
          <div class="col-7 col-lg-8">
            <div *ngIf="preSelectedBillToCustomer">
              <input
                class="form-control"
                [value]="preSelectedBillToCustomer.CustomerName + ' (' + preSelectedBillToCustomer.CustomerNumber + ')'"
                disabled
              />
            </div>
            <div *ngIf="!preSelectedBillToCustomer && selectedShipToCustomer && !isShipTo">
              <input
                class="form-control"
                [value]="
                  startOrderForm.controls.shipToName.value
                    ? startOrderForm.controls.shipToName.value + ' (' + startOrderForm.controls.shipToNumber.value + ')'
                    : ''
                "
                disabled
              />
            </div>
            <div *ngIf="!preSelectedBillToCustomer && isShipTo && customerBillToList?.length > 0">
              <ng-select formControlName="customerNumber">
                <ng-option *ngFor="let customerBillTo of customerBillToList" [value]="customerBillTo.billToNumber">
                  {{ customerBillTo.billToName }} ({{ customerBillTo.billToNumber }})
                </ng-option>
              </ng-select>
            </div>
            <div *ngIf="!preSelectedBillToCustomer && isShipTo && customerBillToList?.length === 0">
              <pos-app-lib-customer-search
                [forOrderSearch]="true"
                [customerType]="'billto'"
                (selectNewCustomer)="selectBillToCustomer($event)"
                [selectedCustomerName]="selectedBillToCustomer?.CustomerName"
              ></pos-app-lib-customer-search>
            </div>
          </div>
        </div>
        <div class="row py-2">
          <div class="col-5 col-lg-4">
            <label>ORDER TYPE <span class="text-danger">*</span></label>
          </div>
          <div class="col-7 col-lg-8">
            <ng-select formControlName="orderType">
              <ng-option
                *ngFor="let orderType of startOrderForm.controls.onBehalf.value === '0' ? orderTypes : nonCorpOrderTypes"
                [value]="orderType.OrderTypeCode"
              >
                {{ orderType.OrderTypeDescription }}
              </ng-option>
            </ng-select>
          </div>
        </div>
        <div class="row text-danger" *ngIf="startOrderForm.controls.orderType.errors?.invalidNonCorp">
          <div class="offset-5 col-7 offset-lg-4 col-lg-8">Selected order type is not valid for Non-corp store</div>
        </div>
        <div class="row text-danger" *ngIf="startOrderForm.controls.orderType.errors?.invalidOrderType">
          <div class="offset-5 col-7 offset-lg-4 col-lg-8">Cannot process a credit without a register connected</div>
        </div>
        <div class="row py-2">
          <div class="col-5 col-lg-4">
            <label>
              VEHICLE<span class="pl-2 text-danger" (click)="clearVehicle()" *ngIf="selectedCustomerVehicle || selectedVehicle">(clear)</span>
            </label>
          </div>
          <div class="col-7 col-lg-8">
            <input type="search" class="form-control" [value]="vehicleDescription()" readonly />
          </div>
        </div>
        <div class="row py-2">
          <div class="offset-3 col-9 offset-lg-4 col-lg-8 justify-content-end d-flex">
            <button
              *ngIf="activeOrderVehicle$ | async as selectedVehicle"
              type="button"
              class="btn btn-secondary text-white"
              (click)="getSelectedVehicle(selectedVehicle) && open(content)"
              [disabled]="
                startOrderForm.controls.customerNumber.value === '' ||
                selectedVehicle?.vehicleDescription?.trim() === '' ||
                (loadingCustomerDetails$ | async) ||
                (loadingLookupLists$ | async)
              "
            >
              USE VEHICLE FROM CATALOGUE
            </button>
            <span class="px-2"></span>
            <button
              type="button"
              class="btn btn-secondary text-white"
              (click)="open(content)"
              [disabled]="startOrderForm.controls.customerNumber.value === '' || (loadingLookupLists$ | async) || (loadingCustomerDetails$ | async)"
            >
              SELECT FROM GARAGE
            </button>
          </div>
        </div>
        <div class="row py-2">
          <div class="col-5 col-lg-4">
            <label
              >{{
                userRole === USER_ROLE.external && startOrderForm.controls.orderType.value === ORDER_TYPE.quoteOrder
                  ? 'CUSTOMER DETAILS'
                  : 'ORDER REFERENCE'
              }}
              <span *ngIf="isOrderReferenceRequired" style="color: red">*</span></label
            >
          </div>
          <div class="col-7 col-lg-8">
            <input
              class="form-control"
              formControlName="reference"
              maxlength="25"
              [class.is-invalid]="startOrderForm.get('reference').errors?.invalid"
            />
          </div>
        </div>
        <!-- TODO handle show user -->
        <div class="row py-2" *ngIf="userRole === USER_ROLE.showExternal || userRole === USER_ROLE.showInternal">
          <div class="col-5 col-lg-4">
            <label>RAISE ORDER ON BEHALF OF</label>
          </div>
          <div class="col-7 col-lg-8">
            <input type="radio" formControlName="onBehalf" value="0" /><span class="px-1"></span>ARB BRANCH
            <span class="px-2"></span>
            <input type="radio" formControlName="onBehalf" value="1" /><span class="px-1"></span>NON-CORPORATE STORE
          </div>
        </div>
        <div
          class="row py-2"
          [ngClass]="{
            'd-none': startOrderForm.controls.onBehalf.value !== '0'
          }"
        >
          <div class="col-5 col-lg-4">
            <label>BRANCH <span class="text-danger">*</span></label>
          </div>
          <div class="col-7 col-lg-8">
            <ng-select formControlName="branch">
              <ng-option *ngFor="let branch of branchList" [value]="branch.BranchCode">
                <span class="pr-1" *ngIf="branch.BranchCode">{{ branch.BranchCode }} -</span>
                {{ branch.BranchDescription }}
              </ng-option>
              <ng-template ng-label-tmp let-item="item">
                {{ item | branch : branchList }}
              </ng-template>
            </ng-select>
          </div>
        </div>
        <div
          class="row py-2"
          [ngClass]="{
            'd-none': startOrderForm.controls.onBehalf.value !== '1'
          }"
        >
          <div class="col-5 col-lg-4">
            <label>NON-CORPORATE STORE</label>
          </div>
          <div class="col-7 col-lg-8">
            <ng-select formControlName="nonCorpStore">
              <ng-option *ngFor="let store of nonCorpStores" [value]="store">
                {{ store.customerName }} - {{ store.address1 }}, {{ store.suburb }}, {{ store.postCode }}, {{ store.state }}
              </ng-option>
            </ng-select>
          </div>
        </div>
        <div class="row py-2" *ngIf="preSelectedBillToCustomer">
          <div class="offset-4 col-8 font-weight-bold text-right">CONTACT ARB TO SHIP FROM A DIFFERENT LOCATION</div>
        </div>
        <div class="d-flex justify-content-end my-4">
          <button type="submit" (click)="createOrder()" class="btn text-white" [disabled]="invalid || (loadingCustomerDetails$ | async)">
            CREATE ORDER AND GO TO CATALOGUE
          </button>
          <span class="px-4"></span>
          <button type="submit" (click)="createOrder(true)" class="btn text-white" [disabled]="invalid || (loadingCustomerDetails$ | async)">
            CREATE ORDER AND GO TO ORDER
          </button>
          <span class="px-4"></span>
          <button type="button" class="btn btn-cancel" (click)="cancel()">CANCEL</button>
        </div>
      </form>
    </div>
  </div>
</div>

<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h3 class="arb-large-heading" id="modal-basic-title">
      {{ isAddingVehicle ? 'NEW VEHICLE DETAILS' : isAddingCustomer ? 'NEW CUSTOMER DETAILS' : 'CUSTOMER VEHICLE SEARCH' }}
    </h3>
  </div>
  <div class="modal-body">
    <app-customers-vehicles
      *ngIf="!isAddingVehicle && !isAddingCustomer"
      [customerNumber]="selectedShipToCustomer?.CustomerNumber || preSelectedBillToCustomer?.CustomerNumber"
      [currentCustomerVehicleId]="selectedCustomerVehicle?.customerVehicleId"
      (selectVehicleEvent)="selectCustomerVehicle($event)"
      (closeActiveModal)="d('Cross click')"
    ></app-customers-vehicles>
    <div *ngIf="isAddingVehicle">
      <app-customers-vehicles-details-new
        [currentCustomer]="this.selectedShipToCustomer.CustomerNumber"
        [preselectedVehicle]="selectedVehicle"
        (vehicleAdded)="closeAddingVehicle($event)"
        (cancelAdd)="closeAddingVehicle()"
      ></app-customers-vehicles-details-new>
    </div>
    <div *ngIf="isAddingCustomer">
      <app-customers-details-new (newCustomer)="handleNewCustomer($event)" [isForNewOrder]="true"></app-customers-details-new>
    </div>
  </div>
</ng-template>
