import { Component } from '@angular/core';
import { NOTE_TASK_ACTIVITY_TYPE } from '@pos-app/data';
import { ICellRenderer } from 'ag-grid-community';

@Component({
  selector: 'app-action-cell',
  template: `
    <div class="row">
      <div class="col-1">
        <mat-icon
          *ngIf="isOrderLinked"
          (click)="invokeFetchOrderMethod()"
          title="Load order into staging"
          >refresh</mat-icon
        >
      </div>
      <div class="col-1">
        <mat-icon (click)="invokeEditNote()" title="Edit Note">edit</mat-icon>
      </div>
      <div class="col-1">
        <mat-icon
          *ngIf="!isReadOnly"
          (click)="invokeDeleteNote()"
          title="Delete Note"
          >delete</mat-icon
        >
      </div>
      <div class="col-1">
        <mat-icon
          *ngIf="isTask && !isTaskCompleted"
          (click)="invokeCompleteTask()"
          title="Complete Task"
          >check</mat-icon
        >
      </div>
      <div class="col-1">
        <mat-icon
          *ngIf="isAttachment"
          (click)="invokeViewFileAttachment()"
          title="View File Attachment"
          >attachment</mat-icon
        >
      </div>
    </div>
  `,
  styles: [
    `
      .btn {
        line-height: 0.5;
      }
    `
  ]
})
export class TimelineGridActionRendererComponent
  implements ICellRenderer {
  public params: any;
  isTask: boolean;
  isAttachment: boolean;
  isOrderLinked: boolean;
  isReadOnly: boolean;
  isTaskCompleted: boolean;

  agInit(params: any): void {
    this.params = params;
    this.isReadOnly = params.data.readOnlyYN === 'Y';
    this.isTask = params.data.activityType === NOTE_TASK_ACTIVITY_TYPE.Task;
    this.isAttachment = params.data.attachmentIDArray.length > 0;
    this.isOrderLinked =
      params.data.orderNumber !== ' ' && params.data.orderType !== ' ';
    this.isTaskCompleted = params.data.dateCompleted;
  }

  public invokeEditNote() {
    this.params.context.componentParent.navigateToEditNote(this.params.data);
  }

  public invokeDeleteNote() {
    this.params.context.componentParent.deleteNoteTask(this.params.data);
  }

  public invokeCompleteTask() {
    this.params.context.componentParent.completeTask(this.params.data);
  }

  public invokeViewFileAttachment() {
    this.params.context.componentParent.viewFileAttachment(this.params.data);
  }

  public invokeFetchOrderMethod() {
    this.params.context.componentParent.fetchOrder(this.params.data);
  }

  refresh(): boolean {
    return false;
  }
}
