import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { CustomerDetails, LookupLists, Customer, State } from '@pos-app/data';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CustomersPartialState } from '../../+state/customers.reducer';
import {
  CreateShipToCustomer,
  CheckDuplicateCustomer,
  SelectCountry
} from '../../+state/customers.actions';
import { customersQuery } from '../../+state/customers.selectors';
import { hasUnsavedChanges, clearUnsavedChanges, MessageService } from '@pos-app/core-ui';
import { takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-customers-ship-to',
  templateUrl: './customers-ship-to.component.html',
  styleUrls: ['./customers-ship-to.component.scss']
})
export class CustomersShipToComponent implements OnInit, OnDestroy {
  public selectedCustomerDetails$: Observable<CustomerDetails>;
  public lookupLists$: Observable<LookupLists>;
  public customerShipToForm: FormGroup;
  public formValid = false;
  public formDirty = false;
  public formValue = null;
  public unSubscribe$ = new Subject<void>();
  public isCreatingCustomer$: Observable<boolean>;
  public duplicateCustomers$: Observable<Customer[]>;
  public checkingDuplicate = false;
  public newCustomerDetails: any;
  public isCreatingCustomer: boolean;
  public filteredStateList: State[];

  constructor(
    private router: Router,
    private store: Store<CustomersPartialState>,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.selectedCustomerDetails$ = this.store.select(
      customersQuery.getSelectedCustomer
    );
    this.store
      .select(customersQuery.getIsCreatingCustomer)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe(isCreating => {
        setTimeout(() => {
          this.isCreatingCustomer = isCreating;
        }, 0);
      });
    this.lookupLists$ = this.store.select(customersQuery.getLookupLists);
    this.store
      .select(customersQuery.getFilteredStates)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe(filteredStates => {
        setTimeout(() => {
          this.filteredStateList = filteredStates;
        }, 0);
      });
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  formDirtyHandler(dirty) {
    this.formDirty = dirty;
    if (dirty) {
      this.store.dispatch(hasUnsavedChanges({ unsavedChanges: true }));
      this.messageService.dispatchAction(
        hasUnsavedChanges({ unsavedChanges: true })
      );
    }
  }

  checkDuplicate() {
    this.checkingDuplicate = true;
    this.newCustomerDetails = {
      fullName: this.formValue.name,
      firstName: '',
      lastName: '',
      phoneNumber: this.formValue.phone,
      address1: this.formValue.addressLine1,
      email: this.formValue.email
    };
    this.store.dispatch(new CheckDuplicateCustomer(this.newCustomerDetails));
    this.duplicateCustomers$ = this.store.select(
      customersQuery.getDuplicateCustomers
    );
  }

  customerChoosen(customer: Customer) {
    if (customer) {
      this.store.dispatch(clearUnsavedChanges({ unsavedChanges: false }));
      this.messageService.dispatchAction(
        hasUnsavedChanges({ unsavedChanges: false })
      );
      this.router.navigate(['/pos/customers/' + customer.CustomerNumber]);
    } else {
      this.store.dispatch(new CreateShipToCustomer(this.formValue));
    }
  }

  cancel() {
    // this.store.dispatch(new ClearData());
    this.router.navigate(['pos/customers/search']);
  }

  getFilteredStateList(countryCode) {
    this.store.dispatch(new SelectCountry(countryCode));
  }
}
