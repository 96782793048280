import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@pos-app/core-ui';
import { ROUTE_URL } from '@pos-app/data';
import { ToastrService } from 'ngx-toastr';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  forgotPasswordForm: FormGroup;
  loading = false;
  error = '';

  returnUrl: string;
  alive = true;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private apiService: ApiService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  ngOnDestroy() {
    this.alive = false;
  }

  submit() {
    if (!this.forgotPasswordForm.valid) {
      this.forgotPasswordForm.markAllAsTouched();
      return;
    }

    this.loading = true;
    this.apiService
      .forgottenPassword(this.forgotPasswordForm.value)
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        () => {
          this.toastr.success('Password reset email sent');
          this.router.navigate([ROUTE_URL.login]);
          this.loading = false;
        },
        () => {
          this.loading = false;
          this.error = 'Oops! Something went wrong. Please try again';
        }
      );
  }
}
