import { Component, Input } from '@angular/core';
import { ProductDetail } from '../../models/service-proxies';

@Component({
  selector: 'app-product-product-summary',
  templateUrl: './product-summary.component.html',
  styleUrls: ['./product-summary.component.scss'],
})
export class ProductProductSummaryComponent {
  @Input() public product: ProductDetail;
  @Input() public hasAssociatedFittmentWarning: boolean;
  @Input() public hasSelectedVehicle: boolean;
  @Input() public isFitted: boolean;
}
