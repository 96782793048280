import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { CEDCategoryDto } from '../../models/service-proxies';

@Injectable()
export class TreeDataHelperService {
    constructor() { }
    private TreeCategory: CEDCategoryDto[];
    setTreeCategory(category: CEDCategoryDto[]) {
        this.TreeCategory = category;
        localStorage.setItem('TreeCategory', JSON.stringify(category));
    }
    getTreeCategory() {
        if (!this.TreeCategory) {
            const cacheCategory = localStorage.getItem('TreeCategory');
            if (cacheCategory) {
                this.TreeCategory = JSON.parse(cacheCategory);
                return this.TreeCategory;
            }
        }

        return this.TreeCategory;
    }
    lastActivityTimeStorageKey(lastActivityTimeStorageKey: any, arg1: (err: any, data: any) => void) {
        throw new Error('Method not implemented.');
    }
    controlValue(lastActivityTime: number) {
        throw new Error('Method not implemented.');
    }
    findNode(data, selector): any {
        let nodes = _.filter(data, selector);
        if (nodes && nodes.length === 1) {
            return nodes[0];
        }

        let foundNode = null;

        _.forEach(data, d => {
            if (!foundNode) {
                foundNode = this.findNode(d.children, selector);
            }
        });

        return foundNode;
    }

    findParent(data, nodeSelector) {
        let node = this.findNode(data, nodeSelector);
        if (!node) {
            return null;
        }

        return node.parent;
    }

    findChildren(data, selector) {
        let traverseChildren = function (node) {
            let names = [];
            if (node.children) {
                _.forEach(node.children, c => {
                    names.push(c.data.name);
                    names = names.concat(traverseChildren(c));
                });
            }
            return names;
        };

        let foundNode = this.findNode(data, selector);
        if (foundNode) {
            return traverseChildren(foundNode);
        } else {
            return [];
        }
    }
}
