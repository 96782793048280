<div class="container">
  <pos-app-spinner [show]="isSubmitting"></pos-app-spinner>
  <div *ngIf="totalFiles > 0" class="progress">
    Uploading {{ uploadingFilesProgress$ | async }}/{{ totalFiles }}
  </div>
  <div id="contentToConvert" class="px-5 pt-4 pb-5">
    <h3
      *ngIf="warrantySubmittedNumber"
      class="mt-5 text-center arb-large-heading"
    >
      WARRANTY NUMBER: {{ warrantySubmittedNumber }}
    </h3>
    <h3 class="text-center arb-large-heading">WARRANTY FORM</h3>
    <h5 *ngIf="warrantySubmittedNumber" class="text-center">
      {{ dateTimeStamp }}
    </h5>
    <app-warrantyform-header
      [warrantySubmittedNumber]="warrantySubmittedNumber"
      [loginDetails]="loginDetails$ | async"
      (headerValue)="handleSection(FORM_SECTION.header, $event)"
    ></app-warrantyform-header>
    <hr class="pt-2" />
    <div>
      <input
        type="checkbox"
        [(ngModel)]="isFitted"
        (change)="formValidity.vehicle = !isFitted; vehicleDetails = null"
      /><span class="px-2"></span>Are the warranty products fitted to a vehicle?
      <div *ngIf="isFitted">
        <app-warrantyform-vehicle
          [warrantySubmittedNumber]="warrantySubmittedNumber"
          (vehicleValue)="handleSection(FORM_SECTION.vehicle, $event)"
        ></app-warrantyform-vehicle>
      </div>
    </div>
    <hr class="pt-2" />
    <div>
      <input
        type="checkbox"
        [(ngModel)]="isOME"
        (change)="formValidity.ome = !isOME; omeDetails = null"
      /><span class="px-2"></span>Does the warranty include Old Man Emu shock
      absorbers?
      <div *ngIf="isOME">
        <app-warrantyform-ome
          (omeValue)="handleSection(FORM_SECTION.ome, $event)"
          [warrantyFaultList]="warrantyFaults$ | async"
        ></app-warrantyform-ome>
      </div>
    </div>
    <hr class="pt-2" />
    <div>
      <app-warrantyform-parts
        (partGrid)="handleSection(FORM_SECTION.originalParts, $event)"
        (replaceItem)="itemToReplace = $event"
      ></app-warrantyform-parts>
    </div>
    <hr class="pt-2" />
    <div>
      <app-warrantyform-parts
        (partGrid)="handleSection(FORM_SECTION.replacementParts, $event)"
        [isReplacement]="true"
        [itemToReplace]="itemToReplace"
      ></app-warrantyform-parts>
    </div>
    <div *ngIf="warrantyShowExpensesTF$ | async">
      <hr class="pt-2" />
      <div>
        <app-warrantyform-action
          (actionDetails)="handleSection(FORM_SECTION.action, $event)"
        ></app-warrantyform-action>
      </div>
    </div>

    <hr class="pt-2" />
    <div class="d-flex justify-content-end">
      <button
        class="btn text-white"
        [disabled]="isInvalidForm()"
        (click)="submitWarranty()"
      >
        SUBMIT
      </button>
    </div>
  </div>

  <ng-template #submitConfirmation let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h3 class="arb-large-heading" id="modal-basic-title">
        {{
          formSubmitStatus
            ? 'Warranty form submitted successfully'
            : 'Error when submitting the Warranty'
        }}
      </h3>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="d('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div>
        {{
          formSubmitStatus
            ? 'Warranty Number: ' + warrantySubmittedNumber
            : warrantySubmitErrorMessage
        }}
      </div>
      <u class="text-primary" type="button" (click)="convetToPDF()">
        EXPORT TO PDF
      </u>
    </div>
    <div class="modal-footer">
      <button class="btn text-white" type="button" (click)="d('Cross click')">
        Close
      </button>
    </div>
  </ng-template>
</div>
