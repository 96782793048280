import { createAction, props, union } from '@ngrx/store';

export const LOAD_LOOKUP_LISTS = '[EPC-Customer] Load lookup lists';
export const LOAD_LOOKUP_LISTS_SUCCESS = '[EPC-Customer] Load lookup lists success';
export const LOAD_LOOKUP_LISTS_FAILED = '[EPC-Customer] Load lookup lists failed';

export const LOAD_CUSTOMER_VEHICLES = '[EPC-Customer] Load customer vehicles';
export const LOAD_CUSTOMER_VEHICLES_SUCCESS = '[EPC-Customer] Load customer vehicles success';
export const LOAD_CUSTOMER_VEHICLES_FAILED = '[EPC-Customer] Load customer vehicles failed';

export const LOAD_ACTIVE_ORDER_HEADER = '[EPC-Customer] Load active order header';
export const LOAD_ACTIVE_ORDER_HEADER_SUCCESS = '[EPC-Customer] Load active order header success';
export const LOAD_ACTIVE_ORDER_HEADER_FAILED = '[EPC-Customer] Load active order header failed';

export const MAINTAIN_CUSTOMER_VEHICLE = '[EPC-Customer] Maintain a customer vehicle';
export const MAINTAIN_CUSTOMER_VEHICLE_SUCCESS = '[EPC-Customer] Maintain a customer vehicle success';
export const MAINTAIN_CUSTOMER_VEHICLE_FAILED = '[EPC-Customer] Maintain a customer vehicle failed';

export const ADD_CUSTOMER_VEHICLE = '[EPC-Customer] Add customer vehicle';
export const ADD_CUSTOMER_VEHICLE_SUCCESS = '[EPC-Customer] Add customer vehicle success';
export const ADD_CUSTOMER_VEHICLE_FAILED = '[EPC-Customer] Add customer vehicle';

export const SELECT_COUNTRY = '[EPC-Customer] Select country';
export const SET_SELECTED_VEHICLE = '[EPC-Customer] Set selected vehicle';

export const SELECT_CUSTOMER = '[EPC-Customer] Select customer';
export const SELECT_CUSTOMER_SUCCESS = '[EPC-Customer] Select customer success';
export const SELECT_CUSTOMER_FAILED = '[EPC-Customer] Select customer failed';

export const UPDATE_ORDER_VEHICLE = '[EPC-Customer] Update order vehicle';
export const UPDATE_ORDER_VEHICLE_SUCCESS = '[EPC-Customer] Update order vehicle success';
export const UPDATE_ORDER_VEHICLE_FAILED = '[EPC-Customer] Update order vehicle failed';

export const LoadCustomerVehicles = createAction(LOAD_CUSTOMER_VEHICLES, props<{ payload: any }>());
export const LoadCustomerVehiclesSuccess = createAction(LOAD_CUSTOMER_VEHICLES_SUCCESS, props<{ payload: any }>());
export const LoadCustomerVehiclesFailed = createAction(LOAD_CUSTOMER_VEHICLES_FAILED, props<{ payload: any }>());

export const SelectCountry = createAction(SELECT_COUNTRY, props<{ payload: string }>());

export const MaintainCustomerVehicle = createAction(MAINTAIN_CUSTOMER_VEHICLE, props<{ payload: any }>());
export const MaintainCustomerVehicleSuccess = createAction(MAINTAIN_CUSTOMER_VEHICLE_SUCCESS, props<{ payload: any }>());
export const MaintainCustomerVehicleFailed = createAction(MAINTAIN_CUSTOMER_VEHICLE_FAILED, props<{ payload: any }>());

export const LoadLookupLists = createAction(LOAD_LOOKUP_LISTS, props<{}>());
export const LoadLookupListsSuccess = createAction(LOAD_LOOKUP_LISTS_SUCCESS, props<{ payload: any }>());
export const LoadLookupListsFailed = createAction(LOAD_LOOKUP_LISTS_FAILED, props<{ payload: any }>());

export const SetActiveOrderVehicle = createAction(SET_SELECTED_VEHICLE, props<{ payload: any }>());

export const SelectCustomer = createAction(SELECT_CUSTOMER, props<{ payload: any}>());
export const SelectCustomerSuccess = createAction(SELECT_CUSTOMER_SUCCESS, props<{ payload: any }>());
export const SelectCustomerFailed = createAction(SELECT_CUSTOMER_FAILED, props<{ payload: any }>());

export const LoadActiveOrderHeader = createAction(LOAD_ACTIVE_ORDER_HEADER, props<{ payload: any }>());
export const LoadActiveOrderHeaderSuccess = createAction(LOAD_ACTIVE_ORDER_HEADER_SUCCESS, props<{ payload: any }>());
export const LoadActiveOrderHeaderFailed = createAction(LOAD_ACTIVE_ORDER_HEADER_FAILED, props<{ payload: any }>());

export const UpdateOrderHeader = createAction(UPDATE_ORDER_VEHICLE, props<{ payload: any }>());
export const UpdateOrderHeaderSuccess = createAction(UPDATE_ORDER_VEHICLE_SUCCESS, props<{ payload: any }>());
export const UpdateOrderHeaderFailed = createAction(UPDATE_ORDER_VEHICLE_FAILED, props<{ payload: any }>());

const all = union({
  LoadCustomerVehicles,
  LoadCustomerVehiclesSuccess,
  LoadCustomerVehiclesFailed,
  SelectCountry,
  MaintainCustomerVehicle,
  MaintainCustomerVehicleSuccess,
  MaintainCustomerVehicleFailed,
  LoadLookupLists,
  LoadLookupListsSuccess,
  LoadLookupListsFailed,
  SetActiveOrderVehicle,
  SelectCustomer,
  SelectCustomerSuccess,
  SelectCustomerFailed,
  LoadActiveOrderHeader,
  LoadActiveOrderHeaderSuccess,
  LoadActiveOrderHeaderFailed,
  UpdateOrderHeader,
  UpdateOrderHeaderSuccess,
  UpdateOrderHeaderFailed
});
export type CustomerActions = typeof all;