import { throwError, combineLatest } from 'rxjs';
import { mergeMap as _observableMergeMap, catchError as _observableCatch, catchError, retry } from 'rxjs/operators';
import { Observable, throwError as _observableThrow, of as _observableOf } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import {
  VehicleSearchParamters,
  VehicleSearchResult,
  VehicleDescriptionSearchParameter,
  VehicleDescriptionSearchResult,
} from '../+store/vehicles/vehicle';
import { SelectedVehicle } from '../models/userState.model';
import { AppConsts } from '../constants/AppConsts';
import { ItemAvaibility } from '../models/itemAvailability.model';
import {
  AddItemToOrderV2InputDto,
  IAddItemToOrderOutput,
  OrderHeaderOutput,
  CreateOrderSilentOutput,
} from '../models/addItemToOrder.model';
import { FetchItemPriceGroupOutput, FetchItemPriceGroupInput } from '../models/fetItemPriceGroup.model';
import { API_ENDPOINTS } from '@pos-app/data';

@Injectable()
export class OOEApiServiceProxy {
  private http: HttpClient;

  protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

  constructor(@Inject(HttpBackend) handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }

  updateSelectedVehicle(parameters: SelectedVehicle): Observable<object> {
    return this.post<object>(API_ENDPOINTS.updateSelectedVehicle, parameters);
  }

  fetchItemAvailability(itemNumber: string): Observable<ItemAvaibility> {
    return this.post<ItemAvaibility>(API_ENDPOINTS.fetchItemAvailability, { itemNumber });
  }

  addItemToOrder(input: AddItemToOrderV2InputDto): Observable<IAddItemToOrderOutput> {
    return this.post<IAddItemToOrderOutput>(API_ENDPOINTS.addItemToOrder, input);
  }

  createOrderSilent(): Observable<CreateOrderSilentOutput> {
    return this.post<CreateOrderSilentOutput>(API_ENDPOINTS.createOrderSilent);
  }

  searchVehicle(parameters: VehicleSearchParamters): Observable<VehicleSearchResult> {
    return this.post<VehicleSearchResult>(API_ENDPOINTS.vehicleSearch, { ...parameters, brand: AppConsts.brand });
  }

  getVehicleDescription(parameters: VehicleDescriptionSearchParameter): Observable<VehicleDescriptionSearchResult> {
    return this.post<VehicleDescriptionSearchResult>(API_ENDPOINTS.fetchVehicleDescription, {
      ...parameters,
      brand: AppConsts.brand,
    });
  }

  getOrderHeader(parameters): Observable<OrderHeaderOutput> {
    return this.post<OrderHeaderOutput>(API_ENDPOINTS.fetchOrderHeader, parameters);
  }

  fetchItemPriceGroup(input: FetchItemPriceGroupInput): Observable<FetchItemPriceGroupOutput> {
    return this.post<FetchItemPriceGroupOutput>(API_ENDPOINTS.fetchItemPriceGroup, input);
  }

  getPostcodes(): Observable<any> {
    return this.post<any>(API_ENDPOINTS.postcodes, { brand: AppConsts.brand });
  }

  getCustomerDetails(parameters) {
    return this.post<any>(API_ENDPOINTS.getCustomerDetails, parameters);
  }

  getCustomerVehicles(parameters) {
    return this.post<any>(API_ENDPOINTS.getCustomerVehicles, parameters);
  }

  maintainCustomerVehicle(parameters) {
    return this.post<any>(API_ENDPOINTS.maintainCustomerVehicle, parameters);
  }

  getLookupLists() {
    return combineLatest([
      this.post<any>(API_ENDPOINTS.countryLookup, {}),
      this.post<any>(API_ENDPOINTS.stateLookup, {}),
      this.post<any>(API_ENDPOINTS.colourLookup, {}),
    ]);
  }

  updateOrderHeader(parameters) {
    return this.post<any>(API_ENDPOINTS.updateOrderHeader, parameters);
  }

  private post<T>(url, params?): Observable<T> {
    let headers = this.getHeaders();
    return this.http
      .post<T>(AppConsts.ooeAPIBaseUrl + url, params, { headers })
      .pipe(retry(1), catchError(this.handleError));
  }

  private handleError(err) {
    if (err.error) {
      if (err.error.ErrorMessage) {
        if (err.error.ErrorMessage === 'Token not found' || err.status === 444) {
          localStorage.clear();
        }
        return throwError(err.error.ErrorMessage);
      }
    }
    return throwError(err);
  }

  private getHeaders() {
    const token = localStorage.getItem('jde-token');
    if (token) {
      return new HttpHeaders({
        tokentouse: `${token}`,
        ContentType: 'application/json',
      });
    }

    return new HttpHeaders({ ContentType: 'application/json' });
  }
}
