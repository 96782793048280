<div
  id="product-summary-section"
  class="product-summary arb-clear-text-for-table"
>
  <h3 class="arb-large-heading">{{ product.companyProductTitle }}</h3>
  <div class="row arb-field-label" style="margin-top: 5px">
    <div class="col-xs-12 col-md-6 col-lg-6 text-right">
      {{ product.brandName }}
      <span *ngIf="product.subBrand">, {{ product.subBrand }}</span>
    </div>
    <div class="col-xs-12 col-md-6 col-lg-6">
      ITEM # {{ product.companySKU }}
    </div>
  </div>
  <h5
    *ngIf="hasAssociatedFittmentWarning && !hasSelectedVehicle"
    class="note-message text-center"
  >
    SELECT VEHICLE TO CONFIRM FIT COMPATIBILITY
  </h5>
  <h5
    *ngIf="hasAssociatedFittmentWarning && hasSelectedVehicle && !isFitted"
    class="warning-message text-center"
  >
    THIS PART DOES NOT FIT YOUR SELECTED VEHICLE
  </h5>
  <h5
    *ngIf="product.note"
    class="text-center"
    style="text-transform: uppercase; margin: 5px"
  >
    {{ product.note }}
  </h5>
</div>
