export class AppConsts {

    // static readonly tenancyNamePlaceHolderInUrl = '{TENANCY_NAME}';

    // static remoteServiceBaseUrl: string;
    // static remoteServiceBaseUrlFormat: string;
    // static appBaseUrl: string;
    // static appBaseHref: string; // returns angular's base-href parameter value if used during the publish
    // static appBaseUrlFormat: string;
    // static recaptchaSiteKey: string;
    // static subscriptionExpireNootifyDayCount: number;

    // static epcApiKey: string;

    static ooeAPIBaseUrl: string;
    static brand: string;

    // static localeMappings: any = [];
    // static tenancyName: string;
    // static tenantId: number;
    
    // static readonly userManagement = {
    //     defaultAdminUserName: 'admin'
    // };

    // static readonly localization = {
    //     defaultLocalizationSourceName: 'PartsEcomerce'
    // };

    // static readonly authorization = {
    //     encrptedAuthTokenName: 'enc_auth_token'
    // };

    // static readonly grid = {
    //     defaultPageSize: 10
    // };

    static SUPPLEMENTARY_CHARGE_GROUP_NAME = "SUPPLEMENTARY CHARGE";
    static BUNDLE_CHARGE_GROUP_NAME = "BUNDLE CONTENTS";
}
