<div>
  <label>
    VEHICLE INFORMATION - REQUIRED IF WARRANTY PRODUCTS HAVE BEEN FITTED
  </label>
  <form [formGroup]="vehicleForm">
    <div class="row">
      <div class="col-3 my-auto ">
        <div class="d-flex align-middle">
          <label>VEHICLE *</label><span class="px-1"></span
          ><mat-icon
            (click)="onClearVehicle()"
            *ngIf="
              selectedVehicle || vehicleForm.controls.vehicleSelector.value
            "
            class="text-danger"
            >close</mat-icon
          >
        </div>
        <div *ngIf="!selectedVehicle">
          <a
            type="button"
            class="text-primary"
            (click)="onToggleVehicleSelector()"
          >
            {{
              useVehicleForm ? 'Use Search Vehicle Tool' : 'Use Vehicle Form'
            }}
          </a>
        </div>
      </div>
      <div [ngClass]="useVehicleForm ? 'col-4' : 'col-9'">
        <div>
          <div *ngIf="useVehicleForm; else vehicleSearch">
            <input
              [ngClass]="{
                'border border-danger rounded': !vehicleForm.controls
                  .vehicleShortDesc.value
              }"
              *ngIf="!selectedVehicle"
              class="form-control"
              formControlName="vehicleShortDesc"
              placeholder="Please enter Make, Model, Year, Engine, Trim"
            />
            <input
              [ngClass]="{
                'border border-danger rounded': !vehicleForm.controls
                  .vehicleSelector.value
              }"
              *ngIf="selectedVehicle"
              class="form-control"
              formControlName="vehicleSelector"
              readonly
              placeholder="Please enter Make, Model, Year, Engine, Trim"
            />
          </div>
          <ng-template #vehicleSearch>
            <div
              [ngClass]="{
                'border border-danger rounded': !vehicleForm.controls
                  .vehicleSelector.value
              }"
            >
              <app-vehicles-selection
                [hideCancel]="true"
                (submitCar)="onSelectVehicle($event)"
              ></app-vehicles-selection>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-3">
        <label>KMS *</label>
      </div>
      <div class="col-4">
        <input
          type="number"
          class="form-control"
          formControlName="currentOdometer"
          [ngClass]="{
            'border border-danger rounded':
              vehicleForm.controls.currentOdometer.errors
          }"
        />
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-3">
        <label
          >AFTERMARKET ACCESSORIES FITTED * ({{ accessoriesFittedCharCount }} /
          500)</label
        >
      </div>
      <div class="col-9">
        <textarea
          *ngIf="!warrantySubmittedNumber"
          class="form-control"
          formControlName="accessoriesFitted"
          maxlength="500"
          [class.is-invalid]="vehicleForm.controls.accessoriesFitted.errors"
        ></textarea>
        <div
          *ngIf="warrantySubmittedNumber"
          [ngStyle]="{ 'word-break': 'break-word' }"
        >
          {{ vehicleForm.controls.accessoriesFitted.value }}
        </div>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-3">
        <label>PRIMARY USE OF VEHICLE *</label>
      </div>
      <div class="col-4 d-flex my-auto">
        <input
          class="my-auto"
          type="radio"
          formControlName="businessOrPersonalUseBP"
          value="B"
        /><span class="px-1"></span>BUSINESS
        <span class="px-3"></span>
        <input
          class="my-auto"
          type="radio"
          formControlName="businessOrPersonalUseBP"
          value="P"
        /><span class="px-1"></span>PERSONAL
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-3">
        <label
          >VEHICLE CONDITION * ({{ vehicleConditionCharCount }} / 500)</label
        >
      </div>
      <div class="col-9">
        <textarea
          *ngIf="!warrantySubmittedNumber"
          class="form-control"
          formControlName="vehicleCondition"
          maxlength="500"
          [class.is-invalid]="vehicleForm.controls.vehicleCondition.errors"
        ></textarea>
        <div
          *ngIf="warrantySubmittedNumber"
          [ngStyle]="{ 'word-break': 'break-word' }"
        >
          {{ vehicleForm.controls.vehicleCondition.value }}
        </div>
      </div>
    </div>
  </form>
</div>
