import { Component, Input, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import {
  ItemAvaibilityItem,
  ItemAvaibility,
} from '../../models/itemAvailability.model';
import { ProductPriceItem } from '../../models/productPriceItem.model';
import { ProductRichContentDto } from '../../models/service-proxies';
import { ProductProductImagesComponent } from '../product-images/product-images.component';
import { PostcodeComponent } from '../postcode/postcode.component';
import { OOESessionService } from '../../../../../../../../libs/core-ui/src/lib/services/ooe-session.service';

@Component({
  selector: 'app-product-prices-stocks',
  templateUrl: './product-prices-stocks.component.html',
  styleUrls: ['./product-prices-stocks.component.scss'],
})
export class ProductProductPricesStocksComponent {
  @ViewChild('postCodeInputModal', { static: false })
  public postCodeInputModal: PostcodeComponent;
  @ViewChild('productImage', { static: true })
  public productImage: ProductProductImagesComponent;

  @Input() public stocks: ItemAvaibility;
  @Input() public basedPrices: ProductPriceItem[];
  @Input() public images: ProductRichContentDto[] = [];

  public get isUserloggedIn(): boolean {
    return this.ooeSessionService.loginedSession != null;
  }

  public get userPostCode(): string {
    return this.ooeSessionService.getUserPostCode().trim();
  }

  constructor(private ooeSessionService: OOESessionService) {}

  public getQuantity(item: ItemAvaibilityItem): string {
    if (this.stocks && item) {
      if ((this.stocks.availabilityMode = '1')) {
        let qtyAvailable = Number(item.qtyAvailable.replace(/,/g, ''));

        return isNaN(qtyAvailable)
          ? item.qtyAvailable
          : qtyAvailable.toString();
      }

      if ((this.stocks.availabilityMode = '2')) {
        let lowMediumHighValue = Number(
          item.lowMediumHighValue.replace(/,/g, '')
        );
        return isNaN(lowMediumHighValue)
          ? item.lowMediumHighValue
          : lowMediumHighValue.toString();
      }
    }

    return 'N/A';
  }

  public showPostCodePrompt(): void {
    this.postCodeInputModal.show();
  }
}
