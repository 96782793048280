import { createAction, props } from '@ngrx/store';
import { CoreUiEntity } from './core-ui.models';
import { BrandDefaults, Colour, LoadedGiftCard, OpenOrder } from '@pos-app/data';

export const loadCoreUi = createAction('[CoreUi] Load CoreUi');

export const loadCoreUiSuccess = createAction('[CoreUi] Load CoreUi Success', props<{ coreUi: CoreUiEntity[] }>());

export const hasUnsavedChanges = createAction('[CoreUi] Has Unsaved Changes', props<{ unsavedChanges: boolean }>());

export const loginSuccess = createAction('[CoreUi] Login Success', props<{ payload: any }>());

export const loadCoreUiFailure = createAction('[CoreUi] Load CoreUi Failure', props<{ error: any }>());

export const clearUnsavedChanges = createAction('[CoreUi] Clear Unsaved Changes', props<{ unsavedChanges: boolean }>());

export const navigateToUrl = createAction('[CoreUi] Navigate to Url', props<{ url: string }>());

export const createQuickSale = createAction('[CoreUi] Open quick sale');

export const searchCustomers = createAction('[CoreUi] Search customers', props<{ customerfuzzySearch: string; customerType: string }>());

export const searchCustomersSuccess = createAction('[CoreUi] Search customers success', props<{ result: any }>());

export const searchCustomersFailed = createAction('[CoreUi] Search customers failed', props<{ errors: any }>());

export const clearSearchData = createAction('[CoreUi] Clear search data');

export const hasActiveOrder = createAction(
  '[CoreUi] Has active order',
  props<{
    orderNumber: string;
    quickSaveAllowed?: boolean;
  }>()
);

export const setOpenOrder = createAction('[CoreUi] Set open order', props<{ openOrder: OpenOrder, quickSaveAllowed: boolean }>());

export const loadColourList = createAction('[CoreUi] Load colour list', props<{ colourList: Colour[] }>());

export const setSelectedVehicle = createAction(
  '[CoreUi] Set selected vehicle',
  props<{
    customerVehicleID: string;
    partsVehicleID: string | number;
    vehicleDescription: string;
    jdeVehicleID: string;
  }>()
);

export const setCashDrawer = createAction('[CoreUi] Set cash drawer', props<{ cashDrawer: string }>());

export const hasPendingPayments = createAction('[CoreUi] Has pending payments', props<{ hasPendingPayments: boolean }>());

export const loadBrandDefaults = createAction('[CoreUi] Load brand defaults');

export const loadBrandDefaultsSuccess = createAction('[CoreUi] Load brand defaults success', props<{ result: BrandDefaults }>());

export const loadBrandDefaultsFailed = createAction('[CoreUi] Load brand defaults failed', props<{ errors: any }>());

export const hasIncompleteOrder = createAction('[Action] Has incomplete order', props<{ isIncompleteOrder: boolean }>());

export const hasLoadedGiftCard = createAction('[Action] Set loaded gift cards', props<{ hasLoadedGiftCards: boolean }>());

export const resetAllFlags = createAction('[Action] Reset all flags');

export const getOverdueAlertCounts = createAction('[CoreUi] OverDue Task Alert Counts');
export const getOverdueAlertCountsSuccess = createAction(
  '[CoreUi] OverDue Task Alert Counts Success',
  props<{
    alertCount: any;
  }>()
);
export const getOverdueAlertCountsError = createAction('[CoreUi] OverDue Task Alert Counts Error', props<{ errors: any }>());

export const checkIsShowroomView = createAction('[CoreUi] Is Showroom View', props<{ isShowRoomView: boolean }>());

export const hasOrderChanged = createAction('[CoreUi] Has order changed', props<{ isOrderChanged: any }>());

export const parkedOrderListLength = createAction('[CoreUi] Parked order list length', props<{ parkedOrderListLength: number }>());
