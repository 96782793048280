import { HttpClient } from '@angular/common/http';
import {
  Injectable,
  Injector,
  InjectionToken,
  Optional,
  Inject,
  OnDestroy
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DrawerService } from './drawer/drawer.service';
import { ElectronService } from './electron/electron.service';

export interface IAppConfig {
  brand: string;
  backendUrl: string;

  enableTheme: boolean;
  themeCssUrl: string;
  favicon: string;

  dmsBackendUrl: string;
  isElectron: boolean;
  isCashDrawerAttached: boolean;
  hostName: string;
}

export const IS_PROD = new InjectionToken<string>('IS_PROD');
@Injectable({
  providedIn: 'root'
})
export class ConfigService implements OnDestroy {
  private configURL = '/assets/config/config.local.json';
  private prodConfigURL = '/assets/config/config.json';

  AppConfig: IAppConfig;
  isProd: boolean;
  unSubscribe$ = new Subject<void>();

  constructor(
    private http: HttpClient,
    private injector: Injector,
    private electronService: ElectronService,
    private drawerService: DrawerService,
    @Optional() @Inject(IS_PROD) isProd?: boolean
  ) {
    this.isProd = isProd;
    this.AppConfig = {
      backendUrl: '',
      dmsBackendUrl: '',
      brand: '',
      enableTheme: false,
      themeCssUrl: '',
      favicon: '',
      isElectron: false,
      isCashDrawerAttached: false,
      hostName: ''
    };
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  async loadAppConfig() {
    try {
      const result = await this.http
        .get<IAppConfig>(!this.isProd ? this.configURL : this.prodConfigURL)
        .toPromise();

      this.AppConfig.backendUrl = result.backendUrl;
      this.AppConfig.dmsBackendUrl = result.dmsBackendUrl;
      this.AppConfig.brand = result.brand;

      this.AppConfig.enableTheme = result.enableTheme;
      this.AppConfig.themeCssUrl = result.themeCssUrl;
      this.AppConfig.favicon = result.favicon;

      const isElectronApp = this.electronService.isElectron;
      if (isElectronApp) {
        this.AppConfig.isElectron = isElectronApp;
        this.drawerService.checkDevicesAvailability();
        this.drawerService.cashdrawerAvailability$
          .pipe(takeUntil(this.unSubscribe$))
          .subscribe(x => {
            this.AppConfig.isCashDrawerAttached = x.status;
            this.AppConfig.hostName = x.hostName;
          });
      }
    } catch {
      const toastr = this.injector.get(ToastrService);
      toastr.error('Please check that the POS config.json file is present!');
    }
  }
}
