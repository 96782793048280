<div class="py-4 app-forgotpassword background">
  <div class="background-overlay">
    <div class="row mt-30">
      <div class="col-xs-1 col-md-2"></div>
      <div class="col-xs-10 col-md-8">
        <div class="card">
          <div class="card-header text-white text-center">
            <h1 class="title-heading">EMAIL TEMPORARY PASSWORD</h1>
          </div>
          <div class="card-body text-white text-center">
            <p>
              Please enter your email address to send you a temporary password
              to use to login.
              <br />
              After you login with this temporary password you will be required
              to change it.
            </p>
            <form
              [formGroup]="forgotPasswordForm"
              class="login-container"
              (ngSubmit)="submit()"
            >
              <div class="formGroup pb-2 row">
                <div class="col-3">
                  <label for="username"
                    >EMAIL<span class="text-danger">*</span></label
                  >
                </div>
                <div class="col-9">
                  <input
                    type="username"
                    class="form-control"
                    placeholder="Email"
                    formControlName="email"
                  />
                </div>
              </div>
              <div
                *ngIf="
                  forgotPasswordForm.controls['email'].touched &&
                  forgotPasswordForm.controls['email'].errors
                "
                class="text-white row"
              >
                <div class="offset-3 col-9">
                  Valid email address is required.
                </div>
              </div>
              <div class="formGroup text-right pt-3">
                <span class="pr-5">
                  <button
                    type="submit"
                    class="btn text-white"
                    [disabled]="!forgotPasswordForm.valid || loading"
                  >
                    <pos-app-spinner2 *ngIf="loading"></pos-app-spinner2>
                    SEND EMAIL
                  </button>
                </span>
                <span class="px-2"></span>
                <button
                  type="button"
                  class="btn btn-cancel"
                  [routerLink]="['/pos/login']"
                >
                  CANCEL
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-xs-1 col-md-2 col-xl"></div>
    </div>
  </div>
</div>
