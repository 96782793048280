<div *ngIf="hasTrailerAttachmentChanged || hasTrailerDetailsChanged" class="d-flex justify-content-center row" style="height: 28px">
  <div class="col">
    <h6 class="arb-large-heading text-center text-danger p-0 m-0">Camper Trailer Info has unsaved changes, please save or cancel it.</h6>
  </div>
</div>
<form [formGroup]="camperServiceDetailsForm" (keydown.enter)="$event.preventDefault()">
  <div class="row py-2">
    <div class="col-6 p-2">
      <h6 class="arb-large-heading">SERVICE/WARRANTY HISTORY</h6>
    </div>
    <div class="col-2 header-label my-auto">
      <label class="d-flex text-danger">NEXT SERVICE DATE </label>
    </div>
    <div class="col-3 p-2">
      <input disabled [value]="nextServiceDate" class="form-control overflow-hidden no-decoration" />
    </div>
  </div>
  <div class="row" style="height: 28px">
    <div class="col">
      <span *ngIf="hasFormValueChanged || hasServiceAttachmentChanged" class="text-danger p-0 m-0">
        Service History has unsaved changes, please save or cancel.
      </span>
    </div>
  </div>
  <div class="row">
    <div class="col-2 header-label my-auto">
      <label class="d-flex">
        SERVICE DATE
        <mat-icon *ngIf="!showDatePicker" class="px-2" title="Add New Service Date" (click)="displayDatePicker()">add_circle_outline</mat-icon>
      </label>
    </div>
    <div *ngIf="showDatePicker" class="col-2 d-flex p-2">
      <input
        ngbDatepicker
        formControlName="serviceDate"
        class="form-control overflow-hidden"
        #d1="ngbDatepicker"
        (dateSelect)="onServiceDateSelected($event)"
      />
      <button class="btn btn-secondary calendar text-white px-2 py-3" (click)="d1.toggle()" type="button"></button>
    </div>
    <div *ngIf="!showDatePicker" class="col-2 d-flex p-2">
      <ng-select (change)="onServiceDateChanged($event)" formControlName="serviceDate" style="width: 100%">
        <ng-option *ngFor="let serviceDate of serviceHistoryDateList" [value]="serviceDate">
          {{ serviceDate }}
        </ng-option>
      </ng-select>
    </div>
  </div>
  <div class="row">
    <div class="col-2 header-label my-auto">
      <label class="d-flex">INVOICE/WARRANTY NO </label>
    </div>
    <div class="col-4 d-flex p-2">
      <input formControlName="invoiceWarrantyNumber" class="form-control overflow-hidden" />
    </div>
    <div class="col-2 header-label my-auto">
      <label class="d-flex">SERVICE STORE </label>
    </div>
    <div class="col-3 d-flex py-2">
      <ng-select formControlName="serviceStore" style="width: 100%">
        <ng-option *ngFor="let store of storeList" [value]="store.customerNumber"> {{ store.customerNumber }} ({{ store.customerName }}) </ng-option>
      </ng-select>
    </div>
  </div>
  <div class="row">
    <div class="col-2 header-label my-auto">
      <label class="d-flex">SALESPERSON</label>
    </div>
    <div class="col-4 d-flex p-2">
      <input formControlName="salesperson" class="form-control overflow-hidden" />
    </div>
    <div class="col-2 header-label my-auto">
      <label class="d-flex">SERVICE TECH </label>
    </div>
    <div class="col-3 d-flex py-2">
      <input formControlName="serviceTechnician" class="form-control overflow-hidden" />
    </div>
  </div>
  <div class="row">
    <div class="col-2 header-label my-auto">
      <label class="d-flex">DETAILS OF SERVICE</label>
    </div>
    <div class="col-6 d-flex p-2">
      <textarea formControlName="detailsOfService" class="form-control" style="resize: none" rows="3"></textarea>
    </div>
  </div>
  <div class="row">
    <div class="col-2 header-label my-auto">
      <label class="d-flex">PARTS REPLACED/USED</label>
    </div>
    <div class="col-6 d-flex p-2">
      <textarea formControlName="partsReplacedUsedInService" class="form-control" style="resize: none" rows="3"></textarea>
    </div>
  </div>
  <div class="row">
    <div class="col-2 header-label my-auto">
      <label class="d-flex">ATTACHED FILES</label>
    </div>
    <div class="col-6 my-auto">
      <button
        [disabled]="hasTrailerAttachmentChanged || hasTrailerDetailsChanged"
        (click)="invokeOpenServiceAttachments()"
        type="button"
        class="btn btn-secondary"
      >
        <small>SELECTED FILES</small>
        <span class="badge badge-light ml-1">{{ serviceAttachmentsLength }}</span>
      </button>
      <span class="px-2"><strong>FILES MUST BE LESS THAN 15MEG</strong></span>
    </div>
    <div class="col d-flex float-end justify-content-end">
      <button [disabled]="disableButton()" (click)="invokeCancelForm()" class="btn btn-cancel mx-1 px-2" type="button">CANCEL</button>
      <button
        [disabled]="disableButton() || camperServiceDetailsForm.invalid"
        (click)="invokeUpdateServiceHistory()"
        class="btn text-white mx-1 px-2"
        type="submit"
      >
        SAVE
      </button>
    </div>
  </div>
</form>
