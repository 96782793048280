<form [formGroup]="actionForm">
  <label>EXPENSES CLAIMED</label>
  <div class="row pt-2">
    <div class="col-2">
      <label>LABOUR</label>
    </div>
    <div class="col-2">
      <input
        class="form-control text-right"
        type="text"
        (change)="patchValue('labour', $event.target.value)"
        [value]="getAmount(actionForm.controls.labour.value) | currency"
        [class.is-invalid]="actionForm.controls.labour.errors?.pattern"
        (focus)="$event.target.select()"
      />
    </div>
    <div class="col-2">
      <label>FREIGHT</label>
    </div>
    <div class="col-2">
      <input
        class="form-control text-right"
        type="text"
        (change)="patchValue('freight', $event.target.value)"
        [value]="getAmount(actionForm.controls.freight.value) | currency"
        [class.is-invalid]="actionForm.controls.freight.errors?.pattern"
        (focus)="$event.target.select()"
      />
    </div>
    <div class="col-2">
      <label>OTHER</label>
    </div>
    <div class="col-2">
      <input
        class="form-control text-right"
        type="text"
        (change)="patchValue('other', $event.target.value)"
        [value]="getAmount(actionForm.controls.other.value) | currency"
        [class.is-invalid]="actionForm.controls.other.errors?.pattern"
        (focus)="$event.target.select()"
      />
    </div>
  </div>
</form>
