import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private isNavbarHidden$ = new BehaviorSubject<boolean>(false);
  private isFooterHidden$ = new BehaviorSubject<boolean>(false);
  private isComponentHidden$ = new BehaviorSubject<boolean>(false);
  private isSpotlightHidden$ = new BehaviorSubject<boolean>(false);

  constructor() {}

  setIsNavBarHidden(value: boolean) {
    this.isNavbarHidden$.next(value);
  }

  setIsFooterHidden(value: boolean) {
    this.isFooterHidden$.next(value);
  }

  setIsComponentHidden(value: boolean) {
    this.isComponentHidden$.next(value);
  }

  setIsSpotlightHidden(value: boolean) {
    this.isSpotlightHidden$.next(value);
  }

  getIsNavBarHidden() {
    return this.isNavbarHidden$.asObservable();
  }

  getIsFooterHidden() {
    return this.isFooterHidden$.asObservable();
  }

  getIsComponentHidden() {
    return this.isComponentHidden$.asObservable();
  }

  getIsSpotlightHidden() {
    return this.isSpotlightHidden$.asObservable().pipe(delay(100));
  }
}
