<div class="modal-header">
  <h3 class="arb-large-heading" id="modal-basic-title">BUYIN PRODUCT</h3>
</div>
<div class="modal-body">
  <form [formGroup]="supplierForm">
    <div class="row mb-2">
      <div class="col-4">
        <label>SUPPLIER</label>
      </div>
      <div class="col-8">
        <pos-app-fuzzy-search
          [apiEndpointKey]="'searchSuppliers'"
          [apiRequestKey]="'supplierFuzzySearch'"
          [valueKey]="'SupplierNumber'"
          [description1Key]="'SupplierName'"
          [placeholder]="'ENTER THE SUPPLIER CODE / NAME'"
          (selectedItem)="selectSupplier($event)"
          [searchStr]="supplierForm.controls.supplier.value.toString()"
          [isAutoFocus]="true"
        ></pos-app-fuzzy-search>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-4">
        <label>PRODUCT CODE - DESCRIPTION</label>
      </div>
      <div class="col-8">
        <input
          class="form-control"
          formControlName="productCode"
          [class.is-invalid]="
            supplierForm.controls.productCode.touched &&
            supplierForm.controls.productCode.errors
          "
        />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-4">
        <label>PURCHASE PRICE</label>
      </div>
      <div class="col-8">
        <input
          class="form-control"
          formControlName="unitPrice"
          [class.is-invalid]="
            supplierForm.controls.unitPrice.touched &&
            supplierForm.controls.unitPrice.errors?.pattern
          "
        />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-4">
        <label>SELL PRICE</label>
      </div>
      <div class="col-8">
        <input readonly class="form-control" formControlName="sellPrice" />
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <button
        class="btn text-white"
        (click)="handleNonStockItem()"
        [disabled]="
          !supplierForm.valid ||
          supplierForm.controls.supplier.value === 0 ||
            supplierForm.controls.supplier.value === ''
        "
      >
        SUBMIT
      </button>
      <button class="btn btn-cancel ml-3" (click)="activeModal.close()">
        LEAVE AS NONSTOCK
      </button>
    </div>
  </form>
</div>
