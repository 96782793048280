import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef, Output, EventEmitter, Input } from '@angular/core';

import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { distinctUntilChanged, debounceTime, tap, takeUntil, filter } from 'rxjs/operators';

import { MatChipInputEvent } from '@angular/material/chips';
import { ENTER } from '@angular/cdk/keycodes';

import {
  IVehicleState,
  LoadVehicles,
  getVehicleFilterDropdownValues,
  getStateLoaded,
  getStateSelectedVehicle,
  getStateTooManyVehicles,
  getStateVehicles,
  getStateSearchString,
  AdvancedSearchStatus,
  getStatePaRtsVehicleID,
} from '../../../epc/+store/vehicles';

import { ToastrService } from 'ngx-toastr';
import { manageFilters, getPatchValuesWhenRemovingPill } from '../../helper/vehicles-filters-helper';
import { FILTER_LABELS, Vehicle, getFilterKey } from '@pos-app/data';

@Component({
  selector: 'app-vehicles-selection',
  templateUrl: './vehicles-selection.component.html',
  styleUrls: ['./vehicles-selection.component.scss'],
})
export class VehiclesSelectionComponent implements OnInit, OnDestroy {
  @ViewChild('searchTextBox') public searchBox: ElementRef;

  @Input() public hideCancel: boolean;
  @Input() public fromEpc = false;

  @Output() public submitCar = new EventEmitter<Vehicle>();
  @Output() public cancelSelect = new EventEmitter<void>();

  public vehicleSearchForm: FormGroup;
  public selectedVehicle: Vehicle;
  public searchPills: string[] = [];
  public searchStringMapToFilter = new Object();
  public searchFormControl = new FormControl('');
  public isSubmitted = false;
  public previousFormValue: any;
  public vehicleSearchTextLabel: string;

  public vehicles$ = this.store.select(getStateVehicles);
  public vehicleFilterDropdownValue$ = this.store.select(getVehicleFilterDropdownValues);
  public isLoaded$ = this.store.select(getStateLoaded);
  public tooManyVehicles$ = this.store.select(getStateTooManyVehicles);

  public readonly separatorKeysCodes: number[] = [ENTER];

  private initialForm;
  private paRtsVehicleID: number;
  private currentEPCSelectedVehicle: Vehicle;

  private unSubscribe$ = new Subject<void>();

  constructor(
    private store: Store<{ vehicleState: IVehicleState }>,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private cdRef: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.vehicleSearchForm = this.formBuilder.group({
      year: [''],
      make: [''],
      model: [''],
      generation: [''],
      subModel: [''],
      chassis: [''],
      series: [''],
      bodyType: [''],
      driveType: [''],
      transmissionType: [''],
      engineCode: [''],
      engineType: [''],
      searchString: [''],
    });

    this.initialForm = this.vehicleSearchForm.value;
    this.store.dispatch(AdvancedSearchStatus({ payload: true }));

    this.store
      .select(getStateSearchString)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((searchString) => {
        this.vehicleSearchForm.controls['searchString'].setValue(searchString);
        if (searchString?.length > 0) {
          this.searchPills = searchString.split(' ');
        }
      });

    this.store
      .select(getStatePaRtsVehicleID)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((paRtsVehicleID) => {
        this.paRtsVehicleID = paRtsVehicleID;
      });

    this.store.dispatch(
      LoadVehicles({
        payload: this.paRtsVehicleID
          ? {
              ...this.vehicleSearchForm.value,
              partsVehicleID: this.paRtsVehicleID,
            }
          : this.vehicleSearchForm.value,
      })
    );

    this.vehicleFilterDropdownValue$
      .pipe(
        takeUntil(this.unSubscribe$),
        tap((allValues) => {
          if (allValues) {
            const { patchValues, suggestedFilters, initialLoad, updatedSearchPills, updatedSearchStringMapToFilter } = manageFilters(
              allValues,
              this.vehicleSearchForm.value,
              this.searchPills,
              this.searchStringMapToFilter
            );
            this.searchPills = updatedSearchPills;
            this.searchStringMapToFilter = updatedSearchStringMapToFilter;
            this.vehicleSearchForm.patchValue(
              {
                ...patchValues,
                searchString: this.searchPills.join(' ').trim(),
              },
              {
                emitEvent: false,
                onlySelf: true,
              }
            );
            this.getSuggestedLabel(suggestedFilters, initialLoad);
            this.previousFormValue = this.vehicleSearchForm.value;
          }
        })
      )
      .subscribe();

    this.store
      .select(getStateSelectedVehicle)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((selectedVehicle) => {
        if (this.fromEpc) {
          this.currentEPCSelectedVehicle = selectedVehicle;
        }

        if (selectedVehicle) {
          this.selectedVehicle = selectedVehicle;
        }
      });
    this.onChanges();
  }

  public ngOnDestroy(): void {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();

    this.store.dispatch(AdvancedSearchStatus({ payload: false }));
  }

  public selectVehicle(selectedVehicle: Vehicle): void {
    if (!this.selectedVehicle || this.selectedVehicle.VehicleID != selectedVehicle.VehicleID) {
      this.selectedVehicle = selectedVehicle;
    }
  }

  public addString(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.getSearchStringToSend(value);
    }

    if (input) {
      input.value = '';
    }
  }

  public removePill(stringToRemove: string): void {
    const { patchValues, updatedSearchStringMapToFilter } = getPatchValuesWhenRemovingPill(stringToRemove, this.searchStringMapToFilter);
    this.searchStringMapToFilter = updatedSearchStringMapToFilter;
    this.vehicleSearchForm.patchValue(
      {
        ...patchValues,
      },
      {
        emitEvent: false,
        onlySelf: true,
      }
    );
    this.searchPills = this.searchPills.filter((x) => x !== stringToRemove);
    this.vehicleSearchForm.controls.searchString.setValue(this.searchPills.join(' ').trim());
    this.cdRef.detectChanges();
    this.searchBox.nativeElement.focus();
  }

  public reset(): void {
    this.vehicleSearchForm.reset(this.initialForm);
    this.searchPills = [];
    this.isSubmitted = false;
  }

  public submit(): void {
    if (!this.fromEpc) {
      this.isSubmitted = true;
      this.submitCar.emit(this.selectedVehicle);
      return
    }

    if (!this.currentEPCSelectedVehicle || this.currentEPCSelectedVehicle.VehicleID != this.selectedVehicle.VehicleID) {
      this.isSubmitted = true;
      this.submitCar.emit(this.selectedVehicle);
      this.cancel();
    } else {
      this.toastrService.info('This is already your current selected vehicle');
    }
  }

  public cancel(): void {
    this.cancelSelect.emit();
  }

  private getSuggestedLabel(suggestedFilters: string, initialLoad: boolean): void {
    suggestedFilters = FILTER_LABELS[suggestedFilters];
    if (!initialLoad) {
      this.vehicleSearchTextLabel =
        !suggestedFilters || suggestedFilters === '' ? 'Vehicle Search' : `Vehicle Search - Suggestion: Enter ${suggestedFilters}`;
    } else {
      this.vehicleSearchTextLabel = 'Vehicle Search - Suggestion: Enter Year and Model';
    }
  }

  private getSearchStringToSend(value): void {
    this.searchPills.push(value.trim());
    this.vehicleSearchForm.controls.searchString.setValue((this.vehicleSearchForm.controls.searchString.value + ' ' + value).trim());
  }

  private onChanges(): void {
    this.vehicleSearchForm.valueChanges.pipe(takeUntil(this.unSubscribe$), distinctUntilChanged()).subscribe((value) => {
      Object.keys(value)
        .filter((key) => value[key] === null)
        .forEach((formKey) => {
          // when the value is null as being cleared by the select component
          // look for the matched pill and patch empty value to other dropdowns that linked to that pill
          // and remove the pill
          const key = getFilterKey(formKey);
          value[formKey] = '';
          const matchedPill =
            this.searchStringMapToFilter[key] === this.previousFormValue[formKey].toString() ? this.searchStringMapToFilter[key] : null;
          if (matchedPill) {
            const { patchValues, updatedSearchStringMapToFilter } = getPatchValuesWhenRemovingPill(matchedPill, this.searchStringMapToFilter);
            this.searchStringMapToFilter = updatedSearchStringMapToFilter;
            Object.keys(patchValues).forEach((k) => {
              value[k] = '';
            });
            this.searchPills = this.searchPills.filter((x) => x !== matchedPill);
            value.searchString = this.searchPills.join(' ').trim();
          }
        });
      this.selectedVehicle = null;
      value.year = value.year.toString();
      this.store.dispatch(LoadVehicles({ payload: value }));
      this.searchFormControl.patchValue('');
      this.isSubmitted = false;
    });

    this.searchFormControl.valueChanges
      .pipe(
        debounceTime(900),
        takeUntil(this.unSubscribe$),
        distinctUntilChanged(),
        filter((v) => (v || '').trim().length >= 2)
      )
      .subscribe((value) => {
        this.getSearchStringToSend(value);
      });
  }
}
