import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService, DialogService, ElectronService } from '@pos-app/core-ui';
import { ORDER_PAYMENT_TYPE, RegisterHost } from '@pos-app/data';
import { ToastrService } from 'ngx-toastr';
import { of, Subject } from 'rxjs';
import { filter, finalize, switchMap, takeUntil } from 'rxjs/operators';
import { CreditOriginalPayment, HistoricalPayment, OrderHeader, PaymentInfo, PaymentType, PendingPayment } from '../../+state/orders.models';
import { isEqual } from 'lodash';

@Component({
  selector: 'app-orders-payment',
  templateUrl: './orders-payment.component.html',
  styleUrls: ['./orders-payment.component.scss'],
})
export class OrdersPaymentComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('content') modalBox: TemplateRef<any>;
  @ViewChild('eftpos') eftposPayment: TemplateRef<any>;

  @Input() orderHeader: OrderHeader;
  @Input() paymentInfo: PaymentInfo;
  @Input() orderNumber: string;
  @Input() paymentTypes: PaymentType[];
  @Input() hasGiftCard: boolean;
  @Input() cashDrawer: RegisterHost;
  @Input() allowFakeEFTPOSTF: boolean;

  @Output() cashPayment = new EventEmitter<boolean>();
  @Output() pendingPaymentChange = new EventEmitter<any[]>();
  @Output() refetchPaymentInfo = new EventEmitter<void>();
  @Output() setOutstandingBalance = new EventEmitter<number>();
  @Output() next = new EventEmitter<void>();
  @Output() pollEftPos = new EventEmitter<boolean>();

  giftpendingId: string;
  eftposPendingId: string;

  paymentStackForm = new FormGroup({
    payments: new FormArray([]),
    pastPayments: new FormArray([]),
  });
  totalInvoicedValue = 0;
  totalPayments = 0;
  outstandingValue = 0;
  hasCashPayment: boolean;
  currentEditingPaymentIndex: number;
  currentSoldItemValue: number;
  minimumPayment: number;
  minimumCashPayment: number;
  totalDeferredValue: number;
  totalValue: number;
  creditOriginalPayments: CreditOriginalPayment[] = [];
  recommendedPayment: number;
  isSaving = false;
  isLoading: boolean = false;
  isElectron = this.electronService.isElectron;

  unSubscribe$ = new Subject<void>();

  get paymentsFormArray() {
    return this.paymentStackForm.get('payments') as FormArray;
  }

  get pastPaymentsFormArray() {
    return this.paymentStackForm.get('pastPayments') as FormArray;
  }

  get formEditing(): boolean {
    return (this.paymentsFormArray.getRawValue() || []).some((item) => item.focusing);
  }

  get formLoading(): boolean {
    return (this.paymentsFormArray.getRawValue() || []).some((item) => item.loading);
  }

  get formInvalid(): boolean {
    return this.paymentsFormArray.invalid;
  }

  get formDisabled(): boolean {
    return this.formInvalid || this.formLoading || this.formEditing;
  }

  get pendingPayments() {
    return (this.paymentsFormArray.getRawValue() || []).map((item) => {
      return {
        pendingId: item.pendingId,
        paymentType: item.paymentType,
        paymentInstrument: item.paymentInstrument,
        reference: item.reference,
        amount: +item.amount,
      };
    });
  }

  get eftpostPendingPayments() {
    return this.pendingPayments.filter((payment) => payment.paymentType === ORDER_PAYMENT_TYPE.eftpos);
  }

  constructor(
    private modalService: NgbModal,
    private apiService: ApiService,
    private toastr: ToastrService,
    private dialogService: DialogService,
    private electronService: ElectronService
  ) {}

  ngOnInit() {
    if (this.orderHeader.payableYN === 'N') {
      this.paymentStackForm.disable();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.paymentInfo) {
      this.initForm();
    }
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  initForm(): void {
    if (this.paymentInfo) {
      this.currentSoldItemValue = this.paymentInfo.totals?.currentValue;
      this.minimumPayment = this.paymentInfo.paymentAdvice.minimumPayment;
      this.minimumCashPayment = this.currentSoldItemValue;
      this.recommendedPayment = this.paymentInfo.paymentAdvice?.recommendedPayment;
      this.totalPayments = this.paymentInfo.totals.totalPayments;
      this.totalDeferredValue = this.paymentInfo.totals.deferredValue;
      this.totalValue = this.paymentInfo.totals.totalValue;
      this.outstandingValue = this.paymentInfo.totals.outstandingAmount;
      this.totalInvoicedValue = this.paymentInfo.totals.invoicedValue;
      this.creditOriginalPayments = this.paymentInfo.creditOriginalPayments;

      this.patchPastPaymentsForm(this.paymentInfo.historicalPayments || []);
      this.patchPendingPaymentsForm(this.paymentInfo.pendingPayments || []);

      this.checkHasCashPayment();
    }
  }

  patchPendingPaymentsForm(pendingPayments: PendingPayment[]): void {
    if (!pendingPayments?.length) {
      this.paymentsFormArray.clear({ emitEvent: false });
    } else {
      const formArrayValues = this.paymentsFormArray.getRawValue() || [];
      /* add/edit */
      pendingPayments?.forEach((pendingPayment, index) => {
        let formIndex = formArrayValues.findIndex((item) => pendingPayment._id === item.pendingId);

        if (formIndex < 0) {
          this.addPaymentToFormArray(pendingPayment, index);
        } else if (this.paymentsFormArray.controls[formIndex].value.loading) {
          this.updatePaymentInFormArray(pendingPayment, formIndex);
        }
      });
      /* remove */
      formArrayValues.forEach((item, formIndex) => {
        if (!pendingPayments.some((pendingPayment) => pendingPayment._id === item.pendingId)) {
          this.paymentsFormArray.removeAt(formIndex);
        }
      });
    }

    this.pendingPaymentChange.emit(this.pendingPayments);
  }

  addPaymentToFormArray(pendingPayment: PendingPayment, index?: number): void {
    const isEftpos = pendingPayment.type === ORDER_PAYMENT_TYPE.eftpos || pendingPayment.eftposTrReference?.trim().length > 0;
    const isGift = pendingPayment.type === ORDER_PAYMENT_TYPE.gift;

    const newPayment = new FormGroup({
      pendingId: new FormControl(pendingPayment._id || index.toString()),
      paymentType: new FormControl(pendingPayment.type, [Validators.required]),
      paymentInstrument: new FormControl(
        pendingPayment.type ? this.paymentTypes.find((item) => item.paymentTypeCode === pendingPayment.type)?.payInstrument || '' : ''
      ),
      reference: new FormControl(pendingPayment.reference),
      amount: new FormControl(Number.isInteger(pendingPayment.amount) ? +pendingPayment.amount : +pendingPayment.amount?.toFixed(2) || '', [
        Validators.required,
      ]),
      index: new FormControl(index),
      orderNumber: new FormControl(this.orderNumber),
      hideDeleteIcon: new FormControl(isEftpos),
      displayPaymentType: new FormControl(isEftpos ? pendingPayment.type : null),
      loading: new FormControl(false),
      focusing: new FormControl(false),
    });
    this.listenPaymentChange(newPayment);

    if (isEftpos || isGift) {
      newPayment.disable();
    }

    this.paymentsFormArray.push(newPayment, { emitEvent: false });
  }

  updatePaymentInFormArray(pendingPayment: PendingPayment, index?: number): void {
    const isEftpos = pendingPayment.type === ORDER_PAYMENT_TYPE.eftpos || pendingPayment.eftposTrReference?.trim().length > 0;
    const isGift = pendingPayment.type === ORDER_PAYMENT_TYPE.gift;

    this.paymentsFormArray.at(index).patchValue(
      {
        pendingId: pendingPayment._id,
        paymentType: pendingPayment.type,
        paymentInstrument: pendingPayment.type
          ? this.paymentTypes.find((item) => item.paymentTypeCode === pendingPayment.type)?.payInstrument || ''
          : '',
        reference: pendingPayment.reference,
        amount: Number.isInteger(pendingPayment.amount) ? +pendingPayment.amount : +pendingPayment.amount?.toFixed(2) || '',
        hideDeleteIcon: isEftpos,
        displayPaymentType: isEftpos ? pendingPayment.type : null,
        loading: false,
        focusing: false,
      },
      { emitEvent: false }
    );

    if (isEftpos || isGift) {
      this.paymentsFormArray.at(index).disable({ emitEvent: false });
    } else {
      this.paymentsFormArray.at(index).enable({ emitEvent: false });
    }
  }

  patchPastPaymentsForm(historicalPayments: HistoricalPayment[]): void {
    this.pastPaymentsFormArray.clear({ emitEvent: false });

    historicalPayments.forEach((pastPayment) => {
      const pastPaymentForm = new FormGroup({
        paymentType: new FormControl(pastPayment.type),
        paymentInstrument: new FormControl(
          pastPayment.type ? this.paymentTypes.find((item) => item.paymentTypeCode === pastPayment.type)?.payInstrument : ''
        ),
        reference: new FormControl(pastPayment.reference),
        amount: new FormControl(Number.isInteger(pastPayment.amount) ? +pastPayment.amount : +pastPayment.amount?.toFixed(2) || ''),
        date: new FormControl(pastPayment.date),
      });
      pastPaymentForm.disable({ emitEvent: false });
      this.pastPaymentsFormArray.push(pastPaymentForm, { emitEvent: false });
    });
  }

  onFocus(group: FormGroup): void {
    group.get('focusing').setValue(true, { emitEvent: false });
  }

  onBlur(group: FormGroup): void {
    /* emitEvent: true to trigger valueChange and call API */
    group.get('focusing').setValue(false);
  }

  addNewPayment(input?: any) {
    if (!input) {
      this.addPaymentToFormArray({} as PendingPayment, this.paymentsFormArray.getRawValue()?.length);
      this.pendingPaymentChange.emit(this.pendingPayments);
      return;
    }

    this.isSaving = true;
    this.apiService
      .savePendingPayments({
        registerID: this.cashDrawer?.registerID,
        orderNumber: this.orderNumber,
        payInstrument: input.payInstrument,
        amount: +input.amount || 0,
        paymentType: input.paymentType,
        reference: input.input,
      })
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe(() => {
        this.isSaving = false;
        this.refetchPaymentInfo.emit();
      });
  }

  getMinPaymentAmount() {
    const amount = Number(this.recommendedPayment || this.minimumPayment);
    return Math.abs(amount) > Math.abs(this.totalPayments) ? amount.toFixed(2) : '0';
  }

  listenPaymentChange(paymentItem: FormGroup) {
    paymentItem
      .get('paymentType')
      .valueChanges.pipe(
        filter(() => {
          return paymentItem.get('pendingId').value?.length < 10;
        }),
        takeUntil(this.unSubscribe$)
      )
      .subscribe((val: string) => {
        if (val) {
          const paymentInstrument = this.paymentTypes.find((x) => x.paymentTypeCode === val)?.payInstrument || '';
          paymentItem.get('paymentInstrument').setValue(paymentInstrument, { emitEvent: false });
        } else {
          paymentItem.get('amount').setValue(0, { emitEvent: false });
        }

        if (val === ORDER_PAYMENT_TYPE.gift) {
          if (this.hasGiftCard) {
            this.toastr.error('Cannot pay for a gift card with a gift card');
            paymentItem.patchValue({ paymentType: null }, { emitEvent: false });
          } else {
            this.giftpendingId = paymentItem.controls.pendingId.value;
            this.openModal(paymentItem, true);
          }
        }

        if (val === ORDER_PAYMENT_TYPE.eftpos) {
          this.eftposPendingId = paymentItem.controls.pendingId.value;
          this.openModal(paymentItem, false);
        }
      });

    paymentItem.valueChanges
      .pipe(
        filter(() => !this.paymentStackForm.pristine),
        takeUntil(this.unSubscribe$),
        switchMap((value) => {
          this.checkHasCashPayment();

          this.pendingPaymentChange.emit(this.pendingPayments);
          const oldValue = this.paymentInfo.pendingPayments.find((item) => item._id === value.pendingId);
          const isChanged =
            !isEqual(value.amount, oldValue?.amount) || !isEqual(value.paymentType, oldValue?.type) || !isEqual(value.reference, oldValue?.reference);

          if (
            paymentItem.valid &&
            isChanged &&
            !paymentItem.get('focusing').value &&
            value.paymentType !== ORDER_PAYMENT_TYPE.gift &&
            value.paymentType !== ORDER_PAYMENT_TYPE.eftpos
          ) {
            const input = {
              _id: value.pendingId,
              amount: +value.amount,
              payInstrument: value.paymentInstrument || '',
              paymentType: value.paymentType || '',
              reference: value.reference || '',
              orderNumber: this.orderNumber,
              registerID: this.cashDrawer?.registerID,
            };

            /* mark for updating */
            paymentItem.get('loading').setValue(true, { emitEvent: false });
            paymentItem.disable({ emitEvent: false });

            if (value.pendingId?.length > 10) {
              return this.apiService.updatePendingPayment(input);
            }
            return this.apiService.savePendingPayments(input);
          }
          return of(null);
        })
      )
      .subscribe((res) => {
        if (res) {
          this.refetchPaymentInfo.emit();
        }
      });
  }

  openModal(item, isGiftcard) {
    item.get('amount').markAsTouched();

    this.modalService.open(isGiftcard ? this.modalBox : this.eftposPayment, {
      size: isGiftcard ? 'xl' : 'lg',
      backdrop: isGiftcard ? true : 'static',
      keyboard: isGiftcard,
    });
  }

  trackByFn(index, formItem: FormGroup) {
    return formItem.value.pendingId;
  }

  removePayment(index) {
    const payments = this.paymentStackForm.get('payments') as FormArray;
    const pendingId = (payments.controls[index] as FormGroup).controls.pendingId.value;
    const paymentType = (payments.controls[index] as FormGroup).controls.paymentType.value;

    if (!pendingId || pendingId.toString().length < 10) {
      this.paymentsFormArray.removeAt(index);
      this.pendingPaymentChange.emit(this.pendingPayments);
      return;
    }

    if (paymentType === ORDER_PAYMENT_TYPE.gift) {
      this.toastr.error(
        'This gift card payment has already been processed by the gift card provider. To reverse the gift card payment load the balance on a new card for the customer and add it to the sales order (ARBGC)'
      );
      return;
    } else {
      (paymentType === ORDER_PAYMENT_TYPE.cash || !paymentType
        ? of(true)
        : this.dialogService.confirm(
            'ONLY DELETE THIS PAYMENT LINE IF YOU HAVE NOT DONE A TRANSACTION ON THE EFTPOS DEVICE. ARE YOU SURE YOU WANT TO DELETE THIS PAYMENT?',
            'DELETE PAYMENT CONFIRMATION'
          )
      )
        .pipe(
          switchMap((val) => {
            if (val) {
              return this.apiService.removePendingPayment({
                _id: pendingId,
              });
            }
            return of(null);
          }),
          takeUntil(this.unSubscribe$)
        )
        .subscribe((res) => {
          if (res) {
            this.paymentsFormArray.removeAt(index);
            this.refetchPaymentInfo.emit();
          }
        });
    }
  }

  handleGiftPayment(paymentDetails) {
    this.isLoading = true;
    if (paymentDetails) {
      const input = {
        _id: paymentDetails._id.length > 10 ? paymentDetails._id : null,
        amount: +paymentDetails.amount,
        reference: paymentDetails.reference.toString(),
        type: ORDER_PAYMENT_TYPE.gift,
        payInstrument: '?',
        paymentType: ORDER_PAYMENT_TYPE.gift,
        orderNumber: this.orderNumber,
      };

      (input._id ? this.apiService.updatePendingPayment(input) : this.apiService.savePendingPayments(input))
        .pipe(
          takeUntil(this.unSubscribe$),
          finalize(() => {
            this.isLoading = false;
            this.giftpendingId = null;
            this.refetchPaymentInfo.emit();
          })
        )
        .subscribe({
          next: (res) => {
            if (res?.ErrorFlag === '0') {
              this.toastr.success(res.ErrorMessage);
              return;
            }
            this.toastr.error(res?.ErrorMessage);
          },
          error: (error) => {
            this.toastr.error(error?.ErrorMessage);
          },
        });
    }
    this.modalService.dismissAll();
  }

  handleEftposPayment(paymentDetails) {
    this.isLoading = true;
    if (paymentDetails) {
      const input = {
        _id: paymentDetails._id.length > 10 ? paymentDetails._id : null,
        amount: +paymentDetails.amount,
        registerID: paymentDetails.registerID,
        orderNumber: paymentDetails.orderNumber,
        payInstrument: '?',
        paymentType: ORDER_PAYMENT_TYPE.eftpos,
        eftposDeviceName: paymentDetails.eftposDeviceName,
        reference: '',
        fakeTransactionType: paymentDetails.fakeTransactionType || null,
      };
      const paymentItemIndex = this.paymentsFormArray.getRawValue()?.findIndex((item) => item.pendingId === paymentDetails._id);
      if (paymentItemIndex >= 0) {
        this.paymentsFormArray.controls[paymentItemIndex].get('loading').setValue(true, { emitEvent: false });
        this.paymentsFormArray.controls[paymentItemIndex].disable({ emitEvent: false });
      }

      (input._id ? this.apiService.updatePendingPayment(input) : this.apiService.savePendingPayments(input))
        .pipe(
          takeUntil(this.unSubscribe$),
          finalize(() => {
            this.isLoading = false;
            this.eftposPendingId = null;
            this.refetchPaymentInfo.emit();
          })
        )
        .subscribe({
          next: (res) => {
            if (res?.ErrorFlag === '0') {
              this.toastr.success(res.ErrorMessage);
              return;
            }
            this.toastr.error(res?.ErrorMessage);
          },
          error: (error) => {
            this.toastr.error(error?.ErrorMessage);
          },
        });
    }
    this.modalService.dismissAll();
  }

  checkHasCashPayment() {
    const items = this.paymentsFormArray?.controls || [];
    const cashItems = items.filter((item) => item.get('paymentType').value === ORDER_PAYMENT_TYPE.cash);

    this.hasCashPayment = cashItems.length > 0;
    this.cashPayment.emit(this.hasCashPayment);
    this.setOutstandingBalance.emit(this.outstandingValue < 0 ? 0 : this.outstandingValue);
  }

  notAllowNewPayment() {
    // not allow adding more payment when there's a zero payment
    return this.formDisabled || this.paymentsFormArray.getRawValue().some((payment) => payment.paymentType === ORDER_PAYMENT_TYPE.eftpos);
  }
}
