import { GridOptions } from 'ag-grid-community';
import { ASSOCIATION_TYPE, ORDER_ITEM_NUMBER, ORDER_LINE_STATUS, ORDER_TYPE, roundingNumber } from '@pos-app/data';
import { getCurrencyValueFormat } from 'libs/core-ui/src/lib/utils/locale-helper';
import { takeUntil } from 'rxjs/operators';
import { OrderGridComponent } from './order-grid.component';

export const getGridDef = (showEPC: boolean, orderGrid: OrderGridComponent, hidePrice: boolean): GridOptions => {
  return {
    defaultColDef: {
      resizable: true,
    },
    columnDefs: [
      {
        colId: 'ooeLineNumber',
        hide: true,
      },
      {
        cellRenderer: 'actionRenderer',
        colId: 'action',
        width: 160,
        hide: orderGrid.isGridReadOnly,
        cellClass: 'cell-class',
        headerClass: 'header-class',
        suppressMenu: true,
        headerComponent: 'actionHeader',
        cellClassRules: {
          'action-highlighted': (params) => {
            return (
              orderGrid.dispatchList.findIndex((x) => x.id === params.data.id) !== -1 ||
              orderGrid.creditList.findIndex((x) => x.id === params.data.id) !== -1
            );
          },
        },
      },
      {
        headerName: 'CODE',
        field: 'itemNumber',
        colId: 'itemNumber',
        editable: (params) => {
          return (
            (params.data.itemNumber === ORDER_ITEM_NUMBER.newItem || !params.data.orderNumber) &&
            !orderGrid.isReadOnly &&
            !orderGrid.orderLoading &&
            !params.node.data.rowLoading
          );
        },
        rowDrag: (params) => {
          return params.data.itemNumber !== '' && params.data.parentLineNumber === 0 && params.data.orderNumber;
        },
        width: 200,
        cellRenderer: 'itemNumberCellRenderer',
        cellRendererParams: {
          showEPC,
        },
        cellClass: 'cell-class',
        headerClass: 'header-class',
      },
      {
        headerName: 'DESCRIPTION',
        field: 'itemDescription',
        colId: 'itemDescription',
        editable: (params) => {
          return (
            (params.data.itemNumber === ORDER_ITEM_NUMBER.subTotal || params.data.changeDescriptionAllowedYN === 'Y') &&
            params.data.readOnlyLineYN !== 'Y' &&
            params.data.lineStatus !== ORDER_LINE_STATUS.invoiced &&
            params.data.lineStatus !== ORDER_LINE_STATUS.cancelled &&
            !orderGrid.isReadOnly
          );
        },
        width: 250,
        cellClass: 'cell-class',
        headerClass: 'header-class',
        valueSetter: (params) => {
          if (params.newValue !== params.oldValue) {
            if (params.data['orderNumber']) {
              params.data = Object.assign([], params.data);
              params.data['itemDescription'] = params.newValue;
              orderGrid.cellEdited.emit({
                index: params.node.rowIndex,
                data: params.data,
              });
              return true;
            } else {
              params.data['itemDescription'] = params.newValue;
            }
          }
        },
        cellEditor: 'textCellEditorComponent',
      },
      {
        headerName: 'QTY',
        field: 'qtyOrdered',
        colId: 'qtyOrdered',
        editable: (params) => {
          return (
            params.data.readOnlyLineYN !== 'Y' &&
            params.data.itemNumber !== ORDER_ITEM_NUMBER.subTotal &&
            params.data.itemNumber !== ORDER_ITEM_NUMBER.buyin &&
            params.data.lineStatus !== ORDER_LINE_STATUS.invoiced &&
            params.data.lineStatus !== ORDER_LINE_STATUS.cancelled &&
            !params.data.parentLineNumber &&
            params.data.freeGoodsLineYN !== 'Y' &&
            !orderGrid.isReadOnly
          );
        },
        width: 70,
        cellClass: 'cell-class',
        headerClass: 'header-class',
        valueFormatter: (params) => {
          return params.data?.itemNumber === ORDER_ITEM_NUMBER.subTotal ? '' : params.value;
        },
        valueSetter: (params) => {
          if (+params.newValue !== +params.oldValue) {
            if (params.data['orderNumber']) {
              params.data = Object.assign([], params.data);
              params.data['qtyOrdered'] = params.newValue;
              params.data['extPriceInc'] = roundingNumber(+params.data['unitPriceInc'] * params.newValue, 2);
              params.data['extPriceEx'] = roundingNumber(+params.data['unitPriceEx'] * params.newValue, 2);
              orderGrid.cellEdited.emit({
                index: params.node.rowIndex,
                data: params.data,
                colKey: 'qtyOrdered',
              });
              return true;
            } else {
              params.data['qtyOrdered'] = params.newValue;
            }
          }
        },
        cellClassRules: {
          'error-cell': (params) =>
            params.data.lineStatus !== ORDER_LINE_STATUS.invoiced &&
            params.data.lineStatus !== ORDER_LINE_STATUS.cancelled &&
            params.data.itemNumber !== ORDER_ITEM_NUMBER.subTotal &&
            params.data.associationType !== ASSOCIATION_TYPE.mandatory &&
            +params.data.qtyOrdered === 0,
        },
        cellEditor: 'textCellEditorComponent',
        cellEditorParams: {
          type: 'number',
          isCreditOrder: orderGrid.isCreditOrder,
        },
      },
      {
        headerName: 'BO',
        colId: 'bo',
        field: 'qtyBackOrdered',
        width: 70,
        cellClass: 'cell-class',
        headerClass: 'header-class',
        valueFormatter: (params) => {
          return params.data?.itemNumber === ORDER_ITEM_NUMBER.subTotal ? '' : params.value;
        },
      },
      {
        headerName: 'UPRICE',
        colId: 'unitPrice',
        cellRenderer: 'orderLoadingCellRenderer',
        editable: (params) => {
          return (
            (!orderGrid.isExternalUser || (orderGrid.isExternalUser && orderGrid.orderHeader.orderType === ORDER_TYPE.quoteOrder)) &&
            params.data.orderNumber &&
            params.data.itemNumber &&
            params.data.readOnlyLineYN === 'N' &&
            params.data.overridePriceAllowedYN === 'Y' &&
            params.data.lineStatus !== ORDER_LINE_STATUS.invoiced &&
            params.data.lineStatus !== ORDER_LINE_STATUS.cancelled &&
            !orderGrid.isReadOnly
          );
        },
        valueGetter: (params) => {
          if (params.data.updatingRow?.trim() === 'ADD') {
            /* this to make cell detect change */
            return new Date().toISOString();
          }

          if (params.data?.itemNumber === ORDER_ITEM_NUMBER.subTotal) {
            return '';
          }

          const price = orderGrid.viewSettings.showExPrice ? params.data.unitPriceEx : params.data.unitPriceInc;
          return price === 0 ? '0' : orderGrid.displayPrice(price);
        },
        width: 120,
        hide: hidePrice,
        valueSetter: (params) => {
          //TODO: Thousand separator as space i.e. 68 000,678
          //DONE: Check if comma or dot as separator for thousand or comma for decimal, replace with dot
          params.newValue = getCurrencyValueFormat(params.newValue, orderGrid.locale);

          if (
            (orderGrid.viewSettings.showExPrice && +params.newValue !== params.data.unitPriceEx) ||
            (!orderGrid.viewSettings.showExPrice && +params.newValue !== params.data.unitPriceInc) ||
            params.newValue === ''
          ) {
            orderGrid
              .priceOverride(params)
              .pipe(takeUntil(orderGrid.unSubscribe$))
              .subscribe((res) => {
                if (res) {
                  const newExtPriceInc = params.data['qtyOrdered'] * res.newUnitPriceInc;
                  const newExtPriceEx = params.data['qtyOrdered'] * res.newUnitPriceEx;

                  params.data['unitPriceInc'] = roundingNumber(+res.newUnitPriceInc, 4);
                  params.data['unitPriceEx'] = roundingNumber(+res.newUnitPriceEx, 4);
                  params.data['extPriceInc'] = roundingNumber(newExtPriceInc, 2);
                  params.data['extPriceEx'] = roundingNumber(newExtPriceEx, 2);
                  params.data['overridePriceYN'] = res.overridePriceYN;
                  params.data['miscChargeYN'] = params.data.miscChargeYN;
                  orderGrid.cellEdited.emit({
                    index: params.node.rowIndex,
                    data: params.data,
                  });
                }
              });
            return true;
          }
          return false;
        },
        cellClass: 'cell-class price-class',
        headerClass: 'header-class',
        cellClassRules: {
          'price-overriden-cell': (params) => {
            return params.data.overridePriceYN === 'Y' && params.data.miscChargeYN !== 'Y';
          },
          'orange-cell': (params) => {
            return (
              params.data.unitPriceInc === 0 &&
              params.data.kitPC.trim() === '' &&
              params.data.freeGoodsLineYN !== 'Y' &&
              params.data.itemNumber !== ORDER_ITEM_NUMBER.subTotal
            );
          },
        },
      },
      {
        headerName: 'UOM',
        field: 'uom',
        width: 90,
        cellClass: 'cell-class',
        headerClass: 'header-class',
        valueFormatter(params) {
          if (params.data?.itemNumber === ORDER_ITEM_NUMBER.subTotal) {
            return '';
          }
          return params.value;
        },
      },
      {
        headerName: 'PRICE',
        colId: 'extPrice',
        width: 120,
        cellClass: 'cell-class price-class',
        headerClass: 'header-class',
        hide: hidePrice,
        valueGetter: (params) => {
          return orderGrid.viewSettings.showExPrice ? params.data['extPriceEx'] : params.data['extPriceInc'];
        },
        cellRenderer: (params) => {
          if (params.data.orderNumber || params.data.itemNumber === ORDER_ITEM_NUMBER.subTotal) {
            const price = orderGrid.viewSettings.showExPrice ? params.data.extPriceEx : params.data.extPriceInc;
            return price === 0 ? '0' : orderGrid.displayPrice(price);
          }
        },
        cellClassRules: {
          'price-overriden-cell': (params) => {
            return params.data.overridePriceYN === 'Y' && params.data.miscChargeYN !== 'Y';
          },
        },
      },
      {
        headerName: 'TAX Y/N',
        colId: 'tax',
        cellRenderer: 'checkboxRenderer',
        width: 100,
        cellClass: 'cell-class',
        headerClass: 'header-class',
      },
      {
        headerName: 'FIT',
        colId: 'fit',
        cellRenderer: 'checkboxRenderer',
        cellRendererParams: {
          isReadOnly: orderGrid.isReadOnly,
        },
        width: 90,
        cellClass: 'cell-class',
        headerClass: 'header-class',
        valueGetter: (params) => {
          return params.data.fittedYN && params.data.fittedYN.trim() ? params.data.fittedYN : 'N';
        },
      },
      {
        headerName: 'COLOUR',
        colId: 'colour',
        cellRenderer: 'colourRenderer',
        cellRendererParams: {
          isReadOnly: orderGrid.isReadOnly,
        },
        width: 150,
        cellClass: 'cell-class p-0',
        headerClass: 'header-class',
        autoHeight: true,
        valueGetter: (params) => {
          if (params.data.paintableYN === 'Y') {
            return params.data.colourCode && params.data.colourCode.trim() ? params.data.colourCode : 'UNPAINTED';
          } else {
            return '';
          }
        },
      },
      {
        headerName: 'AVAILABILITY',
        colId: 'availability',
        field: 'availability',
        cellRenderer: 'orderLoadingCellRenderer',
        valueGetter: (params) => {
          if (params.data.updatingRow?.trim()) {
            /* this to make cell detect change */
            return new Date().toISOString();
          }

          return orderGrid.availabilityGrid(params.data);
        },
        width: 150,
        cellClass: 'cell-class availability-cell',
        headerClass: 'header-class',
        tooltipValueGetter: (params) => {
          if (
            params.data.lineStatus !== ORDER_LINE_STATUS.invoiced &&
            params.data.lineStatus !== ORDER_LINE_STATUS.cancelled &&
            params.data.itemNumber !== ORDER_ITEM_NUMBER.newItem &&
            params.data.itemNumber !== ORDER_ITEM_NUMBER.subTotal &&
            params.data.nonStockLineYN !== 'Y' &&
            !orderGrid.isShowUser &&
            !orderGrid.isExternalUser
          ) {
            return 'Click to see detailed availability';
          }
        },
      },
      {
        headerName: 'ATP',
        colId: 'atpDescription',
        width: 150,
        hide: !orderGrid.viewSettings.showATP,
        cellRenderer: 'orderLoadingCellRenderer',
        valueGetter: (params) => {
          if (params.data.updatingRow?.trim()) {
            /* this to make cell detect change */
            return new Date().toISOString();
          }

          return params.data.atpDescription?.trim();
        },
        cellClass: 'cell-class availability-cell',
        headerClass: 'header-class',
        tooltipValueGetter: (params) => {
          if (
            params.data.lineStatus !== ORDER_LINE_STATUS.invoiced &&
            params.data.lineStatus !== ORDER_LINE_STATUS.cancelled &&
            params.data.itemNumber !== ORDER_ITEM_NUMBER.newItem &&
            params.data.itemNumber !== ORDER_ITEM_NUMBER.subTotal &&
            params.data.nonStockLineYN !== 'Y' &&
            !orderGrid.isShowUser &&
            !orderGrid.isExternalUser
          ) {
            return 'Click to see detailed availability';
          }
        },
      },
      {
        headerName: 'MESSAGES',
        colId: 'messages',
        resizable: true,
        cellClass: 'cell-class p-0',
        flex: 1,
        headerClass: 'header-class',
        cellRenderer: (params) => {
          let messageHtml = '';
          if (orderGrid.orderMessages) {
            const messages = orderGrid.orderMessages.filter((x) => x.lineNumber === params.data.ooeLineNumber);
            messages.forEach((message) => {
              messageHtml = `${messageHtml}<div class="${
                message.msgType === 'ERROR' ? 'cell-error' : message.msgType === 'WARN' ? 'cell-warning' : 'cell-success'
              } message">${message.message}</div>`;
            });
          }
          return messageHtml;
        },
      },
    ],
    stopEditingWhenGridLosesFocus: true,
    suppressRowDrag: orderGrid.isReadOnly,
  };
};
