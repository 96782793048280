import { Component, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'vehicle-update-modal',
  templateUrl: './vehicle-update-modal.component.html',
  styleUrls: ['./vehicle-update-modal.component.scss']
})
export class VehicleUpdateModalComponent {
  @ViewChild('vehicleUpdateModal', { static: true }) public modal: ModalDirective;

  @Input() public confirmationText: string;
  @Output() public okclick = new EventEmitter<void>();
  
  public show(): void {
    this.modal.show();
  }

  public confirm(): void {
    this.okclick.emit();
    this.close();
  }

  public close(): void {
    this.modal.hide();
  }
}
