<div class="product-list">
  <div
    *ngIf="existVehicleFitmentRule && (!selectedVehicle?.partsVehicleID || (selectedVehicle?.partsVehicleID && !useVehicleInFilter))"
    class="exist-fit-rule"
  >
    <div>
      Note: Vehicle fit rules exist for items in this group
      <span *ngIf="!selectedVehicle?.partsVehicleID"> - select a vehicle to confirm fitting compatibility </span>
      <span *ngIf="selectedVehicle?.partsVehicleID && !useVehicleInFilter">
        - tick the vehicle filter checkbox to confirm fitting compatibility
      </span>
    </div>
  </div>
  <!--<Primeng-Table-Start>-->
  <div class="primeng-datatable-container product-table">
    <div class="primeng-paging-container" [hidden]="primengTableHelper.totalRecordsCount == 0">
      <p-paginator
        #paginatorTop
        [rows]="primengTableHelper.recordsCountPerPage"
        [rowsPerPageOptions]="primengTableHelper.recordsCountPerPageOptions"
        [totalRecords]="primengTableHelper.totalRecordsCount"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{{ currentPageString }}"
        (onPageChange)="changePage($event, 'top')"
      >
      </p-paginator>
    </div>
    <p-table
      #dataTable
      [lazy]="true"
      (onLazyLoad)="getProducts($event)"
      [value]="primengTableHelper.records"
      rows="{{ primengTableHelper.recordsCountPerPage }}"
      [loading]="isLoading"
      [lazy]="true"
      [lazyLoadOnInit]="false"
      [scrollable]="false"
      [responsive]="primengTableHelper.isResponsive"
      [resizableColumns]="primengTableHelper.resizableColumns"
    >
      <ng-template pTemplate="body" let-record="$implicit" let-rowIndex="rowIndex">
        <tr *ngIf="rowIndex == 0" class="invisible-row">
          <td class="description"></td>
          <td class="stock"></td>
          <td class="price"></td>
          <td class="order"></td>
          <td class="image"></td>
        </tr>
        <tr *ngIf="isGroup(record)" class="product-group arb-medium-heading">
          <td colspan="5" class="ipad-none">
            <span title="{{ groupByColumns[record.level - 1] }}">{{ getGroupTitle(record) }}</span>
          </td>
          <td colspan="3" class="ipad-visible-cell">
            <span title="{{ groupByColumns[record.level - 1] }}">{{ getGroupTitle(record) }}</span>
          </td>
        </tr>
        <tr *ngIf="isInnerMostGroup(record)" class="product-row-header arb-field-label">
          <th class="description">Description</th>
          <th class="stock">Stock</th>
          <th class="price">
            Price
            <div class="enter-postcode link text-center" *ngIf="!isUserloggedIn && userPostCode" (click)="showPostCodePrompt()">Change Postcode</div>
          </th>
          <th class="order">Order</th>
          <th class="image"></th>
        </tr>
        <tr *ngIf="!isGroup(record)" class="product-row arb-clear-text-for-table">
          <td class="description">
            <div>
              <div class="product-title arb-field-label">
                {{ record.companyProductTitle }}
              </div>
              <div class="item-code text-uppercase">Item # {{ record.companySKU }}</div>
            </div>
            <div *ngIf="record.existMandatoryAssociation" class="mandatory-association-note">
              Mandatory associated items exist - please review in product details
            </div>
            <div *ngIf="record.isBundle" class="bundle-description text-uppercase">
              {{ getBundleDescription(record) }}
            </div>
            <div class="ipad-visible">
              <div class="product-image">
                <img appProductImage src="{{ record.richContentUrlThumb }}" alt="Product Image" (click)="openInFullScreen(record)" />
              </div>
              <div style="width: 170px">
                <span class="btn btn-primary btn-sm view-product-detail" (click)="viewProduct(record.companySKU)"> Product Details </span>
                <ng-container *ngIf="isUserloggedIn">
                  <div *ngIf="canAddProductToOrder(record) && hasValidOrderTypes()" class="order">
                    <div class="quantity">
                      <input type="number" maxlength="5" class="order-quantity" [(ngModel)]="productOrderOptions[record.companySKU].quantity" />
                      <span class="label">
                        {{ record.numberOfComponentsPerProduct }}
                      </span>
                    </div>
                    <button class="btn btn-sm text-white add-to-order arb-ok-button" (click)="addToOrder(record)">Add To Order</button>
                  </div>
                  <div *ngIf="!canAddProductToOrder(record)" class="text-uppercase">
                    {{ readOnlyEPCTF ? 'Unload enquiry order to add part to order' : 'Add to order from Product Details' }}
                  </div>
                </ng-container>
                <span *ngIf="!isUserloggedIn" class="no-active-order"> Login For Order </span>
              </div>
            </div>
          </td>
          <td class="stock">
            <div class="login-for-stock text-center" *ngIf="!isUserloggedIn">Login For Stock</div>
            <ng-container *ngIf="isUserloggedIn">
              <div class="stock" *ngIf="isLoadingPriceAndStock" class="retrieving"><i class="pi pi-spin pi-spinner"></i> Retrieving stocks..</div>
              <ng-container *ngIf="!isLoadingPriceAndStock">
                <div *ngIf="!getStocks(record)" class="stock">
                  <div>No stock info.</div>
                </div>
                <div *ngIf="getStocks(record) as itemStocks" class="stock">
                  <div class="stock-row" *ngFor="let item of itemStocks">
                    <span> {{ item.branchName ? item.branchName : item.branchCode }}: </span>
                    <span class="{{ item.quantity > 0 ? 'bold' : '' }} value">
                      {{ item.qtyAvailable | number }}
                    </span>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </td>
          <td class="price">
            <div class="price retrieving" *ngIf="isLoadingPriceAndStock && (isUserloggedIn || userPostCode != '')">
              <i class="pi pi-spin pi-spinner"></i> Retrieving prices..
            </div>

            <ng-container *ngIf="!isLoadingPriceAndStock && singleProductPrices[record.companySKU]">
              <div class="price no-price text-center" *ngIf="singleProductPrices[record.companySKU].length == 0">No price info.</div>
              <div class="price" *ngIf="singleProductPrices[record.companySKU].length > 0">
                <table class="text-uppercase base-price">
                  <tr *ngFor="let price of singleProductPrices[record.companySKU]">
                    <td style="font-size: 0.9em">
                      {{ price.priceScheduleDescription }}
                    </td>
                    <td class="text-right">
                      <ng-container *ngIf="price.currency === 'NZD'; else normalCurrency">
                        {{
                          price.taxedUnitPrice ? (price.taxedUnitPrice.toFixed(2) | currency : price.currency : 'symbol' : '1.0-2' : 'en-NZ') : 'N/A'
                        }}
                      </ng-container>
                      <ng-template #normalCurrency>
                        {{ price.taxedUnitPrice ? (price.taxedUnitPrice.toFixed(2) | currency : price.currency : 'symbol' : '1.0-2') : 'N/A' }}
                      </ng-template>
                    </td>
                  </tr>
                </table>
              </div>
            </ng-container>
            <div class="enter-postcode link" *ngIf="!isUserloggedIn && !userPostCode" (click)="showPostCodePrompt()">Enter postcode</div>
          </td>
          <td class="text-center order">
            <div>
              <span class="btn btn-primary btn-sm view-product-detail mb-2" (click)="viewProduct(record.companySKU)"> Product Details </span>
              <ng-container *ngIf="isUserloggedIn">
                <div *ngIf="canAddProductToOrder(record) && hasValidOrderTypes()" class="order">
                  <div class="quantity">
                    <input type="number" maxlength="5" class="order-quantity" [(ngModel)]="productOrderOptions[record.companySKU].quantity" />
                    <span class="label">
                      {{ record.numberOfComponentsPerProduct }}
                    </span>
                  </div>
                  <button class="btn btn-sm text-white add-to-order arb-ok-button" (click)="addToOrder(record)">Add To Order</button>
                </div>
                <div *ngIf="!canAddProductToOrder(record)" class="text-uppercase">
                  {{ readOnlyEPCTF ? 'Unload enquiry order to add part to order' : 'Add to order from Product Details' }}
                </div>
              </ng-container>
              <span *ngIf="!isUserloggedIn" class="no-active-order"> Login For Order </span>
            </div>
          </td>
          <td class="product-image image">
            <img appProductImage src="{{ record.richContentUrlThumb }}" alt="Product Image" (click)="openInFullScreen(record)" />
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">No Data</div>
    <div class="primeng-paging-container" [hidden]="primengTableHelper.totalRecordsCount == 0">
      <p-paginator
        #paginatorBottom
        [rows]="primengTableHelper.recordsCountPerPage"
        [rowsPerPageOptions]="primengTableHelper.recordsCountPerPageOptions"
        [totalRecords]="primengTableHelper.totalRecordsCount"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{{ currentPageString }}"
        (onPageChange)="changePage($event, 'bottom')"
      >
      </p-paginator>
    </div>
  </div>
  <!--<Primeng-Table-End>-->
</div>
<app-postcode #postCodeInputModal></app-postcode>
<p-confirmDialog header="Confirmation"></p-confirmDialog>
