<div *ngIf="showDescription || isInternal" class="pb-2">
  <label>ITEM:</label><span class="pl-2">{{ item.itemDescription1 }}</span
  ><br />
  <label>CURRENCY:</label><span class="pl-2">{{ item.customerCurrency }}</span>
</div>
<div>
  <table class="table table-striped table-bordered table-sm">
    <thead>
      <tr>
        <th>
          <label>PRICE SCALE</label>
        </th>
        <th *ngIf="!isExternal || displayOptions.displayIncTax || isInternal">
          <label>INC TAX </label>
        </th>
        <th *ngIf="!isExternal || displayOptions.displayExTax || isInternal">
          <label>EX TAX </label>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="isDisplayMyPrice">
        <td *ngIf="!isShowroomView || isInternal">My Price</td>
        <td *ngIf="(!isShowroomView && (!isExternal || displayOptions.displayIncTax)) || isInternal">
          {{ item.customerTaxedUnitPrice | currency : (displayOptions.displayCurrency || isInternal ? item.customerCurrency : '') }}
        </td>
        <td *ngIf="!isExternal || displayOptions.displayExTax || isInternal">
          {{ item.customerUnitPrice | currency : (displayOptions.displayCurrency ? item.customerCurrency : '') }}
        </td>
      </tr>
      <tr *ngFor="let price of item.priceList">
        <td>{{ price.priceScheduleDescription }} ({{ price.priceScheduleCode }})</td>
        <td *ngIf="!isExternal || displayOptions.displayIncTax || isInternal">
          {{ price.taxedUnitPrice | currency : (displayOptions.displayCurrency || isInternal ? price.currency : '') }}
        </td>
        <td *ngIf="!isExternal || displayOptions.displayExTax || isInternal">
          {{ price.unitPrice | currency : (displayOptions.displayCurrency || isInternal ? price.currency : '') }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div *ngIf="showCloseBtn" class="row">
  <div class="col-sm">
    <button class="btn btn-cancel mb-3 mr-3 float-right" (click)="closeModal()">CLOSE</button>
  </div>
</div>
