export const ALLOCATE_FITTER_TOASTR = {
  OrderSuccess: 'Orders have been fetched successfully',
  RenameGroupSuccess: 'New Group name has been assigned',
  OrderSplited: 'Order has been splited',
  Orderconsolidated: 'Order has been consolidated',
  ReassignLine: 'Array of lines added to new group',
  ChangeOrderBooking: ' order has been assigned to fitter: ',
  UnassignedOrderBooking: ' order has been unallocated successfully',
};

export const SERVER_RESPONSE_MESSAGES = {
  Success: 'success',
};

export const ALLOCATE_FITTER_UNIQUE_IDS = {
  OrderForBooking: 'ordersForBooking',
  ArrayOfLineDropZone: 'arrayOfLinesDropZone',
};

export const EMPTY_STRING = '';
export const NO_FITTER_RESOURCE_ID = '000000';
