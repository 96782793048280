<div *ngIf="!(isComponentHidden$ | async)" class="landing-container">
  <div class="top-search-container">
    <app-top-search></app-top-search>
  </div>
  <div class="top-order-status">
    <app-order-status-bar></app-order-status-bar>
  </div>
  <div class="main-landing-container">
    <div
      class="left-side-container"
      [hidden]="!isLeftSideOpened"
    >
      <span
        class="left-side-toggler"
        (click)="toggleLeftSide()"
        title="Click to toggle catalog"
        ><i class="pi pi-angle-double-left"></i
      ></span>
      <app-product-filter-list (selectCategory)="onSelectCategory($event)"></app-product-filter-list>
    </div>
    <div class="right-side-container">
      <span
        class="left-side-toggler"
        [hidden]="isLeftSideOpened"
        title="Open left side"
        (click)="toggleLeftSide()"
        ><i class="pi pi-angle-double-right"></i
      ></span>
      <app-breadcrumb></app-breadcrumb>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
