import { Injectable } from '@angular/core';
import {
  CanDeactivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Store } from '@ngrx/store';
import { take, map } from 'rxjs/operators';
import {
  getHasUnsavedChanges,
  CoreUiPartialState,
  DialogService,
  hasUnsavedChanges,
  MessageService
} from '@pos-app/core-ui';
import { Observable } from 'rxjs';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesCanDeactivateGuard
  implements CanDeactivate<CanComponentDeactivate> {
  constructor(
    private store: Store<CoreUiPartialState>,
    private dialogService: DialogService,
    private router: Router,
    private messageService: MessageService
  ) {}

  canDeactivate(
    component: CanComponentDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ) {
    if (component && component.canDeactivate) {
      return component.canDeactivate();
    }
    return this.store.select(getHasUnsavedChanges).pipe(
      map(val => {
        if (val) {
          this.dialogService
            .confirm(
              'Are you sure you want to leave this page? Select No to go back and save your update, or select Yes to leave this page and discard your changes.',
              'You have unsaved changes!'
            )
            .pipe(take(1))
            .subscribe(sel => {
              if (sel) {
                this.store.dispatch(
                  hasUnsavedChanges({ unsavedChanges: false })
                );
                this.messageService.dispatchAction(
                  hasUnsavedChanges({ unsavedChanges: false })
                );
                console.log(`User selected ${sel}`, nextState);
                this.router.navigateByUrl(nextState.url);
              }
            });
          return false;
        }
        return true;
      }, take(1))
    );
  }
}
