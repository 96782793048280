import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import {
  AuthService,
  CoreUiPartialState,
  ApiService,
  getIsLoggedIn
} from '@pos-app/core-ui';
import { ApiResponse, UserProfile } from '@pos-app/data';
import { of } from 'rxjs';
import { takeWhile, switchMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reset-password',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  form: FormGroup;
  loading = false;
  error = '';
  alive = true;
  returnUrl: string;
  isLoggedIn: boolean;
  userProfile: UserProfile;
  showLogin: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<CoreUiPartialState>,
    private router: Router,
    private apiService: ApiService,
    private authService: AuthService,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    const email = this.route.snapshot.queryParams.email;
    this.form = this.formBuilder.group({
      email: [email],
      oldPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    });
    this.store
      .pipe(select(getIsLoggedIn))
      .pipe(takeWhile(() => this.alive))
      .subscribe(isLoggedIn => (this.isLoggedIn = isLoggedIn));
  }

  ngOnDestroy() {
    this.alive = false;
  }

  submit() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.loading = true;
    this.apiService
      .resetPassword({
        email: this.form.controls.email.value,
        oldPassword: this.form.controls.oldPassword.value,
        newPassword: this.form.controls.newPassword.value
      })
      .pipe(
        takeWhile(() => this.alive),
        switchMap((x: ApiResponse) => {
          if (x.ErrorFlag === '1') {
            return of(x);
          } else {
            this.toastr.success(x.ErrorMessage);
            if (this.isLoggedIn) {
              return this.authService.logout();
            } else {
              return of(null);
            }
          }
        }),
        switchMap(res => {
          // When fail to reset password
          if (res && res.ErrorFlag === '1') {
            this.toastr.error(res.ErrorMessage);
            return of(null);
          } else {
            return this.authService.login(
              this.form.controls.email.value,
              this.form.controls.newPassword.value
            );
          }
        })
      )
      .subscribe(
        data => {
          if (!data) {
            return;
          } else if (data.ShowCodes.length > 0) {
            this.userProfile = data;
            this.showLogin = true;
          } else {
            this.redirectAfterLogin();
          }
        },
        error => {
          this.toastr.error(error.ErrorMessage);
          this.loading = false;
        }
      );
  }

  isInValid() {
    return (
      !this.form.valid ||
      this.form.controls.newPassword.value !==
        this.form.controls.confirmPassword.value
    );
  }

  redirectAfterLogin() {
    this.router.navigate(['/pos/login']);
  }
}
