import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY} from 'rxjs';
import { catchError, map, mergeMap} from 'rxjs/operators';
import { VehicleApiService } from '../../services/vehicle-api.service';
import { LoadVehiclesSuccess, LoadVehicles, SelectVehicle, DeselectVehicle, UseInFilter, ShowUniversalProducts, ClearRequestSelectVehicle } from './vehicles.actions';
import { VehicleSearchResult } from './vehicle';
import { SelectedVehicle } from '../../models/userState.model';
import { OOESessionService } from '../../../../../../../../libs/core-ui/src/lib/services/ooe-session.service';

@Injectable()
export class VehicleEffects {
  vehicle$ = createEffect(
    () => this.actions$.pipe(
      ofType(LoadVehicles),
      mergeMap((action) => {
        return this.vehicleService.searchVehicles(action.payload).pipe(
          map(res => {
            return LoadVehiclesSuccess({ payload: <VehicleSearchResult>res })
          }),
          catchError(error => EMPTY),
        )
      }
      )
    )
  );

  selectVehicle$ = createEffect(
    () => this.actions$.pipe(
      ofType(SelectVehicle),
      mergeMap((action) => {
        if (this.ooeSessionService.loginedSession && !!action.payload.VehicleID) {
          let vehicle = new SelectedVehicle();
          vehicle.partsVehicleID = action.payload.partsVehicleID;
          vehicle.customerVehicleID = action.payload.customerVehicleID || '';
          vehicle.jdeVehicleID = action.payload.VehicleID;
          vehicle.vehicleDescription = `${action.payload.Make} ${action.payload.Model} ${action.payload.Year} ` +
            `${action.payload.Generation} ${action.payload.Series} ${action.payload.SubModel} ` +
            `${action.payload.BodyType} ${action.payload.EngineType} ${action.payload.EngineCode} ` +
            `${action.payload.DriveType} ${action.payload.TransmissionType} ${action.payload.Chassis}`;

          this.vehicleService.updateSelectedVehicle(vehicle).subscribe();
        }

        return [UseInFilter({ payload: true }), ShowUniversalProducts({ payload: true }), ClearRequestSelectVehicle()];
      })
    ),
  );

  deselectVehicle$ = createEffect(
    () => this.actions$.pipe(
      ofType(DeselectVehicle),
      mergeMap(() => {
        if (this.ooeSessionService.loginedSession) {
          let vehicle = new SelectedVehicle();
          vehicle.partsVehicleID = '';
          vehicle.customerVehicleID = '';
          vehicle.jdeVehicleID = '';
          vehicle.vehicleDescription = '';

          this.vehicleService.updateSelectedVehicle(vehicle).subscribe();
        }

        return [UseInFilter({ payload: false }), ShowUniversalProducts({ payload: false })]
      })
    ),
  );

  constructor(
    private actions$: Actions,
    private vehicleService: VehicleApiService,
    private ooeSessionService: OOESessionService
  ) { }
}