import { Component, ViewChild, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Table } from 'primeng/table';
import { finalize, filter, debounceTime, skip, map, concatMap, withLatestFrom } from 'rxjs/operators';
import { ProductSearchInput, AttributeInput, AttributeInputWithRelationship, AssociatedItem } from '../../models/service-proxies';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { getStateSelectedVehicle, getStateUsedAsFilter, getStateShowedUniversalProducts } from '../../+store/vehicles';
import { getStateFilter, GlobalFilter, ResetFilterLoaded, LoadFilterData } from '../../+store/filter';
import { Vehicle } from '../../+store/vehicles/vehicle';
import { clone } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { Subject, combineLatest } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { IOOEOrder } from '../../models/userState.model';
import { OOEApiServiceProxy } from '../../services/ooe-api-service-proxy';
import { FetchItemPriceGroupItemPriceObject } from '../../models/fetItemPriceGroup.model';
import { AddItemToOrderV2InputDto } from '../../models/addItemToOrder.model';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService, LazyLoadEvent } from 'primeng/api';
import { Lightbox } from '@ngx-gallery/lightbox';
import { Gallery, ImageItem } from '@ngx-gallery/core';
import { EpcApiService } from '../../services/epc-api.service';
import { Group, ProductItem, ProductOrderOption } from '../../models/product-item.model';
import { PriceAndStock, GetPriceAndStockInput } from '../../models/price-and-stock.model';
import { IMainState } from '../../+store';
import { Paginator } from 'primeng/paginator';
import { AppConsts } from '../../constants/AppConsts';
import { PrimengTableHelper } from '../../shared/utils/PrimengTableHelper';
import { OOESessionService } from '../../../../../../../../libs/core-ui/src/lib/services/ooe-session.service';
import { MessageService } from 'libs/core-ui/src/lib/services';
import { PostcodeComponent } from '../../components/postcode/postcode.component';
import { PaginatorChange } from '../../models/paginator-change.model';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('dataTable', { static: true }) public dataTable: Table;
  @ViewChild('paginatorTop', { static: true }) public paginatorTop: Paginator;
  @ViewChild('paginatorBottom', { static: true }) public paginatorBottom: Paginator;
  @ViewChild('postCodeInputModal') public postCodeInputModal: PostcodeComponent;

  public existVehicleFitmentRule = false;
  public originalItems: ProductItem[] = [];
  public productOrderOptions: any = {};
  public groupByColumns: string[] = [];
  public currentPageString: string = '';
  public postcode: string;
  public priceAndStockItems: PriceAndStock[] = [];
  public singleProductPrices: any = {}; // list of product price groups by SKU. Used for display.
  public defaultProductImage: string = '/assets/img/ARB_logo.png'; //todo: get from db
  public openOrder: IOOEOrder;
  public globalSetting: GlobalFilter;
  public categoryId: number;
  public selectedVehicle: Vehicle;
  public useVehicleInFilter: boolean;
  public showUniversalProducts: boolean;
  public isLoading: boolean;
  public isFirstLoadCompleted: boolean;
  public isLoadingPriceAndStock: boolean;
  public primengTableHelper = new PrimengTableHelper();
  public readOnlyEPCTF: boolean;

  public get isUserloggedIn(): boolean {
    return this.ooeSessionService.loginedSession != null;
  }

  public get userPostCode(): string {
    return this.ooeSessionService.getUserPostCode().trim();
  }

  private componentDestroyed$ = new Subject<void>();

  constructor(
    private lightbox: Lightbox,
    private gallery: Gallery,
    private epcApiService: EpcApiService,
    private ooeSessionService: OOESessionService,
    private route: ActivatedRoute,
    private store: Store<IMainState>,
    private router: Router,
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private confirmationService: ConfirmationService,
    private ooeAPIServiceProxy: OOEApiServiceProxy,
    private messageService: MessageService,
    private location: Location
  ) {}

  public ngOnInit(): void {
    this.primengTableHelper.isResponsive = true;

    this.store.dispatch(ResetFilterLoaded());

    combineLatest([
      this.store.select(getStateUsedAsFilter),
      this.store.select(getStateSelectedVehicle),
      this.store.select(getStateShowedUniversalProducts),
    ])
      .pipe(
        takeUntil(this.componentDestroyed$),
        map((data) => {
          const [usedAsFilter, selectedVehicle, showedUniversalProducts] = data;

          this.useVehicleInFilter = usedAsFilter;
          this.selectedVehicle = selectedVehicle;
          this.showUniversalProducts = showedUniversalProducts;

          return data;
        }),
        debounceTime(1000),
        skip(1)
      )
      .subscribe((data) => {
        if (this.isFirstLoadCompleted) {
          this.getProducts();
        }
      });

    this.store
      .pipe(
        select(getStateFilter),
        filter((val) => val.updated),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((filter) => {
        if (JSON.stringify(this.globalSetting) != JSON.stringify(filter)) {
          if (this.globalSetting.cedCategoryId === filter.cedCategoryId) {
            this.globalSetting = filter;
            this.getProducts();
          }
        }
      });

    this.messageService
      .listen('postcodeUpdate')
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => this.refreshPricesWithPostCode());

    this.messageService
      .listen('userStateUpdate')
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => this.refreshPrice());

    this.route.url.pipe(withLatestFrom(this.route.params, this.route.queryParams)).subscribe(([url, params, queryParams]) => {
      this.categoryId = +params['id'];
      const page = queryParams['page'];

      this.loadData(page);
    });
  }

  public ngAfterViewInit(): void {
    if (this.ooeSessionService.loginedSession) {
      this.getOpenOrder();
      this.ooeSessionService.ooeUserState$.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => {
        this.getOpenOrder();
      });
    }
  }

  public getOpenOrder(): void {
    this.openOrder = this.ooeSessionService.openOrder;
    this.readOnlyEPCTF = this.openOrder?.readOnlyEPCTF;
  }

  public ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  public hasValidOrderTypes(): boolean {
    const session = JSON.parse(localStorage.getItem('jdeSession'));
    var returnV = true;
    if (!session || !session.ValidOrderTypes || session.ValidOrderTypes.length <= 0) returnV = false;
    return returnV;
  }

  public isGroup(item): boolean {
    return item.level;
  }

  public isInnerMostGroup(item): boolean {
    return this.groupByColumns.length == 0 || item.level == this.groupByColumns.length;
  }

  public getGroupTitle(record) {
    let groupTitle = '';
    let value = record[this.groupByColumns[record.level - 1]];

    switch (this.groupByColumns[record.level - 1]) {
      case 'brandName':
        groupTitle = this.guardUnavailableData(value, 'No Brand');
        break;
      case 'subBrand':
        groupTitle = this.guardUnavailableData(value, 'No Sub Brand');
        break;
      default:
        groupTitle = this.guardUnavailableData(value, 'No data');
        break;
    }

    return groupTitle;
  }

  public getBundleDescription(product: ProductItem) {
    let contents: string = 'Bundle contains: ';

    let allComponents = this.getBundleComponent(product);

    if (allComponents.length) {
      contents += allComponents
        .map((comp) => {
          return comp.companySKU + ' (' + comp.quantity + ')';
        })
        .join(', ');
    }

    return contents;
  }

  public canAddProductToOrder(product: ProductItem) {
    if (this.readOnlyEPCTF) {
      return false;
    }

    if (!product.isBundle) {
      return !product.isFittable && !product.existMandatoryAssociation;
    }

    return true;
  }

  public addToOrder(product: any) {
    let productOrderOption = this.productOrderOptions[product.companySKU];

    this.confirmationService.confirm({
      message:
        'Are you sure you want to add this item to your order? <br><br> <div style="text-align:center">Item: [' +
        product.companyProductTitle +
        '] </div><div style="text-align:center">Quantity: ' +
        productOrderOption.quantity +
        '</div>',
      acceptLabel: 'Yes',
      rejectLabel: 'Cancel',
      accept: () => {
        if (!this.ooeSessionService.hasActiveOrder) {
          this.createOrderSilent(product, productOrderOption);
        } else this.doAddToOrder(product, productOrderOption);
      },
    });
  }

  public getStocks(product: ProductItem) {
    if (this.priceAndStockItems && this.priceAndStockItems.length) {
      let item = this.priceAndStockItems.find((st) => st.itemCode == product.companySKU);
      return item ? item.stock : null;
    }

    return null;
  }

  public showPostCodePrompt() {
    this.postCodeInputModal.show();
  }

  public viewProduct(companySKU: string) {
    this.router.navigate(['product/' + encodeURIComponent(companySKU)], {
      relativeTo: this.route.parent,
    });
  }

  public openInFullScreen(record: ProductItem) {
    if (record.richContentUrl && record.richContentUrl != '') {
      const galleryRef = this.gallery.ref('productLightBox');
      galleryRef.setConfig({ counter: false, thumb: false });
      galleryRef.reset();
      galleryRef.load([
        new ImageItem({
          src: record.richContentUrl,
          thumb: record.richContentUrlThumb,
          name: record.richContentUrl,
        }),
      ]);

      this.lightbox.open(0, 'productLightBox');
    }
  }

  public changePage(event: PaginatorChange, position: 'top' | 'bottom'): void {
    if (!event) {
      return;
    }

    if (position == 'top') {
      this.paginatorBottom.first = event.first;
      this.paginatorBottom.updatePageLinks();
    } else {
      this.paginatorTop.first = event.first;
      this.paginatorTop.updatePageLinks();
    }

    if (this.primengTableHelper.recordsCountPerPage != event.rows) {
      this.primengTableHelper.recordsCountPerPage = event.rows;

      this.getProducts();
      return;
    }
    this.location.go(`/epc/category/${this.categoryId}?page=${event.page + 1}`);

    this.getProducts(event);
  }

  public getProducts(event?: LazyLoadEvent | Paginator, isFirstLoad = false) {
    if (!this.globalSetting || !this.globalSetting.cedCategoryId) {
      return;
    }
    if (this.primengTableHelper.shouldResetPaging(event)) {
      this.resetPaginator();
    }

    var input = new ProductSearchInput();

    input.brand = AppConsts.brand;
    input.priceList = this.ooeSessionService.priceLists;
    input.cedCategoryId = this.globalSetting.cedCategoryId;
    input.vehicleId = this.useVehicleInFilter && this.selectedVehicle ? Number(this.selectedVehicle.partsVehicleID) : undefined;
    input.showUniversalProducts = this.showUniversalProducts;

    if (this.globalSetting.brandIds && !!this.globalSetting.brandIds.length) {
      input.brandIds = this.globalSetting.brandIds;
    }
    if (this.globalSetting.categories && !!this.globalSetting.categories.length) {
      input.categories = this.globalSetting.categories;
    }
    if (this.globalSetting.subBrands && !!this.globalSetting.subBrands.length) {
      input.subBrands = this.globalSetting.subBrands;
    }

    if (this.globalSetting.attributes) {
      input.attributes = this.globalSetting.attributes.map((item) => {
        return new AttributeInput(item);
      });
    }
    if (this.globalSetting.attributesWithRelationship) {
      input.attributesWithRelationship = this.globalSetting.attributesWithRelationship.map((item) => {
        return new AttributeInputWithRelationship(item);
      });
      for (var index = 0; index < input.attributesWithRelationship.length; index++) {
        if (input.attributesWithRelationship[index].childItems) {
          input.attributesWithRelationship[index].childItems = input.attributesWithRelationship[index].childItems.map((childItem) => {
            return new AttributeInput(childItem);
          });
        }
      }
    }
    input.sorting = this.primengTableHelper.getSorting(this.dataTable);
    input.maxResultCount = this.primengTableHelper.getMaxResultCount(this.paginatorTop);
    input.skipCount = this.primengTableHelper.getSkipCount(this.paginatorTop);

    this.showProductLoadingSpinner(isFirstLoad);
    this.epcApiService
      .getProductList(input)
      .pipe(
        filter((result) => !!result.items),
        finalize(() => this.hideProductLoadingSpinner())
      )
      .subscribe((result) => {
        this.originalItems = result.items;

        if (!this.isFirstLoadCompleted) {
          this.isFirstLoadCompleted = true;
        }

        this.primengTableHelper.totalRecordsCount = result.totalCount;
        this.paginatorTop.updatePaginatorState();

        this.primengTableHelper.records = this.formatForGrouping(result.items, result.orderedGroupingFields);

        this.existVehicleFitmentRule = result.existVehicleFitmentRule; // to show the recommendation message

        /* get price and stock */
        if (result.items?.length > 0) {
          this.buildPricesAndStocks(result.items);
        }

        /* Handle paging */
        if (result.items.length <= 1) {
          this.currentPageString = result.items.length + ' Product';
        } else {
          this.currentPageString = input.skipCount + 1 + ' - ' + (input.skipCount + result.items.length) + ' of ' + result.totalCount + ' Products';
        }

        this.createOrderOption();
        this.hideProductLoadingSpinner();
      });

    if (event == null || isFirstLoad) {
      this.store.dispatch(LoadFilterData({ payload: { input, isFirstLoad } }));
    }
  }

  private resetPaginator() {
    this.paginatorTop.first = 0;
    this.paginatorBottom.first = 0;

    this.location.go(`/epc/category/${this.categoryId}?page=${1}`);
  }

  private loadData(page: string): void {
    this.globalSetting = {
      cedCategoryId: this.categoryId,
      updated: true,
    } as GlobalFilter;
    this.isFirstLoadCompleted = false;

    if (page) {
      this.paginatorTop.rows = this.primengTableHelper.recordsCountPerPage;
      this.paginatorTop.first = (Number(page) - 1) * this.paginatorTop.rows;

      this.paginatorBottom.rows = this.paginatorTop.rows;
      this.paginatorBottom.first = this.paginatorTop.first;

      this.getProducts(this.paginatorTop, true);
      return;
    }

    this.getProducts(null, true);
  }

  private createOrderOption() {
    this.productOrderOptions = {};

    for (let i = 0; i < this.originalItems.length; i++) {
      this.productOrderOptions[this.originalItems[i].companySKU] = new ProductOrderOption();
      this.productOrderOptions[this.originalItems[i].companySKU].quantity =
        +this.originalItems[i].quantityPerVehicle > 0 ? this.originalItems[i].quantityPerVehicle : 1;
    }
  }

  private formatForGrouping(items: ProductItem[], groupingFields: string[]) {
    let products: any[] = [];

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      let product = clone(item);

      products.push(product);
    }

    this.groupByColumns = [];
    for (let g = 0; g < groupingFields.length; g++) {
      switch (groupingFields[g]) {
        case 'BrandName':
          this.groupByColumns.push('brandName');
          break;
        case 'SubBrand':
          this.groupByColumns.push('subBrand');
          break;
        default:
          break;
      }
    }

    return this.addGroups(products, this.groupByColumns);
  }

  private addGroups(data: any[], groupByColumns: string[]): any[] {
    const rootGroup = new Group();
    return this.getSublevel(data, 0, groupByColumns, rootGroup);
  }

  private getSublevel(data: any[], level: number, groupByColumns: string[], parent: Group): any[] {
    if (level >= groupByColumns.length) {
      return data;
    }

    const groups = this.uniqueBy(
      data.map((row) => {
        const result = new Group();
        result.level = level + 1;
        result.parent = parent;
        for (let i = 0; i <= level; i++) {
          result[groupByColumns[i]] = row[groupByColumns[i]];
        }
        return result;
      }),
      JSON.stringify
    );

    const currentColumn = groupByColumns[level];
    let subGroups = [];
    groups.forEach((group) => {
      const rowsInGroup = data.filter((row) => group[currentColumn] === row[currentColumn]);
      group.totalCounts = rowsInGroup.length;
      const subGroup = this.getSublevel(rowsInGroup, level + 1, groupByColumns, group);
      subGroup.unshift(group);
      subGroups = subGroups.concat(subGroup);
    });

    return subGroups;
  }

  private uniqueBy(a, key) {
    const seen = {};
    return a.filter((item) => {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }

  private guardUnavailableData(value: any, defaultText: string) {
    if (value == null || value == '') {
      return defaultText;
    } else return value;
  }

  private buildPricesAndStocks(products: ProductItem[]): void {
    if ((!this.ooeSessionService.loginedSession && !this.ooeSessionService.getUserPostCode().trim()) || !products?.length) {
      return;
    }

    this.singleProductPrices = {};
    this.getPricesAndStocks(products);
  }

  private getPricesAndStocks(products: ProductItem[]) {
    const input: GetPriceAndStockInput = { itemCodes: [] };

    products.forEach((product) => {
      input.itemCodes.push({ itemCode: product.companySKU, quantity: 1 });
    });

    this.isLoadingPriceAndStock = true;
    this.epcApiService
      .getPriceAndStock(input)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (res) => {
          this.priceAndStockItems = res.items;

          products.forEach((item) => {
            this.singleProductPrices[item.companySKU] = this.buildItemPriceList(item.companySKU);
          });

          this.isLoadingPriceAndStock = false;
        },
        (err) => {
          this.isLoadingPriceAndStock = false;
          this.singleProductPrices = {};
        }
      );
  }

  private addItemToOrder(product: any, productOrderOption: any) {
    this.showProductLoadingSpinner();
    let productLine = new AddItemToOrderV2InputDto();
    productLine.orderNumber = this.openOrder.orderNumber.trim();
    productLine.itemNumber = product.companySKU;
    productLine.quantity = productOrderOption.quantity;
    productLine.overridePriceYN = 'N';
    productLine.useEPCYN = 'Y';
    productLine.fetchInventoryYN = 'Y';
    productLine.enforceMandatoryAssociationYN = 'Y';
    productLine.fitYN = productOrderOption.fitted ? 'Y' : 'N';
    productLine.paintYN = productOrderOption.painted ? 'Y' : 'N';
    productLine.unitPriceEx = 0;
    productLine.unitPriceInc = 0;
    productLine.sourceSystem = 'EPC';

    this.ooeAPIServiceProxy.addItemToOrder(productLine).subscribe((result) => {
      this.toastr.success('Item added');
      this.messageService.trigger('orderUpdated');
      this.hideProductLoadingSpinner();
    });
  }

  private getBundleComponent(product: any): AssociatedItem[] {
    return (product.associatedItems || []).filter((itm) => {
      return itm.associationType == 'Bundle Component';
    });
  }

  private createOrderSilent(product: any, productOrderOption: ProductOrderOption) {
    this.spinnerService.show(undefined, { fullScreen: true });

    this.ooeAPIServiceProxy.createOrderSilent().subscribe((result) => {
      this.spinnerService.hide();
      if (!result || result?.ErrorFlag == '1') {
        this.toastr.error(result.ErrorMessage);
        return;
      }

      this.toastr.success('Order Created');

      this.ooeSessionService
        .fetchUserState()
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(({ OpenOrder }) => {
          this.openOrder = OpenOrder;
          this.doAddToOrder(product, productOrderOption, true);
          return;
        });
    });
  }

  private doAddToOrder(product: any, productOrderOption: ProductOrderOption, isCreatedOrderSilent: boolean = false) {
    if (!this.ooeSessionService.hasActiveOrder && isCreatedOrderSilent == false) {
      this.createOrderSilent(product, productOrderOption);
      return;
    }

    //Add main product and mandatory items
    if (productOrderOption.quantity == undefined && productOrderOption.quantity == 0) {
      productOrderOption.quantity = 1;
    }

    // DungNP update 21 July: AddItemToOrderV2 can auto inspect the bundle content, so just add the bundle code to order is enough
    this.addItemToOrder(product, productOrderOption);
  }

  // for display
  private buildItemPriceList(itemCode: string) {
    const productItem = this.priceAndStockItems.find((item) => item.itemCode == itemCode);

    return (productItem.priceList || []).map((priceItem) => {
      return new FetchItemPriceGroupItemPriceObject({
        priceSchedule: priceItem.priceScheduleCode,
        priceScheduleDescription: priceItem.priceScheduleDescription,
        currency: priceItem.currency,
        unitPrice: priceItem.exTaxUnitPrice ? Number(priceItem.exTaxUnitPrice) : null,
        taxedUnitPrice: priceItem.taxedUnitPrice ? Number(priceItem.taxedUnitPrice) : null,
        taxedYN: priceItem.taxedYN,
        quantity: priceItem.quantity ? Number(priceItem.quantity) : null,
        priceLabel: priceItem.priceScheduleDescription,
      });
    });
  }

  private showProductLoadingSpinner(fullScreen = true) {
    this.isLoading = true;
    if (fullScreen) {
      this.spinnerService.show(undefined, { fullScreen: true });
    }
  }

  private hideProductLoadingSpinner() {
    this.isLoading = false;
    this.spinnerService.hide();
  }

  private refreshPricesWithPostCode() {
    if (!this.isUserloggedIn) {
      this.buildPricesAndStocks(this.originalItems);
    }
  }

  private refreshPrice() {
    this.buildPricesAndStocks(this.originalItems);
  }
}
