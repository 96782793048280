<pos-app-spinner [show]="isBusy$ | async"></pos-app-spinner>
<div class="container-fluid py-5" *ngIf="{ values: lookupLists$ | async } as lookupLists">
  <div class="text-center">
    <h3 class="pt-3 arb-large-heading">MANAGE REGISTERED USERS</h3>
    <p>Use this screen to manage your USER's access to ARB Online</p>
  </div>
  <div class="card shadow-sm mt-4">
    <div class="card-body">
      <div class="row pb-4">
        <div class="col-1 my-auto">
          <label>CUSTOMER</label>
        </div>
        <div class="col-4">
          <pos-app-lib-customer-search
            [customerType]="'billto'"
            (selectNewCustomer)="selectCustomer($event)"
            [forOrderSearch]="true"
            [selectedCustomerName]="
              selectedCustomer ? selectedCustomer?.CustomerName + ' (' + selectedCustomer?.CustomerNumber + ')' : selectedCustomerName || ''
            "
            [disabled]="preSelectedCustomerNumber"
          ></pos-app-lib-customer-search>
        </div>
        <ng-template [ngIf]="!preSelectedCustomerNumber">
          <div class="col-1 my-auto">
            <label>USER</label>
          </div>
          <div class="col-4">
            <ng-select (change)="onSelectUser($event)">
              <ng-option *ngFor="let user of externalUserList" [value]="user">
                {{ user.name }} - {{ user.email }} ({{ user.parentCustomerNumber }})
              </ng-option>
            </ng-select>
          </div>
        </ng-template>
      </div>
      <form [formGroup]="globalUserSecurityForm" *ngIf="selectedCustomer">
        <div class="row w-100">
          <div class="col-3">
            <label>HOME BRANCH</label>
            <ng-select formControlName="branch">
              <ng-option *ngFor="let branch of lookupLists.values?.branchList" [value]="branch.BranchCode">
                <span class="pe-1" *ngIf="branch.BranchCode">{{ branch.BranchCode }} -</span>
                {{ branch.BranchDescription }}
              </ng-option>
            </ng-select>
          </div>
          <div class="col-6">
            <label>INVENTORY BRANCH</label>
            <ng-select formControlName="inventoryBranches" [multiple]="true" [searchable]="true">
              <ng-option *ngFor="let branch of lookupLists.values?.branchList" [value]="branch.BranchCode">
                <span class="pe-1" *ngIf="branch.BranchCode">{{ branch.BranchCode }} -</span>
                {{ branch.BranchDescription }}
              </ng-option>
            </ng-select>
          </div>
          <div class="col-3">
            <div class="mt-3 ms-2">
              <input class="form-check-input" type="checkbox" formControlName="ARBuAvailableYN" />
              <label class="ps-2">ARB-U AVAILABLE</label>
            </div>
          </div>
        </div>
        <div class="row w-100 mt-3">
          <div class="col-3">
            <label>INVENTORY VIEW</label>
            <ng-select formControlName="inventoryView">
              <ng-option *ngFor="let inventoryView of lookupLists.values?.inventoryViewList" [value]="inventoryView.Code">
                <span class="pe-1" *ngIf="inventoryView.Code">{{ inventoryView.Code }} -</span>
                {{ inventoryView.Description }}
              </ng-option>
            </ng-select>
          </div>
          <div class="col-6">
            <label>VALID ORDER TYPES</label>
            <ng-select
              formControlName="validOrderTypes"
              [items]="lookupLists.values?.orderTypeList"
              [multiple]="true"
              bindLabel="OrderTypeDescription"
              bindValue="OrderTypeCode"
              [searchable]="true"
            >
            </ng-select>
          </div>
          <div class="col">
            <button class="btn text-white mt-2 float-end" (click)="updateGlobalSetting()" [disabled]="!globalUserSecurityForm.valid">UPDATE</button>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-3">
            <input class="form-check-input" type="checkbox" formControlName="displayCurrencyYN" />
            <label class="ps-2">SHOW CURRENCY FOR PRICES</label>
          </div>
          <div class="col-3">
            <input class="form-check-input" type="checkbox" formControlName="displayIncTaxYN" />
            <label class="ps-2">SHOW INC TAX PRICES</label>
          </div>
          <div class="col-3">
            <input class="form-check-input" type="checkbox" formControlName="displayExTaxYN" />
            <label class="ps-2">SHOW EX TAX PRICES</label>
          </div>
          <div class="col-3">
            <input class="form-check-input" type="checkbox" formControlName="showNationalInventoryYN" />
            <label class="ps-2">SHOW NATIONAL INVENTORY</label>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="card shadow-sm mt-4">
    <div class="card-body">
      <div *ngIf="selectedCustomer && !(isCustomerRegistered$ | async) && !(isBusy$ | async)" class="text-danger mb-4">
        Customer is not registered yet. Enter details at the top of the screen and press UPDATE button to register customer before adding users
      </div>
      <form [formGroup]="registerUserForm" class="login-container">
        <div class="row">
          <div class="col-1 my-auto">
            <label for="email">EMAIL</label>
          </div>
          <div class="col-4">
            <input type="email" class="form-control" placeholder="Email" formControlName="email" />
          </div>
          <div class="col-1 my-auto">
            <label for="email">NAME</label>
          </div>
          <div class="col-4">
            <input type="text" class="form-control" placeholder="Name" formControlName="name" />
          </div>
          <div class="col-2">
            <button
              class="btn text-white float-end"
              (click)="registerUser()"
              [disabled]="!registerUserForm.valid || !selectedCustomer || !(isCustomerRegistered$ | async)"
            >
              REGISTER
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="card shadow-sm mt-4">
    <div class="card-body">
      <form>
        <div class="d-flex flex-row justify-content-around">
          <div class="d-flex">
            <input class="form-check-input" type="checkbox" [formControl]="disabledUserSearch" />
            <label class="ps-3">SHOW DISABLED USERS</label>
          </div>
          <div class="d-flex"><label class="pe-3">FILTER EXISTING USERS</label><input [formControl]="registeredUserSearch" /></div>
        </div>
        <div class="table-responsive tableFixHead">
          <table class="border my-4 table table-bordered">
            <thead>
              <tr>
                <th scope="col" class="text-uppercase text-center">
                  <label>Action</label>
                </th>
                <th scope="col" class="text-uppercase text-center">
                  <label>Email</label>
                </th>
                <th scope="col" class="text-uppercase text-center">
                  <label>Name</label>
                </th>
                <th scope="col" class="text-uppercase text-center">
                  <label>Date Created</label>
                </th>
                <th scope="col" class="text-uppercase text-center">
                  <label>Created By</label>
                </th>
                <th scope="col" class="text-uppercase text-center">
                  <label>Last Login Date</label>
                </th>
                <th scope="col" class="text-uppercase text-center">
                  <label>Date Effective</label>
                </th>
                <th scope="col" class="text-uppercase text-center">
                  <label>Date Expired</label>
                </th>
                <th scope="col" class="text-uppercase text-center">
                  <label>Access Enabled</label>
                </th>
                <th *ngFor="let function of securityFunctionList$ | async" scope="col" class="text-uppercase text-center">
                  <label>{{ function }}</label>
                </th>
                <th *ngIf="isARBuAvailable" scope="col" class="text-uppercase text-center">
                  <label>ARB-U Cohort</label>
                </th>
                <th *ngIf="isARBuAvailable" scope="col" class="text-uppercase text-center">
                  <label>Reports To</label>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let userForm of userForms; let i = index" [formGroup]="userForm" [hidden]="hideRow(i)">
                <td>
                  <div class="d-flex">
                    <mat-icon (click)="save(i)" title="Save" [ngClass]="!userForm.dirty ? 'action-disabled' : ''">save</mat-icon>
                    <span class="px-2"> |</span>
                    <mat-icon (click)="resetPassword(i)" title="Reset Password">settings_backup_restore</mat-icon>
                  </div>
                </td>
                <td>
                  {{ userForm.controls.email.value }}
                </td>
                <td class="tableFixHead-fixed-left">
                  <input type="text" formControlName="name" />
                </td>
                <td>
                  {{ userForm.controls.dateCreated.value }}
                </td>
                <td>
                  {{ userForm.controls.createdBy.value }}
                </td>
                <td>
                  {{ userForm.controls.dateLastLogin.value }}
                </td>
                <td>
                  <div class="form-group">
                    <div class="input-group">
                      <input
                        class="form-control"
                        name="d1"
                        ngbDatepicker
                        #d1="ngbDatepicker"
                        formControlName="dateEffective"
                        [placement]="'bottom'"
                      />
                      <div class="input-group-append">
                        <button class="btn btn-secondary calendar text-white px-2 py-3" (click)="d1.toggle()" type="button"></button>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="form-group">
                    <div class="input-group">
                      <input
                        class="form-control"
                        name="d2"
                        ngbDatepicker
                        #d2="ngbDatepicker"
                        formControlName="dateExpiry"
                        [placement]="'bottom'"
                        [minDate]="minDate"
                      />
                      <div class="input-group-append">
                        <button class="btn btn-secondary calendar text-white px-2 py-3" (click)="d2.toggle()" type="button"></button>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-center">
                  <input class="form-check-input" type="checkbox" formControlName="enableAccess" />
                </td>
                <td class="text-center" *ngFor="let function of securityFunctionList$ | async">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    [name]="function"
                    (change)="toggleFunction(i, $event)"
                    [checked]="hasFunction(i, function)"
                  />
                </td>
                <td *ngIf="isARBuAvailable" style="min-width: 150px">
                  <ng-select formControlName="ARBuCohort" style="width: 100%">
                    <ng-option *ngFor="let ARBuCohorts of ARBuCohortsList" [value]="ARBuCohorts">
                      {{ ARBuCohorts }}
                    </ng-option>
                  </ng-select>
                </td>
                <td *ngIf="isARBuAvailable" style="min-width: 150px">
                  <ng-select formControlName="ARBuReportsTo" style="width: 100%">
                    <ng-option *ngFor="let user of reportToUserList" [value]="user.email"> {{ user.name }} - {{ user.email }} </ng-option>
                  </ng-select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </div>
  </div>
</div>
