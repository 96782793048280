import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DetailLevel } from '../../models/service-proxies';
import { IProductOrderItem } from '../../models/product-order-item';
import * as _ from 'lodash';
import { ItemAvaibilityItem } from '../../models/itemAvailability.model';
import { AppConsts } from '../../constants/AppConsts';
import { Router, ActivatedRoute } from '@angular/router';
import { EpcApiService } from '../../services/epc-api.service';
import { OOESessionService } from '../../../../../../../../libs/core-ui/src/lib/services/ooe-session.service';

@Component({
  selector: 'app-product-associated-items',
  templateUrl: './product-associated-items.component.html',
  styleUrls: ['./product-associated-items.component.scss'],
})
export class ProductProductAssociatedItemsComponent implements OnInit {
  @Input()
  set items(value: IProductOrderItem[]) {
    this._items = value;
    this.groupItemsByCategory();

    this._items.forEach((item) => {
      if (item.isMandatory) {
        this.selectProduct(item);
      }
    });

    this.mainFit = this._items.find((t) => t.associatedType == 'Fit');
    this.mainPaint = this._items.find((t) => t.associatedType == 'Paint');
  }

  @Input() mainQuantity: number;
  @Input() fitLabel: string;
  @Input() addFitLabel: string;
  @Input() currencyCode: string;

  @Output() orderItemsChanged = new EventEmitter<IProductOrderItem>();
  @Output() itemVisibilityChanged = new EventEmitter<IProductOrderItem>();
  @Output() changeEAIRules = new EventEmitter<boolean>();

  enforceAssociatedItemsRules: boolean = true;
  supplementGroupName = AppConsts.SUPPLEMENTARY_CHARGE_GROUP_NAME;
  currencyLabel: string = 'AUD';
  groupedItems: any[];
  mainFit: IProductOrderItem;
  mainPaint: IProductOrderItem;

  get items(): IProductOrderItem[] {
    return this._items;
  }

  public get isUserloggedIn(): boolean {
    return this.ooeSessionService.loginedSession != null;
  }

  public get userPostCode(): string {
    return this.ooeSessionService.getUserPostCode().trim();
  }

  private _items: IProductOrderItem[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private epcApiService: EpcApiService,
    private ooeSessionService: OOESessionService
  ) {}

  ngOnInit() {}

  groupItemsByCategory() {
    this.groupedItems = [];

    const groups = new Set(this._items.map((item) => item.cedCategory));

    groups.forEach((g) =>
      this.groupedItems.push({
        order: g == AppConsts.SUPPLEMENTARY_CHARGE_GROUP_NAME ? 0 : 1,
        isCollapsed: g != AppConsts.SUPPLEMENTARY_CHARGE_GROUP_NAME && g != AppConsts.BUNDLE_CHARGE_GROUP_NAME, //&& (this._items.filter((i) => i.cedCategory === g && i.isMandatory).length == 0),
        isFilterCollapsed: true,
        name: g,
        values: this._items.filter((i) => i.cedCategory === g),
      })
    );

    this.groupedItems.forEach((g) => {
      g.filters = this.getItemGroupFilters(g.values);
      g.currentFilter = g.filters ? new Array(g.filters.length) : [];
    });

    this.groupedItems = _.sortBy(this.groupedItems, 'order', 'name');
  }

  getItemGroupFilters(items) {
    if (!items[0].cedCategoryId) {
      return null;
    }

    let groupFilters = [];

    var allBrands = _.uniq(items.map((itm) => itm.brandName));
    if (allBrands.length > 0) {
      groupFilters.push({
        attribute: 'Brand',
        values: _.orderBy(
          allBrands.map((b) => ({
            value: b,
            cnt: items.filter((i) => i.brandName == b).length,
          })),
          ['cnt'],
          ['desc']
        ),
      });
    }

    var allSubBrands = _.uniq(items.map((itm) => (itm.subBrand && itm.subBrand != '' ? itm.subBrand : '')));
    if (allSubBrands.length > 0 && allSubBrands.find((sb) => sb && sb != '') != null) {
      groupFilters.push({
        attribute: 'Sub Brand',
        values: _.orderBy(
          allSubBrands.map((sb) => ({
            value: sb != '' ? sb : 'OTHER',
            cnt: items.filter((i) => (i.subBrand ? i.subBrand == sb : sb == '')).length,
          })),
          ['cnt'],
          ['desc']
        ),
      });
    }

    let allAtts = items.flatMap((itm) => itm.attributes);
    let allAttNames = allAtts.length ? _.uniq(allAtts.map((att) => att.attribute)) : [];

    if (allAttNames.length && allAtts[0].sortNumber != -1) {
      allAttNames = _.orderBy(
        allAttNames,
        [
          function (attName) {
            return allAtts.find((att) => att.attribute == attName).sortNumber;
          },
        ],
        ['asc']
      );
    } else {
      allAttNames = _.orderBy(allAttNames, ['attribute', 'asc']);
    }

    allAttNames.forEach((attName: string) => {
      let allAttValues = _.uniq(allAtts.filter((att) => att.attribute == attName).map((att) => att.value)).filter((attV) => attV && attV != '');

      if (allAttValues.length > 0) {
        let occurrencesOfAttribute = allAtts.filter((at) => at.attribute == attName);
        groupFilters.push({
          attribute: attName,
          values: _.orderBy(
            allAttValues.map((attV) => ({
              value: attV,
              cnt: occurrencesOfAttribute.filter((at) => at.value == attV).length,
            })),
            ['cnt'],
            ['desc']
          ),
        });
      }
    });

    return groupFilters;
  }

  groupFilterChange(group) {
    if (group.filters && group.filters.length) {
      group.filters.forEach((filter) => {
        if (filter.attribute == 'Brand') {
          var allBrands = _.uniq(group.values.map((itm) => itm.brandName));
          filter.values = allBrands.map((b) => ({
            value: b,
            cnt: group.values.filter((i) => i.brandName == b && !this.isFilterOut(group, i, 'Brand')).length,
          }));
        } else if (filter.attribute == 'Sub Brand') {
          var allSubBrands = _.uniq(group.values.map((itm) => itm.subBrand));
          filter.values = allSubBrands.map((sb) => ({
            value: sb,
            cnt: group.values.filter((i) => i.subBrand == sb && !this.isFilterOut(group, i, 'Sub Brand')).length,
          }));
        } else if (filter.attribute != 'Brand' && filter.attribute != 'Sub brand') {
          let allAttValues = _.uniq(
            group.values
              .flatMap((itm) => itm.attributes)
              .filter((att) => att.attribute == filter.attribute)
              .map((att) => att.value)
          );

          filter.values = allAttValues.map((attV) => ({
            value: attV,
            cnt: group.values.filter(
              (i) =>
                i.attributes.find((att) => att.attribute == filter.attribute && att.value == attV) != null &&
                !this.isFilterOut(group, i, filter.attribute)
            ).length,
          }));
        }
      });
    }
  }

  // letOutCondition: the filter to be not included when apply filter
  isFilterOut(group, record, letOutCondition = null) {
    for (let i = 0; i < group.currentFilter.length; i++) {
      let theFilter = group.filters[i].attribute,
        theVal = group.currentFilter[i];

      if (!theVal || letOutCondition == theFilter) {
        continue;
      }

      if (
        (theFilter == 'Brand' && record.brandName != theVal) ||
        (theFilter == 'Sub Brand' && record.subBrand != theVal) ||
        (theFilter != 'Sub Brand' &&
          theFilter != 'Brand' &&
          record.attributes.find((att) => att.attribute == theFilter && ((theVal == 'OTHER' && !att.value) || att.value == theVal)) == null)
      ) {
        return true;
      }
    }

    return false;
  }

  handleProductImgNotLoad(event: any) {
    event.srcElement.src = '/assets/img/ARB_logo.png';
  }

  forceAssociatedItemAddOn(item, shouldSelected, addOnType) {
    // on item FIT/PAINT and associated item FIT/PAINT
    if (addOnType != 'Fit' && addOnType != 'Paint') {
      return;
    }

    if (item.isMandatory) {
      if (item.associatedItems) {
        let addOn = item.associatedItems.find((addOn) => addOn.associatedType == addOnType);
        if (addOn) {
          addOn.isSelected = shouldSelected;
        }

        _.forEach(
          item.associatedItems.filter(
            (itm) => itm.associatedType != 'Fit' && itm.associatedType != 'Paint' && itm.associatedType != 'SupplementaryCharge'
          ),
          (itm) => {
            this.forceAssociatedItemAddOn(itm, shouldSelected, addOnType);
          }
        );
      }
    }
  }

  selectProduct(data: IProductOrderItem): void {
    const isSelected = data.isSelected;
    if (data.associatedType == 'Fit' || data.associatedType == 'Paint') {
      let assocItems = this._items.filter(
        (item) => item.associatedType != 'Fit' && item.associatedType != 'Paint' && item.associatedType != 'SupplementaryCharge'
      );

      if (this.enforceAssociatedItemsRules) {
        _.forEach(assocItems, (itm) => {
          this.forceAssociatedItemAddOn(
            itm,
            data.associatedType == 'Fit' ? this.mainFit && this.mainFit.isSelected : this.mainPaint && this.mainPaint.isSelected,
            data.associatedType
          );
        });
      }

      this.orderItemsChanged.emit(data);
      return;
    }

    if (!isSelected) {
      _.forEach(data.associatedItems, (subItem) => {
        if (!subItem.isMandatory) {
          subItem.isSelected = false;
        }
      });
    } else {
      let thisFit = data.associatedItems ? data.associatedItems.find((addOn) => addOn.associatedType == 'Fit') : null;
      if (thisFit) {
        _.forEach(data.associatedItems, (addOn) => {
          if (addOn.addtionalProductData.associatedType == 'Fit') {
            addOn.isSelected = thisFit.isSelected; // mandatory FIT to be same as its parent.
          }
        });
      }
    }

    this.epcApiService
      .getConflictingProducts(data.companySKU, AppConsts.brand, this.ooeSessionService.priceLists, DetailLevel.Full, undefined, false)
      .subscribe((result) => {
        _.forEach(this.items, (item) => {
          if (result.indexOf(item.companySKU) >= 0) {
            if (isSelected) {
              item.isConflicting = true;
              item.conflictingWith = data.companySKU;

              if (this.enforceAssociatedItemsRules) {
                item.quantity = 0;
                item.isSelected = false;

                _.forEach(item.associatedItems, (subItem) => {
                  if (!subItem.isMandatory) {
                    subItem.isSelected = false;
                  }
                });
              }

              item.infoMessage = 'Conflicting Associated Item - Due To ' + data.companySKU;
            } else {
              item.isConflicting = false;
              item.conflictingWith = '';
              item.quantity = item.originalQuantity;
              item.infoMessage = item.associatedType == 'Mandatory Bundle' ? 'Mandatory Bundle Item' : item.associatedType + ' Associated Item';
            }
          }
        });

        this.orderItemsChanged.emit(data);
      });
  }

  checkGroupHasSelected(group) {
    let hasSelected = false;
    let selectedCount = 0;

    if (group.values) {
      for (var index = 0; index < group.values.length; index++) {
        var element = group.values[index];
        if (element.isSelected) {
          hasSelected = true;
          selectedCount++;
        }
      }
      if (selectedCount == group.values.length) {
        hasSelected = false;
      }
    }

    return hasSelected;
  }

  updateItemPrices(data: IProductOrderItem): void {
    this.orderItemsChanged.emit(data);
  }

  supplementItemChange(addOn: IProductOrderItem, item: IProductOrderItem) {
    if ((addOn.associatedType == 'Fit' || addOn.associatedType == 'Paint') && this.enforceAssociatedItemsRules) {
      // make sub's FIT the same selection state
      _.forEach(
        item.associatedItems.filter(
          (itm) => itm.associatedType != 'Fit' && itm.associatedType != 'Paint' && itm.associatedType != 'SupplementaryCharge'
        ),
        (subItem) => {
          this.forceAssociatedItemAddOn(subItem, addOn.isSelected, addOn.associatedType);
        }
      );
    }

    this.updateItemPrices(item);
  }

  getFitDescription(companySKU: string) {
    if (companySKU == 'FIT') {
      return this.fitLabel ? this.fitLabel : 'FITTED';
    } else if (companySKU == 'ADDFIT') {
      return this.addFitLabel ? this.addFitLabel : 'ADDITIONAL FITTED';
    }
  }

  getShortAvailabilityDescription(stocks: any): string {
    let desc = '';
    if (stocks && stocks.SearchResults) {
      _.forEach(stocks.SearchResults, (stock, i: number) => {
        desc += this.getQuantity(stocks, stock) + (i < stocks.SearchResults.length - 1 ? ' / ' : '');
      });
    }
    return desc;
  }

  getMyPrice(record: IProductOrderItem) {
    if (record) {
      return record.customerCurrency + ' ' + record.customerUnitPrice;
    }

    return '';
  }

  getQuantity(stock: any, item: ItemAvaibilityItem): string {
    if ((stock.availabilityMode = '1')) {
      let qtyAvailable = Number(item.qtyAvailable.replace(/,/g, ''));

      return isNaN(qtyAvailable) ? item.qtyAvailable : qtyAvailable.toString();
    }

    if ((stock.availabilityMode = '2')) {
      let lowMediumHighValue = Number(item.lowMediumHighValue.replace(/,/g, ''));
      return isNaN(lowMediumHighValue) ? item.lowMediumHighValue : lowMediumHighValue.toString();
    }

    return 'N/A';
  }

  changePanelGroupState(group) {
    group.isCollapsed = !group.isCollapsed;
    if (!group.isCollapsed) {
      if (group.values && this.hasNoStockPriceItems(group.values)) {
        _.forEach(group.values, (item) => {
          this.itemVisibilityChanged.emit(item);
        });
      }
    }
  }

  hasNoStockPriceItems(items): boolean {
    for (let i = 0; i < items.length; i++) {
      if (
        !items[i].prices ||
        items[i].prices.length == 0 ||
        !items[i].stocks ||
        (items[i].stocks.SearchResults && items[i].stocks.SearchResults.length == 0)
      ) {
        return true;
      }
    }

    return false;
  }

  viewProduct(productNumber: string) {
    this.router.navigate(['product/' + encodeURIComponent(productNumber)], {
      relativeTo: this.route.parent,
    });
  }

  resetAssociatedItems(): void {
    this.changeEAIRules.emit(this.enforceAssociatedItemsRules);
    if (!this.enforceAssociatedItemsRules) {
      return;
    }

    _.forEach(this.items, (item) => {
      if (item.isMandatory) {
        item.isSelected = true;
        this.forceAssociatedItemAddOn(item, this.mainFit && this.mainFit.isSelected, 'Fit');
        this.forceAssociatedItemAddOn(item, this.mainPaint && this.mainPaint.isSelected, 'Paint');
      } else if (item.isConflicting) {
        item.quantity = item.originalQuantity;
        item.isSelected = false;

        // DungNP comment out below 3 lines: donnot reset the message because when unser untik and tick the checkbox again the conflicting note doesn't apear -> incorrect;
        // item.isConflicting = false;
        // item.conflictingWith = null;
        // item.infoMessage = item.associatedType + " Associated Item";

        _.forEach(item.associatedItems, (subItem) => {
          if (!subItem.isMandatory) {
            subItem.isSelected = false;
          }
        });
      }
    });

    _.forEach(this.items, (item) => {
      if (item.isMandatory) {
        this.selectProduct(item);
      }
    });

    this.orderItemsChanged.emit();
  }

  isSelected(t): boolean {
    return t.isSelected;
  }

  getBundleContents(record: IProductOrderItem): string {
    let contents: string = 'Bundle Contents: ';

    _.forEach(
      record.associatedItems.filter((item) => item.associatedType === 'Mandatory Bundle'),
      (item, index) => {
        contents += index == 0 ? item.companySKU : ', ' + item.companySKU;
      }
    );

    return contents;
  }

  getTotalFitPrice(fitRecord: IProductOrderItem): string {
    if (fitRecord.addtionalProductData?.isBundleMetaInfo) {
      return (this.mainQuantity * Number(fitRecord.addtionalProductData.bundleComponentFitIncTax)).toFixed(2);
    } else {
      let mainFitPrice = this.mainQuantity * fitRecord.quantity * Number(fitRecord.customerTaxedUnitPrice);

      /* TODO: PBI-3688 associated items fit price */
      // this.items.forEach((item) => {
      //   if (
      //     item.associatedType != 'Fit' &&
      //     item.associatedType != 'Mandatory Bundle'
      //   ) {
      //     mainFitPrice += this.getItemFitPrice(null, item);
      //   }
      // });

      return mainFitPrice.toFixed(2);
    }
  }

  getTotalPaintPrice(paintRecord: IProductOrderItem): string {
    // IF the product does not have paint option, and is a bundle, and has component have paint option, sum those paints to be bundle paint option
    let mainPaintPrice = paintRecord.addtionalProductData?.isBundleMetaInfo
      ? 0.0
      : this.mainQuantity * paintRecord.quantity * Number(paintRecord.customerTaxedUnitPrice || paintRecord.prices[0]?.taxedUnitPrice);

    if (mainPaintPrice > 0) {
      return mainPaintPrice.toFixed(2);
    } else {
      let bundleComponentPaintSum = this.getBundleComponentsPaintSum();
      if (paintRecord.addtionalProductData?.isBundleMetaInfo) {
        paintRecord.addtionalProductData.bundleComponentPaintSum = bundleComponentPaintSum;
      }

      return (this.mainQuantity * paintRecord.quantity * bundleComponentPaintSum).toFixed(2);
    }
  }

  private getBundleComponentsPaintSum(): number {
    let componentPaintSum = 0.0;
    this.items.forEach((item) => {
      if (item.associatedType == 'Mandatory Bundle') {
        componentPaintSum += this.getItemPaintPrice(null, item);
      }
    });

    return componentPaintSum;
  }

  private getItemPaintPrice(parentNode: IProductOrderItem, node: IProductOrderItem): number {
    let totalPrice = 0;
    let parentQuantity = parentNode && parentNode.quantity ? Number(parentNode.quantity) : 1;
    let thisQuantity = node.quantity ? Number(node.quantity) : 1;

    if (node.associatedType == 'Paint') {
      totalPrice += parentQuantity * thisQuantity * Number(node.customerTaxedUnitPrice);
    } else {
      for (const child of node.associatedItems) totalPrice += this.getItemPaintPrice(node, child);
    }

    return totalPrice;
  }

  private getItemFitPrice(parentNode: IProductOrderItem, node: IProductOrderItem): number {
    let totalPrice = 0;
    if ((parentNode == null || parentNode.isSelected) && node.isSelected) {
      let parentQuantity = parentNode && parentNode.quantity ? Number(parentNode.quantity) : 1;
      let thisQuantity = node.quantity ? Number(node.quantity) : 1;

      if (node.associatedType == 'Fit' && node.addtionalProductData.supplementGroupItemName == 'FITTED') {
        if (this.mainFit.addtionalProductData.isBundleMetaInfo && parentNode.addtionalProductData.fitBundleUnitPriceIncTax) {
          // when product is under a bundle, use adjusted value fitBundleUnitPriceIncTax instead of customerTaxedUnitPrice (fitUnitPriceIncTax)
          totalPrice += parentQuantity * thisQuantity * Number(parentNode.addtionalProductData.fitBundleUnitPriceIncTax);
        } else {
          totalPrice += parentQuantity * thisQuantity * Number(node.customerTaxedUnitPrice);
        }
      } else {
        for (const child of node.associatedItems) totalPrice += this.getItemFitPrice(node, child);
      }
    }

    return totalPrice;
  }
}
