import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Observable, of } from 'rxjs';
import { takeUntil, switchMap } from 'rxjs/operators';
import { Vehicle, State, LookupLists } from '@pos-app/data';
import { CustomersPartialState } from '../../+state/customers.reducer';
import { MaintainCustomerVehicle, SelectCustomerAction, SelectCountry } from '../../+state/customers.actions';
import { Store } from '@ngrx/store';
import { customersQuery } from '../../+state/customers.selectors';
import { hasUnsavedChanges, MessageService } from '@pos-app/core-ui';

@Component({
  selector: 'app-customers-vehicles-details-new',
  templateUrl: './customers-vehicles-details-new.component.html',
  styleUrls: ['./customers-vehicles-details-new.component.scss'],
})
export class CustomersVehiclesDetailsNewComponent implements OnInit, OnDestroy {
  @Input() public currentCustomer: string;
  @Input() public preselectedVehicle: any;
  @Output() public vehicleAdded = new EventEmitter<string>();
  @Output() public cancelAdd = new EventEmitter<void>();

  public selectedCustomerNumber: string;
  public selectedVehicle: Vehicle;
  public toAddDefaultVehicle = false;
  public formValid = false;
  public formDirty = false;
  public formValue = null;
  public selectedCustomerVehicleID: string;

  public isUpdating$: Observable<boolean>;
  public isLoading$: Observable<boolean>;
  public stateList$: Observable<State[]>;
  public lookupLists$: Observable<LookupLists>;
  public selectedCustomerState$: Observable<string>;
  public selectedCustomerCountry$: Observable<string>;

  private unSubscribe$ = new Subject<void>();

  constructor(
    private store: Store<CustomersPartialState>,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService
  ) {}

  public ngOnInit(): void {
    if (this.currentCustomer) {
      this.selectedCustomerNumber = this.currentCustomer;
    } else {
      this.selectedCustomerNumber = this.route.parent.snapshot.params.id;
      this.store.dispatch(new SelectCustomerAction(this.selectedCustomerNumber));
    }
    this.store
      .select(customersQuery.getToAddDefaultVehicle)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((x) => {
        this.toAddDefaultVehicle = x;
      });
    this.isLoading$ = this.store.select(customersQuery.getLoadingCustomerVehicles);
    this.isUpdating$ = this.store.select(customersQuery.getUpdatingCustomerVehicle);
    this.stateList$ = this.store.select(customersQuery.getFilteredStates);
    this.selectedCustomerState$ = this.store.select(customersQuery.getSelectedCustomerState);
    this.selectedCustomerCountry$ = this.store.select(customersQuery.getSelectedCustomerCountry);
    this.lookupLists$ = this.store.select(customersQuery.getLookupLists);
    this.store
      .select(customersQuery.getCustomerVehicleID)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((res) => {
        this.selectedCustomerVehicleID = res;
      });
  }

  public ngOnDestroy(): void {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  public formDirtyHandler(dirty): void {
    this.formDirty = dirty;
    if (dirty) {
      this.store.dispatch(hasUnsavedChanges({ unsavedChanges: true }));
      this.messageService.dispatchAction(hasUnsavedChanges({ unsavedChanges: true }));
    }
  }

  public addNewVehicle(): void {
    const addNewVehicleForm = {
      ...this.formValue,
      customerNumber: this.selectedCustomerNumber,
      defaultFlag: this.toAddDefaultVehicle ? '1' : '0',
    };
    addNewVehicleForm['actionCode'] = 'A';
    addNewVehicleForm['vehicleID'] = this.selectedVehicle ? this.selectedVehicle.VehicleID : this.preselectedVehicle.jdeVehicleID;
    this.store.dispatch(new MaintainCustomerVehicle(addNewVehicleForm));
    this.isUpdating$
      .pipe(
        switchMap((isUpdating) => {
          if (!isUpdating) {
            return this.isLoading$;
          } else {
            return of(true);
          }
        }),
        takeUntil(this.unSubscribe$)
      )
      .subscribe((isLoading) => {
        if (!isLoading) {
          // Only emit when finisng reloading vehicle list
          this.currentCustomer
            ? this.vehicleAdded.emit(this.selectedCustomerVehicleID)
            : this.router.navigate(['pos/customers/' + this.selectedCustomerNumber + '/vehicles']);
        }
      });
  }

  public vehicleSelected(vehicle): void {
    this.selectedVehicle = vehicle;
    this.store.dispatch(hasUnsavedChanges({ unsavedChanges: true }));
  }

  public getFilteredStateList(countryCode): void {
    this.store.dispatch(new SelectCountry(countryCode));
  }

  public cancel(): void {
    this.currentCustomer ? this.cancelAdd.emit() : this.router.navigate(['pos/customers/' + this.selectedCustomerNumber + '/vehicles']);
  }

  public getVehicleDescription(): string {
    return this.selectedVehicle
      ? `${this.selectedVehicle.Make} ${this.selectedVehicle.Model} ${this.selectedVehicle.Year}
    ${this.selectedVehicle.Generation} ${this.selectedVehicle.Series}
    ${this.selectedVehicle.SubModel} ${this.selectedVehicle.BodyType}
    ${this.selectedVehicle.EngineType} ${this.selectedVehicle.EngineCode}
    ${this.selectedVehicle.DriveType} ${this.selectedVehicle.TransmissionType}
    ${this.selectedVehicle.Chassis}`
      : this.preselectedVehicle
      ? this.preselectedVehicle.vehicleDescription
      : '';
  }
}
