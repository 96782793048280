import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { CustomersPartialState } from '../../+state/customers.reducer';
import { ActivatedRoute } from '@angular/router';
import { customersQuery } from '../../+state/customers.selectors';
import { ordersQuery } from '../../../orders/+state/orders.selectors';
import {
  ClearData,
  GetCustomerSalesAction,
  GetUserStateAction,
  LoadLookupLists,
  SelectCustomerAction,
} from '../../+state/customers.actions';
import { CustomersResponseService } from '../../+state/customers.response';
import { combineLatest, Observable, Subject } from 'rxjs';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { CustomerDetails, OpenOrder, USER_ROLE, SECURITY_FUNCTION, OrderType, ORDER_TYPE, BrandDefaults } from '@pos-app/data';
import {
  ApiService,
  CoreUiPartialState,
  getActiveOrder,
  getBrandDefaults,
  getCashDrawer,
  getNonCorpOrderTypes,
  getOrderTypes,
  getSecurityFunctions,
  getUserId,
  getUserRole,
  isExternalUser,
} from '@pos-app/core-ui';
import { FetchJdeOrder, LoadParkedOrdersList } from '../../../orders/+state/orders.actions';
import { ToastrService } from 'ngx-toastr';
import { OrdersPartialState } from '../../../orders/+state/orders.reducer';

@Component({
  selector: 'app-customers-base',
  templateUrl: './customers-base.component.html',
  styleUrls: ['./customers-base.component.scss'],
})
export class CustomersBaseComponent implements OnInit, OnDestroy {
  public selectedCustomer: CustomerDetails;
  public activeOrder$: Observable<any>;
  public isExternalUser$: Observable<boolean>;
  public userID$: Observable<string>;
  public brandDefaults$: Observable<BrandDefaults>;
  public securityFunctions: string[];
  public loadingCustomerDetails$ = this.customerStore.select(customersQuery.getLoadingCustomerDetails);
  public loadingFetchingOrderFromJDE$ = this.customerStore.select(ordersQuery.getIsFetchingJdeOrder);
  public customerSales$ = this.customerStore.select(customersQuery.getCustomerSales);
  public openOrder: OpenOrder;
  public orderTypes: OrderType[];
  public hasCashDrawer: boolean;
  public isShowUser: boolean;

  public isShowStockAvailability: boolean;
  public isFetching: boolean;
  public giftCardNumber: string;
  public giftCardBalance: string;
  public giftCardErrorMessage: string;

  public SECURITY_FUNCTION = SECURITY_FUNCTION;

  private unSubscribe$ = new Subject<void>();

  constructor(
    private customerStore: Store<CustomersPartialState>,
    private customersResponseService: CustomersResponseService,
    private orderStore: Store<OrdersPartialState>,
    private coreUiStore: Store<CoreUiPartialState>,
    private activeRoute: ActivatedRoute,
    private apiService: ApiService,
    private toastr: ToastrService
  ) {}

  public ngOnInit(): void {
    this.brandDefaults$ = this.coreUiStore.select(getBrandDefaults);

    this.dispatchParkedOrdersList();
    this.dispatchGetUserState();

    combineLatest([this.activeRoute.params, this.coreUiStore.select(getUserRole), this.coreUiStore.select(getSecurityFunctions)])
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe(([routeParams, userRole, securityFunctions]) => {
        this.securityFunctions = securityFunctions;
        this.isShowUser = userRole && userRole.includes('SHOW');
        if (routeParams?.id) {
          this.fetchCustomerSummary(userRole, routeParams?.id);
        } else {
          this.customerStore.dispatch(new ClearData());
        }
      });

    this.customerStore
      .select(customersQuery.getSelectedCustomer)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((customer) => {
        this.selectedCustomer = customer;
      });

    this.customerStore.dispatch(new LoadLookupLists());
    this.activeOrder$ = this.coreUiStore.select(getActiveOrder);
    this.isExternalUser$ = this.coreUiStore.select(isExternalUser);
    this.userID$ = this.coreUiStore.select(getUserId);

    combineLatest([
      this.coreUiStore.select(getOrderTypes),
      this.coreUiStore.select(getNonCorpOrderTypes),
      this.coreUiStore.select(getCashDrawer),
    ])
      .pipe(take(1))
      .subscribe(([orderTypes, nonCorpOrderTypes, cashDrawer]) => {
        const register = JSON.parse(cashDrawer);
        this.hasCashDrawer = register && register.status === 'O';
        // concat 2 lists and remove duplicates
        this.orderTypes = orderTypes
          .concat(nonCorpOrderTypes)
          .filter((type, index, array) => index === array.findIndex((orderType) => orderType.OrderTypeCode === type.OrderTypeCode))
          .filter((type) => type.PaymentYN !== 'Y' || this.hasCashDrawer);
      });
  }

  public ngOnDestroy(): void {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  public fetchCustomerSummary(userRole, id): void {
    // for external user, it will call CustomerDetailsExternal API that takes no input
    this.customerStore.dispatch(new SelectCustomerAction(userRole !== USER_ROLE.external ? id : null));
    this.customersResponseService.selectCustomerSuccess$.pipe(take(1)).subscribe(() => {
      this.customerStore.dispatch(new GetCustomerSalesAction(id));
    });
  }

  public fetchOrderFromJDE(data): void {
    if (
      this.isShowUser &&
      ((data.orderType === ORDER_TYPE.reservedInvoice && !this.hasCashDrawer) ||
        (data.orderType !== ORDER_TYPE.reservedInvoice &&
          this.orderTypes.findIndex((type) => type.OrderTypeCode === data.orderType) === -1))
    ) {
      this.toastr.error('Cannot fetch this order without a register connected');
      return;
    }
    this.customerStore.dispatch(
      new FetchJdeOrder({
        orderNumber: data.orderNumber,
        orderType: data.orderType,
      })
    );
  }

  public fetchCardBalance(): void {
    this.giftCardErrorMessage = null;
    this.giftCardBalance = undefined;

    if (!this.giftCardNumber) {
      return;
    }
    
    this.isFetching = true;
    this.apiService
      .fetchCardBalance({
        cardID: this.giftCardNumber,
      })
      .pipe(
        finalize(() => (this.isFetching = false)),
        takeUntil(this.unSubscribe$)
      )
      .subscribe(
        (res) => {
          this.isFetching = false;
          if (res) {
            this.giftCardBalance = res.balance;
          }
        },
        (error) => {
          this.giftCardErrorMessage = error.ErrorMessage;
          this.toastr.error(error.ErrorMessage);
        }
      );
  }

  public dispatchParkedOrdersList(): void {
    this.orderStore.dispatch(new LoadParkedOrdersList());
  }

  public dispatchGetUserState(): void {
    this.customerStore.dispatch(new GetUserStateAction());
  }
}
