import SchedulerFactory from "./scheduler_factory";
import allExtensions from "./ext/extensions_all";

const factory = new SchedulerFactory(allExtensions);

const scheduler = factory.getSchedulerInstance();
const Scheduler = {
	plugin: scheduler.bind(factory.plugin, factory)
};

window.scheduler = scheduler;
window.Scheduler = Scheduler;

if(!window.$dhx) {
	window.$dhx = {};
}

window.$dhx.scheduler = scheduler;
window.$dhx.Scheduler = Scheduler;

export { scheduler, Scheduler };