import { Action } from '@ngrx/store';
import { Entity } from './vehicles.reducer';
import { VehicleSearchResult, VehicleSearchParamters } from '@pos-app/data';

export enum VehiclesActionTypes {
  LoadVehicles = '[Vehicles] Load Vehicles',
  VehiclesLoaded = '[Vehicles] Vehicles Loaded',
  VehiclesLoadError = '[Vehicles] Vehicles Load Error'
}

export class LoadVehicles implements Action {
  readonly type = VehiclesActionTypes.LoadVehicles;
  constructor(public payload: VehicleSearchParamters) {}
}

export class VehiclesLoadError implements Action {
  readonly type = VehiclesActionTypes.VehiclesLoadError;
  constructor(public payload: any) {}
}

export class VehiclesLoaded implements Action {
  readonly type = VehiclesActionTypes.VehiclesLoaded;
  constructor(
    public payload: { responnse: VehicleSearchResult; isInitial: boolean }
  ) {}
}

export type VehiclesAction = LoadVehicles | VehiclesLoaded | VehiclesLoadError;

export const fromVehiclesActions = {
  LoadVehicles,
  VehiclesLoaded,
  VehiclesLoadError
};
