<form [formGroup]="warrantyForm">
  <div>
    <label>RESELLER INFORMATION</label>
    <table class="table table-sm table-bordered">
      <tbody>
        <td>
          <label>RESELLER NAME</label>
        </td>
        <td>
          {{ warrantyForm.controls.reseller.value }}
        </td>
        <td>
          <label>WRITTEN BY</label>
        </td>
        <td>
          {{ warrantyForm.controls.writtenBy.value }}
        </td>
        <td>
          <label>DATE</label>
        </td>
        <td>
          {{ today | date: 'shortDate' }}
        </td>
      </tbody>
    </table>
  </div>
  <hr class="pt-2" />
  <div>
    <label>CUSTOMER INFORMATION</label>
    <table class="table table-sm table-bordered">
      <tbody>
        <tr>
          <td class="align-middle">
            <label class="my-auto">CUSTOMER NAME *</label>
          </td>
          <td>
            <input
              class="form-control"
              formControlName="name"
              placeholder="Please enter customer name"
              [class.is-invalid]="warrantyForm.controls.name.errors"
              autofocus
            />
          </td>
          <td class="align-middle">
            <label class="my-auto">PHONE *</label>
          </td>
          <td>
            <input
              class="form-control"
              formControlName="phone"
              placeholder="Please enter phone"
              [class.is-invalid]="warrantyForm.controls.phone.errors"
            />
          </td>
          <td class="align-middle">
            <label class="my-auto">Customer Reference</label>
          </td>
          <td>
            <input
              class="form-control"
              formControlName="customerReference"
              placeholder="Please enter customer ref no"
            />
          </td>
        </tr>
        <tr>
          <td class="align-middle">
            <label class="my-auto">EMAIL *</label>
          </td>
          <td>
            <input
              class="form-control"
              formControlName="email"
              placeholder="Please enter email"
              [class.is-invalid]="warrantyForm.controls.email.errors"
            />
          </td>
          <td class="align-middle">
            <label class="my-auto">ADDRESS</label>
          </td>
          <td>
            <input
              class="form-control"
              formControlName="addressLine1"
              placeholder="Please enter address"
            />
          </td>
          <td class="align-middle">
            <label class="my-auto">POSTCODE</label>
          </td>
          <td>
            <input
              class="form-control"
              formControlName="postCode"
              placeholder="Please enter postcode"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <hr class="pt-2" />
  <div>
    <label>WARRANTY INFORMATION</label>
    <div class="row">
      <div class="col-3">
        <label
          >DESCRIBE THE WARRANTY ISSUE * ({{ issueDescriptionCharCount }} / 500
          CHARS)</label
        >
      </div>
      <div class="col-9">
        <textarea
          *ngIf="!warrantySubmittedNumber"
          class="form-control"
          formControlName="issueDescription"
          maxlength="500"
          [class.is-invalid]="warrantyForm.controls.issueDescription.errors"
        ></textarea>
        <div
          *ngIf="warrantySubmittedNumber"
          [ngStyle]="{ 'word-break': 'break-word' }"
        >
          {{ warrantyForm.controls.issueDescription.value }}
        </div>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-3">
        <label>ATTACHED FILES</label>
      </div>
      <div class="col-9">
        <div
          *ngFor="let attachment of attachedFiles; index as i"
          class="d-flex align-middle pb-2"
        >
          <mat-icon class="text-danger" (click)="onRemoveFile(i)">
            close</mat-icon
          ><span class="px-1"></span>{{ attachment.name }}
        </div>
        <div>
          <label class="arb-other-button" for="file-upload">SELECT FILES</label>
          <label class="ml-3">FILES MUST BE LESS THAN 15MEG</label>
        </div>
        <input
          id="file-upload"
          type="file"
          class="d-none"
          (change)="onFileChange($event)"
          multiple
        />
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-3">
        <label>ORIGINAL PARTS SUPPLIED/FITTED BY *</label>
      </div>
      <div class="col-9">
        <input
          class="form-control"
          formControlName="suppliedFittedBy"
          [class.is-invalid]="warrantyForm.controls.suppliedFittedBy.errors"
        />
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-3">
        <label
          >ACTION TAKEN/PROPOSED ({{ actionCharCount }} / 500 CHARS)
        </label>
      </div>
      <div class="col-9">
        <textarea
          *ngIf="!warrantySubmittedNumber"
          class="form-control"
          formControlName="action"
          maxlength="500"
        ></textarea>
        <div
          *ngIf="warrantySubmittedNumber"
          [ngStyle]="{ 'word-break': 'break-word' }"
        >
          {{ warrantyForm.controls.action.value }}
        </div>
      </div>
    </div>
  </div>
</form>
