import {
  ColDef,
  ColumnApi,
  GridApi,
  GridOptions,
} from 'ag-grid-community';
import { Location } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DialogService } from '@pos-app/core-ui';
import {
  CustomerDetails,
  ListTasksNotes,
  NOTE_TASK_ACTION_CODE,
  NOTE_TASK_ACTIVITY_TYPE,
  OrderType,
  ORDER_TYPE,
} from '@pos-app/data';
import { getLocalLocaleDate } from 'libs/core-ui/src/lib/utils/utils-date';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UpdateNotesTasksAction } from '../../+state/customers.actions';
import { CustomersPartialState } from '../../+state/customers.reducer';
import { FetchJdeOrder } from '../../../orders/+state/orders.actions';
import { CustomersNotesCompleteTaskComponent } from '../customers-notes-complete-task/customers-notes-complete-task.component';
import { CustomersNotesFileAttachmentComponent } from '../customers-notes-file-attachment/customers-notes-file-attachment.component';
import { CustomersNotesFormComponent } from '../customers-notes-form/customers-notes-form.component';
import { TimelineGridActionRendererComponent } from './timelineGridActionRender/timelineGridActionRender';
import { isBefore, parse } from 'date-fns';

@Component({
  selector: 'app-customers-notes-task-timeline',
  templateUrl: './customers-notes-task-timeline.component.html',
  styleUrls: ['./customers-notes-task-timeline.component.scss'],
})
export class CustomersNotesTaskTimelineComponent
  implements OnInit, OnChanges, AfterViewInit
{
  private unSubscribe$ = new Subject<void>();
  frameworkComponents;
  context;
  gridOptions: GridOptions;
  columnDefs: ColDef[];
  loading: boolean;
  private gridApi: GridApi;
  private gridColumnApi: ColumnApi;
  isEditOrAddNoteTask: boolean;
  searchValue: string;
  hideDoneCol: boolean = true;

  @Input() orderTypes: OrderType[];
  @Input() selectedCustomer: CustomerDetails;
  @Input() rowData;
  @Input() isTaskCalendarComp: boolean = false;
  @Input() isShowCompletedTasks: boolean = false;
  @Input() taskCalendarFilter: string = '';
  @Input() defaultCustomer;
  @Input() listTaskNotesFilter: ListTasksNotes;

  @ViewChild(CustomersNotesFormComponent)
  noteFormChild: CustomersNotesFormComponent;
  @ViewChild(CustomersNotesCompleteTaskComponent)
  completeTaskChild: CustomersNotesCompleteTaskComponent;
  @ViewChild(CustomersNotesFileAttachmentComponent)
  fileAttachmentChild: CustomersNotesFileAttachmentComponent;

  constructor(
    private dialogService: DialogService,
    private store: Store<CustomersPartialState>,
    private toastr: ToastrService,
    private _location: Location,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(LOCALE_ID) public locale: string
  ) {
    this.frameworkComponents = {
      actionRenderer: TimelineGridActionRendererComponent,
    };
    this.context = { componentParent: this };
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setRowData([]);
  }

  ngOnInit() {
    this.hideDoneCol = true;
    this.gridOptions = {
      defaultColDef: {
        autoHeight: true,
        wrapText: true,
        resizable: true,
        sortable: true,
        width: 150,
        cellClass: 'timeline-cell-class ag-right-aligned-cell',
        headerClass: 'header-class ag-left-aligned-header',
      },
      columnDefs: [
        {
          headerName: 'ACTION',
          width: 160,
          cellRenderer: 'actionRenderer',
          cellStyle: {
            'text-align': 'center',
          },
        },
        {
          headerName: 'RAISED',
          width: 130,
          field: 'startDate',
          valueFormatter: (params) =>
            getLocalLocaleDate(params.data.startDate, this.locale),
          cellStyle: { 'text-align': 'right' },
          hide: this.isTaskCalendarComp ? true : false,
        },
        {
          headerName: 'DUE',
          width: 130,
          field: 'endDate',
          valueFormatter: (params) =>
            getLocalLocaleDate(params.data.endDate, this.locale),
          cellStyle: (params: any) => {
            const today = new Date();
            const dueDate = parse(
              params.data.endDate,
              'dd/MM/yyyy',
              new Date()
            );
            // dueDate < today
            if (isBefore(dueDate, today)) {
              return {
                background: '#ed1c1a',
                border: '1px solid #980000',
                color: '#fff',
              };
            }
            return { 'text-align': 'center' };
          },
        },
        {
          headerName: 'DONE',
          width: 130,
          field: 'dateCompleted',
          valueFormatter: (params) =>
            getLocalLocaleDate(params.data.dateCompleted, this.locale),
          cellStyle: () => {
            return {
              'text-align': 'right',
            };
          },
          hide: this.hideDoneCol ? true : false,
        },
        {
          headerName: 'ASSIGNED TO',
          width: 170,
          field: 'assignees',
          cellRenderer: (params: any) => {
            return params.data.assignees.map((x) => x.assigneeName).toString();
          },
          cellStyle: { 'text-align': 'left' },
        },
        {
          headerName: 'ENTERED BY',
          width: 140,
          field: 'ownerDescription',
          cellStyle: { 'text-align': 'left' },
          hide: this.isTaskCalendarComp ? true : false,
        },
        {
          headerName: 'CUSTOMER',
          width: 140,
          field: 'customerName',
          onCellClicked: (params: any) => {
            params.data.assignees.forEach((assignee) => {
              if (assignee.assignee !== params.data.customerNumber) {
                this.router.navigate([
                  '/pos/customers/' + params.data.customerNumber + '/notes',
                ]);
              }
            });
          },
          cellStyle: (params: any) => {
            let cellStyle: any = { 'text-align': 'left' };
            params.data.assignees.forEach((assignee) => {
              if (assignee.assignee !== params.data.customerNumber) {
                cellStyle = {
                  ...cellStyle,
                  'text-decoration': 'underline',
                  cursor: 'pointer',
                };
              }
            });
            return cellStyle;
          },
          hide: this.isTaskCalendarComp ? false : true,
        },
        {
          headerName: 'BRANCH',
          width: 140,
          field: 'ownerDescription',
          cellStyle: { 'text-align': 'left' },
          hide: this.isTaskCalendarComp ? false : true,
        },
        {
          headerName: 'NOTES',
          width: 370,
          field: 'note',
          cellStyle: (params: any) => {
            return {
              'text-align': 'left',
              'text-transform': 'none',
              'text-decoration': params.data.additionalDetails
                ? 'underline'
                : 'none',
              cursor: params.data.additionalDetails ? 'pointer' : 'cursor',
            };
          },
          onCellClicked: (params: any) => {
            if (params.data.additionalDetails) {
              this.dialogService.confirm(
                params.data.additionalDetails,
                `Additional Notes`,
                null,
                null,
                null,
                null,
                false
              );
            }
          },
        },
        {
          headerName: 'Tasks/Notes',
          width: 150,
          field: 'activityType',
          cellStyle: { 'text-align': 'left' },
          hide: true,
        },
      ],
    };
  }

  ngAfterViewInit() {
    // Open new note form by default
    if (this.route.snapshot.queryParams.openNewNote === 'true') {
      this.navigateToEditNote();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.rowData && changes.rowData.currentValue && this.gridApi) {
      this.gridApi.setRowData(this.rowData);
    }
    if (changes.isShowCompletedTasks && this.gridColumnApi) {
      this.hideDoneCol = !changes.isShowCompletedTasks.currentValue;
      this.gridColumnApi.setColumnVisible('DONE', this.hideDoneCol);
    }
  }

  completeTask(noteTaskDetails) {
    this.completeTaskChild.openCompleteTaskModal(
      noteTaskDetails,
      this.isTaskCalendarComp,
      this.taskCalendarFilter,
      this.defaultCustomer
    );
  }

  viewFileAttachment(item) {
    this.fileAttachmentChild.openFileAttachmentModal(item);
  }

  navigateToEditNote(noteTaskDetails?) {
    this.noteFormChild.openModal(
      noteTaskDetails,
      this.orderTypes,
      this.isTaskCalendarComp,
      this.taskCalendarFilter,
      this.defaultCustomer
    );
  }

  deleteNoteTask(noteTaskDetails) {
    this.dialogService
      .confirm(
        `<p>Are you sure you want to delete this note?</p>
        <p>${noteTaskDetails.note}</p>`,
        `Delete Note`,
        'Delete',
        'Cancel',
        true,
        null,
        false
      )
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((val) => {
        if (val) {
          this.store.dispatch(
            new UpdateNotesTasksAction({
              defaultCustomer: this.defaultCustomer,
              taskCalendarFilter: this.taskCalendarFilter,
              isTask: this.isTaskCalendarComp,
              actionCode: NOTE_TASK_ACTION_CODE.Delete,
              activityID: noteTaskDetails.activityID,
              note: noteTaskDetails.note,
              dueDate: noteTaskDetails.endDate,
              customerNumber: noteTaskDetails.customerNumber,
              additionalDetails: noteTaskDetails.additionalDetails,
              contactWhosWhoLine: '',
              assignees: '',
              attachmentArray: [],
              activityType: noteTaskDetails.endDate.trim()
                ? NOTE_TASK_ACTIVITY_TYPE.Task
                : NOTE_TASK_ACTIVITY_TYPE.Note,
              orderNumber: noteTaskDetails.orderNumber,
              orderType: noteTaskDetails.orderType,
              listTaskNotesFilter: this.listTaskNotesFilter,
            })
          );
        }
      });
  }

  fetchOrder(noteTaskDetails) {
    let orderNumber = noteTaskDetails.orderNumber as string;
    let orderType = noteTaskDetails.orderType;

    if (
      orderType === ORDER_TYPE.reservedInvoice ||
      (orderType !== ORDER_TYPE.reservedInvoice &&
        this.orderTypes.findIndex(
          (type) => type.OrderTypeCode === orderType
        ) === -1)
    ) {
      this.toastr.error('Cannot fetch this order without a register connected');
      return;
    }
    this.store.dispatch(
      new FetchJdeOrder({
        orderNumber: orderNumber,
        orderType: orderType,
      })
    );
  }

  navigateBack() {
    this._location.back();
  }

  toggleShowDoneColumn() {
    this.gridColumnApi.setColumnVisible('dateCompleted', true);
  }
}
