import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService, MessageService } from '@pos-app/core-ui';
import { ToastrService } from 'ngx-toastr';
import { SearchResults } from '../../core/models/search-results';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent implements OnInit, OnDestroy {
  public loadingTasksContentList: boolean = true;
  public entityID: string;
  public isNavBarHeaderVisible: boolean = false;
  public collapse: boolean = false;
  public close: boolean = true;
  public searchResults: SearchResults[];
  public accordionActiveIndex: number = -1;
  public message: string = 'Content will be uploaded soon';
  private unSubscribe$ = new Subject<void>();

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private toastr: ToastrService
  ) {}

  public ngOnInit(): void {
    this.entityID = this.route.snapshot.queryParams.entityID;
    this.messageService.setIsNavBarHidden(true);
    this.messageService.setIsFooterHidden(true);
    this.fetchContentStructure();
  }

  public ngOnDestroy(): void {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  public fetchContentStructure(): void {
    this.apiService
      .fetchContentStructure({
        entityID: this.entityID,
      })
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe({
        next: (data) => {
          this.searchResults = this.sortSearchResult(data.SearchResults);
        },
        error: (error) => {
          console.error(error);
        },
        complete: () => {
          this.loadingTasksContentList = false;
        },
      });
  }

  public toggle(index: number): void {
    this.accordionActiveIndex = index;
  }

  public onCollapse(): void {
    this.accordionActiveIndex = -1;
  }

  public onDownloadContent(
    sequenceID: number,
    fileSeq: number,
    fileLabel: string,
    fileAttachmentExist: boolean
  ): void {
    if (!fileAttachmentExist) {
      this.toastr.info(this.message, fileLabel);
    } else {
      this.apiService
        .downloadContentFile(
          {
            entityID: this.entityID,
            sequenceID: sequenceID,
            fileSeq: fileSeq,
          },
          fileLabel
        )
        .pipe(takeUntil(this.unSubscribe$))
        .subscribe();
    }
  }

  public sortSearchResult(data: any): SearchResults[] {
    let sortedResults: SearchResults[];
    sortedResults = data.sort((a, b) => (a.SequenceID < b.SequenceID ? -1 : 1));
    sortedResults = sortedResults.map((results) => {
      results.ContentFileArray.sort((a, b) => (a.FileSeq < b.FileSeq ? -1 : 1));
      return results;
    });
    return sortedResults;
  }
}
