<div class="card">
  <div class="card-header">
    <label>
      {{ gridHeader }}
    </label>
  </div>
  <div class="card-body">
    <div class="text-warning" *ngIf="gridResult && gridResult.length === 0">
      Your search yields no results - Please search again
    </div>
    <div *ngIf="gridResult && gridResult.length > 0">
      <div class="row">
        <div class="col-2">
          <input
            type="text"
            class="form-control"
            [(ngModel)]="searchValue"
            placeholder="Quick filter..."
          />
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-12">
          <ag-grid-angular
            class="ag-theme-balham datagrid"
            [quickFilterText]="searchValue"
            [masterDetail]="true"
            [detailCellRendererParams]="detailCellRendererParams"
            [keepDetailRows]="true"
            [rowData]="rowData"
            [pagination]="true"
            [paginationPageSize]="10"
            [enableRangeSelection]="true"
            [rowDragManaged]="true"
            [gridOptions]="masterGridOptions"
            (gridReady)="onGridReady($event)"
            [domLayout]="'autoHeight'"
            [context]="context"
            [frameworkComponents]="frameworkComponents"
            [domLayout]="'autoHeight'"
          >
          </ag-grid-angular>
        </div>
      </div>
    </div>
  </div>
</div>
