import { VehiclesAction, VehiclesActionTypes } from './vehicles.actions';
import {
  Vehicle,
  VehicleFilterDropdownValues
} from '@pos-app/data';

export const VEHICLES_FEATURE_KEY = 'vehicles';

/**
 * Interface for the 'Vehicles' data used in
 *  - VehiclesState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface VehiclesState {
  vehicleFilterDropdownValues: VehicleFilterDropdownValues;
  vehicleFilterDropdownValuesInitial: VehicleFilterDropdownValues;
  vehicles: Vehicle[];
  tooManyVehicles: string;
  selectedVehicle?: Vehicle;
  loaded: boolean;
  error?: any;
}

export interface VehiclesPartialState {
  readonly [VEHICLES_FEATURE_KEY]: VehiclesState;
}

export const initialState: VehiclesState = {
  vehicleFilterDropdownValues: null,
  vehicleFilterDropdownValuesInitial: null,
  vehicles: [],
  tooManyVehicles: '',
  loaded: false
};

export function reducer(
  state: VehiclesState = initialState,
  action: VehiclesAction
): VehiclesState {
  switch (action.type) {
    case VehiclesActionTypes.LoadVehicles: {
      if (
        Object.values(action.payload).filter(x => !!x).length === 0 &&
        state.vehicleFilterDropdownValuesInitial
      ) {
        // load from cache
        return {
          ...state,
          vehicles: [],
          tooManyVehicles: '',
          vehicleFilterDropdownValues: state.vehicleFilterDropdownValuesInitial
        };
      }
      return { ...state, loaded: false, selectedVehicle: null };
    }
    case VehiclesActionTypes.VehiclesLoadError: {
      return { ...state, loaded: true };
    }
    case VehiclesActionTypes.VehiclesLoaded: {
      const {
        Vehicles: vehicles,
        ErrorFlag,
        ErrorMessage,
        TooManyVehiclesYN: tooManyVehicles,
        ...vehicleFilterDropdownValues
      } = action.payload.responnse;
      const selectedVehicle =
        vehicles && vehicles.length === 1 ? vehicles[0] : null;
      state = {
        ...state,
        vehicleFilterDropdownValues,
        // cache initial form value so that we don't have to load again
        ...(action.payload.isInitial && {
          vehicleFilterDropdownValuesInitial: vehicleFilterDropdownValues
        }),
        vehicles,
        tooManyVehicles,
        selectedVehicle,
        loaded: true
      };
      break;
    }
  }
  return state;
}
