<div class="product-filter-list-container">
  <div
    [hidden]="!shouldShowProductFilters"
    style="padding: 15px; display: block; overflow: visible"
  >
    <div
      class="form-group search-filter"
      *ngIf="categoryData || attributeData || brandData || subbrandData"
    >
      <h3 class="arb-large-heading">Product Filter</h3>
      <div
        class="reset-filter"
        title="Click to reset filter"
        (click)="resetFilter()"
      >
        Reset Filter
      </div>
    </div>

    <div class="product-filter">
      <p-panel
        class="filter-group"
        [ngClass]="{ collapsed: categoryData.isCollapsed }"
        *ngIf="
          categoryData &&
          categoryData.categories &&
          categoryData.categories.length > 0
        "
      >
        <p-header class="filter-group-name">
          <div
            class="ui-helper-clearfix"
            (click)="categoryData.isCollapsed = !categoryData.isCollapsed"
          >
            Product Groups
            <span style="float: right" class="toggle-icon">
              <i class="pi pi-angle-up" *ngIf="!categoryData.isCollapsed"></i>
              <i class="pi pi-angle-down" *ngIf="categoryData.isCollapsed"></i>
            </span>
          </div>
        </p-header>
        <div class="values" *ngFor="let itm of categoryData.categories">
          <div
            class="filter-item"
            [hidden]="!itm.isSelected && categoryData.isCollapsed"
          >
            <div class="left">
              <input
                type="checkbox"
                [(ngModel)]="itm.isSelected"
                (change)="updateFilters('categories')"
              />
              <span class="filter-item-value">{{ itm.name }}</span>
            </div>
            <span class="product-cnt">{{ itm.products }}</span>
          </div>
        </div>
      </p-panel>
      <p-panel
        *ngIf="brandData && brandData.brands && brandData.brands.length > 0"
        class="filter-group"
        [ngClass]="{ collapsed: brandData.isCollapsed }"
      >
        <p-header class="filter-group-name">
          <div
            class="ui-helper-clearfix"
            (click)="updateFilterGroupUIStatus(brandData)"
          >
            Brand
            <span style="float: right" class="toggle-icon">
              <i class="pi pi-angle-up" *ngIf="!brandData.isCollapsed"></i>
              <i class="pi pi-angle-down" *ngIf="brandData.isCollapsed"></i>
            </span>
          </div>
        </p-header>
        <div class="values" *ngFor="let itm of brandData.brands">
          <div
            class="filter-item"
            [hidden]="!itm.isSelected && brandData.isCollapsed"
          >
            <div class="left">
              <input
                type="checkbox"
                [(ngModel)]="itm.isSelected"
                (change)="updateFilters('brandIds')"
              />
              <span class="filter-item-value">{{ itm.displayText }}</span>
            </div>
            <span class="product-cnt">{{ itm.products }}</span>
          </div>
        </div>
      </p-panel>
      <p-panel
        class="filter-group"
        [ngClass]="{ collapsed: subbrandData.isCollapsed }"
        *ngIf="
          subbrandData &&
          subbrandData.subbrands &&
          subbrandData.subbrands.length > 0
        "
      >
        <p-header class="filter-group-name">
          <div
            class="ui-helper-clearfix"
            (click)="updateFilterGroupUIStatus(subbrandData)"
          >
            Sub Brand
            <span style="float: right" class="toggle-icon">
              <i class="pi pi-angle-up" *ngIf="!subbrandData.isCollapsed"></i>
              <i class="pi pi-angle-down" *ngIf="subbrandData.isCollapsed"></i>
            </span>
          </div>
        </p-header>
        <div class="values" *ngFor="let itm of subbrandData.subbrands">
          <div
            class="filter-item"
            [hidden]="!itm.isSelected && subbrandData.isCollapsed"
          >
            <div class="left">
              <input
                type="checkbox"
                [(ngModel)]="itm.isSelected"
                (change)="updateFilters('subBrands')"
              />
              <span class="filter-item-value">{{ itm.value }}</span>
            </div>
            <span class="product-cnt">{{ itm.products }}</span>
          </div>
        </div>
      </p-panel>

      <ng-container
        *ngFor="let attributeDataDisplayGroup of attributeDataDisplay"
      >
        <p-panel
          *ngIf="attributeDataDisplayGroup.groups.length > 0"
          class="filter-group"
          [ngClass]="{ collapsed: attributeDataDisplayGroup.isCollapsed }"
        >
          <p-header class="filter-group-name">
            <div
              class="ui-helper-clearfix"
              (click)="updateFilterGroupUIStatus(attributeDataDisplayGroup)"
            >
              {{ attributeDataDisplayGroup.groupTitle }}
              <span style="float: right" class="toggle-icon">
                <i
                  class="pi pi-angle-up"
                  *ngIf="!attributeDataDisplayGroup.isCollapsed"
                ></i>
                <i
                  class="pi pi-angle-down"
                  *ngIf="attributeDataDisplayGroup.isCollapsed"
                ></i>
              </span>
            </div>
          </p-header>
          <div class="values">
            <ng-container
              *ngFor="
                let itm of attributeDataDisplayGroup.groups;
                let itmIndex = index
              "
            >
              <div
                class="filter-item"
                [hidden]="
                  !itm.isSelected && attributeDataDisplayGroup.isCollapsed
                "
                style="flex-direction: column"
              >
                <div class="parent-filter-att">
                  <div class="left">
                    <input
                      type="checkbox"
                      [(ngModel)]="itm.isSelected"
                      (change)="
                        updateFiltersForAttWithRelationship(
                          itm,
                          itmIndex,
                          attributeDataDisplayGroup
                        )
                      "
                    />
                    <span class="filter-item-value">{{ itm.value }}</span>
                  </div>
                  <span class="product-cnt">{{ itm.products }}</span>
                </div>
                <ng-container *ngIf="itm.isSelected">
                  <div
                    class="child-filter-att"
                    *ngFor="
                      let childItem of itm.childItems;
                      let selectedChildIndex = index
                    "
                  >
                    <div class="left">
                      <input
                        type="checkbox"
                        [disabled]="childItem.isDisabled"
                        [(ngModel)]="childItem.isSelected"
                        (change)="
                          updateFiltersForAttWithRelationship(
                            childItem,
                            selectedChildIndex,
                            itm.childItems
                          )
                        "
                      />
                      <span class="filter-item-value">
                        {{ childItem.value }}
                      </span>
                    </div>
                    <span class="product-cnt">{{ childItem.products }}</span>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </p-panel>
      </ng-container>
      <div *ngIf="!!selectedVehicle" style="display: flex">
        <label
          class="arb-detail-text arb-red"
          style="margin-left: 15px; margin-top: 15px"
          [hidden]="!useVehicleInFilter"
          ><input
            type="checkbox"
            [(ngModel)]="showUniversalProducts"
            (change)="updateShowUniversalProducts()"
            style="width: 16px; height: 16px"
          />
          <span style="margin-left: 10px">
            Show non-vehicle linked products
          </span>
        </label>
      </div>
    </div>
  </div>

  <h3 class="category-tree-title arb-large-heading">
    Browse Product Range
    <span *ngIf="treeData == null || treeData.length == 0" class="retrieving">
      <i class="pi pi-spin pi-spinner"></i>
    </span>
  </h3>
  <div *ngIf="cedCategories$ | async" class="category-tree">
    <div *ngIf="treeData?.length > 0">
      <p-tree
        [value]="treeData"
        selectionMode="single"
        [selection]="selectedCategory"
        (onNodeSelect)="onSelectCategory($event)"
      ></p-tree>
    </div>
  </div>
</div>

<ng-template #selectVehicleContent let-c="close " let-d="dismiss">
  <div class="modal-header">
    <h3 class="modal-title" id="modal-basic-title ">
      <ng-container [ngSwitch]="vehicleRequiredYPN">
        <ng-container *ngSwitchCase="'Y'">VEHICLE MUST BE SELECTED TO BROWSE PRODUCTS IN THIS CATEGORY</ng-container>
        <ng-container *ngSwitchCase="'P'">VEHICLE SELECTION RECOMMENDED TO BROWSE PRODUCTS IN THIS CATEGORY</ng-container>
        <ng-container *ngSwitchDefault>SELECT VEHICLE</ng-container>
      </ng-container>
    </h3>
  </div>
  <div class="modal-body">
    <app-vehicles-selection (cancelSelect)="d('Cross click'); onCancelSelectVehicle()" (submitCar)="onSelectVehicle($event)" [fromEpc]="true"></app-vehicles-selection>
  </div>
</ng-template>
