import {
  ColDef,
  ColumnApi,
  GridApi,
  GridOptions,
} from 'ag-grid-community';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  ApiService,
  DialogService
} from '@pos-app/core-ui';
import { DefaultOrder, ParkedOrders } from '@pos-app/data';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { OrderParkedGridActionRenderComponent } from '../../../orders/components/orderParkedGridActionrRenderer/orderParkedGridActionRenderer.component';

import { Store } from '@ngrx/store';
import { CustomersPartialState } from '../../+state/customers.reducer';

import {
  LoadParkedOrder,
  LoadParkedOrdersList
} from '../../../orders/+state/orders.actions';
import { OrdersPartialState } from '../../../orders/+state/orders.reducer';
import { ordersQuery } from '../../../orders/+state/orders.selectors';

@Component({
  selector: 'app-customers-parked-orders',
  templateUrl: './customers-parked-orders.component.html',
  styleUrls: ['./customers-parked-orders.component.scss'],
})
export class CustomersParkedOrdersComponent implements OnInit, OnDestroy {
  @Input() userID: string;
  @Output() onSaveOrderToJDE = new EventEmitter();

  gridOptions: GridOptions;
  columnDefs: ColDef[];
  frameworkComponents;
  context;
  parkedOrderList: ParkedOrders[];
  public selectedUser: string;
  private unSubscribe$ = new Subject<void>();
  private gridApi: GridApi;
  private gridColumnApi: ColumnApi;
  public loading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private apiService: ApiService,
    private toastrService: ToastrService,
    private customerStore: Store<CustomersPartialState>,
    private orderStore: Store<OrdersPartialState>,
    private dialogService: DialogService,
    @Inject(LOCALE_ID) public locale: string
  ) {
    this.frameworkComponents = {
      actionRenderer: OrderParkedGridActionRenderComponent,
    };
    this.context = { componentParent: this };
    this.gridOptions = this.gridOptionsData();
  }

  ngOnInit(): void {
    moment.locale(this.locale);
    this.selectedUser = this.userID;
    this.fetchAllParkedOrders();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const sortModel = [{ colId: 'dateTime', sort: 'desc' }];
  }

  gridOptionsData() {
    return {
      defaultColDef: {
        resizable: true,
        sortable: true,
        width: 150,
        cellClass: 'cell-class ag-right-aligned-cell',
        headerClass: 'header-class ag-left-aligned-header',
      },
      columnDefs: [
        {
          headerName: 'ACTION',
          width: 100,
          cellRenderer: 'actionRenderer',
          cellStyle: { 'text-align': 'center' },
        },
        {
          headerName: 'ORDER',
          width: 100,
          field: 'orderNumber',
          cellStyle: { 'text-align': 'right' },
        },
        {
          headerName: 'TYPE',
          width: 80,
          field: 'orderType',
          cellStyle: { 'text-align': 'center' },
        },
        {
          headerName: 'CUSTOMER NO',
          width: 150,
          field: 'customerNumber',
          cellStyle: { 'text-align': 'right' },
        },
        {
          headerName: 'CUSTOMER NAME',
          width: 200,
          field: 'customerName',
          cellStyle: { 'text-align': 'left' },
        },
        {
          headerName: 'CUSTOMER REFERENCE',
          width: 220,
          field: 'customerPO',
          cellStyle: { 'text-align': 'left' },
        },
        {
          headerName: 'DATE TIME',
          width: 180,
          field: 'dateTime',
          valueFormatter: this.dateFormatter,
          cellStyle: { 'text-align': 'left' },
        },
      ],
    };
  }

  dateFormatter(params) {
    return moment(params.value).format('L <HH:mm>');
  }

  fetchAllParkedOrders() {
    this.loading$.next(true);
    this.dispatchParkedOrdersList();
    this.orderStore
      .select(ordersQuery.getLoadingParkedOrdersList)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe(
        (res) => {
          this.parkedOrderList = res;
          if (!!this.parkedOrderList) {
            setTimeout(() => {
              this.loading$.next(false);
            }, 0);
          }
        },
        (error) => {
          this.toastrService.error(error);
          this.loading$.next(false);
        },
        () => {
          this.gridApi.setRowData(this.parkedOrderList);
        }
      );
  }

  loadParkedOrder(defaultOrder: DefaultOrder) {
    this.loading$.next(true);
    this.customerStore.dispatch(new LoadParkedOrder(defaultOrder));
    this.gridApi.setRowData(this.parkedOrderList);
    setTimeout(() => {
      this.loading$.next(false);
    }, 1000);
  }

  finaliseParkedOrderToJDE(parameters: any) {
    this.loading$.next(true);
    this.apiService
      .finaliseOrderToJDE({
        orderNumber: parameters.orderNumber,
        email: '',
        outputType: '',
        overrideJdeInvoiceNo: null,
        overrideJdeOrderNo: null,
        printerID: '',
      })
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe({
        next: (res) => {
          if (res.ErrorFlag === '0') {
            this.toastrService.success(res.ErrorMessage);
          }
          this.fetchAllParkedOrders();
        },
        error: (error) => {
          this.toastrService.error(error.ErrorMessage);
          this.fetchAllParkedOrders();
          this.loading$.next(false);
        },
        complete: () => {
          this.loading$.next(false);
          this.onSaveOrderToJDE.emit({ userId: this.selectedUser });
        },
      });
  }

  removeParkedOrder(defaultOrder: DefaultOrder, isOrderChanged: boolean) {
    isOrderChanged
      ? this.messageDialogForDeleteParkOrder(defaultOrder)
      : this.callRemoveParkedOrderService(defaultOrder);
  }

  callRemoveParkedOrderService(defaultOrder: DefaultOrder) {
    this.loading$.next(true);
    this.apiService
      .removeParkedOrder(defaultOrder)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe({
        next: () => {
          this.fetchAllParkedOrders();
        },
        error: (error) => {
          this.toastrService.error(error.ErrorMessage);
          this.fetchAllParkedOrders();
          this.loading$.next(false);
        },
        complete: () => {
          this.toastrService.success(
            `${defaultOrder.orderNumber} has been removed successfully`
          );

          this.loading$.next(false);
        },
      });
  }

  messageDialogForDeleteParkOrder(defaultOrder: DefaultOrder) {
    this.dialogService
      .confirm(
        `Are you sure you want to delete the ${defaultOrder.orderNumber} order? Either Select EXIT to go back or SAVE ORDER to add it to JDE.
    Select DISCARD to delete the order.
    You have unsaved changes!`,
        'WARNING: UNSAVED ORDER',
        `DISCARD`,
        `EXIT`,
        false,
        true,
        false,
        {
          action: this.apiService.finaliseOrderToJDE({
            orderNumber: defaultOrder.orderNumber,
            email: '',
            printerID: '',
            outputType: '',
          }),
          text: 'SAVE ORDER',
        }
      )
      .pipe(
        switchMap((val) => {
          if (val === true) {
            this.callRemoveParkedOrderService(defaultOrder);
          } else {
            this.fetchAllParkedOrders();
          }
          return of(null);
        })
      )
      .subscribe();
  }

  dispatchParkedOrdersList() {
    this.orderStore.dispatch(new LoadParkedOrdersList());
  }

  ngOnDestroy(): void {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }
}
