<div class="container pt-3">
  <form [formGroup]="giftCardForm">
    <div class="row pb-2">
      <div class="col-4 col-xl-3 my-auto">
        <label>ENTER GIFT CARD NUMBER *</label>
      </div>
      <div class="col-5">
        <input type="text" class="form-control" formControlName="cardNumber" />
      </div>
      <div class="col-1 my-auto" *ngIf="isPayment">
        <label>PIN *</label>
      </div>
      <div class="col-2" *ngIf="isPayment">
        <input type="text" class="form-control" formControlName="pin" />
      </div>
    </div>
    <div class="row pb-2">
      <div class="col-4 col-xl-3 my-auto">
        <label>CARD BALANCE</label>
      </div>
      <div class="col-5">
        <input
          type="text"
          class="form-control"
          [value]="giftCardForm.get('cardBalance').value !== null ? (giftCardForm.get('cardBalance').value | number : '1.2-2') : 'Not activated'"
          disabled
        />
      </div>
      <div class="col-3">
        <button class="btn btn-secondary" (click)="fetchCardBalance()" [disabled]="!giftCardForm.get('cardNumber').value || isFetching">
          FETCH BALANCE
          <pos-app-spinner2 *ngIf="isFetching"></pos-app-spinner2>
        </button>
      </div>
    </div>
    <div class="row pb-2">
      <div class="col-4 col-xl-3 my-auto">
        <label>AMOUNT {{ isPayment ? 'TO PAY' : loadedCard || giftCardForm.get('reference')?.value ? 'LOADED' : 'TO LOAD' }}</label>
      </div>
      <div class="col-5">
        <input
          type="text"
          class="form-control"
          [value]="giftCardForm.get('amount').value | number : '1.2-2'"
          (change)="giftCardForm.patchValue({ amount: $event.target.value })"
          [disabled]="!isPayment"
          [class.is-invalid]="giftCardForm.get('amount').errors?.max"
        />
        <small *ngIf="amountNeedToPaid && isPayment" class="text-danger">
          ${{ amountNeedToPaid }} WILL STILL NEED TO BE PAID AFTER USING UP THIS CARD
        </small>
      </div>
      <div class="col-3">
        <button
          class="btn btn-secondary"
          (click)="cardTransaction()"
          [disabled]="
            !giftCardForm.valid ||
            !giftCardForm.get('amount').value ||
            giftCardForm.get('cardBalance').value === '' ||
            (!isPayment && giftCardForm.get('receipt').value)
          "
        >
          {{ isPayment ? 'DEDUCT FROM BALANCE' : 'LOAD CARD' }}
          <pos-app-spinner2 *ngIf="isLoading"></pos-app-spinner2>
        </button>
      </div>
    </div>
    <div class="row pb-2">
      <div class="col-4 col-xl-3 my-auto">
        <label>RECEIPT</label>
      </div>
      <div class="col-5">
        <input type="text" class="form-control" formControlName="receipt" />
      </div>
    </div>
    <div class="row">
      <div class="col-4 col-xl-3 my-auto">
        <label>RESPONSE FROM VENDOR</label>
      </div>
      <div class="col-5">
        <input type="text" class="form-control" formControlName="response" />
      </div>
    </div>
  </form>
</div>
