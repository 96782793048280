import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OrdersRoutingModule } from './orders-routing.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromOrders from './+state/orders.reducer';
import { OrdersEffects } from './+state/orders.effects';
import { OrdersBaseComponent } from './containers/orders-base/orders-base.component';
import { DynamicFiltersComponent } from './components/dynamic-filters/dynamic-filters.component';
import { OrdersResultGridComponent } from './components/orders-result-grid/orders-result-grid.component';
import { StaticFiltersComponent } from './components/static-filters/static-filters.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { AgGridModule } from 'ag-grid-angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { CoreUiModule } from '@pos-app/core-ui';
import { MatIconModule } from '@angular/material/icon';
import { CustomersModule } from '../customers/customers.module';
import { OrdersStartComponent } from './containers/orders-start/orders-start.component';
import { VehiclesModule } from '../vehicles/vehicles.module';
import { OrdersDetailsComponent } from './containers/orders-details/orders-details.component';
import { OrderHeaderComponent } from './components/order-header/order-header.component';
import { OrderGridComponent } from './components/order-grid/order-grid.component';
import { HttpClientModule } from '@angular/common/http';
import { OrderGridActionRendererComponent } from './components/orderGridActionRenderer/orderGridActionRender.component';
import { OrderGridItemSearchComponent } from './components/orderGridItemSearchRenderer/orderGridItemSearch.component';
import { OrderTotalComponent } from './components/order-total/order-total.component';
import { CheckboxCellComponent } from './components/orderGridCheckboxCellRenderer/orderGridCheckboxCellRenderer.component';
import { ColourCellComponent } from './components/orderGridColourCellRenderer/orderGridColourCellRenderer.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { OrderDistributeComponent } from './components/order-distribute/order-distribute.component';
import { OrderSearchGridActionRendererComponent } from './components/orderSearchGridActionRenderer/orderSearchGridActionRender.component';
import { OrderGridActionHeaderComponent } from './components/orderGridActionHeader/orderGridActionHeader.component';
import { OrdersStockAllocationComponent } from './components/orders-stock-allocation/orders-stock-allocation.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { OrdersPaymentComponent } from './components/orders-payment/orders-payment.component';
import { OrdersGiftCardComponent } from './components/orders-gift-card/orders-gift-card.component';
import { OrdersCheckCreditUsersComponent } from './components/orders-check-credit-users/orders-check-credit-users.component';
import { OrdersBuyinSupplierComponent } from './components/orders-buyin-supplier/orders-buyin-supplier.component';
import { OrdersDetailedAvailabilityComponent } from './components/orders-detailed-availability/orders-detailed-availability.component';
import { OrdersGiftCardLoadupComponent } from './components/orders-gift-card-loadup/orders-gift-card-loadup.component';
import { OrderHeaderNotesComponent } from './components/order-header-notes/order-header-notes.component';
import { OrdersOverrideBranchComponent } from './components/orders-override-branch/orders-override-branch.component';
import { OrdersOverrideShowComponent } from './components/orders-override-show/orders-override-show.component';
import { OrdersEftposComponent } from './components/orders-eftpos/orders-eftpos.component';
import { MatBadgeModule } from '@angular/material/badge';
import { LineOrderAttachmentsComponent } from './components/line-order-attachments/line-order-attachments.component';
import { NgbdModalContent } from './containers/orders-details/orders-details.component';
import { OrderParkedGridActionRenderComponent } from './components/orderParkedGridActionrRenderer/orderParkedGridActionRenderer.component';
import { OrderLoadingCellRendererComponent } from './components/order-loading-cell-renderer/order-loading-cell-renderer.component';
import { DynamicFormControlComponent } from './components/dynamic-form-control/dynamic-form-control.component';
import { BranchPipe } from './pipes/branch.pipe';
import { OrdersBuyinSupplierDialogComponent } from './components/orders-buyin-supplier-dialog/orders-buyin-supplier-dialog.component';
import { TextCellEditorComponent } from './components/orderGridCellEditorRenderer/textCellEditor.component';
import { OrderItemNumberCellRendererComponent } from './components/order-item-number-cell-renderer/order-item-number-cell-renderer.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';

@NgModule({
  declarations: [
    NgbdModalContent,
    OrdersBaseComponent,
    DynamicFiltersComponent,
    OrdersResultGridComponent,
    StaticFiltersComponent,
    DynamicFormControlComponent,
    OrdersDetailsComponent,
    OrdersStartComponent,
    OrderHeaderComponent,
    OrderGridActionRendererComponent,
    OrderGridActionHeaderComponent,
    OrderSearchGridActionRendererComponent,
    OrderGridComponent,
    OrderGridItemSearchComponent,
    OrderTotalComponent,
    CheckboxCellComponent,
    ColourCellComponent,
    OrderDistributeComponent,
    OrdersStockAllocationComponent,
    OrdersPaymentComponent,
    OrdersGiftCardComponent,
    OrdersCheckCreditUsersComponent,
    OrdersBuyinSupplierComponent,
    OrdersDetailedAvailabilityComponent,
    OrdersGiftCardLoadupComponent,
    OrderHeaderNotesComponent,
    OrdersOverrideBranchComponent,
    OrdersOverrideShowComponent,
    OrdersEftposComponent,
    LineOrderAttachmentsComponent,
    OrderParkedGridActionRenderComponent,
    OrderLoadingCellRendererComponent,
    OrdersBuyinSupplierDialogComponent,
    BranchPipe,
    TextCellEditorComponent,
    OrderItemNumberCellRendererComponent,
  ],
  imports: [
    MatBadgeModule,
    CommonModule,
    NgbModule,
    HttpClientModule,
    MatIconModule,
    MatStepperModule,
    MatTabsModule,
    AgGridModule.withComponents([]),
    OrdersRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    CoreUiModule,
    CustomersModule,
    VehiclesModule,
    NgbModalModule,
    StoreModule.forFeature(fromOrders.ORDERS_FEATURE_KEY, fromOrders.reducer),
    EffectsModule.forFeature([OrdersEffects]),
    AngularEditorModule,
    NgxMaskModule.forRoot({
      validation: false,
    }),
  ],
})
export class OrdersModule {}
