<div *ngIf="selectedCustomer"><label>EDIT CUSTOMER</label></div>
<div class="card shadow-sm">
  <div class="card-body p-3">
    <form class="row" [formGroup]="customerDetailsForm">
      <div class="col-sm-6">
        <div class="form-group">
          <input
            class="form-check-input"
            type="checkbox"
            formControlName="useBusinessNameYN"
          />
          Use Business Name on Invoice
        </div>
        <div class="form-group row">
          <div class="col-6">
            <div class="row">
              <label class="col-sm-5 my-2 firstname-label" for="firstName"
                >FIRST NAME*</label
              >
              <div class="col-sm-7 firstname-value">
                <input
                  type="text"
                  class="form-control"
                  [class.is-invalid]="
                    customerDetailsForm.controls.firstName.touched &&
                    customerDetailsForm.controls.firstName.errors
                  "
                  formControlName="firstName"
                  (keyup.enter)="pressEnter()"
                  autofocus
                />
                <div
                  class="invalid-feedback"
                  *ngIf="
                    customerDetailsForm.controls.firstName.touched &&
                    customerDetailsForm.controls.firstName.errors
                  "
                >
                  This field is required
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="row">
              <label class="col-sm-5 my-2 surname-label" for="surname"
                >SURNAME*</label
              >
              <div class="col-sm-7 surname-value">
                <input
                  type="text"
                  class="form-control"
                  [class.is-invalid]="
                    customerDetailsForm.controls.surname.touched &&
                    customerDetailsForm.controls.surname.errors
                  "
                  formControlName="surname"
                  (keyup.enter)="pressEnter()"
                />
                <div
                  class="invalid-feedback"
                  *ngIf="
                    customerDetailsForm.controls.surname.touched &&
                    customerDetailsForm.controls.surname.errors
                  "
                >
                  This field is required
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 my-2" for="name">PHONE *</label>
          <div class="col-sm-8">
            <input
              type="text"
              class="form-control"
              [class.is-invalid]="
                customerDetailsForm.controls.phone.touched &&
                customerDetailsForm.controls.phone.errors
              "
              formControlName="phone"
              (keyup.enter)="pressEnter()"
            />
            <div
              class="invalid-feedback"
              *ngIf="
                customerDetailsForm.controls.phone.touched &&
                customerDetailsForm.controls.phone.errors?.required
              "
            >
              This field is required
            </div>
            <div
              class="invalid-feedback"
              *ngIf="
                customerDetailsForm.controls.phone.touched &&
                customerDetailsForm.controls.phone.errors?.pattern
              "
            >
              Phone number is invalid
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 my-2" for="email"
            >EMAIL
            <span *ngIf="customerDetailsForm.controls.marketingOptInYN.value &&
            customerDetailsForm.controls.marketingOptInYN.value!==''"
              >*</span>
          </label>
          <div class="col-sm-8">
            <input
              type="text"
              class="form-control"
              [class.is-invalid]="
                customerDetailsForm.controls.email.touched &&
                customerDetailsForm.controls.email.errors
              "
              formControlName="email"
              (keyup.enter)="pressEnter()"
            />
            <div
              class="invalid-feedback"
              *ngIf="
                customerDetailsForm.controls.marketingOptInYN.value===true &&
                customerDetailsForm.controls.email.errors?.required
              "
            >
              Email is required if the customer wants marketing emails
            </div>
            <div
              class="invalid-feedback"
              *ngIf="
                (customerDetailsForm.controls.email.touched &&
                customerDetailsForm.controls.email.errors?.email) || 
                (customerDetailsForm.controls.email.errors && 
                customerDetailsForm.controls.marketingOptInYN.value)
              "
            >
              Email is invalid
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 my-2" for="name">ADDRESS LINE 1</label>
          <div class="col-sm-8">
            <input
              type="text"
              class="form-control"
              formControlName="addressLine1"
              (keyup.enter)="pressEnter()"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 my-2" for="name">ADDRESS LINE 2</label>
          <div class="col-sm-8">
            <input
              type="text"
              class="form-control"
              formControlName="addressLine2"
              (keyup.enter)="pressEnter()"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 my-2" for="name">SUBURB</label>
          <div class="col-sm-8">
            <input
              type="text"
              class="form-control"
              formControlName="suburb"
              (keyup.enter)="pressEnter()"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-4 my-2" for="name">STATE</label>
          <div class="col-sm-8">
            <ng-select
              [items]="stateList"
              formControlName="state"
              bindLabel="StateDescription"
              bindValue="StateCode"
            >
            </ng-select>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 my-2" for="name">POSTCODE 
            <span *ngIf="customerDetailsForm.controls.marketingOptInYN.value &&
            customerDetailsForm.controls.marketingOptInYN.value!==''"
              >*</span> 
        </label>
          <div class="col-sm-8">
            <input
              type="text"
              class="form-control"
              [class.is-invalid]="
                (customerDetailsForm.controls.postCode.touched &&
                customerDetailsForm.controls.postCode.errors) || 
                 (customerDetailsForm.controls.postCode.errors 
                 && customerDetailsForm.controls.marketingOptInYN.value)
              "
              formControlName="postCode"
              (keyup.enter)="pressEnter()"

            />
            <div
            class="invalid-feedback"
            *ngIf="
              customerDetailsForm.controls.marketingOptInYN.value===true
            "
          >
            Postcode is required if the customer wants marketing emails
          </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 my-2" for="name">COUNTRY</label>
          <div class="col-sm-8">
            <ng-select
              [items]="lookupLists?.countryList"
              formControlName="country"
              bindLabel="CountryDescription"
              bindValue="CountryCode"
            >
            </ng-select>
            <div
              class="invalid-feedback"
              *ngIf="
                customerDetailsForm.controls.country.touched &&
                customerDetailsForm.controls.country.errors
              "
            >
              This field is required
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div
          class="form-group row"
          *ngIf="customerDetailsForm.controls.useBusinessNameYN.value"
        >
          <label class="col-sm-4 my-2" for="name">BUSINESS NAME *</label>
          <div class="col-sm-8">
            <input
              type="text"
              class="form-control"
              [class.is-invalid]="
                customerDetailsForm.controls.businessName.touched &&
                customerDetailsForm.controls.businessName.errors
              "
              formControlName="businessName"
              (keyup.enter)="pressEnter()"
            />
            <div
              class="invalid-feedback"
              *ngIf="
                customerDetailsForm.controls.businessName.touched &&
                customerDetailsForm.controls.businessName.errors?.required
              "
            >
              This field is required
            </div>
          </div>
        </div>
        <div
          class="form-group row"
          *ngIf="!customerDetailsForm.controls.useBusinessNameYN.value"
        >
          <label class="col-sm-4 my-2" for="name">SECONDARY CONTACT</label>
          <div class="col-sm-8">
            <input
              type="text"
              class="form-control"
              formControlName="secContactName"
              (keyup.enter)="pressEnter()"
            />
          </div>
        </div>

        <div
          class="form-group row"
          *ngIf="!customerDetailsForm.controls.useBusinessNameYN.value"
        >
          <label class="col-sm-4 my-2" for="name"
            >SECONDARY PHONE
            <span
              *ngIf="customerDetailsForm.controls.secContactName.value !== ''"
              >*</span
            ></label
          >
          <div class="col-sm-8">
            <input
              type="text"
              class="form-control"
              [class.is-invalid]="
                customerDetailsForm.controls.secContactPhone.touched &&
                customerDetailsForm.controls.secContactPhone.errors
              "
              formControlName="secContactPhone"
              (keyup.enter)="pressEnter()"
            />
            <div
              class="invalid-feedback"
              *ngIf="
                customerDetailsForm.controls.secContactName.value !== '' &&
                customerDetailsForm.controls.secContactPhone.touched &&
                customerDetailsForm.controls.secContactPhone.errors?.required
              "
            >
              This field is required
            </div>
            <div
              class="invalid-feedback"
              *ngIf="
                customerDetailsForm.controls.secContactPhone.errors?.pattern
              "
            >
              Phone number is invalid
            </div>
          </div>
        </div>
        <ng-container
          *ngIf="
            selectedCustomer?.CustomerType === customerType.billTo ||
            selectedCustomer?.CustomerType === customerType.shipTo
          "
        >
          <div class="form-group row">
            <label class="col-sm-4 my-2" for="zone">PICK SCHEDULE (ZONE)</label>
            <ng-select
              [items]="lookupLists?.zoneList"
              formControlName="zone"
              bindLabel="ZoneDescription"
              bindValue="ZoneCode"
              class="col-sm-7"
            >
            </ng-select>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 my-2" for="route">PICK DAYS (ROUTE)</label>
            <ng-select
              [items]="lookupLists?.routeList"
              formControlName="route"
              bindLabel="RouteDescription"
              bindValue="RouteCode"
              class="col-sm-7"
            >
            </ng-select>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 my-2" for="freightHandlingCode"
              >FREIGHT HANDLING</label
            >
            <ng-select
              [items]="lookupLists?.freightList"
              formControlName="freightHandlingCode"
              bindLabel="FreightHandlingDescription"
              bindValue="FreightHandlingCode"
              class="col-sm-7"
            >
            </ng-select>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 my-2" for="carrier">CARRIER</label>
            <ng-select formControlName="carrier" class="col-sm-7">
              <ng-option
                *ngFor="let carrier of lookupLists?.carrierList"
                [value]="carrier.CarrierCode"
                [title]="carrier.CarrierCode"
              >
                {{ carrier.CarrierDescription }} ({{
                  carrier.CarrierCode
                }}) </ng-option
              >>
            </ng-select>
          </div>
        </ng-container>
        <div class="shadow mt-4 p-4 mb-5 bg-white rounded" *ngIf="brandDefaults?.showMarketingOptInTF" >
        <div class="form-group" *ngIf="!isReadOnlyCustomer">
          <div class="mt-2" >
            <label>Sign customer up to ARB’s email subscription?*</label>
         </div>
        <div class="mt-2">By joining our subscription, you’ll get the following perks delivered straight to your inbox:</div> 
          <div class="mt-2">
            <ul>
              <li>Your FREE digital copy of 4x4 Culture magazine</li> 

              <li>Brand new product launches and offers</li>

              <li>In-store promotions and events in your local area</li>
            </ul>
          </div>
          <div>
            NOTE: It's free to Sign up and you can unsubscribe or change your email preferences at any time.
          </div>
          
             <div class="mt-2">
             Please select:
             <div>
             <div class="form-check form-check-inline">
                <input class="form-check-input" 
                  type="radio" 
                  formControlName="marketingOptInYN" 
                  [value]="true"
                  (keyup.enter)="pressEnter"
                  checked>
                <label class="form-check-label" for="radio1" >
                    YES
                </label>
             </div>
             <div class="form-check form-check-inline">
              <input class="form-check-input" 
                type="radio" 
                formControlName="marketingOptInYN" 
                [value]="false" 
                (keyup.enter)="pressEnter"
                >
              <label class="form-check-label" for="radio1" >
                  NO
              </label>
            </div> 
            <div class="form-check form-check-inline">
              <input class="form-check-input" 
                type="radio" 
                formControlName="marketingOptInYN" 
                value="" 
                (keyup.enter)="pressEnter"
                checked>
              <label class="form-check-label" for="radio1" >
                  UNCONFIRMED
              </label>
            </div> 
          </div>     
          </div>  
        </div>
      </div>
      </div>
    </form>
  </div>
</div>
