import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { ApiService, CoreUiPartialState, getBranch } from '@pos-app/core-ui';
import {
  Branch,
  ChangeOrderBooking,
  ConsolidateOrder,
  DefaultOrder,
  FitterFilters,
  OrderForBooking,
  ReassignLine,
  RenameGroupName,
  Resource,
  ResourceListPayload,
  WeekListObject,
} from '@pos-app/data';

import {
  formatDateForInternal,
  getCurrentDate,
} from 'libs/core-ui/src/lib/utils/utils-date';
import {
  ALLOCATE_FITTER_TOASTR,
  ALLOCATE_FITTER_UNIQUE_IDS,
  NO_FITTER_RESOURCE_ID,
  SERVER_RESPONSE_MESSAGES,
} from 'libs/data/src/lib/constants/allocate-fitter';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { concatMap, finalize, map, takeUntil } from 'rxjs/operators';
import { LoadParkedOrdersList } from '../../../orders/+state/orders.actions';
import { OrdersPartialState } from '../../../orders/+state/orders.reducer';
import { AllocateOrderDialogComponent } from '../../components/allocate-order-dialog/allocate-order-dialog.component';
@Component({
  selector: 'app-allocate-fitter',
  templateUrl: './allocate-fitter.component.html',
  styleUrls: ['./allocate-fitter.component.scss'],
})
export class AllocateFitterComponent implements OnInit, OnDestroy {
  private unSubscribe$ = new Subject<void>();
  private formatter: NgbDateParserFormatter;

  defaultBranch$: Observable<Branch>;
  branchList: Branch[];
  currentDate: string;
  weekList: WeekListObject[];
  resourceListPayload: ResourceListPayload;
  fitterFiltersData: FitterFilters;
  resourceList: Resource[];
  x: Resource[];
  unAssignedOrdersList: OrderForBooking[];
  assignedOrdersList: OrderForBooking[];
  fuzzyResourceOrdersList: OrderForBooking[];
  dragDropUniqueIds: Map<string, string[]>;
  assignedOrders: Map<string, OrderForBooking[]>;
  reassignLineObject: ReassignLine;
  arrayOfLineOrderNumber: string;
  arrayOfLineOrderType: string;
  arrayOfLineFromGroupName: string;
  arrayOfLineLineNumber: string;
  changeOrderBooking: ChangeOrderBooking;
  orderForBookingDate: string;
  fuzzySearchResourceList: Resource[];

  public loading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private coreUIStore: Store<CoreUiPartialState>,
    private orderStore: Store<OrdersPartialState>,
    private apiService: ApiService,
    private toastrService: ToastrService,
    public dialog: MatDialog,
    @Inject(LOCALE_ID) public locale: string
  ) {}

  ngOnInit(): void {
    this.defaultBranch$ = this.coreUIStore.pipe(
      takeUntil(this.unSubscribe$),
      select(getBranch)
    );

    this.currentDate = this.getCurrentDate();

    this.apiService
      .fetchWeekList()
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((x) => {
        this.weekList = x.weekList;
      });

    this.apiService
      .getBranchList()
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((res) => {
        this.branchList = res.SearchResults;
      });

    this.dragDropUniqueIds = new Map();
    this.fuzzySearchResourceList = new Array<Resource>();
    this.initializeAssignedOrders();
    // this.dragDropUniqueIds = new Map([
    //   ['orderForBookingCards', ['orderForBooking']],
    // ]);
    this.dispatchParkedOrdersList();
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  getCurrentDate() {
    return getCurrentDate(this.locale);
  }

  getResourceList(data: ResourceListPayload): Observable<any> {
    return this.apiService.fetchResourceList(data);
  }

  getOrdersForBooking(data: FitterFilters): Observable<any> {
    return this.apiService.fetchOrdersForBooking(data);
  }

  fetchResourceListAndOrderForBooking(data: FitterFilters) {
    this.loading$.next(true);
    if (!!data) {
      this.fitterFiltersData = data;

      this.resourceListPayload = {
        startDate: data.startDate,
        endDate: data.endDate,
        branch: data.branch,
      };

      this.getResourceList(this.resourceListPayload)
        .pipe(
          concatMap((resourceResponse) => {
            return this.getOrdersForBooking(this.fitterFiltersData).pipe(
              map((ordersForBooking) => {
                this.assignedOrdersList = this.getAssignedOrdersList(
                  ordersForBooking.Response
                );

                this.unAssignedOrdersList = this.getUnAssignedOrdersList(
                  ordersForBooking.Response
                );

                this.fuzzyResourceOrdersList = this.assignedOrdersList;

                resourceResponse.resourceList.map((resource) =>
                  this.populateResourceAllocatedOrders(
                    this.fuzzyResourceOrdersList,
                    resource.resourceID
                  )
                );

                resourceResponse.resourceList.forEach((resource) =>
                  this.addDragDropResourceIdsList(resource.resourceID)
                );

                return {
                  resourceList: resourceResponse.resourceList,
                  orderForBooking: ordersForBooking.Response,
                  message: ordersForBooking.ErrorMessage,
                };
              })
            );
          }),
          concatMap((res) => {
            if (
              !!this.fuzzyResourceOrdersList &&
              this.fuzzyResourceOrdersList.length > 0
            ) {
              return this.fuzzySearchResponseList(
                res.resourceList,
                res.orderForBooking
              );
            } else {
              return of(res);
            }
          })
        )
        .subscribe({
          next: (res: any) => {
            this.sortLists(res);
            this.toastrService.success(res.message);
          },
          error: (error) => {
            this.loading$.next(false);
            this.toastrService.error(error?.ErrorMessage);
            this.clearResourceList();
            this.clearFitterFiltersData();
          },
          complete: () => {
            this.loading$.next(false);
          },
        });
    }
  }

  fetchOrderForBookingAndResourceList(data: FitterFilters) {
    this.loading$.next(true);
    if (!!data) {
      this.fitterFiltersData = data;
      this.getOrdersForBooking(this.fitterFiltersData)
        .pipe(
          map((ordersForBooking) => {
            this.assignedOrdersList = this.getAssignedOrdersList(
              ordersForBooking.Response
            );

            this.unAssignedOrdersList = this.getUnAssignedOrdersList(
              ordersForBooking.Response
            );

            this.fuzzyResourceOrdersList = this.assignedOrdersList;

            return {
              orderForBooking: ordersForBooking.Response,
              resourceListPayload: {
                branch: data.branch,
                startDate: !!ordersForBooking.Response[0].InvoiceDate.trim()
                  ? ordersForBooking.Response[0].InvoiceDate
                  : ordersForBooking.Response[0].RequestDate,
                endDate: !!ordersForBooking.Response[0].InvoiceDate.trim()
                  ? ordersForBooking.Response[0].InvoiceDate
                  : ordersForBooking.Response[0].RequestDate,
              },
            };
          }),
          concatMap((response) => {
            return this.getResourceList(response.resourceListPayload).pipe(
              map((resourceResponse) => {
                resourceResponse.resourceList.map((resource) =>
                  this.populateResourceAllocatedOrders(
                    this.fuzzyResourceOrdersList,
                    resource.resourceID
                  )
                );

                resourceResponse.resourceList.forEach((resource) =>
                  this.addDragDropResourceIdsList(resource.resourceID)
                );
                return {
                  resourceList: resourceResponse.resourceList,
                  orderForBooking: response.orderForBooking,
                  message: resourceResponse.ErrorMessage,
                };
              })
            );
          }),
          concatMap((res) => {
            if (
              !!this.fuzzyResourceOrdersList &&
              this.fuzzyResourceOrdersList.length > 0
            ) {
              return this.fuzzySearchResponseList(
                res.resourceList,
                res.orderForBooking
              );
            } else {
              return of(res);
            }
          })
        )
        .subscribe({
          next: (res: any) => {
            this.sortLists(res);
            this.toastrService.success(res.message);
          },
          error: (error) => {
            this.loading$.next(false);
            this.toastrService.error(error?.ErrorMessage);
            this.clearResourceList();
            this.clearFitterFiltersData();
          },
          complete: () => {
            this.loading$.next(false);
          },
        });
    }
  }

  getAssignedOrdersList(ordersForBookingList: OrderForBooking[]) {
    return ordersForBookingList
      .filter((orderForBooking) => {
        if (orderForBooking.ResourceAssigned.trim().length > 0) {
          return orderForBooking;
        }
      })
      .sort((a, b) => (a.OrderNumber < b.OrderNumber ? -1 : 1));
  }

  getUnAssignedOrdersList(ordersForBookingList: OrderForBooking[]) {
    return ordersForBookingList
      .filter((orderForBooking) => {
        if (orderForBooking.ResourceAssigned.trim().length === 0) {
          return orderForBooking;
        }
      })
      .sort((a, b) => (a.OrderNumber < b.OrderNumber ? -1 : 1));
  }

  addDragDropResourceIdsList(resourceID: string) {
    if (
      !this.dragDropUniqueIds.has(ALLOCATE_FITTER_UNIQUE_IDS.OrderForBooking)
    ) {
      this.dragDropUniqueIds.set(ALLOCATE_FITTER_UNIQUE_IDS.OrderForBooking, [
        ALLOCATE_FITTER_UNIQUE_IDS.OrderForBooking,
      ]);
    }

    if (
      !this.dragDropUniqueIds
        .get(ALLOCATE_FITTER_UNIQUE_IDS.OrderForBooking)
        .find((x) => x == resourceID)
    ) {
      this.dragDropUniqueIds
        .get(ALLOCATE_FITTER_UNIQUE_IDS.OrderForBooking)
        .push(resourceID);
    }
  }

  addDragDropArrayOfLinesIdsList(
    orderNumber: string,
    orderType: string,
    groupName: string
  ) {
    if (!this.dragDropUniqueIds.has(orderNumber)) {
      this.dragDropUniqueIds.set(orderNumber, [
        ALLOCATE_FITTER_UNIQUE_IDS.ArrayOfLineDropZone,
      ]);
    }
    if (
      !this.dragDropUniqueIds
        .get(orderNumber)
        .find((x) => x == `${orderNumber}-${orderType}-${groupName}`)
    ) {
      this.dragDropUniqueIds
        .get(orderNumber)
        .push(`${orderNumber}-${orderType}-${groupName}`);
    }

    if (
      !this.dragDropUniqueIds.has(
        ALLOCATE_FITTER_UNIQUE_IDS.ArrayOfLineDropZone
      )
    ) {
      this.dragDropUniqueIds.set(
        ALLOCATE_FITTER_UNIQUE_IDS.ArrayOfLineDropZone,
        [ALLOCATE_FITTER_UNIQUE_IDS.ArrayOfLineDropZone]
      );
    }

    if (
      !this.dragDropUniqueIds
        .get(ALLOCATE_FITTER_UNIQUE_IDS.ArrayOfLineDropZone)
        .find((x) => x == `${orderNumber}-${orderType}-${groupName}`)
    ) {
      this.dragDropUniqueIds
        .get(ALLOCATE_FITTER_UNIQUE_IDS.ArrayOfLineDropZone)
        .push(`${orderNumber}-${orderType}-${groupName}`);
    }
  }

  populateResourceAllocatedOrders(
    allocatedOrdersList: OrderForBooking[],
    resourceID: string
  ) {
    let list: OrderForBooking[] = allocatedOrdersList.filter(
      (assignedOrder) => assignedOrder.ResourceAssigned.trim() === resourceID
    );

    this.fuzzyResourceOrdersList = allocatedOrdersList.filter(
      (assignedOrder) => !list.includes(assignedOrder)
    );

    this.assignedOrders.set(resourceID, list);
  }

  populateFuzzySearchResourceAllocatedOrders(
    fuzzySearchResourceOrdersList: OrderForBooking[]
  ) {
    fuzzySearchResourceOrdersList.forEach((orderForBooking) => {
      this.getFuzzySearchResourceById(
        orderForBooking.ResourceAssigned.trim()
      ).subscribe({
        next: (res) => {
          if (res.ErrorMessage.trim() === SERVER_RESPONSE_MESSAGES.Success) {
            this.fuzzySearchResourceList.push(res.resourceList);
          }
        },
        error: (error) => {
          this.toastrService.error(error?.ErrorMessage);
        },
      });
    });
  }

  updateGroupName(newGroupName: RenameGroupName) {
    this.apiCallAction(
      this.apiService.renameGroupName(newGroupName),
      () => {},
      this.hasTransactionNumber()
    );
  }

  getFuzzySearchResourceById(resourceID) {
    return this.apiService
      .fetchResourceById({ resourceID: resourceID })
      .pipe(takeUntil(this.unSubscribe$));
  }

  getSplitOrder(parameters: DefaultOrder) {
    this.apiCallAction(
      this.apiService.splitOrder(parameters),
      () => {},
      this.hasTransactionNumber()
    );
  }

  getConsolidateOrder(parameters: ConsolidateOrder) {
    this.apiCallAction(
      this.apiService.consolidateOrder(parameters),
      () => {},
      this.hasTransactionNumber()
    );
  }

  reassignLine(arrayOfLinesDragDrop: any) {
    if (
      !!arrayOfLinesDragDrop.currentContainer &&
      arrayOfLinesDragDrop.previousContainer ===
        arrayOfLinesDragDrop.currentContainer
    ) {
      this.moveItemInContainer(
        arrayOfLinesDragDrop.currentContainer.data,
        arrayOfLinesDragDrop.previousIndex,
        arrayOfLinesDragDrop.currentIndex
      );
      return;
    } else if (
      !!arrayOfLinesDragDrop.currentContainer &&
      arrayOfLinesDragDrop.previousContainer !==
        arrayOfLinesDragDrop.currentContainer
    ) {
      this.transferItemToOtherContainer(
        arrayOfLinesDragDrop.previousContainer.data,
        arrayOfLinesDragDrop.currentContainer.data,
        arrayOfLinesDragDrop.previousIndex,
        arrayOfLinesDragDrop.currentIndex
      );
    }

    this.reassignLineObject = {
      orderNumber: this.arrayOfLineOrderNumber,
      orderType: this.arrayOfLineOrderType,
      fromGroupName: this.arrayOfLineFromGroupName,
      toGroupName: arrayOfLinesDragDrop.toGroupName,
      lineNumber: this.arrayOfLineLineNumber,
    };

    this.apiCallAction(
      this.apiService.reassignLine(this.reassignLineObject),
      () => {},
      this.hasTransactionNumber()
    );
  }

  changeOrderForBooking(changeOrderBooking: any) {
    if (
      changeOrderBooking.previousContainer ===
      changeOrderBooking.currentContainer
    ) {
      this.moveItemInContainer(
        changeOrderBooking.currentContainer.data,
        changeOrderBooking.previousIndex,
        changeOrderBooking.currentIndex
      );
      return;
    } else {
      this.transferItemToOtherContainer(
        changeOrderBooking.previousContainer.data,
        changeOrderBooking.currentContainer.data,
        changeOrderBooking.previousIndex,
        changeOrderBooking.currentIndex
      );
      if (
        !!changeOrderBooking.isOrderAssignedToFitter &&
        changeOrderBooking.resourceID !== NO_FITTER_RESOURCE_ID
      ) {
        const dialogRef = this.dialog.open(AllocateOrderDialogComponent, {
          height: '250px',
          width: '300px',
          data: changeOrderBooking.startDate.toString(),
          disableClose: true,
        });

        dialogRef
          .afterClosed()
          .pipe(takeUntil(this.unSubscribe$))
          .subscribe((result) => {
            this.orderForBookingDate = this.formatDate(result);

            this.changeOrderBooking = {
              orderNumber: changeOrderBooking.orderNumber,
              orderType: changeOrderBooking.orderType,
              groupName: changeOrderBooking.GroupName,
              resourceID: changeOrderBooking.resourceID,
              startTime: changeOrderBooking.startTime,
              endTime: changeOrderBooking.endTime,
              startDate: this.orderForBookingDate,
              endDate: this.orderForBookingDate,
            };

            if (this.hasTransactionNumber()) {
              this.fitterFiltersData.startDate = this.orderForBookingDate;
              this.fitterFiltersData.endDate = this.orderForBookingDate;
            }

            this.changeOrderForBookingCallAction(this.changeOrderBooking);
          });
      } else if (
        !changeOrderBooking.isOrderAssignedToFitter ||
        changeOrderBooking.resourceID === NO_FITTER_RESOURCE_ID
      ) {
        this.changeOrderBooking = {
          orderNumber: changeOrderBooking.orderNumber,
          orderType: changeOrderBooking.orderType,
          groupName: changeOrderBooking.GroupName,
          resourceID: changeOrderBooking.resourceID,
          startTime: changeOrderBooking.startTime,
          endTime: changeOrderBooking.endTime,
          startDate: changeOrderBooking.startDate,
          endDate: changeOrderBooking.endDate,
        };

        if (this.hasTransactionNumber()) {
          this.fitterFiltersData.startDate = '';
          this.fitterFiltersData.endDate = '';
        }

        this.changeOrderForBookingCallAction(
          this.changeOrderBooking,
          this.hasTransactionNumber()
        );
      } else {
        return;
      }
    }
  }

  changeOrderForBookingCallAction(
    changeOrderBooking: ChangeOrderBooking,
    isOrderByTransactionNo?: boolean
  ) {
    this.apiCallAction(
      this.apiService.changeOrderBooking(changeOrderBooking),
      () => {},
      isOrderByTransactionNo
    );
  }

  moveItemInContainer(data, previousIndex, currentIndex) {
    moveItemInArray(data, previousIndex, currentIndex);
  }

  transferItemToOtherContainer(
    previousData: any,
    currentData: any,
    previousIndex: number,
    currentIndex: number
  ) {
    transferArrayItem(previousData, currentData, previousIndex, currentIndex);
  }

  apiCallAction(
    apiCall,
    callback?: () => void,
    isOrderByTransactionNo?: boolean
  ) {
    return apiCall
      .pipe(
        takeUntil(this.unSubscribe$),
        finalize(() => {
          if (!!isOrderByTransactionNo) {
            this.fetchOrderForBookingAndResourceList(this.fitterFiltersData);
          } else {
            this.fetchResourceListAndOrderForBooking(this.fitterFiltersData);
          }
        })
      )
      .subscribe({
        next: (res) => {},
        error: (error) => {
          this.toastrService.error(error?.ErrorMessage);
        },
        complete: () => {
          !!callback ? callback() : null;
        },
      });
  }

  setArrayOfLineParentOrderData(draggedArrayOfLineCard: any) {
    this.arrayOfLineOrderNumber = draggedArrayOfLineCard.orderNumber;
    this.arrayOfLineOrderType = draggedArrayOfLineCard.orderType;
    this.arrayOfLineFromGroupName = draggedArrayOfLineCard.fromGroupName;
    this.arrayOfLineLineNumber = draggedArrayOfLineCard.lineNumber;
  }

  dispatchParkedOrdersList() {
    this.orderStore.dispatch(new LoadParkedOrdersList());
  }

  initializeAssignedOrders() {
    this.assignedOrders = new Map();
  }

  formatDate(date: string) {
    return moment(date).format('DD/MM/YYYY');
  }

  //TODO:openDialog as method
  openDialog(data: any) {
    const dialogRef = this.dialog.open(AllocateOrderDialogComponent, {
      height: '250px',
      width: '300px',
      data: { date: data.requestDate },
    });
  }

  loadResourceAvailability(searchedResource: Resource) {
    if (
      this.resourceList.find(
        (resource) => resource.resourceID === searchedResource.resourceID
      )
    ) {
      return;
    } else {
      this.resourceList.push({
        resourceID: searchedResource.resourceID,
        resourceName: searchedResource.resourceName,
      });
      this.addDragDropResourceIdsList(searchedResource.resourceID);
      this.populateResourceAllocatedOrders(
        this.assignedOrdersList,
        searchedResource.resourceID
      );
    }
  }

  fuzzySearchResponseList(
    resourceList: Resource[],
    orderForBooking: OrderForBooking[]
  ) {
    const orderForBookingArray$: Observable<any>[] = [];
    this.fuzzyResourceOrdersList.forEach((orderForBooking) => {
      const orderForBooking$: Observable<any> = this.getFuzzySearchResourceById(
        orderForBooking.ResourceAssigned.trim()
      ).pipe(
        map((fuzzySearchResource) => fuzzySearchResource.resourceList.pop())
      );
      orderForBookingArray$.push(orderForBooking$);
    });
    return forkJoin(orderForBookingArray$).pipe(
      map((fuzzySearchResourceList) => {
        return {
          resourceList: resourceList,
          orderForBooking: orderForBooking,
          fuzzySearchResourceList: fuzzySearchResourceList,
        };
      })
    );
  }

  sortLists(res: any) {
    this.resourceList = res.resourceList;
    this.resourceList.sort((a, b) => (a.resourceID < b.resourceID ? -1 : 1));
    if (
      !!this.fuzzyResourceOrdersList &&
      this.fuzzyResourceOrdersList.length > 0
    ) {
      res.fuzzySearchResourceList.forEach((resource) => {
        this.loadResourceAvailability(resource);
      });
    }

    this.unAssignedOrdersList.forEach((order) => {
      if (order.ArrayOfLines.length > 0) {
        this.addDragDropArrayOfLinesIdsList(
          order.OrderNumber.toString(),
          order.OrderType,
          order.GroupName
        );
      }
    });
  }

  hasTransactionNumber() {
    return this.fitterFiltersData.transactionNumber.trim() ? true : false;
  }

  clearResourceList() {
    this.resourceList = [];
  }

  clearFitterFiltersData() {
    this.fitterFiltersData = {} as FitterFilters;
  }
}
