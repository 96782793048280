<div class="container pb-5" *ngIf="selectedCustomer">
  <pos-app-spinner
    [show]="loadingTasksNotesList || loadingUpdateAlertMessage"
  ></pos-app-spinner>
  <div class="text-center">
    <h3 class="pt-3 arb-large-heading">CUSTOMER NOTES</h3>
  </div>
  <!-- Customer Details -->
  <div class="row py-2 mt-4">
    <div class="col-md-5 col-xl-4 col-sm-12">
      <div class="row">
        <label class="col-form-label font-weight-bold">NAME</label>
        <div class="ml-3">
          <u
            [routerLink]="[
              '/pos/customers/' + selectedCustomer.CustomerNumber + '/details'
            ]"
            >{{ selectedCustomer.CustomerName }}</u
          >
        </div>
      </div>
    </div>
    <div class="col-md-3 col-xl-3 col-sm-12">
      <div class="row">
        <label class="col-form-label font-weight-bold">TYPE</label>
        <div class="ml-3">
          {{ selectedCustomer.CustomerType }}
        </div>
      </div>
    </div>
    <div class="col-md-4 col-xl-2 col-sm-12">
      <div class="row">
        <label class="col-2 col-form-label font-weight-bold">PH</label>
        <div class="ml-3 col-auto">
          {{ selectedCustomer.Phone }}
        </div>
      </div>
    </div>
  </div>
  <form [formGroup]="noteTaskForm">
    <div class="row py-2">
      <div class="col-md-5 col-xl-4 col-sm-12">
        <div class="row">
          <label class="col-form-label font-weight-bold">ALERT MESSAGE</label>
        </div>
      </div>
      <div class="col-md-7 col-xl-7 col-sm-12">
        <div class="row">
          <label
            class="col-form-label col-form-label__last-updated font-weight-bold"
            >LAST UPDATED</label
          >
          <div class="ms-3 col">
            <input formControlName="alertMessageLastUpdatedBy" readonly />
            <input
              class="ms-3"
              formControlName="alertMessageLastUpdated"
              readonly
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Notes Area -->
    <div class="row py-2">
      <div class="col-md-11 col-xl-11 col-sm-12">
        <textarea
          formControlName="alertMessageText"
          class="form-control ms-n3"
        ></textarea>
      </div>
      <div class="col-md-1 col-xl-1 col-sm-12">
        <div class="row justify-content-end">
          <button class="btn btn-secondary" (click)="saveAlertMessage()">
            Save
          </button>
        </div>
        <div class="row mt-2 justify-content-end">
          <button class="btn text-white" (click)="clearAlertMessage()">
            Clear
          </button>
        </div>
      </div>
    </div>
  </form>
  <!-- Timeline -->
  <div class="row py-2">
    <div class="col-md-5 col-xl-4 col-sm-12">
      <div class="row">
        <label class="col-form-label font-weight-bold">TIMELINE</label>
      </div>
    </div>
  </div>

  <app-customers-notes-task-timeline
    [selectedCustomer]="selectedCustomer"
    [orderTypes]="orderTypes"
    [rowData]="rowData"
    [listTaskNotesFilter]="listTaskNotesFilter"
  ></app-customers-notes-task-timeline>
</div>
