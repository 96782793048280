import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PartDetails } from '../../../../core/models/warranty-form.models';

@Component({
  selector: 'app-warrantyform-parts',
  templateUrl: './warrantyform-parts.component.html',
  styleUrls: ['./warrantyform-parts.component.scss']
})
export class WarrantyformPartsComponent
  implements OnInit, OnDestroy, OnChanges {
  @Input() isReplacement: boolean;
  @Input() itemToReplace: PartDetails;
  partsForm: FormGroup;
  @Output() partGrid = new EventEmitter<any>();
  @Output() replaceItem = new EventEmitter<PartDetails>();
  unSubscribe$ = new Subject<void>();

  constructor(private fb: FormBuilder) {
    this.partsForm = this.fb.group({
      parts: this.fb.array([])
    });
  }

  ngOnInit() {
    this.partsForm.controls.parts.valueChanges
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe(() => {
        this.partGrid.emit({
          value: this.partsForm.controls.parts.value,
          isValid: this.partsForm.controls.parts.valid
        });
      });
    this.isReplacement ? null : this.addPart();
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.itemToReplace && changes.itemToReplace.currentValue) {
      this.addPart(this.itemToReplace);
    }
  }

  addPart(itemToReplace: PartDetails = null) {
    const parts = this.partsForm.get('parts') as FormArray;
    const newPart = this.fb.group({
      itemNumber: [
        (itemToReplace && itemToReplace.itemNumber) || '',
        [Validators.required]
      ],
      description: [(itemToReplace && itemToReplace.description) || ''],
      quantity: [
        (itemToReplace && itemToReplace.quantity) || 1,
        [Validators.min(1), Validators.required]
      ],
      lotSerialNumber: [(itemToReplace && itemToReplace.lotSerialNumber) || ''],
      invoiceNumber: ['', this.isReplacement ? [] : [Validators.required]],
      dateSuppliedFitted: ['', this.isReplacement ? [] : [Validators.required]]
    });
    parts.push(newPart);
  }

  getAllParts() {
    return (this.partsForm.get('parts') as FormArray).controls;
  }

  onSelectItem(itemForm: FormControl, selectedItem) {
    itemForm.patchValue({
      itemNumber: selectedItem.value
    });

    // Not replace the description if it's an auto added item, then reset the itemToReplace
    this.itemToReplace
      ? (this.itemToReplace = null)
      : itemForm.patchValue({ description: selectedItem.description });
  }

  remove(index) {
    const parts = this.partsForm.get('parts') as FormArray;
    parts.removeAt(index);
  }

  onSelectDate(item, date) {
    item.patchValue({
      dateSuppliedFitted: date
    });
  }

  onReplaceItem(item: FormGroup) {
    this.replaceItem.emit(item.value);
  }
}
