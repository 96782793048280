import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { CUSTOMER_TYPE, CustomerDetails, SECURITY_FUNCTION } from '@pos-app/data';
import { Router } from '@angular/router';
import { ApiService, UtilService, resetAllFlags, CoreUiPartialState } from '@pos-app/core-ui';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-customers-nav',
  templateUrl: './customers-nav.component.html',
  styleUrls: ['./customers-nav.component.scss'],
})
export class CustomersNavComponent implements OnInit, OnDestroy {
  @Input() public activeOrder: { orderNumber: string; quickSaveAllowed: boolean };
  @Input() public selectedCustomer: CustomerDetails;
  @Input() public isExternalUser: boolean;
  @Input() public securityFunctions: string[];

  public customerType = CUSTOMER_TYPE;
  public hasShowSales$: Observable<boolean>;
  private unSubscribe$ = new Subject<void>();

  constructor(private router: Router, private apiService: ApiService, private utilService: UtilService, private store: Store<CoreUiPartialState>) {}

  public ngOnInit(): void {
    if (this.isExternalUser) {
      this.hasShowSales$ = this.apiService.checkForShowSales().pipe(map((res) => res.showOrdersExistsYN === 'Y'));
    }
  }

  public ngOnDestroy(): void {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  public isActive(url): boolean {
    return window.location.href.toLowerCase().indexOf(url.toLowerCase()) >= 0;
  }

  public toStartNewOrder(customerNumber): void {
    this.store.dispatch(resetAllFlags());
    this.router.navigateByUrl(`/pos/orders-start?customerNumber=${customerNumber}`);
  }

  public canStartNewOrder(): boolean {
    return !this.isExternalUser || this.securityFunctions.indexOf(SECURITY_FUNCTION.maintainOrders) !== -1;
  }
}
