import { Component, OnInit, Output, EventEmitter, Input, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { tap, debounceTime, distinctUntilChanged, filter, switchMap, takeUntil, finalize } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ApiService } from '../../services';
import { ApiResponse } from '@pos-app/data';

@Component({
  selector: 'pos-app-fuzzy-search',
  templateUrl: './fuzzy-search.component.html',
  styleUrls: ['./fuzzy-search.component.scss'],
})
export class FuzzySearchComponent implements OnInit, OnDestroy {
  @ViewChild('searchBox') searchBox: ElementRef;

  @Input() searchStr: string;
  @Input() placeholder: string;
  @Input() apiEndpointKey: string;
  @Input() apiRequestKey: string;
  @Input() apiRequestStaticFields: any;
  @Input() valueKey: string;
  @Input() description1Key: string;
  @Input() description2Key: string = '';
  @Input() useNotFoundItem: boolean;
  @Input() isRequired: boolean;
  @Input() isAutoFocus: boolean;
  @Input() isFitterSearch: boolean = false;

  @Output() selectedItem = new EventEmitter<any>();
  @Output() clearSearchValue = new EventEmitter<any>();

  fuzzySearchForm: FormControl;
  showFuzzySearchList = false;
  fuzzySearchList: any;
  loading: boolean;
  unSubscribe$ = new Subject<void>();
  show = false;
  moreRecordsYN: string;
  noResult: boolean;

  constructor(private apiService: ApiService, private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.fuzzySearchForm = new FormControl(null, this.isRequired ? Validators.required : []);
    this.fuzzySearchForm.valueChanges
      .pipe(
        debounceTime(500),
        filter((value) => {
          if (value && typeof value === 'string' && value.trim().length >= 3) {
            return true;
          }

          if (value === '') {
            this.clearSearchValue.emit();
            this.showFuzzySearchList = false;
            this.noResult = false;
          }

          return false;
        }),
        tap((value) => {
          if (this.useNotFoundItem) {
            this.selectedItem.emit({
              value,
              description: '',
            });
          }
        }),
        distinctUntilChanged(),
        switchMap((value) => {
          this.loading = true;
          let parameters = this.apiRequestStaticFields ? this.apiRequestStaticFields : {};
          parameters[this.apiRequestKey] = value;
          return this.apiService[this.apiEndpointKey](parameters);
        }),
        finalize(() => (this.loading = false)),
        takeUntil(this.unSubscribe$)
      )
      .subscribe((x: ApiResponse) => {
        if ((!!x.SearchResults && x.SearchResults.length > 0) || (!!x.resourceList && x.resourceList.length > 0)) {
          this.showFuzzySearchList = true;
          this.noResult = false;
          this.loading = false;
          this.fuzzySearchList = x.SearchResults || x.resourceList;
          this.moreRecordsYN = !!x.moreRecordsYN ? x.moreRecordsYN : null;
          const matchItem = this.fuzzySearchList.find((item) => item[this.valueKey] === this.fuzzySearchForm.value.toUpperCase());
          if (matchItem) {
            this.selectItem(matchItem);
          }
        } else {
          this.noResult = true;
          this.loading = false;
        }
      });
    if (this.searchStr) {
      this.fuzzySearchForm.setValue(this.searchStr);
    }
  }

  ngAfterViewInit() {
    if (!!this.isAutoFocus) {
      this.searchBox.nativeElement.focus();
    }
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  selectItem(item) {
    this.fuzzySearchForm.patchValue(this.getItemDescription(item), {
      emitEvent: false,
    });
    this.fuzzySearchList = null;

    if (this.useNotFoundItem) {
      this.selectedItem.emit({
        value: item[this.valueKey],
        description: item[this.description1Key],
      });

      return;
    }

    if (this.isFitterSearch) {
      this.selectedItem.emit({
        value: item[this.valueKey],
        description: item[this.description1Key],
      });

      return;
    }

    this.selectedItem.emit(item[this.valueKey]);
  }

  getItemDescription(item) {
    return this.useNotFoundItem
      ? item[this.valueKey]
      : `${item[this.valueKey]} - ${item[this.description1Key]} ${item[this.description2Key] ? item[this.description2Key] : ''}`;
  }

  isFocus(e) {
    this.show = true;
    e.target.select();
    this.changeDetectorRef.detectChanges();
  }

  lostFocus(e) {
    setTimeout(() => {
      this.show = false;
    }, 200);
  }

  clear(): void {
    this.searchBox.nativeElement.value = '';
  }
}
