import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';

@Component({
  template: ` <input class="form-control" #input [formControl]="textInput" [type]="type" maxlength="60" /> `,
  styles: [
    `
      .form-control {
        width: 100%;
        height: 100%;
        padding: 0 5px;
      }
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type='number'] {
        -moz-appearance: textfield;
      }
    `,
  ],
})
export class TextCellEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  @ViewChild('input', { read: ViewContainerRef }) public input!: ViewContainerRef;

  public textInput = new FormControl();
  public type = 'text';

  agInit(params: ICellEditorParams): void {
    this.type = params['type'];

    /* add quantity for new item */
    if (params.colDef.colId === 'qtyOrdered' && this.type === 'number' && !params.data.id && !params.value) {
      this.textInput.setValue(params['isCreditOrder'] ? -1 : 1);
      return;
    }

    this.textInput.setValue(params.value);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.input.element.nativeElement?.focus();
      this.input.element.nativeElement?.select();
    });
  }

  getValue(): string | null {
    return this.textInput.value;
  }
}
