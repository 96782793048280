import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'highlightText'
})
export class HighlightTextPipe implements PipeTransform {
    transform(text: string, search: string): string {
        var searchs = search.trim().split(' ');
        const re = new RegExp(`(${ searchs.join('|') })`, 'gi');
        return search ? text.replace(re, `<span class="highlight">$1</span>`) : text;
    }
}
