<pos-app-spinner [show]="isBusy"></pos-app-spinner>

<div *ngIf="role$ | async as role">
  <nav *ngIf="defaultBranch$ | async as branch" class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid p-0">
      <a class="navbar-brand arbonline-logo d-flex align-items-center" href="#">
        <span (click)="navigateToHome()" class="pr-2 d-flex">
          <div class="logo-image"></div>
          <span *ngIf="!configService.AppConfig.enableTheme" class="px-2 my-auto text-white logo-text d-none d-lg-block"> Online </span>
        </span>
        <mat-icon
          *ngIf="!(isExternalUser$ | async) && (loggedinUserId$ | async) && (defaultCustomer$ | async) as customer"
          matBadge="{{ alertCount }}"
          matBadgeColor="warn"
          matBadgeOverlap="false"
          class="calendar-icon mt-neg-1rem"
          (click)="navigateToTaskCalendar(customer.customerNumber)"
        >
          event
        </mat-icon>
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul *ngIf="{ value: defaultCustomer$ | async } as customer" class="navbar-nav me-auto mb-2 mb-lg-0 flex-wrap">
          <li *ngIf="(loggedinUserId$ | async) && (forInternalUser(role) || role === USER_ROLE.showExternal) && isCashDrawer" class="nav-item">
            <a class="nav-link" (click)="quickSale(branch)" routerLinkActive="active"> QUICK SALE </a>
          </li>

          <li
            *ngIf="
              (loggedinUserId$ | async) &&
              ((forExternalUser(role) && (orderTypes$ | async)?.length > 0) ||
                role === USER_ROLE.showExternal ||
                canMaintainOrders(securityFunctions$ | async))
            "
            class="nav-item"
          >
            <a class="nav-link" (click)="newOrder()" routerLinkActive="active"> NEW ORDER {{ customer.ValidOrderTypes | json }}</a>
          </li>
          <li *ngIf="activeOrder?.orderNumber" class="nav-item">
            <div class="d-flex flex-column">
              <a class="nav-link mb-0 pb-0" (click)="viewOrder()"> VIEW ORDER </a>
              <div *ngIf="openOrder">
                <a class="nav-link my-0 py-0" (click)="viewOrder()">
                  {{ openOrder.customerName }}
                </a>
              </div>
            </div>
          </li>

          <li
            *ngIf="
              (loggedinUserId$ | async) &&
              (forInternalUser(role) || role === USER_ROLE.showExternal || (securityFunctions$ | async).indexOf('MaintainOrders') !== -1)
            "
            class="nav-item"
          >
            <a class="nav-link" (click)="navigateTo('/pos/my-orders', false, true)" routerLinkActive="active">
              <span [matBadge]="parkedOrderListLength">MY ORDERS</span>
            </a>
          </li>

          <li
            *ngIf="(loggedinUserId$ | async) && forExternalUser(role) && (securityFunctions$ | async).indexOf('AccountScreen') !== -1"
            class="nav-item"
          >
            <a class="nav-link" (click)="navigateToAccountUser(customer.value.customerNumber)" routerLinkActive="active"> ACCOUNT </a>
          </li>
          <li *ngIf="isShowPopDrawer$ | async" class="nav-item">
            <a class="nav-link" (click)="openCashDrawer()" routerLinkActive="active"> POP DRAWER </a>
          </li>
          <li *ngIf="(loggedinUserId$ | async) && (role$ | async) !== USER_ROLE.guest" class="nav-item">
            <a class="nav-link" (click)="navigateTo('/pos/customers/search', true)" routerLinkActive="active"> SEARCH </a>
          </li>
          <li *ngIf="!(isExternalUser$ | async) && (loggedinUserId$ | async)" class="nav-item dropdown">
            <div ngbDropdown>
              <a class="nav-link" id="dropdown-nav" ngbDropdownToggle>ADMIN</a>
              <div ngbDropdownMenu aria-labelledby="dropdown-nav" class="dropdown-menu">
                <button
                  ngbDropdownItem
                  (click)="navigateTo('/pos/register')"
                  *ngIf="(loggedinUserId$ | async) && forInternalUser(role) && canManageRegisterAdmin(securityFunctions$ | async)"
                  routerLinkActive="active"
                >
                  REGISTER ADMIN
                </button>
                <button ngbDropdownItem (click)="navigateTo('/pos/allocate-fitter')" routerLinkActive="active">ALLOCATE FITTER</button>
                <button *ngIf="brandDefaults?.enableWorkshopBookingTF" ngbDropdownItem (click)="goToScheduler()" routerLinkActive="active">
                  WORKSHOP
                </button>
              </div>
            </div>
          </li>
          <li *ngIf="loggedinUserId$ | async" class="nav-item px-2">
            <a class="nav-link" (click)="navigateTo('/pos/resources')" routerLinkActive="active"> RESOURCES </a>
          </li>
          <li *ngIf="loggedinUserId$ | async" class="nav-item px-2">
            <a class="nav-link" (click)="isFeedback = true" routerLinkActive="active"> FEEDBACK </a>
          </li>
          <li *ngIf="!(loggedinUserId$ | async)" class="nav-item">
            <div class="d-flex">
              <div class="vl"></div>
              <span class="my-auto px-3"> PLEASE LOG IN FOR MORE FEATURES </span>
            </div>
            <button class="btn text-white" (click)="navigateTo('/pos/login')">LOGIN</button>
          </li>
          <li *ngIf="(loggedinUserName$ | async) || (defaultCustomer$ | async)" class="nav-item">
            <div class="d-flex align-items-center text-white">
              <div class="vl d-none d-lg-block"></div>
              <div class="px-2 d-none d-lg-block">
                <mat-icon (click)="switchUser()" title="Switch User"> supervisor_account </mat-icon>
              </div>
              <div class="my-auto pt-1 text-uppercase">
                <div>
                  {{ loggedinUserName$ | async }}
                </div>
                <div *ngIf="forInternalUser(role) || role === USER_ROLE.showExternal">
                  {{ (defaultBranch$ | async).BranchDescription }}
                </div>
                <div *ngIf="role === USER_ROLE.external">
                  {{ jdeSession?.CustomerName }}
                </div>
              </div>
            </div>
          </li>
          <li *ngIf="loggedinUserId$ | async" class="nav-item">
            <div ngbDropdown class="d-inline-block">
              <button class="btn text-white outline-0 m-0 mt-1" id="dropdownBasic1" ngbDropdownToggle>ACCOUNT</button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu-right" style="left: -8.5rem">
                <div class="showroom-toggle" *ngIf="role !== USER_ROLE.guest">
                  SHOWROOM VIEW <span class="pr-2"></span>
                  <label class="switch">
                    <input type="checkbox" (change)="toggleShowroom($event.target.checked)" [checked]="isShowroomView" />
                    <span class="slider round"></span>
                  </label>
                </div>
                <button ngbDropdownItem (click)="navigateTo('/pos/manageusers/internal')" *ngIf="canManageInternalUser(securityFunctions$ | async)">
                  MANAGE INTERNAL USERS
                </button>
                <button
                  ngbDropdownItem
                  (click)="navigateTo('/pos/manageusers/external')"
                  *ngIf="canManageUsers(securityFunctions$ | async) && role !== USER_ROLE.showExternal && role !== USER_ROLE.showInternal"
                >
                  MANAGE REGISTERED USERS
                </button>
                <button ngbDropdownItem (click)="navigateTo('/pos/manageusers/show')" *ngIf="canManageShowUsers(securityFunctions$ | async)">
                  MANAGE SHOW USERS
                </button>
                <button
                  ngbDropdownItem
                  (click)="navigateTo('/pos/manageusers/profile')"
                  *ngIf="forInternalUser(role) || canManageMyProfile(securityFunctions$ | async)"
                >
                  MANAGE MY PROFILE
                </button>
                <button
                  ngbDropdownItem
                  (click)="resetPassword(loggedInUserId)"
                  *ngIf="!forInternalUser(role) && (loggedinUserId$ | async) as loggedInUserId"
                >
                  RESET PASSWORD
                </button>
                <button ngbDropdownItem (click)="switchUser()" class="d-xl-none">SWITCH USER</button>
                <button class="ml-0" ngbDropdownItem (click)="logout()">LOGOUT</button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div *ngIf="showSpotlightNav && loggedinUserId$ | async as loggedInUserId" class="product-spotlight">
    <pos-app-product-spotlight #spotlightElement (navigateToHomeEvent)="navigateToHome()"></pos-app-product-spotlight>
  </div>

  <ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h3 class="arb-large-heading" id="modal-basic-title">SELECT BRANCH</h3>
    </div>
    <div class="modal-body">
      <ng-select (change)="selectBranch($event)">
        <ng-option *ngFor="let branch of branchList" [value]="branch.BranchCode">
          <span class="pr-1" *ngIf="branch.BranchCode"> {{ branch.BranchCode }} - </span>
          {{ branch.BranchDescription }}
        </ng-option>
      </ng-select>
    </div>
  </ng-template>

  <pos-app-feedback
    *ngIf="isFeedback"
    (closeFeedback)="isFeedback = false"
    [isExternalUser]="!forInternalUser(role)"
    [customerDetails]="defaultCustomer$ | async"
  >
  </pos-app-feedback>
</div>
