<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <div class="text-center">
      <h3 class="pt-3 arb-large-heading">
        ATTACHED FILES
      </h3>
    </div>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="d('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <li *ngFor="let file of attachedFiles">
      <u title="Download File" (click)="downloadFile(file)">
        {{ file.name }}
      </u>
    </li>
  </div>
</ng-template>
