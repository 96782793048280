import { Injectable } from '@angular/core';

import { ElectronService } from '../electron/electron.service';
import { BehaviorSubject, Observable } from 'rxjs';

export interface SerialPortInfo {
  path: string;
  manufacturer: string;
  serialNumber: string;
  pnpId: string;
  locationId: string;
  vendorId: string;
  productId: string;
}
@Injectable({
  providedIn: 'root',
})
export class DrawerService {
  private cashdrawerAvailability = new BehaviorSubject<any>({});
  private isPrinterConnected = new BehaviorSubject<boolean>(false);
  private cashdrawerManufacturerIds: string[] = ['FTDI']; // Known Id so far: 'FTDI'

  constructor(private electronService: ElectronService) {
    if (this.electronService.isElectron) {
      this.electronService.contextBridge.getDevicesCallback((e, message) => {
        const status = message.epsonPrinterConnected
          ? true
          : this.checkIfSerialPortIsCashdrawer(
              message.serialPorts,
              this.cashdrawerManufacturerIds
            );
        this.isPrinterConnected.next(message.epsonPrinterConnected);

        this.cashdrawerAvailability.next({
          hostName: message.hostName,
          status,
        });
      });
      this.checkDevicesAvailability();
      this.electronService.contextBridge.consoleLog((e, message) => {
        console.log('consoleLog', message);
      });
    }
  }

  setCashdrawerManufacturerIds(manufacturerIds: string[]): void {
    this.cashdrawerManufacturerIds = manufacturerIds;
    this.checkDevicesAvailability();
  }

  openCashdrawer(): void {
    if (
      this.cashdrawerAvailability.getValue().status &&
      this.electronService.isElectron
    ) {
      this.electronService.contextBridge.openCashdrawer();
      this.electronService.contextBridge.openEpsonCashDrawer();
    }
  }

  printReceipt(data): void {
    this.electronService.contextBridge.printReceipt(data);
  }

  checkDevicesAvailability(): void {
    if (this.electronService.isElectron) {
      this.electronService.contextBridge.checkCashdrawer();
    }
  }

  get cashdrawerAvailability$(): Observable<{status: boolean, hostName: string}> {
    return this.cashdrawerAvailability.asObservable();
  }

  getIsPrinterConnected(): boolean {
    return this.isPrinterConnected.value;
  }

  checkIfSerialPortIsCashdrawer(
    devices: SerialPortInfo[],
    manufacturerIds: string[]
  ): boolean {
    if (!manufacturerIds.length || !devices.length) {
      return false;
    }
    const deviceManufacturers = (devices as SerialPortInfo[]).map((x) =>
      x.manufacturer.toUpperCase()
    );
    return manufacturerIds.some((r) => deviceManufacturers.includes(r));
  }
}
