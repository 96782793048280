<!-- make, model, generation, year from-to, trim, cab, body, engine, fuel, drive, transmission -->
<div class="product-applications arb-clear-text-for-table">
  <div *ngIf="applications.length == 0">No Application Information</div>
  <div *ngIf="applications.length > 0">
    <p-panel class="associated-group" [style]="{ 'margin-bottom': '20px' }">
      <p-header class="p-header">
        <div class="p-helper-clearfix">
          <span class="p-panel-title text-uppercase" style="font-size: 16px; display: inline-block; margin-top: 2px"> Selected Vehicle </span>
        </div>
      </p-header>
      <div *ngIf="getSelectedApplication().length == 0 && selectedVehicleId && selectedVehicleId != ''">This part does not fit your selected vehicle</div>
      <div *ngIf="getSelectedApplication().length == 0 && (!selectedVehicleId || selectedVehicleId == '')">Please select vehicle to see if this part fits</div>
      <ng-container *ngIf="getSelectedApplication() as selecteds">
        <table [hidden]="selecteds.length == 0" class="product-application-table selected-vehicle">
          <thead>
            <tr>
              <th>Make</th>
              <th>Model</th>
              <th>Generation</th>
              <th>Year</th>
              <th>Series</th>
              <th>Sub Model</th>
              <th>Body Type</th>
              <th>Capacity</th>
              <th>Fuel Type</th>
              <th>Drive Type</th>
              <th>Transmission</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let selected of selecteds">
              <tr class="pair-row" *ngIf="selected.isPairRow">
                <td colspan="12">
                  <div class="additional-info-ipad-only">
                    <p>Fuel Type: {{ selected.fuelType }}</p>
                    <p>Drive Type: {{ selected.driveType }}</p>
                    <p>Transmission: {{ selected.transmissionType }}</p>
                  </div>
                  <p class="fitment-note">
                    Fitment Note:
                    {{ selected.paNote && selected.paNote.trim() != '' ? selected.paNote : '(None)' }}
                  </p>
                </td>
              </tr>

              <tr class="application-row" *ngIf="!selected.isPairRow">
                <td>{{ selected.make }}</td>
                <td>{{ selected.model }}</td>
                <td>{{ selected.generation }}</td>
                <td>{{ selected.year }}</td>
                <td>{{ selected.series }}</td>
                <td>{{ selected.subModel }}</td>
                <td>{{ selected.bodyType }}</td>
                <td>{{ selected.engineCapacity }}L</td>
                <td>{{ selected.fuelType }}</td>
                <td>{{ selected.driveType }}</td>
                <td>{{ selected.transmissionType }}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </ng-container>
    </p-panel>
    <p-panel class="associated-group" [style]="{ 'margin-bottom': '10px' }">
      <p-header class="p-header">
        <div class="p-helper-clearfix" (click)="otherVehiclesCollapsed = !otherVehiclesCollapsed">
          <span class="p-panel-title text-uppercase" style="font-size: 16px; display: inline-block; margin-top: 2px"> Other Vehicles </span>
          <a class="expand-btn"> CLICK TO {{ otherVehiclesCollapsed ? 'EXPAND' : 'COLLAPSE' }} SECTION </a>
        </div>
      </p-header>
      <table class="product-application-table other-vehicles" [hidden]="otherVehiclesCollapsed">
        <thead>
          <tr>
            <th>Make</th>
            <th>Model</th>
            <th>Generation</th>
            <th>Year</th>
            <th>Series</th>
            <th>Sub Model</th>
            <th>Body Type</th>
            <th>Capacity</th>
            <th>Fuel Type</th>
            <th>Drive Type</th>
            <th>Transmission</th>
            <th></th>
          </tr>
        </thead>
        <tbody #vehicleLabelSearch>
          <ng-container *ngFor="let vehicle of getOtherApplications()">
            <tr class="pair-row" *ngIf="vehicle.isPairRow">
              <td colspan="12">
                <div class="additional-info-ipad-only">
                  <p>Year: {{ vehicle.year }}</p>
                  <p>Fuel Type: {{ vehicle.fuelType }}</p>
                  <p>Drive Type: {{ vehicle.driveType }}</p>
                  <p>Transmission: {{ vehicle.transmissionType }}</p>
                </div>
                <p class="fitment-note">
                  Fitment Note:
                  {{ vehicle.paNote && vehicle.paNote.trim() != '' ? vehicle.paNote : '(None)' }}
                </p>
              </td>
            </tr>
            <tr class="application-row selectedVehicle" *ngIf="!vehicle.isPairRow">
              <td>{{ vehicle.make }}</td>
              <td>{{ vehicle.model }}</td>
              <td>{{ vehicle.generation }}</td>
              <td>{{ vehicle.year }}</td>
              <td>{{ vehicle.series }}</td>
              <td>{{ vehicle.subModel }}</td>
              <td>{{ vehicle.bodyType }}</td>
              <td>{{ vehicle.engineCapacity }}L</td>
              <td>{{ vehicle.fuelType }}</td>
              <td>{{ vehicle.driveType }}</td>
              <td>{{ vehicle.transmissionType }}</td>
              <td>
                <button class="btn btn-sm text-white" (click)="updateSelectedVehicle(vehicle, selectVehicleContent)">SELECT</button>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </p-panel>
  </div>
</div>
<p-confirmDialog header="Confirmation" #selectVehicleConfirmation key="selectVehicleConfirmation">
  <p-footer>
    <button type="button" class="btn text-white" (click)="selectVehicleConfirmation.accept()" style="width: 80px">OK</button>
    <button type="button" class="btn btn-cancel" (click)="selectVehicleConfirmation.reject()">Cancel</button>
  </p-footer>
</p-confirmDialog>


<ng-template #selectVehicleContent let-c="close " let-d="dismiss">
  <div class="modal-header">
    <h3 class="modal-title" id="modal-basic-title ">SELECT VEHICLE</h3>
  </div>
  <div class="modal-body">
    <app-vehicles-selection (cancelSelect)="d('Cross click')" (submitCar)="onSelectVehicle($event)" [fromEpc]="true"></app-vehicles-selection>
  </div>
</ng-template>
