import { createAction, props, union } from '@ngrx/store';
import { CEDCategoryDto, GetFilterDataOutput, ProductSearchInput } from '../../models/service-proxies';

const RESET_FILTER = 'Reset Filter';
const UPDATE_FILTER = 'Update Filter';
const LOAD_FILTER_DATA = 'Load Filter Data';
const LOAD_FILTER_DATA_SUCCESS = 'Load Filter Data Success';
const UPDATE_FILTER_DATA = 'Update filter data';
const RESET_FILTER_LOADED = 'Reset Filter Loaded';
const CLEAR_ALL_FILTER = 'Clear All Filter';
const GET_CED_CATEGORIES = '[Filter] Get CED Categories';
const GET_CED_CATEGORIES_SUCCESS = '[Filter] Get CED Categories Success';

export const ResetFilter = createAction(RESET_FILTER, props<{ payload: any }>());
export const ResetFilterLoaded = createAction(RESET_FILTER_LOADED);
export const LoadFilterData = createAction(
  LOAD_FILTER_DATA,
  props<{
    payload: {
      input: ProductSearchInput;
      isFirstLoad: boolean;
    };
  }>()
);
export const LoadFilterDataSuccess = createAction(LOAD_FILTER_DATA_SUCCESS, props<{ payload: GetFilterDataOutput }>());
export const UpdateFilterData = createAction(UPDATE_FILTER_DATA, props<{ payload: any }>());
export const UpdateFilter = createAction(UPDATE_FILTER, props<{ payload: any }>());
export const ClearAllFilter = createAction(CLEAR_ALL_FILTER, props<{ payload: any }>());
export const GetCedCategories = createAction(GET_CED_CATEGORIES);
export const GetCedCategoriesSuccess = createAction(GET_CED_CATEGORIES_SUCCESS, props<{ payload: CEDCategoryDto[] }>());

const all = union({ LoadFilterData, LoadFilterDataSuccess, UpdateFilter, ResetFilterLoaded, ResetFilter, ClearAllFilter });
export type FilterActions = typeof all;
