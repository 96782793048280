import { Injectable } from '@angular/core';
import { Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import {
  CustomersPartialState,
  CUSTOMERS_FEATURE_KEY,
} from './customers.reducer';
import {
  CustomersActionTypes,
  SelectCustomerAction,
  SelectCustomerSuccessAction,
  SelectCustomerFailureAction,
  LoadLookupLists,
  LoadLookupListsSuccess,
  LoadLookupListsFailed,
  CreateRetailCustomer,
  CreateRetailCustomerFailed,
  CreateRetailCustomerSuccess,
  CreateShipToCustomer,
  CreateShipToCustomerSuccess,
  CreateShipToCustomerFailed,
  LoadCustomerVehicles,
  LoadCustomerVehiclesSuccess,
  LoadCustomerVehiclesFailed,
  MaintainCustomerVehicle,
  MaintainCustomerVehicleSuccess,
  MaintainCustomerVehicleFailed,
  UpdateRetailCustomer,
  UpdateRetailCustomerSuccess,
  UpdateRetailCustomerFailed,
  UpdateShipToCustomerFailed,
  UpdateShipToCustomer,
  UpdateShipToCustomerSuccess,
  MakeVehicleDefault,
  MakeVehicleDefaultSuccess,
  MakeVehicleDefaultFailed,
  CheckDuplicateCustomer,
  CheckDuplicateCustomerFailed,
  CheckDuplicateCustomerSuccess,
  GetCustomerSalesFailureAction,
  GetCustomerSalesAction,
  GetCustomerSalesSuccessAction,
  GetStatementListAction,
  GetStatementListSuccessAction,
  GetStatementListFailureAction,
  GetInvoiceListSuccessAction,
  GetInvoiceListAction,
  GetInvoiceListFailureAction,
  GetShowSalesAction,
  GetShowSalesSuccessAction,
  GetShowSalesFailureAction,
  ListTasksNotesAction,
  ListTasksNotesFailureAction,
  ListTasksNotesSuccessAction,
  UpdateAlertMessageAction,
  UpdateAlertMessageSuccessAction,
  UpdateAlertMessageFailureAction,
  GetAlertMessageAction,
  GetAlertMessageFailureAction,
  GetAlertMessageSuccessAction,
  UpdateNotesTasksAction,
  UpdateNotesTasksSuccessAction,
  UpdateNotesTasksFailureAction,
  CompleteTasksAction,
  CompleteTasksFailureAction,
  CompleteTasksSuccessAction,
  GetUserStateAction,
  GetUserStateSuccessAction,
  GetUserStateFailureAction,
} from './customers.actions';
import { ApiService, getOverdueAlertCounts } from '@pos-app/core-ui';
import { map, delay, mergeMap, switchMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import {
  clearUnsavedChanges,
  DialogService,
  navigateToUrl,
  clearSearchData,
  MessageService,
  hasUnsavedChanges,
} from '@pos-app/core-ui';
import { NOTE_TASK_ACTIVITY_TYPE } from '@pos-app/data';
import { of } from 'rxjs';
import { OOESessionService } from 'libs/core-ui/src/lib/services/ooe-session.service';

@Injectable()
export class CustomersEffects {
  @Effect() makeVehicleDefault$ = this.dataPersistence.fetch(
    CustomersActionTypes.MAKE_VEHICLE_DEFAULT,
    {
      run: (action: MakeVehicleDefault) => {
        if (!action.payload.default) {
          return this.apiService
            .maintainCustomerVehicle(action.payload.selectedVehicle)
            .pipe(
              mergeMap((result) => {
                return [
                  new MakeVehicleDefaultSuccess(result),
                  new LoadCustomerVehicles({
                    customerNumber:
                      action.payload.selectedVehicle.customerNumber,
                    retiredVehicleYN: 'Y',
                  }),
                ];
              })
            );
        } else {
          return this.apiService
            .maintainCustomerVehicle(action.payload.default)
            .pipe(
              switchMap((_) =>
                this.apiService.maintainCustomerVehicle(
                  action.payload.selectedVehicle
                )
              ),
              mergeMap((result) => {
                return [
                  new MakeVehicleDefaultSuccess(result),
                  new LoadCustomerVehicles({
                    customerNumber:
                      action.payload.selectedVehicle.customerNumber,
                    retiredVehicleYN: 'Y',
                  }),
                ];
              })
            );
        }
      },
      onError: (action: MakeVehicleDefault, error) => {
        return new MakeVehicleDefaultFailed(error);
      },
    }
  );
  @Effect() maintainCustomerVehicle$ = this.dataPersistence.fetch(
    CustomersActionTypes.MAINTAIN_CUSTOMER_VEHICLE,
    {
      run: (action: MaintainCustomerVehicle, state: CustomersPartialState) => {
        return this.apiService.maintainCustomerVehicle(action.payload).pipe(
          mergeMap((result) => {
            switch (action.payload.actionCode) {
              case 'A':
                this.toastrService.success('Vehicle added');
                break;
              case 'R':
                this.toastrService.success('Vehicle retired');
                break;
              case 'U':
                this.toastrService.success('Vehicle unretired');
                break;
              default:
                this.toastrService.success('Record updated');
                break;
            }
            this.messageService.dispatchAction(
              hasUnsavedChanges({ unsavedChanges: false })
            );
            return [
              clearUnsavedChanges({ unsavedChanges: false }),
              new MaintainCustomerVehicleSuccess(result),
              new LoadCustomerVehicles({
                customerNumber: action.payload.customerNumber,
                retiredVehicleYN: 'Y',
              }),
            ];
          })
        );
      },
      onError: (action: MaintainCustomerVehicle, error) => {
        return new MaintainCustomerVehicleFailed(error);
      },
    }
  );

  @Effect() loadCustomerVehicles$ = this.dataPersistence.fetch(
    CustomersActionTypes.LOAD_CUSTOMER_VEHICLES,
    {
      run: (action: LoadCustomerVehicles, state: CustomersPartialState) => {
        return this.apiService
          .getCustomerVehicles(action.payload)
          .pipe(map((result) => new LoadCustomerVehiclesSuccess(result)));
      },
      onError: (action: LoadCustomerVehicles, error) => {
        return new LoadCustomerVehiclesFailed(error);
      },
    }
  );

  @Effect() checkDuplicatCustomer$ = this.dataPersistence.fetch(
    CustomersActionTypes.CHECK_DUPLICATE_CUSTOMER,
    {
      run: (action: CheckDuplicateCustomer, state: CustomersPartialState) => {
        return this.apiService
          .duplicateCustomerSearch(action.payload)
          .pipe(map((result) => new CheckDuplicateCustomerSuccess(result)));
      },
      onError: (action: CheckDuplicateCustomer, error) => {
        return new CheckDuplicateCustomerFailed(error);
      },
    }
  );

  @Effect() loadCustomer$ = this.dataPersistence.fetch(
    CustomersActionTypes.SELECT_CUSTOMER,
    {
      run: (action: SelectCustomerAction, state: CustomersPartialState) => {
        const selectedCustomer = state[CUSTOMERS_FEATURE_KEY].selectedCustomer;
        if (
          !action.reloadCustomer &&
          selectedCustomer &&
          selectedCustomer.CustomerNumber === action.payload
        ) {
          return new SelectCustomerSuccessAction(selectedCustomer);
        }
        const getCustomerDetails = action.payload
          ? this.apiService.getCustomerDetails({
              customerNumberSearch: action.payload,
            })
          : this.apiService.getCustomerDetailsExternal();
        return getCustomerDetails.pipe(
          mergeMap((result) => {
            return [
              new SelectCustomerSuccessAction(result.SearchResults[0]),
              clearSearchData(),
            ];
          })
        );
      },
      onError: (action: SelectCustomerAction, error) => {
        return new SelectCustomerFailureAction(error);
      },
    }
  );

  @Effect() updateRetailCustomer$ = this.dataPersistence.fetch(
    CustomersActionTypes.UPDATE_RETAIL_CUSTOMER,
    {
      run: (action: UpdateRetailCustomer, state: CustomersPartialState) => {
        return this.apiService.updateRetailCustomer(action.payload).pipe(
          mergeMap((result) => {
            this.toastrService.success('Record updated');
            this.messageService.dispatchAction(
              hasUnsavedChanges({ unsavedChanges: false })
            );
            return [
              clearUnsavedChanges({ unsavedChanges: false }),
              new UpdateRetailCustomerSuccess(result),
            ];
          })
        );
      },
      onError: (action: UpdateRetailCustomer, error) => {
        return new UpdateRetailCustomerFailed(error);
      },
    }
  );

  @Effect() createRetailCustomer$ = this.dataPersistence.fetch(
    CustomersActionTypes.CREATE_RETAIL_CUSTOMER,
    {
      run: (action: CreateRetailCustomer, state: CustomersPartialState) => {
        return this.apiService.createRetailCustomer(action.payload).pipe(
          delay(2000),
          mergeMap((result) => {
            this.dialogService.confirm(
              `${action.payload.firstName} ${action.payload.surname} (${result.CustomerNumber})`,
              'Retail customer created successfully',
              null,
              null,
              null,
              null,
              false
            );
            this.messageService.dispatchAction(
              hasUnsavedChanges({ unsavedChanges: false })
            );
            return [
              clearUnsavedChanges({ unsavedChanges: false }),
              new CreateRetailCustomerSuccess(result),
              navigateToUrl({
                url: `pos/customers/${result.CustomerNumber}/add/vehicles`,
              }),
            ];
          })
        );
      },
      onError: (action: CreateRetailCustomer, error) => {
        return new CreateRetailCustomerFailed(error);
      },
    }
  );

  @Effect() updateShipToCustomer$ = this.dataPersistence.fetch(
    CustomersActionTypes.UPDATE_SHIPTO_CUSTOMER,
    {
      run: (action: UpdateShipToCustomer, state: CustomersPartialState) => {
        return this.apiService.updateShipToCustomer(action.payload).pipe(
          mergeMap((result) => {
            this.toastrService.success('Record updated');
            this.messageService.dispatchAction(
              hasUnsavedChanges({ unsavedChanges: false })
            );
            return [
              clearUnsavedChanges({ unsavedChanges: false }),
              new UpdateShipToCustomerSuccess(result),
            ];
          })
        );
      },
      onError: (action: UpdateShipToCustomer, error) => {
        return new UpdateShipToCustomerFailed(error);
      },
    }
  );

  @Effect() createShipToAddress$ = this.dataPersistence.fetch(
    CustomersActionTypes.CREATE_SHIPTO_CUSTOMER,
    {
      run: (action: CreateShipToCustomer, state: CustomersPartialState) => {
        return this.apiService.createShipToCustomer(action.payload).pipe(
          delay(2000),
          mergeMap((result) => {
            this.dialogService.confirm(
              `${action.payload.name} (${result.CustomerNumber})`,
              'Ship To customer created successfully',
              null,
              null,
              null,
              null,
              false
            );
            this.messageService.dispatchAction(
              hasUnsavedChanges({ unsavedChanges: false })
            );
            return [
              clearUnsavedChanges({ unsavedChanges: false }),
              new CreateShipToCustomerSuccess(result),
              navigateToUrl({ url: `pos/customers/${result.CustomerNumber}` }),
            ];
          })
        );
      },
      onError: (action: CreateShipToCustomer, error) => {
        return new CreateShipToCustomerFailed(error);
      },
    }
  );

  @Effect() loadListLookup$ = this.dataPersistence.fetch(
    CustomersActionTypes.LOAD_LOOKUP_LISTS,
    {
      run: (action: LoadLookupLists, state: CustomersPartialState) => {
        if (state[CUSTOMERS_FEATURE_KEY].cachedLookupLists) {
          return;
        }

        return this.apiService.getLookupLists().pipe(
          map(
            ([
              branchListResult,
              countryListResult,
              stateListResult,
              zoneListResult,
              routeListResult,
              freightListResult,
              colourListResult,
              carrierListResult,
              phoneTypeListResult,
              vehicleRetiredReasonListResult,
            ]) => {
              const result = {
                branchList: branchListResult.SearchResults,
                countryList: countryListResult.SearchResults,
                stateList: stateListResult.SearchResults,
                zoneList: zoneListResult.SearchResults,
                routeList: routeListResult.SearchResults,
                freightList: freightListResult.SearchResults,
                colourList: colourListResult.SearchResults,
                carrierList: carrierListResult.SearchResults,
                phoneTypeList: phoneTypeListResult.SearchResults,
                vehicleRetiredReasonList:
                  vehicleRetiredReasonListResult.SearchResults,
              };
              return new LoadLookupListsSuccess(result);
            }
          )
        );
      },
      onError: (action: LoadLookupLists, error) => {
        return new LoadLookupListsFailed(error);
      },
    }
  );

  @Effect() getCustomerSales$ = this.dataPersistence.fetch(
    CustomersActionTypes.GET_CUSTOMER_SALES,
    {
      run: (action: GetCustomerSalesAction, state: CustomersPartialState) => {
        return this.apiService
          .getCustomerSales({ customerNumberSearch: action.payload })
          .pipe(map((result) => new GetCustomerSalesSuccessAction(result)));
      },
      onError: (action: GetCustomerSalesAction, error) => {
        return new GetCustomerSalesFailureAction(error);
      },
    }
  );

  @Effect() getStatementList$ = this.dataPersistence.fetch(
    CustomersActionTypes.GET_STATEMENT,
    {
      run: (action: GetStatementListAction, state: CustomersPartialState) => {
        return this.apiService
          .getStatementList()
          .pipe(map((result) => new GetStatementListSuccessAction(result)));
      },
      onError: (action: GetStatementListAction, error) => {
        return new GetStatementListFailureAction(error);
      },
    }
  );

  @Effect() getInvoiceList$ = this.dataPersistence.fetch(
    CustomersActionTypes.GET_INVOICE,
    {
      run: (action: GetInvoiceListAction, state: CustomersPartialState) => {
        return this.apiService
          .getInvoiceList()
          .pipe(map((result) => new GetInvoiceListSuccessAction(result)));
      },
      onError: (action: GetInvoiceListAction, error) => {
        return new GetInvoiceListFailureAction(error);
      },
    }
  );

  @Effect() getShowSales$ = this.dataPersistence.fetch(
    CustomersActionTypes.GET_SHOW_SALES,
    {
      run: (action: GetShowSalesAction, state: CustomersPartialState) => {
        return this.apiService
          .getShowSales(action.payload)
          .pipe(map((result) => new GetShowSalesSuccessAction(result)));
      },
      onError: (action: GetShowSalesAction, error) => {
        return new GetShowSalesFailureAction(error);
      },
    }
  );

  @Effect() getTasksNotesList$ = this.dataPersistence.fetch(
    CustomersActionTypes.LIST_TASKS_NOTES,
    {
      run: (action: ListTasksNotesAction, state: CustomersPartialState) => {
        return this.apiService
          .listTasksNotes(action.payload)
          .pipe(map((result) => new ListTasksNotesSuccessAction(result)));
      },
      onError: (action: ListTasksNotesAction, error) => {
        return new ListTasksNotesFailureAction(error);
      },
    }
  );

  @Effect() getAlertMessage$ = this.dataPersistence.fetch(
    CustomersActionTypes.GET_ALERT_MESSAGE,
    {
      run: (action: GetAlertMessageAction, state: CustomersPartialState) => {
        return this.apiService
          .getCustomerAlert(action.payload)
          .pipe(map((result) => new GetAlertMessageSuccessAction(result)));
      },
      onError: (action: GetAlertMessageAction, error) => {
        return new GetAlertMessageFailureAction(error);
      },
    }
  );

  @Effect() updateAlertMessage$ = this.dataPersistence.fetch(
    CustomersActionTypes.UPDATE_ALERT_MESSAGE,
    {
      run: (action: UpdateAlertMessageAction, state: CustomersPartialState) => {
        return this.apiService.updateAlert(action.payload).pipe(
          mergeMap((result) => {
            if (result.ErrorFlag === '0') {
              return [
                new UpdateAlertMessageSuccessAction(result),
                new GetAlertMessageAction({
                  customerNumber: action.payload.customerNumber,
                }),
              ];
            }
          })
        );
      },
      onError: (action: UpdateAlertMessageAction, error) => {
        this.toastrService.error(error.ErrorMessage);
        return new UpdateAlertMessageFailureAction(error);
      },
    }
  );

  @Effect() updateNotesTasks$ = this.dataPersistence.fetch(
    CustomersActionTypes.UPDATE_NOTES_TASKS,
    {
      run: (action: UpdateNotesTasksAction, state: CustomersPartialState) => {
        let updateNoteTaskAction;
        updateNoteTaskAction =
          action.payload.activityType === NOTE_TASK_ACTIVITY_TYPE.Note
            ? this.apiService.updateNotes({
                ...action.payload,
                assignTo: action.payload.assignees,
              })
            : this.apiService.updateTasks(action.payload);
        return updateNoteTaskAction.pipe(
          mergeMap((result) => {
            return [
              new UpdateNotesTasksSuccessAction(result),
              new ListTasksNotesAction({
                type: action.payload.isTask ? 'T' : '',
                ownerIdentifier:
                  action.payload.listTaskNotesFilter.ownerIdentifier,
                customerNumber: action.payload.isTask
                  ? ''
                  : action.payload.customerNumber,
                assignee: action.payload.listTaskNotesFilter.assignee,
                completeYN: action.payload.isTask ? 'N' : '',
                fromDate: action.payload.listTaskNotesFilter.fromDate,
                toDate: action.payload.listTaskNotesFilter.toDate,
              }),
              getOverdueAlertCounts(),
            ];
          })
        );
      },
      onError: (action: UpdateNotesTasksAction, error) => {
        this.toastrService.error(error.ErrorMessage);
        return new UpdateNotesTasksFailureAction(error);
      },
    }
  );

  @Effect() completeTask$ = this.dataPersistence.fetch(
    CustomersActionTypes.COMPLETE_TASKS,
    {
      run: (action: CompleteTasksAction, state: CustomersPartialState) => {
        return this.apiService.completeTask(action.payload).pipe(
          mergeMap((result) => {
            return [
              new CompleteTasksSuccessAction(result),
              new ListTasksNotesAction({
                type: action.payload.isTask ? 'T' : '',
                ownerIdentifier:
                  action.payload.listTaskNotesFilter.ownerIdentifier,
                customerNumber: action.payload.isTask
                  ? ''
                  : action.payload.customerNumber,
                assignee: action.payload.listTaskNotesFilter.assignee,
                completeYN: action.payload.isTask ? 'N' : '',
                fromDate: action.payload.listTaskNotesFilter.fromDate,
                toDate: action.payload.listTaskNotesFilter.toDate,
              }),
              getOverdueAlertCounts(),
            ];
          })
        );
      },
      onError: (action: CompleteTasksAction, error) => {
        this.toastrService.error(error.ErrorMessage);
        return new CompleteTasksFailureAction(error);
      },
    }
  );

  @Effect() getUserState$ = this.dataPersistence.fetch(
    CustomersActionTypes.GET_USER_STATE,
    {
      run: (action: GetUserStateAction, state: CustomersPartialState) => {
        return this.ooeSessionService.fetchUserState().pipe(
          switchMap((result) => {
            return of(new GetUserStateSuccessAction(result));
          })
        );
      },
      onError: (action: GetUserStateAction, error) => {
        return new GetUserStateFailureAction(error);
      },
    }
  );

  constructor(
    private dataPersistence: DataPersistence<CustomersPartialState>,
    private apiService: ApiService,
    private toastrService: ToastrService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private ooeSessionService: OOESessionService
  ) {}
}
