<div class="order-status">
  <div class="d-flex">
    <div class="order">
      <div>
        <a
          *ngIf="(!isUserloggedIn || !hasActiveOrder) && hasValidOrderTypes()"
          href="/pos/orders-start"
          class="new-order btn text-white arb-ok-button"
        >
          start new order
        </a>
      </div>
      <ng-container *ngIf="isUserloggedIn && hasActiveOrder">
        <a class="current-order-info text-uppercase arb-red" [routerLink]="['/pos/orders/' + openOrder.orderNumber]">
          <i class="pi pi-shopping-cart"></i>
          <span class="order-value">
            {{ orderValue | currency : (activeOrderHeader$ | async)?.currencyCode }}
          </span>
          <span>[NO: {{ openOrder.orderNumber }}]</span>
          <span class="ref" *ngIf="openOrder.orderReference"> REF: {{ openOrder.orderReference }} </span>
          <span class="ref" *ngIf="openOrder.readOnlyEPCTF"> ENQUIRY ONLY </span>
        </a>
      </ng-container>
    </div>
</div>
