export class AddItemToOrderInputDto implements IAddItemToOrderInputDto {
    orderNumber: string | undefined;
    brand: string | undefined;
    parentItemCode: string | undefined;
    parentItemDesc: string | undefined;
    parentItemQty: string | undefined;
    parentItemUOM: string | undefined;
    paintableYN: string | undefined;
    paintYN: string | undefined;
    fitableYN: string | undefined;
    fittedYN: string | undefined
    unitPriceExTax: string | undefined;
    unitPriceIncTax: string | undefined;
    extPriceExTax: string | undefined;
    extPriceIncTax: string | undefined;
    partsVehicleID: number | undefined;
    fitProductCode: string | undefined;
    fitProductDesc: string | undefined;
    fitUnitPriceExTax: string | undefined;
    fitUnitPriceIncTax: string | undefined;
    paintProductCode: string | undefined;
    paintDesc: string | undefined;
    parentLineNumber: number;
    productNumber : string | undefined;
    quantity : number;
    nonStockLineYN:string | undefined;
    overridePriceAllowedYN:string | undefined;
    assocItems: AddItemToOrderAssociatedItemInputDto[]

    constructor(data?: IAddItemToOrderInputDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }
}

export class AddItemToOrderV2InputDto {
    orderNumber: string;
    itemNumber: string;   
    quantity: number;
    overridePriceYN: string;
    useEPCYN: string;
    fetchInventoryYN: string;
    enforceMandatoryAssociationYN: string;
    fitYN: string;
    paintYN: string;
    unitPriceEx: number;
    unitPriceInc: number;
    sourceSystem: 'EPC' | 'OOE'
};

export class AddItemToOrderAssociatedItemInputDto implements IAddItemToOrderAssociatedItemInputDto {
    assocItemCode: string | undefined;
    assocItemDesc: string | undefined;
    assocType: string | undefined;
    assocQty: number | undefined;
    assocItemUOM: string | undefined;
    assocUnitPriceExTax: string | undefined;
    assocUnitPriceIncTax: string | undefined;
    assocExtPriceExTax: string | undefined;
    assocExtPriceIncTax: string | undefined;

    constructor(data?: IAddItemToOrderAssociatedItemInputDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }
}

export interface IAddItemToOrderAssociatedItemInputDto {
    assocItemCode: string | undefined;
    assocItemDesc: string | undefined;
    assocType: string | undefined;
    assocQty: number | undefined;
    assocItemUOM: string | undefined;
    assocUnitPriceExTax: string | undefined;
    assocUnitPriceIncTax: string | undefined;
    assocExtPriceExTax: string | undefined;
    assocExtPriceIncTax: string | undefined;
}

export interface IAddItemToOrderInputDto {
    orderNumber: string | undefined;
    brand: string | undefined;
    parentItemCode: string | undefined;
    parentItemDesc: string | undefined;
    parentItemQty: string | undefined;
    parentItemUOM: string | undefined;
    paintableYN: string | undefined;
    paintYN: string | undefined;
    fitableYN: string | undefined;
    fittedYN: string | undefined
    unitPriceExTax: string | undefined;
    unitPriceIncTax: string | undefined;
    extPriceExTax: string | undefined;
    extPriceIncTax: string | undefined;
    partsVehicleID: number | undefined;
    fitProductCode: string | undefined;
    fitProductDesc: string | undefined;
    fitUnitPriceExTax: string | undefined;
    fitUnitPriceIncTax: string | undefined;
    paintProductCode: string | undefined;
    paintDesc: string | undefined;
    parentLineNumber: number;
    productNumber : string | undefined;
    quantity : number;
    nonStockLineYN:string | undefined;
    overridePriceAllowedYN:string | undefined;
    assocItems: AddItemToOrderAssociatedItemInputDto[]
}

export interface IAddItemToOrderOutput {
    ErrorFlag: string,
    ErrorMessage: string,
    SearchResults: any[],
};

export interface OrderHeaderOutput {
    ErrorFlag: string,
    ErrorMessage: string,
    SearchResults: any,
}

export interface CreateOrderSilentOutput {
    ErrorFlag: string,
    ErrorMessage: string,
    OrderNumber: string,
}

export interface OrderHeader {
    branch: string;
    brand: string;
    customerName: string;
    customerNumber: number;
    customerVehicleID: number;
    orderCompany: string;
    orderCreateDateTime: Date;
    orderNote: string;
    orderNoteCustFacing: string;
    orderNumber: string;
    orderType: string;
    partsVehicleID: number;
    reference: string;
    requestDate: Date;
    shipToNumber: number;
    userEmail: string;
    vehicleDescription: string;
  }