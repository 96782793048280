import { Injectable } from '@angular/core';

import isElectron from 'is-electron';
import { ElectronContextBridgeInterface } from './electron-context-bridge.interface';

// === Imports for < Electron 18 compatibility purpose start =====
import { ipcRenderer, webFrame } from 'electron';
import * as childProcess from 'child_process';
import * as fs from 'fs';
import * as os from 'os';
// === Imports for < Electron 18 compatibility purpose ends =====

declare var arbPos: ElectronContextBridgeInterface;

@Injectable({
  providedIn: 'root',
})
export class ElectronService {
  // === Fields for < Electron 18 compatibility purpose start =====
  ipcRenderer: typeof ipcRenderer;
  webFrame: typeof webFrame;
  childProcess: typeof childProcess;
  fs: typeof fs;
  os: typeof os;
  // === Fields for compatibility purpose ends =====

  contextBridge: ElectronContextBridgeInterface;

  get isElectron(): boolean {
    return isElectronApp();
  }

  constructor() {
    // Conditional imports
    if (this.isElectron) {
      const isElectron18OrNewer = !!window['arbPos'];
      if (isElectron18OrNewer) {
        this.contextBridge = arbPos;
      } else {
        console.log('Running electron service in compatiblity mode');
        this.contextBridge = this.createLegacyIpcRendererContextBridge();
      }
    }
  }

  openFileNatively(fileUrl: string): void {
    this.contextBridge.openFileNatively(fileUrl);
  }

  openLinkNatively(url: string): void {
    this.contextBridge.openLinkNatively(url);
  }

  private createLegacyIpcRendererContextBridge(): ElectronContextBridgeInterface {
    // Electron 18+ can't use this method since `require` is not exposed in contextIsolation mode
    this.ipcRenderer = window['require']('electron').ipcRenderer;
    this.webFrame = window['require']('electron').webFrame;

    this.childProcess = window['require']('child_process');
    this.fs = window['require']('fs');
    this.os = window['require']('os');
    
    return {
      openFileNatively: (fileUrl: string) => {
        this.ipcRenderer.send('fromApp', ['open-file', fileUrl]);
      },
      openLinkNatively: (url: string) => {
        this.ipcRenderer.send('fromApp', ['open-link', url]);
      },
      openCashdrawer: () => {
        this.ipcRenderer.send('fromApp', 'openCashdrawer');
      },
      openEpsonCashDrawer: () => {
        this.ipcRenderer.send('fromApp', 'openEpsonCashDrawer');
      },
      printReceipt: (data: unknown) => {
        this.ipcRenderer.send('fromApp', ['printReceipt', data]);
      },
      checkCashdrawer: () => {
        this.ipcRenderer.send('fromApp', 'checkCashdrawer');
      },
      getDevicesCallback: (callback: (e: unknown, message: any) => void) => {
        this.ipcRenderer.on('fromMain:getDevices', callback);
      },
      getLocale: (callback: (e: unknown, message: any) => void) => {
        this.ipcRenderer.on('fromMain:locale', callback);
      },
      consoleLog: (callback: (e: unknown, message: any) => void) => {
        this.ipcRenderer.on('fromMain:consoleLog', callback);
      },
    };
  }
}

export function isElectronApp() {
  return isElectron();
}
