import { Component, EventEmitter, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { ApiService } from '../../services';
import { SecureCatalogueService } from '../../services/secure-catelogue.service';
import { Subject } from 'rxjs';
import { LayoutService } from '../../services/layout.service';

interface Entities {
  Entity: string;
  Sequence: number;
  Title: string;
  Url: string;
  EffectiveDate: Date;
  ExpiryDate: Date;
}

@Component({
  selector: 'pos-app-product-spotlight',
  templateUrl: './product-spotlight.component.html',
  styleUrls: ['./product-spotlight.component.scss'],
})
export class ProductSpotlightComponent implements OnInit, OnDestroy {
  @Output() public navigateToHomeEvent = new EventEmitter<string>();

  public showIframe = false;
  public spotLightInfo: Entities[];
  public iframeUrl: any = null;
  public entityTabIndex = -1;
  public destroyed$ = new Subject();
  public isSpotlightHidden$ = this.layoutService.getIsSpotlightHidden();

  constructor(
    private secureCatalogueService: SecureCatalogueService,
    private layoutService: LayoutService,
    private router: Router,
    private apiService: ApiService,
    private sanitizer: DomSanitizer
  ) {}

  @HostListener('window:beforeunload')
  public ngOnDestroy(): void {
    this.layoutService.setIsComponentHidden(false);
    this.clear();

    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public ngOnInit(): void {
    this.isScrollBar();

    this.clear();
    this.layoutService.setIsComponentHidden(false);
    this.showIframe = false;
    this.router.events.pipe(filter((val) => val instanceof NavigationEnd)).subscribe(() => {
      this.layoutService.setIsComponentHidden(false);
      this.showIframe = false;
    });
    this.fetchSpotLightInfo();
  }

  public fetchSpotLightInfo(): void {
    this.apiService
      .fetchSpotLightInfo()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (data) => {
          this.spotLightInfo = this.sortEntities(data.Entities);
        },
        (error) => {
          console.error('fetchSpotLightInfo', error);
        }
      );
  }

  public navigateToHome(): void {
    this.navigateToHomeEvent.emit();
  }

  public loadIframe(showIframe: boolean, iframeUrl: any): void {
    this.layoutService.setIsComponentHidden(true);
    this.iframeUrl = this.transform(iframeUrl);
    this.router.navigateByUrl(this.secureCatalogueService.getLandingPage()).then((data) => {
      this.iFrameAssignment(showIframe);
    });
  }

  public iFrameAssignment(isVisible: boolean): void {
    this.showIframe = isVisible;
    this.isScrollBar();
  }

  public transform(url): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  public sortEntities(data: any): Entities[] {
    let sortedEntities: Entities[];
    sortedEntities = data.sort((a, b) => (a.Sequence < b.Sequence ? -1 : 1));
    return sortedEntities;
  }

  public demo1BtnClick(): void {
    const tabCount = 3;
    this.entityTabIndex = (this.entityTabIndex + 1) % tabCount;
  }

  public onTabSelectOrChange($event): void {
    this.entityTabIndex = $event.index;
    let entity = this.getDataByIndex($event.index - 1);
    if (entity) {
      this.loadIframe(true, entity.Url);
    }
  }

  public getDataByIndex(index: number): Entities {
    return this.spotLightInfo[index];
  }

  public setInitialTabIndex(): void {
    this.entityTabIndex = 0;
  }

  public clear(): void {
    this.iframeUrl = null;
    this.showIframe = false;
    this.entityTabIndex = -1;
  }

  public isScrollBar(): void {
    if (this.showIframe) {
      document.documentElement.style.setProperty('--main-scrollbar-width', '0');
      document.documentElement.style.setProperty('--main-scrollbar-height', '0');
    } else {
      document.documentElement.style.setProperty('--main-scrollbar-width', '17px');
      document.documentElement.style.setProperty('--main-scrollbar-height', '17px');
    }
  }
}
