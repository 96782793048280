import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService, AttachmentType } from '@pos-app/core-ui';
import { KEYS, ORDER_ATTACHMENT_TYPE } from '@pos-app/data';
import { ToastrService } from 'ngx-toastr';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-line-order-attachments',
  templateUrl: './line-order-attachments.component.html',
  styleUrls: ['./line-order-attachments.component.scss'],
})
export class LineOrderAttachmentsComponent implements OnInit {
  @Input() orderDetails: any;
  @Input() orderType: any;
  @Input() orderCompany: any;
  @Input() isReadOnly: boolean;

  @Output() refreshOrderGrid = new EventEmitter<void>();
  @Output() closeActiveModal = new EventEmitter<boolean>();

  newNoteForm: FormGroup;
  attachedFile: File;
  allAttachments: any[] = [];
  editorConfig: AngularEditorConfig;
  SIZE_LIMIT = 15 * 1024 * 1024; // 15MB
  noteInEdit = null;
  unSubscribe$ = new Subject<void>();
  ORDER_ATTACHMENT_TYPE = ORDER_ATTACHMENT_TYPE;
  displayNoteGrid: boolean = true;
  userEmail = JSON.parse(localStorage.getItem(KEYS.jdeSession)).UserID;

  constructor(private toastr: ToastrService, private apiService: ApiService, private fb: FormBuilder) {
    this.newNoteForm = this.fb.group({
      note: [''],
      link: [
        '',
        // Commented Url validation as requested. Leave it here in case it's needed in the future
        // Validators.pattern(
        //   '^(http[s]?:\\/\\/){0,1}[a-zA-Z0-9\\.\\-\\#\\?]+\\.[a-zA-Z]{2,5}$'
        // )
      ],
      noteType: [''],
    });

    this.editorConfig = {
      editable: true,
      spellcheck: false,
      height: '15rem',
      minHeight: '5rem',
      placeholder: 'Enter note here...',
      translate: 'no',
      defaultParagraphSeparator: 'p',
      toolbarHiddenButtons: [
        ['fontName', 'heading'],
        [
          'fontSize',
          'textColor',
          'backgroundColor',
          'customClasses',
          'link',
          'unlink',
          'insertImage',
          'insertVideo',
          'insertHorizontalRule',
          'toggleEditorMode',
        ],
      ],
    };
  }

  ngOnInit() {
    this.newNoteForm.valueChanges.pipe(takeUntil(this.unSubscribe$)).subscribe((formValue) => {
      if (formValue.noteType === '') {
        this.displayNoteGrid = true;
      } else {
        this.displayNoteGrid = false;
      }
    });
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.orderDetails) {
      this.loadAllAttachments();
    }
  }

  loadAllAttachments() {
    this.apiService
      .getAttachments(
        'order_line',
        `${this.orderDetails.orderNumber.toString()}|${this.orderType}|${this.orderCompany}|${this.orderDetails.ooeLineNumber.toFixed(3)}`
      )
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((res) => {
        this.allAttachments = res
      });
  }

  selectNoteType() {
    this.newNoteForm.patchValue({
      note: '',
      link: '',
    });
  }

  saveAttachment() {
    if (this.noteInEdit) {
      this.updateItem({
        id: this.noteInEdit._id,
        data: this.newNoteForm.value.noteType === ORDER_ATTACHMENT_TYPE.note ? this.newNoteForm.value.note : this.newNoteForm.value.link,
        display: this.noteInEdit.display,
      });
      this.resetNoteForm();
      return;
    }
    if (this.attachedFile) {
      if (this.attachedFile.size > this.SIZE_LIMIT) {
        this.toastr.error('File is too big. Max allowed is 10MB');
      } else {
        this.apiService
          .uploadAttachment({
            entityType: 'order_line',
            entityId: `${this.orderDetails.orderNumber.toString()}|${this.orderType}|${this.orderCompany}|${this.orderDetails.ooeLineNumber.toFixed(
              3
            )}`,
            type: ORDER_ATTACHMENT_TYPE.file as AttachmentType,
            file: this.attachedFile,
          })
          .pipe(takeUntil(this.unSubscribe$))
          .subscribe(
            () => {
              this.attachedFile = null;
              this.resetNoteForm();
              this.toastr.success('File uploaded');
              this.loadAllAttachments();
              this.refreshOrderGrid.emit();
            },
            (e) => {
              this.toastr.error('Upload failed');
            }
          );
      }
    } else {
      this.apiService
        .uploadAttachment({
          entityType: 'order_line',
          entityId: `${this.orderDetails.orderNumber.toString()}|${this.orderType}|${this.orderCompany}|${this.orderDetails.ooeLineNumber.toFixed(
            3
          )}`,
          type: this.newNoteForm.value.noteType as AttachmentType,
          noteOrLink: this.newNoteForm.value.note || this.newNoteForm.value.link,
        })
        .pipe(takeUntil(this.unSubscribe$))
        .subscribe(
          (x) => {
            this.toastr.success(`${this.newNoteForm.value.noteType === 'note' ? 'Note' : 'Link'} added`);
            this.resetNoteForm();
            this.loadAllAttachments();
            this.refreshOrderGrid.emit();
          },
          (e) => {
            this.toastr.error('Action failed');
          }
        );
    }
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      this.attachedFile = event.target.files.item(0);
    }
  }

  downloadFile(item) {
    this.apiService
      .downloadFile(item._id, item.name)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((x) => {});
  }

  deleteItem(item) {
    this.resetNoteForm();
    this.apiService
      .deleteAttachment(item._id, 'D', this.userEmail)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((x) => {
        this.toastr.success('Line note deleted');
        this.loadAllAttachments();
        this.refreshOrderGrid.emit();
      });
  }

  updateItem(data) {
    this.apiService
      .updateAttachment(data)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((x) => {
        this.toastr.success('Order note updated');
        this.loadAllAttachments();
        this.refreshOrderGrid.emit();
      });
  }

  editNote(item) {
    this.noteInEdit = item;
    this.newNoteForm.patchValue({
      ...{ [item.type]: item.data },
      noteType: item.type,
    });
  }

  changeDisplay(item, checked) {
    this.updateItem({ id: item._id, data: item.data, display: checked });
  }

  resetNoteForm() {
    this.noteInEdit = null;
    this.newNoteForm.patchValue({
      note: '',
      noteType: '',
      link: '',
    });
  }

  formatTime(time) {
    // JDE time doesn't put a 0 at the start when less than 10am
    if (parseInt(time.slice(0, 2)) > 24) {
      return ['0' + time.slice(0, 1), ':', time.slice(1, 3)].join('');
    } else {
      return [time.slice(0, 2), ':', time.slice(2, 4)].join('');
    }
  }

  closeModal() {
    this.closeActiveModal.emit(true);
  }
}
