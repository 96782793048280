import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { StagingOrderLine, ViewSettings } from '../../+state/orders.models';
import { ORDER_ITEM_NUMBER, ORDER_LINE_STATUS } from '@pos-app/data';

@Component({
  selector: 'app-order-total',
  templateUrl: './order-total.component.html',
  styleUrls: ['./order-total.component.scss'],
})
export class OrderTotalComponent implements OnChanges {
  @Input() dispatchList: StagingOrderLine[];
  @Input() viewSettings: ViewSettings;
  @Input() orderDetails: StagingOrderLine[];
  @Input() isQuoteOrder: boolean;
  @Input() currencyCode: string;
  @Input() depositAmount: number;

  public totalValue = 0
  public totalValueEx = 0
  public minPayment: number;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dispatchList && changes.dispatchList.currentValue) {
      this.minPayment = this.dispatchList
        .filter(
          (item) =>
            item.itemNumber !== ORDER_ITEM_NUMBER.subTotal &&
            item.changeLineStatus !== ORDER_LINE_STATUS.cancelled &&
            item.lineStatus !== ORDER_LINE_STATUS.cancelled
        )
        .map((line) => line.extPriceInc)
        .reduce((a, b) => Math.round((a + b + Number.EPSILON) * 100) / 100, 0);
    }

    if (changes.orderDetails?.currentValue || changes.viewSettings) {
      this.calculateTotals();
    }
  }

  calculateTotals() {
    let filteredList = this.orderDetails.filter(
      (item) =>
        item.itemNumber !== ORDER_ITEM_NUMBER.subTotal &&
        item.lineStatus !== ORDER_LINE_STATUS.cancelled &&
        item.changeLineStatus !== ORDER_LINE_STATUS.cancelled
    );
    filteredList = !this.viewSettings.showInvoiced ? filteredList.filter((item) => item.lineStatus !== ORDER_LINE_STATUS.invoiced) : filteredList;
    // To make correct sum to 2 decimal places
    this.totalValue = filteredList.reduce((a, b) => Math.round((a + b.extPriceInc + Number.EPSILON) * 100) / 100, 0);
    this.totalValueEx = filteredList.reduce((a, b) => Math.round((a + b.extPriceEx + Number.EPSILON) * 100) / 100, 0);
  }
}
