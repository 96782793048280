<div class="d-flex">
  <input
    #searchBox
    type="search"
    [formControl]="fuzzySearchForm"
    class="form-control"
    [placeholder]="placeholder"
    [ngClass]="{
      expanded: show,
      'is-invalid': fuzzySearchForm.errors?.required
    }"
    (focus)="isFocus($event)"
    (blur)="lostFocus($event)"
  />
  <span class="pl-2" *ngIf="loading">
    <pos-app-spinner2 [dark]="true"></pos-app-spinner2>
  </span>
</div>
<p *ngIf="noResult && !useNotFoundItem" class="text-danger pt-2">No Result Found</p>
<div *ngIf="show && !noResult && fuzzySearchList?.length > 0 && showFuzzySearchList">
  <div class="text-danger" *ngIf="moreRecordsYN === 'Y' && show">More results exist - Please narrow search</div>
  <table class="border table table-striped shadow-lg searchResult mr-3" *ngIf="show && fuzzySearchList && fuzzySearchList.length > 0">
    <tbody>
      <tr (click)="selectItem(item)" *ngFor="let item of fuzzySearchList; let i = index">
        <td>
          <ngb-highlight [result]="item[description1Key]" [term]="fuzzySearchForm.value"> </ngb-highlight>
          <span class="px-1"></span>
          <span *ngIf="item[description2Key]">
            <ngb-highlight [result]="item[description2Key]" [term]="fuzzySearchForm.value"></ngb-highlight>
            <span class="px-1"></span>
          </span>
          (<ngb-highlight [result]="item[valueKey]" [term]="fuzzySearchForm.value"></ngb-highlight>)
        </td>
      </tr>
    </tbody>
  </table>
</div>
