import { Component } from '@angular/core';
import { ASSOCIATION_TYPE, ORDER_ITEM_NUMBER, ORDER_LINE_STATUS } from '@pos-app/data';
import { OrderGridComponent } from '../order-grid/order-grid.component';
import { Subject } from 'rxjs';
import { ICellRenderer } from 'ag-grid-community';

@Component({
  selector: 'app-action-cell',
  template: `
    <span *ngIf="!isNewRow && isConvertToCredit() && !isExternalUser()">
      <mat-icon (click)="creditInvoicedLine()" title="Credit this invoiced line"> copyright </mat-icon>
      <span class="pr-1"></span>
    </span>

    <!--Adding cart for associationType:mandatory items-->
    <span *ngIf="isSellAllowed()">
      <mat-icon
        *ngIf="isConvertToOrder() && params.data.readOnlyLineYN === 'N' && !isExternalUser()"
        (click)="convertQuoteToSalesOrder()"
        [title]="'Convert Quote line to Sales Order line'"
      >
        launch
      </mat-icon>
      <mat-icon
        *ngIf="!isConvertToOrder() && params.data.readOnlyLineYN === 'N' && !isExternalUser()"
        (click)="sellOrderItem()"
        [title]="'Sell this line'"
      >
        {{ isTruckIcon() ? 'local_shipping' : 'shopping_cart' }}
      </mat-icon>
      <span class="pr-1"></span>
    </span>

    <!--PRICE CREDIT ORDER BIN-->
    <span *ngIf="isDeleteAllowed() && params.data.readOnlyLineYN === 'N'">
      <mat-icon (click)="invokeDeleteMethod()" title="Delete/Cancel line">delete</mat-icon>
      <span class="pr-1"></span>
    </span>

    <span *ngIf="(!isNewRow && !isAutoAddedLine()) || isFreeGoodLine()">
      <span *ngIf="(isDispatchable() || isConvertToOrder()) && !isCancelledOrInvoiced()">
        <ng-container
          *ngIf="
            (params.data.parentLineNumber === 0 && params.data.freeGoodsLineYN === 'N') ||
            (params.data.parentLineNumber !== 0 && params.data.freeGoodsLineYN === 'Y' && !this.parentComponent.viewSettings.enforceItemRule)
          "
        >
          <mat-icon
            *ngIf="isConvertToOrder() && params.data.readOnlyLineYN === 'N' && !isExternalUser()"
            [title]="'Convert Quote line to Sales Order line'"
            (click)="convertQuoteToSalesOrder()"
          >
            launch
          </mat-icon>
          <mat-icon
            *ngIf="!isConvertToOrder() && !isExternalUser()"
            [title]="'Sell this line'"
            (click)="params.data.readOnlyLineYN === 'N' && sellOrderItem()"
          >
            {{ isTruckIcon() ? 'local_shipping' : 'shopping_cart' }}
          </mat-icon>
          <span class="pr-1"></span>
        </ng-container>
      </span>

      <span
        *ngIf="
          params.data.freeGoodsLineYN === 'N' ||
          (params.data.freeGoodsLineYN === 'Y' && !parentComponent.viewSettings.enforceItemRule) ||
          isPriceCreditDeleteAllowed()
        "
      >
        <ng-container *ngIf="params.data.readOnlyLineYN === 'N'">
          <mat-icon (click)="invokeDeleteMethod()" title="Delete/Cancel line">delete</mat-icon>
          <span class="pr-1"></span>
        </ng-container>
      </span>
      <span
        *ngIf="
          params.data.itemNumber !== orderItemNumber.subTotal &&
          params.data.lineStatus !== orderLineStatus.cancelled &&
          params.data.lineStatus !== orderLineStatus.invoiced &&
          !isCreditOrder() &&
          !isFreeGoodLine()
        "
      >
        <ng-container *ngIf="params.data.readOnlyLineYN === 'N'">
          <mat-icon (click)="invokeSubTotalMethod()" title="Add subtotal below this line"> functions</mat-icon>
          <span class="pr-1"></span>
        </ng-container>
      </span>
      <mat-icon
        (click)="invokePriceScalesMethod()"
        *ngIf="
          params.data.itemNumber !== orderItemNumber.subTotal &&
          params.data.lineStatus !== orderLineStatus.cancelled &&
          !isFreeGoodLine() &&
          !isShowUser() &&
          !isExternalUser()
        "
        title="Show all price scales"
      >
        attach_money
      </mat-icon>
      <mat-icon
        *ngIf="!isExternalUser()"
        class="stack-icon"
        (click)="invokeLineAttachment(params.data.readOnlyLineYN === 'Y')"
        title="View line attachment"
      >
        attachment
      </mat-icon>
      <mat-icon
        *ngIf="lineAttachments?.length > 0 && !isExternalUser()"
        (click)="invokeLineAttachment(params.data.readOnlyLineYN === 'Y')"
        class="stack-icon stack-top ml-1"
        color="warn"
        title="View line attachment"
      >
        priority_high
      </mat-icon>
    </span>
    <div *ngIf="isNewRow">
      <mat-icon
        (click)="invokeSearchMethod()"
        *ngIf="params.data.readOnlyLineYN !== 'Y' && params.data.itemNumber !== orderItemNumber.subTotal"
        title="Search product"
      >
        search
      </mat-icon>
    </div>
    <span *ngIf="!isNewRow && !isFreeGoodLine() && !isExternalUser()">
      <mat-icon class="stack-icon" (click)="invokeLineAttachment(params.data.readOnlyLineYN === 'Y')" title="View line attachment">
        attachment
      </mat-icon>
      <mat-icon
        *ngIf="lineAttachments?.length > 0"
        class="stack-icon stack-top ml-1"
        (click)="invokeLineAttachment(params.data.readOnlyLineYN === 'Y')"
        color="warn"
        title="View line attachment"
      >
        priority_high
      </mat-icon>
    </span>
  `,
  styles: [
    `
      .btn {
        line-height: 0.5;
      }

      .dispatched {
        color: green;
      }

      .stack-icon {
        position: absolute;
      }

      .stack-top {
        z-index: 9;
      }
    `,
  ],
})
export class OrderGridActionRendererComponent implements ICellRenderer {
  public params: any;
  public parentComponent: OrderGridComponent;
  isNewRow = false;
  orderItemNumber = ORDER_ITEM_NUMBER;
  orderLineStatus = ORDER_LINE_STATUS;
  lineAttachments;
  unSubscribe$ = new Subject<void>();

  constructor() {}

  agInit(params: any): void {
    this.params = params;
    this.parentComponent = this.params.context.componentParent;
    if (!this.params.node.data.orderNumber) {
      this.isNewRow = true;
    }
    this.lineAttachments = params.data.attachmentIdArray;
  }

  public invokeLineAttachment(isReadOnly) {
    this.parentComponent.attachItem(this.params.node, isReadOnly);
  }

  public invokeDeleteMethod() {
    this.parentComponent.deleteItem(this.params.node);
  }

  public invokeSearchMethod() {
    this.parentComponent.searchingItem(this.params.node.data.itemNumber);
  }

  public invokeSubTotalMethod() {
    this.parentComponent.addSubTotal(this.params.node);
  }

  public invokePriceScalesMethod() {
    this.parentComponent.priceScales(this.params.node);
  }

  public isAutoAddedLine() {
    return this.params.data.parentLineNumber !== 0;
  }

  public creditInvoicedLine(): void {
    if (this.parentComponent.orderHeader.quickSaleYN !== 'Y' && !this.isCreditOrder()) {
      this.parentComponent.dispatchItem(this.params.data, ORDER_LINE_STATUS.credit);
    }
  }

  public sellOrderItem(): void {
    if (this.parentComponent.orderHeader.quickSaleYN !== 'Y' && !this.isCreditOrder()) {
      this.parentComponent.dispatchItem(this.params.data, ORDER_LINE_STATUS.selling);
    }
  }

  public convertQuoteToSalesOrder(): void {
    if (this.parentComponent.orderHeader.quickSaleYN !== 'Y' && !this.isCreditOrder()) {
      this.parentComponent.dispatchItem(this.params.data, ORDER_LINE_STATUS.convertQuote);
    }
  }

  public isDispatchable() {
    return (
      (this.parentComponent.cashDrawer &&
        this.parentComponent.orderHeader &&
        this.parentComponent.orderHeader.customerPaymentTerms === 'POS' &&
        this.parentComponent.orderHeader.dispatchableYN === 'Y' &&
        !(this.params.data.itemNumber.toUpperCase() === ORDER_ITEM_NUMBER.giftCard && this.params.data.readOnlyLineYN === 'Y')) ||
      // when dispatchableYN = 'Y' and payableYN = 'Y' and cash drawer not attached
      // then shopping cart  and SELL GOODS is shown
      (this.parentComponent.orderHeader &&
        this.parentComponent.orderHeader.payableYN === 'Y' &&
        this.parentComponent.orderHeader.dispatchableYN === 'Y' &&
        !this.parentComponent.cashDrawer) ||
      // when dispatchableYN = 'Y' and payableYN = 'N' then shopping cart
      // and SELL GOODS is shown and payment screen is readonly
      (this.parentComponent.orderHeader &&
        this.parentComponent.orderHeader.dispatchableYN === 'Y' &&
        this.parentComponent.orderHeader.payableYN === 'N')
    );
  }

  public isConvertToOrder() {
    return this.parentComponent.isConvertToOrder;
  }

  public isTruckIcon() {
    return (
      (this.parentComponent.orderHeader.dispatchableYN === 'Y' && this.parentComponent.orderHeader.payableYN === 'N') ||
      (this.parentComponent.orderHeader.dispatchableYN === 'Y' &&
        this.parentComponent.orderHeader.payableYN === 'Y' &&
        !this.parentComponent.cashDrawer)
    );
  }

  public isConvertToCredit() {
    return (
      this.parentComponent.orderHeader &&
      this.parentComponent.orderHeader.convertToCreditYN === 'Y' &&
      !this.parentComponent.orderHeader.orderNumber.startsWith('T') &&
      this.params.node.data.lineStatus === ORDER_LINE_STATUS.invoiced &&
      this.params.node.data.itemNumber !== ORDER_ITEM_NUMBER.subTotal &&
      // Allow for mandatory items except kit components i.e. blank association type
      (this.params.data.parentLineNumber === 0 || this.isMandatoryItem(this.params.data))
    );
  }

  public isCancelledOrInvoiced() {
    return (
      this.params.node.data.lineStatus === ORDER_LINE_STATUS.cancelled ||
      this.params.node.data.changeLineStatus === ORDER_LINE_STATUS.cancelled ||
      this.params.node.data.lineStatus === ORDER_LINE_STATUS.invoiced
    );
  }

  public isMandatoryItem(itemLine) {
    const associationType = itemLine.associationType.toUpperCase();
    return (
      associationType === ASSOCIATION_TYPE.mandatory ||
      associationType === ASSOCIATION_TYPE.paint ||
      associationType === ASSOCIATION_TYPE.fit ||
      itemLine.kitPC !== 'C'
    );
  }

  public isCreditOrder() {
    return this.parentComponent.isCreditOrder;
  }

  public isFreeGoodLine() {
    return this.params.data.freeGoodsLineYN === 'Y';
  }

  public isShowUser() {
    return this.parentComponent.isShowUser;
  }

  public isExternalUser() {
    return this.parentComponent.isExternalUser;
  }

  public isDeleteAllowed() {
    return (
      !this.isNewRow &&
      this.params.data.associationType.toUpperCase() === ASSOCIATION_TYPE.mandatory &&
      !this.parentComponent.viewSettings.enforceItemRule
    );
  }
  public isSellAllowed() {
    return (
      !this.isNewRow &&
      this.params.data.associationType.toUpperCase() === ASSOCIATION_TYPE.mandatory &&
      !this.parentComponent.viewSettings.enforceItemRule
    );
  }
  public isPriceCreditDeleteAllowed() {
    return !this.isNewRow && this.isCreditOrder() && this.params.data.parentLineNumber === 0;
  }

  public refresh(): boolean {
    return false;
  }
}
