import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ArrayOfLinesObject } from '@pos-app/data';

@Component({
  selector: 'app-array-of-line-card',
  templateUrl: './array-of-line-card.component.html',
  styleUrls: ['./array-of-line-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArrayOfLineCardComponent implements OnInit {
  @Input() ArrayOfLineCard: ArrayOfLinesObject;

  constructor() {}

  ngOnInit(): void {}
}
