import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Supplier } from '../../models/supplier.model';

@Component({
  templateUrl: './orders-buyin-supplier-dialog.component.html',
  styleUrls: ['./orders-buyin-supplier-dialog.component.scss'],
})
export class OrdersBuyinSupplierDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: Supplier, private dialogRef: MatDialogRef<OrdersBuyinSupplierDialogComponent>) {}

  public select(item: Supplier): void {
    this.dialogRef.close(item);
  }

  public cancel(): void {
    this.dialogRef.close();
  }
}
