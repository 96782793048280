import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SaleService {
  private _callSale$ = new Subject();
  public callSale$ = this._callSale$.asObservable();

  constructor() {}

  openQuickSale() {
    return this._callSale$.next();
  }
}
