import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { USER_ROLE } from '@pos-app/data';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { WarrantyHeader } from '../../../../core/models/warranty-form.models';

@Component({
  selector: 'app-warrantyform-header',
  templateUrl: './warrantyform-header.component.html',
  styleUrls: ['./warrantyform-header.component.scss']
})
export class WarrantyformHeaderComponent implements OnInit, OnDestroy {
  @Input() loginDetails: any;
  @Input() warrantySubmittedNumber;
  @Output() headerValue = new EventEmitter<{
    value: WarrantyHeader;
    isValid: boolean;
  }>();
  warrantyForm: FormGroup;
  attachedFiles: File[] = [];
  unSubscribe$ = new Subject<void>();
  today = Date();
  issueDescriptionCharCount: number = 0;
  actionCharCount: number = 0;

  constructor(private fb: FormBuilder) {
    this.warrantyForm = this.fb.group({
      name: ['', [Validators.required]],
      firstName: [''],
      surname: [''],
      email: ['', [Validators.required,Validators.email]],
      phone: ['', [Validators.required, Validators.pattern('^[0-9 ]{10,}$')]],
      customerReference: [''],
      phoneType: [''],
      addressLine1: [''],
      addressLine2: [''],
      suburb: [''],
      state: [''],
      postCode: [''],
      country: [''],
      secContactName: [''],
      secContactPhone: [''],
      marketingOptInYN: [''],
      useBusinessNameYN: [''],
      shipToNumber: [0],
      branch: [''],
      reseller: [''],
      writtenBy: [''],
      issueDescription: ['', [Validators.required]],
      action: [''],
      suppliedFittedBy: ['', [Validators.required]],
      attachedFiles: [[]]
    });
  }

  ngOnInit() {
    this.warrantyForm.patchValue({
      reseller:
        this.loginDetails.Role === USER_ROLE.internal
          ? this.loginDetails.DefaultBranchName.toUpperCase()
          : this.loginDetails.CustomerName.toUpperCase(),
      writtenBy: this.loginDetails.Name.toUpperCase(),
      branch: this.loginDetails.DefaultBranch
    });
    this.headerValue.emit({
      value: this.warrantyForm.value,
      isValid: this.warrantyForm.valid
    });
    this.warrantyForm.controls.name.valueChanges
      .pipe(
        debounceTime(300),
        takeUntil(this.unSubscribe$)
      )
      .subscribe((value: string) => {
        const nameArray = value.split(' ');
        const surname =
          nameArray.length === 1 ? '' : nameArray[nameArray.length - 1];
        this.warrantyForm.patchValue({
          surname,
          firstName: value.replace(`${surname}`, '').trim()
        });
      });
    this.warrantyForm.valueChanges
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe(headerForm => {
        this.headerValue.emit({
          value: headerForm,
          isValid: this.warrantyForm.valid
        });
        this.issueDescriptionCharCount = headerForm.issueDescription.length;
        this.actionCharCount = headerForm.action.length;
      });
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      Array.from<File>(event.target.files).forEach(file => this.attachedFiles.push(file));
      this.warrantyForm.patchValue({
        attachedFiles: this.attachedFiles
      });
    }
  }

  onRemoveFile(index: number) {
    this.attachedFiles.splice(index, 1);
  }
}
