import { Injectable } from '@angular/core';
import { Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import {
  OrdersPartialState,
  ORDERS_FEATURE_KEY,
  OrdersState,
} from './orders.reducer';
import { ordersQuery } from './orders.selectors';
import {
  OrdersActionTypes,
  LoadFitlerLists,
  LoadFitlerListsSuccess,
  LoadFitlerListsFailed,
  SearchOrderSummary,
  SearchOrderSummarySuccess,
  SearchOrderSummaryFailed,
  CreateOrder,
  CreateOrderFailed,
  CreateOrderSuccess,
  LoadOrderHeaderSuccess,
  LoadOrderHeaderFailed,
  LoadOrderHeader,
  LoadStagingOrder,
  LoadStagingOrderSuccess,
  LoadStagingOrderFailed,
  UpdateStagingOrderLine,
  ValidateOrder,
  ValidateOrderSuccess,
  ValidateOrderFailed,
  ResetOrderValidation,
  UpdateStagingOrderLineFailed,
  RevalidateOrder,
  RevalidateOrderFailed,
  RevalidateOrderSuccess,
  ConvertLines,
  ConvertLinesFailed,
  ConvertLinesSuccess,
  UpdateAndValidate,
  UpdateAndValidateFailed,
  ValidateSplitLineOrder,
  ValidateSplitLineFailed,
  CreateCredit,
  CreateCreditFailed,
  CreateCreditSuccess,
  CopyOrder,
  CopyOrderFailed,
  CopyOrderSuccess,
  FetchJdeOrder,
  FetchJdeOrderFailed,
  FetchJdeOrderSuccess,
  FinaliseOrder,
  FinaliseOrderFailed,
  FinaliseOrderSuccess,
  AddItemsToOrder,
  AddItemsToOrderFailed,
  AddItemsToOrderSuccess,
  AddItemProcessed,
  FetchLoadedGiftCards,
  FetchLoadedGiftCardsSuccess,
  FetchLoadedGiftCardsFailed,
  LoadParkedOrdersList,
  LoadParkedOrdersListSuccess,
  LoadParkedOrdersListFailed,
  LoadParkedOrder,
  LoadParkedOrderSuccess,
  LoadParkedOrderFailed,
  fetchUpdatedOrderLineSuccess,
  fetchUpdatedOrderLineFailed,
  fetchUpdatedOrderLine,
  FetchPaymentInfo,
  FetchPaymentInfoSuccess,
  FetchPaymentInfoFailed,
  UpdateStagingOrderAllLine,
  UpdateStagingOrderAllLineFailed,
} from './orders.actions';
import {
  catchError,
  concatMap,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import {
  navigateToUrl,
  MessageService,
  DialogService,
  DrawerService,
  ApiService,
  hasLoadedGiftCard,
  CoreUiPartialState,
  getHasActiveOrder,
  getHasPendingPayments,
  getHasLoadedGiftCards,
  resetAllFlags,
  createQuickSale,
  getHasOrderChanged,
  parkedOrderListLength,
  hasPendingPayments,
} from '@pos-app/core-ui';
import { merge, combineLatest, of, from, Observable, forkJoin } from 'rxjs';
import {
  ORDER_TYPE,
  ORDER_LINE_STATUS,
  ORDER_ITEM_NUMBER,
  roundingNumber,
} from '@pos-app/data';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SecureCatalogueService } from 'libs/core-ui/src/lib/services/secure-catelogue.service';
import { UpdatedOrderLine } from './orders.models';

@Injectable()
export class OrdersEffects {
  @Effect() loadFilterLists$ = this.dataPersistence.fetch(
    OrdersActionTypes.LOAD_FILTER_LISTS,
    {
      run: (action: LoadFitlerLists, state: OrdersPartialState) => {
        if (state[ORDERS_FEATURE_KEY].cachedOrderFilterLists) {
          return;
        }
        return this.apiService.getOrderFilterLists().pipe(
          map(
            ([
              branchListResult,
              orderTypeListResult,
              productGroupListResult,
              customerChannelListResult,
            ]) => {
              const result = {
                branchList: branchListResult.SearchResults,
                orderTypeList: orderTypeListResult.SearchResults,
                productGroupList: productGroupListResult.SearchResults,
                customerChannelList: customerChannelListResult.SearchResults,
              };

              result.branchList.unshift({
                BranchCode: '',
                BranchDescription: 'All branches',
              });
              result.orderTypeList.unshift({
                OrderTypeCode: '',
                OrderTypeDescription: 'All order types',
              });
              return new LoadFitlerListsSuccess(result);
            }
          )
        );
      },
      onError: (action: LoadFitlerLists, error) => {
        return new LoadFitlerListsFailed(error);
      },
    }
  );

  @Effect() searchOrderSummary$ = this.dataPersistence.fetch(
    OrdersActionTypes.SEARCH_ORDER_SUMMARY,
    {
      run: (action: SearchOrderSummary, state: OrdersPartialState) => {
        const orderType = action.payload.orderType;
        const getOrderSummaryTask =
          orderType === ORDER_TYPE.saleOrder
            ? this.apiService.getOrderSummaryOpenOrders(action.payload)
            : orderType === ORDER_TYPE.quoteOrder
            ? this.apiService.getOrderSummaryQuoteOrders(action.payload)
            : this.apiService.getOrderSummaryInvoicedOrders(action.payload);
        return getOrderSummaryTask.pipe(
          map((result) => new SearchOrderSummarySuccess(result))
        );
      },
      onError: (action: SearchOrderSummary, error) => {
        return new SearchOrderSummaryFailed(error);
      },
    }
  );

  @Effect() createOrder$ = this.dataPersistence.fetch(
    OrdersActionTypes.CREATE_ORDER,
    {
      run: (action: CreateOrder, state: OrdersPartialState) => {
        return this.apiService.createOrder(action.payload.data).pipe(
          map((result) => {
            this.toastr.success('Order created successfully');

            this.router.navigateByUrl(
              action.payload.toOrder
                ? `/pos/orders/${result.OrderNumber}?continueUrl=home`
                : this.secureCatalogueService.getLandingPage()
            );

            return new CreateOrderSuccess();
          })
        );
      },
      onError: (action: CreateOrder, error) => {
        this.toastr.error(error?.ErrorMessage);
        return new CreateOrderFailed(error);
      },
    }
  );

  @Effect() loadOrderHeader$ = this.dataPersistence.fetch(
    OrdersActionTypes.LOAD_ORDER_HEADER,
    {
      run: (action: LoadOrderHeader, state: OrdersPartialState) => {
        return this.apiService.getOrderHeader(action.payload).pipe(
          map((result) => {
            return new LoadOrderHeaderSuccess(result);
          })
        );
      },
      onError: (action: LoadOrderHeader, error) => {
        return new LoadOrderHeaderFailed(error);
      },
    }
  );

  @Effect() loadStagingOrder$ = this.dataPersistence.fetch(
    OrdersActionTypes.LOAD_STAGING_ORDER_DETAILS,
    {
      run: (action: LoadStagingOrder, state: OrdersPartialState) => {
        return this.orderStore.select(ordersQuery.getViewSettings).pipe(
          take(1),
          switchMap((viewSettings) => {
            return this.apiService.getStagingOrderDetails({
              orderNumber: action.payload.orderNumber,
              fetchCancelledLinesTF: viewSettings.showCancelled || false,
              fetchInvoicedLinesTF: viewSettings.showInvoiced || false
            });
          }),
          mergeMap((result) => {
            const orderNumber = action.payload.orderNumber;
            const actions: any[] = [
              new LoadStagingOrderSuccess(result),
            ];

            const updatingItems = result?.SearchResults?.filter(item => !!item.updatingRow?.trim());
            if (updatingItems?.length) {
              actions.push(new fetchUpdatedOrderLine({
                orderNumber: orderNumber,
                ids: updatingItems.map((item) => item.id),
              }))
            }

            return actions;
          })
        );
      },
      onError: (action: LoadStagingOrder, error) => {
        this.toastr.error(error?.ErrorMessage);
        return new LoadStagingOrderFailed(error);
      },
    }
  );

  @Effect() loadUpdatedOrderLine$ = this.dataPersistence.fetch(
    OrdersActionTypes.FETCH_UPDATED_ORDER_LINE,
    {
      run: (action: fetchUpdatedOrderLine, state: OrdersPartialState) => {
        const requests: Observable<UpdatedOrderLine>[] = action.payload.ids.map(
          (id) => {
            return this.apiService.fetchUpdatedOrderLine({
              id,
              orderNumber: action.payload.orderNumber,
            });
          }
        );

        return forkJoin(requests).pipe(
          map((results) => new fetchUpdatedOrderLineSuccess(results))
        );
      },
      onError: (action: fetchUpdatedOrderLine, error) => {
        this.toastr.error(error?.ErrorMessage);
        return new fetchUpdatedOrderLineFailed(error);
      },
    }
  );

  @Effect() updateStagingOrderAllLine$ = this.dataPersistence.fetch(
    OrdersActionTypes.UPDATE_STAGING_ORDER_ALL_LINE,
    {
      run: (action: UpdateStagingOrderAllLine, state: OrdersPartialState) => {
        const orderNumber = state[ORDERS_FEATURE_KEY].activeOrderHeader.orderNumber;
        const input = {
          orderNumber: orderNumber,
          ...action.payload
        }

        return this.apiService.updateAllItemsOnOrder(input).pipe(
          mergeMap(() => {
            this.toastr.success('Order lines update successfully.');
            return [
              new ResetOrderValidation(),
              new LoadStagingOrder({ orderNumber }),
            ];
          })
        );
      },
      onError: (action: UpdateStagingOrderAllLine, error) => {
        return new UpdateStagingOrderAllLineFailed(error);
      },
    }
  );

  @Effect() updateStagingOrderLine$ = this.dataPersistence.fetch(
    OrdersActionTypes.UPDATE_STAGING_ORDER_LINE,
    {
      run: (action: UpdateStagingOrderLine, state: OrdersPartialState) => {
        const orderNumber = state[ORDERS_FEATURE_KEY].activeOrderHeader.orderNumber;
        const requests: Observable<any>[] = action.payload.map((item) =>
          this.apiService.updateItemOnOrder(item)
        );
        return combineLatest(requests).pipe(
          mergeMap(() => {
            this.toastr.success('Order line update successfully.');
            return [
              new ResetOrderValidation(),
              new LoadStagingOrder({ orderNumber }),
            ];
          })
        );
      },
      onError: (action: UpdateStagingOrderLine, error) => {
        return new UpdateStagingOrderLineFailed(error);
      },
    }
  );

  @Effect() validateOrder$ = this.dataPersistence.fetch(
    OrdersActionTypes.VALIDATE_ORDER,
    {
      run: (action: ValidateOrder, state: OrdersPartialState) => {
        return this.apiService.validateOrderInJDE(action.payload).pipe(
          mergeMap((result) => {
            return [
              new ValidateOrderSuccess({
                finalModeYN: action.payload.finalModeYN,
                result,
              }),
              /* LoadStagingOrder is called in response of LoadOrderHeader */
              new LoadOrderHeader({
                orderNumber: action.payload.orderNumber,
              }),
            ];
          })
        );
      },
      onError: (action: ValidateOrder, error) => {
        this.toastr.error(error?.ErrorMessage);
        return merge([
          /* LoadStagingOrder is called in response of LoadOrderHeader */
          new LoadOrderHeader({ orderNumber: action.payload.orderNumber }),
          new ValidateOrderFailed(error),
        ]);
      },
    }
  );

  @Effect() revalidateOrder$ = this.dataPersistence.fetch(
    OrdersActionTypes.REVALIDATE_ORDER,
    {
      run: (action: RevalidateOrder, state: OrdersPartialState) => {
        const currentOrder = state[ORDERS_FEATURE_KEY].stagingOrder;
        const updateItems =
          currentOrder.length > 0
            ? combineLatest(
                currentOrder.map((orderLine) => {
                  const updatedData = {
                    orderNumber: orderLine.orderNumber,
                    brand: orderLine.brand,
                    id: orderLine.id,
                    itemQty: orderLine.qtyOrdered,
                    paintYN: orderLine.paintYN,
                    fittedYN: orderLine.fittedYN,
                    colourCode: orderLine.colourCode,
                    itemDesc: orderLine.itemDescription,
                    unitPriceExTax: orderLine.unitPriceEx,
                    unitPriceIncTax: orderLine.unitPriceInc,
                    extPriceExTax: orderLine.extPriceEx,
                    extPriceIncTax: orderLine.extPriceInc,
                    partsVehicleID: orderLine.partsVehicleID,
                    overridePriceYN: orderLine.overridePriceYN,
                    reasonCode: action.payload.reasonCode,
                  };
                  return this.apiService.updateItemOnOrder(updatedData);
                })
              )
            : of({});
        return updateItems.pipe(
          mergeMap(() => {
            return [
              new RevalidateOrderSuccess(''),
              /* LoadStagingOrder is called in response of LoadOrderHeader */
              new LoadOrderHeader({ orderNumber: action.payload.orderNumber }),
            ];
          })
        );
      },
      onError: (action: RevalidateOrder, error) => {
        return new RevalidateOrderFailed(error);
      },
    }
  );


  @Effect() convertLines$ = this.dataPersistence.fetch(
    OrdersActionTypes.CONVERT_LINES,
    {
      run: (action: ConvertLines, state: OrdersPartialState) => {
        const orderNumber = state[ORDERS_FEATURE_KEY].activeOrderHeader.orderNumber;
        return this.apiService.finaliseOrderToJDE({
          orderNumber,
          email: ' ',
          outputType: ' ',
          overrideBranch: action.payload.overrideData
            ? action.payload.overrideData.overrideBranch
            : ' ',
          overrideJdeInvoiceNo: 0,
          overrideJdeOrderNo: 0,
          overrideSalesPerson:
            action.payload.overrideData.overrideSalesPerson,
          overrideShipTo: action.payload.overrideData.overrideShipTo,
          printerID: ' ',
        }).pipe(
          switchMap((res) => {
            return of(res.orderNumber);
          }),
          mergeMap((newOrderNumber) => {
            this.reloadOrderDetails(newOrderNumber, true);
            return [new FetchJdeOrderSuccess(), new ConvertLinesSuccess()];
          })
        );
      },
      onError: (action: ConvertLines, error) => {
        return new ConvertLinesFailed(error);
      },
    }
  );

  @Effect() createCredit$ = this.dataPersistence.fetch(
    OrdersActionTypes.CREATE_CREDIT,
    {
      run: (action: CreateCredit, state: OrdersPartialState) => {
        const orderNumber = state[ORDERS_FEATURE_KEY].activeOrderHeader.orderNumber;

        return this.apiService.checkPreviousCredit({
          orderNumber,
        })
        .pipe(
          switchMap((res) => {
            if (res.creditedFlagYN === 'Y') {
              return this.dialogService
                .confirm(
                  `Select NO to go back, or select YES to raise another credit for this invoice`,
                  'WARNING: CREDIT ALREADY RAISED FOR THIS INVOICE'
                )
                .pipe(
                  switchMap((val) => {
                    if (val) {
                      return this.apiService.createCreditOrder({
                        orderNumber,
                        creditType: action.payload.creditType
                      });
                    } else {
                      // Select NO means revert items' changeLineStatus to blank
                      const input = {
                        orderNumber: orderNumber,
                        changeLineStatus: ORDER_LINE_STATUS.empty,
                      }

                      return this.apiService.updateAllItemsOnOrder(input)
                    }
                  })
                );
            } else {
              return this.apiService.createCreditOrder({
                orderNumber,
                creditType: action.payload.creditType
              });
            }
          }),
          map((res) => {
            // when select NO, do nothing
            if (Array.isArray(res)) {
              return new CreateCreditSuccess();
            } else {
              this.reloadOrderDetails(res.newOrderNumber, false);
              return new CreateCreditSuccess();
            }
          })
        );
      },
      onError: (action: CreateCredit, error) => {
        return new CreateCreditFailed(error);
      },
    }
  );

  @Effect() copyOrder$ = this.dataPersistence.fetch(
    OrdersActionTypes.COPY_ORDER,
    {
      run: (action: CopyOrder, state: OrdersPartialState) => {
        const orderType = state[ORDERS_FEATURE_KEY].activeOrderHeader.orderType;
        return this.apiService.copyOrder().pipe(
          map((res) => {
            this.reloadOrderDetails(
              res.newOrderNumber,
              false
            );
            return new CopyOrderSuccess();
          })
        );
      },
      onError: (action: CopyOrder, error) => {
        return new CopyOrderFailed(error);
      },
    }
  );

  @Effect() updateAndValidate$ = this.dataPersistence.fetch(
    OrdersActionTypes.UPDATE_AND_VALIDATE,
    {
      run: (action: UpdateAndValidate, state: OrdersPartialState) => {
        const orderNumber =
          state[ORDERS_FEATURE_KEY].activeOrderHeader.orderNumber;
        const isValidationRequired =
          state[ORDERS_FEATURE_KEY].stagingOrder.some((item) => item.validateRequiredYN === 'Y' && item.lineValidatedYN !== 'Y');
        const requests: Observable<any>[] = action.payload.data.map((item) =>
          this.apiService.updateItemOnOrder(item)
        );

        return combineLatest(requests).pipe(
          switchMap(() => {
            return isValidationRequired
              ? this.apiService.validateOrderInJDE({
                  orderNumber,
                  finalModeYN: 'N',
                })
              : of({});
          }),
          mergeMap((res) => {
            return of(
              new FinaliseOrder({
                receiptData: action.payload.receiptData,
                distributeOutputData: action.payload.distributeOutputData,
                nextNumberType: action.payload.nextNumberType,
                itemsToUpdate: action.payload.data,
              })
            );
          })
        );
      },
      onError: (action: UpdateAndValidate, error) => {
        return new UpdateAndValidateFailed(error);
      },
    }
  );

  @Effect() validateSplitLineOrder$ = this.dataPersistence.fetch(
    OrdersActionTypes.VALIDATE_SPLIT_LINES_ORDER,
    {
      run: (action: ValidateSplitLineOrder, state: OrdersPartialState) => {
        const orderNumber = action.payload.orderNumber;

        // validate order to populate free goods
        return this.apiService
          .validateOrderInJDE({
            orderNumber,
            finalModeYN: 'N',
          })
          .pipe(
            withLatestFrom(this.orderStore.select(ordersQuery.getViewSettings)),
            switchMap(([, viewSettings]) => {
              return this.apiService.getStagingOrderDetails({
                orderNumber,
                fetchCancelledLinesTF: viewSettings.showCancelled,
                fetchInvoicedLinesTF: viewSettings.showInvoiced
              });
            }),
            switchMap((res) => {
              // Check new free goods added
              const orderDetails = res.SearchResults;
              const freeGoods: any[] = orderDetails.filter(
                (item) =>
                  item.freeGoodsLineYN === 'Y' &&
                  item.changeLineStatus.trim() === ''
              );
              // Update changeLineStatus of new free goods
              if (freeGoods.length > 0) {
                const requests: Observable<any>[] = freeGoods.map(
                  (freeGood) => {
                    return this.apiService.updateItemOnOrder({
                      orderNumber,
                      id: freeGood.id,
                      changeLineStatus: ORDER_LINE_STATUS.selling,
                    });
                  }
                );

                return combineLatest([...requests, of(res)]);
              }
              return of([res]);
            }),
            mergeMap((res) => {
              return of(
                new LoadStagingOrderSuccess(res.pop()),
                new FinaliseOrder({
                  receiptData: action.payload.receiptData,
                  distributeOutputData: action.payload.distributeOutputData,
                  nextNumberType: action.payload.nextNumberType,
                })
              );
            })
          );
      },
      onError: (action: ValidateSplitLineOrder, error) => {
        return new ValidateSplitLineFailed(error);
      },
    }
  );

  @Effect() fetchJdeOrder$ = this.dataPersistence.fetch(
    OrdersActionTypes.FETCH_JDE_ORDER,
    {
      run: (action: FetchJdeOrder, state: OrdersPartialState) => {
        return this.coreUiStore.select(getHasActiveOrder).pipe(
          take(1),
          withLatestFrom(this.coreUiStore.select(getHasOrderChanged)),
          switchMap(([hasActiveOrder, isOrderChanged]) => {
            if (hasActiveOrder && isOrderChanged) {
              return combineLatest([
                this.coreUiStore.select(getHasPendingPayments),
                this.coreUiStore.select(getHasLoadedGiftCards),
              ]);
            } else {
              return of(null);
            }
          }),
          take(1),
          switchMap((res) => {
            if (res && (res[0] || res[1])) {
              this.toastr.error(
                res[1]
                  ? 'There are loaded gift cards on the current order so the order must be finalised'
                  : 'There are pending payments on the current order so the order must either be saved into JDE or the pending payment must be netted out or deleted'
              );
              return of(null);
            }
            return of({});
          }),
          switchMap((res) => {
            if (res) {
              return this.apiService.fetchOrderFromJDE({
                orderNumber: action.payload.orderNumber,
                orderType: action.payload.orderType,
              })
            }
            // when answering No
            return of(null);
          }),
          map((res) => {
            if (res?.ErrorFlag === '0') {
              this.toastr.success('Order loaded to staging successfully');
              this.router.navigateByUrl(
                `/pos/orders/${action.payload.orderNumber}?continueUrl=home`
              );
              return new FetchJdeOrderSuccess();
            }

            this.toastr.error(res.ErrorMessage);
            return new FetchJdeOrderFailed(res);
          })
        );
      },
      onError: (action: FetchJdeOrder, error) => {
        this.toastr.error(error?.ErrorMessage);
        return new FetchJdeOrderFailed(error);
      },
    }
  );

  @Effect() fetchPaymentInfo$ = this.dataPersistence.fetch(
    OrdersActionTypes.FETCH_PAYMENT_INFO,
    {
      run: (action: FetchPaymentInfo, state: OrdersPartialState) => {
        return this.apiService.fetchPaymentInfo(action.payload).pipe(
          map((res) => {
            this.orderStore.dispatch(hasPendingPayments({hasPendingPayments: res.pendingPayments?.length > 0}))

            return new FetchPaymentInfoSuccess(res)
          })
        );
      },
      onError: (action: FetchPaymentInfo, error) => {
        return new FetchPaymentInfoFailed(error);
      },
    }
  );

  @Effect() fetchLoadedGiftCards$ = this.dataPersistence.fetch(
    OrdersActionTypes.FETCH_LOADED_GIFTCARDS,
    {
      run: (action: FetchLoadedGiftCards, state: OrdersPartialState) => {
        return this.apiService.fetchGiftCardLoad(action.payload).pipe(
          map((res) => {
            this.messageService.dispatchAction(
              hasLoadedGiftCard({
                hasLoadedGiftCards: res.completedLoads?.length > 0,
              })
            );
            return new FetchLoadedGiftCardsSuccess(res);
          })
        );
      },
      onError: (action: FetchLoadedGiftCards, error) => {
        return new FetchLoadedGiftCardsFailed(error);
      },
    }
  );

  @Effect() finaliseOrder$ = this.dataPersistence.fetch(
    OrdersActionTypes.FINALISE_ORDER,
    {
      run: (action: FinaliseOrder, state: OrdersPartialState) => {
        const orderHeader = state[ORDERS_FEATURE_KEY].activeOrderHeader;
        // Get invoice number or JDE order number
        const task = action.payload.nextNumberType
          ? this.apiService.fetchNextNumber({
              orderType: orderHeader.orderType,
              nextNumberType: action.payload.nextNumberType,
            })
          : of(null);
        return task.pipe(
          tap((res) => {
            let transactionNo;
            if (res) {
              transactionNo = `${res.nextNumber}.${
                res.invoiceType ? res.invoiceType : orderHeader.orderType
              }`;
            } else {
              transactionNo = `${orderHeader.orderNumber}.${orderHeader.orderType}`;
            }
            if (action.payload.receiptData) {
              const stagingOrder = state[ORDERS_FEATURE_KEY].stagingOrder;
              const openOrderLines = [];
              const newOrderLines = [];
              let hasOpenLines = false;
              let hasNewLines = false;

              stagingOrder.map((item) => {
                if (item.lineStatus.trim() === ORDER_LINE_STATUS.new) {
                  newOrderLines.push(item.ooeLineNumber);
                }
              });

              stagingOrder.map((item) => {
                if (item.lineStatus === ORDER_LINE_STATUS.open) {
                  openOrderLines.push(item.ooeLineNumber);
                }
              });

              const printOrderLines = stagingOrder
                .filter(
                  (item) =>
                    item.itemNumber !== ORDER_ITEM_NUMBER.subTotal &&
                    item.kitPC !== 'C' &&
                    item.lineStatus !== ORDER_LINE_STATUS.cancelled &&
                    item.changeLineStatus !== ORDER_LINE_STATUS.cancelled
                )
                .map((item) => {
                  // Get serial numbers from stock allocation object
                  let serialNumber = item.serialNumber.trim();
                  let isInvoiced = false;
                  let isSelling = false;
                  if (action.payload.itemsToUpdate) {
                    const foundItem = action.payload.itemsToUpdate.find(
                      (anItem) => anItem.orderLine === item.ooeLineNumber
                    );
                    if (foundItem) {
                      serialNumber = foundItem.serialNumber.trim();
                      isInvoiced = true;
                      isSelling = true;

                      openOrderLines.forEach((openLineNumber, index) => {
                        if (openLineNumber == foundItem.orderLine)
                          openOrderLines.splice(index, 1);
                      });

                      newOrderLines.forEach((newLineNumber, index) => {
                        if (newLineNumber == foundItem.orderLine)
                          newOrderLines.splice(index, 1);
                      });
                    }
                  }
                  hasOpenLines = openOrderLines.length > 0 ?? false;
                  hasNewLines = newOrderLines.length > 0 ?? false;

                  return {
                    itemNumber: item.itemNumber,
                    description: item.itemDescription,
                    price: item.unitPriceInc.toFixed(2),
                    qty: item.qtyOrdered,
                    total: item.extPriceInc.toFixed(2),
                    tax: (+item.extPriceInc - +item.extPriceEx).toFixed(2),
                    colourCode: item.paintYN === 'Y' ? item.colourCode : '',
                    serialNumber,
                    isInvoiced:
                      isInvoiced ||
                      item.lineStatus === ORDER_LINE_STATUS.invoiced,
                    isSelling:
                      isSelling ||
                      item.changeLineStatus === ORDER_LINE_STATUS.selling,
                  };
                });

              this.drawerService.printReceipt({
                ...action.payload.receiptData,

                //REDUNDANT LOGIC
                // ** Invoice receipt logic:
                // outstanding > 0 (either sale or deposit transaction) => split to 2 receipts when applicable:
                // 1. tax invoice receipt that shows current selling lines (if it's deposit i.e. no selling lines, just print the deposit receipt)
                // 2. deposit receipt that shows all items
                // if outstanding === 0, there are 2 cases:
                // 1. selling remaining items in the partial sale or selling all items: print 1 tax invoice recept that shows current/all selling items
                // 2. deposit with payment amount >= total order value: print 1 deposit receipt that shows all items

                //NEW LOGIC
                // ** Invoice receipt logic:
                // *** hasOpenLines: checks if orders have open orders
                // *** hasNewLines: checks if orders have new added orders
                // hasOpenLines or hasNewLines == true :
                // 1. No Condition on outstanding amount
                // 2. orderLines is responsible for printing deposit reciept
                // 3. sellingLines is responsible for printing tax invoice reciept
                // hasOpenLines or hasNewLines == false :
                // 1. orderLines is responsible for printing invoice reciept / deposit receipt depending on isInvoice
                // 1. sellingLine is null

                orderLines:
                  // !action.payload.receiptData.isInvoice
                  hasOpenLines || hasNewLines
                    ? printOrderLines
                    : printOrderLines.filter((item) => item.isSelling),
                sellingLines:
                  printOrderLines.filter((item) => item.isSelling).length > 0 &&
                  printOrderLines.length !==
                    printOrderLines.filter((item) => item.isSelling).length &&
                  (hasOpenLines || hasNewLines)
                    ? printOrderLines.filter((item) => item.isSelling)
                    : null,
                transactionNo,
              });
            }
          }),
          switchMap((res) => {
            return this.apiService.finaliseOrderToJDE({
              orderNumber: orderHeader.orderNumber,
              overrideJdeInvoiceNo:
                res && action.payload.nextNumberType === 'I'
                  ? res.nextNumber
                  : null,
              overrideJdeOrderNo:
                res && action.payload.nextNumberType === 'O'
                  ? res.nextNumber
                  : null,
              email: action.payload.distributeOutputData.email,
              printerID: action.payload.distributeOutputData.printerID,
              outputType: action.payload.distributeOutputData.toOutput
                ? action.payload.distributeOutputData.outputType
                : '',
            });
          }),
          mergeMap((res) => {
            this.messageService.dispatchAction(resetAllFlags());
            this.toastr.success(res.ErrorMessage);
            return [
              new FinaliseOrderSuccess(res),
              action.payload.isQuickSale &&
              action.payload.redirectRoute === 'QuickSale'
                ? createQuickSale()
                : navigateToUrl({
                    url: this.secureCatalogueService.getLandingPage(),
                  }),
            ];
          })
        );
      },
      onError: (action: FinaliseOrder, error) => {
        return new FinaliseOrderFailed(error);
      },
    }
  );

  @Effect() addItemsToOrder$ = this.dataPersistence.fetch(
    OrdersActionTypes.ADD_ITEMS_TO_ORDER,
    {
      run: (action: AddItemsToOrder, state: OrdersPartialState) => {
        let errorItems = [];
        let addedItem = 0;
        const numberOfItemsToAdd = action.payload.items.length;
        const items = from(action.payload.items).pipe(
          concatMap((item: any, index) => {
            const buyin = item.buyin ? { ...item.buyin } : {};
            const addItem$ = this.apiService.addItemToOrder({
              orderNumber: action.payload.orderNumber,
              itemNumber: item.itemNumber,
              quantity: item.quantity,
              overridePriceYN: item.price ? 'Y' : 'N',
              useEPCYN: action.payload.catalogueEnabledYN,
              fetchInventoryYN: 'Y',
              enforceItemAssociationsYN: action.payload.enforceItemRule
                ? 'Y'
                : 'N',
              unitPriceInc: item.price ? roundingNumber(item.price, 4) : '0',
              unitPriceEx: item.price
                ? roundingNumber(
                    item.price / (1 + action.payload.customerTaxRate / 100),
                    4
                  )
                : '0',
              reasonCode: action.payload.reasonCode,
              sourceSystem: 'OOE',
              changeLineStatus: item.changeLineStatus || '',
              itemDescription: item.itemDescription,
              ...buyin,
            });
            // adding 1 item popup toastr error
            // adding multiple items, popup dialog show list of errors
            return numberOfItemsToAdd === 1
              ? addItem$
              : addItem$.pipe(
                  catchError((error) => {
                    errorItems.push({
                      row: index + 1,
                      itemNumber: item.itemNumber,
                      message: error?.ErrorMessage,
                    });
                    return of({});
                  })
                );
          })
        );
        return items.pipe(
          mergeMap((res) => {
            if (res['ErrorFlag'] === '0') {
              addedItem++;
              // reload grid when all lines are added
              if (addedItem === numberOfItemsToAdd) {
                if (errorItems.length > 0) {
                  let message = '';
                  errorItems.forEach(
                    (item) =>
                      (message += `Row: ${item.row}, item: ${item.itemNumber}, message: ${item.message} <br/>`)
                  );
                  this.dialogService.alert(
                    message,
                    'ADD ITEM ERRORS',
                    'OK',
                    true
                  );
                }
                return [
                  new AddItemProcessed(`${addedItem}/${numberOfItemsToAdd}`),
                  new AddItemsToOrderSuccess(res['SearchResults']),
                  new LoadStagingOrder({
                    orderNumber: action.payload.orderNumber,
                  }),
                ];
              }
              return of(
                new AddItemProcessed(`${addedItem}/${numberOfItemsToAdd}`)
              );
            } else {
              this.toastr.error(res['ErrorMessage']);
              return of(
                new AddItemsToOrderFailed({
                  message: res['ErrorMessage'],
                  itemNumber: action.payload?.items[0]?.itemNumber,
                })
              );
            }
          })
        );
      },
      onError: (action: AddItemsToOrder, error) => {
        this.toastr.error(error?.ErrorMessage);
        return new AddItemsToOrderFailed({ message: error });
      },
    }
  );

  reloadOrderDetails(
    newOrderNumber,
    isShowBuyinPopup: boolean
  ) {

    if (isShowBuyinPopup) {
      this.router.navigateByUrl(
        `/pos/orders/${newOrderNumber}?continueUrl=home&showConvertQuoteMessage=true&showConvertBuyInPopup=true`
      )
      return
    }
  
    this.router.navigateByUrl(
      `/pos/orders/${newOrderNumber}?continueUrl=home&showConvertQuoteMessage=true`
    );
  }

  @Effect() loadParkedOrdersList$ = this.dataPersistence.fetch(
    OrdersActionTypes.LOAD_PARKED_ORDERS_LIST,
    {
      run: (action: LoadParkedOrdersList, state: OrdersPartialState) => {
        return this.apiService.fetchParkedOrderList().pipe(
          switchMap((result) => {
            this.messageService.dispatchAction(
              parkedOrderListLength({
                parkedOrderListLength: result.parkOrderList.length,
              })
            );
            return of(new LoadParkedOrdersListSuccess(result));
          })
        );
      },
      onError: (action: LoadParkedOrdersList, error) => {
        this.toastr.error(error?.ErrorMessage);
        return new LoadParkedOrdersListFailed(error);
      },
    }
  );

  @Effect() loadParkedOrder$ = this.dataPersistence.fetch(
    OrdersActionTypes.LOAD_PARKED_ORDER,
    {
      run: (action: LoadParkedOrder, state: OrdersPartialState) => {
        return this.apiService.loadParkedOrder(action.payLoad).pipe(
          map((response) => {
            if (response) {
              this.toastr.success(
                `${action.payLoad.orderNumber} has been loaded successfully`
              );
              /* TODO: use router instead  window.location */
              this.router.navigateByUrl(
                `/pos/orders/${action.payLoad.orderNumber}?continueUrl=home`
              );
              // window.location.href = `/pos/orders/${action.payLoad.orderNumber}?continueUrl=home`;
            }

            return new LoadParkedOrderSuccess();
          })
        );
      },
      onError: (action: LoadParkedOrder, error) => {
        this.toastr.error(error?.ErrorMessage);
        return new LoadParkedOrderFailed(error);
      },
    }
  );

  constructor(
    private dataPersistence: DataPersistence<OrdersPartialState>,
    private apiService: ApiService,
    private toastr: ToastrService,
    private messageService: MessageService,
    private router: Router,
    private dialogService: DialogService,
    private secureCatalogueService: SecureCatalogueService,
    private drawerService: DrawerService,
    private coreUiStore: Store<CoreUiPartialState>,
    private orderStore: Store<OrdersState>
  ) {}

  getQuickSaveAllowedBasedOnOrderType(orderType) {
    return (
      orderType !== ORDER_TYPE.creditPrice &&
      orderType !== ORDER_TYPE.creditReturn
    );
  }
}
