import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Branch, NonCorpStore } from '@pos-app/data';
import { ShowUser } from '../../../manageusers/+state/user.model';

@Component({
  selector: 'app-orders-override-show',
  templateUrl: './orders-override-show.component.html',
  styleUrls: ['./orders-override-show.component.scss']
})
export class OrdersOverrideShowComponent implements OnInit {
  @Input() branchList: Branch[];
  @Input() nonCorpStoreList: NonCorpStore[];
  @Input() nonCorpStoreSale: boolean;
  @Input() showBranch: string;
  @Input() shipTo: string;
  @Input() orderBranch: string;
  @Input() showUsers: ShowUser[];
  @Input() salesPerson: string;
  @Output() selectForShow = new EventEmitter<any>();
  form: FormGroup;
  iniBranch: string;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      overrideBranch: [null],
      overrideShipTo: [null],
      overrideSalesPerson: [null, Validators.required]
    });
  }

  ngOnInit() {
    this.iniBranch = this.nonCorpStoreSale ? this.showBranch : this.orderBranch;
    this.form.patchValue({
      overrideBranch: this.iniBranch,
      overrideShipTo: this.nonCorpStoreSale ? this.shipTo : null,
      overrideSalesPerson:
        this.salesPerson && this.salesPerson.includes('~')
          ? this.salesPerson.split('~')[0]
          : null
    });
  }

  onSelect() {
    const selectedPerson = this.showUsers.find(
      person => person.name === this.form.controls.overrideSalesPerson.value
    );
    const overridenPerson = `${selectedPerson.name}~${selectedPerson.organisationsCode}`;
    const overrideShipToNumber = this.form.controls.overrideShipTo.value;
    this.selectForShow.emit({
      overrideBranch:
        this.form.controls.overrideBranch.value !== this.iniBranch
          ? this.form.controls.overrideBranch.value
          : null,
      overrideShipTo:
        this.nonCorpStoreSale && overrideShipToNumber !== this.shipTo
          ? overrideShipToNumber
          : null,
      overrideSalesPerson:
        overridenPerson !== this.salesPerson ? overridenPerson : null,
      nonCorpStoreSaleYN: this.nonCorpStoreSale ? 'Y' : 'N'
    });
  }

  onCancel() {
    this.selectForShow.emit(null);
  }

  switchShowOrder() {
    this.nonCorpStoreSale = !this.nonCorpStoreSale;
    // Enforce store selection when it's non-corp sale
    this.nonCorpStoreSale
      ? this.form.controls.overrideShipTo.setValidators(Validators.required)
      : this.form.controls.overrideShipTo.clearValidators();
    this.form.controls.overrideShipTo.updateValueAndValidity();

    this.form.patchValue({
      overrideBranch: this.nonCorpStoreSale
        ? this.showBranch
        : this.orderBranch,
      overrideShipTo: null
    });
  }
}
