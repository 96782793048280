import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import {
  allowFakeEFTPOSTF,
  ApiService,
  ConfigService,
  CoreUiPartialState,
  DrawerService,
  getBranch,
  getCashDrawer,
  getUserId,
  MessageService,
  setCashDrawer,
} from '@pos-app/core-ui';
import { Branch, Register, RegisterHost } from '@pos-app/data';
import { combineLatest, forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { LoadParkedOrdersList } from '../orders/+state/orders.actions';
import { OrdersPartialState } from '../orders/+state/orders.reducer';

@Component({
  selector: 'app-register-admin',
  templateUrl: './register-admin.component.html',
  styleUrls: ['./register-admin.component.scss'],
})
export class RegisterAdminComponent implements OnInit, OnDestroy {
  @ViewChild('content') modalBox: any;
  @ViewChild('registerPinpad') pinpadRegistration: any;
  hostName: string;
  registerOpened: boolean;
  registerList: Register[] = [];
  unSubscribe$ = new Subject<void>();
  isBusy: boolean;
  showBranchRegister = true;
  isCashDrawerAttached: boolean;
  assignedRegister: RegisterHost = null;
  currentUserBranch: Branch;
  currentUserId: string;
  currentTime: string;
  currentTimeToSend: string;
  cashFloat: number;
  pairCode: string;
  eftposDeviceName: string;
  fakeEFTPOSTF: boolean;

  allowFakeEFTPOSTF$ = this.store.select(allowFakeEFTPOSTF)

  constructor(
    private store: Store<CoreUiPartialState>,
    private orderStore: Store<OrdersPartialState>,
    private apiService: ApiService,
    private configService: ConfigService,
    private toastr: ToastrService,
    private drawerService: DrawerService,
    private messageService: MessageService,
    public modalService: NgbModal
  ) {}

  ngOnInit() {
    this.isCashDrawerAttached = this.configService.AppConfig.isCashDrawerAttached;

    this.getCashDrawer();

    this.getUserId();

    this.getBranch();

    this.refetchRegister(of([]));

    this.dispatchParkedOrdersList();
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  manageRegister() {
    this.modalService.dismissAll();
    const process = this.apiService
      .manageRegister({
        register: this.assignedRegister.registerID,
        cashFloat: this.cashFloat,
        dateTime: this.currentTimeToSend,
        actionCode: this.registerOpened ? 'C' : 'O',
        device: this.isCashDrawerAttached ? this.hostName : this.currentUserId,
      })
      .pipe(
        tap(() => {
          this.eftposDeviceName = null;
          this.toastr.success(`Register ${this.registerOpened ? 'closed' : 'opened'} successfully`);
        })
      );

    this.cashFloat = null;
    this.refetchRegister(process);
  }

  assignRegister(selectedRegister) {
    if (selectedRegister) {
      this.isBusy = true;
      const process = this.apiService
        .setRegisterForHost({
          host: this.hostName,
          register: selectedRegister,
        })
        .pipe(tap(() => this.toastr.success(`Register assigned to host successfully`)));
      this.refetchRegister(process);
    }
  }

  refetchRegister(process: Observable<any>) {
    process
      .pipe(
        switchMap((res) => {
          if (res) {
            this.modalService.dismissAll();
            return this.apiService.getRegisterForHost({ host: this.hostName });
          }
          return of(null);
        }),
        finalize(() => (this.isBusy = false)),
        takeUntil(this.unSubscribe$)
      )
      .subscribe((res) => {
        if (res?.ErrorFlag === '0' && res?.SearchResults?.length > 0) {
          this.messageService.dispatchAction(setCashDrawer({ cashDrawer: JSON.stringify(res.SearchResults[0]) }));
        }
      });
  }

  getRegisterList() {
    return !this.registerList || !this.currentUserBranch
      ? []
      : !this.showBranchRegister
      ? this.registerList
      : this.registerList.filter((x) => x.branch === this.currentUserBranch.BranchCode);
  }

  getLinkedEftpos(registerId) {
    const selectedRegister = this.registerList.find((item) => item.registerID === registerId);
    return selectedRegister ? selectedRegister.eftposDevices.map((item) => item.eftposDeviceName) : [];
  }

  openModal() {
    const now = moment();
    this.currentTime = now.format('DD/MM/YYYY HH:mm');
    // 24/11/20 14:01:01 PM UTC+11:00
    const utcOffset = now.utcOffset() / 60;
    this.currentTimeToSend = `${now.format('DD/MM/YY hh:mm:ss A')} UTC${utcOffset < 0 ? '-' : '+'}${utcOffset}:00`;
    this.modalService.open(this.modalBox);
    this.popCashDrawer();
  }

  popCashDrawer() {
    this.drawerService.openCashdrawer();
  }

  assignEftpos() {
    this.isBusy = true;
    const process = this.apiService
      .assignEFTPOSDeviceToHost({
        host: this.hostName,
        register: this.assignedRegister.registerID,
        eftposDeviceName: this.eftposDeviceName || '',
        fakeEFTPOSTF: this.eftposDeviceName ? this.fakeEFTPOSTF : false
      })
      .pipe(
        catchError((error) => {
          this.isBusy = false;
          if (error?.ErrorStatus === '500') {
            // Error assigning Eftpos due to invalid secret
            // Popup to input pinpad paircode
            this.modalService
              .open(this.pinpadRegistration, {
                backdrop: 'static',
              })
              .result.then(
                () => {},
                () => {
                  this.eftposDeviceName = null;
                }
              );
          } else {
            this.toastr.error(error?.ErrorMessage);
            this.eftposDeviceName = null;
          }

          return of(null);
        })
      );
    this.refetchRegister(process);
  }

  onRegisterPinpad() {
    // Call register pinpad API
    this.isBusy = true;
    this.apiService
      .registerEftpos({
        eftposDeviceName: this.eftposDeviceName,
        pairCode: this.pairCode,
      })
      .pipe(finalize(() => (this.isBusy = false)))
      .subscribe(
        (res) => {
          this.assignEftpos();
        },
        (error) => {
          this.toastr.error('Error registering the pinpad');
        }
      );
  }

  pinpadRePair() {
    this.modalService.open(this.pinpadRegistration, {
      backdrop: 'static',
    });
  }

  reprintReceipt() {
    this.isBusy = true;
    this.apiService
      .reprintReceipt({ eftposDeviceName: this.eftposDeviceName })
      .pipe(
        finalize(() => (this.isBusy = false)),
        takeUntil(this.unSubscribe$)
      )
      .subscribe({
        next: () => {
          this.toastr.success('Receipt has been reprinted again');
        },
        error: (error) => {
          this.toastr.error(error);
        },
      });
  }

  getCashDrawer() {
    this.isBusy = true;
    this.store
      .select(getCashDrawer)
      .pipe(
        distinctUntilChanged(),
        finalize(() => {
          this.isBusy = false;
        }),
        switchMap((cashDrawer) => {
          return forkJoin([of(cashDrawer), this.apiService.getRegisterList()]);
        }),
        takeUntil(this.unSubscribe$)
      )
      .subscribe(([cashDrawer, registerListRes]) => {
        this.registerList = registerListRes.SearchResults;
        if (cashDrawer) {
          this.assignedRegister = JSON.parse(cashDrawer);
          
          let eftposList = [];
          this.registerOpened = this.assignedRegister.status === 'O';
          this.fakeEFTPOSTF = this.assignedRegister.fakeEFTPOSTF;
          this.eftposDeviceName = this.assignedRegister.eftposDeviceName?.trim() || null;
          eftposList = this.getLinkedEftpos(this.assignedRegister.registerID);
          if (eftposList.length === 1 && !this.eftposDeviceName && this.registerOpened) {
            // Auto assign the EFTPOS
            this.eftposDeviceName = eftposList[0] || null;
            this.assignEftpos();
            // if it's open and no defined EFTPOS, allow the EFTPOS assignment
          }
        }
      });
  }

  getUserId() {
    this.store
      .select(getUserId)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((userId) => {
        this.isBusy = true;
        this.currentUserId = userId;
        if (this.configService.AppConfig.isElectron) {
          this.drawerService.cashdrawerAvailability$.pipe(takeUntil(this.unSubscribe$)).subscribe((res) => {
            this.hostName = res.hostName ? res.hostName : '';
          });
        } else {
          this.hostName = this.configService.AppConfig.hostName ? this.configService.AppConfig.hostName : userId;
        }
        this.isBusy = false;
      });
  }

  getBranch() {
    this.store
      .select(getBranch)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((branch) => {
        this.currentUserBranch = branch;
      });
  }

  dispatchParkedOrdersList() {
    this.orderStore.dispatch(new LoadParkedOrdersList());
  }
}
