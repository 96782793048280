<pos-app-spinner [show]="isUpdatingCustomerVehicle$ | async"></pos-app-spinner>
<app-customers-vehicles-details-form
  [selectedCustomerVehicle]="selectedCustomerVehicle$ | async"
  (formChanged)="formValue = $event"
  (formIsValid)="formValid = $event"
  (formIsDirty)="formDirtyHandler($event)"
  (submitByEnter)="formValid && updateCustomerVehicle()"
  [filteredStateList] ="stateList$ | async"
  [lookupLists] = "lookupLists$ | async"
  (selectedCountry) = "getFilteredStateList($event)"
></app-customers-vehicles-details-form>

<div class="pt-3 pb-5 text-right">
    <button class="btn text-white" [disabled]="!formValid" (click)="updateCustomerVehicle()">
      SAVE
    </button>
    <span class="px-2"></span>
    <button class="btn btn-cancel" (click)="cancel()">
      CANCEL
    </button>
</div>
