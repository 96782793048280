import {
  ColDef,
  GridApi,
  GridOptions,
} from 'ag-grid-community';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnInit,
  Output,
} from '@angular/core';
import { ApiService } from '@pos-app/core-ui';
import { OrderType, RecentOrderUser } from '@pos-app/data';
import moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OrderSearchGridActionRendererComponent } from '../../../orders/components/orderSearchGridActionRenderer/orderSearchGridActionRender.component';

@Component({
  selector: 'app-customers-recent-orders',
  templateUrl: './customers-recent-orders.component.html',
  styleUrls: ['./customers-recent-orders.component.scss'],
})
export class CustomersRecentOrdersComponent implements OnInit {
  @Input() userID: string;
  @Input() isExternal: boolean;
  @Input() validOrderTypes: OrderType[];
  @Output() onFetchOrderFromJDE = new EventEmitter();

  frameworkComponents;
  context;
  gridOptions: GridOptions;
  columnDefs: ColDef[];
  loading: boolean;
  rowData = [];
  private unSubscribe$ = new Subject<void>();
  public isBusy = false;
  public params: any;
  public staticFilterValues = null;
  public customerOrderDetails = [];
  public userList: RecentOrderUser[];
  public selectedUser: string;
  private gridApi: GridApi;

  public loading$ = new BehaviorSubject<boolean>(false);
  public curret;
  constructor(
    private apiService: ApiService,
    @Inject(LOCALE_ID) public locale: string
  ) {
    this.frameworkComponents = {
      actionRenderer: OrderSearchGridActionRendererComponent,
    };
    this.context = { componentParent: this };
    this.gridOptions = {
      defaultColDef: {
        resizable: true,
        sortable: true,
        width: 150,
        cellClass: 'cell-class ag-right-aligned-cell',
        headerClass: 'header-class ag-left-aligned-header',
      },
      columnDefs: [
        {
          headerName: 'ACTION',
          width: 100,
          cellRenderer: 'actionRenderer',
          cellStyle: { 'text-align': 'center' },
          cellRendererParams: {
            showFetchOrderIconTF: true
          }
        },
        {
          headerName: 'ORDER',
          width: 100,
          field: 'orderNumber',
          cellStyle: { 'text-align': 'right' },
        },
        {
          headerName: 'TYPE',
          width: 80,
          field: 'orderType',
          cellStyle: { 'text-align': 'center' },
        },
        {
          headerName: 'CUSTOMER NO',
          width: 150,
          field: 'customerNumber',
          cellStyle: { 'text-align': 'right' },
        },
        {
          headerName: 'CUSTOMER NAME',
          width: 200,
          field: 'customerName',
          cellStyle: { 'text-align': 'left' },
        },
        {
          headerName: 'CUSTOMER REFERENCE',
          width: 220,
          field: 'customerPO',
          cellStyle: { 'text-align': 'left' },
        },
        {
          headerName: 'DATE TIME',
          width: 180,
          field: 'dateTime',
          valueFormatter: this.dateFormatter,
          cellStyle: { 'text-align': 'left' },
        },
      ],
    };
  }

  dateFormatter(params) {
    //moment(params.value).format('DD/MM/YYYY <HH:mm>');
    //Date formate on Browser Locale
    return moment(params.value).format('L <HH:mm>');
    // //TODO: Workaround for dateformat server response ISO format i.e. 2022-09-28T07:35:16.199Z, change to dateFormat used in orders-result-grid-modes.ts
    // let date = params.split('T')[0].split('-');
    // return getFullYearLocaleDate(this.locale, {
    //   year: date[0],
    //   monthIndex: date[1] - 1, // 0 - 11 is for Jan - Dec Url: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date
    //   day: date[2],
    // });
  }

  ngOnInit() {
    this.selectedUser = this.userID;
    this.getUsersForRecentOrders(this.selectedUser);
  }

  getUsersForRecentOrders(selectedUser) {
    this.apiService
      .getUsersForRecentOrders()
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((res) => {
        this.userList = res.SearchResults;
        this.loadRecentOrders(selectedUser);
      });
  }

  loadRecentOrders(userID: string) {
    this.apiService
      .fetchRecentOrders({ inputUserEmail: userID })
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe({
        next: (res) => {
          this.rowData = res.recentOrderList.filter(
            (order) =>
              this.validOrderTypes.findIndex(
                (item) => item.OrderTypeCode === order.orderType
              ) !== -1
          );
        },
        error: (error) => {},
        complete: () => {},
      });

    if (!!this.rowData) {
      this.gridApi.setRowData(this.rowData);
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  fetchOrderFromJDE(data) {
    this.onFetchOrderFromJDE.emit({
      orderNumber: data.data.orderNumber,
      orderType: data.data.orderType,
    });
  }
}
