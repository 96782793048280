import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ProductRichContentDto } from '../../models/service-proxies';
import { remove } from 'lodash';
import { GalleryItem, Gallery, ImageItem } from '@ngx-gallery/core';
import { Lightbox } from '@ngx-gallery/lightbox';

@Component({
  selector: 'app-product-product-images',
  templateUrl: './product-images.component.html',
  styleUrls: ['./product-images.component.scss'],
})
export class ProductProductImagesComponent implements OnInit {
  @ViewChild('carousel', { static: false }) public productImages: any;
  @Input() public images: ProductRichContentDto[] = [];

  public showNavigationArrows = false;
  public showNavigationIndicators = false;
  public defaultImageUrl = '/assets/img/ARB_logo.png';
  public playVideoImageUrl: string = 'assets/img/play-video.png';
  public items: GalleryItem[];
  public galleryId = 'galleryId';

  public playerVars = {
    cc_lang_pref: 'en',
  };

  public get canNext(): boolean {
    return this.images && this.displayImageCnt() > 1;
  }

  public get canPrev(): boolean {
    return this.images && this.displayImageCnt() > 1;
  }
  private player;

  constructor(public gallery: Gallery, private lightbox: Lightbox) {}

  public getYoutubeVideoId(url): string {
    var videoid = url.match(
      /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/
    );
    if (videoid != null) {
      return videoid[1];
    } else {
      console.error('The youtube url is not valid.');
      console.error(url);
      return '';
    }
  }

  public savePlayer(player): void {
    this.player = player;
    if (window.innerWidth <= 1084) {
      this.player.setSize(400, 225);
    } else {
      this.player.setSize(500, 280);
    }
  }

  public playVideo(): void {
    this.player.playVideo();
  }

  public pauseVideo(): void {
    this.player.pauseVideo();
  }

  public ngOnInit(): void {
    this.images = this.images.filter((img) => img.type == 1 || img.type == 2);
    this.updateGalleryImages();
  }

  public ngOnChanges(): void {
    // check the object "changes" for new data
    this.updateGalleryImages();
  }

  public updateGalleryImages(): void {
    // Only get image type === 1(jpg, png)
    this.items = this.images.reduce((filtered, option) => {
      if (option.type === 1) {
        filtered.push(
          new ImageItem({
            src: option.contentUrl,
            thumb: option.contentUrlThumb,
            name: option.fileName,
          })
        );
      }
      return filtered;
    }, []);

    const galleryRef = this.gallery.ref(this.galleryId);
    galleryRef.reset();
    galleryRef.load(this.items);
  }

  public next(): void {
    this.productImages.next();
  }

  public prev(): void {
    this.productImages.prev();
  }

  public displayImageCnt(): number {
    return this.images.filter((img) => img.type == 1 || img.type == 2).length;
  }

  public errorImageLoad(url): void {
    // Not Remove one which has only 1 image in slide
    this.images.forEach((item) => {
      if (item.contentUrl === url) {
        item.type = undefined;
      }
    });

    if (this.images.length > 1) {
      remove(this.images, (item) => {
        return item.contentUrl === url;
      });
    }

    this.updateGalleryImages();
  }

  public openImage(index: number): void {
    if (this.images[index].type != undefined) {
      this.lightbox.open(index, this.galleryId, {});
    }
  }
}
