import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { debounce } from 'lodash';
import { finalize, takeUntil, take } from 'rxjs/operators';
import {
  ProductSearchInputDto,
  BasePagingOutputDtoOfProductSearchOutputDto,
  BasePagingOutputDtoOfCategorySearchOutputDto,
  Paging,
  ProductSearchOutputDto,
} from '../../models/service-proxies';
import { Router } from '@angular/router';
import {
  getStateSelectedVehicle,
  getStateUsedAsFilter,
  getStateShowedUniversalProducts,
} from '../../+store/vehicles';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { EpcApiService } from '../../services/epc-api.service';
import { AppConsts } from '../../constants/AppConsts';
import { Vehicle } from '../../models/vehicle-data';
import { IMainState } from '../../+store';
import { OOESessionService } from 'libs/core-ui/src/lib/services/ooe-session.service';

@Component({
  selector: 'app-top-search',
  templateUrl: './top-search.component.html',
  styleUrls: ['./top-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TopSearchComponent implements OnInit, OnDestroy {
  public searchText: string;
  public productResult = new BasePagingOutputDtoOfProductSearchOutputDto();
  public categoryResult = new BasePagingOutputDtoOfCategorySearchOutputDto();
  public doProductSearchDebouce: any;
  public isProductSearching: boolean = false;
  public isBrandSearching: boolean = false;
  public isCategorySearching: boolean = false;
  public isCedSearching: boolean = false;
  public isSearching: boolean = true;
  public searchTimeout: any;
  public found: boolean = true;
  public latestEventKey: any;
  public userPickedResultItem: boolean = false;
  public vehicleId: number;
  public showedUniversalProduct: boolean;
  public searchSparePart: boolean = false;

  private componentDestroyed$ = new Subject<void>();

  constructor(
    private epcApiService: EpcApiService,
    private store: Store<IMainState>,
    private router: Router,
    private ooeSessionService: OOESessionService
  ) {}

  public ngOnInit(): void {
    this.doProductSearchDebouce = debounce(this.doProductSearch, 500);
    this.store
      .select(getStateSelectedVehicle)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((selectedVehicle: Vehicle) => {
        if (selectedVehicle != null) {
          this.store
            .select(getStateUsedAsFilter)
            .pipe(take(1))
            .subscribe((useVehicleInFilter) => {
              if (useVehicleInFilter) {
                this.vehicleId = Number(selectedVehicle.partsVehicleID);
              } else {
                this.vehicleId = null;
              }
            });
          this.store
            .select(getStateShowedUniversalProducts)
            .pipe(take(1))
            .subscribe((showedUniversalProduct) => {
              this.showedUniversalProduct = showedUniversalProduct;
            });
        } else {
          this.vehicleId = null;
        }
      });

    this.store
      .select(getStateUsedAsFilter)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((useVehicleInFilter) => {
        if (useVehicleInFilter) {
          this.store
            .select(getStateSelectedVehicle)
            .pipe(take(1))
            .subscribe((selectedVehicle: Vehicle) => {
              if (selectedVehicle) {
                this.vehicleId = Number(selectedVehicle.partsVehicleID);
              } else {
                this.vehicleId = null;
              }
            });
        } else {
          this.vehicleId = null;
        }
      });
  }

  public ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  public onProducSearchKeydown(event): void {
    this.found = true;
    this.latestEventKey = event.key;
    this.isSearching = true;
    this.searchTimeout = setTimeout(() => {
      this.doProductSearchDebouce();
    }, 500);
  }

  public doProductSearch(): void {
    if (this.searchText && this.searchText.trim()) {
      if (this.latestEventKey === 'Enter') {
        clearTimeout(this.searchTimeout);
        this.userPickedResultItem = true;
      } else {
        this.userPickedResultItem = false;
      }

      this.search(0, 10, (input) => this.getAll(input));
    } else {
      this.resetSearch();
    }
  }

  public getProducts(input: ProductSearchInputDto): void {
    this.isProductSearching = true;
    this.epcApiService
      .searchProduct(input)
      .pipe(
        takeUntil(this.componentDestroyed$),
        finalize(() => {
          this.isProductSearching = false;
        })
      )
      .subscribe((result: BasePagingOutputDtoOfProductSearchOutputDto) => {
        this.productResult = result;
      });
  }

  public getCategories(input: ProductSearchInputDto): void {
    this.isCategorySearching = true;
    this.epcApiService
      .searchCategory(input)
      .pipe(
        takeUntil(this.componentDestroyed$),
        finalize(() => {
          this.isCategorySearching = false;
        })
      )
      .subscribe((result: BasePagingOutputDtoOfCategorySearchOutputDto) => {
        this.categoryResult = result;
      });
  }

  public search(currentPage: number, pageSize: number, searchFn: any): void {
    let input = new ProductSearchInputDto();
    input.searchText = this.searchText;
    input.brand = AppConsts.brand;
    input.priceList = this.ooeSessionService.priceLists;
    input.paging = new Paging();
    input.paging.currentPage = currentPage + 1;
    input.paging.pageSize = pageSize;
    input.searchSparePart = this.searchSparePart;
    if (this.vehicleId != null && this.vehicleId != 0) {
      input.vehicleId = this.vehicleId;
    }

    searchFn(input);
  }

  public getAll(input: ProductSearchInputDto): void {
    this.isSearching = true;
    this.epcApiService
      .searchAll(input)
      .pipe(
        takeUntil(this.componentDestroyed$),
        finalize(() => {
          this.isSearching = false;
        })
      )
      .subscribe((result) => {
        this.found = result.found;
        this.productResult = result.products;
        this.categoryResult = result.categories;
        if (
          this.userPickedResultItem &&
          (this.productResult.records.length == 1 ||
            this.productResult.records.find(
              (pr) =>
                pr.companySKU.toUpperCase() == this.searchText.toUpperCase()
            ))
        ) {
          var product =
            this.productResult.records.length == 1
              ? this.productResult.records[0]
              : this.productResult.records.find(
                  (pr) =>
                    pr.companySKU.toUpperCase() == this.searchText.toUpperCase()
                );
          this.resetSearch();
          this.router.navigate([
            'epc/product/' + encodeURIComponent(product.companySKU),
          ]);
        } else {
          this.userPickedResultItem = false;
        }
      });
  }

  public paginateProduct(event): void {
    this.search(event.page, event.rows, (input) => this.getProducts(input));
  }

  public paginateCategory(event): void {
    this.search(event.page, event.rows, (input) => this.getCategories(input));
  }

  public searchProduct(companySKU: string): void {
    this.resetSearch();
    this.router.navigate(['epc/product/' + encodeURIComponent(companySKU)]);
  }

  public searchCategory(cedCategory): void {
    this.resetSearch();
    if (cedCategory.parentCEDCategoryId) {
      this.router.navigate(['epc/category/' + cedCategory.cedCategoryId]);
    } else {
      this.router.navigate(['epc'], {
        queryParams: { id: cedCategory.cedCategoryId },
      });
    }
  }

  public getProductSearchHeader(): string {
    if (this.vehicleId) {
      return 'Products Filtered by Vehicle Selected';
    } else {
      return 'Product Result';
    }
  }

  public resetSearch(): void {
    this.searchText = null;

    this.productResult.records = [];
    this.productResult.totalRecord = 0;

    this.categoryResult.totalRecord = 0;
    this.categoryResult.records = [];

    this.userPickedResultItem = false;

    this.isSearching = false;
    this.isCategorySearching = false;
    this.isProductSearching = false;
  }

  public getProductResultText(product: ProductSearchOutputDto): string {
    if (product.productNumber && product.companyProductTitle) {
      return product.productNumber + ', ' + product.companyProductTitle;
    }

    return product.productNumber;
  }

  public foundByOE(product: ProductSearchOutputDto): boolean {
    return (
      product.referenceNumber &&
      this.matchSearchText(product.referenceNumber)
    );
  }

  public foundByTrade(product: ProductSearchOutputDto): boolean {
    return (
      product.tradeNumber &&
      this.matchSearchText(product.tradeNumber)
    );
  }

  public foundByMetaTag(product: ProductSearchOutputDto): boolean {
    return (
      product.metaKeyWords &&
      this.matchSearchText(product.metaKeyWords)
    );
  }

  public matchSearchText(str): boolean {
    return (
      !!str && str.toUpperCase().indexOf(this.searchText.toUpperCase()) != -1
    );
  }
}
