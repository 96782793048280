import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Customer } from '@pos-app/data';
import { CoreUiPartialState, clearSearchData } from '@pos-app/core-ui';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-customers-search',
  templateUrl: './customers-search.component.html',
  styleUrls: ['./customers-search.component.scss'],
})
export class CustomersSearchComponent implements OnInit {
  @Input() public displayCustomerDetails: boolean = false;
  @Output() public displaySelectedCustomer = new EventEmitter<Customer>();

  constructor(private router: Router, private coreStore: Store<CoreUiPartialState>) {}

  public ngOnInit(): void {
    this.coreStore.dispatch(clearSearchData());
  }

  public selectCustomer(customer: Customer): void {
    if (customer) {
      if (!this.displayCustomerDetails) {
        this.router.navigate(['pos/customers/' + customer.CustomerNumber + '/details']);
      } else {
        this.displaySelectedCustomer.emit(customer);
      }
    }
  }
}
