import { CustomersAction, CustomersActionTypes } from './customers.actions';
import {
  Customer,
  CustomerDetails,
  LookupLists,
  CustomerVehicle,
  CustomerSales,
  Statement,
  Invoice,
  ShowSale,
  TasksNotes,
  CustomerAlertMessage,
  UserState,
} from '@pos-app/data';

export const CUSTOMERS_FEATURE_KEY = 'customers';

/**
 * Interface for the 'Customers' data used in
 *  - CustomersState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

export interface CustomersState {
  selectedCustomer?: CustomerDetails;
  customerSales?: CustomerSales;
  loadingCustomerDetails?: boolean;
  customerStatements?: Statement[];
  loadingCustomerStatements?: boolean;
  customerInvoices?: Invoice[];
  loadingCustomerInvoices?: boolean;
  customerShowSales?: ShowSale[];
  loadingCustomerShowSales?: boolean;
  customers: Customer[];
  hasMoreRecords: boolean;
  searchingCustomers?: boolean;
  lookupLists: LookupLists;
  loadingLookupLists: boolean;
  cachedLookupLists?: LookupLists;
  isCreatingCustomer: boolean;
  createdCustomerId?: string;
  customerVehicles: CustomerVehicle[];
  loadingCustomerVehicles?: boolean;
  updatingCustomerVehicle?: boolean;
  selectedCustomerVehicleID?: string;
  duplicateCustomers: Customer[];
  updatingCustomer?: boolean;
  selectedCustomerVehicle?: CustomerVehicle;
  error?: any;
  selectedCountryCode: string;
  tasksNotesList: TasksNotes[];
  loadingTasksNotesList?: boolean;
  customerAlertMessage: CustomerAlertMessage;
  loadingAlertMessage?: boolean;
  loadingCompleteTask?: boolean;
  userState?: UserState;
  loadingUserState?: boolean;
}

export interface CustomersPartialState {
  readonly [CUSTOMERS_FEATURE_KEY]: CustomersState;
}

export const initialState: CustomersState = {
  lookupLists: null,
  customers: [],
  duplicateCustomers: null,
  loadingLookupLists: false,
  selectedCustomer: null,
  isCreatingCustomer: false,
  customerVehicles: [],
  hasMoreRecords: false,
  selectedCountryCode: '',
  tasksNotesList: [],
  customerAlertMessage: null,
  userState: null,
  loadingUserState: false
};

export function reducer(
  state: CustomersState = initialState,
  action: CustomersAction
): CustomersState {
  switch (action.type) {
    case CustomersActionTypes.SELECT_COUNTRY: {
      return {
        ...state,
        selectedCountryCode: action.payload,
      };
    }

    case CustomersActionTypes.CLEAR_DATA: {
      return {
        ...state,
        selectedCustomer: null,
        selectedCustomerVehicle: null,
        selectedCustomerVehicleID: null,
      };
    }

    case CustomersActionTypes.SELECT_CUSTOMER_VEHICLE: {
      return {
        ...state,
        selectedCustomerVehicle: state.customerVehicles.find(
          (vehicle) => vehicle.customerVehicleID === action.payload
        ),
      };
    }

    case CustomersActionTypes.CHECK_DUPLICATE_CUSTOMER: {
      return { ...state, searchingCustomers: true, duplicateCustomers: null };
    }
    case CustomersActionTypes.CHECK_DUPLICATE_CUSTOMER_FAILED: {
      return { ...state, searchingCustomers: false };
    }
    case CustomersActionTypes.CHECK_DUPLICATE_CUSTOMER_SUCCESS: {
      state = {
        ...state,
        duplicateCustomers: action?.payload?.SearchResults || null,
        searchingCustomers: false,
      };
      break;
    }

    case CustomersActionTypes.LOAD_CUSTOMER_VEHICLES: {
      return { ...state, loadingCustomerVehicles: true };
    }
    case CustomersActionTypes.LOAD_CUSTOMER_VEHICLES_FAILED: {
      return { ...state, loadingCustomerVehicles: false };
    }
    case CustomersActionTypes.LOAD_CUSTOMER_VEHICLES_SUCCESS: {
      state = {
        ...state,
        customerVehicles: action.payload.SearchResults,
        loadingCustomerVehicles: false,
      };
      break;
    }

    case CustomersActionTypes.MAINTAIN_CUSTOMER_VEHICLE: {
      return {
        ...state,
        updatingCustomerVehicle: true,
        selectedCustomerVehicleID: null,
      };
    }
    case CustomersActionTypes.MAINTAIN_CUSTOMER_VEHICLE_FAILED: {
      return { ...state, updatingCustomerVehicle: false };
    }
    case CustomersActionTypes.MAINTAIN_CUSTOMER_VEHICLE_SUCCESS: {
      state = {
        ...state,
        updatingCustomerVehicle: false,
        selectedCustomerVehicleID: action.payload.CustomerVehicleID,
        // start reloading customer vehicles
        loadingCustomerVehicles: true,
      };
      break;
    }

    case CustomersActionTypes.MAKE_VEHICLE_DEFAULT: {
      return {
        ...state,
        updatingCustomerVehicle: true,
        selectedCustomerVehicleID: null,
      };
    }
    case CustomersActionTypes.MAKE_VEHICLE_DEFAULT_FAILED: {
      return { ...state, updatingCustomerVehicle: false };
    }
    case CustomersActionTypes.MAKE_VEHICLE_DEFAULT_SUCCESS: {
      state = {
        ...state,
        updatingCustomerVehicle: false,
        selectedCustomerVehicleID: action.payload.CustomerVehicleID,
        // start reloading customer vehicles
        loadingCustomerVehicles: true,
      };
      break;
    }

    case CustomersActionTypes.CREATE_RETAIL_CUSTOMER: {
      return {
        ...state,
        isCreatingCustomer: true,
        createdCustomerId: null,
      };
    }
    case CustomersActionTypes.CREATE_RETAIL_CUSTOMER_FAILED: {
      return { ...state, isCreatingCustomer: false };
    }
    case CustomersActionTypes.CREATE_RETAIL_CUSTOMER_SUCCESS: {
      state = {
        ...state,
        createdCustomerId: action.payload.CustomerNumber,
        isCreatingCustomer: false,
      };
      break;
    }

    case CustomersActionTypes.UPDATE_RETAIL_CUSTOMER: {
      return {
        ...state,
        updatingCustomer: true,
      };
    }
    case CustomersActionTypes.UPDATE_RETAIL_CUSTOMER_FAILED: {
      return { ...state, updatingCustomer: false };
    }
    case CustomersActionTypes.UPDATE_RETAIL_CUSTOMER_SUCCESS: {
      state = {
        ...state,
        updatingCustomer: false,
      };
      break;
    }

    case CustomersActionTypes.UPDATE_SHIPTO_CUSTOMER: {
      return {
        ...state,
        updatingCustomer: true,
      };
    }
    case CustomersActionTypes.UPDATE_SHIPTO_CUSTOMER_FAILED: {
      return { ...state, updatingCustomer: false };
    }
    case CustomersActionTypes.UPDATE_SHIPTO_CUSTOMER_SUCCESS: {
      state = {
        ...state,
        updatingCustomer: false,
      };
      break;
    }

    case CustomersActionTypes.CREATE_SHIPTO_CUSTOMER: {
      return {
        ...state,
        isCreatingCustomer: true,
        createdCustomerId: null,
      };
    }
    case CustomersActionTypes.CREATE_SHIPTO_CUSTOMER_FAILED: {
      return { ...state, isCreatingCustomer: false };
    }
    case CustomersActionTypes.CREATE_SHIPTO_CUSTOMER_SUCCESS: {
      state = {
        ...state,
        createdCustomerId: action.payload.CustomerNumber,
        isCreatingCustomer: false,
      };
      break;
    }

    case CustomersActionTypes.LOAD_LOOKUP_LISTS: {
      if (state.cachedLookupLists) {
        return { ...state, lookupLists: state.cachedLookupLists };
      }
      return { ...state, loadingLookupLists: true };
    }
    case CustomersActionTypes.LOAD_LOOKUP_LISTS_FAILED: {
      return { ...state, loadingLookupLists: false };
    }
    case CustomersActionTypes.LOAD_LOOKUP_LISTS_SUCCESS: {
      state = {
        ...state,
        lookupLists: action.payload,
        loadingLookupLists: false,
        cachedLookupLists: action.payload,
      };
      break;
    }

    case CustomersActionTypes.SELECT_CUSTOMER: {
      state = {
        ...state,
        loadingCustomerDetails: true,
        createdCustomerId: null,
      };
      break;
    }
    case CustomersActionTypes.SELECT_CUSTOMER_SUCCESS: {
      state = {
        ...state,
        selectedCustomer: action.payload,
        selectedCountryCode: action.payload ? action.payload.CountryCode : null,
        loadingCustomerDetails: false,
      };
      break;
    }
    case CustomersActionTypes.SELECT_CUSTOMER_FAILED: {
      state = {
        ...state,
        loadingCustomerDetails: false,
      };
      break;
    }
    case CustomersActionTypes.SET_SELECTED_CUSTOMER: {
      state = {
        ...state,
        selectedCustomer: action.payload,
        selectedCountryCode: action.payload ? action.payload.CountryCode : null,
      };
      break;
    }

    case CustomersActionTypes.GET_CUSTOMER_SALES: {
      state = {
        ...state,
        customerSales: null,
      };
      break;
    }
    case CustomersActionTypes.GET_CUSTOMER_SALES_SUCCESS: {
      state = {
        ...state,
        customerSales: action.payload.SearchResults[0],
      };
      break;
    }

    case CustomersActionTypes.GET_STATEMENT: {
      state = {
        ...state,
        customerStatements: null,
        loadingCustomerStatements: true,
      };
      break;
    }
    case CustomersActionTypes.GET_STATEMENT_SUCCESS: {
      state = {
        ...state,
        customerStatements: action.payload.SearchResults,
        loadingCustomerStatements: false,
      };
      break;
    }
    case CustomersActionTypes.GET_STATEMENT_FAILED: {
      state = {
        ...state,
        loadingCustomerStatements: false,
      };
      break;
    }

    case CustomersActionTypes.GET_INVOICE: {
      state = {
        ...state,
        customerInvoices: null,
        loadingCustomerInvoices: true,
      };
      break;
    }
    case CustomersActionTypes.GET_INVOICE_SUCCESS: {
      state = {
        ...state,
        customerInvoices: action.payload.SearchResults,
        loadingCustomerInvoices: false,
      };
      break;
    }
    case CustomersActionTypes.GET_INVOICE_FAILED: {
      state = {
        ...state,
        loadingCustomerInvoices: false,
      };
      break;
    }
    case CustomersActionTypes.GET_SHOW_SALES: {
      state = {
        ...state,
        customerShowSales: null,
        loadingCustomerShowSales: true,
      };
      break;
    }
    case CustomersActionTypes.GET_SHOW_SALES_SUCCESS: {
      state = {
        ...state,
        customerShowSales: action.payload.SearchResults,
        loadingCustomerShowSales: false,
      };
      break;
    }
    case CustomersActionTypes.GET_SHOW_SALES_FAILED: {
      state = {
        ...state,
        loadingCustomerShowSales: false,
      };
      break;
    }
    case CustomersActionTypes.LIST_TASKS_NOTES: {
      state = {
        ...state,
        loadingTasksNotesList: true,
      };
      break;
    }
    case CustomersActionTypes.LIST_TASKS_NOTES_SUCCESS: {
      state = {
        ...state,
        tasksNotesList: action.payload.SearchResults,
        loadingTasksNotesList: false,
      };
      break;
    }
    case CustomersActionTypes.LIST_TASKS_NOTES_FAILED: {
      state = {
        ...state,
        loadingTasksNotesList: false,
      };
      break;
    }
    case CustomersActionTypes.UPDATE_ALERT_MESSAGE: {
      state = {
        ...state,
        loadingAlertMessage: true,
      };
      break;
    }
    case CustomersActionTypes.UPDATE_ALERT_MESSAGE_SUCCESS: {
      state = {
        ...state,
        loadingAlertMessage: false,
      };
      break;
    }
    case CustomersActionTypes.UPDATE_ALERT_MESSAGE_FAILED: {
      state = {
        ...state,
        loadingAlertMessage: false,
      };
      break;
    }
    case CustomersActionTypes.GET_ALERT_MESSAGE: {
      state = {
        ...state,
        loadingAlertMessage: true,
      };
      break;
    }
    case CustomersActionTypes.GET_ALERT_MESSAGE_SUCCESS: {
      state = {
        ...state,
        customerAlertMessage: action.payload,
        loadingAlertMessage: false,
      };
      break;
    }
    case CustomersActionTypes.GET_ALERT_MESSAGE_FAILED: {
      state = {
        ...state,
        loadingAlertMessage: false,
      };
      break;
    }
    case CustomersActionTypes.UPDATE_NOTES_TASKS: {
      state = {
        ...state,
        loadingTasksNotesList: true,
      };
      break;
    }
    case CustomersActionTypes.UPDATE_NOTES_TASKS_SUCCESS: {
      state = {
        ...state,
        loadingTasksNotesList: false,
      };
      break;
    }
    case CustomersActionTypes.UPDATE_NOTES_TASKS_FAILED: {
      state = {
        ...state,
        loadingTasksNotesList: false,
      };
      break;
    }
    case CustomersActionTypes.COMPLETE_TASKS: {
      state = {
        ...state,
        loadingCompleteTask: true,
      };
      break;
    }
    case CustomersActionTypes.COMPLETE_TASKS_SUCCESS: {
      state = {
        ...state,
        loadingCompleteTask: false,
      };
      break;
    }
    case CustomersActionTypes.COMPLETE_TASKS_FAILED: {
      state = {
        ...state,
        loadingCompleteTask: false,
      };
      break;
    }
    case CustomersActionTypes.GET_USER_STATE: {
      return { ...state, loadingUserState: true };
    }
    case CustomersActionTypes.GET_USER_STATE_FAILED: {
      return { ...state, loadingCustomerDetails: false };
    }
    case CustomersActionTypes.GET_USER_STATE_SUCCESS: {
      state = {
        ...state,
        userState: action.payload.OpenOrder,
        loadingCustomerDetails: false,
      };
      break;
    }
  }

  return state;
}
