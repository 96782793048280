import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService, DialogService } from '@pos-app/core-ui';
import { ATTACHMENT_DISPLAY_TEXT, Customer, CustomerTrailerSearch } from '@pos-app/data';
import { sortCustomerTrailerSearchList } from 'libs/core-ui/src/lib/utils/utils-camper-trailer';
import { CAMPER_TRAILER_BASE_ROUTE, DIALOG_BUTTON_TEXT, DIALOG_MESSAGE, DIALOG_TITLE } from 'libs/data/src/lib/constants/camper-trailer';
import { AttachmentEntityType } from 'libs/data/src/lib/models/attachment';
import {
  CamperCustomerDetails,
  RegoStatus,
  ServiceDate,
  ServiceHistory,
  StoreList,
  TrailerDetails,
  UpadateServiceHistory,
  UpdateTrailerInfo,
} from 'libs/data/src/lib/models/camperTrailer';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { concatMap, filter, finalize, map, switchMap, takeUntil } from 'rxjs/operators';
import { CamperServiceDetailsComponent } from '../../components/camper-service-details/camper-service-details.component';
import { CamperTrailerDetailsComponent } from '../../components/camper-trailer-details/camper-trailer-details.component';

@Component({
  selector: 'app-camper-trailer',
  templateUrl: './camper-trailer.component.html',
  styleUrls: ['./camper-trailer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamperTrailerComponent implements OnInit, OnDestroy {
  @ViewChild('content') modalBox: any;
  @ViewChild('camperServiceDetails') camperServiceDetailsComponent: CamperServiceDetailsComponent;
  @ViewChild('camperTrailerDetails') camperTrailerDetailsComponent: CamperTrailerDetailsComponent;

  public assetNumber: string;
  public customerNumber: number;
  public trailerDetails: TrailerDetails;
  public serviceHistoryMap: Map<string, ServiceHistory>;
  public serviceAttachmentMap: Map<any, any>;
  public serviceHistory: ServiceHistory;
  public camperCustomerDetails: CamperCustomerDetails = null;
  public regoStatusMap: Map<string, { RegoDescription: string }>;
  public regoStatusList: RegoStatus[];
  public selectedCustomer: any;

  public multiTrailers: Map<number, CustomerTrailerSearch>;

  public loading$ = new BehaviorSubject<boolean>(false);

  showTextLoader: boolean = false;
  generateReportLoaderText: string = ATTACHMENT_DISPLAY_TEXT.emptyText;
  trailerAttachmentsLength: number = 0;
  serviceAttachmentsLength: number = 0;
  entityId: string;
  entityType: AttachmentEntityType;
  serviceHistoryDateList: Array<string>;
  currentSelectedServiceDate: string = null;
  nextServiceDate: string;
  allTrailerAttachments: Array<any> = [];
  allServiceAttachments: Array<any> = [];
  allAttachments: any;
  hasMultiTrailers: boolean = false;
  multiTrailerSearchFlag: number = 1;
  currentTrailerIndex: number = 0;
  lastTrailerIndex: number = 0;
  isTrailerDetailsChanged = false;
  isServiceDetailsChanged = false;
  isTrailerAttachment: boolean;
  isServiceAttachment: boolean;
  isAddingCustomer: boolean;
  isEditingCustomer: boolean;
  isSelectingCustomer: boolean;
  storeList: StoreList[];

  private unSubscribe$ = new Subject<void>();

  constructor(
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private dialogService: DialogService,
    private toastrService: ToastrService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.multiTrailers = new Map();
    this.regoStatusMap = new Map();
    this.serviceHistoryMap = new Map();
    this.serviceAttachmentMap = new Map();
    this.serviceHistoryDateList = new Array<string>();

    this.fetchStoreList();
    this.fetchRegoStatusList();
    this.setCamperTrailerDetails(this.fetchCamperTrailerDetails());
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  fetchStoreList() {
    this.apiService
      .fetchStoreList()
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((res) => {
        this.storeList = res.SearchResults;
      });
  }

  fetchRegoStatusList() {
    this.apiService
      .fetchRegoStatus({})
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((response) => {
        this.regoStatusList = response.SearchResults;
      });
  }

  customerTrailerSearch(customerNumber: string): Observable<any> {
    return this.apiService.customerTrailerSearch({
      searchString: customerNumber,
    });
  }

  fetchCustomerTrailerDetails(assetNumber) {
    return this.apiService.fetchCustomerTrailerDetails({ assetNumber: assetNumber }).pipe(
      takeUntil(this.unSubscribe$),
      filter((camperTrailerRes) => !!camperTrailerRes),
      map((camperTrailerRes) => {
        if (camperTrailerRes?.assetNumbers?.length > 1 && this.multiTrailerSearchFlag === 1) {
          const trailers = camperTrailerRes?.assetNumbers.map((item) => {
            return {
              AssetNumber: item,
            };
          });

          let sortedCustomerTrailerSearchList: CustomerTrailerSearch[] = sortCustomerTrailerSearchList(trailers);

          this.setLastTrailerIndex(sortedCustomerTrailerSearchList.length - 1);

          for (let i = 0; i < sortedCustomerTrailerSearchList.length; i++) {
            this.multiTrailers.set(i, sortedCustomerTrailerSearchList[i]);

            if (sortedCustomerTrailerSearchList[i].AssetNumber === Number(this.assetNumber)) {
              this.setCurrentTrailerIndex(i);
            }
          }
          this.multiTrailerSearchFlag = 0;
        }
        return {
          CustomerDetails: {
            customerName: camperTrailerRes.CustomerName,
            customerNumber: camperTrailerRes.CustomerNumber,
            customerPhone: camperTrailerRes.CustomerPhone,
            customerEmail: camperTrailerRes.CustomerEmail,
          },
          TrailerDetails: camperTrailerRes.TrailerDetails,
          HasMultiTrailer: camperTrailerRes.assetNumbers?.length > 1,
        };
      })
    );
  }

  fetchCamperTrailerDetails() {
    return this.activatedRoute.params.pipe(
      takeUntil(this.unSubscribe$),
      map((routeParams) => {
        return routeParams.id;
      }),
      concatMap((assetNumber) => {
        this.assetNumber = assetNumber;
        return this.fetchCustomerTrailerDetails(this.assetNumber);
      })
    );
  }

  fetchCustomerDetails(customerNumber: Number) {
    this.apiService
      .getCustomerDetails({
        customerNumberSearch: customerNumber,
      })
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((customerDetails) => {
        this.selectedCustomer = customerDetails.SearchResults[0];
      });
  }

  setCamperTrailerDetails(camperObs$: Observable<any>) {
    this.loading$.next(true);
    camperObs$
      .pipe(
        takeUntil(this.unSubscribe$),
        finalize(() => this.loading$.next(false))
      )
      .subscribe({
        next: (result) => {
          this.serviceHistoryDateList = [];
          this.allTrailerAttachments = [];

          this.hasMultiTrailers = result.HasMultiTrailer;

          this.camperCustomerDetails = result.CustomerDetails;
          this.customerNumber = this.camperCustomerDetails.customerNumber;

          // Index Zero Provided as TrailerDetails will have only one trailer only
          // trailer/TrailerSearch will be used to determine multi trailer

          if (!this.isTrailerDetailsChanged) {
            this.trailerDetails = result.TrailerDetails[0];
          }

          if (this.hasNewTrailerAttachment() || this.hasMultiTrailers) {
            if (this.trailerDetails.TrailerAttachments && this.trailerDetails.TrailerAttachments.length > 0) {
              this.allTrailerAttachments = this.trailerDetails.TrailerAttachments;

              this.trailerAttachmentsLength = this.allTrailerAttachments.length;
            } else {
              this.trailerAttachmentsLength = this.allTrailerAttachments.length;
            }
          }

          //To skip update if there is a change in current state of Service History
          if (!this.isServiceDetailsChanged || !this.hasNewServiceAttachment()) {
            this.nextServiceDate = result.TrailerDetails[0].NextServiceDate;
            if (this.trailerDetails.ServiceHistory && this.trailerDetails.ServiceHistory.length > 0) {
              this.trailerDetails.ServiceHistory.forEach((serviceHistory, index) => {
                let serviceDate = serviceHistory.ServiceDate;
                this.serviceHistoryDateList.push(serviceDate);

                this.serviceHistoryMap.set(serviceDate, serviceHistory);

                if (serviceHistory.ServiceAttachments && serviceHistory.ServiceAttachments.length > 0) {
                  this.serviceAttachmentMap.set(
                    `${this.assetNumber}|${this.yearMonthDayDateFormat(serviceHistory.ServiceDate)}`,
                    serviceHistory.ServiceAttachments
                  );
                }
              });
              if (!!this.currentSelectedServiceDate) {
                this.serviceHistory = this.serviceHistoryMap.get(this.currentSelectedServiceDate);
                this.serviceAttachmentsLength = this.serviceHistory?.ServiceAttachments?.length;
              } else {
                this.currentSelectedServiceDate = this.trailerDetails.ServiceHistory[0].ServiceDate;
                this.serviceHistory = this.trailerDetails.ServiceHistory[0];
                this.serviceAttachmentsLength = this.serviceHistory?.ServiceAttachments?.length;
              }
            }
          }

          this.loading$.next(false);
        },
      });
  }

  setLastTrailerIndex(trailerIndex: number) {
    this.lastTrailerIndex = trailerIndex;
  }

  setCurrentTrailerIndex(trailerIndex: number) {
    this.currentTrailerIndex = trailerIndex;
  }

  switchTrailer(index: number) {
    this.getNextTrailer(index);
  }

  getNextTrailer(index: number) {
    this.currentTrailerIndex += index;

    if (this.currentTrailerIndex > this.lastTrailerIndex) {
      this.currentTrailerIndex = 0;
    }

    let nextTrailerAssetNumber = this.multiTrailers.get(this.currentTrailerIndex).AssetNumber;

    this.assetNumber = nextTrailerAssetNumber.toString();
    this.updateRouteWithNextTrailer();
  }

  updateTrailerInfo(updateTrailerInfo: UpdateTrailerInfo) {
    this.loading$.next(true);
    this.apiService
      .updateTrailerInfo(updateTrailerInfo)
      .pipe(
        finalize(() => {
          this.setCamperTrailerDetails(this.fetchCamperTrailerDetails());

          this.isTrailerDetailsChanged = false;
          this.camperTrailerDetailsComponent.hasFormValueChanged = false;
        })
      )
      .subscribe((res) => {
        this.loading$.next(false);
        this.toastrService.success(res.ErrorMessage);
      });
  }

  onUpdateServiceHistory(parameters: UpadateServiceHistory | ServiceDate) {
    this.apiService
      .updateServiceHistory(parameters)
      .pipe(
        takeUntil(this.unSubscribe$),
        finalize(() => {
          this.setCamperTrailerDetails(this.fetchCamperTrailerDetails());

          this.isServiceDetailsChanged = false;
          this.camperServiceDetailsComponent.hasFormValueChanged = false;
        })
      )
      .subscribe((res) => {
        this.toastrService.success(res.ErrorMessage);
      });
  }

  cancelTrailerForm() {
    if (this.hasNewTrailerAttachment()) {
      this.setCamperTrailerDetails(this.fetchCamperTrailerDetails());
    }

    if (this.isTrailerDetailsChanged) {
      this.trailerDetails = { ...this.trailerDetails };
      this.isTrailerDetailsChanged = false;
      this.camperTrailerDetailsComponent.hasFormValueChanged = false;
    }
  }

  onCancelServiceForm() {
    if (this.hasNewServiceAttachment()) {
      this.setCamperTrailerDetails(this.fetchCamperTrailerDetails());
    }

    if (this.isServiceDetailsChanged) {
      let serviceDate = this.currentSelectedServiceDate;

      if (!this.trailerDetails.ServiceHistory.some(item => item.ServiceDate === serviceDate)) {
        serviceDate = this.trailerDetails.ServiceHistory[0].ServiceDate;
      }

      this.serviceHistory = {
        ...this.serviceHistoryMap.get(serviceDate),
      };
      this.isServiceDetailsChanged = false;
      this.camperServiceDetailsComponent.hasFormValueChanged = false;
    }
  }

  onGetServiceHistoryByDate(serviceDate: string) {
    if (this.hasNewServiceAttachment()) {
      this.messageDialog(DIALOG_TITLE.unsavedChanges, DIALOG_MESSAGE.serviceDropDown, DIALOG_BUTTON_TEXT.default, false)
        .pipe(
          takeUntil(this.unSubscribe$),
          switchMap(() => {
            return of(null);
          })
        )
        .subscribe();
    } else {
      this.serviceAttachmentsLength = 0;
      if (!!serviceDate) {
        if (this.hasServiceDate(serviceDate)) {
          this.serviceHistory = this.serviceHistoryMap.get(serviceDate);
          let entityId = this.getServiceAttachmentEntryId(this.assetNumber, serviceDate);
          if (this.serviceAttachmentMap.has(entityId)) {
            this.serviceAttachmentsLength = this.serviceAttachmentMap.get(entityId).length;
          }
        }
      }

      this.currentSelectedServiceDate = serviceDate;
    }
  }

  openTrailerAttachments() {
    this.isTrailerAttachment = true;
    this.isServiceAttachment = false;
    this.isAddingCustomer = false;
    this.isEditingCustomer = false;
    this.isSelectingCustomer = false;
    this.entityType = 'CamperTrailer';
    this.entityId = this.assetNumber;
    this.openModalBox();
  }

  onOpenServiceAttachments(currentSelectedDate: string) {
    this.allServiceAttachments = [];
    this.isServiceAttachment = true;
    this.isTrailerAttachment = false;
    this.isAddingCustomer = false;
    this.isEditingCustomer = false;
    this.isSelectingCustomer = false;
    this.entityType = 'ServiceAttachment';
    if (!!currentSelectedDate) {
      this.entityId = this.getServiceAttachmentEntryId(this.assetNumber, currentSelectedDate);

      if (this.serviceAttachmentMap.has(this.entityId)) {
        this.allServiceAttachments = this.serviceAttachmentMap.get(this.entityId);
      } else {
        this.allServiceAttachments = [];
      }
    }
    this.openModalBox();
  }

  loadAllAttachments(attachments: any) {
    if (!!this.isTrailerAttachment && !this.isServiceAttachment) {
      this.allTrailerAttachments = attachments;
      this.trailerAttachmentsLength = this.allTrailerAttachments.length;
    }
    if (!!this.isServiceAttachment && !this.isTrailerAttachment) {
      let entityId = this.getServiceAttachmentEntryId(this.assetNumber, this.currentSelectedServiceDate);

      this.serviceAttachmentMap.delete(entityId);
      this.serviceAttachmentMap.set(entityId, attachments);

      this.allServiceAttachments = this.serviceAttachmentMap.get(entityId);

      this.serviceAttachmentsLength = this.allServiceAttachments.length;
    }
  }

  getServiceAttachmentEntryId(assetNumber: string, serviceDate: string) {
    return `${assetNumber}|${this.yearMonthDayDateFormat(serviceDate)}`;
  }

  openModalBox() {
    this.modalService
      .open(this.modalBox, {
        windowClass: 'garage-modal',
        size: 'xl',
        backdrop: 'static',
      })
      .result.then(
        (_) => {
          this.isAddingCustomer = false;
          this.isEditingCustomer = false;
          this.isSelectingCustomer = false;
          this.isTrailerAttachment = false;
          this.isServiceAttachment = false;
        },
        (_) => {
          this.isAddingCustomer = false;
          this.isEditingCustomer = false;
          this.isSelectingCustomer = false;
          this.isTrailerAttachment = false;
          this.isServiceAttachment = false;
        }
      );
  }

  handleCloseModal(event) {
    if (event) {
      this.modalService.dismissAll();
    }
  }

  yearMonthDayDateFormat(date: string) {
    let formatedDate = date.split('/');
    return formatedDate[2] + formatedDate[1] + formatedDate[0];
  }

  hasServiceDate(serviceDate: string) {
    return this.serviceHistoryDateList.includes(serviceDate);
  }

  generateReport() {
    this.showTextLoader = true;
    this.generateReportLoaderText = ATTACHMENT_DISPLAY_TEXT.camperTrailerReportText;
    this.loading$.next(true);

    this.apiService
      .camperTrailerGenerateReport({ assetNumber: this.assetNumber })
      .pipe(
        finalize(() => {
          this.loading$.next(false);
          this.showTextLoader = false;
          this.generateReportLoaderText = ATTACHMENT_DISPLAY_TEXT.emptyText;
        }),
        map((res) => {
          const contentDisposition = res.headers.get('Content-Disposition');
          let fileName = contentDisposition.split('filename=')[1];
          let fileUrl = window.URL.createObjectURL(res.body);

          return { fileName, fileUrl };
        })
      )
      .subscribe(({ fileName, fileUrl }) => {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = fileUrl;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(fileUrl);
        a.remove();
      });
  }

  hasTrailerDetailsChanged(hasChanged: boolean) {
    this.isTrailerDetailsChanged = hasChanged;
    this.changeDetectorRef.detectChanges();
  }

  onHasServiceDetailsChanged(hasChanged: boolean) {
    this.isServiceDetailsChanged = hasChanged;
    this.changeDetectorRef.detectChanges();
  }

  messageDialog(dialogTitle: string, dialogMessage: string, yesButtonText = 'OKAY', showExitButton: boolean): Observable<boolean> {
    return this.dialogService.confirm(dialogMessage, dialogTitle, yesButtonText, showExitButton ? `EXIT` : null, false, true, false);
  }

  hasNewServiceAttachment() {
    if (
      !this.serviceHistoryMap.get(this.currentSelectedServiceDate) ||
      !this.serviceAttachmentMap.get(this.getServiceAttachmentEntryId(this.assetNumber, this.currentSelectedServiceDate))
    ) {
      return false;
    }
    if (
      JSON.stringify(this.serviceHistoryMap.get(this.currentSelectedServiceDate).ServiceAttachments) ===
      JSON.stringify(this.serviceAttachmentMap.get(this.getServiceAttachmentEntryId(this.assetNumber, this.currentSelectedServiceDate)))
    ) {
      return false;
    } else {
      return true;
    }
  }

  hasNewTrailerAttachment() {
    if (!this.trailerDetails) {
      return false;
    }
    if (JSON.stringify(this.allTrailerAttachments) === JSON.stringify(this.trailerDetails.TrailerAttachments)) {
      return false;
    } else {
      return true;
    }
  }

  fetchNewSelectedCustomer(customer: Customer) {
    this.selectedCustomer = customer;
    this.customerNumber = parseInt(customer.CustomerNumber);
  }

  handleAddNewCustomer(customer) {
    this.isTrailerAttachment = false;
    this.isServiceAttachment = false;
    this.isSelectingCustomer = false;
    this.modalService.dismissAll();
    if (customer) {
      this.apiService
        .fetchCustomerDetails({
          customerNumber: customer,
        })
        .pipe(
          takeUntil(this.unSubscribe$),
          finalize(() => {
            if (this.isEditingCustomer) {
              this.setCamperTrailerDetails(this.fetchCamperTrailerDetails());
            }
            this.isAddingCustomer = false;
            this.isEditingCustomer = false;
          }),
          concatMap((res) => {
            this.fetchNewSelectedCustomer(res.SearchResults[0]);
            if (this.isEditingCustomer) {
              return of(null);
            } else {
              return this.messageDialog(DIALOG_TITLE.trailerOwnsership, DIALOG_MESSAGE.trailerOwner, DIALOG_BUTTON_TEXT.trailerOwnership, true);
            }
          }),
          switchMap((val) => {
            if (val === true) {
              let obsr$ = this.updateTrailerInfo(this.mapTrailerDetailsToUpdateTrailerInfo());
              return of(obsr$);
            } else {
              return of(null);
            }
          })
        )
        .subscribe((res) => {
          if (res) {
            this.toastrService.success(res.ErrorMessage);
          }
        });
    }
  }

  handleSelectCustomer(customer: Customer) {
    this.isAddingCustomer = false;
    this.isEditingCustomer = false;
    this.isTrailerAttachment = false;
    this.isServiceAttachment = false;

    if (customer) {
      this.fetchNewSelectedCustomer(customer);
      this.modalService.dismissAll();

      this.messageDialog(DIALOG_TITLE.trailerOwnsership, DIALOG_MESSAGE.trailerOwner, DIALOG_BUTTON_TEXT.trailerOwnership, true)
        .pipe(
          takeUntil(this.unSubscribe$),
          finalize(() => {
            this.isSelectingCustomer = false;
          }),
          switchMap((val) => {
            if (val === true) {
              let obsr$ = this.updateTrailerInfo(this.mapTrailerDetailsToUpdateTrailerInfo());
              return of(obsr$);
            } else {
              return of(null);
            }
          })
        )
        .subscribe((res) => {
          if (res) {
            this.toastrService.success(res.ErrorMessage);
          }
        });
    }
  }

  addNewCustomer() {
    this.isAddingCustomer = true;
    this.isEditingCustomer = false;
    this.isSelectingCustomer = false;
    this.isTrailerAttachment = false;
    this.isServiceAttachment = false;
    this.openModalBox();
  }

  selectNewCustomer() {
    this.isSelectingCustomer = true;
    this.isAddingCustomer = false;
    this.isEditingCustomer = false;
    this.isTrailerAttachment = false;
    this.isServiceAttachment = false;
    this.openModalBox();
  }

  editCustomer() {
    this.isEditingCustomer = true;
    this.isAddingCustomer = false;
    this.isSelectingCustomer = false;
    this.isTrailerAttachment = false;
    this.isServiceAttachment = false;

    if (!this.selectedCustomer) {
      this.fetchCustomerDetails(this.customerNumber);
    }

    this.openModalBox();
  }

  mapTrailerDetailsToUpdateTrailerInfo(): UpdateTrailerInfo {
    return {
      assetNumber: Number(this.assetNumber),
      vin: this.trailerDetails.VIN,
      purchaseDate: this.trailerDetails.PurchaseDate,
      invoiceNumber: this.trailerDetails.InvoiceNumber,
      regoNumber: this.trailerDetails.RegoNumber,
      registrationStatusCode: this.trailerDetails.RegistrationStatusCode,
      sellingStore: this.trailerDetails.SellingStore.toString(),
      salesperson: this.trailerDetails.Salesperson,
      preferredARBStore: this.trailerDetails.PreferredARBStore.toString(),
      serviceReminders: this.trailerDetails.ServiceReminders,
      optionsFitted: this.trailerDetails.OptionsFitted,
      customerNumber: this.customerNumber,
    };
  }

  updateRouteWithNextTrailer() {
    this.router.navigate([CAMPER_TRAILER_BASE_ROUTE, this.assetNumber]);
  }
}
