//====================================================================================================================================
// Mock Data
//=====================================================================================================================================
export let defaultConfig: [
  {
    defaultStartTime: '07:00:00';
    defaultFinishTime: '07:00:00';
    defaultBreakStartTime: '12:00:00';
    defaultBreakEndTime: '13:00:00';
    defaultWorkingDay: [1, 2, 3, 4, 5];
    customStartTime: '';
    customFinishTime: '';
    customBreakStartTime: '';
    customBreakEndTime: '';
    customWorkingDay: [0, 1, 2, 3, 4, 5, 6];
  }
];

export let elements = [
  { key: 0, label: 'UNALLOCATED JOBS', open: true, children: [] },
  { key: 1, label: '4X4 Technician', open: true, children: [] },
];

export let fetchEventType = [
  { key: 1, label: 'Cleaning' },
  { key: 2, label: 'Break' },
  { key: 3, label: 'Training' },
  { key: 4, label: 'Annual Leave' },
  { key: 5, label: 'Sick Leave' },
  { key: 6, label: 'Parental Leave' },
  { key: 7, label: "Carer's Leave" },
  { key: 8, label: 'Maternal Leave' },
  { key: 9, label: 'Appointment Leave' },
  { key: 10, label: 'Unscheduled Leave' },
];

export let technician = [
  { key: 2, label: 'John Williams' },
  { key: 3, label: 'John Williams' },
  { key: 4, label: 'John Williams' },
  { key: 5, label: 'John Williams' },
  { key: 6, label: 'John Williams' },
];

export let fetchAttachments = [
  { type: 'Note', user: 'bryan', date: '02/02/2023', time: '09:45:00', detail: '1312312' },
  { type: 'Note', user: 'bryan', date: '02/02/2023', time: '09:45:00', detail: '312312' },
  { type: 'Link', user: 'bryan', date: '02/02/2023', time: '09:45:00', detail: 'click to see ', link: 'online.arb.com.au' },
  { type: 'Link', user: 'bryan', date: '02/02/2023', time: '09:45:00', detail: 'click here ', link: 'online.arb.com.au' },
  { type: 'file', user: 'bryan', date: '02/02/2023', time: '09:45:00', detail: 'Pop Up changes.png' },
];

export const fetchOrdersForBooking = {
  ErrorFlag: '0',
  ErrorMessage: 'success',
  Response: [
    {
      OrderNumber: 22798944,
      OrderType: 'SO',
      CustomerName: 'Garry Help',
      CustomerPhone: '0400789123',
      CarShortDesc: 'FORD RANGER XLT HI-RIDE',
      CarRego: 'TBA',
      RequestDate: '10/02/2023',
      InvoiceDate: ' ',
      CustomerPO: '',
      Status: 0,
      ResourceAssigned: '007219',
      GroupName: '',
      TotalFitHours: 4.3,
      SpiltOrderYN: 'N',
      ArrayOfLines: [],
    },
    {
      OrderNumber: 22798951,
      OrderType: 'SO',
      CustomerName: 'OUTBACK 4WD',
      CustomerPhone: '9720 6226',
      CarShortDesc: 'No Vehicle on Sales Order',
      CarRego: '',
      RequestDate: '10/02/2023',
      InvoiceDate: ' ',
      CustomerPO: 'Free Goods Test #6',
      Status: 0,
      ResourceAssigned: '',
      GroupName: 'ENTER GROUP NAME(1)',
      TotalFitHours: 0,
      SpiltOrderYN: 'Y',
      ArrayOfLines: [
        {
          PartNumber: '2700',
          LineNumber: 1,
          PartDesc: 'COIL LC 200 SER-FRONT-(L)',
          FitHours: 0,
        },
        {
          PartNumber: 'FAU2001870',
          LineNumber: 1.01,
          PartDesc: 'FRONT AXLE UPGRADE LABEL PACK',
          FitHours: 0,
        },
      ],
    },
    {
      OrderNumber: 22798992,
      OrderType: 'SO',
      CustomerName: 'BRETT RYAN - STAFF',
      CustomerPhone: '0400233394',
      CarShortDesc: 'No Vehicle on Sales Order',
      CarRego: '',
      RequestDate: '10/02/2023',
      InvoiceDate: ' ',
      CustomerPO: '',
      Status: 0,
      ResourceAssigned: '007484',
      GroupName: 'ENTER GROUP NAME(1)',
      TotalFitHours: 0.5,
      SpiltOrderYN: 'Y',
      ArrayOfLines: [
        {
          PartNumber: 'ARB705',
          LineNumber: 1,
          PartDesc: 'RECOVERY STRAP 8000KG',
          FitHours: 0,
        },
        {
          PartNumber: '814406',
          LineNumber: 2,
          PartDesc: 'AWNING, PVC BAG, 2000 X 2500MM',
          FitHours: 0,
        },
        {
          PartNumber: 'ARB4158A',
          LineNumber: 3,
          PartDesc: 'SUPERGRIP SANDPEG 370MM 4 PACK',
          FitHours: 0,
        },
        {
          PartNumber: 'ARB4159A',
          LineNumber: 4,
          PartDesc: 'GUY ROPE SET W/CARABINER 2 PAC',
          FitHours: 0,
        },
        {
          PartNumber: '2859',
          LineNumber: 5,
          PartDesc: 'COIL SPRING FRONT, HEAVY DUTY',
          FitHours: 0,
        },
        {
          PartNumber: 'FIT',
          LineNumber: 6,
          PartDesc: 'CHARGE TO FIT PRODUCT TO VEHIC',
          FitHours: 0.5,
        },
        {
          PartNumber: 'FAU701540',
          LineNumber: 7,
          PartDesc: 'FRONT AXLE UPGRADE LABEL PACK',
          FitHours: 0,
        },
      ],
    },
    {
      OrderNumber: 22798992,
      OrderType: 'SO',
      CustomerName: 'BRETT RYAN - STAFF',
      CustomerPhone: '0400233394',
      CarShortDesc: 'No Vehicle on Sales Order',
      CarRego: '',
      RequestDate: '10/02/2023',
      InvoiceDate: ' ',
      CustomerPO: '',
      Status: 0,
      ResourceAssigned: '006632',
      GroupName: 'ENTER GROUP NAME(2)',
      TotalFitHours: 0.5,
      SpiltOrderYN: 'Y',
      ArrayOfLines: [
        {
          PartNumber: '2853',
          LineNumber: 8,
          PartDesc: 'COIL SPRING FRONT',
          FitHours: 0,
        },
        {
          PartNumber: 'FIT',
          LineNumber: 9,
          PartDesc: 'CHARGE TO FIT PRODUCT TO VEHIC',
          FitHours: 0.5,
        },
        {
          PartNumber: 'FAU701540',
          LineNumber: 10,
          PartDesc: 'FRONT AXLE UPGRADE LABEL PACK',
          FitHours: 0,
        },
      ],
    },
    {
      OrderNumber: 22798992,
      OrderType: 'SO',
      CustomerName: 'BRETT RYAN - STAFF',
      CustomerPhone: '0400233394',
      CarShortDesc: 'No Vehicle on Sales Order',
      CarRego: '',
      RequestDate: '10/02/2023',
      InvoiceDate: ' ',
      CustomerPO: '',
      Status: 0,
      ResourceAssigned: '0092',
      GroupName: 'ENTER GROUP NAME(3)',
      TotalFitHours: 0.5,
      SpiltOrderYN: 'Y',
      ArrayOfLines: [
        {
          PartNumber: '2703',
          LineNumber: 11,
          PartDesc: 'COIL SPRING FRONT',
          FitHours: 0,
        },
        {
          PartNumber: 'FIT',
          LineNumber: 12,
          PartDesc: 'CHARGE TO FIT PRODUCT TO VEHIC',
          FitHours: 0.5,
        },
        {
          PartNumber: 'FAU2001870',
          LineNumber: 13,
          PartDesc: 'FRONT AXLE UPGRADE LABEL PACK',
          FitHours: 0,
        },
      ],
    },
    {
      OrderNumber: 22798992,
      OrderType: 'SO',
      CustomerName: 'BRETT RYAN - STAFF',
      CustomerPhone: '0400233394',
      CarShortDesc: 'No Vehicle on Sales Order',
      CarRego: '',
      RequestDate: '10/02/2023',
      InvoiceDate: ' ',
      CustomerPO: '',
      Status: 0,
      ResourceAssigned: '006756',
      GroupName: 'ENTER GROUP NAME(4)',
      TotalFitHours: 0.5,
      SpiltOrderYN: 'Y',
      ArrayOfLines: [
        {
          PartNumber: '2856',
          LineNumber: 14,
          PartDesc: 'COIL SPRING FRONT',
          FitHours: 0,
        },
        {
          PartNumber: 'FIT',
          LineNumber: 15,
          PartDesc: 'CHARGE TO FIT PRODUCT TO VEHIC',
          FitHours: 0.5,
        },
        {
          PartNumber: 'FAU701540',
          LineNumber: 16,
          PartDesc: 'FRONT AXLE UPGRADE LABEL PACK',
          FitHours: 0,
        },
      ],
    },
    {
      OrderNumber: 22798992,
      OrderType: 'SO',
      CustomerName: 'BRETT RYAN - STAFF',
      CustomerPhone: '0400233394',
      CarShortDesc: 'No Vehicle on Sales Order',
      CarRego: '',
      RequestDate: '10/02/2023',
      InvoiceDate: ' ',
      CustomerPO: '',
      Status: 0,
      ResourceAssigned: '006709',
      GroupName: 'ENTER GROUP NAME(5)',
      TotalFitHours: 0.5,
      SpiltOrderYN: 'Y',
      ArrayOfLines: [
        {
          PartNumber: '2701',
          LineNumber: 17,
          PartDesc: 'COIL SPRING FRONT',
          FitHours: 0,
        },
        {
          PartNumber: 'FAU2001870',
          LineNumber: 18,
          PartDesc: 'FRONT AXLE UPGRADE LABEL PACK',
          FitHours: 0,
        },
        {
          PartNumber: 'FIT',
          LineNumber: 19,
          PartDesc: 'CHARGE TO FIT PRODUCT TO VEHIC',
          FitHours: 0.5,
        },
      ],
    },
    {
      OrderNumber: 22798992,
      OrderType: 'SO',
      CustomerName: 'BRETT RYAN - STAFF',
      CustomerPhone: '0400233394',
      CarShortDesc: 'No Vehicle on Sales Order',
      CarRego: '',
      RequestDate: '10/02/2023',
      InvoiceDate: ' ',
      CustomerPO: '',
      Status: 0,
      ResourceAssigned: '007447',
      GroupName: 'ENTER GROUP NAME(7)',
      TotalFitHours: 0.8,
      SpiltOrderYN: 'Y',
      ArrayOfLines: [
        {
          PartNumber: 'BP5190003R',
          LineNumber: 23,
          PartDesc: 'BP51 COIL OVER SHOCK, FRONT RH',
          FitHours: 0,
        },
        {
          PartNumber: 'FAU2001870',
          LineNumber: 24,
          PartDesc: 'FRONT AXLE UPGRADE LABEL PACK',
          FitHours: 0,
        },
        {
          PartNumber: 'FIT',
          LineNumber: 25,
          PartDesc: 'CHARGE TO FIT PRODUCT TO VEHIC',
          FitHours: 0.8,
        },
      ],
    },
    {
      OrderNumber: 22798992,
      OrderType: 'SO',
      CustomerName: 'BRETT RYAN - STAFF',
      CustomerPhone: '0400233394',
      CarShortDesc: 'No Vehicle on Sales Order',
      CarRego: '',
      RequestDate: '10/02/2023',
      InvoiceDate: ' ',
      CustomerPO: '',
      Status: 0,
      ResourceAssigned: '007447',
      GroupName: 'ENTER GROUP NAME(8)',
      TotalFitHours: 0.5,
      SpiltOrderYN: 'Y',
      ArrayOfLines: [
        {
          PartNumber: '2702',
          LineNumber: 26,
          PartDesc: 'COIL SPRING FRONT',
          FitHours: 0,
        },
        {
          PartNumber: 'FAU2001870',
          LineNumber: 27,
          PartDesc: 'FRONT AXLE UPGRADE LABEL PACK',
          FitHours: 0,
        },
        {
          PartNumber: 'FIT',
          LineNumber: 28,
          PartDesc: 'CHARGE TO FIT PRODUCT TO VEHIC',
          FitHours: 0.5,
        },
      ],
    },
    {
      OrderNumber: 22798992,
      OrderType: 'SO',
      CustomerName: 'BRETT RYAN - STAFF',
      CustomerPhone: '0400233394',
      CarShortDesc: 'No Vehicle on Sales Order',
      CarRego: '',
      RequestDate: '10/02/2023',
      InvoiceDate: ' ',
      CustomerPO: '',
      Status: 0,
      ResourceAssigned: '007409',
      GroupName: 'ENTER GROUP NAME(9)',
      TotalFitHours: 0.5,
      SpiltOrderYN: 'Y',
      ArrayOfLines: [
        {
          PartNumber: '2705',
          LineNumber: 29,
          PartDesc: 'COIL SPRING FRONT',
          FitHours: 0,
        },
        {
          PartNumber: 'FAU2001870',
          LineNumber: 30,
          PartDesc: 'FRONT AXLE UPGRADE LABEL PACK',
          FitHours: 0,
        },
        {
          PartNumber: 'FIT',
          LineNumber: 31,
          PartDesc: 'CHARGE TO FIT PRODUCT TO VEHIC',
          FitHours: 0.5,
        },
      ],
    },
    {
      OrderNumber: 22798992,
      OrderType: 'SO',
      CustomerName: 'BRETT RYAN - STAFF',
      CustomerPhone: '0400233394',
      CarShortDesc: 'No Vehicle on Sales Order',
      CarRego: '',
      RequestDate: '10/02/2023',
      InvoiceDate: ' ',
      CustomerPO: '',
      Status: 0,
      ResourceAssigned: '006016',
      GroupName: 'ENTER GROUP NAME(11)',
      TotalFitHours: 5,
      SpiltOrderYN: 'Y',
      ArrayOfLines: [
        {
          PartNumber: '803103',
          LineNumber: 41,
          PartDesc: 'ARB SIMPSON TENT WITH ANNEXE',
          FitHours: 0,
        },
        {
          PartNumber: '3440540',
          LineNumber: 42,
          PartDesc: 'SUMMIT STEEL BULLBAR, WINCH CO',
          FitHours: 0,
        },
        {
          PartNumber: 'FIT',
          LineNumber: 43,
          PartDesc: 'CHARGE TO FIT PRODUCT TO VEHIC',
          FitHours: 5,
        },
        {
          PartNumber: 'CC1',
          LineNumber: 44,
          PartDesc: 'PAINT CHARGE  FOR BULL BAR',
          FitHours: 0,
        },
      ],
    },
  ],
};

export const fetchEventToTimelineCalanderData = {
  ErrorFlag: '0',
  ErrorMessage: 'success',
  Response: [
    {
      eventId: 1,
      eventType: { key: 1, label: 'cleaning' },
      resourceID: '006016',
      start_date: '2023-02-10 17:00',
      end_date: '2023-02-10 19:00',
      text: 'Task A-12458',
      description: 'Cleaning Job',
      wholeDay: false,
    },
    {
      eventId: 2,
      eventType: { key: 2, label: 'break' },
      resourceID: '000092',
      start_date: '2023-02-10 17:00',
      end_date: '2023-02-10 19:00',
      text: 'Task A-12458',
      description: 'Extra Break',
      wholeDay: false,
    },
    {
      eventId: 3,
      eventType: { key: 3, label: 'training' },
      resourceID: '003718',
      start_date: '2023-02-10 17:00',
      end_date: '2023-02-10 19:00',
      text: 'Task A-12458',
      description: 'Training',
      wholeDay: false,
    },
    {
      eventId: 4,
      eventType: { key: 4, label: 'annual' },
      resourceID: '006756',
      start_date: '2023-02-10 07:00',
      end_date: '2023-02-10 19:00',
      text: 'Task A-12458',
      description: 'Annual Leave',
      wholeDay: true,
    },
    {
      eventId: 5,
      eventType: { key: 5, label: 'appointment' },
      resourceID: '007409',
      start_date: '2023-02-10 17:00',
      end_date: '2023-02-10 19:00',
      text: 'Task A-12458',
      description: 'Appointment Leave',
      wholeDay: false,
    },
    {
      eventId: 6,
      eventType: { key: 6, label: 'sick' },
      resourceID: '007219',
      start_date: '2023-02-10 17:00',
      end_date: '2023-02-10 19:00',
      text: 'Task A-12458',
      description: 'Sick Leave',
      wholeDay: false,
    },
    {
      eventId: 7,
      eventType: { key: 7, label: 'carers' },
      resourceID: '006709',
      start_date: '2023-02-06 13:00',
      end_date: '2023-2-6 15:30',
      text: 'Task A-12458',
      description: "Carer's Leave",
      wholeDay: false,
    },
    {
      eventId: 8,
      eventType: { key: 8, label: 'maternal' },
      resourceID: '007447',
      start_date: '2023-02-10 17:00',
      end_date: '2023-02-10 19:00',
      text: 'Task A-12458',
      description: 'Maternal Leave',
      wholeDay: false,
    },
  ],
};

export const fetchResourceList = {
  ErrorFlag: '0',
  ErrorMessage: ' success',
  resourceList: [
    {
      resourceID: '006016',
      resourceName: 'Antonio Deuscit',
    },
    {
      resourceID: '000092',
      resourceName: "Chris O'Shea",
    },
    {
      resourceID: '003718',
      resourceName: 'George Thompson',
    },
    {
      resourceID: '006756',
      resourceName: 'Jai Jordan',
    },
    {
      resourceID: '007409',
      resourceName: 'Joel Brooks',
    },
    {
      resourceID: '007219',
      resourceName: 'Josh Danks',
    },
    {
      resourceID: '006709',
      resourceName: 'Justin Crabtree',
    },
    {
      resourceID: '007447',
      resourceName: 'Lachlan Scott',
    },
    {
      resourceID: '007484',
      resourceName: 'Michael Kincaid',
    },
    {
      resourceID: '006632',
      resourceName: 'Shaun Proctor',
    },
    {
      resourceID: '006634',
      resourceName: 'aaa Proctora',
    },
    {
      resourceID: '006635',
      resourceName: 'aafdfd Proctorb',
    },
    {
      resourceID: '006636',
      resourceName: 'Shfdfaun Proctorc',
    },
    {
      resourceID: '006637',
      resourceName: 'hello ',
    },
    {
      resourceID: '006639',
      resourceName: 'sss ',
    },
    {
      resourceID: '006640',
      resourceName: 'ahello ',
    },
  ],
};

 //mockData fetch order
//  this.allocatedOrders = fetchOrdersForBooking?.Response.filter((el) => el.ResourceAssigned !== '').map((allocatedOrders, index) => {
//     const totalInMinutes =(allocatedOrders?.TotalFitHours?.toString()?.substring(0, 2)) as any * 60 + allocatedOrders?.TotalFitHours?.toString()?.substring(3, 5);
//     const startTime = '07:00:00';
//     const endTime = moment(startTime, 'HH:mm:ss').add(totalInMinutes, 'minutes').format('HH:mm');
//     const startDateTime = getformated(date) + ' 07:00'.toString();
//     const endDateTime = (getformated(date) + ' ' + endTime).toString();
//     index % 2 === 0
//       ? scheduler.parse([
//           {
//             start_date: startDateTime,
//             end_date: endDateTime,
//             text:
//               getWorkDay(getformated(date), convertMM(scheduler.getState().date), getformated(date) + endTime) +
//               ' | ' +
//               allocatedOrders?.GroupName +
//               ' | ' +
//               allocatedOrders?.OrderNumber +
//               ' | ' +
//               allocatedOrders?.CarShortDesc +
//               ' | ' +
//               allocatedOrders?.TotalFitHours +
//               ' hrs' +
//               ' | ' +
//               allocatedOrders?.CustomerName,
//             data: allocatedOrders,
//             section_id: parseInt(allocatedOrders?.ResourceAssigned, 10),
//           },
//         ])
//       : scheduler.parse([
//           {
//             start_date: startDateTime,
//             end_date: endDateTime,
//             text:
//               getWorkDay(getformated(date), convertMM(scheduler.getState().date), getformated(date) + endTime) +
//               ' | ' +
//               allocatedOrders?.GroupName +
//               ' | ' +
//               allocatedOrders?.OrderNumber +
//               ' | ' +
//               allocatedOrders?.CarShortDesc +
//               ' | ' +
//               allocatedOrders?.TotalFitHours +
//               ' hrs' +
//               ' | ' +
//               allocatedOrders?.CustomerName,
//             data: allocatedOrders,
//             section_id: parseInt(allocatedOrders?.ResourceAssigned, 10),
//           },
//         ]);
//   });

/* 
    - add technician 
    - edit work start and finish time
    - edit break start and finish time
    - add same vent to all 
*/
