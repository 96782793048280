import { Component } from '@angular/core';
import { ORDER_LINE_STATUS } from '@pos-app/data';
import { OrderGridComponent } from '../order-grid/order-grid.component';
import { ICellRenderer } from 'ag-grid-community';
import { R } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-action-header',
  template: `
    <div>
      <span *ngIf="isConvertToCredit() && !isExternalUser()">
        <mat-icon
          class="mt-1"
          (click)="creditAllInvoicedLine()"
          [ngClass]="isAllDispatched(true) ? 'dispatched' : ''"
          title="Credit all Invoiced lines"
        >
          copyright
        </mat-icon>
        <span class="pr-1"></span>
      </span>

      <ng-container *ngIf="!isInvoicedOrder()">
        <span *ngIf="isConvertToOrder() && !isExternalUser()">
          <mat-icon
            (click)="convertAllQuoteToSellsOrder()"
            [ngClass]="{ dispatched: isAllDispatched() }"
            [title]="'Convert all Quote lines to Sales Order'"
          >
            launch
          </mat-icon>
          <span class="pr-1"></span>
        </span>
        <span *ngIf="!isConvertToOrder() && isDispatchable() && !isExternalUser()">
          <mat-icon (click)="sellAllItems()" [ngClass]="{ dispatched: isAllDispatched() }" [title]="'Sell all lines'">
            {{ isTruckIcon() ? 'local_shipping' : 'shopping_cart' }}
          </mat-icon>
          <span class="pr-1"></span>
        </span>
        <mat-icon (click)="deleteAllLine()" title="Delete/Cancel all lines">delete</mat-icon>
      </ng-container>
    </div>
  `,
  styles: [
    `
      .btn {
        line-height: 0.5;
      }
      .dispatched {
        color: green;
      }
    `,
  ],
})
export class OrderGridActionHeaderComponent implements ICellRenderer {
  public params: any;
  public parentComponent: OrderGridComponent;

  //For select and de-select of orders
  public isOrdersSelect: boolean = false;

  agInit(params: any): void {
    this.params = params;
    this.parentComponent = this.params.context.componentParent;
  }

  public deleteAllLine(): void {
    this.parentComponent.deleteAllItems();
  }

  public creditAllInvoicedLine(): void {
    if (!this.isQuickSale() && !this.isCreditOrder()) {
      this.parentComponent.dispatchAllItems(ORDER_LINE_STATUS.credit);
    }
  }

  public sellAllItems(): void {
    if (!this.isQuickSale() && !this.isCreditOrder()) {
      this.parentComponent.dispatchAllItems(ORDER_LINE_STATUS.selling);
    }
  }

  public convertAllQuoteToSellsOrder(): void {
    if (!this.isQuickSale() && !this.isCreditOrder()) {
      this.parentComponent.dispatchAllItems(ORDER_LINE_STATUS.convertQuote);
    }
  }

  public isAllDispatched(isConvertToCredit = false): boolean {
    return this.parentComponent.isAllDispatched(isConvertToCredit);
  }

  public isDispatchable(): boolean {
    return (
      (this.parentComponent.cashDrawer &&
        this.parentComponent.orderHeader &&
        this.parentComponent.orderHeader.customerPaymentTerms === 'POS' &&
        this.parentComponent.orderHeader.dispatchableYN === 'Y') ||
      // when dispatchableYN = 'Y' and payableYN = 'Y' and cash drawer not attached
      // then shopping cart  and SELL GOODS is shown
      (this.parentComponent.orderHeader &&
        this.parentComponent.orderHeader.payableYN === 'Y' &&
        this.parentComponent.orderHeader.dispatchableYN === 'Y' &&
        !this.parentComponent.cashDrawer) ||
      // when dispatchableYN = 'Y' and payableYN = 'N' then shopping cart
      // and SELL GOODS is shown and payment screen is readonly
      (this.parentComponent.orderHeader &&
        this.parentComponent.orderHeader.dispatchableYN === 'Y' &&
        this.parentComponent.orderHeader.payableYN === 'N')
    );
  }

  public isConvertToOrder(): boolean {
    return this.parentComponent.isConvertToOrder;
  }

  public isTruckIcon(): boolean {
    return (
      (this.parentComponent.orderHeader.dispatchableYN === 'Y' && this.parentComponent.orderHeader.payableYN === 'N') ||
      (this.parentComponent.orderHeader.dispatchableYN === 'Y' &&
        this.parentComponent.orderHeader.payableYN === 'Y' &&
        !this.parentComponent.cashDrawer)
    );
  }

  public isConvertToCredit(): boolean {
    return (
      this.parentComponent.orderHeader &&
      this.parentComponent.orderHeader.convertToCreditYN === 'Y' &&
      !this.parentComponent.orderHeader.orderNumber.startsWith('T') &&
      this.parentComponent.gridResult.find((item) => item.lineStatus === ORDER_LINE_STATUS.invoiced) &&
      this.parentComponent.viewSettings.showInvoiced
    );
  }

  public isQuickSale(): boolean {
    return this.parentComponent.orderHeader.quickSaleYN === 'Y';
  }

  public isExternalUser() {
    return this.parentComponent.isExternalUser;
  }

  public isCreditOrder(): boolean {
    return this.parentComponent.isCreditOrder;
  }

  public isInvoicedOrder(): boolean {
    return this.parentComponent.isInvoicedOrder();
  }

  public refresh(): boolean {
    return false;
  }
}
