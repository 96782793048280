import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  MANAGEUSERS_FEATURE_KEY,
  ManageusersState,
} from './manageusers.reducer';

// Lookup the 'Manageusers' feature state managed by NgRx
const getManageusersState = createFeatureSelector<ManageusersState>(
  MANAGEUSERS_FEATURE_KEY
);

const getIsLoadingUnregistered = createSelector(
  getManageusersState,
  (state: ManageusersState) => state.isLoadingUnregisteredUsers
);

const getUnregisteredUsers = createSelector(
  getManageusersState,
  (state: ManageusersState) => state.unregisteredUserList
);
const getIsLoadingRegistered = createSelector(
  getManageusersState,
  (state: ManageusersState) => state.isLoadingRegisteredUsers
);

const getRegisteredUsers = createSelector(
  getManageusersState,
  (state: ManageusersState) => state.registeredUserList
);

const getExternalRegisteredUsers = createSelector(
  getManageusersState,
  (state: ManageusersState) => state.externalRegisteredUserList
);

const getSecurityFunctionList = createSelector(
  getManageusersState,
  (state: ManageusersState) => state.securityFunctionList
);

const getSavingUserSecurityFunctions = createSelector(
  getManageusersState,
  (state: ManageusersState) => state.savingSecurityFunctions
);

const getLookupLists = createSelector(
  getManageusersState,
  (state: ManageusersState) => state.lookupLists
);

const getIsBusy = createSelector(
  getManageusersState,
  (state: ManageusersState) => state.isBusy
);

const getIsCustomerRegistered = createSelector(
  getManageusersState,
  (state: ManageusersState) => state.isCustomerRegistered
);

const getError = createSelector(
  getManageusersState,
  (state: ManageusersState) => state.error
);

const getARBuAvailable = createSelector(
  getManageusersState,
  (state: ManageusersState) => state.isARBuAvailable
);

export const manageusersQuery = {
  getUnregisteredUsers,
  getIsLoadingUnregistered,
  getRegisteredUsers,
  getExternalRegisteredUsers,
  getIsCustomerRegistered,
  getIsLoadingRegistered,
  getSecurityFunctionList,
  getSavingUserSecurityFunctions,
  getLookupLists,
  getIsBusy,
  getError,
  getARBuAvailable,
};
