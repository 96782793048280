import { Component, OnInit, OnDestroy, Inject, LOCALE_ID } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Customer, ManagedUserFilterLists } from '@pos-app/data';
import { ExternalUser, ManagedExternalUser, ReportToUser } from '../../+state/user.model';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { ManageusersPartialState } from '../../+state/manageusers.reducer';
import { Store, select } from '@ngrx/store';
import { manageusersQuery } from '../../+state/manageusers.selectors';
import {
  LoadRegisteredUsers,
  EditExternalUserSecurityFunctions,
  LoadSecurityFunctions,
  ResetRegisteredList,
  LoadLookupLists,
  UpdateAccountCustomerSettings,
} from '../../+state/manageusers.actions';
import { formatDate } from '@angular/common';
import {
  ApiService,
  CoreUiPartialState,
  isExternalUser,
  DialogService,
  hasUnsavedChanges,
  MessageService,
  getDefaultCustomer,
} from '@pos-app/core-ui';
import { takeUntil, switchMap, take, filter, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgbDateStruct, NgbDateParserFormatter, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { OrdersPartialState } from '../../../orders/+state/orders.reducer';
import { LoadParkedOrdersList } from '../../../orders/+state/orders.actions';
import moment from 'moment';

@Component({
  selector: 'app-manageusers-external',
  templateUrl: './manageusers-external.component.html',
  styleUrls: ['./manageusers-external.component.scss'],
})
export class ManageusersExternalComponent implements OnInit, OnDestroy {
  registerUserForm: FormGroup;
  selectedCustomer: Customer;
  registeredUsers$: Observable<ManagedExternalUser[]>;
  registeredUserSearch = new FormControl('');
  disabledUserSearch = new FormControl(false);
  securityFunctionList$: Observable<string[]>;
  userForms: FormGroup[] = [];
  preSelectedCustomerNumber = false;
  newRegisteredEmail: string;
  minDate: NgbDateStruct;
  globalUserSecurityForm: FormGroup;
  lookupLists$: Observable<ManagedUserFilterLists>;
  isBusy$: Observable<boolean>;
  externalUserList: ExternalUser[];
  selectedCustomerName: string;
  isCustomerRegistered$: Observable<boolean>;
  ARBuCohortsList: Array<string> = [];
  reportToUserList: Array<ReportToUser> = [];
  isARBuAvailable: boolean = false;
  private unSubscribe$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<ManageusersPartialState>,
    private orderStore: Store<OrdersPartialState>,
    private apiService: ApiService,
    private toastr: ToastrService,
    private ngbCalendar: NgbCalendar,
    private formatter: NgbDateParserFormatter,
    private coreUIStore: Store<CoreUiPartialState>,
    private dialogService: DialogService,
    private messageService: MessageService,
    @Inject(LOCALE_ID) public locale
  ) {
    this.registerUserForm = this.formBuilder.group({
      email: ['', [Validators.email, Validators.required]],
      name: ['', Validators.required],
    });

    this.globalUserSecurityForm = this.formBuilder.group({
      branch: [''],
      inventoryBranches: [''],
      inventoryView: ['', Validators.required],
      customerNumber: [''],
      currencyCode: [''],
      validOrderTypes: [''],
      displayCurrencyYN: [false],
      displayIncTaxYN: [false],
      displayExTaxYN: [false],
      showNationalInventoryYN: [false],
      ARBuAvailableYN: [false],
    });
  }

  ngOnInit() {
    this.store.dispatch(new ResetRegisteredList());
    this.store.dispatch(new LoadLookupLists());
    this.dispatchParkedOrdersList();
    this.lookupLists$ = this.store.select(manageusersQuery.getLookupLists);
    this.isBusy$ = this.store.select(manageusersQuery.getIsBusy);
    moment.locale(this.locale);

    this.fetchARBuCohorts();

    combineLatest([this.coreUIStore.pipe(select(getDefaultCustomer)), this.coreUIStore.pipe(select(isExternalUser))])
      .pipe(
        take(1),
        switchMap(([customer, externalUser]) => {
          if (externalUser && customer) {
            this.preSelectedCustomerNumber = true;
            Object.keys(this.globalUserSecurityForm.controls).forEach((key) => {
              this.globalUserSecurityForm.controls[key].disable();
            });

            return this.apiService
              .customerSearch({
                customerfuzzySearch: '',
                customerNumber: +customer.customerNumber,
                customerType: 'billto',
              })
              .pipe(map((result) => result?.SearchResults?.find((item) => item.CustomerNumber === customer.customerNumber)));
          }
          return of(null);
        })
      )
      .subscribe((result) => {
        if (result) {
          this.selectCustomer(result);
        }
      });
    this.registeredUsers$ = this.store.pipe(select(manageusersQuery.getExternalRegisteredUsers));
    this.securityFunctionList$ = this.store.pipe(select(manageusersQuery.getSecurityFunctionList));

    this.isCustomerRegistered$ = this.store.pipe(select(manageusersQuery.getIsCustomerRegistered));

    this.registeredUsers$.pipe(takeUntil(this.unSubscribe$)).subscribe((users) => {
      if (this.disabledUserSearch.value) {
        users = users.filter((el) => el.enableAccess === false);
      }
      if (users) {
        this.userForms = [];
        this.reportToUserList = [{ name: '', email: '' }];
        let addedUser = null;
        users.map((user) => {
          this.reportToUserList.push({ name: user.name, email: user.email });
          const userForm = this.formBuilder.group({
            email: [user.email],
            name: [user.name],
            dateCreated: [this.displayDate(user.dateCreated)],
            createdBy: [user.createdBy],
            dateLastLogin: [this.displayDate(user.dateLastLogin)],
            dateEffective: [this.displayDate(user.dateEffective)],
            dateExpiry: [this.displayDate(user.dateExpiry)],
            enableAccess: [user.enableAccess],
            securityFunctions: [user.securityFunctions],
            ARBuCohort: [user.ARBuCohort],
            ARBuReportsTo: [user.ARBuReportsTo],
          });

          if (this.newRegisteredEmail && this.newRegisteredEmail === user.email) {
            addedUser = userForm;
            this.registerUserForm.patchValue({ email: '', name: '' });
          } else {
            this.userForms.push(userForm);
          }

          userForm.valueChanges.pipe(takeUntil(this.unSubscribe$)).subscribe((value) => {
            this.coreUIStore.dispatch(hasUnsavedChanges({ unsavedChanges: true }));
            this.messageService.dispatchAction(hasUnsavedChanges({ unsavedChanges: true }));
          });
        });
        if (addedUser) {
          this.userForms.unshift(addedUser);
        }
      }
    });
    this.store.dispatch(new LoadSecurityFunctions({ type: 'external' }));
    const today = new Date();
    this.minDate = {
      year: today.getFullYear(),
      month: today.getMonth() + 1,
      day: today.getDay(),
    };

    this.apiService
      .getExternalUserList({ activeOnlyYN: 'Y', customerOnlyYN: 'Y' })
      .pipe()
      .subscribe((res) => {
        this.externalUserList = res.SearchResults;
      });

    this.disabledUserSearch.valueChanges.pipe(takeUntil(this.unSubscribe$)).subscribe((val) => this.filterDisabledUser(val));
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  selectCustomer(e) {
    this.selectedCustomer = e;
    if (this.selectedCustomer) {
      this.hasARBuAvailable(this.selectedCustomer.ARBuAvailableYN === 'Y');
      this.globalUserSecurityForm.patchValue({
        branch: this.selectedCustomer.BranchCode,
        inventoryBranches: this.selectedCustomer.inventoryBranches.map((x) => x.BranchCode),
        customerNumber: this.selectedCustomer.CustomerNumber,
        inventoryView: this.selectedCustomer.InventoryView,
        validOrderTypes: this.selectedCustomer.ValidOrderTypes,
        currencyCode: this.selectedCustomer.CurrencyCode,
        displayCurrencyYN: this.selectedCustomer.displayCurrencyYN === 'Y',
        displayExTaxYN: this.selectedCustomer.displayExTaxYN === 'Y',
        displayIncTaxYN: this.selectedCustomer.displayIncTaxYN === 'Y',
        showNationalInventoryYN: this.selectedCustomer.showNationalInventoryYN === 'Y',
        ARBuAvailableYN: this.selectedCustomer.ARBuAvailableYN === 'Y',
      });

      this.store.dispatch(
        new LoadRegisteredUsers({
          CustomerABNumber: this.selectedCustomer.CustomerNumber,
          // includeDisabledUsers: true
        })
      );
    } else {
      this.store.dispatch(new ResetRegisteredList());
    }
  }

  hideRow(i) {
    if (this.registeredUserSearch.value === '' && !this.disabledUserSearch.value) {
      return false;
    } else {
      return (
        this.userForms[i].controls.name.value.toLowerCase().indexOf(this.registeredUserSearch.value.toLowerCase()) === -1 &&
        this.userForms[i].controls.email.value.toLowerCase().indexOf(this.registeredUserSearch.value.toLowerCase()) === -1
      );
    }
  }

  toggleFunction(i, event) {
    const functionName = event.target.name;
    let userFunctions = this.userForms[i].controls.securityFunctions.value as string[];
    userFunctions = Object.assign([], userFunctions);
    if (event.target.checked) {
      userFunctions.push(functionName);
    } else {
      userFunctions = userFunctions.filter((x) => x !== functionName);
    }
    this.userForms[i].patchValue({ securityFunctions: userFunctions });
    this.userForms[i].markAsDirty();
  }
  save(i) {
    if (this.userForms[i].dirty) {
      const dateEffective = this.formatter.parse(this.userForms[i].controls.dateEffective.value);
      const dateExpiry = this.formatter.parse(this.userForms[i].controls.dateExpiry.value);

      if (!this.checkValidDate(dateEffective, dateExpiry)) {
        return;
      }

      this.store.dispatch(
        new EditExternalUserSecurityFunctions({
          email: this.userForms[i].controls.email.value,
          name: this.userForms[i].controls.name.value,
          actionCode: 'C',
          parentCustomer: this.selectedCustomer.CustomerNumber,
          effectiveDate: formatDate(new Date(dateEffective.year, dateEffective.month - 1, dateEffective.day), 'yyyy-MM-ddThh:mm:ss', 'en-au'),
          expiryDate: formatDate(new Date(dateExpiry.year, dateExpiry.month - 1, dateExpiry.day), 'yyyy-MM-ddThh:mm:ss', 'en-au'),
          active: this.userForms[i].controls.enableAccess.value,
          securityFunctions: this.userForms[i].controls.securityFunctions.value,
          ARBuCohort: this.userForms[i].controls.ARBuCohort.value,
          ARBuReportsTo: this.userForms[i].controls.ARBuReportsTo.value,
        })
      );
    }
  }

  registerUser() {
    this.store.dispatch(
      new EditExternalUserSecurityFunctions({
        ...this.registerUserForm.value,
        actionCode: 'A',
        parentCustomer: this.selectedCustomer.CustomerNumber,
        effectiveDate: formatDate(Date.now(), 'yyyy-MM-ddThh:mm:ss', 'en-au'),
        expiryDate: '2040-01-01T00:00:00',
        active: true,
        securityFunctions: [],
      })
    );
    this.newRegisteredEmail = this.registerUserForm.controls.email.value;
    //this.registerUserForm.patchValue({ email: '', name: '' });
  }

  hasFunction(i, f) {
    return this.userForms[i].controls.securityFunctions.value.indexOf(f) !== -1;
  }

  resetPassword(i) {
    this.dialogService
      .confirm(
        `The password will be reset and a new temporary password will be emailed.
        Are you sure you want to reset the password for this user?`
      )
      .pipe(
        take(1),
        switchMap((sel) => {
          if (sel) {
            return this.apiService.forgottenPassword({
              parentCustomer: this.selectedCustomer?.CustomerNumber,
              email: this.userForms[i].controls.email.value,
            });
          }
          return of(null);
        })
      )
      .pipe(
        filter((v) => v != null),
        takeUntil(this.unSubscribe$)
      )
      .subscribe(
        () => {
          this.toastr.success('Password reset email sent');
        },
        () => {
          this.toastr.error('Failed to send new password email. Please try again');
        }
      );
  }

  displayDate(dateStr) {
    // formatDate(dateStr, 'dd/MM/yyyy', 'en-au');
    return moment(dateStr).format('L');
  }

  checkValidDate(dateEffective: NgbDateStruct, dateExpiry: NgbDateStruct) {
    const validExpiry = new Date(dateExpiry.year, dateExpiry.month - 1, dateExpiry.day) > new Date();
    if (!validExpiry) {
      this.toastr.error('Invalid expiry date');
      return false;
    }
    const validSetDate =
      new Date(dateExpiry.year, dateExpiry.month - 1, dateExpiry.day) > new Date(dateEffective.year, dateEffective.month - 1, dateEffective.day);

    if (!validSetDate) {
      this.toastr.error('Effective date must be before expiry date');
      return false;
    }
    return true;
  }

  updateGlobalSetting() {
    if (!this.globalUserSecurityForm.controls.branch.value) {
      this.globalUserSecurityForm.patchValue({ branch: '' });
    }
    if (!this.globalUserSecurityForm.controls.validOrderTypes.value) {
      this.globalUserSecurityForm.patchValue({ validOrderTypes: [] });
    }
    this.store.dispatch(
      new UpdateAccountCustomerSettings({
        ...this.globalUserSecurityForm.value,
        displayCurrencyYN: this.globalUserSecurityForm.controls.displayCurrencyYN.value ? 'Y' : 'N',
        displayExTaxYN: this.globalUserSecurityForm.controls.displayExTaxYN.value ? 'Y' : 'N',
        displayIncTaxYN: this.globalUserSecurityForm.controls.displayIncTaxYN.value ? 'Y' : 'N',
        showNationalInventoryYN: this.globalUserSecurityForm.controls.showNationalInventoryYN.value ? 'Y' : 'N',
        ARBuAvailableYN: this.globalUserSecurityForm.controls.ARBuAvailableYN.value ? 'Y' : 'N',
      })
    );

    this.store
      .select(manageusersQuery.getARBuAvailable)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((response) => {
        this.hasARBuAvailable(response);
      });
  }

  onSelectUser(user: ExternalUser) {
    this.selectedCustomer = null;
    this.selectedCustomerName = null;
    this.userForms = [];

    if (user) {
      this.apiService
        .customerSearch({
          customerfuzzySearch: '',
          customerNumber: +user.parentCustomerNumber,
          customerType: '',
        })
        .pipe(map((result) => result?.SearchResults?.find((item) => +item.CustomerNumber === +user.parentCustomerNumber)))
        .subscribe((customer) => {
          if (customer) {
            this.selectCustomer(customer);
          }
        });
    }
  }

  dispatchParkedOrdersList() {
    this.orderStore.dispatch(new LoadParkedOrdersList());
  }

  fetchARBuCohorts() {
    this.ARBuCohortsList = [''];
    this.apiService
      .fetchARBuCohorts({})
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((res) => {
        if (res.SearchResults && res.SearchResults.length > 0) {
          this.ARBuCohortsList = res.SearchResults.map((result) => {
            return result.Description;
          });
          this.ARBuCohortsList.unshift('');
        }
      });
  }

  hasARBuAvailable(ARBuAvailableYN: boolean) {
    this.isARBuAvailable = ARBuAvailableYN;
  }

  filterDisabledUser(checked) {
    checked
      ? this.store.dispatch(
          new LoadRegisteredUsers({
            CustomerABNumber: this.selectedCustomer.CustomerNumber,
            includeDisabledUsers: true,
          })
        )
      : this.store.dispatch(
          new LoadRegisteredUsers({
            CustomerABNumber: this.selectedCustomer.CustomerNumber,
          })
        );
  }
}
