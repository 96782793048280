import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DisplayOptions, ItemPriceGroup } from '@pos-app/data';

@Component({
  selector: 'pos-app-item-price-scales',
  templateUrl: './item-price-scales.component.html',
  styleUrls: ['./item-price-scales.component.scss'],
})
export class ItemPriceScalesComponent {
  @Input() item: ItemPriceGroup;
  @Input() displayOptions: DisplayOptions;
  @Input() showDescription = true;
  @Input() isExternal: boolean;
  @Input() isInternal: boolean;
  @Input() isShowroomView: boolean;
  @Input() showCloseBtn = true;
  @Input() isDisplayMyPrice = false;

  @Output() closeActiveModal = new EventEmitter<boolean>();

  closeModal() {
    this.closeActiveModal.emit(true);
  }
}
