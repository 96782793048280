import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { environment } from 'apps/pos-app/src/environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler {
  private toastr: any;

  constructor(private injector: Injector) {}

  handleError(err: any) {
    if (!environment.production) {
      console.error(err);
    }
    // ignore ExpressionChangedAfterItHasBeenCheckedError
    // ignore undefined observable stream error
    if (
      err &&
      ((typeof err.toString === 'function' && err.toString()) || '').indexOf(
        'ExpressionChangedAfterItHasBeenCheckedError'
      ) === -1 &&
      ((typeof err.toString === 'function' && err.toString()) || '').indexOf(
        "You provided 'undefined' where a stream was expected"
      ) === -1
    ) {
      this.toastr = this.injector.get(ToastrService);
      this.toastr.error('', '', { timeOut: 1 });
      this.toastr.error(
        err.ErrorMessage
          ? err.ErrorMessage
          : 'There was an error when processing the data'
      );
    }
  }
}
