import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { OrderForBooking, RenameGroupName, Resource } from '@pos-app/data';

@Component({
  selector: 'app-fitters',
  templateUrl: './fitters.component.html',
  styleUrls: ['./fitters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FittersComponent implements OnInit {
  @Input() resourceList: Resource[];
  @Input() assignedOrders: Map<string, OrderForBooking[]>;
  @Input() unAssignedOrdersList: Array<OrderForBooking>;
  @Input() dragDropOrdersIdsList: Array<string>;
  @Output() renameGroupName = new EventEmitter<RenameGroupName>();
  @Output() changeOrderForBookingEvent = new EventEmitter<any>();
  @Output() invokeResourceFuzzySearch = new EventEmitter<any>();

  resource: Resource;

  constructor() {}

  ngOnInit(): void {}

  invokeChangeOrderBooking(event) {
    this.changeOrderForBookingEvent.emit(event);
  }

  updateGroupName(newGroupName: RenameGroupName) {
    this.renameGroupName.emit(newGroupName);
  }

  invokeLoadResourceAvailability(fuzzySearchResource) {
    this.resource = {
      resourceID: fuzzySearchResource.value,
      resourceName: fuzzySearchResource.description,
    };
    this.invokeResourceFuzzySearch.emit(this.resource);
  }
}
