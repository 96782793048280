import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewContainerRef
} from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

@Component({
  selector: 'pos-app-lib-spinner',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {
  title: string;
  body: string;
  faIconClasses = '';
  bodyIsHtml = false;
  okButton = 'OK';
  YesButton = '';
  NoButton = '';
  ShowClose = true;
  otherButton: { action: Observable<any>; text: string } = null;

  constructor(public activeModal: NgbActiveModal) {}

  onOtherButton() {
    this.otherButton.action.subscribe(() => {
      this.activeModal.close(this.otherButton.text);
    });
  }
}
