import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { Printer, CustomerDetails, ORDER_TYPE, ORDER_CONFIRMATION, USER_ROLE } from '@pos-app/data';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ManagedUser } from '../../../manageusers/+state/user.model';

@Component({
  selector: 'app-order-distribute',
  templateUrl: './order-distribute.component.html',
  styleUrls: ['./order-distribute.component.scss'],
})
export class OrderDistributeComponent implements OnInit, OnChanges, OnDestroy {
  @Input() orderType: string;
  @Input() printerList: Printer[];
  @Input() userDetails: ManagedUser;
  @Input() selectedCustomer: CustomerDetails;
  @Input() userRole: string;
  @Input() orderConfirmation: string;
  @Input() isQuickSale: boolean;
  @Input() isCreditOrder: boolean;
  @Input() cashPaymentData: {
    cashChange: number;
    cashTendered: number;
  };
  @Input() isReceiptPrinterConnected: boolean;

  @Output() distributeOrderData = new EventEmitter<any>();
  @Output() updateEmail = new EventEmitter<any>();
  @Output() allowToFinalise = new EventEmitter<boolean>();
  @Output() setIncompleteOrder = new EventEmitter<void>();

  distributeForm: FormGroup;
  USER_ROLE = USER_ROLE;
  unSubscribe$ = new Subject<void>();

  constructor(private fb: FormBuilder) {
    this.distributeForm = this.fb.group({
      toOutput: [false],
      toEmail: [false],
      toPrint: [false],
      toPrintReceipt: [false],
      email: ['', [Validators.email, Validators.required]],
      printerID: [''],
    });
  }

  ngOnInit() {
    this.distributeForm.controls.email.valueChanges
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe(() => this.checkEmail(this.distributeForm.controls.toEmail.value));

    this.distributeForm.controls.toEmail.valueChanges.pipe(takeUntil(this.unSubscribe$)).subscribe((val) => this.checkEmail(val));

    this.distributeForm.valueChanges
      .pipe(
        // not allowing leading or trailing spaces in email
        filter((val) => {
          if (val.email.startsWith(' ') || val.email.endsWith(' ')) {
            this.distributeForm.patchValue({ email: val.email.trim() });
            return false;
          }
          return true;
        }),
        takeUntil(this.unSubscribe$)
      )
      .subscribe((val) => {
        this.distributeOrderData.emit({
          toOutput: this.distributeForm.controls.toOutput.value,
          orderType: this.orderType,
          outputType: this.orderType === ORDER_TYPE.quoteOrder ? 'QT' : !!this.orderConfirmation ? 'OI' : 'OC',
          email: !val.toEmail ? '' : val.email,
          printerID: !val.toPrint || this.userRole === USER_ROLE.external ? '' : val.printerID ? val.printerID : '',
          toPrintReceipt: val.toPrintReceipt,
        });
      });
    this.setIncompleteOrder.emit();
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.distributeForm) {
      this.distributeForm.patchValue({
        toOutput: !!this.orderConfirmation || this.userRole === USER_ROLE.external,
        toPrint: this.isReceiptPrinterConnected ? false : !!this.orderConfirmation,
        toPrintReceipt: !!this.orderConfirmation && this.isReceiptPrinterConnected,
        toEmail: this.userRole === USER_ROLE.external,
        email: this.userRole === USER_ROLE.external ? this.userDetails?.email.trim() : this.selectedCustomer?.Email.trim(),
        printerID:
          this.userRole?.indexOf('SHOW') === -1 ? this.userDetails?.printer : this.printerList?.length > 0 ? this.printerList[0].printerRole : '',
      });

      if (!!this.orderConfirmation) {
        this.distributeForm.controls.toOutput.disable();
      }
      this.distributeForm.updateValueAndValidity();
    }
  }

  updateCustomerEmail() {
    this.updateEmail.emit({
      customerNumber: this.selectedCustomer.CustomerNumber,
      emailAddress: this.distributeForm.controls.email.value,
    });
  }

  getOrderConfirmationHeading() {
    return this.orderType === ORDER_TYPE.quoteOrder
      ? 'QUOTE'
      : this.isCreditOrder
      ? 'CREDIT NOTE'
      : this.orderConfirmation === ORDER_CONFIRMATION.deposit
      ? 'DEPOSIT RECEIPT'
      : this.orderConfirmation === ORDER_CONFIRMATION.invoice
      ? 'INVOICE'
      : 'ORDER CONFIRMATION';
  }

  checkEmail(toEmail) {
    this.allowToFinalise.emit(!toEmail ? true : this.distributeForm.controls.email.valid);
  }
}
