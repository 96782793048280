import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';
import { isValid } from 'date-fns';
import { formatDateForInternal, getFullYearLocaleDate, parseDate } from '../utils-date';

@Injectable({ providedIn: 'root' })
export class CustomAdapter extends NgbDateAdapter<string> {
  constructor(@Inject(LOCALE_ID) public locale) {
    super();
  }
  readonly DELIMITER = '/';
  regex = /yy|y|Y|yyy/g;
  fromModel(dateString: string | null): NgbDateStruct | null {
    if (dateString) {
      if (isValid(parseDate(dateString, this.locale))) {
        const date = formatDateForInternal(dateString, this.locale).split(
          this.DELIMITER
        );

        let parsedYear =
          Math.sign(parseInt(date[2]) - 2000) === -1
            ? 2000 + parseInt(date[2])
            : parseInt(date[2]);

        const parsedDate = {
          day: parseInt(date[0], 10),
          month: parseInt(date[1], 10),
          year: parsedYear,
        };

        return {
          day: parsedDate.day,
          month: parsedDate.month,
          year: parsedDate.year,
        };
      } else {
        const date = dateString.split(this.DELIMITER);
        return {
          day: parseInt(date[0], 10),
          month: parseInt(date[1], 10),
          year: parseInt(date[2], 10),
        };
      }
    }
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date
      ? getFullYearLocaleDate(this.locale, {
          day: date.day,
          monthIndex: date.month - 1,
          year: date.year,
        })
      : null;
  }
}

@Injectable({ providedIn: 'root' })
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';
  constructor(@Inject(LOCALE_ID) public locale) {
    super();
  }
  parse(dateString: string): NgbDateStruct | null {
    if (dateString) {
      if (isValid(parseDate(dateString, this.locale))) {
        const date = formatDateForInternal(dateString, this.locale).split(
          this.DELIMITER
        );

        let parsedYear =
          Math.sign(parseInt(date[2]) - 2000) === -1
            ? 2000 + parseInt(date[2])
            : parseInt(date[2]);

        const parsedDate = {
          day: parseInt(date[0], 10),
          month: parseInt(date[1], 10),
          year: parsedYear,
        };

        return {
          day: parsedDate.day,
          month: parsedDate.month,
          year: parsedDate.year,
        };
      } else {
        const date = dateString.split(this.DELIMITER);
        return {
          day: parseInt(date[0], 10),
          month: parseInt(date[1], 10),
          year: parseInt(date[2], 10),
        };
      }
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? getFullYearLocaleDate(this.locale, {
          year: date.year,
          monthIndex: date.month - 1, // 0 - 11 is for Jan - Dec Url: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date
          day: date.day,
        })
      : // date.day + this.DELIMITER + date.month + this.DELIMITER + date.year
        '';
  }
}
