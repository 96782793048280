<form [formGroup]="vehicleDetailsForm">
  <div class="card">
    <div class="card-body">
      <div>
        {{ selectedCustomerVehicle?.VehicleFullDescription }}
      </div>
      <div class="form-group row pt-3">
        <label for="regoNumber" class=" col-4 col-xl-2">Registration*</label>
        <div class="col-8 col-xl-5">
          <input
            type="text"
            class="form-control w-100"
            name="regoNumber"
            [class.is-invalid]="
              vehicleDetailsForm.controls.regoNumber.touched &&
              vehicleDetailsForm.controls.regoNumber.errors
            "
            formControlName="regoNumber"
            (keyup.enter) = "pressEnter()"
            [value]="vehicleDetailsForm.controls.regoNumber.value | uppercase"
            autofocus
          />
          <div
            class="invalid-feedback"
            *ngIf="
              vehicleDetailsForm.controls.regoNumber.touched &&
              vehicleDetailsForm.controls.regoNumber.errors?.required
            "
          >
            This field is required
          </div>
          <div
            class="invalid-feedback"
            *ngIf="
              vehicleDetailsForm.controls.regoNumber.touched &&
              vehicleDetailsForm.controls.regoNumber.errors?.pattern
            "
          >
            The registration number is invalid
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="regoState" class=" col-4 col-xl-2">State</label>
        <div class="col-8 col-xl-5">
          <ng-select
            [items]="stateList"
            formControlName="regoState"
            bindLabel="StateDescription"
            bindValue="StateCode"
            class="w-100"
          >
          </ng-select>
        </div>
      </div>
      <div class="form-group row">
        <label for="country" class=" col-4 col-xl-2">Country</label>
        <div class="col-8 col-xl-5">
          <ng-select
            [items]="lookupLists?.countryList"
            formControlName="country"
            bindLabel="CountryDescription"
            bindValue="CountryCode"
            class="w-100"
          >
          </ng-select>
        </div>
      </div>
      <div class="form-group row">
        <label for="vinNumber" class=" col-4 col-xl-2">VIN</label>
        <div class="col-8 col-xl-5">
          <input
            type="text"
            class="form-control w-100"
            name="vinNumber"
            [class.is-invalid]="
              vehicleDetailsForm.controls.vinNumber.touched &&
              vehicleDetailsForm.controls.vinNumber.errors
            "
            formControlName="vinNumber"
            (keyup.enter) = "pressEnter()"
          />
          <div
            class="invalid-feedback"
            *ngIf="
              vehicleDetailsForm.controls.vinNumber.touched &&
              vehicleDetailsForm.controls.vinNumber.errors?.pattern
            "
          >
            The VIN is invalid
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="colourCode" class=" col-4 col-xl-2">Colour Code</label>
        <div class="col-8 col-xl-5">
          <ng-select
            [items]="lookupLists?.colourList"
            formControlName="colourCode"
            bindLabel="ColoursDescription"
            bindValue="ColoursDescription"
            class="w-100"
          >
          </ng-select>
        </div>
      </div>
      <div class="form-group row">
        <label for="description" class=" col-4 col-xl-2">Short description</label>
        <div class="col-8 col-xl-5">
          <input
            type="text"
            class="form-control w-100"
            name="shortDescription"
            formControlName="shortDescription"
            (keyup.enter) = "pressEnter()"
          />
        </div>
      </div>
    </div>
  </div>
</form>
