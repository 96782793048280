import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApiService } from '@pos-app/core-ui';
import { Show, ShowSale } from '@pos-app/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetShowSalesAction } from '../../+state/customers.actions';
import { CustomersPartialState } from '../../+state/customers.reducer';
import { customersQuery } from '../../+state/customers.selectors';

@Component({
  selector: 'app-customers-show-sales',
  templateUrl: './customers-show-sales.component.html',
  styleUrls: ['./customers-show-sales.component.scss'],
})
export class CustomersShowSalesComponent implements OnInit {
  loading$: Observable<boolean>;
  customerShowList$: Observable<Show[]>;
  customerShowSales$: Observable<ShowSale[]>;
  columnDefs;
  gridOptions;
  searchValue = '';

  constructor(private apiService: ApiService, private store: Store<CustomersPartialState>) {
    this.gridOptions = {
      defaultColDef: {
        resizable: true,
        sortable: true,
        width: 200,
        cellClass: 'cell-class ag-right-aligned-cell',
        headerClass: 'header-class ag-right-aligned-header',
      },
      columnDefs: [
        {
          headerName: 'ORDER NUMBER',
          width: 150,
          field: 'orderNumber',
        },
        {
          headerName: 'Order TYPE',
          field: 'orderTypeDescription',
        },
        {
          headerName: 'SHIP TO',
          field: 'shipToName',
        },
        {
          headerName: 'ITEM CODE',
          width: 150,
          field: 'itemCode',
        },
        {
          headerName: 'ITEM DESCRIPTION',
          field: 'itemDescription',
        },
        {
          headerName: 'QUANTITY',
          width: 100,
          field: 'qtyOrdered',
        },
        {
          headerName: 'UNIT PRICE',
          width: 100,
          field: 'unitPrice',
          valueFormatter: this.currencyFormatter,
        },
        {
          headerName: 'EXT PRICE',
          width: 100,
          field: 'extPrice',
          valueFormatter: this.currencyFormatter,
        },
        {
          headerName: 'SALES REP',
          field: 'salesRep',
        },
        {
          headerName: 'CUSTOMER NAME',
          field: 'customerName',
        },
        {
          headerName: 'CUSTOMER ADDRESS',
          field: 'customerAddress1',
        },
        {
          headerName: 'CUSTOMER PHONE',
          field: 'customerPhone',
        },
        {
          headerName: 'DEPOSIT',
          width: 100,
          field: 'deposit',
        },
      ],
    };
  }

  ngOnInit() {
    this.customerShowList$ = this.apiService.getCustomerShowList().pipe(map((res) => res.SearchResults));
    this.loading$ = this.store.select(customersQuery.getLoadingCustomerShowSales);
    this.customerShowSales$ = this.store.select(customersQuery.getCustomerShowSales);
  }

  getShowSales(showCode: string) {
    this.store.dispatch(new GetShowSalesAction({ showCode }));
  }

  currencyFormatter(params) {
    return params.value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }
  onGridReady(params) {
  }
}
