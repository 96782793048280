import { VehicleFilterDropdownValues, Vehicle } from '../../models/vehicle-data';
import { IVehicleState } from './vehicle.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EPC_VEHICLE_FEATURE_KEY } from './vehicles.reducer';

const getVehicleState = createFeatureSelector<IVehicleState>(EPC_VEHICLE_FEATURE_KEY);

const getVehicleDropdown = (state: IVehicleState): VehicleFilterDropdownValues => state.Vehicles;
const getVehicles = (state: IVehicleState): Vehicle[] => state.Vehicles.Vehicles;
const getLoaded = (state: IVehicleState): boolean => state.IsLoaded;
const getTooManyVehicles = (state: IVehicleState): string => state.Vehicles.TooManyVehiclesYN;
const getUsedAsFilter = (state: IVehicleState): boolean => state.UsedAsFilter;
const getShowedUniversalProducts = (state: IVehicleState): boolean => state.ShowedUniversalProducts;
const getSelectedVehicle = (state: IVehicleState): Vehicle => state.VehicleSelected;
const getRequestSelectVehicle = (state: IVehicleState): Vehicle => state.VehicleToBeSelected;
const getIsAdvancedSearch = (state: IVehicleState): boolean => state.AdvancedSearch;
const getSearchString = (state: IVehicleState): string => state.SearchString;
const getPaRtsVehicleID = (state: IVehicleState): number => state.paRtsVehicleID;
const getInitialized = (state: IVehicleState): boolean => state.Initiated;

const getVehicleFilterDropdownValues = createSelector(getVehicleState, getVehicleDropdown);

const getStateVehicles = createSelector(getVehicleState, getVehicles);

const getStateLoaded = createSelector(getVehicleState, getLoaded);

const getStateTooManyVehicles = createSelector(getVehicleState, getTooManyVehicles);

const getStateUsedAsFilter = createSelector(getVehicleState, getUsedAsFilter);

const getStateShowedUniversalProducts = createSelector(getVehicleState, getShowedUniversalProducts);

const getStateSelectedVehicle = createSelector(getVehicleState, getSelectedVehicle);

const getStateRequestSelectVehicle = createSelector(getVehicleState, getRequestSelectVehicle);

const getStateIsAdvancedSearch = createSelector(getVehicleState, getIsAdvancedSearch);

const getStateSearchString = createSelector(getVehicleState, getSearchString);

const getStatePaRtsVehicleID = createSelector(getVehicleState, getPaRtsVehicleID);

const getStateInitialized = createSelector(getVehicleState, getInitialized);

export {
  getVehicleFilterDropdownValues,
  getStateLoaded,
  getStateTooManyVehicles,
  getStateUsedAsFilter,
  getStateShowedUniversalProducts,
  getStateVehicles,
  getStateSelectedVehicle,
  getStateRequestSelectVehicle,
  getStateSearchString,
  getStatePaRtsVehicleID,
  getStateIsAdvancedSearch,
  getStateInitialized,
};
