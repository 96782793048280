import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrderType } from '@pos-app/data';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-orders-search',
  templateUrl: './orders-search.component.html',
  styleUrls: ['./orders-search.component.scss']
})
export class OrdersSearchComponent implements OnInit {
  @Input() orderTypes: OrderType[];
  @Input() hasCashDrawer: boolean;
  @Output() fetchJDEOrder = new EventEmitter<any>();
  orderNumber: string;
  orderSearchForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.orderSearchForm = this.formBuilder.group({
      orderNumber: ['', [Validators.required]],
      orderType: [this.orderTypes[0].OrderTypeCode, [Validators.required]]
    });
  }

  fetchOrder() {
    let orderNumber = this.orderSearchForm.get('orderNumber').value as string;
    let orderType = this.orderSearchForm.get('orderType').value;

    if (orderNumber && orderType) {
      if (orderNumber.includes('.')) {
        const splittedStr = orderNumber.split('.');
        orderNumber = splittedStr[0];
        orderType = splittedStr[1];
      }
      this.fetchJDEOrder.emit({ orderNumber: orderNumber.trim(), orderType });
    } else {
      this.toastr.error('Please provide a valid Order Number and Order Type');
    }
  }
}
