<div class="container">
  <table class="table table-borderless text-right mt-2">
    <thead>
      <tr>
        <th scope="col"></th>
        <th scope="col"><label>EX.TAX</label></th>
        <th scope="col"><label>TAX</label></th>
        <th scope="col" *ngIf="depositAmount > 0"><label>DEPOSITS</label></th>
        <th
          scope="col"
          *ngIf="minPayment > 0 && minPayment > depositAmount && !isQuoteOrder"
        >
          <label>MIN PAYMENT</label>
        </th>
        <th scope="col" class="border-top border-right border-left border-dark">
          <label>TOTAL</label>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="text-center">
          <label>ORDER TOTALS</label>
        </td>
        <td>
          {{ totalValueEx | currency: currencyCode : 'symbol-narrow' }}
        </td>
        <td>
          {{ totalValue - totalValueEx | currency: currencyCode : 'symbol-narrow' }}
        </td>
        <td *ngIf="depositAmount > 0">
          {{ depositAmount | currency: currencyCode : 'symbol-narrow' }}
        </td>
        <td *ngIf="minPayment > 0 && minPayment > depositAmount && !isQuoteOrder">
          {{ minPayment - depositAmount | currency: currencyCode : 'symbol-narrow' }}
        </td>
        <td class="border-bottom border-right border-left border-dark">
          {{ totalValue | currency: currencyCode : 'symbol-narrow' }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
