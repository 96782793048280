import { CdkDragDrop, CdkDragEnter } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { OrderForBooking, RenameGroupName, Resource } from '@pos-app/data';

@Component({
  selector: 'app-orders-for-fitter',
  templateUrl: './orders-for-fitter.component.html',
  styleUrls: ['./orders-for-fitter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrdersForFitterComponent implements OnInit {
  @Input() fitter: Resource;
  @Input() ordersAssignedToFitter: OrderForBooking[];
  @Input() dragDropOrdersIdsList: Array<string>;
  @Output() orderAddedToFitterEvent = new EventEmitter<any>();

  @Output() renameGroupName = new EventEmitter<RenameGroupName>();

  draggedOrder: OrderForBooking;

  constructor() {}

  ngOnInit(): void {}

  orderDrop(event: CdkDragDrop<string[]>) {
    this.invokeChangeOrderBooking({
      previousContainer: event.previousContainer,
      currentContainer: event.container,
      previousIndex: event.previousIndex,
      currentIndex: event.currentIndex,
      orderNumber:
        event.previousContainer.data[event.previousIndex][
          'OrderNumber'
        ].toString(),
      orderType: event.previousContainer.data[event.previousIndex]['OrderType'],
      GroupName: event.previousContainer.data[event.previousIndex]['GroupName'],
      resourceID: this.fitter.resourceID,
      resourceName: this.fitter.resourceName,
      startDate: !!event.previousContainer.data[event.previousIndex][
        'InvoiceDate'
      ].trim()
        ? event.previousContainer.data[event.previousIndex][
            'InvoiceDate'
          ].trim()
        : event.previousContainer.data[event.previousIndex][
            'RequestDate'
          ].trim(),
      endDate: !!event.previousContainer.data[event.previousIndex][
        'InvoiceDate'
      ].trim()
        ? event.previousContainer.data[event.previousIndex][
            'InvoiceDate'
          ].trim()
        : event.previousContainer.data[event.previousIndex][
            'RequestDate'
          ].trim(),
      startTime: 0,
      endTime: 0,
      isOrderAssignedToFitter: true,
    });
  }

  invokeChangeOrderBooking(orderDragDrop: any) {
    this.orderAddedToFitterEvent.emit(orderDragDrop);
  }

  updateGroupName(newGroupName: RenameGroupName) {
    this.renameGroupName.emit(newGroupName);
  }
}
