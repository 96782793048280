import { Component, OnInit, OnDestroy, Output, EventEmitter, AfterViewInit, ChangeDetectorRef, ViewChild, TemplateRef } from '@angular/core';
import { GetFilterDataOutput, CriteriaFilterDto, IGetFilterDataOutput, CEDCategoryDto, ICEDCategoryDto } from '../../models/service-proxies';
import { Store, select } from '@ngrx/store';
import {
  getStateSelectedVehicle,
  getStateUsedAsFilter,
  getStateShowedUniversalProducts,
  ShowUniversalProducts,
  RequestVehicleSelection,
} from '../../+store/vehicles';
import {
  getStateFilter,
  UpdateFilter,
  getStateFilterData,
  ClearAllFilter,
  ResetFilter,
  UpdateFilterData,
  GetCedCategories,
  getCedCategories,
} from '../../+store/filter';
import { Vehicle } from '../../models/vehicle-data';
import { Subscription, Subject, Observable, combineLatest } from 'rxjs';
import { takeUntil, filter, take, map, debounceTime, delay, tap, withLatestFrom } from 'rxjs/operators';
import * as _ from 'lodash';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { TreeNode } from 'primeng/api';
import { ArrayToTreeConverterService } from '../../shared/services/array-to-tree-converter.service';
import { TreeDataHelperService } from '../../shared/services/tree-data-helper.service';
import { EpcApiService } from '../../services/epc-api.service';
import { AppConsts } from '../../constants/AppConsts';
import { IMainState } from '../../+store';
import { MessageService } from '@pos-app/core-ui';
import { OOESessionService } from 'libs/core-ui/src/lib/services/ooe-session.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-product-filter-list',
  templateUrl: './product-filter-list.component.html',
  styleUrls: ['./product-filter-list.component.scss'],
})
export class ProductFilterListComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('selectVehicleContent') selectVehicleContent: TemplateRef<any>;

  @Output() public selectCategory = new EventEmitter<{
    isRoot: boolean;
    cedCategoryId: number;
    parentCEDCategoryId?: number;
  }>();

  public shouldShowProductFilters: boolean = true;
  public treeData: any;
  public selectedCategoryId: number;
  public selectedCategory: TreeNode<ICEDCategoryDto>;

  public categoryData: any = {};
  public attributeData: any[] = []; //[{isCollapsed: bool, groups: []}]
  public attributeDataWithRelationship: any[] = []; //[{isCollapsed: bool, groups: []}]
  public attributeDataDisplay: any[] = []; //[{isCollapsed: bool, groups: []}]
  public brandData: any = {}; // [{isCollapsed: bool, brands: []}]
  public subbrandData: any = {};
  public useVehicleInFilter: boolean;
  public selectedVehicle: Vehicle;
  public showUniversalProducts: boolean;

  public vehicleSelectedSubscription: Subscription;

  public filterData$: Observable<GetFilterDataOutput>;

  public queryParams: any;
  public vehicleRequiredYPN: 'Y' | 'N' | 'P' = 'N';

  public cedCategories$ = this.store.select(getCedCategories).pipe(
    tap((cedCategories) => {
      this.initTreeData(cedCategories);
    })
  );

  private tobeSelectedCategory: TreeNode<ICEDCategoryDto>;

  private componentDestroyed$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private arrayToTreeConverterService: ArrayToTreeConverterService,
    private treeDataHelperService: TreeDataHelperService,
    private store: Store<IMainState>,
    private epcApiService: EpcApiService,
    private messageService: MessageService,
    private ooeSessionService: OOESessionService,
    private ngbModal: NgbModal,
    private cdr: ChangeDetectorRef
  ) {
    this.route.queryParams.subscribe((params) => {
      this.queryParams = params;
    });

    this.messageService
      .listen('selectCedCategory')
      .pipe(
        takeUntil(this.componentDestroyed$),
        withLatestFrom(this.store.select(getCedCategories)),
        map(([cedCategoryId, cedCategories]) => {
          return cedCategories?.find((item) => +item.cedCategoryId === +cedCategoryId) || null;
        })
      )
      .subscribe((cedCategory) => {
        if (cedCategory) {
          const node = this.treeDataHelperService.findNode(this.treeData, {
            data: { cedCategoryId: cedCategory.cedCategoryId },
          });
          node.parent = this.treeDataHelperService.findNode(this.treeData, {
            data: { cedCategoryId: cedCategory.parentCEDCategoryId },
          });

          this.onSelectCategory({ node });
        }
      });
  }

  public ngOnInit(): void {
    this.store.dispatch(GetCedCategories());

    if (this.router.url.toLowerCase() === '/epc' || this.router.url.toLowerCase().startsWith('/epc?')) {
      this.store.dispatch(ClearAllFilter({ payload: null }));
    }

    if (this.router.url === '/epc' || this.router.url.startsWith('/epc?') || this.router.url.startsWith('/epc/product')) {
      this.shouldShowProductFilters = false;
    } else {
      this.shouldShowProductFilters = true;
    }

    this.router.events.pipe(takeUntil(this.componentDestroyed$)).subscribe((e) => {
      if (e instanceof NavigationStart) {
        if (e.url === '/epc' || e.url?.startsWith('/epc?') || e.url?.startsWith('/epc/product')) {
          this.shouldShowProductFilters = false;
        } else {
          this.shouldShowProductFilters = true;
        }
      }
    });
  }

  public ngAfterViewInit(): void {
    this.store
      .select(getStateFilterData)
      .pipe(takeUntil(this.componentDestroyed$), delay(0))
      .subscribe((filterData) => {
        if (filterData.categoryId) {
          this.selectedCategoryId = filterData.categoryId;

          this.buildOtherFilterComponent(filterData);
        }
      });

    combineLatest([
      this.store.select(getStateUsedAsFilter),
      this.store.select(getStateSelectedVehicle),
      this.store.select(getStateShowedUniversalProducts),
    ])
      .pipe(
        takeUntil(this.componentDestroyed$),
        map((data) => {
          const [usedAsFilter, selectedVehicle, showedUniversalProducts] = data;

          this.useVehicleInFilter = usedAsFilter;
          this.selectedVehicle = selectedVehicle;
          this.showUniversalProducts = showedUniversalProducts;
          this.cdr.detectChanges();

          return data;
        }),
        debounceTime(500)
      )
      .subscribe(() => {
        if ((!this.selectedVehicle || !this.useVehicleInFilter) && this.vehicleRequiredYPN === 'Y') {
          this.router.navigateByUrl('/epc');
        }

        this.updateCriteria();
      });

    this.store
      .pipe(
        select(getStateFilter),
        filter((val) => val.updated),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((filter) => {
        if (filter.cedCategoryId && this.selectedCategoryId != filter.cedCategoryId) {
          this.selectedCategoryId = filter.cedCategoryId;
          this.autoSelectCategory();
        }
      });

    this.messageService
      .listen('landingPageAccessed')
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        this.onAccessLandingPage();
      });
  }

  public ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  public initTreeData(cedCategories: ICEDCategoryDto[]): void {
    this.treeData = [];

    if (cedCategories?.length > 0) {
      this.treeData = this.arrayToTreeConverterService.createTree(cedCategories, 'parentCEDCategoryId', 'cedCategoryId', null, 'children', [
        {
          target: 'styleClass',
          value: 'category-tree-node',
        },
        {
          target: 'label',
          targetFunction(item) {
            return item.name;
          },
        },
        {
          target: 'selectable',
          value: true,
        },
      ]);

      this.autoSelectCategory();
    }
  }

  public onAccessLandingPage(): void {
    this.store.dispatch(ResetFilter({ payload: { categoryId: 0 } }));
    this.selectedCategoryId = 0;
    this.autoSelectCategory();
  }

  public updateShowUniversalProducts(): void {
    this.store.dispatch(ShowUniversalProducts({ payload: this.showUniversalProducts }));
  }

  public updateCriteria(): void {
    if (this.selectedCategoryId) {
      this.getCriterias(
        this.selectedCategoryId,
        this.selectedVehicle ? Number(this.selectedVehicle.partsVehicleID) : null,
        this.showUniversalProducts
      );
    }
  }

  public buildOtherFilterComponent(result: IGetFilterDataOutput): void {
    this.brandData = {
      isCollapsed: this.brandData.isCollapsed == undefined ? true : this.brandData.isCollapsed,
      brands: _.map(result.brandFilterList, (br) => {
        return { ...br, isSelected: false };
      }),
    };
    this.subbrandData = {
      isCollapsed: this.subbrandData.isCollapsed == undefined ? true : this.subbrandData.isCollapsed,
      subbrands: _.map(result.subBrandFilterList, (sbr) => {
        return { ...sbr, isSelected: false };
      }),
    };
    this.categoryData = {
      isCollapsed: this.categoryData.isCollapsed == undefined ? true : this.categoryData.isCollapsed,
      groups: _.map(result.categoryFilterList, (cat) => {
        return { ...cat, isSelected: false };
      }),
    };
    this.attributeData = this.getAttributeGroup(result.groupAttributesFilterList);
    this.attributeDataWithRelationship = result.groupAttributesFilterWithRelationshipList;
    this.attributeDataDisplay = this.buildAttributeDataDisplay(result.groupAttributesFilterList);

    this.store
      .select(getStateFilter)
      .pipe(take(1))
      .subscribe((filters) => {
        let currentState = filters;

        _.forEach(this.brandData.brands, (t) => {
          if (currentState.brandIds && currentState.brandIds.includes(t.value)) {
            t.isSelected = true;
          }
        });

        _.forEach(this.subbrandData.subbrands, (t) => {
          if (currentState.subBrands && currentState.subBrands.includes(t.value)) {
            t.isSelected = true;
          }
        });

        _.forEach(this.categoryData.categories, (t) => {
          if (currentState.categories && currentState.categories.includes(t.cedCategoryId)) {
            t.isSelected = true;
          }
        });

        if (currentState.attributes) {
          _.forEach(this.attributeData, (attG) => {
            _.forEach(attG.groups, (att) => {
              if (currentState.attributes.find((sAtt) => sAtt.attribute == att.attribute && sAtt.value == att.value) != null) {
                att.isSelected = true;
              }
            });
          });
        }
        if (currentState.attributesWithRelationship) {
          _.forEach(this.attributeDataDisplay, (attG) => {
            _.forEach(attG.groups, (att) => {
              if (currentState.attributesWithRelationship.find((sAtt) => sAtt.attribute == att.attribute && sAtt.value == att.value) != null) {
                att.isSelected = true;

                _.forEach(att.childItems, (attChild) => {
                  if (
                    currentState.attributesWithRelationship.find(
                      (sAtt) =>
                        sAtt.attribute == att.attribute &&
                        sAtt.value == att.value &&
                        sAtt.childItems.find(
                          (sAttchild) =>
                            sAttchild.attribute == attChild.attribute && sAttchild.value == attChild.value && sAttchild.isSelected == true
                        )
                    ) != null
                  ) {
                    attChild.isSelected = true;
                  }
                  attChild.isDisabled = false;
                });
              }
            });
          });
        }

        if (currentState.filterGroupUIStatus) {
          _.forEach(currentState.filterGroupUIStatus, (group) => {
            if (group.groupTitle == 'Brand') this.brandData.isCollapsed = group.isCollapsed;
            else if (group.groupTitle == 'Sub Brand') this.subbrandData.isCollapsed = group.isCollapsed;
            else {
              _.forEach(this.attributeDataDisplay, (attG) => {
                if (attG.groupTitle == group.groupTitle) attG.isCollapsed = group.isCollapsed;
              });
            }
          });
        }
      });
  }

  public getCriterias(categoryId: number, vehicleId?: number, showedUniversalProducts: boolean = false): void {
    if (this.ooeSessionService.priceLists?.length) {
      this.epcApiService
        .getGroupAttributesFilterList(categoryId, AppConsts.brand, this.ooeSessionService.priceLists, vehicleId || undefined, showedUniversalProducts)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((results: CriteriaFilterDto[]) => {
          this.attributeData = this.getAttributeGroup(results);
          this.store.dispatch(UpdateFilterData({ payload: { key: 'Attributes', value: results } }));
        });
    } else {
      this.attributeData = this.getAttributeGroup([]);
      this.store.dispatch(UpdateFilterData({ payload: { key: 'Attributes', value: [] } }));
    }
  }

  public getAttributeGroup(attributes) {
    let groups = [];
    let groupParents = attributes.filter((att) => att.group == null);
    if (groupParents.length > 0) {
      _.forEach(groupParents, (parent) => {
        let groupAtts = [];

        _.map(
          attributes.filter((att) => att.group == parent.attribute),
          (att) => {
            groupAtts.push({ ...att, isSelected: false });
          }
        );

        groups.push({ isCollapsed: true, groups: groupAtts });
      });
    }

    return groups;
  }

  public buildAttributeDataDisplay(attributes) {
    let returnGroups = [];
    let groupParents = attributes.filter((att) => att.group == null);
    if (groupParents.length > 0) {
      var listChildName: string[] = [];
      _.forEach(groupParents, (parent) => {
        let groupAtts = [];
        var groupTitle = parent.value;
        _.map(
          attributes.filter((att) => att.group == parent.attribute),
          (att) => {
            groupAtts.push({ ...att, isSelected: false });
          }
        );

        returnGroups.push({
          groupTitle: groupTitle,
          isCollapsed: true,
          groups: groupAtts,
        });
      });
      var childList = [];

      for (var k = 0; k < returnGroups.length; k++) {
        var parentAtt = returnGroups[k];
        for (var j = 0; j < parentAtt.groups.length; j++) {
          parentAtt.groups[j].childItems = [];
          for (var i = 0; i < this.attributeDataWithRelationship.length; i++) {
            var relationShip = this.attributeDataWithRelationship[i];
            if (
              attributes.filter((k) => k.attribute == relationShip.parentAttributeName && k.value == relationShip.parentAttributeValue).length > 0 &&
              attributes.filter((k) => k.attribute == relationShip.childAttributeName && k.value == relationShip.childAttributeValue).length > 0
            ) {
              if (
                parentAtt.groups[j].attribute == relationShip.parentAttributeName &&
                parentAtt.groups[j].value == relationShip.parentAttributeValue
              ) {
                _.map(
                  attributes.filter((att) => att.attribute == relationShip.childAttributeName && att.value == relationShip.childAttributeValue),
                  (att) => {
                    parentAtt.groupTitle = relationShip.parentAttributeName + '/' + relationShip.childAttributeName;
                    parentAtt.isHasRelationshipGroup = true;
                    childList.push(att);
                    parentAtt.groups[j].childItems.push({
                      ...att,
                      parentName: parentAtt.groups[j].attribute,
                      isSelected: false,
                      isDisabled: true,
                    });
                  }
                );
              }
            }
          }
        }
      }
      for (var l = 0; l < childList.length; l++) {
        var childAtt = childList[l];
        for (var m = 0; m < returnGroups.length; m++) {
          var element = returnGroups[m];
          element.groups = element.groups.filter(function (el) {
            return el.attribute != childAtt.attribute || el.value != childAtt.value;
          });
        }
      }
    }
    return returnGroups;
  }

  public getAttributeGroupName(attributeGroup): string {
    if (attributeGroup.length > 0) {
      return attributeGroup[0].attribute;
    }

    return '';
  }

  public showParentNodes(node): void {
    if (!node.parent) {
      return;
    }

    node.parent.styleClass = '';
    this.showParentNodes(node.parent);
  }

  public updateFilters(key): void {
    let payload = {
      key: key,
      value: null,
      page: 1,
    };
    if (key == 'categories' && this.categoryData.categories) {
      payload.value = _.map(
        this.categoryData.categories.filter((cat) => cat.isSelected),
        (cat) => {
          return cat.cedCategoryId;
        }
      );
    } else if (key == 'brandIds' && this.brandData.brands) {
      payload.value = _.map(
        this.brandData.brands.filter((br) => br.isSelected),
        (br) => {
          return br.value;
        }
      );
    } else if (key == 'subBrands' && this.subbrandData.subbrands) {
      payload.value = _.map(
        this.subbrandData.subbrands.filter((sbr) => sbr.isSelected),
        (sbr) => {
          return sbr.value;
        }
      );
    } else if (key == 'attributes') {
      let selectedAtts = [];

      _.forEach(this.attributeData, (attG) => {
        _.map(
          attG.groups.filter((att) => att.isSelected),
          (att) => {
            selectedAtts.push({
              attribute: att.attribute,
              value: att.value,
              isLinkedAttribute: att.isLinkedAttribute,
            });
          }
        );
      });

      payload.value = selectedAtts;
    } else if (key === 'ResetAll') {
      payload.value = this.selectedCategoryId;
    }

    this.store.dispatch(UpdateFilter({ payload: payload }));
  }

  public updateFilterGroupUIStatus(item): void {
    let payload = {
      key: 'filterGroupUIStatus',
      value: null,
      page: 1,
    };
    item.isCollapsed = !item.isCollapsed;

    let uiGroupStatus = [];

    uiGroupStatus.push(
      {
        groupTitle: 'Brand',
        isCollapsed: this.brandData.isCollapsed,
      },
      {
        groupTitle: 'Sub Brand',
        isCollapsed: this.subbrandData.isCollapsed,
      }
    );

    _.forEach(this.attributeDataDisplay, (attG) => {
      uiGroupStatus.push({
        groupTitle: attG.groupTitle,
        isCollapsed: attG.isCollapsed,
      });
    });

    payload.value = uiGroupStatus;

    this.store.dispatch(UpdateFilter({ payload: payload }));
  }

  public updateFiltersForAttWithRelationship(item, itmIndex, itemGroup): void {
    let payload = {
      key: 'attributesWithRelationship',
      value: null,
      page: 1,
    };
    let selectedAtts = [];

    if (!item.parentName) {
      if (itemGroup.isHasRelationshipGroup) {
        if (itemGroup.groups) {
          var parentGroups = itemGroup.groups;
          for (var i = 0; i < parentGroups.length; i++) {
            var unSelectItem = parentGroups[i];
            if (i != itmIndex) {
              unSelectItem.isSelected = false;
              for (var index = 0; index < unSelectItem.childItems.length; index++) {
                unSelectItem.childItems[index].isSelected = false;
                unSelectItem.childItems[index].isDisabled = true;
              }
            }
          }
        }
      }

      if (item.childItems) {
        _.forEach(item.childItems, (childItem) => {
          if (item.isSelected == false) {
            childItem.isSelected = false;
          }

          if (item.childItems.length == 1 && item.isSelected == true) {
            childItem.isSelected = true;
          }
          childItem.isDisabled = !item.isSelected;
        });
      }
    } else {
      // have parrent -> child
      for (var i = 0; i < itemGroup.length; i++) {
        var unSelectItem = itemGroup[i];
        if (i != itmIndex) {
          unSelectItem.isSelected = false;
        }
      }
    }

    _.forEach(this.attributeDataDisplay, (attG) => {
      _.map(
        attG.groups.filter((att) => att.isSelected),
        (att) => {
          var attChildItem = [];
          if (att.childItems) {
            att.childItems.forEach((element) => {
              if (element.isSelected == true) {
                attChildItem.push(element);
              }
            });
          }
          selectedAtts.push({
            attribute: att.attribute,
            value: att.value,
            isLinkedAttribute: att.isLinkedAttribute,
            childItems: attChildItem,
          });
        }
      );
    });
    payload.value = selectedAtts;
    this.store.dispatch(UpdateFilter({ payload: payload }));
  }

  public onSelectVehicle(vehicle: Vehicle): void {
    this.store.dispatch(
      RequestVehicleSelection({
        payload: vehicle,
      })
    );

    this.setCategory(this.tobeSelectedCategory);
  }

  public onCancelSelectVehicle(): void {
    if (this.vehicleRequiredYPN === 'P') {
      this.setCategory(this.tobeSelectedCategory);
    }
  }

  public onSelectCategory(event: { node: TreeNode<ICEDCategoryDto> }): void {
    const categorySelected: ICEDCategoryDto = event.node.data;

    if (!categorySelected) {
      return;
    }

    this.vehicleRequiredYPN = categorySelected.vehicleRequiredYPN;
    this.tobeSelectedCategory = event.node;

    if (!this.selectedVehicle && (categorySelected.vehicleRequiredYPN === 'Y' || categorySelected.vehicleRequiredYPN === 'P')) {
      this.ngbModal.open(this.selectVehicleContent, {
        windowClass: 'vehicle-selection-modal',
        size: 'xl',
      });

      return;
    }

    this.setCategory(event.node);
  }

  private setCategory(node: TreeNode<ICEDCategoryDto>): void {
    if (node.data.parentCEDCategoryId && node.data.cedCategoryId !== this.selectedCategoryId) {
      this.resetFilter();
    }

    this.selectedCategoryId = node.data.cedCategoryId;
    this.selectedCategory = node;

    let children = this.treeDataHelperService.findChildren(this.treeData, {
      data: { cedCategoryId: this.selectedCategoryId },
    });

    if (children) {
      var theNode = this.treeDataHelperService.findNode(this.treeData, {
        data: { cedCategoryId: this.selectedCategoryId },
      });
      if (theNode.expanded) {
        theNode.expanded = false;
      } else {
        theNode.expanded = true;
      }
    }

    let parent = this.treeDataHelperService.findParent(this.treeData, {
      data: { cedCategoryId: this.selectedCategoryId },
    });

    var eventData = {
      isRoot: !parent,
      cedCategoryId: this.selectedCategoryId,
      parentCEDCategoryId: this.selectedCategory.data.parentCEDCategoryId,
    };

    this.selectCategory.emit(eventData);
  }

  public resetFilter(): void {
    _.forEach(this.categoryData.categories, (cat) => (cat.isSelected = false));
    _.forEach(this.brandData.brands, (br) => (br.isSelected = false));
    _.forEach(this.subbrandData.subbrands, (sbr) => (sbr.isSelected = false));
    _.forEach(this.attributeData, (attG) =>
      _.forEach(attG.groups, (att) => {
        att.isSelected = false;
      })
    );

    this.updateFilters('ResetAll');
  }

  private autoSelectCategory(): void {
    if (this.treeData == null) {
      return;
    }

    // landing page access => selectedCategoryId = 0
    if (!this.selectedCategoryId) {
      this.selectedCategory = null;
      _.forEach(this.treeData, (item) => {
        let node = this.treeDataHelperService.findNode(this.treeData, {
          data: {
            cedCategoryId: item.data.cedCategoryId,
          },
        });

        node.expanded = false;
      });
    }

    this.selectedCategory = this.treeDataHelperService.findNode(this.treeData, {
      data: { cedCategoryId: this.selectedCategoryId },
    });

    // expand parents
    if (this.selectedCategory != null) {
      let parent = this.treeDataHelperService.findNode(this.treeData, {
        data: {
          cedCategoryId: this.selectedCategory.data.parentCEDCategoryId,
        },
      });

      if (parent != null) {
        let self = this;
        setTimeout(function () {
          // wait for the tree to be built completely
          parent.expanded = true;
          let grandParent = self.treeDataHelperService.findNode(self.treeData, {
            data: { cedCategoryId: parent.data.parentCEDCategoryId },
          });
          if (grandParent != null) {
            grandParent.expanded = true;
          }
        }, 200);
      }
    }
  }
}
