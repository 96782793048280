<div class="order-for-booking-card rounded-1 py-3 px-2 my-2">
  <div class="row">
    <div class="col-auto font-lg">
      {{ orderForBookingCard.OrderNumber }} ({{
        orderForBookingCard.TotalFitHours
      }})
    </div>
    <div *ngIf="isSplitOrder() || isConsolidateOrder()" class="col">
      <span class="float-end">
        <mat-icon
          (click)="
            isSplitOrder()
              ? invokeConsolidateOrderOrder()
              : isConsolidateOrder()
              ? invokeSplitOrder()
              : null
          "
          [title]="
            isSplitOrder()
              ? 'Consolidate the Order'
              : isConsolidateOrder()
              ? 'Split the Order'
              : null
          "
          class="mat-lg"
        >
          {{
            isSplitOrder()
              ? 'merge_type'
              : isConsolidateOrder()
              ? 'call_split'
              : null
          }}
        </mat-icon>
      </span>
    </div>
  </div>
  <div class="row">
    <div class="col-auto">
      {{ orderForBookingCard.CustomerName }}
      {{ orderForBookingCard.CustomerPhone }}
    </div>
  </div>
  <div class="row">
    <div class="col-auto">
      {{ orderForBookingCard.CarShortDesc }}
    </div>
  </div>
  <div class="row">
    <div class="col-auto">
      <strong>REQ:</strong>{{ orderForBookingCard.RequestDate
      }}<span
        *ngIf="
          orderForBookingCard.InvoiceDate &&
          orderForBookingCard.InvoiceDate.trim().length > 0
        "
        >&nbsp;<strong>INV:</strong>{{ orderForBookingCard.InvoiceDate }}</span
      >
    </div>
  </div>
  <div class="row d-flex justify-content-center">
    <div class="col">
      <input
        class="form-control"
        placeholder="Enter Group Name"
        [value]="
          !!orderForBookingCard.GroupName ? orderForBookingCard.GroupName : ''
        "
        (blur)="updateGroupName($event)"
      />
    </div>
  </div>
  <div
    *ngIf="hasArrayOfLines()"
    [id]="
      orderForBookingCard.OrderNumber +
      '-' +
      orderForBookingCard.OrderType +
      '-' +
      orderForBookingCard.GroupName
    "
    cdkDropList
    cdk-scrollable
    [cdkDropListData]="orderForBookingCard.ArrayOfLines"
    [cdkDropListConnectedTo]="dragDropArrayOfLineIdsList"
    (cdkDropListDropped)="invokeReassignLine($event)"
    [cdkDropListDisabled]="isArrayOfLinesDisabled"
  >
    <div
      *ngFor="let ArrayOfLine of orderForBookingCard.ArrayOfLines"
      class="row align-items-start m-2 border border-1 border-dark"
      cdkDragLockAxis="y"
      cdkDrag
      [cdkDragData]="ArrayOfLine"
      (cdkDragStarted)="itemExitedParentContainer($event)"
    >
      <div class="col">
        <app-array-of-line-card
          [ArrayOfLineCard]="ArrayOfLine"
        ></app-array-of-line-card>
      </div>
    </div>
  </div>
</div>
