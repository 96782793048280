export const CUSTOMER_TYPE = {
  billTo: 'BILLTO',
  retail: 'RETAIL',
  shipTo: 'SHIPTO'
};

export const INVOICE_TO = [
  {
    code: 'S',
    description: 'SHIP TO'
  },
  {
    code: 'B',
    description: 'BILL TO'
  },
  {
    code: 'P',
    description: 'PARENT'
  }
];

export const NOTE_TASK_ACTIVITY_TYPE = {
  Note: 'COMMENT',
  Task: 'TODOTASK'
};

export const NOTE_TASK_ACTION_CODE = {
  Add: 'A',
  Delete: 'D',
  Edit: 'C'
};

export const BRANCH_CALENDAR_FILTER = {
  MyCalendar: 'MyCalendar',
  BranchCalendar: 'BranchCalendar'
};
