<form [formGroup]="camperCustomerDetailsForm" (keydown.enter)="$event.preventDefault()">
  <div class="row gx-4">
    <div class="col-auto header-label my-auto">
      <label class="d-flex">
        CUSTOMER

        <mat-icon (click)="invokeAddNewCustomer()" title="Add a customer">add_circle_outline</mat-icon>
        <mat-icon (click)="invokeSelectNewCustomer()" title="Edit customer">edit</mat-icon>
      </label>
    </div>
    <div class="col-2 d-flex p-2">
      <a style="cursor: pointer" class="form-control overflow-hidden" (click)="invokeEditCustomer()">
        {{ camperCustomerDetailsForm.controls.customerName.value }}
      </a>
    </div>
    <div class="col-auto header-label my-auto">
      <label class="d-flex">PH </label>
    </div>
    <div class="col-2 d-flex p-2">
      <span class="form-control overflow-hidden no-decoration">{{ camperCustomerDetailsForm.controls.customerPhone.value }}</span>
    </div>
    <div class="col-auto header-label my-auto">
      <label class="d-flex">EMAIL </label>
    </div>
    <div class="col-2 d-flex p-2">
      <a class="form-control overflow-hidden no-decoration">{{ camperCustomerDetailsForm.controls.customerEmail.value }}</a>
    </div>
    <div class="col d-flex float-end justify-content-end">
      <button [disabled]="disableGenerateReport" (click)="invokeGenerateReport()" class="navlink btn">GENERATE REPORT</button>
    </div>
  </div>
</form>
