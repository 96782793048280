import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  takeUntil,
} from 'rxjs/operators';

@Component({
  selector: 'pos-app-date-picker-single',
  templateUrl: './date-picker-single.component.html',
  styleUrls: ['./date-picker-single.component.scss'],
})
export class DatePickerSingleComponent implements OnInit, OnDestroy, OnChanges {
  @Input() showError: boolean;
  @Input() allowFutureDate: boolean = false;
  @Input() allowPastDate: boolean = true;
  @Input() initialDate: string;
  @Output() selectedDate = new EventEmitter<string>();
  datePickerForm = new FormControl('');
  todayDate = new NgbDate(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    new Date().getDate()
  );
  minDate: NgbDate;

  unSubscribe$ = new Subject<void>();
  constructor(private formatter: NgbDateParserFormatter) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.initialDate) {
      this.datePickerForm.patchValue(this.initialDate ? this.initialDate : '');
    }

    if (changes.allowPastDate) {
      this.minDate = this.allowPastDate
        ? new NgbDate(1900, 1, 1) // Set minimum date to 1900-01-01 if allowPastDate is true
        : this.todayDate;
    }
  }

  ngOnInit() {
    this.datePickerForm.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.unSubscribe$)
      )
      .subscribe((value) => {
        if (!value) {
          this.selectedDate.emit('');
          this.datePickerForm.setErrors(
            { invalid: false },
            { emitEvent: false }
          );
          return;
        }
        const pickedDate = this.formatter.parse(value);
        if (
          isNaN(pickedDate.day) ||
          isNaN(pickedDate.month) ||
          isNaN(pickedDate.year)
        ) {
          this.selectedDate.emit('');
          this.datePickerForm.setErrors(
            { invalid: true },
            { emitEvent: false }
          );
          return;
        }
        const momentDate = moment(
          `${pickedDate.day}/${pickedDate.month}/${
            pickedDate.year < 100 ? 2000 + pickedDate.year : pickedDate.year
          }`,
          'D/M/YYYY',
          true
        );
        const today = moment().startOf('day');
        if (
          !momentDate.isValid() ||
          (this.allowFutureDate ? '' : momentDate.isAfter(today)) ||
          (this.allowPastDate ? '' : momentDate.isBefore(today))
        ) {
          this.selectedDate.emit('');
          this.datePickerForm.setErrors(
            { invalid: true },
            { emitEvent: false }
          );
          return;
        }
        this.datePickerForm.setErrors({ invalid: false }, { emitEvent: false });
        this.selectedDate.emit(momentDate.format('DD/MM/YYYY'));
      });
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }
}
