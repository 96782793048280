<div
  class="full-overlay"
  *ngIf="show"
  [class.full-overlay]="!partial"
  [class.partial-overlay]="partial"
>
  <div *ngIf="!!showTextLoader" class="loading-container">
    <div class="loading-text">
      {{ displayText }}
    </div>
  </div>
  <div class="spinner">
    <div class="bounce1"></div>
    <div class="bounce2"></div>
    <div class="bounce3"></div>
  </div>
</div>
