import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { CustomersPartialState } from '../../+state/customers.reducer';
import { Store } from '@ngrx/store';
import { customersQuery } from '../../+state/customers.selectors';
import {
  LoadCustomerVehicles,
  MaintainCustomerVehicle,
  MakeVehicleDefault
} from '../../+state/customers.actions';
import { CustomerVehicle, LookupLists } from '@pos-app/data';
import { takeUntil } from 'rxjs/operators';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { formatDate } from '@angular/common';
import { CoreUiPartialState, isExternalUser } from '@pos-app/core-ui';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-customers-vehicles-maintenance',
  templateUrl: './customers-vehicles-maintenance.component.html',
  styleUrls: ['./customers-vehicles-maintenance.component.scss']
})
export class CustomersVehiclesMaintenanceComponent
  implements OnInit, OnDestroy {
  public lookupLists$: Observable<LookupLists>;
  public customerVehicles$: Observable<CustomerVehicle[]>;
  public isLoadingCustomerVehicles$: Observable<boolean>;
  public selectedCustomerNumber: string;
  public showRetired = false;
  public selectedVehicle: CustomerVehicle = null;
  public retireCodeForm = new FormControl('001');
  public hasActiveVehicle$: Observable<boolean>;
  public customerVehicles: CustomerVehicle[];
  public defaultVehicle$: Observable<CustomerVehicle>;
  public vehicleCustomerIDCopied = false;
  public vehicleIDCopied = false;
  public copiedValue = '';
  public isExternalUser$: Observable<boolean>;
  private unSubscribe$ = new Subject<void>();
  @ViewChild('popOver') popOver: NgbPopover;

  constructor(
    private store: Store<CustomersPartialState>,
    private coreUiStore: Store<CoreUiPartialState>,
    private clipboardService: ClipboardService
  ) {}

  ngOnInit() {
    this.clipboardService.configure({ cleanUpAfterCopy: true });
    this.store
      .select(customersQuery.getSelectedCustomer)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe(res => {
        setTimeout(() => {
          this.selectedCustomerNumber = res.CustomerNumber;
          this.store.dispatch(
            new LoadCustomerVehicles({
              customerNumber: this.selectedCustomerNumber,
              retiredVehicleYN: 'Y'
            })
          );
        }, 0);
      });

    this.isExternalUser$ = this.coreUiStore.select(isExternalUser);

    this.customerVehicles$ = this.store.select(
      customersQuery.getCustomerVehicles
    );

    this.hasActiveVehicle$ = this.store.select(
      customersQuery.getHasActiveVehicles
    );

    this.isLoadingCustomerVehicles$ = this.store.select(
      customersQuery.getLoadingCustomerVehicles
    );

    this.lookupLists$ = this.store.select(customersQuery.getLookupLists);

    this.defaultVehicle$ = this.store.select(customersQuery.getDefaultVehicle);
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  selectVehicle(vehicle) {
    this.selectedVehicle = vehicle;
  }

  retire() {
    this.popOver.close();
    const retireForm = {
      customerNumber: this.selectedCustomerNumber,
      actionCode: 'R',
      customerVehicleID: this.selectedVehicle.customerVehicleID,
      dateRetired: formatDate(Date.now(), 'dd/MM/yy', 'en-au'),
      reasonCodeRetired: this.retireCodeForm.value
    };
    this.store.dispatch(new MaintainCustomerVehicle(retireForm));
    this.selectedVehicle = null;
  }

  unRetire() {
    const unRetireForm = {
      customerNumber: this.selectedCustomerNumber,
      actionCode: 'U',
      customerVehicleID: this.selectedVehicle.customerVehicleID
    };
    this.store.dispatch(new MaintainCustomerVehicle(unRetireForm));
    this.selectedVehicle = null;
  }

  makeDefault(defaultVehicle: CustomerVehicle) {
    const makeDefaultPayload = {
      default: {},
      selectedVehicle: {
        customerNumber: this.selectedCustomerNumber,
        customerVehicleID: this.selectedVehicle.customerVehicleID,
        actionCode: 'C',
        regoNumber: this.selectedVehicle.RegoNumber,
        regoState: this.selectedVehicle.RegoState,
        vinNumber: this.selectedVehicle.VinNumber,
        colourCode: this.selectedVehicle.ColourCode,
        shortDescription: this.selectedVehicle.ShortDescription,
        fullDescription: this.selectedVehicle.VehicleFullDescription,
        vehicleID: this.selectedVehicle.VehicleID,
        defaultFlag: '1'
      }
    };

    makeDefaultPayload.default = defaultVehicle
      ? {
          customerNumber: this.selectedCustomerNumber,
          customerVehicleID: defaultVehicle.customerVehicleID,
          actionCode: 'C',
          regoNumber: defaultVehicle.RegoNumber,
          regoState: defaultVehicle.RegoState,
          vinNumber: defaultVehicle.VinNumber,
          colourCode: defaultVehicle.ColourCode,
          shortDescription: defaultVehicle.ShortDescription,
          fullDescription: defaultVehicle.VehicleFullDescription,
          vehicleID: defaultVehicle.VehicleID,
          defaultFlag: '0'
        }
      : null;

    this.store.dispatch(new MakeVehicleDefault(makeDefaultPayload));
    this.selectedVehicle = null;
  }

  showRetiredToggle() {
    this.showRetired = !this.showRetired;
    this.selectedVehicle = null;
  }

  copyToClipBoard(value, isCustomerVehicleID = false) {
    this.clipboardService.copy(value);
    this.vehicleCustomerIDCopied = isCustomerVehicleID;
    this.vehicleIDCopied = !isCustomerVehicleID;
    this.copiedValue = value;
  }
}
