import {
  formatCurrency,
  formatNumber,
  getLocaleCurrencyCode,
} from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getCurrencyValueFormat } from 'libs/core-ui/src/lib/utils/locale-helper';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ActionDetails } from '../../../../core/models/warranty-form.models';

@Component({
  selector: 'app-warrantyform-action',
  templateUrl: './warrantyform-action.component.html',
  styleUrls: ['./warrantyform-action.component.scss'],
})
export class WarrantyformActionComponent implements OnInit, OnDestroy {
  @Output() actionDetails = new EventEmitter<{
    value: ActionDetails;
    isValid: boolean;
  }>();
  actionForm: FormGroup;
  unSubscribe$ = new Subject<void>();

  constructor(private fb: FormBuilder, @Inject(LOCALE_ID) public locale) {
    this.actionForm = this.fb.group({
      labour: [
        0,
        [Validators.min(0), Validators.pattern('^[0-9]+(.[0-9]+)?$')],
      ],
      freight: [
        0,
        [Validators.min(0), Validators.pattern('^[0-9]+(.[0-9]+)?$')],
      ],
      other: [0, [Validators.min(0), Validators.pattern('^[0-9]+(.[0-9]+)?$')]],
    });
  }

  ngOnInit() {
    this.actionDetails.emit({
      value: this.actionForm.value,
      isValid: this.actionForm.valid,
    });
    this.actionForm.valueChanges
      .pipe(debounceTime(300), takeUntil(this.unSubscribe$))
      .subscribe((actionForm) =>
        this.actionDetails.emit({
          value: actionForm,
          isValid: this.actionForm.valid,
        })
      );
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  getAmount(value: string) {
    return isNaN(+value) ? Number(value) : Number((+value).toFixed(2));
  }

  patchValue(key, value) {
    value = getCurrencyValueFormat(value, this.locale);
    this.actionForm.patchValue({
      [key]: this.getAmount(value),
    });
  }
}
