import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  ApiService,
  CoreUiPartialState,
  getOrderTypes,
} from '@pos-app/core-ui';
import {
  CustomerDetails,
  ListTasksNotes,
  OrderType,
} from '@pos-app/data';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomersPartialState } from '../../+state/customers.reducer';
import { customersQuery } from '../../+state/customers.selectors';
import {
  GetAlertMessageAction,
  ListTasksNotesAction,
  UpdateAlertMessageAction,
} from '../../+state/customers.actions';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { getLocalLocaleDate } from 'libs/core-ui/src/lib/utils/utils-date';

@Component({
  selector: 'app-customers-notes',
  templateUrl: './customers-notes.component.html',
  styleUrls: ['./customers-notes.component.scss'],
})
export class CustomersNotesComponent implements OnInit {
  public loadingTasksNotesList: boolean;
  public loadingUpdateAlertMessage: boolean;
  public selectedCustomer: CustomerDetails;
  public orderTypes: OrderType[];
  private unSubscribe$ = new Subject<void>();
  isEditOrAddNoteTask: boolean;
  routedParamCustomerNumber: number = this.route.snapshot.params.id;
  searchValue: string;
  rowData = [];
  listTaskNotesFilter: ListTasksNotes;

  noteTaskForm: FormGroup;

  constructor(
    private store: Store<CustomersPartialState>,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private coreUiStore: Store<CoreUiPartialState>,
    private formBuilder: FormBuilder,
    @Inject(LOCALE_ID) public locale
  ) {}

  ngOnInit() {
    this.noteTaskForm = this.formBuilder.group({
      alertMessageLastUpdated: [''],
      alertMessageText: [''],
      alertMessageLastUpdatedBy: [''],
    });

    this.getListTaskNotes();

    this.store.dispatch(
      new GetAlertMessageAction({
        customerNumber: this.routedParamCustomerNumber,
      })
    );
    this.store
      .select(customersQuery.getAlertMessage)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((alertMessage) => {
        if (
          alertMessage &&
          alertMessage.alertUpdatedDate !== ' ' &&
          alertMessage.alertUpdatedTime !== 0
        ) {
          this.noteTaskForm.patchValue({
            alertMessageText: alertMessage.alertMessage,
            alertMessageLastUpdated: `${getLocalLocaleDate(
              alertMessage.alertUpdatedDate,
              this.locale
            )} - ${alertMessage.alertUpdatedTime}`,
            alertMessageLastUpdatedBy: alertMessage.alertUpdatedBy,
          });
        }
      });
    this.apiService
      .getCustomerDetails({
        customerNumberSearch: this.routedParamCustomerNumber,
      })
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((customerDetails) => {
        this.selectedCustomer = customerDetails.SearchResults[0];
      });
    this.store
      .select(customersQuery.getLoadingTasksNotesList)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((loadingTasksNotes) => {
        this.loadingTasksNotesList = loadingTasksNotes;
      });
    this.store
      .select(customersQuery.getTasksNotesList)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((tasksNotes) => {
        this.rowData = tasksNotes;
      });
    this.store
      .select(customersQuery.getLoadingAlertMessage)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((loadingAlertMessage) => {
        this.loadingUpdateAlertMessage = loadingAlertMessage;
      });
    this.coreUiStore
      .select(getOrderTypes)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((orderTypes) => {
        this.orderTypes = orderTypes;
      });
  }

  getListTaskNotes() {
    this.initListTaskNotesFilter();
    this.listTaskNotesFilter.customerNumber =
      this.routedParamCustomerNumber.toString();
    this.store.dispatch(new ListTasksNotesAction(this.listTaskNotesFilter));
  }

  saveAlertMessage() {
    this.store.dispatch(
      new UpdateAlertMessageAction({
        actionCode: 'A',
        customerNumber: this.routedParamCustomerNumber,
        alertMessage: this.noteTaskForm.controls.alertMessageText.value,
      })
    );
  }

  clearAlertMessage() {
    this.noteTaskForm.patchValue({
      alertMessageText: '',
      alertMessageLastUpdated: '',
      alertMessageLastUpdatedBy: '',
    });

    this.store.dispatch(
      new UpdateAlertMessageAction({
        actionCode: 'D',
        customerNumber: this.routedParamCustomerNumber,
        alertMessage: '',
      })
    );
  }

  initListTaskNotesFilter() {
    this.listTaskNotesFilter = {
      type: '',
      ownerIdentifier: '',
      customerNumber: '',
      assignee: '',
      completeYN: '',
      fromDate: '',
      toDate: '',
    };
  }
}
