<div class="d-flex flex-row-reverse">
  <div class="col-auto customers-notes__quick-filter">
    <input
      type="text"
      class="form-control"
      [(ngModel)]="searchValue"
      placeholder="Quick filter..."
    />
  </div>
</div>
<ag-grid-angular
  #agGrid
  class="ag-theme-balham datagrid py-2 ml-n3 mr-n3"
  [context]="context"
  [frameworkComponents]="frameworkComponents"
  [gridOptions]="gridOptions"
  (gridReady)="onGridReady($event)"
  [domLayout]="'autoHeight'"
  [pagination]="true"
  [paginationPageSize]="10"
  [quickFilterText]="searchValue"
>
</ag-grid-angular>

<div class="row py-2">
  <div class="col">
    <div class="row">
      <mat-icon title="Load order into staging">refresh</mat-icon>
      <p>Open order in ARB Online</p>
    </div>
  </div>
  <div class="col">
    <div class="row">
      <mat-icon title="View File Attachment">attachment</mat-icon>
      <p>View File Attachment</p>
    </div>
  </div>
  <div class="col">
    <div class="row">
      <mat-icon title="Complete Task">check</mat-icon>
      <p>Complete Task</p>
    </div>
  </div>
  <div class="col">
    <div class="row">
      <mat-icon title="Edit Note">edit</mat-icon>
      <p>Edit Note</p>
    </div>
  </div>
  <div class="col">
    <div class="row">
      <mat-icon title="Delete Note">delete</mat-icon>
      <p>Delete Note</p>
    </div>
  </div>
</div>
<div class="row py-2">
  <div></div>
  <div class="ml-auto">
    <button class="btn btn-secondary" (click)="navigateToEditNote()">
      NEW NOTE
    </button>
    <button class="btn btn-cancel ml-3" (click)="navigateBack()">RETURN</button>
  </div>
</div>

<app-customers-notes-form
  [selectedCustomer]="selectedCustomer"
  [listTaskNotesFilter]="listTaskNotesFilter"
></app-customers-notes-form>

<app-customers-notes-complete-task
  [listTaskNotesFilter]="listTaskNotesFilter"
></app-customers-notes-complete-task>

<app-customers-notes-file-attachment></app-customers-notes-file-attachment>
