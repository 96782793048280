import { createAction, props, union } from '@ngrx/store';
import { VehicleSearchResult, VehicleSearchParamters, Vehicle, VehicleToBeSelect } from './vehicle';

const SET_USER_SELECTED_VEHICLE = '[EPC-Vehicle] Set User Selected Vehicle';
const LOAD_VEHICLE_SUCCESS = '[EPC-Vehicle] Load Vehicles Success';
const LOAD_VEHICLE = '[EPC-Vehicle] Load Vehicles';
const ADVANCED_SEARCH_STATUS = '[EPC-Vehicle] Advanced Search Status';
const SEARCH_STRING = '[EPC-Vehicle] Search String';
const SELECT_VEHICLE = '[EPC-Vehicle] Select Vehicle';
const USE_IN_FILTER = '[EPC-Vehicle] Use Vehicle In Search';
const SHOW_UNIVERSAL_PRODUCTS = '[EPC-Vehicle] Show universal products ';
const CLEAR_REQUEST_SELECT_VEHICLE = '[EPC-Vehicle] Clear The select Vehicle Request';
const REQUEST_SELECT_VEHICLE = '[EPC-Vehicle] Request to select vehicle';
const RESET_VEHICLE_SEARCH = '[EPC-Vehicle] Reset Vehicle Search';
const DESELECT_VEHICLE = '[EPC-Vehicle] Deselect Vehicle';
const INIT_SELECTED_VEHICLE = '[EPC-Vehicle] Deselect Vehicle';
const UPDATE_VEHICLE_DESCRIPTION = '[EPC-Vehicle] Update Vehicle short description';
const SET_PARTS_VEHICLE_ID = '[EPC-Vehicle] Set partsVehicleID';

export const SetUserSelectedVehicle = createAction(SET_USER_SELECTED_VEHICLE, props<{ payload: any }>());
export const LoadVehiclesSuccess = createAction(LOAD_VEHICLE_SUCCESS, props<{ payload: VehicleSearchResult }>());
export const LoadVehicles = createAction(LOAD_VEHICLE, props<{ payload: VehicleSearchParamters }>());
export const AdvancedSearchStatus = createAction(ADVANCED_SEARCH_STATUS, props<{ payload: any }>());
export const SearchString = createAction(SEARCH_STRING, props<{ payload: string }>());
export const SetPartsVehicleID = createAction(SET_PARTS_VEHICLE_ID, props<{ payload: number }>());
export const SelectVehicle = createAction(SELECT_VEHICLE, props<{ payload: Vehicle & { customerVehicleID?: number } }>());
export const UseInFilter = createAction(USE_IN_FILTER, props<{ payload: any }>());
export const ShowUniversalProducts = createAction(SHOW_UNIVERSAL_PRODUCTS, props<{ payload: any }>());
export const RequestVehicleSelection = createAction(REQUEST_SELECT_VEHICLE, props<{ payload: VehicleToBeSelect }>());
export const ClearRequestSelectVehicle = createAction(CLEAR_REQUEST_SELECT_VEHICLE);
export const ResetVehicleSearch = createAction(RESET_VEHICLE_SEARCH);
export const DeselectVehicle = createAction(DESELECT_VEHICLE);
export const InitSelectedVehicle = createAction(INIT_SELECTED_VEHICLE, props<{ payload: Vehicle }>());
export const UpdateSelectedVehicle = createAction(SELECT_VEHICLE, props<{ payload: Vehicle }>());
export const UpdateVehicleDescription = createAction(UPDATE_VEHICLE_DESCRIPTION, props<{ payload: any }>());

const all = union({
  SetUserSelectedVehicle,
  LoadVehiclesSuccess,
  LoadVehicles,
  AdvancedSearchStatus,
  SearchString,
  SetPartsVehicleID,
  SelectVehicle,
  DeselectVehicle,
  ClearRequestSelectVehicle,
  InitSelectedVehicle,
});
export type VehicleActions = typeof all;
