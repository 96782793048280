import {
  APP_INITIALIZER,
  DEFAULT_CURRENCY_CODE,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocalizationService } from '../services/localization.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (LocalizationService: LocalizationService) => {
        return LocalizationService.localeInitializer();
      },
      deps: [LocalizationService],
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useFactory: (LocalizationService: LocalizationService) => {
        return LocalizationService.getDefaultLocaleId();
      },
      deps: [LocalizationService],
    },
    { provide: DEFAULT_CURRENCY_CODE, useValue: '' },
  ],
})
export class LocalizationModule {}
