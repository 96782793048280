<pos-app-spinner [show]="isLoading"></pos-app-spinner>
<div class="px-3 overflow-auto pb-5">
  <h3 class="text-center py-3 arb-large-heading">RESOURCES</h3>
  <div *ngIf="!isLoading">
    <div *ngIf="allFiles.length > 0; else elseBlock">
      <div class="row">
        <div *ngIf="allLinks.length > 0" class="col-3">
          <label>RELATED WEBSITES</label>
          <div *ngFor="let link of allLinks">
            <a (click)="openLink(link.id, true)">
              {{ link.name }}
            </a>
          </div>
        </div>
        <div class="col-9">
          <div class="d-flex pb-3">
            <div class="w-50">
              <select
                [(ngModel)]="selectedCategory"
                class="form-control"
                (change)="onSelectCategory()"
              >
                <option value="">ALL CONTENT CATEGORIES </option>
                <option
                  *ngFor="let category of categories"
                  [value]="category"
                  >{{ category }}</option
                >
              </select>
            </div>
            <div class="w-50 d-flex pl-3">
              <form>
                <input
                  type="search"
                  [formControl]="contentSearch"
                  class="form-control"
                  placeholder="Filter content"
                />
              </form>
            </div>
          </div>
          <div>
            <label *ngIf="contentToShow.length === 0"
              >NO RESOURCES. PLEASE REFINE YOUR FILTERS</label
            >
            <table
              class="table table-sm table-bordered"
              *ngIf="contentToShow.length > 0"
            >
              <thead>
                <tr>
                  <th scope="col" class="px-3"><label>FILE</label></th>
                  <th scope="col"><label>CATEGORY</label></th>
                  <th scope="col"><label>SIZE (MB)</label></th>
                  <th scope="col"><label>DATE UPDATED</label></th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let file of contentToShow; let i = index">
                  <tr
                    *ngIf="
                      !hideRow(i) &&
                      (!selectedCategory || file.directory === selectedCategory)
                    "
                  >
                    <td class="px-3">
                      <a (click)="openLink(file.id)">
                        {{ file.name }}
                      </a>
                    </td>
                    <td class="pr-3">
                      {{ file.directory }}
                    </td>
                    <td class="pr-3">
                      {{ file.size / (1024 * 1024) | number }}
                    </td>
                    <td class="pr-3">
                      {{ file.lastModified | date: 'd/M/yyyy' }}
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
          <div
            class="d-flex justify-content-between p-2"
            *ngIf="contentToShow.length > pageSize"
          >
            <ngb-pagination
              [collectionSize]="contentToShow.length"
              [(page)]="page"
              [pageSize]="pageSize"
              [maxSize]="5"
              [boundaryLinks]="true"
            >
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
    <ng-template #elseBlock>
      <label>NO RESOURCES AVAILABLE FOR THE CURRENT CUSTOMER</label>
    </ng-template>
  </div>
</div>
