<form [formGroup]="orderSearchForm" class="pt-4 pb-2 px-0 container-fluid">
  <label>ORDER SEARCH</label>
  <div class="row py-2">
    <div class="col">
      <label>ORDER NUMBER</label>
      <input
        autofocus
        class="form-control"
        formControlName="orderNumber"
        maxlength="25"
      />
    </div>
    <div class="col">
      <label>ORDER TYPE</label>
      <ng-select
        formControlName="orderType"
        [ngClass]="{
          'border border-danger rounded':
            orderSearchForm.controls.orderType.errors
        }"
        [clearable]="false"
      >
        <ng-option
          *ngFor="let orderType of orderTypes"
          [value]="orderType.OrderTypeCode"
          >{{ orderType.OrderTypeDescription }}</ng-option
        >
      </ng-select>
    </div>
    <div class="col d-flex align-items-end justify-content-end">
      <button class="navlink btn" (click)="fetchOrder()">FETCH ORDER</button>
    </div>
  </div>
</form>
