<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h3 class="arb-large-heading" id="modal-basic-title">BUYIN PRODUCT</h3>
  </div>
  <div class="modal-body">
    <form [formGroup]="supplierForm">
      <div class="row mb-2">
        <div class="col-4">
          <label>PRODUCT CODE<span class="text-danger">*</span></label>
        </div>
        <div class="col-8">
          <input
            class="form-control"
            formControlName="productCode"
            [autofocus]="true"
            (blur)="checkBuyinItem()"
            [class.is-invalid]="supplierForm.controls.productCode.touched && supplierForm.controls.productCode.errors"
          />
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-4">
          <label>DESCRIPTION<span class="text-danger">*</span></label>
        </div>
        <div class="col-8">
          <input
            class="form-control"
            formControlName="itemDescription"
            [class.is-invalid]="supplierForm.controls.itemDescription.touched && supplierForm.controls.itemDescription.errors"
          />
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-4">
          <label>SUPPLIER<span class="text-danger">*</span></label>
        </div>
        <div class="col-8">
          <pos-app-fuzzy-search
            [apiEndpointKey]="'searchSuppliers'"
            [apiRequestKey]="'supplierFuzzySearch'"
            [valueKey]="'SupplierNumber'"
            [description1Key]="'SupplierName'"
            [placeholder]="'ENTER THE SUPPLIER CODE / NAME'"
            (selectedItem)="selectSupplier($event)"
            [searchStr]="supplierForm.controls.supplier.value.toString()"
          ></pos-app-fuzzy-search>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-4">
          <label>PURCHASE PRICE</label>
        </div>
        <div class="col-8">
          <input
            class="form-control"
            type="number"
            formControlName="unitPrice"
            [class.is-invalid]="supplierForm.controls.unitPrice.touched && supplierForm.controls.unitPrice.errors?.pattern"
          />
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-4">
          <label>SELL PRICE</label>
        </div>
        <div class="col-8">
          <input readonly class="form-control" formControlName="sellPrice" />
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div  class="text-danger">
          <ng-container *ngIf="supplierForm.controls.productCode.errors?.maxlength || supplierForm.controls.itemDescription.errors?.maxlength">
            Exceeded 60 character limit for both code and description
          </ng-container>
        </div>
        <div class="d-flex justify-content-between">
          <button class="btn btn-cancel mr-2" (click)="d('Cross click')">CANCEL</button>
          <button class="btn text-white" (click)="addBuyin()" [disabled]="!supplierForm.valid">SUBMIT</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
