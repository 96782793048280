<div>
  <label>OLD MAN EMU SHOCK ABSORBERS INFORMATION</label>
  <p>
    - Attach copy of this form to defective suspension unit and hold in store
    for 90 days after the warranty procedure has been finalised
  </p>
  <p>
    - Ensure all mounting rubbers and washers are attached to defective unit
  </p>
  <form [formGroup]="omeForm">
    <div class="row">
      <div class="col-3">
        <label>SUSPENSION FAILURE *</label>
      </div>
      <div class="col-4">
        <ng-select
          [items]="warrantyFaultList"
          bindValue="faultCode"
          bindLabel="faultDescription"
          groupBy="faultCategory"
          formControlName="faultCode"
          [ngClass]="{
            'border border-danger rounded': omeForm.controls.faultCode.errors
          }"
        >
        </ng-select>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-3">
        <label>SUSPENSION FAILURE DATE *</label>
      </div>
      <div class="col-4">
        <div
          [ngClass]="{
            'border border-danger rounded': !omeForm.controls.faultDate.value
          }"
        >
          <pos-app-date-picker-single
            (selectedDate)="onSelectDate($event)"
          ></pos-app-date-picker-single>
        </div>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-3">
        <label>KMS SINCE FITMENT *</label>
      </div>
      <div class="col-4">
        <input
          type="number"
          formControlName="distanceTravelledSinceFit"
          class="form-control"
          [ngClass]="{
            'border border-danger rounded':
              omeForm.controls.distanceTravelledSinceFit.errors
          }"
        />
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-3">
        <label>WHEEL/TYRE SIZE *</label>
      </div>
      <div class="col-4">
        <input
          class="form-control"
          formControlName="wheelTyreSize"
          [ngClass]="{
            'border border-danger rounded':
              omeForm.controls.wheelTyreSize.errors
          }"
        />
      </div>
    </div>
  </form>
</div>
