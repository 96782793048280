import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ItemToAdd, StagingOrderLine } from '../../+state/orders.models';
import { ApiService } from '@pos-app/core-ui';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { OrdersBuyinSupplierDialogComponent } from '../orders-buyin-supplier-dialog/orders-buyin-supplier-dialog.component';
import { Supplier } from '../../models/supplier.model';

export function maxLengthValidator(maxLength: number, controlName): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const description: string = control?.parent?.get('itemDescription')?.value || '';
    const code: string = control?.parent?.get('productCode')?.value || '';

    if (!description && !code) {
      return null;
    }

    const error = (code?.length || 0) + (description?.length || 0) > maxLength - 1 ? { maxlength: true } : null

    control?.parent.get(controlName).setErrors(error);
    return error;
  };
}

@Component({
  selector: 'app-orders-buyin-supplier',
  templateUrl: './orders-buyin-supplier.component.html',
  styleUrls: ['./orders-buyin-supplier.component.scss'],
})
export class OrdersBuyinSupplierComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('content') modalBox: any;

  @Input() buyinProduct: StagingOrderLine;
  @Input() defaultMarkup: number;
  @Output() toAddBuyin = new EventEmitter<any>();
  @Output() addItemsToOrder = new EventEmitter<ItemToAdd[]>();

  supplierForm: FormGroup;

  private unSubscribe$ = new Subject<void>();

  constructor(private modalService: NgbModal, private apiService: ApiService, private dialog: MatDialog) {}

  ngOnInit() {
    this.supplierForm = new FormGroup({
      supplier: new FormControl(this.buyinProduct ? this.buyinProduct.supplierCode : '', [Validators.required, Validators.pattern('^[0-9]+')]),
      productCode: new FormControl(this.buyinProduct?.itemDescription ? this.buyinProduct.itemDescription.split('-')[0] : '', [
        Validators.required,
        maxLengthValidator(60, 'itemDescription'),
      ]),
      itemDescription: new FormControl(this.buyinProduct?.itemDescription ? this.buyinProduct.itemDescription.split('-')[1] : '', [
        Validators.required,
        maxLengthValidator(60, 'productCode'),
      ]),
      unitPrice: new FormControl(this.buyinProduct ? this.buyinProduct.unitCost : '', [Validators.pattern('^[0-9]+(.[0-9]+)?$')]),
      sellPrice: new FormControl(this.buyinProduct ? (this.buyinProduct.unitCost * (1 + this.defaultMarkup / 100)).toFixed(3) : ''),
    });

    this.supplierForm.get('unitPrice').valueChanges.pipe(takeUntil(this.unSubscribe$)).subscribe((value) => {
      let sellPrice: any;

      if (value !== undefined && value !== null && value !== '') {
        sellPrice = ((Number(value) || 0) * (1 + this.defaultMarkup / 100)).toFixed(3);
      }
      
      this.supplierForm.controls.sellPrice.setValue(sellPrice, {
        emitEvent: false,
      });
    });
  }

  ngAfterViewInit() {
    this.modalService.open(this.modalBox, { size: 'lg', backdrop: 'static' }).result.then(
      (_) => {},
      (_) => {
        this.toAddBuyin.emit(null);
      }
    );
  }

  ngOnDestroy(): void {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  checkBuyinItem(): void {
    const itemNumber = this.supplierForm.get('productCode').value;
    if (itemNumber) {
      this.apiService
        .checkBuyinItem({ itemNumber })
        .pipe(takeUntil(this.unSubscribe$))
        .subscribe((res) => {
          if (res?.SearchResults?.length > 0) {
            const data = res?.SearchResults || [];

            this.dialog
              .open(OrdersBuyinSupplierDialogComponent, {
                data,
                position: { top: '1.75rem' },
                panelClass: 'p-0',
              })
              .afterClosed()
              .pipe(takeUntil(this.unSubscribe$))
              .subscribe((supplier: Supplier) => {
                if (supplier) {
                  this.addItemsToOrder.emit([
                    {
                      itemNumber: supplier.itemNumber?.trim(),
                      quantity: 1,
                      price: 0,
                    },
                  ]);
                  this.modalService.dismissAll();
                }
              });
          }
        });
    }
  }

  addBuyin() {
    this.toAddBuyin.emit({
      ...this.supplierForm.value,
      markup: this.defaultMarkup,
    });
    this.modalService.dismissAll();
  }

  selectSupplier(supplierCode) {
    this.supplierForm.patchValue({ supplier: supplierCode });
  }

  descriptionMaxlength(value): number {
    return 59 - (value?.length || 0);
  }
}
