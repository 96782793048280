<div class="container my-2 py-2">
  <div class="row mx-5 px-5">
    <form [formGroup]="fitterFiltersForm">
      <div class="row mb-5 align-items-center">
        <div class="col-auto px-0 mx-0 d-inline">
          <label class="col-form-label block-size float-start">BRANCH</label>
          <ng-select formControlName="branch" class="float-start w-px-270">
            <ng-option
              *ngFor="let branch of branchList"
              [value]="branch.BranchCode"
            >
              {{ branch.BranchDescription }} ({{ branch.BranchCode }})
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div
        class="row mb-2 py-2 border border-start-0 border-end-0 border-bottom-0 border-dark"
      ></div>
      <div class="row align-items-center">
        <div class="col-auto mx-0 px-0">
          <h6 class="align-text-bottom">ASSIGN FITTER BY ORDER</h6>
        </div>
      </div>
      <div class="row mb-4 pb-2 align-items-center">
        <div class="col-auto mx-0 px-0 d-inline">
          <label class="col-form-label block-size pe-1">ORDER</label>
          <input
            class="form-control w-px-270 form-unset-display"
            formControlName="transactionNumber"
            placeholder="ENTER PICK, SALES ORDER OR INVOICE"
          />
        </div>
        <div class="col d-flex justify-content-end">
          <button
            class="btn me-md-5"
            (click)="fetchOrderForBookingAndResourceList()"
            [disabled]="disableFetchOrderFromTransactionNumber()"
          >
            FETCH ORDERS
          </button>
        </div>
      </div>

      <div
        class="row mb-2 py-2 border border-start-0 border-end-0 border-bottom-0 border-dark"
      ></div>
      <div class="row pb-2 align-items-center">
        <div class="col-auto px-0 mx-0">
          <h6 class="align-text-bottom">OR ASSIGN FITTER BY DATE</h6>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-auto mx-0 px-0 d-inline">
          <label
            class="col-form-label pe-1 me-1 pointer-cursor"
            (click)="enableReviewByDateToggle(true)"
            >REVIEW BY DATE</label
          >
          <input
            class="form-control w-px-200 form-unset-display pointer-cursor"
            ngbDatepicker
            #d1="ngbDatepicker"
            formControlName="reviewByDate"
            (click)="enableReviewByDateToggle(true)"
          />
          <button
            class="btn btn-secondary calendar text-white px-2 py-3"
            (click)="d1.toggle(); enableReviewByDateToggle(true)"
            type="button"
          ></button>
        </div>

        <div class="col d-flex justify-content-end">
          <label
            class="col-form-label pointer-cursor"
            (click)="enableReviewByDateToggle(false)"
            >REVIEW BY WEEK</label
          >
          <app-filters-review-by-week
            (click)="enableReviewByDateToggle(false)"
            [label]="weekListObjLabel"
            (indexToUpdate)="getCurentReviewByWeekLabel($event)"
            [minNumber]="minRelativeWeekNumber"
            [maxNumber]="maxRelativeWeekNumber"
            formControlName="reviewByWeek"
          ></app-filters-review-by-week>
        </div>
      </div>
      <div class="row mt-5 my-1 py-2 align-items-center">
        <div class="col-4 d-inline mx-0 px-0 pe-5 me-2">
          <label class="col-form-label pe-1 me-2">UNALLOCATED ORDERS</label>
          <input
            class="form-check-input align-text-bottom"
            type="checkbox"
            formControlName="unallocatedOnlyYN"
            id="unallocatedOnlyYN"
          />
        </div>
        <div class="col-auto d-inline pe-5 me-2">
          <label class="col-form-label pe-1 me-2">INVOICED ONLY Y/N</label>
          <input
            class="form-check-input align-text-bottom"
            type="checkbox"
            formControlName="invoicedOnlyYN"
            id="invoicedOnlyYN "
          />
        </div>
        <div class="col d-flex justify-content-end">
          <button
            class="btn me-md-5"
            (click)="fetchResourceListAndOrderForBooking()"
            [disabled]="disableFetchOrder()"
          >
            FETCH ORDERS
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
