<div
  *ngIf="{
    selectedVehicle: selectedVehicle$ | async,
    activeOrderHeader: activeOrderHeader$ | async
  } as data"
>
  <div *ngIf="!data.selectedVehicle">
    <input
      id="vehicleSearchBox"
      [(ngModel)]="searchString"
      (click)="resetSearch()"
      (input)="changed()"
      placeholder="Vehicle Search - Suggestion: Enter year and model"
    />
    <span class="spinner-border text-primary loading" role="status" *ngIf="(isAdvancedSearch$ | async) === false && (isLoaded$ | async) === false">
      <span class="sr-only"></span>
    </span>
  </div>
  <div *ngIf="data.selectedVehicle" #vehicleLabelSearch class="vehicle-selection arb-detail-text-bold">
    <div class="vehicle-selected">
      <span class="text-uppercase" style="margin-right: 6px">Vehicle: </span>
      <span class="selectedVehicle">
        {{ data.selectedVehicle.Make }}
        {{ data.selectedVehicle.Model }}
        {{ data.selectedVehicle.Year }}
        {{ data.selectedVehicle.Generation }}
        {{ data.selectedVehicle.Series }}
        {{ data.selectedVehicle.SubModel }}
        {{ data.selectedVehicle.BodyType }}
        {{ data.selectedVehicle.EngineType }}
        {{ data.selectedVehicle.EngineCode }}
        {{ data.selectedVehicle.DriveType }}
        {{ data.selectedVehicle.TransmissionType }}
        {{ data.selectedVehicle.Chassis }}
      </span>
      <span class="action">
        <span (click)="reselectVehicle(selectVehicleContent)" class="edit-vehicle">
          <i class="pi pi-pencil"></i>
        </span>
        <span (click)="deselectVehicle()" class="vehicle-deselected close">
          <i class="pi pi-times"></i>
        </span>
      </span>
    </div>
    <div class="use-vehiclel-checkbox">
      <label class="arb-detail-text arb-red">
        <input type="checkbox" [(ngModel)]="useVehicleInFilter" (change)="updateVehicleUseFilter()" />
        <span>Filter Products By Vehicle</span>
      </label>
    </div>
  </div>
</div>

<div
  class="search-results"
  *ngIf="(vehicles$ | async)?.length > 0 && (isAdvancedSearch$ | async) === false && (isLoaded$ | async) === true && useTopSearch"
>
  <ng-scrollbar class="scroll-event" thumbClass="white-scrollbars">
    <div class="list-group">
      <button
        type="button"
        class="list-group-item list-group-item-action py-1"
        *ngFor="let vehicle of vehicles$ | async as vehicles; let i = index"
        (click)="selectVehicle(vehicle)"
      >
        {{ vehicle.Make }} {{ vehicle.Model }} {{ vehicle.Year }} {{ vehicle.Generation }} {{ vehicle.Series }} {{ vehicle.SubModel }}
        {{ vehicle.BodyType }} {{ vehicle.EngineType }} {{ vehicle.EngineCode }} {{ vehicle.DriveType }}
        {{ vehicle.TransmissionType }}
        {{ vehicle.Chassis }}
      </button>
    </div>
  </ng-scrollbar>

  <button (click)="openAdvancedSearch(selectVehicleContent)" type="button" class="advanced-search btn-primary">Click For Advanced Search....</button>
</div>
<vehicle-update-modal #vehicleDeselectModal (okclick)="removeVehicleSelection()"></vehicle-update-modal>
<vehicle-update-modal #vehicleChangeModal (okclick)="handleUpdateVehicle()"></vehicle-update-modal>

<ng-template #newVehicleDetailContent let-c="close " let-d="dismiss " class="vehicle-modal">
  <div class="modal-header">
    <h3 class="modal-title" id="modal-basic-title ">NEW VEHICLE DETAILS</h3>
    <button type="button " class="close" aria-label="Close " (click)="d('Cross click')">
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <epc-customers-vehicles-details-new
      [currentCustomerNumber]="(activeOrderHeader$ | async)?.customerNumber"
      [selectedVehicle]="vehicleToBeSelected"
      (vehicleAdded)="closeAddingVehicle()"
      (cancelAdd)="closeAddingVehicle()"
    >
    </epc-customers-vehicles-details-new>
  </div>
</ng-template>

<ng-template #selectVehicleContent let-c="close " let-d="dismiss">
  <div class="modal-header">
    <h3 class="modal-title" id="modal-basic-title ">SELECT VEHICLE</h3>
    <button type="button " class="close" aria-label="Close " (click)="d('Cross click')">
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-vehicles-selection [fromEpc]="true" (cancelSelect)="d('Cross click')" (submitCar)="onSelectVehicle($event)"></app-vehicles-selection>
  </div>
</ng-template>
