import { OrdersAction, OrdersActionTypes } from './orders.actions';
import { DefaultOrder, LoadedGiftCard, OrderFilterLists, OrderSummary, ParkedOrders } from '@pos-app/data';
import { OrderHeader, StagingOrderLine, OrderMessage, FinalisedOrder, ViewSettings, PaymentInfo } from './orders.models';

export const ORDERS_FEATURE_KEY = 'orders';

/**
 * Interface for the 'Orders' data used in
 *  - OrdersState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface OrdersState {
  list: Entity[]; // list of Orders; analogous to a sql normalized table
  selectedId?: string | number; // which Orders record has been selected
  loaded: boolean; // has the Orders list been loaded
  error?: any; // last none error (if any)
  orderFilterLists: OrderFilterLists;
  loadingOrderFilterLists: boolean;
  orderSummary: OrderSummary[];
  searchingOrderSummary: boolean;
  creatingOrder: boolean;
  cachedOrderFilterLists?: OrderFilterLists;
  loadingActiveOrderHeader: boolean;
  activeOrderHeader: OrderHeader;
  loadingStagingOrder: boolean;
  countOrderLineLoading: number;
  stagingOrder: StagingOrderLine[];
  validatingOrder: boolean;
  finalisedOrder: FinalisedOrder;
  orderMessages: OrderMessage[];
  headerMessages: OrderMessage[];
  paymentInfo: PaymentInfo;
  loadingPendingpayments: boolean;
  isFetchingJdeOrder: boolean;
  addItemProcessed: string;
  loadedGiftCards: LoadedGiftCard[];
  pendingLoadAmounts: number[];
  loadingParkedOrders: boolean;
  parkedOrdersList: ParkedOrders[];
  isLoadingParkedOrder: boolean;
  defaultOrder: DefaultOrder;
  viewSettings: ViewSettings;
  totalHistoricalPaymentAmount: number;
}

export interface OrdersPartialState {
  readonly [ORDERS_FEATURE_KEY]: OrdersState;
}

export const initialState: OrdersState = {
  list: [],
  loaded: false,
  orderFilterLists: null,
  loadingOrderFilterLists: false,
  orderSummary: null,
  searchingOrderSummary: false,
  creatingOrder: false,
  loadingActiveOrderHeader: false,
  activeOrderHeader: null,
  headerMessages: [],
  loadingStagingOrder: false,
  countOrderLineLoading: 0,
  stagingOrder: [],
  orderMessages: [],
  validatingOrder: false,
  finalisedOrder: null,
  isFetchingJdeOrder: false,
  paymentInfo: null,
  loadingPendingpayments: false,
  addItemProcessed: '',
  loadedGiftCards: [],
  pendingLoadAmounts: [],
  loadingParkedOrders: false,
  parkedOrdersList: [],
  isLoadingParkedOrder: false,
  defaultOrder: null,
  viewSettings: {
    showExPrice: false,
    showCancelled: false,
    showInvoiced: false,
    enforceItemRule: true,
    showTaxFlag: false,
    showATP: false,
  },
  totalHistoricalPaymentAmount: 0,
};

export function reducer(state: OrdersState = initialState, action: OrdersAction): OrdersState {
  switch (action.type) {
    case OrdersActionTypes.CLEAN_STAGING_ORDER_DETAILS: {
      return initialState;
    }

    case OrdersActionTypes.CANCEL_ORDER: {
      return { ...state, ...initialState };
    }

    case OrdersActionTypes.CLEAR_DATA: {
      return { ...state, orderSummary: null };
    }

    case OrdersActionTypes.LOAD_FILTER_LISTS: {
      if (state.cachedOrderFilterLists) {
        return { ...state, orderFilterLists: state.cachedOrderFilterLists };
      }
      return { ...state, loadingOrderFilterLists: true };
    }
    case OrdersActionTypes.LOAD_FILTER_LISTS_FAILED: {
      return { ...state, loadingOrderFilterLists: false };
    }
    case OrdersActionTypes.LOAD_FILTER_LISTS_SUCCESS: {
      state = {
        ...state,
        orderFilterLists: action.payload,
        loadingOrderFilterLists: false,
        cachedOrderFilterLists: action.payload,
      };
      break;
    }

    case OrdersActionTypes.SEARCH_ORDER_SUMMARY: {
      return { ...state, searchingOrderSummary: true, orderSummary: null };
    }
    case OrdersActionTypes.SEARCH_ORDER_SUMMARY_FAILED: {
      return { ...state, searchingOrderSummary: false };
    }
    case OrdersActionTypes.SEARCH_ORDER_SUMMARY_SUCCESS: {
      state = {
        ...state,
        orderSummary: action.payload.SearchResults,
        searchingOrderSummary: false,
      };
      break;
    }

    case OrdersActionTypes.CREATE_ORDER: {
      return { ...state, creatingOrder: true };
    }
    case OrdersActionTypes.CREATE_ORDER_FAILED: {
      return { ...state, creatingOrder: false };
    }
    case OrdersActionTypes.CREATE_ORDER_SUCCESS: {
      state = {
        ...state,
        creatingOrder: false,
      };
      break;
    }

    case OrdersActionTypes.LOAD_ORDER_HEADER: {
      return {
        ...state,
        loadingActiveOrderHeader: true,
        activeOrderHeader: null,
        totalHistoricalPaymentAmount: 0,
        headerMessages: [],
      };
    }
    case OrdersActionTypes.LOAD_ORDER_HEADER_FAILED: {
      return { ...state, loadingActiveOrderHeader: false };
    }
    case OrdersActionTypes.LOAD_ORDER_HEADER_SUCCESS: {
      state = {
        ...state,
        loadingActiveOrderHeader: false,
        activeOrderHeader: action.payload.SearchResults,
        totalHistoricalPaymentAmount: action.payload.totalHistoricalPaymentAmount,
        headerMessages: action.payload.messages,
      };
      break;
    }

    case OrdersActionTypes.LOAD_STAGING_ORDER_DETAILS: {
      return {
        ...state,
        loadingStagingOrder: true,
      };
    }
    case OrdersActionTypes.LOAD_STAGING_ORDER_DETAILS_FAILED: {
      return { ...state, loadingStagingOrder: false };
    }
    case OrdersActionTypes.LOAD_STAGING_ORDER_DETAILS_SUCCESS: {
      state = {
        ...state,
        loadingStagingOrder: false,
        stagingOrder: action.payload.SearchResults,
        orderMessages: action.payload.messages,
      };
      break;
    }
    case OrdersActionTypes.FETCH_UPDATED_ORDER_LINE: {
      return {
        ...state,
        countOrderLineLoading: state.countOrderLineLoading + 1,
      };
    }
    case OrdersActionTypes.FETCH_UPDATED_ORDER_LINE_SUCCESS: {
      state = {
        ...state,
        stagingOrder: (state.stagingOrder || []).map((order) => {
          const newItems = action.payload;
          const newData = newItems.find((item) => item.id === order.id);

          if (newData && order.id === newData.id) {
            return {
              ...order,
              availability: newData.availability,
              updatingRow: newData.updatingRow?.trim(),
              atpDescription: newData.atpDescription,
              availableDate: newData.availableDate,
              unitPriceEx: newData.unitPriceEx,
              unitPriceInc: newData.unitPriceInc,
              validateRequiredYN: newData.validateRequiredYN,
              lineValidatedYN: newData.lineValidatedYN,
              extPriceEx: newData.extPriceEx,
              extPriceInc: newData.extPriceInc,
              qtyBackOrdered: newData.qtyBackOrdered,
              isFetchUpdatedOrderLine: true,
            };
          }
          return {
            ...order,
            isFetchUpdatedOrderLine: false,
          };
        }),
        countOrderLineLoading: state.countOrderLineLoading > 0 ? state.countOrderLineLoading - 1 : 0,
      };
      break;
    }

    case OrdersActionTypes.UPDATE_STAGING_ORDER_LINE: {
      return {
        ...state,
        loadingStagingOrder: true,
      };
    }
    case OrdersActionTypes.UPDATE_STAGING_ORDER_LINE_FAILED: {
      return { ...state, loadingStagingOrder: false };
    }

    case OrdersActionTypes.UPDATE_GRID_DATA: {
      let temp = [];
      temp = Object.assign(temp, state.stagingOrder);
      temp[action.payload.index] = action.payload.data;
      return { ...state, stagingOrder: temp };
    }

    case OrdersActionTypes.VALIDATE_ORDER: {
      return {
        ...state,
        validatingOrder: true,
        finalisedOrder: null,
      };
    }
    case OrdersActionTypes.VALIDATE_ORDER_FAILED: {
      return { ...state, validatingOrder: false };
    }
    case OrdersActionTypes.VALIDATE_ORDER_SUCCESS: {
      state = {
        ...state,
        validatingOrder: false,
        finalisedOrder: null,
      };
      break;
    }

    case OrdersActionTypes.REVALIDATE_ORDER: {
      return {
        ...state,
        validatingOrder: true,
        finalisedOrder: null,
      };
    }
    case OrdersActionTypes.REVALIDATE_ORDER_FAILED: {
      return { ...state, validatingOrder: false };
    }
    case OrdersActionTypes.REVALIDATE_ORDER_SUCCESS: {
      state = {
        ...state,
        validatingOrder: false,
      };
      break;
    }

    case OrdersActionTypes.RESET_ORDER_VALIDATION: {
      state = {
        ...state,
      };
      break;
    }

    case OrdersActionTypes.CONVERT_LINES_SUCCESS: {
      state = {
        ...state,
        stagingOrder: [],
      };
      break;
    }

    case OrdersActionTypes.FETCH_JDE_ORDER: {
      return {
        ...state,
        isFetchingJdeOrder: true,
      };
    }
    case OrdersActionTypes.FETCH_JDE_ORDER_FAILED: {
      return { ...state, isFetchingJdeOrder: false };
    }
    case OrdersActionTypes.FETCH_JDE_ORDER_SUCCESS: {
      state = {
        ...state,
        isFetchingJdeOrder: false,
      };
      break;
    }

    case OrdersActionTypes.UPDATE_AND_VALIDATE: {
      return {
        ...state,
        validatingOrder: true,
        finalisedOrder: null,
      };
    }
    case OrdersActionTypes.UPDATE_AND_VALIDATE_FAILED: {
      return { ...state, validatingOrder: false };
    }

    case OrdersActionTypes.VALIDATE_SPLIT_LINES_ORDER: {
      return {
        ...state,
        validatingOrder: true,
        finalisedOrder: null,
      };
    }
    case OrdersActionTypes.VALIDATE_SPLIT_LINES_ORDER_FAILED: {
      return { ...state, validatingOrder: false };
    }

    case OrdersActionTypes.RESET_ORDER_VALIDATION: {
      state = {
        ...state,
      };
      break;
    }

    case OrdersActionTypes.FETCH_PAYMENT_INFO: {
      return {
        ...state,
        paymentInfo: null,
      };
    }
    case OrdersActionTypes.FETCH_PAYMENT_INFO_FAILED: {
      return { ...state, paymentInfo: null };
    }

    case OrdersActionTypes.FETCH_PAYMENT_INFO_SUCCESS: {
      state = {
        ...state,
        paymentInfo: action.payload,
      };
      break;
    }

    case OrdersActionTypes.FETCH_LOADED_GIFTCARDS: {
      return {
        ...state,
        loadedGiftCards: [],
        pendingLoadAmounts: []
      };
    }

    case OrdersActionTypes.FETCH_LOADED_GIFTCARDS_SUCCESS: {
      state = {
        ...state,
        loadedGiftCards: action.payload.completedLoads,
        pendingLoadAmounts: action.payload.pendingLoadAmounts
      };
      break;
    }

    case OrdersActionTypes.ADD_ITEMS_TO_ORDER: {
      return {
        ...state,
        addItemProcessed: '',
      };
    }
    case OrdersActionTypes.ADD_ITEMS_TO_ORDER_FAILED: {
      if (action.payload.itemNumber) {
        return {
          ...state,
          addItemProcessed: '',
          stagingOrder: [
            ...state.stagingOrder,
            {
              itemNumber: action.payload.itemNumber,
              message: action.payload.message,
            } as StagingOrderLine,
          ],
        };
      }

      return { ...state, addItemProcessed: '' };
    }
    case OrdersActionTypes.ADD_ITEMS_TO_ORDER_SUCCESS: {
      return { ...state, addItemProcessed: '' };
    }
    case OrdersActionTypes.ADD_ITEMS_PROCESSED: {
      return { ...state, addItemProcessed: action.payload };
    }

    case OrdersActionTypes.LOAD_PARKED_ORDERS_LIST: {
      return {
        ...state,
        loadingParkedOrders: true,
      };
    }
    case OrdersActionTypes.LOAD_PARKED_ORDERS_LIST_FAILED: {
      return { ...state, loadingParkedOrders: false };
    }
    case OrdersActionTypes.LOAD_PARKED_ORDERS_LIST_SUCCESS: {
      state = {
        ...state,
        loadingParkedOrders: false,
        parkedOrdersList: action.payload.parkOrderList,
      };
      break;
    }

    case OrdersActionTypes.LOAD_PARKED_ORDER: {
      return {
        ...state,
        isLoadingParkedOrder: true,
      };
    }
    case OrdersActionTypes.LOAD_PARKED_ORDER_FAILED: {
      return { ...state, isLoadingParkedOrder: false };
    }
    case OrdersActionTypes.LOAD_PARKED_ORDER_SUCCESS: {
      state = {
        ...state,
        isLoadingParkedOrder: false,
      };
      break;
    }

    case OrdersActionTypes.UPDATE_VIEW_SETTINGS: {
      state = {
        ...state,
        viewSettings: {
          ...state.viewSettings,
          ...action.payload,
        },
      };
      break;
    }
  }
  return state;
}
