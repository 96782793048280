import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ServiceHistory, StoreList, UpadateServiceHistory } from 'libs/data/src/lib/models/camperTrailer';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-camper-service-details',
  templateUrl: './camper-service-details.component.html',
  styleUrls: ['./camper-service-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamperServiceDetailsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() assetNumber: string;
  @Input() serviceHistoryDateList: Array<string>;
  @Input() serviceHistory: ServiceHistory;
  @Input() storeList: StoreList[];
  @Input() nextServiceDate: string;
  @Input() currentServiceDate: string;
  @Input() serviceAttachmentsLength: number;
  @Input() hasServiceAttachmentChanged = false;
  @Input() hasTrailerAttachmentChanged = false;
  @Input() hasTrailerDetailsChanged = false;

  @Output() getServiceHistoryByDate = new EventEmitter<string>();
  @Output() updateServiceHistory = new EventEmitter<UpadateServiceHistory>();
  @Output() cancelForm = new EventEmitter<any>();
  @Output() openServiceAttachments = new EventEmitter<any>();
  @Output() hasServiceDetailsChanged = new EventEmitter<boolean>();

  public showDatePicker = false;
  public camperServiceDetailsForm = new FormGroup({
    serviceDate: new FormControl('', Validators.required),
    invoiceWarrantyNumber: new FormControl(''),
    serviceStore: new FormControl(''),
    salesperson: new FormControl(''),
    serviceTechnician: new FormControl(''),
    detailsOfService: new FormControl(''),
    partsReplacedUsedInService: new FormControl(''),
  });
  public currentSelectedDate: string;
  public initialFormValue: any;

  private _hasFormValueChanged = false;
  private unSubscribe$ = new Subject<void>();

  set hasFormValueChanged(value) {
    this._hasFormValueChanged = value;
    this.hasServiceDetailsChanged.emit(value);
  }

  get hasFormValueChanged(): boolean {
    return this._hasFormValueChanged;
  }

  constructor() {}

  ngOnInit(): void {
    this.camperServiceDetailsForm.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.unSubscribe$)).subscribe((val) => {
      this.compareValues();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.serviceHistory?.currentValue && this.camperServiceDetailsForm) {
      this.initializeForm();
    }

    if (changes.currentServiceDate) {
      if (this.currentServiceDate && this.camperServiceDetailsForm?.get('serviceDate').value !== this.currentServiceDate) {
        this.updateServiceDate();
      }
    }

    if (changes.hasTrailerDetailsChanged) {
      if (this.hasTrailerDetailsChanged) {
        this.disableFormControls();
      } else {
        this.enableFormControls();
      }
    }

    if (changes.hasTrailerAttachmentChanged) {
      if (this.hasTrailerAttachmentChanged) {
        this.disableFormControls();
      } else {
        this.enableFormControls();
      }
    }
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  initializeForm() {
    this.initialFormValue = null;

    this.patchValueForCamperServiceDetailsForm(this.serviceHistory);
    this.currentSelectedDate = this.serviceHistory.ServiceDate;
    this.hasFormValueChanged = false;
    this.initialFormValue = this.camperServiceDetailsForm.getRawValue();
  }

  patchValueForCamperServiceDetailsForm(serviceHistory: ServiceHistory) {
    this.camperServiceDetailsForm.patchValue({
      serviceDate: serviceHistory.ServiceDate,
      invoiceWarrantyNumber: serviceHistory.InvoiceWarrantyNumber || '',
      serviceStore: serviceHistory.ServiceStore || null,
      salesperson: serviceHistory.Salesperson || '',
      serviceTechnician: serviceHistory.ServiceTechnician || '',
      detailsOfService: serviceHistory.DetailsOfService || '',
      partsReplacedUsedInService: serviceHistory.PartsReplacedUsedInService || '',
    });
  }

  invokeUpdateServiceHistory() {
    this.updateServiceHistory.emit({
      assetNumber: Number(this.assetNumber),
      serviceDate: this.camperServiceDetailsForm.value.serviceDate,
      invoiceWarrantyNumber: this.camperServiceDetailsForm.value.invoiceWarrantyNumber,
      serviceStore: this.camperServiceDetailsForm.value.serviceStore,
      salesperson: this.camperServiceDetailsForm.value.salesperson,
      serviceTechnician: this.camperServiceDetailsForm.value.serviceTechnician,
      detailsOfService: this.camperServiceDetailsForm.value.detailsOfService,
      partsReplacedUsedInService: this.camperServiceDetailsForm.value.partsReplacedUsedInService,
    });
  }

  onServiceDateChanged(serviceDate: string) {
    let formatedDate = this.addLeadingZero(serviceDate);
    this.getServiceHistoryByDate.emit(formatedDate);
    this.currentSelectedDate = formatedDate;
  }

  invokeCancelForm() {
    this.cancelForm.emit();
    this.showDatePicker = false;
    this.hasFormValueChanged = false;
  }

  displayDatePicker() {
    if (this.hasTrailerAttachmentChanged || this.hasTrailerDetailsChanged) {
      return;
    }

    this.showDatePicker = true;
    this.camperServiceDetailsForm.reset();
  }

  onServiceDateSelected(date: NgbDate) {
    this.onServiceDateChanged(date ? date.day + '/' + date.month + '/' + date.year : '');
    this.showDatePicker = false;
  }

  invokeOpenServiceAttachments() {
    this.openServiceAttachments.emit(this.currentServiceDate);
  }

  compareValues() {
    const currentFormValue = this.camperServiceDetailsForm.getRawValue();

    if (JSON.stringify(currentFormValue) !== JSON.stringify(this.initialFormValue)) {
      this.hasFormValueChanged = true;
    } else {
      this.hasFormValueChanged = false;
    }
  }

  updateServiceDate() {
    this.camperServiceDetailsForm.patchValue({
      serviceDate: this.currentServiceDate,
    });
  }

  addLeadingZero(dateString: string) {
    if (!dateString) {
      return null;
    }

    let [day, month, year] = dateString.split('/');
    if (day.length === 1) {
      day = '0' + day;
    }
    if (month.length === 1) {
      month = '0' + month;
    }
    return `${day}/${month}/${year}`;
  }

  disableButton() {
    if (this.hasServiceAttachmentChanged || this.hasFormValueChanged) {
      return false;
    } else {
      return true;
    }
  }

  disableFormControls() {
    this.camperServiceDetailsForm.disable({emitEvent: false});
  }

  enableFormControls() {
    this.camperServiceDetailsForm.enable({emitEvent: false});
  }
}
