import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  ApiService,
  CoreUiPartialState,
  MessageService,
  getDefaultCustomer,
  getDisplayOptions,
  getIsShowroomView,
  getSecurityFunctions,
} from '@pos-app/core-ui';
import { DisplayOptions, ItemPriceGroup, SECURITY_FUNCTION } from '@pos-app/data';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, of, Subject } from 'rxjs';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { ItemBranchAvailability } from '../../../orders/+state/orders.models';
import { FuzzySearchComponent } from 'libs/core-ui/src/lib/components/fuzzy-search/fuzzy-search.component';
import { ItemPriceGroupResponse } from 'libs/core-ui/src/lib/models/item-price-group';

@Component({
  selector: 'app-stockavailability',
  templateUrl: './stockavailability.component.html',
  styleUrls: ['./stockavailability.component.scss'],
})
export class StockavailabilityComponent implements OnInit, OnDestroy {
  @ViewChild('fuzzySearch') fuzzySearch: FuzzySearchComponent;

  @Input() isExternalUser: boolean;
  @Output() showStockAvailability = new EventEmitter<boolean>();

  isLoading: boolean;
  unSubscribe$ = new Subject<void>();
  itemAvailability: ItemBranchAvailability[];
  itemPriceGroup: ItemPriceGroup;
  customer: any;
  displayOptions: DisplayOptions;
  allowToViewStock: boolean;
  isShowroomView: boolean;
  isInternalUser: boolean;
  isShowTable = false;
  atpBranch: string;
  atpDateDescription: string;
  isDisplayMyPrice: boolean;
  itemNumber: string;

  constructor(
    private apiService: ApiService,
    private coreUiStore: Store<CoreUiPartialState>,
    private toastr: ToastrService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    combineLatest([
      this.coreUiStore.select(getDisplayOptions),
      this.coreUiStore.select(getDefaultCustomer),
      this.coreUiStore.select(getSecurityFunctions),
    ])
      .pipe(take(1))
      .subscribe(([displayOptions, defaultCustomer, securityFunctions]) => {
        this.displayOptions = displayOptions;
        this.customer = defaultCustomer;
        this.allowToViewStock = securityFunctions.indexOf(SECURITY_FUNCTION.viewStock) !== -1;
      });

    this.coreUiStore
      .select(getIsShowroomView)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((res) => {
        this.isShowroomView = res;
      });

    this.messageService
      .listen('userStateUpdate')
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe(() => {
        if (this.itemNumber) {
          this.loadItemAvailability(this.itemNumber);
        }
      });
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  clearSearchValue() {
    this.itemAvailability = null;
    this.itemPriceGroup = null;
  }

  cancel(): void {
    this.showStockAvailability.emit(false);
    this.isShowTable = false;
    this.fuzzySearch.clear();
  }

  loadItemAvailability(itemNumber) {
    this.isInternalUser = !this.isExternalUser;
    this.itemAvailability = null;
    this.itemPriceGroup = null;
    this.isLoading = true;
    this.itemNumber = itemNumber;

    combineLatest([
      this.allowToViewStock || this.isInternalUser ? this.apiService.getItemAvailability({ itemNumber }) : of(null),
      this.displayOptions.displayExTax || this.displayOptions.displayIncTax || this.isInternalUser
        ? this.apiService.getItemPriceGroup({
            customerNumber: this.customer.customerNumber,
            branch: this.customer.defaultBranch,
            itemCodes: [{ code: itemNumber, quantity: 1 }],
            postCode: '',
            overrideShowroomViewYN: 'N',
          })
        : of(null),
    ])
      .pipe(
        finalize(() => (this.isLoading = false)),
        takeUntil(this.unSubscribe$)
      )
      .subscribe(
        ([itemAvailability, priceGroup]: [any, ItemPriceGroupResponse]) => {
          this.isLoading = false;
          this.itemAvailability = itemAvailability ? itemAvailability.SearchResults : {};
          this.itemPriceGroup = priceGroup ? priceGroup.items[0] : ({} as any);
          this.isShowTable = true;
          this.atpBranch = itemAvailability.ATPBranch;
          this.atpDateDescription = itemAvailability.ATPDateDescription;
          this.isDisplayMyPrice = priceGroup.displayMyPriceYN === 'Y';

          this.showStockAvailability.emit(true);
        },
        (error) => {
          this.toastr.error(error.ErrorMessage);
        }
      );
  }

  displayAvailability(availabilityNumber, availabilityString) {
    return isNaN(Number(availabilityString)) ? availabilityString : +availabilityNumber;
  }
}
