import { ICellRenderer } from 'ag-grid-community';
import { Component } from '@angular/core';

@Component({
  selector: 'app-action-cell',
  template: `
    <div>
      <mat-icon *ngIf="params?.data?.showFetchOrderIconTF || params?.showFetchOrderIconTF" (click)="invokeFetchJDEOrderMethod()" title="Load order into staging"> refresh </mat-icon>
      <mat-icon *ngIf="params?.data?.showReprintInvoiceIconTF " (click)="sendEmail()" title="Email invoice reprint" class="px-2">picture_as_pdf</mat-icon>
    </div>
  `,
  styles: [
    `
      .btn {
        line-height: 0.5;
      }
    `,
  ],
})
export class OrderSearchGridActionRendererComponent implements ICellRenderer {
  public params: any;

  public agInit(params: any): void {
    this.params = params;
  }

  public invokeFetchJDEOrderMethod() {
    this.params.context.componentParent.fetchOrderFromJDE(this.params.node);
  }

  public sendEmail() {
    this.params.context.componentParent.emailInvoiceReprint(this.params.node);
  }

  public refresh(): boolean {
    return false;
  }
}
