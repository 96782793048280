
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CustomersState } from './customers.state';
import { EPC_CUSTOMER_FEATURE_KEY } from './customers.reducer';

const getCustomerState = createFeatureSelector<CustomersState>(
  EPC_CUSTOMER_FEATURE_KEY
);

const getError = createSelector(
  getCustomerState,
  (state: CustomersState): any => state.error
);

const getLookupLists = createSelector(
  getCustomerState,
  (state: CustomersState): any => state.lookupLists
);

const getLoadingLookupLists = createSelector(
  getCustomerState,
  (state: CustomersState): any => state.loadingLookupLists
);

const getCustomerVehicles = createSelector(
  getCustomerState,
  (state: any) => {
    if (
      state.customerVehicles  && (state.customerVehicles.length === 0 || state.customerVehicles.length === 1)
    ) {
      return state.customerVehicles;
    }

    const array = state.customerVehicles.slice();
    const defaultVehicle = array
      .sort((a, b) => Number(a.DefaultFlag) - Number(b.DefaultFlag))
      .pop();

    array
      .sort((a, b) => Number(b.customerVehicleID) - Number(a.customerVehicleID))
      .unshift(defaultVehicle);

    return array;
  }
);

const getLoadingCustomerVehicles = createSelector(
  getCustomerState,
  (state: CustomersState) => state.loadingCustomerVehicles
);

const getUpdatingCustomerVehicle = createSelector(
  getCustomerState,
  (state: CustomersState) => state.updatingCustomerVehicle
);

const getCustomerVehicleID = createSelector(
  getCustomerState,
  (state: CustomersState) => state.selectedCustomerVehicleID
);

const getToAddDefaultVehicle = createSelector(
  getCustomerState,
  (state: CustomersState) =>
    state.customerVehicles.filter(vehicle => vehicle.DefaultFlag === '1')
      .length === 0
);

const getSelectedCustomerVehicle = createSelector(
  getCustomerState,
  (state: CustomersState) => state.selectedCustomerVehicle
);

const getFilteredStates = createSelector(
  getCustomerState,
  (state: CustomersState) => {
    if (state.lookupLists) {
      return state.lookupLists.stateList.filter(
        x => x.CountryCode === state.selectedCountryCode
      );
    }
    return [];
  }
);

const getSelectedCustomerState = createSelector(
  getCustomerState,
  (state: CustomersState) => state.selectedCustomer.StateCode
);

const getSelectedCustomerCountry = createSelector(
  getCustomerState,
  (state: CustomersState) => state.selectedCustomer.CountryCode
);

const getSelectedCustomer = createSelector(
  getCustomerState,
  (state: CustomersState) => state.selectedCustomer
);

export const getActiveOrderVehicle = createSelector(
  getCustomerState,
  (state: CustomersState) => {
    return state.activeOrderVehicle;
  }
);

export const getActiveOrderHeader = createSelector(
  getCustomerState,
  (state: CustomersState) => {
    return state.activeOrderHeader
  }
);

export const customersQuery = {
  getError,
  getSelectedCustomer,
  getLookupLists,
  getLoadingLookupLists,
  getCustomerVehicles,
  getLoadingCustomerVehicles,
  getUpdatingCustomerVehicle,
  getToAddDefaultVehicle,
  getSelectedCustomerVehicle,
  getCustomerVehicleID,
  getSelectedCustomerState,
  getSelectedCustomerCountry,
  getFilteredStates,
  getActiveOrderVehicle,
  getActiveOrderHeader
};
