import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '@pos-app/core-ui';

@Component({
  selector: 'app-customers-notes-file-attachment',
  templateUrl: './customers-notes-file-attachment.component.html',
  styleUrls: ['./customers-notes-file-attachment.component.scss']
})
export class CustomersNotesFileAttachmentComponent implements OnInit {
  @ViewChild('content') modalBox: any;
  attachedFiles;

  constructor(private modalService: NgbModal, private apiService: ApiService) {}

  ngOnInit() {}

  openFileAttachmentModal(fileItem) {
    this.attachedFiles = fileItem.attachmentIDArray;

    this.modalService
      .open(this.modalBox, { backdrop: 'static' })
      .result.then(_ => {}, _ => {});
  }

  downloadFile(file) {
    this.apiService.downloadFile(file._id, file.name).subscribe(x => {});
  }
}
