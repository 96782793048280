export interface IOOEUserState {
  ErrorFlag: string | undefined;
  ErrorMessage: undefined;
  OpenOrder: IOOEOrder | undefined;
  jdeVehicleID: string | undefined;
  quickSaveYN: 'Y' | 'N';
  showCodes: any[];
  showroomViewYN: 'Y' | 'N';
  tempVehicleYN: 'N' | 'Y';
}

export interface IOOEOrder {
  orderNumber: string | undefined;
  customerNumber: number | undefined;
  orderReference: string | undefined;
  customerName: string | undefined;
  orderValue: number;
  partsVehicleID: number;
  customerVehicleID: string;
  vehicleDescription: string | undefined;
  readOnlyEPCTF: boolean;
  orderType: string;
  orderCompany: string;
  pendingPaymentsTF: boolean
}

export interface IOOEOrderHeader {
  branch: string;
  brand: string;
  customerName: string;
  customerNumber: number;
  customerVehicleID: number;
  orderCompany: string;
  orderCreateDateTime: Date;
  orderNote: string;
  orderNoteCustFacing: string;
  orderNumber: string;
  orderType: string;
  partsVehicleID: number;
  reference: string;
  requestDate: Date;
  shipToNumber: number;
  userEmail: string;
  vehicleDescription: string;
}

export interface IOOESelectedVehicle {
  partsVehicleID: string;
  customerVehicleID: string | number;
  vehicleDescription: string | undefined;
}

export class OOEUserState implements IOOEUserState {
  ErrorFlag: string | undefined;
  ErrorMessage: undefined;
  OpenOrder: IOOEOrder | undefined;
  jdeVehicleID: string | undefined;
  quickSaveYN: 'Y' | 'N';
  showCodes: any[];
  showroomViewYN: 'Y' | 'N';
  tempVehicleYN: 'Y' | 'N';
}

export class SelectedVehicle implements IOOESelectedVehicle {
  partsVehicleID: string;
  customerVehicleID: string | number;
  vehicleDescription: string | undefined;
  jdeVehicleID: string;
}

export interface IOOEUserType {
  ErrorFlag: string | undefined;
  ErrorMessage: undefined;
  userType: string | undefined;
}

export interface ApiResponse {
  [k: string]: any;
  ErrorFlag: string;
  ErrorMessage: string;
}
