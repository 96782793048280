<pos-app-spinner [show]="loading$ | async"></pos-app-spinner>
<div>
  <label>PARKED ORDERS:</label>
  <div class="pl-3 py-3 row">
    <div class="col-4"></div>
  </div>
  <ag-grid-angular
    #agGrid
    class="ag-theme-balham datagrid"
    [rowData]="parkedOrderList"
    [context]="context"
    [frameworkComponents]="frameworkComponents"
    [gridOptions]="gridOptions"
    (gridReady)="onGridReady($event)"
    [domLayout]="'autoHeight'"
    [pagination]="true"
    [paginationPageSize]="10"
  >
  </ag-grid-angular>
</div>
