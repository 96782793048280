<pos-app-spinner
  [displayText]="generateReportLoaderText"
  [showTextLoader]="showTextLoader"
  [show]="loading$ | async"
></pos-app-spinner>
<div class="container-fluid max1200 pb-5">
  <div class="row">
    <h3 class="text-center py-4 arb-large-heading">
      ARB CAMPER TRAILER PORTAL
    </h3>
  </div>
  <app-camper-customer-details
    [camperCustomerDetails]="camperCustomerDetails"
    [disableGenerateReport]="
      isTrailerDetailsChanged ||
      isServiceDetailsChanged ||
      hasNewTrailerAttachment() ||
      hasNewServiceAttachment()
    "
    (generateReport)="generateReport()"
    (addNewCustomer)="addNewCustomer()"
    (selectNewCustomer)="selectNewCustomer()"
    (editCustomer)="editCustomer()"
  ></app-camper-customer-details>
  <hr />
  <app-camper-trailer-details
    #camperTrailerDetails
    [assetNumber]="assetNumber"
    [customerNumber]="customerNumber"
    [trailerDetails]="!!trailerDetails ? trailerDetails : ''"
    [hasMultiTrailers]="hasMultiTrailers"
    [storeList]="storeList"
    [regoStatusList]="regoStatusList"
    [trailerAttachmentsLength]="trailerAttachmentsLength"
    [hasTrailerAttachmentChanged]="!!hasNewTrailerAttachment() ? true : false"
    [hasServiceAttachmentChanged]="!!hasNewServiceAttachment() ? true : false"
    [hasServiceDetailsChanged]="isServiceDetailsChanged"
    (nextTrailerIndex)="switchTrailer($event)"
    (updateTrailerInfo)="updateTrailerInfo($event)"
    (cancelForm)="cancelTrailerForm()"
    (openTrailerAttachments)="openTrailerAttachments()"
    (hasTrailerDetailsChanged)="hasTrailerDetailsChanged($event)"
  ></app-camper-trailer-details>
  <hr />
  <app-camper-service-details
    #camperServiceDetails
    [assetNumber]="assetNumber"
    [serviceHistoryDateList]="
      !!serviceHistoryDateList ? serviceHistoryDateList : ''
    "
    [serviceHistory]="serviceHistory"
    [storeList]="storeList"
    [nextServiceDate]="nextServiceDate"
    [currentServiceDate]="currentSelectedServiceDate"
    [serviceAttachmentsLength]="serviceAttachmentsLength"
    [hasServiceAttachmentChanged]="!!hasNewServiceAttachment() ? true : false"
    [hasTrailerAttachmentChanged]="!!hasNewTrailerAttachment() ? true : false"
    [hasTrailerDetailsChanged]="isTrailerDetailsChanged"
    (hasServiceDetailsChanged)="onHasServiceDetailsChanged($event)"
    (getServiceHistoryByDate)="onGetServiceHistoryByDate($event)"
    (updateServiceHistory)="onUpdateServiceHistory($event)"
    (cancelForm)="onCancelServiceForm()"
    (openServiceAttachments)="onOpenServiceAttachments($event)"
  ></app-camper-service-details>
</div>

<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h3 class="arb-large-heading" id="modal-basic-title">
      {{
        isTrailerAttachment
          ? 'TRAILER ATTACHMENT'
          : isServiceAttachment
          ? 'SERVICE ATTACHMENT'
          : isAddingCustomer
          ? 'NEW CUSTOMER'
          : isEditingCustomer
          ? 'EDIT CUSTOMER'
          : 'SELECT CUSTOMER'
      }}
    </h3>
  </div>
  <div class="modal-body">
    <div *ngIf="isTrailerAttachment || isServiceAttachment">
      <pos-app-attachment-files-notes
        [preloadAttachments]="
          !!isTrailerAttachment ? allTrailerAttachments : allServiceAttachments
        "
        [entityId]="entityId"
        [entityType]="entityType"
        (reloadedAttachments)="loadAllAttachments($event)"
        (closeActiveModal)="handleCloseModal($event)"
      ></pos-app-attachment-files-notes>
    </div>
    <div *ngIf="isAddingCustomer">
      <app-customers-details-new
        [isForNewOrder]="true"
        (newCustomer)="handleAddNewCustomer($event)"
      ></app-customers-details-new>
    </div>
    <div *ngIf="isEditingCustomer">
      <app-customers-details
        [isForNewOrder]="true"
        [preSelectedCustomer]="selectedCustomer"
        (updatedCustomer)="handleAddNewCustomer($event)"
      ></app-customers-details>
    </div>
    <div *ngIf="isSelectingCustomer">
      <pos-app-lib-customer-search
        [forOrderHeader]="true"
        (selectNewCustomer)="handleSelectCustomer($event)"
      ></pos-app-lib-customer-search>
    </div>
  </div>
</ng-template>
