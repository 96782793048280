<div class="row mb-3">
  <div class="col-auto mx-3 d-flex">
    <span class="align-text-bottom mt-2"><strong>FITTERS</strong></span>
  </div>
  <div class="col">
    <div class="row">
      <div class="col-4">
        <label class="float-end mt-2">ADD FITTER NOT SHOWN</label>
      </div>
      <div class="col-4 float-end">
        <pos-app-fuzzy-search
          [apiEndpointKey]="'resourceSearch'"
          [apiRequestKey]="'resourceSearchString'"
          [valueKey]="'resourceID'"
          [description1Key]="'resourceName'"
          [description2Key]="'branch'"
          [placeholder]="'ENTER RESOURCE NAME'"
          [isFitterSearch]="true"
          (selectedItem)="invokeLoadResourceAvailability($event)"
        ></pos-app-fuzzy-search>
      </div>
    </div>
  </div>
</div>
<div class="horizontal-scroll py-3 px-2">
  <div cdk-scrollable class="row min-row bg-light-gray mx-2 py-2">
    <div
      class="col-auto w-315 rounded-1 h-100 color-scheme mx-2"
      *ngFor="let resource of resourceList"
    >
      <div class="row">
        <div class="col">
          <h6 class="font-weight-500 font-size-18">
            {{ resource.resourceName }}
          </h6>
        </div>
      </div>

      <app-orders-for-fitter
        [fitter]="resource"
        [dragDropOrdersIdsList]="dragDropOrdersIdsList"
        [ordersAssignedToFitter]="
          assignedOrders.get(resource.resourceID.toString())
        "
        (renameGroupName)="updateGroupName($event)"
        (orderAddedToFitterEvent)="invokeChangeOrderBooking($event)"
      ></app-orders-for-fitter>
    </div>
  </div>
</div>
