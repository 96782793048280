import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { parse } from 'date-fns';

@Component({
  selector: 'app-allocate-order-dialog',
  templateUrl: './allocate-order-dialog.component.html',
  styleUrls: ['./allocate-order-dialog.component.scss'],
})
export class AllocateOrderDialogComponent {
  date = parse(this.data, 'dd/MM/yyyy', new Date());
  constructor(
    public dialogRef: MatDialogRef<AllocateOrderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }
}
