<pos-app-spinner [show]="loading"></pos-app-spinner>
<div class="container pt-4">
  <h4 class="text-center">SHOW USER ADMINISTRATION</h4>
  <div class="row pt-4">
    <div class="col-2">
      <label>SELECT SHOW</label>
    </div>
    <div class="col-4">
      <ng-select
        [(ngModel)]="selectedShow"
        [placeholder]="'SELECT A SHOW'"
        (change)="getShowUsers()"
      >
        <ng-option *ngFor="let show of showList$ | async" [value]="show">
          {{ show.showDescription }}
        </ng-option>
      </ng-select>
    </div>
    <div class="col-6"></div>
  </div>
  <div *ngIf="selectedShow">
    <div class="row pt-4">
      <div class="col-2">
        <label>BRANCH</label>
      </div>
      <div class="col-2">
        <input
          [value]="selectedShow?.showBranch"
          class="form-control"
          disabled
        />
      </div>
      <div class="col-1">
        <label>START</label>
      </div>
      <div class="col-2">
        <input
          [value]="selectedShow?.startDate"
          class="form-control"
          disabled
        />
      </div>
      <div class="col-1">
        <label>END</label>
      </div>
      <div class="col-2">
        <input [value]="selectedShow?.endDate" class="form-control" disabled />
      </div>
      <div class="col-2"></div>
    </div>
    <div class="row pt-4">
      <div class="col-2">
        <label>ADD ARB EMPLOYEE</label>
      </div>
      <div class="col-4">
        <pos-app-fuzzy-search
          [apiEndpointKey]="'fetchJDEUsers'"
          [apiRequestStaticFields]="{ fetchRegistered: true }"
          [apiRequestKey]="'userFilter'"
          [description1Key]="'userName'"
          [valueKey]="'userID'"
          [placeholder]="'SEARCH AND SELECT INTERNAL USER'"
          [isAutoFocus]="true"
          (selectedItem)="addShowCodeToUser($event)"
        ></pos-app-fuzzy-search>
      </div>
      <div class="col-2">
        <label>ADD EXTERNAL USER</label>
      </div>
      <div class="col-4">
        <ng-select
          (change)="addShowCodeToUser($event)"
          [placeholder]="'SELECT EXTERNAL USER'"
        >
          <ng-option *ngFor="let user of externalUserList" [value]="user.email">
            {{ user.name }} ({{ user.email }})
          </ng-option>
        </ng-select>
      </div>
    </div>
    <div class="row pt-4">
      <label>OR CREATE NEW USER JUST FOR THE SHOW</label>
    </div>
    <form [formGroup]="newUserForm">
      <div class="row pt-4">
        <div class="col-1">
          <label>EMAIL</label>
        </div>
        <div class="col-3">
          <input class="form-control" formControlName="email" />
        </div>
        <div class="col-1">
          <label>NAME</label>
        </div>
        <div class="col-2">
          <input class="form-control" formControlName="name" />
        </div>
        <div class="col-1">
          <label>PASSWORD</label>
        </div>
        <div class="col-2">
          <input
            type="password"
            class="form-control"
            formControlName="password"
          />
        </div>
        <div class="col-2">
          <button
            class="btn text-white"
            (click)="registerNewUser()"
            [disabled]="!newUserForm.valid"
          >
            REGISTER USER
          </button>
        </div>
      </div>
    </form>
    <div class="row py-4">
      <label>LIST OF SHOW USERS</label>
      <table
        class="table table-sm table-bordered"
        *ngIf="showUsers$ | async as userList"
      >
        <thead>
          <tr>
            <td><label>ACTION</label></td>
            <td><label>USER/EMAIL</label></td>
            <td><label>NAME</label></td>
            <td><label>BRANCH/CUSTOMERS</label></td>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let user of userList">
            <td>
              <mat-icon title="Delete user" (click)="deleteUser(user.userEmail)"
                >delete</mat-icon
              ><span class="px-2"></span
              ><mat-icon
                (click)="resetPasswordPopup(user.userEmail)"
                title="Reset Password"
                *ngIf="user.userEmail.indexOf('@') !== -1"
                >settings_backup_restore</mat-icon
              >
            </td>
            <td>{{ user.userEmail }}</td>
            <td>{{ user.name }}</td>
            <td>{{ user.organisation }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h3 class="arb-large-heading" id="modal-basic-title">RESET PASSWORD</h3>
  </div>
  <div class="modal-body">
    <form [formGroup]="resetPasswordForm">
      <div class="formGroup py-2 row">
        <div class="col-3">
          <label for="password"
            >NEW PASSWORD<span class="text-danger">*</span></label
          >
        </div>
        <div class="col-9">
          <input
            type="password"
            class="form-control"
            placeholder="New password"
            formControlName="newPassword"
          />
        </div>
      </div>
      <div
        *ngIf="
          resetPasswordForm.controls['newPassword'].touched &&
          resetPasswordForm.controls['newPassword'].errors
        "
        class="text-danger row"
      >
        <div class="offset-3 col-9">New password is required.</div>
      </div>

      <div class="formGroup py-2 row">
        <div class="col-3">
          <label for="password"
            >CONFIRM PASSWORD<span class="text-danger">*</span></label
          >
        </div>
        <div class="col-9">
          <input
            type="password"
            class="form-control"
            placeholder="Confirm password"
            formControlName="confirmPassword"
          />
        </div>
      </div>
      <div
        *ngIf="
          resetPasswordForm.controls['confirmPassword'].touched &&
          (resetPasswordForm.controls['confirmPassword'].value !==
            resetPasswordForm.controls['newPassword'].value ||
            resetPasswordForm.controls['confirmPassword'].errors)
        "
        class="text-danger row"
      >
        <div
          class="offset-3 col-9"
          *ngIf="!resetPasswordForm.controls['confirmPassword'].errors"
        >
          New password and Confirm password do not match
        </div>
        <div
          class="offset-3 col-9"
          *ngIf="resetPasswordForm.controls['confirmPassword'].errors"
        >
          Confirm password is required
        </div>
      </div>
      <div class="pt-3 d-flex justify-content-end">
        <button
          class="btn text-white"
          [disabled]="isResetFormInvalid() || loading"
          (click)="resetPassword()"
        >
          <pos-app-spinner2 *ngIf="loading"></pos-app-spinner2>
          RESET PASSWORD
        </button>
      </div>
    </form>
  </div>
</ng-template>
