<div class="background">
  <div class="background-overlay">
    <div class="row py-4 mg-top">
      <div class="col-xs-1 col-md-2"></div>
      <div class="col-xs-10 col-md-8">
        <div class="card">
          <div class="card-header text-white text-center">
            <h1 class="title-heading">RESET PASSWORD</h1>
          </div>
          <div class="card-body text-white">
            <p class="text-center">
              Please enter your old or temporary password followed by your new
              password
            </p>
            <form
              [formGroup]="form"
              class="login-container"
              (ngSubmit)="submit()"
            >
              <div class="formGroup py-2 row">
                <div class="col-3">
                  <label for="password"
                    >OLD PASSWORD<span class="text-danger">*</span></label
                  >
                </div>
                <div class="col-9">
                  <input
                    type="password"
                    class="form-control"
                    placeholder="Old password"
                    formControlName="oldPassword"
                  />
                </div>
              </div>
              <div
                *ngIf="
                  form.controls['oldPassword'].touched &&
                  form.controls['oldPassword'].errors
                "
                class="text-white row"
              >
                <div class="offset-3 col-9">Old password is required.</div>
              </div>

              <div class="formGroup py-2 row">
                <div class="col-3">
                  <label for="password"
                    >NEW PASSWORD<span class="text-danger">*</span></label
                  >
                </div>
                <div class="col-9">
                  <input
                    type="password"
                    class="form-control"
                    placeholder="New password"
                    formControlName="newPassword"
                  />
                </div>
              </div>
              <div
                *ngIf="
                  form.controls['newPassword'].touched &&
                  form.controls['newPassword'].errors
                "
                class="text-white row"
              >
                <div class="offset-3 col-9">New password is required.</div>
              </div>

              <div class="formGroup py-2 row">
                <div class="col-3">
                  <label for="password"
                    >CONFIRM PASSWORD<span class="text-danger">*</span></label
                  >
                </div>
                <div class="col-9">
                  <input
                    type="password"
                    class="form-control"
                    placeholder="Confirm password"
                    formControlName="confirmPassword"
                  />
                </div>
              </div>
              <div
                *ngIf="
                  form.controls['confirmPassword'].touched &&
                  (form.controls['confirmPassword'].value !==
                    form.controls['newPassword'].value ||
                    form.controls['confirmPassword'].errors)
                "
                class="text-white row"
              >
                <div
                  class="offset-3 col-9"
                  *ngIf="!form.controls['confirmPassword'].errors"
                >
                  New password and Confirm password do not match
                </div>
                <div
                  class="offset-3 col-9"
                  *ngIf="form.controls['confirmPassword'].errors"
                >
                  Confirm password is required
                </div>
              </div>
              <div class="formGroup text-right pt-3">
                <span class="pr-5">
                  <button
                    type="submit"
                    class="btn text-white"
                    [disabled]="isInValid() || loading"
                  >
                    <pos-app-spinner2 *ngIf="loading"></pos-app-spinner2>
                    RESET PASSWORD
                  </button>
                </span>
                <span class="px-2"></span>
                <button
                  type="button"
                  class="btn btn-cancel"
                  [routerLink]="['/pos/login']"
                >
                  CANCEL
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-xs-1 col-md-2 col-xl"></div>
    </div>
  </div>
</div>

<app-logintoshow
  [userProfile]="userProfile"
  (loggedInShow)="redirectAfterLogin()"
  *ngIf="userProfile"
></app-logintoshow>
