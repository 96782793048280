export const USER_ROLE = {
  external: 'EXTERNAL',
  guest: 'GUEST',
  internal: 'INTERNAL',
  showExternal: 'SHOW-EXTERNAL',
  showInternal: 'SHOW-INTERNAL'
};

export const SECURITY_FUNCTION = {
  adminUser: 'AdminUser',
  manageUser: 'ManageUsers',
  allowPayments: 'AllowPayments',
  processCredits: 'ProcessCredits',
  maintainOrders: 'MaintainOrders',
  registerAdmin: 'RegisterAdmin',
  showAdmin: 'ShowAdmin',
  viewStock: 'ViewStock',
  seeBuyPrice: 'SeeBuyPrice'
};
