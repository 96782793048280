import { createReducer, on } from '@ngrx/store';
import { CustomersState } from './customers.state';
import {
  LoadCustomerVehicles,
  LoadCustomerVehiclesSuccess,
  LoadCustomerVehiclesFailed,
  MaintainCustomerVehicle,
  MaintainCustomerVehicleSuccess,
  MaintainCustomerVehicleFailed,
  LoadLookupLists,
  LoadLookupListsFailed,
  SetActiveOrderVehicle,
  LoadLookupListsSuccess,
  SelectCountry,
  SelectCustomer,
  SelectCustomerSuccess,
  SelectCustomerFailed,
  LoadActiveOrderHeader,
  LoadActiveOrderHeaderSuccess,
  LoadActiveOrderHeaderFailed,
} from './customers.actions';

export const EPC_CUSTOMER_FEATURE_KEY = 'epcCustomer';

export const initialState: CustomersState = {
  lookupLists: null,
  loadingLookupLists: false,
  customerVehicles: [],
  selectedCountryCode: 'AU',
  activeOrderVehicle: null,
  selectedCustomer: null,
  activeOrderHeader: null,
};

const _customerReducer = createReducer(
  initialState,
  on(SelectCountry, (state, action) => ({
    ...state,
    selectedCountryCode: action.payload,
  })),
  on(LoadCustomerVehicles, (state, action) => ({
    ...state,
    loadingCustomerVehicles: true,
  })),
  on(LoadCustomerVehiclesSuccess, (state, action) => ({
    ...state,
    customerVehicles: action.payload.SearchResults,
    loadingCustomerVehicles: false,
  })),
  on(LoadCustomerVehiclesFailed, (state, action) => ({
    ...state,
    loadingCustomerVehicles: false,
  })),
  on(MaintainCustomerVehicle, (state, action) => ({
    ...state,
    updatingCustomerVehicle: true,
    selectedCustomerVehicleID: null,
  })),
  on(MaintainCustomerVehicleSuccess, (state, action) => ({
    ...state,
    updatingCustomerVehicle: false,
    selectedCustomerVehicleID: action.payload.CustomerVehicleID,
    loadingCustomerVehicles: true,
  })),
  on(MaintainCustomerVehicleFailed, (state, action) => ({
    ...state,
    updatingCustomerVehicle: false,
  })),
  on(LoadLookupLists, (state, action) => ({
    ...state,
    loadingLookupLists: true,
  })),
  on(LoadLookupListsSuccess, (state, action) => ({
    ...state,
    lookupLists: action.payload,
    loadingLookupLists: false,
    cachedLookupLists: action.payload,
  })),
  on(LoadLookupListsFailed, (state, action) => ({
    ...state,
    loadingLookupLists: false,
  })),
  on(SetActiveOrderVehicle, (state, action) => ({
    ...state,
    activeOrderVehicle: action.payload,
  })),
  on(SelectCustomer, (state, action) => ({
    ...state,
    loadingCustomerDetails: true,
    createdCustomerId: null,
  })),
  on(SelectCustomerSuccess, (state, action) => ({
    ...state,
    selectedCustomer: action.payload.SearchResults
      ? action.payload.SearchResults[0]
      : null,
    selectedCountryCode:
      action.payload.SearchResults && action.payload.SearchResults[0]
        ? action.payload.SearchResults[0].CountryCode
        : null,
    loadingCustomerDetails: false,
  })),
  on(SelectCustomerFailed, (state, action) => ({
    ...state,
    loadingCustomerDetails: false,
  })),

  on(LoadActiveOrderHeader, (state, action) => ({
    ...state,
    loadingOrderHeader: true,
  })),
  on(LoadActiveOrderHeaderSuccess, (state, action) => ({
    ...state,
    activeOrderHeader: action.payload,
    loadingOrderHeader: false,
  })),
  on(LoadActiveOrderHeaderFailed, (state, action) => ({
    ...state,
    loadingOrderHeader: false,
  }))
);

export function customerReducer(state, action) {
  return _customerReducer(state, action);
}
