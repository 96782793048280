import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '@pos-app/core-ui';
import { Branch } from '@pos-app/data';
import { combineLatest, of, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { CrossDock, Inbound, Inventory, OrderHeader, StagingOrderLine } from '../../+state/orders.models';

@Component({
  selector: 'app-orders-detailed-availability',
  templateUrl: './orders-detailed-availability.component.html',
  styleUrls: ['./orders-detailed-availability.component.scss'],
})
export class OrdersDetailedAvailabilityComponent implements OnInit, OnDestroy {
  @ViewChild('content') modalBox: any;

  @Input() availabilityItem: StagingOrderLine;
  @Input() orderHeader: OrderHeader;
  @Input() branchList: Branch[];

  @Output() cancelAvailabilityView = new EventEmitter<void>();

  unSubscribe$ = new Subject<void>();
  crossDockList: CrossDock[];
  detailedInventory: Inventory[];
  inboundDetails: Inbound[];
  loading: boolean;
  dispatchBranch: Branch;
  columnDefs;
  gridOptions;
  rowData = [];
  autoGroupColumnDef;
  selectedBranch;
  paintCode = 'UNPAINTED';

  private gridApi;

  constructor(private modalService: NgbModal, private apiService: ApiService) {
    this.autoGroupColumnDef = {
      headerName: 'BRANCH/STATE',
      field: 'branchLabel',
      minWidth: 300,
      cellClass: 'cell-class',
      headerClass: 'header-class',
      cellRendererParams: {
        suppressCount: true,
      },
    };

    this.gridOptions = {
      defaultColDef: {
        resizable: true,
        cellRenderer: (params) => {
          const data = params.value;
          return data < 0 ? `<span class="text-danger">${data}</span>` : data;
        },
      },
      columnDefs: [
        {
          field: 'groupLabel',
          rowGroup: true,
          hide: true,
        },
        {
          headerName: 'ON HAND',
          field: 'qtyOnHand',
          sortable: true,
          cellClass: 'cell-class',
          headerClass: 'header-class',
          aggFunc: 'sum',
          width: 120,
        },
        {
          headerName: 'AVAILABLE',
          field: 'qtyAvailable',
          sortable: true,
          cellClass: 'cell-class available-cell',
          headerClass: 'header-class',
          aggFunc: 'max',
          width: 120,
        },
        {
          headerName: 'IN TRANSIT',
          field: 'qtyInTransit',
          sortable: true,
          cellClass: 'cell-class',
          headerClass: 'header-class',
          aggFunc: 'sum',
          width: 160,
        },
        {
          headerName: 'OPEN INBOUND',
          field: 'qtyInboundNotShipped',
          sortable: true,
          cellClass: 'cell-class',
          headerClass: 'header-class',
          aggFunc: 'sum',
          width: 160,
        },
        {
          headerName: 'ON WO w/o PARTS LIST',
          field: 'qtyOnWOPartsList',
          sortable: true,
          cellClass: 'cell-class',
          headerClass: 'header-class',
          aggFunc: 'sum',
          width: 240,
        },
      ],
    };
  }

  ngOnInit() {
    this.selectedBranch = this.orderHeader.branch;
    this.dispatchBranch = this.branchList.find((x) => x.BranchCode === this.orderHeader.branch);
    this.loading = true;
    combineLatest([
      this.orderHeader.orderNumber.startsWith('T')
        ? of({ crossDockArray: [] })
        : this.apiService.getCrossDock({
            itemNumber: this.availabilityItem.itemNumber,
            branch: this.orderHeader.branch,
            orderNumber: this.orderHeader.orderNumber,
          }),
      this.apiService.getDetailedInventory({
        itemNumber: this.availabilityItem.itemNumber,
        branch: this.orderHeader.branch,
        lotSerialNumber: this.availabilityItem.paintableYN !== 'Y' ? '' : this.paintCode,
      }),
      this.apiService.getInboundDetails({
        itemNumber: this.availabilityItem.itemNumber,
        branch: this.orderHeader.branch,
      }),
    ])
      .pipe(
        finalize(() => (this.loading = false)),
        takeUntil(this.unSubscribe$)
      )
      .subscribe(([crossDock, inventory, inbound]) => {
        this.loading = false;
        this.crossDockList = crossDock.crossDockArray;
        this.detailedInventory = inventory.availabilityArray;
        this.inboundDetails = inbound.inboundArray;
        this.rowData = this.detailedInventory;
        this.modalService.open(this.modalBox, {
          backdrop: 'static',
          size: 'xl',
        });
      });
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  cancel() {
    this.modalService.dismissAll();
    this.cancelAvailabilityView.emit();
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  onDataRendered() {
    if (this.gridApi) {
      this.gridApi.forEachNode((node, b) => {
        if (node.level === 0 && node.childIndex === 0) {
          node.setExpanded(true);
          return;
        }
      });
    }
  }

  selectBranch(branch: string) {
    this.apiService
      .getInboundDetails({
        itemNumber: this.availabilityItem.itemNumber,
        branch,
      })
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((res) => {
        this.inboundDetails = res.inboundArray;
      });
  }

  refetchAvailability() {
    this.apiService
      .getDetailedInventory({
        itemNumber: this.availabilityItem.itemNumber,
        branch: this.orderHeader.branch,
        lotSerialNumber: this.paintCode,
      })
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((inventory) => {
        this.detailedInventory = inventory.availabilityArray;
        this.rowData = this.detailedInventory;
      });
  }
}
