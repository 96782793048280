<pos-app-spinner [show]="isLoading"></pos-app-spinner>
<div class="container pt-3">
  <div class="row pb-2">
    <div class="col-4 col-xl-3 my-auto">
      <label>ENTER AMOUNT</label>
    </div>
    <div class="col-5">
      <input type="number" [formControl]="amount" class="form-control" />
    </div>
  </div>
  <div class="actions pb-2">
    <ng-container *ngIf="fakeEFTPOSTF">
      <button
        class="btn text-white"
        (click)="makeTransaction('ERROR')"
        [disabled]="amount.invalid || amount.value === 0 || amount.value > outstanding || isLoading"
      >
        ERROR EFTPOS
      </button>
      <button
        class="btn text-white"
        (click)="makeTransaction('PENDING')"
        [disabled]="amount.invalid || amount.value === 0 || amount.value > outstanding || isLoading"
      >
        PENDING EFTPOS
      </button>
    </ng-container>
    <button
      class="btn text-white"
      (click)="makeTransaction()"
      [disabled]="amount.invalid || amount.value === 0 || amount.value > outstanding || isLoading"
    >
      SEND TO EFTPOS
    </button>
    <button class="btn btn-cancel" (click)="cancel.emit()">CANCEL</button>
  </div>
</div>
