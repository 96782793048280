import { Action } from '@ngrx/store';

export enum ManageusersActionTypes {
  LOAD_INTERNAL_USERS = '[Manageusers] Load Internal Users',
  LOAD_INTERNAL_USERS_SUCCESS = '[Manageusers] Load Internal Users Success',
  LOAD_INTERNAL_USERS_FAILED = '[Manageusers] Load Internal Users Failed',

  LOAD_REGISTERED_USERS = '[Manageusers_external] Load Registered Users',
  LOAD_REGISTERED_USERS_SUCCESS = '[Manageusers_external] Load Registered Users Success',
  LOAD_REGISTERED_USERS_FAILED = '[Manageusers_external] Load Registered Users Failed',

  LOAD_SECURITY_FUNCTIONS = '[Manageusers] Load Security Functions',
  LOAD_SECURITY_FUNCTIONS_SUCCESS = '[Manageusers] Load Security Functions Success',
  LOAD_SECURITY_FUNCTIONS_FAILED = '[Manageusers] Load Security Functions Failed',

  EDIT_USER_SECURITY_FUNCTIONS = '[Manageusers] Edit user security functions',
  EDIT_USER_SECURITY_FUNCTIONS_SUCCESS = '[Manageusers] Edit user security functions success',
  EDIT_USER_SECURITY_FUNCTIONS_FAILED = '[Manageusers] Edit user security functions failed',

  EDIT_EXTERNAL_USER_SECURITY_FUNCTIONS = '[Manageusers] Edit external user security functions',
  EDIT_EXTERNAL_USER_SECURITY_FUNCTIONS_SUCCESS = '[Manageusers] Edit external user security functions success',
  EDIT_EXTERNAL_USER_SECURITY_FUNCTIONS_FAILED = '[Manageusers] Edit external user security functions failed',

  EDIT_EMPLOYEE_PROFILE = '[Manageusers] Edit employee profile',
  EDIT_EMPLOYEE_PROFILE_SUCCESS = '[Manageusers] Edit employee profile success',
  EDIT_EMPLOYEE_PROFILE_FAILED = '[Manageusers] Edit employee profile failed',

  LOAD_LOOKUP_LIST = '[Manageusers] Load lookup lists',
  LOAD_LOOKUP_LIST_SUCCESS = '[Manageusers] Load lookup lists success',
  LOAD_LOOKUP_LIST_FAILED = '[Manageusers] Load lookup lists failed',

  UPDATE_ACCOUNT_CUSTOMER_SETTINGS = '[Manageusers] Update account customer settings',
  UPDATE_ACCOUNT_CUSTOMER_SETTINGS_SUCCESS = '[Manageusers] Update account customer settings success',
  UPDATE_ACCOUNT_CUSTOMER_SETTINGS_FAILED = '[Manageusers] Update account customer settings faild',

  RESET_LIST = '[Manageusers] Reset list',
  RESET_REGISTERED_LIST = '[Manageusers] Reset external registered list',
}

export class ResetList implements Action {
  readonly type = ManageusersActionTypes.RESET_LIST;
  constructor() {}
}

export class ResetRegisteredList implements Action {
  readonly type = ManageusersActionTypes.RESET_REGISTERED_LIST;
  constructor() {}
}

export class LoadInternalUsers implements Action {
  readonly type = ManageusersActionTypes.LOAD_INTERNAL_USERS;
  constructor(public payload: any) {}
}

export class LoadInternalUsersFailed implements Action {
  readonly type = ManageusersActionTypes.LOAD_INTERNAL_USERS_FAILED;
  constructor(public payload: any) {}
}

export class LoadInternalUsersSuccess implements Action {
  readonly type = ManageusersActionTypes.LOAD_INTERNAL_USERS_SUCCESS;
  constructor(public payload: any) {}
}

export class LoadRegisteredUsers implements Action {
  readonly type = ManageusersActionTypes.LOAD_REGISTERED_USERS;
  constructor(public payload: any) {}
}

export class LoadRegisteredUsersFailed implements Action {
  readonly type = ManageusersActionTypes.LOAD_REGISTERED_USERS_FAILED;
  constructor(public payload: any) {}
}

export class LoadRegisteredUsersSuccess implements Action {
  readonly type = ManageusersActionTypes.LOAD_REGISTERED_USERS_SUCCESS;
  constructor(public payload: any) {}
}

export class LoadSecurityFunctions implements Action {
  readonly type = ManageusersActionTypes.LOAD_SECURITY_FUNCTIONS;
  constructor(public payload: any) {}
}

export class LoadSecurityFunctionsFailed implements Action {
  readonly type = ManageusersActionTypes.LOAD_SECURITY_FUNCTIONS_FAILED;
  constructor(public payload: any) {}
}

export class LoadSecurityFunctionsSuccess implements Action {
  readonly type = ManageusersActionTypes.LOAD_SECURITY_FUNCTIONS_SUCCESS;
  constructor(public payload: any) {}
}
export class EditUserSecurityFunctions implements Action {
  readonly type = ManageusersActionTypes.EDIT_USER_SECURITY_FUNCTIONS;
  constructor(public payload: any) {}
}

export class EditUserSecurityFunctionsFailed implements Action {
  readonly type = ManageusersActionTypes.EDIT_USER_SECURITY_FUNCTIONS_FAILED;
  constructor(public payload: any) {}
}

export class EditUserSecurityFunctionsSuccess implements Action {
  readonly type = ManageusersActionTypes.EDIT_USER_SECURITY_FUNCTIONS_SUCCESS;
  constructor(public payload: any) {}
}

export class EditEmployeeProfile implements Action {
  readonly type = ManageusersActionTypes.EDIT_EMPLOYEE_PROFILE;
  constructor(public payload: any) {}
}

export class EditEmployeeProfileFailed implements Action {
  readonly type = ManageusersActionTypes.EDIT_EMPLOYEE_PROFILE_FAILED;
  constructor(public payload: any) {}
}

export class EditEmployeeProfileSuccess implements Action {
  readonly type = ManageusersActionTypes.EDIT_EMPLOYEE_PROFILE_SUCCESS;
  constructor(public payload: any) {}
}

export class EditExternalUserSecurityFunctions implements Action {
  readonly type = ManageusersActionTypes.EDIT_EXTERNAL_USER_SECURITY_FUNCTIONS;
  constructor(public payload: any) {}
}

export class EditExternalUserSecurityFunctionsFailed implements Action {
  readonly type =
    ManageusersActionTypes.EDIT_EXTERNAL_USER_SECURITY_FUNCTIONS_FAILED;
  constructor(public payload: any) {}
}

export class EditExternalUserSecurityFunctionsSuccess implements Action {
  readonly type =
    ManageusersActionTypes.EDIT_EXTERNAL_USER_SECURITY_FUNCTIONS_SUCCESS;
  constructor(public payload: any) {}
}

export class LoadLookupLists implements Action {
  readonly type = ManageusersActionTypes.LOAD_LOOKUP_LIST;
  constructor() {}
}

export class LoadLookupListsFailed implements Action {
  readonly type = ManageusersActionTypes.LOAD_LOOKUP_LIST_FAILED;
  constructor(public payload: any) {}
}

export class LoadLookupListsSuccess implements Action {
  readonly type = ManageusersActionTypes.LOAD_LOOKUP_LIST_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateAccountCustomerSettings implements Action {
  readonly type = ManageusersActionTypes.UPDATE_ACCOUNT_CUSTOMER_SETTINGS;
  constructor(public payload: any) {}
}

export class UpdateAccountCustomerSettingsFailed implements Action {
  readonly type =
    ManageusersActionTypes.UPDATE_ACCOUNT_CUSTOMER_SETTINGS_FAILED;
  constructor(public payload: any) {}
}

export class UpdateAccountCustomerSettingsSuccess implements Action {
  readonly type =
    ManageusersActionTypes.UPDATE_ACCOUNT_CUSTOMER_SETTINGS_SUCCESS;
  constructor(public payload: any, public ARBuAvailableYN: string) {}
}

export type ManageusersAction =
  | LoadInternalUsers
  | LoadInternalUsersSuccess
  | LoadInternalUsersFailed
  | LoadRegisteredUsers
  | LoadRegisteredUsersFailed
  | LoadRegisteredUsersSuccess
  | LoadSecurityFunctions
  | LoadSecurityFunctionsSuccess
  | LoadSecurityFunctionsFailed
  | EditUserSecurityFunctions
  | EditUserSecurityFunctionsSuccess
  | EditUserSecurityFunctionsFailed
  | EditExternalUserSecurityFunctions
  | EditExternalUserSecurityFunctionsSuccess
  | EditExternalUserSecurityFunctionsFailed
  | ResetList
  | ResetRegisteredList
  | EditEmployeeProfile
  | EditEmployeeProfileSuccess
  | EditEmployeeProfileFailed
  | LoadLookupLists
  | LoadLookupListsSuccess
  | LoadLookupListsFailed
  | UpdateAccountCustomerSettings
  | UpdateAccountCustomerSettingsSuccess
  | UpdateAccountCustomerSettingsFailed;

export const fromManageusersActions = {
  LoadInternalUsers,
  LoadInternalUsersSuccess,
  LoadInternalUsersFailed,
  LoadRegisteredUsers,
  LoadRegisteredUsersSuccess,
  LoadRegisteredUsersFailed,
  LoadSecurityFunctions,
  LoadSecurityFunctionsSuccess,
  LoadSecurityFunctionsFailed,
  EditUserSecurityFunctions,
  EditUserSecurityFunctionsSuccess,
  EditUserSecurityFunctionsFailed,
  EditExternalUserSecurityFunctions,
  EditExternalUserSecurityFunctionsSuccess,
  EditExternalUserSecurityFunctionsFailed,
  ResetList,
  ResetRegisteredList,
  EditEmployeeProfile,
  EditEmployeeProfileSuccess,
  EditEmployeeProfileFailed,
  LoadLookupLists,
  LoadLookupListsSuccess,
  LoadLookupListsFailed,
  UpdateAccountCustomerSettings,
  UpdateAccountCustomerSettingsSuccess,
  UpdateAccountCustomerSettingsFailed,
};
