import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ApiService } from '@pos-app/core-ui';
import { Show } from '@pos-app/data';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { ExternalUser, ShowUser } from '../../+state/user.model';
import { LoadParkedOrdersList } from '../../../orders/+state/orders.actions';
import { OrdersPartialState } from '../../../orders/+state/orders.reducer';

@Component({
  selector: 'app-manageusers-show',
  templateUrl: './manageusers-show.component.html',
  styleUrls: ['./manageusers-show.component.scss'],
})
export class ManageusersShowComponent implements OnInit {
  @ViewChild('content') modalBox: any;
  selectedShow: Show;
  showList$: Observable<Show>;
  newUserForm: FormGroup;
  externalUserList: ExternalUser[];
  showUsers$: Observable<ShowUser[]>;
  loading: boolean;
  resetPasswordForm: FormGroup;

  constructor(
    private apiService: ApiService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private orderStore: Store<OrdersPartialState>
  ) {
    this.newUserForm = this.fb.group({
      email: ['', [Validators.email]],
      name: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
    this.resetPasswordForm = this.fb.group({
      email: [''],
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    });
  }

  ngOnInit() {
    this.showList$ = this.apiService.getShowList().pipe(
      map((res) => {
        return res.SearchResults;
      })
    );
    this.getExternalUserList();
    this.dispatchParkedOrdersList();
  }

  getExternalUserList() {
    this.apiService
      .getExternalUserList()
      .pipe()
      .subscribe((res) => {
        this.externalUserList = res.SearchResults;
      });
  }

  addShowCodeToUser(user: string) {
    if (user) {
      this.loading = true;
      this.apiService
        .createShowCode({
          userEmail: user,
          showCode: this.selectedShow.showCode,
        })
        .pipe(finalize(() => (this.loading = false)))
        .subscribe(() => {
          this.toastr.success('User added to the show');
          this.loading = false;
          this.getShowUsers();
        });
    }
  }
  registerNewUser() {
    this.loading = true;
    const base64pwd = btoa(this.newUserForm.controls.password.value);
    this.apiService
      .createShowUser({
        userEmail: this.newUserForm.controls.email.value,
        userName: this.newUserForm.controls.name.value,
        password: base64pwd,
        showCode: this.selectedShow.showCode,
      })
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(() => {
        this.toastr.success('User registered to the show');
        this.loading = false;
        this.getShowUsers();
        this.getExternalUserList();
      });
  }

  getShowUsers() {
    if (this.selectedShow) {
      this.showUsers$ = this.apiService
        .getShowUsers({ showCode: this.selectedShow.showCode })
        .pipe(map((res) => res.SearchResults));
    }
  }

  resetPasswordPopup(email: string) {
    this.resetPasswordForm.patchValue({
      email,
      newPassword: '',
      confirmPassword: '',
    });
    this.resetPasswordForm.markAsPristine();
    this.modalService.open(this.modalBox, { size: 'lg' });
  }

  deleteUser(email: string) {
    this.apiService
      .deleteShowCode({
        userEmail: email,
        showCode: this.selectedShow.showCode,
      })
      .pipe()
      .subscribe(() => {
        this.toastr.success('Show user removed');
        this.getShowUsers();
      });
  }

  isResetFormInvalid() {
    return (
      !this.resetPasswordForm.valid ||
      this.resetPasswordForm.controls.newPassword.value !==
        this.resetPasswordForm.controls.confirmPassword.value
    );
  }

  resetPassword() {
    this.loading = true;
    this.apiService
      .resetPassword({
        email: this.resetPasswordForm.controls.email.value,
        oldPassword: '',
        newPassword: this.resetPasswordForm.controls.newPassword.value,
        bypassPasswordCheck: 'Y',
      })
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(
        (res) => {
          if (res) {
            this.loading = false;
            this.toastr.success(res.ErrorMessage);
            this.modalService.dismissAll();
          }
        },
        (error) => {
          this.toastr.error(error.ErrorMessage);
        }
      );
  }

  dispatchParkedOrdersList() {
    this.orderStore.dispatch(new LoadParkedOrdersList());
  }
}
