<pos-app-spinner [show]="isLoadingCustomerVehicles$ | async"></pos-app-spinner>
<div
  class="card"
  *ngIf="{ values: customerVehicles$ | async } as customerVehicles"
>
  <div class="card-body">
    <div
      class="text-warning"
      *ngIf="
        !(isLoadingCustomerVehicles$ | async) &&
        customerVehicles?.values?.length === 0
      "
    >
      The customer currently has no vehicle. Please add a vehicle to this
      customer
    </div>
    <div *ngIf="customerVehicles?.values?.length > 0">
      <div class="custom-control custom-checkbox my-1 mr-sm-2 text-right">
        <input
          type="checkbox"
          class="custom-control-input"
          id="customControlInline"
          [checked]="showRetired"
          (change)="showRetiredToggle()"
        />
        <label class="custom-control-label" for="customControlInline"
          >Show Retired Vehicles</label
        >
      </div>
      <div
        class="text-warning"
        *ngIf="!(hasActiveVehicle$ | async) && !showRetired"
      >
        This customer has no active vehicle. Check the 'Show Retired Vehicles'
        to see all retired vehicles
      </div>
      <table
        class="table table-sm table-bordered"
        *ngIf="(hasActiveVehicle$ | async) || showRetired"
      >
        <thead>
          <tr>
            <th scope="col">Default</th>
            <th scope="col">Short Description</th>
            <th scope="col">Rego</th>
            <th scope="col">Colour</th>
            <th scope="col">Date Added</th>
            <th scope="col" *ngIf="showRetired">Date Retired</th>
            <th scope="col">Vehicle Long Description</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let vehicle of customerVehicles?.values; let i = index"
            (click)="selectVehicle(vehicle)"
            [ngClass]="
              (customerVehicles?.values)[i] === selectedVehicle
                ? 'table-primary'
                : ''
            "
            [hidden]="
              !showRetired && (customerVehicles?.values)[i].DateRetired !== ''
            "
          >
            <td class="text-center">
              <mat-icon *ngIf="vehicle.DefaultFlag === '1'">check</mat-icon>
            </td>
            <td>
              {{ vehicle.ShortDescription
              }}<span class="px-2"
                ><mat-icon
                  (click)="copyToClipBoard(vehicle.CustomerVehicleID, true)"
                  [matTooltip]="
                    !vehicleCustomerIDCopied ||
                    copiedValue !== vehicle.CustomerVehicleID
                      ? 'Copy Customer Vehicle ID'
                      : 'Copied'
                  "
                  >move_to_inbox</mat-icon
                ></span
              >
            </td>
            <td>{{ vehicle.RegoNumber }}</td>
            <td>{{ vehicle.ColourCode }}</td>
            <td>{{ vehicle.DateCreated }}</td>
            <td *ngIf="showRetired">{{ vehicle.DateRetired }}</td>
            <td>
              {{ vehicle.VehicleFullDescription
              }}<span class="px-2"
                ><mat-icon
                  (click)="copyToClipBoard(vehicle.VehicleID)"
                  [matTooltip]="
                    !vehicleIDCopied || copiedValue !== vehicle.VehicleID
                      ? 'Copy Generic Vehicle ID'
                      : 'Copied'
                  "
                  >move_to_inbox</mat-icon
                ></span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="pt-2 pb-3">
      <button
        class="btn arb-other-button"
        [routerLink]="
          ['/pos/customers/'] + selectedCustomerNumber + '/add/vehicles'
        "
      >
        Add Vehicle
      </button>
      <span *ngIf="selectedVehicle">
        <span class="px-2"
          ><button
            class="arb-other-button"
            [routerLink]="
              ['/pos/customers/'] +
              selectedCustomerNumber +
              '/vehicles/' +
              selectedVehicle.customerVehicleID
            "
          >
            Edit Vehicle
          </button></span
        >
        <span *ngIf="{ values: defaultVehicle$ | async } as defaultVehicle">
          <span
            class="pr-2"
            *ngIf="
              defaultVehicle?.values?.CustomerVehicleID !==
                selectedVehicle.customerVehicleID &&
              selectedVehicle.DateRetired === ''
            "
          >
            <button
              class="arb-other-button px-2"
              (click)="makeDefault(defaultVehicle.values)"
            >
              Make Vehicle Default
            </button>
          </span>
        </span>
        <button
          type="button"
          class="arb-other-button"
          placement="right"
          [ngbPopover]="retireCode"
          popoverTitle="Please select the retire code"
          [autoClose]="'outside'"
          #popOver="ngbPopover"
          *ngIf="selectedVehicle.DateRetired === ''"
        >
          Retire Vehicle
        </button>
        <button
          class="arb-other-button"
          *ngIf="selectedVehicle.DateRetired !== ''"
          (click)="unRetire()"
        >
          Un-retire Vehicle
        </button>
      </span>
    </div>
  </div>
</div>

<ng-template #retireCode>
  <select [formControl]="retireCodeForm">
    <option
      *ngFor="let reason of (lookupLists$ | async).vehicleRetiredReasonList"
      [value]="reason.VehicleRetiredReasonCode"
      >{{
        reason.VehicleRetiredReasonCode +
          ' - ' +
          reason.VehicleRetiredReasonDescription
      }}</option
    >
  </select>
  <div class="pt-1">
    <button class="btn btn-success btn-sm" (click)="retire()">OK</button>
  </div>
</ng-template>
