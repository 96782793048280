import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as saveAs from 'file-saver';
import {
  AlertMessageToUpdate,
  ApiResponse,
  API_ENDPOINTS,
  CustomerSearchResult,
  LISTS_FOR_CUSTOMERS,
  LISTS_FOR_MANAGED_USERS,
  LISTS_FOR_ORDERS,
  ListTasksNotes,
  VehicleSearchParamters,
  VehicleSearchResult,
  LoadStagingOrderInput,
  StockAllocationResponse,
  AllocationArray,
  FetchGiftCardLoadResponse,
  CustomerSearchParamters,
  BrandDefaults,
} from '@pos-app/data';
import { combineLatest, from, Observable, of } from 'rxjs';
import { ConfigService } from './config.service';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import * as localforage from 'localforage';
import { ItemPriceGroupResponse } from '../models/item-price-group';

export type AttachmentEntityType =
  | 'order'
  | 'feedback'
  | 'warranty'
  | 'crm'
  | 'order_line'
  | 'CamperTrailer'
  | 'ServiceAttachment';
export type AttachmentType = 'file' | 'link' | 'note';
export interface AttachmentDto {
  entityType: AttachmentEntityType;
  entityId: string;
  type: AttachmentType;
  noteOrLink?: string;
  file?: File;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private config: ConfigService, private http: HttpClient) {}

  duplicateCustomerSearch(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.duplicateCustomerSearch}`,
      parameters
    );
  }

  vehicleSearch(parameters: VehicleSearchParamters) {
    return this.http.post<VehicleSearchResult>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.vehicleSearch}`,
      { ...parameters, brand: this.config.AppConfig.brand }
    );
  }

  customerSearch(parameters: CustomerSearchParamters) {
    return this.http.post<CustomerSearchResult>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.customerSearch}`,
      parameters
    );
  }

  fetchCustomerDetails(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchCustomerDetails}`,
      parameters
    );
  }

  fetchCustomerShipTo(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchCustomerShipTo}`,
      parameters
    );
  }

  fetchCustomerBillTo(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchCustomerBillTo}`,
      parameters
    );
  }

  getCustomerDetails(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.getCustomerDetails}`,
      parameters
    );
  }

  getCustomerDetailsExternal() {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.getCustomerDetailsExternal}`,
      {}
    );
  }

  getCustomerVehicles(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.getCustomerVehicles}`,
      parameters
    );
  }

  maintainCustomerVehicle(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.maintainCustomerVehicle}`,
      parameters
    );
  }

  createRetailCustomer(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.createRetailCustomer}`,
      parameters
    );
  }

  updateRetailCustomer(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.updateRetailCustomer}`,
      parameters
    );
  }

  updateShipToCustomer(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.updateShipToCustomer}`,
      parameters
    );
  }

  createShipToCustomer(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.createShipToCustomer}`,
      parameters
    );
  }

  resetPassword(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.resetPasword}`,
      { ...parameters, brand: this.config.AppConfig.brand }
    );
  }

  forgottenPassword(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.forgottenPassword}`,
      { ...parameters, brand: this.config.AppConfig.brand }
    );
  }

  getLookupLists() {
    return combineLatest(
      LISTS_FOR_CUSTOMERS.map((endpoint) => {
        return this.getList(endpoint, null);
      })
    );
  }

  getOrderFilterLists() {
    return combineLatest(
      LISTS_FOR_ORDERS.map((endpoint) => {
        return this.getList(endpoint, null);
      })
    );
  }

  getManagedUserFilterLists() {
    return combineLatest(
      LISTS_FOR_MANAGED_USERS.map((endpoint) => {
        return this.getList(endpoint, null);
      })
    );
  }

  createOrder(parameters: any) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.createOrder}`,
      parameters
    );
  }

  createOrderSilent() {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.createOrderSilent}`,
      null
    );
  }

  getOrderSummary(parameters: any) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.orderSummary}`,
      parameters
    );
  }

  getOrderSummaryOpenOrders(parameters: any) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.orderSummaryOpenOrders}`,
      parameters
    );
  }

  getOrderSummaryQuoteOrders(parameters: any) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.orderSummaryQuoteOrders}`,
      parameters
    );
  }

  generateQuoteForNonCorp(parameters) {
    const url = `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.generateQuoteForNonCorp}`;
    return this.http.post(url, parameters, {
      headers: new HttpHeaders({
        Accept: 'application/octet-stream',
      }),
      observe: 'response',
      responseType: 'blob',
    });
  }


  getOrderSummaryInvoicedOrders(parameters: any) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.orderSummaryInvoicedOrders}`,
      parameters
    );
  }

  getPartDetails(parameters: any) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.getPartDetails}`,
      parameters
    );
  }

  searchItems(parameters: any) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.searchItems}`,
      parameters
    );
  }

  fuzzySearch(parameters: any, endpoint: string) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${endpoint}`,
      parameters
    );
  }

  login(parameters: any) {
    let headers = new HttpHeaders();
    headers = headers
      .set('user', parameters.username)
      .set('password', parameters.password);
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.loginV2}`,
      parameters,
      { headers }
    );
  }

  register(parameters: any) {
    return this.http.post(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.registerExternalUserPublic}`,
      { ...parameters, brand: this.config.AppConfig.brand }
    );
  }

  logout() {
    return this.http.post(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.logoutV2}`,
      null
    );
  }

  getCountryList() {
    return this.getList('countryExternaLookup');
  }

  getBranchList() {
    return this.getList('branchLookup', null).pipe(map(data => {
      return {
        ...data,
        SearchResults : data.SearchResults.map(item => ({
          ...item,
          BranchCode: item.BranchCode?.trim()
        }))
      }
    }));
  }

  getPrinterList() {
    return this.getList('JDEPrinters', null);
  }

  getColourList() {
    return this.getList('colourLookup', null);
  }

  fetchDefaultVehicle(parameters: any) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchDefaultVehicle}`,
      parameters
    );
  }

  fetchJDEUsers(parameters: any) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchJDEUsers}`,
      parameters
    );
  }

  fetchSingleJDE(parameters: any) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchJDESingleUser}`,
      parameters
    );
  }

  fetchRegisteredUsers(parameters: any) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchRegisteredUsers}`,
      parameters
    );
  }

  fetchSecurityFunctions(parameters: any) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchSecurityFunctions}`,
      { ...parameters, brand: this.config.AppConfig.brand }
    );
  }

  setJDEUserSecurity(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.setJDEUserSecurity}`,
      parameters
    );
  }

  setUserSecurity(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.setUserSecurity}`,
      parameters
    );
  }

  fetchUserState() {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchUserState}`,
      {}
    );
  }

  getOrderHeader(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchOrderHeader}`,
      parameters
    );
  }

  addItemToOrder(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.addItemToOrder}`,
      { ...parameters, brand: this.config.AppConfig.brand }
    );
  }

  validateItemForOrder(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.validateItemForOrder}`,
      parameters
    );
  }

  getStagingOrderDetails(parameters: LoadStagingOrderInput) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchOrderDetails}`,
      parameters
    );
  }

  fetchUpdatedOrderLine(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchUpdatedOrderLine}`,
      parameters
    );
  }

  removeItemFromOrder(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.removeItemFromOrder}`,
      parameters
    );
  }

  updateItemOnOrder(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.updateItemOnOrder}`,
      parameters
    );
  }
  
  updateAllItemsOnOrder(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.updateAllItemsOnOrder}`,
      parameters
    );
  }

  updateOrderHeader(parameters) {
    return this.http.post<ApiResponse& {refreshDetailGridTF: boolean}>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.updateOrderHeader}`,
      parameters
    );
  }

  getItemPriceGroup(parameters) {
    return this.http.post<ItemPriceGroupResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchItemPriceGroup}`,
      { ...parameters, brand: this.config.AppConfig.brand }
    );
  }

  unloadOrder(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.unloadOrder}`,
      parameters
    );
  }

  insertLineAfter(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.insertLineAfter}`,
      parameters
    );
  }

  reSequenceLines(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.reSequenceLines}`,
      parameters
    );
  }

  getItemAvailability(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchItemAvailability}`,
      parameters
    );
  }

  validateOrderInJDE(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.validateOrderInJDE}`,
      parameters
    );
  }

  getAttachments(type: AttachmentEntityType, id: string) {
    const url = `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.attachmentUpload}${type}/${id}`;
    return this.http.get<any[]>(url);
  }

  downloadFile(id: string, file: string) {
    const url = `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.attachmentUpload}${id}`;
    return this.saveFileAs(url, file);
  }

  deleteAttachment(id: string, statusFlag: string, userEmail: string) {
    const url = `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.attachmentUpload}${id}/${statusFlag}/${userEmail}`;
    return this.http.put(url, {});
  }

  saveFileAs(uri: string, fileName: string) {
    return this.http
      .get(uri, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map((res) => {
          saveAs((res as any).body, fileName);
          return true;
        })
      );
  }

  uploadAttachment(req: AttachmentDto) {
    const url = `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.attachmentUpload}${req.entityType}/${req.entityId}/${req.type}`;
    if (req.type === 'file') {
      const formData = new FormData();
      formData.append('file', req.file, req.file.name);

      const headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');

      return this.http.post(url, formData, { headers });
    } else {
      return this.http.post(url, {
        data: req.noteOrLink,
      });
    }
  }

  updateAttachment(parameters) {
    const url = `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.attachmentUpload}${parameters.id}`;

    return this.http.put(url, {
      data: parameters.data,
      display: parameters.display,
    });
  }

  toggleShowroomView(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.toggleShowroomView}`,
      parameters
    );
  }

  updateSelectedVehicle(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.updateSelectedVehicle}`,
      parameters
    );
  }

  distributeOrder(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.distributeOrder}`,
      parameters
    );
  }

  updateAccountCustomerSettings(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.updateAccountCustomerSettings}`,
      parameters
    );
  }

  updateCustomerEmail(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.updateCustomerEmail}`,
      parameters
    );
  }

  fetchOrderFromJDE(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchOrderFromJDE}`,
      parameters
    );
  }

  checkLineStockAllocation(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.checkLineStockAllocation}`,
      parameters
    );
  }

  checkStockAllocation(parameters: {orderNumber: string}) {
    return this.http.post<StockAllocationResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.checkStockAllocation}`,
      parameters
    );
  }

  adjustStockAllocation(parameters: {orderNumber: string, allocationArray: AllocationArray[]}) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.adjustStockAllocation}`,
      parameters
    );
  }

  fetchCashCustomer(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchCashCustomer}`,
      parameters
    );
  }

  fetchPayments(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchPayments}`,
      parameters
    );
  }

  fetchInvoicePayments(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchInvoicePayments}`,
      parameters
    );
  }

  getPaymentTypes(parameters?) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.getPaymentTypes}`,
      parameters ? parameters : { brand: this.config.AppConfig.brand }
    );
  }

  fetchCardBalance(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.givexGetBalance}`,
      parameters
    );
  }

  givexActivation(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.givexActivation}`,
      parameters
    );
  }

  redeemCard(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.redeemCard}`,
      parameters
    );
  }

  savePayment(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.savePayment}`,
      parameters
    );
  }

  getRegisterList() {
    return this.getList('getRegisterList', null);
  }

  checkUserForCredits(parameters) {
    let headers = new HttpHeaders();
    headers = headers
      .set('user', parameters.username)
      .set('password', btoa(parameters.password));
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.checkUserForCredits}`,
      { brand: this.config.AppConfig.brand },
      { headers }
    );
  }

  getCreditReasons() {
    return this.getList('getCreditReasonList',  null);
  }
  getSuppliers() {
    return this.getList('getSupplierList');
  }

  getCrossDock(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchCrossDock}`,
      parameters
    );
  }

  getDetailedInventory(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchDetailedInventory}`,
      parameters
    );
  }

  getInboundDetails(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchInboundDetails}`,
      parameters
    );
  }

  getCashRoundedAmount(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchCashRoundedAmount}`,
      parameters
    );
  }

  getRegisterForHost(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchRegisterForHost}`,
      parameters
    );
  }

  setRegisterForHost(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.setRegisterForHost}`,
      parameters
    );
  }

  manageRegister(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.openCloseRegiser}`,
      parameters
    );
  }

  getFeedbackTypes() {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.getFeedbackTypes}`,
      { brand: this.config.AppConfig.brand }
    );
  }

  searchSuppliers(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.searchSuppliers}`,
      parameters
    );
  }

  getCustomerSales(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.getCustomerSales}`,
      parameters
    );
  }

  getStatementList() {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.getStatementList}`,
      {}
    );
  }

  getInvoiceList() {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.getInvoiceList}`,
      {}
    );
  }

  createCreditOrder(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.createCreditOrder}`,
      parameters
    );
  }

  emailStatementExternal(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.emailStatementExternal}`,
      parameters
    );
  }

  emailInvoiceReprintExternal(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.emailInvoiceReprintExternal}`,
      parameters
    );
  }

  copyOrder() {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.copyOrder}`,
      {}
    );
  }

  fetchRecentOrders(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchRecentOrders}`,
      parameters
    );
  }

  getList(listEndPoint: string, input = { brand: this.config.AppConfig.brand }) {

    return from(localforage.getItem(listEndPoint)).pipe(
      switchMap((value) => {
        if (value) {
          return of({ SearchResults: value });
        }
        return this.http.post<ApiResponse>(
          `${this.config.AppConfig.backendUrl}${API_ENDPOINTS[listEndPoint]}`,
          input
        );
      }),
      map((res) => {
        // if res is an ApiResponse, save to localforage
        if (res['ErrorFlag']) {
          localforage.setItem(listEndPoint, res.SearchResults);
        }
        return res;
      })
    );
  }

  getResources(customerCategory: string, securityFunctions: string[]) {
    let headers = new HttpHeaders();

    headers = headers
      .set('brand', this.config.AppConfig.brand)
      .set('customercategory', customerCategory)
      .set('securityFunctions', securityFunctions);

    return this.http.get<any>(
      `${this.config.AppConfig.dmsBackendUrl}${API_ENDPOINTS.getResources}`,
      { headers }
    );
  }

  getDownloadUrl(
    itemId: string,
    isLink: boolean = false,
    getAdditionalMetadata = false
  ): Observable<{ fileUrl: string; contentType?: string }> {
    let headers = new HttpHeaders();
    headers = headers
      .set('brand', this.config.AppConfig.brand)
      .set('id', itemId);
    if (isLink) {
      headers = headers.set('islink', 'true');
    }
    return this.http
      .get<{ fileUrl: string }>(
        `${this.config.AppConfig.dmsBackendUrl}${API_ENDPOINTS.getDownloadUrl}`,
        { headers }
      )
      .pipe(
        mergeMap((res) => {
          if (!getAdditionalMetadata) {
            return of(res);
          }

          return this.http.head(res.fileUrl, { observe: 'response' }).pipe(
            map((value) => {
              return {
                ...res,
                contentType: value.headers.get('content-type'),
              };
            })
          );
        })
      );
  }

  getCatalogueStatus() {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchCatalogueStatus}`,
      {}
    );
  }

  checkSerialNumberOnHand(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.checkSerialNumberOnHand}`,
      parameters
    );
  }

  checkPreviousCredit(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.checkPreviousCredit}`,
      parameters
    );
  }

  submitWarranty(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.submitWarranty}`,
      parameters
    );
  }

  getWarrantyFaultList(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.getWarrantyFaultList}`,
      parameters
    );
  }

  getShowList() {
    return this.getList('getShowList');
  }

  getExternalUserList(parameters = {}) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.getExternalUserList}`,
      parameters
    );
  }

  createShowCode(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.createShowCode}`,
      parameters
    );
  }

  createShowUser(parameters) {
    let headers = new HttpHeaders();
    headers = headers.set('password', parameters.password);
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.createShowUser}`,
      {
        userName: parameters.userName,
        userEmail: parameters.userEmail,
        brand: this.config.AppConfig.brand,
        showCode: parameters.showCode,
      },
      { headers }
    );
  }

  loginToShow(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.loginToShow}`,
      parameters
    );
  }

  getShowUsers(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.getShowUsers}`,
      parameters
    );
  }

  deleteShowCode(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.deleteShowCode}`,
      parameters
    );
  }

  getNonCorpStoresForShow() {
    return this.getList('fetchNonCorpStoresForShow');
  }

  checkForShowSales() {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.checkForShowSales}`,
      {}
    );
  }

  getCustomerShowList() {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchCustomerShowList}`,
      {}
    );
  }

  getShowSales(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchShowSalesForExternal}`,
      parameters
    );
  }

  getShowPrinterList() {
    return this.getList('fetchShowPrinters');
  }

  getUsersForRecentOrders() {
    return this.getList('fetchUsersForRecentOrders');
  }

  savePendingPayments(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.savePendingPayments}`,
      parameters
    );
  }
  updatePendingPayment(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.updatePendingPayment}`,
      parameters
    );
  }
  removePendingPayment(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.removePendingPayment}`,
      parameters
    );
  }


  fetchPaymentInfo(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchPaymentInfo}`,
      parameters
    );
  }

  orderLevelReprice(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.orderLevelReprice}`,
      parameters
    );
  }

  resetOrderPrice(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.resetOrderPrice}`,
      parameters
    );
  }

  fetchBranchInvoiceDetails() {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchBranchInvoiceDetails}`,
      {}
    );
  }
  fetchNextNumber(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchNextNumber}`,
      parameters
    );
  }

  finaliseOrderToJDE(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.finaliseOrderToJDE}`,
      parameters
    );
  }

  fetchBrandDefaults() {
    return this.http.post<ApiResponse & {SearchResults: BrandDefaults[]}>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchBrandDefaults}`,
      { brand: this.config.AppConfig.brand }
    );
  }

  listTasksNotes(parameters: ListTasksNotes) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.listTasksNotes}`,
      parameters
    );
  }

  updateNotes(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.updateNotes}`,
      parameters
    );
  }

  updateTasks(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.updateTasks}`,
      parameters
    );
  }

  updateAlert(parameters: AlertMessageToUpdate) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.updateAlert}`,
      parameters
    );
  }

  getCustomerAlert(parameters: number) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.getCustomerAlert}`,
      parameters
    );
  }

  listTemplates() {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.listTemplates}`,
      {}
    );
  }

  listOutcomes() {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.listOutcomes}`,
      {}
    );
  }

  completeTask(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.completeTask}`,
      parameters
    );
  }

  fetchCRMUsers(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchCRMUsers}`,
      parameters
    );
  }

  fetchGiftCardLoad(parameters) {
    return this.http.post<ApiResponse & FetchGiftCardLoadResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchGiftCardLoad}`,
      parameters
    );
  }

  addGiftCardLoad(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.addGiftCardLoad}`,
      parameters
    );
  }

  getOverdueTaskAlert() {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.overdueTaskAlert}`,
      {}
    );
  }

  assignEFTPOSDeviceToHost(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.assignEftposDeviceToHost}`,
      parameters
    );
  }

  registerEftpos(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.registerEftpos}`,
      parameters
    );
  }

  checkEftposStatus(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.checkEftposStatus}`,
      { ...parameters, brand: this.config.AppConfig.brand }
    );
  }

  makeEftposTransaction(parameters) {
    return this.http.post<any>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.makeEftposTransaction}`,
      { ...parameters, brand: this.config.AppConfig.brand }
    );
  }

  fetchVehicleDescription(parameters) {
    return this.http.post<any>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchVehicleDescription}`,
      { ...parameters, brand: this.config.AppConfig.brand }
    );
  }

  fetchShipTos(input) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchShipTos}`,
      input
    );
  }

  listHoldCodes() {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.listHoldCode}`,
      {}
    );
  }

  releaseHoldCode(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.releaseHoldCode}`,
      parameters
    );
  }

  fetchSpotLightInfo() {
    let body: any = null;
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchSpotLightInfo}`,
      body
    );
  }
  fetchContentStructure(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchContentStructure}`,
      parameters
    );
  }

  downloadContentFile(
    parameters,
    fileLabel: string // entityID: string, // sequenceID: number, // fileSeq: number, // brand: string
  ) {
    const url = `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.downloadFile}`;
    return this.http
      .post(url, parameters, {
        responseType: 'blob',
      })
      .pipe(
        map((res) => {
          if (
            res.type == 'application/pdf' ||
            res.type == 'image/jpeg' ||
            res.type == 'image/jpg' ||
            res.type == 'image/png' ||
            res.type == 'image/gif'
          ) {
            var file = new Blob([res], { type: res.type });
            var fileURL = URL.createObjectURL(file);

            // var fileLink = document.createElement('a');
            // fileLink.href = fileURL;

            // // it forces the name of the downloaded file
            // fileLink.download = fileLabel;

            // // triggers the click event
            // fileLink.click();

            window.open(fileURL);
          } else {
            let url = window.URL.createObjectURL(res);
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = fileLabel;
            a.click();
            // window.open(url);
            window.URL.revokeObjectURL(url);
            a.remove();
          }
          return true;
        })
      );
    // return this.saveeFileAs(url, parameters.brand);
  }

  fetchParkedOrderList() {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchParkedOrderList}`,
      {}
    );
  }

  loadParkedOrder(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.loadParkedOrder}`,
      parameters
    );
  }

  removeParkedOrder(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.removeParkedOrder}`,
      parameters
    );
  }

  removeAllParkedOrders() {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.removeAllParkedOrders}`,
      {}
    );
  }

  fetchWeekList() {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchWeekList}`,
      {}
    );
  }

  fetchResourceList(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchResourceList}`,
      parameters
    );
  }

  fetchOrdersForBooking(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchOrdersForBooking}`,
      parameters
    );
  }

  renameGroupName(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.renameGroupName}`,
      parameters
    );
  }

  splitOrder(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.splitOrder}`,
      parameters
    );
  }

  consolidateOrder(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.consolidateOrder}`,
      parameters
    );
  }

  changeOrderBooking(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.changeOrderBooking}`,
      parameters
    );
  }

  reassignLine(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.reassignLine}`,
      parameters
    );
  }

  resourceSearch(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.resourceFuzzySearch}`,
      parameters
    );
  }

  fetchResourceById(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchResourceById}`,
      parameters
    );
  }

  reprintReceipt(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.reprintReceipt}`,
      parameters
    );
  }

  customerTrailerSearch(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.trailerSearch}`,
      parameters
    );
  }

  fetchCustomerTrailerDetails(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchCustomerTrailerDetails}`,
      parameters
    );
  }

  fetchRegoStatus(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchRegoStatus}`,
      parameters
    );
  }

  fetchStoreList() {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchStoreList}`,
      {}
    );
  }

  updateTrailerInfo(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.updateTrailerInfo}`,
      parameters
    );
  }

  updateServiceHistory(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.updateServiceHistory}`,
      parameters
    );
  }

  camperTrailerGenerateReport(assetNumber) {
    const url = `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.camperTrailerGenerateReport}`;
    return this.http.post(url, assetNumber, {
      headers: new HttpHeaders({
        Accept: 'application/octet-stream',
      }),
      observe: 'response',
      responseType: 'blob',
    });
  }

  fetchARBuCohorts(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.fetchARBuCohorts}`,
      parameters
    );
  }

  customerSpecificSearch(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.customerSpecificSearch}`,
      parameters
    );
  }

  checkBuyinItem(parameters) {
    return this.http.post<ApiResponse>(
      `${this.config.AppConfig.backendUrl}${API_ENDPOINTS.checkBuyinItem}`,
      parameters
    );
  }
}
