<div class="row footer">
  <div class="col-sm-9 col-md-8 text-left pl-4">
    <span>
      <a href="https://www.arb.com.au/about/" target="_blank">About us</a>
    </span>
    <span class="px-2">
      <a href="https://www.arb.com.au/contact/get-in-touch/" target="_blank">
        Contact Us
      </a>
    </span>
  </div>
  <div class="col-sm-3 col-md-4 text-right pr-4 my-auto">
    Version: {{ version }} - {{ brand }}
  </div>
</div>
