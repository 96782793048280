import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-action-cell',
  template: `
    <span>
      <mat-icon
        (click)="invokeLoadParkedOrderMethod()"
        title="Load order into staging"
        >refresh</mat-icon
      >
    </span>
    <span *ngIf="IsOrderChanged()">
      <mat-icon
        (click)="invokeFinalizeOrderToJDEMethod()"
        title="Finalize order to JDE "
        >save</mat-icon
      >
      <span class="pr-1 pl-1"></span>
    </span>
    <span>
      <mat-icon
        (click)="invokeRemoveParkedOrderMethod()"
        title="Remove the order from Parked Orders List"
        >delete</mat-icon
      >
      <span class="pr-1"></span>
    </span>
  `,
  styles: [],
})
export class OrderParkedGridActionRenderComponent {
  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  public invokeLoadParkedOrderMethod() {
    this.params.context.componentParent.loadParkedOrder({
      orderNumber: this.params.data.orderNumber,
      orderType: this.params.data.orderType,
    });
  }

  public invokeRemoveParkedOrderMethod() {
    this.params.context.componentParent.removeParkedOrder(
      {
        orderNumber: this.params.data.orderNumber,
        orderType: this.params.data.orderType,
      },
      this.IsOrderChanged()
    );
  }

  public invokeFinalizeOrderToJDEMethod() {
    this.params.context.componentParent.finaliseParkedOrderToJDE({
      orderNumber: this.params.data.orderNumber,
      orderType: this.params.data.orderType,
      email: this.params.data.userEmail,
    });
  }

  IsOrderChanged() {
    return this.params.data.orderChangedYN === 'Y' ? true : false;
  }
}
