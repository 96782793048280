import { formatDate } from '@angular/common';
import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  Branch,
  CreditReason,
  Customer,
  CustomerDetails,
  CustomerVehicle,
  CUSTOMER_TYPE,
  NonCorpStore,
  OrderType,
  ORDER_TYPE,
  BrandDefaults,
  ORDER_TYPES,
  Vehicle,
} from '@pos-app/data';
import { HoldCode, OrderHeader, OrderMessage } from '../../+state/orders.models';
import { ApiService, DialogService } from '@pos-app/core-ui';
import { ToastrService } from 'ngx-toastr';
import { Subject, from } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, map, take, takeUntil } from 'rxjs/operators';
import moment from 'moment';
import { Store } from '@ngrx/store';
import { UpdateRetailCustomer } from '../../../customers/+state/customers.actions';
import { customersQuery } from '../../../customers/+state/customers.selectors';
import { getLocalLocaleDate } from 'libs/core-ui/src/lib/utils/utils-date';
import { FetchJdeOrder } from '../../+state/orders.actions';
import { OOESessionService } from 'libs/core-ui/src/lib/services/ooe-session.service';
import { SearchString } from '../../../epc/+store/vehicles';
import { IMainState } from '../../../epc/+store';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-order-header',
  templateUrl: './order-header.component.html',
  styleUrls: ['./order-header.component.scss'],
})
export class OrderHeaderComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('content') modalBox: any;
  @ViewChild('releaseOrderHoldModal')
  releaseOrderHoldModal: any;
  @Input() orderHeader: OrderHeader;
  @Input() branchList: Branch[];
  @Input() nonCorpStoreList: NonCorpStore[];
  @Input() orderTypeList: OrderType[];
  @Input() nonCorpOrderTypeList: OrderType[];
  @Input() creditReasonList: CreditReason[];
  @Input() headerMessages: OrderMessage[];
  @Input() isExternalUser: boolean;
  @Input() isVehicleDataReady: boolean;
  @Input() isCreditOrder: boolean;
  @Input() selectedReasonCode: string;
  @Input() isShowUser: boolean;
  @Input() customerType: string;
  @Input() hasCashDrawer: boolean;
  @Input() selectedCustomer: CustomerDetails;
  @Input() brandDefaults: BrandDefaults;
  @Input() allAttachments: any[];

  @Output() headersToUpdate = new EventEmitter<any>();
  @Output() useVehicleColourForAll = new EventEmitter<string>();
  @Output() selectReason = new EventEmitter<string>();
  @Output() isHeaderValid = new EventEmitter<boolean>();
  @Output() isOrderOnHold = new EventEmitter<boolean>();
  @Output() loadAllAttachments = new EventEmitter<void>();

  form: FormGroup;
  isGarage = false;
  unSubscribe$ = new Subject<void>();
  isAddingCustomer: boolean;
  isEditingCustomer: boolean;
  isEditingShiptoCustomer: boolean;
  isSelectingCustomer: boolean;
  isAlertMessage: boolean = false;
  allHoldCodeList: HoldCode[];
  isReadOnlyOrderNote: boolean;
  isBusy: boolean;
  holdCodePassword: string;
  selectedHoldCodeToRemove: HoldCode;
  isLoading: boolean = true;
  isOrderReferenceRequired: boolean = false;
  marketingYN: any;
  marketingYNValue: any;
  orderCreateDateString: string;
  shiptoCustomer: CustomerDetails;
  isAddVehicleForCustomer = false;
  tempVehicle: {
    customerVehicleID: number;
    partsVehicleID: number;
    vehicleDescription: string;
    jdeVehicleID: number;
  };

  get orderType(): string {
    return this.form.controls.orderType.value;
  }

  get orderTypes(): OrderType[] {
    const ordertypes = this.isNonCorpOrder() ? this.nonCorpOrderTypeList : this.orderTypeList;
    if (ordertypes?.length) {
      return ordertypes;
    }
    return [
      {
        OrderTypeCode: this.orderType,
        OrderTypeDescription: ORDER_TYPES.find((item) => item.OrderTypeCode === this.orderType)?.OrderTypeDescription || this.orderType,
      } as OrderType,
    ];
  }

  get selectedHoldCodeList(): HoldCode[] {
    return (this.orderHeader?.holdCodes || []).filter((item: HoldCode) => {
      return (this.allHoldCodeList || []).some((x) => x.holdCode === item.code);
    });
  }

  public get isTempVehicle(): boolean {
    return this.ooeSessionService.isTempVehicle;
  }

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private formatter: NgbDateParserFormatter,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private store: Store<IMainState>,
    private dialogService: DialogService,
    private ooeSessionService: OOESessionService,
    @Inject(LOCALE_ID) public locale: string
  ) {
    this.form = this.fb.group({
      customerName: [{ value: '', disabled: true }, [Validators.email, Validators.required]],
      customerNumber: [''],
      branch: ['', Validators.required],
      orderType: ['', [Validators.required]],
      vehicle: [{ value: '', disabled: true }, Validators.required],
      reference: [''],
      orderNumber: [{ value: '', disabled: true }, Validators.required],
      requestDate: ['', Validators.required],
      customerVehicleID: [''],
      partsVehicleID: [''],
      currencyCode: [{ value: '', disabled: true }],
      vehicleColour: [{ value: '', disabled: true }],
      quickSaleYN: [''],
      customerPaymentTerms: [''],
      customerTaxRate: [''],
      shipToNumber: ['', Validators.required],
      shipToName: [''],
      creditReason: [null],
      nonCorpStoreSaleYN: ['N'],
      holdCodes: [null],
      marketingOptInYNFromOrder: [''],
    });
  }

  ngOnInit() {
    this.apiService
      .listHoldCodes()
      .pipe(
        takeUntil(this.unSubscribe$),
        finalize(() => (this.isLoading = false))
      )
      .subscribe((holdCodes) => {
        this.allHoldCodeList = this.isExternalUser ? holdCodes.SearchResults.filter((x) => x.readOnlyYN === 'N') : holdCodes.SearchResults;
      });

    Object.keys(this.form.controls).forEach((key) => {
      if (key === 'branch' || key === 'orderType') {
        this.form
          .get(key)
          .valueChanges.pipe(takeUntil(this.unSubscribe$))
          .subscribe((val) => {
            if (val) {
              if ((val === ORDER_TYPE.creditPrice || val === ORDER_TYPE.creditReturn) && !this.hasCashDrawer) {
                this.form.controls.orderType.setErrors({
                  invalid: true,
                });
                this.toastr.error('Cannot process a credit without a register connected');
              } else {
                this.form.controls.orderType.setErrors(null);
                this.updateHeaders();
              }
            }
          });
      }
    });

    this.form
      .get('reference')
      .valueChanges.pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.unSubscribe$))
      .subscribe((val) => {
        this.addRemoveValidationFromReference(this.selectedCustomer);

        if (this.isValidatorExist('reference')) {
          this.createEmptyFieldErrorMessage(val, 'reference');
        } else {
          this.form.controls.reference.setErrors(null);
          this.isOrderReferenceRequired = false;
        }
        this.updateHeaders();
        this.isHeaderValid.emit(this.form.valid);
      });

    this.form
      .get('creditReason')
      .valueChanges.pipe(takeUntil(this.unSubscribe$))
      .subscribe((value) => {
        this.selectReason.emit(value);
      });

    this.form
      .get('requestDate')
      .valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        map((value) => {
          const requestedDate = this.formatter.parse(value);

          if (isNaN(requestedDate.day) || isNaN(requestedDate.month) || isNaN(requestedDate.year)) {
            this.form.get('requestDate').setErrors({ invalid: true }, { emitEvent: false });
            return false;
          }
          const momentDate = moment(
            `${requestedDate.day}/${requestedDate.month}/${requestedDate.year < 100 ? 2000 + requestedDate.year : requestedDate.year}`,
            'D/M/YYYY',
            true
          );
          const today = moment().startOf('day');
          if (!momentDate.isValid() || momentDate.isBefore(today)) {
            this.toastr.error('Invalid request date.');
            this.form.get('requestDate').setErrors({ invalid: true }, { emitEvent: false });
            return false;
          }
          this.form.get('requestDate').setErrors({ invalid: false }, { emitEvent: false });
          return true;
        }),
        takeUntil(this.unSubscribe$)
      )
      .subscribe((res) => {
        this.isHeaderValid.emit(this.form.valid);
        if (res) {
          this.updateHeaders();
        }
      });

    this.form
      .get('shipToNumber')
      .valueChanges.pipe(takeUntil(this.unSubscribe$))
      .subscribe((shipToNumber) => {
        this.form.patchValue({
          shipToName: this.nonCorpStoreList.find((store) => store.customerNumber === shipToNumber)?.customerName || '',
        });
        this.updateHeaders();
      });

    this.form
      .get('holdCodes')
      .valueChanges.pipe(takeUntil(this.unSubscribe$))
      .subscribe((holdCodeValue) => {
        if (holdCodeValue && !this.selectedHoldCodeList.some((item) => item.holdCode === holdCodeValue.code)) {
          this.updateHeaders(holdCodeValue);
          this.form.controls.holdCodes.setValue(null, { emitEvent: false });
        }
      });

    this.form
      .get('marketingOptInYNFromOrder')
      .valueChanges.pipe(takeUntil(this.unSubscribe$))
      .subscribe((marketingOptInYN) => {
        this.marketingYNValue = marketingOptInYN;
        this.checkMarketingOpt(marketingOptInYN);
      });

    this.store
      .select(customersQuery.getAlertMessage)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((alertMessage) => {
        if (alertMessage && alertMessage.alertUpdatedDate !== ' ' && alertMessage.alertUpdatedTime !== 0) {
          this.isAlertMessage = true;
        }
      });
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    //Check whether selected customer required reference field or not
    //If required add the validation
    if (
      changes.selectedCustomer &&
      this.selectedCustomer &&
      this.form &&
      !changes.selectedCustomer.firstChange &&
      this.selectedCustomer.CustomerNumber == this.form.value['customerNumber']
    ) {
      this.addRemoveValidationFromReference(this.selectedCustomer, false);

      if (this.isValidatorExist('reference')) {
        this.createEmptyFieldErrorMessage(this.form.value['reference'], 'reference');
      } else {
        this.form.controls.reference.setErrors(null);
        this.isOrderReferenceRequired = false;
      }
    }

    if (changes.customerType && changes.customerType.currentValue && this.form) {
      this.customerType === CUSTOMER_TYPE.retail
        ? this.form.controls.reference.clearValidators()
        : this.form.controls.reference.addValidators([Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/), Validators.minLength(1)]);
      this.form.controls.reference.updateValueAndValidity({ emitEvent: false });
      this.isHeaderValid.emit(this.form.valid);
    }

    if (changes.orderHeader && changes.orderHeader.currentValue) {
      this.orderCreateDateString = getLocalLocaleDate(this.orderHeader.orderCreateDateString, this.locale);

      if (this.form) {
        const orderTypeGroup = (this.orderTypeList || this.nonCorpOrderTypeList).find(
          (item) => item.OrderTypeCode === this.orderHeader?.orderType
        )?.OrderTypeGroup;
        if (orderTypeGroup) {
          this.orderTypeList = this.orderTypeList.filter((item) => item.OrderTypeGroup === orderTypeGroup);
          this.nonCorpOrderTypeList = this.nonCorpOrderTypeList.filter((item) => item.OrderTypeGroup === orderTypeGroup);
        }

        this.form.patchValue(
          {
            customerName: this.orderHeader.customerName,
            customerNumber: this.orderHeader.customerNumber,
            reference: this.orderHeader.reference.trim(),
            orderNumber: this.orderHeader.orderNumber,
            vehicle: this.orderHeader.vehicleDescription,
            orderType: this.orderHeader.orderType.trim() ? this.orderHeader.orderType.trim() : null,
            branch: this.orderHeader.branch,
            requestDate: getLocalLocaleDate(this.orderHeader.requestDateString, this.locale),
            customerVehicleID: this.orderHeader.customerVehicleID,
            partsVehicleID: this.orderHeader.partsVehicleID,
            currencyCode: this.orderHeader.currencyCode,
            quickSaleYN: this.orderHeader.quickSaleYN,
            customerPaymentTerms: this.orderHeader.customerPaymentTerms,
            customerTaxRate: this.orderHeader.customerTaxRate,
            shipToNumber: this.orderHeader.shipToNumber || null,
            shipToName: this.orderHeader.shipToName || null,
            nonCorpStoreSaleYN: this.orderHeader.nonCorpStoreSaleYN,
            vehicleColour: this.orderHeader.vehicleColour,
          },
          { emitEvent: false }
        );

        if (this.orderHeader.readOnlyYN === 'N' && this.orderHeader.requestDateString) {
          const requestedDate = this.formatter.parse(this.orderHeader.requestDateString);
          const momentDate = moment(
            `${requestedDate.day}/${requestedDate.month}/${requestedDate.year < 100 ? 2000 + requestedDate.year : requestedDate.year}`,
            'D/M/YYYY',
            true
          );
          const today = moment().startOf('day');
          if (!momentDate.isValid() || momentDate.isBefore(today)) {
            this.form.controls['requestDate'].patchValue(getLocalLocaleDate(today.format('DD/MM/YYYY'), this.locale), { emitEvent: false });
          }
        }

        if (changes.orderHeader.previousValue && changes.orderHeader.currentValue.quickSaleYN !== changes.orderHeader.previousValue.quickSaleYN) {
          this.orderHeader.quickSaleYN === 'Y'
            ? this.form.controls.branch.disable({ emitEvent: false })
            : this.form.controls.branch.enable({ emitEvent: false });
          this.orderHeader.quickSaleYN === 'Y'
            ? this.form.controls.orderType.disable({ emitEvent: false })
            : this.form.controls.orderType.enable({ emitEvent: false });
          this.orderHeader.quickSaleYN === 'Y'
            ? this.form.controls.shipToNumber.disable({ emitEvent: false })
            : this.form.controls.shipToNumber.enable({ emitEvent: false });
        } else if (
          (!changes.orderHeader.previousValue && (this.orderHeader.quickSaleYN === 'Y' || !this.orderHeader.orderNumber.startsWith('T'))) ||
          this.isExternalUser
        ) {
          this.form.controls.branch.disable({ emitEvent: false });
          this.form.controls.orderType.disable({ emitEvent: false });
          this.form.controls.shipToNumber.disable({ emitEvent: false });
        }

        this.isHeaderValid.emit(this.form.valid);
      }

      if (this.isTempVehicle) {
        this.tempVehicle = {
          customerVehicleID: this.orderHeader.customerVehicleID,
          partsVehicleID: this.orderHeader.partsVehicleID,
          vehicleDescription: this.orderHeader.vehicleDescription,
          jdeVehicleID: this.orderHeader.jdeVehicleID,
        };
      }
    }

    if (changes.selectedReasonCode && changes.selectedReasonCode.currentValue && this.form) {
      this.form.patchValue(
        {
          creditReason: this.selectedReasonCode,
        },
        { emitEvent: false }
      );
    }

    if (this.isExternalUser) {
      this.form.controls.branch.disable({ emitEvent: false });
    }

    if ((changes.nonCorpStoreList || changes.orderHeader) && this.isNonCorpOrder()) {
      if (this.nonCorpStoreList && this.orderHeader) {
        this.updateNonCorpStoreList();
      }
    }

    this.isOrderOnHold.emit(this.selectedHoldCodeList?.length > 0);
    this.marketingYN = this.selectedCustomer?.MarketingYN;
  }

  removeHoldCode(code: HoldCode) {
    this.selectedHoldCodeToRemove = code;
    if (code.savedYN === 'Y') {
      this.modalService.open(this.releaseOrderHoldModal, {
        backdrop: 'static',
      });
    } else {
      this.apiService
        .releaseHoldCode({
          holdCode: code.holdCode,
          password: '',
        })
        .pipe(takeUntil(this.unSubscribe$))
        .subscribe((res) => {
          this.updateHeaders(code);
        });
    }
  }

  onRemoveHoldCode() {
    this.isBusy = true;
    this.apiService
      .releaseHoldCode({
        holdCode: this.selectedHoldCodeToRemove.holdCode,
        password: this.holdCodePassword,
      })
      .pipe(finalize(() => (this.isBusy = false)))
      .subscribe(
        (res) => {
          this.updateHeaders(this.selectedHoldCodeToRemove);
          this.closeHoldCodeModal();
        },
        (error) => {
          this.toastr.error(error.ErrorMessage);
        }
      );
  }

  closeHoldCodeModal() {
    this.holdCodePassword = '';
    this.modalService.dismissAll();
  }

  updateHeaders(holdCodeToUpdate?, reloadCustomer = false, reloadUserState = false) {
    const requestedDate = this.formatter.parse(this.form.controls.requestDate.value);

    const newHeaders = {
      reference: this.form.controls.reference.value?.trim(),
      branch: this.form.controls.branch.value,
      orderType: this.form.controls.orderType.value,
      customerVehicleID: this.form.controls.customerVehicleID.value,
      partsVehicleID: this.form.controls.partsVehicleID.value,
      requestDateString: formatDate(
        new Date(requestedDate.year < 100 ? 2000 + requestedDate.year : requestedDate.year, requestedDate.month - 1, requestedDate.day),
        'dd/MM/yyyy',
        'en-au'
      ),
      currencyCode: this.orderHeader.currencyCode,
      vehicleDescription: this.form.controls.vehicle.value,
      customerNumber: this.form.controls.customerNumber.value,
      customerName: this.form.controls.customerName.value,
      quickSaleYN: this.form.controls.quickSaleYN.value,
      customerPaymentTerms: this.form.controls.customerPaymentTerms.value,
      customerTaxRate: this.form.controls.customerTaxRate.value,
      shipToNumber: this.form.controls.shipToNumber.value,
      shipToName: this.form.controls.shipToName.value?.trim(),
      nonCorpStoreSaleYN: this.form.controls.nonCorpStoreSaleYN.value,
      holdCode: holdCodeToUpdate ? holdCodeToUpdate.code : '',
      holdCodeDescription: holdCodeToUpdate ? holdCodeToUpdate.description : '',
    };

    for (const key in newHeaders) {
      if (Object.prototype.hasOwnProperty.call(newHeaders, key)) {
        const newValue = newHeaders[key];
        const oldValue = this.orderHeader[key];

        if (key !== 'holdCode' && key !== 'holdCodeDescription') {
          if (newValue === oldValue) {
            delete newHeaders[key];
          }
        } else if (!newValue) {
          delete newHeaders[key];
        }
      }
    }

    const data = { ...newHeaders, orderNumber: this.form.controls.orderNumber.value };

    this.headersToUpdate.emit({ data, reloadCustomer, reloadUserState });
  }

  openEditVehicleModal() {
    this.isGarage = true;
    this.isAddingCustomer = false;
    this.isEditingCustomer = false;
    this.isEditingShiptoCustomer = false;
    this.isSelectingCustomer = false;

    let searchLabel = '';
    if (this.form.controls['vehicle'].value?.length > 0) {
      const values: string[] = this.form.controls['vehicle'].value.split(' ');
      if (values?.length > 1) {
        searchLabel = values[0] + ' ' + values[1];
      }
    }

    this.store.dispatch(SearchString({ payload: searchLabel }));

    this.openModalBox();
  }

  openNotesModal() {
    this.isGarage = false;
    this.isAddingCustomer = false;
    this.isEditingCustomer = false;
    this.isEditingShiptoCustomer = false;
    this.isSelectingCustomer = false;
    this.isReadOnlyOrderNote = this.orderHeader.readOnlyYN === 'Y';
    this.openModalBox();
  }

  selectCustomerVehicle(selectedVehicle: CustomerVehicle) {
    this.form.patchValue(
      {
        customerVehicleID: selectedVehicle.customerVehicleID,
        partsVehicleID: selectedVehicle.partsVehicleID,
        vehicle: selectedVehicle.ShortDescription,
        vehicleColour: selectedVehicle.ColourCode,
      },
      { emitEvent: false }
    );
    this.modalService.dismissAll();
    this.isGarage = false;
    this.isAddVehicleForCustomer = false;
    this.updateHeaders(null, false, true);
  }

  onSelectVehicle(vehicle: Vehicle) {
    this.form.patchValue(
      {
        customerVehicleID: -1,
        partsVehicleID: vehicle.partsVehicleID,
        vehicle: `${vehicle.Make} ${vehicle.Model} ${vehicle.Series}`,
      },
      { emitEvent: false }
    );
    this.modalService.dismissAll();
    this.isGarage = false;
    this.updateHeaders();
  }

  clearVehicle() {
    this.form.patchValue(
      {
        customerVehicleID: '0',
        partsVehicleID: '0',
        vehicle: ' ',
        vehicleColour: '',
      },
      { emitEvent: false }
    );
    this.updateHeaders();
  }

  colourAll() {
    this.useVehicleColourForAll.emit();
  }

  addNewCustomer() {
    this.isAddingCustomer = true;
    this.isGarage = false;
    this.isEditingCustomer = false;
    this.isEditingShiptoCustomer = false;
    this.isSelectingCustomer = false;
    this.openModalBox();
  }

  editCustomer() {
    if (this.isExternalUser) {
      return;
    }

    this.isEditingCustomer = true;
    this.isGarage = false;
    this.isAddingCustomer = false;
    this.isEditingShiptoCustomer = false;
    this.isSelectingCustomer = false;
    this.openModalBox();
  }

  editShipToCustomer() {
    if (this.orderHeader.shipToNumber === this.orderHeader.customerNumber) {
      this.editCustomer();
      return;
    }

    this.apiService
      .getCustomerDetails({
        customerNumberSearch: this.orderHeader.shipToNumber,
      })
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((res) => {
        if (res?.SearchResults[0]) {
          this.shiptoCustomer = res.SearchResults[0];
          this.isEditingShiptoCustomer = true;
          this.isGarage = false;
          this.isAddingCustomer = false;
          this.isEditingCustomer = false;
          this.isSelectingCustomer = false;

          this.openModalBox();
        }
      });
  }

  editOriginalOrder() {
    if (this.orderHeader?.originalOrder) {
      const originalOrderArr = this.orderHeader.originalOrder.split(' ');

      const orderNumber = originalOrderArr[0];
      const orderType = originalOrderArr[1];

      this.dialogService
        .confirm(`Are you sure you want to park the current order and load the original order?`)
        .pipe(takeUntil(this.unSubscribe$))
        .subscribe((res) => {
          if (res) {
            this.store.dispatch(
              new FetchJdeOrder({
                orderNumber,
                orderType,
              })
            );
          }
        });
    }
  }

  checkMarketingOpt(marketingOptInYN: any) {
    if (
      this.orderHeader?.quickSaleYN === 'N' &&
      (this.selectedCustomer?.Email === '' || this.selectedCustomer?.PostCode === '') &&
      marketingOptInYN
    ) {
      this.editCustomer();
    } else if (this.orderHeader?.quickSaleYN === 'Y') {
      this.form.patchValue(this.getValuesToPatchMarketingOpt(marketingOptInYN), {
        emitEvent: false,
      });
      this.addNewCustomer();
    } else {
      this.store.dispatch(
        new UpdateRetailCustomer({
          customerNumber: this.selectedCustomer?.CustomerNumber,
          branch: this.selectedCustomer?.BranchCode,
          firstName: this.selectedCustomer?.FirstName,
          surname: this.selectedCustomer?.Surname,
          email: this.selectedCustomer?.Email,
          phone: this.selectedCustomer?.Phone,
          phoneType: this.selectedCustomer?.PhoneType,
          addressLine1: this.selectedCustomer?.AddressLine1,
          addressLine2: this.selectedCustomer?.AddressLine2,
          suburb: this.selectedCustomer?.Suburb,
          state: this.selectedCustomer?.StateCode,
          postCode: this.selectedCustomer?.PostCode,
          country: this.selectedCustomer?.CountryCode,
          secContactName: this.selectedCustomer?.SecondaryContact,
          secContactPhone: this.selectedCustomer?.SecondaryContactPhone,
          marketingOptInYN: marketingOptInYN ? 'Y' : !marketingOptInYN ? 'N' : '',
          useBusinessNameYN: this.selectedCustomer?.UseBusinessNameYN,
        })
      );

      this.apiService
        .getCustomerDetails({
          customerNumberSearch: this.selectedCustomer?.CustomerNumber,
        })
        .pipe(takeUntil(this.unSubscribe$))
        .subscribe((res) => {
          this.selectedCustomer = res.SearchResults[0];
          this.form.patchValue(this.getValuesToPatchMarketingOptIn(res.SearchResults[0]), {
            emitEvent: false,
          });
          this.updateHeaders(null, true);
        });
    }
  }

  fetchCustomerDetails(customerNumber) {
    this.modalService.dismissAll();

    if (customerNumber) {
      this.apiService
        .fetchCustomerDetails({
          customerNumber,
        })
        .pipe(takeUntil(this.unSubscribe$))
        .subscribe((res) => {
          const customer = res.SearchResults[0];

          if (this.isEditingCustomer || this.isAddingCustomer) {
            this.form.patchValue(this.getValuesToPatch(customer), {
              emitEvent: false,
            });
          }

          if (this.isEditingShiptoCustomer) {
            this.form.patchValue(
              {
                shipToNumber: customer.CustomerNumber,
                shipToName: customer.CustomerName,
              },
              {
                emitEvent: false,
              }
            );
          }

          this.isEditingCustomer = false;
          this.isAddingCustomer = false;
          this.isEditingShiptoCustomer = false;

          this.updateHeaders(null, true);
        });
    }
  }

  selectNewCustomer() {
    this.isSelectingCustomer = true;
    this.isGarage = false;
    this.isAddingCustomer = false;
    this.isEditingCustomer = false;
    this.isEditingShiptoCustomer = false;

    this.openModalBox();
  }

  handleSelectCustomer(customer: Customer) {
    if (customer) {
      this.isSelectingCustomer = false;
      this.modalService.dismissAll();

      if (customer.CustomerType === CUSTOMER_TYPE.shipTo) {
        this.apiService
          .fetchCustomerBillTo({
            customerShipTo: customer.CustomerNumber,
          })
          .pipe(takeUntil(this.unSubscribe$))
          .subscribe((result) => {
            let valuesToPatch = this.getValuesToPatch(customer);
            if (result) {
              if (result.SearchResults[0].billToNumber)
                valuesToPatch = {
                  ...valuesToPatch,
                  quickSaleYN: 'N',
                  customerNumber: result.SearchResults[0].billToNumber,
                  customerName: result.SearchResults[0].billToName,
                };
              this.form.patchValue(valuesToPatch, { emitEvent: false });
              this.updateHeaders();
            }
          });
      } else if (customer.CustomerType === CUSTOMER_TYPE.billTo) {
        let valuesToPatch = this.getValuesToPatch(customer);
        valuesToPatch = { ...valuesToPatch, quickSaleYN: 'N' };
        this.form.patchValue(valuesToPatch, { emitEvent: false });
        this.updateHeaders();
      } else {
        this.apiService
          .fetchCashCustomer({ branch: this.orderHeader.branch })
          .pipe(takeUntil(this.unSubscribe$))
          .subscribe((res) => {
            let valuesToPatch = this.getValuesToPatch(customer);

            // check if selected customer is a quicksale customer
            if (customer.CustomerNumber === res.customerNumber.toString()) {
              valuesToPatch = { ...valuesToPatch, quickSaleYN: 'Y' };
            }

            if (this.isTempVehicle) {
              valuesToPatch = {
                ...valuesToPatch,
                customerVehicleID: this.orderHeader.customerVehicleID,
                partsVehicleID: this.orderHeader.partsVehicleID,
                vehicle: this.orderHeader.vehicleDescription,
                vehicleColour: this.orderHeader.vehicleColour,
              };
            }

            this.form.patchValue(valuesToPatch, { emitEvent: false });
            this.updateHeaders();

            if (this.isTempVehicle) {
              this.isAddVehicleForCustomer = true;
              this.openEditVehicleModal();
            } else {
              this.isAddVehicleForCustomer = false;
            }
          });
      }
    }
  }

  getValuesToPatchMarketingOpt(value: Boolean) {
    return { marketingOptInYNFromOrder: value ? 'Y' : 'N' };
  }
  getValuesToPatchMarketingOptIn(customerDetail: CustomerDetails) {
    return {
      marketingOptInYNFromOrder: customerDetail?.MarketingYN ? '' : customerDetail?.MarketingYN === 'Y' ? 'Y' : 'N',
    };
  }

  getValuesToPatch(customer: Customer) {
    return {
      customerNumber: customer.CustomerNumber,
      customerName: customer.CustomerName,
      customerVehicleID: 0,
      partsVehicleID: 0,
      vehicle: ' ',
      vehicleColour: '',
      quickSaleYN: 'N',
      customerPaymentTerms: customer.customerPaymentTerms,
      customerTaxRate: customer.customerTaxRate,
      shipToNumber: customer.CustomerNumber,
      shipToName: customer.CustomerName,
    };
  }

  openModalBox() {
    this.modalService
      .open(this.modalBox, {
        windowClass: 'garage-modal',
        size: 'xl',
        backdrop: 'static',
      })
      .result.then(
        (_) => {
          this.isAddingCustomer = false;
          this.isSelectingCustomer = false;
          this.isGarage = false;
          this.isEditingCustomer = false;
          this.isEditingShiptoCustomer = false;
        },
        (_) => {
          this.isAddingCustomer = false;
          this.isSelectingCustomer = false;
          this.isGarage = false;
          this.isEditingCustomer = false;
          this.isEditingShiptoCustomer = false;
        }
      );
  }

  isNonCorpOrder() {
    return this.orderHeader?.nonCorpStoreSaleYN === 'Y';
  }

  switchShowOrder() {
    this.form.patchValue(
      {
        orderType: this.convertOrderType(this.isNonCorpOrder(), this.form.controls.orderType.value),
        nonCorpStoreSaleYN: this.isNonCorpOrder() ? 'N' : 'Y',
        shipToNumber: this.isNonCorpOrder() ? this.orderHeader.customerNumber : ' ',
        shipToName: this.isNonCorpOrder() ? this.orderHeader.customerName : ' ',
      },
      { emitEvent: false }
    );
    this.updateHeaders();
  }

  convertOrderType(isNonCorpOrder: boolean, orderType: string) {
    return isNonCorpOrder && orderType === ORDER_TYPE.showSaleOrder
      ? ORDER_TYPE.saleOrder
      : !isNonCorpOrder && orderType === ORDER_TYPE.saleOrder
      ? ORDER_TYPE.showSaleOrder
      : this.nonCorpOrderTypeList.find((item) => item.OrderTypeCode === orderType)
      ? orderType
      : ' ';
  }

  handleCloseModal(event) {
    if (event) {
      this.modalService.dismissAll();
    }
  }

  //Add validation to Reference field
  addRemoveValidationFromReference(customer: CustomerDetails, emitEvent = true) {
    if (customer.CustomerType === CUSTOMER_TYPE.retail || customer.referenceRequiredYN === 'N') {
      this.form.get('reference').clearValidators();
    } else if (customer.referenceRequiredYN === 'Y') {
      this.form.get('reference').addValidators([Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/), Validators.minLength(1)]);
    }
    this.form.get('reference').updateValueAndValidity({ emitEvent });
  }

  //Check Reference is required or not
  isReferenceRequired(referenceRequiredYN: string) {
    if (referenceRequiredYN == 'Y') {
      return true;
    }
    return false;
  }

  //TODO:remove reference as hardcoded
  //Generate Generic Empty field error message toastr
  createEmptyFieldErrorMessage(value: string, key: string) {
    if (value.trim() === '') {
      this.form.controls.reference.setErrors({
        invalid: true,
      });
      this.isOrderReferenceRequired = true;
      let errorKey = key.substring(0, 1).toUpperCase();
      errorKey = errorKey + key.substring(1);
      this.toastr.error(`${errorKey} field cannot be empty, please provide value.`);
    } else {
      this.isOrderReferenceRequired = false;
    }
  }

  //Check if validation exist or not
  isValidatorExist(field: string) {
    const formField = this.form.get(field);
    if (!formField.validator) {
      return false;
    }

    const validator = formField.validator({} as AbstractControl);
    return validator && validator.required;
  }

  private updateNonCorpStoreList(): void {
    if (!this.nonCorpStoreList.some((item) => +item.customerNumber === this.orderHeader?.shipToNumber)) {
      this.nonCorpStoreList.push({
        customerNumber: this.orderHeader?.shipToNumber,
        customerName: this.orderHeader?.shipToName,
      } as any);
      this.form.controls['shipToNumber'].setValue(this.orderHeader.shipToNumber, { emitEvent: false });
      this.form.controls['shipToName'].setValue(this.orderHeader.shipToName, { emitEvent: false });
    }
  }
}
