import { Component, ElementRef, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { OOESessionService } from '../../../../../../../../libs/core-ui/src/lib/services/ooe-session.service';
import { OOEApiServiceProxy } from '../../services/ooe-api-service-proxy';

@Component({
  selector: 'app-postcode',
  templateUrl: './postcode.component.html',
  styleUrls: ['./postcode.component.scss'],
})
export class PostcodeComponent {
  @ViewChild('postcodeInputModal', { static: true })
  public modal: ModalDirective;
  @ViewChild('postcodeInput', { static: true })
  public postcodeInput: ElementRef;

  public postcodes = [];
  public postcode = '';
  public active = false;
  public saving = false;

  constructor(
    private ooeSessionService: OOESessionService,
    private ooeApiServiceProxy: OOEApiServiceProxy
  ) {}

  public focusPostCodeInput(): void {
    setTimeout(() => {
      document.getElementById('postcodeInput').focus();
    }, 100);
  }

  public show(): void {
    this.postcode = this.ooeSessionService.getUserPostCode().trim();
    this.active = true;
    this.saving = false;
    this.modal.show();
  }

  public onShown(): void {
    this.focusPostCodeInput();
  }

  public save(): void {
    if (this.postcode && this.validatePostCode(this.postcode)) {
      this.saving = true;
      this.ooeSessionService.setUserPostCode(this.postcode.trim());
      this.close();
    }
  }

  public close(): void {
    this.active = false;
    this.modal.hide();
  }

  public validatePostCode(inputPostCode: any): boolean {
    if (!this.postcodes || this.postcodes.length == 0) {
      this.ooeApiServiceProxy
        .getPostcodes()
        .pipe()
        .subscribe((data) => {
          if (data.ErrorFlag == '0' && data.SearchResults) {
            this.postcodes = data.SearchResults;
          }

          return (
            this.postcodes.find((p) => p.postCode === inputPostCode) != null
          );
        });
    } else {
      return this.postcodes.find((p) => p.postCode === inputPostCode) != null;
    }
  }
}
