import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  ApiService,
} from '@pos-app/core-ui';
import { ROUTE_URL } from '@pos-app/data';
import { Subject, Observable, of } from 'rxjs';
import { first, takeUntil, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignUpComponent implements OnInit, OnDestroy {
  form: FormGroup;
  loading = false;
  error = '';

  unSubscribe$ = new Subject<void>();
  returnUrl: string;
  countryList$: Observable<any>;
  isSubmitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private apiService: ApiService,
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      name: ['', [Validators.required]],
      country: ['AU', [Validators.required]],
      postcode: ['', [Validators.pattern('^[0-9]{4}$')]]
    });
    this.countryList$ = this.apiService
      .getCountryList()
      .pipe(map(res => res.SearchResults));
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  submit() {
    this.isSubmitted = true;
    if (this.form.invalid) {
      return;
    }
    this.apiService
      .register({
        email: this.form.controls.email.value,
        name: this.form.controls.name.value,
        countryCode: this.form.controls.country.value,
        postCode: this.form.controls.postcode.value
      })
      .pipe(
        first(),
        takeUntil(this.unSubscribe$)
      )
      .subscribe(res => {
        this.toastr.success('Password reset email sent');
        this.router.navigate([ROUTE_URL.login]);
      });
  }

  cancel() {
    this.router.navigate([ROUTE_URL.login]);
  }
}
