export const ROUTE_URL = {
  vehiclesSelection: '/vehicles/selection',
  home: '/epc',
  customerBase: '/pos/customers',
  login: '/pos/login',
  resetPasword: '/pos/reset-password',
};

export const LISTS_FOR_CUSTOMERS = [
  'branchLookup',
  'countryLookup',
  'stateLookup',
  'zoneLookup',
  'routeLookup',
  'freightLookup',
  'colourLookup',
  'carrierLookup',
  'phoneTypeLookup',
  'vehicleRetiredReasonLookup',
];

export const LISTS_FOR_ORDERS = ['branchLookup', 'orderTypeLookup', 'productGroup', 'customerChannelLookup'];

export const LISTS_FOR_MANAGED_USERS = ['branchLookup', 'orderTypeLookup', 'inventoryViewLookup'];

export const API_ENDPOINTS = {
  stateLookup: '/lists/state',
  countryLookup: '/lists/country',
  countryExternaLookup: '/lists/CountryExternal',
  branchLookup: '/lists/branch',
  zoneLookup: '/lists/zone',
  routeLookup: '/lists/route',
  freightLookup: '/lists/FreightHandling',
  colourLookup: '/lists/colours',
  carrierLookup: '/lists/carrier',
  phoneTypeLookup: '/lists/PhoneType',
  vehicleRetiredReasonLookup: '/lists/VehicleRetiredReason',
  attachmentUpload: '/api/attachments/',
  vehicleSearch: '/vehicle/FetchHierarchy',
  customerSearch: '/customer/search',
  fetchCustomerDetails: '/customer/FetchCustomerDetails',
  duplicateCustomerSearch: '/customer/DuplicateCustomerSearch',
  getCustomerDetails: '/customer/summary',
  getCustomerDetailsExternal: '/sellarb/CustomerDetailsExternal',
  createRetailCustomer: '/customer/CreateCashCustomer',
  updateRetailCustomer: '/customer/UpdateCashCustomer',
  updateShipToCustomer: '/customer/UpdateShipToCustomer',
  createShipToCustomer: '/customer/CreateShipToCustomer',
  getCustomerVehicles: '/customer/FetchVehicleList',
  maintainCustomerVehicle: '/customer/MaintainVehicle',
  login: '/user/login',
  loginV2: '/user/LoginV2',
  resetPasword: '/user/ResetUserPassword',
  forgottenPassword: '/user/ForgottenPassword',
  registerExternalUserPublic: '/user/RegisterExternalUserPublic',
  logout: '/user/logout',
  logoutV2: '/user/LogoutV2',
  orderTypeLookup: '/lists/OrderType',
  inventoryViewLookup: '/lists/InventoryView',
  customerChannelLookup: '/lists/CustomerChannel',
  productGroup: '/lists/ProductGroup',
  enteredBy: '/lists/EnteredBy',
  orderSummary: '/sales/OrderSummary',
  orderSummaryOpenOrders: '/sales/OrderSummaryOpenOrders',
  orderSummaryQuoteOrders: '/sales/OrderSummaryQuoteOrders',
  generateQuoteForNonCorp: '/asyncOrder/GenerateQuoteForNonCorp',
  orderSummaryInvoicedOrders: '/sales/OrderSummaryInvoicedOrders',
  getPartDetails: '/sales/PartDetails',
  searchItems: '/item/ItemSearch',
  fetchJDEUsers: '/user/FetchJDEUsers',
  fetchJDESingleUser: '/user/FetchJDESingleUser',
  fetchRegisteredUsers: '/user/FetchRegisteredUsers',
  setJDEUserSecurity: '/user/SetJDEUserSecurity',
  setUserSecurity: '/user/SetUserSecurity',
  fetchSecurityFunctions: '/lists/FetchSecurityFunctionList',
  JDEPrinters: '/lists/JDEPrinters',
  fetchDefaultVehicle: '/vehicle/FetchDefaultVehicle',
  createOrder: '/staging/CreateOrder',
  fetchCustomerShipTo: '/customer/FetchCustomerShipTo',
  fetchCustomerBillTo: '/customer/FetchCustomerBillTo',
  fetchUserState: '/userState/FetchUserState',
  fetchOrderHeader: '/staging/FetchOrderHeader',
  addItemToOrder: '/staging/AddItemToOrder',
  validateItemForOrder: '/staging/ValidateItemForOrder',
  fetchOrderDetails: '/staging/FetchOrderDetails',
  fetchUpdatedOrderLine: '/staging/FetchUpdatedOrderLine',
  removeItemFromOrder: '/staging/RemoveItemFromOrder',
  updateItemOnOrder: '/staging/UpdateItemOnOrder',
  updateAllItemsOnOrder: '/staging/UpdateAllItemsOnOrder',
  updateOrderHeader: '/staging/UpdateOrderHeader',
  unloadOrder: '/staging/UnloadOrder',
  insertLineAfter: '/staging/InsertLineAfter',
  reSequenceLines: '/staging/ReSequenceLines',
  fetchItemAvailability: '/item/FetchItemAvailability',
  checkBuyinItem: '/item/CheckBuyinItem',
  validateOrderInJDE: '/staging/ValidateOrderInJDE',
  toggleShowroomView: '/userState/ToggleShowroomView',
  updateSelectedVehicle: '/userState/UpdateSelectedVehicle',
  distributeOrder: '/order/DistributeOrder',
  updateAccountCustomerSettings: '/customer/UpdateAccountCustomerSettings',
  updateCustomerEmail: '/customer/UpdateCustomerEmail',
  fetchOrderFromJDE: '/order/FetchOrderFromJDE',
  checkLineStockAllocation: '/item/CheckLineStockAllocation',
  checkStockAllocation: '/item/CheckStockAllocation',
  adjustStockAllocation: '/item/adjustStockAllocation',
  fetchCashCustomer: '/customer/FetchCashCustomer',
  fetchPayments: '/lists/FetchPayments',
  fetchPaymentInfo: '/order/fetchPaymentInfo',
  fetchInvoicePayments: '/sellarb/FetchInvoicePayments',
  getPaymentTypes: '/lists/PaymentTypes',
  givexGetBalance: '/givex/GivexGetBalance',
  givexActivation: '/givex/GivexActivation',
  redeemCard: '/givex/GivexRedemption',
  savePayment: '/staging/SavePayments',
  getRegisterList: '/lists/ListRegisters',
  getCreditReasonList: '/lists/ListReasonCodes',
  getSupplierList: '/lists/ListSuppliers',
  fetchCrossDock: '/item/FetchCrossDock',
  fetchDetailedInventory: '/item/FetchDetailedInventory',
  fetchInboundDetails: '/item/FetchInboundDetails',
  checkUserForCredits: '/user/CheckUserForCredits',
  fetchCashRoundedAmount: '/order/FetchCashRoundedAmount',
  fetchRegisterForHost: '/registers/FetchRegisterForHost',
  setRegisterForHost: '/registers/SetRegisterForHost',
  openCloseRegiser: '/registers/OpenCloseRegister',
  getFeedbackTypes: '/api/feedback/types',
  searchSuppliers: '/supplier/SupplierSearch',
  getCustomerSales: '/customer/CustomerSalesFigures',
  getStatementList: '/sellarb/StatementListExternal',
  getInvoiceList: '/sellarb/InvoiceListExternal',
  createCreditOrder: '/staging/CreateCreditOrder',
  emailStatementExternal: '/sellarb/EmailStatementExternal',
  emailInvoiceReprintExternal: '/sellarb/EmailInvoiceReprintExternal',
  copyOrder: '/staging/CopyOrder',
  fetchRecentOrders: '/order/FetchRecentOrders',
  fetchCatalogueStatus: '/user/FetchCatalogueStatus',
  checkSerialNumberOnHand: '/item/CheckSerialNumberOnHand',
  checkPreviousCredit: '/order/CheckPreviousCredit',
  submitWarranty: '/warranty/SubmitWarranty',
  getWarrantyFaultList: '/lists/ListWarrantyFaultCodes',
  getShowList: '/show/FetchShowList',
  getExternalUserList: '/lists/FetchExternalUsers',
  createShowCode: '/show/CreateShowCode',
  createShowUser: '/show/CreateShowUser',
  loginToShow: '/show/LoginToShow',
  getShowUsers: '/show/FetchShowUsers',
  deleteShowCode: '/show/RemoveShowCode',
  fetchNonCorpStoresForShow: '/show/FetchNonCorpStoresForShow',
  checkForShowSales: '/show/CheckForShowSales',
  fetchCustomerShowList: '/show/FetchCustomerShowList',
  fetchShowSalesForExternal: '/show/FetchShowSalesForExternal',
  fetchShowPrinters: '/show/FetchShowPrinters',
  fetchUsersForRecentOrders: '/lists/FetchUsersForRecentOrders',
  savePendingPayments: '/staging/SavePendingPayments',
  removePendingPayment: '/staging/RemovePendingPayments',
  updatePendingPayment: '/staging/UpdatePendingPayments',
  orderLevelReprice: '/order/OrderLevelReprice',
  resetOrderPrice: '/order/ResetOrderPrice',
  fetchBranchInvoiceDetails: '/lists/FetchBranchInvoiceDetails',
  fetchNextNumber: '/order/FetchNextNumber',
  finaliseOrderToJDE: '/asyncOrder/AsyncOrderToJDE',
  fetchBrandDefaults: '/lists/FetchBrandDefaults',
  listTasksNotes: '/crm/ListTasksNotes',
  updateNotes: '/crm/UpdateNotes',
  updateTasks: '/crm/UpdateToDo',
  updateAlert: '/crm/UpdateAlert',
  getCustomerAlert: '/crm/getCustomerAlert',
  listTemplates: '/crm/ListTemplates',
  listOutcomes: '/crm/ListOutcomes',
  completeTask: '/crm/CompleteTask',
  fetchCRMUsers: '/crm/FetchCRMUsers',
  fetchGiftCardLoad: '/staging/FetchGiftCardLoad',
  addGiftCardLoad: '/staging/AddGiftCardLoad',
  overdueTaskAlert: '/crm/OverdueTaskAlert',
  assignEftposDeviceToHost: '/eftpos/AssignEFTPOSDeviceToHost',
  registerEftpos: '/api/eftpos/register',
  checkEftposStatus: '/api/eftpos/status',
  makeEftposTransaction: '/api/eftpos/transaction',
  fetchVehicleDescription: '/vehicle/FetchVehicleDescription',
  fetchShipTos: '/customer/FetchShipTos',
  listHoldCode: '/lists/ListHoldCodes',
  releaseHoldCode: '/order/ReleaseOrderHoldCode',
  fetchContentStructure: '/ContentComponent/FetchContentStructure',
  downloadFile: '/ContentComponent/DownloadFile',
  fetchSpotLightInfo: '/ContentComponent/FetchSpotLightInfo',
  fetchParkedOrderList: '/parkOrder/FetchParkedOrderList',
  loadParkedOrder: '/parkOrder/LoadParkedOrder',
  removeParkedOrder: '/parkOrder/RemoveParkedOrder',
  removeAllParkedOrders: '/parkOrder/RemoveAllParkedOrders',
  fetchWeekList: '/WorkshopBooking/FetchWeekList',
  fetchResourceList: '/WorkshopBooking/FetchResourceList',
  fetchOrdersForBooking: '/WorkshopBooking/FetchOrdersForBooking',
  changeOrderBooking: '/WorkshopBooking/changeOrderBooking',
  splitOrder: '/WorkshopBooking/SplitOrder',
  consolidateOrder: '/WorkshopBooking/ConsolidateOrder',
  renameGroupName: '/WorkshopBooking/RenameGroupName',
  reassignLine: '/WorkshopBooking/ReassignLine',
  resourceFuzzySearch: '/WorkshopBooking/ResourceFuzzySearch',
  fetchResourceById: '/WorkshopBooking/FetchResourceByID',
  reprintReceipt: '/eftpos/reprintReceipt',
  trailerSearch: '/trailer/TrailerSearch',
  fetchCustomerTrailerDetails: '/trailer/FetchCustomerTrailerDetails',
  updateTrailerInfo: '/trailer/UpdateTrailerInfo',
  fetchRegoStatus: '/lists/RegoStatus',
  updateServiceHistory: '/trailer/UpdateServiceHistory',
  fetchStoreList: '/trailer/StoreList',
  camperTrailerGenerateReport: '/trailer/GenerateReport',
  fetchARBuCohorts: '/lists/ARBuCohorts',
  customerSpecificSearch: '/customer/customerSpecificSearch',
  createOrderSilent: '/staging/CreateOrderSilent',
  fetchItemPriceGroup: '/item/FetchItemPriceGroup',
  postcodes: '/lists/PostCodes',
  fetchRecordsForBooking:'/WorkshopBooking/FetchRecordsForBooking',
  listWorkshopTask : '/lists/ListWorkshopTasks',
  maintainTaskBooking : '/WorkshopBooking/MaintainTaskBooking',
  maintainOrderBooking:'/WorkshopBooking/MaintainOrderBooking',
  fetchWorkshopHours:'/WorkshopBooking/FetchWorkshopHours',

  // DMS API
  getResources: '/resources',
  getDownloadUrl: '/downloadUrl',
};

export const KEYS = {
  jdeSession: 'jdeSession',
  jdeToken: 'jde-token',
  cashDrawer: 'cashDrawer',
  version: 'version',
  catalogueStatus: 'catalogueStatus',
};
