export const getFormFilterKey = (key: string): string => {
  return key.charAt(0).toLowerCase() + key.slice(1);
};

export const getFilterKey = (key: string): string => {
  return key.charAt(0).toUpperCase() + key.slice(1);
};

export const roundingNumber = (number: number, decimalPoint: number) => {
  return (+`${Math.round(
    +`${number}e+${decimalPoint}`
  )}e-${decimalPoint}`).toFixed(decimalPoint);
};
