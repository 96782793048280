<div class="product-details row arb-clear-text-for-table">
  <div class="product-attributes col-md-6">
    <table class="product-items-table">
      <caption>
        Product Info Criteria
      </caption>
      <thead>
        <tr>
          <th>Attribute</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of productInfoAttribute">
          <td>{{ item.attribute }}</td>
          <td>{{ item.value }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="product-attributes col-md-6">
    <table class="product-items-table">
      <caption>
        Fitment Info Criteria
      </caption>
      <thead>
        <tr>
          <th>Attribute</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of productFitmentInfoAttribute">
          <td>{{ item.attribute }}</td>
          <td>{{ item.value }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
