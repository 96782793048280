import { Component, HostListener, OnDestroy, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  AuthService,
  CoreUiPartialState,
  getIsLoggedIn,
  getBrandDefaults
} from '@pos-app/core-ui';
import { ROUTE_URL, UserProfile, BrandDefaults } from '@pos-app/data';
import { Subject, Observable} from 'rxjs';
import { first, take, takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { SecureCatalogueService } from 'libs/core-ui/src/lib/services/secure-catelogue.service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  loading = false;
  isSubmitted = false;
  public isRedirect = false
  public redirectURL =''

  unSubscribe$ = new Subject<void>();

  userProfile: UserProfile;

  public isCaps: boolean = false;
  @Input() brandDefaults: BrandDefaults;
  public brandDefaults$: Observable<BrandDefaults>;

  @HostListener('window:keydown', ['$event'])
  public onKeydown(event: KeyboardEvent): void {
    this.isCaps =
      typeof event.getModifierState === 'function' &&
      event.getModifierState('CapsLock');
  }

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<CoreUiPartialState>,
    private router: Router,
    private authService: AuthService,
    private toastrService: ToastrService,
    private secureCatalogueService: SecureCatalogueService,
    private loc: Location,
    private coreUiStore: Store<CoreUiPartialState>,
  ) {}

  ngOnInit() {
    const appDomain = window.location.href.replace(this.loc.path(), '');
    this.coreUiStore
    .select(getBrandDefaults)
    .pipe(takeUntil(this.unSubscribe$))
    .subscribe((data) => {
      if(data?.redirectURL?.length > 0) {
        if(appDomain!==data.redirectURL){
          this.isRedirect = true
          this.redirectURL = data.redirectURL
        }else{
          this.isRedirect = false
        }
      }
    });
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });

    // if user already logged => redirect them to the home
    this.store
      .select(getIsLoggedIn)
      .pipe(take(1))
      .subscribe((isLoggedIn) => {
        if (isLoggedIn) {
          window.location.href = this.secureCatalogueService.getLandingPage();
        }
      });
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  get username() {
    return this.loginForm.controls.username;
  }

  get password() {
    return this.loginForm.controls.password;
  }

  login() {
    this.isSubmitted = true;

    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;
    this.authService
      .login(
        this.loginForm.controls.username.value,
        this.loginForm.controls.password.value
      )
      .pipe(first())
      .subscribe(
        (data) => {
          if (!data) {
            return;
          } else if (data.ShowCodes.length > 0) {
            this.userProfile = data;
          } else {
            this.redirectAfterLogin();
          }
        },
        (error) => {
          this.toastrService.error(error.ErrorMessage);
          if (
            error.ErrorMessage ===
            'Password needs to be reset prior to transacting'
          ) {
            this.router.navigate([ROUTE_URL.resetPasword], {
              queryParams: {
                email: this.loginForm.controls.username.value,
              },
            });
          }
          this.loading = false;
        }
      );
  }

  redirectAfterLogin() {
    window.location.href = this.secureCatalogueService.getLandingPage();
  }
}
