import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ApiService, AttachmentType } from '@pos-app/core-ui';
import { KEYS, ORDER_ATTACHMENT_TYPE } from '@pos-app/data';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-order-header-notes',
  templateUrl: './order-header-notes.component.html',
  styleUrls: ['./order-header-notes.component.scss']
})
export class OrderHeaderNotesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() orderNumber: number;
  @Input() orderType: string;
  @Input() orderCompany: string;
  @Input() preloadAttachments: any;
  @Input() isReadOnly: boolean;
  @Output() reloadedAttachments = new EventEmitter<void>();
  @Output() closeActiveModal = new EventEmitter<boolean>();
  newNoteForm: FormGroup;
  attachedFile: File;
  allAttachments: any;
  editorConfig: AngularEditorConfig;
  SIZE_LIMIT = 15 * 1024 * 1024; // 15MB
  noteInEdit = null;
  unSubscribe$ = new Subject<void>();
  ORDER_ATTACHMENT_TYPE = ORDER_ATTACHMENT_TYPE;
  displayNoteGrid: boolean = true;
  userEmail = JSON.parse(localStorage.getItem(KEYS.jdeSession)).UserID;

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private api: ApiService
  ) {
    this.newNoteForm = this.fb.group({
      note: [''],
      link: [
        ''
        // Commented Url validation as requested. Leave it here in case it's needed in the future
        // Validators.pattern(
        //   '^(http[s]?:\\/\\/){0,1}[a-zA-Z0-9\\.\\-\\#\\?]+\\.[a-zA-Z]{2,5}$'
        // )
      ],
      noteType: ['']
    });

    this.editorConfig = {
      editable: true,
      spellcheck: false,
      height: '15rem',
      minHeight: '5rem',
      placeholder: 'Enter note here...',
      translate: 'no',
      defaultParagraphSeparator: 'p',
      toolbarHiddenButtons: [
        ['fontName', 'heading'],
        [
          'fontSize',
          'textColor',
          'backgroundColor',
          'customClasses',
          'link',
          'unlink',
          'insertImage',
          'insertVideo',
          'insertHorizontalRule',
          'toggleEditorMode'
        ]
      ]
    };
  }

  ngOnInit() {
    this.newNoteForm.valueChanges
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe(formValue => {
        if (formValue.noteType === '') {
          this.displayNoteGrid = true;
        } else {
          this.displayNoteGrid = false;
        }
      });
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.preloadAttachments && changes.preloadAttachments.currentValue) {
      this.allAttachments = this.preloadAttachments;
    }
  }

  private reloadAttachments() {
    this.reloadedAttachments.emit();
  }

  selectNoteType() {
    this.newNoteForm.patchValue({
      note: '',
      link: ''
    });
  }

  saveAttachment() {
    if (this.noteInEdit) {
      this.updateItem({
        id: this.noteInEdit._id,
        data:
          this.newNoteForm.value.noteType === ORDER_ATTACHMENT_TYPE.note
            ? this.newNoteForm.value.note
            : this.newNoteForm.value.link,
        display: this.noteInEdit.display
      });
      this.resetNoteForm();
      return;
    }
    if (this.attachedFile) {
      if (this.attachedFile.size > this.SIZE_LIMIT) {
        this.toastr.error('File is too big. Max allowed is 10MB');
      } else {
        this.api
          .uploadAttachment({
            entityType: 'order',
            entityId: `${this.orderNumber.toString()}|${this.orderType}|${
              this.orderCompany
            }`,
            type: ORDER_ATTACHMENT_TYPE.file as AttachmentType,
            file: this.attachedFile
          })
          .subscribe(
            () => {
              this.attachedFile = null;
              this.resetNoteForm();
              this.reloadAttachments();
              this.toastr.success('File uploaded');
            },
            e => {
              this.toastr.error('Upload failed');
            }
          );
      }
    } else {
      this.api
        .uploadAttachment({
          entityType: 'order',
          entityId: `${this.orderNumber.toString()}|${this.orderType}|${
            this.orderCompany
          }`,
          type: this.newNoteForm.value.noteType as AttachmentType,
          noteOrLink: this.newNoteForm.value.note || this.newNoteForm.value.link
        })
        .subscribe(
          x => {
            this.toastr.success(
              `${
                this.newNoteForm.value.noteType === 'note' ? 'Note' : 'Link'
              } added`
            );
            this.resetNoteForm();
            this.reloadAttachments();
          },
          e => {
            this.toastr.error('Action failed');
          }
        );
    }
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      this.attachedFile = event.target.files.item(0);
    }
  }

  downloadFile(item) {
    this.api.downloadFile(item._id, item.name).subscribe(x => {});
  }

  deleteItem(item) {
    this.resetNoteForm();
    this.api.deleteAttachment(item._id, 'D', this.userEmail).subscribe(x => {
      this.reloadAttachments();
      this.toastr.success('Order note deleted');
    });
  }

  updateItem(data) {
    this.api.updateAttachment(data).subscribe(x => {
      this.reloadAttachments();
      this.toastr.success('Order note updated');
    });
  }

  editNote(item) {
    this.noteInEdit = item;
    this.newNoteForm.patchValue({
      ...{ [item.type]: item.data },
      noteType: item.type
    });
  }

  resetNoteForm() {
    this.noteInEdit = null;
    this.newNoteForm.patchValue({
      note: '',
      noteType: '',
      link: ''
    });
  }

  formatTime(time) {
    // JDE time doesn't put a 0 at the start when less than 10am
    if (parseInt(time.slice(0, 2)) > 24) {
      return ['0' + time.slice(0, 1), ':', time.slice(1, 3)].join('');
    } else {
      return [time.slice(0, 2), ':', time.slice(2, 4)].join('');
    }
  }

  closeModal() {
    this.closeActiveModal.emit(true);
  }
}
