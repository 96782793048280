<form [formGroup]="stockAllocationForm">
  <div *ngIf="errorList.length > 0">
    <h5>UNABLE-TO-ALLOCATE ITEMS</h5>
    <table class="table table-bordered mt-2">
      <thead>
        <tr>
          <td><label>ITEM</label></td>
          <td><label>QTY</label></td>
          <td><label>ERROR MESSAGE</label></td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let errorStock of errorList" class="text-danger">
          <td class="text-uppercase">
            <div>
              {{ errorStock.itemNumber }}
            </div>
            <div>
              {{ errorStock.itemDescription }}
            </div>
          </td>
          <td>
            {{ errorStock.quantity }}
          </td>
          <td>
            {{ errorStock.errorMessage }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="serializedList.length > 0">
    <h5>SERIALISED ITEMS</h5>
    <input
      *ngIf="!isCreditOrder"
      class="form-control"
      [formControl]="serialNumberFormControl"
      placeholder="SCAN SERIAL NUMBER HERE TO AUTO ALLOCATE"
      autofocus
    />
    <table class="table table-bordered mt-2">
      <thead>
        <tr>
          <td><label>ITEM</label></td>
          <td><label>QTY</label></td>
          <td><label>SERIAL NUMBER</label></td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let allocation of serializedList; let rowIndex = index">
          <td
            class="text-uppercase"
            [ngClass]="
              stockAllocationForm.controls[allocation.lineNumber].value.length === math.abs(allocation.quantity) ? 'text-success' : 'text-danger'
            "
          >
            <div>
              {{ allocation.itemNumber }}
            </div>
            <div>
              {{ allocation.itemDescription }}
            </div>
          </td>
          <td>
            {{ allocation.quantity }}
          </td>
          <td>
            <div *ngIf="isCreditOrder" [formArrayName]="allocation.lineNumber">
              <ng-container *ngFor="let item of stockAllocationForm.controls[allocation.lineNumber]['controls']; let itemIndex = index">
                <div
                  [formGroupName]="itemIndex"
                  [ngClass]="checkRowIsSerialNumberOnHand(rowIndex) ? 'border border-danger rounded' : ''"
                  class="mb-1"
                >
                  <input
                    [id]="rowIndex + '-' + itemIndex"
                    class="form-control"
                    formControlName="serialNumber"
                    (input)="onChangeMethod($event, allocation.itemNumber, rowIndex)"
                    autocomplete="off"
                  />
                </div>
              </ng-container>
            </div>

            <div *ngIf="!isCreditOrder">
              <div
                *ngIf="
                  getAvailableSerializedItems(allocation.itemNumber).length >=
                    allocation.quantity - stockAllocationForm.controls[allocation.lineNumber].value.length;
                  else elseBlock
                "
              >
                <div>
                  <ng-select
                    [formControlName]="allocation.lineNumber"
                    [multiple]="true"
                    [maxSelectedItems]="allocation.quantity"
                    [placeholder]="'Please select the serial number'"
                    appendTo="body"
                  >
                    <ng-option *ngFor="let availableItem of getAvailableSerializedItems(allocation.itemNumber)" [value]="availableItem.serialNumber">
                      {{ availableItem.serialNumber }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="text-danger">
                  {{
                    stockAllocationForm.controls[allocation.lineNumber].value.length !== allocation.quantity &&
                    stockAllocationForm.controls[allocation.lineNumber].touched
                      ? 'Allocated quantity not match ordered quantity'
                      : ''
                  }}
                </div>
              </div>
              <ng-template #elseBlock>
                <span class="text-danger">
                  ERROR: NOT ENOUGH STOCK OF SERIALIZED ITEM. ONHAND QUANTITY IS:
                  {{
                    getAvailableSerializedItems(allocation.itemNumber).length + stockAllocationForm.controls[allocation.lineNumber].value.length
                  }}</span
                >
              </ng-template>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="noStockInBlankList.length > 0">
    <h5>NO STOCKS IN BLANK LOCATION</h5>
    <table class="table table-bordered">
      <thead>
        <tr>
          <td><label>ITEM</label></td>
          <td><label>QTY</label></td>
          <td><label>LOCATION</label></td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let allocation of noStockInBlankList">
          <td class="text-uppercase" [ngClass]="validStockAllocation(allocation) ? 'text-success' : 'text-danger'">
            <div>
              {{ allocation.itemNumber }}
              <span class="pl-1" *ngIf="allocation.lotSerialNumber"> ({{ allocation.lotSerialNumber }}) </span>
            </div>
            <div>
              {{ allocation.itemDescription }}
            </div>
          </td>
          <td>
            {{ allocation.quantity }}
          </td>
          <td>
            <div [formArrayName]="allocation.lineNumber" *ngFor="let item of getFormArray(allocation.lineNumber); let i = index">
              <div [formGroupName]="i" class="row pb-2">
                <div class="col-5">
                  <ng-select [placeholder]="'Select location'" formControlName="itemLocation" appendTo="body">
                    <ng-option *ngFor="let availableLocation of getAvailableLocation(allocation, item)" [value]="availableLocation.location">
                      {{ availableLocation.label }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="col-2">
                  <input class="form-control" type="number" formControlName="qty" />
                </div>
                <div class="col-1 my-auto" *ngIf="getFormArray(allocation.lineNumber).length > 1">
                  <mat-icon class="my-auto" class="text-danger" (click)="removeLocation(allocation.lineNumber, i)">delete</mat-icon>
                </div>
                <div [ngClass]="checkNegative(allocation.onHandQtyArray, item) ? 'col-4 my-auto warning-stock' : 'col-4 my-auto'">
                  {{ checkNegative(allocation.onHandQtyArray, item) ? 'STOCK WILL GO NEGATIVE' : '' }}
                </div>
              </div>
            </div>
            <div class="pb-2">
              <button
                *ngIf="shouldPickAnotherLocation(allocation) && stockAllocationForm.controls[allocation.lineNumber].valid"
                class="btn btn-secondary mx-0"
                (click)="addLocation(allocation)"
              >
                PICK ANOTHER LOCATION
              </button>
            </div>
            <div class="text-danger">
              {{
                !this.stockAllocationForm.controls[allocation.lineNumber].dirty || validStockAllocation(allocation)
                  ? ''
                  : 'Allocated quantity not match ordered quantity'
              }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</form>
