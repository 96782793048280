import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { WeekListObject } from '@pos-app/data';

@Component({
  selector: 'app-filters-review-by-week',
  templateUrl: './filters-review-by-week.component.html',
  styleUrls: ['./filters-review-by-week.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FiltersReviewByWeekComponent,
    },
  ],
})
export class FiltersReviewByWeekComponent
  implements OnInit, ControlValueAccessor, OnChanges
{
  @Input() label: string;
  @Input() disabled = false;
  @Input() maxNumber;
  @Input() minNumber;
  @Output() indexToUpdate = new EventEmitter<any>();
  inputLabel: string;
  index: number = 0;
  touched: boolean = false;

  ngOnInit(): void {
    this.indexToUpdate.emit({ index: this.index });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.label) {
      this.inputLabel = this.label;
    }
  }

  onChange = (input) => {};

  onTouched = () => {};

  onRight() {
    this.markAsTouched();
    if (!this.disabled) {
      this.index = this.index + 1;
      this.onChange(this.index);
      this.indexToUpdate.emit({ index: this.index });
    }
  }

  onLeft() {
    this.markAsTouched();
    if (!this.disabled) {
      this.index -= 1;
      this.onChange(this.index);
      this.indexToUpdate.emit({ index: this.index });
    }
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  writeValue(label: string): void {
    this.inputLabel = label;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  constructor() {}
}
