import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  ApiService,
  CoreUiPartialState,
  DialogService,
  getLoginDetails,
  getWarrantyShowExpensesTF,
  MessageService,
} from '@pos-app/core-ui';
import html2canvas from 'html2canvas';
import { SecureCatalogueService } from 'libs/core-ui/src/lib/services/secure-catelogue.service';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, interval, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  defaultIfEmpty,
  finalize,
  map,
  switchMap,
  takeWhile,
} from 'rxjs/operators';
import {
  ActionDetails,
  OmeDetails,
  PartDetails,
  VehicleDetails,
  WarrantyDto,
  WarrantyFault,
  WarrantyHeader,
  WarrantyLineDto,
} from '../../../core/models/warranty-form.models';
import jspdf from 'jspdf';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-warrantyform',
  templateUrl: './warrantyform.component.html',
  styleUrls: ['./warrantyform.component.scss'],
})
export class WarrantyformComponent implements OnInit, OnDestroy {
  @ViewChild('submitConfirmation')
  submitConfirmationModalBox: any;
  today = new Date();
  unSubscribe$ = new Subject<void>();
  isFitted = false;
  warrantyHeader: WarrantyHeader;
  vehicleDetails: VehicleDetails;
  omeDetails: OmeDetails;
  partsDetails: [];
  replacePartsDetails: [];
  actionDetails: ActionDetails;
  attachedFiles: File[] = [];
  isOME = false;
  loginDetails$: Observable<any>;
  itemToReplace: PartDetails;
  isSubmitting: boolean;
  warrantyFaults$: Observable<WarrantyFault[]>;
  uploadingFilesProgress$: Observable<any>;
  totalFiles = 0;
  uploadingFile = 0;
  formSubmitStatus: boolean;
  warrantySubmittedNumber: number;
  warrantySubmitErrorMessage: string;
  dateTimeStamp;
  public warrantyShowExpensesTF$: Observable<boolean>;

  formValidity = {
    header: false,
    vehicle: true, //optional
    ome: true, //optional
    originalParts: false,
    replacementParts: true, //optional
    action: true, //optional
  };

  FORM_SECTION = {
    header: 'header',
    vehicle: 'vehicle',
    ome: 'ome',
    originalParts: 'originalParts',
    replacementParts: 'replacementParts',
    action: 'action',
  };

  constructor(
    private router: Router,
    private store: Store<CoreUiPartialState>,
    private apiService: ApiService,
    private messageService: MessageService,
    private secureCatalogue: SecureCatalogueService,
    private modalService: NgbModal,
    private coreUiStore: Store<CoreUiPartialState>,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  ngOnInit() {
    this.actionDetails = this.getDefaultActionDetails();
    this.loginDetails$ = this.store.select(getLoginDetails);
    this.warrantyFaults$ = this.apiService
      .getWarrantyFaultList({
        faultCategory: '',
      })
      .pipe(map((res) => res.SearchResults));

    this.warrantyShowExpensesTF$ = this.coreUiStore.select(
      getWarrantyShowExpensesTF
    );
    this.cdr.detectChanges();
  }

  handleSection(section: string, sectionForm: any) {
    switch (section) {
      case this.FORM_SECTION.header:
        this.warrantyHeader = sectionForm.value;
        this.formValidity.header = sectionForm.isValid;
        break;
      case this.FORM_SECTION.vehicle:
        this.vehicleDetails = sectionForm.value;
        this.formValidity.vehicle = sectionForm.isValid;
        break;
      case this.FORM_SECTION.ome:
        this.omeDetails = sectionForm.value;
        this.formValidity.ome = sectionForm.isValid;
        break;
      case this.FORM_SECTION.originalParts:
        this.partsDetails = sectionForm.value;
        this.formValidity.originalParts = sectionForm.isValid;
        break;
      case this.FORM_SECTION.replacementParts:
        this.replacePartsDetails = sectionForm.value;
        this.formValidity.replacementParts = sectionForm.isValid;
        break;
      case this.FORM_SECTION.action:
        this.actionDetails = sectionForm.value;
        this.formValidity.action = sectionForm.isValid;
        break;
    }
  }

  isInvalidForm() {
    // Invalid if there's any invalid section
    return (
      Object.keys(this.formValidity).filter((key) => !this.formValidity[key])
        .length > 0
    );
  }

  submitWarranty() {
    this.isSubmitting = true;
    forkJoin(
      this.warrantyHeader.attachedFiles.map((file) => {
        this.totalFiles = this.warrantyHeader.attachedFiles.length;
        this.uploadingFilesProgress$ = interval(500).pipe(
          map((value) => this.uploadingFile + value),
          takeWhile((x) => x <= this.totalFiles)
        );
        return this.apiService.uploadAttachment({
          entityType: 'warranty',
          entityId: 'rma',
          type: 'file',
          file,
        });
      })
    )
      .pipe(
        defaultIfEmpty([]),
        switchMap((res) => {
          const attachmentIds =
            res.length > 0 ? res.map((attachment) => attachment.id) : res;
          // build the payload
          const warrantyPayload = this.getWarrantyPayload(
            this.warrantyHeader,
            this.vehicleDetails,
            this.omeDetails,
            this.partsDetails,
            this.replacePartsDetails,
            this.actionDetails,
            attachmentIds
          );

          return this.apiService.submitWarranty(warrantyPayload).pipe(
            catchError((error) => {
              return of(error);
            })
          );
        }),
        switchMap((res) => {
          this.dateTimeStamp = moment().format('MM/DD/YYYY, hh:mma');
          this.isSubmitting = false;
          this.formSubmitStatus = res.ErrorFlag === '0';
          this.warrantySubmittedNumber = res.WarrantyID;
          this.warrantySubmitErrorMessage = res.ErrorMessage;
          return this.modalService
            .open(this.submitConfirmationModalBox, {
              size: 'lg',
            })
            .result.then(
              (_) => {
                this.warrantySubmittedNumber = res.WarrantyID;
              },
              (_) => {}
            );
        })
      )
      .subscribe(() => {
        setTimeout(() => {
          this.router.navigateByUrl(this.secureCatalogue.getLandingPage());
        }, 400);
      });
  }

  getWarrantyPayload(
    warrantyHeader: WarrantyHeader,
    vehicleDetails: VehicleDetails,
    omeDetails: OmeDetails,
    partsDetails: PartDetails[],
    replacePartsDetails: PartDetails[],
    actionDetails: ActionDetails,
    attachmentIds: string[]
  ) {
    const warranty: WarrantyDto = {
      shipToNumber: 0,
      branch: warrantyHeader.branch,
      firstName: warrantyHeader.firstName,
      surname: warrantyHeader.surname,
      email: warrantyHeader.email,
      phone: warrantyHeader.phone.toString(),
      customerReference:warrantyHeader.customerReference,
      phoneType: '',
      addressLine1: warrantyHeader.addressLine1,
      addressLine2: '',
      suburb: '',
      state: '',
      postCode: warrantyHeader.postCode,
      country: '',
      secContactName: '',
      secContactPhone: '',
      marketingOptInYN: '',
      useBusinessNameYN: '',
      accessoriesFitted: vehicleDetails ? vehicleDetails.accessoriesFitted : '',
      actionTaken: warrantyHeader.action,
      businessOrPersonalUseBP: vehicleDetails
        ? vehicleDetails.businessOrPersonalUseBP
        : '',
      currentOdometer: vehicleDetails
        ? vehicleDetails.currentOdometer.toString()
        : '',
      distanceTravelledSinceFit: omeDetails
        ? omeDetails.distanceTravelledSinceFit.toString()
        : '',
      fittedFlag10: vehicleDetails ? '1' : '0',
      omeFlag10: omeDetails ? '1' : '0',
      issueDescription: warrantyHeader.issueDescription,
      suppliedFittedBy: warrantyHeader.suppliedFittedBy,
      vehicleCondition: vehicleDetails ? vehicleDetails.vehicleCondition : '',
      wheelTyreSize: omeDetails ? omeDetails.wheelTyreSize : '',
      labourValue: actionDetails.labour,
      freightValue: actionDetails.freight,
      otherValue: actionDetails.other,
      photoIDArray: attachmentIds,
      jdeVehicleID: vehicleDetails ? vehicleDetails.vehicleId : 0,
      vehicleShortDesc: vehicleDetails ? vehicleDetails.vehicleShortDesc : '',
      originalLines: [],
      replacementLines: [],
    };

    warranty.originalLines = partsDetails.map((item) => {
      const warrantyLine: WarrantyLineDto = {
        itemNumber: item.itemNumber,
        description: item.description,
        quantity: item.quantity,
        lotSerialNumber: item.lotSerialNumber,
        vehicleSelector: vehicleDetails ? vehicleDetails.vehicleSelector : '',
        invoiceNumber: +item.invoiceNumber,
        invoiceType: '',
        invoiceCompany: '',
        origOrderNumber: 0,
        origOrderType: '',
        origOrderCompany: '',
        origOrderLine: 0,
        dateSuppliedFitted: item.dateSuppliedFitted,
        faultDate: omeDetails ? omeDetails.faultDate : '',
        faultCode: omeDetails ? omeDetails.faultCode : '',
        returnConnote: '',
      };
      return warrantyLine;
    });

    warranty.replacementLines = replacePartsDetails
      ? replacePartsDetails.map((item) => {
          const warrantyLine: WarrantyLineDto = {
            itemNumber: item.itemNumber,
            description: item.description,
            quantity: item.quantity,
            lotSerialNumber: item.lotSerialNumber,
            vehicleSelector: vehicleDetails
              ? vehicleDetails.vehicleSelector
              : '',
            invoiceNumber: item.invoiceNumber ? +item.invoiceNumber : 0,
            invoiceType: '',
            invoiceCompany: '',
            origOrderNumber: 0,
            origOrderType: '',
            origOrderCompany: '',
            origOrderLine: 0,
            dateSuppliedFitted: item.dateSuppliedFitted,
            returnConnote: '',
          };
          return warrantyLine;
        })
      : [];

    return warranty;
  }

  convetToPDF() {
    var data = document.getElementById('contentToConvert');
    html2canvas(data).then((canvas) => {
      var imgData = canvas.toDataURL('image/jpg');
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jspdf('p', 'mm');
      var position = 0;

      doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save('warranty-form.pdf');
    });
  }

  private getDefaultActionDetails(): ActionDetails {
    return {
      action: '',
      labour: 0,
      freight: 0,
      other: 0
    };
  }

}
