<div class="row product-prices-stocks arb-clear-text-for-table">
  <table>
    <tr>
      <td class="product-stocks">
        <div class="stocks">
          <div
            class="retrieving"
            *ngIf="
              isUserloggedIn &&
              (!stocks ||
                (stocks.SearchResults && stocks.SearchResults.length == 0))
            "
          >
            <i class="pi pi-spin pi-spinner"></i>Retrieving stocks..
          </div>
          <div *ngIf="!isUserloggedIn" class="login-for-stock">
            Login for stocks
          </div>
          <table
            *ngIf="isUserloggedIn && stocks && stocks.SearchResults"
            class="stock-table"
          >
            <thead>
              <tr>
                <th colspan="2" class="arb-medium-heading text-center">
                  STOCK AVAILABLE
                </th>
              </tr>
            </thead>
            <tr *ngFor="let stock of stocks.SearchResults">
              <td class="arb-field-label stock-label">
                {{ stock.branchName ? stock.branchName : stock.branchCode }}:
              </td>
              <td class="arb-field-label stock-price">
                {{ getQuantity(stock) }}
              </td>
            </tr>
          </table>
        </div>
      </td>
      <td class="images-container" rowSpan="2">
        <app-product-product-images
          #productImage
          [images]="images"
        ></app-product-product-images>
      </td>
      <td class="product-prices">
        <div class="prices">
          <div
            class="retrieving"
            *ngIf="isUserloggedIn && basedPrices.length == 0"
          >
            <i class="pi pi-spin pi-spinner"></i> Retrieving prices..
          </div>
          <a
            href="javascript:void(0);"
            class="enter-postcode link"
            *ngIf="!isUserloggedIn && !userPostCode"
            (click)="showPostCodePrompt()"
          >
            Enter postcode
          </a>
          <table *ngIf="basedPrices.length > 0" class="price-table">
            <thead>
              <tr>
                <th colspan="2" class="arb-medium-heading text-center">
                  BASE PRICE
                </th>
              </tr>
            </thead>
            <tr *ngFor="let price of basedPrices">
              <td class="arb-field-label price-label">
                {{ price.priceScheduleDescription }}
              </td>
              <td class="arb-field-label price-value">
                {{ price.taxedUnitPrice ? (price.taxedUnitPrice.toFixed(2) | currency : price.currency : 'symbol-narrow') : 'N/A' }}
              </td>
            </tr>
          </table>
        </div>
      </td>
    </tr>
    <tr *ngIf="productImage.canPrev || productImage.canNext">
      <td class="switch-image">
        <a
          [hidden]="!productImage || !productImage.canPrev"
          class="arb-other-button slider-button"
          (click)="productImage.prev()"
          ><i class="pi pi-angle-left"></i
        ></a>
      </td>
      <td class="switch-image">
        <a
          [hidden]="!productImage || !productImage.canNext"
          class="arb-other-button slider-button"
          (click)="productImage.next()"
          ><i class="pi pi-angle-right"></i
        ></a>
      </td>
    </tr>
  </table>
</div>

<app-postcode #postCodeInputModal></app-postcode>
