<div mat-dialog-content>
  <h6 class="arb-medium-heading p-2">PLEASE CONFIRM FITTING DATE</h6>
  <mat-form-field appearance="fill">
    <input
      matInput
      [matDatepicker]="picker"
      placeholder="Choose a date"
      [(ngModel)]="date"
    />
    <mat-hint>DD/MM/YYYY</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker color="primary"></mat-datepicker>
  </mat-form-field>
</div>
<div class="float-end" mat-dialog-actions>
  <button class="btn" [mat-dialog-close]="date">CONFIRM</button>
</div>
