import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { CustomersPartialState } from '../../+state/customers.reducer';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { BrandDefaults, CustomerDetails, CUSTOMER_TYPE, LookupLists, State } from '@pos-app/data';
import { UpdateRetailCustomer, UpdateShipToCustomer, SelectCountry, LoadLookupLists } from '../../+state/customers.actions';
import { customersQuery } from '../../+state/customers.selectors';
import { FormGroup } from '@angular/forms';
import { hasUnsavedChanges, MessageService, CoreUiPartialState, isExternalUser, getBrandDefaults } from '@pos-app/core-ui';
import { delay, map, take, takeUntil } from 'rxjs/operators';
import { CustomersResponseService } from '../../+state/customers.response';

@Component({
  selector: 'app-customers-details',
  templateUrl: './customers-details.component.html',
  styleUrls: ['./customers-details.component.scss'],
})
export class CustomersDetailsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public isForNewOrder: boolean;
  @Input() public marketingOptInYNFromOrder: boolean;
  @Input() public preSelectedCustomer;

  @Output() public updatedCustomer = new EventEmitter<string>();

  public selectedCustomerNumber: string;
  public selectedCustomerDetails: CustomerDetails;
  public customerDetailsForm: FormGroup;
  public formValid = true;
  public formDirty = false;
  public formValue = null;
  public customerType = CUSTOMER_TYPE;
  public updatingCustomerDetails$: Observable<boolean>;
  public lookupLists$: Observable<LookupLists>;
  public stateList$: Observable<State[]>;
  public stateList: State[];
  public isExternalUser$: Observable<boolean>;
  public brandDefaults$: Observable<BrandDefaults>;
  public marketingOptInYNFromOrderValue: any;

  private unSubscribe$ = new Subject<void>();

  constructor(
    private store: Store<CustomersPartialState>,
    private coreUiStore: Store<CoreUiPartialState>,
    private router: Router,
    private messageService: MessageService,
    private customersResponseService: CustomersResponseService
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.preSelectedCustomer?.currentValue) {
      this.selectedCustomerDetails = this.preSelectedCustomer;
    }
  }

  public ngOnInit(): void {
    this.store.dispatch(new LoadLookupLists());

    this.store
      .select(customersQuery.getSelectedCustomer)
      .pipe(takeUntil(this.unSubscribe$), delay(0))
      .subscribe((res) => {
        if (res && !this.preSelectedCustomer) {
          this.selectedCustomerDetails = res;
        }
      });

    this.lookupLists$ = this.store.select(customersQuery.getLookupLists).pipe(map(lookupLists => {
      this.stateList = lookupLists?.stateList?.filter(
        (x) => x.CountryCode === this.selectedCustomerDetails?.CountryCode
      )

      return lookupLists;
    }));
    this.updatingCustomerDetails$ = this.store.select(customersQuery.getUpdatingCustomer);
    this.isExternalUser$ = this.coreUiStore.select(isExternalUser);
    this.brandDefaults$ = this.coreUiStore.select(getBrandDefaults);

    this.marketingOptInYNFromOrderValue = this.marketingOptInYNFromOrder;
  }

  public ngOnDestroy(): void {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  public formDirtyHandler(dirty): void {
    this.formDirty = dirty;
    if (dirty && !this.isForNewOrder) {
      this.store.dispatch(hasUnsavedChanges({ unsavedChanges: true }));
      this.messageService.dispatchAction(hasUnsavedChanges({ unsavedChanges: true }));
    }
  }

  public updateRetailCustomer(): void {
    this.store.dispatch(new UpdateRetailCustomer(this.formValue));

    if (this.isForNewOrder) {
      this.customersResponseService.updateRetailCustomerSuccess$.pipe(take(1)).subscribe(() => {
        this.updatedCustomer.emit(this.selectedCustomerDetails.CustomerNumber);
      });
    }
  }

  public updateShipToCustomer(): void {
    this.store.dispatch(new UpdateShipToCustomer(this.formValue));
    if (this.isForNewOrder) {
      this.customersResponseService.updateShipToCustomerSuccess$.pipe(take(1)).subscribe(() => {
        this.updatedCustomer.emit(this.selectedCustomerDetails.CustomerNumber);
      });
    }
  }

  public cancel(): void {
    if (this.isForNewOrder) {
      this.updatedCustomer.emit(null);
      return;
    }
    this.router.navigate(['pos/customers/search']);
  }

  public getFilteredStateList(countryCode): void {
    this.store.dispatch(new SelectCountry(countryCode));
  }
}
