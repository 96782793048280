<pos-app-spinner [show]="loading"></pos-app-spinner>
<div class="row py-2">
  <div class="col-9">
    <h4>STATEMENT HISTORY</h4>
  </div>
  <div class="col-3 d-flex justify-content-end">
    <input
      type="text"
      class="form-control"
      [(ngModel)]="searchValue"
      placeholder="Quick filter..."
    />
  </div>
</div>
<div class="pb-5">
  <ag-grid-angular
    #agGrid
    class="ag-theme-balham datagrid"
    [rowData]="rowData"
    [context]="context"
    [frameworkComponents]="frameworkComponents"
    [gridOptions]="gridOptions"
    (gridReady)="onGridReady($event)"
    [domLayout]="'autoHeight'"
    [quickFilterText]="searchValue"
    [pagination]="true"
    [paginationPageSize]="10"
  >
  </ag-grid-angular>
</div>
