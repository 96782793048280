import { createReducer, on, Action } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as CoreUiActions from './core-ui.actions';
import { CoreUiEntity } from './core-ui.models';
import { Customer, CUSTOMER_TYPE, Colour, KEYS, UserProfile, BrandDefaults, OpenOrder } from '@pos-app/data';

export const COREUI_FEATURE_KEY = 'coreUi';

export interface State extends EntityState<CoreUiEntity> {
  selectedId?: string | number; // which CoreUi record has been selected
  hasUnsavedChanges?: boolean; // which CoreUi record has been selected
  loaded: boolean; // has the CoreUi list been loaded
  error?: string | null; // last none error (if any)
  loginDetails?: UserProfile;
  searchingCustomers: boolean;
  searchingBillToCustomers: boolean;
  customers: Customer[];
  billToCustomers: Customer[];
  hasMoreRecords: boolean;
  hasMoreBillToRecords: boolean;
  hasActiveOrder: boolean;
  activeOrder: { orderNumber: string; quickSaveAllowed: boolean };
  openOrder: OpenOrder;
  colourList: Colour[];
  selectedVehicle: any;
  cashDrawer: string;
  customerSearchValue: string;
  hasPendingPayments: boolean;
  brandDefaults: BrandDefaults;
  isIncompleteOrder: boolean;
  hasLoadedGiftCards: boolean;
  alertCount: number;
  isShowRoomView: boolean;
  isOrderChanged: boolean;
  parkedOrderListLength: number;
}

export interface CoreUiPartialState {
  readonly [COREUI_FEATURE_KEY]: State;
}

export const coreUiAdapter: EntityAdapter<CoreUiEntity> = createEntityAdapter<CoreUiEntity>();

export const initialState: State = coreUiAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  searchingCustomers: false,
  searchingBillToCustomers: false,
  customers: [],
  billToCustomers: [],
  hasMoreRecords: false,
  hasMoreBillToRecords: false,
  hasActiveOrder: false,
  activeOrder: null,
  openOrder: null,
  colourList: [],
  selectedVehicle: null,
  cashDrawer: localStorage.getItem('cashDrawer'),
  customerSearchValue: '',
  hasPendingPayments: false,
  brandDefaults: null,
  isIncompleteOrder: false,
  hasLoadedGiftCards: false,
  alertCount: 0,
  isShowRoomView: false,
  isOrderChanged: false,
  parkedOrderListLength: 0,
});

const coreUiReducer = createReducer(
  initialState,
  on(CoreUiActions.loadCoreUi, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(CoreUiActions.loadCoreUiSuccess, (state, { coreUi }) => coreUiAdapter.addAll(coreUi, { ...state, loaded: true })),
  on(CoreUiActions.loadCoreUiFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(CoreUiActions.hasUnsavedChanges, (state, { unsavedChanges }) => ({
    ...state,
    hasUnsavedChanges: unsavedChanges,
  })),

  on(CoreUiActions.clearUnsavedChanges, (state, { unsavedChanges }) => ({
    ...state,
    hasUnsavedChanges: unsavedChanges,
  })),

  on(CoreUiActions.loginSuccess, (state, { payload }) => ({
    ...state,
    loginDetails: payload,
  })),

  on(CoreUiActions.searchCustomers, (state, action) => {
    const searchingKey = action.customerType === CUSTOMER_TYPE.billTo.toLowerCase() ? 'searchingBillToCustomers' : 'searchingCustomers';
    const hasMoreRecordsKey = action.customerType === CUSTOMER_TYPE.billTo.toLowerCase() ? 'hasMoreBillToRecords' : 'hasMoreRecords';

    return {
      ...state,
      [searchingKey]: true,
      [hasMoreRecordsKey]: false,
    };
  }),
  on(CoreUiActions.searchCustomersFailed, (state) => {
    const searchingKey = state.searchingBillToCustomers ? 'searchingBillToCustomers' : 'searchingCustomers';
    return {
      ...state,
      [searchingKey]: false,
    };
  }),
  on(CoreUiActions.searchCustomersSuccess, (state, { result }) => {
    const searchingKey = state.searchingBillToCustomers ? 'searchingBillToCustomers' : 'searchingCustomers';
    const listKey = state.searchingBillToCustomers ? 'billToCustomers' : 'customers';
    const hasMoreRecordsKey = state.searchingBillToCustomers ? 'hasMoreBillToRecords' : 'hasMoreRecords';
    return {
      ...state,
      [listKey]: result.SearchResults,
      [searchingKey]: false,
      [hasMoreRecordsKey]: result.moreRecordsYN === 'Y',
    };
  }),
  on(CoreUiActions.clearSearchData, (state) => ({
    ...state,
    customers: [],
    billToCustomers: [],
    searchingCustomers: false,
    searchingBillToCustomers: false,
    hasMoreRecords: false,
    hasMoreBillToRecords: false,
  })),

  on(CoreUiActions.hasActiveOrder, (state, { orderNumber, quickSaveAllowed = false }) => ({
    ...state,
    hasActiveOrder: !!orderNumber,
    activeOrder: { orderNumber, quickSaveAllowed },
  })),

  on(CoreUiActions.setOpenOrder, (state, { openOrder, quickSaveAllowed }) => {
    return {
      ...state,
      openOrder: openOrder,
      hasActiveOrder: !!openOrder?.orderNumber,
      activeOrder: { orderNumber: openOrder?.orderNumber, quickSaveAllowed: quickSaveAllowed },
    };
  }),

  on(CoreUiActions.setSelectedVehicle, (state, { customerVehicleID, partsVehicleID, vehicleDescription, jdeVehicleID }) => ({
    ...state,
    selectedVehicle: {
      partsVehicleID,
      vehicleDescription,
      customerVehicleID,
      jdeVehicleID,
    },
  })),

  on(CoreUiActions.loadColourList, (state, { colourList }) => ({
    ...state,
    colourList,
  })),

  on(CoreUiActions.setCashDrawer, (state, { cashDrawer }) => {
    localStorage.setItem(KEYS.cashDrawer, cashDrawer);
    return {
      ...state,
      cashDrawer,
    };
  }),
  on(CoreUiActions.hasPendingPayments, (state, { hasPendingPayments }) => {
    return {
      ...state,
      hasPendingPayments,
    };
  }),
  on(CoreUiActions.loadBrandDefaultsSuccess, (state, { result }) => ({
    ...state,
    brandDefaults: result,
  })),
  on(CoreUiActions.hasIncompleteOrder, (state, { isIncompleteOrder }) => ({
    ...state,
    isIncompleteOrder,
  })),
  on(CoreUiActions.hasLoadedGiftCard, (state, { hasLoadedGiftCards }) => ({
    ...state,
    hasLoadedGiftCards,
  })),
  on(CoreUiActions.resetAllFlags, (state) => ({
    ...state,
    hasActiveOrder: false,
    activeOrder: null,
    hasLoadedGiftCards: false,
    hasPendingPayments: false,
    isIncompleteOrder: false,
  })),
  on(CoreUiActions.getOverdueAlertCounts, (state) => ({
    ...state,
    overdueTaskAlertCount: null,
  })),
  on(CoreUiActions.getOverdueAlertCountsSuccess, (state, { alertCount }) => ({
    ...state,
    alertCount,
  })),
  on(CoreUiActions.checkIsShowroomView, (state, { isShowRoomView }) => ({
    ...state,
    isShowRoomView,
  })),
  on(CoreUiActions.hasOrderChanged, (state, { isOrderChanged }) => ({
    ...state,
    isOrderChanged,
  })),
  on(CoreUiActions.parkedOrderListLength, (state, { parkedOrderListLength }) => ({
    ...state,
    parkedOrderListLength,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return coreUiReducer(state, action);
}
