import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '@pos-app/core-ui';
import { LoadedGiftCard } from '@pos-app/data';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-orders-gift-card',
  templateUrl: './orders-gift-card.component.html',
  styleUrls: ['./orders-gift-card.component.scss'],
})
export class OrdersGiftCardComponent implements OnInit, OnDestroy, OnChanges {
  @Input() _id: string;
  @Input() isPayment?: boolean;
  @Input() loadAmount: number;
  @Input() outstanding: number;
  @Input() loadedCard?: LoadedGiftCard;
  @Input() orderNumber?: string;
  @Input() showCancel: boolean

  @Output() paymentDetails = new EventEmitter<any>();

  giftCardForm: FormGroup;
  unSubcribe$ = new Subject<void>();
  isFetching: boolean;
  isLoading: boolean;

  get amountNeedToPaid(): number {
    const value = (this.loadAmount || 0) - (this.giftCardForm?.controls['amount']?.value || 0);

    return value > 0 ? value : 0;
  }

  constructor(private fb: FormBuilder, private apiService: ApiService, private toastr: ToastrService) {}

  ngOnInit() {
    this.giftCardForm = this.fb.group({
      cardNumber: [this.loadedCard?.giftcardNumber || '', Validators.required],
      amount: [this.loadAmount !== null ? Number(this.loadAmount) : ''],
      receipt: [
        {
          value: this.loadedCard?.receipt || '',
          disabled: true,
        },
      ],
      cardBalance: [''],
      response: [{ value: this.loadedCard?.responseFromVendor || '', disabled: true }],
      pin: [''],
    });

    if (this.isPayment) {
      this.giftCardForm.get('pin').setValidators([Validators.required]);
      this.giftCardForm.get('cardBalance').setValidators([Validators.required]);
    } else if (this.loadedCard) {
      this.giftCardForm.disable();
    }

    this.giftCardForm
      .get('cardNumber')
      .valueChanges.pipe(takeUntil(this.unSubcribe$))
      .subscribe(() => {
        this.giftCardForm.patchValue({ cardBalance: '' });
      });

    this.giftCardForm.valueChanges.pipe(takeUntil(this.unSubcribe$)).subscribe((x) => {
      if (this.outstanding < x.cardBalance) {
        this.giftCardForm.get('amount').setValidators([Validators.max(this.outstanding)]);
      } else {
        this.giftCardForm.get('amount').setValidators([Validators.max(x.cardBalance)]);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.outstanding && changes.outstanding.currentValue && this.giftCardForm) {
      this.giftCardForm.get('amount').setValidators([Validators.max(this.outstanding)]);
    }
  }

  ngOnDestroy() {
    this.unSubcribe$.next();
    this.unSubcribe$.complete();
  }

  fetchCardBalance() {
    this.isFetching = true;
    this.giftCardForm.patchValue({ cardBalance: '' });
    this.apiService
      .fetchCardBalance({
        cardID: this.giftCardForm.get('cardNumber').value,
      })
      .pipe(
        finalize(() => (this.isFetching = false)),
        takeUntil(this.unSubcribe$)
      )
      .subscribe(
        (res) => {
          this.isFetching = false;
          if (res) {
            this.giftCardForm.patchValue(
              {
                cardBalance: res.balance,
                response: res.message,
              },
              { emitEvent: false }
            );

            if (res.balance <= this.loadAmount) {
              this.giftCardForm.patchValue(
                {
                  amount: res.balance,
                },
                { emitEvent: false }
              );
            }
          }
        },
        (error) => {
          if (error?.ErrorStatus == 404) {
            this.giftCardForm.patchValue({
              cardBalance: null,
            });
          } else {
            this.toastr.error(error?.ErrorMessage);
          }
        }
      );
  }

  cardTransaction() {
    this.isLoading = true;
    const transaction = this.isPayment
      ? this.apiService.redeemCard({
          cardID: this.giftCardForm.get('cardNumber').value,
          paymentAmount: this.giftCardForm.get('amount').value,
          pin: this.giftCardForm.get('pin').value,
        })
      : this.apiService.givexActivation({
          cardID: this.giftCardForm.get('cardNumber').value,
          loadAmount: this.giftCardForm.get('amount').value,
          orderNumber: this.orderNumber,
        });

    transaction
      .pipe(
        finalize(() => (this.isLoading = false)),
        takeUntil(this.unSubcribe$)
      )
      .subscribe(
        (res) => {
          this.giftCardForm.patchValue({ cardBalance: '' });
          this.isLoading = false;
          if (res) {
            this.giftCardForm.patchValue({
              response: res.message,
              receipt: res.transactionID,
            });
            this.paymentDetails.emit({
              _id: this._id?.toString(),
              giftcardNumber: this.giftCardForm.get('cardNumber').value,
              reference: res.transactionID,
              responseFromVendor: res.message,
              amount: this.giftCardForm.get('amount').value,
            });
            if (!this.isPayment) {
              this.giftCardForm.get('cardNumber').disable();
            }
          }
        },
        (error) => {
          this.toastr.error(error?.ErrorMessage);
        }
      );
  }
}
