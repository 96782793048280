import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApiService, DialogService } from '@pos-app/core-ui';
import { Invoice } from '@pos-app/data';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { GetInvoiceListAction } from '../../+state/customers.actions';
import { CustomersPartialState } from '../../+state/customers.reducer';
import { customersQuery } from '../../+state/customers.selectors';
import { CustomersGridActionRendererComponent } from '../../components/customersGridActionRenderer/customersGridActionRender.component';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { getLocalLocaleDate } from 'libs/core-ui/src/lib/utils/utils-date';

@Component({
  selector: 'app-customers-invoice',
  templateUrl: './customers-invoice.component.html',
  styleUrls: ['./customers-invoice.component.scss'],
})
export class CustomersInvoiceComponent implements OnInit {
  columnDefs;
  gridOptions;
  rowData = [];
  searchValue = '';
  context;
  frameworkComponents;
  unSubscribe$ = new Subject<void>();
  loading: boolean;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private store: Store<CustomersPartialState>,
    private apiService: ApiService,
    private dialogService: DialogService,
    private formatter: NgbDateParserFormatter
  ) {
    this.frameworkComponents = {
      actionRenderer: CustomersGridActionRendererComponent,
    };
    this.context = { componentParent: this };
    this.gridOptions = {
      defaultColDef: {
        resizable: true,
        sortable: true,
        width: 150,
        cellClass: 'cell-class ag-right-aligned-cell',
        headerClass: 'header-class ag-right-aligned-header',
      },
      columnDefs: [
        {
          headerName: 'ACTION',
          width: 100,
          cellRenderer: 'actionRenderer',
        },
        {
          headerName: 'INVOICE NUMBER',
          width: 220,
          field: 'invoiceNumber',
        },
        {
          headerName: 'INVOICE TYPE',
          width: 200,
          field: 'invoiceType',
        },
        {
          headerName: 'DATE',
          field: 'date',
          valueFormatter: (params) => {
            return getLocalLocaleDate(params.value, this.locale);
          },
        },
        {
          headerName: 'REFERENCE',
          field: 'reference',
        },
        {
          headerName: 'GROSS AMOUNT',
          width: 180,
          field: 'grossAmount',
          valueFormatter: (params) => this.currencyFormatter(params),
        },
        {
          headerName: 'PAID AMOUNT',
          width: 150,
          field: 'paidAmount',
          valueFormatter: this.currencyFormatter,
        },
        {
          headerName: 'OPEN AMOUNT',
          width: 160,
          field: 'openAmount',
          valueFormatter: this.currencyFormatter,
        },
      ],
    };
  }

  ngOnInit() {
    this.store.dispatch(new GetInvoiceListAction());
    this.store
      .select(customersQuery.getCustomerInvoices)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((res) => (this.rowData = res));
  }

  onGridReady(params) {}

  currencyFormatter(params) {
    return params.value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  sendEmail(data: Invoice) {
    this.loading = true;
    this.apiService
      .emailInvoiceReprintExternal({
        invoiceNumber: data.invoiceNumber,
        invoiceType: data.invoiceType,
      })
      .pipe(
        finalize(() => (this.loading = false)),
        takeUntil(this.unSubscribe$)
      )
      .subscribe(() => {
        this.loading = false;
        this.dialogService.confirm('An email with the selected invoice has been sent', null, null, null, null, false);
      });
  }
}
