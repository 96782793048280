<!-- <div class="d-flex justify-content-end">
	<button type="button" class="btn btn-secondary"><mat-icon>settings</mat-icon></button>
</div> -->
<div #scheduler_here class="dhx_cal_container">
  <div class="dhx_cal_navline">
    <div class="dhx_cal_prev_button">&nbsp;</div>
    <div class="dhx_cal_next_button">&nbsp;</div>
    <div class="dhx_cal_today_button"></div>
    <div class="dhx_cal_date"></div>
    <div class="dhx_cal_tab" name="day_tab" style="width: 60px; margin: 4px">&nbsp;</div>
    <div class="dhx_cal_tab" name="week_tab" style="width: 60px; margin: 4px">&nbsp;</div>
    <div class="dhx_cal_tab" name="month_tab" style="width: 80px; margin: 4px">&nbsp;</div>
    <div class="dhx_cal_tab" name="timeline_tab" style="right: 380px"></div>
    <div class="dhx_cal_tab" data-tab="Settings"></div>
  </div>
  <div class="dhx_cal_header"></div>
  <div class="dhx_cal_data"></div>
</div>
