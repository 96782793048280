import { Component } from '@angular/core';
import { ICellRenderer } from 'ag-grid-community';
@Component({
  selector: 'app-action-cell',
  template: `
    <span><mat-icon (click)="sendEmail()" title="Send email">picture_as_pdf</mat-icon></span>
  `
})
export class CustomersGridActionRendererComponent
  implements ICellRenderer {
  public params: any;

  agInit(params: any): void {
    this.params = params;
  }

  sendEmail() {
    this.params.context.componentParent.sendEmail(this.params.node.data);
  }

  refresh(): boolean {
    return false;
  }
}
