<pos-app-spinner [show]="loading"></pos-app-spinner>
<ng-template #content>
  <div class="modal-header">
    <h3 class="arb-large-heading" id="modal-basic-title">DETAILED AVAILABILITY</h3>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-1">
        <label>ITEM</label>
      </div>
      <div class="col-2 text-uppercase">
        {{ availabilityItem.itemNumber }}
      </div>
      <div class="col-2">
        <label>ITEM DESCRIPTION</label>
      </div>
      <div class="col-7">
        {{ availabilityItem.itemDescription }}
      </div>
      <div class="col-1">
        <label>DISPATCH</label>
      </div>
      <div class="col-2">
        <ng-container *ngIf="dispatchBranch">
          {{ dispatchBranch.BranchDescription }}<span class="pr-2"></span>({{ dispatchBranch.BranchCode }})
        </ng-container>
      </div>
      <div class="col-2">
        <label>ON ORDER</label>
      </div>
      <div class="col-1">
        {{ availabilityItem.qtyOrdered }}
      </div>
      <div class="col-2">
        <label>ON BACKORDER</label>
      </div>
      <div class="col-4">
        {{ availabilityItem.qtyBackOrdered }}
      </div>
    </div>
    <div class="row" *ngIf="availabilityItem.paintableYN === 'Y'">
      <div class="col-1 my-auto">
        <label class="my-auto">LOT</label>
      </div>
      <div class="col-2">
        <ng-select (change)="refetchAvailability()" [(ngModel)]="paintCode" [clearable]="false">
          <ng-option value="UNPAINTED">UNPAINTED</ng-option>
          <ng-option
            [value]="availabilityItem.colourCode"
            *ngIf="availabilityItem.colourCode.trim() !== '' && availabilityItem.colourCode.indexOf('TBA') === -1"
            >{{ availabilityItem.colourCode }}
          </ng-option>
        </ng-select>
      </div>
    </div>
    <div *ngIf="crossDockList.length !== 0" class="mt-4">
      <label>CROSS DOCK TRANSFERS</label>
      <table class="table table-bordered table-sm">
        <thead>
          <tr class="table-header">
            <th>SENDING BRANCH</th>
            <th>STATUS</th>
            <th>TRANSFER NUMBER</th>
            <th>QTY ORDERED</th>
            <th>REFERENCE</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let crossDock of crossDockList">
            <td class="text-uppercase">
              {{ crossDock.sendingBatchOrOrderOrig }}
            </td>
            <td class="text-uppercase">
              {{ crossDock.status }}
            </td>
            <td class="text-uppercase">
              {{ crossDock.orderNumber }}
            </td>
            <td class="text-uppercase">
              {{ crossDock.qtyOrdered }}
            </td>
            <td class="text-uppercase">
              {{ crossDock.reference }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="my-4">
      <label>INVENTORY BY BRANCH</label>
      <ag-grid-angular
        class="ag-theme-balham datagrid"
        [rowData]="rowData"
        [gridOptions]="gridOptions"
        (gridReady)="onGridReady($event)"
        [domLayout]="'autoHeight'"
        [autoGroupColumnDef]="autoGroupColumnDef"
        [suppressAggFuncInHeader]="true"
        (firstDataRendered)="onDataRendered()"
        (rowDataChanged)="onDataRendered()"
      >
      </ag-grid-angular>
    </div>
    <div class="mb-4">
      <span class="d-flex mb-2">
        <label class="my-auto">INBOUND ORDERS FOR</label>
        <span class="px-2"></span>
        <ng-select [(ngModel)]="selectedBranch" class="w-25" (change)="selectBranch($event)">
          <ng-option *ngFor="let branch of branchList" [value]="branch.BranchCode">
            {{ branch.BranchDescription }}<span class="pr-2"></span>({{ branch.BranchCode }})
          </ng-option></ng-select
        >
      </span>
      <div *ngIf="inboundDetails.length === 0">NO INBOUND DETAILS FOR THE SELECTED BRANCH</div>
      <table class="table table-bordered table-sm" *ngIf="inboundDetails.length > 0">
        <thead>
          <tr class="table-header">
            <th>SENDING BRANCH OR BUYER</th>
            <th>STATUS</th>
            <th>ORDER NUMBER</th>
            <th>IN TRANSIT (Y/N)</th>
            <th>QTY ORDERED</th>
            <th>REFERENCE</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let inbound of inboundDetails">
            <td class="text-uppercase">
              {{ inbound.sendingBatchOrOrderOrig }}
            </td>
            <td class="text-uppercase">
              {{ inbound.status }}
            </td>
            <td class="text-uppercase">
              {{ inbound.orderNumber }}
            </td>
            <td class="text-uppercase">
              {{ inbound.inTransit }}
            </td>
            <td class="text-uppercase">
              {{ inbound.qtyOrdered }}
            </td>
            <td class="text-uppercase">
              {{ inbound.reference }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="d-flex justify-content-end">
      <button class="btn btn-cancel" (click)="cancel()">RETURN TO ORDER</button>
    </div>
  </div>
</ng-template>
