<pos-app-spinner [show]="loading"></pos-app-spinner>
<ng-template #content>
  <div class="modal-header">
    <h3 class="arb-large-heading" id="modal-basic-title">
      SELECT LOCATION TO LOGIN
    </h3>
  </div>
  <div class="modal-body">
    <ng-select (change)="loginToShow($event)">
      <ng-option
        value=""
        *ngIf="
          userProfile.Role === USER_ROLE.internal ||
          userProfile.Role === USER_ROLE.external
        "
        >{{
          userProfile.Role === USER_ROLE.internal
            ? userProfile.DefaultBranchName.toUpperCase() + ' (RETAIL STORE)'
            : userProfile.CustomerName.toUpperCase()
        }}</ng-option
      >
      <ng-option
        *ngFor="let show of userProfile.ShowCodes"
        [value]="show.showCode"
        >{{ show.description.toUpperCase() }}</ng-option
      >
    </ng-select>
  </div>
</ng-template>
