import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ROUTE_URL } from '@pos-app/data';
import { AuthService } from './auth.service';
import { MessageService } from './message.service';
import {
  hasIncompleteOrder,
  navigateToUrl,
  resetAllFlags,
} from '../+state/core-ui/core-ui.actions';
import { SecureCatalogueService } from './secure-catelogue.service';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  constructor(
    private toastrService: ToastrService,
    private router: Router,
    private authService: AuthService,
    private messageService: MessageService,
    private secureCatalogueService: SecureCatalogueService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('jde-token');
    if (token) {
      let headers = req.headers;
      headers = headers.append('tokentouse', `${token}`);
      req = req.clone({
        headers,
      });
    }

    return next.handle(req).pipe(
      map((event) => {
        if (event instanceof HttpResponse && Object.prototype.hasOwnProperty.call(event.body, 'result') ) {
          event = event.clone({ body: event.body.result });
        }
        return event;
      }),
      catchError((err) => {
        if (err.status === 401) {
          this.authService.clearToken();
          this.messageService.dispatchAction(
            hasIncompleteOrder({ isIncompleteOrder: false })
          );
          this.toastrService.error(
            err.error && err.error.ErrorMessage
              ? err.error.ErrorMessage
              : 'Unauthorised'
          );
          this.router.navigate([ROUTE_URL.login]);
          return throwError(null);
        }
        if (err.error) {
          if (err.error.ErrorMessage) {
            if (
              err.error.ErrorMessage === 'No default vehicle found for customer'
            ) {
              // SUPPRESS errors
              return throwError(null);
            } else if (
              err.error.ErrorMessage ===
              'Order not found for the user+brand combination'
            ) {
              this.toastrService.error(err.error.ErrorMessage);
              this.messageService.dispatchAction(resetAllFlags());
              setTimeout(() => {
                this.messageService.dispatchAction(
                  navigateToUrl({
                    url: this.secureCatalogueService.getLandingPage(),
                  })
                );
              }, 1000);
            }
            return throwError(err.error);
          }
        }
        return throwError(err);
      })
    );
  }
}
