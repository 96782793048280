<div *ngIf="hasServiceAttachmentChanged || hasServiceDetailsChanged" class="d-flex justify-content-center row" style="height: 28px">
  <div class="col">
    <h6 class="arb-large-heading text-center text-danger p-0 m-0">Service History has unsaved changes, please save or cancel it.</h6>
  </div>
</div>
<form [formGroup]="camperTrailerDetailsForm" (keydown.enter)="$event.preventDefault()">
  <div class="row">
    <h6 class="arb-large-heading">CAMPER TRAILER INFO</h6>
  </div>
  <div class="row" style="height: 28px">
    <div class="col">
      <span class="text-danger p-0 m-0" *ngIf="hasFormValueChanged || hasTrailerAttachmentChanged">
        Trailer Info has unsaved changes, please save or cancel.
      </span>
    </div>
  </div>
  <div class="row">
    <div class="col-2 header-label my-auto">
      <label class="d-flex">
        VIN
        <mat-icon *ngIf="customerNumber" class="px-2 text-danger" title="Diposed the record" (click)="removeTrailer()"> close </mat-icon>
        <mat-icon *ngIf="!!hasMultiTrailers" class="px-2" (click)="invokeSwitchTrailer()" title="Switch the camper trailer">compare_arrows</mat-icon>
      </label>
    </div>
    <div class="col-4 d-flex p-2">
      <input formControlName="vin" class="form-control overflow-hidden no-decoration" />
    </div>

    <div class="col-2 header-label my-auto">
      <label class="d-flex">PURCHASE DATE </label>
    </div>
    <div class="col-3 d-flex p-2">
      <input ngbDatepicker formControlName="purchaseDate" class="form-control overflow-hidden" #d1="ngbDatepicker" />
      <button class="btn btn-secondary calendar text-white px-2 py-3" (click)="d1.toggle()" type="button"></button>
    </div>
  </div>
  <div class="row">
    <div class="col-2 header-label my-auto">
      <label class="d-flex">INVOICE/RECEIPT NO </label>
    </div>
    <div class="col-4 d-flex p-2">
      <input formControlName="invoiceNumber" class="form-control overflow-hidden" />
    </div>

    <div class="col-2 header-label my-auto">
      <label class="d-flex">REGO NUMBER </label>
    </div>
    <div class="col-3 d-flex p-2">
      <input formControlName="regoNumber" class="form-control overflow-hidden" />
    </div>
  </div>
  <div class="row">
    <div class="col-2 header-label my-auto">
      <label class="d-flex">SELLING STORE </label>
    </div>
    <div class="col-4 d-flex p-2">
      <ng-select formControlName="sellingStore" style="width: 100%">
        <ng-option *ngFor="let store of storeList" [value]="store.customerNumber"> {{ store.customerNumber }} ({{ store.customerName }}) </ng-option>
      </ng-select>
    </div>

    <div class="col-2 header-label my-auto">
      <label class="d-flex">SALESPERSON </label>
    </div>
    <div class="col-3 d-flex p-2">
      <input formControlName="salesPerson" class="form-control overflow-hidden" />
    </div>
  </div>
  <div class="row">
    <div class="col-2 header-label my-auto">
      <label class="d-flex">PREFFERED STORE</label>
    </div>
    <div class="col-4 d-flex p-2">
      <ng-select formControlName="preferredARBStore" style="width: 100%">
        <ng-option *ngFor="let store of storeList" [value]="store.customerNumber"> {{ store.customerNumber }} ({{ store.customerName }}) </ng-option>
      </ng-select>
    </div>

    <div class="col-2 header-label my-auto">
      <label class="d-flex" for="serviceRemiderCheckbox">SERVICE REMINDERS </label>
    </div>
    <div class="col-2 d-flex p-2 align-middle">
      <input class="form-check-input" type="checkbox" formControlName="serviceReminders" id="serviceRemiderCheckbox" value="" />
    </div>
  </div>
  <div class="row">
    <div class="col-2 header-label my-auto">
      <label class="d-flex">STATUS </label>
    </div>
    <div class="col-2 d-flex p-2">
      <ng-select formControlName="registrationStatusCode" style="width: 100%">
        <ng-option *ngFor="let regoStatus of regoStatusList" [value]="regoStatus.RegoCode">
          {{ regoStatus.RegoCode }} ({{ regoStatus.RegoDescription }})
        </ng-option>
      </ng-select>
    </div>
  </div>
  <div class="row">
    <div class="col-2 header-label my-auto">
      <label class="d-flex">OPTIONS FITTED</label>
    </div>
    <div class="col-6 p-2">
      <textarea formControlName="optionsFitted" class="form-control" style="resize: none" rows="3"></textarea>
    </div>
  </div>
  <div class="row">
    <div class="col-2 header-label my-auto">
      <label class="d-flex">ATTACHED FILES</label>
    </div>
    <div class="col-6 my-auto">
      <button
        [disabled]="hasServiceAttachmentChanged || hasServiceDetailsChanged"
        (click)="invokeOpenTrailerAttachments()"
        type="button"
        class="btn btn-secondary"
      >
        <small>SELECTED FILES</small>
        <span class="badge badge-light ml-1">{{ trailerAttachmentsLength }}</span>
      </button>
      <span class="px-2"><strong>FILES MUST BE LESS THAN 15MEG</strong></span>
    </div>
    <div class="col d-flex float-end justify-content-end">
      <button [disabled]="disableButton()" (click)="invokeCancelForm()" class="btn btn-cancel mx-1 px-2">CANCEL</button>
      <button [disabled]="disableButton()" (click)="invokeUpdateTrailerInfo()" class="btn text-white mx-1 px-2">SAVE</button>
    </div>
  </div>
</form>
