import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
@Component({
  selector: 'app-order-grid-item-search',
  templateUrl: './orderGridItemSearch.component.html',
  styleUrls: ['./orderGridItemSearch.component.scss'],
})
export class OrderGridItemSearchComponent implements OnInit {
  @Input() searchStr: string;

  @Output() addingItem = new EventEmitter<any>();
  @Output() closeActiveModal = new EventEmitter<boolean>();

  itemCode = '';
  quantity = 1;

  constructor() {}

  ngOnInit() {}

  addItem() {
    this.addingItem.emit({ itemCode: this.itemCode?.trim(), quantity: this.quantity });
  }

  closeModal() {
    this.closeActiveModal.emit(true);
  }
}
