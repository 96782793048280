import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Branch } from '@pos-app/data';
import { OverrideData } from '../../+state/orders.models';

@Component({
  selector: 'app-orders-override-branch',
  templateUrl: './orders-override-branch.component.html',
  styleUrls: ['./orders-override-branch.component.scss']
})
export class OrdersOverrideBranchComponent implements OnInit {
  @Input() shipTo: string;
  @Input() branch: string;
  @Input() isConvertToOrder: boolean;
  @Input() branchList: Branch[];
  @Input() overridenBranch: string;
  @Output() selectOverridenBranch = new EventEmitter<OverrideData>();

  constructor() {}

  ngOnInit() {}

  onSelectBranch() {
    this.selectOverridenBranch.emit({
      overrideBranch: this.overridenBranch,
      overrideSalesPerson: null,
      overrideShipTo: null,
      nonCorpStoreSaleYN: null
    });
  }

  onCancel() {
    this.selectOverridenBranch.emit(null);
  }
}
