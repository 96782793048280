export const DIALOG_MESSAGE = {
  trailerSwtich: `Are you sure! you want to switch the Trailer? Either Select EXIT to save the changes or Select SWITCH to discard changes and switch to next Trailer`,
  serviceDropDown: `There are unsaved changes, please either save the changes or cancel them`,
  trailerOwner: 'Are you sure! you want to assign Trailer to this customer',
};

export const DIALOG_TITLE = {
  unsavedChanges: 'WARNING: UNSAVED CHANGES',
  trailerOwnsership: 'WARNING: OWNERSHIP OF TRAILER',
};

export const DIALOG_BUTTON_TEXT = {
  trailerSwitchButton: 'SWITCH',
  default: 'OKAY',
  confirm: 'CONFIRM',
  trailerOwnership: 'ASSIGN OWNERSHIP',
};

export const CAMPER_TRAILER_BASE_ROUTE = 'pos/camper-trailer/';
