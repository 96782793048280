import { Component, OnInit, OnDestroy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { OrdersPartialState } from '../../+state/orders.reducer';
import { Store } from '@ngrx/store';
import { LoadFitlerLists, SearchOrderSummary, ClearData, FetchJdeOrder, LoadParkedOrdersList } from '../../+state/orders.actions';
import { OrderFilterLists, OrderSummary, CustomerDetails, ORDER_TYPE } from '@pos-app/data';
import { Observable, Subject } from 'rxjs';
import { ordersQuery } from '../../+state/orders.selectors';
import {
  clearSearchData,
  CoreUiPartialState,
  isExternalUser,
  getSecurityFunctions,
  ApiService,
  DialogService,
} from '@pos-app/core-ui';
import { ActivatedRoute } from '@angular/router';
import { finalize, takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { CustomersPartialState } from '../../../customers/+state/customers.reducer';
import { GetUserStateAction } from '../../../customers/+state/customers.actions';

@Component({
  selector: 'app-orders-base',
  templateUrl: './orders-base.component.html',
  styleUrls: ['./orders-base.component.scss'],
})
export class OrdersBaseComponent implements OnInit, OnDestroy, AfterViewInit {
  public orderFilterLists$: Observable<OrderFilterLists>;
  public staticFilterValues = null;
  public dynamicFilterValues = null;
  public dynamicFilterConfig$: Observable<any>;
  public isFormValid = false;
  public orderSummary$: Observable<OrderSummary[]>;
  public selectedCustomerNumber: string;
  public selectedCustomerDetails$: Observable<CustomerDetails>;
  public isExternalUser$: Observable<boolean>;
  public securityFunctions$: Observable<string[]>;
  public orderType: string;
  public isBusy = false;
  public loading = false;

  private unSubscribe$ = new Subject<void>();

  constructor(
    private orderStore: Store<OrdersPartialState>,
    private customerStore: Store<CustomersPartialState>,
    private route: ActivatedRoute,
    private coreUiStore: Store<CoreUiPartialState>,
    private cdref: ChangeDetectorRef,
    private toastr: ToastrService,
    private apiService: ApiService,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.dispatchParkedOrdersList();
    this.dispatchGetUserState();
    this.coreUiStore.dispatch(clearSearchData());
    this.isExternalUser$ = this.coreUiStore.select(isExternalUser);
    this.securityFunctions$ = this.coreUiStore.select(getSecurityFunctions);
    this.orderStore.dispatch(new ClearData());
    this.orderStore.dispatch(new LoadFitlerLists());
    this.orderFilterLists$ = this.orderStore.select(ordersQuery.getOrderFilterLists);
    this.dynamicFilterConfig$ = this.orderStore.select(ordersQuery.getDynamicFilterConfig);
    this.orderSummary$ = this.orderStore.select(ordersQuery.getOrderSummary);
    // Loading spinner
    this.orderStore
      .select(ordersQuery.getIsFetchingJdeOrder)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((searching) => {
        setTimeout(() => {
          this.isBusy = searching;
        }, 0);
      });
    this.orderStore
      .select(ordersQuery.getloadingOrderFilterLists)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((searching) => {
        setTimeout(() => {
          this.isBusy = searching;
        }, 0);
      });
    this.orderStore
      .select(ordersQuery.getSearchingOrderSummary)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((searching) => {
        setTimeout(() => {
          this.isBusy = searching;
        }, 0);
      });
  }

  ngAfterViewInit() {
    this.selectedCustomerNumber = this.route.snapshot.params.customerNumber;
    this.orderType = this.route.snapshot.queryParams.type;
    if (this.selectedCustomerNumber) {
      this.cdref.detectChanges();
    }
    if (this.orderType) {
      this.searchOrders();
    }
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  searchOrders() {
    let data = {
      ...this.staticFilterValues,
      invDateFrom: this.staticFilterValues.orderType === ORDER_TYPE.invoicedOrder ? this.staticFilterValues.invDateFrom : '',
      invDateTo: this.staticFilterValues.orderType === ORDER_TYPE.invoicedOrder ? this.staticFilterValues.invDateTo : '',
    };
    data['invoicedOrders10'] = data['invoicedOrders10'] ? '1' : '0';
    data['creditOrders10'] = data['creditOrders10'] ? '1' : '0';
    if (this.dynamicFilterValues) {
      Object.keys(this.dynamicFilterValues)
        .filter((key) => key.indexOf('DateRange') > 0)
        .forEach((key) => {
          data = {
            ...data,
            ...this.dynamicFilterValues[key],
          };
        });
      data = {
        ...data,
        ...this.dynamicFilterValues,
      };
    }

    this.orderStore.dispatch(new SearchOrderSummary(data));
  }

  staticfilteredValueChangeHandler(value) {
    this.staticFilterValues = value;
    setTimeout(() => {
      this.isFormValid = this.staticFilterValues.customerNumber;
    }, 0);
  }

  fetchOrderFromJDE(data) {
    this.orderStore.dispatch(
      new FetchJdeOrder({
        orderNumber: data.OrderNumber,
        orderType: data.OrderType,
      })
    );
  }

  sendEmail(data: OrderSummary) {
    this.loading = true;
    this.apiService
      .emailInvoiceReprintExternal({
        invoiceNumber: data.InvoiceNumber,
        invoiceType: data.DocumentType,
      })
      .pipe(
        finalize(() => (this.loading = false)),
        takeUntil(this.unSubscribe$)
      )
      .subscribe(() => {
        this.loading = false;
        this.dialogService.confirm('An email with the selected invoice has been sent', null, null, null, null, false);
      });
  }

  dispatchParkedOrdersList() {
    this.orderStore.dispatch(new LoadParkedOrdersList());
  }

  dispatchGetUserState() {
    this.customerStore.dispatch(new GetUserStateAction());
  }
}
