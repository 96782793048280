import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import {
  ApiService,
  CoreUiPartialState,
  getDefaultCustomer,
  getOrderTypes,
} from '@pos-app/core-ui';
import { CustomerDetails, ListTasksNotes, OrderType } from '@pos-app/data';
import { Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { ListTasksNotesAction } from '../../+state/customers.actions';
import { CustomersPartialState } from '../../+state/customers.reducer';
import { customersQuery } from '../../+state/customers.selectors';
import { BRANCH_CALENDAR_FILTER } from '@pos-app/data';
import moment from 'moment';
import { CustomersNotesTaskTimelineComponent } from '../../components/customers-notes-task-timeline/customers-notes-task-timeline.component';
import { OrdersPartialState } from '../../../orders/+state/orders.reducer';
import { LoadParkedOrdersList } from '../../../orders/+state/orders.actions';
import { formatDateForInternalFromNgbDate } from 'libs/core-ui/src/lib/utils/utils-date';

@Component({
  selector: 'app-customers-task-calendar',
  templateUrl: './customers-task-calendar.component.html',
  styleUrls: ['./customers-task-calendar.component.scss'],
})
export class CustomersTaskCalendarComponent implements OnInit {
  @ViewChild(CustomersNotesTaskTimelineComponent)
  noteTaskTimelineChild: CustomersNotesTaskTimelineComponent;

  public loadingTasksNotesList: boolean;
  public selectedCustomer: CustomerDetails;
  public orderTypes: OrderType[];
  private unSubscribe$ = new Subject<void>();
  routedParamCustomerNumber: number = this.route.snapshot.params.id;
  rowData: any = [];
  defaultCustomer: any;
  calendarForm: FormGroup;
  fromDate: NgbDate;
  toDate: NgbDate;
  BRANCH_CALENDAR_FILTER = BRANCH_CALENDAR_FILTER;
  taskCalendarFilter: string = BRANCH_CALENDAR_FILTER.MyCalendar;
  isShowCompletedTasks: boolean = false;
  listTaskNotesFilter: ListTasksNotes;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private coreUiStore: Store<CoreUiPartialState>,
    private store: Store<CustomersPartialState>,
    private orderStore: Store<OrdersPartialState>,
    private calendar: NgbCalendar
  ) {
    this.initListTaskNotesFilter();
    this.toDate = null;
    this.fromDate = this.calendar.getPrev(this.calendar.getToday(), 'm', 3);
    this.listTaskNotesFilter.fromDate = formatDateForInternalFromNgbDate(
      this.fromDate
    );
    this.listTaskNotesFilter.assignee =
      this.routedParamCustomerNumber.toString();
    this.calendarForm = this.formBuilder.group({
      calendarFilter: [BRANCH_CALENDAR_FILTER.MyCalendar],
      fromDate: [''],
      toDate: [''],
      showCompletedTasks: [''],
    });
  }

  ngOnInit() {
    this.store
      .select(customersQuery.getTasksNotesList)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((tasksNotes) => {
        this.rowData = this.sortTaskNotesByAscendingDate(tasksNotes);
      });
    this.apiService
      .getCustomerDetails({
        customerNumberSearch: this.routedParamCustomerNumber,
      })
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((customerDetails) => {
        this.selectedCustomer = customerDetails.SearchResults[0];
      });
    this.coreUiStore
      .select(getOrderTypes)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((orderTypes) => {
        this.orderTypes = orderTypes;
      });
    this.coreUiStore
      .select(getDefaultCustomer)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((defaultCustomer) => {
        this.defaultCustomer = defaultCustomer;
      });
    this.store
      .select(customersQuery.getLoadingTasksNotesList)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((loadingTasksNotes) => {
        this.loadingTasksNotesList = loadingTasksNotes;
      });
    this.calendarForm.valueChanges
      .pipe(
        takeUntil(this.unSubscribe$),
        tap((calendarFormValue) => {
          if (!!calendarFormValue.showCompletedTasks) {
            this.isShowCompletedTasks = true;
          } else {
            this.isShowCompletedTasks = false;
          }
        })
      )
      .subscribe((calendarFormValue) => {
        this.initListTaskNotesFilter();
        this.taskCalendarFilter = calendarFormValue;
        this.listTaskNotesFilter = this.listTaskNotesMap(calendarFormValue);

        this.store.dispatch(
          new ListTasksNotesAction({
            type: 'T',
            ownerIdentifier:
              calendarFormValue.calendarFilter ===
              BRANCH_CALENDAR_FILTER.BranchCalendar
                ? this.defaultCustomer.defaultBranch.padStart(12, ' ')
                : '',
            customerNumber: '',
            assignee:
              calendarFormValue.calendarFilter ===
              BRANCH_CALENDAR_FILTER.MyCalendar
                ? this.routedParamCustomerNumber
                : '',
            completeYN:
              calendarFormValue.showCompletedTasks === true ? 'Y' : 'N',
            fromDate: calendarFormValue.fromDate
              ? calendarFormValue.fromDate
              : moment().format('DD/MM/YYYY'),
            toDate: calendarFormValue.toDate,
          })
        );
        if (calendarFormValue.showCompletedTasks) {
          this.noteTaskTimelineChild.toggleShowDoneColumn();
        }
      });

    this.dispatchParkedOrdersList();
  }

  onSelectDate(date) {
    this.calendarForm.patchValue({
      fromDate: date.fromDate !== '01/01/1970' ? date.fromDate : '',
      toDate: date.toDate !== '01/01/1970' ? date.toDate : '',
    });
  }

  dispatchParkedOrdersList() {
    this.orderStore.dispatch(new LoadParkedOrdersList());
  }

  sortTaskNotesByAscendingDate(taskNotes: any) {
    let sortedTaskNotes = [...taskNotes];

    return sortedTaskNotes.sort((a, b) => {
      a = a.endDate.split('/').reverse().join('');
      b = b.endDate.split('/').reverse().join('');
      return a > b ? 1 : a < b ? -1 : 0;
    });
  }

  initListTaskNotesFilter() {
    this.listTaskNotesFilter = {
      type: '',
      ownerIdentifier: '',
      customerNumber: '',
      assignee: '',
      completeYN: '',
      fromDate: '',
      toDate: '',
    };
  }

  listTaskNotesMap(calendarFormValue: any): ListTasksNotes {
    return {
      type: 'T',
      ownerIdentifier:
        calendarFormValue.calendarFilter ===
        BRANCH_CALENDAR_FILTER.BranchCalendar
          ? this.defaultCustomer.defaultBranch.padStart(12, ' ')
          : '',
      customerNumber: '',
      assignee:
        calendarFormValue.calendarFilter === BRANCH_CALENDAR_FILTER.MyCalendar
          ? this.routedParamCustomerNumber.toString()
          : '',
      completeYN: calendarFormValue.showCompletedTasks === true ? 'Y' : 'N',
      fromDate: calendarFormValue.fromDate
        ? calendarFormValue.fromDate
        : moment().format('DD/MM/YYYY'),
      toDate: calendarFormValue.toDate,
    };
  }
}
