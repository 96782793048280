import { LazyLoadEvent } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';

export class PrimengTableHelper {
  public isResponsive = true;
  public resizableColumns: false;
  public totalRecordsCount = 0;
  public records: any[];
  public isLoading = false;
  public recordsCountPerPage = 10;

  public readonly recordsCountPerPageOptions = [10, 20, 30, 50];

  public showLoadingIndicator(): void {
    setTimeout(() => {
      this.isLoading = true;
    }, 0);
  }

  public hideLoadingIndicator(): void {
    setTimeout(() => {
      this.isLoading = false;
    }, 0);
  }

  public getSorting(table: Table): string {
    let sorting;

    if (table.sortField) {
      sorting = table.sortField;
      if (table.sortOrder === 1) {
        sorting += ' ASC';
      } else if (table.sortOrder === -1) {
        sorting += ' DESC';
      }
    }

    return sorting;
  }

  public getMaxResultCount(paginator: Paginator): number {
    return paginator?.rows || 10;
  }

  public getSkipCount(paginator: Paginator): number {
    return paginator?.first || 0;
  }

  public shouldResetPaging(event: LazyLoadEvent): boolean {
    if (!event /*|| event.sortField*/) {
      // if you want to reset after sorting, comment out parameter
      return true;
    }

    return false;
  }
}
