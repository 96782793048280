<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="col">
        <h5>GLOBAL FILTERS</h5>
      </div>
      <div class="col col-form-value d-flex justify-content-end mb-2">
        <button class="btn btn-cancel" [routerLink]="getCustomersLink()">RETURN TO CUSTOMER DETAILS</button>
      </div>
    </div>
    <form [formGroup]="staticFilterForm">
      <div class="row">
        <div class="col-md-4">
          <div class="row">
            <label class="col-sm-3 col-form-label font-weight-bold">CUSTOMER</label>
            <div class="col-sm-9 col-form-value">
              <pos-app-lib-customer-search
                (selectNewCustomer)="selectCustomer($event)"
                [forOrderSearch]="true"
                [selectedCustomerName]="selectedCustomerNumber"
                [disabled]="selectedCustomerNumber"
              >
              </pos-app-lib-customer-search>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="row">
            <label class="col-sm-3 col-form-label font-weight-bold">BRANCH</label>
            <div class="col-sm-9 col-form-value">
              <ng-select formControlName="branch" [selectOnTab]="true">
                <ng-option *ngFor="let branch of branchList" [value]="branch.BranchCode">
                  {{ branch.BranchDescription }}
                  <span class="px-2" *ngIf="branch.BranchCode">({{ branch.BranchCode }})</span>
                </ng-option>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="row">
            <label class="col-sm-3 col-form-label font-weight-bold">ORDER DATE</label>
            <div class="col-sm-9 col-form-value d-flex justify-content-end">
              <pos-app-date-picker
                (selectedDateRange)="selectOrderDate($event)"
                [preSelectedCustomer]="preSelectedCustomer"
                [fromDate]="orderFromDate"
                [toDate]="toDate"
                [disabled]="disableOrderDate"
              ></pos-app-date-picker>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <label class="col-1 col-form-label font-weight-bold">ORDER TYPE</label>
        <div class="col-8 col-form-value my-auto d-flex align-center column-gap">
          <div>
            <input type="radio" formControlName="orderType" [value]="ORDER_TYPE.saleOrder" class="mr-1" />
            <span class="title">OPEN ORDERS</span>
          </div>
          <div>
            <input type="radio" formControlName="orderType" [value]="ORDER_TYPE.quoteOrder" class="mr-1" />
            <span class="title">QUOTES</span>
          </div>
          <div>
            <input type="radio" formControlName="orderType" [value]="ORDER_TYPE.invoicedOrder" class="mr-1" />
            <span class="title">INVOICED ORDERS</span>
          </div>
        </div>
      </div>
      <div
        class="row pt-1"
        *ngIf="
          staticFilterForm.controls.orderType.value === ORDER_TYPE.invoicedOrder || staticFilterForm.controls.orderType.value === ORDER_TYPE.saleOrder
        "
      >
        <div class="col-4 d-flex">
          <label class="pr-2 col-form-label">INVOICED DATE</label>
          <div class="col-form-value">
            <pos-app-date-picker [fromDate]="invoicedFromDate" [toDate]="toDate" (selectedDateRange)="selectInvoicedDate($event)">
            </pos-app-date-picker>
          </div>
        </div>
        <div class="col-4 my-auto d-flex">
          <div class="my-auto">
            <input type="checkbox" class="mr-1" formControlName="invoicedOrders10" /><span class="px-2">SALES ORDERS</span>
            <span class="px-2"></span>
            <input type="checkbox" class="mr-1" formControlName="creditOrders10" /><span class="px-2">CREDIT ORDERS</span>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
