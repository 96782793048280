<pos-app-spinner [show]="isCreatingCustomer"></pos-app-spinner>
<div *ngIf="!checkingDuplicate">
  <app-customers-ship-to-form
    (formChanged)="formValue = $event"
    (formIsValid)="formValid = $event"
    (formIsDirty)="formDirtyHandler($event)"
    [soldToCustomer]="(selectedCustomerDetails$ | async)"
    [lookupLists]="lookupLists$ | async"
    (submitByEnter)="formValid && checkDuplicate()"
    (cancelled)="cancel()"
    (selectedCountry)="getFilteredStateList($event)"
    [filteredStateList]="filteredStateList"
  >
  </app-customers-ship-to-form>

  <div class="row pt-3 pb-5 mx-1 float-right">
    <button class="btn text-white mr-3" [disabled]="!formValid" (click)="checkDuplicate()">
      SAVE
    </button>
    <button class="btn btn-cancel" (click)="cancel()">
      CANCEL
    </button>
  </div>
</div>

<app-customers-duplicate
  [setNewCustomerDetails]="newCustomerDetails"
  [setDuplicateCustomers]="duplicateCustomers$ | async"
  (customerChoosen)="customerChoosen($event)"
  (cancelled)="cancel()"
>
</app-customers-duplicate>
