import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CamperCustomerDetails } from 'libs/data/src/lib/models/camperTrailer';

@Component({
  selector: 'app-camper-customer-details',
  templateUrl: './camper-customer-details.component.html',
  styleUrls: ['./camper-customer-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CamperCustomerDetailsComponent implements OnInit, OnChanges {
  @Input() camperCustomerDetails: CamperCustomerDetails;
  @Input() disableGenerateReport: boolean = true;

  @Output() generateReport = new EventEmitter<any>();
  @Output() addNewCustomer = new EventEmitter<any>();
  @Output() editCustomer = new EventEmitter<any>();
  @Output() selectNewCustomer = new EventEmitter<any>();

  camperCustomerDetailsForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.camperCustomerDetailsForm = this.fb.group({
      customerName: [{ value: '', disabled: true }],
      customerNumber: [''],
      customerPhone: [''],
      customerEmail: [''],
    });
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.camperCustomerDetails && changes.camperCustomerDetails.currentValue && this.camperCustomerDetailsForm) {
      this.patchValueForCamperCustomerDetailsForm(this.camperCustomerDetails);
    }
  }

  patchValueForCamperCustomerDetailsForm(camperCustomerDetails: CamperCustomerDetails) {
    this.camperCustomerDetailsForm.patchValue({
      customerNumber: camperCustomerDetails.customerNumber,
      customerName: camperCustomerDetails.customerName,
      customerPhone: camperCustomerDetails.customerPhone,
      customerEmail: camperCustomerDetails.customerEmail,
    });
  }

  invokeAddNewCustomer() {
    this.addNewCustomer.emit();
  }

  invokeSelectNewCustomer() {
    this.selectNewCustomer.emit();
  }

  invokeEditCustomer() {
    this.editCustomer.emit();
  }

  invokeGenerateReport() {
    this.generateReport.emit();
  }
}
