import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Observable, of, combineLatest } from 'rxjs';
import { takeUntil, switchMap, tap, finalize, take, withLatestFrom, map, filter } from 'rxjs/operators';
import { AuthService, DialogService, ApiService, DrawerService, ConfigService, UtilService, ElectronService, EventService } from '../../services';
import { MessageService } from '../../services/message.service';
import { Store, select } from '@ngrx/store';
import { CoreUiPartialState } from '../../+state/core-ui/core-ui.reducer';
import {
  getUserId,
  getSecurityFunctions,
  getUserName,
  getHasUnsavedChanges,
  getBranch,
  getHasActiveOrder,
  getIsLoggedIn,
  getDefaultCustomer,
  getCashDrawer,
  getUserRole,
  getHasPendingPayments,
  getBrandDefaults,
  getIsIncompleteOrder,
  getHasLoadedGiftCards,
  isExternalUser,
  getOverdueAlerts,
  getHasOrderChanged,
  getParkedOrderListLength,
  getOrderTypes,
  getActiveOrder,
  getOpenOrder,
} from '../../+state/core-ui/core-ui.selectors';
import { Branch, ORDER_TYPE, SECURITY_FUNCTION, USER_ROLE, BrandDefaults, KEYS, UserProfile, RegisterHost, OpenOrder } from '@pos-app/data';
import {
  hasUnsavedChanges,
  hasPendingPayments,
  hasLoadedGiftCard,
  resetAllFlags,
  checkIsShowroomView,
  getOverdueAlertCounts,
} from '../../+state/core-ui/core-ui.actions';
import { formatDate } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SecureCatalogueService } from '../../services/secure-catelogue.service';
import { ToastrService } from 'ngx-toastr';
import { ProductSpotlightComponent } from '../product-spotlight/product-spotlight.component';
import { SaleService } from '../../services/sale.service';
import { OOESessionService } from '../../services/ooe-session.service';
import { LayoutService } from '../../services/layout.service';

@Component({
  selector: 'pos-app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit, OnDestroy {
  @ViewChild('content') modalBox: any;
  @ViewChild('spotlightElement') spotlightElement: ProductSpotlightComponent;

  hasUnsavedChanges: boolean;
  hasActiveOrder: boolean;
  isShowroomView: boolean;
  isCashDrawer = false;
  isBusy = false;
  branchList: Branch[];
  activeOrder: { orderNumber: string; quickSaveAllowed: boolean };
  openOrder: OpenOrder;
  registerList = [];
  hostName = '';
  isFeedback: boolean;
  hideBanner = true;
  isCashDrawerAttached: boolean;
  USER_ROLE = USER_ROLE;
  hasPendingPayments: boolean;
  brandDefaults: BrandDefaults;
  isIncompleteOrder: boolean;
  hasLoadedGiftCards: boolean;
  alertCount: number;
  jdeSession;
  isShowIframe: boolean = false;
  isOrderChanged: boolean;
  isParkedOrderChanged: boolean;
  parkedOrderListLength: number;
  isElectron = this.electronService.isElectron;
  showSpotlightNav: boolean;

  isShowPopDrawer$: Observable<boolean>;
  orderTypes$: Observable<any[]>;
  defaultBranch$: Observable<Branch>;
  defaultCustomer$: Observable<any>;
  isExternalUser$: Observable<boolean>;
  loggedinUserId$: Observable<string>;
  loggedinUserName$: Observable<string>;
  securityFunctions$: Observable<string[]>;
  role$: Observable<string>;

  private unSubscribe$ = new Subject<void>();

  constructor(
    private authService: AuthService,
    private router: Router,
    private messageService: MessageService,
    private layoutService: LayoutService,
    private store: Store<CoreUiPartialState>,
    private dialogService: DialogService,
    private apiService: ApiService,
    private modalService: NgbModal,
    private drawerService: DrawerService,
    private toastrService: ToastrService,
    public configService: ConfigService,
    public secureCatalogueService: SecureCatalogueService,
    private utilService: UtilService,
    private saleService: SaleService,
    private cdr: ChangeDetectorRef,
    private ooeSessionService: OOESessionService,
    private electronService: ElectronService,
    private eventService: EventService
  ) {
    router.events.subscribe((event) => {
      if (router.url.match(/(\/pos\/scheduler\/?)/g)) {
        this.showSpotlightNav = false;
      } else {
        this.showSpotlightNav = true;
      }
    });
  }

  ngOnInit() {
    this.isExternalUser$ = this.store.select(isExternalUser);
    this.isCashDrawerAttached = this.configService.AppConfig.isCashDrawerAttached;
    this.loggedinUserId$ = this.store.pipe(select(getUserId));
    this.loggedinUserName$ = this.store.pipe(select(getUserName));
    this.defaultBranch$ = this.store.pipe(select(getBranch));
    this.securityFunctions$ = this.store.pipe(select(getSecurityFunctions));
    this.role$ = this.store.pipe(select(getUserRole));
    this.defaultCustomer$ = this.store.pipe(select(getDefaultCustomer));
    this.orderTypes$ = this.store.pipe(select(getOrderTypes));
    this.isShowPopDrawer$ = combineLatest([
      this.loggedinUserId$,
      this.store.select(getCashDrawer).pipe(map((x) => (x ? (JSON.parse(x) as RegisterHost) : null))),
      this.drawerService.cashdrawerAvailability$,
      this.role$,
    ]).pipe(
      map(([loggedinUserId, cashDrawer, cashdrawerAvailability, role]) => {
        return (
          !!loggedinUserId &&
          cashDrawer?.status === 'O' &&
          cashdrawerAvailability?.status &&
          (role === USER_ROLE.showExternal || this.forInternalUser(role))
        );
      })
    );

    this.store
      .select(getIsIncompleteOrder)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((val) => {
        this.isIncompleteOrder = val;
      });
    this.store
      .select(getHasLoadedGiftCards)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((val) => {
        this.hasLoadedGiftCards = val;
      });
    this.store
      .select(getCashDrawer)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((x: string) => {
        if (x) {
          const register: RegisterHost = JSON.parse(x);
          this.isCashDrawer = register && register.status === 'O';
        }
      });

    this.store
      .pipe(
        select(getIsLoggedIn),
        takeUntil(this.unSubscribe$),
        switchMap((loggedIn) => {
          if (loggedIn) {
            this.store.dispatch(getOverdueAlertCounts());
            return this.ooeSessionService.fetchUserState();
          }
          return of(null);
        }),
        tap((res) => {
          if (res && res.ErrorFlag !== '1') {
            this.isShowroomView = res.showroomViewYN === 'Y';
            this.store.dispatch(
              checkIsShowroomView({
                isShowRoomView: res.showroomViewYN === 'Y',
              })
            );

            this.jdeSession = JSON.parse(localStorage.getItem(KEYS.jdeSession));
            const updatedJdeSession: UserProfile = {
              ...this.jdeSession,
              PriceLists: res.priceLists,
            };
            localStorage.setItem(KEYS.jdeSession, JSON.stringify(updatedJdeSession));
          }
        }),
        switchMap((res) => {
          if (res && res.ErrorFlag !== '1') {
            const orderNumber = res.OpenOrder.orderNumber;
            if (orderNumber !== ' ') {
              return this.apiService.fetchGiftCardLoad({
                orderNumber,
              });
            }
          }
          return of(null);
        })
      )
      .subscribe((res) => {
        this.messageService.trigger('userStateUpdate');
        this.messageService.dispatchAction(
          hasLoadedGiftCard({
            hasLoadedGiftCards: res?.completedLoads?.length > 0,
          })
        );
      });

    this.store
      .pipe(select(getHasPendingPayments), takeUntil(this.unSubscribe$))
      .subscribe((hasPendingPayments) => (this.hasPendingPayments = hasPendingPayments));

    this.store.pipe(select(getHasUnsavedChanges), takeUntil(this.unSubscribe$)).subscribe((hasUnsaved) => (this.hasUnsavedChanges = hasUnsaved));

    combineLatest([this.store.select(getHasActiveOrder), this.store.select(getActiveOrder), this.store.select(getOpenOrder)])
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe(([hasActiveOrder, activeOrder, openOrder]) => {
        this.hasActiveOrder = hasActiveOrder;
        this.activeOrder = activeOrder;
        this.openOrder = openOrder;
      });

    this.store
      .select(getBrandDefaults)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((res) => {
        if (res) {
          this.brandDefaults = res;
        }
      });

    this.store.pipe(select(getOverdueAlerts), takeUntil(this.unSubscribe$)).subscribe((alertCount: any) => {
      this.alertCount = alertCount;
    });

    //Open quick sale on finanlize order
    this.saleService.callSale$.pipe(withLatestFrom(this.defaultBranch$), takeUntil(this.unSubscribe$)).subscribe(([, branch]) => {
      this.quickSale(branch);
    });

    //New Condition check hasOrderChanged
    this.store.pipe(select(getHasOrderChanged), takeUntil(this.unSubscribe$)).subscribe((isOrderChanged) => {
      this.isOrderChanged = isOrderChanged;
    });

    //Parked Orders length
    this.store.pipe(select(getParkedOrderListLength), takeUntil(this.unSubscribe$)).subscribe((length) => {
      this.parkedOrderListLength = length;
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  switchUser() {
    if (this.isIncompleteOrder || this.hasLoadedGiftCards) {
      this.dialogService
        .alert('You must finalise the order before switching user', this.isIncompleteOrder ? 'INCOMPLETE ORDER' : 'GIFTCARDS LOADED')
        .pipe(take(1))
        .subscribe();
    } else {
      this.authService.clearToken();
      this.messageService.dispatchAction(resetAllFlags());
      this.navigateTo('/pos/login');
    }
  }

  navigateTo(url, isSearch = false, isMyOrder = false) {
    if (this.hasUnsavedChanges) {
      this.dialogService
        .confirm(
          `Are you sure you want to leave this page? Select No to go back and save your update,
        or select Yes to leave this page and discard your changes.
        You have unsaved changes!`
        )
        .pipe(takeUntil(this.unSubscribe$))
        .subscribe((val) => {
          if (val) {
            this.store.dispatch(hasUnsavedChanges({ unsavedChanges: false }));
            this.hasUnsavedChanges = false;
            this.router.navigateByUrl(url);
          }
        });
    } else {
      this.router.navigateByUrl(url);
    }

    this.router.url === '/pos/customers/search' && isSearch ? window.location.reload() : null;
    this.router.url === '/pos/my-orders' && isMyOrder ? window.location.reload() : null;
  }

  resetPassword(id) {
    this.router.navigateByUrl(`/pos/reset-password?email=${id}`);
  }

  async logout() {
    let logoutAction;
    let response = await this.apiService.fetchParkedOrderList().toPromise();
    let isParkedOrderChanged = this.checkIsParkedOrderChanged(response.parkOrderList);

    if (this.checkPendingOrder()) {
      return;
    }

    if (this.hasActiveOrder && this.isOrderChanged) {
      logoutAction = this.utilService
        .cancelActiveOrderConfirmation(
          `Select NO to go back and save your order,
      or select YES to log out and discard the unsaved order.`,
          this.activeOrder.orderNumber,
          this.activeOrder.quickSaveAllowed
        )
        .pipe(
          switchMap((val) => {
            if (val) {
              this.messageService.dispatchAction(resetAllFlags());
              if (!!isParkedOrderChanged) {
                logoutAction = this.parkedOrderChangedErrorDialog();
                this.logoutAction(logoutAction);
              } else if (response.parkOrderList.length > 0 && !isParkedOrderChanged) {
                logoutAction = this.removeAllUnchangedParkedOrders();
                this.logoutAction(logoutAction);
              } else {
                logoutAction = this.authService.logout();
                this.logoutAction(logoutAction);
              }
            }
            return of(null);
          })
        );
      this.logoutAction(logoutAction);
    } else if (this.hasUnsavedChanges) {
      logoutAction = this.dialogService
        .confirm(
          `Are you sure you want to leave this page? Select No to go back and save your update,
        or select Yes to leave this page and discard your changes.
        You have unsaved changes!`
        )
        .pipe(
          switchMap((val) => {
            if (val) {
              this.store.dispatch(hasUnsavedChanges({ unsavedChanges: false }));
              this.messageService.dispatchAction(hasUnsavedChanges({ unsavedChanges: false }));
              if (!!isParkedOrderChanged) {
                this.parkedOrderChangedErrorDialog();
                this.logoutAction(logoutAction);
              } else if (response.parkOrderList.length > 0 && !isParkedOrderChanged) {
                this.removeAllUnchangedParkedOrders();
                this.logoutAction(logoutAction);
              } else {
                logoutAction = this.authService.logout();
                this.logoutAction(logoutAction);
              }
            }
            return of(null);
          })
        );
      this.logoutAction(logoutAction);
    } else if (!!isParkedOrderChanged) {
      logoutAction = this.parkedOrderChangedErrorDialog();
      this.logoutAction(logoutAction);
    } else if (response.parkOrderList.length > 0 && !isParkedOrderChanged) {
      logoutAction = this.removeAllUnchangedParkedOrders();
      this.logoutAction(logoutAction);
    } else {
      logoutAction = this.authService.logout();
      this.logoutAction(logoutAction);
    }
  }

  canMaintainOrders(securityFunctions) {
    securityFunctions.indexOf(SECURITY_FUNCTION.maintainOrders) !== -1;
  }

  canManageUsers(securityFunctions) {
    return securityFunctions.indexOf(SECURITY_FUNCTION.adminUser) !== -1 || securityFunctions.indexOf(SECURITY_FUNCTION.manageUser) !== -1;
  }

  canManageShowUsers(securityFunctions) {
    return securityFunctions.indexOf(SECURITY_FUNCTION.adminUser) !== -1 || securityFunctions.indexOf(SECURITY_FUNCTION.showAdmin) !== -1;
  }

  canManageInternalUser(securityFunctions) {
    return securityFunctions.indexOf(SECURITY_FUNCTION.adminUser) !== -1;
  }

  canOverrideCashDrawer(securityFunctions) {
    return securityFunctions.indexOf(SECURITY_FUNCTION.allowPayments) !== -1;
  }

  canManageMyProfile(securityFunctions) {
    return securityFunctions.indexOf(SECURITY_FUNCTION.adminUser) !== -1 || securityFunctions.indexOf(SECURITY_FUNCTION.showAdmin) !== -1;
  }

  canManageRegisterAdmin(securityFunctions) {
    if (securityFunctions.indexOf(SECURITY_FUNCTION.adminUser) !== -1) {
      return true;
    }

    return securityFunctions.indexOf(SECURITY_FUNCTION.registerAdmin) !== -1;
  }

  toggleShowroom(showroomView) {
    this.apiService
      .toggleShowroomView({
        showroomViewYN: showroomView ? 'Y' : 'N',
      })
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((res) => {
        this.messageService.trigger('userStateUpdate');
        this.store.dispatch(checkIsShowroomView({ isShowRoomView: showroomView }));
      });
  }

  newOrder() {
    if (this.checkPendingOrder()) {
      return;
    }

    this.navigateTo('/pos/orders-start');
  }

  quickSale(branch: Branch) {
    if (this.checkPendingOrder()) {
      return;
    }

    if (!branch) {
      this.isBusy = true;
      this.apiService
        .getBranchList()
        .pipe(
          finalize(() => (this.isBusy = false)),
          takeUntil(this.unSubscribe$)
        )
        .subscribe((res) => {
          this.isBusy = false;
          this.branchList = res.SearchResults;
          this.modalService.open(this.modalBox, { backdrop: 'static' });
        });
    } else {
      this.createOrderSilent();
    }
  }

  
  createOrderSilent() {
    this.isBusy = true;
    this.apiService
      .createOrderSilent()
      .pipe(
        takeUntil(this.unSubscribe$),
        filter((res) => !!res),
        finalize(() => (this.isBusy = false))
      )
      .subscribe((res) => {
        this.navigateTo(`/pos/orders/${res.OrderNumber}?continueUrl=home`);
      });
  }

  addOrderForCashCustomer(branch: string) {
    this.isBusy = true;
    this.apiService
      .fetchCashCustomer({ branch })
      .pipe(
        switchMap((res) => {
          const newOrder = {
            customerNumber: res.customerNumber,
            customerName: res.customerName,
            shipToNumber: res.customerNumber,
            orderType: ORDER_TYPE.saleOrder,
            partsVehicleID: '',
            customerVehicleID: '',
            vehicleShortDescription: '',
            reference: '',
            branch,
            requestDate: formatDate(Date.now(), 'yyyy-MM-ddThh:mm:ss', 'en-au'),
            currencyCode: this.brandDefaults.currrencyCode,
            customerPaymentTerms: res.customerPaymentTerms,
            customerTaxRate: res.customerTaxRate,
            quickSaleYN: 'Y',
          };

          return this.apiService.createOrder(newOrder).pipe(
            filter((res) => !!res),
            tap((result) => {
              this.navigateTo(`/pos/orders/${result.OrderNumber}?continueUrl=home`);
            })
          );
        }),
        finalize(() => (this.isBusy = false)),
        takeUntil(this.unSubscribe$)
      )
      .subscribe({
        error: (error) => this.toastrService.error(error.ErrorMessage),
      });
  }

  selectBranch(selectedBranch) {
    this.addOrderForCashCustomer(selectedBranch);
    this.modalService.dismissAll();
  }

  openCashDrawer() {
    this.drawerService.openCashdrawer();
  }

  navigateToAccountUser(customerNumber: string) {
    this.navigateTo(`/pos/customers/${customerNumber}/details`);
  }

  forInternalUser(role) {
    return role.indexOf(USER_ROLE.internal) !== -1;
  }

  forExternalUser(role) {
    return role === USER_ROLE.external;
  }

  navigateToHome() {
    this.navigateTo(this.secureCatalogueService.getLandingPage());
    this.layoutService.setIsComponentHidden(false);
    this.spotlightElement?.iFrameAssignment(false);
    this.spotlightElement?.setInitialTabIndex();
  }

  navigateToTaskCalendar(customerNumber: string) {
    this.navigateTo(`/pos/customers/${customerNumber}/taskCalendar`);
  }

  viewOrder() {
    this.navigateTo(`/pos/orders/${this.activeOrder.orderNumber}?continueUrl=home`);
  }

  checkPendingOrder() {
    if (this.hasLoadedGiftCards || this.hasPendingPayments) {
      this.toastrService.error(
        this.hasLoadedGiftCards
          ? 'There are loaded gift cards on the current order so the order must be finalised'
          : 'There are pending payments on the current order so the order must either be saved into JDE or the pending payment must be netted out or deleted'
      );
    }
    return this.hasPendingPayments || this.hasLoadedGiftCards;
  }

  checkIsParkedOrderChanged(parkOrderList) {
    return parkOrderList.some((parkedOrder) => parkedOrder.orderChangedYN === 'Y');
  }
  parkedOrderChangedErrorDialog() {
    return this.dialogService
      .confirm(`You cannot logout! You have park orders, please deal with them to logout`, `ERROR: Cannot Logout With Parked Orders`, `OKAY`, null)
      .pipe(
        switchMap((val) => {
          if (!!val) {
            return of(this.navigateTo('/pos/my-orders'));
          }
          return of(null);
        })
      );
  }

  removeAllUnchangedParkedOrders() {
    return this.apiService.removeAllParkedOrders().pipe(
      switchMap((val) => {
        if (!!val) {
          return this.authService.logout();
        }
        return of(null);
      })
    );
  }

  logoutAction(logoutAction) {
    logoutAction.pipe(takeUntil(this.unSubscribe$)).subscribe((res) => {
      if (res) {
        this.isCashDrawer = false;
        this.navigateTo('/pos/login');
      }
    });
  }
  checkNavDisable() {
    return this.eventService.navDisable;
  }

  goToScheduler() {
    const date = formatDate(Date.now(), 'dd/MM/YYYY', 'en-au')
    this.navigateTo(`/pos/scheduler/${date}`);
  }
}
