import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { Vehicle } from '../../models/vehicle-data';
import { Store } from '@ngrx/store';
import {
  LoadVehicles,
  getStateVehicles,
  getStateLoaded,
  getStateIsAdvancedSearch,
  SearchString,
  RequestVehicleSelection,
  ResetVehicleSearch,
  getStateSelectedVehicle,
  DeselectVehicle,
  getStateUsedAsFilter,
  UseInFilter,
  ShowUniversalProducts,
  SetUserSelectedVehicle,
  getStateRequestSelectVehicle,
  SelectVehicle,
  ClearRequestSelectVehicle,
} from '../../+store/vehicles';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { VehicleDescriptionSearchParameter, VehicleSearchParamters } from '../../+store/vehicles/vehicle';
import { IMainState } from '../../+store';
import { VehicleUpdateModalComponent } from '../order-status-bar/vehicle-update-modal/vehicle-update-modal.component';
import { LoadActiveOrderHeader, SetActiveOrderVehicle, UpdateOrderHeader, getActiveOrderHeader } from '../../+store/customers';
import { OOESessionService } from 'libs/core-ui/src/lib/services/ooe-session.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IOOEOrder, IOOEUserState } from '../../models/userState.model';
import { OOEApiServiceProxy } from '../../services/ooe-api-service-proxy';
import { setSelectedVehicle } from '@pos-app/core-ui';

@Component({
  selector: 'app-vehicle-search',
  templateUrl: './vehicle-search.component.html',
  styleUrls: ['./vehicle-search.component.scss'],
})
export class VehicleSearchComponent implements OnInit, OnDestroy {
  @ViewChild('vehicleDeselectModal') public vehicleDeselectModal: VehicleUpdateModalComponent;
  @ViewChild('vehicleChangeModal') public vehicleChangeModal: VehicleUpdateModalComponent;
  @ViewChild('newVehicleDetailContent') public newVehicleDetailContent: ElementRef;
  @ViewChild('vehicleLabelSearch') public vehicleLabelSearch: ElementRef;

  public subject = new Subject();
  public useTopSearch: boolean;
  public searchString: string;
  public useVehicleInFilter: boolean;
  public vehicleToBeSelected: Vehicle;

  public vehicles$ = this.store.select(getStateVehicles);
  public isLoaded$ = this.store.select(getStateLoaded);
  public isAdvancedSearch$ = this.store.select(getStateIsAdvancedSearch);
  public selectedVehicle$ = this.store.select(getStateSelectedVehicle);
  public useVehicleInFilter$ = this.store.select(getStateUsedAsFilter);
  public activeOrderHeader$ = this.store.select(getActiveOrderHeader);

  private componentDestroyed$ = new Subject<void>();

  public get ooeUserState(): IOOEUserState {
    return this.ooeSessionService.getOOEUserState();
  }

  public get openOrder(): IOOEOrder {
    return this.ooeSessionService.openOrder;
  }

  public get hasActiveOrder(): boolean {
    return this.ooeSessionService.hasActiveOrder;
  }

  public get isTempVehicle(): boolean {
    return this.ooeSessionService.isTempVehicle;
  }

  constructor(
    private store: Store<IMainState>,
    private ooeSessionService: OOESessionService,
    private ooeApiServiceProxy: OOEApiServiceProxy,
    private modalService: NgbModal
  ) {}

  public ngOnInit(): void {
    this.subject.pipe(debounceTime(900), takeUntil(this.componentDestroyed$)).subscribe((searchParams) => {
      this.store.dispatch(LoadVehicles({ payload: <VehicleSearchParamters>searchParams }));
    });

    this.useVehicleInFilter$.pipe(takeUntil(this.componentDestroyed$)).subscribe((value) => {
      this.useVehicleInFilter = value;
    });

    this.store
      .select(getStateRequestSelectVehicle)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((vehicleToBeSelected) => {
        if (vehicleToBeSelected?.VehicleID) {
          this.vehicleToBeSelected = vehicleToBeSelected;

          if (this.ooeUserState && this.hasActiveOrder) {
            if (!this.openOrder.partsVehicleID || this.openOrder.partsVehicleID.toString() != vehicleToBeSelected.partsVehicleID) {
              this.changeActiveOrderVehicle();
            }
          } else {
            this.store.dispatch(SelectVehicle({ payload: vehicleToBeSelected }));
          }
        }
      });

    this.loadData();
  }

  public ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  public changed(): void {
    this.useTopSearch = true;
    this.isAdvancedSearch$.subscribe((value) => {
      if (value) {
        this.useTopSearch = false;
      }
    });

    this.searchVehicle();
  }

  public searchVehicle(): void {
    let searchParams = {
      brand: '',
      make: '',
      year: '',
      searchString: '',
      model: '',
      generation: '',
      subModel: '',
      chassis: '',
      series: '',
      bodyType: '',
      driveType: '',
      transmissionType: '',
      engineType: '',
      engineCode: '',
    };

    searchParams.searchString = this.searchString;
    this.subject.next(searchParams);
  }

  public resetSearch(): void {
    this.store.dispatch(ResetVehicleSearch());
  }

  public reselectVehicle(content): void {
    this.selectedVehicle$.pipe(take(1)).subscribe((vehicle) => {
      let vehicleDescParam = new VehicleDescriptionSearchParameter();
      vehicleDescParam.jdeVehicleID = vehicle.VehicleID && vehicle.VehicleID != '' ? Number(vehicle.VehicleID) : 0;
      vehicleDescParam.partsVehicleID = Number(vehicle.partsVehicleID);

      this.ooeApiServiceProxy
        .getVehicleDescription(vehicleDescParam)
        .pipe()
        .subscribe((result) => {
          let searchLabel = this.vehicleLabelSearch.nativeElement.querySelector('.selectedVehicle').innerHTML.trim();
          if (result.SearchResults.length > 0) {
            searchLabel = result.SearchResults[0].vehicleDescription;
          }
          this.store.dispatch(SearchString({ payload: searchLabel }));
          this.showVehicleSelectionModal(content);
        });
    });
  }

  public showVehicleSelectionModal(content): void {
    this.modalService.open(content, {
      windowClass: 'vehicle-selection-modal',
      size: 'xl',
    });
  }

  public openAdvancedSearch(content): void {
    this.store.dispatch(SearchString({ payload: this.searchString }));
    this.reset();
    this.showVehicleSelectionModal(content);
  }

  public selectVehicle(vehicle: Vehicle): void {
    this.store.dispatch(RequestVehicleSelection({ payload: vehicle }));
    this.reset();
  }

  public reset(): void {
    this.searchString = '';
    this.store.dispatch(ResetVehicleSearch());
    this.useTopSearch = true;
  }

  public loadData(): void {
    if (this.ooeSessionService.loginedSession) {
      this.ooeSessionService
        .fetchUserState()
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe({
          next: ({ OpenOrder, jdeVehicleID }) => {
            let selectedVehicle = null;

            // if there is selected vehicle in user state
            if (OpenOrder?.partsVehicleID) {
              selectedVehicle = {
                VehicleID: jdeVehicleID,
                partsVehicleID: OpenOrder.partsVehicleID,
                Make: OpenOrder.vehicleDescription,
              };
            }

            if (OpenOrder?.orderNumber) {
              // reset checkbox Use in Filter to true if differ from current
              this.selectedVehicle$.pipe(take(1)).subscribe((currentVehicle) => {
                if (selectedVehicle?.partsVehicleID && (!currentVehicle || selectedVehicle.partsVehicleID != currentVehicle.partsVehicleID)) {
                  this.store.dispatch(UseInFilter({ payload: true }));
                  this.store.dispatch(ShowUniversalProducts({ payload: true }));
                }
              });

              this.store.dispatch(
                SetActiveOrderVehicle({
                  payload: {
                    partsVehicleID: OpenOrder.partsVehicleID,
                    vehicleDescription: OpenOrder.vehicleDescription,
                    customerVehicleID: OpenOrder.customerVehicleID,
                    jdeVehicleID: jdeVehicleID,
                  },
                })
              );

              this.store.dispatch(
                LoadActiveOrderHeader({
                  payload: {
                    orderNumber: OpenOrder.orderNumber,
                  },
                })
              );

              this.store.dispatch(
                setSelectedVehicle({
                  customerVehicleID: OpenOrder.customerVehicleID,
                  partsVehicleID: OpenOrder.partsVehicleID,
                  vehicleDescription: OpenOrder.vehicleDescription,
                  jdeVehicleID: jdeVehicleID,
                })
              )
            }

            // always set userstate selected vehicle
            this.store.dispatch(SetUserSelectedVehicle({ payload: selectedVehicle }));
          },
        });
    }
  }

  public updateVehicleUseFilter(): void {
    this.store.dispatch(UseInFilter({ payload: this.useVehicleInFilter }));
  }

  public getVehicleDeselectConfirmationText(): string {
    if (this.hasActiveOrder) {
      return 'This will remove the vehicle selection on the customer sales order, do you want to proceed?';
    } else {
      return 'Are you sure you want to clear current vehicle selection?';
    }
  }

  public deselectVehicle(): void {
    this.vehicleDeselectModal.confirmationText = this.getVehicleDeselectConfirmationText();
    this.vehicleDeselectModal.show();
  }

  public removeVehicleSelection(): void {
    this.store.dispatch(DeselectVehicle());
    if (this.hasActiveOrder) {
      this.store.dispatch(
        UpdateOrderHeader({
          payload: {
            customerVehicleId: null,
            partsVehicleID: null,
            vehicleDescription: '',
          },
        })
      );
    }
  }

  public changeActiveOrderVehicle(): void {
    if (this.vehicleChangeModal) {
      this.vehicleChangeModal.confirmationText = 'This will change the vehicle on the customer sales order, do you want to proceed?';
      this.vehicleChangeModal.show();
    }
  }

  public closeAddingVehicle(): void {
    this.store.dispatch(ClearRequestSelectVehicle());
    this.modalService.dismissAll();
  }

  public handleUpdateVehicle(): void {
    if (this.isTempVehicle) {
      this.store.dispatch(
        SelectVehicle({
          payload: {
            ...this.vehicleToBeSelected,
            customerVehicleID: -1, // special value will tell the system it is a tempVehicle
          },
        })
      );
      return;
    }

    this.openVehicleDetailModal();
  }

  public openVehicleDetailModal(): void {
    this.modalService.open(this.newVehicleDetailContent, {
      windowClass: 'garage-modal',
      size: 'xl',
    });
  }

  public onSelectVehicle(vehicle: Vehicle): void {
    this.store.dispatch(
      RequestVehicleSelection({
        payload: vehicle,
      })
    );
  }
}
