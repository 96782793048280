import { FormatWidth, getLocaleDateFormat } from '@angular/common';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { format, parse } from 'date-fns';
import { enAU } from 'date-fns/locale';

export function getFullYearLocaleDate(
  currentLocale: any,
  date: { year: number; monthIndex: number; day: number }
) {
  return format(
    new Date(date.year, date.monthIndex, date.day),
    getFullYearLocaleFormat(currentLocale, FormatWidth.Short),
    currentLocale
  );
}

export function getFullYearLocaleFormat(locale: any, FormatWidth: FormatWidth) {
  const regex = /yy|y|Y|yyy/g;
  return getLocaleDateFormat(locale, FormatWidth).replace(regex, 'yyyy');
}

export function parseDate(
  dateString: string,
  currentLocale: string,
  formatWidth: FormatWidth = FormatWidth.Short
) {
  return parse(
    dateString,
    getFullYearLocaleFormat(currentLocale, formatWidth),
    new Date(),
    { locale: enAU }
  );
}

export function getLocalLocaleDate(dateString: string, locale: string) {
  if (dateString?.trim()?.includes('/')) {
    const date = dateString.split('/');

    return getFullYearLocaleDate(locale, {
      day: parseInt(date[0], 10),
      monthIndex: parseInt(date[1], 10) - 1,
      year: parseInt(date[2], 10),
    });
  }

  if (dateString?.trim().includes('-')) {
    const date = new Date(dateString)
    
    return getFullYearLocaleDate(locale, {
      day: date.getDate(),
      monthIndex: date.getMonth(),
      year: date.getFullYear(),
    });
  }

  return null
}

export function formatDateForInternal(
  dateString: string,
  currentLocale: string,
  formatWidth: FormatWidth = FormatWidth.Short
) {
  return format(
    new Date(parseDate(dateString, currentLocale, formatWidth)),
    'dd/MM/yyyy'
  );
}

export function formatDateForInternalFromNgbDate(date: NgbDate) {
  return `${date.day}/${date.month}/${date.year}`;
}

export function getCurrentDate(
  locale: string,
  formatWidth: FormatWidth = FormatWidth.Short
) {
  return format(new Date(), getFullYearLocaleFormat(locale, formatWidth));
}
