import { Injectable } from '@angular/core';
import { KEYS, UserProfile, USER_ROLE } from '@pos-app/data';
import { forkJoin, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { loginSuccess } from '../+state/core-ui/core-ui.actions';
import { ApiService } from './api.service';
import { ConfigService } from './config.service';
import { MessageService } from './message.service';
import * as localforage from 'localforage';
import { SecureCatalogueService } from './secure-catelogue.service';
import { ElectronService } from './electron/electron.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private config: ConfigService,
    private apiService: ApiService,
    private messageService: MessageService,
    private secureCatalogueService: SecureCatalogueService,
    private electronService: ElectronService
  ) {}

  login(username: string, password: string) {
    const base64pwd = btoa(password);
    const brand = this.config.AppConfig.brand;
    const data = { username, password: base64pwd, brand, electronTF: this.electronService.isElectron };

    return this.apiService.login(data).pipe(
      tap((res) => {
        const { ErrorFlag, ErrorMessage, ...userProfile } = res;
        localStorage.setItem(KEYS.jdeToken, res.Token);
        localStorage.setItem(KEYS.jdeSession, JSON.stringify(userProfile));
      }),
      switchMap((res) => {
        const { ErrorFlag, ErrorMessage, ...values } = res;
        const userProfile = values as UserProfile;
        if (userProfile && userProfile.Role === USER_ROLE.internal) {
          return forkJoin([of(userProfile), this.getRegister(userProfile).pipe(catchError((_) => of(null))), this.apiService.getCatalogueStatus()]);
        }
        return forkJoin([of(userProfile), of(null), this.apiService.getCatalogueStatus()]);
      }),
      map(([userProfile, register, catalogueStatusRes]) => {
        if (register && register.ErrorFlag === '0' && register.SearchResults.length > 0) {
          localStorage.setItem(KEYS.cashDrawer, JSON.stringify(register.SearchResults[0]));
        }
        const { ErrorFlag, ErrorMessage, ...catalogueStatus } = catalogueStatusRes;

        if (catalogueStatus) {
          localStorage.setItem(KEYS.catalogueStatus, JSON.stringify(catalogueStatus));
        }
        this.secureCatalogueService.setIsCatalogueVisible(catalogueStatus.catalogueVisibleYN === 'Y');
        this.messageService.dispatchAction(loginSuccess({ payload: userProfile }));
        return userProfile;
      })
    );
  }

  restoreSession() {
    const session = localStorage.getItem(KEYS.jdeSession);
    const payload = session ? JSON.parse(session) : null;
    this.messageService.dispatchAction(loginSuccess({ payload }));
  }

  logout() {
    return this.apiService.logout().pipe(
      map((res) => {
        this.clearToken();
        return res;
      }),
      catchError((_) => {
        this.clearToken();
        return of(null);
      })
    );
  }

  clearToken() {
    localStorage.removeItem(KEYS.jdeToken);
    localStorage.removeItem(KEYS.jdeSession);
    localStorage.removeItem(KEYS.cashDrawer);
    localStorage.removeItem(KEYS.catalogueStatus);
    localforage.clear();
    this.secureCatalogueService.setIsCatalogueVisible(false);
    this.messageService.dispatchAction(loginSuccess({ payload: null }));
  }

  getRegister(userProfile) {
    let hostName = userProfile.UserID;
    if (this.config.AppConfig.isElectron && this.config.AppConfig.isCashDrawerAttached) {
      hostName = this.config.AppConfig.hostName;
    }
    return this.apiService.getRegisterForHost({ host: hostName });
  }
}
