<pos-app-spinner [show]="isBusy"></pos-app-spinner>
<div class="container-fluid">
  <div class="text-center py-3">
    <h3>REGISTER ADMINISTRATION</h3>
  </div>
  <div class="row pb-2">
    <div class="offset-2 col-2 my-auto">
      <label class="my-auto"> HOST NAME </label>
    </div>
    <div class="col-3">
      <input class="form-control" disabled [value]="hostName" />
    </div>
  </div>
  <div class="row pb-2">
    <div class="offset-2 col-2 my-auto my-auto">
      <label class="my-auto"> ASSIGNED REGISTER </label>
    </div>
    <div class="col-3">
      <ng-select
        (change)="assignRegister($event)"
        [(ngModel)]="assignedRegister ? assignedRegister.registerID : assignedRegister"
        [clearable]="false"
        [disabled]="isCashDrawerAttached && assignedRegister"
        [placeholder]="'Please select a register'"
      >
        <ng-option *ngFor="let register of getRegisterList()" [value]="register.registerID">{{ register.registerID }} </ng-option>
      </ng-select>
    </div>
    <div class="col-3 d-flex my-auto">
      <input type="checkbox" class="my-auto form-check-input" [(ngModel)]="showBranchRegister" />
      <span class="px-2">ONLY SHOW REGISTERS IN MY BRANCH</span>
    </div>
  </div>
  <div class="row pb-2">
    <div class="offset-2 col-2 my-auto">
      <label class="my-auto"> REGISTER STATUS </label>
    </div>
    <div class="col-3">
      <input class="form-control" [value]="registerOpened ? 'OPEN' : 'CLOSE'" disabled />
    </div>
  </div>
  <div class="row pb-2">
    <div class="offset-2 col-2 my-auto">
      <label class="my-auto"> ASSIGN EFTPOS </label>
    </div>
    <div class="col-3">
      <ng-select
        (change)="assignEftpos()"
        [(ngModel)]="eftposDeviceName"
        [disabled]="!assignedRegister || assignedRegister.status === 'C'"
        [placeholder]="'Please select an EFTPOS device'"
      >
        <ng-option *ngFor="let eftpos of getLinkedEftpos(assignedRegister?.registerID)" [value]="eftpos">
          {{ eftpos }}
        </ng-option>
      </ng-select>
    </div>
    <div *ngIf="(allowFakeEFTPOSTF$ | async) && eftposDeviceName" class="col-2 d-flex my-auto">
      <input type="checkbox" class="my-auto form-check-input" [(ngModel)]="fakeEFTPOSTF" (change)="assignEftpos()"/>
      <span class="px-2">Enable fake EFTPOS </span>
    </div>
    <div class="col-auto">
      <button *ngIf="eftposDeviceName" class="btn text-white" (click)="pinpadRePair()">RE-PAIR</button>
    </div>
    <div class="col-auto">
      <button *ngIf="eftposDeviceName" class="btn text-white" (click)="reprintReceipt()">REPRINT LAST</button>
    </div>
  </div>
  <div class="row pb-2">
    <div class="offset-2 col-2 my-auto">
      <label class="my-auto"> REGISTER OPENED </label>
    </div>
    <div class="col-3">
      <input class="form-control" [value]="assignedRegister?.openedDateTime || ''" disabled />
    </div>
  </div>
  <div class="row pb-2">
    <div class="offset-2 col-2 my-auto">
      <label class="my-auto"> REGISTER OPENED BY </label>
    </div>
    <div class="col-3">
      <input class="form-control" [value]="assignedRegister?.openedBy || ''" disabled />
    </div>
  </div>
  <div class="row pb-2">
    <div class="offset-2 col-2 my-auto">
      <label class="my-auto"> REGISTER CLOSED </label>
    </div>
    <div class="col-3">
      <input class="form-control" [value]="assignedRegister?.closedDateTime || ''" disabled />
    </div>
  </div>
  <div class="row pb-2">
    <div class="offset-2 col-2 my-auto">
      <label class="my-auto"> REGISTER CLOSED BY </label>
    </div>
    <div class="col-3">
      <input class="form-control" [value]="assignedRegister?.closedBy || ''" disabled />
    </div>
  </div>
  <div class="row" *ngIf="assignedRegister">
    <div class="offset-2 col-5 d-flex justify-content-end">
      <button class="btn btn-secondary" *ngIf="isCashDrawerAttached" (click)="popCashDrawer()">POP REGISTER</button>
      <span class="px-2"></span>
      <button class="btn text-white" (click)="openModal()">{{ registerOpened ? 'CLOSE' : 'OPEN' }} REGISTER</button>
    </div>
  </div>
</div>

<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h3 class="arb-large-heading" id="modal-basic-title">
      {{ registerOpened ? 'CLOSE REGISTER' : 'OPEN REGISTER' }}
    </h3>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row pb-2">
      <div class="col-5 my-auto">
        <label class="my-auto">ASSIGNED REGISTER</label>
      </div>
      <div class="col-7">
        <input class="form-control" [value]="assignedRegister?.registerID" disabled />
      </div>
    </div>
    <div class="row pb-2">
      <div class="col-5 my-auto">
        <label class="my-auto">REGISTER {{ registerOpened ? 'CLOSED' : 'OPENED' }}</label>
      </div>
      <div class="col-7">
        <input class="form-control" [value]="currentTimeToSend" disabled />
      </div>
    </div>
    <div class="row pb-2">
      <div class="col-5 my-auto">
        <label class="my-auto">REGISTER {{ registerOpened ? 'CLOSED' : 'OPENED' }} BY</label>
      </div>
      <div class="col-7">
        <input class="form-control" [value]="currentUserId" disabled />
      </div>
    </div>
    <div class="row pb-2">
      <div class="col-5 my-auto">
        <label class="my-auto">{{ registerOpened ? 'ENDING' : 'STARTING' }} CASH FLOAT</label>
      </div>
      <div class="col-7">
        <input
          class="form-control"
          [(ngModel)]="cashFloat"
          #cash="ngModel"
          pattern="^[0-9]+(.[0-9]+)?$"
          [class.is-invalid]="cash.touched && cash.errors"
          required
          name="cashFloat"
          [autofocus]="true"
          (keyup.enter)="manageRegister()"
        />
      </div>
    </div>
    <div class="row pb-2">
      <div class="col-12 d-flex justify-content-end">
        <button class="btn text-white" (click)="manageRegister()" [disabled]="!cash.valid">
          {{ registerOpened ? 'CLOSE REGISTER' : 'OPEN REGISTER' }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #registerPinpad let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h3 class="arb-large-heading" id="modal-basic-title">PINPAD REGISTRATION</h3>
  </div>
  <div class="modal-body">
    <div class="row pb-2">
      <div class="col">Instruction on how to obtain the Pinpad paircode</div>
    </div>
    <div class="row pb-2">
      <div class="col-4 my-auto">
        <label class="my-auto">PAIRCODE</label>
      </div>
      <div class="col-8">
        <input class="form-control" [(ngModel)]="pairCode" />
      </div>
    </div>
    <div class="row pb-2">
      <div class="col-12 d-flex justify-content-end">
        <button class="btn btn-cancel mr-3" (click)="d('Cross click')">CANCEL</button>
        <button class="btn text-white" (click)="onRegisterPinpad()" [disabled]="!pairCode || isBusy">
          REGISTER PINPAD
          <pos-app-spinner2 *ngIf="isBusy"></pos-app-spinner2>
        </button>
      </div>
    </div>
  </div>
</ng-template>
