//ENGLISH LANGUAGE LOCALES
import enUS from '@angular/common/locales/en';
import enGB from '@angular/common/locales/en-GB';
import enAE from '@angular/common/locales/en-AE';
import enAG from '@angular/common/locales/en-AG';
import enAI from '@angular/common/locales/en-AI';
import enAS from '@angular/common/locales/en-AS';
import enAT from '@angular/common/locales/en-AT';
import enAU from '@angular/common/locales/en-AU';
import enBB from '@angular/common/locales/en-BB';
import enBE from '@angular/common/locales/en-BE';
import enBI from '@angular/common/locales/en-BI';
import enBM from '@angular/common/locales/en-BM';
import enBS from '@angular/common/locales/en-BS';
import enBW from '@angular/common/locales/en-BW';
import enBZ from '@angular/common/locales/en-BZ';

import cs from '@angular/common/locales/cs';

import nn from '@angular/common/locales/nn';

import enCA from '@angular/common/locales/en-CA';
import enCC from '@angular/common/locales/en-CC';
import enCH from '@angular/common/locales/en-CH';
import enCK from '@angular/common/locales/en-CK';
import enCM from '@angular/common/locales/en-CM';
import enCX from '@angular/common/locales/en-CX';
import enCY from '@angular/common/locales/en-CY';

import enDE from '@angular/common/locales/en-DE';
import enDG from '@angular/common/locales/en-DG';
import enDK from '@angular/common/locales/en-DK';
import enDM from '@angular/common/locales/en-DM';

import enER from '@angular/common/locales/en-ER';

import enZA from '@angular/common/locales/en-ZA';

//FRENCH LANGUAGE LOCALES
import fr from '@angular/common/locales/fr';
import frCA from '@angular/common/locales/fr-CA';
import frCD from '@angular/common/locales/fr-CD';
import frCF from '@angular/common/locales/fr-CF';
import frCG from '@angular/common/locales/fr-CG';
import frCH from '@angular/common/locales/fr-CH';
import frCI from '@angular/common/locales/fr-CI';
import frCM from '@angular/common/locales/fr-CM';

import frBE from '@angular/common/locales/fr-BE';
import frBF from '@angular/common/locales/fr-BF';
import frBI from '@angular/common/locales/fr-BI';
import frBJ from '@angular/common/locales/fr-BJ';
import frBL from '@angular/common/locales/fr-BL';

import frDJ from '@angular/common/locales/fr-DJ';
import frDZ from '@angular/common/locales/fr-DZ';

import frGA from '@angular/common/locales/fr-GA';
import frGF from '@angular/common/locales/fr-GF';
import frGN from '@angular/common/locales/fr-GN';
import frGP from '@angular/common/locales/fr-GP';
import frGQ from '@angular/common/locales/fr-GQ';

import frHT from '@angular/common/locales/fr-HT';

import frKM from '@angular/common/locales/fr-KM';

import frLU from '@angular/common/locales/fr-LU';

import frMA from '@angular/common/locales/fr-MA';
import frMC from '@angular/common/locales/fr-MC';
import frMF from '@angular/common/locales/fr-MF';
import frMG from '@angular/common/locales/fr-MG';
import frML from '@angular/common/locales/fr-ML';
import frMQ from '@angular/common/locales/fr-MQ';
import frMR from '@angular/common/locales/fr-MR';
import frMU from '@angular/common/locales/fr-MU';

import frNC from '@angular/common/locales/fr-NC';
import frNE from '@angular/common/locales/fr-NE';

import frPF from '@angular/common/locales/fr-PF';
import frPM from '@angular/common/locales/fr-PM';

import frRE from '@angular/common/locales/fr-RE';
import frRW from '@angular/common/locales/fr-RW';

import frSC from '@angular/common/locales/fr-SC';
import frSN from '@angular/common/locales/fr-SN';
import frSY from '@angular/common/locales/fr-SY';

import frTD from '@angular/common/locales/fr-TD';
import frTG from '@angular/common/locales/fr-TG';
import frTN from '@angular/common/locales/fr-TN';

import frVU from '@angular/common/locales/fr-VU';
import frWF from '@angular/common/locales/fr-WF';

import caAD from '@angular/common/locales/ca-AD';

import ar from '@angular/common/locales/ar';

import es from '@angular/common/locales/es';
import esCO from '@angular/common/locales/es-CO';
import esMX from '@angular/common/locales/es-MX';

import ru from '@angular/common/locales/ru';

import th from '@angular/common/locales/th';

import bsBA from '@angular/common/locales/bs';

import nl from '@angular/common/locales/nl';

import hr from '@angular/common/locales/hr';

import fi from '@angular/common/locales/fi';

import de from '@angular/common/locales/de';

import el from '@angular/common/locales/el';

import it from '@angular/common/locales/it';
import pl from '@angular/common/locales/pl';

import pt from '@angular/common/locales/pt';
import ptPT from '@angular/common/locales/pt';

import ro from '@angular/common/locales/ro';

//Imports were not imported by Lazy loading,
//because of webpack issue as it does not find the correct url for added local dynamically
//TODO: Add all imports manually and update switch cases respectively

export function getTargetLocale(browserLocale: string) {
  switch (browserLocale) {
    //ENGLISH
    case 'en-AU':
      return enAU;
    case 'en-US':
      return enUS;
    case 'en-GB':
      return enGB;
    case 'en-ZA':
      return enZA;
    case 'en-AE':
      return enAE;
    case 'en-AG':
      return enAG;
    case 'en-AI':
      return enAI;
    case 'en-AS':
      return enAS;
    case 'en-AT':
      return enAT;
    case 'en-ZA':
      return enZA;

    //FRENCH
    case 'fr-FR':
      return fr;
    case 'ca-AD':
      return caAD;

    //CZECH
    case 'cs':
      return cs;

    //NORWEGIAN
    case 'nn':
      return nn;

    //RUSSIAN
    case 'ru':
      return ru;

    //SPANISH
    case 'es':
      return es;
    case 'es-CO':
      return esCO;
    case 'es-MX':
      return esMX;

    //ARABIC
    case 'ar':
      return ar;

    //ARABIC
    case 'ar':
      return ar;

    //THAI
    case 'th':
      return th;

    //DUTCH
    case 'nl':
      return nl;

    //CROATION
    case 'hr':
      return hr;

    //FINNISH
    case 'fi':
      return fi;

    //GERMAN
    case 'de':
      return de;
    case 'de-DE':
      return de;

    //GREEK
    case 'el':
      return el;

    //ITALIAN
    case 'it':
      return it;

    //POLSIH
    case 'pl':
      return pl;

    //PORTUGUESE
    case 'pt':
      return pt;
    case 'pt-PT':
      return ptPT;

    //ROMANIAN
    case 'ro':
      return ro;

    //DEFAULT enAU
    default:
      return enAU;
  }
}
