<div class="product-spotlight-wrapper" [ngClass]="{'pb-0': isSpotlightHidden$ | async}">
  <div [ngClass]="{'initial': (isSpotlightHidden$ | async)}" class="product-spotlight-container navbar-fixed-top">
    <div class="row product-spotlight align-items-center">
      <div class="col-3">
        <h4 class="mb-0">PRODUCT SPOTLIGHT</h4>
      </div>
      <div class="col-9 col-md-6 text-center">
        <div class="">
          <mat-tab-group
            animationDuration="2000ms"
            (selectedTabChange)="onTabSelectOrChange($event)"
            [(selectedIndex)]="entityTabIndex"
          >
            <mat-tab></mat-tab>
            <mat-tab
              *ngFor="let entity of spotLightInfo"
              [label]="entity.Title"
            ></mat-tab>
          </mat-tab-group>
        </div>
      </div>
      <div class="col-3 d-none d-md-block">
        <button
          class="product-spotlight-btn float-end"
          (click)="navigateToHome()"
          type="button"
        >
          PRODUCT CATALOGUE
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="showIframe == true" class="iframe-container">
    <iframe
      class="iframe-arb-wordpress"
      [src]="iframeUrl"
      title="ARB wordpress frame"
      allowfullscreen=""
      style="width: 100%; height: 1080px"
    ></iframe>
  </div>
</div>
