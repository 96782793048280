import { getLocaleCurrencyCode, registerLocaleData } from '@angular/common';
import { Injectable } from '@angular/core';

import { getTargetLocale } from '../utils/locale-imports';
import { ElectronService } from './electron/electron.service';

const localeId = navigator.language;

@Injectable({ providedIn: 'root' })
export class LocalizationService {
  lang: string;
  predefinedLocale: string =  localStorage.getItem('PREDEFINED_LOCALE')!=="undefined"?JSON.parse(localStorage.getItem('PREDEFINED_LOCALE')): null;
  isExternalUser: boolean = localStorage.getItem('IS_EXTERNAL_USER')!=="undefined"?JSON.parse(localStorage.getItem('IS_EXTERNAL_USER')): null;
  isShow: boolean = localStorage.getItem('IS_SHOW')!=="undefined"?JSON.parse(localStorage.getItem('IS_SHOW')):null;

  constructor(private electronService: ElectronService) {}

  getDefaultLocaleId() {
    if(this.predefinedLocale && (this.electronService.isElectron || !this.isExternalUser || this.isShow )){ }
    else{
     this.predefinedLocale = null 
    }
    if (this.electronService.isElectron) {
      this.electronService.contextBridge.getLocale((e, message) => {
        this.lang = message.currentLocale;
      });
      return this.predefinedLocale?? this.lang ?? 'en-AU';
    } else {
      return this.predefinedLocale?? this.lang ?? 'en-AU';
    }
  }

  getDefaultCurrencyCode() {
    return getLocaleCurrencyCode(this.getDefaultLocaleId());
  }

  localeInitializer() {
    if(this.predefinedLocale && (this.electronService.isElectron || !this.isExternalUser || this.isShow )){ }
    else{
      this.predefinedLocale = localeId  
    }
    return (): Promise<any> => {
      return new Promise((resolve) => {
        this.getBrowserLanguage(getTargetLocale(this.predefinedLocale??this.lang??localeId));
        resolve(true);
      });
    };
  }

  getBrowserLanguage(module: any) {
    registerLocaleData(module);
    this.lang = module[0];
  }
}
