import { Component, OnInit, ViewChild } from '@angular/core';
import {
  NgbCarousel,
  NgbSlideEvent,
} from '@ng-bootstrap/ng-bootstrap';

import Banner from './ads.json';

@Component({
  selector: 'pos-app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  images = [];
  paused = false;
  pauseOnHover = true;
  showNavigationArrows = true;
  showNavigationIndicators = true;

  @ViewChild('carousel', { static: true }) carousel: NgbCarousel;

  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  onSlide(slideEvent: NgbSlideEvent) {}

  ngOnInit() {
    Banner.images.map(x => this.images.push(`/assets/img/banner/${x}`));
  }
}
