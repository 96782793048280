export interface ResourceItem {
  id: string;
  name: string;
  type: string;
  directory?: string;
  children?: ResourceItem[];
  lastModified?: string;
  size?: number;
}

export const RESOURCE_TYPE = {
  link: 'link',
  file: 'file',
  folder: 'folder'
};
