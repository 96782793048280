import { Injectable } from '@angular/core';
import { IOOEUserSession } from '../../../../../apps/pos-app/src/app/features/epc/models/userSession.model';
import { IOOEOrder, IOOEUserState } from '../../../../../apps/pos-app/src/app/features/epc/models/userState.model';
import { ApiService, CoreUiPartialState, MessageService, hasPendingPayments, setOpenOrder } from '@pos-app/core-ui';
import { map } from 'rxjs/operators';
import { Observable, Subject, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { OpenOrder } from '@pos-app/data';

@Injectable({ providedIn: 'root' })
export class OOESessionService {
  private _ooeUserState: IOOEUserState;
  public ooeUserState$ = new Subject<IOOEUserState>();

  constructor(private messageService: MessageService, private apiService: ApiService, private store: Store<CoreUiPartialState>) {}

  get loginedSession(): IOOEUserSession {
    if (localStorage.getItem('jdeSession')) {
      var session = JSON.parse(localStorage.getItem('jdeSession'));
      return session;
    }

    return null;
  }

  get priceLists(): string {
    if (this.loginedSession?.PriceLists) {
      return this.loginedSession.PriceLists.join(',');
    }
    return '';
  }

  get openOrder(): IOOEOrder {
    return this._ooeUserState?.OpenOrder;
  }

  get isTempVehicle(): boolean {
    return this._ooeUserState?.tempVehicleYN === 'Y';
  }

  get hasActiveOrder(): boolean {
    return !!this.openOrder?.orderNumber?.trim();
  }

  setOOEUserState(value): void {
    this._ooeUserState = value;
  }

  getOOEUserState(): IOOEUserState {
    return this._ooeUserState;
  }

  getUserPostCode(): string {
    if (sessionStorage.getItem('userPostCode')) {
      return sessionStorage.getItem('userPostCode');
    }

    return ' ';
  }

  setUserPostCode(postcode: string) {
    sessionStorage.setItem('userPostCode', postcode);
    this.messageService.trigger('postcodeUpdate', postcode);
  }

  fetchUserState(): Observable<IOOEUserState> {
    return this.apiService.fetchUserState().pipe(
      map((res: IOOEUserState) => {
        this._ooeUserState = res;
        this.store.dispatch(
          setOpenOrder({
            openOrder: (res.OpenOrder?.orderNumber?.trim() ? res.OpenOrder : null) as any,
            quickSaveAllowed: res.quickSaveYN === 'Y',
          })
        );

        this.store.dispatch(
          hasPendingPayments({
            hasPendingPayments: res.OpenOrder.pendingPaymentsTF,
          })
        );

        this.ooeUserState$.next(res);
        return res;
      })
    );
  }
}
