<ng-container [ngSwitch]="config?.type">
  <div *ngSwitchCase="'text'">
    <input
      type="text"
      [formControl]="control"
      class="form-control"
      [placeholder]="
        config.label === 'Vehicle Group'
          ? 'Enter a vehicle description or type NONE for order without a vehicle'
          : ''
      "
    />
  </div>
  <div *ngSwitchCase="'number'">
    <input type="number" [formControl]="control" class="form-control" />
  </div>
  <div *ngSwitchCase="'boolean'">
    <div class="custom-control custom-checkbox my-1 mr-sm-2">
      <input
        type="checkbox"
        class="custom-control-input"
        id="customControlInline"
        [formControl]="control"
      />
      <label class="custom-control-label" for="customControlInline"></label>
    </div>
  </div>
  <div *ngSwitchCase="'list'">
    <select [formControl]="control" class="form-control">
      <option *ngFor="let item of config?.values" [value]="item">{{
        item
      }}</option>
    </select>
  </div>
  <div *ngSwitchCase="'api-list'">
    <ng-select [formControl]="control">
      <ng-option
        *ngFor="let item of config?.values"
        [value]="item[config.valueField]"
      >
        {{ item[config.textField] }}
        <span *ngIf="item[config.valueField]"
          >({{ item[config.valueField] }})</span
        >
      </ng-option>
    </ng-select>
  </div>
  <div *ngSwitchCase="'dateRange'">
    <pos-app-date-picker
      (selectedDateRange)="selectDate($event)"
    ></pos-app-date-picker>
  </div>
  <div *ngSwitchCase="'fuzzySearch'">
    <input type="hidden" [formControl]="control" />
    <input type="search" [formControl]="fuzzySearchForm" class="form-control" />
    <div
      *ngIf="
        fuzzySearchList && fuzzySearchList.length > 0 && showFuzzySearchList
      "
    >
      <ng-select [formControl]="control" (change)="selectItem()">
        <ng-option value="">Please select the Part item</ng-option>
        <ng-option
          *ngFor="let item of fuzzySearchList"
          [value]="item[config.valueField]"
        >
          <span *ngFor="let key of getObjectKeys(item)" class="px-2">{{
            item[key]
          }}</span>
        </ng-option>
      </ng-select>
    </div>
  </div>
  <div *ngSwitchDefault></div>
</ng-container>
