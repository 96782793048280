import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Store } from '@ngrx/store';
import { CoreUiPartialState, getSecurityFunctions } from '@pos-app/core-ui';
import { SECURITY_FUNCTION } from '@pos-app/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { SecureCatalogueService } from 'libs/core-ui/src/lib/services/secure-catelogue.service';

@Injectable({
  providedIn: 'root'
})
export class RegisterGuard implements CanActivate {
  constructor(
    private store: Store<CoreUiPartialState>,
    private router: Router,
    private toastr: ToastrService,
    private secureCatalogueService: SecureCatalogueService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.select(getSecurityFunctions).pipe(
      map((functions: string[]) => {
        if (
          functions.indexOf(SECURITY_FUNCTION.registerAdmin) === -1 &&
          functions.indexOf(SECURITY_FUNCTION.allowPayments) === -1
        ) {
          this.toastr.error("You don't have the right to access the page");
          this.router.navigate([this.secureCatalogueService.getLandingPage()]);
          return false;
        }
        return true;
      })
    );
  }
}
