import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  ConsolidateOrder,
  DefaultOrder,
  OrderForBooking,
  RenameGroupName,
} from '@pos-app/data';

@Component({
  selector: 'app-order-for-booking-card',
  templateUrl: './order-for-booking-card.component.html',
  styleUrls: ['./order-for-booking-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderForBookingCardComponent implements OnInit {
  @Input() orderForBookingCard: OrderForBooking;
  @Input() dragDropArrayOfLineIdsList: Array<string>;
  @Input() isArrayOfLinesDisabled: Array<string>;

  @Output() renameGroupName = new EventEmitter<RenameGroupName>();
  @Output() splitOrder = new EventEmitter<DefaultOrder>();
  @Output() consolidateOrder = new EventEmitter<ConsolidateOrder>();
  @Output() arrayOfLinesdropEvent = new EventEmitter<any>();
  @Output() arrayOfLineParentOrderData = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  isSplitOrder() {
    return (
      !!this.orderForBookingCard &&
      this.orderForBookingCard.SpiltOrderYN === 'Y' &&
      this.orderForBookingCard.ResourceAssigned.trim() === ''
    );
  }

  isConsolidateOrder() {
    return (
      !!this.orderForBookingCard &&
      this.orderForBookingCard.SpiltOrderYN === 'N' &&
      this.orderForBookingCard.ResourceAssigned.trim() === ''
    );
  }

  hasArrayOfLines() {
    return (
      !!this.orderForBookingCard.ArrayOfLines &&
      this.orderForBookingCard.ArrayOfLines.length > 0
    );
  }

  itemExitedParentContainer(event: any) {
    this.arrayOfLineParentOrderData.emit({
      orderNumber: this.orderForBookingCard.OrderNumber.toString(),
      orderType: this.orderForBookingCard.OrderType,
      fromGroupName: this.orderForBookingCard.GroupName,
      lineNumber: event.source.data.LineNumber.toString(),
    });
  }

  updateGroupName(event) {
    if (
      event.target.value.trim() !== this.orderForBookingCard.GroupName.trim()
    ) {
      this.renameGroupName.emit({
        orderNumber: this.orderForBookingCard.OrderNumber.toString(),
        orderType: this.orderForBookingCard.OrderType,
        oldGroupName: this.orderForBookingCard.GroupName.trim(),
        newGroupName: event.target.value.trim(),
      });
    }
  }

  invokeSplitOrder() {
    this.splitOrder.emit({
      orderNumber: this.orderForBookingCard.OrderNumber.toString(),
      orderType: this.orderForBookingCard.OrderType,
    });
  }

  invokeConsolidateOrderOrder() {
    this.consolidateOrder.emit({
      orderNumber: this.orderForBookingCard.OrderNumber.toString(),
      orderType: this.orderForBookingCard.OrderType,
      groupName: this.orderForBookingCard.GroupName.trim(),
    });
  }

  invokeReassignLine(event: CdkDragDrop<string[]>) {
    this.arrayOfLinesdropEvent.emit({
      previousContainer: event.previousContainer,
      currentContainer: event.container,
      previousIndex: event.previousIndex,
      currentIndex: event.currentIndex,
      orderNumber: this.orderForBookingCard.OrderNumber.toString(),
      orderType: this.orderForBookingCard.OrderType,
      toGroupName: this.orderForBookingCard.GroupName,
    });
  }
}
