<div class="product-fitting-intructions arb-clear-text-for-table">
  <table class="product-items-table">
    <tbody>
      <ng-container *ngFor="let record of fittingInstructions; let rowIndex = index">
        <tr *ngIf="rowGroupMetadata[record.contentDescription].index === rowIndex" class="row-group">
          <td colspan="2">
            {{ record.contentDescription }}
          </td>
        </tr>
        <tr *ngIf="record.fileName" class="fitting-intruction-row">
          <td style="width: 80%">
            <span class="fitting-intruction-title">{{ record.fileName }}</span>
          </td>
          <td class="download-link">
            <ng-container *ngIf="record.contentUrl">
              <a class="link" (click)="download(record)"> Download </a>
              |
              <a target="_blank" [download]="record.fileName" [attr.filename]="record.fileName" [href]="record.contentUrl"> View </a>
            </ng-container>
          </td>
        </tr>
      </ng-container>
      <ng-container *ngIf="supplierUrl">
        <tr class="row-group">
          <td colspan="2">Product's External Website</td>
        </tr>
        <tr class="fitting-intruction-row">
          <td style="width: 80%" class="fitting-intruction-title">
            <a href="{{ supplierUrl }}" target="_blank">{{ supplierUrl }}</a>
          </td>
        </tr>
      </ng-container>
      <ng-container *ngIf="supersededProductNumber">
        <tr class="fitting-intruction-row">
          <td colspan="2">
            <span>Superseded Part Number: </span>
            <span>{{ supersededProductNumber }}</span>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
