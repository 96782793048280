import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { CustomersPartialState } from '../../+state/customers.reducer';
import { Store } from '@ngrx/store';
import { customersQuery } from '../../+state/customers.selectors';
import { LoadCustomerVehicles, MaintainCustomerVehicle, MakeVehicleDefault } from '../../+state/customers.actions';
import { CustomerVehicle, Vehicle, VehicleRetiredReason } from '@pos-app/data';
import { map, take } from 'rxjs/operators';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { formatDate } from '@angular/common';
import { ClipboardService } from 'ngx-clipboard';
import { CustomersResponseService } from '../../+state/customers.response';

@Component({
  selector: 'app-customers-vehicles',
  templateUrl: './customers-vehicles.component.html',
  styleUrls: ['./customers-vehicles.component.scss'],
})
export class CustomersVehiclesComponent implements OnInit, OnDestroy {
  @ViewChild('popOver') public popOver: NgbPopover;

  @Input() public customerNumber: string;
  @Input() public currentCustomerVehicleId: string;
  @Input() public preselectedVehicle: {
    customerVehicleID: number;
    partsVehicleID: number;
    vehicleDescription: string;
    jdeVehicleID: number;
  };
  @Input() public isAddVehicleForCustomer: boolean;

  @Output() public selectVehicleEvent = new EventEmitter<CustomerVehicle>();
  @Output() public closeActiveModal = new EventEmitter<boolean>();

  public retiredReasons$: Observable<VehicleRetiredReason[]>;
  public customerVehicles$: Observable<CustomerVehicle[]>;
  public isLoadingCustomerVehicles$: Observable<boolean>;
  public selectedCustomerNumber: string;
  public showRetired = false;
  public selectedVehicle: CustomerVehicle = null;

  public retireCodeForm = new FormControl('001');
  public hasActiveVehicle$: Observable<boolean>;
  public customerVehicles: CustomerVehicle[];
  public defaultVehicle$: Observable<CustomerVehicle>;
  public vehicleCustomerIDCopied = false;
  public vehicleIDCopied = false;
  public copiedValue = '';
  public isAddingVehicle = false;
  public isEdittingVehicle = false;
  public vehicleToAdd: Vehicle;
  private unSubscribe$ = new Subject<void>();

  constructor(
    private store: Store<CustomersPartialState>,
    private clipboardService: ClipboardService,
    private customersResponseService: CustomersResponseService
  ) {}

  public ngOnInit() {
    this.clipboardService.configure({ cleanUpAfterCopy: true });
    if (this.customerNumber) {
      this.selectedCustomerNumber = this.customerNumber;

      if (!this.isAddVehicleForCustomer) {
        this.store.dispatch(
          new LoadCustomerVehicles({
            customerNumber: this.selectedCustomerNumber,
            retiredVehicleYN: 'Y',
          })
        );
      }
    }

    this.customerVehicles$ = this.store.select(customersQuery.getCustomerVehicles);

    this.hasActiveVehicle$ = this.store.select(customersQuery.getHasActiveVehicles);

    this.isLoadingCustomerVehicles$ = this.store.select(customersQuery.getLoadingCustomerVehicles);

    this.retiredReasons$ = this.store.select(customersQuery.getLookupLists).pipe(map((x) => x.vehicleRetiredReasonList));

    this.defaultVehicle$ = this.store.select(customersQuery.getDefaultVehicle);

    if (this.isAddVehicleForCustomer) {
      this.addVehicle();
    }
  }

  public ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  public selectVehicle(vehicle) {
    this.selectedVehicle = vehicle;
    this.currentCustomerVehicleId = this.selectedVehicle.customerVehicleID;
  }

  public retire() {
    this.popOver.close();
    const retireForm = {
      customerNumber: this.selectedCustomerNumber,
      actionCode: 'R',
      customerVehicleID: this.selectedVehicle.customerVehicleID,
      dateRetired: formatDate(Date.now(), 'dd/MM/yy', 'en-au'),
      reasonCodeRetired: this.retireCodeForm.value,
    };
    this.store.dispatch(new MaintainCustomerVehicle(retireForm));
    this.selectedVehicle = null;
  }

  public unRetire() {
    const unRetireForm = {
      customerNumber: this.selectedCustomerNumber,
      actionCode: 'U',
      customerVehicleID: this.selectedVehicle.customerVehicleID,
    };
    this.store.dispatch(new MaintainCustomerVehicle(unRetireForm));
    this.selectedVehicle = null;
  }

  public makeDefault(defaultVehicle: CustomerVehicle) {
    const makeDefaultPayload = {
      default: {},
      selectedVehicle: {
        customerNumber: this.selectedCustomerNumber,
        customerVehicleID: this.selectedVehicle.customerVehicleID,
        actionCode: 'C',
        regoNumber: this.selectedVehicle.RegoNumber,
        regoState: this.selectedVehicle.RegoState,
        vinNumber: this.selectedVehicle.VinNumber,
        colourCode: this.selectedVehicle.ColourCode,
        shortDescription: this.selectedVehicle.ShortDescription,
        fullDescription: this.selectedVehicle.VehicleFullDescription,
        vehicleID: this.selectedVehicle.VehicleID,
        defaultFlag: '1',
      },
    };

    makeDefaultPayload.default = defaultVehicle
      ? {
          customerNumber: this.selectedCustomerNumber,
          customerVehicleID: defaultVehicle.customerVehicleID,
          actionCode: 'C',
          regoNumber: defaultVehicle.RegoNumber,
          regoState: defaultVehicle.RegoState,
          vinNumber: defaultVehicle.VinNumber,
          colourCode: defaultVehicle.ColourCode,
          shortDescription: defaultVehicle.ShortDescription,
          fullDescription: defaultVehicle.VehicleFullDescription,
          vehicleID: defaultVehicle.VehicleID,
          defaultFlag: '0',
        }
      : null;

    this.store.dispatch(new MakeVehicleDefault(makeDefaultPayload));
    this.selectedVehicle = null;
  }

  public showRetiredToggle() {
    this.showRetired = !this.showRetired;
    this.selectedVehicle = null;
  }

  public copyToClipBoard(value, isCustomerVehicleID = false) {
    this.clipboardService.copy(value);
    this.vehicleCustomerIDCopied = isCustomerVehicleID;
    this.vehicleIDCopied = !isCustomerVehicleID;
    this.copiedValue = value;
  }

  public submit() {
    this.selectVehicleEvent.emit(this.selectedVehicle);
  }

  public addVehicle() {
    this.isAddingVehicle = true;
  }

  public editVehicle() {
    this.isEdittingVehicle = true;
  }

  public edittedVehicle(vehicleForm) {
    this.isAddingVehicle = false;
    this.isEdittingVehicle = false;

    this.store.dispatch(
      new LoadCustomerVehicles({
        customerNumber: this.selectedCustomerNumber,
        retiredVehicleYN: 'Y',
      })
    );

    if (this.isAddVehicleForCustomer && this.preselectedVehicle) {
      this.customersResponseService.loadCustomerVehiclesSuccess$.pipe(take(1)).subscribe(({ payload }: any) => {
        const customerVehicles: CustomerVehicle[] = payload.SearchResults || [];
        const selectedVehicle = customerVehicles.find((item) => Number(item.VehicleID) === +this.preselectedVehicle.jdeVehicleID);
        if (selectedVehicle) {
          this.selectVehicle(selectedVehicle);
          this.submit();
        }
      });
    }

    // Only interested in colour change to display in order header
    if (this.selectedVehicle && this.selectedVehicle.ColourCode !== vehicleForm.colourCode) {
      this.selectedVehicle = Object.assign({}, this.selectedVehicle);
      this.selectedVehicle.ColourCode = vehicleForm.colourCode;
    }
  }

  public cancel() {
    this.isAddingVehicle = false;
    this.isEdittingVehicle = false;
  }

  public closeModal() {
    this.closeActiveModal.emit(true);
  }
}
