import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ApiService } from '@pos-app/core-ui';
import { ListTasksNotes, TaskOutComes, TasksNotes } from '@pos-app/data';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CompleteTasksAction } from '../../+state/customers.actions';
import { CustomersPartialState } from '../../+state/customers.reducer';
import { customersQuery } from '../../+state/customers.selectors';
import { ActivatedRoute } from '@angular/router';
import { format } from 'date-fns';

@Component({
  selector: 'app-customers-notes-complete-task',
  templateUrl: './customers-notes-complete-task.component.html',
  styleUrls: ['./customers-notes-complete-task.component.scss'],
})
export class CustomersNotesCompleteTaskComponent implements OnInit {
  @ViewChild('content') modalBox: any;
  @Input() listTaskNotesFilter: ListTasksNotes;
  completeTaskForm: FormGroup;
  taskOutComesList: TaskOutComes[];
  isFollowUpTaskPossible: boolean;
  loadingCompleteTask$: Observable<boolean>;
  noteTaskDetails: TasksNotes;
  isTaskCalendarComp: boolean = false;
  taskCalendarFilter: string;
  defaultCustomer: any;

  private unSubscribe$ = new Subject<void>();

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private store: Store<CustomersPartialState>,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.completeTaskForm = this.formBuilder.group({
      taskOutcome: [''],
      note: [''],
      additionalDetails: [''],
    });
    this.apiService
      .listOutcomes()
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((outComes) => {
        this.taskOutComesList = outComes.SearchResults;
      });
    this.completeTaskForm.controls.taskOutcome.valueChanges
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((taskOutComesValue) => {
        this.isFollowUpTaskPossible =
          taskOutComesValue.furtherTaskPossibleYN === 'Y';
      });
    this.loadingCompleteTask$ = this.store.select(
      customersQuery.getLoadingCompleteTask
    );
  }

  openCompleteTaskModal(
    noteTaskDetails,
    isTaskCalendarComp,
    taskCalendarFilter,
    defaultCustomer
  ) {
    this.taskCalendarFilter = taskCalendarFilter;
    this.defaultCustomer = defaultCustomer;
    this.isTaskCalendarComp = isTaskCalendarComp;
    this.noteTaskDetails = noteTaskDetails;
    this.completeTaskForm.patchValue({
      note: noteTaskDetails.note,
      additionalDetails: noteTaskDetails.additionalDetails,
    });
    this.modalService
      .open(this.modalBox, { size: 'xl', backdrop: 'static' })
      .result.then(
        (_) => {},
        (_) => {}
      );
  }

  completeTask() {
    const formValue = this.completeTaskForm.getRawValue();
    const today = format(new Date(), 'dd/MM/yyyy');

    this.store.dispatch(
      new CompleteTasksAction({
        defaultCustomer: this.defaultCustomer,
        taskCalendarFilter: this.taskCalendarFilter,
        isTask: this.isTaskCalendarComp,
        activityID: this.noteTaskDetails.activityID,
        note: formValue.note,
        additionalDetails: formValue.additionalDetails,
        dateCompleted: today,
        activityResultIndex: formValue.taskOutcome.index,
        customerNumber: this.route.snapshot.params.id,
        listTaskNotesFilter: this.listTaskNotesFilter,
      })
    );
    this.modalService.dismissAll();
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
