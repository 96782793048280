<div *ngIf="customer">
  <div class="row">
    <div class="col-md-5 col-xl-4 col-sm-12">
      <div class="row">
        <label class="col-form-label font-weight-bold">NAME</label>
        <div class="col-form-value col-name">
          {{ customer.CustomerName }}<span class="px-1">({{ customer.CustomerNumber }})</span>
        </div>
      </div>
    </div>
    <div class="col-md-3 col-xl-3 col-sm-12">
      <div class="row">
        <label class="col-form-label font-weight-bold">TYPE</label>
        <div class="col-form-value">
          {{ customer.CustomerType }}
        </div>
      </div>
    </div>
    <div class="col-md-4 col-xl-2 col-sm-12">
      <div class="row">
        <label class="col-form-label font-weight-bold">PH</label>
        <div class="col-form-value">
          {{ customer.Phone }}
        </div>
      </div>
    </div>
    <div *ngIf="!isExternal" class="col-md-5 col-xl-3 col-sm-12">
      <div class="row">
        <div class="col-auto">
          <mat-icon
            *ngIf="customer.alertMessage !== ' '"
            class="text-danger mr-3"
            [routerLink]="['/pos/customers/' + customer.CustomerNumber + '/notes']"
          >
            alarm
          </mat-icon>
        </div>
        <div class="col-auto">
          <button class="btn" [routerLink]="['/pos/customers/' + customer.CustomerNumber + '/notes']">CUSTOMER NOTES</button>
        </div>
      </div>
    </div>
  </div>
  <ng-template [ngIf]="!isExternal">
    <div class="row">
      <div class="col-md-3 col-xl-4 col-sm-12">
        <div class="row">
          <label class="col-form-label font-weight-bold">VEHICLE</label>
          <div class="col-form-value col-vehicle">
            {{ vehicleText }}
          </div>
        </div>
      </div>
      <div class="col-md-4 col-xl-5 col-sm-12">
        <div class="row">
          <label class="col-form-label font-weight-bold">CAMPER</label>
          <div class="col-form-value">
            {{ customer.CamperTrailerVIN }}
          </div>
        </div>
      </div>
      <div *ngIf="!!customer.CamperTrailerVIN?.trim()" class="col-md-5 col-xl-3 col-sm-12">
        <div class="row">
          <button class="btn" [routerLink]="['/pos/camper-trailer/' + assetNumber]">CAMPER TRAILER</button>
        </div>
      </div>
    </div>
    <div class="row">
      <label class="col-form-label font-weight-bold">NOTES</label>
      <div class="col-form-value col-notes">
        {{ customer.CustomerFreeTextNotes }}
      </div>
    </div>
  </ng-template>
  <hr />
  <div class="row pb-2">
    <div class="col-md-4 col-sm-12 d-flex" *ngIf="!isExternal">
      <button class="btn btn-secondary" [routerLink]="['/pos/orders/customer/' + customer.CustomerNumber]" [queryParams]="{ type: 'invoiced' }">
        TOTAL SPENT
      </button>
      <div class="col-form-value col-total-spent my-auto" *ngIf="sales">
        <!-- <span> {{ sales?.TotalSpentForever.trim() | currency }}</span> -->
        <span> {{ sales?.TotalSpentForever_Number | currency : (!!sales?.CurrencyCode ? sales?.CurrencyCode : '') }}</span>
      </div>
    </div>
    <div class="col-md-4 col-sm-12 d-flex">
      <button class="btn btn-secondary" [routerLink]="['/pos/orders/customer/' + customer.CustomerNumber]" [queryParams]="{ type: 'open' }">
        OPEN ORDERS
      </button>
      <div class="col-form-value my-auto" *ngIf="sales">
        <!-- {{ sales?.NoOfOpenOrders }} - ${{ sales?.ValueOfOpenOrders }} -->
        {{ sales?.NoOfOpenOrders_Number | number }} -
        {{ sales?.ValueOfOpenOrders_Number | currency : (!!sales?.CurrencyCode ? sales?.CurrencyCode : '') }}
      </div>
    </div>
    <div class="col-md-4 col-sm-12 d-flex">
      <button class="btn btn-secondary" [routerLink]="['/pos/orders/customer/' + customer.CustomerNumber]" [queryParams]="{ type: 'quote' }">
        OPEN QUOTES
      </button>
      <div class="col-form-value my-auto" *ngIf="sales">
        <!--{{ sales?.NoOfOpenQuotes }} - ${{ sales?.ValueOfOpenQuotes }}-->
        {{ sales.NoOfOpenQuotes_Number | number }} -
        {{ sales?.ValueOfOpenQuotes_Number | currency : (!!sales?.CurrencyCode ? sales?.CurrencyCode : '') }}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="row py-2">
        <label class="col-form-label font-weight-bold">LAST INVOICE</label>
        <div class="col-form-value col-last-order">
          {{ customer.LastInvDetails }}
        </div>
      </div>
      <div *ngIf="sales" class="row py-2">
        <div class="col-6 px-1">
          <div class="row">
            <div class="col">
              <label class="font-weight-bold">TOTAL BALANCE</label>
              <div class="col-form-value col-last-order">
                {{ sales?.TotalARBalance | currency : (!!sales?.CurrencyCode ? sales?.CurrencyCode : '') }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 px-1">
          <div class="row">
            <div class="col">
              <label class="font-weight-bold">PAST DUE</label>
              <div class="col-form-value col-last-order">
                {{ sales.PastDueBalance | currency : (!!sales?.CurrencyCode ? sales?.CurrencyCode : '') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
