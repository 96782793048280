import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApiService, DialogService } from '@pos-app/core-ui';
import { Statement } from '@pos-app/data';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { GetStatementListAction } from '../../+state/customers.actions';
import { CustomersPartialState } from '../../+state/customers.reducer';
import { customersQuery } from '../../+state/customers.selectors';
import { CustomersGridActionRendererComponent } from '../../components/customersGridActionRenderer/customersGridActionRender.component';
import { getLocalLocaleDate } from 'libs/core-ui/src/lib/utils/utils-date';

@Component({
  selector: 'app-customers-statement',
  templateUrl: './customers-statement.component.html',
  styleUrls: ['./customers-statement.component.scss']
})
export class CustomersStatementComponent implements OnInit, OnDestroy {
  columnDefs;
  gridOptions;
  rowData = [];
  searchValue = '';
  frameworkComponents;
  context;
  unSubscribe$ = new Subject<void>();
  loading: boolean;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private store: Store<CustomersPartialState>,
    private apiService: ApiService,
    private dialogService: DialogService
  ) {
    this.frameworkComponents = {
      actionRenderer: CustomersGridActionRendererComponent
    };
    this.context = { componentParent: this };
    this.gridOptions = {
      defaultColDef: {
        resizable: true,
        sortable: true,
        width: 200,
        cellClass: 'cell-class ag-right-aligned-cell',
        headerClass: 'header-class ag-right-aligned-header'
      },
      columnDefs: [
        {
          headerName: 'ACTION',
          width: 100,
          cellRenderer: 'actionRenderer'
        },
        {
          headerName: 'STATEMENT DATE',
          width: 180,
          field: 'statementDate',
          valueFormatter: (params) => {
            return getLocalLocaleDate(params.value, this.locale);
          },
        },
        {
          headerName: 'PREVIOUS BALANCE',
          field: 'previousBalance',
          valueFormatter: this.currencyFormatter
        },
        {
          headerName: 'NEW INVOICES',
          width: 160,
          field: 'newInvoices',
          valueFormatter: this.currencyFormatter
        },
        {
          headerName: 'ADJUSTMENTS',
          width: 170,
          field: 'adjustments',
          valueFormatter: this.currencyFormatter
        },
        {
          headerName: 'PAYMENTS RECEIVED',
          width: 200,
          field: 'paymentsReceived',
          valueFormatter: this.currencyFormatter
        },
        {
          headerName: 'OUTSTANDING BALANCE DUE',
          width: 260,
          field: 'outstandingBalance',
          valueFormatter: this.currencyFormatter
        },
        {
          headerName: 'FUTURE DUE',
          width: 150,
          field: 'futureDue',
          valueFormatter: this.currencyFormatter
        }
      ]
    };
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  ngOnInit() {
    this.store.dispatch(new GetStatementListAction());
    this.store
      .select(customersQuery.getCustomerStatements)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe(res => (this.rowData = res));
  }
  onGridReady(params) {
  }

  currencyFormatter(params) {
    return params.value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  sendEmail(data: Statement) {
    this.loading = true;
    this.apiService
      .emailStatementExternal({
        printUniqueKeyID: data.printUniqueKeyID
      })
      .pipe(
        finalize(() => (this.loading = false)),
        takeUntil(this.unSubscribe$)
      )
      .subscribe(() => {
        this.loading = false;
        this.dialogService.confirm(
          'An email with the selected statement has been sent',
          null,
          null,
          null,
          null,
          false
        );
      });
  }
}
