<pos-app-spinner [show]="loading$ | async"></pos-app-spinner>
<form [formGroup]="newAttachmentsNoteForm">
  <div>
    <div class="d-flex row pb-4" *ngIf="!noteInEdit">
      <div class="col-3">
        <select
          class="form-control"
          formControlName="noteType"
          (change)="selectNoteType()"
        >
          <option value="">SELECT NOTE TYPE</option>
          <option [value]="ATTACHMENT_TYPE.note">Note</option>
          <option [value]="ATTACHMENT_TYPE.link">Link</option>
          <option [value]="ATTACHMENT_TYPE.file">File</option>
        </select>
      </div>
    </div>
    <div [ngSwitch]="newAttachmentsNoteForm.controls.noteType.value">
      <angular-editor
        *ngSwitchCase="ATTACHMENT_TYPE.note"
        formControlName="note"
        [config]="editorConfig"
      ></angular-editor>

      <input
        *ngSwitchCase="ATTACHMENT_TYPE.link"
        type="text"
        formControlName="link"
        class="form-control mb-3"
        placeholder="Enter link"
      />
      <input
        *ngSwitchCase="ATTACHMENT_TYPE.file"
        type="file"
        (change)="onFileChange($event)"
        [multiple]="false"
      />
    </div>
  </div>
  <div class="mt-3 mb-3 text-right">
    <button
      class="btn text-white"
      [disabled]="
        (newAttachmentsNoteForm.controls.noteType.value ===
          ATTACHMENT_TYPE.link &&
          (!newAttachmentsNoteForm.valid ||
            !newAttachmentsNoteForm.controls.link.value)) ||
        (newAttachmentsNoteForm.controls.noteType.value ===
          ATTACHMENT_TYPE.note &&
          !newAttachmentsNoteForm.controls.note.value) ||
        (newAttachmentsNoteForm.controls.noteType.value ===
          ATTACHMENT_TYPE.file &&
          !attachedFile) ||
        newAttachmentsNoteForm.controls.noteType.value === ''
      "
      (click)="saveAttachment()"
      *ngIf="newAttachmentsNoteForm.controls.noteType.value"
    >
      SAVE
    </button>
    <span class="px-2"></span>
    <button class="btn btn-cancel" (click)="resetNoteForm()" *ngIf="noteInEdit">
      CANCEL
    </button>
  </div>
</form>
<div *ngIf="allAttachments?.length > 0 && displayNoteGrid">
  <table class="table table-striped table-bordered w-100">
    <thead>
      <tr>
        <th scope="col" class="text-uppercase">
          <label>ACTION</label>
        </th>
        <th scope="col" class="text-uppercase">
          <label>TYPE</label>
        </th>
        <th scope="col" class="text-uppercase w-100">
          <label>DATA</label>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of allAttachments">
        <td class="text-center d-flex">
          <mat-icon (click)="deleteItem(item)">delete</mat-icon>
          <span class="px-1"></span>
          <mat-icon
            (click)="editNote(item)"
            *ngIf="item.type !== ATTACHMENT_TYPE.file"
            >edit</mat-icon
          >
        </td>
        <td class="text-center text-capitalize">
          {{ item.type }}
        </td>
        <td *ngIf="item.type === ATTACHMENT_TYPE.link">
          <label
            >LAST CHANGED BY: {{ item.lastChangeUser }}
            {{ item.lastChangeDate }}
            {{ formatTime(item.lastChangeTime) }}</label
          >
          <div>
            <a [href]="item.data" target="_blank">{{ item.data }}</a>
          </div>
        </td>
        <td *ngIf="item.type === ATTACHMENT_TYPE.note">
          <label
            >LAST CHANGED BY: {{ item.lastChangeUser }}
            {{ item.lastChangeDate }}
            {{ formatTime(item.lastChangeTime) }}</label
          >
          <div [innerHTML]="item.data"></div>
        </td>
        <td *ngIf="item.type === ATTACHMENT_TYPE.file">
          <label
            >LAST CHANGED BY: {{ item.lastChangeUser }}
            {{ item.lastChangeDate }}
            {{ formatTime(item.lastChangeTime) }}</label
          >
          <div>
            <a href="javascript:void(0)" (click)="downloadFile(item)">{{
              item.name
            }}</a>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<button class="btn btn-cancel mt-3 float-end" (click)="closeModal()">
  CLOSE
</button>
