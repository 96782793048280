import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EpcGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    const status = localStorage.getItem('catalogueStatus');
    const isVisible = status
      ? JSON.parse(status).catalogueVisibleYN === 'Y'
      : false;
    if (isVisible) {
      return of(true);
    }
    this.router.navigateByUrl('/pos/login');
    return of(false);
  }
}
