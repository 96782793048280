<pos-app-spinner [show]="isLoading"></pos-app-spinner>
<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h3 class="arb-large-heading" id="modal-basic-title">
      FEEDBACK
    </h3>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label>FEEDBACK TYPE</label>
      <select
        class="form-control"
        id="feedbackType"
        required
        [(ngModel)]="feedbackType"
        name="feedbackType"
        #feedback="ngModel"
        (change)="gatherInformation()"
      >
        <option value="">Please select feedback type</option>
        <option
          *ngFor="let ftype of feedbackTypeList"
          [value]="ftype.feedbackCode"
          >{{ ftype.feedbackDescription }}</option
        >
      </select>
    </div>
    <div class="pt-3" *ngIf="feedbackDetails">
      <label>INFORMATION GATHERED</label>
      <div [innerText]="feedbackDetails"></div>
      <p class="text-center pt-3">
        The above diagnostic information has been loaded into your clipboard.
        Please click on the button below to go to our help desk system to log a
        new ticket and paste this information in. In addition to this
        information please ensure you enter a detailed problem description
        including part number, brand and specific problem encountered.
      </p>
      <div class="d-flex justify-content-end">
        <div>
          <a *ngIf="!isElectron"
            [href]="feedbackUrl"
            target="_blank"
            class="text-white btn"
          >
            {{ feedbackTitle }}
          </a>
          <a *ngIf="isElectron"
             (click)="openFeedbackInNativeBrowser(feedbackUrl)"
             class="text-white btn">
            {{ feedbackTitle }}
          </a>
        </div>
        <span class="px-2"></span>
        <button class="btn btn-cancel" (click)="cancel()">CANCEL</button>
      </div>
    </div>
  </div>
</ng-template>
