<pos-app-spinner [show]="isLoading"></pos-app-spinner>
<form *ngIf="orderHeader" [formGroup]="form">
  <mat-tab-group class="order-header-tab" selectedIndex="1">
    <mat-tab></mat-tab>
    <mat-tab label="ORDER HEADER">
      <div class="py-4">
        <div class="row row-gap mx-0">
          <div class="col-3 d-flex align-items-center">
            <label class="d-flex align-items-center col-5">
              CUSTOMER
              <span *ngIf="orderHeader?.orderNumber.startsWith('T')" class="my-auto pl-2">
                <mat-icon *ngIf="!isExternalUser" (click)="selectNewCustomer()" title="Change Customer">edit</mat-icon>
              </span>
            </label>
            <div class="col-7 nowrap">
              <a class="form-control overflow-hidden text-truncate" [ngClass]="{ disabled: isExternalUser }" (click)="editCustomer()">
                <u>{{ form.controls.customerName.value }}</u>
              </a>
            </div>
          </div>

          <div class="col-3 d-flex align-items-center">
            <label class="col-5">DISPATCH FROM</label>
            <div class="col-7">
              <div class="row">
                <div [ngClass]="isShowUser && orderHeader?.orderNumber.startsWith('T') ? 'col-10' : 'col-12'">
                  <div *ngIf="!isNonCorpOrder(); else nonCorpOrder" class="w-100 my-auto">
                    <ng-select formControlName="branch" [appendTo]="'body'" [clearable]="false" class="custom">
                      <ng-option *ngFor="let branch of branchList" [value]="branch.BranchCode">
                        {{ branch.BranchDescription }} ({{ branch.BranchCode }})
                      </ng-option>
                      <ng-template ng-label-tmp let-item="item">
                        {{ item | branch : branchList }}
                      </ng-template>
                    </ng-select>
                  </div>
                  <ng-template #nonCorpOrder>
                    <div
                      class="w-100 my-auto"
                      [ngClass]="{
                        'border border-danger rounded': form.controls.shipToNumber.errors
                      }"
                    >
                      <ng-select
                        *ngIf="orderHeader?.readOnlyYN === 'N'"
                        formControlName="shipToNumber"
                        [appendTo]="'body'"
                        [clearable]="false"
                        class="custom"
                      >
                        <ng-option *ngFor="let store of nonCorpStoreList" [value]="store.customerNumber">
                          {{ store.customerName }}
                          <ng-container *ngIf="store.address1">({{ store.address1 }}, {{ store.suburb }}, {{ store.postCode }}, {{ store.state }})</ng-container>
                        </ng-option>
                      </ng-select>
                      <div *ngIf="orderHeader?.readOnlyYN === 'Y'" class="form-control overflow-hidden text-truncate">
                        {{ orderHeader.shipToName }}
                      </div>
                    </div>
                  </ng-template>
                </div>
                <span class="my-auto" *ngIf="isShowUser && orderHeader?.quickSaleYN !== 'Y' && orderHeader?.orderNumber.startsWith('T')">
                  <mat-icon
                    [title]="orderHeader?.nonCorpStoreSaleYN === 'Y' ? 'Switch to ARB branch' : 'Switch to Non-Corp store'"
                    (click)="switchShowOrder()"
                  >
                    compare_arrows
                  </mat-icon>
                </span>
              </div>
            </div>
          </div>

          <div class="col-3 d-flex align-items-center">
            <label class="col-5">ORDER TYPE</label>
            <div class="col-7">
              <ng-select
                formControlName="orderType"
                [appendTo]="'body'"
                [clearable]="false"
                [ngClass]="{
                  'border border-danger rounded': form.controls.orderType.errors
                }"
                [readonly]="orderHeader?.readOnlyYN === 'Y'"
              >
                <ng-option *ngFor="let orderType of orderTypes" [value]="orderType.OrderTypeCode">
                  {{ orderType.OrderTypeDescription }}
                </ng-option>
              </ng-select>
            </div>
          </div>

          <div class="col-3 d-flex align-items-center">
            <label class="col-5">CURRENCY</label>
            <div class="col-3">
              <input class="form-control w-100" formControlName="currencyCode" [readonly]="orderHeader?.readOnlyYN === 'Y'" />
            </div>
          </div>

          <div class="col-3 d-flex align-items-center">
            <label class="col-5">REFERENCE <span *ngIf="isOrderReferenceRequired" style="color: red">*</span></label>
            <div class="col-7">
              <input
                class="form-control"
                formControlName="reference"
                maxlength="25"
                [class.is-invalid]="isOrderReferenceRequired"
                [readonly]="orderHeader?.readOnlyYN === 'Y'"
              />
            </div>
          </div>

          <div class="col-3 d-flex align-items-center">
            <label class="col-5">ORDER NO</label>
            <div class="col-7">
              <input class="form-control" formControlName="orderNumber" [readonly]="orderHeader?.readOnlyYN === 'Y'" />
            </div>
          </div>

          <div class="col-3 d-flex align-items-center">
            <label class="col-5">REQUEST DATE</label>
            <div class="col-7">
              <div class="input-group">
                <input
                  class="form-control"
                  name="d1"
                  ngbDatepicker
                  #d1="ngbDatepicker"
                  formControlName="requestDate"
                  [placement]="'bottom'"
                  [class.is-invalid]="form.get('requestDate').errors?.invalid"
                  container="body"
                  [readonly]="orderHeader?.readOnlyYN === 'Y'"
                />
                <div class="input-group-append">
                  <button
                    *ngIf="orderHeader?.readOnlyYN === 'N'"
                    class="btn btn-secondary calendar text-white btn-picker"
                    (click)="d1.toggle()"
                    type="button"
                  ></button>
                </div>
              </div>
            </div>
          </div>

          <div class="col-3 d-flex align-items-center">
            <label class="col-5">ORDER NOTES</label>
            <div class="col-3">
              <button type="button" class="btn btn-secondary mx-0 w-100" (click)="openNotesModal()">
                <small>Notes</small>
                <span class="badge badge-light ml-1">{{ allAttachments?.length }}</span>
              </button>
            </div>
          </div>

          <div *ngIf="!isExternalUser && orderHeader?.quickSaleYN !== 'Y'" class="col-3 d-flex align-items-center">
            <label class="col-5">CUSTOMER NOTES</label>
            <div class="col-7">
              <mat-icon
                *ngIf="isAlertMessage"
                class="text-danger mr-3"
                [routerLink]="['/pos/customers/' + selectedCustomer?.CustomerNumber + '/notes']"
              >
                alarm
              </mat-icon>
              <button type="button" class="btn mr-4" [routerLink]="['/pos/customers/' + selectedCustomer?.CustomerNumber + '/notes']">VIEW</button>
              <button
                type="button"
                class="btn mr-4"
                [routerLink]="['/pos/customers/' + selectedCustomer?.CustomerNumber + '/notes']"
                [queryParams]="{ openNewNote: 'true' }"
              >
                ADD
              </button>
            </div>
          </div>

          <div class="col-3 d-flex align-items-center" *ngIf="isCreditOrder">
            <label class="col-5">REASON *</label>
            <div class="col-7">
              <ng-select
                [placeholder]="'Select reason for credit'"
                [appendTo]="'body'"
                [class.is-invalid]="!form.controls.creditReason.value"
                formControlName="creditReason"
                [readonly]="orderHeader?.readOnlyYN === 'Y'"
              >
                <ng-option *ngFor="let reason of creditReasonList" [value]="reason.code"> {{ reason.description }} ({{ reason.code }}) </ng-option>
              </ng-select>
            </div>
          </div>

          <div class="col-3 d-flex align-items-center">
            <label class="col-5 d-flex align-items-center">
              VEHICLE
              <span class="px-1"></span>
              <mat-icon (click)="openEditVehicleModal()" *ngIf="isVehicleDataReady && orderHeader?.readOnlyYN === 'N'" title="Edit vehicle">
                edit
              </mat-icon>
              <mat-icon *ngIf="orderHeader?.readOnlyYN === 'N'" (click)="clearVehicle()" class="my-auto" title="Remove vehicle">clear</mat-icon>
            </label>
            <div class="col-7 pr-2">
              <input class="form-control" formControlName="vehicle" [readonly]="orderHeader?.readOnlyYN === 'Y'" />
            </div>
          </div>
          <div class="col-3 d-flex align-items-center">
            <div class="col-3">
              <input class="form-control" formControlName="vehicleColour" [readonly]="orderHeader?.readOnlyYN === 'Y'" />
            </div>
            <div
              *ngIf="
                form.controls.vehicleColour.value !== ' ' &&
                form.controls.vehicleColour.value !== 'TBA TO BE ADVISED' &&
                orderHeader?.readOnlyYN !== 'Y'
              "
              class="col-2 px-1"
            >
              <mat-icon (click)="colourAll()" title="Use vehicle colour for all paintable lines"> brush </mat-icon>
            </div>
          </div>
        </div>
        <div class="row mt-2 mx-0">
          <div *ngIf="orderHeader?.readOnlyYN === 'N'" class="col-3 d-flex align-items-center">
            <label class="col-5">HOLD CODES</label>
            <div class="col-7">
              <ng-select
                formControlName="holdCodes"
                [appendTo]="'body'"
                [searchable]="true"
                [readonly]="orderHeader?.readOnlyYN === 'Y'"
                placeholder="Select"
              >
                <ng-option *ngFor="let code of allHoldCodeList" [value]="code"> ({{ code.code }}) {{ code.description }} </ng-option>
              </ng-select>
            </div>
          </div>
          <div class="col flex-wrap d-flex align-items-center">
            <ul *ngFor="let code of selectedHoldCodeList" [ngStyle]="{ display: 'inline-block' }" class="my-0">
              <li [ngStyle]="{ float: 'left', color: code.readOnlyYN === 'Y' ? 'red' : 'black' }">
                ({{ code.holdCode || code.code }}) {{ code.description }}
                <mat-icon
                  *ngIf="orderHeader?.readOnlyYN !== 'Y' && code.readOnlyYN !== 'Y'"
                  [ngStyle]="{ 'vertical-align': 'bottom' }"
                  (click)="removeHoldCode(code)"
                  title="Remove hold code"
                >
                  delete
                </mat-icon>
              </li>
            </ul>
          </div>
        </div>
        <div class="row mt-4 mx-0">
          <div
            class="col form-inline"
            *ngIf="
              (!brandDefaults?.showMarketingOptInTF &&
                (orderType === 'SQ' || orderType === 'SO' || orderType === 'S6') &&
                customerType === 'RETAIL' &&
                marketingYN === '') ||
              (orderHeader?.quickSaleYN === 'Y' && customerType === 'RETAIL')
            "
          >
            <label class="text-danger custom">SIGN CUSTOMER UP TO ARB'S EMAIL SUBSCRIPTION ?</label>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" formControlName="marketingOptInYNFromOrder" [value]="true" />
              <label class="form-check-label" for="radio1"> YES </label>
            </div>
            <div *ngIf="orderHeader?.quickSaleYN !== 'Y'" class="form-check form-check-inline">
              <input class="form-check-input" type="radio" formControlName="marketingOptInYNFromOrder" [value]="false" />
              <label class="form-check-label" for="radio1"> NO </label>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <div class="my-auto">
            <span
              *ngFor="let message of headerMessages"
              [ngClass]="message.msgType === 'ERROR' ? 'text-danger' : message.msgType === 'WARN' ? 'text-warning' : 'text-success'"
              class="mx-2 text-uppercase"
            >
              {{ message.message }}
            </span>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="INFORMATION">
      <div class="py-4 information">
        <div class="row row-gap mx-0">
          <div class="col-3 d-flex align-items-center">
            <label class="col-5">SOLD TO</label>
            <div class="col-7 nowrap">
              <a class="form-control overflow-hidden" (click)="editCustomer()">
                <u>{{ form.controls.customerName.value }}</u>
              </a>
            </div>
          </div>
          <div class="col-3 d-flex align-items-center">
            <label class="col-5">TERMS</label>
            <div class="col-7">
              <input class="form-control" [value]="orderHeader.paymentTermDesc || ''" readonly />
            </div>
          </div>
          <div class="col-3 d-flex align-items-center">
            <label class="col-5">CHANNEL</label>
            <div class="col-7">
              <input class="form-control" [value]="orderHeader.priceChannelDesc || ''" readonly />
            </div>
          </div>
          <div class="col-3 d-flex align-items-center">
            <label class="col-5">ORDER DATE</label>
            <div class="col-4">
              <div class="form-control">
                {{ orderHeader.orderCreateDateString }}
              </div>
            </div>
          </div>
          <div class="col-3 d-flex align-items-center">
            <label class="col-5">SHIP TO</label>
            <div class="col-7">
              <a class="form-control" (click)="editShipToCustomer()">
                <u>{{ orderHeader.shipToName }}</u>
              </a>
            </div>
          </div>
          <div class="col-3 d-flex align-items-center">
            <label class="col-5">SALESPERSON</label>
            <div class="col-7">
              <input class="form-control" [value]="orderHeader.salespersonName" readonly />
            </div>
          </div>
          <div class="col-3 d-flex align-items-center">
            <label class="col-5">ORIGINAL ORDER</label>
            <div class="col-7">
              <a class="form-control" (click)="editOriginalOrder()">
                <u>{{ orderHeader.originalOrder }}</u>
              </a>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</form>

<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h3 class="arb-large-heading" id="modal-basic-title">
      {{
        isGarage
          ? 'SELECT VEHICLE'
          : isAddingCustomer
          ? 'NEW CUSTOMER'
          : isSelectingCustomer
          ? 'SELECT CUSTOMER'
          : isEditingCustomer || isEditingShiptoCustomer
          ? 'EDIT CUSTOMER'
          : 'ORDER NOTE'
      }}
    </h3>
  </div>
  <div class="modal-body">
    <div *ngIf="!isGarage && !isAddingCustomer && !isSelectingCustomer && !isEditingCustomer && !isEditingShiptoCustomer">
      <app-order-header-notes
        [preloadAttachments]="allAttachments"
        [orderNumber]="orderHeader.orderNumber"
        [orderType]="orderHeader.orderType"
        [orderCompany]="orderHeader.orderCompany"
        [isReadOnly]="isReadOnlyOrderNote"
        (reloadedAttachments)="loadAllAttachments.emit()"
        (closeActiveModal)="handleCloseModal($event)"
      ></app-order-header-notes>
    </div>
    <div *ngIf="isGarage">
      <app-vehicles-selection
        *ngIf="isTempVehicle && !isAddVehicleForCustomer; else selectVehicleTemplate"
        (cancelSelect)="d('Cross click')"
        (submitCar)="onSelectVehicle($event)"
      ></app-vehicles-selection>

      <ng-template #selectVehicleTemplate>
        <app-customers-vehicles
          [customerNumber]="orderHeader.customerNumber"
          [currentCustomerVehicleId]="orderHeader.customerVehicleID"
          [preselectedVehicle]="tempVehicle"
          [isAddVehicleForCustomer]="isAddVehicleForCustomer"
          (closeActiveModal)="handleCloseModal($event); isAddVehicleForCustomer = false"
          (selectVehicleEvent)="selectCustomerVehicle($event)"
        ></app-customers-vehicles>
      </ng-template>
    </div>
    <div *ngIf="isAddingCustomer">
      <app-customers-details-new
        [isForNewOrder]="true"
        [marketingOptInYNFromOrder]="marketingYNValue"
        (newCustomer)="fetchCustomerDetails($event)"
      ></app-customers-details-new>
    </div>
    <div *ngIf="isEditingCustomer">
      <app-customers-details
        [isForNewOrder]="true"
        [preSelectedCustomer]="selectedCustomer"
        [marketingOptInYNFromOrder]="marketingYNValue"
        (updatedCustomer)="fetchCustomerDetails($event)"
      ></app-customers-details>
    </div>
    <div *ngIf="isEditingShiptoCustomer">
      <app-customers-details
        [isForNewOrder]="true"
        [preSelectedCustomer]="shiptoCustomer"
        [marketingOptInYNFromOrder]="marketingYNValue"
        (updatedCustomer)="fetchCustomerDetails($event)"
      ></app-customers-details>
    </div>
    <div *ngIf="isSelectingCustomer && !isAddingCustomer">
      <pos-app-lib-customer-search (selectNewCustomer)="handleSelectCustomer($event)" [forOrderHeader]="true"></pos-app-lib-customer-search>
      <button class="btn btn-cancel mt-3 float-right" (click)="handleCloseModal(true)">CLOSE</button>
      <button class="btn btn-warning mt-3 float-right" (click)="addNewCustomer()" *ngIf="isSelectingCustomer">CREATE NEW CUSTOMER</button>
    </div>
  </div>
</ng-template>

<ng-template #releaseOrderHoldModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h3 class="arb-large-heading" id="modal-basic-title">RELEASE ORDER HOLD</h3>
  </div>
  <div class="modal-body">
    <div class="row pb-2">
      <div class="col-4 my-auto">
        <label class="my-auto">PASSWORD</label>
      </div>
      <div class="col-8">
        <input type="password" class="form-control" [(ngModel)]="holdCodePassword" (keyup.enter)="onRemoveHoldCode()" />
      </div>
    </div>
    <div class="row pb-2">
      <div class="col-12 d-flex justify-content-end">
        <button class="btn btn-cancel mr-3" (click)="closeHoldCodeModal()">CANCEL</button>
        <button class="btn text-white" (click)="onRemoveHoldCode()" [disabled]="!holdCodePassword || isBusy">
          ENTER
          <pos-app-spinner2 *ngIf="isBusy"></pos-app-spinner2>
        </button>
      </div>
    </div>
  </div>
</ng-template>
