import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ORDERS_FEATURE_KEY, OrdersState } from './orders.reducer';

// Lookup the 'Orders' feature state managed by NgRx
const getOrdersState = createFeatureSelector<OrdersState>(ORDERS_FEATURE_KEY);

const getError = createSelector(
  getOrdersState,
  (state: OrdersState) => state.error
);

const getloadingOrderFilterLists = createSelector(
  getOrdersState,
  (state: OrdersState) => state.loadingOrderFilterLists
);
const getOrderFilterLists = createSelector(
  getOrdersState,
  (state: OrdersState) => state.orderFilterLists
);

const getOrderSummary = createSelector(getOrdersState, (state: OrdersState) => {
  if (!state.orderSummary) {
    return null;
  }
  const orderSummaryList = state.orderSummary.map((orderSummary) => {
    return {
      ...orderSummary,
      RevenueBranchName: `${orderSummary.RevenueBranchName} (${orderSummary.RevenueBranch.trim()})`,
      StockingBranchName: `${orderSummary.StockingBranchName} (${orderSummary.StockingBranch.trim()})`,
      ShipToName: `${orderSummary.ShipToName} (${orderSummary.ShipToNumber})`,
      holdCodeDescription:
        orderSummary.holdCodeDescription === ''
          ? ''
          : `${orderSummary.holdCodeDescription} (${orderSummary.HoldCode})`,
    };
  });

  orderSummaryList.sort((a, b) => {
    return parse(b.InvoiceDate) - parse(b.InvoiceDate);
  });

  return orderSummaryList;
});
const getSearchingOrderSummary = createSelector(
  getOrdersState,
  (state: OrdersState) => state.searchingOrderSummary
);

const getDynamicFilterConfig = createSelector(
  getOrdersState,
  (state: OrdersState) => {
    if (!state.orderFilterLists) {
      return null;
    }
    const dynamicFilterConfig = {
      orderStatus: {
        type: 'list',
        values: ['HELD', 'PROCESSING', 'BACKORDER', 'OPEN', 'COMPLETE'],
        label: 'Order Status',
      },
      fitOrderYN: {
        type: 'boolean',
        label: 'Fit Order',
      },
      enteredBy: {
        type: 'text',
        label: 'Entered by',
      },
      customerChannel: {
        type: 'api-list',
        values: state.orderFilterLists.customerChannelList,
        label: 'Customer Channel',
        textField: 'CustomerChannelDescription',
        valueField: 'CustomerChannelCode',
      },
      customerPo: {
        type: 'text',
        label: 'Customer Reference',
      },
      heldOrderFlag10: {
        type: 'boolean',
        label: 'Held Order',
      },
      // orderDateRange: {
      //   type: 'dateRange',
      //   label: 'Order Date',
      //   fromKey: 'ordDateFrom',
      //   toKey: 'ordDateTo'
      // },
      invoiceDateRange: {
        type: 'dateRange',
        label: 'Invoice Date',
        fromKey: 'invDateFrom',
        toKey: 'invDateTo',
      },
      requestDateRange: {
        type: 'dateRange',
        label: 'Request Date',
        fromKey: 'reqDateFrom',
        toKey: 'reqDateTo',
      },
      vehicleGroupString: {
        type: 'text',
        label: 'Vehicle Group',
      },
      vehicleID: {
        type: 'text',
        label: 'Specific Vehicle',
      },
      vehicleRego: {
        type: 'text',
        label: 'Vehicle Registration',
      },
      orderNumber: {
        type: 'number',
        label: 'Order Number',
      },
      invoiceNumber: {
        type: 'number',
        label: 'Invoice Number',
      },
      partNumber: {
        type: 'fuzzySearch',
        label: 'Part Number',
        valueField: 'ProductCode',
        endpoint: '/item/ItemSearch',
      },
      productGroup: {
        type: 'api-list',
        values: state.orderFilterLists.productGroupList,
        label: 'Product Group',
        textField: 'ProductGroupDescription',
        valueField: 'ProductGroupCode',
      },
      revenueBunit: {
        type: 'api-list',
        values: state.orderFilterLists.branchList,
        label: 'Revenue Branch',
        textField: 'BranchDescription',
        valueField: 'BranchCode',
      },
    };
    return dynamicFilterConfig;
  }
);

const getLoadingActiveOrderHeader = createSelector(
  getOrdersState,
  (state: OrdersState) => state.loadingActiveOrderHeader
);

const getActiveOrderHeader = createSelector(
  getOrdersState,
  (state: OrdersState) => state.activeOrderHeader
);

const getTotalHistoricalPaymentAmount = createSelector(
  getOrdersState,
  (state: OrdersState) => state.totalHistoricalPaymentAmount
);

const getLoadingStagingOrder = createSelector(
  getOrdersState,
  (state: OrdersState) => state.loadingStagingOrder
);

const getCountOrderLineLoading = createSelector(
  getOrdersState,
  (state: OrdersState) => state.countOrderLineLoading
);

const getStagingOrder = createSelector(
  getOrdersState,
  (state: OrdersState) => state.stagingOrder
);

const getValidatingOrder = createSelector(
  getOrdersState,
  (state: OrdersState) => state.validatingOrder
);

const getOrderValidated = createSelector(
  getOrdersState,
  (state: OrdersState) => !(state.stagingOrder || []).some((item) => item.validateRequiredYN === 'Y' && item.lineValidatedYN !== 'Y')
);

const getOrderMessages = createSelector(
  getOrdersState,
  (state: OrdersState) => state.orderMessages
);

const getHeaderMessages = createSelector(
  getOrdersState,
  (state: OrdersState) => state.headerMessages
);

const getFinalisedOrder = createSelector(
  getOrdersState,
  (state: OrdersState) => state.finalisedOrder
);

const getIsFetchingJdeOrder = createSelector(
  getOrdersState,
  (state: OrdersState) => state.isFetchingJdeOrder
);

const getLoadingPendingPayments = createSelector(
  getOrdersState,
  (state: OrdersState) => state.loadingPendingpayments
);

const getPaymentInfo = createSelector(
  getOrdersState,
  (state: OrdersState) => state.paymentInfo
);

const getAddItemProcessed = createSelector(
  getOrdersState,
  (state: OrdersState) => state.addItemProcessed
);

const getLoadedGiftCards = createSelector(
  getOrdersState,
  (state: OrdersState) => state.loadedGiftCards
);

const getPendingLoadAmounts = createSelector(
  getOrdersState,
  (state: OrdersState) => state.pendingLoadAmounts
);

const getLoadingParkedOrdersList = createSelector(
  getOrdersState,
  (state: OrdersState) => state.parkedOrdersList
);

const getViewSettings = createSelector(
  getOrdersState,
  (state: OrdersState) => state.viewSettings
);

export const ordersQuery = {
  getError,
  getloadingOrderFilterLists,
  getOrderFilterLists,
  getOrderSummary,
  getSearchingOrderSummary,
  getDynamicFilterConfig,
  getLoadingActiveOrderHeader,
  getActiveOrderHeader,
  getLoadingStagingOrder,
  getCountOrderLineLoading,
  getStagingOrder,
  getValidatingOrder,
  getOrderValidated,
  getOrderMessages,
  getHeaderMessages,
  getFinalisedOrder,
  getIsFetchingJdeOrder,
  getLoadingPendingPayments,
  getPaymentInfo,
  getAddItemProcessed,
  getLoadedGiftCards,
  getPendingLoadAmounts,
  getLoadingParkedOrdersList,
  getViewSettings,
  getTotalHistoricalPaymentAmount
};

function parse(value) {
  if (typeof value === 'string' && value.includes('/')) {
    const str = value.split('/');

    const year = Number('20' + str[2]);
    const month = Number(str[1]);
    const day = Number(str[0]);

    return new Date(year, month, day).getTime();
  } else if (typeof value === 'string' && value === '') {
    return 0;
  }
}
