<div>
  <pos-app-spinner [show]="isBusy"></pos-app-spinner>
  <div
    class="container-fluid py-4"
    *ngIf="{ values: orderFilterLists$ | async } as orderFilterLists"
  >
    <div class="row">
      <div class="col-12">
        <app-static-filters
          [branchList]="orderFilterLists?.values?.branchList"
          [orderTypeList]="orderFilterLists?.values?.orderTypeList"
          (filterValuesChange)="staticfilteredValueChangeHandler($event)"
          [selectedCustomerNumber]="selectedCustomerNumber"
          [preSelectedCustomer]="
            selectedCustomerNumber && selectedCustomerNumber !== ''
          "
          [orderType]="orderType"
        ></app-static-filters>
      </div>
    </div>
    <div class="row py-3">
      <div class="col-12">
        <app-dynamic-filters
          [filters]="dynamicFilterConfig$ | async"
          (filterValuesChange)="dynamicFilterValues = $event"
        ></app-dynamic-filters>
      </div>
    </div>
    <div class="pb-2 text-right">
      <button
        class="btn btn-secondary"
        (click)="searchOrders()"
        [disabled]="!isFormValid"
      >
        FIND
      </button>
    </div>
    <div class="row" *ngIf="orderSummary$ | async">
      <div class="col-12">
        <app-orders-result-grid
          [gridResult]="orderSummary$ | async"
          [orderType]="staticFilterValues.orderType"
          (getOrderFromJDE)="fetchOrderFromJDE($event)"
          (sendEmail)="sendEmail($event)"
          [isExternal]="isExternalUser$ | async"
          [securityFunctions]="securityFunctions$ | async"
        ></app-orders-result-grid>
      </div>
    </div>
  </div>
</div>
