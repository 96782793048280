import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OmeDetails, WarrantyFault } from '../../../../core/models/warranty-form.models';

@Component({
  selector: 'app-warrantyform-ome',
  templateUrl: './warrantyform-ome.component.html',
  styleUrls: ['./warrantyform-ome.component.scss']
})
export class WarrantyformOmeComponent implements OnInit, OnDestroy, OnChanges {
  @Input() warrantyFaultList: WarrantyFault[];
  @Output() omeValue = new EventEmitter<{
    value: OmeDetails;
    isValid: boolean;
  }>();
  omeForm: FormGroup;
  unSubscribe$ = new Subject<void>();
  constructor(
    private fb: FormBuilder,
  ) {
    this.omeForm = this.fb.group({
      faultDate: ['', [Validators.required]],
      workOrderBuildCode: [''],
      distanceTravelledSinceFit: [1, [Validators.required, Validators.min(1)]],
      faultCode: ['', [Validators.required]],
      wheelTyreSize: ['', [Validators.required]]
    });
  }

  ngOnInit() {
    this.omeValue.emit({
      value: this.omeForm.value,
      isValid: this.omeForm.valid
    });
    this.omeForm.valueChanges
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe(omeForm => {
        this.omeValue.emit({ value: omeForm, isValid: this.omeForm.valid });
      });
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.warrantyFaultList && changes.warrantyFaultList.currentValue) {
      this.warrantyFaultList.sort((a, b) => {
        var groupA = a.faultGroup.toUpperCase();
        var groupB = b.faultGroup.toUpperCase();
        if (groupA < groupB) {
          return groupA < groupB ? -1 : 1;
        }
      });
    }
  }

  onSelectDate(date: string) {
    this.omeForm.patchValue({
      faultDate: date
    });
  }
}
