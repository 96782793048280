import { Component, OnInit, AfterViewInit, OnDestroy, ElementRef, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { EpcApiService } from '../../services/epc-api.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OrdersState } from '../../../orders/+state/orders.reducer';
import { LoadParkedOrdersList } from '../../../orders/+state/orders.actions';
import { MessageService } from '@pos-app/core-ui';

@Component({
  selector: 'app-landing-main',
  templateUrl: './landing-main.component.html',
  styleUrls: ['./landing-main.component.scss'],
})
export class LandingMainComponent implements OnInit, AfterViewInit, OnDestroy {
  public landingContent: string;

  private unSubscribe$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<OrdersState>,
    private epcApiService: EpcApiService,
    private renderer: Renderer2,
    private element: ElementRef,
    private messageService: MessageService
  ) {}
  public ngAfterViewInit(): void {
    this.route.queryParams.pipe(takeUntil(this.unSubscribe$)).subscribe((params) => {
      let id = params['id']; // cedCategoryId
      if (id) {
        this.getCategoryContent(Number(id));
      } else {
        this.getLandingContent();
      }
    });
  }
  public ngOnInit(): void {
    this.store.dispatch(new LoadParkedOrdersList());

    this.renderer.listen(this.element.nativeElement, 'click', (event: MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();

      const elements = event.target['offsetParent'].getElementsByClassName('category-item');

      if (elements?.length > 0) {
        const link = elements[0].getAttribute('href');
        if (link.includes('category')) {
          const cedCategoryId = link.substring(link.lastIndexOf('/') + 1, link.length);
          this.messageService.trigger('selectCedCategory', cedCategoryId);
        } else {
          this.router.navigateByUrl(link);
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  public getLandingContent(): void {
    this.epcApiService
      .getPageContentStatic('LandingPageContent')
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((result) => {
        this.landingContent = result.content?.replace(/target=\"_self\"/g, 'target="_self" class="category-item"');
      });
  }

  public getCategoryContent(id: number): void {
    this.epcApiService
      .getStaticContent(id)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((result) => {
        this.landingContent = result;
      });
  }
}
