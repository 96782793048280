import { InjectionToken } from '@angular/core';
import { StoreConfig } from '@ngrx/store/src/store_module';

import * as fromVehicle from '../+store/vehicles';
import * as fromFilter from '../+store/filter';

export const SELECTED_VEHICLE_STORAGE_KEYS = new InjectionToken<keyof fromVehicle.IVehicleState[]>('VehiclesStorageKeys');
export const SELECTED_VEHICLE_LOCAL_STORAGE_KEY = new InjectionToken<string[]>('VehiclesStorage');
export const SELECTED_VEHICLE_CONFIG_TOKEN = new InjectionToken<StoreConfig<fromVehicle.IVehicleState, fromVehicle.VehicleActions>>('VehiclesConfigToken');

export const USE_VEHICLE_STORAGE_KEYS = new InjectionToken<keyof fromVehicle.IVehicleState[]>('UseVehicleStorageKey');
export const USE_VEHICLE_LOCAL_STORAGE_KEY = new InjectionToken<string[]>('UseVehicleStorage');
export const USE_VEHICLE_CONFIG_TOKEN = new InjectionToken<StoreConfig<fromVehicle.IVehicleState, fromVehicle.VehicleActions>>('UseVehicleConfigToken');

export const SHOW_UNIVERSAL_PRODUCTS_STORAGE_KEYS = new InjectionToken<keyof fromVehicle.IVehicleState[]>('ShowUniversalProductsStorageKey');
export const SHOW_UNIVERSAL_PRODUCTS_LOCAL_STORAGE_KEY = new InjectionToken<string[]>('ShowUniversalProductsStorage');
export const SHOW_UNIVERSAL_PRODUCTS_CONFIG_TOKEN = new InjectionToken<StoreConfig<fromVehicle.IVehicleState, fromVehicle.VehicleActions>>('ShowUniversalProductsConfigToken');

export const FILTER_STORAGE_KEYS = new InjectionToken<keyof fromFilter.IFilterState[]>('FilterStorageKeys');
export const FILTER_LOCAL_STORAGE_KEY = new InjectionToken<string[]>('FilterStorage');
export const FILTER_CONFIG_TOKEN = new InjectionToken<StoreConfig<fromFilter.IFilterState, fromFilter.FilterActions>>('FilterConfigToken');