import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Subject, of } from 'rxjs';
import { takeUntil, switchMap } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { customersQuery } from '../../../../customers/+state/customers.selectors';
import { SelectCountry } from '../../../../customers/+state/customers.actions';
import { MaintainCustomerVehicle } from '../../../+store/customers/customers.actions';

import { VehicleDescriptionSearchParameter } from '../../../+store/vehicles/vehicle';
import { IMainState } from '../../../+store';

import { OOEApiServiceProxy } from '../../../services/ooe-api-service-proxy';

@Component({
  selector: 'epc-customers-vehicles-details-new',
  templateUrl: './customers-vehicles-details-new.component.html',
  styleUrls: ['./customers-vehicles-details-new.component.scss'],
})
export class EpcCustomersVehiclesDetailsNewComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public currentCustomerNumber: string;
  @Input() public selectedVehicle: any;
  @Output() public vehicleAdded = new EventEmitter<string>();
  @Output() public cancelAdd = new EventEmitter<void>();

  public selectedCustomerNumber: string;
  public toAddDefaultVehicle = false;
  public formValid = false;
  public formDirty = false;
  public formValue = null;
  public selectedCustomerVehicleID: string;

  public isUpdating$ = this.store.select(customersQuery.getUpdatingCustomerVehicle);
  public isLoading$ = this.store.select(customersQuery.getLoadingCustomerVehicles);
  public stateList$ = this.store.select(customersQuery.getFilteredStates);
  public lookupLists$ = this.store.select(customersQuery.getLookupLists);
  public selectedCustomerState$ = this.store.select(customersQuery.getSelectedCustomerState);
  public selectedCustomerCountry$ = this.store.select(customersQuery.getSelectedCustomerCountry);

  private unSubscribe$ = new Subject<void>();

  constructor(private store: Store<IMainState>, private ooeApiServiceProxy: OOEApiServiceProxy) {}

  public ngOnInit(): void {
    this.selectedCustomerNumber = this.currentCustomerNumber;

    this.store
      .select(customersQuery.getToAddDefaultVehicle)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((x) => {
        this.toAddDefaultVehicle = x;
      });

    this.store
      .select(customersQuery.getCustomerVehicleID)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((res) => {
        this.selectedCustomerVehicleID = res;
      });
  }

   public ngOnChanges(changes: SimpleChanges): void {
      if (changes.currentCustomerNumber) {
        this.selectedCustomerNumber = this.currentCustomerNumber;
      }
  }

  public ngOnDestroy(): void {
    this.vehicleAdded.unsubscribe();
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  public formDirtyHandler(dirty): void {
    this.formDirty = dirty;
  }

  public addNewVehicle(): void {
    const addNewVehicleForm = {
      ...this.formValue,
      customerNumber: this.selectedCustomerNumber,
      defaultFlag: this.toAddDefaultVehicle ? '1' : '0',
    };

    addNewVehicleForm['actionCode'] = 'A';

    if (this.selectedVehicle.VehicleID) {
      addNewVehicleForm['vehicleID'] = this.selectedVehicle.VehicleID;

      this.store.dispatch(
        MaintainCustomerVehicle({
          payload: {
            newVehicle: addNewVehicleForm,
            partsVehicleID: this.selectedVehicle.partsVehicleID,
          },
        })
      );
    } else {
      let vehicleDescParam = new VehicleDescriptionSearchParameter();
      vehicleDescParam.jdeVehicleID = 0;
      vehicleDescParam.partsVehicleID = this.selectedVehicle.partsVehicleID;

      this.ooeApiServiceProxy
        .getVehicleDescription(vehicleDescParam)
        .pipe(takeUntil(this.unSubscribe$))
        .subscribe((result) => {
          if (result.SearchResults.length > 0) {
            addNewVehicleForm['vehicleID'] = result.SearchResults[0].jdeVehicleID.toString();
            this.store.dispatch(
              MaintainCustomerVehicle({
                payload: {
                  newVehicle: addNewVehicleForm,
                  partsVehicleID: this.selectedVehicle.partsVehicleID,
                },
              })
            );
          }
        });
    }

    this.isUpdating$
      .pipe(
        switchMap((isUpdating) => {
          if (!isUpdating) {
            return this.isLoading$;
          } else {
            return of(true);
          }
        }),
        takeUntil(this.unSubscribe$)
      )
      .subscribe((isLoading) => {
        if (!isLoading) {
          this.vehicleAdded.emit(this.selectedCustomerVehicleID);
        }
      });
  }

  public getFilteredStateList(countryCode): void {
    this.store.dispatch(new SelectCountry(countryCode));
  }

  public cancel(): void {
    this.cancelAdd.emit();
  }

  public getVehicleDescription(): void {
    return this.selectedVehicle.Model
      ? `${this.selectedVehicle.Make} ${this.selectedVehicle.Model} ${this.selectedVehicle.Year}
    ${this.selectedVehicle.Generation} ${this.selectedVehicle.Series}
    ${this.selectedVehicle.SubModel} ${this.selectedVehicle.BodyType}
    ${this.selectedVehicle.EngineType} ${this.selectedVehicle.EngineCode}
    ${this.selectedVehicle.DriveType} ${this.selectedVehicle.TransmissionType}
    ${this.selectedVehicle.Chassis}`
      : this.selectedVehicle.Make;
  }
}
