import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { map, takeUntil } from 'rxjs/operators';
import { ApiService } from '../../services/api.service';
import * as CoreUiActions from './core-ui.actions';
import * as fromCoreUi from './core-ui.reducer';
import { MessageService } from '../../services';

@Injectable()
export class CoreUiEffects {
  loadCoreUi$ = createEffect(() =>
    this.dataPersistence.fetch(CoreUiActions.loadCoreUi, {
      run: (
        action: ReturnType<typeof CoreUiActions.loadCoreUi>,
        state: fromCoreUi.CoreUiPartialState
      ) => {
        // Your custom service 'load' logic goes here. For now just return a success action...
        return CoreUiActions.loadCoreUiSuccess({ coreUi: [] });
      },

      onError: (action: ReturnType<typeof CoreUiActions.loadCoreUi>, error) => {
        console.error('Error', error);
        return CoreUiActions.loadCoreUiFailure({ error });
      },
    })
  );

  // navigateToUrl$: Observable<void> = ofType(navigateToUrl).
  navigateToUrl$ = createEffect(() =>
    this.dataPersistence.fetch(CoreUiActions.navigateToUrl, {
      run: (
        action: ReturnType<typeof CoreUiActions.navigateToUrl>,
        state: fromCoreUi.CoreUiPartialState
      ) => {
        // this.router.navigate([action.url]);
        if (action.url === 'history-back') {
          window.history.back();
        } else {
          this.router.navigateByUrl(action.url);
        }
      },
    })
  );

  creatQuickSale$ = createEffect(() =>
    this.dataPersistence.fetch(CoreUiActions.createQuickSale, {
      run: (
        action: ReturnType<typeof CoreUiActions.createQuickSale>,
        state: fromCoreUi.CoreUiPartialState
      ) => {
        this.messageService.createQuickSale();
      },
    })
  );

  loadCustomers$ = createEffect(() =>
    this.dataPersistence.fetch(CoreUiActions.searchCustomers, {
      run: (
        action: ReturnType<typeof CoreUiActions.searchCustomers>,
        state: fromCoreUi.CoreUiPartialState
      ) => {
        return this.apiService
          .customerSearch({
            customerNumber: 0,
            customerfuzzySearch: action.customerfuzzySearch,
            customerType: action.customerType,
          })
          .pipe(
            takeUntil(
              this.actions$.pipe(ofType(CoreUiActions.clearSearchData))
            ),
            map((result) => {
              return CoreUiActions.searchCustomersSuccess({ result });
            })
          );
      },
      onError: (
        action: ReturnType<typeof CoreUiActions.searchCustomers>,
        error
      ) => {
        return CoreUiActions.searchCustomersFailed(error);
      },
    })
  );

  getBrandDefaults$ = createEffect(() =>
    this.dataPersistence.fetch(CoreUiActions.loadBrandDefaults, {
      run: (
        action: ReturnType<typeof CoreUiActions.loadBrandDefaults>,
        state: fromCoreUi.CoreUiPartialState
      ) => {
        return this.apiService.fetchBrandDefaults().pipe(
          map((res) => {
            return CoreUiActions.loadBrandDefaultsSuccess({
              result: res.SearchResults[0],
            });
          })
        );
      },
      onError: (
        action: ReturnType<typeof CoreUiActions.loadBrandDefaults>,
        error
      ) => {
        return CoreUiActions.loadBrandDefaultsFailed(error);
      },
    })
  );

  getOverdueTaskAlert$ = createEffect(() =>
  this.dataPersistence.fetch(CoreUiActions.getOverdueAlertCounts, {
    run: (
      action: ReturnType<typeof CoreUiActions.getOverdueAlertCounts>,
      state: fromCoreUi.CoreUiPartialState
    ) => {
      return this.apiService.getOverdueTaskAlert().pipe(
        map((res) => {
          return CoreUiActions.getOverdueAlertCountsSuccess({
            alertCount: res.AlertCount,
          });
        })
      );
    },
    onError: (
      action: ReturnType<typeof CoreUiActions.getOverdueAlertCounts>,
      error
    ) => {
      return CoreUiActions.getOverdueAlertCountsError(error);
    },
  })
);


  constructor(
    private dataPersistence: DataPersistence<fromCoreUi.CoreUiPartialState>,
    private router: Router,
    private apiService: ApiService,
    private messageService: MessageService,
    private actions$: Actions
  ) {}
}
