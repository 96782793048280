<pos-app-spinner [show]="isThinking"></pos-app-spinner>
<div class="row">
  <div class="col-12 col-xl-6 pb-1 justify-content-end d-flex justify-content-xl-start">
    <div *ngIf="!isReadOnly && (!isExternalUser || (isExternalUser && orderHeader?.orderType === ORDER_TYPE.quoteOrder))" class="d-flex my-auto">
      <span class="my-auto text-uppercase"> Order Discount $ or % </span>
      <span class="px-1"></span>
      <form class="d-flex">
        <div class="w-1">
          <input type="text" class="form-control" [formControl]="discount" [readonly]="isReadOnly" />
        </div>
        <span class="px-1"></span>
        <button (click)="rePrice()" type="submit" class="btn btn-secondary" [disabled]="!discount.valid && rowData.length > 1">REPRICE</button>
      </form>
      <span class="px-1"></span>
      <button type="button" class="btn btn-secondary" [disabled]="!enableResetPrice" (click)="resetPrices()">RESET PRICE</button>
    </div>
  </div>
  <div class="col-12 col-xl-6">
    <div ngbDropdown class="d-flex justify-content-end" placement="top-right">
      <button *ngIf="isJDEOrder() && !isGridReadOnly" type="button" class="btn mr-4" (click)="onCopyOrder()">COPY ORDER</button>
      <button class="btn btn-secondary" id="dropdownBasic2" ngbDropdownToggle>VIEW SETTINGS</button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic2" class="dropdown-menu-right">
        <div class="showroom-toggle" *ngIf="!isExternalUser || userSecurityFunctions.includes(SEE_BUY_PRICE)">
          SHOW EX-TAX PRICES <span class="pr-2"></span>
          <label class="switch">
            <input class="my-auto" type="checkbox" [checked]="viewSettings.showExPrice" (change)="viewSettingsToggle('exPrice')" />
            <span class="slider round"></span>
          </label>
        </div>
        <div class="showroom-toggle">
          SHOW CANCELLED LINES <span class="pr-2"></span>
          <label class="switch">
            <input class="my-auto" type="checkbox" [checked]="viewSettings.showCancelled" (change)="viewSettingsToggle('cancelled')" />
            <span class="slider round"></span>
          </label>
        </div>
        <div class="showroom-toggle">
          SHOW INVOICED LINES <span class="pr-2"></span>
          <label class="switch">
            <input class="my-auto" type="checkbox" [checked]="viewSettings.showInvoiced" (change)="viewSettingsToggle('invoiced')" />
            <span class="slider round"></span>
          </label>
        </div>
        <div class="showroom-toggle">
          ENFORCE ASSOCIATED ITEMS RULES <span class="pr-2"></span>
          <label class="switch">
            <input class="my-auto" type="checkbox" [checked]="viewSettings.enforceItemRule" (change)="viewSettingsToggle('itemRule')" />
            <span class="slider round"></span>
          </label>
        </div>
        <div
          class="showroom-toggle"
          *ngIf="
            !isExternalUser || (isExternalUser && orderHeader?.orderType === ORDER_TYPE.quoteOrder && userSecurityFunctions.includes(SEE_BUY_PRICE))
          "
        >
          SHOW TAX YES/NO FLAG <span class="pr-2"></span>
          <label class="switch">
            <input class="my-auto" type="checkbox" [checked]="viewSettings.showTaxFlag" (change)="viewSettingsToggle('tax')" />
            <span class="slider round"></span>
          </label>
        </div>
        <div class="showroom-toggle">
          SHOW ATP ESTIMATE <span class="pr-2"></span>
          <label class="switch">
            <input class="my-auto" type="checkbox" [checked]="viewSettings.showATP" (change)="viewSettingsToggle('atp')" />
            <span class="slider round"></span>
          </label>
        </div>
      </div>
    </div>
  </div>
</div>

<ag-grid-angular
  #orderGrid
  class="ag-theme-balham datagrid"
  [ngClass]="{ readonly: isReadOnly }"
  [enableRangeSelection]="true"
  [rowDragManaged]="true"
  [context]="{ componentParent: this }"
  [frameworkComponents]="frameworkComponents"
  [gridOptions]="gridOptions"
  [rowClassRules]="rowClassRules"
  [processDataFromClipboard]="processDataFromClipboard"
  [getDataPath]="getDataPath"
  [treeData]="true"
  [groupDefaultExpanded]="-1"
  [domLayout]="'autoHeight'"
  [groupSuppressAutoColumn]="true"
  [tooltipShowDelay]="0"
  [immutableData]="true"
  [getRowNodeId]="getRowNodeId"
  (cellEditingStarted)="onCellEditingStarted()"
  (cellEditingStopped)="onCellEditingStopped($event)"
  (rowDragEnd)="onRowDragEnd($event)"
  (rowDragLeave)="onRowDragLeave($event)"
  (cellDoubleClicked)="onCellDoubleClicked($event)"
  (cellClicked)="onCellClicked($event)"
  (rowDataUpdated)="onDataRendered()"
  (gridReady)="onGridReady($event)"
>
</ag-grid-angular>

<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h3 class="arb-large-heading" id="modal-basic-title">
      {{ showPriceScales ? 'PRICE SCALES' : 'PRODUCT SEARCH' }}
    </h3>
  </div>
  <div class="modal-body">
    <app-order-grid-item-search
      *ngIf="!showPriceScales"
      [searchStr]="itemSearchStr"
      (addingItem)="addSearchedItem($event)"
      (closeActiveModal)="handleCloseModal($event)"
    ></app-order-grid-item-search>
    <pos-app-item-price-scales
      *ngIf="showPriceScales"
      [item]="itemPricesToShow"
      [displayOptions]="displayOptions"
      [isExternal]="isExternalUser"
      (closeActiveModal)="handleCloseModal($event)"
    >
    </pos-app-item-price-scales>
  </div>
</ng-template>

<app-orders-buyin-supplier
  *ngIf="addingBuyin || editingBuyin"
  [buyinProduct]="editingBuyin"
  [defaultMarkup]="brandDefaults.buyinMarkupPerc"
  (toAddBuyin)="handleBuyin($event)"
  (addItemsToOrder)="addItemsToOrder.emit({ items: $event, enforceItemRule: viewSettings.enforceItemRule })"
></app-orders-buyin-supplier>

<ng-template #lineAttachmentContent let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h3 class="arb-large-heading" id="modal-basic-title">LINE NOTE</h3>
  </div>
  <div class="modal-body">
    <app-line-order-attachments
      [orderDetails]="orderDetails"
      [orderType]="orderHeader.orderType"
      [orderCompany]="orderHeader.orderCompany"
      [isReadOnly]="isReadOnlyLineAttachment"
      (refreshOrderGrid)="refreshOrderGrid()"
      (closeActiveModal)="handleCloseModal($event)"
    ></app-line-order-attachments>
  </div>
</ng-template>
