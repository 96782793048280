import { Injectable } from '@angular/core';
import { Effect} from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { VehiclesPartialState } from './vehicles.reducer';
import {
  LoadVehicles,
  VehiclesLoaded,
  VehiclesLoadError,
  VehiclesActionTypes
} from './vehicles.actions';
import { ApiService } from '../../../../../../../libs/core-ui/src/lib/services/api.service';
import { map } from 'rxjs/operators';

@Injectable()
export class VehiclesEffects {
  @Effect() loadVehicles$ = this.dataPersistence.fetch(
    VehiclesActionTypes.LoadVehicles,
    {
      run: (action: LoadVehicles, state: VehiclesPartialState) => {
        // TODO revise if is initial is required
        const isInitial = Object.values(action.payload).filter(x => !!x).length === 0;
        return this.apiService
          .vehicleSearch(action.payload)
          .pipe(
            map(result => new VehiclesLoaded({ responnse: result, isInitial }))
          );
      },
      onError: (action: LoadVehicles, error) => {
        return new VehiclesLoadError(error);
      }
    }
  );

  constructor(
    private dataPersistence: DataPersistence<VehiclesPartialState>,
    private apiService: ApiService
  ) {}
}
