<pos-app-spinner [show]="updatingCustomerDetails$ | async"></pos-app-spinner>
<div [ngClass]="(isExternalUser$ | async) ? 'mb-5' : 'mb-3'">
  <app-customers-details-form
    *ngIf="selectedCustomerDetails && selectedCustomerDetails.CustomerType !== customerType.shipTo"
    (formChanged)="formValue = $event"
    (formIsValid)="formValid = $event"
    (formIsDirty)="formDirtyHandler($event)"
    [selectedCustomer]="selectedCustomerDetails"
    [lookupLists]="lookupLists$ | async"
    (submitByEnter)="formValid && updateRetailCustomer()"
    (selectedCountry)="getFilteredStateList($event)"
    [filteredStateList]="stateList"
    [brandDefaults]="brandDefaults$ | async"
    [marketingOptInYNFromOrderValue]="marketingOptInYNFromOrderValue"
  >
  </app-customers-details-form>

  <app-customers-ship-to-form
    *ngIf="selectedCustomerDetails && selectedCustomerDetails.CustomerType === customerType.shipTo"
    (formChanged)="formValue = $event"
    (formIsValid)="formValid = $event"
    (formIsDirty)="formDirtyHandler($event)"
    [selectedCustomer]="selectedCustomerDetails"
    [lookupLists]="lookupLists$ | async"
    (submitByEnter)="formValid && updateShipToCustomer()"
    (selectedCountry)="getFilteredStateList($event)"
    [filteredStateList]="stateList"
  >
  </app-customers-ship-to-form>
</div>

<div class="actions pb-5" *ngIf="selectedCustomerDetails && !(isExternalUser$ | async)">
  <button class="btn btn-cancel" (click)="cancel()">CANCEL</button>
  <button
    class="btn text-white"
    [disabled]="!formValid || !formDirty"
    (click)="selectedCustomerDetails.CustomerType === customerType.shipTo ? updateShipToCustomer() : updateRetailCustomer()"
  >
    SAVE
  </button>
</div>
