<div class="accordion-wrapper border-radius-none">
  <pos-app-spinner [show]="loadingTasksContentList"></pos-app-spinner>
  <accordion
    [isAnimated]="true"
    style="margin-top: 5px; margin-bottom: 5px"
    class="content-accordion border-radius-none"
    *ngFor="let searchResult of searchResults; index as i"
  >
    <accordion-group
      (isOpenChange)="onCollapse()"
      [isOpen]="accordionActiveIndex === i"
      (click)="toggle(i)"
      class="content-title border-radius-none"
      id="{{ searchResult.SequenceID }}"
    >
      <button
        class="content-button btn btn-link btn-block w-100 shadow-none border-radius-none"
        accordion-heading
        type="button"
      >
        <div class="row">
          <div class="col-10 text-left">
            <h6>{{ searchResult.SectionTitle }}</h6>
          </div>
          <div class="col-2 align-bottom">
            <mat-icon
              *ngIf="accordionActiveIndex != i"
              aria-hidden="false"
              class="float-right content-accordion-icon"
            >
              add
            </mat-icon>
            <mat-icon
              *ngIf="accordionActiveIndex === i"
              aria-hidden="false"
              class="float-right content-accordion-icon"
            >
              remove
            </mat-icon>
          </div>
        </div>
      </button>
      <div class="content-table-wrapper">
        <table class="content-table table table-striped border-radius-none">
          <tbody>
            <tr *ngFor="let contentFile of searchResult.ContentFileArray">
              <td>{{ contentFile.SequenceID }}.{{ contentFile.FileSeq }}</td>
              <td
                (click)="
                  onDownloadContent(
                    contentFile.SequenceID,
                    contentFile.FileSeq,
                    contentFile.FileLabel,
                    contentFile.AttachmentExist
                  )
                "
              >
                <a>
                  <h6>{{ contentFile.FileLabel }}</h6>
                  <p>{{ contentFile.Description }}</p>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </accordion-group>
  </accordion>
</div>
