import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ORDER_PAYMENT_TYPE } from '@pos-app/data';
import { FormControl, Validators } from '@angular/forms';
@Component({
  selector: 'app-orders-eftpos',
  templateUrl: './orders-eftpos.component.html',
  styleUrls: ['./orders-eftpos.component.scss'],
})
export class OrdersEftposComponent implements OnChanges {
  @Input() _id: string;
  @Input() loadAmount: number;
  @Input() outstanding: number;
  @Input() eftposDeviceName: string;
  @Input() registerID: string;
  @Input() orderNumber: string;
  @Input() fakeEFTPOSTF: boolean;

  @Output() paymentDetails = new EventEmitter<any>();
  @Output() cancel = new EventEmitter<any>();

  isLoading = false;
  amount = new FormControl(null, [Validators.required, Validators.pattern(/^-?\d*[.]?\d{0,2}$/)]);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loadAmount && this.amount) {
      this.amount.setValue(Number(this.loadAmount));
    }
  }

  makeTransaction(fakeTransactionType?: 'ERROR' | 'PENDING' ) {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.paymentDetails.emit({
      _id: this._id?.toString(),
      amount: parseFloat(this.amount.value),
      registerID: this.registerID,
      orderNumber: this.orderNumber,
      payInstrument: '?',
      paymentType: ORDER_PAYMENT_TYPE.eftpos,
      eftposDeviceName: this.eftposDeviceName,
      reference: '',
      fakeTransactionType: fakeTransactionType
    });
  }
}
