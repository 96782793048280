import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FeedbackType, KEYS, OpenOrder } from '@pos-app/data';
import { combineLatest, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { ApiService, ConfigService, ElectronService } from '../../services';
import { ClipboardService } from 'ngx-clipboard';
import moment from 'moment';

@Component({
  selector: 'pos-app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit {
  @ViewChild('content') modalBox: any;
  @Input() isExternalUser: boolean;
  @Input() customerDetails: any;
  @Output() closeFeedback = new EventEmitter<void>();
  openOrder: OpenOrder;
  feedbackTypeList: FeedbackType[];
  unSubscribe$ = new Subject<void>();
  feedbackType = '';
  feedbackDetails = null;
  emailString = '';
  DATA_HELPDESK =
    'https://arbcorporation.atlassian.net/servicedesk/customer/portal/2/group/44/create/141';
  SYSTEM_HELPDESK =
    'https://arbcorporation.atlassian.net/servicedesk/customer/portal/2/group/43/create/140';
  version: string;
  registerId: string;
  timeStamp: string;
  isLoading: boolean;
  isElectron: boolean;

  constructor(
    private modalService: NgbModal,
    private apiService: ApiService,
    private clipboardService: ClipboardService,
    private configService: ConfigService,
    private electronService: ElectronService
  ) {}

  ngOnInit() {
    this.isElectron = this.configService.AppConfig.isElectron;
    this.version = localStorage.getItem(KEYS.version);
    const cashDrawer = localStorage.getItem(KEYS.cashDrawer)
      ? JSON.parse(localStorage.getItem(KEYS.cashDrawer))
      : null;
    this.registerId = cashDrawer
      ? `${cashDrawer.registerID}_${cashDrawer.branch}`
      : '';
    this.clipboardService.configure({ cleanUpAfterCopy: true });
    const now = moment();
    this.timeStamp = now.format('DD/MM/YYYY HH:mm');

    this.isLoading = true;
    combineLatest([
      this.apiService.getFeedbackTypes(),
      this.apiService.fetchUserState(),
    ])
      .pipe(
        finalize(() => (this.isLoading = false)),
        takeUntil(this.unSubscribe$)
      )
      .subscribe(([feedbackTypes, userState]) => {
        this.isLoading = false;
        this.feedbackTypeList = feedbackTypes.SearchResults;
        this.openOrder = userState.OpenOrder;
        this.modalService
          .open(this.modalBox, { backdrop: 'static', size: 'lg' })
          .result.then(
            () => {
              this.closeFeedback.emit();
            },
            () => {
              this.closeFeedback.emit();
            }
          );
      });
  }

  gatherInformation() {
    // Keep this indentation for clipboard
    this.feedbackDetails = this.feedbackDetailsString();
    if (this.isExternalUser) {
      const detailsForEmail = this.feedbackDetailsString(true);
      this.emailString = `mailto:arbonline@arb.com.au?Subject=ArbOnline Feedback&body=${detailsForEmail}`;
    }
    this.clipboardService.copy(this.feedbackDetails);
  }

  cancel() {
    this.modalService.dismissAll();
    this.closeFeedback.emit();
  }

  feedbackDetailsString(emailFormat = false) {
    const token = emailFormat ? '%0A' : '';
    return `Feedback type: ${this.feedbackType}${token}
Time: ${this.timeStamp}${token}
URL: ${location.href}${token}
Version: ${this.version}${token}
Logged In Username: ${this.openOrder.userName}${token}
User Branch: ${this.customerDetails.defaultBranch} - ${this.customerDetails.defaultBranchName}${token}
Sales Order Number: ${this.openOrder.orderNumber}${token}
Customer Number: ${this.openOrder.customerNumber}${token}
POS Register ID: ${this.registerId}${token}
JDE Vehicle ID: ${this.openOrder.partsVehicleID}${token}
Customer Vehicle ID: ${this.openOrder.customerVehicleID}${token}
Vehicle Description: ${this.openOrder.vehicleDescription}${token}
---------------------------------------------------------------
Part Number:${token}
Problem Description:${token}
    `;
  }

  get feedbackUrl(): string {
    return this.isExternalUser
      ? this.emailString
      : this.feedbackType === 'DATA'
      ? this.DATA_HELPDESK
      : this.SYSTEM_HELPDESK;
  }

  get feedbackTitle(): string {
    return this.isExternalUser ? 'EMAIL TO ARB' : 'GO TO HELPDESK';
  }

  openFeedbackInNativeBrowser(url: string) {
    this.electronService.openLinkNatively(url);
  }
}
