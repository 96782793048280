import { getLocaleNumberSymbol, NumberSymbol } from '@angular/common';

//FYI: decimal thousand separator or dot thousand seprator cannot ne replace for all value

export function getCurrencyValueFormat(currencyValue, locale) {
  if (currencyValue.match(/\s+/)) {
    currencyValue = currencyValue.replace(/\s/g, '');
  }
  if (getLocaleNumberSymbol(locale, NumberSymbol.CurrencyGroup) === ',') {
    currencyValue = currencyValue.replace(/,/g, '');
    return getCurrencyDecimalFormat(currencyValue, locale);
  }
  if (getLocaleNumberSymbol(locale, NumberSymbol.CurrencyGroup) === '.') {
    currencyValue = currencyValue.replace(/./g, '');
    return getCurrencyDecimalFormat(currencyValue, locale);
  } else {
    return getCurrencyDecimalFormat(currencyValue, locale);
  }
}

export function checkWhiteSpace(value: string) {
  return value.replace(/\s/g, '');
}

export function getCurrencyDecimalFormat(currencyValue, locale) {
  if (getLocaleNumberSymbol(locale, NumberSymbol.CurrencyDecimal) === ',') {
    //Check if decimal has comma as separator and replace with dot
    return currencyValue.replace(/,/g, '.');
  } else {
    return currencyValue;
  }
}
