<div
  class="container-fluid product-not-found arb-clear-text-for-table"
  *ngIf="!productOutput.productInfo && productNotFound"
>
  Sorry! We cannot find the product.
</div>
<div
  class="product-loading d-flex align-items-center justify-content-center"
  *ngIf="!productOutput.productInfo && isProductLoading"
>
  <i class="pi pi-spin pi-spinner"></i>Retrieving Product Information...
</div>
<div
  class="container-fluid product-container arb-clear-text-for-table"
  *ngIf="productOutput.productInfo"
  [busyIf]="isProductLoading"
>
  <app-product-product-summary
    [product]="productOutput.productInfo"
    [hasAssociatedFittmentWarning]="hasAssociatedFittmentWarning"
    [hasSelectedVehicle]="!!selectedVehicleId"
    [isFitted]="isFitted"
  ></app-product-product-summary>
  <app-product-prices-stocks
    #pricesStocks
    [stocks]="mainProduct.stocks"
    [basedPrices]="mainProduct.prices"
    [images]="productOutput.images"
  ></app-product-prices-stocks>
  <app-product-add-to-order
    #addToOrder
    [product]="mainProduct"
    [readOnlyEPCTF]="readOnlyEPCTF"
    [currencyCode]="currencyCode"
    [totalPrice]="configuredPrices[0]"
    [hasAssociatedItemWarning]="hasAssociatedItemWarning"
    (addedToOrder)="addItemToOrder($event)"
    (updatedQuantity)="updateMainProductPrices()"
  ></app-product-add-to-order>

  <div class="row" style="margin-top: 20px">
    <div class="col-md-12">
      <ul
        ngbNav
        #nav="ngbNav"
        [(activeId)]="active"
        class="nav-tabs"
        [destroyOnHide]="true"
      >
        <li [ngbNavItem]="1">
          <a class="arb-field-label" ngbNavLink>Associated Items</a>
          <ng-template ngbNavContent>
            <app-product-associated-items
              [items]="associatedItems"
              [currencyCode]="currencyCode"
              [mainQuantity]="mainProduct.quantity"
              [fitLabel]="productOutput.fitDescription"
              [addFitLabel]="productOutput.addFitDescription"
              (orderItemsChanged)="updateOrderItem($event)"
              (changeEAIRules)="recievechangeEAIRules($event)"
            ></app-product-associated-items>
          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a class="arb-field-label" ngbNavLink>Product Description</a>
          <ng-template ngbNavContent>
            <app-product-product-details
              [product]="productOutput.productInfo"
            ></app-product-product-details>
          </ng-template>
        </li>
        <li [ngbNavItem]="3">
          <a class="arb-field-label" ngbNavLink>Tech Specs</a>
          <ng-template ngbNavContent>
            <app-product-tech-specs
              [productInfoAttribute]="productInfoAttribute"
              [productFitmentInfoAttribute]="productFitmentInfoAttribute"
            ></app-product-tech-specs>
          </ng-template>
        </li>
        <li [ngbNavItem]="4">
          <a class="arb-field-label" ngbNavLink>Applications</a>
          <ng-template ngbNavContent>
            <app-product-applications
              [applications]="productOutput.applications"
              [selectedVehicleId]="selectedVehicleId"
            ></app-product-applications>
          </ng-template>
        </li>
        <li [ngbNavItem]="5">
          <a class="arb-field-label" ngbNavLink>Product Resources</a>
          <ng-template ngbNavContent>
            <app-product-fitting-instructions
              [supplierUrl]="productOutput.productInfo.supplierUrl"
              [supersededProductNumber]="
                productOutput.productInfo.supersededProductNumber
              "
              [fittingInstructions]="productOutput.fittingInstructions"
            ></app-product-fitting-instructions>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class=" "></div>
    </div>
  </div>
</div>
