import {
  GridOptions,
  Module,
  ModuleRegistry,
} from 'ag-grid-community';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  LOCALE_ID,
} from '@angular/core';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { OrderSearchGridActionRendererComponent } from '../orderSearchGridActionRenderer/orderSearchGridActionRender.component';
import { ORDER_TYPE, SECURITY_FUNCTION } from '@pos-app/data';
import { getMasterGrid, getDetailedGrid } from './orders-result-grid-model';
import { ApiService } from '@pos-app/core-ui';

@Component({
  selector: 'app-orders-result-grid',
  templateUrl: './orders-result-grid.component.html',
  styleUrls: ['./orders-result-grid.component.scss'],
})
export class OrdersResultGridComponent implements OnInit, OnChanges {
  @Input() gridResult: any;
  @Input() orderType: string;
  @Input() isExternal: boolean;
  @Input() securityFunctions: string[];
  @Output() getOrderFromJDE = new EventEmitter<any>();
  @Output() sendEmail = new EventEmitter<any>();

  searchValue: '';
  public columnDefs;
  public masterGridOptions: GridOptions;
  rowData = [];
  detailCellRendererParams: any;
  fromDate: NgbDate = null;
  toDate: NgbDate = null;
  invoicedFromDate: NgbDate = null;
  invoicedToDate: NgbDate = null;
  showSaleOrders = true;
  showCreditOrders = true;
  gridHeader: string;
  frameworkComponents: any;
  context: any;
  private gridApi;
  private gridColumnApi;

  constructor(
    private apiService: ApiService,
    private calendar: NgbCalendar,
    @Inject(LOCALE_ID) public locale: string
  ) {
    this.toDate = this.calendar.getToday();
    this.fromDate = this.calendar.getNext(this.calendar.getToday(), 'm', -2);
    this.frameworkComponents = {
      actionRenderer: OrderSearchGridActionRendererComponent,
    };
    this.context = { componentParent: this };
    this.masterGridOptions = getMasterGrid(this.orderType, this.locale);

    this.detailCellRendererParams = getDetailedGrid(
      this.orderType,
      this.apiService,
      this.locale
    );
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.gridResult && changes.gridResult.currentValue) {
      this.rowData = this.gridResult;
      this.gridHeader =
        this.orderType === ORDER_TYPE.saleOrder
          ? 'OPEN ORDERS'
          : this.orderType === ORDER_TYPE.quoteOrder
          ? 'QUOTES'
          : 'INVOICED ORDERS';
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    if (
      this.orderType === ORDER_TYPE.saleOrder ||
      this.orderType === ORDER_TYPE.quoteOrder
    ) {
      this.gridColumnApi.setColumnsVisible(
        ['InvoiceNumber', 'InvoiceDate'],
        false
      );

      if (this.orderType === ORDER_TYPE.quoteOrder) {
        this.gridColumnApi.setColumnsVisible(
          ['OutstandingAmount', 'PaymentsReceived', 'holdCodeDescription'],
          false
        );
      }
    } else {
      this.gridColumnApi.setColumnsVisible(
        ['PaymentsReceived', 'OrderStatus'],
        false
      );
    }

    if (
      this.isExternal &&
      this.securityFunctions.indexOf(SECURITY_FUNCTION.maintainOrders) === -1
    ) {
      this.gridColumnApi.setColumnsVisible(['action'], false);
    }
    this.autoSizeAll();
    this.gridApi.setSuppressClipboardPaste(true);
  }

  autoSizeAll() {
    const allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds, true);
    // need this to resize all columns
    setTimeout(() => {
      this.gridColumnApi.autoSizeColumns(allColumnIds, true);
    }, 50);
  }

  fetchOrderFromJDE(cell) {
    this.getOrderFromJDE.emit(cell.data);
  }

  emailInvoiceReprint(cell) {
    this.sendEmail.emit(cell.data);
  }
}
