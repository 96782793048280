import { IFilterState, IGlobalFilter } from './filter.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ICEDCategoryDto, IGetFilterDataOutput } from '../../models/service-proxies';
import { FILTER_FEATURE_KEY } from './filter.reducer';

const filterState = createFeatureSelector<IFilterState>(FILTER_FEATURE_KEY);

const getStateFilterData = createSelector(filterState, (state: IFilterState): IGetFilterDataOutput => state.filterData);

const getStateFilter = createSelector(filterState, (state: IFilterState): IGlobalFilter => state.filter);

const getCedCategories = createSelector(filterState, (state: IFilterState): ICEDCategoryDto[] => state.cedCategories);

export { getStateFilter, getStateFilterData, getCedCategories };
