import { Component } from '@angular/core';
import { ORDER_ITEM_NUMBER, ORDER_LINE_STATUS } from '@pos-app/data';
import { OrderGridComponent } from '../order-grid/order-grid.component';
import { ICellRenderer, ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'checkbox-cell',
  template: ` <input *ngIf="params.data.itemNumber !== ORDER_ITEM_NUMBER.subTotal" type="checkbox" (change)="onChange($event)" [hidden]="hideCheckBox()" [checked]="isChecked()" [disabled]="isDisabled()" />`,
})
export class CheckboxCellComponent implements ICellRenderer {
  public params: ICellRendererParams;
  public parentComponent: OrderGridComponent;
  public ORDER_ITEM_NUMBER = ORDER_ITEM_NUMBER;

  constructor() {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.parentComponent = this.params.context.componentParent;
  }

  public onChange(event) {
    if (this.params.colDef.colId === 'fit') {
      this.parentComponent.changeFitOption(this.params.node, event.currentTarget.checked);
      return;
    }

    this.parentComponent.changeTaxOption(this.params.node, event.currentTarget.checked);
  }

  public hideCheckBox() {
    return (
      !this.params.data.orderNumber ||
      (this.params.colDef.colId === 'fit' && (this.params.data.fitableYN !== 'Y' || this.params.data.parentLineNumber !== 0)) ||
      (this.params.colDef.colId === 'tax' && this.params.data.kitPC === 'C') ||
      this.params.data.itemNumber.toUpperCase() === ORDER_ITEM_NUMBER.giftCard
    );
  }

  public isChecked() {
    return (
      (this.params.colDef.colId === 'fit' && this.params.data.fittedYN === 'Y') ||
      (this.params.colDef.colId === 'tax' && this.params.data.taxYN === 'Y')
    );
  }

  public isCancelledOrInvoiced() {
    return (
      this.params.data.lineStatus === ORDER_LINE_STATUS.invoiced ||
      this.params.data.lineStatus === ORDER_LINE_STATUS.cancelled ||
      this.params.data.changeLineStatus === ORDER_LINE_STATUS.cancelled
    );
  }

  public isCreditOrder() {
    return this.parentComponent.isCreditOrder;
  }

  public isDisabled() {
    return (
      this.isCancelledOrInvoiced() ||
      (this.params.colDef.colId === 'fit' && this.isCreditOrder()) ||
      (this.params.colDef.colId === 'tax' && this.parentComponent.orderHeader && this.parentComponent.orderHeader.customerTaxRate === 0) ||
      this.params.data.readOnlyLineYN === 'Y' || this.params['isReadOnly']
    );
  }

  refresh(): boolean {
    return false;
  }
}
