import { Component, Input } from '@angular/core';
import { ProductDetail } from '../../models/service-proxies';

@Component({
  selector: 'app-product-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
})
export class ProductProductDetailsComponent {
  @Input() public product: ProductDetail;
}
