import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ManageusersComponent } from './manageusers.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromManageusers from './+state/manageusers.reducer';
import { ManageusersEffects } from './+state/manageusers.effects';
import { ManageusersExternalComponent } from './containers/manageusers-external/manageusers-external.component';
import { ManageusersInternalComponent } from './containers/manageusers-internal/manageusers-internal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreUiModule } from '@pos-app/core-ui';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatIconModule } from '@angular/material/icon';
import { ManageusersProfileComponent } from './containers/manageusers-profile/manageusers-profile.component';
import { ManageusersShowComponent } from './containers/manageusers-show/manageusers-show.component';

@NgModule({
  declarations: [
    ManageusersComponent,
    ManageusersExternalComponent,
    ManageusersInternalComponent,
    ManageusersProfileComponent,
    ManageusersShowComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    CoreUiModule,
    ReactiveFormsModule,
    NgSelectModule,
    MatIconModule,
    StoreModule.forFeature(
      fromManageusers.MANAGEUSERS_FEATURE_KEY,
      fromManageusers.reducer
    ),
    EffectsModule.forFeature([ManageusersEffects])
  ]
})
export class ManageusersModule {}
