import { ApiService } from '@pos-app/core-ui';

export function loadPartDetails(apiService: ApiService, parameters: any) {
  return apiService.getPartDetails(parameters);
}

export function fuzzySearch(
  apiService: ApiService,
  parameters: any,
  endpoint: string
) {
  return apiService.fuzzySearch(parameters, endpoint);
}
