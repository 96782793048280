import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GiftCardLoadup } from '../../+state/orders.models';

@Component({
  selector: 'app-orders-gift-card-loadup',
  templateUrl: './orders-gift-card-loadup.component.html',
  styleUrls: ['./orders-gift-card-loadup.component.scss'],
})
export class OrdersGiftCardLoadupComponent   {
  @Input() giftCardLoadUpList: GiftCardLoadup[];
  @Input() orderNumber: string;
  
  @Output() cardLoadedEmit = new EventEmitter<number>();

  public cardTabIndex = 1;

  public getLabel(i): string {
    return `Card ${i + 1}`;
  }

  public cardLoaded(i, paymentDetails): void {
    this.giftCardLoadUpList[i].receipt = paymentDetails.reference;

    this.cardLoadedEmit.emit(this.giftCardLoadUpList[i].orderLineNumber);
  }
}
