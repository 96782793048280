import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output,
  OnDestroy
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService, DialogService, CoreUiPartialState, hasUnsavedChanges, getBrandDefaults } from '@pos-app/core-ui';
import { Router } from '@angular/router';
import {
  CUSTOMER_TYPE,
  LookupLists,
  CustomerDetails,
  State,
  BrandDefaults
} from '@pos-app/data';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged,filter, concatMap} from 'rxjs/operators';
import { Customer } from '@pos-app/data';
import { Store } from '@ngrx/store';


@Component({
  selector: 'app-customers-details-form',
  templateUrl: './customers-details-form.component.html',
  styleUrls: ['./customers-details-form.component.scss']
})
export class CustomersDetailsFormComponent
  implements OnInit, OnChanges, OnDestroy {
  @Input() selectedCustomer: CustomerDetails;
  @Input() lookupLists: LookupLists;
  @Input() brandDefaults: BrandDefaults;
  @Input() marketingOptInYNFromOrderValue: Boolean;
  @Output() formChanged = new EventEmitter<any>();
  @Output() formIsValid = new EventEmitter<boolean>();
  @Output() formIsDirty = new EventEmitter<boolean>();
  @Output() submitByEnter = new EventEmitter<void>();
  @Output() selectedCountry = new EventEmitter<string>();
  @Output() customerChoosen = new EventEmitter<Customer>();
  @Output() newCustomerEvent = new EventEmitter<boolean>();

  public stateList: State[];
  @Input() set filteredStateList(value) {
    this.stateList = value;
  }
  public customerDetailsForm: FormGroup;
  public isReadOnlyCustomer = false;
  public currentCustomerSelected=false;
  private unSubscribe$ = new Subject<void>();
  public customerType = CUSTOMER_TYPE;
  public loading$ = new BehaviorSubject<boolean>(false);
  public loading = false;
  public matchedCustomer: Customer;
  public brandDefaults$: Observable<BrandDefaults>;
  public selectedCustomerCurrent: Customer;

  constructor(
    private router: Router,
    private apiService: ApiService,
     private formBuilder: FormBuilder,
     private dialogService: DialogService,
     private store: Store<CoreUiPartialState>,
     ) {
    this.customerDetailsForm = this.formBuilder.group({
      customerNumber: [''],
      branch: [''],
      name: [''],
      firstName: ['', Validators.required],
      surname: ['', Validators.required],
      email: ['', [Validators.email]],
      phone: [''],
      phoneType: [''],
      addressLine1: [''],
      addressLine2: [''],
      suburb: [''],
      state: [''],
      postCode: [''],
      country: [''],
      secContactName: [''],
      secContactPhone: [''],
      marketingOptInYN: [''],
      useBusinessNameYN: [false],
      businessName: [''],
      zone: [''],
      carrier: [''],
      route: [''],
      freightHandlingCode: ['']
    });
  }

  ngOnInit() {
    this.customerDetailsForm.valueChanges
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe(x => {
        let formValue = this.customerDetailsForm.getRawValue();
        if (formValue.useBusinessNameYN) {
          formValue = {
            ...formValue,
            secContactName: `${formValue.firstName} ${formValue.surname}`,
            firstName: formValue.businessName,
            surname: ''
          };
        }
        formValue = {
          ...formValue,
          marketingOptInYN: (formValue.marketingOptInYN==="")?"":formValue.marketingOptInYN ? 'Y' : 'N',
          useBusinessNameYN: formValue.useBusinessNameYN ? 'Y' : 'N'
        };
        this.formChanged.emit(formValue);
        this.formIsValid.emit(this.customerDetailsForm.valid);
        this.formIsDirty.emit(this.customerDetailsForm.dirty);
      });

    this.customerDetailsForm.controls.marketingOptInYN.valueChanges
    .pipe(takeUntil(this.unSubscribe$)).
    subscribe(
      value => {
       this.setEmailValidator(value);
       this.setPostCodeValidator(value);
      }
    );

    this.customerDetailsForm.controls.secContactName.valueChanges
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe(value => {
        if (value !== '') {
          this.customerDetailsForm.controls.secContactPhone.setValidators([
            Validators.required,
            Validators.pattern('^[0-9 ]{10,}$')
          ]);
        } else {
          this.customerDetailsForm.controls.secContactPhone.setValidators([
            Validators.pattern('^[0-9 ]{10,}$')
          ]);
        }
        this.customerDetailsForm.controls.secContactPhone.updateValueAndValidity();
      });

    this.customerDetailsForm.controls.country.valueChanges
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe(value => {
        this.selectedCountry.emit(value);
        this.customerDetailsForm.controls.state.patchValue('');
      });

    this.customerDetailsForm.controls.useBusinessNameYN.valueChanges
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe(value => {
        if (value) {
          this.customerDetailsForm.controls.businessName.setValidators([
            Validators.required
          ]);
        } else {
          this.customerDetailsForm.controls.businessName.clearValidators();
        }
        this.customerDetailsForm.controls.businessName.updateValueAndValidity();
      });

      this.customerDetailsForm.controls.phone.valueChanges
        .pipe(
           debounceTime(450),
          distinctUntilChanged(),
          filter((value)=>(value || '').trim().length>7 && !this.loading && !this.currentCustomerSelected && this.validPhone(value) ),
          concatMap((value)=>{
            this.loading=true
            this.loading$.next(true)
            return  this.getCustomerSpecificSearch({searchString:value, searchType:'P'}).pipe(
              takeUntil(this.unSubscribe$),
              )})
        )
        .subscribe({
          next:(resposne:any)=>{
            this.matchedCustomer = resposne.CustomerMatch
            this.loading=false
            this.loading$.next(true)
            if(this.matchedCustomer?.CustomerNumber){
              getPopup(this.dialogService, this.matchedCustomer)
              .subscribe((val) => {
                if (val) {  
                  this.currentCustomerSelected = true
                  this.store.dispatch(
                    hasUnsavedChanges({ unsavedChanges: true })
                  );
                  this.apiService
                    .getCustomerDetails({
                      customerNumberSearch: this.matchedCustomer?.CustomerNumber.toString(),
                    })
                    .pipe(takeUntil(this.unSubscribe$))
                    .subscribe((customerDetails) => {
                      this.selectedCustomer = customerDetails.SearchResults[0];
                      callPatchValue(this.selectedCustomer, this.customerDetailsForm, this.marketingOptInYNFromOrderValue, this.brandDefaults)
                       this.setSelectedCustomer(true)
                       let formValue = this.customerDetailsForm.getRawValue();
                      this.formChanged.emit(formValue);
                      this.formIsValid.emit(this.customerDetailsForm.valid);
                      this.formIsDirty.emit(this.customerDetailsForm.dirty);
                    });
                }
              }) 
            }
          }
        })

        this.customerDetailsForm.controls.email.valueChanges
        .pipe(
          debounceTime(450),
          distinctUntilChanged(),
          filter((value)=>(value || '').trim().length>7 &&( !this.loading) && (!this.currentCustomerSelected) && (this.validEmail(value)) ),
          concatMap((value)=>{
           
            
            this.loading=true
            this.loading$.next(true)
            return  this.getCustomerSpecificSearch({searchString:value, searchType:'E'}).pipe(
              takeUntil(this.unSubscribe$),
              )})
        )
        .subscribe({
          next:(resposne:any)=>{
            this.matchedCustomer = resposne.CustomerMatch
            this.loading=false
            this.loading$.next(true)
            if(this.matchedCustomer?.CustomerNumber){
              getPopup(this.dialogService, this.matchedCustomer)
              .subscribe((val) => {
                if (val) {
                  this.currentCustomerSelected = true
                  this.store.dispatch(
                    hasUnsavedChanges({ unsavedChanges: true })
                  );
                  this.apiService
                    .getCustomerDetails({
                      customerNumberSearch: this.matchedCustomer?.CustomerNumber.toString(),
                    })
                    .pipe(takeUntil(this.unSubscribe$))
                    .subscribe((customerDetails) => {
                      this.selectedCustomer = customerDetails.SearchResults[0];
                      callPatchValue(this.selectedCustomer, this.customerDetailsForm, this.marketingOptInYNFromOrderValue, this.brandDefaults)
                      this.setSelectedCustomer(true)
                      let formValue = this.customerDetailsForm.getRawValue();
                      this.formChanged.emit(formValue);
                      this.formIsValid.emit(this.customerDetailsForm.valid);
                      this.formIsDirty.emit(this.customerDetailsForm.dirty);
                    });
                }
              }) 
            }
          }
        })
        
        this.brandDefaults$ = this.store.select(getBrandDefaults);
        if(this.marketingOptInYNFromOrderValue===true || this.marketingOptInYNFromOrderValue===false ){
          this.customerDetailsForm.patchValue({
            marketingOptInYN:this.marketingOptInYNFromOrderValue 
          })
        }
        
  }

  selectCustomer(customer) {
    this.selectedCustomerCurrent = customer;
  }

  chooseExistingCustomer() {
    this.customerChoosen.emit(this.selectedCustomerCurrent);
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.selectedCustomer &&
      changes.selectedCustomer.currentValue &&
      this.customerDetailsForm
    ) {
      this.currentCustomerSelected = true
      callPatchValue(this.selectedCustomer, this.customerDetailsForm, this.marketingOptInYNFromOrderValue, this.brandDefaults)
      this.customerDetailsForm.markAsPristine();

      this.setEmailValidator(
        this.customerDetailsForm.controls.marketingOptInYN.value
      );
  
      this.setPostCodeValidator(
        this.customerDetailsForm.controls.marketingOptInYN.value
      );
      
      this.isReadOnlyCustomer = this.selectedCustomer.ReadOnlyCustomerYN
        ? this.selectedCustomer.ReadOnlyCustomerYN === 'Y'
        : this.selectedCustomer.CustomerType === CUSTOMER_TYPE.billTo;
      if (this.isReadOnlyCustomer) {
        this.customerDetailsForm.disable({ emitEvent: false, onlySelf: true });
      }
      if(this.marketingOptInYNFromOrderValue){
        this.setEmailValidator(this.marketingOptInYNFromOrderValue)
        this.setPostCodeValidator(this.marketingOptInYNFromOrderValue)
      }

    }

    if (
      changes.brandDefaults &&
      changes.brandDefaults.currentValue &&
      this.customerDetailsForm
    ) {
      if (!this.customerDetailsForm.controls.country.value) {
        this.customerDetailsForm.patchValue({
          country: this.brandDefaults.countryCode
        });
        this.selectedCountry.emit(this.brandDefaults.countryCode);
      }
      const phoneValidator = Validators.pattern(
        new RegExp(`^[0-9 ]{${this.brandDefaults.phoneDigits},}$`)
      );
      this.customerDetailsForm.controls.phone.setValidators([phoneValidator]);
      this.customerDetailsForm.controls.secContactPhone.setValidators([
        phoneValidator
      ]);
    }
  }
  validEmail(value){
     return (value)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  }

  validPhone(value){
    return value.match(`^[0-9 ]{${this.brandDefaults.phoneDigits},}$`)
  }

  setEmailValidator(value) {
    if (value && value===true)  {
      this.customerDetailsForm.controls.email.setValidators([
        Validators.required,
        Validators.email
      ]);
    } else {
      this.customerDetailsForm.controls.email.setValidators([]);
    }
    this.customerDetailsForm.controls.email.updateValueAndValidity();
    
  }

  setPostCodeValidator(value) {
    if (value && value===true) {
      this.customerDetailsForm.controls.postCode.setValidators([
        Validators.required,
      ]);
    } else {
      this.customerDetailsForm.controls.postCode.setValidators([]);
    }
    this.customerDetailsForm.controls.postCode.updateValueAndValidity();
  }

  pressEnter() {
    this.submitByEnter.emit();
  }

  getCustomerSpecificSearch(parameters: any) {
    return this.apiService.customerSpecificSearch({
      searchString: parameters.searchString,
      searchType: parameters.searchType
    });
  }

  setSelectedCustomer(value: boolean) {
    this.newCustomerEvent.emit(value)
  }
}
function getPopup(dialogService: DialogService, matchedCustomer: Customer) {
  return dialogService.confirm(
    ` <div>
        ${matchedCustomer.CustomerName?"<label>Customer:</label> " + matchedCustomer.CustomerName:""}
      </div>
      <div>
        ${matchedCustomer.CustomerType?"<label>Customer type:</label> " + matchedCustomer.CustomerType:""}
      </div>
      <div>
        ${matchedCustomer.Email?"<label>Email:</label> " + matchedCustomer.Email:""}
      </div>
      <div>
        ${matchedCustomer.Phone?"<label>Phone:</label> " + matchedCustomer.Phone:""}
      </div>
      <div>
        ${matchedCustomer.PostCode?"<label>Post Code:</label> " +matchedCustomer.PostCode:""}
      </div>
      `,
      `Match Found!  ${matchedCustomer.CustomerNumber?"  Customer Number:" + matchedCustomer.CustomerNumber:""} `,
      "USE EXISTING CUSTOMER",
      "CONTINUE ADDING NEW CUSTOMER",
      true,false,true
    )
}

function callPatchValue(selectedCustomer: CustomerDetails, customerDetailsForm: FormGroup, marketingOptInYNFromOrderValue: Boolean, brandDefaults: BrandDefaults) {
  customerDetailsForm.patchValue(
    {
      customerNumber: selectedCustomer.CustomerNumber,
      branch: selectedCustomer.BranchCode,
      name: selectedCustomer.CustomerName,
      firstName: selectedCustomer.FirstName,
      surname: selectedCustomer.Surname,
      email: selectedCustomer.Email,
      phone: selectedCustomer.Phone,
      phoneType: selectedCustomer.PhoneType,
      addressLine1: selectedCustomer.AddressLine1,
      addressLine2: selectedCustomer.AddressLine2,
      suburb: selectedCustomer.Suburb,
      state: selectedCustomer.StateCode,
      postCode: selectedCustomer.PostCode,
      country:
        selectedCustomer.CountryCode === ''
          ? brandDefaults.countryCode
          : selectedCustomer.CountryCode,
      secContactName: selectedCustomer.SecondaryContact,
      secContactPhone: selectedCustomer.SecondaryContactPhone,
      marketingOptInYN: marketingOptInYNFromOrderValue?true:selectedCustomer.MarketingYN===''?"":selectedCustomer.MarketingYN=== 'Y'? true: false ,
      useBusinessNameYN: selectedCustomer.UseBusinessNameYN === 'Y',
      businessName:
        selectedCustomer.UseBusinessNameYN === 'Y'
          ? selectedCustomer.CustomerName
          : '',
      zone: selectedCustomer.ZoneNumber,
      carrier: selectedCustomer.CarrierNumber,
      route: selectedCustomer.RouteCode,
      freightHandlingCode: selectedCustomer.FreightCode
    },
    {
      emitEvent: false,
      onlySelf: true
    }
  );
}
