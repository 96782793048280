<pos-app-fuzzy-search
  [apiEndpointKey]="'searchItems'"
  [apiRequestKey]="'searchString'"
  [valueKey]="'ProductCode'"
  [description1Key]="'ProductDescription1'"
  [description2Key]="'ProductDescription2'"
  [placeholder]="'ENTER PART CODE / DESCRIPTION'"
  [searchStr]="searchStr"
  [isAutoFocus]="true"
  (selectedItem)="itemCode = $event"
></pos-app-fuzzy-search>
<div>
  <input type="number" class="form-control my-3" [(ngModel)]="quantity" />
</div>
<div class="float-right">
  <button class="btn btn-cancel mr-3" (click)="closeModal()">CLOSE</button>
  <button class="btn" (click)="addItem()">Add Item</button>
</div>
