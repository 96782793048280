import { Component, OnInit } from '@angular/core';
import { ConfigService, MessageService } from '@pos-app/core-ui';
import { environment } from 'apps/pos-app/src/environments/environment';

@Component({
  selector: 'pos-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public brand: string;
  public version = environment.buildId;

  constructor(
    private config: ConfigService,
    private messageService: MessageService
  ) {}

  public ngOnInit(): void {
    this.brand = this.config.AppConfig.brand;

    localStorage.setItem('version', `${this.version} - ${this.brand}`);
  }

  public ngOnDestroy(): void {
    this.messageService.ngOnDestroy();
  }
}
