<pos-navbar *ngIf="!(isNavBarHidden$ | async)"></pos-navbar>
<main>
  <router-outlet></router-outlet>
</main>
<pos-footer *ngIf="!(isFooterHidden$ | async)"></pos-footer>
<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="ball-clip-rotate"
></ngx-spinner>
