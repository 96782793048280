import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { cloneDeep } from 'lodash';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { GetCedCategories, GetCedCategoriesSuccess, LoadFilterData, LoadFilterDataSuccess, UpdateFilterData } from './filter.actions';
import { EpcApiService } from '../../services/epc-api.service';

@Injectable()
export class FilterEffects {
  filterData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadFilterData),
      switchMap(({ payload }) => {
        const input = payload.input;
        return this.apiService.getCategoryFilterDataWithProductCount(input).pipe(
          mergeMap((res) => {
            if (payload.isFirstLoad) {
              return [
                LoadFilterDataSuccess({ payload: res }),
                UpdateFilterData({
                  payload: {
                    key: 'FilterProductCount',
                    value: cloneDeep(res),
                  },
                }),
              ];
            }

            return [
              UpdateFilterData({
                payload: { key: 'FilterProductCount', value: cloneDeep(res) },
              }),
            ];
          }),
          catchError((error) => EMPTY)
        );
      })
    )
  );

  getCedCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetCedCategories),
      switchMap(() => {
        return this.apiService.getCEDCategories().pipe(
          map(({ items }) => {
            return GetCedCategoriesSuccess({ payload: items });
          }),
          catchError((error) => EMPTY)
        );
      })
    )
  );

  constructor(private actions$: Actions, private apiService: EpcApiService) {}
}
