<div class="product-add-to-order arb-clear-text-for-table" [style.top.px]="top">
  <div class="container-fluid">
    <table>
      <tr>
        <td class="arb-medium-heading" style="text-align: center; width: 25%">
          <span>ORDER QUANTITY</span>
          <div class="quantity">
            <p-inputNumber class="quantity-input" [(ngModel)]="product.quantity" [min]="0" (ngModelChange)="updateQuantity()"></p-inputNumber>
          </div>
          <span>
            {{ product.numberOfComponentsPerProduct }}
          </span>
        </td>
        <td class="arb-medium-heading" style="text-align: center; width: 50%">
          <button *ngIf="isUserloggedIn && hasValidOrderTypes() && !readOnlyEPCTF" class="arb-ok-button" [disabled]="addToOrderClicked" (click)="addToOrder()">
            Add To Order
          </button>
          <span *ngIf="readOnlyEPCTF">Unload enquiry order to add part to order</span>
        </td>
        <td class="arb-medium-heading" style="text-align: center; width: 25%">
          <span>
            TOTAL PRICE
            <span class="total-price">
              {{ totalPrice | currency : currencyCode : 'symbol-narrow' : '0.2-2' }}
            </span>
          </span>
        </td>
      </tr>
    </table>
  </div>
  <div *ngIf="hasAssociatedItemWarning" class="row fluid">
    <div class="col-md-12" style="margin-top: 10px">
      <p *ngIf="hasAssociatedItemWarning" class="warning-message text-center">
        MANDATORY ASSOCIATED ITEMS EXIST - PLEASE REVIEW BEFORE ADDING TO ORDER
      </p>
    </div>
  </div>
</div>
