import { ICEDCategoryDto, IGetFilterDataOutput } from "../../models/service-proxies";

export interface IFilterState {
    filterData: IGetFilterDataOutput
    filter: IGlobalFilter,
    isLoaded: boolean,
    cedCategories: ICEDCategoryDto[]
}

export class GlobalFilter implements IGlobalFilter {
    cedCategoryId!: number | undefined;
    brandIds!: number[] | undefined;
    subBrands!: string[] | undefined;
    categories!: number[] | undefined;
    attributes!: any[] | undefined;
    page!: number | undefined;
    updated!: boolean| undefined;   
    attributesWithRelationship!: any[] | undefined;
    filterGroupUIStatus! : any[] | undefined;
}

export interface IGlobalFilter {
    cedCategoryId: number | undefined;
    brandIds: number[] | undefined;
    subBrands: string[] | undefined;
    categories: number[] | undefined;
    attributes: any[] | undefined;
    page: number | undefined;
    updated: boolean| undefined;
    attributesWithRelationship: any[] | undefined;
    filterGroupUIStatus: any[] | undefined;
}