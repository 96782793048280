<div class="product-images arb-clear-text-for-table">
  <img *ngIf="!displayImageCnt()" appProductImage src="" alt="No Image" />
  <ngb-carousel
    *ngIf="displayImageCnt()"
    #carousel
    data-interval="showNavigationIndicators"
    [showNavigationArrows]="showNavigationArrows"
    [showNavigationIndicators]="showNavigationIndicators"
  >
    <ng-template ngbSlide *ngFor="let image of images; let i = index">
      <div class="picsum-img-wrapper">
        <img
          *ngIf="image.type == 1 || image.type == -1"
          (imageErrorCallBack)="errorImageLoad($event)"
          appProductImage
          src="{{ image.contentUrl }}"
          alt="image.contentDescription"
          (click)="openImage(i)"
        />
        <div *ngIf="image.type == 2" class="video-container">
          <youtube-player
            [videoId]="getYoutubeVideoId(image.fileName)"
            (ready)="savePlayer($event)"
            [playerVars]="playerVars"
            width="500"
            height="280"
          >
          </youtube-player>
        </div>
      </div>
    </ng-template>
  </ngb-carousel>
</div>
