import { Component, OnInit } from '@angular/core';
import { CoreUiPartialState, getUserName, getUserId } from '@pos-app/core-ui';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-manageusers-profile',
  templateUrl: './manageusers-profile.component.html',
  styleUrls: ['./manageusers-profile.component.scss']
})
export class ManageusersProfileComponent implements OnInit {
  userId$: Observable<string>;

  constructor(private store: Store<CoreUiPartialState>) {}

  ngOnInit() {
    this.userId$ = this.store.pipe(select(getUserId));
  }
}
