<form class="w-100">
  <div class="input-group">
    <input
      class="form-control"
      name="d1"
      ngbDatepicker
      #d1="ngbDatepicker"
      [formControl]="datePickerForm"
      [placement]="'top'"
      [class.is-invalid]="showError && datePickerForm.errors?.invalid"
      [minDate]="minDate"
    />
    <div class="input-group-append">
      <button
        class="btn btn-secondary calendar text-white mr-0 p-all"
        (click)="d1.toggle()"
        type="button"
      ></button>
    </div>
  </div>
</form>
