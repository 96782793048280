<div
  bsModal
  #vehicleUpdateModal="bs-modal"
  class="modal fade confirm-modal"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
  [config]="{ backdrop: 'static', class: 'confirm-modal' }"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          <span>Change Vehicle Selection</span>
        </h5>
      </div>

      <div class="modal-body">
        <p>{{ confirmationText }}</p>
      </div>

      <div class="modal-footer">
        <button
          type="button"
          (click)="confirm()"
          class="btn text-white"
          style="width: 80px"
        >
          <i class="fa fa-arrow-circle-right"></i>
          <span>OK</span>
        </button>
        <button type="button" class="btn btn-cancel" (click)="close()">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
