<mat-tab-group animationDuration="1000ms" [selectedIndex]="cardTabIndex" >
  <mat-tab ></mat-tab>
  <mat-tab 
    *ngFor="let loadup of giftCardLoadUpList; index as i"
    [labelClass]="loadup.loadedCard ? 'success' : ''"
    [label]="getLabel(i)"
  >
    <app-orders-gift-card
      [orderNumber]="orderNumber"
      [loadedCard]="loadup.loadedCard"
      [loadAmount]="loadup.amount"
      (paymentDetails)="cardLoaded(i, $event)"
    ></app-orders-gift-card>
  </mat-tab>
</mat-tab-group>

