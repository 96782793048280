import { Component, Input } from '@angular/core';
import { ProductAttributeDto } from '../../models/service-proxies';

@Component({
  selector: 'app-product-tech-specs',
  templateUrl: './product-tech-specs.component.html',
  styleUrls: ['./product-tech-specs.component.scss'],
})
export class ProductProductTechSpecsComponent {
  @Input() public productInfoAttribute: ProductAttributeDto[] = [];
  @Input() public productFitmentInfoAttribute: ProductAttributeDto[] = [];
}
