<pos-app-spinner [show]="loadingCustomerDetails || loadingFetchingOrderFromJDE"></pos-app-spinner>

<div class="pt-2 container-fluid max1200" *ngIf="{ value: isExternalUser$ | async } as isExternalUser">
  <div
    *ngIf="
      !selectedCustomer &&
      (!isExternalUser.value ||
        securityFunctions.indexOf(SECURITY_FUNCTION.maintainOrders) !== -1) &&
      !loadingCustomerDetails &&
      orderTypes.length
    "
  >
    <hr />
    <app-customers-parked-orders [userID]="userID$ | async" (onSaveOrderToJDE)="saveToJDE($event)"></app-customers-parked-orders>
    <hr />

    <app-orders-search [orderTypes]="orderTypes" [hasCashDrawer]="hasCashDrawer" (fetchJDEOrder)="fetchOrderFromJDE($event)"></app-orders-search>
    <hr />

    <div
      *ngIf="
        !selectedCustomer &&
        (!isExternalUser.value ||
          securityFunctions.indexOf(SECURITY_FUNCTION.maintainOrders) !== -1) &&
        !loadingCustomerDetails &&
        orderTypes.length
      "
      class="pb-5"
    >
      <div class="d-flex" *ngIf="openOrder" class="mb-4">
        <div *ngIf="openOrder.orderNumber.trim() !== ''" class="mt-4">
          <label>ACTIVE ORDER:</label><span class="px-2"></span>
          <a [routerLink]="['/pos/orders/' + openOrder.orderNumber]">{{ openOrder.orderNumber }} - {{ openOrder.customerName }}</a>
        </div>
      </div>
      <app-customers-recent-orders
        #saveToJDE
        (onFetchOrderFromJDE)="fetchOrderFromJDE($event)"
        [userID]="userID$ | async"
        [isExternal]="isExternalUser.value"
        [validOrderTypes]="orderTypes"
      ></app-customers-recent-orders>
    </div>
  </div>
</div>
