import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
  ApiService,
  CoreUiPartialState,
  ElectronService,
  getSecurityFunctions,
} from '@pos-app/core-ui';
import { KEYS } from '@pos-app/data';
import { Subject } from 'rxjs';
import { debounceTime, finalize, take, takeUntil } from 'rxjs/operators';
import { LoadParkedOrdersList } from '../orders/+state/orders.actions';
import { OrdersPartialState } from '../orders/+state/orders.reducer';
import { ResourceItem, RESOURCE_TYPE } from '../../core/models/resources.model';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss'],
})
export class ResourcesComponent implements OnInit, OnDestroy {
  categories: string[];
  listToShow: ResourceItem[] = [];
  selectedCategory = '';
  contentSearch: FormControl;
  allFiles: ResourceItem[] = [];
  allLinks: ResourceItem[] = [];
  page = 1;
  pageSize = 10;
  isLoading: boolean;
  contentToShow: ResourceItem[];
  unSubscribe$ = new Subject<void>();
  securityFunctions: string[];
  selectedCategoryContent: ResourceItem[];

  constructor(
    private apiService: ApiService,
    private store: Store<CoreUiPartialState>,
    private orderStore: Store<OrdersPartialState>,
    private electronService: ElectronService
  ) {}

  ngOnInit() {
    this.contentSearch = new FormControl('');
    const catalogueStatus = JSON.parse(
      localStorage.getItem(KEYS.catalogueStatus)
    );
    this.store
      .select(getSecurityFunctions)
      .pipe(take(1))
      .subscribe((res) => (this.securityFunctions = res));
    this.isLoading = true;
    this.apiService
      .getResources(catalogueStatus['customerCategory'], this.securityFunctions)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((res) => {
        this.isLoading = false;
        if (res) {
          this.allFiles = this.getAllFiles(res, RESOURCE_TYPE.file).sort(
            (a, b) => {
              return a.name > b.name ? 1 : -1;
            }
          );
          this.allLinks = this.getAllFiles(res, RESOURCE_TYPE.link);
          this.categories = (
            [...new Set(res.map((item) => item.directory))] as string[]
          ).filter((category) => category !== 'Related Websites');

          this.contentToShow = this.allFiles;
        }
      });
    this.contentSearch.valueChanges
      .pipe(takeUntil(this.unSubscribe$), debounceTime(300))
      .subscribe((value) => {
        this.search(value);
      });

    this.dispatchParkedOrdersList();
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  extractFile(
    resourceItem: ResourceItem,
    allFiles: ResourceItem[],
    directory: string,
    type: string
  ) {
    if (resourceItem.type === type) {
      allFiles.push({ ...resourceItem, directory });
    }
    if (resourceItem.type === RESOURCE_TYPE.folder && resourceItem.children) {
      resourceItem.children.forEach((child) =>
        this.extractFile(child, allFiles, directory, type)
      );
    }
  }

  getAllFiles(resources: ResourceItem[], type: string) {
    let allFiles = [];
    resources.forEach((item) => {
      this.extractFile(item, allFiles, item.directory, type);
    });
    return allFiles;
  }

  search(value: string) {
    this.page = 1;
    this.contentToShow = [];
    value
      ? setTimeout(() => {
          this.contentToShow = (
            this.selectedCategoryContent || this.allFiles
          ).filter(
            (file) =>
              file.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
          );
        }, 0)
      : this.onSelectCategory();
  }

  hideRow(i) {
    // In pagination
    const isInPage =
      i >= (this.page - 1) * this.pageSize &&
      i < (this.page - 1) * this.pageSize + this.pageSize;
    return !isInPage;
  }

  onSelectCategory() {
    this.contentSearch.patchValue('', { emitEvent: false });
    this.contentToShow = [];
    setTimeout(() => {
      this.selectedCategoryContent = this.selectedCategory
        ? this.allFiles.filter(
            (item) => item.directory === this.selectedCategory
          )
        : this.allFiles;
      this.contentToShow = this.selectedCategoryContent;
      this.page = 1;
    }, 0);
  }

  openLink(id: string, isOpeningAsUrl = false) {
    return this.apiService
      .getDownloadUrl(id, isOpeningAsUrl)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((res) => {
        if (this.electronService.isElectron) {
          if (isOpeningAsUrl) {
            this.electronService.openLinkNatively(res.fileUrl);
          } else {
            this.electronService.openFileNatively(res.fileUrl);
          }
          return;
        }
        window.open(res.fileUrl, isOpeningAsUrl ? '_blank' : '_self');
      });
  }

  dispatchParkedOrdersList() {
    this.orderStore.dispatch(new LoadParkedOrdersList());
  }
}
