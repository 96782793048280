import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { CustomersActionTypes } from './customers.actions';

@Injectable({
  providedIn: 'root',
})
export class CustomersResponseService {
  constructor(private actions$: Actions) {}

  public selectCustomerSuccess$ = this.actions$.pipe(
    ofType(CustomersActionTypes.SELECT_CUSTOMER_SUCCESS)
  );

  public updateRetailCustomerSuccess$ = this.actions$.pipe(
    ofType(CustomersActionTypes.UPDATE_RETAIL_CUSTOMER_SUCCESS)
  );

  public updateShipToCustomerSuccess$ = this.actions$.pipe(
    ofType(CustomersActionTypes.UPDATE_SHIPTO_CUSTOMER_SUCCESS)
  );

  public loadCustomerVehiclesSuccess$ = this.actions$.pipe(
    ofType(CustomersActionTypes.LOAD_CUSTOMER_VEHICLES_SUCCESS)
  );
}
