import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CustomerTrailerSearch } from '@pos-app/data';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  concatMap,
  debounceTime,
  distinctUntilChanged,
  filter,
  takeUntil,
  finalize,
} from 'rxjs/operators';
import { sortCustomerTrailerSearchList } from '../../utils/utils-camper-trailer';
import { ApiService } from '../../services';

@Component({
  selector: 'pos-app-lib-customer-trailer-search',
  templateUrl: './customer-trailer-search.component.html',
  styleUrls: ['./customer-trailer-search.component.scss'],
})
export class CustomerTrailerSearchComponent implements OnInit, OnDestroy {
  @Output() selectNewCustomerTrailer =
    new EventEmitter<CustomerTrailerSearch>();
  @ViewChild('searchBox') searchBox: ElementRef;

  showTable = false;
  unSubscribe$ = new Subject<void>();
  public loading$ = new BehaviorSubject<boolean>(false);
  customerSelected: CustomerTrailerSearch = null;
  listOfCustomersTrailers: CustomerTrailerSearch[];

  indexFocus = 0;

  customerTrailerSearchForm: FormGroup;

  constructor(private apiService: ApiService, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.customerTrailerSearchForm = this.fb.group({
      customerTrailerSearch: [''],
    });

    this.onChanges();
  }

  onChanges() {
    this.customerTrailerSearchForm
      .get('customerTrailerSearch')
      .valueChanges.pipe(
        debounceTime(250),
        distinctUntilChanged(),
        filter((value) => (value || '').trim().length >= 3),
        concatMap((value) => {
          this.loading$.next(true);
          return this.getCustomersSearchTrailer(value).pipe(
            takeUntil(this.unSubscribe$),
            finalize(() => this.loading$.next(false))
          );
        })
      )
      .subscribe({
        next: (response: any) => {
          this.listOfCustomersTrailers = sortCustomerTrailerSearchList(
            response.SearchResults
          );
          this.showTable =
            this.listOfCustomersTrailers.length > 0 ? true : false;
        },
        error: (error) => {
          this.loading$.next(false);
        },
      });
  }

  onKeyboard(e) {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      let newIdx = this.indexFocus + (e.key === 'ArrowDown' ? 1 : -1);
      newIdx = newIdx < 0 ? 0 : newIdx;
      this.indexFocus = newIdx % this.listOfCustomersTrailers.length;
    } else if (e.key === 'Enter') {
      this.selectCustomerTrailer(this.listOfCustomersTrailers[this.indexFocus]);
    } else {
      this.showTable = true;
    }
  }

  lostFocus(e) {
    setTimeout(() => {
      this.showTable = false;
      //this.searchBox.nativeElement.focus();
    }, 300);
  }

  isFocus(e) {
    this.showTable = true;
    e.target.select();
  }

  selectCustomerTrailer(customerTrailer: CustomerTrailerSearch) {
    this.showTable = false;
    this.selectNewCustomerTrailer.emit(customerTrailer);
  }

  getCustomersSearchTrailer(parameters: any) {
    return this.apiService.customerTrailerSearch({
      searchString: parameters,
    });
  }

  ngOnDestroy(): void {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }
}
