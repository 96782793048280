import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OrdersBaseComponent } from './containers/orders-base/orders-base.component';

const routes: Routes = [
  {
    path: '',
    component: OrdersBaseComponent
  },
  {
    path: 'customer/:customerNumber',
    component: OrdersBaseComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrdersRoutingModule {}
