<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header text-center w-100">
    <div style="margin-left: 19rem !important" class="modal-title">
      <h3 class="pt-3 arb-large-heading">{{ noteTaskDetails ? 'EDIT' : 'ADD' }} NOTE / TASK</h3>
      <p *ngIf="!noteTaskDetails">
        <label>THIS WILL ADD A NOTE ON THE CUSTOMER AND NOT ON THE ORDER</label>
      </p>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="isTaskCalendarComp && !selectedCustomer">
      <label class="customer-search-label">
        <div>CUSTOMER SEARCH</div>
        <mat-icon (click)="addNewCustomer()" title="Add a customer">add_circle_outline</mat-icon>
      </label>
      <app-customers-search [displayCustomerDetails]="true" (displaySelectedCustomer)="fetchNewSelectedCustomer($event)"></app-customers-search>
    </ng-container>
    <div *ngIf="isTaskCalendarComp && selectedCustomer" class="row py-2 mt-2">
      <div class="col-2 header-label my-auto">
        <label class="d-flex">
          CUSTOMER
          <span class="my-auto pl-2">
            <mat-icon (click)="addNewCustomer()" title="Add a customer">add_circle_outline</mat-icon>
            <mat-icon (click)="selectNewCustomer()" title="Edit customer">edit</mat-icon>
          </span>
        </label>
      </div>
      <div class="col-3 d-flex">
        <a class="form-control overflow-hidden" (click)="editCustomer()">
          <u>{{ selectedCustomer?.CustomerName }}</u>
        </a>
        <mat-icon class="mt-1" (click)="clearCustomer()" title="Clear customer">clear</mat-icon>
      </div>
      <div class="col-1">
        <label>Type</label>
      </div>
      <div class="col-2">{{ selectedCustomer?.CustomerType }}</div>
      <div class="col-1">
        <label>PH</label>
      </div>
      <div class="col-2">{{ selectedCustomer?.Phone }}</div>
    </div>
    <div *ngIf="selectedCustomer && !isTaskCalendarComp" class="row py-2 mt-2">
      <div class="col-2">
        <label>NAME</label>
      </div>
      <div class="col-2">{{ selectedCustomer?.CustomerName }}</div>
      <div class="col-1">
        <label>Type</label>
      </div>
      <div class="col-2">{{ selectedCustomer?.CustomerType }}</div>
      <div class="col-1">
        <label>PH</label>
      </div>
      <div class="col-2">{{ selectedCustomer?.Phone }}</div>
    </div>
    <!-- Edit/Add Notes Area -->
    <form [formGroup]="noteForm">
      <div class="row py-2 mt-2">
        <label class="col-2"> TEMPLATE TO USE </label>
        <div class="col">
          <ng-select formControlName="template" (change)="onSelectTemplate()" bindLabel="templateName">
            <ng-option *ngFor="let template of templateList" [value]="template">
              {{ template.templateName }}
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div class="row py-2">
        <div class="col-2"></div>
        <label class="col-2"> ORDER NUMBER </label>
        <label class="col-2"> ORDER TYPE </label>
      </div>
      <div class="row py-2">
        <label class="col-2"> LINK TO ORDER </label>
        <div class="col-2">
          <input class="form-control" formControlName="orderNumber" maxlength="25" type="text" />
        </div>
        <div class="col-2">
          <ng-select formControlName="orderType" [clearable]="false">
            <ng-option *ngFor="let orderType of orderTypes" [value]="orderType.OrderTypeCode">{{ orderType.OrderTypeDescription }}</ng-option>
          </ng-select>
        </div>
        <div class="col">
          <button *ngIf="activeOrder?.orderNumber" class="btn btn-secondary" (click)="openOrderInJDE()">LINK TO OPEN ORDER</button>
        </div>
      </div>
      <div class="row py-2">
        <label class="col-2"> DATE DUE (TASK) </label>
        <div class="col-3">
          <pos-app-date-picker-single
            (selectedDate)="onSelectDate($event)"
            [allowFutureDate]="true"
            [allowPastDate]="false"
            [initialDate]="initialDate"
          ></pos-app-date-picker-single>
        </div>
      </div>
      <div class="row py-2">
        <label class="col-2"> ASSIGN TO </label>
        <div class="col">
          <ng-select
            formControlName="assignTo"
            bindLabel="userName"
            [multiple]="true"
            [ngClass]="{
              'border border-danger rounded': noteForm.controls.assignTo.errors
            }"
          >
            <ng-option *ngFor="let assignee of assignees" [value]="assignee.usersAddressBookNumber.toString()">
              {{ assignee.userName }}
            </ng-option>
          </ng-select>
          <input class="form-check-input" formControlName="isBranchAssignee" type="checkbox" />
          <label class="ms-3">ONLY SHOW PEOPLE FROM MY BRANCH</label>
        </div>
      </div>
      <div class="row py-2 d-none">
        <label class="col-2"> CONTACT </label>
        <div class="col">
          <input class="form-control" formControlName="contact" maxlength="25" size="30" type="text" />
        </div>
      </div>
      <div class="row py-2">
        <label class="col-2"> NOTE* </label>
        <div class="col-10">
          <input
            class="form-control"
            formControlName="note"
            size="100"
            maxlength="51"
            type="text"
            [ngClass]="{
              'border border-danger rounded': noteForm.controls.note.errors
            }"
          />
        </div>
      </div>
      <div class="row py-2">
        <label class="col-2"> ADDITIONAL DETAILS </label>
        <div class="col">
          <textarea
            formControlName="additionalDetails"
            type="text"
            class="form-control"
            maxlength="1999"
            [ngClass]="{
              'border border-danger rounded': noteForm.controls.additionalDetails.errors
            }"
          ></textarea>
        </div>
      </div>
      <!-- Attach File Area -->
      <div class="row py-2">
        <div class="col-2">
          <label>ATTACHED FILES</label>
        </div>
        <div class="col">
          <div *ngFor="let attachment of attachedFiles; index as i" class="d-flex align-middle pb-2">
            <mat-icon class="text-danger" (click)="onRemoveFile(i)"> close</mat-icon><span class="px-1"></span>{{ attachment.name }}
          </div>
          <label class="arb-other-button" for="file-upload">SELECT FILES</label>
          <input id="file-upload" type="file" class="d-none" (change)="onFileChange($event)" multiple />
        </div>
      </div>
      <!-- Button Area -->
      <div class="d-flex justify-content-end mt-4">
        <button type="submit" class="btn text-white" (click)="saveNoteTask()" [disabled]="!noteForm.valid">SAVE</button>
        <span class="px-2"></span>
        <button type="button" class="btn btn-cancel" (click)="closeModal()">CANCEL</button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #customerModalContent let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h3 class="arb-large-heading" id="modal-basic-title">
      {{ isAddingCustomer ? 'NEW CUSTOMER' : isSelectingCustomer ? 'SELECT CUSTOMER' : isEditingCustomer ? 'EDIT CUSTOMER' : 'ORDER NOTE' }}
    </h3>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="isAddingCustomer">
      <app-customers-details-new (newCustomer)="handleAddNewCustomer($event)" [isForNewOrder]="true"></app-customers-details-new>
    </div>
    <div *ngIf="isEditingCustomer">
      <app-customers-details
        [isForNewOrder]="true"
        (updatedCustomer)="handleAddNewCustomer($event)"
        [preSelectedCustomer]="selectedCustomer"
      ></app-customers-details>
    </div>
    <div *ngIf="isSelectingCustomer">
      <pos-app-lib-customer-search (selectNewCustomer)="handleSelectCustomer($event)" [forOrderHeader]="true"></pos-app-lib-customer-search>
    </div>
  </div>
</ng-template>
